
import * as AppointmentActions from '../actions/Actions_Appointment.js';

const INITIAL_STATE = { APPOINTMENT_REDUCER: { actions: AppointmentActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: AppointmentActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {

    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case AppointmentActions.INITIAL_STATE:
            {
                return state;
            }
        case AppointmentActions.IDLE_STATE:
            {
                //console.log("[Appointment] : IDLE_STATE : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.IDLE_STATE, data: payload, error: _error } };
            }
        case AppointmentActions.GET_APPOINTMENT_SUCCESS:
            {
                //console.log("[Appointment] : GET_APPOINTMENT_SUCCESS : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.GET_APPOINTMENT_SUCCESS, data: payload, error: _error } };
            }
        case AppointmentActions.GET_APPOINTMENT_FAILURE:
            {
                console.log("[Appointment] : GET_APPOINTMENT_FAILURE : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.GET_APPOINTMENT_FAILURE, data: payload, error: _error } };
            }
        case AppointmentActions.CREATE_APPOINTMENT_SUCCESS:
            {
                //console.log("[Appointment] : CREATE_APPOINTMENT_SUCCESS : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.CREATE_APPOINTMENT_SUCCESS, data: payload, error: _error } };
            }
        case AppointmentActions.CREATE_APPOINTMENT_FAILURE:
            {
                console.log("[Appointment] : CREATE_APPOINTMENT_FAILURE : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.CREATE_APPOINTMENT_FAILURE, data: payload, error: _error } };
            }
        case AppointmentActions.UPDATE_APPOINTMENT_SUCCESS:
            {
                //console.log("[Appointment]: UPDATE_APPOINTMENT_SUCCESS : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.UPDATE_APPOINTMENT_SUCCESS, data: payload, error: _error } };
            }
        case AppointmentActions.UPDATE_APPOINTMENT_FAILURE:
            {
                //console.log("[Appointment]: UPDATE_APPOINTMENT_FAILURE : ", payload);
                return { ...state, APPOINTMENT_REDUCER: { actions: AppointmentActions.UPDATE_APPOINTMENT_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[Appointment]  default :", state);
                return state;
            }
    }
}
