import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';
export const UPLOAD_IMAGE_SQUARE_SUCCESS = 'UPLOAD_IMAGE_SQUARE_SUCCESS';
export const UPLOAD_IMAGE_SQUARE_FAILURE = 'UPLOAD_IMAGE_SQUARE_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

const _header = { Authorization: `Bearer ${CONFIG.EP_token}`, }

//const _header = { 'Content-Type': 'application/json', Authorization: `Bearer ${_token}`, 'Cache-Control': ['no-cache', 'no-store', 'must-revalidate',] }

// UploadFiles =============================================================================================================================

export function APIUpLoadImage(confirmInfo) {

    // console.log('APIUpLoadImage : ', confirmInfo);

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.uploads}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpLoadImage : ", response.data);
            dispatch(APIUpLoadImage_Success(response.data));

        }).catch((error) => {
            console.log("APIUpLoadImage fail", error);
            dispatch(APIUpLoadImage_Failure(error));
        })
    }
}

function APIUpLoadImage_Success(data) { return { type: UPLOAD_IMAGE_SUCCESS, payload: data }; }

function APIUpLoadImage_Failure(error) { return { type: UPLOAD_IMAGE_FAILURE, payload: error }; }

// UploadFiles =============================================================================================================================


// UploadFiles =============================================================================================================================

export function APIUpLoadImageSquare(confirmInfo) {

    // console.log('APIUpLoadImageSquare : ', confirmInfo);

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.uploads}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpLoadImageSquare : ", response.data);
            dispatch(APIUpLoadImageSquare_Success(response.data));

        }).catch((error) => {
            console.log("APIUpLoadImageSquare fail", error);
            dispatch(APIUpLoadImageSquare_Failure(error));
        })
    }
}

function APIUpLoadImageSquare_Success(data) { return { type: UPLOAD_IMAGE_SQUARE_SUCCESS, payload: data }; }

function APIUpLoadImageSquare_Failure(error) { return { type: UPLOAD_IMAGE_SQUARE_FAILURE, payload: error }; }

// UploadFiles =============================================================================================================================

