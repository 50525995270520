import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { Metrics, Colors, Fonts, Family } from "../../themes";
import { isMobile, isTablet } from '../../common/DeviceCheck';
import { SetDate } from '../../common/SetDate';

class BookingPanelHeader extends Component {
    render() {
        const { packageInfo } = this.props;
        //console.log('BookingPanelHeader : ',packageInfo)

        return (
            <div style={{ width: '100%', display: 'flex', backgroundColor: Colors.theme, padding: '10px 0px', textAlign: 'left' }}>
                <div style={{ flex: 7, margin: '0px 20px' }}>
                    <label style={style.label_packageName}>{packageInfo.name}</label>
                </div>
                <div onClick={() => { /* */ }} style={{ flex: '1', textAlign: 'center', margin: '0px 10px' }}>
                    <label style={style.label_packageName}>{``}</label>
                </div>
            </div>);
    }
}

export default withRouter(BookingPanelHeader);

const style = {
    label_packageName: {
        height: '31px',
        width: '100%',
        color: Colors.white,
        fontSize: '16px',
        fontFamily: Family.roboto
    },
};