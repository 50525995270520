import { Province } from './Province';

const HeaderPage = {
    TH: "สร้างจุดนัดหมาย",
    EN: "Create Appointment",
    CN: "创建约会",
    UPDATE: "Update Appointment",
}

const BodyPage = [
    [{
        title_TH: "ชื่อ", title_EN: "Appointment Name", title_CN: "名称",
        type: "text",
        require: true,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ลักษณะ", title_EN: "Description", title_CN: "描述",
        type: "text-multiline",
        require: true,
        stateName: "description",
        placeholder_TH: "ลักษณะ", placeholder_EN: "Insert Description", placeholder_CN: "插入说明"
    }],
    [{
        title_TH: "Package Type", title_EN: "Package Type", title_CN: "Package Type",
        type: "checkbox",
        stateName: "packageTypeService",
        items: [
            { id: 0, label: 'OneDayTrip', value: 'OneDayTrip' },
            { id: 1, label: 'Transfer', value: 'Transfer' },
            { id: 2, label: 'Hotel', value: 'Hotel' },
            { id: 3, label: 'All', value: 'All' },
        ]
    }],
    [{
        title_TH: "Routing Type", title_EN: "Routing Type", title_CN: "Routing Type",
        type: "checkbox",
        stateName: "routingType",
        items: [
            { id: 0, label: 'AirPort', value: 'airPort' },
            { id: 1, label: 'Direct', value: 'direct' },
            { id: 2, label: 'Port', value: 'port' },
            { id: 3, label: 'Island', value: 'island', },
            { id: 4, label: 'PointToPoint', value: 'pointToPoint', },
        ]
    }], [{
        title_TH: "ที่อยู่", title_EN: "Address", title_CN: "地址",
        type: "text",
        require: true,
        stateName: "address",
        placeholder_TH: "ที่อยู่", placeholder_EN: "Insert Address", placeholder_CN: "插入地址"
    }, {
        pro_TH: "จังหวัด", pro_EN: "Province", pro_CN: "省",
        dis_TH: "ตำบล", dis_EN: "District", dis_CN: "区",
        type: "search_select",
        requirePro: true,
        requireDis: false,
        stateName_pro: "province", stateName_dis: "district",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: Province,
    }, {
        title_TH: "ประเทศ", title_EN: "Country", title_CN: "国家",
        type: "select",
        stateName: "country",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: [{
            label_TH: "ไทย",
            label_EN: "Thailand",
            label_CN: "泰国",
            value: 'Thailand',
        }]
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "actived",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "image",
        stateName: "picture",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "media",
        stateName: "gallery",
        require: {
            EN: '650 x 450'
        }
    }]
];

export default {
    HeaderPage,
    BodyPage,
}