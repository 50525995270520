import React from 'react'
import { path } from 'ramda';
import styled from 'styled-components';
import { connect } from 'react-redux';

import Button from '../../constant/Button';
import FormTypes from '../../common/FormTypes';
import ButtonText from '../../constant/Button/ButtonText';

import { Images, Colors, Family } from '../../themes';
import DialogPanel from '../../componets/DialogPanel.jsx';
import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';
import * as CustomerActions from '../../actions/Actions_CustomerManager.js';

let waitingTime = true;

class CustomerCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isBtnActive: false, isLoading: false, msgError: undefined, dialogType: undefined, CustomerInfo: { ...props.CustomerInfo } }
    }

    componentWillReceiveProps(nextProps) {

        const { CustomerManager } = nextProps;
        console.log(`CustomerManager [componentWillReceiveProps] : `, CustomerManager);

        if (!!CustomerManager) {

            const { actions, data } = CustomerManager.CUSTOMER_REDUCER;

            switch (actions) {
                case CustomerActions.UPDATE_CUSTOMER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE_CUSTOMER_SUCCESS', dialogType: 'success', });
                        this.props.getAPICustomerList();
                    } break;
                case CustomerActions.UPDATE_CUSTOMER_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    render() {

        const { onClosePanel } = this.props;
        const { isBtnActive, CustomerInfo, isLoading, msgError, dialogType } = this.state;
        const { fullName, id, email, memberLevel, memberType, active, type, countryCode, tel } = CustomerInfo;
        const isDisabled = (!isBtnActive);


        const fildeList = [
            { label: '', value: fullName, type: 'header' },
            { label: 'User ID : ', value: id, type: 'text' },
            { label: 'Phone number : ', value: `${(countryCode || '')}${tel}`, type: 'text' },
            { label: 'Email : ', value: email, type: 'text' },
            { label: 'Level : ', value: memberLevel, type: 'text' },
            { label: 'Type : ', value: memberType, type: 'text' },
            { label: 'Regiter Type : ', value: type, type: 'text' },
            { label: 'Visible : ', value: active, type: 'switch', stateName: 'active' },
        ]

        console.log('fieldList', fildeList);
        console.log('props', this.props, this.state);

        return (
            <InfoContianer>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <FormLabelContianer>
                    {fildeList.map((each, index) => (this.contentPanel(each, index)))}
                    <ButtonContianer>
                        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ width: '130px', paddingRight: '15px', }} buttonStyle={{ padding: '10px' }} onClick={() => onClosePanel()} />
                        <Button.Themes TitleText={ButtonText.BUTTON_SAVE} style={{ width: '130px' }}
                            disabled={isDisabled} buttonStyle={{ padding: '10px' }} onClick={() => { this.onSave() }} />
                    </ButtonContianer>
                </FormLabelContianer>
            </InfoContianer>
        )
    }

    contentPanel(each, index) {

        switch (each.type) {
            case 'header':
                {
                    return (<HeaderLabel key={index}>{each.value}</HeaderLabel>)
                }
            case 'switch':
                {
                    return (
                        <ItemContianer key={index}>
                            <FormTypes.Switches Left
                                ValueText={each.label}
                                checked={(each.value === 1)}
                                styleValue={{ fontWeight: 'unset' }}
                                onChange={(e) => { this.onChangeState(each.stateName, e.target.checked ? 1 : 0) }} />
                        </ItemContianer>
                    )
                }
            default:
                {
                    return (
                        <ItemContianer key={index}>
                            <TitleLabel>{each.label}</TitleLabel>
                            <TextLabel>{each.value || '-'}</TextLabel>
                        </ItemContianer>
                    )
                }
        }
    }

    onChangeState = (fieldname, value) => {

        console.log('onChangeState', fieldname, value);
        let CustomerInfo = { ...this.state.CustomerInfo };
        console.log('CustomerInfo', CustomerInfo);

        switch (fieldname) {
            case 'active':
                {
                    CustomerInfo = { ...CustomerInfo, [fieldname]: value }
                } break;
        }
        console.log('Customer Sucess', CustomerInfo);
        this.setState({ CustomerInfo, isBtnActive: true })
    }

    onSave = () => {
        console.log('onChangeState', this.state);
        const { CustomerInfo } = this.state;
        this.setState({ isLoading: true }, () => this.props.APIUpdateCustomer(CustomerInfo))
    }

    onCloseDialog = () => { this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }) }
}


function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        APIUpdateCustomer: (_confirmInfo) => {
            InitWaitingTime();
            dispatch(CustomerActions.APIUpdateCustomer(_confirmInfo));
        },
        getAPICustomerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CustomerActions.getCustomerList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerCard);;

const InfoContianer = styled.div`
        display:flex;
        min-width: 500px;
        width: 100%;`;

const ButtonContianer = styled.div`
        display:flex;
        justify-content: flex-end;`;

const FormLabelContianer = styled.div`
        width: 100%;
        font-family:${Family.roboto};
        font-size: 14px;`;

const ItemContianer = styled.div`
        align-items: center;
        height: 35px;
        display:flex;`;

const TitleLabel = styled.div`
        line-height: 16px;`;

const TextLabel = styled.div`
        padding-left:10px;
        opacity: 0.64;
        line-height: 16px;`;

const HeaderLabel = styled.div`
        font-size: 20px;
        font-weight:bold;
        padding: 20px 0px;
        opacity: 0.64;
        line-height: 16px;`;
