import React from 'react';
import PropTypes from 'prop-types';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Family, Size, Colors } from '../themes';
let data = [
    { name: 'N', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'S', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Nn', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Ss', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Nm', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Sm', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Nm', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Sm', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'Sm', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: 'W', OneDayTrip: 0, Transfer: 0, Package: 0 },];


const CMarging = { top: 5, right: 30, left: 20, bottom: 10 }
const ChartStyle = { fontFamily: Family.roboto, fontSize: Size.chart.label };

function BookingSummaryChart(props) {

    const { BCWidth, Info } = props;
    const _isInfo = (Array.isArray(Info) && Info.length > 0);
    const _data = setInfo(_isInfo, Info);


    return (
        <BarChart width={BCWidth || 250} height={200} data={_data} margin={CMarging} style={ChartStyle}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {(_isInfo) ? customBar(_data) : defaultBar()}
        </BarChart>);
}

function setInfo(_isInfo, Info) {

    return (_isInfo) ? ((Info.length > 4) ?
        (Info.map(_info => { _info.name = _info.name.substring(0, 10); return _info })) : Info) : data;
}

function customBar(_info) {
    return [<Bar key={'OneDayTrip'} dataKey="value" fill={Colors.barChart_1} />,]
}

function defaultBar() {
    return [
        <Bar key={'OneDayTrip'} dataKey="OneDayTrip" fill={Colors.barChart_1} />,
        <Bar key={'Transfer'} dataKey="Transfer" fill={Colors.barChart_2} />,
        <Bar key={'Package'} dataKey="Package" fill={Colors.barChart_3} />];
}

export default (BookingSummaryChart);


// let data = [
//     { name: 'N', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'S', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Nn', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'Ss', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Nm', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'Sm', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Nm', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'Sm', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Sm', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'W', OneDayTrip: 2000, Transfer: 9800, Package: 2290 },];