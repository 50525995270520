
import * as AgentActions from '../actions/Actions_AgentManager';

const INITIAL_STATE = { AGENT_REDUCER: { actions: AgentActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: AgentActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case AgentActions.INITIAL_STATE:
            {
                return state;
            }
        case AgentActions.IDLE_STATE:
            {
                //console.log("[ Agent ] : IDLE_STATE : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.IDLE_STATE, data: payload, error: _error } };
            }
        case AgentActions.GET_AGENT_SUCCESS:
            {
                // console.log("[ Agent ] : GET_AGENT_SUCCESS : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.GET_AGENT_SUCCESS, data: payload, error: _error } };
            }
        case AgentActions.GET_AGENT_FAILURE:
            {
                console.log("[ Agent ] : GET_AGENT_FAILURE : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.GET_AGENT_FAILURE, data: payload, error: _error } };
            }
        case AgentActions.GET_AGENT_INFO_SUCCESS:
            {
                console.log("[ Agent ] : GET_AGENT_INFO_SUCCESS : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.GET_AGENT_INFO_SUCCESS, data: payload, error: _error } };
            }
        case AgentActions.GET_AGENT_INFO_FAILURE:
            {
                console.log("[ Agent ] : GET_AGENT_INFO_FAILURE : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.GET_AGENT_INFO_FAILURE, data: payload, error: _error } };
            }
        case AgentActions.GET_AGENT_WITHPARENT_ID_SUCCESS:
            {
                console.log("[ Agent ] : GET_AGENT_WITHPARENT_ID_SUCCESS : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.GET_AGENT_WITHPARENT_ID_SUCCESS, data: payload, error: _error } };
            }
        case AgentActions.GET_AGENT_WITHPARENT_ID_FAILURE:
            {
                console.log("[ Agent ] : GET_AGENT_WITHPARENT_ID_FAILURE : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.GET_AGENT_WITHPARENT_ID_FAILURE, data: payload, error: _error } };
            }
        case AgentActions.CREATE_AGENT_SUCCESS:
            {
                //console.log("[ Agent ] : CREATE_AGENT_SUCCESS : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.CREATE_AGENT_SUCCESS, data: payload, error: _error } };
            }
        case AgentActions.CREATE_AGENT_FAILURE:
            {
                console.log("[ Agent ] : CREATE_AGENT_FAILURE : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.CREATE_AGENT_FAILURE, data: payload, error: _error } };
            }
        case AgentActions.UPDATE_AGENT_SUCCESS:
            {
                // console.log("[ Agent ] : UPDATE_AGENT_SUCCESS : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.UPDATE_AGENT_SUCCESS, data: payload, error: _error } };
            }
        case AgentActions.UPDATE_AGENT_FAILURE:
            {
                console.log("[ Agent ] : UPDATE_AGENT_FAILURE : ", payload);
                return { ...state, AGENT_REDUCER: { actions: AgentActions.UPDATE_AGENT_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[ Agent ]  default :", state);
                return state;
            }
    }
}
