import React, { Component } from 'react'
import { path, equals } from 'ramda';
import 'react-datepicker/dist/react-datepicker.css';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Colors } from '../../themes';
import FormTypes from '../../common/FormTypes';

class FormTextTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {
                endDatestartDate: props.valueStart,
                endDate: props.valueEnd,
            },
        };

    }
    componentWillReceiveProps(nextProps) {
        if (!equals(path(['time', 'startDate'], this.state), nextProps.valueStart) || !equals(path(['time', 'endDate'], this.state), nextProps.valueEnd)) {
            this.setState({ time: { ...this.state.time, startDate: nextProps.valueStart, endDate: nextProps.valueEnd }, });
        }
    }

    handleChange = (fielename, date) => {
        const { onChange, stateStart, stateEnd } = this.props;

        this.setState({
            time: {
                ...this.state.time,
                ...date,
            },
        }, () => {
            if (typeof onChange === 'function') {
                if (date.startDate) {
                    onChange(stateStart, this.state.time.startDate);
                } else if (date.endDate) {
                    onChange(stateEnd, this.state.time.endDate);
                }
            }
        });
    }

    render() {
        const { classes, disabled, TitleEnd, TitleStart, valueStart, valueEnd, onChange } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.textInput}>
                    <FormTypes.TextFormat
                        type={'time'}
                        TitleText={TitleStart}
                        disabled={disabled}
                        value={valueStart}
                        onChange={onChange()}
                        style={{ margin: '0px', }} />
                </div>
                <div className={classes.line}> {'To'} </div>
                <div className={classes.textInput}>
                    <FormTypes.TextFormat
                        type={'time'}
                        TitleText={TitleEnd}
                        disabled={disabled}
                        value={valueEnd}
                        onChange={onChange()}
                        style={{ margin: '0px', }} />
                </div>
            </div>
        )
    }
}
const styles = theme => (
    {
        root: { position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'center' },
        textInput: {
            display: 'flex',
            flexDirection: 'column',
        },
        line: {
            display: 'flex',
            alignItems: 'center',
            // lineHeight: 7,
            padding: ' 0px 15px',
            fontSize: '16px',
            color: Colors.themeDark
        },
    }
)
FormTextTime.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormTextTime);
