import axios from 'axios';
import CONFIG from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const REFUND_SUCCESS = 'REFUND_SUCCESS';
export const REFUND_FAILURE = 'REFUND_FAILURE';

// export const GET_REFUND_SUCCESS = 'GET_REFUND_SUCCESS';
// export const GET_REFUND_FAILURE = 'GET_REFUND_FAILURE';

export const GET_REFUND_HISTORY_LISTS_SUCCESS = 'GET_REFUND_HISTORY_LISTS_SUCCESS';
export const GET_REFUND_HISTORY_LISTS_FAILURE = 'GET_REFUND_HISTORY_LISTS_FAILURE';

export const UPDATE_REFUND_SUCCESS = 'UPDATE_REFUND_SUCCESS';
export const UPDATE_REFUND_FAILURE = 'UPDATE_REFUND_FAILURE';
export const GET_REFUND_REQUEST_LISTS_SUCCESS = 'GET_REFUND_REQUEST_LISTS_SUCCESS';
export const GET_REFUND_REQUEST_LISTS_FAILURE = 'GET_REFUND_REQUEST_LISTS_FAILURE';

export const GET_REFUND_REASONS_SUCCESS = 'GET_REFUND_REASONS_SUCCESS';
export const GET_REFUND_REASONS_FAILURE = 'GET_REFUND_REASONS_FAILURE';

export const CREATE_REFUND_REASONS_SUCCESS = 'CREATE_REFUND_REASONS_SUCCESS';
export const CREATE_REFUND_REASONS_FAILURE = 'CREATE_REFUND_REASONS_FAILURE';

export const UPDATE_REFUND_REASONS_SUCCESS = 'UPDATE_REFUND_REASONS_SUCCESS';
export const UPDATE_REFUND_REASONS_FAILURE = 'UPDATE_REFUND_REASONS_FAILURE';



// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

const _header = { 'Content-Type': 'application/json', Authorization: `Bearer ${CONFIG.EP_token}`, 'Cache-Control': ['no-cache', 'no-store', 'must-revalidate',] }

// getRefundReasonsList =====================================================================================================================

export function getRefundReasonsList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getRefundReasonsList :", `${CONFIG.LBhost}${CONFIG.api.getRefundReasonsList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getRefundReasonsList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getRefundReasonsList : ", response);
            localStorage.setItem('RefundList', response.data)
            dispatch(getRefundReasonsList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getRefundReasonsList Failure", error);
            dispatch(getRefundReasonsList_Failure(error));
        })
    }
}

function getRefundReasonsList_Success(data) {
    return { type: GET_REFUND_REASONS_SUCCESS, payload: data };
}

function getRefundReasonsList_Failure(error) {
    return { type: GET_REFUND_REASONS_FAILURE, payload: error };
}

// createRefundReasons ========================================================================================================================


export function APICreateRefundReasons(confirmInfo) {

    console.log('APICreateRefundReasons : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createRefundReasons}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateRefundReasons : ", response.data);
            dispatch(APICreateRefundReasons_Success(response.data));

        }).catch((error) => {
            console.log("APICreateRefundReasons Failure", error);
            dispatch(APICreateRefundReasons_Failure(error));
        })
    }
}

function APICreateRefundReasons_Success(data) {
    return { type: CREATE_REFUND_REASONS_SUCCESS, payload: data };
}

function APICreateRefundReasons_Failure(error) {
    return { type: CREATE_REFUND_REASONS_FAILURE, payload: error };
}

// updateRefundReasons =========================================================================================================================

export function APIUpdateRefundReasons(confirmInfo) {
    console.log('APIUpdateRefundReasons : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateRefundReasons}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { refundPolicyId: confirmInfo.refundPolicyId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateRefundReasons : ", response.data);
            dispatch(APIUpdateRefundReasons_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateRefundReasons Failure", error);
            dispatch(APIUpdateRefundReasons_Failure(error));
        })
    }
}

function APIUpdateRefundReasons_Success(data) {
    return { type: UPDATE_REFUND_REASONS_SUCCESS, payload: data };
}

function APIUpdateRefundReasons_Failure(error) {
    return { type: UPDATE_REFUND_REASONS_FAILURE, payload: error };
}

// getRefundList =====================================================================================================================

// export function getRefundList(_keyword = '', _fileterjson = {}) {

//     console.log("[API] getRefundList :", `${CONFIG.LBhost}${CONFIG.api.getRefundList}`)
//     return dispatch => {
//         // _fileterjson.keyword = _keyword;
//         const request = axios({
//             url: `${CONFIG.LBhost}${CONFIG.api.getRefundList}`,
//             method: 'GET',
//             headers: CONFIG.LBheader,
//             params: _keyword,
//             data: _fileterjson,
//             dataType: 'json',
//         });

//         request.then((response) => {
//             // console.log("[API] getRefundList : ", response);
//             localStorage.setItem('RefundList', response.data)
//             dispatch(getRefundList_Success(response.data));

//         }).catch((error) => {
//             console.log("[API] getRefundList Failure", error);
//             dispatch(getRefundList_Failure(error));
//         })
//     }
// }

// function getRefundList_Success(data) {
//     return { type: GET_REFUND_SUCCESS, payload: data };
// }

// function getRefundList_Failure(error) {
//     return { type: GET_REFUND_FAILURE, payload: error };
// }

// APIRefund ========================================================================================================================


export function APIRefund(confirmInfo) {

    console.log('APIRefund : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.APIRefund}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIRefund : ", response.data);
            dispatch(APIRefund_Success(response.data));

        }).catch((error) => {
            // console.log("APIRefund Failure", error);
            dispatch(APIRefund_Failure(error));
        })
    }
}

function APIRefund_Success(data) {
    return { type: REFUND_SUCCESS, payload: data };
}

function APIRefund_Failure(error) {
    return { type: REFUND_FAILURE, payload: error };
}

// APIRefundRequestList ============================================================================================================================

export function APIRefundRequestList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getRefundRequestList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIRefundRequestList Success : ", response.data);
            dispatch(APIRefundRequestList_Success(response.data));

        }).catch((error) => {
            console.log("[API] RefundRequestList Failure", error);
            dispatch(APIRefundRequestList_Failure(error));
        })
    }
}

function APIRefundRequestList_Success(data) {
    return { type: GET_REFUND_REQUEST_LISTS_SUCCESS, payload: data };
}

function APIRefundRequestList_Failure(error) {
    return { type: GET_REFUND_REQUEST_LISTS_FAILURE, payload: error };
}
// APIRefundHistoryList ============================================================================================================================

export function APIRefundHistoryList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getRefundHistoryList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIRefundHistoryList Success : ", response.data);
            dispatch(APIRefundHistoryList_Success(response.data));

        }).catch((error) => {
            console.log("[API] APIRefundHistoryList Failure", error);
            dispatch(APIRefundHistoryList_Failure(error));
        })
    }
}

function APIRefundHistoryList_Success(data) {
    return { type: GET_REFUND_HISTORY_LISTS_SUCCESS, payload: data };
}

function APIRefundHistoryList_Failure(error) {
    return { type: GET_REFUND_HISTORY_LISTS_FAILURE, payload: error };
}

//updateRefund =========================================================================================================================

export function APIUpdateRefund(confirmInfo) {
    console.log('APIUpdateRefund : ', confirmInfo)
    console.log('APIUpdateRefund : ', `${CONFIG.host}${CONFIG.api.updateRefund}`)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.updateRefund}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateRefund : ", response.data);
            dispatch(APIUpdateRefund_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateRefund Failure", error);
            dispatch(APIUpdateRefund_Failure(error));
        })
    }
}

function APIUpdateRefund_Success(data) {
    return { type: UPDATE_REFUND_SUCCESS, payload: data };
}

function APIUpdateRefund_Failure(error) {
    return { type: UPDATE_REFUND_FAILURE, payload: error };
}

