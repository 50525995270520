import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_PROMOTIONS_SUCCESS = 'GET_PROMOTIONS_SUCCESS';
export const GET_PROMOTIONS_FAILURE = 'GET_PROMOTIONS_FAILURE';

export const GET_PROMOTIONALL_SUCCESS = 'GET_PROMOTIONALL_SUCCESS';
export const GET_PROMOTIONALL_FAILURE = 'GET_PROMOTIONALL_FAILURE';

export const CREATE_PROMOTIONS_SUCCESS = 'CREATE_PROMOTIONS_SUCCESS';
export const CREATE_PROMOTIONS_FAILURE = 'CREATE_PROMOTIONS_FAILURE';

export const UPDATE_PROMOTIONS_SUCCESS = 'UPDATE_PROMOTIONS_SUCCESS';
export const UPDATE_PROMOTIONS_FAILURE = 'UPDATE_PROMOTIONS_FAILURE';

export const GET_PROMOTIONS_CODE_SUCCESS = 'GET_PROMOTIONS_CODE_SUCCESS';
export const GET_PROMOTIONS_CODE_FAILURE = 'GET_PROMOTIONS_CODE_FAILURE';

export const CREATE_PROMOTIONS_CODE_SUCCESS = 'CREATE_PROMOTIONS_CODE_SUCCESS';
export const CREATE_PROMOTIONS_CODE_FAILURE = 'CREATE_PROMOTIONS_CODE_FAILURE';

export const UPDATE_PROMOTIONS_CODE_SUCCESS = 'UPDATE_PROMOTIONS_CODE_SUCCESS';
export const UPDATE_PROMOTIONS_CODE_FAILURE = 'UPDATE_PROMOTIONS_CODE_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================
export function getPromotions(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getPromotions}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            // data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAgentInfo : ", response);
            dispatch(getPromotions_Success(response.data));

        }).catch((error) => {
            console.log("[API] getPromotions Failure", error);
            dispatch(getPromotions_Failure(error));
        })
    }
}

function getPromotions_Success(data) {
    return { type: GET_PROMOTIONALL_SUCCESS, payload: data };
}

function getPromotions_Failure(error) {
    return { type: GET_PROMOTIONALL_FAILURE, payload: error };
}

// getPromotionList ============================================================================================================================

export function getPromotionsList(_keyword = '', _fileterjson = {}) {

    //console.log("[API] getProductsList :", `${CONFIG.LBhost}${CONFIG.api.getProductsList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getPromotionsList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] PromotionsList : ", response.data);
            dispatch(getPromotionsList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getProductsList Failure", error);
            dispatch(getPromotionsList_Failure(error));
        })
    }
}

function getPromotionsList_Success(data) {
    return { type: GET_PROMOTIONS_SUCCESS, payload: data };
}

function getPromotionsList_Failure(error) {
    return { type: GET_PROMOTIONS_FAILURE, payload: error };
}

// CreatePromotion =========================================================================================================================


export function APICreatePromotions(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createPromotions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreatePromotions : ", response.data);
            dispatch(APICreatePromotions_Success(response.data));

        }).catch((error) => {
            console.log("APICreatePromotions fail", error);
            dispatch(APICreatePromotions_Failure(error));
        })
    }
}

function APICreatePromotions_Success(data) {
    return { type: CREATE_PROMOTIONS_SUCCESS, payload: data };
}

function APICreatePromotions_Failure(error) {
    return { type: CREATE_PROMOTIONS_FAILURE, payload: error };
}
// updatePromotion ======================================================================================================================================

export function APIUpdatePromotions(confirmInfo) {
    // console.log('APIUpdateSeller : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updatePromotions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { promotionId: confirmInfo.promotionId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpdateSeller : ", response.data);
            dispatch(APIUpdatePromotions_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateSeller fail", error);
            dispatch(APIUpdatePromotions_Failure(error));
        })
    }
}

function APIUpdatePromotions_Success(data) {
    return { type: UPDATE_PROMOTIONS_SUCCESS, payload: data };
}

function APIUpdatePromotions_Failure(error) {
    return { type: UPDATE_PROMOTIONS_FAILURE, payload: error };
}

// updatePromotion ======================================================================================================================================

// getPromotionsCodeList ============================================================================================================================

export function getPromotionsCodeList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getPromotionsCodeList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] PromotionsCodeList : ", response.data);
            dispatch(getPromotionsCodeList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getProductsList Failure", error);
            dispatch(getPromotionsCodeList_Failure(error));
        })
    }
}

function getPromotionsCodeList_Success(data) {
    return { type: GET_PROMOTIONS_CODE_SUCCESS, payload: data };
}

function getPromotionsCodeList_Failure(error) {
    return { type: GET_PROMOTIONS_CODE_FAILURE, payload: error };
}

// CreatePromotionsCode =========================================================================================================================

export function APICreatePromotionsCode(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createPromotionsCode}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APICreatePromotionsCode : ", response.data);
            dispatch(APICreatePromotionsCode_Success(response.data));

        }).catch((error) => {
            console.log("APICreatePromotionsCode fail", error);
            dispatch(APICreatePromotionsCode_Failure(error));
        })
    }
}

function APICreatePromotionsCode_Success(data) {
    return { type: CREATE_PROMOTIONS_CODE_SUCCESS, payload: data };
}

function APICreatePromotionsCode_Failure(error) {
    return { type: CREATE_PROMOTIONS_CODE_FAILURE, payload: error };
}

// updatePromotionsCode ======================================================================================================================================

export function APIUpdatePromotionsCode(confirmInfo) {

    // console.log('APIUpdatePromotionsCode : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updatePromotionsCode}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdatePromotionsCode : ", response.data);
            dispatch(APIUpdatePromotionsCode_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdatePromotionsCode fail", error);
            dispatch(APIUpdatePromotionsCode_Failure(error));
        })
    }
}

function APIUpdatePromotionsCode_Success(data) {
    return { type: UPDATE_PROMOTIONS_CODE_SUCCESS, payload: data };
}

function APIUpdatePromotionsCode_Failure(error) {
    return { type: UPDATE_PROMOTIONS_CODE_FAILURE, payload: error };
}

// updatePromotionsCode ======================================================================================================================================