import React, { Component } from 'react'
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import validate from "validate.js";
import * as R from 'ramda';
import { path, omit, keys, equals, update, remove, clone } from 'ramda';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction';

import FormTypes from '../../common/FormTypes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import Package from '../../constant/JSON/Package';
import { acceptFile } from '../../constant';
import FileUploader from '../../FileUploader';
import { init_package } from '../../constant/initState/initPackage';
import * as validator from '../../constant/Validator/Package';
import SupplierCard from './SupplierCard';
import AppointmentCard from './AppointmentCard';
import * as format from '../../common/setFormat';
import { PathName, Roles } from '../../constant';
import moment from 'moment';
import { SetDateFormat } from '../../common/SetDate';

import { AheadTime } from '../../constant/JSON/Number';

let LanguagePackage = null;
const suggestions = AheadTime.map(suggestion => ({
    value: suggestion.value,
    label: suggestion.precis,
}));

class PackageCard extends Component {

    error = null;
    imageGallery = null;
    imageOther = [];
    state = { isLoading: false, msgError: undefined, dialogType: undefined };

    constructor(props) {

        super(props);

        const { isTransfer, isOneDayTrip, isFerries, supplierInfo, packageInfo } = props;

        this.state = {
            packageInfo:
                isTransfer ? { ...init_package, type: 'Transfer', supplierId: supplierInfo ? supplierInfo.supplierId : '' } :
                    isOneDayTrip ? { ...init_package, type: 'OneDayTrip', supplierId: supplierInfo ? supplierInfo.supplierId : '' } :
                        isFerries ? { ...init_package, type: 'Ferries', bags: "0", conveyanceType: "ferries", transferType: "join", supplierId: supplierInfo ? supplierInfo.supplierId : '' }
                            : { ...init_package },
            oldPackageInfo: { ...packageInfo },
            isSupplier: false, supplierId: '',
            pickUpZoneList: [],
            isPickUpList: false,
            isDropOff: false,
            isBtnActive: false,
            isUpdateAPI: false,
        }

    }


    onClosePanel = () => (this.setState({ isSupplier: false, isPickUpList: false, isDropOffList: false }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

    render() {

        const { classes, IsEdit, LanguagePage } = this.props;
        const { packageInfo, oldPackageInfo, isBtnActive, isSupplier, isPickUpList, isLoading, dialogType, msgError, isDropOffList } = this.state;
        if (packageInfo.language === "") { (LanguagePackage = "EN") } else (LanguagePackage = packageInfo.language);
        // console.log('packageState', this.state)
        let isPickUp = (IsEdit) ? path(['isPickUp'], oldPackageInfo) : path(['isPickUp'], packageInfo);

        return (
            <React.Fragment>
                {(isDropOffList) && <DialogAction Active={isDropOffList} OnClose={this.onClosePanel} AcrionCom={this.GetDropOffList()} />}
                {(isPickUpList) && <DialogAction Active={isPickUpList} OnClose={this.onClosePanel} AcrionCom={this.GetPickUpList()} />}
                {(isSupplier) && <DialogAction Active={isSupplier} OnClose={this.onClosePanel} AcrionCom={this.GetSupplierId()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                <div style={{ display: 'flex', justifyContent: IsEdit ? 'flex-end' : 'space-between', alignItems: 'center' }}>
                    {(!IsEdit) && <Typography className={classes.typography}> {Package.HeaderPage.EN} </Typography>}
                </div>
                {

                    (IsEdit ? Package.BodyEditPage : Package.BodyNewPage).map((card, i) => {
                        return (
                            <div key={i} className={((i === 4 || i === 5) && !isPickUp) ? null : classes.card}>
                                {card.map((row, index) => {

                                    let pickUp = row.type === 'pickUp';

                                    return (
                                        (pickUp && !isPickUp) ? null :
                                            <div key={index} className={classes.row}>
                                                <div className={classes.title} > {row.guide ? row.title_EN + row.guide : row.title_EN}</div>
                                                {row.length > 0 && row.map((each, i) => {

                                                    let additional = each.type === 'additional';

                                                    return (
                                                        <div key={i} className={classes.each} style={{ width: each.type === 'switch' && '20%', flexDirection: additional && 'column', paddingTop: 5 }} >
                                                            {this.SetItemsWithType(each)}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                    )
                                })}
                            </div>
                        )
                    })
                }

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL}
                        onClick={() => this.onCancle()} />
                    <Button.Themes TitleText={ButtonText.BUTTON_SUBMIT} disabled={!isBtnActive} style={{ paddingLeft: '15px', }}
                        onClick={() => { (IsEdit) ? this.onUpdate() : this.onSave() }} />
                </div>

            </React.Fragment >
        )
    }

    SetItemsWithType(each) {

        const { packageInfo, oldPackageInfo, isUpdateAPI } = this.state;
        const { LocationServicesList, DestinationsList, AppointmentList, supplierInfo, SupplierList, IsEdit, isOneDayTrip, isTransfer, isFerries } = this.props;
        let isError = !!path([each.stateName], this.error)
        let Active = (IsEdit) ? oldPackageInfo.visible : packageInfo.visible;
        let options = (each.options) ? each.options : null;
        let ferries = packageInfo.conveyanceType === 'ferries';
        let oldFerries = oldPackageInfo.conveyanceType === 'ferries';
        let joinFerries = (IsEdit) ? (oldFerries && oldPackageInfo.transferType === 'join') : (ferries && packageInfo.transferType === 'join')
        let limit = each.stateName === 'limit';
        let Language = (IsEdit) ? this.props.LanguagePage : LanguagePackage
        let conveyance = each.stateName === 'conveyanceType';
        let dropOff = each.stateName === 'isDropOff';
        let Ferries = (IsEdit) ? oldPackageInfo.type === 'Ferries' : packageInfo.type === 'Ferries'
        let Transfer = (IsEdit) ? oldPackageInfo.type === 'Transfer' : packageInfo.type === 'Transfer'
        let isPickUp = (IsEdit) ? path(['isPickUp'], oldPackageInfo) : path(['isPickUp'], packageInfo)
        let isDropOff = (IsEdit) ? path(['isDropOff'], oldPackageInfo) : path(['isDropOff'], packageInfo)
        const LocationList = path(['datas'], LocationServicesList);
        let valueStateName = (IsEdit) ? path([each.stateName], oldPackageInfo) : path([each.stateName], packageInfo)
        // console.log('packageInfo', oldPackageInfo, packageInfo, oldFerries, ferries, IsEdit, joinFerries)
        switch (each.type) {
            case "text": {
                return (
                    <FormTypes.TextFormat
                        error={isError}
                        disabled={Active ? true : false}
                        Require={(Active) ? false : each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={valueStateName}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "selectId": {
                return (
                    (supplierInfo && supplierInfo.supplierId) ?
                        <FormTypes.TextFormat disabled
                            disabled={Active ? true : false}
                            error={isError}
                            Require={(Active) ? false : each.require}
                            TitleText={each.title_EN}
                            placeholder={each.placeholder_EN}
                            value={supplierInfo.name || ''}
                            onChange={undefined} />
                        :
                        <div style={{ display: 'flex', marginBottom: '10px', width: '100%' }}>
                            {
                                (IsEdit) ? null :
                                    <Button.ThemesDark TitleText={each.title_EN} disabled={(Active) ? true : false}
                                        buttonStyle={{ padding: 7, margin: '10px 0px 0px', }}
                                        style={{ marginRight: '15px', width: '15%', fontSize: 12 }} onClick={() => this.setState({ isSupplier: true })} />
                            }
                            <div style={{ width: '100%' }} onClick={() => { IsEdit && this.setState({ isSupplier: true }) }} >
                                <FormTypes.TextFormat disabled
                                    value={SupplierList.filter(supplierInfo => (supplierInfo.supplierId === (packageInfo.supplierId || oldPackageInfo.supplierId))).map((supplier) => { return (`${supplier.name}`) })} />
                            </div>
                            {/* <div disabled={(Active || IsEdit) ? true : false} style={{ fontFamily: Family.roboto, width: '100%', padding: '0px 20px', lineHeight: 3, fontSize: 16, fontWeight: 300, margin: '15px 0px 0px', border: `${'1px solid' + Colors.border}` }}
                                onClick={() => { IsEdit ? null : this.setState({ isSupplier: true }) }}  >
                                {SupplierList.filter(supplierInfo => (supplierInfo.supplierId === (packageInfo.supplierId || oldPackageInfo.supplierId))).map((supplier) => { return (`${supplier.name}`) })}
                            </div> */}
                        </div>

                )
            }
            case "text-multiline": {
                return (
                    <FormTypes.TextFormat multiline rows={6}
                        disabled={Active ? true : false}
                        error={isError}
                        Require={(Active) ? false : each.require}
                        style={{ marginBottom: 10 }}
                        TitleText={each.title_EN}
                        // placeholder={each.placeholder_EN}
                        value={valueStateName}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "text-number": {
                return (
                    <FormTypes.TextFormat
                        disabled={Active ? true : false}
                        error={isError}
                        Require={(Active) ? false : each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={valueStateName}
                        InputFormat={format.NumberFormatCustom}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                    />
                )
            }
            case "select": {
                let disabledType = (isUpdateAPI || IsEdit) && each.stateName === 'type';
                let disabledLocation = (isUpdateAPI || IsEdit) && each.stateName === 'locationServiceId';

                return (
                    <FormTypes.Select
                        disabled={(ferries && conveyance) || disabledType || disabledLocation || Active ? true : false}
                        error={isError}
                        style={{ marginRight: (each.stateName === 'type' || each.stateName === 'locationServiceId') ? '10px' : 0 }}
                        Require={Active || disabledType || disabledLocation ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueStateName}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={
                            each.stateName === 'locationServiceId' ?
                                Array.isArray(LocationList) ? LocationList.map((option) =>
                                    (<option key={option.locationServiceId} value={option.locationServiceId}> {option[Language].name || '-'} </option>)) : null
                                : isTransfer ?
                                    Array.isArray(each.optionsTransfer) && each.optionsTransfer.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN || '-'} </option>) })
                                    : isOneDayTrip ?
                                        Array.isArray(each.optionsDayTour) && each.optionsDayTour.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN || '-'} </option>) })
                                        : isFerries ?
                                            Array.isArray(each.optionsFerries) && each.optionsFerries.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN || '-'} </option>) })
                                            :
                                            Array.isArray(each.options) && each.options.map((option, i) => {
                                                return (<option key={i} value={option.value}> {option.label_EN || '-'} </option>)
                                            })
                        } />
                )
            }
            case "startFerries": {
                let locationId = LocationList && LocationList.find(val => (val.locationServiceId === (IsEdit ? oldPackageInfo.locationServiceId : packageInfo.locationServiceId)))
                let new_appointment = locationId && AppointmentList.filter(val => (val.parentId === (locationId.code)) && (val.actived))
                let new_info = (IsEdit) ? oldPackageInfo : packageInfo

                return (
                    (joinFerries) &&
                    each.form.map((form, index) => {
                        let pickUpList = form.stateName === 'pickUpList';
                        let dropOffList = form.stateName === 'dropOffList';
                        let check_value = Array.isArray(new_info[form.stateName]) && new_info[form.stateName].length > 0 ? true : false
                        return (
                            <FormTypes.Select
                                key={index}
                                disabled={(!locationId || Active || check_value) ? true : false}
                                error={!!path([form.stateName], this.error)}
                                style={{ marginRight: pickUpList && '10px' }}
                                Require={(Active) ? false : form.require}
                                TitleText={form.title_EN}
                                value={path([form.stateName, 0], new_info)}
                                onChange={(e) => { this.onChangeState(form.stateName, [e.target.value]) }}
                                options={
                                    Array.isArray(new_appointment) && new_appointment.map((option) => {
                                        return (<option key={option.appointmentId} value={option.appointmentId}> {option[Language].name || '-'} </option>)
                                    })
                                } />

                        )
                    })

                )
            }
            case "switch": {
                return (
                    <FormTypes.Switches
                        Right={true}
                        disabled={Active && each.stateName !== 'visible' || (dropOff && (!(Transfer || Ferries)) ? true : false)}
                        ValueText={each.label_EN}
                        TitleText={each.title_EN}
                        checked={valueStateName}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }
            case 'media': {
                const value = path([each.stateName], oldPackageInfo);
                return (
                    <React.Fragment>
                        {(IsEdit) ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader
                                    ref={(ref) => this[each.stateName] = ref}
                                    disabled={Active ? true : false}
                                    multiple
                                    onChange={(files) => this.setState({ [`new_${each.stateName}`]: files })}
                                    onDefaultChange={(defaultFiles) => { this.onChangeState(each.stateName, defaultFiles) }}
                                    defaultFiles={value}
                                    accept={acceptFile.image}
                                />
                                <div style={{ color: Colors.disabled, fontSize: '10px', paddingTop: '5px' }} >
                                    {each.require.EN}
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader multiple accept={acceptFile.image}
                                    disabled={Active ? true : false}
                                    onChange={(files) => { this.onChangeState(each.stateName, files) }} />
                                <div style={{ color: Colors.disabled, fontSize: '12px', paddingTop: '10px', fontFamily: Family.roboto }} >
                                    {each.require.EN}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
            case "additional": {
                return (
                    <React.Fragment>
                        {this.setTypes(Active)}
                    </React.Fragment>
                )
            }
            case 'text-duration': {
                // let cutOffTime = each.stateName === 'cutOffTime';
                let valueStateName = (IsEdit) ? path([each.stateGroup, each.stateName], oldPackageInfo)
                    : path([each.stateGroup, each.stateName], packageInfo)
                let valueStateUnit = (IsEdit) ? path([each.stateGroup, each.stateUnit], oldPackageInfo) : path([each.stateGroup, each.stateUnit], packageInfo)
                // let valueStateName = (IsEdit) ? (cutOffTime ? path([each.stateName], oldPackageInfo) : path([each.stateGroup, each.stateName], oldPackageInfo))
                //     : (cutOffTime ? path([each.stateName], packageInfo) : path([each.stateGroup, each.stateName], packageInfo))
                // let valueStateUnit = (IsEdit) ? path([each.stateGroup, each.stateUnit], oldPackageInfo) : path([each.stateGroup, each.stateUnit], packageInfo)
                return (
                    <FormTypes.TextDuration
                        disabled={Active ? true : false}
                        error={isError}
                        errorUnit={!!path([each.stateUnit], this.error)}
                        Require={(Active) ? false : each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={valueStateName}
                        valueUnit={valueStateUnit}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        onChangeUnit={(e) => { this.onChangeState(each.stateUnit, e.target.value) }}
                        options={Array.isArray(options) && options.map((option, i) => { return (<option key={i} value={option.value}> {option.precis || '-'} </option>) })} />
                )
            }
            case 'text-cutOffTime': {

                return (

                    <FormTypes.SelectText type={'select'}
                        options={suggestions}
                        value={valueStateName || ''}
                        valueUnit={'Hour(s)'}
                        style={{ paddingLeft: '10px' }}
                        disabled={Active && true}
                        error={isError}
                        stateName={each.stateName}
                        Require={(Active) ? false : each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        onChange={(name, value) => { this.onChangeState(name, value) }}
                    />
                )
            }
            case 'form-checkbox': {
                return (
                    <FormTypes.FormCondition
                        disabled={Active ? true : false}
                        StateInfo={IsEdit ? oldPackageInfo : packageInfo}
                        TitleText={each.title_EN}
                        Items={each.items}
                        Language={LanguagePackage}
                        handleToggle={this.handleToggle} />
                )
            }
            case 'button-list': {
                let location_id = (IsEdit) ? path(['locationServiceId'], oldPackageInfo) : path(['locationServiceId'], packageInfo)
                let StateList = valueStateName
                let new_info = (IsEdit) ? oldPackageInfo : packageInfo
                let check_value = Array.isArray(new_info.pickUpList) && new_info.pickUpList.length === 1 ? true : false

                return (
                    <div style={{ width: '100%' }}>
                        <Button.ThemesDark FullWidth TitleText={each.title_EN}
                            disabled={(Active) ? true : (location_id ? ((!isPickUp || check_value) && true) : true)}
                            onClick={() => this.setState({ isPickUpList: true })} />
                        {
                            <div style={{ padding: '10px', border: Array.isArray(StateList) && StateList.length > 0 ? '1px solid #bfbfbf' : null }}>
                                {
                                    Array.isArray(StateList) && StateList.length > 0 && StateList.map((each, index) => {
                                        const valuePickUp = Array.isArray(AppointmentList) && AppointmentList.find(info => info.appointmentId === each)
                                        return (
                                            <div key={index} style={{ margin: '15px 15px 15px 0px', padding: '5px', fontFamily: Family.roboto, fontWeight: 300 }}>
                                                {index + 1 + ') '} {valuePickUp ? valuePickUp[Language].name : each}
                                            </div>)
                                    })
                                }
                            </div>
                        }
                    </div>

                )
            }

            case 'dropOff-list': {
                let location_id = (IsEdit) ? path(['locationServiceId'], oldPackageInfo) : path(['locationServiceId'], packageInfo)
                let StateList = valueStateName
                let new_info = (IsEdit) ? oldPackageInfo : packageInfo
                let check_value = Array.isArray(new_info.dropOffList) && new_info.dropOffList.length === 1 ? true : false

                return (
                    <div style={{ width: '100%' }}>
                        <Button.ThemesDark TitleText={each.title_EN} style={{ width: '100%' }}
                            disabled={(Active) ? true : (location_id ? ((!isDropOff || check_value) && true) : true)}
                            onClick={() => this.setState({ isDropOffList: true })} />
                        {
                            <div style={{ padding: '10px', border: StateList.length > 0 ? '1px solid #bfbfbf' : null }}>
                                {
                                    Array.isArray(StateList) && StateList.length > 0 && StateList.map((each, index) => {
                                        const valuePickUp = Array.isArray(AppointmentList) && AppointmentList.find(info => info.appointmentId === each)
                                        let Language = (IsEdit) ? this.props.LanguagePage : LanguagePackage
                                        return (
                                            <div key={index} style={{ margin: '15px 15px 15px 0px', padding: '5px', fontFamily: Family.roboto, fontWeight: 300 }}>
                                                {index + 1 + ') '} {valuePickUp ? valuePickUp[Language].name : each}
                                            </div>)
                                    })
                                }
                            </div>
                        }
                    </div>

                )
            }

            case 'form-text': {
                let service = each.stateName === 'service' || each.stateName === 'serviceExclusion';
                let textArray = (IsEdit) ? path([each.stateName], oldPackageInfo) : path([each.stateName], packageInfo);
                let pickUpPolicy = each.stateName === 'pickUpPolicy';
                return (
                    (pickUpPolicy && !isPickUp) ? null : <div style={{ flexDirection: 'column', display: 'flex', width: ' 100%' }}>
                        {
                            !!textArray && textArray.map((text, index) => {
                                return (
                                    <div key={index} style={{ position: 'relative', width: '100%' }}>
                                        <FormTypes.FormText
                                            disabled={Active ? true : false}
                                            indexArray={index}
                                            error={isError}
                                            Require={(Active) ? false : each.require}
                                            TitleText={each.title_EN}
                                            placeholder={each.placeholder_EN}
                                            value={service ? text.topic : text}
                                            onChange={(e) => { this.onChangeState(each.stateName, textArray, e.target.value, index) }}
                                            onRemove={() => this.onRemove(each.stateName, index, textArray)} />
                                    </div>
                                )
                            })
                        }
                        <Button.ThemesDark TitleText={ButtonText.BUTTON_ADD_MORE_TITLE} disabled={Active ? true : false}
                            style={{ marginTop: 20, width: '100%' }} onClick={() => this.onAddMore(each.stateName, textArray)} />
                    </div>
                )
            }
            case 'form-detail': {
                let detailArray = (IsEdit) ? path([each.stateName], oldPackageInfo) : path([each.stateName], packageInfo);
                return (
                    <div style={{ flexDirection: 'column', display: 'flex', width: ' 100%' }}>
                        {
                            !!detailArray && detailArray.map((detail, index) => {
                                // console.log('packageInfo', packageInfo, each.stateName)
                                return (
                                    <div key={index} style={{ position: 'relative', width: '100%' }}>
                                        <FormTypes.FormSchedule
                                            Active={Active}
                                            disabled={Active ? true : false}
                                            stateTime={ferries ? packageInfo[each.stateName][0] === moment(detail.time, 'hh:mm') : oldFerries && moment(detail.time, 'hh:mm')}
                                            FormLayout={Package.ScheduleData}
                                            PackageInfo={IsEdit ? oldPackageInfo : packageInfo}
                                            StateInfo={IsEdit ? oldPackageInfo[each.stateName][index] : packageInfo[each.stateName][index]}
                                            indexArray={index}
                                            DestinationsList={DestinationsList}
                                            AppointmentList={AppointmentList}
                                            LocationList={LocationList}
                                            LanguagePackage={LanguagePackage}
                                            onChange={(value) => { this.onChangeState(each.stateName, detailArray, value, index) }}
                                            onRemove={() => this.onRemove(each.stateName, index, detailArray)} />
                                    </div>

                                )
                            })
                        }
                        <Button.ThemesDark TitleText={ButtonText.BUTTON_ADD_MORE_SUBJECT} disabled={Active ? true : false}
                            style={{ width: '100%' }} onClick={() => this.onAddMore(each.stateName, detailArray)} />
                    </div >
                )
            }
            case "form-period": {

                let periodArray = (IsEdit) ? path([each.stateName], oldPackageInfo) : path([each.stateName], packageInfo);

                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {
                            !!periodArray && periodArray.map((period, index) => {

                                let checkDate = !!equals(SetDateFormat(minStart), SetDateFormat(period.startDate));
                                let minStart = (index > 0 && checkDate) ? moment(periodArray[index - 1].endDate).add(1, 'day') : moment(period.startDate);

                                return (
                                    <div key={index} style={{ position: 'relative', width: '100%' }}>
                                        <FormTypes.FormTimePeriod
                                            packageId={(IsEdit) ? oldPackageInfo.packageId : null}
                                            TransferType={(IsEdit) ? oldPackageInfo.type : packageInfo.type}
                                            Active={Active}
                                            minStartDate={(period.startDate === '') ? minStart : null}
                                            // minStartDate={index > 0 ? moment(periodArray[index - 1].endDate).add(1, 'day') : null}
                                            maxEndDate={undefined}
                                            disabled={Active ? true : false}
                                            FormLayout={Package.PeriodData}
                                            // TitleCheckBox={each.title_EN}
                                            // Items={each.items}
                                            StateInfo={(IsEdit) ? oldPackageInfo[each.stateName][index] : packageInfo[each.stateName][index]}
                                            Language={LanguagePackage}
                                            // TitleStart={each.start_EN}
                                            // TitleEnd={each.end_EN}
                                            indexArray={index}
                                            handleToggle={this.handleToggle}
                                            onChange={(value) => {
                                                console.log('value : ', value, each.stateName)
                                                this.onChangeState(each.stateName, periodArray, value, index)
                                            }}
                                            onRemove={() => this.onRemove(each.stateName, index, periodArray)} />
                                    </div>
                                )
                            })
                        }
                        <Button.ThemesDark TitleText={ButtonText.BUTTON_ADD_TRAVEL} disabled={Active ? true : false}
                            style={{ width: '100%' }} onClick={() => this.onAddMore(each.stateName, periodArray)} />

                    </div>
                )
            }
            case 'form-zone': {
                let pickUpZoneList = each.stateName === 'pickUpZoneList';
                let valueState = (IsEdit) ? path([each.stateName], oldPackageInfo) : path([each.stateName], packageInfo);

                // const { pickUpList: OldPickUpList } = oldPackageInfo;
                // if (IsEdit) {
                //     if (OldPickUpList.length > 0 && valueState.length === 0)
                //         valueState = OldPickUpList.map((id) => ({ appointmentId: id, pickUpTime: { startDate: '', endDate: '', }, extraCharge: 0, }))
                // }

                return (
                    <React.Fragment>
                        {pickUpZoneList && !isPickUp ? null :
                            <FormTypes.FormPickUpZone
                                error={isError}
                                disabled={Active ? true : false}
                                Require={(Active) ? false : each.require}
                                onChange={(fieldName, value) => { this.onChangeState(fieldName, value) }}
                                value={valueState}
                                TitleText={each.title_EN}
                                InfoList={(IsEdit) ? oldPackageInfo.pickUpList : packageInfo.pickUpList}
                                AppointmentList={AppointmentList}
                            />}
                    </React.Fragment>
                )
            }
        }

    }

    setTypes(Active) {
        const { IsEdit } = this.props;
        const { packageInfo, oldPackageInfo } = this.state;
        let isError = this.error;
        switch (packageInfo.type || oldPackageInfo.type) {
            case "Transfer": {
                return (
                    <FormTypes.FormAdditional
                        disabled={Active ? true : false}
                        Active={Active}
                        error={isError}
                        FormLayout={Package.TransferData}
                        onChange={(fieldName, value) => { this.onChangeState(fieldName, value) }}
                        StateInfo={IsEdit ? oldPackageInfo : packageInfo} />
                )
            }
            case "Service": {
                return (
                    < FormTypes.FormAdditional
                        disabled={Active ? true : false}
                        Active={Active}
                        error={isError}
                        FormLayout={Package.TypeServiceData}
                        onChange={(fieldName, value) => { this.onChangeState(fieldName, value) }}
                        StateInfo={IsEdit ? oldPackageInfo : packageInfo} />
                )
            }
            case "Ferries": {
                return (
                    <FormTypes.FormAdditional
                        disabled={Active ? true : false}
                        Active={Active}
                        error={isError}
                        FormLayout={Package.FerriesData}
                        onChange={(fieldName, value) => { this.onChangeState(fieldName, value) }}
                        StateInfo={(IsEdit) ? oldPackageInfo : packageInfo}
                    />
                )
            }
        }
    }

    handleToggle = (fieldName, state, index) => () => {

        // console.log('handleToggle', fieldName, state, index);

        const { IsEdit } = this.props;
        const { oldPackageInfo, isUpdateAPI } = this.state;
        let packageInfo = (isUpdateAPI || IsEdit) ? oldPackageInfo : { ...this.state.packageInfo }
        // console.log('packageInfo', packageInfo)
        switch (fieldName) {

            case "condition": {
                const newIcon = R.clone(packageInfo.icon);
                newIcon[0][state.stateName] = !newIcon[0][state.stateName];
                this.setState({ isBtnActive: true }, () => this.onChangeState('icon', newIcon));
            } break;

            case "supplier": {
                const _supplierId = this.state.supplierId === state.supplierId ? null : state.supplierId;
                this.setState({ isBtnActive: true }, () => this.onChangeState('supplierId', _supplierId));
            } break;

            case "pickUpList": {
                const { pickUpList } = packageInfo;
                let pickUpId = [...pickUpList];
                let pickUpZoneList = [...packageInfo.pickUpZoneList];

                const currentIndex = pickUpId.indexOf(state.appointmentId);

                if (currentIndex === -1) {
                    pickUpId.push(state.appointmentId);
                    pickUpZoneList.push({
                        appointmentId: state.appointmentId,
                        pickUpTime: {
                            startDate: '',
                            endDate: '',
                        },
                        extraCharge: 0,
                    })

                    // console.log('pickUpZoneList : ', pickUpZoneList)
                }
                else {

                    pickUpId.splice(currentIndex, 1);
                    // console.log('pickUpZoneList : else', pickUpZoneList)
                    pickUpZoneList = pickUpZoneList.filter((item) => item.appointmentId !== state.appointmentId);
                }
                let result = {
                    pickUpZoneList: pickUpZoneList,
                    pickUpId: pickUpId
                }
                // console.log('pickUpZoneList :', pickUpZoneList);
                this.setState({ isBtnActive: true }, () => this.onChangeState('pickUpList', result));
            } break;

            case "dropOffList": {
                const { dropOffList } = packageInfo;
                let dropOffId = [...dropOffList];

                const currentIndex = dropOffId.indexOf(state.appointmentId);

                if (currentIndex === -1) {
                    dropOffId.push(state.appointmentId);
                }
                else { dropOffId.splice(currentIndex, 1); }
                this.setState({ isBtnActive: true }, () => this.onChangeState('dropOffList', dropOffId));
            } break;
        }
    }

    GetSupplierId = () => (<SupplierCard
        _supplierId={this.state.packageInfo.supplierId}
        onAcceptonClosePanel={this.onClosePanel}
        SupplierList={this.props.SupplierList}
        handleToggle={this.handleToggle} />)

    GetPickUpList = () => (<AppointmentCard
        LocationServicesList={path(['datas'], this.props.LocationServicesList)}
        LanguagePackage={LanguagePackage}
        onAcceptonClosePanel={this.onClosePanel}
        packageInfo={(this.props.IsEdit) ? this.state.oldPackageInfo : this.state.packageInfo}
        handleToggle={this.handleToggle}
        AppointmentList={this.props.AppointmentList}
    />)
    GetDropOffList = () => (<AppointmentCard
        DropOff={true}
        LocationServicesList={path(['datas'], this.props.LocationServicesList)}
        LanguagePackage={LanguagePackage}
        onAcceptonClosePanel={this.onClosePanel}
        packageInfo={(this.props.IsEdit) ? this.state.oldPackageInfo : this.state.packageInfo}
        handleToggle={this.handleToggle}
        AppointmentList={this.props.AppointmentList}
    />)
    onAddMore = (fieldName, textArray) => {
        // console.log('fieldName', fieldName, textArray)
        const { oldPackageInfo, } = this.state;
        const { IsEdit } = this.props;
        let packageInfo = (IsEdit) ? oldPackageInfo : { ...this.state.packageInfo }
        let Ferries = (IsEdit) ? oldPackageInfo.type === 'Ferries' : packageInfo.type === 'Ferries'
        let Transfer = packageInfo.type === 'Transfer' || oldPackageInfo.type === 'Transfer';
        switch (fieldName) {
            case "cancelPolicy":
            case "pickUpPolicy":
            case "shouldKnow": {
                let checkValue = textArray.some(val => val === '')
                if (checkValue) {
                    this.setState({ isLoading: true, msgError: 'Please Select the form.', dialogType: 'error', isBtnActive: false })
                } else
                    packageInfo = { ...packageInfo, [fieldName]: textArray.concat("") }
            } break;
            case "serviceExclusion":
            case "service": {
                let checkValue = textArray.some(val => val.topic === '')
                if (checkValue) {
                    this.setState({ isLoading: true, msgError: 'Please Select the form.', dialogType: 'error', isBtnActive: false })
                } else
                    packageInfo = { ...packageInfo, [fieldName]: textArray.concat({ topic: '', detail: [] }) }
            } break;
            case "schedule": {
                let checkValue = textArray.some(val => val.time === '')
                if (checkValue) {
                    this.setState({ isLoading: true, msgError: 'Please Select the form.', dialogType: 'error', isBtnActive: false })
                } else
                    packageInfo = { ...packageInfo, [fieldName]: textArray.concat({ time: Ferries ? moment('07:00', 'hh:mm') : '', topic: '', detail: [], appointmentId: '', destinationId: '' }) }
            } break;
            case "periodInfo": {
                let checkValue = textArray.some(val => val.startDate === '' || val.endDate === '' || val.serviceDate.length === 0 ||
                    (val.marginAdult || val.marginKid) < "30" || val.netPrice.adult === ('' || 0) || val.netPrice.kid === (Transfer ? '' : ('' || 0)))
                if (checkValue) {
                    this.setState({ isLoading: true, msgError: 'Please complete the form and Margin need more 30 percent.', dialogType: 'error', isBtnActive: false })
                } else
                    packageInfo = {
                        ...packageInfo, [fieldName]: textArray.concat({
                            startDate: "",
                            endDate: "",
                            serviceDate: [],
                            price: { adult: 0, kid: 0, infant: 0, currency: "THB" },
                            netPrice: { adult: 0, kid: 0, infant: 0, currency: "THB" },
                            margin: 0,
                            marginAdult: 30,
                            marginKid: 30,
                            marginInfant: 0,
                            isCloseListService: false,
                            closeListService: []
                        })
                    }
            } break;

        }
        // console.log('packageInfo success', packageInfo)
        this.setState({ packageInfo, oldPackageInfo: packageInfo, isBtnActive: true })
    }

    onRemove = (fieldName, index, textArray) => {

        const { oldPackageInfo } = this.state;
        const { IsEdit } = this.props;
        let packageInfo = (IsEdit) ? oldPackageInfo : { ...this.state.packageInfo }

        switch (fieldName) {
            default: {
                packageInfo = { ...packageInfo, [fieldName]: remove(index, 1, textArray) }
            }
        }
        this.setState({ packageInfo, oldPackageInfo: packageInfo, isBtnActive: true })
    }

    onChangeState = (fieldName, state, value, index) => {

        // console.log('[onChangeState]', fieldName, ':', state, ':', value, ':', index);

        const { IsEdit } = this.props;
        // const { oldPackageInfo, packageInfo: nowPackageInfo } = this.state;

        let packageInfo = (IsEdit) ? { ...this.state.oldPackageInfo } : { ...this.state.packageInfo }

        switch (fieldName) {
            case "type":
                {
                    if (state === 'Ferries') {
                        packageInfo = {
                            ...packageInfo, [fieldName]: state,
                            dropOffList: [], pickUpList: [], isPickUp: false, isDropOff: false,
                            bags: "0", passengers: "", conveyanceType: "ferries", transferIn: "", transferType: "join",//transferType
                            serviceType: "", limit: "", schedule: [{ appointmentId: "", destinationId: "", detail: [], time: moment('07:00', 'hh:mm'), topic: "" }]
                        }
                    } else {
                        packageInfo = {
                            ...packageInfo, [fieldName]: state,
                            dropOffList: [], pickUpList: [], isPickUp: false, isDropOff: false,
                            bags: "", passengers: "", conveyanceType: "", transferIn: "", transferType: "",//transferType
                            serviceType: "",
                            schedule: [{ appointmentId: "", destinationId: "", detail: [], time: "", topic: "" }]
                        }
                    }
                } break;

            case "start":
            case "unit":
            case "end":
                {
                    packageInfo = { ...packageInfo, scheduleTime: { ...packageInfo.scheduleTime, [fieldName]: state } }
                } break;

            case "startDate":
            case "endDate":
                {
                    packageInfo = {
                        ...packageInfo, periodInfo: [{
                            ...packageInfo.periodInfo,
                            [fieldName]: update(index, { ...state[index], startDate: value.startDate, endDate: value.endDate }, state)
                        }]
                    }
                } break;

            case "cancelPolicy":
            case "pickUpPolicy":
            case "shouldKnow":
                {
                    packageInfo = { ...packageInfo, [fieldName]: update(index, value, state) }
                } break;

            case "serviceExclusion":
            case "service":
                {
                    packageInfo = { ...packageInfo, [fieldName]: update(index, { ...state[index], topic: value, detail: [] }, state) }
                } break;

            case "schedule":
                {
                    packageInfo = { ...packageInfo, [fieldName]: update(index, { ...state[index], time: value.time, topic: value.topic, detail: value.detail, appointmentId: value.appointmentId, destinationId: value.destinationId }, state) }
                } break;

            case "periodInfo":
                {
                    //Check Now
                    const { startDate, endDate, price, netPrice, serviceDate, marginAdult, marginKid, isCloseListService, closeListService } = value;

                    packageInfo = {
                        ...packageInfo, [fieldName]: update(index, {
                            ...state[index], startDate: startDate, endDate: endDate, price: price, netPrice: netPrice, serviceDate: serviceDate,
                            marginAdult: marginAdult, marginKid: marginKid, isCloseListService: isCloseListService, closeListService: closeListService
                        }, state)
                    }

                } break;

            case "transferType":
                {
                    packageInfo = {
                        ...packageInfo, [fieldName]: state,
                        infant_normal: 0, adult_normal: '', kid_normal: '', //price
                    }
                } break;

            case "cutOffTime":
                {
                    if (packageInfo.beforeRefund === '') {
                        packageInfo = { ...packageInfo, beforeRefund: state, [fieldName]: state }
                    } else {
                        packageInfo = { ...packageInfo, [fieldName]: state }
                    }
                } break;

            case 'pickUpList':
                {

                    if (state.pickUpId) {
                        packageInfo = { ...packageInfo, [fieldName]: state.pickUpId, pickUpZoneList: state.pickUpZoneList }
                    }
                    else {
                        packageInfo = {
                            ...packageInfo, [fieldName]: state,
                            pickUpZoneList: { appointmentId: state, pickUpTime: { startDate: '', endDate: '', }, extraCharge: 0 }
                        }
                    }
                } break;

            default:
                {
                    packageInfo = { ...packageInfo, [fieldName]: state }
                } break;
        }

        // console.log('PackageInfo success', packageInfo)

        this.setState({ packageInfo, oldPackageInfo: packageInfo, isBtnActive: true }, () => this.validate({ [fieldName]: state }));

    }

    validate = (state) => {

        let result = true;
        const fieldName = keys(state)[0];

        const { constraints } = validator;

        if (!!constraints[fieldName]) {

            const validateResult = validate(state, { [fieldName]: constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            }
            else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {

        let success = true;
        const { IsEdit } = this.props;
        const { isUpdateAPI } = this.state;
        const { packageInfo, oldPackageInfo } = this.state;

        let Transfer = packageInfo.type === 'Transfer' || oldPackageInfo.type === 'Transfer';
        let Service = packageInfo.type === 'Service' || oldPackageInfo.type === 'Service';
        let Ferries = packageInfo.conveyanceType === 'ferries' || oldPackageInfo.conveyanceType === 'ferries';

        let inputValidator =
            Transfer ? validator.inputTransfer :
                Ferries ? validator.inputFerries :
                    Service ? validator.inputService : validator.input
        inputValidator.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: (isUpdateAPI || IsEdit) ?
                    oldPackageInfo[each.fieldName] || oldPackageInfo.scheduleTime[each.fieldName] || oldPackageInfo.periodInfo[0][each.fieldName] :
                    packageInfo[each.fieldName] || packageInfo.scheduleTime[each.fieldName] || packageInfo.periodInfo[0][each.fieldName],
            });;
            success = success && validateResult;
        });

        return success;
    }

    onSave = () => {

        const { packageInfo, isUpdateAPI } = this.state;
        const { supplierId, conveyanceType, type } = this.state.packageInfo;
        const { imageGallery } = { ...packageInfo };

        let ferries = conveyanceType === 'ferries';
        let Transfer = type === 'Transfer';

        let validateCondition = !equals(packageInfo.icon, Package.StructureCondition)
        let validateSchedule = packageInfo.schedule.some(val => val.time === '')
        let validatePeriod = packageInfo.periodInfo.some(val => val.startDate === '' || val.endDate === '' || (val.marginAdult || val.marginKid) < 30 || val.serviceDate.length === 0 || val.netPrice.adult === ('' || 0) || val.netPrice.kid === (Transfer ? '' : ('' || 0)))
        packageInfo.periodInfo.map(each => {
            each.marginAdult = parseFloat(each.marginAdult);
            each.marginKid = parseFloat(each.marginKid);
        });

        console.log('New PackageInfo', packageInfo);
        console.log('Error', this.validateAll(), this.error);

        if (supplierId && this.validateAll()) {
            if (!validatePeriod) {
                if (validateCondition) {
                    if (imageGallery.length > 3) {
                        if (ferries) {
                            if (!validateSchedule) {
                                isUpdateAPI ? this.props.updateAPIPackage(packageInfo, supplierId) :
                                    this.setState({ isUpdateAPI: true }, () => this.props.saveAPIPackage(packageInfo, supplierId))
                            } else this.setState({ isLoading: true, msgError: 'Please Select time in the form (Tour Program). ', dialogType: 'error', isBtnActive: false })
                        } else {
                            isUpdateAPI ? this.props.updateAPIPackage(packageInfo, supplierId) :
                                this.setState({ isUpdateAPI: true }, () => this.props.saveAPIPackage(packageInfo, supplierId))
                        }
                    } else this.setState({ isLoading: true, msgError: 'Please Select Image Covero at least 4 photo.', dialogType: 'error' })
                } else this.setState({ isLoading: true, msgError: 'Please Select Condition.', dialogType: 'error', isBtnActive: false })
            } else this.setState({ isLoading: true, msgError: 'Please complete the form (Travel Time) and Margin need more 30 percent.', dialogType: 'error', isBtnActive: false })
        } else this.setState({ isLoading: true, msgError: 'Please Select Supplier and complete the form.', dialogType: 'error', isBtnActive: false })
    }

    onUpdate = () => {
        const { oldPackageInfo } = this.state;
        const { conveyanceType, type } = this.state.oldPackageInfo;
        const { defaultFiles } = this.imageGallery ? this.imageGallery.state.length === 0 ? [] : this.imageGallery.state : [];
        let Transfer = type === 'Transfer';
        this.imageOther = this.imageOther.concat(this.imageGallery.files, defaultFiles)
        let validateCondition = !equals(oldPackageInfo.icon, Package.StructureCondition)
        let newImage = this.imageGallery.files;
        let ferries = conveyanceType === 'ferries';
        let validatePeriod = oldPackageInfo.periodInfo.some(val => val.startDate === '' || val.endDate === '' || (val.marginAdult || val.marginKid) < 30 || val.serviceDate.length === 0 || val.netPrice.adult === ('' || 0) || val.netPrice.kid === (Transfer ? '' : ('' || 0)))
        let validateSchedule = oldPackageInfo.schedule.some(val => val.time === '')
        oldPackageInfo.periodInfo.map(each => {
            each.marginAdult = parseFloat(each.marginAdult);
            each.marginKid = parseFloat(each.marginKid);
        })
        // console.log('New packageInfo', oldPackageInfo)
        // console.log('Error', this.validateAll(), this.error);

        if (this.validateAll()) {
            if (!validatePeriod) {
                if (validateCondition) {
                    if (ferries) {
                        if (!validateSchedule) {
                            if (this.imageOther.length > 3) {
                                this.props.updateAPIPackage(oldPackageInfo, defaultFiles, newImage)
                            } else this.setState({ isLoading: true, msgError: 'Please Select Image Covero at least 4 photo.', dialogType: 'error', isBtnActive: false })
                        } else this.setState({ isLoading: true, msgError: 'Please Select time in the form (Tour Program)', dialogType: 'error', isBtnActive: false })
                    } else {
                        if (this.imageOther.length > 3) {
                            this.props.updateAPIPackage(oldPackageInfo, defaultFiles, newImage)
                        } else this.setState({ isLoading: true, msgError: 'Please Select Image Covero at least 4 photo.', dialogType: 'error', isBtnActive: false })
                    }
                } else this.setState({ isLoading: true, msgError: 'Please Select Condition.', dialogType: 'error', isBtnActive: false })
            } else this.setState({ isLoading: true, msgError: 'Please complete the form (Travel Time) and Margin need more 30 percent.', dialogType: 'error', isBtnActive: false })
        } else this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false })
    }

    onCancle = () => {
        const { packageInfo } = this.state;
        const { isTransfer } = this.props;
        let supplierInfo = path(['supplierInfo'], this.props) ? path(['supplierInfo'], this.props) : path(['location', 'state', 'supplierInfo'], this.props)
        this.props.history.push({
            pathname: !!(supplierInfo) ? PathName.SupplierInfo : isTransfer ? PathName.TransferManage : PathName.PackageManage, state: { packageInfo, supplierInfo }, search: '', hash: ''
        })
    }
}

const styles = theme => (
    {
        card: { backgroundColor: Colors.white, margin: '24px 0px', padding: '20px', textAlign: 'start', marginTop: '0px' },
        typography: { textAlign: 'start', fontSize: 24, fontWeight: 'bold', color: Colors.theme, margin: '24px 0px' },
        title: { fontSize: 18, fontWeight: 'bold', color: Colors.theme, fontFamily: Family.roboto },
        row: { display: 'flex', },
        each: { maxWidth: '980px', width: '100%', display: 'flex' },
    }
)
PackageCard.propTypes = { classes: PropTypes.object.isRequired, };
export default withRouter(withStyles(styles)(PackageCard))

