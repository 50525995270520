export const countryCode = [
    {
        label: "Afghanistan (+93)",
        id: "+93",
        code: "AF"
    },
    {
        label: "Aland Islands (+358)",
        id: "+358",
        code: "AX"
    },
    {
        label: "Albania (+355)",
        id: "+355",
        code: "AL"
    },
    {
        label: "Algeria (+213)",
        id: "+213",
        code: "DZ"
    },
    {
        label: "AmericanSamoa (+1 684)",
        id: "+1 684",
        code: "AS"
    },
    {
        label: "Andorra (+376)",
        id: "+376",
        code: "AD"
    },
    {
        label: "Angola (+244)",
        id: "+244",
        code: "AO"
    },
    {
        label: "Anguilla (+1 264)",
        id: "+1 264",
        code: "AI"
    },
    {
        label: "Antarctica (+672)",
        id: "+672",
        code: "AQ"
    },
    {
        label: "Antigua and Barbuda (+1268)",
        id: "+1268",
        code: "AG"
    },
    {
        label: "Argentina (+54)",
        id: "+54",
        code: "AR"
    },
    {
        label: "Armenia (+374)",
        id: "+374",
        code: "AM"
    },
    {
        label: "Aruba (+297)",
        id: "+297",
        code: "AW"
    },
    {
        label: "Australia (+61)",
        id: "+61",
        code: "AU"
    },
    {
        label: "Austria (+43)",
        id: "+43",
        code: "AT"
    },
    {
        label: "Azerbaijan (+994)",
        id: "+994",
        code: "AZ"
    },
    {
        label: "Bahamas (+1 242)",
        id: "+1 242",
        code: "BS"
    },
    {
        label: "Bahrain (+973)",
        id: "+973",
        code: "BH"
    },
    {
        label: "Bangladesh (+880)",
        id: "+880",
        code: "BD"
    },
    {
        label: "Barbados (+1 246)",
        id: "+1 246",
        code: "BB"
    },
    {
        label: "Belarus (+375)",
        id: "+375",
        code: "BY"
    },
    {
        label: "Belgium (+32)",
        id: "+32",
        code: "BE"
    },
    {
        label: "Belize (+501)",
        id: "+501",
        code: "BZ"
    },
    {
        label: "Benin (+229)",
        id: "+229",
        code: "BJ"
    },
    {
        label: "Bermuda (+1 441)",
        id: "+1 441",
        code: "BM"
    },
    {
        label: "Bhutan (+975)",
        id: "+975",
        code: "BT"
    },
    {
        label: "Bolivia, Plurinational State of (+591)",
        id: "+591",
        code: "BO"
    },
    {
        label: "Bosnia and Herzegovina (+387)",
        id: "+387",
        code: "BA"
    },
    {
        label: "Botswana (+267)",
        id: "+267",
        code: "BW"
    },
    {
        label: "Brazil (+55)",
        id: "+55",
        code: "BR"
    },
    {
        label: "British Indian Ocean Territory (+246)",
        id: "+246",
        code: "IO"
    },
    {
        label: "Brunei Darussalam (+673)",
        id: "+673",
        code: "BN"
    },
    {
        label: "Bulgaria (+359)",
        id: "+359",
        code: "BG"
    },
    {
        label: "Burkina Faso (+226)",
        id: "+226",
        code: "BF"
    },
    {
        label: "Burundi (+257)",
        id: "+257",
        code: "BI"
    },
    {
        label: "Cambodia (+855)",
        id: "+855",
        code: "KH"
    },
    {
        label: "Cameroon (+237)",
        id: "+237",
        code: "CM"
    },
    {
        label: "Canada (+1)",
        id: "+1",
        code: "CA"
    },
    {
        label: "Cape Verde (+238)",
        id: "+238",
        code: "CV"
    },
    {
        label: "Cayman Islands (+345)",
        id: "+345",
        code: "KY"
    },
    {
        label: "Central African Republic (+236)",
        id: "+236",
        code: "CF"
    },
    {
        label: "Chad (+235)",
        id: "+235",
        code: "TD"
    },
    {
        label: "Chile (+56)",
        id: "+56",
        code: "CL"
    },
    {
        label: "China (+86)",
        id: "+86",
        code: "CN"
    },
    {
        label: "Christmas Island (+61)",
        id: "+61",
        code: "CX"
    },
    {
        label: "Cocos (Keeling) Islands (+61)",
        id: "+61",
        code: "CC"
    },
    {
        label: "Colombia (+57)",
        id: "+57",
        code: "CO"
    },
    {
        label: "Comoros (+269)",
        id: "+269",
        code: "KM"
    },
    {
        label: "Congo (+242)",
        id: "+242",
        code: "CG"
    },
    {
        label: "Congo, The Democratic Republic of the Congo (+243)",
        id: "+243",
        code: "CD"
    },
    {
        label: "Cook Islands (+682)",
        id: "+682",
        code: "CK"
    },
    {
        label: "Costa Rica (+506)",
        id: "+506",
        code: "CR"
    },
    {
        label: "Cote d'Ivoire (+225)",
        id: "+225",
        code: "CI"
    },
    {
        label: "Croatia (+385)",
        id: "+385",
        code: "HR"
    },
    {
        label: "Cuba (+53)",
        id: "+53",
        code: "CU"
    },
    {
        label: "Cyprus (+357)",
        id: "+357",
        code: "CY"
    },
    {
        label: "Czech Republic (+420)",
        id: "+420",
        code: "CZ"
    },
    {
        label: "Denmark (+45)",
        id: "+45",
        code: "DK"
    },
    {
        label: "Djibouti (+253)",
        id: "+253",
        code: "DJ"
    },
    {
        label: "Dominica (+1 767)",
        id: "+1 767",
        code: "DM"
    },
    {
        label: "Dominican Republic (+1 849)",
        id: "+1 849",
        code: "DO"
    },
    {
        label: "Ecuador (+593)",
        id: "+593",
        code: "EC"
    },
    {
        label: "Egypt (+20)",
        id: "+20",
        code: "EG"
    },
    {
        label: "El Salvador (+503)",
        id: "+503",
        code: "SV"
    },
    {
        label: "Equatorial Guinea (+240)",
        id: "+240",
        code: "GQ"
    },
    {
        label: "Eritrea (+291)",
        id: "+291",
        code: "ER"
    },
    {
        label: "Estonia (+372)",
        id: "+372",
        code: "EE"
    },
    {
        label: "Ethiopia (+251)",
        id: "+251",
        code: "ET"
    },
    {
        label: "Falkland Islands (Malvinas) (+500)",
        id: "+500",
        code: "FK"
    },
    {
        label: "Faroe Islands (+298)",
        id: "+298",
        code: "FO"
    },
    {
        label: "Fiji (+679)",
        id: "+679",
        code: "FJ"
    },
    {
        label: "Finland (+385)",
        id: "+358",
        code: "FI"
    },
    {
        label: "France (+33)",
        id: "+33",
        code: "FR"
    },
    {
        label: "French Guiana (+594)",
        id: "+594",
        code: "GF"
    },
    {
        label: "French Polynesia (+689)",
        id: "+689",
        code: "PF"
    },
    {
        label: "Gabon (+241)",
        id: "+241",
        code: "GA"
    },
    {
        label: "Gambia (+220)",
        id: "+220",
        code: "GM"
    },
    {
        label: "Georgia (+995)",
        id: "+995",
        code: "GE"
    },
    {
        label: "Germany (+49)",
        id: "+49",
        code: "DE"
    },
    {
        label: "Ghana (+233)",
        id: "+233",
        code: "GH"
    },
    {
        label: "Gibraltar (+350)",
        id: "+350",
        code: "GI"
    },
    {
        label: "Greece (+30)",
        id: "+30",
        code: "GR"
    },
    {
        label: "Greenland (+299)",
        id: "+299",
        code: "GL"
    },
    {
        label: "Grenada (+1 473)",
        id: "+1 473",
        code: "GD"
    },
    {
        label: "Guadeloupe (+590)",
        id: "+590",
        code: "GP"
    },
    {
        label: "Guam (+1 671)",
        id: "+1 671",
        code: "GU"
    },
    {
        label: "Guatemala (+502)",
        id: "+502",
        code: "GT"
    },
    {
        label: "Guernsey (+44)",
        id: "+44",
        code: "GG"
    },
    {
        label: "Guinea (+224)",
        id: "+224",
        code: "GN"
    },
    {
        label: "Guinea-Bissau (+245)",
        id: "+245",
        code: "GW"
    },
    {
        label: "Guyana (+595)",
        id: "+595",
        code: "GY"
    },
    {
        label: "Haiti (+509)",
        id: "+509",
        code: "HT"
    },
    {
        label: "Holy See (Vatican City State) (+379)",
        id: "+379",
        code: "VA"
    },
    {
        label: "Honduras (+504)",
        id: "+504",
        code: "HN"
    },
    {
        label: "Hong Kong (+852)",
        id: "+852",
        code: "HK"
    },
    {
        label: "Hungary (+36)",
        id: "+36",
        code: "HU"
    },
    {
        label: "Iceland (+354)",
        id: "+354",
        code: "IS"
    },
    {
        label: "India (+91)",
        id: "+91",
        code: "IN"
    },
    {
        label: "Indonesia (+62)",
        id: "+62",
        code: "ID"
    },
    {
        label: "Iran, Islamic Republic of Persian Gulf (+98)",
        id: "+98",
        code: "IR"
    },
    {
        label: "Iraq (+964)",
        id: "+964",
        code: "IQ"
    },
    {
        label: "Ireland (+353) (+353)",
        id: "+353",
        code: "IE"
    },
    {
        label: "Isle of Man (+44)",
        id: "+44",
        code: "IM"
    },
    {
        label: "Israel (+972)",
        id: "+972",
        code: "IL"
    },
    {
        label: "Italy (+39)",
        id: "+39",
        code: "IT"
    },
    {
        label: "Jamaica (+1 876)",
        id: "+1 876",
        code: "JM"
    },
    {
        label: "Japan (+81)",
        id: "+81",
        code: "JP"
    },
    {
        label: "Jersey (+44)",
        id: "+44",
        code: "JE"
    },
    {
        label: "Jordan (+962)",
        id: "+962",
        code: "JO"
    },
    {
        label: "Kazakhstan (+7 7)",
        id: "+7 7",
        code: "KZ"
    },
    {
        label: "Kenya (+254)",
        id: "+254",
        code: "KE"
    },
    {
        label: "Kiribati (+686)",
        id: "+686",
        code: "KI"
    },
    {
        label: "Korea, Democratic People's Republic of Korea (+850)",
        id: "+850",
        code: "KP"
    },
    {
        label: "Korea, Republic of South Korea (+82)",
        id: "+82",
        code: "KR"
    },
    {
        label: "Kuwait (+965)",
        id: "+965",
        code: "KW"
    },
    {
        label: "Kyrgyzstan (+996)",
        id: "+996",
        code: "KG"
    },
    {
        label: "Laos (+856)",
        id: "+856",
        code: "LA"
    },
    {
        label: "Latvia (+371)",
        id: "+371",
        code: "LV"
    },
    {
        label: "Lebanon (+961)",
        id: "+961",
        code: "LB"
    },
    {
        label: "Lesotho (+266)",
        id: "+266",
        code: "LS"
    },
    {
        label: "Liberia (+231)",
        id: "+231",
        code: "LR"
    },
    {
        label: "Libyan Arab Jamahiriya (+218)",
        id: "+218",
        code: "LY"
    },
    {
        label: "Liechtenstein (+423)",
        id: "+423",
        code: "LI"
    },
    {
        label: "Lithuania (+370)",
        id: "+370",
        code: "LT"
    },
    {
        label: "Luxembourg (+352)",
        id: "+352",
        code: "LU"
    },
    {
        label: "Macao (+853)",
        id: "+853",
        code: "MO"
    },
    {
        label: "Macedonia (+389)",
        id: "+389",
        code: "MK"
    },
    {
        label: "Madagascar (+261)",
        id: "+261",
        code: "MG"
    },
    {
        label: "Malawi (+265)",
        id: "+265",
        code: "MW"
    },
    {
        label: "Malaysia (+60)",
        id: "+60",
        code: "MY"
    },
    {
        label: "Maldives (+960)",
        id: "+960",
        code: "MV"
    },
    {
        label: "Mali (+223)",
        id: "+223",
        code: "ML"
    },
    {
        label: "Malta (+356)",
        id: "+356",
        code: "MT"
    },
    {
        label: "Marshall Islands (+692)",
        id: "+692",
        code: "MH"
    },
    {
        label: "Martinique (+596)",
        id: "+596",
        code: "MQ"
    },
    {
        label: "Mauritania (+222)",
        id: "+222",
        code: "MR"
    },
    {
        label: "Mauritius (+230)",
        id: "+230",
        code: "MU"
    },
    {
        label: "Mayotte (+262)",
        id: "+262",
        code: "YT"
    },
    {
        label: "Mexico (+52)",
        id: "+52",
        code: "MX"
    },
    {
        label: "Micronesia, Federated States of Micronesia (+691)",
        id: "+691",
        code: "FM"
    },
    {
        label: "Moldova (+373)",
        id: "+373",
        code: "MD"
    },
    {
        label: "Monaco (+377)",
        id: "+377",
        code: "MC"
    },
    {
        label: "Mongolia (+976)",
        id: "+976",
        code: "MN"
    },
    {
        label: "Montenegro (+382)",
        id: "+382",
        code: "ME"
    },
    {
        label: "Montserrat (+1664)",
        id: "+1664",
        code: "MS"
    },
    {
        label: "Morocco (+212)",
        id: "+212",
        code: "MA"
    },
    {
        label: "Mozambique (+258)",
        id: "+258",
        code: "MZ"
    },
    {
        label: "Myanmar (+95)",
        id: "+95",
        code: "MM"
    },
    {
        label: "Namibia (+264)",
        id: "+264",
        code: "NA"
    },
    {
        label: "Nauru (+674)",
        id: "+674",
        code: "NR"
    },
    {
        label: "Nepal (+977)",
        id: "+977",
        code: "NP"
    },
    {
        label: "Netherlands (+31)",
        id: "+31",
        code: "NL"
    },
    {
        label: "Netherlands Antilles (+599)",
        id: "+599",
        code: "AN"
    },
    {
        label: "New Caledonia (+687)",
        id: "+687",
        code: "NC"
    },
    {
        label: "New Zealand (+64)",
        id: "+64",
        code: "NZ"
    },
    {
        label: "Nicaragua (+505)",
        id: "+505",
        code: "NI"
    },
    {
        label: "Niger (+227)",
        id: "+227",
        code: "NE"
    },
    {
        label: "Nigeria (+234)",
        id: "+234",
        code: "NG"
    },
    {
        label: "Niue (+683)",
        id: "+683",
        code: "NU"
    },
    {
        label: "Norfolk Island (+672)",
        id: "+672",
        code: "NF"
    },
    {
        label: "Northern Mariana Islands (+1 670)",
        id: "+1 670",
        code: "MP"
    },
    {
        label: "Norway (+47)",
        id: "+47",
        code: "NO"
    },
    {
        label: "Oman (+968)",
        id: "+968",
        code: "OM"
    },
    {
        label: "Pakistan (+92)",
        id: "+92",
        code: "PK"
    },
    {
        label: "Palau (+680)",
        id: "+680",
        code: "PW"
    },
    {
        label: "Palestinian Territory, Occupied (+970)",
        id: "+970",
        code: "PS"
    },
    {
        label: "Panama (+507)",
        id: "+507",
        code: "PA"
    },
    {
        label: "Papua New Guinea (+675)",
        id: "+675",
        code: "PG"
    },
    {
        label: "Paraguay (+595)",
        id: "+595",
        code: "PY"
    },
    {
        label: "Peru (+51)",
        id: "+51",
        code: "PE"
    },
    {
        label: "Philippines (+63)",
        id: "+63",
        code: "PH"
    },
    {
        label: "Pitcairn (+872)",
        id: "+872",
        code: "PN"
    },
    {
        label: "Poland (+48)",
        id: "+48",
        code: "PL"
    },
    {
        label: "Portugal (+351)",
        id: "+351",
        code: "PT"
    },
    {
        label: "Puerto Rico (+1 939)",
        id: "+1 939",
        code: "PR"
    },
    {
        label: "Qatar (+974)",
        id: "+974",
        code: "QA"
    },
    {
        label: "Romania (+40)",
        id: "+40",
        code: "RO"
    },
    {
        label: "Russia (+7)",
        id: "+7",
        code: "RU"
    },
    {
        label: "Rwanda (+250)",
        id: "+250",
        code: "RW"
    },
    {
        label: "Reunion (+262)",
        id: "+262",
        code: "RE"
    },
    {
        label: "Saint Barthelemy (+590)",
        id: "+590",
        code: "BL"
    },
    {
        label: "Saint Helena, Ascension and Tristan Da Cunha (+290)",
        id: "+290",
        code: "SH"
    },
    {
        label: "Saint Kitts and Nevis (+1 869)",
        id: "+1 869",
        code: "KN"
    },
    {
        label: "Saint Lucia (+1 758)",
        id: "+1 758",
        code: "LC"
    },
    {
        label: "Saint Martin (+590)",
        id: "+590",
        code: "MF"
    },
    {
        label: "Saint Pierre and Miquelon (+508)",
        id: "+508",
        code: "PM"
    },
    {
        label: "Saint Vincent and the Grenadines (+1 784)",
        id: "+1 784",
        code: "VC"
    },
    {
        label: "Samoa (+685)",
        id: "+685",
        code: "WS"
    },
    {
        label: "San Marino (+378)",
        id: "+378",
        code: "SM"
    },
    {
        label: "Sao Tome and Principe (+239)",
        id: "+239",
        code: "ST"
    },
    {
        label: "Saudi Arabia (+966)",
        id: "+966",
        code: "SA"
    },
    {
        label: "Senegal (+221)",
        id: "+221",
        code: "SN"
    },
    {
        label: "Serbia (+381)",
        id: "+381",
        code: "RS"
    },
    {
        label: "Seychelles (+248)",
        id: "+248",
        code: "SC"
    },
    {
        label: "Sierra Leone (+232)",
        id: "+232",
        code: "SL"
    },
    {
        label: "Singapore (+65)",
        id: "+65",
        code: "SG"
    },
    {
        label: "Slovakia (+421)",
        id: "+421",
        code: "SK"
    },
    {
        label: "Slovenia (+386)",
        id: "+386",
        code: "SI"
    },
    {
        label: "Solomon Islands (+677)",
        id: "+677",
        code: "SB"
    },
    {
        label: "Somalia (+252)",
        id: "+252",
        code: "SO"
    },
    {
        label: "South Africa (+27)",
        id: "+27",
        code: "ZA"
    },
    {
        label: "South Georgia and the South Sandwich Islands (+500)",
        id: "+500",
        code: "GS"
    },
    {
        label: "Spain (+34)",
        id: "+34",
        code: "ES"
    },
    {
        label: "Sri Lanka (+94)",
        id: "+94",
        code: "LK"
    },
    {
        label: "Sudan (+249)",
        id: "+249",
        code: "SD"
    },
    {
        label: "Suriname (+597)",
        id: "+597",
        code: "SR"
    },
    {
        label: "Svalbard and Jan Mayen (+47)",
        id: "+47",
        code: "SJ"
    },
    {
        label: "Swaziland (+268)",
        id: "+268",
        code: "SZ"
    },
    {
        label: "Sweden (+46)",
        id: "+46",
        code: "SE"
    },
    {
        label: "Switzerland (+41)",
        id: "+41",
        code: "CH"
    },
    {
        label: "Syrian Arab Republic (+963)",
        id: "+963",
        code: "SY"
    },
    {
        label: "Taiwan (+886)",
        id: "+886",
        code: "TW"
    },
    {
        label: "Tajikistan (+992)",
        id: "+992",
        code: "TJ"
    },
    {
        label: "Tanzania, United Republic of Tanzania (+255)",
        id: "+255",
        code: "TZ"
    },
    {
        label: "Thailand (+66)",
        id: "+66",
        code: "TH"
    },
    {
        label: "Timor-Leste (+670)",
        id: "+670",
        code: "TL"
    },
    {
        label: "Togo (+228)",
        id: "+228",
        code: "TG"
    },
    {
        label: "Tokelau (+690)",
        id: "+690",
        code: "TK"
    },
    {
        label: "Tonga (+676)",
        id: "+676",
        code: "TO"
    },
    {
        label: "Trinidad and Tobago (+1 868)",
        id: "+1 868",
        code: "TT"
    },
    {
        label: "Tunisia (+216)",
        id: "+216",
        code: "TN"
    },
    {
        label: "Turkey (+90)",
        id: "+90",
        code: "TR"
    },
    {
        label: "Turkmenistan (+993)",
        id: "+993",
        code: "TM"
    },
    {
        label: "Turks and Caicos Islands (+1 649)",
        id: "+1 649",
        code: "TC"
    },
    {
        label: "Tuvalu (+688)",
        id: "+688",
        code: "TV"
    },
    {
        label: "Uganda (+256)",
        id: "+256",
        code: "UG"
    },
    {
        label: "Ukraine (+380)",
        id: "+380",
        code: "UA"
    },
    {
        label: "United Arab Emirates (+971)",
        id: "+971",
        code: "AE"
    },
    {
        label: "United Kingdom (+44)",
        id: "+44",
        code: "GB"
    },
    {
        label: "United States (+1)",
        id: "+1",
        code: "US"
    },
    {
        label: "Uruguay (+598)",
        id: "+598",
        code: "UY"
    },
    {
        label: "Uzbekistan (+998)",
        id: "+998",
        code: "UZ"
    },
    {
        label: "Vanuatu (+678)",
        id: "+678",
        code: "VU"
    },
    {
        label: "Venezuela, Bolivarian Republic of Venezuela (+58)",
        id: "+58",
        code: "VE"
    },
    {
        label: "Vietnam (+84)",
        id: "+84",
        code: "VN"
    },
    {
        label: "Virgin Islands, British (+1 284)",
        id: "+1 284",
        code: "VG"
    },
    {
        label: "Virgin Islands, U.S. (+1 340)",
        id: "+1 340",
        code: "VI"
    },
    {
        label: "Wallis and Futuna (+681)",
        id: "+681",
        code: "WF"
    },
    {
        label: "Yemen (+967)",
        id: "+967",
        code: "YE"
    },
    {
        label: "Zambia (+260)",
        id: "+260",
        code: "ZM"
    },
    {
        label: "Zimbabwe (+263)",
        id: "+263",
        code: "ZW"
    }
]