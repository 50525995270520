export const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: 'error require Name',
        }
    },

};
export const input = [
    { fieldName: 'name', },
];