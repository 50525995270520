import axios from 'axios';
import CONFIG from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_PACKAGE_LISTS_SUCCESS = 'GET_PACKAGE_LISTS_SUCCESS';
export const GET_PACKAGE_LISTS_FAILURE = 'GET_PACKAGE_LISTS_FAILURE';

export const GET_PACKAGE_COUNT_SUCCESS = 'GET_PACKAGE_COUNT_SUCCESS';
export const GET_PACKAGE_COUNT_FAILURE = 'GET_PACKAGE_COUNT_FAILURE';

export const GET_PACKAGE_COUNT_SUPPLIER_SUCCESS = 'GET_PACKAGE_COUNT_SUPPLIER_SUCCESS';
export const GET_PACKAGE_COUNT_SUPPLIER_FAILURE = 'GET_PACKAGE_COUNT_SUPPLIER_FAILURE';

export const GET_PACKAGE_SUCCESS = 'GET_PACKAGE_SUCCESS';
export const GET_PACKAGE_FAILURE = 'GET_PACKAGE_FAILURE';

export const CREATE_PACKAGE_SUCCESS = 'CREATE_PACKAGE_SUCCESS';
export const CREATE_PACKAGE_FAILURE = 'CREATE_PACKAGE_FAILURE';

export const UPDATE_PACKAGE_SUCCESS = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILURE = 'UPDATE_PACKAGE_FAILURE';

export const GET_PACKAGE_CATEGORY_SUCCESS = 'GET_PACKAGE_CATEGORY_SUCCESS';
export const GET_PACKAGE_CATEGORY_FAILURE = 'GET_PACKAGE_CATEGORY_FAILURE';

export const CREATE_PACKAGE_CATEGORY_SUCCESS = 'CREATE_PACKAGE_CATEGORY_SUCCESS';
export const CREATE_PACKAGE_CATEGORY_FAILURE = 'CREATE_PACKAGE_CATEGORY_FAILURE';

export const UPDATE_PACKAGE_CATEGORY_SUCCESS = 'UPDATE_PACKAGE_CATEGORY_SUCCESS';
export const UPDATE_PACKAGE_CATEGORY_FAILURE = 'UPDATE_PACKAGE_CATEGORY_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

const _header = { 'Content-Type': 'application/json', Authorization: `Bearer ${CONFIG.EP_token}`, 'Cache-Control': ['no-cache', 'no-store', 'must-revalidate',] }

// getAPIPackage ======================================================================================================================

export function getAPIPackage(_keyword = '', _fileterjson = {}) {

    return dispatch => {
        // console.log('getAPIPackage : ', _keyword);

        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getPackage}`,
            method: 'GET',
            headers: CONFIG.header,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getAPIPackage : ", response);
            dispatch(getAPIPackage_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getAPIPackage Failure", error);
            dispatch(getAPIPackage_Failure(error));
        })
    }
}

function getAPIPackage_Success(data) { return { type: GET_PACKAGE_SUCCESS, payload: data }; }

function getAPIPackage_Failure(error) { return { type: GET_PACKAGE_FAILURE, payload: error }; }

// getAPIPackageLists ======================================================================================================================

export function getAPIPackageLists(_keyword = '', _fileterjson = {}) {

    return dispatch => {

        // console.log('getAPIPackageLists : ', _keyword);

        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getPackageList}`,
            method: 'GET',
            headers: CONFIG.header,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getPackageList : ", response);
            dispatch(getAPIPackageList_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getPackageList Failure", error);
            dispatch(getAPIPackageList_Failure(error));
        })
    }
}

function getAPIPackageList_Success(data) { return { type: GET_PACKAGE_LISTS_SUCCESS, payload: data }; }

function getAPIPackageList_Failure(error) { return { type: GET_PACKAGE_LISTS_FAILURE, payload: error }; }

// getAPIPackageCount ======================================================================================================================

export function getAPIPackageCount(_keyword = '', _fileterjson = {}) {

    return dispatch => {

        // console.log('getAPIPackageCount : ', _keyword);

        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getPackageCount}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        // console.log('request : ', request);
        request.then((response) => {
            // console.log("[API] getAPIPackageCount : ", response);
            dispatch(getAPIPackageCount_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getAPIPackageCount Failure", error);
            dispatch(getAPIPackageCount_Failure(error));
        })
    }
}

function getAPIPackageCount_Success(data) { return { type: GET_PACKAGE_COUNT_SUCCESS, payload: data }; }

function getAPIPackageCount_Failure(error) { return { type: GET_PACKAGE_COUNT_FAILURE, payload: error }; }

// getAPIPackageCount ======================================================================================================================

export function getAPIPackageCountSupplier(_keyword = '', _fileterjson = {}) {

    return dispatch => {

        // console.log('getAPIPackageCountSupplier : ', _keyword);

        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getPackageCountSupplier}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        // console.log('request : ', request);
        request.then((response) => {
            // console.log("[API] getAPIPackageCountSupplier : ", response);
            dispatch(getAPIPackageCountSupplier_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getAPIPackageCountSupplier Failure", error);
            dispatch(getAPIPackageCountSupplier_Failure(error));
        })
    }
}

function getAPIPackageCountSupplier_Success(data) { return { type: GET_PACKAGE_COUNT_SUPPLIER_SUCCESS, payload: data }; }

function getAPIPackageCountSupplier_Failure(error) { return { type: GET_PACKAGE_COUNT_SUPPLIER_FAILURE, payload: error }; }

// getAPIPackageLists ===========================================================================================================

export function APICreatePackage(confirmInfo) {

    // console.log('APICreatePackage : ', confirmInfo)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.createPackage}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APICreatePackage : ", response.data);
            dispatch(APICreatePackage_Success(response.data));

        }).catch((error) => {
            console.log("APICreatePackage fail", error);
            dispatch(APICreatePackage_Failure(error));
        })
    }
}

function APICreatePackage_Success(data) { return { type: CREATE_PACKAGE_SUCCESS, payload: data }; }

function APICreatePackage_Failure(error) { return { type: CREATE_PACKAGE_FAILURE, payload: error }; }

// updatePackageList =========================================================================================================================

export function APIUpdatePackage(confirmInfo) {

    // console.log('APIUpdatePackage : ', confirmInfo)
    // console.log(`${CONFIG.host}${CONFIG.api.updatePackage}`)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.updatePackage}`,
            method: 'POST',
            headers: _header,
            // params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpdatePackage : ", response.data);
            dispatch(APIUpdatePackage_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdatePackage fail", error);
            dispatch(APIUpdatePackage_Failure(error));
        })
    }
}

function APIUpdatePackage_Success(data) { return { type: UPDATE_PACKAGE_SUCCESS, payload: data }; }

function APIUpdatePackage_Failure(error) { return { type: UPDATE_PACKAGE_FAILURE, payload: error }; }


// getPackageCategory =========================================================================================================================

export function getAPIPackageCategory(_keyword = '', _fileterjson = {}) {

    return dispatch => {

        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getPackageCategory}`,
            method: 'GET',
            headers: _header,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getAPIPackageCategory : ", response);
            dispatch(getAPIPackageCategory_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getAPIPackageCategory Failure", error);
            dispatch(getAPIPackageCategory_Failure(error));
        })
    }
}

function getAPIPackageCategory_Success(data) { return { type: GET_PACKAGE_CATEGORY_SUCCESS, payload: data }; }

function getAPIPackageCategory_Failure(error) { return { type: GET_PACKAGE_CATEGORY_FAILURE, payload: error }; }

// createPackageCategory =========================================================================================================================

export function APICreatePackageCategory(confirmInfo) {
    console.log('APICreatePackageCategory : ', confirmInfo)
    console.log(`${CONFIG.host}${CONFIG.api.createPackageCategory}`)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.createPackageCategory}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APICreatePackageCategory : ", response.data);
            dispatch(APICreatePackageCategory_Success(response.data));

        }).catch((error) => {
            console.log("APICreatePackageCategory fail", error);
            dispatch(APICreatePackageCategory_Failure(error));
        })
    }
}

function APICreatePackageCategory_Success(data) { return { type: CREATE_PACKAGE_CATEGORY_SUCCESS, payload: data }; }

function APICreatePackageCategory_Failure(error) { return { type: CREATE_PACKAGE_CATEGORY_FAILURE, payload: error }; }

// updatePackageCategory =========================================================================================================================

export function APIUpdatePackageCategory(confirmInfo) {

    // console.log('APIUpdatePackageCategory : ', confirmInfo)
    // console.log(`${CONFIG.host}${CONFIG.api.updatePackage}`)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.updatePackageCategory}`,
            method: 'POST',
            headers: _header,
            // params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpdatePackageCategory : ", response.data);
            dispatch(APIUpdatePackageCategory_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdatePackageCategory fail", error);
            dispatch(APIUpdatePackageCategory_Failure(error));
        })
    }
}

function APIUpdatePackageCategory_Success(data) { return { type: UPDATE_PACKAGE_CATEGORY_SUCCESS, payload: data }; }

function APIUpdatePackageCategory_Failure(error) { return { type: UPDATE_PACKAGE_CATEGORY_FAILURE, payload: error }; }
