

export function OmiseFee(totalPrice = 0) {

    return (totalPrice > 0) ? ((totalPrice * 3.65) / 100) : 0
}

export function OmiseVat(totalPrice = 0) {

    return (totalPrice > 0) ? ((totalPrice * 7) / 100) : 0
}

export function OmiseTotal(totalPrice = 0) {

    if (totalPrice > 0) {

        const _OmiseFee = OmiseFee(totalPrice);
        const _OmiseVat = OmiseVat(_OmiseFee);

        return (totalPrice - (_OmiseFee + _OmiseVat));
    }
    else
        return 0;
}

export function NetProfits(profits = 0, totalPrice = 0) {

    if (totalPrice > 0) {

        const _OmiseFee = OmiseFee(totalPrice);
        const _OmiseVat = OmiseVat(_OmiseFee);

        return (profits - (_OmiseFee + _OmiseVat));
    }
    else
        return 0;
}