import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';


import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';

import DialogPanel from '../../componets/DialogPanel.jsx';
import { SetDate } from '../../common/SetDate';
import { GetLevelWithID } from '../../common/GetMemberLevels';
import EmptyRows from '../../componets/EmptyRow.jsx';

import { Colors, Icons } from '../../themes';

import Avatar from '../../componets/Avatar.jsx';
import * as CustomerActions from '../../actions/Actions_CustomerManager.js';
import SearchPanel from '../../componets/SearchPanel.jsx';
const { BorderColorIcon } = Icons

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'firstName', isOrder: true, },
    { label: 'PHONE NUMBER', name: 'tel', isOrder: true, },
    { label: 'EMAIL', name: 'email', isOrder: true, },
    { label: 'REGISTER DATE', name: 'registerDate', isOrder: true, },
    { label: 'LEVEL', name: '', isOrder: false, },
    { label: 'REGISTER BY', name: 'type', isOrder: true, },
    { label: 'STATUS', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, },];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 75 };
const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', maxWidth: 300 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };

const _isBtnVisible = false;
const _wording = { header: 'Customer Manage' }

let CustomerList = [];
let waitingTime = false;

class CustomerListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: true }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {

        const { CustomerManager } = nextProps;

        if (!!CustomerManager) {

            const { actions, data } = CustomerManager.CUSTOMER_REDUCER;

            switch (actions) {
                case CustomerActions.WATING_STATE:
                    {
                        console.log('CustomerManager :')
                    }
                case CustomerActions.GET_CUSTOMER_SUCCESS:
                    {
                        // CustomerManager.CUSTOMER_REDUCER.actions = CustomerActions.WATING_STATE;
                        CustomerList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CustomerActions.GET_CUSTOMER_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, CreatePanel, DisplayCustomerInfo, EditInfo } = this.props;//onNextPage
        const { datas } = this.props.CustomerList;
        CustomerList = datas || [];
        const _isCustomer = (CustomerList.length > 0);

        //const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;
        // console.log('[ CustomerListPanel ] : ', this.props.CustomerList)
        const { isLoading, isCreate, msgError, dialogType, orderBy, searchActive } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={(_isBtnVisible) ? (fildeList.length - 1) : fildeList.length} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} onClick={() => this.setState({ searchActive: !searchActive })} />
                                    {(searchActive) && <SearchPanel searchActive={searchActive} SearchType='customer' />}
                                </TableCell>
                                {(_isBtnVisible) && ButtonCrate(this.props)}
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(_isCustomer) ? CustomerList.map(info => {
                                const { title, firstName, lastName, tel, email, isAcceptInformation, countryCode, type, active, avatarLink, memberLevelId, memberTypeId, registerDate, id, memberLevel } = info;
                                // console.log('info : ', info);
                                return (
                                    <TableRow key={id} hover style={{ cursor: 'pointer' }}  >
                                        <TableCell style={_callName} onClick={() => DisplayCustomerInfo(info)}><Avatar URL={avatarLink} /></TableCell>
                                        <TableCell style={_callName} onClick={() => DisplayCustomerInfo(info)}>{`${title || ''} ${firstName}  ${lastName}`}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => DisplayCustomerInfo(info)}>{`${(countryCode || '')}${tel}` || '-'}</TableCell>
                                        <TableCell style={{ ..._callStyle, minWidth: 200, textAlign: 'left', paddingLeft: 20 }} onClick={() => DisplayCustomerInfo(info)}>{email}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => DisplayCustomerInfo(info)}>{SetDate(registerDate)}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => DisplayCustomerInfo(info)}>{GetLevelWithID(memberLevelId) || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => DisplayCustomerInfo(info)}>{type}</TableCell>
                                        <TableCell style={{ ..._callStyle, color: active ? Colors.success : Colors.error }} onClick={() => DisplayCustomerInfo(info)}>{active ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell tyle={_callStyle}><Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(info) }} /></TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(_isCustomer) && GetPagination(this.props.CustomerList, this.onNextPage, this.onChangeLimit)}
                </Paper >
            </React.Fragment>
        );
    }

    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        //console.log('handleRequestSort : ', _fildeName, CustomerList)

        CustomerList = (orderBy === 'desc')
            ? CustomerList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : CustomerList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {

        this.setState({ isLoading: true });
        this.props.getAPICustomerList({ page: 0, num_limit: _limitRows });
    }

    onNextPage = (_pageNumber, _limit) => {

        this.setState({ isLoading: true });
        this.props.getAPICustomerList({ page: _pageNumber, num_limit: _limit });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, pageSize } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 25}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

function ButtonCrate(_props) {

    const { classes, CreatePanel } = _props;

    return (
        <TableCell colSpan={1} style={{ padding: '0px' }} >
            <div style={{ margin: 5, textAlign: 'center' }} onClick={() => CreatePanel()} >
                <Button variant="fab" aria-label="add" className={classes.cssRoot}>
                    <AddIcon />
                </Button>
            </div>
        </TableCell>)
}

const styles = theme => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            marginBottom: 10,
        },
        table: { minWidth: 1000, },
        cssRoot: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500], '&:hover': { backgroundColor: blue[700], },
        },
    });

CustomerListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPICustomerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CustomerActions.getCustomerList(_keyword, _filter));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerListPanel));


