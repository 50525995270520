import React, { Component } from 'react'
import { path, equals, update } from 'ramda';
import CONFIG from '../../config';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

import { create_supplier } from '../../constant/JSON/CreateSupplier';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import { SupplierType } from '../../constant/JSON/SupplierType';
import Avatar from '../../constant/Avatar';
import FormTypes from '../../common/FormTypes';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class DisplaySupplier extends Component {

    render() {
        // console.log('bobo', this.props);
        return (
            <div style={{ maxWidth: 800, overflow: 'unset' }}>
                {
                    create_supplier.map((row, index) => (
                        <div key={index} style={{ display: 'flex' }}>
                            {
                                row.map((each, index) => (
                                    <div key={index} style={{ textAlign: 'start', width: '100%', marginRight: '15px', paddingBottom: '7px' }}>
                                        {this.setSupplier(each)}
                                    </div>
                                ))
                            }
                        </div>
                    ))
                }
            </div>
        );

    }

    setSupplier(each) {
        const { SupplierInfo, supplierID, theme, classes, BookBankList, OnClose } = this.props;
        let bankList = BookBankList;
        let logoBank = null;
        switch (each.display) {
            case "input": {
                return (
                    <FormTypes.TextFormat disabled
                        Require={each.require}
                        TitleText={each.title}
                        value={path([each.stateName], SupplierInfo) || path(['address', each.stateName], SupplierInfo) ||
                            path(['emergencyContact', 0, each.stateName], SupplierInfo) || '-'} />
                    )
            } break;

            case "image": {
                return (
                    <div className={classes.textDefalut} style={{ margin: '0px ', paddingBottom: '6px' }} >
                        <div className={classes.avatar}
                            style={{
                                backgroundSize: 'cover',
                                backgroundColor: Colors.lightGrey,
                                backgroundImage: `url("${CONFIG.host}${path([each.stateName, 'thumbnail'], SupplierInfo)}")`
                            }}
                        />
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button.Themes
                                TitleText={ButtonText.BUTTON_CANCEL}
                                onClick={() => OnClose()} />

                        </div>
                    </div>
                )
            } break;
            case "switch": {
                return (
                    <FormTypes.Switches Left disabled
                        ValueText={each.title}
                        checked={path([each.stateName], SupplierInfo) || false} />
                    )
            } break;

            case "Bookbank": {
                 return (
                    <FormTypes.FormBookBank
                        disabled
                        BookBankList={BookBankList}
                        Info={path([each.stateName], SupplierInfo)}
                        valueAccount={path(['bank', each.stateName_account], SupplierInfo)}
                        onChange={() => { }} />
 )
            } break;
        }
    }
}

const styles = theme => (

    {
        textField: { width: '100%', marginTop: 0, marginBottom: 7, color: Colors.border, fontSize: '13px', fontFamily: Family.roboto, },
        textInput: {
            marginTop: 5,
            padding: 8,
            color: Colors.grey,
            borderRadius: 0,
            border: `${'1px solid ' + Colors.border}`,
            fontSize: 14,
            fontFamily: Family.roboto,
        },
        textDefalut: {
            margin: '5px 0px',
            fontSize: '12px',
            color: Colors.grey,
            fontFamily: Family.roboto,
        },
        textFormLabel: { fontSize: 18, marginTop: -3, fontFamily: Family.roboto, },
        avataronline: {
            position: 'relative',
            boxShadow: '-1 0 1px 1.9px ',
            backgroundRepeat: 'round',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
        },
        avatar: {
            height: '120px',
            width: '120px',
            boxShadow: '0 2px 5px 0 hsla(0,0%,47%,.5)',
        }
    }
);
DisplaySupplier.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

export default (withStyles(styles, { withTheme: true })(DisplaySupplier));