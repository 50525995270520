import React, { Component, Fragment } from 'react';
import { equals, path } from "ramda";
import Close from "../images/ic_close_black_18px.svg";
import Add from '../images/ic_add_white_18px.svg';
import { Colors, Images } from '../themes';

const DISPLAY_MODE = {
    IMAGE: 'IMAGE',
    DEFAULT: 'DEFAULT',
};

const imageType = ['image/jpeg', 'image/jpg', 'image/png'];

const getDisplayMode = (avatar) => {
    return !!avatar ? DISPLAY_MODE.IMAGE : DISPLAY_MODE.DEFAULT;
};

// const getPositionOnGirthOfAvatar = (avatarSize = 100, componentSize = 20) => {
//     const avataRadius = avatarSize / 2;
//     return avataRadius - ((avataRadius * Math.sqrt(2)) / 2) - (componentSize / 2);
// };

class Avatar extends Component {
    inputRef;
    imageFile;
    width = false;
    base64Image;
    // remove = "";
    constructor(props) {
        super(props);

        this.state = {
            displayMode: getDisplayMode(props.avatar),
            initAvatar: props.avatar,
            avatar: props.avatar,
            isZoom: false,
            zoom: true,
            removable: true
        };

        this.onFileChange = this.onFileChange.bind(this);
        this.onImageError = this.onImageError.bind(this);
        this.getAvatar = this.getAvatar.bind(this);
        this.remove = this.remove.bind(this);
        this.isChanged = this.isChanged.bind(this);
        this.clickFileInput = this.clickFileInput.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.avatar, this.state.initAvatar) && typeof nextProps.avatar === 'string') {
            this.setState({
                displayMode: getDisplayMode(nextProps.avatar),
                initAvatar: nextProps.avatar,
                avatar: nextProps.avatar,
            });
        }
    }

    isChanged() {
        return this.state.initAvatar !== this.state.avatar;
    }

    getAvatar(type) {
        switch (type) {
            case 'base64':
                return this.base64Image;
            case 'file':
                return this.imageFile;
            default:
                return this.state.avatar;
        }
    }

    displayAvatar() {
        if (this.state.displayMode === DISPLAY_MODE.DEFAULT) {
            return Add;
        }
        return this.state.avatar;
    }

    clickFileInput() {
        if (!!this.inputRef) {
            this.inputRef.click();
        }
    }

    remove() {
        this.inputRef.value = null;
        this.imageFile = undefined;
        this.base64Image = undefined;
        this.setState({
            avatar: '',
            displayMode: getDisplayMode(''),
        });
    }

    render() {
        const { isZoom, removable, zoom } = this.state;
        const { onClick, onRemove, editable, size = 100 } = this.props;
        const clickAble = typeof onClick === 'function' || editable;

        return (

            <Fragment>
                <div
                    onClick={() => {
                        if (this.width) {
                            if (!!this.img.style) {
                                this.img.style.width = '100%';
                                this.img.style.height = '';
                            }
                        }
                        if (isZoom) {
                            this.setState({ isZoom: false });
                        }
                    }}
                    style={{
                        // marginTop: '15px',
                        width: `${size}px`,
                        height: `${size}px`,
                        position: 'relative',
                        boxShadow: '0 2px 5px 0 hsla(0,0%,47%,.5)',
                    }}
                // style={{
                //     // border: `1px solid #888`,
                //     width: isZoom ? '' : size,
                //     height: isZoom ? '' : size,
                //     lineHeight: '130px',
                //     textAlign: 'center',
                //     backgroundColor: Colors.lightGrey,
                //     margin: '10px 10px 0px 0px',
                //     position: isZoom ? 'fixed' : 'relative',
                //     justifyContent: 'center',
                //     alignItems: 'center',
                //     display: isZoom ? 'block' : 'flex',
                //     zIndex: isZoom ? 100 : '',
                //     top: isZoom ? 0 : '',
                //     left: isZoom ? 0 : '',
                //     right: isZoom ? 0 : '',
                //     bottom: isZoom ? 0 : '',
                //     overflowY: isZoom ? 'scroll' : '',
                // }}

                >
                    <button
                        style={{
                            padding: '0px',
                            width: `${size}px`,
                            minWidth: `${size}px`,
                            height: `${size}px`,
                            position: 'relative',
                            border: '0px',
                            backgroundColor: Colors.defaultImage
                        }}
                        // buttonStyle={{
                        //     backgroundColor: Colors.white,
                        // }}
                        // overlayStyle={{
                        //     justifyContent: 'center',
                        //     alignItems: 'center',
                        //     display: 'flex',
                        // }}
                        disabled={!clickAble}
                        // backgroundColor="#d8d8d8"
                        onClick={() => {
                            if (typeof onClick === 'function') {
                                onClick();
                            } else {
                                if (editable === true) {
                                    this.inputRef.click();
                                }
                            }
                        }
                        }
                    >

                        <img src={this.displayAvatar()}
                            alt="avatar"
                            onError={this.onImageError}
                            style={{
                                width: '100%',

                            }}
                        // onLoad={(e) => {
                        //     const imgWidth = path(['target', 'naturalWidth'], e);
                        //     const imgHeight = path(['target', 'naturalHeight'], e);
                        //     const imgStyle = path(['target', 'style'], e);
                        //     // if (imgWidth > imgHeight) {
                        //     //     this.width = true;
                        //     //     imgStyle.width = '100%';
                        //     //     imgStyle.height = '';
                        //     // } else {
                        //     //     imgStyle.width = '';
                        //     //     imgStyle.height = '100%';
                        //     // }
                        // }}
                        />
                    </button>
                    {
                        this.state.displayMode === DISPLAY_MODE.DEFAULT ?
                            null
                            :
                            removable && !isZoom && (
                                <img
                                    src={Close}
                                    onClick={() => {
                                        if (typeof onRemove === 'function') {
                                            onRemove();
                                        } else {
                                            this.remove()
                                        }
                                    }}
                                    style={{
                                        position: 'absolute',
                                        top: -8,
                                        right: -5,
                                        cursor: 'pointer',
                                        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
                                        borderRadius: '50%',
                                        color: Colors.lightGrey,
                                        backgroundColor: Colors.white,
                                    }} />
                            )

                    }
                    {/* {
                        zoom && !isZoom && (
                            <img
                                src={Zoom}
                                onClick={() => {
                                    this.setState({ isZoom: true });
                                }}
                                style={{
                                    position: 'absolute',
                                    bottom: 3,
                                    right: 3,
                                    cursor: 'pointer',
                                    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
                                    // borderRadius: '50%',
                                    color:Colors.lightGrey,
                                    backgroundColor: Colors.white,
                                }}
                            />
                        )
                    } */}
                    {/* {
                        editable === true && (
                            <IconButton
                                style={{
                                    position: 'absolute',
                                    right: getPositionOnGirthOfAvatar(),
                                    bottom: getPositionOnGirthOfAvatar(),
                                    backgroundColor: Colors.white,
                                    width: '20px',
                                    height: '20px',
                                    padding: 0,
                                    borderRadius: '50%',
                                }}
                                iconStyle={{
                                    width: '14px',
                                    height: '14px',
                                }}
                                onClick={() => {
                                    if (typeof onClick === 'function') {
                                        onClick();
                                    } else {
                                        if (editable === true) {
                                            this.inputRef.click();
                                        }
                                    }
                                }}
                            >
                                <Settings
                                // className={classes.editIcon} 
                                />
                            </IconButton>
                        )
                    } */}
                </div>
                {
                    editable === true && (
                        <input
                            ref={(ref) => this.inputRef = ref}
                            accept=".jpg, .png"
                            style={{
                                display: 'none',
                            }}
                            type="file"
                            onChange={this.onFileChange}
                        />
                    )
                }
            </Fragment>
        );
    }

    onFileChange(event) {
        const files = event.target.files;
        // console.log('files', files)
        const { onChange } = this.props;
        if (files.length === 1) {
            const fileType = files[0].type;

            if (typeof onChange === 'function') {
                onChange(files[0]);
            }
            // console.log('imageType', imageType)
            if (imageType.indexOf(fileType) > -1) {
                this.imageFile = files[0];
                const reader = new FileReader();
                // console.log('reader', reader)
                reader.onloadend = (e) => {
                    this.base64Image = path(['target', 'result'], e);
                    this.setState({
                        avatar: this.base64Image,
                        displayMode: DISPLAY_MODE.IMAGE,
                    });
                    // this.props.onBase64Loaded && this.props.onBase64Loaded(this.base64Image);
                };
                reader.readAsDataURL(files[0]);
            } else {
                // this.refs.alert_dialog.fail(
                //     basicDialogLabel.wrong_image_file_type.message);
                this.inputRef.value = null;
            }
        } else {
            // click cencel
        }
    }

    onImageError() {
        if (this.props.onImageError) {
            this.props.onImageError();
        }

        const newState = {
            ...this.state,
            displayMode: getDisplayMode(this.state.initAvatar),
        };
        if (!equals(this.state.initAvatar, this.state.avatar)) {
            newState.avatar = this.state.initAvatar;
        } else {
            if (newState.displayMode === DISPLAY_MODE.IMAGE) {
                newState.displayMode = DISPLAY_MODE.DEFAULT;
            }
        }

        this.setState(newState);
    }
}

export default Avatar;
