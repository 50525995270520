export const init_currency = {
    "currencyId": "",
    "currencyType": "",
    "EN": {
        "name": "",
        "description": "",
    },
    "TH": {
        "name": "",
        "description": "",
    },
    "CN": {
        "name": "",
        "description": "",
    },
    "actived": false,
}

export const init_currencyRate = {
    "currencyRateId": "",
    "currencyTypeFrom": "",
    "currencyTypeTo": "",
    "currencyRate": "",
    "actived": false,
}

