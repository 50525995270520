import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path, equals, keys, omit, zipObj } from 'ramda';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import PackPromotionListPanel from '../containers/Promotions/PackPromotionListPanel.jsx'
import PromotionListPanel from '../containers/Promotions/PromotionListPanel.jsx'
import CreatePromotion from '../containers/Promotions/CreatePromotion.jsx'

import { PathName } from '../constant';
import { init_promotion } from '../constant/initState/initPromotion';

// import BigCalendar from 'react-big-calendar';
import { momentLocalizer, Views } from 'react-big-calendar';

import moment from 'moment';

import events from '../common/Events';
import Calendar from '../componets/Calendar';
import { getFormatted } from '../common/SetDate.js'

import { InitWaitingTime, SetStateWithTime, } from '../common/SetTime.js';
import * as PackageAction from '../actions/Actions_PackageManager.js';
import * as PromotionActions from '../actions/Actions_PromotionManager.js';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';

import '../App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
// import '../containers/Promotions/card.css';
import { Family } from '../themes';

// const localizer = BigCalendar.momentLocalizer(moment)
const localizer = momentLocalizer(moment)

const _height = (window.innerHeight - 130);
let PromotionPackage = [];
let PromotionsList = [];
let waitingTime = true;
let PromotionsAll = [];
let PackageAll = [];
let _today;

class PromotionManage extends Component {

  constructor(props) {
    super(props);
    _today = new Date();

    this.state = {
      promotionInfo: undefined,
      oldPromotionInfo: null,
      isLoading: true,
      eidtInfo: false,
      isPackage: false,
      error: null,
      isCreate: false,
      isEdit: false,
      msgError: undefined,
      dialogType: undefined,
      orderBy: 'desc',
      sortFilde: undefined,
    }

    //BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment)) // or globalizeLocalizer

    this.props.getAPIPackageList({ isPromotion: true, limit: 10 });
    this.props.getAPIPromotionsList({ num_limit: 10 })
    this.props.getAPIPromotions()
    this.props.getAPIPackage()
  }

  componentWillReceiveProps(nextProps) {

    const { PackageManager, PromotionManager } = nextProps;

    if (!!PackageManager) {

      const { actions, data } = PackageManager.PACKAGE_REDUCER;

      switch (actions) {
        case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
          {
            PromotionPackage = (data || []);
            SetStateWithTime(this, waitingTime);
          } break;

        case PackageAction.GET_PACKAGE_SUCCESS:
          {
            PackageAll = (data || []);
            SetStateWithTime(this, waitingTime);
          } break;

        case PackageAction.GET_PACKAGE_LISTS_FAILURE:
        case PackageAction.GET_PACKAGE_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break;
      }
    }

    if (!!PromotionManager) {

      const { actions, data } = PromotionManager.PROMOTIONS_REDUCER;
      switch (actions) {
        case PromotionActions.GET_PROMOTIONS_SUCCESS:
          {
            PromotionsList = data.result;
            SetStateWithTime(this, waitingTime);
          } break;

        case PromotionActions.GET_PROMOTIONALL_SUCCESS:
          {
            PromotionsAll = data;
            SetStateWithTime(this, waitingTime);
          } break;

        case PromotionActions.GET_PROMOTIONS_FAILURE:
        case PromotionActions.GET_PROMOTIONALL_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break;
      }
    }
  }

  render() {

    const { isLoading, isCreate, } = this.state;

    return (
      <div className="App">
        {(isLoading) && <DialogPanel Active={isLoading} />}
        {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.PromotionPanel()} />}
        <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
          <div style={{ display: 'inline-block' }}>
            {this.contentPanel()}
          </div>
        </List>
      </div>
    );
  }

  PromotionPanel = () => {

    const { promotionInfo, oldPromotionInfo, isEdit } = this.state;
    // console.log('promotionInfo', promotionInfo);
    return (
      <CreatePromotion
        isEdit={isEdit}
        promotionInfo={promotionInfo}
        PromotionsAll={PromotionsAll}
        oldPromotionInfo={oldPromotionInfo}
        onClosePanel={this.onClosePanel}
        PackageAll={PackageAll} />
    )
  }

  contentPanel() {

    return (
      <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
        <div style={{ marginTop: 40, height: '100%' }}>
          {this.MyCalendar()}
        </div>
        <div style={{ marginTop: 55, height: '100%' }}>
          <PromotionListPanel PromotionsList={PromotionsList} onCreateEvent={this.onCreateEvent()} DisplayPromotionInfo={this.DisplayPromotionInfo()} />
          <PackPromotionListPanel packageInfoList={PromotionPackage} onClickHandle={this.onClickHandle(PathName.PackageDetail)} />
        </div>
      </div>)
  }

  onClickHandle = (page) => (_packageId) => {
    const _packageInfo = PromotionPackage.packageList.find((_package) => { return _package.packageId === _packageId });
    this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo }, search: '', hash: '' });
  }

  DisplayPromotionInfo = () => (PromotionInfo) => {
    this.props.history.push({ pathname: PathName.PromotionInfo, state: { PromotionInfo }, search: '', hash: '' });
  }

  selectPromotion = (event) => {

    console.log('PromotionInfo : ', event);
    const _PromotionInfo = PromotionsAll.find((_promotion) => { return _promotion.id === event });
    this.props.history.push({ pathname: PathName.PromotionInfo, state: { PromotionInfo: _PromotionInfo }, search: '', hash: '' });
  }

  onCreateEvent = () => (_event) => (this.setState({ isCreate: true, promotionInfo: { ...init_promotion, packageId: [], startDate: moment(), endDate: moment() }, oldPromotionInfo: { ...init_promotion, packageId: [] } }));

  onSelectSlot = (_event) => {
    let event = zipObj(['start', 'end'], _event);
    this.setState({ isCreate: true, promotionInfo: { ...init_promotion, packageId: [], startDate: event.start, endDate: event.end }, oldPromotionInfo: { ...init_promotion, packageId: [] } })
  }

  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

  onClosePanel = () => (this.setState({ isCreate: false, eidtInfo: false, isPackage: false, isEdit: false, }));

  MyCalendar = () => {

    const allViews = Object.keys(Views).map(k => Views[k])
    const _event = [];
    const { rowLimit } = this.state;
    let promotions = (!!PromotionsAll) ? (PromotionsAll || []) : [];

    promotions.map((value, index) => {

      const { id, description, name, infant, adult, kid, startDate, endDate, visible, expDate, } = value;

      return (
        _event.push(
          {
            id: id,
            title: name,
            allDay: true,
            start: new Date(startDate),
            end: new Date(endDate),
            action: "select" | "click",
          }
        )
      )
    })

    return (
      <div style={{ marginTop: 20, height: '100%' }}>
        <Calendar
          style={{
            // height: '500px',
            cursor: 'pointer', fontFamily: Family.roboto, fontSize: 14,
          }}
          localizer={localizer}
          selectable
          events={_event}
          views={allViews}
          step={60}
          showMultiDayTimes
          defaultDate={_today || (new Date(2018, 4, 1))}
          onSelectEvent={(event) => {
            this.selectPromotion(event[2])
          }}
          onSelectSlot={(slot) => { (!!(Date.parse(slot.start) >= Date.parse(_today))) && this.onSelectSlot([slot.start, slot.end]) }}
        />
      </div>
    )
  };
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIPackageList: (_keyword) => {
      InitWaitingTime();
      dispatch(PackageAction.getAPIPackageLists(_keyword));
    },
    getAPIPromotionsList: (_keyword) => {
      InitWaitingTime();
      dispatch(PromotionActions.getPromotionsList(_keyword));
    },
    getAPIPromotions: (_filter) => {
      InitWaitingTime();
      dispatch(PromotionActions.getPromotions(_filter));
    },
    getAPIPackage: (_keyword) => {
      InitWaitingTime();
      dispatch(PackageAction.getAPIPackage(_keyword));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PromotionManage);
