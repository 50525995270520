
import * as CustomerActions from '../actions/Actions_CustomerManager.js';

const INITIAL_STATE = { CUSTOMER_REDUCER: { actions: CustomerActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: CustomerActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case CustomerActions.INITIAL_STATE:
            {
                return state;
            }
        case CustomerActions.IDLE_STATE:
            {
                //console.log("[ Customer ] : IDLE_STATE : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.IDLE_STATE, data: payload, error: _error } };
            }
        case CustomerActions.GET_CUSTOMER_SUCCESS:
            {
                //console.log("[ Customer ] : GET_CUSTOMER_SUCCESS : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.GET_CUSTOMER_SUCCESS, data: payload, error: _error } };
            }
        case CustomerActions.GET_CUSTOMER_FAILURE:
            {
                console.log("[ Customer ] : GET_CUSTOMER_FAILURE : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.GET_CUSTOMER_FAILURE, data: payload, error: _error } };
            }
        case CustomerActions.GET_CUSTOMER_REQUESTS_SUCCESS:
            {
                //console.log("[ Customer ] : GET_CUSTOMER_REQUESTS_SUCCESS : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.GET_CUSTOMER_REQUESTS_SUCCESS, data: payload, error: _error } };
            }
        case CustomerActions.GET_CUSTOMER_REQUESTS_FAILURE:
            {
                console.log("[ Customer ] : GET_CUSTOMER_REQUESTS_FAILURE : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.GET_CUSTOMER_REQUESTS_FAILURE, data: payload, error: _error } };
            }
        case CustomerActions.CREATE_CUSTOMER_SUCCESS:
            {
                // console.log("[ Customer ] : CREATE_CUSTOMER_SUCCESS : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.CREATE_CUSTOMER_SUCCESS, data: payload, error: _error } };
            }
        case CustomerActions.CREATE_CUSTOMER_FAILURE:
            {
                console.log("[ Customer ] : CREATE_CUSTOMER_FAILURE : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.CREATE_CUSTOMER_FAILURE, data: payload, error: _error } };
            }
        case CustomerActions.GET_CUSTOMERS_SUCCESS:
            {
                // console.log("[ Customer ] : GET_CUSTOMERS_SUCCESS : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.GET_CUSTOMERS_SUCCESS, data: payload, error: _error } };
            }
        case CustomerActions.GET_CUSTOMERS_FAILURE:
            {
                console.log("[ Customer ] : GET_CUSTOMERS_FAILURE : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.GET_CUSTOMERS_FAILURE, data: payload, error: _error } };
            }
        case CustomerActions.UPDATE_CUSTOMER_SUCCESS:
            {
                // console.log("[ Customer ] : UPDATE_CUSTOMER_SUCCESS : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.UPDATE_CUSTOMER_SUCCESS, data: payload, error: _error } };
            }
        case CustomerActions.UPDATE_CUSTOMER_FAILURE:
            {
                console.log("[ Customer ] : UPDATE_CUSTOMER_FAILURE : ", payload);
                return { ...state, CUSTOMER_REDUCER: { actions: CustomerActions.UPDATE_CUSTOMER_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Customer ]  default :", state);
                return state;
            }
    }
}
