import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import BarChart from './BarChart';
import BookingStatChart from './BookingStatChart';
import TwoLevelPieChart from './TwoLevelPieChart';
import { NumberFormatNoFix } from '../common/SetNumberFormat';

import { Colors } from '../themes';
import Button from '../constant/Button';


function BookingStatCard(props) {

    const { classes, Info, Disable, btnViewHadle, btnCreateHadle, isLoading, chartType } = props;
    //console.warn('BookingStatCard :', Info)

    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography className={classes.title}>{Info.name || ''}</Typography>
                <Typography className={classes.pos}>
                    {/* <div style={{marginTop:'40px',marginBottom:'20px'}}> */}
                    <span style={{ fontSize: '42px', padding: '20px 10px 20px 0px' }}>
                        {(isLoading) ? 0 : NumberFormatNoFix(Info.total || 0)}
                    </span>
                    <span style={{ fontSize: '16px' }}>{`Booking`}</span>
                    {/* </div> */}
                </Typography>
            </CardContent>
            {(isLoading) ? LoadingContainer() : ChartType(chartType, Info)}
            {(!isLoading) &&
                <CardActions className={classes.card_action}>
                <Button.Themes FullWidth onClick={() => btnViewHadle()}
                        disabled={Disable} TitleText={`VIEW ALL ${Info.name || ''}`} />
                </CardActions>}
        </Card>
    );
}

const LoadingContainer = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px', }}>
            <CircularProgress style={{ color: Colors.theme, width: 70, height: 70 }} thickness={5} />
        </div>
    )
}

const ChartType = (_type, _Info) => {

    switch (_type) {
        case 1:
            {
                //console.warn('BookingStatCard :', _Info)
                const bookingStatus = (!!_Info.bookingStatus) && _Info.bookingStatus.map((_itme) => ({ name: _itme.status, value: _itme.value }));
                return <TwoLevelPieChart Info={bookingStatus} />;
            }
        case 2:
            {
                return <BarChart />;
            }
        case 3:
        default:
            {
                return <BookingStatChart />;
            }
    }
}

const styles = theme => (
    {
        card: {
            minWidth: 200,
            maxWidth: 300,
            height: 400,
            marginTop: 20
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            marginBottom: 10,
            fontSize: 20,
            color: Colors.themeDark,
            textAlign: 'left',
        },
        pos: {
            marginBottom: 12,
            color: theme.palette.text.secondary,
            marginTop: '40px',
            marginBottom: '20px',
        },
        button: { backgroundColor: Colors.theme, color: Colors.white, width: '100%', height: '44px', marginTop: 30 },
        button_Disable: { backgroundColor: Colors.disabled, color: Colors.white, width: '100%', height: '44px' },
        card_action: { marginBottom: '0px', marginTop: '0px', paddingTop: '0px', paddingBottom: '0px' },
    });

BookingStatCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(BookingStatCard);