import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';

import AgentCard from '../containers/Agent/AgentCard';
import AgentListPanel from '../containers/Agent/AgentListPanel.jsx'
import AgentStatPanel from '../containers/Agent/AgentStatPanel.jsx'

import { PathName } from '../constant';
import DialogAction from '../componets/DialogAction.jsx';
import DialogPanel, { DailogType } from '../componets/DialogPanel.jsx';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import * as SettingActions from '../actions/Actions_Settings';
import * as AgentActions from '../actions/Actions_AgentManager.js';
import * as UploadActions from '../actions/Actions_UploadManager';

import { init_agent, SetAgentToCreate } from '../constant/initState/initAgent';

import '../App.css';
import styled from 'styled-components';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let AgentList = [];
let BookBankList = [];
let DefaultCommisstions = [];

class AgentManager extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isCreate: false,
            isEdit: false,
            isEditAgent: false,
            isInfo: false,
            isLoading: true,
            isDialog: false,
            AgentInfo: undefined,
            oldAgentInfo: null,
            msgError: undefined,
            dialogType: undefined,
        }
        this.props.getAPIAgentList({ page: 0, num_limit: 10 });
        this.props.getAPIBankList({ num_limit: 100 });
        this.props.getDefaultCommissions();
    }

    componentWillReceiveProps(nextProps) {

        const { SettingManager, AgentManager, UpLoadManager } = nextProps;
        console.log(`AgentManager [componentWillReceiveProps] : `, AgentManager);


        if (!!AgentManager) {
            const { actions, data } = AgentManager.AGENT_REDUCER;
            const { isEdit, isCreate } = this.state;

            switch (actions) {
                case AgentActions.GET_AGENT_SUCCESS:
                    {
                        AgentList = data.result;
                        if (isEdit || isCreate)
                            AgentManager.AGENT_REDUCER.actions = AgentActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case AgentActions.GET_AGENT_FAILURE:
                    {
                        if (isEdit || isCreate)
                            AgentManager.AGENT_REDUCER.actions = AgentActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AgentActions.CREATE_AGENT_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Agent Success.', dialogType: DailogType.success, });
                        this.props.getAPIAgentList({ page: 0, num_limit: 10 });
                    } break;
                case AgentActions.UPDATE_AGENT_SUCCESS:
                    {
                        if (isEdit || isCreate)
                            AgentManager.AGENT_REDUCER.actions = AgentActions.WATING_STATE;

                        SetStateWithObject(this, waitingTime, { msgError: 'Update Agent Success.', dialogType: DailogType.success });
                        this.props.getAPIAgentList({ page: 0, num_limit: 10 });
                    } break;
                case AgentActions.CREATE_AGENT_FAILURE:
                case AgentActions.UPDATE_AGENT_FAILURE:
                    {
                        console.log("AGENT_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: DailogType.error, });
                    } break;
            }
        }

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        DefaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!UpLoadManager) {

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let _newAgent = this.state.AgentInfo;
                        _newAgent.logo = data.result.upload.images;

                        const { isEditAgent } = this.state;

                        // console.log('isEditAgent', isEditAgent)

                        if (isEditAgent) {
                            console.log('UPLOAD_IMAGE_SUCCESS EDIT :', _newAgent)
                            this.props.APIUpdateAgent(_newAgent);
                        }
                        else {
                            console.log('UPLOAD_IMAGE_SUCCESS CREATE :', _newAgent)
                            this.props.APICreateAgent(SetAgentToCreate(_newAgent))
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: DailogType.error, });
                    } break;
            }
        }

    }
    render() {

        const { AgentInfo, isDialog, isLoading, isCreate, msgError, dialogType, isInfo } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.CreateAgentPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={this.onCloseDialog()} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={this.onCloseDialog()} />}

                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    {this.contentPanel()}
                </List>
            </div>
        );
    }

    contentPanel() {

        const { history } = this.props;
        const { isCreate, isLoading } = this.state;

        return (
            <ContentCotianer>
                <AgentStatPanel AgentList={AgentList} history={history} isLoading={isLoading} />
                <AgentListPanel
                    Level={DefaultCommisstions}
                    CreatePanel={this.onCreatePanel(isCreate)}
                    DisplayAgentInfo={this.DisplayAgentInfo()}
                    EditInfo={this.EditAgentInfo()}
                    AgentList={AgentList} />
            </ContentCotianer>
        )
    }

    //========================================================================================================================================================================================================
    //Function Call back use with AgentListPanel

    getAPIAgentListWithLimit = () => {
        const { page, limit, } = AgentList;
        return this.props.getAPIAgentList({ page: (page || 0), num_limit: (limit || 10) });
    }

    CreateAgentPanel = () => {

        const { isEdit, AgentInfo, oldAgentInfo, } = this.state;
        return (
            <AgentCard
                Level={DefaultCommisstions}
                BookBankList={BookBankList}
                AgentInfo={AgentInfo}
                oldAgentInfo={oldAgentInfo}
                onClosePanel={this.onClosePanel}
                isEdit={isEdit}
                onCloseDialog={this.onCloseDialog}
                APICreateAgent={this.APICreateAgent}
                APIUpdateAgent={this.APIUpdateAgent}
            />
        )
    }

    APICreateAgent = (avatar, agentInfo) => {
        // console.log('APICreateAgent', avatar, agentInfo);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, AgentInfo: agentInfo }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, AgentInfo: agentInfo }, () => this.props.APICreateAgent(SetAgentToCreate(agentInfo)));
        }
    }

    APIUpdateAgent = (avatar, agentInfo) => {
        // console.log('APIUpdateAgent', avatar, agentInfo);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, AgentInfo: agentInfo, isEditAgent: true }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, AgentInfo: agentInfo }, () => this.props.APIUpdateAgent(agentInfo));
        }
    }


    onCreatePanel = (_isActive) => () => { this.setState({ isCreate: !_isActive, oldAgentInfo: { ...init_agent }, AgentInfo: { ...init_agent } }) };

    onClosePanel = () => (this.setState({ isCreate: false, isInfo: false, isEdit: false, AgentInfo: { ...init_agent }, error: null, isLoading: false, msgError: undefined, dialogType: undefined, name: [] }));

    onCloseDialog = () => () => (this.setState({ isLoading: false, isDialog: false, isEditAgent: false, msgError: undefined, dialogType: undefined }));

    EditAgentInfo = () => (AgentInfo) => { this.setState({ isCreate: true, isEdit: true, AgentInfo, oldAgentInfo: AgentInfo }) }

    DisplayAgentInfo = () => (agentInfo) => { this.props.history.push({ pathname: PathName.AgentInfo, state: { agentInfo }, search: '', hash: '' }); }

    //========================================================================================================================================================================================================
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIAgentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AgentActions.getAgentList(_keyword, _filter));
        },
        getAPIBankList: (_keyword) => {
            dispatch(SettingActions.getBankList(_keyword));
        },
        APICreateAgent: (_confirmInfo) => {
            dispatch(AgentActions.APICreateAgent(_confirmInfo));
        },
        APIUpdateAgent: (_confirmInfo) => {
            dispatch(AgentActions.APIUpdateAgent(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AgentManager);

const ContentCotianer = styled.div`    
min-height: 600px;
width: 100%;
max-width: 1024px;
display: inline-block;`;




















