
const HeaderPage = {
    CREATE_EN: 'Create Agent', CREATE_TH: '------', CREATE_CN: '------',
    UPDATE_EN: 'Update Agent', UPDATE_TH: '------', UPDATE_CN: '------',
}

const BodyPage = [
    [{
        title_EN: "Agent Name", title_CN: "---------", title_TH: "---------",
        type: "text",
        require: true,
        margin: 'right',
        stateName: "name",
        placeholder_EN: "Insert Seller Name", placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: "Agent Email", title_CN: "---------", title_TH: "---------",
        type: "email",
        require: true,
        stateName: "email",
        placeholder_EN: "Insert Seller Email", placeholder_CN: "---------", placeholder_TH: "---------",
    }],
    [{
        title_EN: 'Emergency', title_CN: "---------", title_TH: "---------",
        type: 'number',
        stateName: 'tel',
        margin: 'right',
        placeholder_EN: "Insert Emergency", placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: 'Person', title_CN: "---------", title_TH: "---------",
        type: 'text',
        stateName: 'person',
        placeholder_EN: 'Insert name', placeholder_CN: "---------", placeholder_TH: "---------",
    }],
    [{
        title_EN: 'Tel Office', title_CN: "---------", title_TH: "---------",
        type: 'number',
        margin: 'right',
        stateName: 'telOffice',
        placeholder_EN: 'Insert Tel Office', placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: 'Reservation Number', title_CN: "---------", title_TH: "---------",
        type: 'number',
        margin: 'right',
        stateName: 'reservationNumber',
        placeholder_EN: 'Insert Reservation Number', placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: 'Official Website', title_CN: "---------", title_TH: "---------",
        type: 'text',
        stateName: 'officialWebsite',
        placeholder_EN: 'Insert Official Website', placeholder_CN: "---------", placeholder_TH: "---------",
    }], [{
        title_EN: 'PriceGroup', title_CN: "---------", title_TH: "---------",
        type: 'number',
        margin: 'right',
        stateName: 'priceGroup',
        placeholder_EN: 'Insert PriceGroup', placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: 'Level', title_CN: "---------", title_TH: "---------",
        type: 'select',
        require: true,
        stateName: 'level',
        placeholder_EN: 'Please Select', placeholder_CN: "---------", placeholder_TH: "---------",
        // options: [
        //     { id: 0, value: 0, label_EN: "Welcome level", label_TH: "---", label_CN: "---" },
        //     { id: 1, value: 1, label_EN: "Green level", label_TH: "---", label_CN: "---" },
        //     { id: 2, value: 2, label_EN: "Gold level", label_TH: "---", label_CN: "---" },
        //     { id: 3, value: 3, label_EN: "Diamond level", label_TH: "---", label_CN: "---" },
        // ]
    }], [{
        title_EN: 'Tag', title_CN: "---------", title_TH: "---------",
        type: 'text',
        margin: 'right',
        stateName: 'tag',
        placeholder_EN: 'Insert Tag', placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: 'AffiliateId', title_CN: "---------", title_TH: "---------",
        type: 'text',
        stateName: 'affiliateId',
        placeholder_EN: 'Insert AffiliateId', placeholder_CN: "---------", placeholder_TH: "---------",
    }], [{
        title_EN: 'CreditTermId', title_CN: "---------", title_TH: "---------",
        type: 'text',
        margin: 'right',
        stateName: 'creditTermId',
        placeholder_EN: 'Insert CreditTermId', placeholder_CN: "---------", placeholder_TH: "---------",
    }, {
        title_EN: 'Credit', title_CN: "---------", title_TH: "---------",
        type: 'text',
        stateName: 'credit',
        placeholder_EN: 'Insert Credit', placeholder_CN: "---------", placeholder_TH: "---------",
    }], [{
        title_EN: 'Bank', title_CN: "---------", title_TH: "---------",
        type: 'select_checkbok',
        stateName: 'bank',
        stateName_account: 'accountNumber'
    }], [{
        title_EN: 'Status', title_CN: "---------", title_TH: "---------",
        type: 'switch',
        stateName: 'actived'
    }], [{
        title_EN: 'Logo', title_CN: "---------", title_TH: "---------",
        type: 'image',
        stateName: 'logo',
    }]
]


export default {
    HeaderPage,
    BodyPage,
}