import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import addMonths from "date-fns/addMonths";

import { Colors, Size, Family } from '../../themes';

/** How to use  
 * 
 * import * as CompPicker from '../componets/CompPicker';
 * 
 * return(
 *      <CompPicker.DateRange 
 *          dateString={this.state.date} 
 *          onChange={(fieldName, date) => this.setState({ date })} />
 * )
 *  
*/

class DateRange extends Component {

    static propTypes = {
        openRange: PropTypes.bool,
        onChange: PropTypes.func,
        dateObject: PropTypes.object,
        dateString: PropTypes.string,
        dateInputLabel: PropTypes.string,
    };

    constructor(props) {
        super(props);
        const { dateString, dateObject } = props;

        this.state = {
            date: (dateString) ? dateString : '',
            startDate: (dateObject) ? dateObject.startDate : '',
            endDate: (dateObject) ? dateObject.endDate : ''
        };

    }

    onDateChange = (date) => {
        this.setState({ date });
        const strDate = new Date(date).toISOString().substr(0, 10);
        this.props.onChange('date', strDate);
    };

    onRangeChange = (valueText) => (date) => {
        console.log('onRangeChange :', valueText, ':', date)
        const strDate = new Date(date).toISOString().substr(0, 10);
        const objectDate = {
            startDate: '',
            endDate: ''
        }
        switch (valueText) {
            case 'startDate': {
                objectDate.startDate = strDate;
                this.setState({ startDate: date })
            } break;
            case 'endDate': {
                objectDate.endDate = strDate;
                this.setState({ endDate: date })
            } break;
        }
        console.log('objectDate', objectDate)
        this.props.onChange('date', objectDate);
    };

    render() {
        const { date, startDate, endDate } = this.state;
        const {
            openRange,
            dateInputLabel
        } = this.props;
        console.log('state', this.state)
        switch (openRange) {
            case true:
                return (
                    <Container flex>
                        {this.renderComp({ startDate, dateInputLabel: 'Start Date' }, 'startDate')}
                        <BlockText>
                            <InputLabel> {'To'} </InputLabel>
                        </BlockText>
                        {this.renderComp({ endDate, dateInputLabel: 'End Date' }, 'endDate')}
                    </Container>
                );
            default: return (this.renderComp({ date, dateInputLabel }));
        }

    }
    /**
     * render component 
     */

    renderComp({
        date,
        dateInputLabel
    }, valueText) {
        console.log('date', date)
        return (
            <Container>
                <InputLabel> {dateInputLabel || 'Date'} </InputLabel>
                <DatePicker
                    selected={date}
                    onChange={(valueText) ? this.onRangeChange(valueText) : this.onDateChange}
                    minDate={new Date()}
                    maxDate={addMonths(new Date(), 24)}
                    showDisabledMonthNavigation
                    customInput={<InputPicker />}
                />
            </Container>
        )
    }
}
export default DateRange;


/** 
 * function other 
 */

function getFormatted(_info) {
    return _info < 10 ? `0${_info}` : _info;
}

/** 
 * style component 
 * */

const Container = styled.div`
    display: flex;
    justify-content: center;    
    align-items: flex-start;
    flex-direction: ${props => props.flex ? 'row' : 'column'};
`;
const BlockText = styled.div`
    width: 50px;
    height: 52.67px;
    display: flex;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
`;
const InputLabel = styled.div`
    color: ${Colors.input.label};
    font-size: ${Size.input.label};
    font-family:${Family.avenir};
    font-weight: 300;
    padding-bottom: 5px;
`;
const InputPicker = styled.input`
    width: 100%;
    padding: 9.5px 10px;
    font-weight: 300;
    font-size: ${Size.input.label};
    font-family:${Family.avenir};
    color: ${Colors.input.label};
    border: 1px solid ${Colors.border};
    min-width: ${props => props.width ? props.width : '100px'};
`;
