import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CONFIG from '../../config';


import Avatar from '../../componets/Avatar.jsx';
import EmptyRows from '../../componets/EmptyRow.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';
import SearchPanel from '../../componets/SearchPanel.jsx';

import { Family, Fonts, Colors, Icons,Images } from '../../themes';

import * as SellerActions from '../../actions/Actions_SellerManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
const { BorderColorIcon,DoneIcon, CloseIcon } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'name', isOrder: true, },
    { label: 'EMAIL', name: 'email', isOrder: true, },
    { label: 'TEL.', name: '', isOrder: false, },
    { label: 'GROUP', name: '', isOrder: false, },
    { label: 'LEVEL', name: 'level', isOrder: true, },
    { label: 'VISIBLE', name: '', isOrder: false, },
    { label: 'VERIFIED', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', minWidth: 160 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };
const _wording = { header: 'Seller Manage' }

let SellerList = [];
let waitingTime = true;

class SellerListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {

        const { SellerManager } = nextProps;
        // console.log(`SellerManager [componentWillReceiveProps] : `, SellerManager, );

        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;
            switch (actions) {
                case SellerActions.GET_SELLER_WITH_AGENT_ID_SUCCESS:
                    {
                        SellerList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_WITH_AGENT_ID_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_SUCCESS:
                    {
                        SellerList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, CreatePanel, DisplaySellerInfo, EditInfo, onClickHandle, isEmployee, Level } = this.props;// onNextPage 
        const { datas } = this.props.SellerList;

        SellerList = datas || [];
        const isSeller = (SellerList.length > 0);
        const { isLoading, msgError, dialogType, orderBy, searchActive } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} onClick={() => this.setState({ searchActive: !searchActive })} />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            {(!Boolean(isEmployee)) && ((searchActive) &&
                                (<TableRow>
                                    <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                        <SearchPanel searchActive={searchActive} SearchType='seller' />
                                    </TableCell>
                                </TableRow>))}
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {/* {_filde.label} */}
                                            {GetSortIcon(orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isSeller) ? SellerList.map(_info => {
                                const { sellerId, name, telOffice, email, group, level, logo, username, actived, approved, firstName, visible, lastName } = _info;
                                // const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${logo.thumbnail})`, backgroundRepeat: 'round' };
                                let levelList = Array.isArray(path(['datas'], Level)) ? path(['datas'], Level).filter((val) => val.defaultCommissionId === level) : [];

                                return (
                                    <TableRow hover style={{ cursor: 'pointer' }} key={sellerId} >
                                        {/* <TableCell    style={{ ..._callStyle, ...{ minWidth: 80, paddingRight: 5 } }} onClick={() => { DisplaySellerInfo(_info) }} ><div style={_backgroundImage} /></TableCell> */}
                                        <TableCell style={_callStyle} onClick={() => DisplaySellerInfo(sellerId)}>  <Avatar URL={(logo) ? path(['thumbnail'], logo) : ""} /></TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplaySellerInfo(sellerId) }} >{username || firstName + ' ' + lastName}</TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplaySellerInfo(sellerId) }} >{email || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplaySellerInfo(sellerId) }} >{telOffice || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplaySellerInfo(sellerId) }} >{group || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplaySellerInfo(sellerId) }} >{path([0, 'levelName'], levelList) || '-'}</TableCell>
                                        {/* <TableCell    style={_callStyle} onClick={() => { DisplaySellerInfo(sellerId) }} >{approved ? 'Approved' : 'Pending'}</TableCell> */}
                                        {/* <TableCell    style={_callStyle} onClick={() => { DisplaySellerInfo(_info) }} >{`${actived}`}</TableCell> */}
                                        <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }}>{GetIconWithStatus(visible)}</TableCell>
                                        <TableCell style={{ ..._callStyle, ...{ color: (actived) ? Colors.success : Colors.error } }} onClick={() => { }} >{actived ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell style={_callStyle}><Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(_info) }} /></TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(isSeller) && GetPagination(this.props.SellerList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        );
    }

    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        console.log('handleRequestSort : ', _fildeName, SellerList)

        SellerList = (orderBy === 'desc')
            ? SellerList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : SellerList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        const { agentId, isEmployee } = this.props;
        console.log('agentId', agentId, isEmployee);
        if (isEmployee) {
            this.props.getAPISellerList_WithAgentId({ where: { agentId: agentId }, num_limit: _limitRows, page: 0 });
        } else {
            this.props.getAPISellerList({ page: 0, num_limit: _limitRows });
        }
    }

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });
        const { agentId, isEmployee } = this.props;
        if (isEmployee) {
            this.props.getAPISellerList_WithAgentId({ where: { agentId: agentId }, page: _pageNumber, num_limit: _limit });
        } else {
            this.props.getAPISellerList({ page: _pageNumber, num_limit: _limit });
        }

    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetIconWithStatus(_status) {

    return ((_status) ? <DoneIcon /> : <CloseIcon />)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, pageSize } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 25}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const styles = theme => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            marginBottom: 10,
        },
        table: { minWidth: 700, },
       });

SellerListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        // setAPICreateSller: (_confirmInfo) => {
        //     dispatch(SellerActions.APICreateSeller(_confirmInfo));
        // }
        getAPISellerList_WithAgentId: (_fliter) => {
            dispatch(SellerActions.getSellerList_WithAgentId(_fliter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SellerListPanel));

//export default withStyles(styles)(SellerListPanel);

// {sellerId,name,telOffice,reservationNumber,emergencyContact,officialWebsite,priceGroup,tag,affiliateId,status,creditTermId,credit,actived,visible,approved,id,userId }


// {
//     "sellerId": "SPR-20180427-000003",
//     "name": "Joe Evils",
//     "telOffice": "string",
//     "reservationNumber": "string",
//     "emergencyContact": [],
//     "officialWebsite": "string",
//     "priceGroup": "string",
//     "tag": "string",
//     "affiliateId": "string",
//     "status": "string",
//     "creditTermId": "string",
//     "credit": "string",
//     "actived": true,
//     "visible": true,
//     "approved": false,
//     "id": "5ae35215d7b288744336a5dd",
//     "userId": "5ae35215d7b288744336a5dc"
//   }