export const GetItemColsPerWidth = () => {
    let _width = window.innerWidth;
    //let _scrollWidth = window.document.body.scrollWidth;
    // console.log('with : ', _width, ' : ', _scrollWidth, ' : ', window.innerWidth, )//window.resize()

    if (_width <= 610) return 1;
    else if (_width > 610 && _width <= 910) return 2;
    else if (_width > 910 && _width <= 1400) return 3;
    else if (_width > 1400 && _width <= 1900) return 4;
    else return 5;
}

export const isMobile = () => {

    //console.log('ismobile : ', navigator.userAgent)
    const checkRows = (GetItemColsPerWidth() === 1);

    if (checkRows)
        return checkRows;
    else if (navigator.userAgent.match(/(iPad|Android)/i) && (window.innerWidth > 800))
        return false;
    else
        return (navigator.userAgent.match(/(Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i)) ? true : false;
}

export const isTablet = () => {
    const _tablet = (navigator.userAgent.match(/(iPad|Android)/i) && (window.innerWidth > 720)) ? true : false;
    return (_tablet && isMobile());
}

const Browser = { MSIE: "MSIE", Firefox: "Firefox", Safari: "Safari", Chrome: "Chrome", Opera: "Opera" };

export const isFirefox = () => (navigator.userAgent.indexOf(Browser.Firefox) > -1);
export const isChrome = () => (navigator.userAgent.indexOf(Browser.Chrome) > -1);
export const isOpera = () => (navigator.userAgent.indexOf(Browser.Opera) > -1);
export const isSafari = () => (navigator.vendor.match(/apple/i));