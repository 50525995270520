import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { path, clone } from 'ramda';
import { SetDateFormat, SetDate } from '../../common/SetDate';
import { HighlightOff } from '@material-ui/icons';
import { Colors, Family } from '../../themes';
import FormTypes from '../../common/FormTypes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import Checkbox from '@material-ui/core/Checkbox';

import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Refund';

import * as RefundActions from '../../actions/Actions_RefundManager';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import Refund from '../../constant/JSON/Refund';

let Structure = Refund.Structure;
let RefundReasonsList = [];
let waitingTime = true;
let Language = 'EN';
let userInfo = JSON.parse(localStorage.getItem('UserInfo'));


class RefundCard extends Component {

    constructor(props) {
        userInfo = JSON.parse(localStorage.getItem('UserInfo'));
        super(props);
        const { subPaymentMethod } = props.paymentInfo;
        let alipay = subPaymentMethod === 'alipay';
        let card = subPaymentMethod === 'card';
        this.state = { isAccept: false, refundPolicyId: null, description: null, refundType: (alipay || card) ? 'online' : 'offline' }
        this.props.getRefundReasonsList();
    }


    componentWillReceiveProps(nextProps) {

        const { RefundManager } = nextProps;
        if (!!RefundManager) {

            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.GET_REFUND_REASONS_SUCCESS:
                    {
                        RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        RefundReasonsList = data.result.datas || [];
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_REASONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }


    render() {
        const { onClosePanel } = this.props;
        const { isLoading, msgError, dialogType } = this.state;

        return (
            <Contianer>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={this.onCloseDialog()} />}
                <div style={{ position: 'absolute', top: 5, right: 10 }} onClick={() => onClosePanel()} >
                    <Button.Icons tooltip={'Close'} icon={<HighlightOff />} buttonStyle={{ color: Colors.theme }} />
                </div>
                {
                    Structure.map((R, index) => (
                        <ListContainer key={index}>
                            {R.map((each, index) => (
                                <div key={index} style={{ margin: each.margin ? each.margin : 0, width: each.width ? each.width : '100%' }}>
                                    {this.contentPanel(each)}
                                </div>
                            ))}
                        </ListContainer>
                    ))
                }
            </Contianer>
        )
    }
    contentPanel = (each) => {

        const { isAccept } = this.state;
        const { paymentInfo, amount } = this.props;
        let Items = each.items || [];


        switch (each.type) {
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={each.stateName === 'refundType' && true}
                        TitleText={each.title_EN}
                        Require={each.require}
                        value={path([each.stateName], this.state)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={
                            (each.stateName === 'refundPolicyId') ?
                                RefundReasonsList.map((option, i) => (<option key={i} value={option.refundPolicyId} >{option[Language].reasons}</option>))
                                :
                                each.options.map((option, i) => (<option key={i} value={option.value} >{option.label}</option>))
                        } />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={3}
                        style={{ marginBottom: 10 }}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], this.state)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case 'additional': {
                return (
                    <AdditionalContianer>
                        <ListItemsText style={{ fontWeight: 'bold' }}> {'Refund Details'} </ListItemsText>
                        <ListItemsText> {'Refund Amount :' + ' ' + (path(['amount'], paymentInfo) || amount)} </ListItemsText>
                    </AdditionalContianer>
                )
            }
            case 'checkbox': {
                return (
                    <CheckboxContianer>
                        {
                            Items.map((item, index) => {
                                return (
                                    <ListItems key={index} onClick={() => this.onChangeState('isAccept', isAccept)}>
                                        <Checkbox color="primary" checked={isAccept} tabIndex={-1} disableRipple />
                                        <CheckboxText >{`${item[Language].name}`}</CheckboxText>
                                    </ListItems>
                                )
                            })
                        }
                        <ButtonContianer>
                            <Button.Themes FullWidth TitleText={ButtonText.BUTTON_APPLY_FOR_REFUND}
                                disabled={!isAccept} buttonStyle={{ margin: 0, padding: 10 }}
                                onClick={() => this.onRefund()} />
                        </ButtonContianer>
                    </CheckboxContianer>
                )
            }

        }
    }

    onChangeState = (fieldName, value) => {
        let newData = { ...this.state };

        switch (fieldName) {
            case 'isAccept':
                const newBoolean = clone(newData);
                newBoolean.isAccept = !newBoolean.isAccept;
                newData = { ...newData, [fieldName]: newBoolean.isAccept }
                break;
            default: {
                newData = { ...newData, [fieldName]: value }
            } break;
        }

        this.setState({ [fieldName]: newData[fieldName] })
    }


    onRefund = () => {
        const { bookingId, packageId, startDate, endDate, userType } = this.props;
        const { isAccept, refundPolicyId, description, refundType } = this.state;
        const Role = userInfo.userType;
        let RefundInfo;

        if (refundPolicyId && description) {
            if (bookingId) {
                RefundInfo = {
                    bookingId: bookingId,
                    refundPolicyId: refundPolicyId,
                    refundType: refundType,
                    description: description,
                    isAccept: isAccept,
                    refundBy: Role,
                    refundById: userInfo.id,
                    typeRefund: 'booking'
                }
            } else if (packageId) {
                RefundInfo = {
                    startDate: SetDateFormat(startDate),
                    endDate: SetDateFormat(startDate),
                    packageId: packageId,
                    refundPolicyId: refundPolicyId,
                    refundType: refundType,
                    description: description,
                    isAccept: isAccept,
                    refundBy: (userType) ? userType : Role,
                    refundById: userInfo.id,
                    typeRefund: 'package'
                }
            }

            // console.log('onRefund', RefundInfo);
            this.props.confirmRefund(RefundInfo);
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error' })
        }
    }
    onCloseDialog = () => () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getRefundReasonsList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(RefundActions.getRefundReasonsList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RefundCard);

const Contianer = styled.div`
        max-width: 800px;
        padding-top: 30px;
        font-family: ${Family.roboto};`;
const ListContainer = styled.div`
        display: flex;
        padding: 2px 0px;`;
const ListItems = styled.div`
        display: flex;
        flexDirection: row;
        margin-left: -15px;
        align-items: center;`;
const ButtonContianer = styled.div`
        display: flex;
        justify-content: flex-end;`;
const CheckboxContianer = styled.div`
        width: 100%;
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;`;
const AdditionalContianer = styled.div`
        width:100%;
        background: #f5f6f7;
        border: 1px solid ${Colors.border};
        padding: 10px;`;
const ListItemsText = styled.div`
        color: #5B5B5B;
        padding: 10px;
        font-size: 14px;`;
const CheckboxText = styled.div`
        color: #5B5B5B;
        font-weight: 400;
        font-size: 14px;`;

