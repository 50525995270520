import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import AppoiontmentListPanel from '../containers/Appointment/AppoiontmentListPanel.jsx';
import AppointmentCard from "../containers/Appointment/AppointmentCard";

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';

import * as validator from '../constant/Validator/Appoiontment';
import { init_appoiontment } from '../constant/initState/initAppoiontment';

import * as AppointmentActions from '../actions/Actions_Appointment.js';

import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let AppoiontmentList = [];

class AppointmentManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, isCreate: false, isEdit: false, isView: false,
            AppointmentInfo: undefined, oldAppointmentInfo: undefined,
        };
        this.props.getAPIAppointmentList({ page: 0, num_limit: 10 });
    }

    componentWillReceiveProps(nextProps) {

        const { AppointmentManager, UpLoadManager } = nextProps;
        if (!!AppointmentManager) {
            const { actions, data } = AppointmentManager.APPOINTMENT_REDUCER;

            switch (actions) {
                case AppointmentActions.GET_APPOINTMENT_SUCCESS:
                    {
                        AppoiontmentList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AppointmentActions.GET_APPOINTMENT_FAILURE:
                    { SetStateWithTime(this, waitingTime) } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.CreateAppointmentPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>                        {this.contentPanel()}                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <AppoiontmentListPanel
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.EditAppointmentPlace()}
                    onClickHandle={this.onClickHandle()}
                    AppoiontmentList={AppoiontmentList} />
            </div>)
    }

    getAPIAppoiontmentListWithLimit = () => {
        const { page, limit, language } = AppoiontmentList;
        return this.props.getAPIAppointmentList({ page: (page || 0), num_limit: (limit || 10), language: (language || 'EN') });
    }

    CreateAppointmentPanel = () => {
        return (
            <AppointmentCard
                isEdit={this.state.isEdit}
                isView={this.state.isView}
                oldAppointmentInfo={this.state.oldAppointmentInfo}
                AppointmentInfo={this.state.AppointmentInfo}
                onClosePanel={this.onClosePanel}
                AppoiontmentList={AppoiontmentList}
                onChangeLanguage={this.onChangeLanguage}
                getAPIAppointmentList={this.getAPIAppoiontmentListWithLimit}
            />

        )
    }

    onChangeLanguage = (_language) => {
        const { page } = AppoiontmentList;
        this.props.getAPIAppointmentList({ page: page, num_limit: 10, language: _language });
    }

    EditAppointmentPlace = () => (AppointmentInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            isView: false,
            AppointmentInfo,
            oldAppointmentInfo: AppointmentInfo
        })
    }

    onClickHandle = () => (AppointmentInfo) => {
        this.setState({
            isCreate: true,
            isEdit: false,
            isView: true,
            isBtn: false,
            AppointmentInfo,
            oldAppointmentInfo: AppointmentInfo,
        });
    }

    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, AppointmentInfo: { ...init_appoiontment }, oldAppointmentInfo: { ...init_appoiontment } }));
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, AppointmentInfo: { ...init_appoiontment }, }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAppointmentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AppointmentActions.getAppointmentList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AppointmentManage);
