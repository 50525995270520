// import React from 'react';
// import Select from 'react-select';

// import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';

// import { AheadTime } from '../../constant/JSON/Number';
// import { Family, Colors, RequireForm } from '../../themes';

// const suggestions = AheadTime.map(suggestion => ({
//     value: suggestion.value,
//     label: suggestion.precis,
// }));

// class SelectText extends React.Component {

//     handleChange = name => state => {
//         this.setState({
//             [name]: state ? state.value : '',
//         }, () => this.props.onChange(name, this.state[name]));
//     };

//     render() {
//         const { classes, styleUnit, TitleText, placeholder, stateName, value, Require, error, disabled } = this.props;
//         const selectStyles = {
//             borderBottom: error ? `${'2px solid' + Colors.error}` : `${'1px solid' + Colors.border}`,
//             borderRadius: '0px', border: `${'1px solid' + Colors.border}`,
//             fontFamily: Family.roboto, color: Colors.themeDark,
//             fontSize: 16, fontWeight: 300,
//         };
//         return (
//             <div className={classes.root} >
//                 <div className={classes.textFormLabel} >{Require ? <div> {RequireForm()}{TitleText} </div> : TitleText}</div>
//                 <div className={classes.formControl} >
//                     <Select
//                         disabled={disabled}
//                         className={classes.select}
//                         style={selectStyles}
//                         error={true}
//                         options={suggestions}
//                         value={value}
//                         onChange={this.handleChange(stateName)}
//                         placeholder={placeholder} />
//                     <div className={classes.textUnit} style={styleUnit}>  {'Hour(s)'} </div>
//                 </div >
//             </div>
//         );
//     }
// }
// const styles = theme => ({
//     root: {
//         display: 'flex',
//         flexDirection: 'column',
//         justifyContent: 'flex-end',
//         paddingLeft: '10px',
//         width: '100%',
//     },
//     select: {
//         display: 'flex',
//         width: '100%',
//         fontFamily: Family.roboto,
//         fontWeight: 300,
//         fontSize: 16,
//         color: Colors.themeDark,
//     },
//     formControl: { display: 'flex', width: '100%', alignItems: 'baseline' },
//     textFormLabel: {
//         fontFamily: Family.roboto,
//         fontWeight: 300,
//         fontSize: 12,
//         backgroundColor: 'transparent',
//         color: Colors.themeDark,
//         paddingBottom: '5px',
//     },
//     textUnit: {
//         display: 'block',
//         fontFamily: Family.roboto,
//         fontSize: 12,
//         color: Colors.themeDark,
//         padding: '10px',
//     }
// });

// SelectText.propTypes = {
//     classes: PropTypes.object.isRequired,
//     theme: PropTypes.object.isRequired,
// };

// export default withStyles(styles, { withTheme: true })(SelectText);


import React, { Fragment } from 'react';
import Select from 'react-select';
import { path } from 'ramda';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import * as format from '../../common/setFormat';

import FormTypes from '../../common/FormTypes';
import { Family, Colors, Size, RequireForm } from '../../themes';

class SelectText extends React.Component {

    render() {
        return (this.renderContent());
    }

    renderContent() {
        const { classes, valueUnit, style, options, stateGroup, stateName, type, onChange, TitleText, placeholder, value, Require, error, disabled } = this.props;
        const _style = {
            borderBottom: (error) ? `${'1px solid' + Colors.error}` : null,
            borderRadius: '0px', border: `${'1px solid' + Colors.border}`,
        };

        switch (type) {
            case 'select': {
                return (
                    <Container style={style}>
                        {/* <TextLabel>
                            {(Require) ? <Fragment> {RequireForm()}{TitleText} </Fragment> : TitleText}
                        </TextLabel> */}
                        <ListItem>
                            <FormTypes.Select
                                error={error}
                                disabled={disabled}
                                TitleText={(Require) ? <Fragment> {RequireForm()}{TitleText} </Fragment> : TitleText}
                                value={value}
                                onChange={(event) => onChange(stateName, event ? event.target.value : 0, stateGroup)}
                                options={options.map((option, i) => (
                                    <option key={i} value={option.value}>
                                        {option.label || '-'}
                                    </option>
                                ))} />
                            {/* <Select
                                disabled={disabled}
                                className={classes.select}
                                style={_style}
                                error={error}
                                options={options}
                                value={value}
                                onChange={(event)=> onChange(stateName,event ? event.value : 0, stateGroup)}
                                placeholder={placeholder || ''} /> */}
                            <TextUnit>   {valueUnit} </TextUnit>
                        </ListItem >
                    </Container>
                )
            } break;
            case 'text': {
                return (
                    <Container>
                        <ListItem>
                            <FormTypes.TextFormat
                                InputFormat={format.NumberFormatCustom}
                                disabled={disabled}
                                error={error}
                                TitleText={(Require) ? <Fragment> {RequireForm()}{TitleText} </Fragment> : <Fragment> {TitleText} </Fragment>}
                                value={value}
                                onChange={(e) => onChange(stateName, e.target.value, stateGroup)}
                            />
                            <TextUnit >  {valueUnit}   </TextUnit>
                        </ListItem >
                    </Container>
                )
            } break;
        }
    }
}

const styles = theme => ({
    select: {
        display: 'flex',
        width: '100%',
        fontFamily: Family.roboto,
        fontWeight: 300,
        fontSize: Size.Small,
        color: Colors.themeDark,
    },
})

export default withStyles(styles)(SelectText);


const Container = styled.div`
    font-family:${Family.roboto};
    font-weight: 300;
    color: ${Colors.themeDark};
    font-size:${(props) => props.size ? props.size : `${Size.Smaller}`};
    width: 100%;
    ${(props) => props.isMobile && css``}`;

const TextLabel = styled.div`
    background-color: transparent;
    padding:6.5px 0px;
    ${ (props) => props.isMobile && css``} `;

const TextUnit = styled.div`
    display: flex;
    align-items: center;
    padding-left:10px;
    background-color: transparent;
    ${ (props) => props.isMobile && css``} `;

const ListItem = styled.div`
    display: flex;
    width: 100%;
    align-items: baseline
    ${ (props) => props.isMobile && css``} `;
