import React from 'react';
import Add from '../images/ic_add_white_18px.svg';
import { Colors } from '../themes';

const AddImage = ({ onClick, size }) => {
    return (
        <div
            onClick={onClick}
            style={{
                width: size,
                height: size,
                backgroundColor: Colors.defaultImage,
                marginTop: '10px'
            }}
        >
            <div
                style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >

                <img src={Add} style={{ width: '100px', height: '100px', color: Colors.white }} />
            </div>
        </div>
    );
};


export default AddImage;


