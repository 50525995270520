import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { path } from 'ramda';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Colors, Family } from '../../themes';
import { isMobile } from '../DeviceCheck';

class Checkboxes extends Component {
    render() {
        const { classes, TitleText, StateInfo, disabled, Items, handleToggle, Language, style } = this.props;
        return (
            <div className={classes.root} style={style}>
                <div className={classes.textFormLabel}> {TitleText} </div>
                <div className={classes.listItem}>
                    {
                        Array.isArray(Items) && Items.map((each, index) => {
                            return (
                                <ListItem dense button key={index} className={classes.item}
                                    disabled={disabled} onClick={() => handleToggle(each.stateName, each)}>
                                    <Checkbox color="primary" checked={path([each.stateName], StateInfo)} className={classes.checkbox} tabIndex={-1} disableRipple />
                                    <ListItemText style={{ fontSize: '12px' }} primary={`${each[Language].name || ''}`} />
                                </ListItem>
                            )
                        })
                    }

                </div>

            </div>
        )

    }
}
const styles = theme => (
    {
        root: { padding: 0, display: 'flex', flexDirection: 'column', fontFamily: Family.roboto, },
        // checkbox: {  },
        item: { display: 'flex', width: '100%', alignItems: 'center', padding: 0, marginLeft: -12 },
        listItem: { display: 'flex', flexDirection: isMobile() ? 'column' : 'row' },
        textFormLabel: { fontSize: '14px', paddingTop: '15px 0px 0px', color: Colors.themeDark, fontFamily: Family.roboto, fontWeight: 300 },
    }
)
Checkboxes.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(Checkboxes);