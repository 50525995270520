import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import HotelListPanel from '../containers/Hotel/HotelListPanel.jsx'
import HotelStatPanel from '../containers/Hotel/HotelStatPanel.jsx'

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';
import { PathName, ErrorCode, UserFormat, SupplierFormat, Roles, SetSupplierToCrate } from '../constant';

//import CreateSupplier from './CreateSupplier';
import HotelCard from '../containers/Hotel/HotelCard';
import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';

import * as SupplierActions from '../actions/Actions_SupplierManager.js';
import * as StatiticActions from '../actions/Actions_StatiticManager.js';
import * as BankActions from '../actions/Actions_Settings';

import { init_supplier } from '../constant/initState/initSupplier';
import '../App.css';

const _height = (window.innerHeight - 130);
let SupplierList = [];
let BookBankList = [];
let BestSupplierList = [];
let waitingTime = true;

class HotelManage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // error: null,
      isCreate: false, isEdit: false,
      isLoading: true, msgError: undefined, dialogType: undefined,
      orderBy: 'desc',
      // isChecked: true,
      SupplierInfo: undefined, oldSupplierInfo: null,

      // sortFilde: undefined,
    }

    this.props.getAPISupplierList({ page: 0, num_limit: 10, orderBy: 'desc' });
    this.props.getAPISummarySupplierStatus();
    this.props.getAPIBestSupplierList({ limit: 10 });
    this.props.getAPIgetBankList({ num_limit: 100 });
  }

  componentWillReceiveProps(nextProps) {
    const { SupplierManager, StatiticManager, SettingManager } = nextProps;

    if (!!SupplierManager) {

      console.log(`Hotel [componentWillReceiveProps] : `, SupplierManager);
      const { actions, data } = SupplierManager.SUPPLIER_REDUCER;
      const { isEdit, isCreate } = this.state;

      switch (actions) {
        case SupplierActions.GET_SUPPLIER_SUCCESS:
          {
            SupplierList = data.result;
            console.log(`${actions} : `, SupplierList);
            if (isEdit || isCreate)
              SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;

            SetStateWithTime(this, waitingTime);
          } break;
        case SupplierActions.GET_SUPPLIER_FAILURE:
          {
            if (isEdit || isCreate)
              SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;

            SetStateWithTime(this, waitingTime);
          } break;
      }
    }

    if (!!StatiticManager) {

      const { actions, data } = StatiticManager.STATITIC_REDUCER;
      const { isEdit, isCreate } = this.state;

      switch (actions) {

        case StatiticActions.GET_BEST_SUPPLIER_STAT_SUCCESS:
          {

            BestSupplierList = data.results.data;
            //console.log(`${actions} : `, StatiticManager, );

            // if (isEdit || isCreate)
            //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

            // SetStateWithTime(this, waitingTime);
          } break;

        case StatiticActions.GET_BEST_SUPPLIER_STAT_FAILURE:
          {
            // if (isEdit || isCreate)
            //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

            // SetStateWithTime(this, waitingTime);
          } break;
      }
    }

    if (!!SettingManager) {

      const { actions, data } = SettingManager.SETTING_REDUCER;

      switch (actions) {
        case BankActions.GET_BANKLIST_SETTING_SUCCESS:
          {
            BookBankList = data.result;
            // SettingManager.actions = BankActions.WATING_STATE;
            //console.log('BookBankList', BookBankList)
            // SetStateWithTime(this, waitingTime);
          } break;
        case BankActions.GET_BANKLIST_SETTING_FAILURE:
          {
            // SettingManager.actions = BankActions.WATING_STATE;
            //SetStateWithTime(this, waitingTime);
          } break;
      }
    }
  }


  render() {
    const { isLoading, isCreate, msgError, dialogType } = this.state;

    return (
      <div className="App">
        {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={(this.CreateSupplierPanel())} />}
        {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
        <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
          <div style={{ display: 'inline-block' }}>
            {this.contentPanel()}
          </div>
        </List>
      </div>
    );
  }

  CreateSupplierPanel = () => {
    return (
      <HotelCard
        isEdit={this.state.isEdit}
        BookBankList={BookBankList}
        SupplierInfo={this.state.SupplierInfo}
        oldSupplierInfo={this.state.oldSupplierInfo}
        onClosePanel={this.onClosePanel}
        getAPISupplierList={this.getSupplierListWithLimit} />
    )
  }

  EditSupplierInfo = () => (SupplierInfo) => {
    this.setState({
      isCreate: true,
      isEdit: true,
      SupplierInfo,
      oldSupplierInfo: SupplierInfo,
    });
  }

  DisplaySupplierInfo = () => (supplierInfo) => {
    this.props.history.push({ pathname: PathName.SupplierInfo, state: { supplierInfo }, search: '', hash: '' });
  }

  contentPanel() {
    return (
      <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
        <HotelListPanel
          CreatePanel={this.onCreatePanel(this.state.isCreate)}
          DisplaySupplierInfo={this.DisplaySupplierInfo()}
          EditInfo={this.EditSupplierInfo()}
          SupplierList={SupplierList} />
      </div>)
  }

  //========================================================================================================================================================================================================

  onCreatePanel = (_isActive) => () => { return this.setState({ isCreate: !_isActive, oldSupplierInfo: { ...init_supplier }, SupplierInfo: { ...init_supplier } }) };

  onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, error: null, SupplierInfo: undefined, isLoading: false, msgError: undefined, dialogType: undefined }));

  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

  getSupplierListWithLimit = () => {
    const { page, limit, } = SupplierList;
    return this.props.getAPISupplierList({ page: (page || 0), num_limit: (limit || 10) });
  }

  //========================================================================================================================================================================================================

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPISupplierList: (_keyword, _filter) => {
      InitWaitingTime();
      dispatch(SupplierActions.getSupplierList(_keyword, _filter));
    },
    getAPIBestSupplierList: (_filter) => {
      dispatch(StatiticActions.getBestSupplierList(_filter));
    },
    getAPISummarySupplierStatus: (_filter) => {
      dispatch(StatiticActions.getSummarySupplierStatus(_filter))
    },
    getAPIgetBankList: (_keyword) => {
      dispatch(BankActions.getBankList(_keyword));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HotelManage);
