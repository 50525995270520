import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Colors, Family } from '../themes';
import { NumberFormats } from '../common/SetNumberFormat';

// import { connect } from 'react-redux';
// import { isMobile } from '../PackageInfoPanel/GetItemColsPerWidth';

const isForeignActive = false;

class PriceDetailPanel extends Component {

    render() {

        const { foreignInfo, bookingInfo, _price, _foreignPrice, priceTotal } = this.props;
        const { adult, kid, infant, currency } = _price;

        // const { Adult, Kid, Infant } = bookingInfo; //Foreign
        // console.log('PriceDetailPanel : ', foreignInfo, ' : ', this.SumObject(foreignInfo))

        return (
            <div style={{ marginTop: '20px', borderBottom: `${'1px solid' + Colors.border}` }}>
                <div style={_style.priceItem_container}>
                    <label style={_style.label_detail}>
                        {`${bookingInfo.adult} Adult / ${NumberFormats(adult)}  ${currency}`}
                    </label>
                    <label style={_style.priceInfo_lable}>{`${NumberFormats(bookingInfo.adult * adult)} ${currency}`}</label>
                </div>
                <div style={_style.priceItem_container}>
                    <label style={_style.label_detail}>
                        {`${bookingInfo.kid} Kid / ${NumberFormats(kid)}  ${currency}`}
                    </label>
                    <label style={_style.priceInfo_lable}>
                        {`${NumberFormats(bookingInfo.kid * kid)} ${currency}`}
                    </label>
                </div>
                <div style={isForeignActive ? _style.priceItem_container : { width: '100%', height: '80px', display: 'flex', textAlign: 'left' }}>
                    <label style={_style.label_detail}>
                        {`${bookingInfo.infant} Infant / ${NumberFormats(infant)}  ${currency}`}
                    </label>
                    <label style={_style.priceInfo_lable}>
                        {`${NumberFormats(bookingInfo.infant * infant)} ${currency}`}
                    </label>
                </div>
                {isForeignActive ? this.ForeignPanel(foreignInfo, _foreignPrice, currency) : undefined}
            </div >);
    }

    ForeignPanel(foreignInfo, _foreignPrice, currency) {

        return (
            <div>
                <div style={_style.priceItem_container}>
                    <label style={_style.label_detail}>
                        {`${this.SumObject(foreignInfo)} Foreign `}
                    </label>
                </div>
                <div style={_style.priceItem_container}>
                    <label style={_style.label_detail}>
                        {`${foreignInfo.Adult} Adult / ${NumberFormats(_foreignPrice.adult)}  ${currency}`}
                    </label>
                    <label style={_style.priceInfo_lable}>
                        {`${this.CalculatePrice([this.SetPrice(_foreignPrice.adult, foreignInfo.Adult)])} ${currency}`}
                    </label>
                </div>
                <div style={_style.priceItem_container}>
                    <label style={_style.label_detail}>
                        {`${foreignInfo.Kid} Kid / ${NumberFormats(_foreignPrice.kid)}  ${currency}`}
                    </label>
                    <label style={_style.priceInfo_lable}>
                        {`${this.CalculatePrice([this.SetPrice(_foreignPrice.kid, foreignInfo.Kid)])} ${currency}`}
                    </label>
                </div>
                <div style={{ width: '100%', height: '80px', display: 'flex', textAlign: 'left' }}>
                    <label style={_style.label_detail}>
                        {`${foreignInfo.Infant} Infant / ${NumberFormats(_foreignPrice.infant)}  ${currency}`}
                    </label>
                    <label style={_style.priceInfo_lable}>
                        {`${this.CalculatePrice([this.SetPrice(_foreignPrice.infant, foreignInfo.Infant)])} ${currency}`}
                    </label>
                </div>
            </div>)
    }

    SumObject(_objValue) {
        return (Object.values(_objValue).reduce((a, b) => a + b))
    }

    CalculatePrice(_list) {

        let _price = 0;

        for (const _items of _list) {
            if (_items.amount > 0) {
                _price += (_items.price * _items.amount);
            }
        }

        return NumberFormats(_price);
    }

    SetPrice = (_price, _amount) => ({ price: _price, amount: _amount });
}

export default withRouter(PriceDetailPanel);

const _style = {
    priceInfo_lable: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        marginTop: '5px',
        textAlign: 'right',
        marginRight: '5px',
        fontFamily: Family.roboto
    },
    priceItem_container: {
        width: '100%',
        height: '50px',
        display: 'flex',
        textAlign: 'left',
        borderBottom: `${'1px solid' + Colors.borderLight}`,
        fontFamily: Family.roboto
    },
    packege_container: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
    },
    label_detail: {
        height: '31px',
        width: '100%',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        marginTop: '5px',
        marginBottom: '5px',
        fontFamily: Family.roboto
    },
    label_detailMoblie: {
        height: '31px',
        width: '100%',
        color: Colors.themeDark,
        fontSize: '16px',
        display: 'inline-grid',
        marginTop: '5px',
        marginBottom: '5px',
        fontFamily: Family.roboto
    },
};
