import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';

import DialogPanel from '../componets/DialogPanel.jsx';
import { SetDate, getFormatted, } from '../common/SetDate.js'

import * as BookingAction from '../actions/Actions_BookingManager.js';
import * as MemberActions from '../actions/Actions_MemberManager.js';
import * as AgentActions from '../actions/Actions_AgentManager.js';
import * as SupplierActions from '../actions/Actions_SupplierManager.js';
import * as SellerActions from '../actions/Actions_SellerManager.js';
import * as CustomerActions from '../actions/Actions_CustomerManager.js';
import * as PackageAction from '../actions/Actions_PackageManager.js';
import * as AccountingActions from '../actions/Actions_Accounting.js';

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Button from '../constant/Button';
import { Level } from '../constant/JSON/LevelList';
import { BookingStatus } from '../common/StatusBooking';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';
import styled from 'styled-components';
import { Colors } from '../themes/index.js';

const ActiveStatus = { Active: 'active', InActive: 'inactive' }
const StatusList = ['all'].concat(Object.values(BookingStatus));//['all', 'approved', 'cancel', 'reject', 'in-process', 'waiting'];
const ActiveList = ['all'].concat(Object.values(ActiveStatus));

const RoleList = ['', 'admin', 'supplier', 'agent', 'seller', 'customer'];
const DateTypeList = ['Booking Date', 'Traval Date'];

const SearchType = {
    supplier: 'supplier',
    agent: 'agent',
    seller: 'seller',
    customer: 'customer',
    staff: 'staff',
    package: 'package',
    history: 'history',
    booking: 'booking'
}

let placeholder = '';
let waitingTime = true;
const _filter = { key: '', startDate: undefined, endDate: undefined, bookingStatus: undefined, isBookingDate: true, Active: undefined }

class SearchPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false, isSearch: false, btnDisable: true }

    constructor(props) {

        super(props);

        // console.log('BookingStatus : ', Object.values(BookingStatus))

    }

    componentWillReceiveProps(nextProps) {

        const { BookingManager, SupplierManager, AgentManager, SellerManager, CustomerManager, MemberManager, PackageManager } = nextProps;

        if (!!PackageManager) {

            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!BookingManager) {

            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingAction.GET_BOOKING_LISTS_SUCCESS:
                    {
                        // console.log('BookingManager : ', actions)  //#checknew
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SupplierManager) {

            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!AgentManager) {

            const { actions, data } = AgentManager.AGENT_REDUCER;

            switch (actions) {
                case AgentActions.GET_AGENT_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SellerManager) {

            const { actions, data } = SellerManager.SELLER_REDUCER;

            switch (actions) {
                case SellerActions.GET_SELLER_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!CustomerManager) {

            const { actions, data } = CustomerManager.CUSTOMER_REDUCER;

            // console.log('CustomerManager : ', actions)//#checknew

            switch (actions) {
                case CustomerActions.GET_CUSTOMER_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CustomerActions.WATING_STATE:
                    {

                    } break;
            }
        }

        if (!!MemberManager) {

            const { actions, data } = MemberManager.MEMBER_REDUCER;

            switch (actions) {
                case MemberActions.GET_MEMBER_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    }
            }
        }

    }

    componentWillUnmount() {

        _filter.key = '';
        _filter.startDate = undefined;
        _filter.endDate = undefined;
        _filter.bookingStatus = undefined;
    }

    render() {

        const { classes, searchActive, SearchType, isAccounting } = this.props;
        const { isLoading, msgError, dialogType, orderBy, btnDisable } = this.state;
        const _isBooking = (SearchType === 'booking');

        this.CheckSearchType(SearchType || '');

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Collapse in={searchActive} timeout="auto" unmountOnExit>
                    <div style={{ width: '100%', background: 'rgba(184,24,,0.10)', padding: '20px 10px', display: 'flex' }}>
                        <div style={{ flex: 1 }}>
                            <TextField
                                id="Search"
                                label="Search"
                                type="search"
                                placeholder={placeholder}
                                className={classes.textField}
                                style={(_isBooking) ? {} : { width: '100%' }}
                                InputLabelProps={{ shrink: true, }}
                                value={undefined}
                                onChange={(event) => { _filter.key = event.target.value; if (!Boolean(_filter.key)) this.SetDefualtInfo(SearchType, Boolean(isAccounting)); this.ValidateKey() }}
                                onKeyPress={(event) => { if (event.key === 'Enter' && !btnDisable) this.handleRequestSearch(_filter, SearchType) }}
                                margin="normal" />
                        </div>
                        {(_isBooking) ? this.DataPickerPanel(classes) : this.SelectPanel(SearchType)}
                        <div style={{ flex: 0.4, margin: _isBooking ? '27px 0px 0px 0px' : '30px 0px 0px 0px' }}>
                            <Button.Themes disabled={btnDisable} TitleText={`Search`} buttonStyle={{ margin: 0 }}
                                style={{ paddingLeft: '10px', width: '100%' }}
                                // className={btnDisable ? classes.btnInActive : classes.btnActive}  
                                onClick={() => this.handleRequestSearch(_filter, (SearchType || ''))} />
                        </div>
                    </div>
                </Collapse>
            </React.Fragment>
        );
    }

    CheckDataPicker() {

        const { btnDisable } = this.state;
        (btnDisable) ? this.setState({ btnDisable: false }) : this.forceUpdate();
    }

    ValidateKey() {

        const { key, startDate, endDate } = _filter;
        const { btnDisable } = this.state;

        if ((key.length > 0) && btnDisable) {
            this.setState({ btnDisable: false });
        }
        else if (((key.length <= 0) && !btnDisable) && (!(!!startDate || !!endDate))) {
            this.setState({ btnDisable: true });
        }
    }

    handleChange = (name) => (event) => {

        // console.log('handleChange : ', name, ' : ', event.target.value); //#checknew

        const _value = event.target.value;
        _filter.bookingStatus = _value;

        this.setState({ btnDisable: false });

        //    let _value=event.target.value;
        //    this.setState({ [name]: _value,btnActive:Boolean(_value) });
    };

    handleActiveChange = (name) => (event) => {

        const _value = event.target.value;

        // console.log('handleActiveChange : ', name, ' : ', _value); //#checknew

        switch (_value) {
            case ActiveStatus.Active: { _filter.Active = true; } break;
            case ActiveStatus.InActive: { _filter.Active = false; } break;
            default: { _filter.Active = undefined; } break;
        }

        this.setState({ btnDisable: false });
    };

    SelectPanel(searchType) {

        // console.log('SelectPanel : ', searchType, ' : ', SearchType.package);

        const selectStyle = { background: Colors.white };
        const FormControlStyle = { width: '100%', };

        return (
            <React.Fragment>
                {(searchType !== SearchType.package) &&
                    <SelectItemsContianer>
                        <FormControl style={FormControlStyle}>
                            <InputLabel htmlFor="age-native-simple">Level</InputLabel>
                            <Select native
                                // value={this.state.age}
                                // onChange={this.handleChange('status')}
                                style={selectStyle}
                                inputProps={{ id: 'age-native-simple', }}>
                                {Level.map(item => item.name).map((status) => { return <option key={status} value={status}>{status}</option> })}
                            </Select>
                        </FormControl>
                    </SelectItemsContianer>}
                <SelectItemsContianer>
                    <FormControl style={FormControlStyle}>
                        <InputLabel htmlFor="age-native-simple">Status</InputLabel>
                        <Select native
                            // value={this.state.age}
                            onChange={this.handleActiveChange('status')}
                            style={selectStyle}
                            inputProps={{ id: 'age-native-simple', }}>
                            {ActiveList.map((status) => { return <option key={status} value={status}>{status}</option> })}
                        </Select>
                    </FormControl>
                </SelectItemsContianer>
            </React.Fragment>
        )
    }

    DataPickerPanel(classes) {

        return (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ flex: 0.3, margin: '10px 5px 0px 5px', }}>
                    <FormControl style={{ width: '100%' }} >
                        <InputLabel htmlFor="age-native-simple">Date Type</InputLabel>
                        <Select native
                            onChange={(event) => { console.log('Date Type : ', event.target.value); _filter.isBookingDate = (event.target.value === 'Booking Date'); }}
                            style={{ background: Colors.white, height: '38px', padding: '1px 0px 0px 5px', fontSize: 15 }}
                            inputProps={{ id: 'age-native-simple', }}>
                            {DateTypeList.map((status) => { return <option key={status} value={status}>{status}</option> })}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ flex: 0.3, margin: '6px 5px 0px 0px', position: 'relative', zIndex: 1, }}>
                    <React.Fragment>
                        <label >{"Start Date"}</label>
                        <DatePicker
                            selected={_filter.startDate && new Date(_filter.startDate)}
                            selectsStart
                            disabled={false}
                            className={classes.dateInput}
                            dateFormat="dd/MM/yyyy"
                            onChange={(value) => { _filter.startDate = Boolean(value) ? this.SetDateFormat(value) : undefined; this.CheckDataPicker(); }} />
                    </React.Fragment>
                </div>
                <div style={{ flex: 0.3, margin: '6px 5px 0px 0px', position: 'relative', zIndex: 1, }}>
                    <React.Fragment>
                        <label >{"End Date"}</label>
                        <DatePicker
                            selected={_filter.endDate && new Date(_filter.endDate)}
                            selectsEnd
                            disabled={false}
                            className={classes.dateInput}
                            dateFormat="dd/MM/yyyy"
                            onChange={(value) => { _filter.endDate = Boolean(value) ? this.SetDateFormat(value) : undefined; this.CheckDataPicker(); }}
                        />
                    </React.Fragment>
                </div>
                <div style={{ flex: 0.3, margin: '10px 0px 0px 0px', }}>
                    <FormControl style={{ width: '100%' }} >
                        <InputLabel htmlFor="age-native-simple">Status</InputLabel>
                        <Select native
                            onChange={this.handleChange('status')}
                            style={{ background: Colors.white, height: '38px', padding: '1px 0px 0px 5px', fontSize: 15 }}
                            inputProps={{ id: 'age-native-simple', }}>
                            {StatusList.map((status) => { return <option key={status} value={status}>{status}</option> })}
                            {/* {StatusList.filter(_status => { return _status !== bookingStatus }).map((status) => { return <option key={status} value={status}>{status}</option> })} */}
                        </Select>
                    </FormControl>
                </div>
            </div>
        )
    }

    //===============================================================================================================================================================

    handleKeyChange = (event) => {

        if (event.key === 'Enter' || event.keyCode === 13 || event.keyCode === 0)
            console.log('handleKeyChange : ', event.key, ' :')

        //this.handleRequestSearch(_filter, (SearchType || ''))
    }

    //===============================================================================================================================================================
    //const _bookingFilter = {paymentMethod: 'Alienation', limit: 8, bookingStatus: 'in-process,waiting,cancel,approved,reject' };//old true

    handleRequestSearch = (sortFilde, searchType) => {

        // console.log('handleRequestSearch : ', searchType, ' : ', sortFilde.key) //#checknew

        const { key } = sortFilde;

        this.setState({ isLoading: true, isSearch: true });

        switch (searchType) {
            case SearchType.supplier:
                {
                    const filter = {};
                    filter.where = { supplierId: key, name: key, email: key, telOffice: key }
                    this.props.getAPISupplierList(filter)
                } break;

            case SearchType.agent:
                {
                    const filter = {};
                    filter.where = { agentId: key, name: key, email: key, telOffice: key }

                    this.props.getAPIAgentList(filter)
                } break;

            case SearchType.seller:
                {
                    const filter = {};
                    filter.where = { sellerId: key, name: key, email: key, telOffice: key }

                    this.props.getAPISellerList(filter)
                } break;

            case SearchType.customer:
                {
                    const filter = {};
                    filter.where = (sortFilde.Active === undefined) ? { fullName: key, email: key, tel: key } : { fullName: key, email: key, tel: key, active: sortFilde.Active };
                    filter.dataAndSetting = ['active'];
                    // console.log('customer : ', filter, ' : ', sortFilde.Active) //#checknew

                    this.props.getAPICustomerList(filter)
                } break;

            case SearchType.staff:
                {
                    const filter = {};
                    filter.where = { adminId: key, fullName: key, email: key, tel: key }
                    this.props.getAPIMemberList(filter)
                } break;

            case SearchType.package:
                {
                    console.log('package : ', sortFilde)
                    // ({ isPromotion: true, limit: 5,visible: true  })//packageId: key,
                    const filter = (sortFilde.Active === undefined) ? { packageName: key } : { packageName: key, visible: sortFilde.Active };
                    this.props.getAPIpackageInfoList(filter);
                } break;

            case SearchType.history:
                {
                    console.log('history')
                    const filter = { key: 'createDate', orderBy: 'desc', num_limit: 5 };
                    filter.where = { userAssigneeName: key, }
                    this.props.getAPIAccoutingHistoryList(filter);
                } break;

            case SearchType.booking:
            default:
                {
                    const { startDate, endDate, bookingStatus, isBookingDate } = _filter;
                    const { userInfo } = this.props;

                    const filter = {
                        bookingId: key, bookingNo: key, name: key, title: key, firstName: key, lastName: key,
                        amount: key, tel: key, ...this.GetBetween(startDate, endDate, isBookingDate),
                        bookingStatus: (bookingStatus === 'all' ? '' : (bookingStatus || '')),
                    }

                    if (!!userInfo)
                        filter.affiliateId = userInfo.sellerId;

                    // console.log('filter : ', filter, 'isBookingDate : ', Boolean(isBookingDate)); //#checknew

                    this.props.getAPIBookingLists('', filter);// firstName: key,  paymentMethod: 'Alienation',
                } break;
        }
    };

    GetBetween(startDate, endDate, isBookingDate) {

        // console.log('GetBetween : ', isBookingDate); //#checknew
        switch (true) {

            case (!!startDate && !!endDate):
                {
                    const _newDate = new Date(endDate);
                    _newDate.setDate(_newDate.getDate() + 1);

                    return (isBookingDate) ? { startDate: startDate, endDate: _newDate } : { startTDate: startDate, endTDate: _newDate };
                }
            case (!!startDate && endDate === undefined):
                {
                    const _newDate = new Date();
                    _newDate.setDate(_newDate.getDate() + 1);

                    return (isBookingDate) ? { startDate: startDate, endDate: _newDate } : { startTDate: startDate, endTDate: _newDate };
                }
            case (startDate === undefined && !!endDate):
                {
                    const _newDate = this.SetDateFormat(new Date);
                    return (isBookingDate) ? { startDate: _newDate, endDate: endDate } : { startTDate: _newDate, endTDate: endDate };
                }
            default:
                {
                    return undefined;
                }
        }
    }

    SetDateFormat(_value) {

        const _selectDate = new Date(_value);
        return `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate())}`;
    }

    SetDefualtInfo = (searchType = '', isAccounting) => {

        console.log('SetDefualtInfo : ', searchType, ' : ', isAccounting);
        const { isSearch } = this.state;

        if (isSearch) {
            switch (searchType) {
                case SearchType.supplier:
                    {
                        const _filter = ((isAccounting) ?
                            { key: 'wallet', orderBy: 'desc', page: 0, num_limit: 5, } :
                            { page: 0, num_limit: 10, orderBy: 'desc' });

                        this.props.getAPISupplierList(_filter);
                    } break;

                case SearchType.agent:
                    {
                        const _filter = ((isAccounting) ?
                            { key: 'wallet', orderBy: 'desc', page: 0, num_limit: 5, } :
                            { page: 0, num_limit: 10, });

                        this.props.getAPIAgentList(_filter);
                    } break;

                case SearchType.seller:
                    {
                        const _filter = ((isAccounting) ?
                            { key: 'wallet', orderBy: 'desc', page: 0, num_limit: 5, } :
                            { page: 0, num_limit: 10, orderBy: 'desc' });

                        this.props.getAPISellerList(_filter);
                    } break;

                case SearchType.customer:
                    {
                        const _filter = ((isAccounting) ?
                            { key: 'wallet', orderBy: 'desc', page: 0, num_limit: 5, } :
                            { page: 0, num_limit: 25 });

                        this.props.getAPICustomerList(_filter);
                    } break;

                case SearchType.staff:
                    {
                        const _filter = ((isAccounting) ?
                            { key: 'wallet', orderBy: 'desc', page: 0, num_limit: 5, } :
                            { page: 0, num_limit: 10 });

                        this.props.getAPIMemberList(_filter);
                    } break;

                case SearchType.package:
                    {
                        this.props.getAPIpackageInfoList();
                    } break;

                case SearchType.history:
                    {
                        this.props.getAPIAccoutingHistoryList({ key: 'createDate', orderBy: 'desc', num_limit: 5 });
                    } break;

                case SearchType.booking:
                default:
                    {
                        // this.setState({ isSearch: false, isLoading: true }, () => this.props.getAPIBookingLists());
                        const { userInfo } = this.props;
                        if (!!userInfo)
                            this.props.getAPIBookingLists('', { affiliateId: userInfo.sellerId });
                        else
                            this.props.getAPIBookingLists();
                    } break;
            }
        }
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    CheckSearchType = (searchType = '') => {

        switch (searchType) {
            case SearchType.supplier:
                {
                    placeholder = `Suppiler Id / Suppiler Name / Email  / Tel Number`;
                } break;

            case SearchType.agent:
                {
                    placeholder = `Agent Id / Agent Name / Email  / Tel Number`;
                } break;

            case SearchType.seller:
                {
                    placeholder = `Seller Id / Seller Name / Email / Tel Number`;
                } break;

            case SearchType.customer:
                {
                    placeholder = `Customer Name / Email / Phone Number`;
                } break;

            case SearchType.staff:
                {
                    placeholder = `Member Id / Member Name / Email`;
                } break;

            case SearchType.package:
                {
                    placeholder = `Package Name `;
                } break;

            case SearchType.history:
                {
                    placeholder = `Reciver Name`;
                } break;

            case SearchType.booking:
            default:
                {
                    placeholder = `Booking Id / Package Name / Buyer Name`;

                } break;
        }
    }

    //===============================================================================================================================================================
}

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        textField: { width: 400, },
        dateInput: {
            width: 100,
            marginTop: 5,
            padding: 10,
            marginBottom: 10,
            // paddingRight: '49px',
            color: Colors.grey,
            borderRadius: 0,
            border: '1px solid #979797',
            fontSize: 14,
        },
    });

SearchPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingLists: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        },
        getAPIMemberList: (_filter) => {
            InitWaitingTime();
            dispatch(MemberActions.getMemberList(_filter));
        },
        getAPIAgentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AgentActions.getAgentList(_keyword, _filter));
        },
        getAPISupplierList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        getAPICustomerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CustomerActions.getCustomerList(_keyword, _filter));
        },
        getAPIpackageInfoList: (_fliter) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_fliter));
        },
        getAPIAccoutingHistoryList: (_filter) => {
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SearchPanel));


const SelectItemsContianer = styled.div`
      flex: 0.5 1 0%;
      margin: 20px 10px 0px 25px;`;