export const constraints = {
    name: {
        presence: {
            allowEmpty: false,
        },
    },
    type: {
        presence: {
            allowEmpty: false,
        },
    },
    desShort: {
        presence: {
            allowEmpty: false,
        },
    },
    desFull: {
        presence: {
            allowEmpty: false,
        },
    },
    limit: {
        presence: {
            allowEmpty: false,
        },
    },
    locationServiceId: {
        presence: {
            allowEmpty: false,
        },
    },
    passengers: {
        presence: {
            allowEmpty: false,
        },
    },
    bags: {
        presence: {
            allowEmpty: false,
        },
    },
    conveyanceType: {
        presence: {
            allowEmpty: false,
        },
    },
    transferIn: {
        presence: {
            allowEmpty: false,
        },
    },
    transferType: {
        presence: {
            allowEmpty: false,
        },
    },
    start: {
        presence: {
            allowEmpty: false,
        },
    },
    serviceType: {
        presence: {
            allowEmpty: false,
        },
    },
    cutOffTime: {
        presence: {
            allowEmpty: false,
        },
    },
    pickUpList: {
        presence: {
            allowEmpty: false,
        },
    },
    dropOffList: {
        presence: {
            allowEmpty: false,
        },
    },
    unit: {
        presence: {
            allowEmpty: false,
        },
    },

};

export const input = [
    { fieldName: 'name' },
    { fieldName: 'type' },
    { fieldName: 'desShort' },
    { fieldName: 'desFull' },
    { fieldName: 'locationServiceId' },
    { fieldName: 'start' },
    { fieldName: 'unit' },
    { fieldName: 'limit' },
    { fieldName: 'cutOffTime' },
];

export const inputTransfer = [
    ...input,
    { fieldName: 'passengers' },
    { fieldName: 'bags' },
    { fieldName: 'conveyanceType' },
    { fieldName: 'transferIn' },
    { fieldName: 'transferType' },
];

export const inputFerries = [
    ...input,
    { fieldName: 'pickUpList' },
    { fieldName: 'dropOffList' },
];

export const inputService = [
    ...input,
    { fieldName: 'limit' },
    { fieldName: 'serviceType' },
];

