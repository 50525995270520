import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_ACTIVITY_SUCCESS = 'GET_ACTIVITY_SUCCESS';
export const GET_ACTIVITY_FAILURE = 'GET_ACTIVITY_FAILURE';

export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

// getActivityList ============================================================================================================================

export function getActivityList(_keyword = '', _fileterjson = {}) {

    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getActivities}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getActivityList : ", response);
            localStorage.setItem('ActivityList', response.data.result.datas)
            dispatch(getActivityList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getActivityList Failure", error);
            dispatch(getActivityList_Failure(error));
        })
    }
}

function getActivityList_Success(data) {
    return { type: GET_ACTIVITY_SUCCESS, payload: data };
}

function getActivityList_Failure(error) {
    return { type: GET_ACTIVITY_FAILURE, payload: error };
}

// getActivityList =========================================================================================================================


export function APICreateActivity(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createActivity}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APICreateActivity : ", response.data);
            dispatch(APICreateActivity_Success(response.data));

        }).catch((error) => {
            console.log("APICreateActivity fail", error);
            dispatch(APICreateActivity_Failure(error));
        })
    }
}

function APICreateActivity_Success(data) {
    return { type: CREATE_ACTIVITY_SUCCESS, payload: data };
}

function APICreateActivity_Failure(error) {
    return { type: CREATE_ACTIVITY_FAILURE, payload: error };
}

// updateActivityList =========================================================================================================================

export function APIUpdateActivity(confirmInfo) {
    console.log('APIUpdateActivity : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateActivity}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { activityId: confirmInfo.activityId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIUpdateActivity : ", response.data);
            dispatch(APIUpdateActivity_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateActivity fail", error);
            dispatch(APIUpdateActivity_Failure(error));
        })
    }
}

function APIUpdateActivity_Success(data) {
    return { type: UPDATE_ACTIVITY_SUCCESS, payload: data };
}

function APIUpdateActivity_Failure(error) {
    return { type: UPDATE_ACTIVITY_FAILURE, payload: error };
}