import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import { Colors, Family } from '../../themes';

class Switches extends Component {
    render() {
        const { classes, disabled, ValueText, TitleText, checked, style, onChange, styleValue, Left, Right } = this.props;
        return (
            <div className={classes.textField} >
                <div className={classes.textDefalut}
                    style={{ paddingLeft: '15px', ...style }}>{TitleText}</div>
                {
                    Left && <label className={classes.textDefalut} style={{ ...styleValue }}>{ValueText}</label>
                }
                <Switch color="primary"
                    disabled={disabled}
                    checked={checked}
                    onChange={onChange}
                />
                {
                    Right && <label className={classes.textDefalut} style={{ ...styleValue, }}>{ValueText}</label>
                }
            </div>
        )
    }
}
const styles = theme => (
    {
        textDefalut: { fontSize: 14, color: Colors.themeDark, fontWeight: 300, },
        textField: { width: '100%', lineHeight: 0, fontFamily: Family.roboto },
    }
)
Switches.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(Switches);