
import * as BookingAction from '../actions/Actions_BookingManager';

const INITIAL_STATE = { BOOKING_REDUCER: { actions: BookingAction.INITIAL_STATE, data: null, error: null }, };
const ACTION = { type: BookingAction.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case BookingAction.INITIAL_STATE:
            {
                return state;
            }
        case BookingAction.IDLE_STATE:
            {
                //console.log("[Reducer Booking ] : IDLE_STATE : ",payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.IDLE_STATE, data: payload, error: _error } };
            }
        case BookingAction.GET_BOOKING_LISTS_SUCCESS:
            {
                //console.log("[Reducer Booking ] : GET_BOOKING_LISTS_SUCCESS : ",payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_BOOKING_LISTS_SUCCESS, data: payload, error: _error } };
            }
        case BookingAction.GET_BOOKING_LISTS_FAILURE:
            {
                console.log("[Reducer Booking ] : GET_BOOKING_LISTS_FAILURE : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_BOOKING_LISTS_FAILURE, data: payload, error: _error } };
            }
        case BookingAction.GET_LB_BOOKING_LISTS_SUCCESS:
            {
                //console.log("[Reducer Booking ] : GET_LB_BOOKING_LISTS_SUCCESS : ",payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_LB_BOOKING_LISTS_SUCCESS, data: payload, error: _error } };
            }
        case BookingAction.GET_LB_BOOKING_LISTS_FAILURE:
            {
                //console.log("[Reducer Booking ] : GET_LB_BOOKING_LISTS_FAILURE : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_LB_BOOKING_LISTS_FAILURE, data: payload, error: _error } };
            }
        case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
            {
                console.log("[Reducer Booking ] : GET_BOOKING_DETAIL_SUCCESS : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_BOOKING_DETAIL_SUCCESS, data: payload, error: _error } };
            }
        case BookingAction.GET_BOOKING_DETAIL_FAILURE:
            {
                console.log("[Reducer Booking ] : GET_BOOKING_DETAIL_FAILURE : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_BOOKING_DETAIL_FAILURE, data: payload, error: _error } };
            }
        case BookingAction.SET_BOOKING_STATUS_FAILURE:
            {
                console.log("[Reducer Booking ] : SET_BOOKING_STATUS_FAILURE : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.SET_BOOKING_STATUS_FAILURE, data: payload, error: _error } };
            }
        case BookingAction.SET_BOOKING_STATUS_SUCCESS:
            {
                console.log("[Reducer Booking ] : SET_BOOKING_STATUS_SUCCESS : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.SET_BOOKING_STATUS_SUCCESS, data: payload, error: _error } };
            }
        case BookingAction.GET_BOOKING_WITHPACKAGE_SUCCESS:
            {
                console.log("[Reducer Booking ] : GET_BOOKING_WITHPACKAGE_SUCCESS : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_BOOKING_WITHPACKAGE_SUCCESS, data: payload, error: _error } };
            }
        case BookingAction.GET_BOOKING_WITHPACKAGE_FAILURE:
            {
                console.log("[Reducer Booking ] : GET_BOOKING_WITHPACKAGE_FAILURE : ", payload);
                return { ...state, BOOKING_REDUCER: { actions: BookingAction.GET_BOOKING_WITHPACKAGE_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[Reducer Booking ]  default :", state);
                return state;
            }
    }
}
