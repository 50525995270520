import moment from 'moment';

export const init_promotion = {
    "packageId": "",
    "name": "",
    "description": "",
    "adult": 0,
    "type": "",
    "kid": 0,
    "infant": 0,
    "currency": "THB",
    "limit": 0,
    "startDate": "",
    "endDate": "",
    "visible": false,
    "expDate": false,
    "bookingDate": {
        "startDate": moment(),
        "endDate": "",
    },
    "travelDate": {
        "startDate": moment(),
        "endDate": "",
    },
    "percent_adult": 0,
    "percent_infant": 0,
    "percent_kid": 0
}