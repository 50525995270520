import React, { Component, } from 'react';
import { withRouter } from 'react-router';

import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

import { SetDate } from '../../common/SetDate';
import { Metrics, Colors, Family } from "../../themes";
import { BookingStatus } from '../../common/StatusBooking';
import { isMobile, isTablet } from '../../common/DeviceCheck';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import RefundCard from '../Refund/RefundCard';

const BOOKING_STATUS = { WAITING: 'waiting', INPROCESS: 'in-process', APPROVED: 'approved', CANCEL: 'cancel', REJECT: 'reject' };
const StatusList = ['', 'approved', 'abandon', 'cancel', 'reject', 'in-process', 'waiting'];
var _remark;
let userInfo = JSON.parse(localStorage.getItem('UserInfo'));

class ConfirmPanel extends Component {

    state = { open: false, status: '', remark: '', btnActive: false, btnRefund: true };

    constructor(props) {
        userInfo = JSON.parse(localStorage.getItem('UserInfo'));
        super(props);
    }

    render() {

        const { bookingStatus } = this.props;

        // const {currency,discount,net,serviceChargeAndVat,subTotal}=priceTotal;
        // console.log('ConfirmPanel : ',bookingStatus);  
        // const stylePanel = isMobile()?{padding:'20px 0px 20px 20px',width:'95%'}:{padding:'20px 20px 20px 50px',width:'50%',textAlign:'left'};

        return (bookingStatus.match(/(in-process|approved|cancel|on-delivery|complete)/i)) ?
            (this.bookingStatusPanel(bookingStatus)) : <div />
    }

    bookingStatusPanel(bookingStatus) {

        const { bookingId } = this.props;
        const { isLoading, msgError, dialogType } = this.state;

        switch (bookingStatus) {
            case BookingStatus.APPROVED:
            case BookingStatus.ONDELIVERY:
            case BookingStatus.COMPLETE:
                {
                    const _isActive = this.state.btnRefund;

                    return (
                        <React.Fragment>
                            {(!_isActive) && <DialogAction Active={!_isActive} OnClose={this.onClosePanel} AcrionCom={this.refundPanel(bookingId)} />}
                            <Button disabled={!_isActive} style={(_isActive) ? style.btn_enable : style.btn_diable} onClick={this.handleRefund()} >
                                REFUND
                            </Button>
                        </React.Fragment>
                    );
                }
            default:
                {
                    const _isActive = this.state.btnActive;

                    return (
                        <div style={style.container}>
                            <div style={{ width: '100%', display: 'flex' }}>
                                <div style={{ padding: '20px 20px 20px 50px', height: 100, textAlign: 'left', flex: 1 }}>
                                    <div style={{ marginBottom: 10 }}>
                                        <label style={style.label_detail}>{`Status of Payment`}</label>
                                    </div>
                                    <div style={{ width: '100%', }}>
                                        <FormControl style={{ minWidth: '90%', }}>
                                            <InputLabel htmlFor="age-native-simple">Booking Status</InputLabel>
                                            <Select native
                                                value={this.state.age}
                                                onChange={this.handleChange('status')}
                                                inputProps={{ id: 'age-native-simple', }}>
                                                {StatusList.filter(_status => { return _status !== bookingStatus }).map((status) => { return <option key={status} value={status}>{status}</option> })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ padding: '20px 20px 20px 50px', minHeight: 100, textAlign: 'left', flex: 1, }}>
                                        <label style={style.label_detail}>{`Remark Confirm`}</label>
                                        <TextField id="multiline-flexible"
                                            label="Remark"
                                            multiline
                                            rowsMax="4"
                                            value={this.state.multiline}
                                            onChange={this.handleTextChange('remark')}
                                            style={{ width: '100%', }}
                                            margin="normal" />
                                    </div>
                                </div>
                            </div>
                            <Button disabled={!_isActive} style={(_isActive) ? style.btn_enable : style.btn_diable} onClick={this.handleConfirm(bookingId)} >CONFIRM</Button>
                        </div>
                    )
                }
        }

    }
    refundPanel = (bookingId) => {
        const { paymentInfo, confirmRefund } = this.props;
        return (
            <RefundCard
                confirmRefund={confirmRefund}
                paymentInfo={paymentInfo}
                bookingId={bookingId}
                onClosePanel={this.onClosePanel} />
        )
    }

    handleConfirm = (bookingId) => (event) => {

        const _info = { bookingId: bookingId, bookingStatus: this.state.status, remark: (_remark || '') }
        this.props.confirmHandleChange(_info);
        this.setState({ btnActive: false });

        console.log('handleCofirm : ', bookingId, ' : ', _info);
    }

    handleRefund = () => () => {
        this.setState({ btnRefund: false });
    }

    handleChange = name => event => {
        console.log('handleChange : ', name, ' : ', event.target.value);

        let _value = event.target.value;
        this.setState({ [name]: _value, btnActive: Boolean(_value) });
    };

    handleTextChange = name => event => {
        _remark = event.target.value;
        console.log('handleChange : ', name, ' : ', _remark);

        //  let _value=event.target.value;
        //  this.setState({ [name]: _value,btnActive:Boolean(_value) });
    };

    handleClose = () => {
        // this.setState({ open: false });
    };

    handleOpen = () => {
        // this.setState({ open: true });
    };

    onClosePanel = () => { this.setState({ btnRefund: true }); };
    onCloseDialog = () => () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

export default withRouter(ConfirmPanel);

const style =
{
    container:
    {
        backgroundColor:Colors.themeDark_Light,
        width: '100%',
        height: '100%',
        display: 'grid',
        borderTop: `${'3px solid' + Colors.theme}`,
        marginBottom: 50
    },
    btn_enable:
    {
        backgroundColor: Colors.theme,
        color: Colors.white,
        borderRadius: '0px',
        width: '100%',
        height: '44px'
    },
    btn_diable:
    {
        backgroundColor: Colors.disabled,
        color: Colors.white,
        borderRadius: '0px',
        width: '100%',
        height: '44px'
    },
    priceInfo_lable:
    {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'right',
        //   marginTop: '5px',
        //   marginRight: '5px',
    },
    priceItem_container:
    {
        width: '100%',
        height: '50px',
        display: 'flex',
        textAlign: 'left',
        lineHeight: '40px',
        borderBottom: `${'1px solid' + Colors.borderLight}`,
        fontFamily: Family.roboto
    },
    label_detail:
    {
        height: '31px',
        width: '100%',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        fontFamily: Family.roboto
        //   marginTop: '5px',
        //   marginBottom: '5px',
    },
};