import React, { Component } from 'react'
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import Language from '../../constant/Language';

import DialogPanel from '../../componets/DialogPanel.jsx';
import EmptyRows from '../../componets/EmptyRow.jsx';

import * as SettingActions from '../../actions/Actions_Settings';

import {  Family, Colors, Icons } from '../../themes';

import CONFIG from '../../config';

const { BorderColorIcon } = Icons
const fildeList = [
    { label: 'Level Name', name: 'levelName', isOrder: true, },
    { label: 'Price Type', name: 'priceType', isOrder: true, },
    { label: 'ADULT', name: 'adult', isOrder: false, },
    { label: 'KID', name: 'kid', isOrder: false, },
    { label: 'INFANT', name: 'infant', isOrder: false, },
    { label: 'ACTIVED', name: 'actived', isOrder: false, },
    { label: '', name: '', isOrder: false, }];
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100 };
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _wording = { header: 'Default Commission Manage' }

let waitingTime = true;
let listLevel = [];

class LevelListPanel extends Component {
    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }
    constructor(props) {
        super(props);

        this.props.getDefaultCommissions({ num_limit: 25 });
    }
    componentWillReceiveProps(nextProps) {
        // console.log('[ componentWillReceiveProps(nextProps) : ]', nextProps)
        const { SettingManager } = nextProps;
        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        listLevel = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }
    render() {
        const { classes, CreatePanel, EditInfo, ViewInfo } = this.props;
        const { isLoading, msgError, dialogType } = this.state;

        const is_levelList = (listLevel.length > 0);

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px', width: '100%' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(is_levelList) ? listLevel.map(info => {
                                const { defaultCommissionId, levelName, priceType, adult, kid, infant, actived } = info;

                                return (
                                    <TableRow hover style={{ cursor: 'pointer', width: '100%' }} key={defaultCommissionId} >
                                        <TableCell style={_callName} onClick={() => { ViewInfo(info) }} >{levelName || '-'}</TableCell>
                                        <TableCell style={_callName} onClick={() => { ViewInfo(info) }} >{priceType || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { ViewInfo(info) }} >{priceType === 'percent' ? `${adult} %` : adult || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { ViewInfo(info) }} >{priceType === 'percent' ? `${kid} %` : kid || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { ViewInfo(info) }} >{priceType === 'percent' ? `${infant} %` : infant || '-'}</TableCell>
                                        <TableCell style={{ ..._callStyle, ...{ color: (actived) ? Colors.success : Colors.error } }} onClick={() => { ViewInfo(info) }} >{actived ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell style={_callStyle}><Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(info) }} /></TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(is_levelList) && GetPagination(this.props.LevelList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        )
    }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        console.log('handleRequestSort : ', _fildeName, listLevel)
        listLevel = (orderBy === 'desc')
            ? listLevel.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : listLevel.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        this.props.getDefaultCommissions({ page: 0, num_limit: _limitRows, });
    }

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });
        this.props.getDefaultCommissions({ page: _pageNumber, num_limit: _limit });
    }

    // onChangeLanguage = (_language) => {
    //     this.setState({ isLoading: true });
    //     this.props.getAPILocationServiceList({ page: 0, num_limit: 10, language: _language });
    // }
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, pageSize, language } = _ListCount;
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} /> */}
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />
        </div>
    )

}

const styles = theme => (
    {
        root: { width: 1024, marginTop: theme.spacing.unit * 3, overflowX: 'auto', marginBottom: 10, },
        table: { minWidth: 1000, },
        });

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}

LevelListPanel.propTypes = { classes: PropTypes.object.isRequired, };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LevelListPanel));
