import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import { Styles, Colors } from '../../themes';

import DialogPanel from '../../componets/DialogPanel.jsx';
import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';
import * as RefundActions from '../../actions/Actions_RefundManager';
import * as BookingAction from '../../actions/Actions_BookingManager.js';
import * as CustomerAction from '../../actions/Actions_CustomerManager.js';
import * as AppointmentActions from '../../actions/Actions_Appointment.js';

// import {SetDate} from '../../common/SetDate';
// import {isMobile,isTablet} from '../../common/DeviceCheck';
// import List, { ListItem, ListItemText } from '@material-ui/core/List';
// import TotalPanel from './TotalPanel';
// import BuyerInfoPanel from './BuyerInfoPanel';
// import PriceDetailPanel from '../../componets/PriceDetailPanel.jsx';

import GalleryPanel from './GalleryPanel';
import ConfirmPanel from './ConfirmPanel';
import PackageInfoPanel from './PackageInfoPanel';
import PaymetnInfoPanel from './PaymetnInfoPanel';
import BookingInfoPanel from './BookingInfoPanel';
import BookingPanelHeader from './BookingPanelHeader';
import { ErrorCode } from '../../constant';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import '../../App.css';

let _bookingInfo;
let _customerRequests;
let AppoiontmentList;
let waitingTime = true;

// const _height=Styles.heightWithTopic;

const pageWording = {
    topics: 'Booking Detail',
    subheading: ['You get the best rates', ' only at TravisGo'],
    icon: ['safeBook', 'bestPrice', 'fee'],
};

class BookingDetail extends Component {
    state = { isDialog: false, isLoading: true, dialogType: undefined, msgError: undefined }

    constructor(props) {
        super(props);

        // console.log(`BookingDetail [constructor] : `, this.props);

        this.setBookingInfo(props);
        this.props.getCustomerRequests();
        this.props.getAPIAppointmentList();
    }

    componentWillMount() {
        // console.log(`BookingDetail [componentWillMount] : `, this.props);
    }

    componentWillUnmount() {
        // console.log(`BookingDetail [componentWillUnmount] : `, this.props);
        _bookingInfo = undefined;
    }

    componentWillReceiveProps(nextProps) {

        // console.log(`BookingDetail [componentWillReceiveProps] : `, _bookingInfo);
        this.setBookingInfo(nextProps);
        this.setRefund(nextProps);
        // this.setCustomerInfo(nextProps);
    }

    setBookingInfo(_props) {
        const { BookingManager, CustomerManager, AppointmentManager } = _props;
        if (!!BookingManager) {
            const { data, actions, error } = BookingManager.BOOKING_REDUCER;
            switch (actions) {
                case BookingAction.INITIAL_STATE:
                    {
                        BookingManager.BOOKING_REDUCER.actions = BookingAction.WATING_STATE;
                        const { bookingId } = this.props.location.state;

                        if (Boolean(bookingId))
                            this.props.getAPIBookingDetails(bookingId);
                    } break;
                case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
                    {
                        _bookingInfo = data;
                    } break;
                case BookingAction.SET_BOOKING_STATUS_SUCCESS:
                    {
                        // this.state.isLoading = false;
                    } break;
                // case BookingAction.INITIAL_STATE:
                //     {
                //         this.state.isLoading = (data === undefined);
                //     } break;

            }
        }
        if (!!CustomerManager) {
            const { data, actions, error } = CustomerManager.CUSTOMER_REDUCER;
            switch (actions) {
                case CustomerAction.GET_CUSTOMER_REQUESTS_SUCCESS:
                    {
                        CustomerManager.CUSTOMER_REDUCER.actions = CustomerAction.WATING_STATE;
                        _customerRequests = data.result.datas;
                    } break;
                case CustomerAction.GET_CUSTOMER_REQUESTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!AppointmentManager) {
            const { data, actions, error } = AppointmentManager.APPOINTMENT_REDUCER;
            switch (actions) {
                case AppointmentActions.GET_APPOINTMENT_SUCCESS:
                    {
                        AppointmentManager.APPOINTMENT_REDUCER.actions = AppointmentActions.WATING_STATE;
                        AppoiontmentList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AppointmentActions.GET_APPOINTMENT_FAILURE:
                    { SetStateWithTime(this, waitingTime) } break;
            }
        }
    }
    // setCustomerInfo(_props) {

    //     const { CustomerManager } = _props;

    //     const { data, actions, error } = CustomerManager.CUSTOMER_REDUCER;
    //     switch (actions) {
    //         case CustomerAction.GET_CUSTOMER_REQUESTS_SUCCESS:
    //             {
    //                 CustomerManager.CUSTOMER_REDUCER.actions = CustomerAction.WATING_STATE;
    //                 _customerRequests = data.result.datas;
    //             } break;
    //         case CustomerAction.GET_CUSTOMER_REQUESTS_FAILURE:
    //             {
    //                 SetStateWithTime(this, waitingTime);
    //             } break;
    //     }

    // }

    setRefund(_props) {
        const { RefundManager } = _props;
        const { actions, data } = RefundManager.REFUND_REDUCER;

        switch (actions) {
            case RefundActions.REFUND_SUCCESS:
                {
                    RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                    SetStateWithObject(this, waitingTime, { msgError: 'REFUND_SUCCESS', dialogType: 'success', });
                    const { bookingId } = data.result.bookingData
                    // console.log('bookingId', bookingId);
                    if (Boolean(bookingId))
                        this.props.getAPIBookingDetails(bookingId);


                    /********************* [ReCheck get Booking Detail] ***********************/
                } break;
            case RefundActions.REFUND_FAILURE:
                {
                    const _msg = data.response.data.error.message || actions;
                    SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                } break;
        }

    }

    render() {

        const { isDialog, isLoading, msgError, dialogType } = this.state;

        return (
            <div className='App'>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={this.onCloseDialog} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={this.onCloseDialog} />}
                <div style={style.page_container}>
                    <SubTopicsPanel _topics={pageWording.topics} _wording={pageWording.subheading} />
                    <List style={style.list_container}>
                        {(!!_bookingInfo) ? this.contentPanel() : <div />}
                    </List>
                </div>
            </div>);
    }

    componentDidUpdate() {

        const { BookingManager } = this.props;
        const { data, actions, error } = BookingManager.BOOKING_REDUCER;
        console.warn('componentDidUpdate : ', actions)

        switch (actions) {

            case BookingAction.SET_BOOKING_STATUS_SUCCESS:
                {
                    //this.state.isLoading = false;
                    BookingManager.BOOKING_REDUCER.actions = BookingAction.WATING_STATE;
                    SetStateWithObject(this, waitingTime, { msgError: 'Update Booking Status Success.', dialogType: 'success', isSuccess: true });
                } break;

            case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
                {
                    if (this.state.isLoading && (_bookingInfo !== undefined)) {

                        SetStateWithTime(this, waitingTime);
                    }
                } break;

            case BookingAction.SET_BOOKING_STATUS_FAILURE:
                {
                    if (this.state.msgError === undefined) {

                        const _msg = ErrorCode[data.status].EN;
                        console.warn('SET_BOOKING_STATUS_FAILURE : ', data, ' : ', error, ' : ', _msg);

                        const { BookingManager } = this.props;//
                        BookingManager.BOOKING_REDUCER.actions = BookingAction.WATING_STATE_CHANGE_STATUS;//

                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    }
                } break;

            case BookingAction.INITIAL_STATE:
                {
                    this.state.isLoading = (data === undefined);
                } break;
        }
    }

    contentPanel() {

        console.log('_customerRequests : ', _customerRequests);
        const { packageInfo, paymentInfo, bookingId, bookingStatus, expired, date } = _bookingInfo;

        return (
            <div style={style.content_container}>
                <div style={style.summary_container}>
                    <BookingPanelHeader packageInfo={packageInfo[0]} />
                    <PackageInfoPanel _bookingInfo={_bookingInfo} />
                    <BookingInfoPanel _bookingInfo={_bookingInfo} customerRequests={_customerRequests} AppoiontmentList={AppoiontmentList} />
                    <PaymetnInfoPanel paymentInfo={paymentInfo} bookingStatus={bookingStatus} />
                    <GalleryPanel Images={paymentInfo.images} bookingStatus={bookingStatus} />
                    <ConfirmPanel bookingStatus={bookingStatus} paymentInfo={paymentInfo} bookingId={bookingId}
                        confirmHandleChange={this.confirmHandleChange} confirmRefund={this.confirmRefund} />
                </div>
            </div>)
    }

    // NavbarAcitve = (value) => {console.log('NavbarAcitve : ', value); this.props.history.push(value); };

    onCloseDialog = () => (this.setState({ isDialog: false, isLoading: false, msgError: undefined, dialogType: undefined }));

    DidUpdate = (status) => { console.log('DidUpdate : ', status) }

    confirmRefund = (value) => {
        console.log('confirmRefund : ', value);
        this.setState({ isDialog: true }, () => this.props.APIRefund(value));
    }

    confirmHandleChange = (value) => {
        console.log('confirmHandleChange : ', encodeURI(JSON.stringify(value)));

        if (!!value) {

            waitingTime = true
            const { BookingManager } = this.props;//
            BookingManager.BOOKING_REDUCER.actions = BookingAction.WATING_STATE_CHANGE_STATUS;//

            this.props.getAPIBookingStatus(value);
            this.setState({ isLoading: true, msgError: undefined, dialogType: undefined });
        }
    }

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingDetails: (_id) => {
            InitWaitingTime();
            dispatch(BookingAction.getAPIBookingDetail(_id));
        },
        getAPIBookingStatus: (_info) => {
            dispatch(BookingAction.setAPIBookingStatus(_info));
        },
        APIRefund: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(RefundActions.APIRefund(_keyword, _filter));
        },
        getCustomerRequests: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CustomerAction.getCustomerRequests(_keyword, _filter));
        },
        getAPIAppointmentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AppointmentActions.getAppointmentList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingDetail);

const style =
{
    page_container:
    {
        display: 'inline-block',
        width: '100%',
        maxWidth: '1024px'
    },
    summary_container:
    {
        width: '100%',
        backgroundColor: Colors.white,
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '20px',
    },
    content_container:
    {
        height: '100%',
        width: '100%',
        maxWidth: '1024px',
    },
    list_container:
    {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        height: Styles.heightWithTopic
    },
};

