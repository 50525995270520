import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { path, equals, keys, omit } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import validate from "validate.js";

import DialogPanel from '../../../componets/DialogPanel.jsx';
import * as validator from '../../../constant/Validator/SellerType';
import SellerType from '../../../constant/JSON/SellerType';
import Language from '../../../constant/Language';
import Avatar from '../../../constant/Avatar';
import ButtonText from '../../../constant/Button/ButtonText';
import Button from '../../../constant/Button';
import { Colors, Fonts } from '../../../themes'
import FormTypes from '../../../common/FormTypes';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../../common/SetTime.js';

import * as SellerAction from '../../../actions/Actions_SellerManager';
import * as UploadActions from '../../../actions/Actions_UploadManager';

import CONFIG from '../../../config';

let waitingTime = true;

// const _style = { textAlign: 'start', width: '100%' };
const _rowContainer = { display: 'flex' };

class SellerTypeCard extends Component {
    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }
    constructor(props) {
        super(props);
        this.state = {
            SellerTypeInfo: this.props.SellerTypeInfo,
            oldSellerTypeInfo: this.props.oldSellerTypeInfo,
            isUpdate: false,
        }
    }
    componentWillReceiveProps = (nextProps) => {
        const { SellerManager, UpLoadManager } = nextProps;

        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;

            switch (actions) {
                case SellerAction.CREATE_SELLER_TYPE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create SellerType Success.', dialogType: 'success', });
                        this.setState({ sellerTypeId: data.sellerTypeId, isUpdate: true })
                        this.props.getAPISellerTypeList({ num_limit: 10 });
                    } break;

                case SellerAction.UPDATE_SELLER_TYPE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update SellerType Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPISellerTypeList({ num_limit: 10 });
                    } break;

                case SellerAction.CREATE_SELLER_TYPE_FAILURE:
                case SellerAction.UPDATE_SELLER_TYPE_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, sellerTypeId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let SellerTypeInfo = { ...this.state.SellerTypeInfo };
                        SellerTypeInfo.picture = data.result.upload.images;
                        if (isEdit) {
                            this.props.APIUpdateSellerType(SellerTypeInfo)
                        } else if (isUpdate) {
                            SellerTypeInfo = { ...SellerTypeInfo, sellerTypeId: sellerTypeId }
                            this.props.APIUpdateSellerType(SellerTypeInfo)
                        } else {
                            this.props.APICreateSellerType(SellerTypeInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }
    render() {
        const { classes, onClosePanel, isView, SellerTypeList, isEdit } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive, SellerTypeInfo } = this.state;
        // const LanguageSellerType = path(['language'], SellerTypeList);

        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingTop: '15px' }}>
                    <Typography className={classes.title}>{isEdit ? SellerType.HeaderPage.UPDATE_EN : SellerType.HeaderPage.CREATE_EN}</Typography>
                    {/* <Language LanguagePage={LanguageSellerType} onChangeState={this.props.onChangeLanguage} isValue={true} /> */}
                </div>}
                {SellerType.BodyPage.map((row, index) => {
                    return (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={{ width: (each.width) ? each.width : '100%', paddingTop: 5 }}>{this.SetItemsWithType(each)}</div>))}
                        </div>
                    )
                })}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive} onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        )
    }
    SetItemsWithType(each) {

        const { classes, isView, SellerTypeList } = this.props;
        const { oldSellerTypeInfo, SellerTypeInfo } = this.state;
        const valueLanguage = path([each.stateName], SellerTypeInfo);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={undefined}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        styleValue={{ fontFamily: Fonts.family.roboto, }}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], SellerTypeInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
            case 'image': {
                return (
                    <div className={classes.textDefalut} >
                        {isView ? <img className={classes.avatar} src={`${CONFIG.host}${SellerTypeInfo.picture.thumbnail}`} />
                            :
                            <Avatar ref='avatar' editable={true}
                                onChange={(files) => { this.onChangeState(each.stateName, files) }}
                                avatar={Boolean(SellerTypeInfo.picture.thumbnail) ? `${CONFIG.host}${SellerTypeInfo.picture.thumbnail}` : ''} />
                        }
                    </div>
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], SellerTypeInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(each.options) && each.options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN} </option>) })} />
                )
            }
        }
    }
    onChangeState = (fieldName, state) => {
        let SellerTypeInfo = { ...this.state.SellerTypeInfo };
        const { oldSellerTypeInfo } = this.state;
        // const LanguageSellerType = path(['language',], this.props.SellerTypeList);

        switch (fieldName) {
            default: { SellerTypeInfo = { ...SellerTypeInfo, [fieldName]: state, } }
        }
        console.log('SellerTypeInfo success', SellerTypeInfo)
        this.setState({ SellerTypeInfo, isBtnActive: !equals(oldSellerTypeInfo, SellerTypeInfo) }, () => { this.validate({ [fieldName]: state }); });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        // const LanguageSellerType = path(['language'], this.props.SellerTypeList);
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.SellerTypeInfo[each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        let SellerTypeInfo = { ...this.state.SellerTypeInfo }
        const { isEdit } = this.props;
        const { isUpdate, sellerTypeId } = this.state;
        const newAvatar = this.refs.avatar.getAvatar('file');

        if (this.validateAll()) {
            if (!!newAvatar) {
                this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadImage(newAvatar));
            }
            else {
                this.setState({ isLoading: true, isBtnActive: false })
                if (isEdit) {
                    this.props.APIUpdateSellerType(SellerTypeInfo)
                }
                else if (isUpdate) {
                    SellerTypeInfo = { ...SellerTypeInfo, sellerTypeId: sellerTypeId };
                    this.props.APIUpdateSellerType(SellerTypeInfo);
                }
                else { this.props.APICreateSellerType(SellerTypeInfo) }
            }
        }
        else this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
    }

    APIUpLoadImage = (newAvatar) => {
        let formdata = new FormData();
        formdata.append('imgThumbnailWidth', 200);
        formdata.append('imgThumbnailHeight', 200);
        formdata.append("images", newAvatar);
        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
});

function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerAction.getSellerTypeList(_keyword, _filter));
        },
        APICreateSellerType: (_confirmInfo) => {
            dispatch(SellerAction.APICreateSellerType(_confirmInfo));
        },
        APIUpdateSellerType: (_confirmInfo) => {
            dispatch(SellerAction.APIUpdateSellerType(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}
SellerTypeCard.propTypes = { classes: PropTypes.object.isRequired };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SellerTypeCard))