import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { PathName } from '../../constant';
// import BookingStatCard from '../../componets/BookingStatCard.jsx';

import BigChart from '../../componets/BigChart.jsx';
import * as StatiticActions from '../../actions/Actions_StatiticManager.js';

import '../../App.css';

let BookinPaymentList;

class BookingStatusPanel extends Component {

    constructor(props) {
        super(props);

        this.props.getAPIAllSummaryBookingPaymentInfo();
    }

    componentWillReceiveProps(nextProps) {

        const { StatiticManager, isLoading } = nextProps;


        if (!!StatiticManager) {

            const { actions, data } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {

                case StatiticActions.GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS:
                    {
                        BookinPaymentList = data.results;

                        // console.log(`!!! ${actions} `, BookinPaymentList);
                        // StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;
                        // SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        //console.log('BookingStatusPanel : ', this.props)
        const { BookinList, isLoading } = this.props;

        return (
            <div className="App-content">
                <BigChart Info={BookinPaymentList} Disable={true} isLoading={isLoading} />
            </div>)
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAllSummaryBookingPaymentInfo: (_filter) => {
            dispatch(StatiticActions.getAllSummaryBookingPaymentInfo(_filter));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingStatusPanel);
