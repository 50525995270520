import red from "@material-ui/core/colors/red";

export const Colors = {
    error: red.A400,
    success: '#8BC34A',

    border: '#979797',
    borderLight: '#DFDFDF',

    defaultButton: '#2B2B2B',

    themeOpacity: '#bce7f7',
    theme: '#B81807', // Red
    themeDark: '#2B2B2B', // greyDark 
    themeDark_Light: '#F3F3F3',
    themeDark_Item: '#e2e2e2',
    themeDark_Hilight: '#686868',
    defaultImage: '#BFBFBF',

    barChart_1: '#B81807', // <== Theme
    barChart_2: '#F7941E',
    barChart_3: '#FFC400',

    pieChart_1: '#BDBDBD', // Wating Grey                   //Color Full Status
    pieChart_2: '#1E88E5', // inProcess Yellow Light
    pieChart_3: '#8BC34A', // Appoved Green Light
    pieChart_4: '#FF1744', // Cancel Red Orange
    pieChart_5: '#f78201', // Reject Red Dark

    bigChart_1: '#ff8465',  //Green
    bigChart_2: '#ffa365',
    bigChart_3: '#ffba65',
    bigChart_4: '#ffca65',
    bigChart_5: '#ffd965',

    lineChart_1: '#7d0000', // Blue
    lineChart_2: '#cd3700',
    lineChart_3: '#cd7f32',
    lineChart_4: '#ffa500',


    header: {
        background: "#292929",
        color: "#b6b6b6",
    },
    gradient: "linear-gradient(180deg, #4188C6 0%, #2D609C 100%)",
    darkBlue: "#295899",
    lightBlue: "#346DAA",
    darkGrey: '#474747',
    grey: '#9B9B9B',
    lightGrey: '#E9E9E9',
    input: {
        label: 'rgba(0,0,0,0.54)',
        border: '#c8c8c8',
    },
    orange: '#EA4F48',

    disabled: '#AAA9A9',
    white: '#FFFFFF',
    black: '#000000',
    footer: '#222222'
};
