import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_SELLER_SUCCESS = 'GET_SELLER_SUCCESS';
export const GET_SELLER_FAILURE = 'GET_SELLER_FAILURE';

export const GET_SELLER_INFO_SUCCESS = 'GET_SELLER_INFO_SUCCESS';
export const GET_SELLER_INFO_FAILURE = 'GET_SELLER_INFO_FAILURE';

export const CREATE_SELLER_SUCCESS = 'CREATE_SELLER_SUCCESS';
export const CREATE_SELLER_FAILURE = 'CREATE_SELLER_FAILURE';

export const UPDATE_SELLER_SUCCESS = 'UPDATE_SELLER_SUCCESS';
export const UPDATE_SELLER_FAILURE = 'UPDATE_SELLER_FAILURE';

export const GET_SELLER_WITH_AGENT_ID_SUCCESS = 'GET_SELLER_WITH_AGENT_ID_SUCCESS';
export const GET_SELLER_WITH_AGENT_ID_FAILURE = 'GET_SELLER_WITH_AGENT_ID_FAILURE';

export const GET_SELLER_TYPE_SUCCESS = 'GET_SELLER_TYPE_SUCCESS';
export const GET_SELLER_TYPE_FAILURE = 'GET_SELLER_TYPE_FAILURE';

export const CREATE_SELLER_TYPE_SUCCESS = 'CREATE_SELLER_TYPE_SUCCESS';
export const CREATE_SELLER_TYPE_FAILURE = 'CREATE_SELLER_TYPE_FAILURE';

export const UPDATE_SELLER_TYPE_SUCCESS = 'UPDATE_SELLER_TYPE_SUCCESS';
export const UPDATE_SELLER_TYPE_FAILURE = 'UPDATE_SELLER_TYPE_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

// getSellerList ============================================================================================================================

export function getSellerList(_keyword = '', _fliterjson = {}) {

    //console.log("[API] getSellerList :", `${CONFIG.LBhost}${CONFIG.api.getSellerList}`)
    // const request = axios.get(`${api}business/getAllEmployee?businessId=${business_id}`,{headers:{'x-master-key':'smelink1234'}});

    return dispatch => {
        // _fliterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSellerList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fliterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSellerList : ", response);
            localStorage.setItem('SellerList', response.data)
            dispatch(getSellerList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSellerList Failure", error);
            dispatch(getSellerList_Failure(error));
        })
    }
}

function getSellerList_Success(data) {
    return { type: GET_SELLER_SUCCESS, payload: data };
}

function getSellerList_Failure(error) {
    return { type: GET_SELLER_FAILURE, payload: error };
}

// getSellerInfo ============================================================================================================================

export function getSellerInfo(id, _fliterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSellerInfo}${id}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fliterjson,
            // data: _fliterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSellerInfo : ", response);
            dispatch(getSellerInfo_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSellerInfo Failure", error);
            dispatch(getSellerInfo_Failure(error));
        })
    }
}

function getSellerInfo_Success(data) {
    return { type: GET_SELLER_INFO_SUCCESS, payload: data };
}

function getSellerInfo_Failure(error) {
    return { type: GET_SELLER_INFO_FAILURE, payload: error };
}

// getSellerInfo ======================================================================================================================================

// getSellerList_WithAgentId =========================================================================================================================

export function getSellerList_WithAgentId(_fliterjson = {}) {

    // console.log('getSellerList_WithAgentId is fliter : ', _fliterjson)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSellerList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fliterjson,
            data: _fliterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSellerList_WithAgentId : ", response);
            dispatch(getSellerList_WithAgentId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSellerList_WithAgentId Failure", error);
            dispatch(getSellerList_WithAgentId_Failure(error));
        })
    }
}

function getSellerList_WithAgentId_Success(data) {
    return { type: GET_SELLER_WITH_AGENT_ID_SUCCESS, payload: data };
}

function getSellerList_WithAgentId_Failure(error) {
    return { type: GET_SELLER_WITH_AGENT_ID_FAILURE, payload: error };
}

// crateSellerList ======================================================================================================================================

export function APICreateSeller(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createSeller}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APICreateSeller : ", response.data);
            dispatch(APICreateSeller_Success(response.data));

        }).catch((error) => {
            console.log("APICreateSeller fail", error);
            dispatch(APICreateSeller_Failure(error));
        })
    }
}

function APICreateSeller_Success(data) {
    return { type: CREATE_SELLER_SUCCESS, payload: data };
}

function APICreateSeller_Failure(error) {
    return { type: CREATE_SELLER_FAILURE, payload: error };
}

// updateSellerList ======================================================================================================================================

export function APIUpdateSeller(confirmInfo) {
    // console.log('APIUpdateSeller : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateSeller}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpdateSeller : ", response.data);
            dispatch(APIUpdateSeller_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateSeller fail", error);
            dispatch(APIUpdateSeller_Failure(error));
        })
    }
}

function APIUpdateSeller_Success(data) {
    return { type: UPDATE_SELLER_SUCCESS, payload: data };
}

function APIUpdateSeller_Failure(error) {
    return { type: UPDATE_SELLER_FAILURE, payload: error };
}


// getSellerTypeList =====================================================================================================================

export function getSellerTypeList(_keyword = '', _fileterjson = {}) {

    // console.log("[API] getSellerTypeList :", `${CONFIG.LBhost}${CONFIG.api.getSellerTypeList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSellerTypeList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getSellerTypeList : ", response);
            localStorage.setItem('SellerTypeList', response.data)
            dispatch(getSellerTypeList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSellerTypeList Failure", error);
            dispatch(getSellerTypeList_Failure(error));
        })
    }
}

function getSellerTypeList_Success(data) {
    return { type: GET_SELLER_TYPE_SUCCESS, payload: data };
}

function getSellerTypeList_Failure(error) {
    return { type: GET_SELLER_TYPE_FAILURE, payload: error };
}

// createSellerType ========================================================================================================================


export function APICreateSellerType(confirmInfo) {

    console.log('APICreateSellerType : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createSellerType}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateSellerType : ", response.data);
            dispatch(APICreateSellerType_Success(response.data));

        }).catch((error) => {
            console.log("APICreateSellerType Failure", error);
            dispatch(APICreateSellerType_Failure(error));
        })
    }
}

function APICreateSellerType_Success(data) {
    return { type: CREATE_SELLER_TYPE_SUCCESS, payload: data };
}

function APICreateSellerType_Failure(error) {
    return { type: CREATE_SELLER_TYPE_FAILURE, payload: error };
}

// updateSellerType =========================================================================================================================

export function APIUpdateSellerType(confirmInfo) {
    console.log('APIUpdateSellerType : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateSellerType}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { sellerTypeId: confirmInfo.sellerTypeId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateSellerType : ", response.data);
            dispatch(APIUpdateSellerType_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateSellerType Failure", error);
            dispatch(APIUpdateSellerType_Failure(error));
        })
    }
}

function APIUpdateSellerType_Success(data) {
    return { type: UPDATE_SELLER_TYPE_SUCCESS, payload: data };
}

function APIUpdateSellerType_Failure(error) {
    return { type: UPDATE_SELLER_TYPE_FAILURE, payload: error };
}
