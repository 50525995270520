import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

import CONFIG from '../../config';
import DialogPanel from '../../componets/DialogPanel.jsx';
import { FileCopy } from '@material-ui/icons';


import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as PromotionActions from '../../actions/Actions_PromotionManager.js';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

// import ImageGalleryPanel from './ImageGalleryPanel';
// import ImageSlide from '../../componets/ImageSlide.jsx'
// import PriceDetailBar from './PriceDetailBar';
// import ServiceIconPanel from './ServiceIconPanel';
// import ShouldKnow from './ShouldKnow';
// import ServiceInclusion from './ServiceInclusion';
// import TourProgram from './TourProgram';

import { ConvertDate, } from '../../common/SetDate.js';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import '../../App.css';
import '../../themes/Themes.css';

import { isMobile } from '../../common/DeviceCheck';

import { Family, Fonts, Colors, Icons,Styles } from '../../themes';
const { DoneIcon, CloseIcon, } = Icons;

var QRCode = require('qrcode.react');

const _height = Styles.heightWithTopic;

const pageWording = {
    topics: 'Promotion Code Detail.',
    subheading: [],
    icon: ['safeBook', 'bestPrice', 'fee'],
};

let DestinationsList = [];
let waitingTime = true;

class AffiliateCodePanel extends Component {

    state = { isLoading: true, }

    constructor(props) {
        super(props);

    }

    componentWillMount() {

        // console.log(`AffiliateCodePanel [componentWillMount] : `,this.props);   
    }

    componentWillReceiveProps(nextProps) {

        console.log(`AffiliateCodePanel [componentWillReceiveProps] : `, nextProps);

    }
    render() {

        // console.log('AffiliateCodePanel', this.props)
        const { CodeInfo } = this.props;

        return (
            <div className="App">
                <div style={style.page_container}>
                    <label style={isMobile() ? style.topicsMobile : style.topics}>{pageWording.topics}</label>
                </div>
                <List style={style.list_container}>
                    <div style={{ display: 'flex', }}>
                        {this.contentPanel(CodeInfo)}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel(_codeInfo) {

        const { condition, countLimit, visible, description, discountType, discountValue, endDate, id, limit, namePromotion, promotionCode, promotionCodeId, startDate, image } = _codeInfo;

        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10 };
        const _affiliateLink = promotionCode;
        const _topicLable = isMobile() ? style.classMobile : style.class;
        const _infoLable = isMobile() ? style.infoMobile : style.info;

        return (
            <div style={{ height: '450px', width: '100%', maxWidth: '1024px', }}>
                <div style={{ display: isMobile() ? 'block' : 'flex', borderBottom: `5px solid ${Colors.theme}`, borderTop: `5px solid ${Colors.theme}`, padding: '20px 0px' }}>
                    <div style={{ width: '100%', flex: 3 }}>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Code Name : </label>
                            <label style={_infoLable}>{namePromotion}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Code Id : </label>
                            <label style={_infoLable}>{promotionCodeId}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Discount Type : </label>
                            <label style={_infoLable}>{discountType}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Discount : </label>
                            <label style={_infoLable}>{discountValue}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Use Amount : </label>
                            <label style={_infoLable}>{countLimit}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Limit : </label>
                            <label style={_infoLable}>{limit}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Star Date : </label>
                            <label style={_infoLable}>{ConvertDate(startDate.substr(0, 10))}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>End Date : </label>
                            <label style={_infoLable}>{ConvertDate(endDate.substr(0, 10))}</label>
                        </div>
                        {/* <div style={_styleInfoItem}>
                            <label style={_topicLable}>Visible : </label>
                            <label style={_infoLable}>{`${visible}`}</label>
                        </div> */}
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Description : </label>
                            <label style={_infoLable}>{description || '-'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicLable}>Condition : </label>
                            <label style={_infoLable}>{condition || '-'}</label>
                        </div>
                    </div>
                    {(!isMobile()) &&
                        <div style={{ flex: 1, marginRight: 10 }}>
                            {/* <div style={_backgroundImage} /> */}
                        </div>}
                </div>
                <div style={{ display: 'flex', padding: '0px 0px', borderBottom: `${'5px solid' + Colors.theme}` }}>
                    <div style={{ flex: 1, textAlign: 'left', padding: 10 }}>
                        <QRCode
                            value={_affiliateLink}
                            size={128}
                            bgColor={Colors.white}
                            fgColor={Colors.themeDark}
                            level={"L"} />

                    </div>
                    <div style={{ flex: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '15px 0px', padding: isMobile() ? '10px 0px 10px 0px' : 10 }}>
                        <TextField
                            id="PromotionCode"
                            label="Promotion Code"
                            style={{ width: '100%', marginTop: 0 }}
                            value={_affiliateLink} />
                        <Button.Icons themeDark icon={<FileCopy />} tooltip={'Copy'} onClick={() => this.HandleCopy()} />
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0px' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL}
                        onClick={() => this.props.OnClose()} />
                </div>
            </div>
        );
    }

    //===============================================================================================================================================================

    checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

    HandleCopy = () => {

        var copyText = document.getElementById("PromotionCode");
        copyText.select();
        document.execCommand("copy");
        // alert("Copied the text: " + copyText.value);
    }

    GetIconWithStatus = (_status) => ((_status) ? <DoneIcon /> : <CloseIcon />);

    //===============================================================================================================================================================

}

const style =
{
    topics:
    {
        fontSize: 26,
        color: Colors.theme,
        fontFamily: Family.roboto,
        float: 'left',
        // paddingLeft: 50
    },
    topicsMobile:
    {
        fontSize: 18,
        color: Colors.theme,
        fontFamily: Family.roboto,
    },
    page_container:
    {
        display: 'inline-block',
        width: '100%',
        maxWidth: '1030px',
        margin: '5px',

    },
    content_container:
    {
        height: '100%',
        width: '100%',
        maxWidth: '1024px',
    },
    list_container:
    {
        width: '100%',
        position: 'relative',
        // overflow: 'auto',
        height: 550,//_height
    },
    info: {
        height: '14px',
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        lineHeight: '16px',
    },
    class: {
        height: '14px',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '18px',
        // fontWeight: 'bold',
        lineHeight: '16px',
        textAlign: "left"
    },
    infoMobile: {
        height: '14px',
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '14px',
        lineHeight: '16px',
    },
    classMobile: {
        height: '14px',
        paddingRight: '5px',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        // fontWeight: 'bold',
        lineHeight: '16px',
        textAlign: "left"
    },

};

function mapStateToProps(state, ownProps) {
    return {
        PackageManager: state.PackageManager.PACKAGE_REDUCER,
        // DestinationsManager: state.DestinationsManager.DESTINATION_REDUCER,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AffiliateCodePanel);


