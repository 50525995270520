import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { Metrics, Colors, Fonts, Family } from "../../themes";
import { isMobile, isTablet } from '../../common/DeviceCheck';
import { SetDate } from '../../common/SetDate';

class BuyerInfoPanel extends Component {

    render() {

        const { buyerInfo, userInfo } = this.props;
        const { email, tel, title, firstName, lastName, pickupDetail, countryCode, request, specialRequest, bookingId, social, socialId, nationality } = buyerInfo;

        const stylePanel = { padding: '20px 20px 20px 50px', width: '100%', textAlign: 'left', fontFamily: Family.roboto };

        return (
            <div key={bookingId} style={{ backgroundColor: Colors.themeDark_Light, width: '100%', height: '100%', display: 'flex' }}>
                <div style={stylePanel}>
                    <label style={style.label_topic}>{'User Infomation.'}</label>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`User ID : `}</label>
                        <label style={style.priceInfo_lable}>{`${userInfo.id}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`User Name : `}</label>
                        <label style={style.priceInfo_lable}>{`${userInfo.name}`}</label>
                    </div>
                    <div style={{ marginTop: 30 }}><label style={style.label_topic}>{'Buyer Infomation.'}</label></div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Name : `}</label>
                        <label style={style.priceInfo_lable}>{`${title} ${firstName} ${lastName}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Phone : `}</label>
                        <label style={style.priceInfo_lable}>{`${countryCode + tel}` || '-'}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Nationality : `}</label>
                        <label style={style.priceInfo_lable}>{nationality || '-'}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Email : `}</label>
                        <label style={style.priceInfo_lable}>{`${email}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Social : `}</label>
                        <label style={style.priceInfo_lable}>{`${social || '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Social Id : `}</label>
                        <label style={style.priceInfo_lable}>{`${socialId || '-'}`}</label>
                    </div>
                </div>
            </div >);
    }
}

export default withRouter(BuyerInfoPanel);

const style =
{
    priceInfo_lable: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'right',
        // fontFamily: Family.roboto
        //   marginTop: '5px',
        //   marginRight: '5px',
    },
    packege_container: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
    },
    label_topic: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '31px',
        display: 'inline-grid',
        marginTop: '5px',
        // fontFamily: Family.roboto
    },
    label_detail: {
        height: '31px',
        width: '30%',
        maxWith: '100px',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        // fontFamily: Family.roboto
        //   marginTop: '5px',
        //   marginBottom: '5px',
    },
};
