import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PathName } from '../../constant';

import InfoDCard from '../../componets/InfoDCard.jsx';
import AccountingInfoPanel from './AccountingInfoPanel';
import BookingStatChart from '../../componets/BookingStatChart';
import BookingStatCard from '../../componets/BookingStatCard.jsx';
import SupplierStatCard from '../../componets/SupplierStatCard.jsx';
// import TwoLevelPieChart from '../../componets/TwoLevelPieChart';

import * as StatiticActions from '../../actions/Actions_StatiticManager.js';
import * as AccountingActions from '../../actions/Actions_Accounting.js';

import { Roles } from '../../constant/JSON/Roles.js';
import { OmiseTotal, NetProfits } from '../../common/AccountingCalculate.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import '../../App.css';

let waitingTime = true;
let _BookingStatus = [];
let _BookingCharts = [];
let _SumaryBookingWithType = [];
let _WalletInfo = {};

const _Info = {
    Wallet: { name: 'Net Profits', amount: 0, price: 0 },//Wallet
    Total: { name: 'Total Omise', amount: 0, price: 0 },//Total
    OneDayTrip: { name: 'Onedaytrip', amount: 0, price: 0 },
    Package: { name: 'Package', amount: 0, price: 0 },
    Transfer: { name: 'Transfer', amount: 0, price: 0 },
    Hotel: { name: 'Hotel', amount: 0, price: 0 },
}

class AccountingStatistic extends Component {

    constructor(props) {
        super(props);

        const _filter = { startDate: '2018-01-01' };
        this.props.getAPIBookingStatus(_filter);
        this.props.getAPIIncomeAllSummary(_filter);
        this.props.getAPIAllSummaryBookingChart();
    }

    componentWillReceiveProps(nextProps) {

        const { StatiticManager, AccountingManager, isLoading } = nextProps;

        // if (!isLoading && _BookingStatus.length === 0)
        //     this.props.getAPIBookingStatus();

        if (!!StatiticManager) {

            const { actions, data } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {
                case StatiticActions.GET_BOOKING_STATUS_SUCCESS:
                    {
                        _BookingStatus = data.results;

                        //console.log(`[AccountingStatistic] ${actions} `, _BookingStatus);
                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;

                case StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS:
                    {
                        _BookingCharts = data.results.data;
                        // _BookingCharts=BookingStatChart.map((item)=>{})
                        // console.log(`[AccountingStatistic] ${actions} `, _BookingCharts);
                    } break;

                case StatiticActions.GET_ALL_SUMMARY_INCOME_SUCCESS:
                    {
                        //StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;
                        _SumaryBookingWithType = data.results;

                        // console.warn(`!!! ${actions} `, _SumaryBookingWithType);
                    } break;

                case StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_FAILURE:
                case StatiticActions.GET_BOOKING_STATUS_FAILURE:
                    {
                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!AccountingManager)
            this.AccountingManager(AccountingManager.ACCOUNTING_REDUCER);
    }

    AccountingManager(_accountingInfo) {
        const { actions, data } = _accountingInfo

        switch (actions) {
            case AccountingActions.GET_SYSTEM_WALLET_SUCCESS:
                {
                    _accountingInfo.actions = AccountingActions.WATING_STATE;
                    const { wallet } = data[0];
                    _Info.Wallet.price = wallet || 0;
                    console.log(`${actions} : ${wallet}`)
                } break;

            case AccountingActions.GET_WALLET_INFO_WITH_ID_SUCCESS:
                {
                    _accountingInfo.actions = AccountingActions.WATING_STATE;
                    _WalletInfo = data.results;
                    console.log(`GET_WALLET_INFO_WITH_ID_SUCCESS : `, _WalletInfo);
                    // SetStateWithTime(this, waitingTime);
                } break;

            case AccountingActions.WATING_STATE:
                {
                    if (!!data[0]) {
                        const { wallet } = data[0];
                        _Info.Wallet.price = wallet || 0;
                    }
                }
        }
    }

    render() {

        return (this.content())
    }

    content() {

        const { isLoading, DisplayPage, Role, isMobile } = this.props;
        const _isDashBoard = (DisplayPage === PathName.DashBoardPage)

        switch (Role) {
            case Roles.Supplier:
            case Roles.Seller:
                {
                    return <AccountingInfoPanel WalletInfo={_WalletInfo} isMobile={isMobile} />
                }
            case Roles.Admin:
            default:
                {
                    return (
                        <React.Fragment>
                            <div className="App-content">
                                <div style={{ flex: 4, }}>
                                    {SummaryBookingWithWalletPanel(_SumaryBookingWithType)}
                                </div>
                            </div>
                        </React.Fragment>)
                }
        }
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}


function SummaryBookingWithWalletPanel(SummaryBooking) {

    const { totalPrice, totalSale, } = SummaryBooking;
    const Total = { ..._Info.Total };
    const Wallet = { ..._Info.Wallet };

    Total.amount = (totalSale || 0);
    Total.price = OmiseTotal(totalPrice);
    Wallet.price = NetProfits(Wallet.price, totalPrice);

    return (
        <div className="App-content" style={{ maxWidth: 1024, margin: 0 }}>
            <div style={{ flex: 1, margin: 10, marginLeft: 0, marginTop: 0 }}>
                <InfoDCard Info={Total} Disable={false} Styles={1} Type={'full'} />
            </div>
            <div style={{ flex: 1, margin: 10, marginRight: 5, marginTop: 0 }}>
                <InfoDCard Info={Wallet} Disable={true} Type={'full'} />
            </div>
        </div >)
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingStatus: (_keyword) => {
            InitWaitingTime();
            dispatch(StatiticActions.getBookingStatus(_keyword));
        },
        getAPIIncomeAllSummary: (_filter) => {
            dispatch(StatiticActions.getIncomeAllSummary(_filter))
        },
        getAPIAllSummaryBookingChart: (_filter) => {
            dispatch(StatiticActions.getAllSummaryBookingChart(_filter))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountingStatistic);


