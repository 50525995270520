import React, { Component } from 'react';
import { connect } from 'react-redux';
import { keys, path } from 'ramda';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CONFIG from '../config';
import Avatar from './Avatar.jsx';
import Button from '../constant/Button';
import Language from '../constant/Language';
import EmptyRows from '../componets/EmptyRow.jsx';
import { NumberFormats } from '../common/SetNumberFormat.js';
import SearchPanel from './SearchPanel.jsx';
import { InitwaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import * as PackageAction from '../actions/Actions_PackageManager.js';
import { Colors } from '../themes';

const fildeList = ['', 'PACKAGE NAME', 'PROMOTION', 'PRICE', 'TYPE', 'RATING', 'STATUS'];
const fildeMobileList = ['', 'PACKAGE NAME', 'PROMOTION', 'PRICE'];

const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _wording = { header: 'Package Manage' }

let packageInfoList = [];
let waitingTime = false;

class PackageListPanel extends Component {

  state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

  constructor(props) {
    super(props);

    // console.log('PackageListPanel : ', this.props)
  }

  componentWillReceiveProps(nextProps) {

  }

  render() {

    const { classes, onClickHandle, isMobile } = this.props;
    packageInfoList = (!!this.props.packageInfoList) ? (this.props.packageInfoList.packageList || []) : [];
    const isPackage = (packageInfoList.length > 0);
    const fieldLists = isMobile ? fildeMobileList : fildeList;

    // console.log('PackageListPanel : ', this.props)

    return (
      <Paper className={isMobile ? classes.rootMobile : classes.root}>
        <Table className={isMobile ? classes.tableMobile : classes.table}>
          <TableHead >
            <TableRow style={{ height: '100%' }}>
              <TableCell colSpan={fieldLists.length} style={{ padding: '0px' }} >
                <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                <SearchPanel searchActive={true} SearchType={'package'} />
              </TableCell>
            </TableRow>
            <TableRow>
              {fieldLists.map((_filde, index) => { return <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>{_filde}</TableCell> })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(isPackage) ? GetBookingRows(packageInfoList, onClickHandle, classes, isMobile) : <EmptyRows fildeList={fieldLists} />}
          </TableBody>
        </Table>
        {(isPackage) && GetPagination(this.props.packageInfoList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage, isMobile)}
      </Paper>
    );
  }


  //===============================================================================================================================================================

  handleRequestSort = (sortFilde) => {

    const { orderBy, field } = sortFilde;
    const _fildeName = field.name;
    //console.log('handleRequestSort : ', _fildeName, packageInfoList)

    packageInfoList = (orderBy === 'desc')
      ? packageInfoList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
      : packageInfoList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

    this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
  };

  onChangeLimit = (_limitRows, _language) => {
    this.setState({ isLoading: true });
    const { supplierId } = this.props;
    this.props.getAPIpackageInfoList({ supplierId: supplierId, page: 0, limit: _limitRows, language: _language });
  }

  onNextPage = (_pageNumber, _limit, _language) => {
    this.setState({ isLoading: true });
    const { supplierId } = this.props;
    this.props.getAPIpackageInfoList({ supplierId: supplierId, page: _pageNumber, limit: _limit || 25, language: _language });
  }

  onChangeLanguage = (_language) => {
    this.setState({ isLoading: true });
    const { supplierId } = this.props;
    this.props.getAPIpackageInfoList({ supplierId: supplierId, page: 0, limit: 25, language: _language });
  }

  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

  //===============================================================================================================================================================

}

function GetBookingRows(packageInfoList, onClickHandle, classes, isMobile) {

  return packageInfoList.map(_package => {

    // console.log('_package', _package);
    const { packageId, packageInfo, type, isPromotion, rating, visible } = _package;
    const { name, price = { adult: 0 }, image, imageGallery } = packageInfo;
    const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${checkURL(image)})`, backgroundRepeat: 'round' };
    // const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${checkURL(path([0], imageGallery))})`, backgroundRepeat: 'round' };
    // console.log('rating', rating)

    return (
      <TableRow hover style={{ cursor: 'pointer' }} key={packageId} onClick={() => onClickHandle(packageId)} >
        <TableCell style={_callStyle}><div style={_backgroundImage} /></TableCell>
        <TableCell className={classes.name}>{name || '-'}</TableCell>
        <TableCell className={classes.text_info} >{`${isPromotion}`}</TableCell>
        <TableCell className={classes.text_info} >{NumberFormats(price.adult) || 0}</TableCell>
        {(!isMobile) &&
          <React.Fragment>
            <TableCell className={classes.text_info} >{type || '-'}</TableCell>
            <TableCell className={classes.text_info} >{rating.point || '-'}</TableCell>
            <TableCell style={{ ..._callStyle, ...{ color: visible ? Colors.success : Colors.error } }}>
              {visible ? 'Active' : 'Inactive'}
            </TableCell>
          </React.Fragment>}
      </TableRow>
    );
  });
}

function GetSortIcon(orderBy, field, handleRequestSort) {
  return (
    <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
      {field.label}
    </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage, isMobile) {
  const { total, page, limit, pageSize, language } = _ListCount;
  // console.log('_ListCount', _ListCount)

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      {(!isMobile) && <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />}
      <TablePagination
        component="div"
        count={total || 0}
        rowsPerPage={limit || 25}
        page={page || 0}
        backIconButtonProps={{ 'aria-label': 'Previous Page', }}
        nextIconButtonProps={{ 'aria-label': 'Next Page', }}
        onChangePage={(event, page) => { onNextPage(page, limit, language) }}
        onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
    </div>
  )
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
  {
    root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
    rootMobile: { width: window.innerWidth, marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
    table: { minWidth: '1024px', },
    tableMobile: { width: '100%' },
    text_info: { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', },
   name: { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px' },

  });

PackageListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIpackageInfoList: (_fliter) => {
      // InitWaitingTime();
      dispatch(PackageAction.getAPIPackageLists(_fliter));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PackageListPanel));