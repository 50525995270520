import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import InfoCard from '../componets/InfoCard.jsx';
import InfoSCard from '../componets/InfoSCard.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';

import BookingStatCard from '../componets/BookingStatCard.jsx';
import ManageListsPanel from '../componets/ManageListsPanel.jsx';
// import BookingTable from '../containers/Booking/BookingTable.jsx';
import BigChartPanel from '../containers/Booking/BigChartPanel.jsx';
import InProcessListCard from '../containers/Booking/InProcessListCard.jsx';
import BookingStatusPanel from '../containers/Booking/BookingStatusPanel.jsx';
import AccountingStatistic from '../containers/Accounting/AccountingStatistic.jsx';


// import CONFIG from '../config';
import { PathName } from '../constant';

import * as BookingAction from '../actions/Actions_BookingManager.js';
import * as PackageAction from '../actions/Actions_PackageManager.js';
import * as AccountingActions from '../actions/Actions_Accounting.js';
import * as StatiticActions from '../actions/Actions_StatiticManager.js';


import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import '../App.css';

const _height = (window.innerHeight - 115);

const _CardInfo = {
    OneDayTrip: { name: 'OneDayTrip', amount: 0 },
    Package: { name: 'Package', amount: 0 },
    Transfer: { name: 'Transfer', amount: 0 }
}

const _ScardInfo = {
    Promotion: { name: 'PROMOTION', amount: 0 },
    SpacialDiscount: { name: 'SPECIAL DISCOUNT', amount: 0 },
}

let _bookingList = [];
let waitingTime = true;

class DashBoardPage extends Component {

    state = { isLoading: true }

    constructor(props) {

        super(props);

        // console.log(`DashBoardPage [constructor] : `, this.props);
        // const _bookingFilter = { paymentMethod: 'Alienation', limit: 8, bookingStatus: 'in-process,waiting,cancel,approved,reject' };//old true


        const _bookingFilter = { paymentMethod: 'Alienation', bookingStatus: 'in-process,waiting,cancel,approved,reject', limit: 8, };

        this.props.getAPIPackageList();
        this.props.getAPIBookingLists('', _bookingFilter);
        this.props.getAPISystemWallet();
    }

    componentWillMount() {
        //console.log(`DashBoardPage [componentWillMount] : `, this.props);
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager, BookingManager } = nextProps;
        //console.log(`DashBoardPage [componentWillReceiveProps] : `, PackageManager, ' : ', BookingManager);

        if (!!PackageManager)
            this.PackageManager(PackageManager.PACKAGE_REDUCER);
        if (!!BookingManager)
            this.BookingManager(BookingManager.BOOKING_REDUCER);
    }

    PackageManager(_packageInfo) {

        // console.log('PackageManager : ',_packageInfo)
        const { actions, data } = _packageInfo;

        switch (actions) {
            case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                {
                    _CardInfo.OneDayTrip.amount = data.packageTotal;
                    _CardInfo.OneDayTrip.package = data.package;
                } break;
            default: { }
        }
    }

    BookingManager(_bookingInfo) {

        //console.log('BookingManager : ',_bookingInfo)
        const { actions, data } = _bookingInfo;

        switch (actions) {
            case BookingAction.GET_BOOKING_LISTS_SUCCESS:
                {
                    // console.log(data)
                    // _bookingList=data.bookingList.slice(0,5).map(_booking=> { _booking.bookingStatus = 'in-process'; return _booking });

                    _bookingList = data;
                    SetStateWithTime(this, waitingTime);

                } break;

            case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
                {

                } break;

            case BookingAction.GET_BOOKING_LISTS_FAILURE:
                {
                    SetStateWithTime(this, waitingTime);
                } break;
        }
    }

    render() {

        //console.log('DashBoardPage [render] : ',this.props,CONFIG)
        const { isLoading } = this.state;

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height, marginTop: '15px', }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {

        const { history, BookingManager } = this.props;
        const { isLoading } = this.state;

        return (
            <div style={{ height: '100%', width: '100%' }}>
                <BigChartPanel BookinList={_bookingList} history={history} isLoading={isLoading} />
                <BookingStatusPanel BookinList={_bookingList} DisplayPage={PathName.DashBoardPage} history={history} isLoading={isLoading} />
                {/* <AccountingStatistic isLoading={isLoading} Role={'admin'} DisplayPage={PathName.DashBoardPage} /> */}
                {/* <InProcessListCard BookingList={_bookingList}
                    BookingDetail={(id) => this.CallBookingDetail(id)}
                    BookingManage={this.CallBookingManage} /> */}
                {/* {this.packageSetPanel()} */}
                {/* <BookingTable /> */}
                {this.subManageSetPanel()}
            </div>
        )
    }

    packageSetPanel() {

        return (
            <div className="App-content">
                <div style={{ flex: 1, marginRight: '30px' }}>
                    <InfoCard
                        Info={_CardInfo.OneDayTrip} Disable={false}
                        btnCreateHadle={this.btnCreateHadle(PathName.CreatePackage)}
                        btnViewHadle={this.btnViewHadle(PathName.PackageManage)} />
                </div>
                <div style={{ flex: 1, margin: '30px' }}>
                    <InfoCard Info={_CardInfo.Package} Disable={true} />
                </div>
                <div style={{ flex: 1, marginLeft: '30px' }}>
                    <InfoCard Info={_CardInfo.Transfer} Disable={true} />
                </div>
            </div>)
    }

    subManageSetPanel() {

        return (
            <div className="App-content">
                <div style={{ flex: 3.5, marginRight: '30px' }}>
                    <ManageListsPanel onClickHandle={this.onClickHandle()} />
                </div>
                <div style={{ flex: 1.5, float: 'right' }}>
                    <InfoSCard Info={_ScardInfo.Promotion} Disable={true} />
                    <InfoSCard Info={_ScardInfo.SpacialDiscount} Disable={true} />
                </div>
            </div>)
    }

    btnCreateHadle = (value) => () => { this.props.history.push(value); }
    btnViewHadle = (value) => () => { this.props.history.push(value); }

    onClickHandle = (btn_1, btn_2) => (value) => {

        let _path;

        switch (value) {
            case 'ACTIVITIES  MANAGE': _path = PathName.ActivityManage; break;
            case 'DESTINATION  MANAGE': _path = PathName.DestinationManage; break;
            case 'SUPPLIER  MANAGE': _path = PathName.SupplierManage; break;
            case 'SELLER  MANAGE': _path = PathName.AgentManage; break;
            case 'TRANSFER  MANAGE': _path = PathName.TransferManage; break;
            case 'MEMBER  MANAGE': _path = PathName.MemberManage; break;
            case 'CUSTOMER  MANAGE': _path = PathName.CustomerManage; break;
            case 'CONDITION  MANAGE': _path = PathName.ConditionManage; break;
            case 'APPOINTMENT  MANAGE': _path = PathName.AppointmentManage; break;
        }

        console.log('onClickHandle : ', value, ' btn :', btn_1, ' btn 2 : ', btn_2);

        if (_path !== undefined)
            this.props.history.push(_path);
    }

    // NavbarAcitve = (value) => {
    //   console.log('NavbarAcitve : ', value, ' : ', PathName.PackageManage)

    //   switch (value) {
    //     case PathName.PackageManage: { value = { pathname: value, state: { packageInfo: _CardInfo.OneDayTrip }, search: '', } } break;
    //     default: { }
    //   }
    //   this.props.history.push(value);
    // }

    CallBookingDetail(_id) {

        console.log('CallBookingDetail : ', _id)
        this.props.getAPIBookingDetails(_id);
        this.props.history.push({ pathname: PathName.BookingDetail, state: { bookingId: _id }, search: '', hash: '' });
        // this.props.history.push({ pathname: PathName.BookingDetail, state: {}, search: _id, hash: 'test' });
    }

    CallBookingManage = () => {

        console.log('CallBookingManage : ')
        this.props.getAPIBookingLists();
        this.props.history.push({ pathname: PathName.BookingManage, state: { _bookingList }, search: '', hash: '' });
    }

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingLists: (_keyword, _filter) => {
            dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingAction.getAPIBookingDetail(_id));
        },
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists());
        },
        getAPIAllSummaryBookingPaymentInfo: (_filter) => {
            dispatch(StatiticActions.getAllSummaryBookingPaymentInfo(_filter));
        },
        getAPISystemWallet: (_filter) => {
            dispatch(AccountingActions.getSystemWallet(_filter));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardPage);
