export const constraints = {
    title: {
        presence: { allowEmpty: false, },
    },
    firstName: {
        presence: { allowEmpty: false, },
    },
    lastName: {
        presence: { allowEmpty: false, },
    },
    email: {
        email: true,
        presence: { allowEmpty: false, },
    },
    level: {
        presence: { allowEmpty: false, },
    },
    type: {
        presence: { allowEmpty: false, },
    },
    telOffice: {
        format: {
            pattern: '^0(\\d{1,2})[0-9]{7}$',
            message: "can only contain 0-9"
        },
    },
    // code: {
    //     presence: { allowEmpty: false, },
    // },
    // number: {
    //     format: {
    //         pattern: '\\d[0-9]{8}$',
    //         message: "can only contain 0-9"
    //     },
    // }
    tel: {
        format: {
            pattern: '^0(\\d{1,2})[0-9]{7}$',
            message: "can only contain 0-9"
        },
    }
};
export const input = [
    { fieldName: 'title', },
    { fieldName: 'firstName', },
    { fieldName: 'lastName', },
    { fieldName: 'email', },
    { fieldName: 'level', },
    { fieldName: 'type', },
    // { fieldName: 'telOffice', },
    // { fieldName: 'tel', }
]
