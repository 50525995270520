import * as ZoneActions from '../actions/Actions_ZoneManager';

const INITIAL_STATE = { ZONE_REDUCER: { actions: ZoneActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: ZoneActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case ZoneActions.INITIAL_STATE:
            {
                return state;
            }
        case ZoneActions.IDLE_STATE:
            {
                //console.log("[ Zone ] : IDLE_STATE : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.IDLE_STATE, data: payload, error: _error } };
            }
        case ZoneActions.GET_ZONE_SUCCESS:
            {
                //console.log("[ Zone ] : GET_ZONE_SUCCESS : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.GET_ZONE_SUCCESS, data: payload, error: _error } };
            }
        case ZoneActions.GET_ZONE_FAILURE:
            {
                console.log("[ Zone ] : GET_ZONE_FAILURE : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.GET_ZONE_FAILURE, data: payload, error: _error } };
            }
        case ZoneActions.CREATE_ZONE_SUCCESS:
            {
                // console.log("[ Zone ] : CREATE_ZONE_SUCCESS : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.CREATE_ZONE_SUCCESS, data: payload, error: _error } };
            }
        case ZoneActions.CREATE_ZONE_FAILURE:
            {
                console.log("[ Zone ] : CREATE_ZONE_FAILURE : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.CREATE_ZONE_FAILURE, data: payload, error: _error } };
            }
        case ZoneActions.UPDATE_ZONE_SUCCESS:
            {
                // console.log("[ Zone ] : UPDATE_ZONE_SUCCESS : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.UPDATE_ZONE_SUCCESS, data: payload, error: _error } };
            }
        case ZoneActions.UPDATE_ZONE_FAILURE:
            {
                console.log("[ Zone ] : UPDATE_ZONE_FAILURE : ", payload);
                return { ...state, ZONE_REDUCER: { actions: ZoneActions.UPDATE_ZONE_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Zone ]  default :", state);
                return state;
            }
    }
}
