
const HeaderPage = {
    UPDATE_EN: "Update CloseService", UPDATE_TH: "Update CloseService", UPDATE_CN: "Update CloseService",
}

const BodyPage = [
    // [{
    //     title_EN: "", title_TH: "", title_CN: "",
    //     type: "checkbox",
    //     stateName: "isCloseListService",
    //     items: [
    //         { value: '', TH: { name: "เปิด" }, EN: { name: "Open" }, CN: { name: "" } },
    //     ],
    // }]
    [{
        // title_start: 'Start Date',
        // title_end: 'End Date',
        // title_service: 'Service Data',
        stateName: 'periodTime',
        structure: [
            [{
                start_EN: "Start Date", start_TH: "", start_CN: "",
                type: 'date-picker',
                require: true,
                end_EN: "End Date", end_TH: "", end_CN: "",
                stateStart: 'startDate', stateEnd: 'endDate',
            }], [
                { title_EN: "Net Price (Adult)", title_TH: "", title_CN: "", type: "number", stateGroup: 'netPrice', stateName: "adult", require: true, },
                { title_EN: "Margin (minimum 30%)", title_TH: "", title_CN: "", type: "number", stateName: "marginAdult", require: true, },
                { title_EN: "Sale Price", title_TH: "", title_CN: "", type: "number", stateGroup: 'price', stateName: "adult" }
            ], [
                { title_EN: "Net Price (Children)", title_TH: "", title_CN: "", type: "number", stateGroup: 'netPrice', stateName: "kid", require: true },
                { title_EN: "Margin (minimum 30%)", title_TH: "", title_CN: "", type: "number", stateName: "marginKid", disible: true, require: true, },
                { title_EN: "Sale Price", title_TH: "", title_CN: "", type: "number", stateGroup: 'price', stateName: "kid" }

            ], [{
                title_EN: "Service Data", title_TH: "", title_CN: "",
                type: "checkbox", stateName: "detail",
                items: [
                    { value: 'All', TH: { name: "ทั้งหมด" }, EN: { name: "All" }, CN: { name: "" } },
                    { value: 'Mon', TH: { name: "จันทร์" }, EN: { name: "Mon" }, CN: { name: "" } },
                    { value: 'Tue', TH: { name: "อังคาร" }, EN: { name: "Tue" }, CN: { name: "" } },
                    { value: 'Wed', TH: { name: "พุธ" }, EN: { name: "Wed" }, CN: { name: "" } },
                    { value: 'Thu', TH: { name: "พฤหัส" }, EN: { name: "Thu" }, CN: { name: "" } },
                    { value: 'Fri', TH: { name: "ศุกร์" }, EN: { name: "Fri" }, CN: { name: "" } },
                    { value: 'Sat', TH: { name: "เสาร์" }, EN: { name: "Sat" }, CN: { name: "" } },
                    { value: 'Sun', TH: { name: "อาทิตย์" }, EN: { name: "Sun" }, CN: { name: "" } },
                ],
            }], [{
                title_EN: "Close Service", title_TH: "", title_CN: "",
                type: "close_service", stateName: "isCloseListService",
                items: [
                    { value: '', TH: { name: "" }, EN: { name: "Close Service" }, CN: { name: "" } },
                ],
            }]
        ]
    }]
];

export default {
    HeaderPage,
    BodyPage,
}