import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import Button from '@material-ui/core/Button';

import CONFIG from '../../config';
import { Colors, Svg, Images, Fonts, Family } from '../../themes';

import '../../themes/Themes.css';
const iconSellerStyle = { width: '100%', height: '34px', cursor: 'pointer', marginTop: '5px', };
const btnStyle = { margin: 0, padding: 0, width: '100%' };

class CountryCardInfo extends Component {

    // state = { level: Levles.country };

    constructor(props) {

        super(props);

    }

    render() {

        const { itemHandle, onClickCardHandle, info, level, isVisible } = this.props;
        const { countryId, locationServiceId, EN, picture, amount, tag } = info;
        const cardStyle = { backgroundImage: `url(${CONFIG.host}${picture.thumbnail})` };

        const CardInfo = { id: (locationServiceId || countryId), level: level, tag: tag, name: EN.name, picture: picture };

        const loading = false;
        // const { packageInfo } = info;
        // console.log('info : ', info)
        // const { image, name } = packageInfo;

        const isAmount = Boolean(amount);

        return (
            <CardContianer key={countryId} style={cardStyle} isDisable={!isVisible} >
                <EmptyContianer onClick={isVisible ? itemHandle({ level, info }) : undefined}></EmptyContianer>
                <InfoContianer>
                    {(isAmount) ?
                        (
                            <LableContianer >
                                <NameContianer >{EN.name}</NameContianer>
                                <AmountContianer>{`${amount || 0} City`}</AmountContianer>
                            </LableContianer>
                        ) :
                        (
                            <LableContianer isDisable>
                                <NameContianer >{EN.name}</NameContianer>
                                <Button id="hoverGetLink" style={btnStyle} onClick={() => { isVisible && onClickCardHandle(CardInfo); console.log('CountryCardInfo : ', CardInfo) }} >
                                    <Svg.Landing.GetLink style={iconSellerStyle} />
                                </Button>
                            </LableContianer>
                        )
                    }
                </InfoContianer>
            </CardContianer>)
    }

    TranferCard(data, isMobile) {

        console.log("TranferCard : ", data);

        const { level, loading, } = this.state;

        // const { itemHandle, onClickCardHandle, info, isVisible } = data;
        const { countryId, locationServiceId, EN, picture, amount } = data;

        // const cardStyle = { backgroundImage: `url(${CONFIG.host}${picture.thumbnail})` };
        // const CardInfo = { id: (locationServiceId || countryId), name: EN.name, picture: picture };
        // const isAmount = Boolean(amount);

        return (
            <ImageContianer>
                <ImageInnerBox className="item" isMobile={isMobile} >
                    <ProductImage isMobile={isMobile} src={`${CONFIG.host}${picture.thumbnail}`} alt={EN.name} onLoad={() => { (loading) && this.setState({ loading: !loading }) }} />
                    {/* {(loading) && <LoadingContianer className="imageLoding" isMobile={isMobile} style={isMobile ? loadingStyleMobile : loadingStyle} />} */}
                </ImageInnerBox>
            </ImageContianer>)
    }
}

// =====================================================================================================================================================

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryCardInfo);

// =====================================================================================================================================================


const CardContianer = styled.div`
height: 189px;	
width: 189px;	
border: 1px solid #818181;	
background-repeat: round;
box-shadow: 0 3px 5px 0 rgba(0,0,0,0.7);
${(props) => props.isDisable && css`-webkit-filter: grayscale(100%);filter: grayscale(100%); cursor:unset;`}`;

const EmptyContianer = styled.div`	
width: '100%';
height: 96px;
cursor: pointer;`;

const InfoContianer = styled.div`	
height: 100px;	
width: '100%';	
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);`;

const LableContianer = styled.div`
padding: 10px 0px 0px 10px;
text-align: left;
${(props) => props.isDisable && css`padding: 15px 0px 0px 10px;`}`;

const NameContianer = styled.div`	
color: #FFFFFF;	
font-family: ${Family.roboto};	
font-size: 16px;	
font-weight: bold;	
line-height: 19px;`

const AmountContianer = styled.div`	
color: #FFFFFF;	
font-family: ${Family.roboto};	
font-size: 14px;	
font-weight: lighter;	
line-height: 19px;`

const ImgCountry = styled.img`
height: 189px;	
width: 189px;`;

const ImageContianer = styled.div`
height:${189}px;
width:${189}px;
box-shadow: 0 3px 5px 0 rgba(0,0,0,0.7);
&:hover .promotinPrice {-moz-transform: scale(1.12);-webkit-transform: scale(1.12);transform: scale(1.12);`;

const ImageInnerBox = styled.div`
width: 265px;
height: 211px;
position: relative;
${(props) => props.isMobile && css`width: 148.72px;height: 119.38px;`}`;

const ProductImage = styled.img`
background-color: rgba(155, 155, 155, 0.35);
width: 265px;
height: 211px;
${(props) => props.isMobile && css`width: 148.72px;height: 119.38px;`}`;

const LoadingContianer = styled.div`
left: 35%;
bottom: 35%;
position: absolute;
${(props) => props.isMobile && css`left: 30%;bottom: 24%;width: 60px;height: 60px;`}`;
// =====================================================================================================================================================