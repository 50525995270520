import { UserFormat } from '../JSON/UserFormat';
export const init_seller = {
    'title': 'Mr.', //prefix
    'name': '',
    'firstName': '',
    'lastName': '',
    'email': '',
    // 'tel': {
    //     'code': '+66',
    //     'number': ''
    // },
    'tel': '',
    'telOffice': '',
    'level': '',
    'type': '',
    'address': {
        'no': '',
        'road': '',
        'country': 'Thailand',
        'province': '',
        'district': '',
    },
    'marketingChannel': {
        'facebook': '',
        'instagram': '',
        'twitter': '',
        'website': '',
        'hotel': [{
            'name': ''
        }]
    },
    'bank': {
        'bankId': '',
        'accountNumber': ''
    },
    'actived': '',
    'logo': [],
    //-----------------
    'agentId': '',
    'reservationNumber': '',
    'officialWebsite': '',
    'priceGroup': '',
    'tag': '',
    'affiliateId': '',
    'creditTermId': '',
    'credit': '',
    'emergencyContact': [{
        'tel': '',
        'person': '',
    }],
}


export const SetSellerToCreate = (seller) => {

    const _userInfo = { ...UserFormat };
    const _sellerInfo = { ...seller };
    // console.log('seller : ', seller);

    _userInfo.username = seller.email;
    _userInfo.password = seller.email;
    _userInfo.userType = 'seller';
    _userInfo.email = seller.email;
    _userInfo.visible = seller.visible || false;

    _sellerInfo.name = seller.title + seller.firstName + ' ' + seller.lastName;
    _userInfo.user = _sellerInfo;
    // console.log('_userInfo : ', _userInfo);

    return _userInfo;
}
export const SetSellerToUpdate = (seller) => {

    const _sellerInfo = { ...seller };

    _sellerInfo.name = seller.title + seller.firstName + ' ' + seller.lastName;

    // console.log('_sellerInfo : ', _sellerInfo);

    return _sellerInfo;
}
