const HeaderPage = {
    CREATE_EN: "Create Promotion Code", CREATE_TH: "----", CREATE_CN: "----",
    UPDATE_EN: "Update Promotion Code", UPDATE_TH: "----", UPDATE_CN: "----",
}

const SelectCodeType = [
    { id: 0, value: "all", label: "All" },
    { id: 1, value: "seller", label: "Seller" },
    { id: 2, value: "supplier", label: "Supplier" },
    { id: 3, value: "agent", label: "Agent" },
    { id: 4, value: "system", label: "System" },
    { id: 5, value: "blogger", label: "Blogger" },
    { id: 6, value: "hotel", label: "Hotel" },
]

const ConditionComponent = [
    [{
        title_TH: "---", title_EN: "Minimum", title_CN: "---",
        type: "checkbox",
        width: '50%',
        flex: true,
        stateName: 'checkMin',
        required: false,
    },
    {
        title_TH: "---", title_EN: "Minimum", title_CN: "---",
        type: "number",
        // width: '25%',
        flex: true,
        stateName: 'minimum',
        required: false,
    }],
    [{
        title_TH: "---", title_EN: "Maximum", title_CN: "---",
        type: "checkbox",
        width: '50%',
        flex: true,
        stateName: 'checkMax',
        required: false,
    },
    {
        title_TH: "---", title_EN: "Maximum", title_CN: "---",
        type: "number",
        // width: '25%',
        flex: true,
        stateName: 'maximum',
        required: false,
    }],
    [{
        title_TH: "---", title_EN: "Specify Time", title_CN: "---",
        type: "checkbox",
        width: '50%',
        stateName: 'checkDate',
        required: false,
    },
    {
        title_start: "Start Time", title_end: "End Time",
        type: "time",
        stateName_start: 'startTimeCondition',
        stateName_end: "endTimeCondition",
        // required: false,
    }],
    [{
        title_TH: "---", title_EN: "Identify product", title_CN: "---",
        type: "checkbox",
        width: '50%',
        stateName: 'checkProduct',
        required: false,
    },
    {
        title_TH: "---", title_EN: "Select Product", title_CN: "---",
        type: "button-list",
        width: '100%',
        stateName: 'product',
        required: false,
    }],
]

const BodyPage = [
    [{
        title_TH: "---", title_EN: "Promotion Name", title_CN: "---",
        type: "text",
        margin: 'right',
        required: true,
        stateName: "namePromotion",
        placeholder_TH: "-----", placeholder_EN: "----", placeholder_CN: "-----"
    },
    {
        title_EN: "Promotion Code", title_TH: "", title_CN: "",
        type: 'text',
        stateName: "promotionCode",
        required: true,
        helperText: 'More than 5 characters.',
        placeholder_TH: "-----", placeholder_EN: "----", placeholder_CN: "-----"
    }],
    [{
        title_EN: "Description", title_TH: "", title_CN: "",
        type: 'text-multiline',
        stateName: "description",
        required: true,
        placeholder_TH: "-----", placeholder_EN: "----", placeholder_CN: "-----"
    }],
    [{
        title_TH: "---", title_EN: "Promotion Time", title_CN: "---",
        type: 'date',
        stateStart: "startDate",
        stateEnd: "endDate",
        required: true,
    }],
    [{
        title_EN: "Limit", title_TH: "", title_CN: "",
        type: 'number',
        stateName: "limit",
        required: true,
        margin: 'right',
        placeholder_TH: "-----", placeholder_EN: "----", placeholder_CN: "-----"

    },
    {
        title_EN: "Discount Type", title_TH: "", title_CN: "",
        type: 'select',
        stateName: "discountType",
        margin: 'right',
        // required: true,
        option: [
            { name: 'price', value: 'price' },
            { name: 'percent', value: 'percent' },
            { name: 'fixPrice', value: 'fixPrice' }
        ],
    },
    {
        title_EN: "Discount", title_TH: "", title_CN: "",
        type: 'number',
        margin: 'right',
        stateName: "discountValue",
        required: true,
        placeholder_TH: "-----", placeholder_EN: "----", placeholder_CN: "-----"
    },
    {
        title_EN: "Currency", title_TH: "", title_CN: "",
        type: 'select',
        // margin: 'right',
        stateName: "currency",
        required: true,
        placeholder_TH: "-----", placeholder_EN: "----", placeholder_CN: "-----"
    }],
    [{
        title_EN: "Code Type", title_TH: "", title_CN: "",
        type: 'select',
        stateName: "codeType",
        required: true,
    }],
    [{
        title_EN: "Receiver", title_TH: "", title_CN: "",
        type: 'button-list',
        stateName: "tag",
        required: false,

    }],
    [{
        type: 'condition'
    }],
    [{
        type: 'product-list'
    }],
    [{
        title_EN: "Status", title_TH: "", title_CN: "",
        type: 'switch',
        stateName: "actived",
        required: false,
    }]
];

export default {
    HeaderPage,
    BodyPage,
    SelectCodeType,
    ConditionComponent,
} 