const HeaderPage = {
    TH: "สร้างอัตราเงิน",
    EN: "Create Currency Rate",
    CN: "创造条件",
    UPDATE_EN: "Update Currency Rate"
}

const BodyPage = [
    [{
        title_TH: "อัตราสกุลเงิน", title_EN: "Currency Rate", title_CN: "名称",
        type: "text",
        format: 'price',
        isRequired: true,
        stateName: "currencyRate",
        placeholder_TH: "อัตราสกุลเงิน", placeholder_EN: "Insert Currency Rate", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ประเภทสกุลเงินจาก", title_EN: "Currency Rate From", title_CN: "包装类型",
        type: "select",
        isRequired: true,
        stateName: "currencyTypeFrom",
        placeholder_TH: "ประเภทสกุลเงินจาก", placeholder_EN: "Currency Rate From", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ประเภทสกุลเงินถึง", title_EN: "Currency Rate To", title_CN: "包装类型",
        type: "select",
        isRequired: true,
        stateName: "currencyTypeTo",
        placeholder_TH: "ประเภทสกุลเงินถึง", placeholder_EN: "Currency Rate To", placeholder_CN: "插入名称"
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "actived",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}