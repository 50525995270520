
export const content_withdraw = {
    header: 'Withdraw Money',
}

export const withdrawStruct = {
    id: '',
    wallet: 0,
    amount: 0,
    bank: '',
    name: '',
    userType: '',
    logo: '',
}

export const create_withdraw = [
    [
        {
            title: 'Amout Withdraw',
            type: 'number',
            stateName: "amount",
            placeholder: 'Amout Withdraw',
            required: true,
        },
    ],
]


