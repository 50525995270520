import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import { path, equals, keys, omit } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import FormTypes from '../../common/FormTypes';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import CONFIG from '../../config';
import Avatar from '../../constant/Avatar';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import * as UploadActions from '../../actions/Actions_UploadManager';
import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Bank';
import Bank from '../../constant/JSON/Bank';
import Language from '../../constant/Language';
import * as SettingActions from '../../actions/Actions_Settings';
const validate = require("validate.js");
let waitingTime = true;
const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };

class BankCard extends Component {
    state = {
        isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false,
    }

    constructor(props) {
        super(props);
        this.state = {
            BankInfo: this.props.BankInfo,
        }
    }

    componentWillReceiveProps(nextProps) {
        const { SettingManager, UpLoadManager } = nextProps;

        if (!!SettingManager) {
            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.CREATE_BANK_SETTING_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Bank Success.', dialogType: 'success', });
                        const { language } = this.props.BookBankList
                        this.setState({ bankSettingId: data.bankSettingId, isUpdate: true })
                        this.props.getAPIgetBankList({ page: 0, num_limit: 10, language: language });
                    } break;

                case SettingActions.UPDATE_BANK_SETTING_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Bank Success.', dialogType: 'success', });
                        const { language } = this.props.BookBankList
                        this.setState({ isUpdate: false })
                        this.props.getAPIgetBankList({ page: 0, num_limit: 10, language: language });
                    } break;
                case SettingActions.CREATE_BANK_SETTING_FAILURE:
                case SettingActions.UPDATE_BANK_SETTING_FAILURE:
                    {
                        console.log("UPDATE_BANK_SETTING_FAILURE : ", data.response.data.error.message);
                        const _msg = data.response.data.error.message || actions;//ErrorCode[data.status].EN;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!UpLoadManager) {
            const { isEdit } = this.props;
            const { isUpdate, bankSettingId } = this.state;

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let BankInfo = { ...this.state.BankInfo };
                        BankInfo.logo = data.result.upload.images;

                        if (isEdit) {
                            this.props.APIUpdateBank(BankInfo);
                        } else if (isUpdate) {
                            BankInfo = { ...BankInfo, bankSettingId: bankSettingId }
                            this.props.APIUpdateBank(BankInfo);
                        } else {
                            this.props.APICreateBank(BankInfo);
                        }

                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    };


    render() {

        const { classes, isView, isEdit } = this.props;
        const { msgError, dialogType, isLoading, isBtnActive } = this.state;
        const { onReset, onSave } = this;
        const LanguageBank = path(['language',], this.props.BookBankList);
        return (

            <div style={{ maxWidth: 800, backgroundColor: Colors.white, textAlign: ' start', }} >
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isView ? null : isEdit ? Bank.HeaderPage.UPDATE_EN : Bank.HeaderPage.EN}</Typography>
                    {isView ? null : <Language LanguagePage={LanguageBank} onChangeState={this.props.onChangeLanguage} isValue={true} />}
                </div>

                {Bank.BodyPage.map((row, index) => {
                    return (<div key={index}> {row.map((each, index) => {
                        return (<div key={index} style={_style}>
                            {this.SetItemsWithType(each, LanguageBank)}
                        </div>)
                    })}
                    </div>
                    )
                })}

                {!isView ?
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onReset()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => onSave()} />
                    </div>
                    : null
                }

            </div >
        );
    }
    SetItemsWithType(each, LanguageBank) {
        const { classes, isView,isEdit } = this.props;
        const { BankInfo } = this.state;
        const { onChangeState } = this;
        const valueLanguage = path([LanguageBank, each.stateName], BankInfo);
        switch (each.type) {
            case "text": {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.isRequired}
                        TitleText={each.title_EN}
                        value={valueLanguage || ''}
                        onChange={(e) => { onChangeState({ [each.stateName]: e.target.value }) }} />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={3}
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.isRequired}
                        style={{ marginBottom: 10 }}
                        TitleText={each.title_EN}
                        value={valueLanguage || ''}
                        onChange={(e) => { onChangeState({ [each.stateName]: e.target.value }) }} />
                )
            }
            case 'image': {
                return (
                    <div >
                        {
                            (isView) ? 
                            <div className={classes.avatar} 
                            style={{
                                backgroundSize: 'cover',
                                backgroundColor: Colors.lightGrey , 
                                backgroundImage:`url("${CONFIG.host}${BankInfo.logo.thumbnail}")`}} 
                            />
                                :
                                <Avatar ref='avatar' editable={true}
                                    onRemove={() => this.onRemoveImage()}
                                    onChange={(files) => { onChangeState({ [each.stateName]: files }) }}
                                    avatar={Boolean(BankInfo.logo) ? `${CONFIG.host}${BankInfo.logo.thumbnail}` : ''} />
                        }
                    </div>
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], BankInfo) || false}
                        onChange={(e) => { onChangeState({ [each.stateName]: e.target.checked }) }} />
                )
            }
        }
    }
    onChangeState = (state) => {
        let BankInfo = { ...this.state.BankInfo };

        const key = Object.keys(state)[0];
        const LanguageBank = path(['language'], this.props.BookBankList) || 'EN';

        switch (key) {
            case "name":
            case "description":
                { BankInfo = { ...BankInfo, [LanguageBank]: { ...BankInfo[LanguageBank], ...state } } } break;
            default: { BankInfo = { ...BankInfo, ...state } }
        }

        console.log('bank', BankInfo);
        this.setState({ BankInfo, isBtnActive: true },
        );
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        const LanguageBank = path(['language'], this.props.BookBankList);
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.BankInfo[LanguageBank][each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }


    onReset = () => {
        this.setState({ BankInfo: undefined })
        this.props.onClosePanel()
    }

    onRemoveImage = () => {
        const { BankInfo } = this.state;
        this.setState({ BankInfo: { ...BankInfo, logo: { original: "", thumbnail: "" } }, isBtnActive: true })
        this.refs.avatar.remove('file');
    }

    onSave = () => {

        const { isEdit } = this.props;
        const { isUpdate, bankSettingId } = this.state;
        let BankInfo = { ...this.state.BankInfo }
        const newAvatar = this.refs.avatar.getAvatar('file');
        console.log('newAvatar', this.refs, BankInfo);

        if (this.validateAll()) {
            if (newAvatar) {
                this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadImage(newAvatar));
            }
            else {
                this.setState({ isLoading: true, isBtnActive: false });

                if (isEdit) {
                    this.props.APIUpdateBank(BankInfo);
                }
                else if (isUpdate) {
                    BankInfo = { ...BankInfo, bankSettingId: bankSettingId };
                    this.props.APIUpdateBank(BankInfo);
                }
                else {
                    this.props.APICreateBank(BankInfo);
                }
            }
        }
        else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error);
        }
    }

    APIUpLoadImage = (newAvatar) => {

        let formdata = new FormData();
        formdata.append('imgThumbnailWidth', 200);
        formdata.append('imgThumbnailHeight', 200);
        formdata.append("images", newAvatar);

        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}



const styles = theme => (
    {
        title: { fontSize: '24px', display: 'flex' },
        textField: { width: '100%' },
        textFormLabel: { fontSize: '18px' },
        textDefalut: { fontSize: '15px', color: Colors.themeDark, fontFamily: Family.roboto },
        textInput: {
            marginTop: '5px',
            padding: '10px 15px',
            color: Colors.themeDark,
            border: `${'1px solid' + Colors.border}`,
            borderBottom: '0px',
            fontSize: '14px',
        },
        textMultiline: {
            marginTop: '5px',
            padding: '10px 15px',
            color: Colors.themeDark,
            border: `${'1px solid' + Colors.border}`,
            borderBottom: '0px',
            fontSize: '14px',
            marginBottom: '-7px',
        },
        avatar: {
            height: '120px',
            width: '120px',
            margin: '14px 0px',
            boxShadow: '0 2px 5px 0 hsla(0,0%,47%,.5)',
        }
    });

BankCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIgetBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(SettingActions.getBankList(_keyword));
        },
        APICreateBank: (confirmInfo) => {
            dispatch(SettingActions.APICreateBank(confirmInfo));
        },
        APIUpdateBank: (confirmInfo) => {
            dispatch(SettingActions.APIUpdateBank(confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BankCard));
