import React, { Component } from 'react';
import { connect } from 'react-redux';
import validate from "validate.js";
import '../Supplier/Supplier.css';
import { path, equals, keys, omit } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';
import { Colors, Fonts } from '../../themes';
import FormTypes from '../../common/FormTypes';

import Condition from '../../constant/JSON/Condition';
import Language from '../../constant/Language';
import * as validator from '../../constant/Validator/Condition';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import DialogPanel from '../../componets/DialogPanel.jsx';

import * as ConditionActions from '../../actions/Actions_Conditions';

const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex' };

let waitingTime = true;

class ConditionCard extends Component {

  state = { isDialog: false, msgError: undefined, dialogType: undefined, error: null }

  constructor(props) {
    super(props);
    this.state = {
      ConditionInfo: this.props.ConditionInfo,
      oldConditionInfo: this.props.oldConditionInfo,
      isUpdate: false,
    }

  }
  componentWillReceiveProps = (nextProps) => {
    const { ConditionManager } = nextProps;

    if (!!ConditionManager) {
      const { actions, data } = ConditionManager.CONDITION_REDUCER;

      switch (actions) {
        case ConditionActions.CREATE_CONDITIONS_SUCCESS:
          {
            SetStateWithObject(this, waitingTime, { msgError: 'Create Condition Success.', dialogType: 'success', });
            this.setState({ conditionId: data.conditionId, isUpdate: true })
            this.props.getAPIConditionsList({ num_limit: 10 });
          } break;

        case ConditionActions.UPDATE_CONDITIONS_SUCCESS:
          {
            SetStateWithObject(this, waitingTime, { msgError: 'Update Condition Success.', dialogType: 'success', });
            this.setState({ isUpdate: false })
            this.props.getAPIConditionsList({ num_limit: 10 });
          } break;

        case ConditionActions.CREATE_CONDITIONS_FAILURE:
        case ConditionActions.UPDATE_CONDITIONS_FAILURE:
          {
            const _msg = data.response.data.error.message || actions;
            SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
          } break;
      }
    }
  }

  render() {
    const { classes, onClosePanel, isView, isEdit } = this.props;
    const { msgError, dialogType, isDialog, isBtnActive } = this.state;
    const LanguageConditions = path(['language',], this.props.ConditionsList);
    return (
      <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
        {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
        {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className={classes.title}>{isEdit ? Condition.HeaderPage.UPDATE : Condition.HeaderPage.EN}</Typography>
          <Language LanguagePage={LanguageConditions} onChangeState={this.props.onChangeLanguage} isValue={true} />
        </div>}
        <div >
          {
            Condition.BodyPage.map((row, index) => {
              return (
                <div key={index} style={_rowContainer}>           {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each, LanguageConditions)}</div>))} </div>
              )
            })
          }
        </div>
        {!isView &&
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button.Themes
              TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
              onClick={() => onClosePanel()} />
            <Button.Themes
              TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
              onClick={() => { this.onSave() }} />
          </div>}
      </div >
    );
  }

  SetItemsWithType(each, LanguageConditions) {

    const { isView } = this.props;
    const { ConditionInfo } = this.state;
    const isError = !!path([each.stateName], this.error);
    const valueLanguage = path([LanguageConditions, each.stateName], ConditionInfo);

    switch (each.type) {
      case 'text': {
        return (
          <FormTypes.TextFormat
            disabled={isView ? true : false}
            error={isError}
            Require={isView ? false : each.require}
            TitleText={each.title_EN}
            // placeholder={each.placeholder_EN}
            value={valueLanguage || ''}
            onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
        )
      }
      case 'text-multiline': {
        return (
          <FormTypes.TextFormat multiline rows={6}
            style={{ marginBottom: 10 }}
            disabled={isView ? true : false}
            error={isError}
            Require={isView ? false : each.require}
            TitleText={each.title_EN}
            // placeholder={each.placeholder_EN}
            value={valueLanguage || ''}
            onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
        )
      }
      case 'switch': {
        return (
          <FormTypes.Switches Left
            ValueText={each.title_EN}
            // TitleText={each.title_EN}
            disabled={isView ? true : false}
            checked={path([each.stateName], ConditionInfo)}
            onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
        )
      }
    }
  }
  onChangeState = (fieldName, state) => {
    let ConditionInfo = { ...this.state.ConditionInfo };
    const { oldConditionInfo } = this.state;
    const LanguageConditions = path(['language',], this.props.ConditionsList);

    switch (fieldName) {
      case "name":
      case "description": {
        ConditionInfo = { ...ConditionInfo, [LanguageConditions]: { ...ConditionInfo[LanguageConditions], [fieldName]: state } }
      } break;
      default: { ConditionInfo = { ...ConditionInfo, [fieldName]: state } }
    }
    console.log('condition success', ConditionInfo)
    this.setState({ ConditionInfo, isBtnActive: !equals(oldConditionInfo, ConditionInfo) }, () => { this.validate({ [fieldName]: state }); });
  }

  validate = (value) => {
    const fieldName = keys(value)[0];
    let result = true;
    if (!!validator.constraints[fieldName]) {
      const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
      const error = this.error || {};

      if (!!validateResult) {
        result = false;
        this.error = { ...error, ...validateResult };
        this.forceUpdate();
      } else {
        this.error = omit([fieldName], error);
        this.forceUpdate();
      }
    }
    return result;
  }

  validateAll() {
    let success = true;
    const LanguageConditions = path(['language',], this.props.ConditionsList);
    validator.input.forEach(each => {
      const validateResult = this.validate({
        [each.fieldName]: this.state.ConditionInfo[LanguageConditions][each.fieldName],
      });;
      success = success && validateResult;
    });
    return success;
  }

  onSave = () => {
    let ConditionInfo = { ...this.state.ConditionInfo }
    const { isEdit } = this.props;
    const { isUpdate, conditionId } = this.state;

    if (this.validateAll()) {

      this.setState({ isDialog: true, isBtnActive: false });

      if (isEdit) {
        this.props.APIUpdateConditions(ConditionInfo);
      }
      else if (isUpdate) {
        ConditionInfo = { ...ConditionInfo, conditionId: conditionId };
        this.props.APIUpdateConditions(ConditionInfo);
      }
      else {
        this.props.APICreateConditions(ConditionInfo);
      }
    }
    else { this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error) }
  }
  onCloseDialog = () => (this.setState({ isDialog: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({ title: { fontSize: '24px', display: 'flex' }, });

ConditionCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIConditionsList: (_keyword, _filter) => {
      InitWaitingTime();
      dispatch(ConditionActions.getConditionsList(_keyword, _filter));
    },
    APICreateConditions: (_confirmInfo) => {
      dispatch(ConditionActions.APICreateConditions(_confirmInfo));
    },
    APIUpdateConditions: (_confirmInfo) => {
      dispatch(ConditionActions.APIUpdateConditions(_confirmInfo));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConditionCard));
