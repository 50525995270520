import React from 'react';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';



import { PathName, Roles } from './constant';
import { isMobile, } from './common/DeviceCheck';
import { Colors, Family } from './themes/index.js';
//import Icons from './constant/Button/Icons.js';
import { Icons as themesIcons } from './themes'
const {
    AccountingIcon, ActivityIcon, BookingIcon, CountryIcon, CustomerIcon
    , DashboardIcon, DraftsIcon, ExpandMore, ExpandLess, FolderSpecial
    , LogOut, MapIcon, MemberIcon, PagesIcon, PanoramaIcon, PersonAddIcon
    , PlaceIcon, ProductIcon, PromotionIcon, ReaderIcon, SettingsIcon, SpaIcon
    , SpplierIcon, StarBorder, TranferIcon, ZoneIcon, SendIcon, HotelIcon
} = themesIcons;

let _isMobile;

class NestedList extends React.Component {

    state = { open: true, subItemOpen: false, Promotion: false, Products: false };

    render() {

        _isMobile = isMobile();
        const { classes } = this.props;
        const _menuList = GetListMenu(_isMobile);

        return (
            <div className={classes.root}>
                <List component="nav">
                    {_menuList.map((_menu, index) => (
                        <div key={index} >
                            {(typeof (_menu) === 'object') ? this.NestedCollapse(_menu) : this.NestedItem(_menu)}
                        </div>)
                    )}
                </List>
            </div>
        );
    }

    getIcon(name) {

        switch (name) {
            case 'Activities': return <ActivityIcon />
            case 'Accounting': return <AccountingIcon />
            case 'Agent': return <SpaIcon />
            case 'Appointment': return <MapIcon />
            case 'Booking': return <BookingIcon />
            case 'Category Manage': return <SettingsIcon />
            case 'Codes': return <StarBorder />
            case 'Commission': return <ReaderIcon />
            case 'Condition': return <ReaderIcon />
            case 'Country': return <CountryIcon />
            case 'Customer': return <CustomerIcon />
            case 'Dashboard': return <DashboardIcon />
            case 'Default Commission': return <ReaderIcon />
            case 'Destination': return <PanoramaIcon />
            case 'Locations': return <PlaceIcon />
            case 'Logout': return <LogOut />
            case 'Management': return <SettingsIcon />
            case 'Other': return <SettingsIcon />
            case 'Packages': return <ProductIcon />
            case 'Payment': return <AccountingIcon />
            case 'Products': return <PagesIcon />
            case 'Promotion': return <PromotionIcon />
            case 'Seller': return <PersonAddIcon />
            case 'Seller Commission': return <FolderSpecial />
            case 'Seller Dashboard': return <ReaderIcon />
            case 'Seller Managment': return <ReaderIcon />
            case 'Service': return <SettingsIcon />
            case 'Reservation Table': return <SettingsIcon />
            case 'Settings': return <SettingsIcon />
            case 'Special': return <FolderSpecial />
            case 'Special Commission': return <FolderSpecial />
            case 'Staff': return <MemberIcon />
            case 'Supplier': return <SpplierIcon />
            case 'Transfer': return <TranferIcon />
            case 'User': return <MemberIcon />
            case 'Withdraw': return <SendIcon />
            case 'Refund Request': return <SendIcon />
            case 'Website Managment': return <SettingsIcon />
            case 'Zone': return <ZoneIcon />
            case 'Hotel': return <HotelIcon />
            default: return <DraftsIcon />

        }
    }

    onClickHandle = (value) => () => {

        let _path;

        const _info = JSON.parse(localStorage.getItem('UserInfo'));
        const _role = (!!_info) ? (_info.userType || '') : '';

        switch (_role) {

            case Roles.Admin: {
                switch (value) {
                    case 'Accounting': _path = PathName.AccountingManage; break;
                    case 'Management': _path = PathName.TransactionManagement; break;
                    case 'Activities': _path = PathName.ActivityManage; break;
                    case 'Announcement': _path = PathName.AnnouncementManage; break;
                    case 'Agent': _path = PathName.AgentManage; break;
                    case 'Appointment': _path = PathName.AppointmentManage; break;
                    case 'Booking': _path = PathName.BookingManage; break;
                    case 'Category Manage': _path = PathName.CategoryManage; break;
                    case 'Codes': _path = PathName.PromotionCode; break;
                    case 'Commission': _path = PathName.CommissionManage; break;
                    case 'Condition': _path = PathName.ConditionManage; break;
                    case 'Country': _path = PathName.CountryManage; break;
                    case 'Customer': _path = PathName.CustomerManage; break;
                    case 'Dashboard': _path = PathName.DashBoardPage; break;
                    case 'Default Commission': _path = PathName.DefaultCommissionManage; break;
                    case 'Destination': _path = PathName.DestinationManage; break;
                    case 'Locations': _path = PathName.LocationManage; break;
                    case 'Other': _path = PathName.OtherManage; break;
                    case 'Packages': _path = PathName.PackageManage; break;
                    case 'Payment': _path = PathName.PaymentManage; break;
                    case 'Products': _path = PathName.PackageManage; break;
                    case 'Promotion': _path = PathName.PromotionManage; break;
                    case 'Seller': _path = PathName.SellerManage; break;
                    case 'Seller Commission': _path = PathName.SellerCommission; break;
                    case 'Seller Dashboard': _path = PathName.SellerDashboard; break;
                    case 'Seller Managment': _path = PathName.SellerManagment; break;
                    case 'Service': _path = PathName.ServiceManage; break;
                    case 'Reservation Table': _path = PathName.ReservationManage; break;
                    case 'Service': _path = PathName.ServiceManage; break;
                    case 'Settings': _path = PathName.Settings; break;
                    case 'Special': _path = PathName.SpecialManage; break;
                    case 'Special Commission': _path = PathName.SpecialCommission; break;
                    case 'Staff': _path = PathName.MemberManage; break;
                    case 'Supplier': _path = PathName.SupplierManage; break;
                    case 'Transfer': _path = PathName.TransferManage; break;
                    case 'User': _path = PathName.UserManage; break;
                    case 'Withdraw': _path = PathName.WithdrawManagment; break;
                    case 'Refund Request': _path = PathName.RefundRequestManage; break;
                    case 'Website Managment': _path = PathName.WebsiteManage; break;
                    case 'Zone': _path = PathName.ZoneManage; break;
                    case 'Hotel': _path = PathName.Hotel; break;
                    default: _path = PathName.PageNotFound; break;
                }

                // console.log('onClickHandle : ', value, ' : ', _role, ' : ', _path);

                if (Boolean(_path))
                    this.props.history.push(_path);

            } break;

            case Roles.Supplier: {
                switch (value) {
                    case 'Accounting': _path = PathName.AccountingManage; break;
                    case 'Activities': _path = PathName.ActivityManage; break;
                    case 'Appointment': _path = PathName.AppointmentManage; break;
                    case 'Booking': _path = PathName.BookingManage; break;
                    case 'Condition': _path = PathName.ConditionManage; break;
                    case 'Dashboard': _path = PathName.DashBoardPage; break;
                    case 'Destination': _path = PathName.DestinationManage; break;
                    case 'Promotion': _path = PathName.PromotionManage; break;
                    case 'Settings': _path = PathName.Settings; break;
                    default: _path = PathName.PageNotFound; break;
                }

                const supplierInfo = this.props.location.state || JSON.parse(localStorage.getItem('supplierInfo'))
                const isInfo = Boolean(supplierInfo);

                if (isInfo && (!Boolean(localStorage.getItem('supplierInfo')))) {
                    localStorage.setItem('supplierInfo', JSON.stringify(supplierInfo));
                }

                // console.warn('!!! : ', supplierInfo);
                // console.log('onClickHandle : ', value, ' : ', _role, ' : ', _path);

                if (Boolean(_path) && isInfo)
                    this.props.history.push({ pathname: _path, state: supplierInfo, search: '', hash: '' });
                else
                    this.props.history.goBack();

            } break;

            case Roles.Agent: {
                switch (value) {
                    case 'Activities': _path = PathName.ActivityManage; break;
                    case 'Booking': _path = PathName.BookingManage; break;
                    case 'Dashboard': _path = PathName.DashBoardPage; break;
                    case 'Promotion': _path = PathName.PromotionManage; break;
                    case 'Settings': _path = PathName.Settings; break;
                    default: _path = PathName.PageNotFound; break;
                }

                const agentInfo = this.props.location.state || JSON.parse(localStorage.getItem('agentInfo'))
                const isInfo = Boolean(agentInfo);

                if (isInfo && (!Boolean(localStorage.getItem('agentInfo')))) {
                    localStorage.setItem('agentInfo', JSON.stringify(agentInfo));
                }

                // console.warn('!!! : ', agentInfo);
                // console.log('onClickHandle : ', value, ' : ', _role, ' : ', _path);

                if (Boolean(_path) && isInfo)
                    this.props.history.push({ pathname: _path, state: agentInfo, search: '', hash: '' });
                else
                    this.props.history.goBack();

            } break;

            case Roles.Seller: {

                switch (value) {
                    case 'Accounting': _path = PathName.AccountingManage; break;
                    case 'Booking': _path = PathName.BookingManage; break;
                    case 'Dashboard': _path = PathName.DashBoardPage; break;
                    case 'Products': _path = PathName.Products; break;
                    case 'Codes': _path = PathName.PromotionCode; break;
                    case 'Settings': _path = PathName.SettingSeller; break;
                    case 'Logout': _path = PathName.LoginPage; break;
                    default: _path = PathName.PageNotFound; break;
                }

                const { location, history } = this.props;
                const sellerInfo = location.state || JSON.parse(localStorage.getItem('sellerInfo'))
                const isInfo = Boolean(sellerInfo);

                if (value === 'Logout')
                    localStorage.clear();
                else if (isInfo && (!Boolean(localStorage.getItem('sellerInfo')))) {
                    localStorage.setItem('sellerInfo', JSON.stringify(sellerInfo));
                }

                // console.warn('!!! : ', sellerInfo);
                // console.log('onClickHandle : ', value, ' : ', _role, ' : ', _path);

                if (Boolean(_path) && isInfo)
                    history.push({ pathname: _path, state: sellerInfo, search: '', hash: '' });
                else
                    history.goBack();

            } break;

            default: { };
        }
    }

    CheckItemActive(_item) {

        const { pathname } = this.props.history.location;
        const isAcitve = RegExp(`${_item.replace(' ', '')}*`, 'gi').test(pathname);

        // console.log('CheckItemActive : ', isAcitve, ' item : ', _item, ' pathname : ', pathname);

        return (isAcitve) ? hilightItemStyle : {};
    }

    handleClick = () => { this.setState({ open: !this.state.open }); };

    handleSubItemClick = (item) => { this.setState({ [item]: !this.state[item] }); };

    NestedItem(_menu) {

        const { classes } = this.props;

        return (
            <React.Fragment>
                <ListItem style={this.CheckItemActive(_menu)} button onClick={this.onClickHandle(_menu)}>
                    <ListItemIcon className={classes.menuIcons}>
                        {this.getIcon(_menu)}
                    </ListItemIcon>
                    <ListItemText inset primary={_menu} disableTypography className={classes.menuLable} />
                </ListItem>
                <Divider />
            </React.Fragment >);
    }

    NestedCollapse(_menu) {

        const { classes } = this.props;
        const { menuIcons, RIcons, menuLable, nested } = classes;

        const _ItemHeader = Object.keys(_menu)[0];
        const subItemOpen = this.state[_ItemHeader];

        return (
            <React.Fragment>
                <ListItem button onClick={() => this.handleSubItemClick(_ItemHeader)}>
                    <ListItemIcon className={menuIcons}>
                        {this.getIcon(_ItemHeader)}
                    </ListItemIcon>
                    <ListItemText inset primary={_ItemHeader} disableTypography className={menuLable} />
                    <ListItemIcon className={RIcons}>{subItemOpen ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
                </ListItem>
                <Collapse in={subItemOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding style={ItemStyle}>
                        {_menu[_ItemHeader].map((_item, index) => {
                            return (
                                <ListItem key={`${_item}_${index}`} button className={nested} style={this.CheckItemActive(_item)} onClick={this.onClickHandle(_item)} >
                                    <ListItemIcon >
                                        {this.getIcon(_item)}
                                    </ListItemIcon>
                                    <ListItemText inset primary={_item} style={{ paddingLeft: 0 }} />
                                </ListItem>)
                        })}
                    </List>
                </Collapse>
                <Divider />
            </React.Fragment>);
    }
}

function GetListMenu(isMobile) {

    //console.log('AppRouter : ', _info);
    const _info = JSON.parse(localStorage.getItem('UserInfo'));
    const _role = (!!_info) ? (_info.userType || '') : '';

    switch (_role) {
        case Roles.Admin: {

            return [
                'Dashboard',
                'Booking',
                //'Accounting',
                { Accounting: ['Accounting', 'Withdraw', 'Refund Request', 'Management'] },
                { Commission: ['Commission', 'Special'] },
                { Promotion: ['Promotion', 'Codes'] },
                { Website: ['Announcement', 'Category Manage', 'Website Managment'] },
                'Customer',
                'Supplier',
                'Agent',
                { 'Seller Dashboard': ['Seller Managment', 'Seller Commission', 'Special Commission',] },
                'Staff',
                { Products: ['Packages', 'Transfer', 'Service', 'Reservation Table'] },
                { Settings: ['Payment', 'User', 'Default Commission', 'Appointment', 'Destination', 'Activities', 'Condition', 'Locations', 'Zone', 'Country', 'Other','Hotel'] },

            ];
        }

        case Roles.Supplier: {
            return [
                'Dashboard',
                'Booking',
                'Accounting',
                { Settings: ['Appointment', 'Destination', 'Activities', 'Condition',] },];
        }

        case Roles.Agent: {
            return [
                'Dashboard',
                'Booking',
            ];
        }

        case Roles.Seller: {

            const menuList = (isMobile) ?
                [
                    'Dashboard',
                    'Booking',
                    'Accounting',
                    'Settings',
                    'Logout'
                ] :
                [
                    'Dashboard',
                    'Booking',
                    { Promote: ['Products', 'Codes'] },
                    'Accounting',
                    'Settings',
                ]

            return menuList;
        }

        default: {
            return [
                'Dashboard',
                'Settings',];
        }
    }
}

const hilightItemStyle = { backgroundColor: Colors.themeDark_Hilight };
const ItemStyle = { backgroundColor: Colors.themeDark_Item };

const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: Colors.themeDark
    },
    nested: { paddingLeft: theme.spacing(4), },
    menuLable: {
        color: Colors.white,
        fontSize: '14px',
        fontWeight: 300,
        fontFamily: Family.roboto,
        lineHeight: '1.5em',
        paddingLeft: 0
    },
    menuIcons: { color: Colors.white, marginRight: 10 },
    RIcons: { color: Colors.white, margin: 0 }
});

NestedList.propTypes = { classes: PropTypes.object.isRequired, };

export default withRouter(withStyles(styles)(NestedList));

