import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PathName } from '../../constant';
import InfoDCard from '../../componets/InfoDCard.jsx';

import '../../App.css';
import styled, { css } from 'styled-components';

class AccountingInfoPanel extends Component {

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {
    }

    render() {

        const { WalletInfo = {}, isMobile } = this.props;

        const {
            amountApprovedWithdraw,
            amountLostIncome,
            amountRefund,
            amountWaitingWithdraw,
            approvedWithdraw,
            totalAfterWithdraw,
            lostIncome,
            totalPrice,
            refund, total, waitingWithdraw } = WalletInfo;

        const Info = {
            Wallet: { name: 'Commission ', amount: total || 0, price: totalPrice || 0 },
            OnDelivery: { name: 'On Delivery ', amount: amountWaitingWithdraw || 0, price: waitingWithdraw || 0 },
            ReceivedService: { name: 'Received Service ', amount: amountApprovedWithdraw || 0, price: totalAfterWithdraw || 0 },
            CancelBooking: { name: 'Cancel Booking ', amount: amountRefund || 0, price: refund || 0 },
        }

        switch (true) {
            case isMobile:
                {
                    return (
                        <React.Fragment>
                            <CardContianer isMobile>
                                <CardCenter isMobile>
                                    <InfoDCard Info={Info.Wallet} Disable={false} Type={'full'} Height={{ maxWidth: 'unset' }} />
                                </CardCenter>
                            </CardContianer>
                            <CardContianer isMobile>
                                <CardCenter isMobile>
                                    <InfoDCard Info={Info.ReceivedService} Disable={false} Styles={1} Type={'full'} />
                                </CardCenter>
                            </CardContianer >
                            <CardContianer>
                                <CardLeft isMobile>
                                    <InfoDCard Info={Info.OnDelivery} Disable={false} Type={'full'} isMobile />
                                </CardLeft>
                                <CardRight isMobile>
                                    <InfoDCard Info={Info.CancelBooking} Disable={false} Type={'full'} isMobile />
                                </CardRight >
                            </CardContianer>
                        </React.Fragment>)
                }
            default:
                {
                    return (
                        <React.Fragment>
                            <CardContianer>
                                <InfoDCard Info={Info.Wallet} Disable={false} Type={'full'} Height={{ maxWidth: 'unset' }} />
                            </CardContianer>
                            <CardContianer>
                                <CardLeft>
                                    <InfoDCard Info={Info.ReceivedService} Disable={false} Styles={1} Type={'full'} />
                                </CardLeft>
                                <CardCenter>
                                    <InfoDCard Info={Info.OnDelivery} Disable={false} Type={'full'} />
                                </CardCenter>
                                <CardRight>
                                    <InfoDCard Info={Info.CancelBooking} Disable={false} Type={'full'} />
                                </CardRight >
                            </CardContianer >
                        </React.Fragment>)
                }
        }
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}


export default AccountingInfoPanel;

const CardContianer = styled.div`
max-width: ${1024}px;
max-width: 1024px;
display: flex;
align-items: center;
${(props) => props.isMobile && css`display:grid;width:${window.innerWidth}px;box-sizing: border-box;margin:0px;`}`;

const CardLeft = styled.div`
flex: 1 1 0%;
margin: 0px 10px 10px 0px;
${(props) => props.isMobile && css`margin:0px 10px;`}`;

const CardRight = styled.div`
flex: 1 1 0%;
margin: 0px 0px 10px 10px;
${(props) => props.isMobile && css`margin:0px 10px;`}`;

const CardCenter = styled.div`
flex: 1 1 0%;
margin: 0px 10px 10px;
${(props) => props.isMobile && css`margin:0px 10px; `}`;
