import React, { Component } from 'react'

import { path, equals, keys, omit, update } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import DialogPanel from '../../componets/DialogPanel.jsx';

import FormTypes from '../../common/FormTypes';
import Agent from '../../constant/JSON/Agent';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Fonts } from '../../themes';
import Avatar from '../../constant/Avatar';
import AutoThaiAddress from '../../componets/AutoThaiAddress.jsx';
import * as validator from '../../constant/Validator/Agent';

import CONFIG from '../../config';

const validate = require("validate.js");
const _rowContainer = { display: 'flex', paddingTop: 5 };

class AgentCard extends Component {
    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }
    constructor(props) {
        super(props);
        this.state = {
            agentInfo: this.props.AgentInfo,
            oldAgentInfo: this.props.oldAgentInfo,
        }
    }

    render() {
        const { isEdit, isView, classes } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive } = this.state;
        return (
            <div className={classes.root}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {!isView && <Typography className={classes.header}>{isEdit ? Agent.HeaderPage.UPDATE_EN : Agent.HeaderPage.CREATE_EN}</Typography>}
                </div>
                {
                    Agent.BodyPage.map((row, index) => (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => {
                                let _marginRight = each.margin === 'right';
                                return (
                                    <div key={index} style={{ textAlign: 'start', width: (each.width) ? each.width : '100%', marginRight: (_marginRight) ? 15 : null }}>
                                        {this.SetItemsWithType(each)}
                                    </div>
                                )
                            })}
                        </div>
                    ))
                }
                {!isView && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => this.onCancel()} />
                    <Button.Themes TitleText={ButtonText.BUTTON_SAVE} 
                        disabled={!isBtnActive} onClick={() => { this.onSave() }} />
                </div>}
            </div>
        )
    }

    SetItemsWithType(each) {
        const { isEdit, isView, BookBankList, classes, Level } = this.props;
        const { agentInfo, oldAgentInfo } = this.state;
        let options = (each.options) ? each.options : [];
        const isError = !!path([each.stateName], this.error);
        let levelList = Array.isArray(path(['datas'], Level)) ? path(['datas'], Level).filter((val) => val.actived) : [];
        // console.log('Level', Level);
        // console.log('levelList', levelList);
        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], agentInfo) || path(['emergencyContact', 0, each.stateName], agentInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "email": {
                return (
                    <FormTypes.FormEmail
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], agentInfo) || ''}
                        onChange={(value) => { this.onChangeState(each.stateName, value) }} />
                )
            }
            case "number": {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], agentInfo) || path(['emergencyContact', 0, each.stateName], agentInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "select": { //level
                return (
                    <FormTypes.Select
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], agentInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(levelList) && levelList.map((option, i) => { return (<option key={i} value={option.defaultCommissionId}> {option.levelName} </option>) })} />
                )
            }
            case "search_select": {
                return (
                    <AutoThaiAddress each={each}
                        disabledProvince={isView ? true : false}
                        disabledDistrict={isView ? true : false}
                        style={{ paddingLeft: 15, paddingTop: '5px', }}
                        provinceId={path(['address', each.stateName_pro], agentInfo) || ''}
                        districtId={isEdit ? path(['address', each.stateName_dis], agentInfo) : !equals(path(['address', each.stateName_pro], agentInfo), path(['address', each.stateName_pro], oldAgentInfo)) ? '' : path(['address', each.stateName_dis], agentInfo)}
                        onChange={(val) => {
                            if (!!val.district) {
                                this.onChangeState(each.stateName_dis, path(['district', 'code'], val))
                            } else {
                                this.onChangeState(each.stateName_pro, path(['province', 'code'], val))
                            }
                        }} />
                )
            }
            case "select_checkbok": {
                return (
                    <FormTypes.FormBookBank
                        disabled={isView ? true : false}
                        BookBankList={BookBankList}
                        Info={path([each.stateName], agentInfo)}
                        valueAccount={path(['bank', each.stateName_account], agentInfo)}
                        onChange={this.onChangeState} />
                )
            }
            case "switch": {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], agentInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }
            case "image": {
                return (
                    isView ?
                        <img className={classes.avatar} src={`${CONFIG.host}${path([each.stateName, 'thumbnail'], agentInfo)}`} />
                        :
                        <Avatar editable={isView ? false : true} ref='avatar'
                            disabled={isView ? true : false}
                            onRemove={() => this.onRemoveImage()}
                            onChange={(files) => { this.onChangeState(each.stateName, files) }}
                            avatar={`${CONFIG.host}${path([each.stateName, 'thumbnail'], agentInfo)}`} />
                )
            }
        }
    }

    onRemoveImage = () => {
        const { agentInfo } = this.state;
        this.setState({ agentInfo: { ...agentInfo, logo: { original: "", thumbnail: "" } }, isBtnActive: true })
        this.refs.avatar.remove('file');
    }

    onChangeState = (fieldName, state) => {
        // console.log('[onChangeState]', fieldName, state)
        const { oldAgentInfo } = this.state;
        let agentInfo = { ...this.state.agentInfo }

        switch (fieldName) {
            case 'accountNumber':
            case 'bankId': {
                agentInfo = { ...agentInfo, bank: { ...agentInfo.bank, [fieldName]: state } }
            } break;
            case 'tel':
            case 'person': {
                agentInfo = {
                    ...agentInfo,
                    emergencyContact: update(0, { ...agentInfo.emergencyContact[0], [fieldName]: state }, agentInfo)
                }
            } break;
            default: {
                agentInfo = { ...agentInfo, [fieldName]: state }
            }
        }
        // console.log('[agentInfo success]', agentInfo)
        this.setState({ agentInfo, isBtnActive: !equals(oldAgentInfo, agentInfo) }, () => { this.validate({ [fieldName]: state }) })
    }
    validate = (value) => {
        let result = true;
        const fieldName = keys(value)[0];
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};
            if (!!validateResult) {
                result = false;
                this.error = {
                    ...error,
                    ...validateResult
                };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }
    validateAll() {
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.agentInfo[each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        const { agentInfo } = this.state;
        const { isEdit } = this.props;
        const newAvatar = this.refs.avatar.getAvatar('file');

        // console.log('[validateAll]', this.validateAll(), this.error)

        if (this.validateAll()) {
            this.setState({ isBtnActive: false }, () => {
                (isEdit) ? this.props.APIUpdateAgent(newAvatar, agentInfo) : this.props.APICreateAgent(newAvatar, agentInfo)
            })
        } else { this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error' }) }
    }

    onCancel = () => { this.props.onClosePanel() }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

}
const styles = theme => (
    {
        root: { textAlign: 'start', overflow: 'unset', maxWidth: 800 },
        header: { fontSize: '24px', display: 'flex', padding: '10px 0px' },
    }
)
AgentCard.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

export default withStyles(styles, { withTheme: true })(AgentCard);
