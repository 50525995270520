import React from 'react';
import { path } from "ramda";
import Close from "../images/ic_cancel_black_18px.svg";
import Zoom from "../images/ic_zoom_out_map_black_18px.svg";
import { Colors } from '../themes';
class PreviewImage extends React.Component {
    img;
    width = false;
    state = {
        isZoom: false,
    }
    render() {
        const { isZoom } = this.state;
        const { onClick, src, alt = 'preview', removable = true, size, zoom = true } = this.props;
        return (
            <div
                onClick={() => {
                    if (this.width) {
                        this.img.style.width = '100%';
                        this.img.style.height = '';
                    }
                    if (isZoom) {
                        this.setState({ isZoom: false });
                    }
                }}
                style={{
                    // border: `1px solid #888`,
                    width: isZoom ? '' : size,
                    height: isZoom ? '' : size,
                    lineHeight: '130px',
                    textAlign: 'center',
                    backgroundColor: Colors.lightGrey,
                    margin: '10px 10px 0px 0px',
                    position: isZoom ? 'fixed' : 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: isZoom ? 'block' : 'flex',
                    zIndex: isZoom ? 100 : '',
                    top: isZoom ? 0 : '',
                    left: isZoom ? 0 : '',
                    right: isZoom ? 0 : '',
                    bottom: isZoom ? 0 : '',
                    overflowY: isZoom ? 'scroll' : '',
                }}
            >
                <video
                    ref={ref => this.img = ref}
                    alt={alt}
                    src={src}
                    autoPlay
                    style={{
                        width: '100%',
                    }}
                    onLoad={(e) => {
                        const imgWidth = path(['target', 'naturalWidth'], e);
                        const imgHeight = path(['target', 'naturalHeight'], e);
                        const imgStyle = path(['target', 'style'], e);
                        if (imgWidth > imgHeight) {
                            this.width = true;
                            imgStyle.width = '100%';
                            imgStyle.height = '';
                        } else {
                            imgStyle.width = '';
                            imgStyle.height = '100%';
                        }
                    }}
                />
                {
                    removable && !isZoom && (
                        <img
                            src={Close}
                            onClick={onClick}
                            style={{
                                position: 'absolute',
                                top: -8,
                                right: -5,
                                cursor: 'pointer',
                                boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
                                borderRadius: '50%',
                                color: Colors.lightGrey,
                                // backgroundColor: Colors.white,
                            }} />
                    )
                }
                {
                    zoom && !isZoom && (
                        <img
                            src={Zoom}
                            onClick={() => {
                                this.setState({ isZoom: true });
                            }}
                            style={{
                                position: 'absolute',
                                bottom: 3,
                                right: 3,
                                cursor: 'pointer',
                                boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
                                // borderRadius: '50%',
                                color: Colors.lightGrey,
                                // backgroundColor: Colors.white,
                            }}
                        />
                    )
                }
            </div>
        )
    }
};

export default PreviewImage;
