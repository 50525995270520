import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import InfoCard from '../componets/InfoCard.jsx';
// import DialogPanel from '../componets/DialogPanel.jsx';
import PackageListPanel from '../componets/PackageListPanel.jsx';
import ContentPanels from '../componets/ContentManage/ContentPanel.jsx';
import PackageChartPanel from '../containers/Packages/PackageChartPanel.jsx';

import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import { PathName } from '../constant';

import * as PackageAction from '../actions/Actions_PackageManager.js';
import * as StatiticActions from '../actions/Actions_StatiticManager.js';

import '../App.css';

const _CardInfo = {
  OneDayTrip: { name: 'OneDayTrip', amount: 0 },
  Transfer: { name: 'Transfer', amount: 0 },
  Ferries: { name: 'Ferries', amount: 0 },
  Hotel: { name: 'Hotel', amount: 0 },
}

let waitingTime = true;

let packageCount = [];
let packageInfoList = null;
let packageBookingFoWeek = [];

class PackageManage extends Component {

  state = { isLoading: true, msgError: undefined, dialogType: undefined, }

  constructor(props) {
    super(props);
    this.props.getAPIPackageCount();
    this.props.getAPIPackageList({ limit: 25 });

    this.props.getAPIPackageTopOfWeek({ limit: 4, orderBy: 'asc' });
  }
  componentWillReceiveProps(nextProps) {

    const { StatiticManager, PackageManager } = nextProps;

    console.log(`[componentWillReceiveProps] : `, nextProps);

    if (!!PackageManager) {

      const { actions, data } = PackageManager;

      switch (actions) {
        case PackageAction.GET_PACKAGE_COUNT_SUCCESS:
          {
            packageCount = data;
            SetStateWithTime(this, waitingTime);
          } break;
        case PackageAction.GET_PACKAGE_COUNT_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break
        case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
          {
            packageInfoList = data;
            SetStateWithTime(this, waitingTime);
          } break;
        case PackageAction.GET_PACKAGE_LISTS_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break
      }
    }
    if (!!StatiticManager) {

      const { actions, data } = StatiticManager;

      switch (actions) {
        case StatiticActions.GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS:
          {
            console.log(`${actions} : `, data)
            packageBookingFoWeek = data.results || [];

          } break;
        case StatiticActions.GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE:
          {
            console.log(`${actions} : `, data)
          } break
      }
    }
  }

  render() {

    const { isLoading } = this.state;

    return (
      <ContentPanels isLoading={isLoading}  >
        <PackageChartPanel statList={packageBookingFoWeek} />
        {this.packageSetPanel()}
        <PackageListPanel packageInfoList={packageInfoList} onClickHandle={this.onClickHandle(PathName.PackageDetail)} />
      </ContentPanels>);

  }

  packageSetPanel() {

    // console.log('packageCount', packageCount)
    // console.log('packageInfoList', packageInfoList)
    // const amountOneDayTrip = !!packageInfoList ? (packageInfoList.packageList.filter(val => val.type === 'OneDayTrip').length) : null

    _CardInfo.OneDayTrip.amount = packageCount && packageCount.oneDayTrip
    _CardInfo.Transfer.amount = packageCount && packageCount.transfer
    _CardInfo.Ferries.amount = packageCount && packageCount.ferries

    // _CardInfo.OneDayTrip.amount = (packageInfoList && amountOneDayTrip) ? amountOneDayTrip : 0;
    // _CardInfo.Transfer.amount = (packageInfoList && packageInfoList.total && amountOneDayTrip) ? packageInfoList.total - amountOneDayTrip : 0;
    // _CardInfo.Ferries.amount = (packageInfoList && packageInfoList.packageList.length > 0) ? packageInfoList.packageList.filter(val =>
    //   (path(['packageInfo', 'transferType', 'conveyanceType'], val)) === 'ferries').length : 0

    return (
      <div className="App-content">
        <div style={{ flex: 1, margin: '10px', marginLeft: 0 }}>
          <InfoCard
            Info={_CardInfo.OneDayTrip} Disable={false}
            btnCreateHadle={this.btnCreateHadle(PathName.CreatePackage)}
            btnViewHadle={this.btnViewHadle(PathName.PackageManage)} />
        </div>
        <div style={{ flex: 1, margin: '10px' }}>
          <InfoCard Info={_CardInfo.Transfer} Disable={false}
            btnCreateHadle={this.btnCreateHadle(PathName.CreateTransfer)}
            btnViewHadle={this.btnViewHadle(PathName.TransferManage)} />
        </div>
        <div style={{ flex: 1, margin: '10px' }}>
          <InfoCard Info={_CardInfo.Ferries} Disable={false} View={true}
            btnCreateHadle={this.btnCreateHadle(PathName.CreateFerries)}
            btnViewHadle={this.btnViewHadle(PathName.FerriesManage)} />
        </div>
        <div style={{ flex: 1, margin: '10px', marginRight: 0 }}>
          <InfoCard Info={_CardInfo.Hotel} Disable={true} />
        </div>
      </div>)
  }

  btnCreateHadle = (value) => () => { this.props.history.push(value); }
  btnViewHadle = (value) => () => { this.props.history.push(value); }

  onClickHandle = (page) => (_packageId) => {
    console.log('prosp', page, this.props);
    const _packageInfo = packageInfoList.packageList.find((_package) => { return _package.packageId === _packageId });
    this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo }, search: '', hash: '' });
  }

}

function mapStateToProps(state, ownProps) {
  return {
    PackageManager: state.PackageManager.PACKAGE_REDUCER,
    StatiticManager: state.StatiticManager.STATITIC_REDUCER
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAPIPackageList: (_keyword) => {
      InitWaitingTime();
      dispatch(PackageAction.getAPIPackageLists(_keyword));
    },

    getAPIPackageTopOfWeek: (_filter) => {
      dispatch(StatiticActions.getBookingPackageTopOfWeek(_filter));
    },
    getAPIPackageCount: (_keyword) => {
      InitWaitingTime();
      dispatch(PackageAction.getAPIPackageCount(_keyword));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PackageManage);
