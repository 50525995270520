export const init_activity = {
    "activityId": "",
    "EN": {
        "name": "",
        "description": "",
    },
    "TH": {
        "name": "",
        "description": "",
    },
    "CN": {
        "name": "",
        "description": "",
    },
    "picture": "",
    "actived": false,
}