import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PathName } from '../../constant';

import InfoDCard from '../../componets/InfoDCard.jsx';
// import BookingStatCard from '../../componets/BookingStatCard.jsx';
// import SupplierStatCard from '../../componets/SupplierStatCard.jsx';

import * as StatiticActions from '../../actions/Actions_StatiticManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import '../../App.css';
let waitingTime = true;
let _CustomerStatus = [];

const _types = [{ type: "normal", value: 0 }, { type: "google", value: 0 }, { type: "facebook", value: 0 }];
const _ScardInfo = {
    Total: { name: 'Total', amount: 0 },
    Actived: { name: 'Actived', amount: 0 },
    InActive: { name: 'InActive', amount: 0 },
    Null: { name: 'General', amount: 0 },
    Hotel: { name: 'Hotel', amount: 0 },
}

class CustomerStatPanel extends Component {

    constructor(props) {
        super(props);

        // this.props.getAPIBookingStatus();
        this.props.getAPISummaryCustomerStatus();
    }

    componentWillReceiveProps(nextProps) {

        const { StatiticManager, isLoading } = nextProps;

        if (!!StatiticManager) {

            const { actions, data } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {
                case StatiticActions.GET_BOOKING_STATUS_SUCCESS:
                    {
                        //_CustomerStatus = data.results;
                        console.log(`StatiticManager [componentWillReceiveProps] : `, _CustomerStatus);

                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;

                case StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_SUCCESS:
                    {
                        // console.log(`StatiticManager ${actions}  : `, data.results);
                        _CustomerStatus = data.results;

                        const { total, actived, inActive } = _CustomerStatus;

                        _ScardInfo.Actived.amount = actived || 0;
                        _ScardInfo.InActive.amount = inActive || 0;
                        _ScardInfo.Total.amount = total || 0;

                    } break;

                case StatiticActions.GET_BOOKING_STATUS_FAILURE:
                    {
                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;

                case StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_FAILURE:
                    {
                        // SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { BookinList, isLoading } = this.props;
        console.log('CustomerStatPanel : ', BookinList)

        return <div className="App-content">
            <div style={{ flex: 2, }}>
                <InfoDCard Info={_ScardInfo.Total} Disable={true} Height={{ height: 205, marginTop: 0 }} />
            </div>
            <div style={{ flex: 4, }}>
                {CustomerStat(_CustomerStatus || [])}
                {CustomerTypeRegister(_CustomerStatus || [])}
            </div>
        </div>
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }

}

function CustomerStat(props) {

    const _flexStyle = { flex: 1, margin: 10, marginTop: 0 };

    console.log('CustomerStat : ', props)

    return (
        <div className="App-content" style={{ maxWidth: 1024 }}>
            <div style={_flexStyle}>
                <InfoDCard Info={_ScardInfo.Actived} Disable={true} Styles={'small'} />
            </div>
            <div style={_flexStyle}>
                <InfoDCard Info={_ScardInfo.InActive} Disable={true} Styles={'small'} />
            </div>
            <div style={_flexStyle}>
                <InfoDCard Info={_ScardInfo.Null} Disable={true} Styles={'small'} />
            </div>
        </div >)
}

function CustomerTypeRegister(_CustomerStatus = []) {

    const _flexStyle = { flex: 1, margin: 10, marginTop: 0 };

    console.log('CustomerTypeRegister : ', _CustomerStatus)
    const types = _CustomerStatus.types || _types;

    //_types.map((item) => ({ name: item.type, amount: item.value }));

    return (
        <div className="App-content" style={{ maxWidth: 1024 }}>
            {types.map((item, index) => {
                return (
                    <div key={index} style={_flexStyle}>
                        < InfoDCard Info={{ name: item.type, amount: item.value }} Disable={true} Styles={'small'} />
                    </div>)
            })}
        </div>
    )
}



function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingStatus: (_keyword) => {
            InitWaitingTime();
            dispatch(StatiticActions.getBookingStatus(_keyword));
        },
        getAPISummaryCustomerStatus: (_fliter) => {
            dispatch(StatiticActions.getSummaryCustomerStatus(_fliter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerStatPanel);
