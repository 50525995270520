
import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

function GetEmptyRows(props) {
    const { fildeList } = props;

    return (
        <TableRow key={'emtpyRow'}>
            <TableCell colSpan={fildeList.length}>
                <div style={{ textAlign: 'center', width: '100%' }}>{`Empty.`}</div>
            </TableCell>
        </TableRow>)
}

export default GetEmptyRows;