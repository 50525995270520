import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

import { isMobile } from '../common/DeviceCheck'


const wording = {
    title: 'Loading Information.',
    btn_ok: 'Agree', btn_cancel: 'Disagree',
    msg: `Sync information init system.`
}

class DialogAction extends React.Component {

    state = { open: true, };

    handleClickOpen = () => { this.setState({ open: true }); };
    handleClose = () => { this.setState({ open: false }); this.props.OnClose(); };

    render() {
        // console.log('Dialog : ', this.props.Active)
        const { Active, AcrionCom } = this.props;
        const _isMoblie = isMobile();

        return (
            <Dialog
                open={(Active)}
                keepMounted
                onClose={this.handleClose}
                // transition={Transition}
                maxWidth={_isMoblie ? false : 'md'}
                PaperProps={_isMoblie ? paperStyleMobile : paperStyle}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description">
                <DialogContent style={dialogContentStyle} >
                    {AcrionCom}
                </DialogContent>
            </Dialog >
        );
    }
}
// function Transition(props) { return <Slide direction="up" {...props} />; }

const dialogContentStyle = { maxHeight: '800px', padding: '15px' };
const paperStyleMobile = { style: { width: '100%', margin: 10 } };
const paperStyle = { style: { minWidth: 800, margin: 10 } };


export default DialogAction;