import { Province } from './Province';
import { SupplierType } from './SupplierType';

export const content_supplier = { header: 'Supplier' }

export const create_supplier = [
    [
        {
            title: 'Supplier Name',
            type: 'text',
            action: true,
            display: 'input',
            stateName: 'name',
            placeholder: 'Insert Supplier Name',

        },
        {
            title: 'Supplier Email',
            type: 'email',
            action: true,
            display: 'input',
            stateName: 'email',
            placeholder: 'Insert Supplier Email',

        }
    ],
    [
        {
            title: 'Type',
            action: false,
            type: 'select-multiple',
            stateName: 'type',
            options: SupplierType
        },
        {
            title: 'Reservation Number',
            type: 'text',
            action: false,
            stateName: 'reservation_number',
            placeholder: 'Insert Reservation Number',
        }
    ],
    [
        {
            title: 'Official Website',
            type: 'text',
            action: true,
            display: 'input',
            stateName: 'officialWebsite',
            placeholder: 'Insert Official Website',
        }
    ],
    [
        {
            title: 'Telephone Number',
            type: 'number',
            action: true,
            display: 'input',
            stateName: 'telOffice',
            placeholder: 'Insert Telephone Number',
        },
        {
            title: 'Emergency',
            type: 'number',
            action: true,
            display: 'input',
            stateName: 'tel',
            placeholder: 'Insert Emergency',
        },
        {
            title: 'Person',
            type: 'text',
            action: true,
            display: 'input',
            stateName: 'person',
            placeholder: 'Insert name',
        }
    ],

    [
        {
            title: 'No',
            type: 'text',
            size: 'xs',
            action: false,
            stateName: 'no',
            placeholder: 'Insert No',
        },
        {
            title: 'Road',
            type: 'text',
            size: 's',
            action: false,
            stateName: 'road',
            placeholder: 'Insert Road',
        },
        {
            title: 'Province',
            type: 'search_select',
            placeholder: 'Search a province',
            action: false,
            stateName: 'province',
            options: Province
        },

    ],
    [{
        title: 'Postal Code',
        type: 'number',
        action: false,
        stateName: 'post-code',
        placeholder: 'Insert Postal Code'
    },
    {
        title: 'Country',
        type: 'select',
        action: false,
        stateName: 'country',
        options: [{ name_EN: 'Select Country', }, { name_EN: 'THA', }],
    }],
    [
        {
            // title: 'Bookbank',
            // type: 'select_checkbok',
            // action: true,
            // display: 'Bookbank',
            // stateName: ''
            title_TH: "---", title_EN: "BookBank", title_CN: "---",
            type: 'select_checkbok',
            action: true,
            display: "Bookbank",
            stateName: 'bank',
            stateName_account: 'accountNumber'
        }
    ],
    [
        {
            title: 'Status',
            type: 'switch',
            action: true,
            display: 'switch',
            stateName: 'visible',
        }
    ],
    [
        {
            title: 'Logo',
            type: 'image',
            action: true,
            display: 'image',
            stateName: 'logo',
        }
    ]
]
