import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { NumberFormats, NumberFormatNoFix } from '../common/SetNumberFormat';
import { Colors, Family } from '../themes';

function InfoDCard(props) {

    const { classes, Info, Styles, Height, Type } = props;
    const isSmall = (Styles === 'small');
    const _className = (isSmall) ? classes.lable1 : classes.lable;
    //console.log('InfoDCard : ', Info)

    return (
        <Card className={(isSmall) ? classes.card1 : classes.card} style={Height || {}}>
            <CardContent style={{ padding: '16px 5px 24px 5px' }}>
                <Typography className={(isSmall) ? classes.pos1 : classes.pos}>{Info.name}</Typography>
                {DisplayWithType(Type, _className, props)}
            </CardContent>
        </Card>
    );
}

function DisplayWithType(_type = '', _className, _props) {

    const { Info, Disable, isMobile } = _props;

    switch (_type) {

        case 'full':
            {
                return (
                    <React.Fragment>
                        <div>
                            <span className={_className} style={{ fontSize: isMobile ? 18 : 32, padding: '0px', }}>
                                {NumberFormats(Info.price || 0)}
                            </span>
                            <span style={{ fontSize: isMobile ? 14 : 16, padding: '0px', fontFamily: `Roboto,Helvetica,Arial,sans-serif` }}>
                                {` Bath.`}
                            </span>
                        </div>
                        <div>
                            <span className={_className} style={{ fontSize: isMobile ? 12 : 16, marginTop: 10 }}>
                                {(!Disable) && (`of ${NumberFormatNoFix(Info.amount)} Booking.`)}
                            </span>
                        </div>
                    </React.Fragment>)
            }
        default:
            {
                return (
                    <React.Fragment>
                        <div>
                            <span className={_className} style={{ fontSize: '32px', padding: '0px', }}>
                                {NumberFormatNoFix(Info.amount)}
                            </span>
                        </div>
                    </React.Fragment>)
            }
    }
}
const styles = theme => (
    {
        card: {
            // minWidth: 275,
            width: '100%',
            maxWidth: 500,
            marginTop: '10px',
            borderLeft: `${'5px solid' + Colors.theme}`
        },
        card1: {
            // minWidth: 275,
            width: '100%',
            maxWidth: 500,
            height: 92,
            marginTop: '0px',
            borderLeft: `${'5px solid' + Colors.theme}`
        },
        title: {
            marginBottom: 16,
            fontSize: 20,
            color: Colors.black,
            textAlign: 'center',
        },
        title1: {
            marginBottom: 16,
            fontSize: 20,
            color: Colors.black,
            textAlign: 'center',
        },
        pos: {
            marginBottom: 20,
            color: theme.palette.text.secondary,
        },
        pos1: {
            marginBottom: 0,
            color: theme.palette.text.secondary,
        },
        button: { backgroundColor: Colors.theme, color: Colors.white, width: '100%', height: '44px' },
        lable: { fontFamily: Family.roboto, fontSize: '50px', padding: '5px' },
        lable1: { fontFamily: Family.roboto, fontSize: '20px', padding: '5px' }
    });


InfoDCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(InfoDCard);