import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { Metrics, Colors, Family } from "../../themes";
import { isMobile, isTablet } from '../../common/DeviceCheck';
import { SetDate } from '../../common/SetDate';
import CONFIG from '../../config';
import Gallery from 'react-grid-gallery';

const PaymetStatus = 'in-process';

class GalleryPanel extends Component {
    render() {
        const { Images, bookingStatus } = this.props;
        //console.log('GalleryPanel : ', Images,' : ',bookingStatus);

        let IMAGES = [];

        if (!!Images)
            IMAGES = Images.map((Image) => {
                return {
                    src: Image,
                    thumbnail: Image,
                    thumbnailWidth: 320,
                    thumbnailHeight: 212,
                    isSelected: false,
                    caption: '',
                }
            });

        const stylePanel = isMobile() ? { padding: '20px 0px 20px 20px', width: '95%' } : { padding: '20px 20px 20px 50px', width: '50%', textAlign: 'left' };
        
        return (bookingStatus.match(/(in-process|approved|cancel)/i)) ?
            (<div key={`GalleryPanel`} style={{ backgroundColor: Colors.themeDark_Light, width: '100%', height: '100%', borderTop: `${'3px solid ' + Colors.theme}` }}>
                <Gallery images={IMAGES} backdropClosesModal={true} />
            </div >
            ) : <div />;
    }
}

export default withRouter(GalleryPanel);

const style =
{
    priceInfo_lable: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'right',
        //   marginTop: '5px',
        //   marginRight: '5px',
    },
    packege_container: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
        fontFamily: Family.roboto
    },
    label_topic: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '31px',
        display: 'inline-grid',
        marginTop: '5px',
    },
    label_detail: {
        height: '31px',
        width: '100%',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        //   marginTop: '5px',
        //   marginBottom: '5px',
    },
};

// const IMAGES =
        // [{
        //         src: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_b.jpg",
        //         thumbnail: "https://c2.staticflickr.com/9/8817/28973449265_07e3aa5d2e_n.jpg",
        //         thumbnailWidth: 320,
        //         thumbnailHeight: 174,
        //         isSelected: false,
        //         caption: "After Rain (Jeshu John - designerspics.com)"
        // },
        // {
        //         src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        //         thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
        //         thumbnailWidth: 320,
        //         thumbnailHeight: 212,
        //         isSelected: false,
        //         tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
        //         caption: "Boats (Jeshu John - designerspics.com)"
        // },

        // {
        //         src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        //         thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
        //         thumbnailWidth: 320,
        //         thumbnailHeight: 212,
        //         isSelected: false,
        //         tags: [{value: "Ocean", title: "Ocean"},],
        // }]
        // const IMAGES =
        // [{
        //         src: '',
        //         thumbnail: '',
        //         thumbnailWidth: 320,
        //         thumbnailHeight: 212,
        //         isSelected: false,
        //         caption: '',
        // },]
