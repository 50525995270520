import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import DestinationListPanel from '../containers/Destination/DestinationListPanel.jsx'
import DestinationCard from '../containers/Destination/DestinationCard.jsx';

import DialogAction from '../componets/DialogAction.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';

import { init_destination } from '../constant/initState/initDestination';

import { InitWaitingTime, SetStateWithTime, } from '../common/SetTime.js';

import * as DestinationsActions from '../actions/Actions_DestinationsManager';


const _height = (window.innerHeight - 130);
let waitingTime = true;
let DestinationsList = [];

class DestinationManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true, msgError: undefined, dialogType: undefined,
            isCreate: false,
            isEdit: false,
            destinationInfo: undefined,
            oldDestinationInfo: null,
        };
        this.props.getAPIDestinationsLists({ page: 0, num_limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        const { DestinationsManager } = nextProps;

        if (!!DestinationsManager) {
            const { actions, data } = DestinationsManager.DESTINATION_REDUCER;
            switch (actions) {
                case DestinationsActions.GET_DESTINATIONS_SUCCESS:
                    {
                        DestinationsList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case DestinationsActions.GET_DESTINATIONS_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate, } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.DestinationPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>                        {this.contentPanel()}                    </div>
                </List>
            </div>
        );
    }

    DestinationPanel = () => {
        const { destinationInfo, oldDestinationInfo, isEdit, isView } = this.state;
        return (
            <DestinationCard
                isEdit={isEdit}
                isView={isView}
                DestinationsList={DestinationsList}
                destinationInfo={destinationInfo}
                oldDestinationInfo={oldDestinationInfo}
                onClosePanel={this.onClosePanel} />
        )
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <DestinationListPanel
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.EditDestination()}
                    ViewInfo={this.ViewDestination()}
                    DestinationsList={DestinationsList} />
            </div>
        )
    }

    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, oldDestinationInfo: { ...init_destination }, destinationInfo: { ...init_destination } }));

    EditDestination = () => (destinationInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            isView: false,
            destinationInfo,
            oldDestinationInfo: destinationInfo,
        })
    }
    ViewDestination = () => (destinationInfo) => {
        this.setState({
            isCreate: true,
            isEdit: false,
            isView: true,
            destinationInfo,
            oldDestinationInfo: destinationInfo,
        })
    }
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, isView: false, destinationInfo: undefined, }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIDestinationsLists: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(DestinationsActions.getDestinationsList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DestinationManage);
