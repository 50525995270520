// ===================================================================================================================================
// PhiPhiTours
// ===================================================================================================================================

import { api } from '../../api';

// process.env.NODE_ENV === `development`;
const isDevelop = true;
const buildVer = (isDevelop) ? '1.0.0' : '1.0.0';

// console.log(api)

// const port = '3030';
const host = (isDevelop) ? `https://api-ppdev.travisgo.com` : `https://api.phiphitours.com`;
const webURL = (isDevelop) ? `https://phiphi-dev.firebaseapp.com` : `https://www.phiphitours.com`;
const LBhost = (isDevelop) ? `https://api-ppdev.travisgo.com/lb` : `https://api.phiphitours.com/lb`;
const LBheader = { 'Content-Type': 'application/json', 'Accept': 'application/json' };

const EP_token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1MjQ3NDExNzMsImRhdGEiOiJ0cmF2aWdvYWRtaW5AZ21haWwuY29tIiwiaWF0IjoxNTI0NzIzMTczfQ.6YszNF-KAeCqSE_qiIHl-FRDjJMsau-XwTvgdKivM2Q`;

let _info = JSON.parse(localStorage.getItem('UserInfo'));
const LB_token = (!!_info) ? _info.token : '';

// ===================================================================================================================================

// ===================================================================================================================================

export {
    host,
    webURL,
    api,
    LBhost,
    EP_token,
    LB_token,
    LBheader,
    isDevelop,
    buildVer,
    // default: defaultSetting,
};

// ===================================================================================================================================