import { isSafari } from '../../common/DeviceCheck';

let _isSafari = isSafari()
export const Images = {

    logo: {
        login: require('./assets/svg/logo_footer.svg'),
        white: require('./assets/svg/logo_businees_partner.svg'),
        color: require('./assets/svg/logo_backend.svg'),
    },
    HeaderBackground: {
        website: _isSafari ? require('./assets/bg/bg_business_partner.jpg') : require('./assets/bg/bg_business_partner.webp'),
        // tablet: _isSafari ? require('./assets/bg/tablet_bg_businesspartner@1x.jpg') : require('./assets/bg/tablet_bg_businesspartner.webp'),
        // mobile: _isSafari ? require('./assets/bg/mobile_bg_businesspartner@1x.jpg') : require('./assets/bg/mobile_bg_businesspartner.webp'),
    },
    User: {
        userdefault: require('./assets/images/userdefault.png'),
    },
    package: {
        duration: require('./assets/images/Duration.png'),
        passengers: require('./assets/images/package/Passengers.png'),
        bags: require('./assets/images/userdefault.png'),
        sale: require('./assets/images/package/Sale.png')
    },
    ImageBackground: _isSafari ? require('./assets/bg/bg_business_partner.jpg') : require('./assets/bg/bg_business_partner.webp'),
    Calendar: require('./assets/svg/calendar-alt-regular.svg'),
};
