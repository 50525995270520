import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import ConditionListPanel from '../containers/Condition/ConditionListPanel.jsx'
import ConditionCard from '../containers/Condition/ConditionCard';

import DialogAction from '../componets/DialogAction.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';

import * as ConditionActions from '../actions/Actions_Conditions';

import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';
import { init_condition } from '../constant/initState/initCondition';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let ConditionsList = [];

class ConditionManage extends Component {

  state = { isLoading: true, isCreate: false, isEdit: false, ConditionInfo: undefined, oldConditionInfo: null, }

  constructor(props) {
    super(props);

    this.props.getAPIConditionsList({ num_limit: 10 });
  }

  componentWillReceiveProps = (nextProps) => {

    const { ConditionManager } = nextProps;

    if (!!ConditionManager) {

      const { actions, data } = ConditionManager.CONDITION_REDUCER;

      switch (actions) {
        case ConditionActions.GET_CONDITIONS_SUCCESS:
          {
            ConditionsList = data.result;
            SetStateWithTime(this, waitingTime);
          } break;

        case ConditionActions.GET_CONDITIONS_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break;
      }
    }
  }

  render() {
    const { isLoading, isCreate } = this.state;

    return (
      <div className="App">
        {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.createCondition()} />}
        {(isLoading) && <DialogPanel Active={isLoading} />}
        <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
          <div style={{ display: 'inline-block' }}>         {this.contentPanel()}          </div>
        </List>
      </div>
    );
  }

  contentPanel() {
    return (
      <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
        <ConditionListPanel ConditionsList={ConditionsList} CreatePanel={this.onCreatePanel(this.state.isCreate)} EditInfo={this.editCondition()} ViewInfo={this.ViewInfo()} />
      </div>)
  }

  createCondition = () => {
    const { isEdit, ConditionInfo, oldConditionInfo, isView } = this.state;
    return (
      <ConditionCard
        ConditionsList={ConditionsList}
        onChangeLanguage={this.onChangeLanguage}
        isEdit={isEdit}
        isView={isView}
        ConditionInfo={ConditionInfo}
        oldConditionInfo={oldConditionInfo}
        onClosePanel={this.onClosePanel}
      />
    )
  }
  onChangeLanguage = (_language) => {
    const { page } = ConditionsList;
    this.props.getAPIConditionsList({ page: page, num_limit: 10, language: _language });
  }


  onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, oldConditionInfo: { ...init_condition }, ConditionInfo: { ...init_condition } }));
  onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, isView: false, ConditionInfo: undefined, }));

  editCondition = () => (ConditionInfo) => {
    this.setState({
      isCreate: true,
      isEdit: true,
      isView: false,
      ConditionInfo,
      oldConditionInfo: ConditionInfo
    })
  }

  ViewInfo = () => (ConditionInfo) => {
    this.setState({
      isCreate: true,
      isEdit: false,
      isView: true,
      ConditionInfo,
      oldConditionInfo: ConditionInfo
    })
  }

}

function mapStateToProps(state, ownProps) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return {
    getAPIConditionsList: (_keyword, _filter) => {
      InitWaitingTime();
      dispatch(ConditionActions.getConditionsList(_keyword, _filter));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ConditionManage);
