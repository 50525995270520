import React, { Component } from 'react';

import { PieChart, Pie, Sector, Cell } from 'recharts';
import { Family, Colors } from '../themes';

const COLORS = [Colors.pieChart_1, Colors.pieChart_2, Colors.pieChart_3, Colors.pieChart_4, Colors.pieChart_5];

// let data = [
//     { 'in-process': { name: 'In-process', value: 0 } },
//     { 'approved': { name: 'Approved', value: 0 } },
//     { 'wating': { name: 'Wating', value: 0 } },
//     { 'cancel': { name: 'Cancel', value: 0 } },
//     { 'reject': { name: 'Reject', value: 0 } },
//     { 'refunded': { name: 'Refunded', value: 0 } }];

let data = [
    { name: 'Wating', value: 5 },
    { name: 'in-process', value: 46 },
    { name: 'approved', value: 4 },
    { name: 'Cancel', value: 6 },
    { name: 'Reject', value: 7 },
    { name: 'Refunded', value: 0 }];

let _data;


class TwoLevelPieChart extends Component {

    state = { open: false, status: '', remark: '', btnActive: false, activeIndex: 0, };

    render() {

        const { Info } = this.props;
        _data = Info || data;

        // console.log('bookingStatus : ', Info, ' : ', _data)

        return (
            <PieChart width={230} height={200} style={{ fontFamily: Family.roboto, }}>
                <Pie
                    dataKey="value"
                    activeIndex={this.state.activeIndex}
                    activeShape={renderActiveShape}
                    data={_data}
                    // cx={300}
                    // cy={200}
                    innerRadius={50}
                    outerRadius={80}
                    fill="#8884d8"
                    onMouseEnter={this.onPieEnter}>
                    {_data.map((entry, index) => <Cell key={`call_${index}`} fill={COLORS[index % COLORS.length]} />)}
                </Pie>
            </PieChart >
        );
    }

    getInitialState = () => { return { activeIndex: 2, }; }
    onPieEnter = (_data, index) => { this.setState({ activeIndex: index, }); }
}

export default TwoLevelPieChart;

const SetInfoToChart = (_BookingStatus) => {

    // console.log('SetInfoToChart : ', _BookingStatus)
    const _new = Object.keys(_BookingStatus).map((_key) => { if (!_key.match(/(startDate|endDate|total)/i)) return { name: _key, value: _BookingStatus[_key] } });
    // console.log('New data : ', _new)
    return _new;
}


const renderActiveShape = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;

    const RADIAN = Math.PI / 180;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = (cos >= 0) ? 'start' : 'end';

    return (
        <g>
            <text key={'s_name'} x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>{`${payload.name}`}</text>
            <text key={'s_value'} x={cx} y={cy} dy={25} textAnchor="middle" fill={fill}>{`( ${value} )`}</text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill} />

            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill} />

            {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`PV ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#333">
                {`(Rate ${(percent * 100).toFixed(2)}%)`}
            </text> */}
        </g>
    );
};
