
import * as ActivityActions from '../actions/Actions_Activity';

const INITIAL_STATE = { ACTIVITY_REDUCER: { actions: ActivityActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: ActivityActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {

    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case ActivityActions.INITIAL_STATE:
            {
                return state;
            }
        case ActivityActions.IDLE_STATE:
            {
                //console.log("[Activity] : IDLE_STATE : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.IDLE_STATE, data: payload, error: _error } };
            }
        case ActivityActions.GET_ACTIVITY_SUCCESS:
            {
                //console.log("[Activity] : GET_ACTIVITY_SUCCESS : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.GET_ACTIVITY_SUCCESS, data: payload, error: _error } };
            }
        case ActivityActions.GET_ACTIVITY_FAILURE:
            {
                console.log("[Activity] : GET_ACTIVITY_FAILURE : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.GET_ACTIVITY_FAILURE, data: payload, error: _error } };
            }
        case ActivityActions.CREATE_ACTIVITY_SUCCESS:
            {
                //console.log("[Activity] : CREATE_ACTIVITY_SUCCESS : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.CREATE_ACTIVITY_SUCCESS, data: payload, error: _error } };
            }
        case ActivityActions.UPDATE_ACTIVITY_SUCCESS:
            {
                //console.log("[Activity] : UPDATE_ACTIVITY_SUCCESS : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.UPDATE_ACTIVITY_SUCCESS, data: payload, error: _error } };
            }
        case ActivityActions.UPDATE_ACTIVITY_FAILURE:
            {
                //console.log("[Activity] : UPDATE_ACTIVITY_FAILURE : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.UPDATE_ACTIVITY_FAILURE, data: payload, error: _error } };
            }
        case ActivityActions.CREATE_ACTIVITY_FAILURE:
            {
                console.log("[Activity] : CREATE_ACTIVITY_FAILURE : ", payload);
                return { ...state, ACTIVITY_REDUCER: { actions: ActivityActions.CREATE_ACTIVITY_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[Activity]  default :", state);
                return state;
            }
    }
}
