import React, { Component } from 'react';
import { connect } from 'react-redux';
import validate from "validate.js";
import { path, omit, keys } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';
import { SetCustomUpdate } from '../../constant/initState/initPackage';

import Language from '../../constant/Language';
import DialogPanel from '../../componets/DialogPanel.jsx';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors } from '../../themes';

import * as PackageAction from '../../actions/Actions_PackageManager.js';

import * as validator from '../../constant/Validator/PackageTopList';
import PackageTopList from '../../constant/JSON/PackageTopList';
import FormTypes from '../../common/FormTypes';

const _style = { textAlign: 'start', width: '100%' };
const _rowContainer = { display: 'flex' };
let waitingTime = true;
let isBtnActive = [];


class PackageTopListCard extends Component {

    state = {
        isLoading: false, isView: false,
        isDialog: false, msgError: undefined, dialogType: undefined,
        error: null,
    }

    constructor(props) {
        super(props);
        this.state = {
            PackageInfo: this.props.PackageInfo,
            oldPackageInfo: this.props.oldPackageInfo,
            disabled: true,
            isUpdate: false,
        }

    }

    componentWillReceiveProps(nextProps) {
        const { PackageManager } = nextProps;

        if (!!PackageManager) {
            const { actions, data } = PackageManager;
            const { page, language, limit } = this.props.packageInfoList;

            switch (actions) {

                case PackageAction.UPDATE_PACKAGE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update PackageTopList Success.', dialogType: 'success', });
                        this.props.getAPIPackageList({ limit: limit || 25, page: page || 0, language: language || 'EN' });
                    } break;
                case PackageAction.UPDATE_PACKAGE_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }



    render() {
        const { classes, onClosePanel, isView, packageList } = this.props;
        const { msgError, dialogType, isLoading, isDialog, isBtnActive, PackageInfo } = this.state;
        const { onSave } = this;
        const LanguagePackage = path(['language',], this.props.packageInfoList);
        //console.log('Package State : ', PackageInfo);
        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0px' }}>
                        <Typography className={classes.title}> {PackageTopList.HeaderPage.EN} </Typography>
                    </div>}
                {
                    PackageTopList.BodyPage.map((row, index) => (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={{ width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, marginTop: 5 }}>{this.SetItemsWithType(each, LanguagePackage)}</div>))}
                        </div>
                    ))
                }
                {
                    !isView && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => onSave()} />
                    </div>
                }
            </div >
        );
    }

    SetItemsWithType(each, LanguagePackage) {
        const { isView } = this.props;
        const { PackageInfo } = this.state;
        let options = (each.options) ? each.options : null;
        let isError = !!path([each.stateName], this.error)

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled
                        error={isError}
                        Require={each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={path(['packageInfo', each.stateName], PackageInfo) || path([each.stateName], PackageInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "select": {
                return (
                    <FormTypes.Select
                        disabled={isView && true}
                        error={isError}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={String(path([each.stateName], PackageInfo)) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(options) && options.map((option, i) => { return (<option key={i} value={option.value}> {option.value || ''} </option>) })} />
                )
            }
            case "form-checkbox": {
                return (
                    <FormTypes.Checkboxes
                        disabled={isView && true}
                        TitleText={each.title_EN}
                        Items={each.items}
                        StateInfo={PackageInfo}
                        Language={'EN'}
                        handleToggle={(fieldName, each) => this.handleToggle(fieldName, each)}
                    />
                )
            }
        }
    }

    handleToggle = (fieldName, state) => {

        const { PackageInfo } = this.state;
        switch (fieldName) {
            case "visible":
            case "isPromotion":
            case "isAttractions":
            case "isBestSeller":
            case "isRecommends": {
                PackageInfo[state.stateName] = !PackageInfo[state.stateName];
                this.setState({ isBtnActive: true }, () => this.onChangeState(fieldName, PackageInfo[state.stateName]));
            } break;
        }

    }

    onChangeState = (fieldName, state) => {

        let PackageInfo = { ...this.state.PackageInfo };
        switch (fieldName) {
            default: {
                PackageInfo = { ...PackageInfo, [fieldName]: state }
            }
        }
        this.setState({ PackageInfo, isBtnActive: true });
    }
    // validate = (value) => {
    //     const fieldName = keys(value)[0];
    //     // console.log('fieldName', fieldName, value)
    //     let result = true;
    //     if (!!validator.constraints[fieldName]) {
    //         const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
    //         const error = this.error || {};
    //         // console.log('validateResult', validateResult);
    //         if (!!validateResult) {
    //             result = false;
    //             this.error = { ...error, ...validateResult };
    //             this.forceUpdate();
    //         } else {
    //             this.error = omit([fieldName], error);
    //             this.forceUpdate();
    //         }
    //     }
    //     // console.log('result', result, this.error);
    //     return result;
    // }

    // validateAll() {
    //     let success = true;
    //     validator.input.forEach(each => {
    //         const validateResult = this.validate({
    //             [each.fieldName]: this.state.PackageInfo[each.fieldName] || this.state.PackageInfo.packageInfo[each.fieldName],
    //         });;
    //         success = success && validateResult;
    //     });
    //     return success;
    // }

    onSave = () => {
        let Package = { ...this.state.PackageInfo };
        let PackageInfo = Package.packageInfo;
        // console.log('onSave', PackageInfo)
        // if (this.validateAll()) {
        this.setState({ isDialog: true, isBtnActive: false }, () => this.props.getAPIupdatePackage(SetCustomUpdate('PackageTopList', Package, PackageInfo)))
        // } else {
        //     this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        // }
    }
    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, }));
}

const styles = theme => ({ title: { fontSize: '24px', display: 'flex' }, });

PackageTopListCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { PackageManager: state.PackageManager.PACKAGE_REDUCER, }; }
function mapDispatchToProps(dispatch) {
    return {
        getAPIupdatePackage: (packageInfo) => {
            dispatch(PackageAction.APIUpdatePackage(packageInfo));
        },
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PackageTopListCard));

