import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
// import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Button from '../constant/Button';
import SearchPanel from './SearchPanel.jsx';
import EmptyRows from '../componets/EmptyRow.jsx';
import { GetSymbol } from '../common/GetSymbol.js';
import DialogPanel from '../componets/DialogPanel.jsx';
import { mapPaymentType } from '../common/PaymentMethod';
import { SetDate, SetDateShort } from '../common/SetDate';
import { NumberFormats } from '../common/SetNumberFormat';
import { GetStatusColor } from '../common/GetStatusColor';
import SortHandle from '../componets/TableManage/SortHandle.jsx';

import * as BookingAction from '../actions/Actions_BookingManager.js';

import { Colors } from '../themes/index.js';
// import { isMobile } from '../common/DeviceCheck.js';
import { InitwaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

const fildeList = [
  { label: 'BOOKING ID', name: 'bookingId', isOrder: true, },
  { label: 'PACKAGE NAME', name: 'name', isOrder: true, },
  { label: 'BUYERS', name: 'firstName', isOrder: true, },
  { label: 'BOOKING DATE', name: '', isOrder: false, },
  { label: 'TRAVAL DATE', name: '', isOrder: false, },
  { label: 'PAYMENT BY', name: '', isOrder: false, },
  { label: 'PRICE', name: 'net', isOrder: false, },
  { label: 'STATUS', name: 'bookingStatus', isOrder: true, },];

const fildeListSeller = [
  { label: 'BOOKING ID', name: 'bookingId', isOrder: true, },
  { label: 'PACKAGE NAME', name: 'name', isOrder: true, },
  { label: 'BOOKING BY', name: 'promotionCode', isOrder: true, },
  { label: 'BOOKING DATE', name: '', isOrder: false, },
  { label: 'ADULT', name: '', isOrder: false, },
  { label: 'KID', name: '', isOrder: false, },
  { label: 'DISCOUNT', name: '', isOrder: false, },
  { label: 'PRICE', name: 'bookingStatus', isOrder: false, },];

const _wording = { header: 'BOOKING MANAGE', tap: 'DAY TRIPS' }
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', border: `${'1px solid' + Colors.white}` };
const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '15px', maxWidth: 280, border: `${'1px solid' + Colors.white}` };

const _callStyleMobile = { textAlign: 'center', padding: 2, fontSize: 11, width: 50, border: `${'1px solid' + Colors.white}` };
const _callNameMobile = { textAlign: 'left', padding: 2, fontSize: 11, maxWidth: 80, border: `${'1px solid' + Colors.white}` };

let bookingList = [];
let waitingTime = false;

class BoookingListsPanel extends Component {

  state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: true }

  constructor(props) {
    super(props);

  }

  componentWillReceiveProps(nextProps) {

    const { BookingManager } = nextProps;

    if (!!BookingManager) {
      const { actions, data } = BookingManager.BOOKING_REDUCER;

      switch (actions) {
        case BookingAction.GET_BOOKING_LISTS_SUCCESS:
          {
            bookingList = data;
            SetStateWithTime(this, waitingTime);
          } break;
        case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
          {

          } break;
      }
    }

  }

  render() {

    const { classes, BookingList, BookingDetail, BookingManage, userInfo, isMobile } = this.props;
    bookingList = BookingList.bookingList || [];

    // handleRequestSort, orderBy, onNextPage, onChangeLimit
    // const bookingList = ((!!BookingList) && (!!BookingList.bookingList)) ? BookingList.bookingList : [];
   
    const _isSeller = Boolean(userInfo);
    const _isBooking = (bookingList.length > 0);

    const fildeLists = (_isSeller) ? fildeListSeller : fildeList;
    const { isLoading, msgError, dialogType, orderBy, searchActive } = this.state;
    const isCallStyle = isMobile ? _callStyleMobile : _callStyle;

    return (
      <React.Fragment>
        {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
        <Paper className={classes.root}>
          <Table className={(isMobile) ? classes.tableMobile : classes.table}>
            <TableHead>
              <TableRow style={{ height: '100%' }}>
                <TableCell colSpan={fildeLists.length} style={{ padding: '0px' }} >
                  <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0 }}
                    onClick={() => this.setState({ searchActive: !searchActive })} />
                  {(searchActive) && <SearchPanel searchActive={searchActive} SearchType='booking' userInfo={userInfo} />}
                </TableCell>
              </TableRow>
              <TableRow>
                {fildeLists.map((filde, index) => {
                  return (
                    <TableCell key={index} className={classes.customTable}>
                      <SortHandle style={{ color: Colors.white }} orderBy={orderBy} field={filde} handleRequestSort={this.handleRequestSort} />
                    </TableCell>)
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(_isBooking) ? GetBookingRows(classes, bookingList, BookingDetail, _isSeller, isMobile) : <EmptyRows fildeList={fildeLists} />}
            </TableBody>
          </Table>
          {(_isBooking) && GetPagination(BookingList, this.onNextPage, this.onChangeLimit, userInfo)}
        </Paper>
      </React.Fragment>
    );
  }

  //===============================================================================================================================================================

  handleRequestSort = (sortFilde) => {

    const { orderBy, field } = sortFilde;
    const _fildeName = field.name;
  
    bookingList = (orderBy === 'desc')
      ? bookingList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
      : bookingList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

    this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
  };

  onNextPage = (_pageNumber, limit, userInfo) => {

    this.setState({ isLoading: true });
   
    if (!!userInfo)
      this.props.getAPIBookingLists('', { affiliateId: userInfo.sellerId, page: _pageNumber, limit: (limit || 25) });
    else
      this.props.getAPIBookingLists('', { page: _pageNumber, limit: (limit || 25) });
  }

  onChangeLimit = (_limitRows, userInfo) => {

    this.setState({ isLoading: true });
 
    if (!!userInfo)
      this.props.getAPIBookingLists('', { affiliateId: userInfo.sellerId, page: 0, limit: _limitRows });
    else
      this.props.getAPIBookingLists('', { page: 0, limit: _limitRows });
  }

  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

  //===============================================================================================================================================================
}

function GetBookingRows(classes, bookingList, BookingDetail, isSeller, isMobile) {

  return bookingList.map(_booking => {

    const { bookingId, bookingNo, packageInfo, paymentInfo, bookingStatus, buyerInfo, priceTotal, date, promotionCode, affiliateChannel } = _booking;
    const { bookingInfo, isPromotion, name, promotion, price } = packageInfo[0];
    const { adult, kid, date: bookingDate, } = bookingInfo;
    const { subTotal, discount, net, currency } = priceTotal;
    const { subPaymentMethod, paymentMethod } = paymentInfo;

    const isPrice = (isPromotion) ? promotion : price;
    const { adult: adultPrice, kid: kidPrice } = isPrice;

    const isCallStyle = isMobile ? _callStyleMobile : _callStyle;
    const isCallNametyle = isMobile ? _callNameMobile : _callName;
    const comitionChannel = (affiliateChannel === 'code') ? (promotionCode || 'ShareLink') : 'ShareLink';

    return (
      <TableRow key={bookingId} className={classes.row} hover style={{ cursor: 'pointer', }} onClick={() => { BookingDetail(bookingId) }}>
        <TableCell style={isCallStyle}>{bookingNo || bookingId}</TableCell>
        <TableCell style={isCallNametyle}>{name}</TableCell>
        {(isSeller) ?
          <React.Fragment>
            <TableCell style={isCallStyle}>{comitionChannel}</TableCell>
            <TableCell style={isCallStyle}>{SetDate(date)}</TableCell>
            <TableCell style={isCallStyle}>{(adult > 0) ? `${adult} x ${adultPrice}` : '-'}</TableCell>
            <TableCell style={isCallStyle}>{(kid > 0) ? `${kid} x ${kidPrice}` : '-'}</TableCell>
            <TableCell style={isCallStyle}>{discount}</TableCell>
            <TableCell style={GetStatusColor('Booking', isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(subTotal || net)}`}</TableCell>
          </React.Fragment> :
          <React.Fragment>
            <TableCell style={{ ...isCallStyle, textAlign: 'left' }}>{`${buyerInfo.title}${buyerInfo.firstName} ${buyerInfo.lastName}`}</TableCell>
            <TableCell style={isCallStyle}>{SetDate(date)}</TableCell>
            <TableCell style={isCallStyle}>{SetDateShort(bookingDate)}</TableCell>
            <TableCell style={isCallStyle}>{mapPaymentType(subPaymentMethod || paymentMethod)}</TableCell>
            <TableCell style={GetStatusColor('Booking', isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(subTotal || net)}`}</TableCell>
            <TableCell style={GetStatusColor('Booking', isCallStyle, bookingStatus)}>{bookingStatus}</TableCell>
          </React.Fragment>
        }
      </TableRow>)
  });
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, userInfo) {

  const { limit, page, pageSize, total } = _ListCount;

  return (<TablePagination
    component="div"
    count={total || 0}
    rowsPerPage={limit || 25}
    page={page || 0}
    backIconButtonProps={{ 'aria-label': 'Previous Page', }}
    nextIconButtonProps={{ 'aria-label': 'Next Page', }}
    onChangePage={(event, page) => { onNextPage(page, limit, userInfo) }}
    onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, userInfo) }} />)
}

// function GetSortIcon(orderBy, field, handleRequestSort) {
//   return (
//     <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
//       {field.label}
//     </TableSortLabel>)
// }

// function GetFooterPagination() {
//   <TableFooter>
//           <TableRow>
//             <TablePagination
//               colSpan={bookingList.length}
//               count={bookingList.length}
//               rowsPerPage={25}
//               page={0}
//               onChangePage={() => { }}
//               onChangeRowsPerPage={() => { }}
//             // Actions={TablePaginationActionsWrapped}
//             />
//           </TableRow>
//         </TableFooter> 
// }

const styles = theme => (
  {
    root: { width: '100%', marginTop: (theme.spacing.unit * 3), marginTop: '0px' },
    table: { minWidth: 1024, },
    tableMobile: { width: '100%' },
    textField: { width: 400, },
    customTable: {
      textAlign: 'center',
      paddingLeft: '30px',
      paddingRight: '5px',
      backgroundColor: Colors.themeDark,
      color: Colors.white,
      border: `${'1px solid' + Colors.white}`
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: Colors.themeDark_Item,  //#fafafa
      },
      '&:nth-of-type(odd):hover': {
        backgroundColor: Colors.themeDark_Light, //#eee
      },
    },
  });

BoookingListsPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIBookingDetails: (_id) => {
      dispatch(BookingAction.getAPIBookingDetail(_id));
    },
    getAPIBookingLists: (_keyword, _filter) => {
      dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BoookingListsPanel));
