import React, { Component } from 'react';
import { connect } from 'react-redux';
import validate from "validate.js";
import { path, equals, keys, omit } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { SetStateWithObject } from '../../common/SetTime.js';
import FormTypes from '../../common/FormTypes';
import { Colors, Family } from '../../themes';

import Avatar from '../../constant/Avatar';
import Language from '../../constant/Language';

import DialogPanel from '../../componets/DialogPanel.jsx';
import AutoThaiAddress from '../../componets/AutoThaiAddress.jsx';

import * as validator from '../../constant/Validator/Destination';
import Destination from '../../constant/JSON/Destination';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import * as DestinationsActions from '../../actions/Actions_DestinationsManager';
import * as UploadActions from '../../actions/Actions_UploadManager';

import CONFIG from '../../config';
import { acceptFile } from '../../constant';
import FileUploader from '../../FileUploader';
import { isMobile } from '../../common/DeviceCheck';

const _rowContainer = { display: 'flex', flexDirection: isMobile() ? 'column' : 'row' };
let waitingTime = true;


class DestinationCard extends Component {

    state = { isLoading: false, isView: false, isDialog: false, msgError: undefined, dialogType: undefined, error: null, }

    imageOther = [];
    gallery = null;
    constructor(props) {
        let error = null;
        super(props);
        this.state = {
            destinationInfo: this.props.destinationInfo,
            oldDestinationInfo: this.props.oldDestinationInfo,
            disabled: true,
            isUpdate: false,
            new_gallery: [],
        }

    }

    componentWillReceiveProps(nextProps) {
        const { DestinationsManager, UpLoadManager } = nextProps;
        if (!!DestinationsManager) {
            const { actions, data } = DestinationsManager.DESTINATION_REDUCER;
            const { page, language } = this.props.DestinationsList;

            switch (actions) {
                case DestinationsActions.CREATE_DESTINATIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Destination Success.', dialogType: 'success', });
                        this.setState({ destinationId: data.destinationId, isUpdate: true })
                        this.props.getAPIDestinationsLists({ page: page, num_limit: 10, language: language });
                    } break;
                case DestinationsActions.UPDATE_DESTINATIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Destination Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPIDestinationsLists({ page: page, num_limit: 10, language: language });
                    } break;
                case DestinationsActions.CREATE_DESTINATIONS_FAILURE:
                case DestinationsActions.UPDATE_DESTINATIONS_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, destinationId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let destinationInfo = { ...this.state.destinationInfo };
                        const _image = data.result.upload.images;
                        // destinationInfo.picture = data.result.upload.images;
                        console.log('UPLOAD_IMAGE_UPDATE_SUCCESS :', destinationInfo)

                        if (isEdit) {
                            destinationInfo.gallery = destinationInfo.gallery.concat(_image);
                            destinationInfo.picture = destinationInfo.gallery[0];

                            this.props.APIUpdateDestinations(destinationInfo)
                        } else if (isUpdate) {
                            destinationInfo.gallery = destinationInfo.gallery.concat(_image);
                            destinationInfo.picture = destinationInfo.gallery[0];
                            destinationInfo = { ...destinationInfo, destinationId: destinationId }
                            this.props.APIUpdateDestinations(destinationInfo)
                        } else {
                            destinationInfo.gallery = (_image.length > 0) ? _image : [_image];
                            destinationInfo.picture = destinationInfo.gallery[0];

                            this.props.APICreateDestinations(destinationInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }


    render() {
        const { classes, onClosePanel, isView, DestinationsList, isEdit } = this.props;
        const { msgError, dialogType, isLoading, isDialog, isBtnActive, isUpdate } = this.state;
        const LanguageDestination = path(['language',], this.props.DestinationsList);

        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}

                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}> {isEdit ? Destination.HeaderPage.UPDATE : Destination.HeaderPage.EN} </Typography>
                    <Language LanguagePage={LanguageDestination} onChangeState={this.onChangeLanguage} isValue={true} />
                </div>}
                {
                    Destination.BodyPage.map((row, index) => (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={{ textAlign: 'start', paddingTop: 5, width: each.width ? each.width : '100%' }}>{this.SetItemsWithType(each, LanguageDestination)}</div>))}
                        </div>
                    ))
                }
                {
                    !isView && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => { this.onSave() }} />
                    </div>
                }
            </div >
        );
    }

    SetItemsWithType(each, LanguageDestination) {
        const { classes, isView, isEdit } = this.props;
        const { destinationInfo, oldDestinationInfo } = this.state;
        const isError = !!path([each.stateName], this.error);
        const valueLanguage = path([LanguageDestination, each.stateName], destinationInfo);
        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage || path([each.stateName], destinationInfo) || ''}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={4}
                        style={{ marginBottom: 10 }}
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage || ''}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            // case 'image': {
            //     return (
            //         <div className={classes.textDefalut} >
            //             {
            //                 isView ? <img className={classes.avatar} src={`${CONFIG.host}${destinationInfo.picture.thumbnail}`} />
            //                     :
            //                     <Avatar ref='avatar' editable={true}
            //                         onChange={(files) => { this.onChangeState(each.stateName, files) }}
            //                         avatar={Boolean(destinationInfo.picture.thumbnail) ? `${CONFIG.host}${destinationInfo.picture.thumbnail}` : ''} />
            //             }
            //         </div>
            //     )
            // }
            case 'media': {
                const value = path([each.stateName], oldDestinationInfo) || [];
                return (
                    <React.Fragment>
                        {(isEdit || isView) ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader
                                    ref={(ref) => this[each.stateName] = ref}
                                    disabled={isView ? true : false}
                                    multiple
                                    onChange={(files) => this.setState({ [`new_${each.stateName}`]: files, isBtnActive: true })}
                                    onDefaultChange={(defaultFiles) => { this.onChangeState(each.stateName, defaultFiles) }}
                                    defaultFiles={value}
                                    accept={acceptFile.image}
                                />
                                {!isView && <div style={{ color: Colors.disabled, fontSize: '10px', paddingTop: '5px' }} >
                                    {each.require.EN}
                                </div>}
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader multiple accept={acceptFile.image}
                                    disabled={isView ? true : false}
                                    onChange={(files) => { this.onChangeState(each.stateName, files) }} />
                                <div style={{ color: Colors.disabled, fontSize: '12px', paddingTop: '10px', fontFamily: Family.roboto }} >
                                    {each.require.EN}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        ValueText={each.title_EN}
                        disabled={isView ? true : false}
                        checked={path([each.stateName], destinationInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
            case "search_select": {
                return (
                    <AutoThaiAddress each={each}
                        disabledProvince={isView ? true : false}
                        disabledDistrict={isView ? true : false}
                        style={{ paddingLeft: 15 }}
                        errorPro={!!path(['province'], this.error)}
                        RequirePro={isView ? false : each.requirePro}
                        RequireDis={isView ? false : each.requireDis}
                        provinceId={path([each.stateName_pro], destinationInfo) || ''}
                        districtId={path([each.stateName_dis], destinationInfo) || ''}
                        onChange={(val) => {
                            if (!!val.district) {
                                this.onChangeState('district', path(['district', 'code'], val))
                            } else {
                                this.onChangeState('province', path(['province', 'code'], val))
                            }
                        }} />
                )
            }
        }
    }
    onChangeState = (fieldName, state) => {
        console.log('[onChangeState] :', fieldName, state)
        let destinationInfo = { ...this.state.destinationInfo };
        const { oldDestinationInfo } = this.state;
        const LanguageDestination = path(['language',], this.props.DestinationsList);
        switch (fieldName) {
            case "name":
            case "address":
            case "description": {
                destinationInfo = { ...destinationInfo, [LanguageDestination]: { ...destinationInfo[LanguageDestination], [fieldName]: state } }
            } break;
            case "province": {
                destinationInfo = { ...destinationInfo, parentId: state, [fieldName]: state }
            }
            default: { destinationInfo = { ...destinationInfo, [fieldName]: state } }
        }
        console.log('destination success', destinationInfo)
        this.setState({ destinationInfo, isBtnActive: !equals(oldDestinationInfo, destinationInfo) }, () => { this.validate({ [fieldName]: state }); });
    }
    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};
            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        const LanguageDestination = path(['language',], this.props.DestinationsList);
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.destinationInfo[LanguageDestination][each.fieldName] || this.state.destinationInfo[each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        const { isEdit } = this.props;
        const { isUpdate, destinationId, oldDestinationInfo, new_gallery } = this.state;
        let destinationInfo = { ...this.state.destinationInfo }
        const { gallery } = destinationInfo;

        if (this.validateAll()) {
            if (isEdit) {
                const { defaultFiles } = this.gallery ? this.gallery.state.length === 0 ? [] : this.gallery.state : [];
                this.imageOther = this.imageOther.concat(this.gallery.files, defaultFiles)
                let newImage = this.gallery.files;
                if (newImage && newImage.length > 0) {
                    this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(newImage));
                } else {
                    this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APIUpdateDestinations(destinationInfo));
                }
            }
            else {
                if (new_gallery.length > 0 || gallery.length > 0) {
                    if (isUpdate) {
                        this.setState({ isDialog: true, destinationInfo: { ...destinationInfo, gallery: [] }, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
                    }
                    else {
                        this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
                    }
                }
                else {
                    if (isUpdate) {
                        destinationInfo = { ...destinationInfo, destinationId: destinationId };
                        this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APIUpdateDestinations(destinationInfo));
                    }
                    else {
                        this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APICreateDestinations(destinationInfo));
                    }
                }
            }
        }
        else {
            this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error);
        }

        // if (this.validateAll()) {
        //     if (new_gallery.length > 0 || gallery.length > 0) {
        //         if (isEdit) {
        //             const { defaultFiles } = this.gallery ? this.gallery.state.length === 0 ? [] : this.gallery.state : [];
        //             const { removeList } = this.gallery
        //             this.imageOther = this.imageOther.concat(this.gallery.files, defaultFiles)
        //             let newImage = this.gallery.files;
        //             if (newImage && newImage.length > 0) {
        //                 this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(newImage));
        //             } else {
        //                 if (removeList.length > 0) {

        //                     this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APIUpdateDestinations(destinationInfo));
        //                 } else
        //                     this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(new_gallery));
        //             }
        //         } else {
        //             this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
        //         }
        //     } else {
        //         this.setState({ isDialog: true, isBtnActive: false, })
        //         if (isEdit) { this.props.APIUpdateDestinations(destinationInfo) }
        //         else if (isUpdate) {
        //             destinationInfo = { ...destinationInfo, destinationId: destinationId },
        //                 this.props.APIUpdateDestinations(destinationInfo)
        //         } else { this.props.APICreateDestinations(destinationInfo) }
        //     }
        // } else {
        //     this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        // }
    }

    APIUpLoadGallery = (cover) => {

        let formdata = new FormData();
        formdata.append('imgOriginalWidth', 600); //650
        formdata.append('imgOriginalHeight', 600); //450
        formdata.append('imgThumbnailWidth', 400); //340
        formdata.append('imgThumbnailHeight', 400); //260
        formdata.append('imgThumbnailWidthMobile', 200); //308
        formdata.append('imgThumbnailHeightMobile', 200); //181

        for (const _image of cover)
            formdata.append("images", _image.file);

        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, }));
    onChangeLanguage = (_language) => {
        const { page } = this.props.DestinationsList;
        this.props.getAPIDestinationsLists({ page: page, num_limit: 10, language: _language });
    }

}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
    textDefalut: { fontSize: '15px', color: Colors.themeDark_Light, fontFamily: Family.roboto },
    avatar: { height: '120px', width: '120px', margin: '14px 0px', boxShadow: '0 2px 5px 0 hsla(0,0%,47%,.5)', }
});

DestinationCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APICreateDestinations: (_confirmInfo) => {
            dispatch(DestinationsActions.APICreateDestinations(_confirmInfo));
        },
        APIUpdateDestinations: (_confirmInfo) => {
            dispatch(DestinationsActions.APIUpdateDestinations(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getAPIDestinationsLists: (_keyword, _filter) => {
            dispatch(DestinationsActions.getDestinationsList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DestinationCard));

