import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import DialogAction from '../componets/DialogAction.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';

import * as LocationActions from '../actions/Actions_LocationServicesManager';
import * as CountryActions from '../actions/Actions_CountryManager';

import LocationCard from '../containers/LocationService/LocationCard';
import LocationListPanel from '../containers/LocationService/LocationListPanel';
import { init_location } from '../constant/initState/initLocationService';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

let CountryList = [];
let LocationServicesList = [];
let waitingTime = true;
const _height = (window.innerHeight - 130);

class LocationManage extends Component {
    state = { isLoading: true, msgError: undefined, dialogType: undefined, }
    constructor(props) {
        let error = null;
        super(props);
        this.state = {
            LocationServicesInfo: undefined,
            oldLocationServicesInfo: null,
            isCreate: false,
            isEdit: false,
            isView: false,
        }
        this.props.getAPILocationServiceList({ page: 0, num_limit: 10 });
        this.props.getCountryList();
    }

    componentWillReceiveProps(nextProps) {
        // console.log('[ componentWillReceiveProps(nextProps) : ]', nextProps)
        const { LocationServicesManager, CountryManager } = nextProps;
        if (!!LocationServicesManager) {

            const { actions, data } = LocationServicesManager.LOCATIONSERVICES_REDUCER;

            switch (actions) {
                case LocationActions.GET_LOCATIONSERVICES_SUCCESS:
                    {
                        LocationServicesList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case LocationActions.GET_LOCATIONSERVICES_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!CountryManager) {

            const { actions, data } = CountryManager.COUNTRY_REDUCER;

            switch (actions) {
                case CountryActions.GET_COUNTRY_SUCCESS:
                    {
                        CountryList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CountryActions.GET_COUNTRY_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.createLocationService()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        )
    }
    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <LocationListPanel
                    CountryList={CountryList}
                    LocationServicesList={LocationServicesList}
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.editPanel()}
                    ViewInfo={this.viewInfo()} />
            </div>)
    }

    createLocationService = () => {
        const { isEdit, LocationServicesInfo, oldLocationServicesInfo, isView } = this.state;
        return (
            <LocationCard
                CountryList={CountryList}
                LocationServicesList={LocationServicesList}
                onChangeLanguage={this.onChangeLanguage}
                isEdit={isEdit}
                isView={isView}
                LocationServicesInfo={LocationServicesInfo}
                oldLocationServicesInfo={oldLocationServicesInfo}
                onClosePanel={this.onClosePanel}
            />
        )
    }

    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, oldLocationServicesInfo: { ...init_location }, LocationServicesInfo: { ...init_location } }));
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, isView: false, LocationServicesInfo: undefined, }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    onChangeLanguage = (_language) => {
        const { page } = LocationServicesList;
        console.log('_language', _language);
        this.props.getAPILocationServiceList({ page: page, num_limit: 10, language: _language });
    }

    editPanel = () => (LocationServicesInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            isView: false,
            LocationServicesInfo,
            oldLocationServicesInfo: LocationServicesInfo
        })
    }

    viewInfo = () => (LocationServicesInfo) => {
        this.setState({
            isCreate: true,
            isView: true,
            LocationServicesInfo,
            oldLocationServicesInfo: LocationServicesInfo
        })
    }

}
function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPILocationServiceList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(LocationActions.getLocationServicesList(_keyword, _filter));
        },
        getCountryList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CountryActions.getCountryList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationManage);