import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CONFIG from '../config';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import { path, equals } from 'ramda';

import '../App.css';
import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import PaymentTimeCard from '../containers/Settings/PaymentTimeCard';
import PaymentTimePanalList from '../containers/Settings/PaymentTimePanalList';
import BankListPanal from '../containers/Bank/BankListPanal';
import BankCard from '../containers/Bank/BankCard';
import CurrencyManage from '../containers/Currency/CurrencyManage';
import CurrencyRateManage from '../containers/Currency/CurrencyRateManage';
import SellerTypeManage from '../containers/Sellers/SellerType/SellerTypeManage';
import * as SettingActions from '../actions/Actions_Settings';
import * as PaymenttimeSettingActions from '../actions/Actions_Settings';
import * as UploadActions from '../actions/Actions_UploadManager';
import * as CurrencyActions from '../actions/Actions_CurrencyManager';
import * as SellerActions from '../actions/Actions_SellerManager.js';
import * as PackageAction from '../actions/Actions_PackageManager.js';

import LevelManage from '../containers/Level/LevelManage';
import RefundReasonsManage from '../containers/RefundReasons/RefundReasonsManage';

import { PathName, Roles } from '../constant';
import { init_bank } from '../constant/initState/initBank';
import { init_seller, SetSellerToUpdate } from '../constant/initState/initSeller';

import SellerCard from '../containers/Sellers/SellerInfo';
import CloseServiceManage from '../containers/CloseService/CloseServiceManage';
import ReservationTableManage from '../containers/ReservationTable/ReservationTableManage';

import { Colors } from '../themes';
import { isMobile } from '../common/DeviceCheck';

let waitingTime = true;
const _height = (window.innerHeight - 130);
let BookBankList = [];
let SellerTypeList = [];
let DefaultCommisstions = [];

class SettingsManage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            error: null,
            isEdit: false,
            isView: false,
            PaymentTimeInfo: undefined,
            oldPaymentTimeInfo: null,
            msgError: undefined,
            dialogType: undefined,
            BankInfo: undefined,
            oldBankInfo: null,
            isCreateBank: false,
        };
        this.props.getAPISellerTypeList();
        this.props.getDefaultCommissions();
    }
    componentWillReceiveProps(nextProps) {
        const { SettingManager, SellerManager } = nextProps;

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        // SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        DefaultCommisstions = data.result;
                        // SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;

            //     const { isEdit, isCreate } = this.state;

            switch (actions) {
                case SellerActions.GET_SELLER_TYPE_SUCCESS:
                    {
                        SellerTypeList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_TYPE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }


    }

    render() {

        const { isLoading, isCreateBank, isUpdatePackage } = this.state;

        let isSettingSeller = (this.props.location.pathname === PathName.SettingSeller)

        return (
            <div className="App">
                {(isCreateBank) && <DialogAction Active={isCreateBank} OnClose={this.onClosePanel} AcrionCom={this.CreateBankPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>

                    <div style={{ display: (isSettingSeller && isMobile()) ? 'block' : 'inline-block' }}>
                        <div style={{ height: (isSettingSeller) ? 'unset' : '600px', width: '100%', maxWidth: '1024px' }}>

                            {this.contentPanel()}
                        </div>
                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        console.log('this.props.location.pathname', this.props.location.pathname)
        switch (this.props.location.pathname) {
            case PathName.SettingSeller: {
                return (
                    <div style={{ backgroundColor: Colors.white, padding: '15px 35px' }}>
                        <SellerCard
                            Level={DefaultCommisstions}
                            SellerTypeList={SellerTypeList}
                            BookBankList={BookBankList}
                            // oldSellerInfo={oldSellerInfo}
                            // SellerInfo={SellerInfo}
                            location={this.props.location}
                            isEdit={true}
                            isSetting={true} />
                    </div>
                )
            };
            case PathName.PaymentManage: {
                return (
                    <React.Fragment>
                        <PaymentTimePanalList />
                        <BankListPanal
                            CreatePanel={this.onCreatePanel(this.state.isCreateBank)}
                            EditInfo={this.EditBank()}
                            onClickHandle={this.onClickHandle()}
                            BookBankList={BookBankList} />
                        <CurrencyManage />
                        <CurrencyRateManage />
                    </React.Fragment>
                )
            }
            case PathName.UserManage: {
                return (<SellerTypeManage />)
            }
            case PathName.OtherManage: {
                return (<RefundReasonsManage />)
            }
            case PathName.DefaultCommissionManage: {
                return (<LevelManage />)
            }
            case PathName.ServiceManage: {
                return (<CloseServiceManage />)
            }
            case PathName.ReservationManage: {
                return (<ReservationTableManage />)
            }
        }

    }

    onChangeLanguage = (_language) => {
        const { page } = BookBankList;
        this.props.getAPIBankList({ page: page, num_limit: 10, language: _language });
    }

    CreateBankPanel = () => {
        return (
            <BankCard
                onChangeLanguage={this.onChangeLanguage}
                BookBankList={BookBankList}
                isEdit={this.state.isEdit}
                isView={this.state.isView}
                oldBankInfo={this.state.oldBankInfo}
                BankInfo={this.state.BankInfo}
                onClosePanel={this.onClosePanel}
            />
        )
    }

    EditBank = () => (BankInfo) => {
        this.setState({
            isCreateBank: true,
            isEdit: true,
            isView: false,
            BankInfo,
            oldBankInfo: BankInfo,
        });
    }

    onClickHandle = () => (BankInfo) => {
        this.setState({
            isCreateBank: true,
            isEdit: false,
            isView: true,
            BankInfo,
            oldBankInfo: BankInfo,
        });
    }
    // onClickPathname=(page)=>(_packageId)=>{
    //     const _packageInfo = packageInfoList.packageList.find((_package) => { return _package.packageId === _packageId });
    //     this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo }, search: '', hash: '' });
    // }

    onCreatePanel = (_isActive) => () => (this.setState({ isCreateBank: !_isActive, isEdit: false, isView: false, BankInfo: { ...init_bank } }));
    onClosePanel = () => (this.setState({ isCreateBank: false, isUpdatePackage: false }));

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(SettingActions.getBankList(_keyword));
        },
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsManage);
