import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';

export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAILURE = 'GET_MEMBER_FAILURE';

export const CREATE_MEMBER_SUCCESS = 'CREATE_MEMBER_SUCCESS';
export const CREATE_MEMBER_FAILURE = 'CREATE_MEMBER_FAILURE';

export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getMemberList ============================================================================================================================

export function getMemberList(_fileterjson = {}) {
    //console.log("[API] getMemberList :", `${CONFIG.LBhost}${CONFIG.api.getMemberList}`)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getMemberList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: {},
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getMemberList : ", response);
            //localStorage.setItem('MemberList', response.data)
            dispatch(getMemberList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getMemberList Failure", error);
            dispatch(getMemberList_Failure(error));
        })
    }
}

function getMemberList_Success(data) { return { type: GET_MEMBER_SUCCESS, payload: data }; }
function getMemberList_Failure(error) { return { type: GET_MEMBER_FAILURE, payload: error }; }

// getMemberList =========================================================================================================================


export function APICreateMember(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createMember}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APICreateMember : ", response.data);
            dispatch(APICreateMember_Success(response.data));

        }).catch((error) => {
            console.log("APICreateMember fail", error);
            dispatch(APICreateMember_Failure(error));
        })
    }
}

function APICreateMember_Success(data) { return { type: CREATE_MEMBER_SUCCESS, payload: data }; }
function APICreateMember_Failure(error) { return { type: CREATE_MEMBER_FAILURE, payload: error }; }

// getMemberList =========================================================================================================================


export function APIUpdateMember(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateMember}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            params: { "where": { id: confirmInfo.id } },
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIUpdateMember : ", response.data);
            dispatch(APIUpdateMember_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateMember fail", error);
            dispatch(APIUpdateMember_Failure(error));
        })
    }
}

function APIUpdateMember_Success(data) { return { type: UPDATE_MEMBER_SUCCESS, payload: data }; }
function APIUpdateMember_Failure(error) { return { type: UPDATE_MEMBER_FAILURE, payload: error }; }