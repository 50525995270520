import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { SetDateShort } from '../../common/SetDate.js'

import { AreaChart, Area, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { Family, Size, Colors } from '../../themes';

const styles = theme => (
    {
        card: { minWidth: 1020, maxWidth: 300, },
        chart: { height: 250, width: 730, },
    });

const cMargin = { top: 10, right: 30, left: 0, bottom: 0 };

const data = [
    { name: 'Sunday', kbi: 4000, pp: 2400, amt: 2400, pr: 1600 },
    { name: 'Monday', kbi: 3000, pp: 1398, amt: 1210, pr: 2400 },
    { name: 'Tuesday', kbi: 2000, pp: 9800, amt: 4290, pr: 6500 },
    { name: 'Wednesday', kbi: 2780, pp: 3908, amt: 6000, pr: 4300 },
    { name: 'Thursday', kbi: 18900, pp: 4800, amt: 2181, pr: 2900 },
    { name: 'Friday', kbi: 2390, pp: 3800, amt: 2500, pr: 5500 },
    { name: 'Saturday', kbi: 3490, pp: 4300, amt: 3100, pr: 7300 },
];

function PackageChartPanel(props) {

    const { classes, isLoading, statList } = props;

    // console.log('statList : ', statList)

    const newDate = statList.map((data => (data.package.length > 0) ?
        ({
            name: SetDateShort(data.date),
            kbi: data.package[0].total,
            pp: data.package[1].total,
            amt: data.package[2].total,
            pr: data.package[3].total,
        }) :
        ({
            name: SetDateShort(data.date),
            kbi: 0,
            pp: 0,
            amt: 0,
            pr: 0,
        })
    ));

    // console.warn('PackageChartPanel : ', props);

    return (
        <Card className={classes.card}>
            <CardContent>
                {(isLoading) ? <div className={classes.chart} /> : SelectChart(2, (newDate.reverse() || data))}
            </CardContent>
        </Card>
    );
}

function SelectChart(_type = 1, _data) {
    switch (_type) {
        case 1: return ChartArea(_data);
        case 2: return ChartLine(_data);
        default: return ChartArea(_data);
    }
}

const ChartArea = (data) => {
    return (
        <AreaChart width={1000} height={250} data={data} margin={cMargin} style={{ fontFamily: Family.roboto, fontSize: Size.chart.label }}>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={Colors.bigChart_1} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={Colors.bigChart_1} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={Colors.bigChart_2} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={Colors.bigChart_2} stopOpacity={0} />
                </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="kbi" stroke={Colors.bigChart_1} fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="pp" stroke={Colors.bigChart_2} fillOpacity={1} fill="url(#colorPv)" />
        </AreaChart>)
}

const ChartLine = (data) => {
    return (
        <LineChart width={990} height={250} data={data} style={{ fontFamily: Family.roboto, fontSize: Size.chart.label }}>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
            <Line type="monotone" dataKey="kbi" stroke={Colors.lineChart_1} />
            <Line type="monotone" dataKey="pp" stroke={Colors.lineChart_2} />
            <Line type="monotone" dataKey="amt" stroke={Colors.lineChart_3} />
            <Line type="monotone" dataKey="pr" stroke={Colors.lineChart_4} />
        </LineChart>
    )
}

PackageChartPanel.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(PackageChartPanel);