import moment from 'moment';
import { AheadTime } from './Number';

const HeaderPage = {
    TH: "สร้างแพ็กเกจ",
    EN: "Create Package",
    CN: "创建包",
}

const Inclusion = [
    {
        stateName: "isNoRefund", path: "/api/uploads/system/objects/Norefund.png",
        TH: { name: "ไม่คืนเงิน" },
        EN: { name: "No Refund" },
        CN: { name: "没有退款政策" },
    }, {
        stateName: 'isTicket', path: "/api/uploads/system/objects/Ticket.png",
        TH: { name: "มีเอกสารยืนยันการจอง" },
        EN: { name: "E-Ticket" },
        CN: { name: "显示手机或印刷机票" },
    }, {
        stateName: 'isLang', path: "/api/uploads/system/objects/Lang.png",
        TH: { name: "ไกด์ภาษาอังกฤษ" },
        EN: { name: "English Guide" },
        CN: { name: "英语口语指南" },
    }, {
        stateName: 'isProtect', path: "/api/uploads/system/objects/Protect.png",
        TH: { name: "ประกันอุบัติเหตุ" },
        EN: { name: "Accident Insurance" },
        CN: { name: "事故保险" },
    }, {
        stateName: 'isGroup', path: "/api/uploads/system/objects/Group.png",
        TH: { name: "เดินทางร่วมกับผู้อื่น" },
        EN: { name: "Join In Group" },
        CN: { name: "加入集团" },
    }, {
        stateName: 'isTraveler', path: "/api/uploads/system/objects/Traveler.png",
        TH: { name: "เดินทางขั้นต่ำ 1 คน" },
        EN: { name: "Minimum 1 Travelers" },
        CN: { name: "最少 1 位旅行者预订" },
    }, {
        stateName: 'isCar', path: "/api/uploads/system/objects/Car.png",
        TH: { name: "รถรับส่ง ( โรงแรม-ท่าเรือ )" },
        EN: { name: "Hotel Transfer" },
        CN: { name: "酒店接送" },
    }, {
        stateName: 'isInsant', path: "/api/uploads/system/objects/Insant.png",
        TH: { name: "ยืนยันการจองทันที" },
        EN: { name: "Instant Confirmation" },
        CN: { name: "即时确认" },
    }, {
        stateName: 'isVan', path: "/api/uploads/system/objects/Van.png",
        TH: { name: "รถรับ-ส่งส่วนตัว" },
        EN: { name: "Private Van" },
        CN: { name: "即时确认" },
    }, {
        stateName: 'isMaximum', path: "/api/uploads/system/objects/Maximum.png",
        TH: { name: "เดินทางสูงสุด 8 ท่าน / คัน" },
        EN: { name: "Maximum 8 Persons / Van" },
        CN: { name: "即时确认" },
    }, {
        stateName: 'isCalendra', path: "/api/uploads/system/objects/Calendra.png",
        TH: { name: "ออกเดินทางทุกวัน" },
        EN: { name: "Time Period : Daily" },
        CN: { name: "即时确认" },
    }, {
        stateName: 'isReverse', path: "/api/uploads/system/objects/Reverse.png",
        TH: { name: "ยกเลิกฟรี" },
        EN: { name: "Free Canclelation" },
        CN: { name: "即时确认" },
    }
]
const BodyPage = [
    [{
        title_TH: "ผู้ผลิต", title_EN: "Supplier", title_CN: "供应商",
        type: "selectId",
        stateName: "selectId",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }], [{
        title_TH: "ชื่อแพ็กเกจ", title_EN: "Package Name", title_CN: "包裹名字",
        type: "text",
        stateName: "name",
        placeholder_TH: "ชื่อแพ็กเกจ", placeholder_EN: "Insert Package Name", placeholder_CN: "插入包名称",
    }, {
        title_TH: "ประเภทแพ็กเกจ", title_EN: "Package Type", title_CN: "包装类型",
        type: "select",
        stateName: "type",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }, {
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "visible",
    }, {
        title_TH: "ให้มารับ", title_EN: "Pick Up", title_CN: "捡起",
        type: "switch",
        stateName: "isPickUp",
    }], [{
        type: 'additional'
    }], [{
        title_TH: "รายละเอียดย่อ", title_EN: "Description Shot", title_CN: "描述拍摄",
        type: "text-multiline",
        stateName: "desShort",
        require: true,
        placeholder_TH: "รายละเอียดย่อ", placeholder_EN: "Insert Description Shot", placeholder_CN: "插入描述镜头",
    }], [{
        title_TH: "รายละเอียดเต็ม", title_EN: "Description Full", title_CN: "描述拍摄",
        type: "text-multiline",
        stateName: "desFull",
        require: true,
        placeholder_TH: "รายละเอียดเต็ม", placeholder_EN: "Insert Description Full", placeholder_CN: "插入描述完整",
    }], [{
        title_TH: "จำนวนจำกัด", title_EN: "Limit", title_CN: "限制",
        type: "number",
        require: true,
        stateName: "limit",
        placeholder_TH: "จำนวน", placeholder_EN: "Insert Limit", placeholder_CN: "插入限制",
    }, {
        title_TH: "สถานที่ให้บริการ", title_EN: "Service Location", title_CN: "服务地点",
        type: "select",
        stateName: "locationServiceId",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }], [{
        title_TH: "เงื่อนไข", title_EN: "Condition", title_CN: "条件",
        type: "condition",
        stateName: "advice",
    }, {
        title_TH: "ประกอบด้วย", title_EN: "Include", title_CN: "包括",
        type: "checkbox",
        stateName: "icon",
    }], [{
        title_EN: "What you should know", title_TH: "", title_CN: "",
        type: "form-text",
        stateName: "shouldKnow",
    }], [{
        title_EN: "Include", title_TH: "", title_CN: "",
        type: "form-text",
        stateName: "service",
    }], [{
        title_EN: "", title_TH: "", title_CN: "",
        header_EN: "High Light",
        type: "text-multiline",
        stateName: "highlight",
        placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
    }], [{
        title_EN: "Tour Program Detail", title_TH: "", title_CN: "",
        type: "form-detail",
        stateName: "schedule",
        placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
    }], [{
        title_TH: "รูปภาพประกอบ", title_EN: "Cover Photo", title_CN: "封面照片",
        type: "media",
        stateName: "imageGallery",
        require: {
            TH: "ขนาดรูปภาพต้องเท่ากับ 650 x 450 พิกเซล",
            EN: "First image is a cover image. (2000*1948 pixels)",
            CN: "第一张图片是封面图片。 （650 x 450像素）"
        }
    }],
]

const TypeServiceData = [
    [{
        title_TH: "การบริการ", title_EN: "Service Type", title_CN: "转入",
        type: "select",
        stateName: "serviceType",
        require: true,
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: [
            { value: "spa", label_TH: "สปา", label_EN: "Spa", label_CN: "温泉" },
        ]
    }]
]

const TransferData = [
    [
        {
            title_TH: "ผู้โดยสาร", title_EN: "Passengers", title_CN: "乘客",
            type: "number",
            stateName: "passengers",
            require: true,
            placeholder_TH: "จำนวนผู้โดยสาร", placeholder_EN: "Insert Passengers", placeholder_CN: "插入乘客",
        }, {
            title_TH: "กระเป๋า", title_EN: "Bags", title_CN: "手袋",
            type: "number",
            stateName: "bags",
            require: true,
            placeholder_TH: "จำนวนกระเป๋า", placeholder_EN: "Insert Bags", placeholder_CN: "插入袋子",
        }, {
            title_TH: "พาหนะ", title_EN: "Conveyance", title_CN: "输送",
            type: "select",
            stateName: "conveyanceType",
            require: true,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
            options: [
                { value: "camry", label_TH: "รถยนต์ คัมรี่", label_EN: "Camry", label_CN: "凯美瑞" },
                { value: "van", label_TH: "รถตู้", label_EN: "Van", label_CN: "面包车" },
                { value: "hyundai", label_TH: "รถตู้ ฮุนได", label_EN: "Hyundai", label_CN: "现代" },
                { value: "bus", label_TH: "รถบัส", label_EN: "Bus", label_CN: "总线" },
            ]
        }], [{
            title_TH: "ประเภท Transfer", title_EN: "Transfer Type", title_CN: "转移类型",
            type: "select",
            stateName: "transferType",
            require: true,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
            options: [
                { value: "private", label_TH: "ส่วนตัว", label_EN: "Private", label_CN: "私人的" },
                { value: "join", label_TH: "ร่วมกัน", label_EN: "Join", label_CN: "加入" },
            ],
        }, {
            title_TH: "สถานที่เริ่มต้น", title_EN: "Transfer In", title_CN: "转入",
            type: "select",
            stateName: "transferIn",
            require: true,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
            options: [
                { value: "true", label_TH: "เข้า", label_EN: "Transfer In", label_CN: "转入" },
                { value: "false", label_TH: "ออก", label_EN: "Transfer Out", label_CN: "转出去" },
            ],
        }]
]

const FerriesData = [
    [
        {
            title_TH: "ผู้โดยสาร", title_EN: "Passengers", title_CN: "乘客",
            type: "number",
            stateName: "passengers",
            require: true,
            placeholder_TH: "จำนวนผู้โดยสาร", placeholder_EN: "Insert Passengers", placeholder_CN: "插入乘客",
        }, {
            title_TH: "พาหนะ", title_EN: "Conveyance", title_CN: "输送",
            type: "select",
            stateName: "conveyanceType",
            require: false,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
            options: [
                { value: "ferries", label_TH: "เรือ", label_EN: "Ferries", label_CN: "渡轮" },
            ]
        }], [{
            title_TH: "ประเภท Transfer", title_EN: "Transfer Type", title_CN: "转移类型",
            type: "select",
            stateName: "transferType",
            require: false,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
            options: [
                { value: "private", label_TH: "ส่วนตัว", label_EN: "Private", label_CN: "私人的" },
                { value: "join", label_TH: "ร่วมกัน", label_EN: "Join", label_CN: "加入" },
            ],
        }]
]

const ScheduleData = {
    title_TH: "ตารางเวลาเดินทาง", title_EN: "Schedule", title_CN: "时间表",
    structure: [
        [{
            title_TH: "เวลา", title_EN: "Time", title_CN: "时间", type: "date", stateName: "time",
            placeholder_TH: "เวลา", placeholder_EN: "Insert Time", placeholder_CN: "插入时间",
        }, {
            stateName: "topic",
            type: "select",
            des_TH: "สถานที่", des_EN: "Destination", des_CN: "目的地",
            app_TH: "สถานที่", app_EN: "Appointment", app_CN: "约定",
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }], [{
            title_TH: "รายละเอียดสถานที่", title_EN: "Program detail", title_CN: "计划细节",
            type: "text-multiline", stateName: "detail",
            placeholder_TH: "รายละเอียดสถานที่", placeholder_EN: "Insert Detail", placeholder_CN: "插入内容",
        }]
    ]
}

const TimeFerries = [
    {
        type: 'time',
        detail: [{
            title_TH: "เวลา", title_EN: "Time", title_CN: "时间", type: "date",
            stateName: "start",
            type: "startTime",
            placeholder_TH: "เวลา", placeholder_EN: "Insert Time", placeholder_CN: "插入时间",
        }]
    }
]

const SupplierData = {
    title_TH: "รายชื่อผู้ผลิต", title_EN: "Supplier list", title_CN: "供应商名单",
    placeholder_TH: "ค้นหารายชื่อผู้ผลิต", placeholder_EN: "Search Supplier list", placeholder_CN: "搜索供应商列表",
}

const ConditionData = {
    title_TH: "รายการเงื่อนไข", title_EN: "Condition list", title_CN: "条件清单",
    placeholder_TH: "ค้นหารายการเงื่อนไข", placeholder_EN: "Search Condition list", placeholder_CN: "搜索条件列表",
}
const AppointmentData = {
    title_TH: "รายการเงื่อนไข", title_EN: "Pick Up List", title_CN: "条件清单",
    placeholder_TH: "ค้นหารายการเงื่อนไข", placeholder_EN: "Search Appointment list", placeholder_CN: "搜索条件列表",
}

const BodyNewPage = [
    [
        { title_EN: "Supplier Detail", title_TH: "", title_CN: "", type: "title" },
        [{
            title_TH: "ผู้ผลิต", title_EN: "Supplier", title_CN: "供应商",
            type: "selectId",
            stateName: "selectId",
            require: true,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }]
    ],
    [
        { title_EN: "Package Detail", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "media",
            stateName: "imageGallery",
            placeholder_EN: "Add Phot or Video", placeholder_TH: "", placeholder_CN: "",
            require: {
                TH: "ต้องมีภาพมากกว่า 4 ภาพและภาพต้องไม่เกิน 650 x 450 พิกเซล",
                EN: "Must have more than 4 images and the image must not exceed 650 x 450 pixels.",
                CN: "第一张图片是封面图片。 （650 x 450像素）"
            }
        }], [{
            title_EN: "Package Name", title_TH: "", title_CN: "",
            type: "text",
            require: true,
            stateName: "name",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }], [{
            title_EN: "Package Type", title_TH: "", title_CN: "",
            type: "select",
            require: true,
            stateName: "type",
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
            options: [
                { value: "OneDayTrip", label_TH: "การเดินทางวันเดียว", label_EN: "Day Tours", label_CN: "一日游" },
                { value: "Transfer", label_TH: "การเดินทาง Transfer", label_EN: "Airport Transfer", label_CN: "转让" },
                { value: "Ferries", label_TH: "เรือข้ามฝาก", label_EN: "Ferries", label_CN: "服务" },
                { value: "Service", label_TH: "การบริการ", label_EN: "Service", label_CN: "服务" },
            ],
            optionsDayTour: [
                { value: "OneDayTrip", label_TH: "การเดินทางวันเดียว", label_EN: "Day Tours", label_CN: "一日游" },
                { value: "Service", label_TH: "การบริการ", label_EN: "Service", label_CN: "服务" },
            ],
            optionsTransfer: [
                { value: "Transfer", label_TH: "การเดินทาง Transfer", label_EN: "Airport Transfer", label_CN: "转让" },
                { value: "Ferries", label_TH: "เรือข้ามฝาก", label_EN: "Ferries", label_CN: "服务" },
            ],
            optionsFerries: [
                { value: "Ferries", label_TH: "เรือข้ามฝาก", label_EN: "Ferries", label_CN: "服务" },
            ],
        }, {
            title_EN: "Service Destination", title_TH: "", title_CN: "",
            type: "select",
            require: true,
            stateName: "locationServiceId",
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        }, {
            title_EN: "Buy Limit", title_TH: "", title_CN: "",
            type: "text-number",
            require: true,
            stateName: "limit",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }], [{
            type: 'additional'
        }], [{
            title_EN: "Duration time", title_TH: "", title_CN: "",
            type: "text-duration",
            require: true,
            stateGroup: "scheduleTime",
            stateName: "start",
            stateUnit: "unit",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
            options: [
                { value: "hour", precis: "hr.", label_EN: "Hour(s)", label_TH: "", label_CN: "" },
                { value: "minute", precis: "min.", label_EN: "Minute(s)", label_TH: "", label_CN: "" },
            ]
        }, {
            title_EN: "Ahead time", title_TH: "", title_CN: "",
            type: "text-cutOffTime",
            require: true,
            stateName: "cutOffTime",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
            options: AheadTime
        }], [{
            title_EN: "Description Shot", title_TH: "", title_CN: "",
            type: "text",
            require: true,
            stateName: "desShort",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }], [{
            title_EN: "Description Full", title_TH: "", title_CN: "",
            type: "text-multiline",
            require: true,
            stateName: "desFull",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }]
    ], [
        { title_EN: "Pick Up Detail", title_TH: "", title_CN: "", type: "title", type: "isPickUp", ferries: true },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isPickUp",
            require: false,
            label_EN: "Pick Up", label_TH: "", label_CN: ""
        }, {
            title_TH: "นัดหมาย", title_EN: "Pick Up List", title_CN: "约定",
            type: "button-list",
            stateName: "pickUpList",
            require: false,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }]
    ], [
        { title_EN: "Drop Off Detail", title_TH: "", title_CN: "", type: "title", type: "isDropOff", ferries: true },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isDropOff",
            require: false,
            label_EN: "Drop Off", label_TH: "", label_CN: ""
        }, {
            title_TH: "นัดหมาย", title_EN: "Drop Off List", title_CN: "约定",
            type: "dropOff-list",
            stateName: "dropOffList",
            require: false,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }]
    ],
    [
        { title_EN: "PickUp Zone List", title_TH: "", title_CN: "", type: "pickUp" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-zone",
            require: true,
            stateName: "pickUpZoneList",
        }]
    ],
    [
        { title_EN: "Hotel Pickup Up Information ", title_TH: "", title_CN: "", type: "pickUp" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "pickUpPolicy",
        }]
    ], [
        { title_EN: "Cancelation Policy", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isRefund",
            require: false,
            label_EN: "Refund", label_TH: "", label_CN: ""
        }, {
            title_EN: "Before Refund", title_TH: "", title_CN: "",
            type: "text-cutOffTime",
            width: '97%',
            require: false,
            stateName: "beforeRefund",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }],
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "cancelPolicy",
        }]
    ], [
        { title_EN: "What you should know", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "shouldKnow",
        }]
    ], [
        { title_EN: "Include", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "service",
        }]
    ], [
        { title_EN: "Exclusion", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "serviceExclusion",
        }]
    ], [
        { title_EN: "Tour Program Detail", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "text-multiline",
            stateName: "highlight",
            require: false,
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        }], [{
            type: 'startFerries',
            form: [{
                title_EN: "START", title_TH: "", title_CN: "",
                type: "select",
                stateName: "pickUpList",
                require: true,
                placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
            }, {
                title_EN: "END", title_TH: "", title_CN: "",
                type: "select",
                stateName: "dropOffList",
                require: true,
                placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
            }]
        }], [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-detail",
            stateName: "schedule",
            require: false,
            stateTime: "time", stateTopic: "topic", stateDetail: "detail",
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        }]
    ], [
        { title_EN: "Condition", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-checkbox",
            stateName: "advice",
            require: true,
            items: Inclusion
        }]
    ], [
        { title_EN: "Travel Time", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "Service Date", title_TH: "", title_CN: "",
            start_EN: "Start Date", start_TH: "", start_CN: "",
            end_EN: "End Date", end_TH: "", end_CN: "",
            stateStart: 'startDate', stateEnd: 'endDate',
            type: "form-period",
            stateName: "periodInfo",
            items: [
                { value: 'All', TH: { name: "ทั้งหมด" }, EN: { name: "All" }, CN: { name: "所有" } },
                { value: 'Mon', TH: { name: "จันทร์" }, EN: { name: "Mon" }, CN: { name: "周一" } },
                { value: 'Tue', TH: { name: "อังคาร" }, EN: { name: "Tue" }, CN: { name: "周二" } },
                { value: 'Wed', TH: { name: "พุธ" }, EN: { name: "Wed" }, CN: { name: "周三" } },
                { value: 'Thu', TH: { name: "พฤหัส" }, EN: { name: "Thu" }, CN: { name: "周四" } },
                { value: 'Fri', TH: { name: "ศุกร์" }, EN: { name: "Fri" }, CN: { name: "周五" } },
                { value: 'Sat', TH: { name: "เสาร์" }, EN: { name: "Sat" }, CN: { name: "周六" } },
                { value: 'Sun', TH: { name: "อาทิตย์" }, EN: { name: "Sun" }, CN: { name: "周日" } },
            ],
        }],
    ], [
        { title_EN: "Package Traveller  ", title_TH: "", title_CN: "", type: "title", visible: true, guide: '(ต้องการใส่ข้อมูลผู้เดินทางทุกคนหรือไม่)' },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isAllTraveller",
            require: false,
            label_EN: "Traveller", label_TH: "", label_CN: ""
        }]
    ], [
        { title_EN: "Package Active ", title_TH: "", title_CN: "", type: "title", visible: true },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "visible",
            require: false,
            label_EN: "Active", label_TH: "", label_CN: ""
        }]
    ],
]
const BodyEditPage = [
    [
        { title_EN: "Supplier Detail", title_TH: "", title_CN: "", type: "title" },
        [{
            title_TH: "ผู้ผลิต", title_EN: "Supplier", title_CN: "供应商",
            type: "selectId",
            stateName: "selectId",
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }]
    ],
    [
        { title_EN: "Package Detail", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "media",
            stateName: "imageGallery",
            placeholder_EN: "Add Phot or Video", placeholder_TH: "", placeholder_CN: "",
            require: {
                TH: "ต้องมีภาพมากกว่า 4 ภาพและภาพต้องไม่เกิน 650 x 450 พิกเซล",
                EN: "Must have more than 4 images and the image must not exceed 650 x 450 pixels.",
                CN: "第一张图片是封面图片。 （650 x 450像素）"
            }
        }],
        [{
            title_EN: "Package Name", title_TH: "", title_CN: "",
            type: "text",
            require: true,
            stateName: "name",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }], [{
            title_EN: "Package Type", title_TH: "", title_CN: "",
            type: "select",
            require: true,
            stateName: "type",
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
            options: [
                { value: "OneDayTrip", label_TH: "การเดินทางวันเดียว", label_EN: "Day Tours", label_CN: "一日游" },
                { value: "Transfer", label_TH: "การเดินทาง Transfer", label_EN: "Airport Transfer", label_CN: "转让" },
                { value: "Ferries", label_TH: "เรือข้ามฝาก", label_EN: "Ferries", label_CN: "服务" },
                { value: "Service", label_TH: "การบริการ", label_EN: "Service", label_CN: "服务" },
            ],
            optionsDayTour: [
                { value: "OneDayTrip", label_TH: "การเดินทางวันเดียว", label_EN: "Day Tours", label_CN: "一日游" },
                { value: "Service", label_TH: "การบริการ", label_EN: "Service", label_CN: "服务" },
            ],
            optionsTransfer: [
                { value: "Transfer", label_TH: "การเดินทาง Transfer", label_EN: "Airport Transfer", label_CN: "转让" },
                { value: "Ferries", label_TH: "เรือข้ามฝาก", label_EN: "Ferries", label_CN: "服务" },
            ],
            optionsFerries: [
                { value: "Ferries", label_TH: "เรือข้ามฝาก", label_EN: "Ferries", label_CN: "服务" },
            ],
        }, {
            title_EN: "Service Destination", title_TH: "", title_CN: "",
            type: "select",
            require: true,
            stateName: "locationServiceId",
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        }, {
            title_EN: "Buy Limit", title_TH: "", title_CN: "",
            type: "text-number",
            require: true,
            stateName: "limit",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        },], [{
            type: 'additional'
        }], [{
            title_EN: "Duration time", title_TH: "", title_CN: "",
            type: "text-duration",
            require: true,
            stateGroup: "scheduleTime",
            stateName: "start",
            stateUnit: "unit",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
            options: [
                { value: "", precis: "-", label_EN: "-", label_TH: "", label_CN: "" },
                { value: "hour", precis: "hr.", label_EN: "Hour(s)", label_TH: "", label_CN: "" },
                { value: "minute", precis: "min.", label_EN: "Minute(s)", label_TH: "", label_CN: "" },
            ]
        }, {
            title_EN: "Ahead time", title_TH: "", title_CN: "",
            type: "text-cutOffTime",
            require: true,
            stateName: "cutOffTime",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
            options: AheadTime
        }], [{
            title_EN: "Description Shot", title_TH: "", title_CN: "",
            type: "text",
            require: true,
            stateName: "desShort",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }], [{
            title_EN: "Description Full", title_TH: "", title_CN: "",
            type: "text-multiline",
            require: true,
            stateName: "desFull",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }]
    ], [
        { title_EN: "Pick Up Detail", title_TH: "", title_CN: "", type: "isPickUp", ferries: true },

        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isPickUp",
            require: false,
            label_EN: "Pick Up", label_TH: "", label_CN: ""
        }, {
            title_TH: "นัดหมาย", title_EN: "Pick Up List", title_CN: "约定",
            type: "button-list",
            stateName: "pickUpList",
            require: false,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }]
    ], [
        { title_EN: "Drop Off Detail", title_TH: "", title_CN: "", type: "isDropOff", ferries: true },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isDropOff",
            require: false,
            label_EN: "Drop Off", label_TH: "", label_CN: ""
        }, {
            title_TH: "นัดหมาย", title_EN: "Drop Off List", title_CN: "约定",
            type: "dropOff-list",
            stateName: "dropOffList",
            require: false,
            placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        }]
    ], [
        { title_EN: "PickUp Zone List", title_TH: "", title_CN: "", type: "pickUp" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-zone",
            require: false,
            stateName: "pickUpZoneList",
        }]
    ], [
        { title_EN: "Hotel Pickup Up Information ", title_TH: "", title_CN: "", type: "pickUp" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "pickUpPolicy",
        }]
    ], [
        { title_EN: "Cancelation Policy", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isRefund",
            require: false,
            label_EN: "Refund", label_TH: "", label_CN: ""
        }, {
            title_EN: "Before Refund", title_TH: "", title_CN: "",
            type: "text-cutOffTime",
            width: '97%',
            require: false,
            stateName: "beforeRefund",
            placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
        }],
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "cancelPolicy",
        }]
    ], [
        { title_EN: "What you should know", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "shouldKnow",
        }]
    ], [
        { title_EN: "Include", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "service",
        }]
    ], [
        { title_EN: "Exclusion", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-text",
            require: false,
            stateName: "serviceExclusion",
        }]
    ], [
        { title_EN: "Tour Program Detail", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "text-multiline",
            stateName: "highlight",
            require: false,
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        }], [{
            type: 'startFerries',
            form: [{
                title_EN: "START", title_TH: "", title_CN: "",
                type: "select",
                stateName: "pickUpList",
                require: true,
                placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
            }, {
                title_EN: "END", title_TH: "", title_CN: "",
                type: "select",
                stateName: "dropOffList",
                require: true,
                placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
            }]
        }], [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-detail",
            stateName: "schedule",
            require: false,
            placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        }]
    ], [
        { title_EN: "Condition", title_TH: "", title_CN: "", type: "condition" },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "form-checkbox",
            stateName: "advice",
            isEdit: true,
            items: Inclusion
        }]
    ], [
        { title_EN: "Travel Time", title_TH: "", title_CN: "", type: "title" },
        [{
            title_EN: "Service Date", title_TH: "", title_CN: "",
            start_EN: "Start Date", start_TH: "", start_CN: "",
            end_EN: "End Date", end_TH: "", end_CN: "",
            stateStart: 'startDate', stateEnd: 'endDate',
            type: "form-period",
            stateName: "periodInfo",
            items: [
                { value: 'All', TH: { name: "ทั้งหมด" }, EN: { name: "All" }, CN: { name: "所有" } },
                { value: 'Mon', TH: { name: "จันทร์" }, EN: { name: "Mon" }, CN: { name: "周一" } },
                { value: 'Tue', TH: { name: "อังคาร" }, EN: { name: "Tue" }, CN: { name: "周二" } },
                { value: 'Wed', TH: { name: "พุธ" }, EN: { name: "Wed" }, CN: { name: "周三" } },
                { value: 'Thu', TH: { name: "พฤหัส" }, EN: { name: "Thu" }, CN: { name: "周四" } },
                { value: 'Fri', TH: { name: "ศุกร์" }, EN: { name: "Fri" }, CN: { name: "周五" } },
                { value: 'Sat', TH: { name: "เสาร์" }, EN: { name: "Sat" }, CN: { name: "周六" } },
                { value: 'Sun', TH: { name: "อาทิตย์" }, EN: { name: "Sun" }, CN: { name: "周日" } },
            ],
            periodPrice: [
                [
                    { title_EN: "Net Price (Adult)", title_TH: "", title_CN: "", type: "title", stateGroup: 'netPrice', stateName: "adult" },
                    { title_EN: "Margin (minimum 30%)", title_TH: "", title_CN: "", type: "title", stateName: "marginAdult" },
                    { title_EN: "Sale Price", title_TH: "", title_CN: "", type: "title", stateGroup: 'price', stateName: "adult" }
                ], [
                    { title_EN: "Net Price (Children)", title_TH: "", title_CN: "", type: "title", stateGroup: 'netPrice', stateName: "kid" },
                    { title_EN: "Margin (minimum 30%)", title_TH: "", title_CN: "", type: "title", stateName: "marginKid" },
                    { title_EN: "Sale Price", title_TH: "", title_CN: "", type: "title", stateGroup: 'price', stateName: "kid" }
                ],
            ]
        }],
    ], [
        { title_EN: "Package Traveller ", title_TH: "", title_CN: "", type: "title", visible: true, guide: '(ต้องการใส่ข้อมูลผู้เดินทางทุกคนหรือไม่)' },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "isAllTraveller",
            require: false,
            label_EN: "Traveller", label_TH: "", label_CN: ""
        }]
    ], [
        { title_EN: "Package Active", title_TH: "", title_CN: "", type: "title", visible: true },
        [{
            title_EN: "", title_TH: "", title_CN: "",
            type: "switch",
            stateName: "visible",
            require: false,
            label_EN: "Active", label_TH: "", label_CN: ""
        }]
    ],

]

const PeriodData = {
    title_EN: "Travel Time", title_TH: "", title_CN: "",
    structure: [
        [{
            titleStart: "Start Date",
            type: 'date-picker',
            require: true,
            titleEnd: "End Date",
            stateStart: 'startDate', stateEnd: 'endDate',
        }], [
            { title_EN: "Net Price (Adult)", title_TH: "", title_CN: "", type: "number", stateGroup: 'netPrice', stateName: "adult", require: true, },
            { title_EN: "Margin (minimum 30%)", title_TH: "", title_CN: "", type: "number", stateName: "marginAdult", require: true, },
            { title_EN: "Sale Price", title_TH: "", title_CN: "", type: "number", stateGroup: 'price', stateName: "adult" }
        ], [
            { title_EN: "Net Price (Children)", title_TH: "", title_CN: "", type: "number", stateGroup: 'netPrice', stateName: "kid", require: true },
            { title_EN: "Margin (minimum 30%)", title_TH: "", title_CN: "", type: "number", stateName: "marginKid", disible: true, require: true, },
            { title_EN: "Sale Price", title_TH: "", title_CN: "", type: "number", stateGroup: 'price', stateName: "kid" }

        ], [{
            title_EN: "Service Data", title_TH: "", title_CN: "",
            type: "checkbox", stateName: "detail",
            items: [
                { value: 'All', TH: { name: "ทั้งหมด" }, EN: { name: "All" }, CN: { name: "" } },
                { value: 'Mon', TH: { name: "จันทร์" }, EN: { name: "Mon" }, CN: { name: "" } },
                { value: 'Tue', TH: { name: "อังคาร" }, EN: { name: "Tue" }, CN: { name: "" } },
                { value: 'Wed', TH: { name: "พุธ" }, EN: { name: "Wed" }, CN: { name: "" } },
                { value: 'Thu', TH: { name: "พฤหัส" }, EN: { name: "Thu" }, CN: { name: "" } },
                { value: 'Fri', TH: { name: "ศุกร์" }, EN: { name: "Fri" }, CN: { name: "" } },
                { value: 'Sat', TH: { name: "เสาร์" }, EN: { name: "Sat" }, CN: { name: "" } },
                { value: 'Sun', TH: { name: "อาทิตย์" }, EN: { name: "Sun" }, CN: { name: "" } },
            ],
        }], [{
            title_EN: "Close Service", title_TH: "", title_CN: "",
            type: "close_service", stateName: "isCloseListService",
            items: [
                { value: '', TH: { name: "" }, EN: { name: "Close Service" }, CN: { name: "" } },
            ],
        }]
    ]
}

const StructureCondition = [{
    isCalendra: false,
    isCar: false,
    isGroup: false,
    isInsant: false,
    isLang: false,
    isMaximum: false,
    isNoRefund: false,
    isProtect: false,
    isReverse: false,
    isTicket: false,
    isTraveler: false,
    isVan: false,
}]
const StructurePeriod = [{
    endDate: moment().format('YYYY-MM-DD'),
    margin: 30,
    netPrice: { adult: "", kid: "", infant: 0, currency: "THB" },
    price: { adult: "", kid: "", infant: 0, currency: "THB" },
    serviceDate: [],
    startDate: moment().format('YYYY-MM-DD'),
}]
const StructureSchedule = [{
    appointmentId: "",
    destinationId: "",
    detail: [],
    time: "",
    topic: "",
}]

export default {
    HeaderPage,
    BodyPage,
    TransferData,
    TypeServiceData,
    ScheduleData,
    TimeFerries,
    Inclusion,
    SupplierData,
    ConditionData,
    BodyNewPage,
    AppointmentData,
    PeriodData,
    FerriesData,
    BodyEditPage,
    StructureCondition,
    StructurePeriod,
    StructureSchedule,
}

