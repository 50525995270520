import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as BookingAction from '../../actions/Actions_BookingManager.js';

import Button from '../../constant/Button';
import CountryPanel from './CountryPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import PromoteAffiliatePanel from './PromoteAffiliatePanel.jsx';
import PackageListPanel from '../../componets/PackageListPanel.jsx';
import ContentPanels from '../../componets/ContentManage/ContentPanel.jsx';
import PackageAffiliatePanel from '../../containers/Packages/PackageAffiliatePanel.jsx';

import { PathName, Roles } from '../../constant';

import { isMobile, isFirefox, isSafari } from '../../common/DeviceCheck.js';
import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import { Family, Fonts, Colors, Icons } from '../../themes';
const { BorderColorIcon, PasswordIcon, } = Icons;


const pageWording = {
    topics: 'Promote Manager',
    subheading: ['You get the best rates', ' only at TravisGo'],
};

let _role;
let _userInfo;
let _bookingList = [];
let _packgaeList;
let waitingTime = true;

class SellerPromote extends Component {

    state = { isLoading: false, isAffiliate: false, isPromote: false, orderBy: 'desc', width: window.innerWidth, Info: undefined }

    constructor(props) {

        super(props);

        const _info = JSON.parse(localStorage.getItem('UserInfo'));
        _role = (!!_info) ? (_info.userType || '') : '';

        switch (_role) {
            case Roles.Seller: {

                const { state: locationState } = this.props.location;

                if (locationState === undefined) {
                    localStorage.clear();
                    this.props.history.goBack();
                }

                const { sellerInfo } = locationState;

                _userInfo = sellerInfo;
                // this.props.getAPIPackageList({ limit: 5, visible: true });

            } break;
            case Roles.Admin:
            default:
            // this.props.getAPIBookingLists();
        }

        // console.log(`SellerPromote [constructor] : `, this.props.location.state);
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        _role = undefined;
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager } = nextProps;

        if (!!PackageManager) {

            const { data, actions, error } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {

                        _packgaeList = data || [];
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {

                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        if (Boolean(_role)) {

            const _info = JSON.parse(localStorage.getItem('UserInfo'));
            _role = (!!_info) ? (_info.userType || '') : '';

        }

        console.log('SellerPromote')

        const { classes } = this.props;
        const { isLoading, isAffiliate, isPromote, orderBy, value = 0 } = this.state;
        const _isMobile = isMobile();

        return (
            <ContentPanels isLoading={isLoading} OnClose={this.onClosePanel} isMobile={_isMobile} >
                {(isAffiliate) && <DialogAction Active={isAffiliate} OnClose={this.onClosePanel} AcrionCom={this.PackageAffiliatePanel(_isMobile)} />}
                {(isPromote) && <DialogAction Active={isPromote} OnClose={this.onClosePanel} AcrionCom={this.PromoteAffiliatePanel(_isMobile)} />}
                <div style={{ width: '100%', flexGrow: 1, margin: '10px 0px', maxWidth: 1024 }}>
                    <AppBar position="static" style={{ backgroundColor: Colors.themeDark }}>
                        <Tabs classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }} value={value} onChange={this.handleChange}>
                            <Tab classes={{ root: classes.tabRoot }} label="Git" />
                            <Tab classes={{ root: classes.tabRoot }} label="List" />
                        </Tabs>
                    </AppBar>
                    {/* <Button.Icons themeDark icon={<BorderColorIcon />} tooltip={'Git'} onClick={() => { }} />
                    <Button.Icons themeDark icon={<PasswordIcon />} tooltip={'List'} onClick={() => { }} /> */}
                    {this.DisplayPanelWithStyle(value, _isMobile)}
                </div>
            </ContentPanels>);
    }

    DisplayPanelWithStyle(value, _isMobile) {

        const { isLoading, } = this.state;
        const { visible } = _userInfo;

        switch (value) {
            case 0:
                {
                    return <CountryPanel
                        isVisible={visible}
                        onClickHandle={this.onClickHandle()}
                        onClickCardHandle={this.onClickCardHandle()}
                        isMobile={_isMobile} />
                }
            case 1:
                {
                    if (_packgaeList === undefined && !isLoading) {
                        this.props.getAPIPackageList({ limit: 25, visible: true });
                        this.setState({ isLoading: true });
                    }

                    return <PackageListPanel
                        packageInfoList={visible ? _packgaeList : []}
                        onClickHandle={this.onClickHandle()}
                        isMobile={_isMobile} />
                }
        }
    }

    BookingPanel(_isMobile) {

        switch (_role) {
            case Roles.Seller: { }
            case Roles.Admin:
            default: { }
        }
    }

    PackageAffiliatePanel = (isMobile) => {

        const { sellerInfo } = this.props.location.state; //!!!! check
        const { visible, sellerId } = sellerInfo;
        const { packageId } = this.state;

        const _packageInfo = _packgaeList.packageList.find((_item) => (_item.packageId === packageId));

        // console.log('PackageAffiliatePanel [ PackgaeList ] : ', _packageInfo);
        // console.log('PackageAffiliatePanel : ', sellerInfo);

        return <PackageAffiliatePanel packageInfo={_packageInfo} affiliateID={sellerId} isMobile={isMobile} isVisible={visible} OnClose={() => { this.onCloseDialog() }} />
    }

    PromoteAffiliatePanel = (isMobile) => {

        const { sellerInfo } = this.props.location.state;
        const { visible, sellerId } = sellerInfo;
        const { Info } = this.state;

        //  console.log('PromoteAffiliatePanel : ', sellerInfo);

        return <PromoteAffiliatePanel Info={Info} affiliateID={sellerId} isVisible={visible} isMobile={isMobile} OnClose={() => { this.onCloseDialog() }} />
    }

    // ====================================================================================================================================================

    onClickHandle = () => (packageId) => { this.setState({ isAffiliate: true, packageId: packageId }) }

    onClickCardHandle = () => (Info) => { this.setState({ isPromote: true, Info: Info }) }

    onClosePanel = () => (this.setState({ isLoading: false, isAffiliate: false }));

    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, isAffiliate: false, isPromote: false, msgError: undefined, dialogType: undefined }));

    CallBookingDetail(_id) {
        console.log('CallBookingDetail : ', _id);

        this.props.getAPIBookingDetails(_id);
        this.props.history.push({ pathname: PathName.BookingDetail, state: { bookingId: _id }, search: '', hash: '' });
    }

    handleChange = (event, value) => {

        switch (value) {
            case 1:
                {

                } break;
            case 2:
                {
                    this.props.getAPIPackageList({ limit: 25, visible: true });
                } break;
        }

        this.setState({ value });
    };

    updateDimensions = () => {

        const { width } = this.state;
        const _with = window.innerWidth;

        if ((width !== _with)) {
            this.setState({ width: _with });
        }
    }

    // ====================================================================================================================================================
}

// =====================================================================================================================================================

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tabsRoot: {
        backgroundColor: Colors.themeDark,
        borderBottom: `${'1px solid' + Colors.border}`,
    },
    tabsIndicator: {
        backgroundColor: Colors.theme,
        height: 3,
    },
    tabRoot: {
        textTransform: 'initial',
        // minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing.unit * 4,
        color: Colors.white,
        fontFamily: Family.roboto,
        '&:hover': {
            color: Colors.white,
            opacity: 1,
        },
        '&$tabSelected': {
            color: Colors.white,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: Colors.white,
        },
    },

});

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingLists: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingAction.getAPIBookingDetail(_id));
        },
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SellerPromote));

// ====================================================================================================================================================

const StepCotianer = styled.div`    
text-align: left;
padding: 10px 0px 20px 0px;`;