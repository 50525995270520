export const ErrorCode = {
    "400": {
        "TH": "เซิร์ฟเวอร์ไม่สามารถดำเนินการตามคำขอได้",
        "EN": "Bad Request.",
        "CN": "你不帅!"
    },
    "401": {
        "TH": "ไม่พบข้อมูลผู้ใข้งาน",
        "EN": "User not found.",
        "CN": "你不帅!"
    },
    "403": {
        "TH": "ผู้ใช้นี้ยังไม่ได้ทำการยืนยันตัวตน",
        "EN": "User does not active",
        "CN": "你不漂亮!"
    },
    "419": {
        "TH": "บัญชีผู้ใข้งาน หรือ รหัสผ่าน ไม่ถูกต้อง",
        "EN": "Invalid username or password",
        "CN": "你不漂亮!"
    },
    "420": {
        "TH": "รหัสผ่าน ไม่ถูกต้อง",
        "EN": "Invalid password",
        "CN": "你不漂亮!"
    },
    "425": {
        "TH": "อีเมล์นี้ถูกใช้แล้ว",
        "EN": "Email already used",
        "CN": "你不漂亮!"
    },
    "498": {
        "TH": "Token ไม่ถูกต้อง",
        "EN": "Invalid token",
        "CN": "你不漂亮!"
    },
    "499": {
        "TH": "ต้องการ Token",
        "EN": "Token required.",
        "CN": "你不漂亮!"
    },
    "512": {
        "TH": "ไม่สามารถบันทึกข้อมูลได้",
        "EN": "Can not save data to Database.",
        "CN": "你不漂亮!"
    },
    "513": {
        "TH": "ไม่สามารถขอข้อมูลได้",
        "EN": "Can not get data.",
        "CN": "你不漂亮!"
    },
    "514": {
        "TH": "ไม่สามารถอัพเดท token ได้",
        "EN": "Can not update token.",
        "CN": "你不漂亮!"
    },
    "515": {
        "TH": "ไม่สามารถส่งเมล์ เพื่อทำการเปิดใช้งานได้",
        "EN": "Can not send email for active user.",
        "CN": "你不漂亮!"
    },
    "516": {
        "TH": "ไม่พบข้อมูล",
        "EN": "Data not found.",
        "CN": "你不漂亮!"
    },
    "517": {
        "TH": "ไม่สามารถส่งอีเมล เพื่อทำการจองแพ็กเกจได้",
        "EN": "Can not send email for booking.",
        "CN": "你不漂亮!"
    },
    "518": {
        "TH": "ไม่สามารถสร้างไฟล์ pdf ของการจองแพ็กเกจได้",
        "EN": "Can create file booking's pdf.",
        "CN": "你不漂亮!"
    },
    "519": {
        "TH": "ไม่สามารถบันทึกข้อมูลได้ เนื่องจากมีข้อมูลเดิมอยู่แล้ว",
        "EN": "Can not save data to Database because already have data.",
        "CN": "你不漂亮!"
    },
    "528": {
        "TH": "ไม่สามารถส่งอีเมล์ เพื่อทำการรีเซ็ทรหัสผ่านได้",
        "EN": "Can not send email for reset password.",
        "CN": "你不漂亮!"
    },
    "610": {
        "TH": "",
        "EN": "Authentication failed",
        "CN": ""
    },
    "611": {
        "TH": "",
        "EN": "Type is currently not supported",
        "CN": ""
    },
    "612": {
        "TH": "",
        "EN": "Charge expired",
        "CN": ""
    },
    "613": {
        "TH": "",
        "EN": "Charge is not authorized",
        "CN": ""
    },
    "614": {
        "TH": "",
        "EN": "Void failed",
        "CN": ""
    },
    "615": {
        "TH": "",
        "EN": "Amount must be an integer",
        "CN": ""
    },
    "616": {
        "TH": "",
        "EN": "Brand is not included in the list",
        "CN": ""
    },
    "617": {
        "TH": "",
        "EN": "Number is invalid and brand not supported (unknown)",
        "CN": ""
    },
    "618": {
        "TH": "",
        "EN": "Invalid card token",
        "CN": ""
    },
    "619": {
        "TH": "",
        "EN": "Currency is currently not supported and amount is not a number",
        "CN": ""
    },
    "620": {
        "TH": "",
        "EN": "Invalid filters",
        "CN": ""
    },
    "621": {
        "TH": "",
        "EN": "Currency is not currently supported",
        "CN": ""
    },
    "622": {
        "TH": "",
        "EN": "Invalid page",
        "CN": ""
    },
    "623": {
        "TH": "",
        "EN": "Invalid scope",
        "CN": ""
    },
    "624": {
        "TH": "",
        "EN": "Request contains no card parameters",
        "CN": ""
    },
    "625": {
        "TH": "",
        "EN": "Customer cust_test_000000000000 was not found",
        "CN": ""
    },
    "626": {
        "TH": "",
        "EN": "Token was already used",
        "CN": ""
    },
    "627": {
        "TH": "",
        "EN": "The security code is invalid",
        "CN": ""
    },
    "628": {
        "TH": "",
        "EN": "Payment rejected",
        "CN": ""
    },
    "629": {
        "TH": "",
        "EN": "Insufficient fund",
        "CN": ""
    },
    "630": {
        "TH": "",
        "EN": "Stolen or lost card",
        "CN": ""
    },
    "631": {
        "TH": "",
        "EN": "Failed processing",
        "CN": ""
    },
    "632": {
        "TH": "",
        "EN": "Invalid account number",
        "CN": ""
    }
}