export const init_category = {
    "TH": {
        "description": "",
        "nameCategory": "",
    },
    "EN": {
        "description": "",
        "nameCategory": "",
    },
    "CN": {
        "description": "",
        "nameCategory": "",
    },
    "packageList": [],
    "order": '',
    "visible": false
}