import React, { Component } from 'react';
import { path, equals, keys, omit, clone } from 'ramda';

import { connect } from 'react-redux';
import FormTypes from '../common/FormTypes';
import validate from "validate.js";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import IconButton from '@material-ui/core/IconButton';

import * as AuthenActions from '../actions/Actions_AuthenManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import DialogPanel from '../componets/DialogPanel.jsx';
import Language from '../constant/Language';
import * as validator from '../constant/Validator/ChangePasseord';
import ButtonText from '../constant/Button/ButtonText';
import Button from '../constant/Button';
import { Family, Colors } from '../themes';

let waitingTime = true;
const init_password = { oldPassword: '', newPassword: '', confirmPassword: '' };
const Password = [
  { fields: 'Old Password', stateName: 'oldPassword', placeholder: 'Insert Old Password', require: true, helperText: 'Old password can not be blank' },
  { fields: 'New Password', stateName: 'newPassword', placeholder: 'Insert New Password', require: true, helperText: 'New Password must be at least 8 characters.' },
  { fields: 'Confirm Password', stateName: 'confirmPassword', placeholder: 'Insert Confirm Password', require: true, helperText: 'Confirm password is not equal to new password' },
];

class ChangePasswordCard extends Component {
  constructor(props) {
    let error = null;
    super(props)
    this.state = {
      passwordInfo: { ...init_password },
      isBtnActive: false,
      isLoading: false, msgError: undefined, dialogType: undefined,
    }
    // this.props.getAPIChangePasswordLB();
  }

  componentWillReceiveProps(nextProps) {
    const { AuthenManager } = nextProps;
    if (!!AuthenManager) {
      const { actions, data } = AuthenManager.AUTHEN_REDUCER;
      switch (actions) {
        case AuthenActions.CHANGE_PASSWORD_LB_SUCCESS:
          {
            SetStateWithObject(this, waitingTime, { msgError: 'Change Password Success.', dialogType: 'success', });
            // localStorage.setItem('UserInfo', JSON.stringify(data));
            // this.props.history.push({ pathname: PathName.DashBoardPage, state: {} })
            // SetStateWithTime(this, waitingTime);
          } break;
        case AuthenActions.CHANGE_PASSWORD_LB_FAILURE:
          {
            const _msg = data.response.data.error.message || actions;
            SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error' });
          } break;
        default: { }
      }
    }
  }
  render() {
    const { classes } = this.props;
    const { passwordInfo, isBtnActive, isLoading } = this.state;
    return (
      <React.Fragment >
        {(isLoading) && <DialogPanel Active={isLoading} MSG={this.state.msgError} Type={this.state.dialogType} OnClose={() => this.onCloseDialog()} />}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography className={classes.header} style={{ fontSize: '20px' }}> {'Change Password'} </Typography>
        </div>
        <div style={{ padding: '10px 0px' }}>
          {Password.map((each, index) => {
            const isError = !!path([each.stateName], this.error);
            const _Item = each.stateName;
            const ItemOpen = this.state[_Item];
            return (
              <div key={index} style={{ marginBottom: '5px', display: 'flex' }}>
                <FormTypes.TextFormat
                  type={ItemOpen ? 'text' : 'password'}
                  error={isError}
                  helperText={path([each.stateName], this.error)}
                  Require={each.require}
                  TitleText={each.fields}
                  placeholder={each.placeholder}
                  value={path([each.stateName], passwordInfo) || ''}
                  onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={() => this.handleClickShowPassword(_Item)}
                    onMouseDown={this.handleMouseDownPassword}>
                    {ItemOpen ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </div>
              </div>
            )
          })}
        </div>
        <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button.Themes
            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
            onClick={() => this.onReset()} />
          <Button.Themes
            TitleText={ButtonText.BUTTON_SAVE}
            disabled={!isBtnActive}
            onClick={() => this.onSave()} />
        </CardActions>
      </React.Fragment>

    )
  }
  handleMouseDownPassword = event => { event.preventDefault(); };
  handleClickShowPassword = (fieldName) => { this.setState({ [fieldName]: !this.state[fieldName] }); };

  onChangeState = (fieldName, state) => {
    let passwordInfo = { ...this.state.passwordInfo }
    passwordInfo = { ...passwordInfo, [fieldName]: state }

    this.setState({ passwordInfo, isBtnActive: true }, () => { this.validate({ [fieldName]: state }) })
  }

  validate = (value) => {
    let result = true;
    const fieldName = keys(value)[0];
    if (!!validator.constraints[fieldName]) {
      const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
      const error = this.error || {};
      if (!!validateResult) {
        result = false;
        this.error = {
          ...error,
          ...validateResult
        };
        this.forceUpdate();
      } else {
        this.error = omit([fieldName], error);
        this.forceUpdate();
      }
    }
    return result;
  }
  validateAll() {
    let success = true;
    validator.input.forEach(each => {
      const validateResult = this.validate({
        [each.fieldName]: this.state.passwordInfo[each.fieldName],
      });;
      success = success && validateResult;
    });
    return success;
  }

  onSave = () => {
    const { passwordInfo } = this.state;
    if (this.validateAll()) {
      if (equals(passwordInfo.newPassword, passwordInfo.confirmPassword)) {
        delete passwordInfo.confirmPassword
        this.setState({ isLoading: true, isBtnActive: false }, () => this.props.getAPIChangePasswordLB(passwordInfo));
      }
      else {
        this.setState({ isLoading: true, msgError: 'Confirm password is not equal to New password', dialogType: 'error', isBtnActive: false }, () => this.error)
      }
    }
    else {
      this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
    }
  }

  onReset = () => { this.setState({ passwordInfo: { ...init_password }, isBtnActive: false }, () => this.props.onClosePanel()) }
  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));


};
const styles = theme => (
  {
    header: { margin: '10px 0px 0px 0px', fontSize: '18px', display: 'flex', alignItems: 'center' },
  }
);
ChangePasswordCard.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIChangePasswordLB: (_keyword) => {
      dispatch(AuthenActions.ChangePasswordLB(_keyword));
    },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ChangePasswordCard))