import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_CONDITIONS_SUCCESS = 'GET_CONDITIONS_SUCCESS';
export const GET_CONDITIONS_FAILURE = 'GET_CONDITIONS_FAILURE';

export const CREATE_CONDITIONS_SUCCESS = 'CREATE_CONDITIONS_SUCCESS';
export const CREATE_CONDITIONS_FAILURE = 'CREATE_CONDITIONS_FAILURE';

export const UPDATE_CONDITIONS_SUCCESS = 'UPDATE_CONDITIONS_SUCCESS';
export const UPDATE_CONDITIONS_FAILURE = 'UPDATE_CONDITIONS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getConditionsList =====================================================================================================================

export function getConditionsList(_keyword = '', _fileterjson = {}) {

    //console.log("[API] getConditionsList :", `${CONFIG.LBhost}${CONFIG.api.getConditionsList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getConditionsList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getConditionsList : ", response);
            localStorage.setItem('ConditionsList', response.data)
            dispatch(getConditionsList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getConditionsList Failure", error);
            dispatch(getConditionsList_Failure(error));
        })
    }
}

function getConditionsList_Success(data) {
    return { type: GET_CONDITIONS_SUCCESS, payload: data };
}

function getConditionsList_Failure(error) {
    return { type: GET_CONDITIONS_FAILURE, payload: error };
}

// getConditionsList ========================================================================================================================


export function APICreateConditions(confirmInfo) {

    console.log('APICreateConditions : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createConditions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateConditions : ", response.data);
            dispatch(APICreateConditions_Success(response.data));

        }).catch((error) => {
            console.log("APICreateConditions fail", error);
            dispatch(APICreateConditions_Failure(error));
        })
    }
}

function APICreateConditions_Success(data) {
    return { type: CREATE_CONDITIONS_SUCCESS, payload: data };
}

function APICreateConditions_Failure(error) {
    return { type: CREATE_CONDITIONS_FAILURE, payload: error };
}

// updateConditionsList =========================================================================================================================

export function APIUpdateConditions(confirmInfo) {
    console.log('APIUpdateConditions : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateConditions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { conditionId: confirmInfo.conditionId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateConditions : ", response.data);
            dispatch(APIUpdateConditions_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateConditions fail", error);
            dispatch(APIUpdateConditions_Failure(error));
        })
    }
}

function APIUpdateConditions_Success(data) {
    return { type: UPDATE_CONDITIONS_SUCCESS, payload: data };
}

function APIUpdateConditions_Failure(error) {
    return { type: UPDATE_CONDITIONS_FAILURE, payload: error };
}