
import * as ProductsActions from '../actions/Actions_ProductsManager.js';

const INITIAL_STATE = { PRODUCTS_REDUCER: { actions: ProductsActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: ProductsActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case ProductsActions.INITIAL_STATE:
            {
                return state;
            }
        case ProductsActions.IDLE_STATE:
            {
                //console.log("[ Products ] : IDLE_STATE : ", payload);
                return { ...state, PRODUCTS_REDUCER: { actions: ProductsActions.IDLE_STATE, data: payload, error: _error } };
            }
        case ProductsActions.GET_PRODUCTS_SUCCESS:
            {
                // console.log("[ Products ] : GET_PRODUCTS_SUCCESS : ", payload);
                return { ...state, PRODUCTS_REDUCER: { actions: ProductsActions.GET_PRODUCTS_SUCCESS, data: payload, error: _error } };
            }
        case ProductsActions.GET_PRODUCTS_FAILURE:
            {
                console.log("[ Products ] : GET_PRODUCTS_FAILURE : ", payload);
                return { ...state, PRODUCTS_REDUCER: { actions: ProductsActions.GET_PRODUCTS_FAILURE, data: payload, error: _error } };
            }
        case ProductsActions.CREATE_PRODUCTS_SUCCESS:
            {
                // console.log("[ Products ] : CREATE_PRODUCTS_SUCCESS : ", payload);
                return { ...state, PRODUCTS_REDUCER: { actions: ProductsActions.CREATE_PRODUCTS_SUCCESS, data: payload, error: _error } };
            }
        case ProductsActions.CREATE_PRODUCTS_FAILURE:
            {
                console.log("[ Products ] : CREATE_PRODUCTS_FAILURE : ", payload);
                return { ...state, PRODUCTS_REDUCER: { actions: ProductsActions.CREATE_PRODUCTS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Products ]  default :", state);
                return state;
            }
    }
}
