
import * as ReportsActions from '../actions/Actions_Reports';

const INITIAL_STATE = { REPORTS_REDUCER: { actions: ReportsActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: ReportsActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case ReportsActions.INITIAL_STATE:
            {
                return state;
            }
        case ReportsActions.IDLE_STATE:
            {
                //console.log("[ Report ] : IDLE_STATE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.IDLE_STATE, data: payload, error: _error } };
            }
        case ReportsActions.GET_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : GET_PRODUCTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.GET_REPORTS_FAILURE:
            {
                console.log("[ Report ] : GET_REPORTS_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.GET_ADMIN_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : GET_ADMIN_REPORTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_ADMIN_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.GET_ADMIN_REPORTS_FAILURE:
            {
                console.log("[ Report ] : GET_ADMIN_REPORTS_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_ADMIN_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.GET_SUPPLIER_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : GET_SUPPLIER_REPORTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_SUPPLIER_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.GET_SUPPLIER_REPORTS_FAILURE:
            {
                console.log("[ Report ] : GET_SUPPLIER_REPORTS_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_SUPPLIER_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.GET_AGENT_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : GET_AGENT_REPORTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_AGENT_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.GET_AGENT_REPORTS_FAILURE:
            {
                console.log("[ Report ] : GET_AGENT_REPORTS_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_AGENT_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.GET_SELLER_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : GET_SELLER_REPORTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_SELLER_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.GET_SELLER_REPORTS_FAILURE:
            {
                console.log("[ Report ] : GET_SELLER_REPORTS_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_SELLER_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.GET_CUSTOMER_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : GET_CUSTOMER_REPORTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_CUSTOMER_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.GET_CUSTOMER_REPORTS_FAILURE:
            {
                console.log("[ Report ] : GET_CUSTOMER_REPORTS_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.GET_CUSTOMER_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.CREATE_REPORTS_SUCCESS:
            {
                // console.log("[ Report ] : CREATE_PRODUCTS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.CREATE_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.CREATE_REPORTS_FAILURE:
            {
                console.log("[ Report ] : CREATE_PROMOTION_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.CREATE_REPORTS_FAILURE, data: payload, error: _error } };
            }
        case ReportsActions.UPDATE_REPORTS_SUCCESS:
            {
                // console.log("[ Report ]: UPDATE_DESTINATIONS_SUCCESS : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.UPDATE_REPORTS_SUCCESS, data: payload, error: _error } };
            }
        case ReportsActions.UPDATE_REPORTS_FAILURE:
            {
                console.log("[ Report ] : UPDATE_PROMOTION_FAILURE : ", payload);
                return { ...state, REPORTS_REDUCER: { actions: ReportsActions.UPDATE_REPORTS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Report ]  default :", state);
                return state;
            }
    }
}
