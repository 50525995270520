import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { Metrics, Colors, Family } from "../themes";
//import { isMobile } from '../containers/PackageInfoPanel/GetItemColsPerWidth';

const isActive = true;

class SubTopicsPanel extends Component {
    render() {
        const { _wording, _topics, _isMobile } = this.props;

        return (isActive && !_isMobile) ?
            (<div style={_style.container}>
                <div style={_style.topic_box}>{_topics}</div>
                <div style={_style.wording_box}>
                    {_wording[0]}
                    <span style={_style.span_detial}>{_wording[1]}</span>
                </div>
            </div>) : <div style={_style.container} />
    }
}

export default withRouter(SubTopicsPanel);

const _style = {
    container: {
        padding: '15px',
        marginTop: '0px',
        float: 'left'
    },
    topic_box: {
        fontSize: '36px',
        color: Colors.theme,
        fontFamily: Family.roboto,
        marginTop: '20px',
    },
    wording_box: {
        color: Colors.theme,
        fontFamily: Family.roboto,
        fontSize: '14px',
        fontWeight: 'bold',
        marginBottom: '25px',
        marginLeft: '3px',
    },
    span_detial: {
        opacity: 0.67,
        fontWeight: 'normal',
    }
}
