
export const UserFormat = {
    username: "",
    password: "",
    email: "",
    userType: "",
    level: "0",
    emailVerified: false,
    user: {}
}

export const SupplierFormat = {
    "supplierCode": "",
    "name": "",
    "address": {
        "no": "",
        "road": "",
        "district": "",
        "sub-district": "",
        "province": "",
        "post-code": "",
        "country": ""
    },
    "bank": {
        "bankId": "",
        "bankName": "",
        "accountNumber": "",
    },
    "telOffice": "",
    "reservation_number": "",
    "emergencyContact": [{
        "person": "",
        "tel": ""
    }],
    "type": [],
    "officialWebsite": "",
    "logo": "",
    "language": "",
    "parentId": "",
    "actived": false,
    // "visible": false,
    "approved": false,
    // "userId": {},
    // "id": ""
}

export const SetSupplierToCrate = (supplier) => {

    const _userInfo = { ...UserFormat };
    const language = supplier.language === '';
    // console.log('supplier : ', supplier);

    _userInfo.username = supplier.email;
    _userInfo.password = supplier.email.trim();
    _userInfo.userType = 'supplier';
    _userInfo.email = supplier.email;
    _userInfo.visible = supplier.visible || false;

    _userInfo.user = supplier;
    _userInfo.user.language = language ? "EN" : supplier.language;
    // console.log('new_userInfo : ', _userInfo);

    return _userInfo;
}


export const SetMemberToCrate = (member) => {

    const _userInfo = { ...UserFormat };
    const _memberInfo = { ...member };
    // console.log('member : ', _memberInfo);

    _userInfo.username = member.email;
    _userInfo.password = member.email.trim();
    _userInfo.userType = 'admin';
    _userInfo.email = member.email;

    _userInfo.user = _memberInfo;
    // console.log('_userInfo : ', _userInfo);

    return _userInfo;
}