const HeaderPage = {
    EN: "Create Zone", TH: "สร้างสกุลเงิน", CN: "创造条件",
    UPDATE_EN: "Update Zone", UPDATE_TH: "---", UPDATE_CN: "---"
}

const BodyPage = [
    [{
        title_TH: "----", title_EN: "Zone Name", title_CN: "----",
        type: "text",
        require: false,
        stateName: "name",
        placeholder_TH: "----", placeholder_EN: "Insert Name", placeholder_CN: "----"
    }], [{
        title_TH: "----", title_EN: "Remark", title_CN: "----",
        type: "text",
        require: false,
        stateName: "remark",
        placeholder_TH: "----", placeholder_EN: "Insert Remark", placeholder_CN: "----"
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "visible",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "image",
        stateName: "picture",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}