const HeaderPage = {
    TH: "สร้างสกุลเงิน",
    EN: "Create LocationService",
    CN: "创造条件",
    UPDATE: "Update LocationService"
}

const BodyPage = [
    [{
        title_TH: "ประเภทสถานที่", title_EN: "Location Type", title_CN: "包装类型",
        type: "select",
        require: true,
        width: '40%',
        stateName: "locationType",
        options: [
            { value: "province", label_EN: "Province", label_TH: "", label_CN: "" },
            { value: "district", label_EN: "District", label_TH: "", label_CN: "" },
            { value: "subdistrict", label_EN: "Subdistrict", label_TH: "", label_CN: "" },
        ],
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "插入名称"
    }, {
        province_TH: "", province_EN: "Province", province_CN: "包装类型",
        district_TH: "", district_EN: "District", district_CN: "包装类型",
        subdistrict_TH: "", subdistrict_EN: "Sub-District", subdistrict_CN: "包装类型",
        width: '30%',
        margin: '0px 15px',
        type: "code",
        require: true,
        stateName: "code",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "插入名称"
    }, {
        title_TH: "ประเทศ", title_EN: "Country", title_CN: "包装类型",
        type: "select",
        require: false,
        width: '29%',
        stateName: "country",
    }],
    [{
        title_TH: "ชื่อ", title_EN: "Location Name", title_CN: "名称",
        type: "text",
        width: '70%',
        require: false,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }, {
        title_TH: "", title_EN: "Tag", title_CN: "名称",
        type: "text",
        width: '30%',
        margin: '0px 0px 0px 15px',
        require: false,
        stateName: "tag",
        placeholder_TH: "", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"

    }], [{
        title_TH: "ลักษณะ", title_EN: "Description", title_CN: "描述",
        type: "text-multiline",
        require: false,
        stateName: "description",
        placeholder_TH: "ลักษณะ", placeholder_EN: "Insert Description", placeholder_CN: "插入说明"
    }], [{
        title_TH: "ที่อยู่", title_EN: "Address", title_CN: "描述",
        type: "text",
        require: false,
        stateName: "address",
        placeholder_TH: "ที่อยู่", placeholder_EN: "Insert Address", placeholder_CN: "插入说明"
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "isService",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "image",
        stateName: "picture",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}