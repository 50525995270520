const memberLevelList = [
    {
        "name": "normal",
        "value": 0,
        "id": "5a5eb961a022c6fea06f70fb"
    },
    {
        "name": "Blonde",
        "value": 1,
        "id": "5a7408d2a022c6fea07bd750"
    },
    {
        "name": "Silver",
        "value": 2,
        "id": "5a7408eba022c6fea07bd774"
    },
    {
        "name": "Gold",
        "value": 3,
        "id": "5a7408faa022c6fea07bd788"
    },
    {
        "name": "Platinum",
        "value": 4,
        "id": "5a740921a022c6fea07bd7b2"
    },
    {
        "name": "Diamond",
        "value": 5,
        "id": "5a74094fa022c6fea07bd7ea"
    }
];

export const GetLevelWithID = (id) => {
    let _level = memberLevelList.find((levelInfo) => (levelInfo.id === id));
    return (!!_level) ? _level.name : '-';
}