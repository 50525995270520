const HeaderPage = {
    CREATE_EN: "Create Seller Type", CREATE_TH: "---", CREATE_CN: "---",
    UPDATE_EN: "Update Seller Type", UPDATE_TH: "---", UPDATE_CN: "---"
}

const BodyPage = [
    [{
        title_TH: "----", title_EN: "Seller Type Name", title_CN: "----",
        type: "text",
        require: false,
        stateName: "name",
        placeholder_TH: "----", placeholder_EN: "Insert Name", placeholder_CN: "----"
    }], [{
        title_TH: "----", title_EN: "Remark", title_CN: "----",
        type: "text",
        require: false,
        stateName: "remark",
        placeholder_TH: "----", placeholder_EN: "Insert Remark", placeholder_CN: "----"
    }], [{
        title_TH: "---", title_EN: "Status", title_CN: "---",
        type: "switch",
        width: '20%',
        stateName: "visible",
    }, {
        title_TH: "---", title_EN: "Commission", title_CN: "---",
        type: "switch",
        stateName: "isCommission",
    }], [{
        title_TH: "---", title_EN: "Image", title_CN: "---",
        type: "image",
        stateName: "picture",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}

