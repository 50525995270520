import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../../App.css';

import List from '@material-ui/core/List';

import DialogAction from '../../componets/DialogAction.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

import * as CurrencyActions from '../../actions/Actions_CurrencyManager';

import CurrencyCard from './CurrencyCard';
import CurrencyListPanel from './CurrencyListPanel';
import { init_currency } from '../../constant/initState/initCurrency';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let CurrencyList = [];
let waitingTime = true;
const _height = (window.innerHeight - 130);

class CurrencyManage extends Component {
    state = { isLoading: true, msgError: undefined, dialogType: undefined, }
    constructor(props) {
        let error = null;
        super(props);
        this.state = {
            CurrencyInfo: undefined,
            oldCurrencyInfo: null,
            isCreate: false,
            isEdit: false,
            isView: false,
        }
        this.props.getAPICurrencyList({ num_limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        // console.log('[ componentWillReceiveProps(nextProps) : ]', nextProps)
        const { CurrencyManager } = nextProps;
        if (!!CurrencyManager) {

            const { actions, data } = CurrencyManager.CURRENCY_REDUCER;

            switch (actions) {
                case CurrencyActions.GET_CURRENCY_SUCCESS:
                    {
                        CurrencyList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CurrencyActions.GET_CURRENCY_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.createCurrency()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto' }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        )
    }
    contentPanel() {
        return (
            <div style={{ width: '100%', maxWidth: '1024px', }}>
                <CurrencyListPanel
                    CurrencyList={CurrencyList}
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.editPanel()}
                    ViewInfo={this.viewInfo()} />
            </div>)
    }

    createCurrency = () => {
        const { isEdit, CurrencyInfo, oldCurrencyInfo, isView } = this.state;
        return (
            <CurrencyCard
                CurrencyList={CurrencyList}
                onChangeLanguage={this.onChangeLanguage}
                isEdit={isEdit}
                isView={isView}
                CurrencyInfo={CurrencyInfo}
                oldCurrencyInfo={oldCurrencyInfo}
                onClosePanel={this.onClosePanel}
            />
        )
    }

    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, oldCurrencyInfo: { ...init_currency }, CurrencyInfo: { ...init_currency } }));
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, isView: false, CurrencyInfo: undefined, }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    onChangeLanguage = (_language) => {
        const { page } = CurrencyList;
        this.props.getAPICurrencyList({ page: page, num_limit: 10, language: _language });
    }

    editPanel = () => (CurrencyInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            CurrencyInfo,
            oldCurrencyInfo: CurrencyInfo
        })
    }

    viewInfo = () => (CurrencyInfo) => {
        this.setState({
            isCreate: true,
            isView: true,
            CurrencyInfo,
            oldCurrencyInfo: CurrencyInfo
        })
    }

}
function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPICurrencyList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CurrencyActions.getCurrencyList(_keyword, _filter));
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrencyManage);