import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';
import { path, clone } from 'ramda';

import { HighlightOff } from '@material-ui/icons';
import { Colors, Family } from '../themes';
import FormTypes from '../common/FormTypes';
import ButtonText from '../constant/Button/ButtonText';
import Button from '../constant/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { GetSymbol } from '../common/GetSymbol.js';
import { NumberFormats } from '../common/SetNumberFormat';

import DialogPanel from '../componets/DialogPanel.jsx';
import * as validator from '../constant/Validator/Refund';

import * as RefundActions from '../actions/Actions_RefundManager';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import Refund from '../constant/JSON/Refund';
import * as format from '../common/setFormat';
// let Structure = Refund.UpdateStatus;
// let RefundReasonsList = [];
let waitingTime = true;
// let Language = 'EN';
// let userInfo = JSON.parse(localStorage.getItem('UserInfo'));
let isBtnActive = false;

class RequestPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, msgError: undefined, dialogType: undefined,
            _info: {
                status: '',
                remark: '',
                amount: 0,
                isAmountAll: null,
            }
        }
    }

    render() {
        const { Header, Structure = [], action, InfoLists = [], Info } = this.props;
        const { _info } = this.state;
        let isAmount = _info.amount > 0;
        let isReceived = (_info.amount <= Info.receivedWallet);

        if (_info.status) {
            isBtnActive = true;
        } else if (isAmount && isReceived) {
            isBtnActive = true;
        } else {
            isBtnActive = false;
        }


        return (
            <Contianer>
                <Typography> {Header}  </Typography>
                <Line />
                <ListContainer> {this.contentPanel(InfoLists)} </ListContainer>
                {(!action) ?
                    <React.Fragment>
                        <Line />
                        <ListContainer>
                            {
                                Structure.map((row, index) => (
                                    <ListItmes key={index}>
                                        {
                                            row.map((_structure, index) => (<div key={index} style={{ width: (_structure.width) ? _structure.width : '100%' }}> {this.requestPanel(_structure)} </div>))
                                        }
                                    </ListItmes>
                                ))}
                        </ListContainer>
                        <Line />
                        <ButtonContianer>
                            <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} onClick={() => this.props.onClosePanel()}
                                style={{ width: '100px', marginRight: 15 }} buttonStyle={{ margin: 0, padding: 7 }} />
                            <Button.Themes TitleText={ButtonText.BUTTON_SAVE} onClick={() => this.props.onSavePanel(Info, _info)}
                                disabled={!isBtnActive} style={{ width: '100px' }} buttonStyle={{ margin: 0, padding: 7 }} />
                        </ButtonContianer>
                    </React.Fragment>
                    : null
                }
            </Contianer >
        )
    }

    contentPanel = (InfoLists) => (
        InfoLists.map((each, index) => {
            return (
                <ListItmeContianer key={index}>
                    <Title>{each.title || '-'}</Title>
                    <Label>{each.value || '-'} </Label>
                </ListItmeContianer>
            )
        })
    )

    requestPanel = (_structure) => {
        const { _info } = this.state;
        const { Info } = this.props;
        // console.log('Info', Info);
        let Items = _structure.options || [];
        let Status = Info.refundStatus || Info.transferStatus;
        switch (Status) {
            case 'request': {
                Items = _structure.optionsRequest;
            } break;
            case 'in-process': {
                Items = _structure.optionsInProcess;
            } break;
        }

        switch (_structure.type) {
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={3}
                        style={{ marginBottom: 10 }}
                        Require={_structure.require}
                        TitleText={_structure.title_EN}
                        value={path([_structure.stateName], _info)}
                        onChange={(e) => { this.onChangeState(_structure.stateName, e.target.value) }} />
                )
            }
            case 'number': {
                return (
                    <FormTypes.TextFormat
                        InputFormat={format.PriceFormat}
                        Require={_structure.require}
                        TitleText={_structure.title_EN}
                        value={path([_structure.stateName], _info)}
                        onChange={(e) => { this.onChangeState(_structure.stateName, e.target.value) }} />
                )
            }
            case 'checkbox': {
                return (
                    <FormTypes.Checkbox
                        TitleText={_structure.title_EN}
                        StateName={_structure.stateName}
                        value={path([_structure.stateName], _info)}
                        onChange={(e) => { this.onChangeState(_structure.stateName, e.target.checked) }}

                    // value={path([_structure.stateName], _info)}
                    // onChange={(e) => { this.onChangeState(_structure.stateName, e.target.value) }}
                    />
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={_structure.stateName === 'refundType' && true}
                        TitleText={_structure.title_EN}
                        Require={_structure.require}
                        value={path([_structure.stateName], _info)}
                        onChange={(e) => { this.onChangeState(_structure.stateName, e.target.value) }}
                        options={
                            Items.map((option, i) => (<option key={i} value={option.value} >  {option.label}</option>))
                        }
                    />
                )
            }
        }
    }

    // handleToggle = fieldName => event => {
    //     let newData = { ...this.state._info };
    //     console.log('handleToggle', fieldName, event);
    //     switch(fieldName) {
    //         case'isAmountAll':{

    //         }break;
    //     }
    //     // this.setState({ [name]: event.target.checked })
    // }

    onChangeState = (fieldName, value) => {
        const { receivedWallet } = this.props.Info;
        let newData = { ...this.state._info };
        // console.log('onChangeState', receivedWallet, fieldName, value);
        switch (fieldName) {
            case 'isAmountAll': {
                switch (value) {
                    case true: {
                        newData = { ...newData, [fieldName]: value, amount: receivedWallet }
                    } break;
                    case false: {
                        newData = { ...newData, [fieldName]: value, amount: 0 }
                    } break;
                }
            } break;
            case 'amount': {
                newData = { ...newData, [fieldName]: parseFloat(value) }

            } break;
            default: {
                newData = { ...newData, [fieldName]: value }
            } break;
        }
        // console.log('newData', newData);
        this.setState({ _info: newData })
    }
}


export default (RequestPanel);

const Contianer = styled.div`
        max-width: 800px;
        font-family: ${Family.roboto};`;
const Line = styled.div`
        height:3px;
        background-color:${Colors.theme}`;
const Typography = styled.div`            
        padding: 15px 0px;
        font-size: 24px;`;
const ListContainer = styled.div`
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        font-size: 16px; `;
const ListItmeContianer = styled.div`
        display: flex;
        padding: 7px 0px;`;
const ListItmes = styled.div`
display: flex;
        padding: 5px 0px;
        width: 100%;`;
const ButtonContianer = styled.div`
        display: flex;
        padding: 15px 0px;
        justify-content: flex-end;`;
const Title = styled.div`
        font-weight:bold;
        padding-right:5px;`;
const Label = styled.div`
        color:${Colors.grey};
        font-weight:300; `;
