import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import { path } from 'ramda';

import CONFIG from '../../config';
import { PLATFORM_NAME, } from "../../config/platform";

import DialogPanel from '../../componets/DialogPanel.jsx';
import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';
import * as UploadActions from '../../actions/Actions_UploadManager';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as DestinationsActions from '../../actions/Actions_DestinationsManager';
import * as ConditionActions from '../../actions/Actions_Conditions';
import * as LocationServicesActions from '../../actions/Actions_LocationServicesManager';
import * as SupplierActions from '../../actions/Actions_SupplierManager.js';
import * as AppointmentActions from '../../actions/Actions_Appointment';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import ImageSlide from '../../componets/ImageSlide.jsx'
import PackageCard from './PackageCard';
import { PathName } from '../../constant';
import { SetPackegeInfoToUpdate, SetPackegeInfo, SetCustomUpdate } from '../../constant/initState/initPackage';
import '../../App.css';
import LanguageForm from '../../constant/Language';
import { Styles } from '../../themes';


const _height = Styles.heightWithTopic;
const isTravisgo = CONFIG.isPlatForm === PLATFORM_NAME.TRAVISGO;
const pageWording = {
    topics: 'Package Detail',
    subheading: (isTravisgo) ? ['You get the best rates', ' only at TravisGo'] : ['You get the best rates', ' only at Phi Phi Tour'],
    icon: ['safeBook', 'bestPrice', 'fee'],
};

var _packageInfoList;
let SupplierList = [];
let ConditionList = [];
var _packageInfo;
let DestinationsList = [];
let waitingTime = true;
let LocationServicesList = [];
let PackageInfo = null;
let PackageInfoList = null;
let AppointmentList = [];
const RequireForm = <span style={{ color: 'red' }}>* </span>;


class PackageDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packageData: null,
            packageContent: null,
            packageInfo: path(['packageInfo'], this.props.location.state),
            isDialog: false, msgError: undefined, dialogType: undefined,
            isTransfer: this.props.location.pathname === PathName.TransferDetail ? true : false
        }

        const { PackageManager } = props;
        const { data } = PackageManager;

        this.props.getAPIDestinationsLists();
        this.props.getAPIConditionsList({});
        this.props.getAPILocationServicesLists();
        this.props.getAPIAppointmentList({});
        this.props.getAPISupplierList();

        if (data) {
            if (this.state.isTransfer) {
                this.props.getAPIPackageList({ type: 'Transfer', limit: 25, page: data.page || 0, language: data.language || 'EN' })
            }
        }
        //  else {
        //     if (this.state.isTransfer) {
        //         this.props.getAPIPackageList({ type: 'Transfer', limit: 25, page: 0, language: path(['packageInfo', 'packageInfo', 'language'], this.props.location.state) })
        //     } else
        //         this.props.getAPIPackageList({ limit: 25, page: 0, language: path(['packageInfo', 'packageInfo', 'language'], this.props.location.state) })
        // }

    }

    componentWillReceiveProps(nextProps) {
        // console.log(`PackageDetail [componentWillReceiveProps] : `, nextProps);

        const { DestinationsManager, SupplierManager, UpLoadManager, AppointmentManager, PackageManager, ConditionManager, LocationServicesManager } = nextProps;

        if (!!PackageManager) {
            const { data, actions, error } = PackageManager;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime);
                        PackageInfoList = (data.packageList || []);
                        const { packageId } = path(['packageInfo'], this.state);

                        // console.log('packageId', packageId);
                        // console.log('packageInfo', this.state.packageInfo);
                        // console.log('PackageInfoList :', PackageInfoList);

                        if (Array.isArray(PackageInfoList) && PackageInfoList.length > 0) {
                            // console.log('PackageInfoList', PackageInfoList)
                            this.setState({ packageInfo: PackageInfoList.find(_info => _info.packageId === packageId) })
                        }
                    } break;

                case PackageAction.UPDATE_PACKAGE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE PACKAGE SUCCESS.', dialogType: 'success', });

                        const { PackageManager } = this.props;
                        const { data } = PackageManager;

                        if (Boolean(data)) {
                            if (this.state.isTransfer) {
                                this.props.getAPIPackageList({ type: 'Transfer', limit: 25, page: data.page || 0, language: data.language || 'EN' })
                            }
                            else if (path(['location', 'state', 'supplierInfo'], this.props)) {
                                this.props.getAPIPackageList({ limit: 999 })
                            }
                            else {
                                this.props.getAPIPackageList({ limit: 999, page: data.page || 0, language: data.language || 'EN' })
                                // this.props.getAPIPackageList({ limit: 999 })
                            }
                        }

                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                case PackageAction.CREATE_PACKAGE_FAILURE:
                case PackageAction.UPDATE_PACKAGE_FAILURE:
                    {
                        console.log("UPDATE_PACKAGE_FAILURE : ", data);
                        const _msg = data.response.data.error || actions;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!DestinationsManager) {
            const { actions, data } = DestinationsManager;

            switch (actions) {
                case DestinationsActions.GET_DESTINATIONS_SUCCESS:
                    {
                        DestinationsList = data.result.datas;
                        DestinationsManager.actions = DestinationsActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case DestinationsActions.GET_DESTINATIONS_FAILURE:
                    {
                        DestinationsManager.actions = DestinationsActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!ConditionManager) {
            const { actions, data } = ConditionManager;

            switch (actions) {
                case ConditionActions.GET_CONDITIONS_SUCCESS:
                    {
                        ConditionList = data.result.datas;
                        ConditionManager.actions = ConditionActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;

                case ConditionActions.GET_CONDITIONS_FAILURE:
                    {
                        ConditionManager.actions = ConditionActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!LocationServicesManager) {
            const { actions, data } = LocationServicesManager;

            switch (actions) {
                case LocationServicesActions.GET_LOCATIONSERVICES_SUCCESS:
                    {
                        LocationServicesList = data.result;
                        LocationServicesManager.actions = LocationServicesActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case LocationServicesActions.GET_LOCATIONSERVICES_FAILURE:
                    {
                        LocationServicesManager.actions = LocationServicesActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!AppointmentManager) {
            const { actions, data } = AppointmentManager;

            switch (actions) {
                case AppointmentActions.GET_APPOINTMENT_SUCCESS:
                    {
                        AppointmentList = data.result.datas;
                        AppointmentManager.actions = AppointmentActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AppointmentActions.GET_APPOINTMENT_FAILURE:
                    {
                        AppointmentManager.actions = AppointmentActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SupplierManager) {

            const { actions, data } = SupplierManager;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_SUCCESS:
                    {
                        SupplierList = data.result.datas;
                        SupplierManager.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_FAILURE:
                    {
                        SupplierManager.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!UpLoadManager) {
            const { data, actions, error } = UpLoadManager;
            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.actions = UploadActions.WATING_STATE;
                        let _newPackage = { ...this.state.packageData };
                        const _image = data.result.upload.images;
                        // console.log('_newPackage', _newPackage)
                        // console.log('_image', _image)

                        _newPackage.newImageGallery = _newPackage.newImageGallery.concat(_image);
                        _newPackage.newImageCover = _newPackage.newImageCover.concat(_image);

                        console.log('UPLOAD_IMAGE_SUCCESS :', _newPackage)
                        // this.props.getAPIupdatePackage(SetPackegeInfoToUpdate(_newPackage));
                        this.props.getAPIupdatePackage(SetCustomUpdate('UpdatePackage', _newPackage));
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }

    onCloseDialog = () => (this.setState({ isDialog: false, msgError: undefined, dialogType: undefined }));

    onChangeLanguage = (_language) => {
        this.props.getAPIPackageList({ limit: 25, page: 0, language: _language })
    }

    updateAPIPackage = (_package, defaultFiles, newImage) => {
        if (newImage && newImage.length > 0) {

            let newImageSquare = (defaultFiles.length > 0) ? defaultFiles[0] : newImage;
            let files = null;

            if (newImageSquare.length > 0) {
                files = newImageSquare;
                this.setState({
                    isDialog: true,
                    packageData: { ..._package, newImage: newImage, defaultFiles: defaultFiles, newImageGallery: [], newImageCover: [] }
                }, () => this.upLoadImage(files))
            }
            else {
                files = newImageSquare;
                this.setState({ isDialog: true, packageData: { ..._package, newImage: newImage, defaultFiles: files, newImageGallery: [], newImageCover: [] } },
                    () => this.upLoadImage(newImage))
            }
        }
        else {
            this.setState({ isDialog: true, packageData: { ..._package } },
                // () => this.props.getAPIupdatePackage(SetPackegeInfoToUpdate(this.state.packageData)),
                () => this.props.getAPIupdatePackage(SetCustomUpdate('UpdatePackage', this.state.packageData)),
            )
        }
    }


    upLoadImage = (cover) => {

        let formdata = new FormData();
        formdata.append('imgOriginalWidth', 1360); //650
        formdata.append('imgOriginalHeight', 960); //450
        formdata.append('imgThumbnailWidth', 900); //340
        formdata.append('imgThumbnailHeight', 636); //260
        formdata.append('imgThumbnailWidthMobile', 616); //308
        formdata.append('imgThumbnailHeightMobile', 362); //181

        for (const _image of cover)
            formdata.append("images", _image.file);

        this.props.uploadImage(formdata)
    }


    render() {
        const { isDialog, msgError, dialogType } = this.state;
        return (
            <div className="App">
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                <div style={style.page_container}>
                    <SubTopicsPanel _topics={pageWording.topics} _wording={pageWording.subheading} />
                </div>
                <List style={style.list_container}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {

        const packageInfo = path(["packageInfo"], this.state)
        if (!packageInfo) return null;
        const { imageGallery, language } = packageInfo.packageInfo;

        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <ImageSlide ImageList={imageGallery} Host={CONFIG.host} />
                <PackageCard
                    getAPIPackageList={this.props.getAPIPackageList}
                    ImageList={imageGallery}
                    LanguagePage={language}
                    onChangeLanguage={this.onChangeLanguage}
                    AppointmentList={AppointmentList}
                    ConditionList={ConditionList}
                    DestinationsList={DestinationsList}
                    LocationServicesList={LocationServicesList}
                    SupplierList={SupplierList}
                    IsEdit={true}
                    isTransfer={this.props.location.isTransfer ? this.props.location.isTransfer : false}
                    packageInfo={SetPackegeInfo(packageInfo).packageData}
                    updateAPIPackage={this.updateAPIPackage}
                />

            </div>
        );
    }
}

const style =
{
    page_container: { display: 'inline-block', width: '100%', maxWidth: '1030px', },
    list_container: { width: '100%', position: 'relative', overflow: 'auto', height: _height },
};

function mapStateToProps(state, ownProps) {
    return {
        PackageManager: state.PackageManager.PACKAGE_REDUCER,
        DestinationsManager: state.DestinationsManager.DESTINATION_REDUCER,
        ConditionManager: state.ConditionManager.CONDITION_REDUCER,
        LocationServicesManager: state.LocationServicesManager.LOCATIONSERVICES_REDUCER,
        AppointmentManager: state.AppointmentManager.APPOINTMENT_REDUCER,
        UpLoadManager: state.UpLoadManager.UPLOAD_REDUCER,
        SupplierManager: state.SupplierManager.SUPPLIER_REDUCER,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getAPIDestinationsLists: (_keyword) => {
            InitWaitingTime();
            dispatch(DestinationsActions.getDestinationsList());
        },
        getAPIConditionsList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(ConditionActions.getConditionsList());
        },
        getAPILocationServicesLists: (_keyword) => {
            InitWaitingTime();
            dispatch(LocationServicesActions.getLocationServicesList());
        },
        getAPIupdatePackage: (packageInfo) => {
            dispatch(PackageAction.APIUpdatePackage(packageInfo));
        },
        getAPIAppointmentList: (_keyword, _filter) => {
            dispatch(AppointmentActions.getAppointmentList());
        },
        uploadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getAPISupplierList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PackageDetail);


