import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { PathName } from '../constant';
import AppBar from '@material-ui/core/AppBar';

import DialogPanel from '../componets/DialogPanel.jsx';

import AccountingStatistic from '../containers/Accounting/AccountingStatistic.jsx';
import AccountingAgentListPanel from '../containers/Accounting/AccountingAgentListPanel.jsx';
import AccountingSellerListPanel from '../containers/Accounting/AccountingSellerListPanel.jsx';
import AccoutingSupplierListPanel from '../containers/Accounting/AccoutingSupplierListPanel.jsx';
import AccountingCustomerListPanel from '../containers/Accounting/AccountingCustomerListPanel.jsx';

import * as BankActions from '../actions/Actions_Settings';
import * as AgentActions from '../actions/Actions_AgentManager.js';
import * as SellerActions from '../actions/Actions_SellerManager.js';
import * as AccountingActions from '../actions/Actions_Accounting.js';
import * as SupplierActions from '../actions/Actions_SupplierManager.js';
import * as CustomerActions from '../actions/Actions_CustomerManager.js';

import { Roles } from '../constant/JSON/Roles.js';
import { isMobile, isTablet } from '../common/DeviceCheck.js';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';
import { Colors, Family } from '../themes';

import '../App.css';

let waitingTime = true;
let BookBankList = [];
const _height = (window.innerHeight - 130);

let _info = JSON.parse(localStorage.getItem('UserInfo'));
let _role = (!!_info) ? (_info.userType || '') : '';

class TransactionPage extends Component {

    state = { isLoading: true, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {
        super(props);

        _info = JSON.parse(localStorage.getItem('UserInfo'));
        _role = (!!_info) ? (_info.userType || '') : '';

        switch (_role) {
            case Roles.Admin:
            default:
                {
                    const _filter = { key: 'wallet', orderBy: 'desc', num_limit: 10 };
                    this.props.getAPISupplierList(_filter)
                    this.props.getAPISystemWallet();
                    this.props.getAPIgetBankList();
                };
        }
    }

    componentWillReceiveProps(nextProps) {
        const { AccountingManager, SettingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.GET_SYSTEM_WALLET_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_WALLET_INFO_WITH_ID_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE:
                case AccountingActions.GET_SYSTEM_WALLET_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SettingManager) {
            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case BankActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        SettingManager.actions = BankActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BankActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SettingManager.actions = BankActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const _isMobile = isMobile();
        const { isLoading, msgError, dialogType } = this.state;

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <List style={{ width: '100%', flexGrow: 1, position: 'relative', overflow: 'auto', height: _height, }}>
                    <div style={{ display: 'inline-block' }}>
                        <AccountingStatistic isLoading={isLoading} Role={_role} DisplayPage={PathName.DashBoardPage} />
                        {this.contentPanel(_isMobile)}
                    </div>
                </List>
            </div>

        )
    }
    contentPanel(_isMobile) {
        const { classes } = this.props;
        const { value = 0, isLoading } = this.state;

        return (
            <AppBar position="static" >
                <Tabs
                    value={value}
                    variant="fullWidth"
                    onChange={this.handleChange}
                    classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}    >
                    <Tab
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Supplier Accounting" />
                    <Tab
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Agent Accounting" />
                    <Tab
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Seller Accounting" />
                    <Tab
                        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                        label="Customer Accounting" />
                </Tabs>
                <div style={{ width: '100%', maxWidth: '1024px', }}>
                    {this.DisplayPanelWithStyle(value, _isMobile)}
                </div>
            </AppBar>
        )
    }

    DisplayPanelWithStyle(value, _isMobile) {

        switch (value) {
            case 0: {
                return (
                    <AccoutingSupplierListPanel Header={`Supplier Accounting List`} SearchType={Roles.Supplier} BookBankList={BookBankList} />
                )
            }
            case 1: {
                return (
                    <AccountingAgentListPanel Header={`Agent Accounting List`} SearchType={Roles.Agent} BookBankList={BookBankList} />
                )
            }
            case 2: {
                return (
                    <AccountingSellerListPanel Header={`Seller Accounting List`} SearchType={Roles.Seller} BookBankList={BookBankList} />
                )
            }
            case 3: {
                return (
                    <AccountingCustomerListPanel Header={`Customer Accounting List`} SearchType={Roles.Customer} BookBankList={BookBankList} />
                )
            }
        }
    }

    handleChange = (event, value) => {

        const _filter = { key: 'wallet', orderBy: 'desc', num_limit: 10 };

        switch (value) {
            case 0: { this.props.getAPISupplierList(_filter) } break;
            case 1: { this.props.getAPIAgentList(_filter); } break;
            case 2: { this.props.getAPISellerList(_filter); } break;
            case 3: { this.props.getAPICustomerList(_filter); } break;
        }

        this.setState({ value });
    };

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}
const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tabsRoot: {
        backgroundColor: Colors.themeDark,
        borderBottom: `${'1px solid' + Colors.border}`,
    },
    tabsIndicator: {
        backgroundColor: Colors.theme,
        height: 3,
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        color: Colors.white,
        fontFamily: Family.roboto,
        '&:hover': {
            color: Colors.white,
            opacity: 1,
        },
        '&$tabSelected': {
            color: Colors.white,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: Colors.white,
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

TransactionPage.propTypes = { classes: PropTypes.object.isRequired };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISystemWallet: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.getSystemWallet(_filter));
        },
        getAPIAccoutingHistoryList: (_filter) => {
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
        getAPIWalletInfoWithId: (_filter) => {
            dispatch(AccountingActions.getWalletInfoWithId(_filter));
        },
        getAPISupplierList: (_keyword, _filter) => {
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
        getAPIAgentList: (_keyword, _filter) => {
            dispatch(AgentActions.getAgentList(_keyword, _filter));
        },
        getAPISellerList: (_keyword, _filter) => {
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        getAPICustomerList: (_keyword, _filter) => {
            dispatch(CustomerActions.getCustomerList(_keyword, _filter));
        },
        getAPIgetBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(BankActions.getBankList(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransactionPage));

