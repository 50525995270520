import { isSafari } from '../../common/DeviceCheck';

let _isSafari = isSafari()
export const Images = {

    logo: {
        login: require('./assets/images/Group.png'),
        white: require('./assets/images/Logo_White.png'),
        color: require('./assets/images/Logo_Color.png'),
    },
    HeaderBackground: {
        website: _isSafari ? require('./assets/bg/travisgo_bg_businesspartner@1x.jpg') : require('./assets/bg/travisgo_bg_businesspartner.webp'),
        tablet: _isSafari ? require('./assets/bg/travisgo_tablet_bg_businesspartner@1x.jpg') : require('./assets/bg/travisgo_tablet_bg_businesspartner.webp'),
        mobile: _isSafari ? require('./assets/bg/travisgo_mobile_bg_businesspartner@1x.jpg') : require('./assets/bg/travisgo_mobile_bg_businesspartner.webp'),
    },
    User: {
        userdefault: require('./assets/images/userdefault.png'),
    },
    package: {
        duration: require('./assets/images/Duration.png'),
        passengers: require('./assets/images/package/Passengers.png'),
        bags: require('./assets/images/userdefault.png'),
        sale: require('./assets/images/package/Sale.png')
    },
    ImageBackground: require('./assets/bg/travisgo_bg_businesspartner@1x.jpg'),
    Calendar: require('./assets/svg/calendar-alt-regular.svg'),
};
