import React from 'react';
import { Router, Switch, Route, Link, withRouter } from 'react-router-dom';

import { PathName, Roles } from './constant';


//----------------


import BookingDetail from './containers/Booking/BookingDetail.jsx';
import BookingPage from './pages/BookingPage.jsx';
import LoginPage from './pages/LoginPage.jsx';
import DashBoardPage from './pages/DashBoardPage.jsx';
import AccountingPage from './pages/AccountingPage.jsx';
import WithdrawPage from './pages/WithdrawPage.jsx';
import RefundPage from './pages/RefundPage.jsx';
import TransactionPage from './pages/TransactionPage';
import CommissionPage from './pages/CommissionPage.jsx';
import SpecialPage from './pages/SpecialPage.jsx';
import PromotionPage from './pages/PromotionPage.jsx';
import PromotionCodePage from './pages/PromotionCodePage.jsx';
import AnnouncementPage from './pages/AnnouncementPage.jsx';
import CategoryPage from './pages/CategoryPage.jsx';
import WebsitePage from './pages/WebsitePage.jsx';
import CustomerPage from './pages/CustomerPage.jsx';
import SupplierPage from './pages/SupplierPage.jsx';
import AgentPage from './pages/AgentPage.jsx';
import SellerPage from './pages/SellerPage.jsx';
import MemberPage from './pages/MemberPage.jsx';
import PackagePage from './pages/PackagePage.jsx';
import TransferPage from './pages/TransferPage.jsx';
import SettingsPage from './pages/SettingsPage.jsx';
import AppointmentPage from './pages/AppointmentPage.jsx';
import DestinationPage from './pages/DestinationPage.jsx';
import ActivityPage from './pages/ActivityPage.jsx';
import ConditionPage from './pages/ConditionPage.jsx';
import LocationPage from './pages/LocationPage';
import ZonePage from './pages/ZonePage';
import CountryPage from './pages/CountryPage';

import HotelPage from './pages/HotelPage';

import PageNotFound from './pages/PageNotFound.jsx';

//---------------


import AgentInfo from './containers/Agent/AgentInfo.jsx'
import SellerInfo from './containers/Sellers/SellerInfo.jsx'
import CreatePackage from './containers/Packages/CreatePackage.jsx';
import CustomerInfo from './containers/Customers/CustomerInfo.jsx';
import MemberInfo from './containers/Members/MemberInfo.jsx';
import PackageDetail from './containers/Packages/PackageDetail.jsx';
import PromotionInfo from './containers/Promotions/PromotionInfo.jsx';
import ProviderManage from './containers/ProviderManage.jsx';
import SupplierInfo from './containers/Supplier/SupplierInfo.jsx';
import CreateTransfer from './containers/Tranfer/TranferCard.jsx';
import TransferDetail from './containers/Packages/PackageDetail.jsx';
import FerriesCard from './containers/Ferries/FerriesCard.jsx';
import FerriesManage from './containers/Ferries/FerriesManage';
import FerriesDetail from './containers/Packages/PackageDetail.jsx';
import ReservationTable from './containers/ReservationTable/ReservationTable';
import SellerPromote from './containers/Promote/SellerPromote.jsx';
import DrawerScreen from './DrawerScreen.jsx';

import CreateHotel from './containers/Hotel/HotelCard.jsx';






const AppRouter = (_path) => {

    const { pathname } = _path.location;
    const _info = JSON.parse(localStorage.getItem('UserInfo'));
    const _role = (!!_info) ? (_info.userType || '') : '';

    // const _role = Roles.Admin;
    // // console.log('AppRouter : ', _role);

    if (!!_info)
        return (pathname === PathName.Index || pathname === PathName.LoginPage) ? DisplayWithRole(_role) : <DrawerScreen>{DisplayWithRole(_role)}</DrawerScreen>
    else
        return DefaultRoute();

}

const DisplayWithRole = (_role) => {

    switch (_role) {
        case Roles.Admin: return AdminRoute();
        case Roles.Supplier: return SupplierRoute();
        case Roles.Agent: return AgentRoute();
        case Roles.Seller: return SellerRoute();
        default: return DefaultRoute();
    }
}

// const CustomRoute = ({ component: Component, ...rest }) => (<Route {...rest} render={() => (<DrawerScreen><Component /></DrawerScreen>)} />);

const AdminRoute = () => {

    return (
        <Switch>
            <Route exact path={PathName.Index} component={LoginPage} />
            <Route path={PathName.LoginPage} component={LoginPage} />
            <Route path={PathName.DashBoardPage} component={DashBoardPage} />
            <Route path={PathName.MemberManage} component={MemberPage} />
            <Route path={PathName.BookingManage} component={BookingPage} />
            <Route path={PathName.BookingDetail} component={BookingDetail} />
            <Route path={PathName.PackageManage} component={PackagePage} />
            <Route path={PathName.AccountingManage} component={AccountingPage} />
            <Route path={PathName.TransactionManagement} component={TransactionPage} />
            <Route path={PathName.WithdrawManagment} component={WithdrawPage} />
            <Route path={PathName.RefundRequestManage} component={RefundPage} />
            <Route path={PathName.PromotionManage} component={PromotionPage} />
            <Route path={PathName.PromotionCode} component={PromotionCodePage} />
            <Route path={PathName.SupplierManage} component={SupplierPage} />
            <Route path={PathName.CategoryManage} component={CategoryPage} />
            <Route path={PathName.DefaultCommissionManage} component={SettingsPage} />
            <Route path={PathName.AgentManage} component={AgentPage} />
            <Route path={PathName.SellerManage} component={SellerPage} />
            <Route path={PathName.AnnouncementManage} component={AnnouncementPage} />
            <Route path={PathName.WebsiteManage} component={WebsitePage} />
            <Route path={PathName.TransferManage} component={TransferPage} />
            <Route path={PathName.CustomerManage} component={CustomerPage} />
            <Route path={PathName.Settings} component={SettingsPage} />
            <Route path={PathName.ServiceManage} component={SettingsPage} />
            <Route path={PathName.PageNotFound} component={PageNotFound} />
            <Route path={PathName.CommissionManage} component={CommissionPage} />
            <Route path={PathName.SpecialManage} component={SpecialPage} />
            <Route path={PathName.SellerManagment} component={SellerPage} />
            <Route path={PathName.SellerCommission} component={CommissionPage} />
            <Route path={PathName.SpecialCommission} component={SpecialPage} />
            <Route path={PathName.ReservationManage} component={SettingsPage} />
            <Route path={PathName.PaymentManage} component={SettingsPage} />
            <Route path={PathName.UserManage} component={SettingsPage} />
            <Route path={PathName.OtherManage} component={SettingsPage} />
            <Route path={PathName.DestinationManage} component={DestinationPage} />
            <Route path={PathName.ConditionManage} component={ConditionPage} />
            <Route path={PathName.AppointmentManage} component={AppointmentPage} />
            <Route path={PathName.ActivityManage} component={ActivityPage} />
            <Route path={PathName.LocationManage} component={LocationPage} />
            <Route path={PathName.ZoneManage} component={ZonePage} />
            <Route path={PathName.CountryManage} component={CountryPage} />
            <Route path={PathName.FerriesManage} component={FerriesManage} />


            <Route path={PathName.PackageDetail} component={PackageDetail} />
            <Route path={PathName.CreatePackage} component={CreatePackage} />
            <Route path={PathName.CreateTransfer} component={CreateTransfer} />
            <Route path={PathName.TransferDetail} component={TransferDetail} />
            <Route path={PathName.PromotionInfo} component={PromotionInfo} />
            <Route path={PathName.ProviderManage} component={ProviderManage} />
            <Route path={PathName.SupplierInfo} component={SupplierInfo} />
            <Route path={PathName.CustomerInfo} component={CustomerInfo} />
            <Route path={PathName.MemberInfo} component={MemberInfo} />
            <Route path={PathName.AgentInfo} component={AgentInfo} />
            <Route path={PathName.SellerInfo} component={SellerInfo} />
            <Route path={PathName.FerriesDetail} component={FerriesDetail} />
            <Route path={PathName.CreateFerries} component={FerriesCard} />
            <Route path={PathName.ReservationTable} component={ReservationTable} />

            <Route path={PathName.Hotel} component={HotelPage} />
            <Route path={PathName.CreateHotel} component={CreateHotel} />

            <Route component={PageNotFound} />
        </Switch>
    )
}


const SupplierRoute = () => {

    return (
        <Switch>
            <Route exact path={PathName.Index} component={LoginPage} />
            <Route path={PathName.LoginPage} component={LoginPage} />
            <Route path={PathName.DashBoardPage} component={SupplierInfo} />
            <Route path={PathName.BookingManage} component={BookingPage} />
            <Route path={PathName.BookingDetail} component={BookingDetail} />
            <Route path={PathName.PackageManage} component={SupplierInfo} />
            <Route path={PathName.PackageDetail} component={PackageDetail} />
            <Route path={PathName.CreatePackage} component={CreatePackage} />
            <Route path={PathName.PromotionInfo} component={PromotionInfo} />
            <Route path={PathName.SupplierInfo} component={SupplierInfo} />
            <Route path={PathName.CreateFerries} component={FerriesCard} />
            <Route path={PathName.AccountingManage} component={AccountingPage} />
            <Route path={PathName.DestinationManage} component={DestinationPage} />
            <Route path={PathName.ConditionManage} component={ConditionPage} />
            <Route path={PathName.AppointmentManage} component={AppointmentPage} />
            <Route path={PathName.ActivityManage} component={ActivityPage} />
            <Route path={PathName.CreateTransfer} component={CreateTransfer} />
            <Route path={PathName.TransferManage} component={SupplierInfo} />
            <Route path={PathName.FerriesDetail} component={FerriesDetail} />
            <Route path={PathName.Settings} component={SettingsPage} />
            <Route component={PageNotFound} />
        </Switch>
    )
}

const AgentRoute = () => {

    return (
        <Switch>
            <Route exact path={PathName.Index} component={LoginPage} />
            <Route exact path={PathName.LoginPage} component={LoginPage} />
            <Route path={PathName.DashBoardPage} component={AgentInfo} />
            <Route path={PathName.AgentInfo} component={AgentInfo} />
            <Route path={PathName.MemberManage} component={MemberPage} />
            <Route path={PathName.BookingManage} component={BookingPage} />
            <Route path={PathName.BookingDetail} component={BookingDetail} />
            <Route path={PathName.PackageManage} component={PackagePage} />
            <Route path={PathName.PackageDetail} component={PackageDetail} />
            <Route path={PathName.CustomerInfo} component={CustomerInfo} />
            <Route path={PathName.MemberInfo} component={MemberInfo} />
            <Route component={PageNotFound} />
        </Switch>
    )
}

const SellerRoute = () => {

    return (
        <Switch>
            <Route exact path={PathName.Index} component={LoginPage} />
            <Route exact path={PathName.LoginPage} component={LoginPage} />
            <Route path={PathName.DashBoardPage} component={SellerInfo} />
            <Route path={PathName.SellerInfo} component={SellerInfo} />
            <Route path={PathName.MemberManage} component={MemberPage} />
            <Route path={PathName.BookingManage} component={BookingPage} />
            <Route path={PathName.BookingDetail} component={BookingDetail} />
            <Route path={PathName.PackageManage} component={PackagePage} />
            <Route path={PathName.PackageDetail} component={PackageDetail} />
            <Route path={PathName.CustomerInfo} component={CustomerInfo} />
            <Route path={PathName.MemberInfo} component={MemberInfo} />
            <Route path={PathName.PromotionCode} component={PromotionCodePage} />
            <Route path={PathName.AccountingManage} component={AccountingPage} />
            <Route path={PathName.Products} component={SellerPromote} />
            <Route path={PathName.SettingSeller} component={SettingsPage} />
            <Route component={PageNotFound} />
        </Switch>
    )
}

const DefaultRoute = () => {

    return (
        <Switch>
            <Route exact path={'/*'} component={LoginPage} />
        </Switch>
    )
}

export default withRouter(AppRouter);
