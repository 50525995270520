import React, { Component } from 'react'
import { equals, path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconClose from '@material-ui/icons/HighlightOff';

import FormTypes from '../../common/FormTypes';
import { Colors } from '../../themes';
import DurationCard from '../../containers/Packages/DurationCard';
import Package from '../../constant/JSON/Package';

const TimeFerries = Package.TimeFerries;

class FormSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = { StateInfo: this.props.StateInfo, }
    }

    componentWillReceiveProps(nextProps) {
        if (!equals(this.props.StateInfo, nextProps.StateInfo)) {
            this.setState({ StateInfo: nextProps.StateInfo })
        }
    };

    render() {
        const { classes, indexArray, onRemove, FormLayout, disabled } = this.props;
        return (
            <div className={classes.root}>
                {indexArray > 0 && <IconClose style={{ color: Colors.themeDark, position: 'absolute', top: 15, right: 10, zIndex: disabled ? -1 : null }} onClick={onRemove} />}
                {
                    Array.isArray(FormLayout.structure) && FormLayout.structure.map((row, index) => {
                        return (
                            <div key={index} style={{ display: 'flex' }}>
                                {row.map((each, i) => (<div key={i} style={{ width: each.type === 'date' ? '26%' : '100%' }} >
                                    {this.SetItemWithStyle(each, index)}
                                </div>))}
                            </div>
                        )
                    })
                }
            </div>

        )
    }
    SetItemWithStyle(each, index) {
        const { StateInfo, DestinationsList, AppointmentList, LanguagePackage, PackageInfo,
            error, classes, Active, Require, styleTime, LocationList, stateTime, disabled } = this.props
        let Ferries = !!PackageInfo && PackageInfo.conveyanceType === "ferries";
        let Transfer = !!PackageInfo && PackageInfo.type === "Transfer";

        switch (each.type) {
            case "text-multiline": {
                return (
                    <FormTypes.TextFormat multiline rows={6}
                        disabled={disabled}
                        Require={Active ? false : Require}
                        error={error}
                        style={{ marginBottom: 10, }}
                        TitleText={each.title_EN}
                        value={path([each.stateName], this.state.StateInfo)}
                        onChange={(e) => this.onChangeText(each.stateName, e.target.value)} />
                )
            }
            case "date": {
                return (
                    // <div style={{ marginTop: Ferries ? '3px' : '0px', marginRight: '20px' }} >
                    <div style={{ marginRight: '20px' }}>
                        {
                            Ferries ? TimeFerries.length > 0 && TimeFerries.map((each, i) => {
                                return (
                                    <DurationCard
                                        disabled={disabled}
                                        Require={Active ? false : (Ferries ? true : false)}
                                        stateTime={stateTime}
                                        key={i}
                                        style={styleTime}
                                        Ferries={Ferries}
                                        indexadd={index}
                                        each={each}
                                        packageInfo={PackageInfo}
                                        onChangeState={this.onChangeText}
                                    />
                                )
                            }) :
                                <FormTypes.TextFormat
                                    disabled={disabled}
                                    Require={Active ? false : Require}
                                    error={error}
                                    TitleText={each.title_EN}
                                    placeholder={each.placeholder_EN}
                                    value={path([each.stateName], this.state.StateInfo)}
                                    onChange={(e) => this.onChangeText(each.stateName, e.target.value)} />
                        }
                    </div>
                )
            }
            case "select": {
                let locationId = LocationList && LocationList.find(val => (val.locationServiceId === PackageInfo.locationServiceId))
                let new_appointment = locationId && AppointmentList.filter(val => (val.parentId === (locationId.code)) && (val.actived))
                let new_destination = locationId && DestinationsList.filter(val => (val.parentId === (locationId.code)) && (val.actived))

                return (
                    <FormTypes.Select
                        disabled={(!locationId && true) || disabled}
                        error={error}
                        Require={Active ? false : Require}
                        TitleText={(Transfer || Ferries) ? each.app_EN : each.des_EN}
                        value={path([each.stateName], StateInfo)}
                        onChange={(e) => this.onChangeText(each.stateName, e.target.value, index)}
                        options={(Transfer || Ferries) ?
                            Array.isArray(new_appointment) && new_appointment.map((option, i) => { return (<option key={i} value={option[LanguagePackage].name}> {option[LanguagePackage].name} </option>) })
                            :
                            Array.isArray(new_destination) && new_destination.map((option, i) => { return (<option key={i} value={option[LanguagePackage].name}> {option[LanguagePackage].name} </option>) })
                        } />
                )
            }
        }

    }

    onChangeText = (fieldName, value, index) => {
        let scheduleInfo = { ...this.state.StateInfo }
        switch (fieldName) {
            case "topic": {
                const { DestinationsList, AppointmentList, LanguagePackage, PackageInfo } = this.props;
                let Transfer = PackageInfo.type === 'Transfer';
                let Ferries = PackageInfo.type === 'Ferries';
                let newDestination = null;
                let newAppointment = null;

                if (Transfer || Ferries) {
                    if (AppointmentList) {
                        newAppointment = AppointmentList.find((_info) => value === _info[LanguagePackage].name)
                        scheduleInfo = { ...scheduleInfo, [fieldName]: value, appointmentId: newAppointment.appointmentId }
                    }
                } else {
                    if (DestinationsList) {
                        newDestination = DestinationsList.find((_info) => value === _info[LanguagePackage].name)
                        scheduleInfo = { ...scheduleInfo, [fieldName]: value, destinationId: newDestination.destinationId }
                    }
                }
            } break;
            case "time": {
                scheduleInfo = { ...scheduleInfo, [fieldName]: value }
            } break;
            case "detail": {
                scheduleInfo = { ...scheduleInfo, [fieldName]: [value] }
            } break;
        }

        this.setState({ StateInfo: scheduleInfo },
            () => { this.props.onChange(this.state.StateInfo) }
        )
    }
}
const styles = theme => (
    {
        root: { position: 'relative', border: `${'1px solid' + Colors.border}`, padding: '10px', margin: '15px 0px', },
    }
)
FormSchedule.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormSchedule);