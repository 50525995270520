export const init_appoiontment = {
    "appointmentId": "",
    "EN": {
        "name": "",
        "description": "",
        "address": ""
    },
    "TH": {
        "name": "",
        "description": "",
        "address": ""
    },
    "CN": {
        "name": "",
        "description": "",
        "address": ""
    },
    "location": {
        "latitude": "",
        "longitude": ""
    },
    "no": "",
    "road": "",
    "gallery": [],
    "picture": "",
    "parentId": "",
    "actived": false,
    "province": "",
    "district": "",
    "postCode": "",
    "country": "Thailand",
    "routingType": [],
    "packageTypeService": [],
}

