import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { path } from 'ramda';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';

import EmptyRows from '../../componets/EmptyRow.jsx';

import CONFIG from '../../config';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import DialogPanel from '../../componets/DialogPanel.jsx';
import SearchPanel from '../../componets/SearchPanel.jsx';
import { Province } from '../../constant/JSON/Province';
import { Districts } from '../../constant/JSON/District';

import * as SupplierActions from '../../actions/Actions_SupplierManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { pathToFileURL } from 'url';
import Supplier from '../../constant/JSON/Supplier';
const { BorderColorIcon, } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'name', isOrder: true, },
    { label: 'OFFICE', name: '', isOrder: false, },
    { label: 'COUNTRY', name: '', isOrder: false, },
    { label: 'RESERVAION NUMBER', name: 'reservation_number', isOrder: true, },
    { label: 'TEL OFFICE NUMBER', name: '', isOrder: false, },
    { label: 'VERIFIED', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100 };
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _wording = { header: 'Supplier Manage' }

let SupplierList = [];
let waitingTime = true;

class SupplierListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps);
        const { SupplierManager } = nextProps;

        if (!!SupplierManager) {
            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_SUCCESS:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_FAILURE:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_WITHPARENT_ID_SUCCESS:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SupplierList = this.props.SupplierList.datas
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_WITHPARENT_ID_FAILURE:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, CreatePanel, DisplaySupplierInfo, EditInfo, onClickHandle, isEmployee } = this.props;// onNextPage,onChangeLimit,
        const { datas, } = this.props.SupplierList;

        SupplierList = datas || [];
        const is_supplier = (SupplierList.length > 0);
        const { isLoading, msgError, dialogType, searchActive, } = this.state;
        const _isEmployee = (Boolean(isEmployee));

        //console.log('SupplierListPanel : ', SupplierList)

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }}
                                        buttonStyle={{ marginTop: 0, padding: 15 }} onClick={() => this.setState({ searchActive: !searchActive })} />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button className={classes.title_bar} disabled={_isEmployee} onClick={() => this.setState({ searchActive: !searchActive })}>
                                        {_wording.header}
                                    </Button>
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ margin: 5, textAlign: 'center' }} onClick={() => CreatePanel()} >
                                        <Button variant="fab" aria-label="add" className={classes.cssRoot}>
                                            <AddIcon />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow> */}
                            {(!_isEmployee) && ((searchActive) &&
                                (<TableRow>
                                    <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                        <SearchPanel searchActive={searchActive} SearchType='supplier' />
                                    </TableCell>
                                </TableRow>))}
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(is_supplier) ? SupplierList.map(_info => {

                                const { supplierId, name, userType, address, reservation_number, telOffice, logo, visible, approved, officialWebsite } = _info;
                                const _address = address && `${address['sub-district']} ${address.province}`;
                                const _province = Province.filter(province => path(['code'], province) === path(['province'], address)).map((value, index) => { return value.name_EN });
                                // console.log('_info', _info);

                                return (
                                    <TableRow hover style={{ cursor: 'pointer' }} key={supplierId} >
                                        <TableCell style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >
                                            <div style={this.getBackgroundImage(_isEmployee, path(['thumbnail'], logo))} />
                                        </TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplaySupplierInfo(_info) }} >{name}</TableCell>
                                        <TableCell className={classes.text_info} style={{ ..._callStyle, textAlign: 'left' }} onClick={() => { DisplaySupplierInfo(_info) }} >{_province || '-'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{(address && address.country) || '-'}</TableCell>
                                        {/* <TableCell   className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{officialWebsite || '-'}</TableCell> */}
                                        <TableCell className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{reservation_number || '-'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{telOffice || '-'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{approved ? 'Approved' : 'Pending'}</TableCell>
                                        <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }} onClick={() => { DisplaySupplierInfo(_info) }} >{visible ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle}>
                                            <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(_info) }} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(is_supplier) && GetPagination(this.props.SupplierList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        );
    }


    //===============================================================================================================================================================

    getBackgroundImage(_isEmployee, logo) {
        return (_isEmployee) ?
            ({ position: 'relative', boxShadow: 'rgb(170, 169, 169) 0px 0px 1px 1.9px', backgroundRepeat: 'round', width: 50, height: 50, borderRadius: '50%', backgroundImage: `url(${CheckImageURL(logo)})`, backgroundRepeat: 'round', margin: 5 }) :
            ({ width: 70, height: 50, backgroundColor: 'gray', backgroundImage: `url(${CheckImageURL(logo)})`, backgroundRepeat: 'round', border: '1px solid #828181' });
    }

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        //console.log('handleRequestSort : ', _fildeName, SupplierList)

        SupplierList = (orderBy === 'desc')
            ? SupplierList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : SupplierList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        if (Boolean(this.props.isEmployee)) {
            let _parentId = Array.isArray(this.props.SupplierList.datas) ? this.props.SupplierList.datas.filter((each) => each.parentId).map((value) => value.parentId) : []
            // console.log('parentId', _parentId[0]);
            this.props.getAPISupplierList_WithParentId({ page: 0, num_limit: _limitRows, where: { parentId: _parentId[0] } });
        } else {
            this.props.getAPISupplierList({ page: 0, num_limit: _limitRows });
        }
    }

    onNextPage = (_pageNumber, _limit) => {
        if (Boolean(this.props.isEmployee)) {
            let _parentId = Array.isArray(this.props.SupplierList.datas) ? this.props.SupplierList.datas.filter((each) => each.parentId).map((value) => value.parentId) : []
            this.props.getAPISupplierList_WithParentId({ page: _pageNumber, num_limit: _limit, where: { parentId: _parentId[0] } });
        } else {
            this.props.getAPISupplierList({ page: _pageNumber, num_limit: _limit });
        }
        // this.setState({ isLoading: true });
        // this.props.getAPISupplierList({ page: _pageNumber, num_limit: _limit });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

const CheckImageURL = (_url) => (Boolean(_url) ? `${CONFIG.host}${_url}` : '');

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, pageSize } = _ListCount;
    // console.log('_ListCount', _ListCount);
    return (
        <TablePagination
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const styles = theme => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            marginBottom: 10,
        },
        table: { minWidth: 700, },
        title_bar: { backgroundColor: Colors.theme, color: Colors.white, width: '100%', height: '56px', },
        cssRoot: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500], '&:hover': { backgroundColor: blue[700], },
        },
    });

SupplierListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPISupplierList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
        getAPISupplierList_WithParentId: (_keyword, _fliter) => {
            dispatch(SupplierActions.getSupplierList_WithParentId(_keyword, _fliter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SupplierListPanel));

// {supplierId,name,address,tel_office,reservation_number,emergency_contact,logo,actived,visible,approved,userId,userType,items}

// {
//     "supplierId": "SPR-20180426-000003",
//     "name": "kae4",
//     "address": {
//         "no": "1",
//         "road": "1",
//         "district": "1",
//         "sub-district": "1",
//         "province": "1",
//         "post-code": "1",
//         "country": "1"
//     },
//     "tel_office": "0000000000",
//     "reservation_number": "",
//     "emergency_contact": {},
//     "logo": "",
//     "actived": true,
//     "visible": true,
//     "approved": false,
//     "userId": "5ae16faa63fb8105c8bac5ee",
//     "userType": "member",
//     "items": []
// }

// {
//     "username": "",
//   "password": "",
//     "email": "",
//     "userType": "supplier",
//     "level": "0",
//      "user":{
//       "supplierCode": "SC-001",
//       "name": "",
//       "address": {
//         "no": "",
//         "road": "",
//         "district": "",
//         "sub-district": "",
//         "province": "",
//         "post-code": "",
//         "country": ""
//       },
//       "telOffice": "",
//       "reservation_number": "",
//       "emergencyContact": [],
//       "officialWebsite": "",
//       "logo": ""
//     }
//   }