import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import CONFIG from '../../config';

import EmptyRows from '../../componets/EmptyRow.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

// import { BorderColorIcon } from '../../themes/Icons';
import { Colors, Icons } from '../../themes';

import * as ActivityActions from '../../actions/Actions_Activity';

import Language from '../../constant/Language';
const { BorderColorIcon } = Icons;

const fildeList = [
    { label: 'NAME', name: 'name', isOrder: true, }, //NAME (EN) 
    { label: 'DESCRIPTION', name: 'description', isOrder: true, },
    { label: 'STATUS', name: 'active', isOrder: true, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '15px', paddingRight: '5px', minWidth: 200 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };

const _wording = { header: 'ActivityList Manage' }

let ActivityList = [];
let waitingTime = true;

class ActivityListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    componentWillReceiveProps(nextProps) {
        const { ActivityManager, UpLoadManager } = nextProps;
        if (!!ActivityManager) {
            const { actions, data } = ActivityManager.ACTIVITY_REDUCER;

            switch (actions) {
                case ActivityActions.GET_ACTIVITY_SUCCESS:
                    {
                        ActivityList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case ActivityActions.GET_ACTIVITY_FAILURE:
                    { SetStateWithTime(this, waitingTime) } break;
            }
        }

    }

    render() {
        const { classes, CreatePanel, EditInfo, onClickHandle } = this.props;
        const { datas, } = this.props.ActivityList;

        ActivityList = datas || [];
        const _isActivity = (ActivityList.length > 0);

        const { isLoading, msgError, dialogType } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }}
                                    // onClick={() => this.setState({ searchActive: !searchActive })} 
                                    />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {(_isActivity) ? ActivityList.map(info => {
                                const { id, actived } = info;
                                const LanguageActivity = path(['language',], this.props.ActivityList);
                                const NAME = info[LanguageActivity].name;
                                const DESCRIPTION = info[LanguageActivity].description;

                                return (
                                    <TableRow hover style={{ cursor: 'pointer' }} key={id} >
                                        <TableCell style={_callName} onClick={() => { { onClickHandle(info) } }} >{NAME || '-'}</TableCell>
                                        <TableCell style={_callName} onClick={() => { { onClickHandle(info) } }} >{DESCRIPTION || '-'}</TableCell>
                                        <TableCell style={{ ..._callStyle, ...{ color: (actived) ? Colors.success : Colors.error } }} onClick={() => { { onClickHandle(info) } }} >{actived ? 'Actived' : 'Inactived'}</TableCell>
                                        <TableCell style={_callStyle}>
                                            <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(info) }} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(_isActivity) && GetPagination(this.props.ActivityList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
                </Paper>
            </React.Fragment>
        );
    }

    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        ActivityList = (orderBy === 'desc')
            ? ActivityList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : ActivityList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIActivityList({ page: 0, num_limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIActivityList({ page: _pageNumber, num_limit: _limit, language: _language });
    }
    onChangeLanguage = (_language) => {
        this.setState({ isLoading: true });
        this.props.getAPIActivityList({ page: 0, num_limit: 10, language: _language });
    }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {
    const { total, page, limit, pageSize, language } = _ListCount;
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
}

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', marginBottom: 10, },
        table: { minWidth: 1024, },
        });

ActivityListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIActivityList: (_keyword) => {
            InitWaitingTime();
            dispatch(ActivityActions.getActivityList(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ActivityListPanel));