export const SupplierType = [
    {
        "type": "Day Tour",
        "active": true,
        "key": 'OneDayTrip',
        "id": "5af3ce5fcb05af349c4202de"
    },
    {
        "type": "Ferries",
        "active": true,
        "key": 'Ferries',
        "id": "5af3ce90cb05af349c4202df"
    },
    {
        "type": "Service",
        "active": true,
        "key": 'Service',
        "id": "5af3cea1cb05af349c4202e0"
    },
    {
        "type": "Transfer",
        "active": true,
        "key": 'Transfer',
        "id": "5af3cea1cb05af349c4202e1"
    }
]
