import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { SetStateWithTime, } from '../../common/SetTime.js';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '../../constant/Button';

import { GetSymbol } from '../../common/GetSymbol.js';
import EmptyRows from '../../componets/EmptyRow.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';
import CONFIG from '../../config';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import { Family, Fonts, Colors, Icons, } from '../../themes';
import styled from 'styled-components';
const { DoneIcon, CloseIcon, } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'PACKAGE NAME', name: 'name_EN', isOrder: true, }, //NAME (EN) 
    // { label: 'PROMOTION', name: 'description', isOrder: true, },
    // { label: 'TYPE', name: 'name', isOrder: false, },//NAME (TH)
    { label: 'PRICE', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, },
    // { label: 'RATING', name: '', isOrder: false, },
    // { label: 'STATUS', name: 'active', isOrder: true, },
];

const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callName = { textAlign: 'left', padding: '5px', minWidth: 100, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', };
const _callStyle = { textAlign: 'center', padding: '3px', paddingLeft: '5px', paddingRight: '5px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', };
const _wording = { header: 'Pack Promotion List.' };

let packageInfoList = [];
let waitingTime = false;

class DiscountWithProdutListPanel extends Component {

    state = { isLoading: false, isCreate: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {

        // console.log('nextProps', nextProps);
        const { PackageManager, } = nextProps;

        // if (!!PackageManager) {

        //     const { actions, data } = PackageManager.PACKAGE_REDUCER;

        //     switch (actions) {
        //         case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
        //             {
        //                 packageInfoList = data.result;
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //         case PackageAction.GET_PACKAGE_LISTS_FAILURE:
        //             { SetStateWithTime(this, waitingTime); } break;
        //     }
        // }
    }

    render() {

        const { classes, onClickHandle, StateInfo, isPackageLoading, displayType } = this.props;
        packageInfoList = (!!this.props.packageInfoList) ? (this.props.packageInfoList.packageList || []) : [];
        const isPackageInfo = (packageInfoList.length > 0);

        const { isLoading, msgError, dialogType, orderBy } = this.state;
        // console.log('DiscountWithProdutListPanel : ', isPackageLoading);
        // console.log('DiscountWithProdutListPanel : ', packageInfoList);

        return (
            <React.Fragment>
                {/* {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />} */}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                        </TableHead>
                        <TableBody>
                            {(!isPackageLoading) ? this.PackageLoadingPanel() : (isPackageInfo) ? GetBookingRows(packageInfoList, onClickHandle, classes, StateInfo, displayType) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                </Paper>
            </React.Fragment>
        );
    }

    PackageLoadingPanel() {

        return (
            <TableRow >
                <div style={{ textAlign: 'center', padding: '30px 0px', height: 100 }}>
                    <CircularProgress color="secondary" />
                </div>
            </TableRow>)
    }

    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        // const { packageInfoList } = this.props;
        //console.log('handleRequestSort : ', _fildeName, packageInfoList)

        packageInfoList = (orderBy === 'desc')
            ? packageInfoList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : packageInfoList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ page: 0, limit: _limitRows, isPackageInfo: true, });
    }

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ page: _pageNumber, limit: _limit, isPackageInfo: true, });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

function GetBookingRows(packageInfoList, onClickHandle, classes, StateInfo, displayType) {

    const isDisableTextField = (displayType === "autoSet");
    const { discountType, discountValue, currency, checkProduct, product, discountEachProduct, isDiscountEachProduct } = StateInfo;

    if (checkProduct)
        packageInfoList = packageInfoList.filter((_package) => product.includes(_package.packageId));

    return packageInfoList.map(_package => {

        const { packageId, packageInfo, type, isPackageInfo, rating, visible } = _package;
        const { name, image } = packageInfo;

        let defaultType;
        let defaultValue;

        // console.log('isDiscountEachProduct : ', isDiscountEachProduct, ' : ', checkProduct);

        if (isDiscountEachProduct || checkProduct) {
            const oldDiscount = discountEachProduct.find((product) => product.packageId === packageId);

            if (oldDiscount === undefined) {
                defaultValue = discountValue || 0;
                defaultType = discountType;
            }
            else {
                defaultValue = oldDiscount.discountValue;
                defaultType = oldDiscount.discountType;
            }
        }
        else {
            defaultValue = discountValue || 0;
            defaultType = discountType;
        }

        // onClick={() => onClickHandle(packageId)} 

        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={packageId} onClick={() => { }} >
                <TableCell style={{ ..._callStyle, width: 70 }} ><ImageProduct image={`url(${checkURL(image)})`} /></TableCell>
                <TableCell style={_callName}>{name || ''}</TableCell>
                {/* <TableCell style={{ ..._callStyle, color: isPackageInfo ? Colors.success : Colors.error }} >{isPackageInfo ? <DoneIcon /> : <CloseIcon />}</TableCell> */}
                {/* <TableCell className={classes.text_info} >{type}</TableCell> */}
                <TableCell className={classes.text_info} >
                    <TextField
                        id="standard-number"
                        label="Discount"
                        disabled={isDisableTextField}
                        defaultValue={defaultValue}
                        type="number"
                        className={classes.text_field}
                        InputLabelProps={{ shrink: true, }}
                        margin="normal"
                        onChange={(e) => SetProductDiscount(packageId, defaultType, e.target.value, StateInfo)}
                    />
                </TableCell>
                <TableCell className={classes.text_symbol} >{(defaultType === "percent") ? '%' : GetSymbol(currency)}</TableCell>
                {/* <TableCell className={classes.text_info} >{rating.point}</TableCell> */}
                {/* <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }}>{visible ? 'Active' : 'Inactive'}</TableCell> */}
            </TableRow>
        );
    });
}

function SetProductDiscount(packageId, type, discount, StateInfo) {

    const { discountEachProduct = [] } = StateInfo;
    const discountFormat = { packageId: packageId, discountType: type, discountValue: discount };
    const index = discountEachProduct.findIndex((product) => (product.packageId === packageId));

    if (index === -1)
        discountEachProduct.push(discountFormat);
    else
        discountEachProduct[index] = discountFormat;

    // console.log(index);
    // console.log(discountEachProduct);
}

function GetSortIcon(orderBy, field, handleRequestSort) {

    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 25}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
    {
        root: { width: '100%', marginTop: 0, overflowX: 'auto', },
        table: { width: '100%' },//minWidth:1024
        text_info: { textAlign: 'center', padding: '0px', fontFamily: Family.roboto, },
        text_symbol: { textAlign: 'center', padding: '30px 5px 0px 0px', fontFamily: Family.roboto, },
        text_field: { padding: '0px', width: '80px', margin: '0px', }
    });

DiscountWithProdutListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIpackageInfoList: (_fliter) => {
            // InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_fliter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DiscountWithProdutListPanel));


const ImageProduct = styled.div`
width: 70px;
height: 50px;
background-color: ${Colors.defaultImage};
background-image: ${props => props.image};
background-repeat: round;`