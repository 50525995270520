export const constraints = {
    reasons: {
        presence: { allowEmpty: false, },
    },

};
export const input = [
    { fieldName: 'reasons', },

];
