import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { UpperCaseFormats } from '../../common/SetUpperCaseFormat.js';
import { NumberFormats } from '../../common/SetNumberFormat.js';

import DialogPanel from '../../componets/DialogPanel.jsx';
import StepperComponent from '../../componets/StepperComponent.jsx';
import RequestComponent from '../../componets/RequestComponent.jsx';

import * as RefundActions from '../../actions/Actions_RefundManager';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let isBtnActive = false;
let waitingTime = true;

class RefundRequestPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, msgError: undefined, dialogType: undefined,
            newData: {
                status: '',
                remark: ''
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { RefundManager } = nextProps;

        if (!!RefundManager) {
            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.GET_REFUND_REQUEST_LISTS_SUCCESS:
                    {
                        RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_REQUEST_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.UPDATE_REFUND_SUCCESS:
                    {
                        RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE_REFUND_SUCCESS', dialogType: 'success', });
                        this.props.APIRefundRequestList({ num_limit: 25 });
                    } break;
                case RefundActions.UPDATE_REFUND_FAILURE:
                    {
                        const _msg = data.response.data.error || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }
    render() {
        const { onSavePanel } = this;
        const { requestInfo, isView, onClosePanel } = this.props;
        const { newData, isLoading, msgError, dialogType } = this.state;
        const { description, refundFormBy, refundStatus, refundBy, policyReasons, customerDetail } = requestInfo;

        isBtnActive = (!newData.status) && true;

        const InfoLists = [
            { title: 'Customer Name : ', value: customerDetail.fullName },
            { title: 'Refund Reasons : ', value: policyReasons.EN.reasons },
            { title: 'Remark : ', value: description },
            { title: 'Refund Form : ', value: refundFormBy },
            { title: 'Refund By : ', value: refundBy },
            { title: `${UpperCaseFormats(0, refundBy) + ' Name : '}`, value: rendeRefundBy(requestInfo).refundBy },
            { title: 'Amount : ', value: rendeRefundBy(requestInfo).amount },
        ];

        const Structure = [
            [{
                title_EN: "Status Refund", title_TH: "", title_CN: "",
                type: "select",
                require: true,
                value: path(['status'], newData),
                onChange: this.onChangeState('status'),
                options: renderOptions(refundStatus).map((option, i) => (<option key={i} value={option.value} >  {option.label}</option>)),
            }],
            [{
                title_EN: "Remark", title_TH: "", title_CN: "",
                type: "text-multiline",
                require: false,
                value: path(['remark'], newData),
                onChange: this.onChangeState('remark')
            }],
        ];


        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {
                    (isView) ?
                        <StepperComponent
                            activeStep={mapRefundStatus(refundStatus)}
                            steps={mapStepArray(refundStatus)}
                            component={renderRequestComponent(true, { Structure, InfoLists, onClosePanel, onSavePanel })} />
                        :
                        renderRequestComponent(false, { Structure, InfoLists, onClosePanel, onSavePanel })
                }
            </React.Fragment>
        )
    }


    onChangeState = (fieldName) => (event) => {
        let newData = { ...this.state.newData };

        switch (fieldName) {
            default: {
                newData = { ...newData, [fieldName]: event.target.value }
            } break;
        }
        this.setState({ newData })
    };

    onSavePanel = () => {
        const { refundId } = this.props.requestInfo;
        const { remark, status } = this.state.newData;
        let newData;

        if (status) {
            newData = {
                refundId: refundId,
                remark: remark,
                refundStatus: status
            }

            this.setState({ isLoading: true }, () => this.props.APIUpdateRefund(newData))
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error' })
        }
    };

    onCloseDialog = () => { this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }) }
};

function renderRequestComponent(action, props) {
    const { Structure, InfoLists, onClosePanel, onSavePanel } = props;

    return (
        <RequestComponent Action={action}
            Header={'Refund Request'}
            Structure={Structure}
            InfoLists={InfoLists}
            onClickCancel={onClosePanel}
            onClickSave={() => onSavePanel()}
            BtnSaveActive={isBtnActive} />
    )
};


function rendeRefundBy(props) {
    const { customerRequestData, customerDetail, refundBy, amount, total } = props;
    let _refundBy = null;
    let _amount = 0;

    switch (refundBy) {
        case 'supplier': {
            _refundBy = customerRequestData.name
            _amount = total
        };
        case 'customer': {
            _amount = amount
            _refundBy = customerDetail.fullName
        };
        case 'admin': {
            _amount = total
        };
    };
    return { refundBy: _refundBy, amount: `${NumberFormats(_amount || 0)} Bath` }
};

function renderOptions(refundStatus) {
    let optionsRefund = [];

    switch (refundStatus) {
        case 'request': {
            optionsRefund = [
                { value: 'in-process', label: "in-process" },
                { value: 'success', label: "success" },
                { value: 'refuse', label: "refuse" },
            ];
        } break;
        case 'in-process': {
            optionsRefund = [
                { value: 'success', label: "success" },
                { value: 'refuse', label: "refuse" },
            ];
        } break;
    };

    return optionsRefund;
};

function mapRefundStatus(status) {
    switch (status) {
        case 'success': return 2;
        case 'in-process': return 1;
        default: return 0;
    }
};

function mapStepArray(status) {
    switch (status) {
        default: return ["request", "in-process", "success"];
    }
};

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIRefundRequestList: (_filter) => {
            InitWaitingTime();
            dispatch(RefundActions.APIRefundRequestList(_filter));
        },
        APIUpdateRefund: (_confirmInfo) => {
            dispatch(RefundActions.APIUpdateRefund(_confirmInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundRequestPanel);
