// ===================================================================================================================================
// TravisGo
// ===================================================================================================================================

import { api } from '../../api';

// process.env.NODE_ENV === `development`;
const isDevelop = false;
const buildVer = (isDevelop) ? '1.0.9' : '1.0.6';

// console.log(api)

const port = '3030';
const host = (isDevelop) ? `https://tvg-api.poysoft.com` : `https://tvg-api.poysoft.com`;
const webURL = (isDevelop) ? `https://travis-go.firebaseapp.com` : `https://www.travisgo.com`;
const LBhost = (isDevelop) ? `https://tvg-api.poysoft.com/lb` : `https://tvg-api.poysoft.com/lb`;
const LBheader = { 'Content-Type': 'application/json', 'Accept': 'application/json' };

const EP_token = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1MjQ3NDExNzMsImRhdGEiOiJ0cmF2aWdvYWRtaW5AZ21haWwuY29tIiwiaWF0IjoxNTI0NzIzMTczfQ.6YszNF-KAeCqSE_qiIHl-FRDjJMsau-XwTvgdKivM2Q`;

let _info = JSON.parse(localStorage.getItem('UserInfo'));
const LB_token = (!!_info) ? _info.token : '';

// ===================================================================================================================================

// ===================================================================================================================================

export {
    host,
    webURL,
    api,
    LBhost,
    EP_token,
    LB_token,
    LBheader,
    isDevelop,
    buildVer,
    // default: defaultSetting,
};

// ===================================================================================================================================