import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_DESTINATIONS_SUCCESS = 'GET_DESTINATIONS_SUCCESS';
export const GET_DESTINATIONS_FAILURE = 'GET_DESTINATIONS_FAILURE';

export const CREATE_DESTINATIONS_SUCCESS = 'CREATE_DESTINATIONS_SUCCESS';
export const CREATE_DESTINATIONS_FAILURE = 'CREATE_DESTINATIONS_FAILURE';

export const UPDATE_DESTINATIONS_SUCCESS = 'UPDATE_DESTINATIONS_SUCCESS';
export const UPDATE_DESTINATIONS_FAILURE = 'UPDATE_DESTINATIONS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getDestinationsList =====================================================================================================================

export function getDestinationsList(_keyword = '', _fileterjson = {}) {

    //console.log("[API] getDestinationsList :", `${CONFIG.LBhost}${CONFIG.api.getDestinationsList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getDestinationsList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getDestinationsList : ", response);
            localStorage.setItem('DestinationsList', response.data)
            dispatch(getDestinationsList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getDestinationsList Failure", error);
            dispatch(getDestinationsList_Failure(error));
        })
    }
}

function getDestinationsList_Success(data) {
    return { type: GET_DESTINATIONS_SUCCESS, payload: data };
}

function getDestinationsList_Failure(error) {
    return { type: GET_DESTINATIONS_FAILURE, payload: error };
}

// getDestinationsList ========================================================================================================================


export function APICreateDestinations(confirmInfo) {

    console.log('APICreateDestinations : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createDestinations}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateDestinations : ", response.data);
            dispatch(APICreateDestinations_Success(response.data));

        }).catch((error) => {
            console.log("APICreateDestinations fail", error);
            dispatch(APICreateDestinations_Failure(error));
        })
    }
}

function APICreateDestinations_Success(data) {
    return { type: CREATE_DESTINATIONS_SUCCESS, payload: data };
}

function APICreateDestinations_Failure(error) {
    return { type: CREATE_DESTINATIONS_FAILURE, payload: error };
}

// updateDestinationsList =========================================================================================================================

export function APIUpdateDestinations(confirmInfo) {
    console.log('APIUpdateDestinations : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateDestinations}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { destinationId: confirmInfo.destinationId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateDestinations : ", response.data);
            dispatch(APIUpdateDestinations_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateDestinations fail", error);
            dispatch(APIUpdateDestinations_Failure(error));
        })
    }
}

function APIUpdateDestinations_Success(data) {
    return { type: UPDATE_DESTINATIONS_SUCCESS, payload: data };
}

function APIUpdateDestinations_Failure(error) {
    return { type: UPDATE_DESTINATIONS_FAILURE, payload: error };
}