export const init_sellerType = {
    "sellerTypeId": "",
    "name": "",
    "remark": "",
    "picture": {
        "original": '',
        "thumbnail": '',
    },
    "isCommission": false,
    "visible": false,
}