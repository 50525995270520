import React, { Component } from 'react';
import { connect } from 'react-redux';


import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import Button from '../../constant/Button';
import SearchPanel from '../../componets/SearchPanel.jsx';
import EmptyRows from '../../componets/EmptyRow.jsx';
import { GetSymbol } from '../../common/GetSymbol.js';
import DialogPanel from '../../componets/DialogPanel.jsx';
import { mapPaymentType } from '../../common/PaymentMethod';
import { SetDate, SetDateShort } from '../../common/SetDate';
import { NumberFormats } from '../../common/SetNumberFormat';
import { GetStatusColor } from '../../common/GetStatusColor';
import SortHandle from '../../componets/TableManage/SortHandle.jsx';

import * as BookingAction from '../../actions/Actions_BookingManager';

import { Colors } from '../../themes/index.js';
// import { isMobile } from '../common/DeviceCheck.js';
import { InitwaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

const fildeLists = [
    // { label: 'BOOKING ID', name: 'booking id', isOrder: false, },
    { label: 'BOOKING DATE', name: '', isOrder: false, },
    { label: 'PACKAGE NAME', name: '', isOrder: false, },
    { label: 'BOOKING BY', name: '', isOrder: false, },
    { label: 'TRAVAL DATE', name: 'date', isOrder: true, },
    { label: 'TRAVELLER', name: '', isOrder: false, },
    { label: 'AMOUNT', name: '', isOrder: false, },
    { label: 'COMMISSION', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: true, },];

let bookingList = [];
let waitingTime = false;

const _callStyleMobile = { textAlign: 'center', padding: 2, fontSize: 11, width: 50, border: `${'1px solid' + Colors.white}` };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', border: `${'1px solid' + Colors.white}` };
const _callNameMobile = { textAlign: 'left', padding: 2, fontSize: 11, maxWidth: 80, border: `${'1px solid' + Colors.white}` };
const _wording = { header: 'BOOKING MANAGE', tap: 'DAY TRIPS' }
const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '15px', maxWidth: 280, border: `${'1px solid' + Colors.white}` };



class BookingSellerLists extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: true }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {

        console.log('nextProps', nextProps);

        const { BookingManager } = nextProps;

        if (!!BookingManager) {
            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingAction.GET_BOOKING_LISTS_SUCCESS:
                    {
                        bookingList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

    }

    render() {
        const { classes, BookingList, BookingDetail, userInfo, isMobile } = this.props;
        const { isLoading, msgError, dialogType, orderBy, searchActive } = this.state;

        bookingList = BookingList.bookingList || [];
        console.log('isMobli', isMobile);
        // const _isSeller = Boolean(userInfo);
        const _isBooking = (bookingList.length > 0);
        const isCallStyle = (isMobile) ? _callStyleMobile : _callStyle;


        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}

                <Paper className={classes.root}>
                    <Table className={(isMobile) ? classes.tableMobile : classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeLists.length} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0 }}
                                        onClick={() => this.setState({ searchActive: !searchActive })} />
                                    {(searchActive) && <SearchPanel searchActive={searchActive} SearchType='booking' userInfo={userInfo} />}
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                {fildeLists.map((filde, index) => {
                                    return (
                                        <TableCell key={index} className={classes.customTable} style={{ paddingRight: (filde.isOrder) ? 5 : 15 }}>
                                            <SortHandle style={{ color: Colors.white, paddingLeft: 10 }} orderBy={orderBy} field={filde} handleRequestSort={this.handleRequestSort} />
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(_isBooking) ? GetBookingRows(classes, bookingList, BookingDetail, isMobile) : <EmptyRows fildeList={fildeLists} />}
                        </TableBody>
                    </Table>
                    {(_isBooking) && GetPagination(BookingList, this.onNextPage, this.onChangeLimit, userInfo)}
                </Paper>
            </React.Fragment>
        )

    }
    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        console.log('handleRequestSort : ', sortFilde, _fildeName, bookingList)
        if (_fildeName === 'date') {
            bookingList = (orderBy === 'desc')
                ? bookingList.sort((a, b) => (b.packageInfo[0].bookingInfo[_fildeName] < a.packageInfo[0].bookingInfo[_fildeName] ? -1 : 1))
                : bookingList.sort((a, b) => (a.packageInfo[0].bookingInfo[_fildeName] < b.packageInfo[0].bookingInfo[_fildeName] ? -1 : 1));
        } else {
            bookingList = (orderBy === 'desc')
                ? bookingList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
                : bookingList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));
        }

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onNextPage = (_pageNumber, limit, userInfo) => {

        this.setState({ isLoading: true });
        console.log('onNextPage : ', userInfo)

        if (!!userInfo)
            this.props.getAPIBookingLists('', { affiliateId: userInfo.sellerId, page: _pageNumber, limit: (limit || 25) });
        else
            this.props.getAPIBookingLists('', { page: _pageNumber, limit: (limit || 25) });
    }

    onChangeLimit = (_limitRows, userInfo) => {

        this.setState({ isLoading: true });
        console.log('onChangeLimit : ', userInfo)

        if (!!userInfo)
            this.props.getAPIBookingLists('', { affiliateId: userInfo.sellerId, page: 0, limit: _limitRows });
        else
            this.props.getAPIBookingLists('', { page: 0, limit: _limitRows });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

}



function GetBookingRows(classes, bookingList, BookingDetail, isMobile) {

    return bookingList.map(_booking => {
        const { bookingId, bookingNo, packageInfo, paymentInfo, bookingStatus, buyerInfo, priceTotal, date, promotionCode, affiliateChannel, commissionLog = {} } = _booking;
        const { bookingInfo, isPromotion, name, promotion, price } = packageInfo[0];
        const { adult = 0, kid = 0, infant = 0, date: bookingDate, } = bookingInfo;
        const { subTotal, discount, net, currency } = priceTotal;
        const { subPaymentMethod, paymentMethod } = paymentInfo;
        const { affiliatePrice } = commissionLog;

        const isPrice = (isPromotion) ? promotion : price;
        const { adult: adultPrice, kid: kidPrice } = isPrice;

        const isCallStyle = isMobile ? _callStyleMobile : _callStyle;
        const isCallNameStyle = isMobile ? _callNameMobile : _callName;
        const comitionChannel = (affiliateChannel === 'code') ? (Boolean(promotionCode) ? `Code [${promotionCode}]` : 'Link') : 'Link';
        // console.log('_booking', _booking);

        let traveller = adult + kid + infant;
        return (
            <TableRow hover tabIndex={-1} className={classes.row} key={bookingId} style={{ cursor: 'pointer', }} onClick={() => { BookingDetail(bookingId) }}>
                {/* <TableCell    style={isCallStyle}>{bookingNo || bookingId}</TableCell> */}
                <TableCell style={isCallStyle}>{SetDate(date)}</TableCell>
                <TableCell style={isCallNameStyle} >{name || '-'}</TableCell>
                <TableCell style={isCallStyle}>{comitionChannel || '-'}</TableCell>
                <TableCell style={isCallStyle}>{SetDateShort(bookingDate)}</TableCell>
                <TableCell style={isCallStyle}>{traveller || '-'}</TableCell>
                <TableCell style={GetStatusColor('Transaction', isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(subTotal || net)}`}</TableCell>
                <TableCell style={GetStatusColor('Transaction', isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(affiliatePrice || 0)}`}</TableCell>
                <TableCell style={GetStatusColor('Transaction', isCallStyle, bookingStatus)}>{bookingStatus || '-'}</TableCell>
            </TableRow >)
    });
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, userInfo) {

    const { limit, page, pageSize, total } = _ListCount;

    return (<TablePagination
        component="div"
        count={total || 0}
        rowsPerPage={limit || 25}
        page={page || 0}
        backIconButtonProps={{ 'aria-label': 'Previous Page', }}
        nextIconButtonProps={{ 'aria-label': 'Next Page', }}
        onChangePage={(event, page) => { onNextPage(page, limit, userInfo) }}
        onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, userInfo) }} />)
}

const styles = theme => (
    {
        root: { width: '100%', marginTop: (theme.spacing.unit * 3), marginTop: '0px', maxWidth: 1024 },
        table: { minWidth: 1024, },
        tableMobile: { width: '100%' },
        textField: { width: 400, },
        customTable: {
            textAlign: 'center',
            paddingLeft: '5px',
            paddingRight: '5px',
            backgroundColor: Colors.themeDark,
            color: Colors.white,
            border: `${'1px solid' + Colors.white}`
        },
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: Colors.themeDark_Light,
            },
            '&:nth-of-type(odd):hover': {
                backgroundColor: Colors.themeDark_Item,
            },
            // '&$disabled'
        },
    });
BookingSellerLists.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingDetails: (_id) => {
            dispatch(BookingAction.getAPIBookingDetail(_id));
        },
        getAPIBookingLists: (_keyword, _filter) => {
            dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingSellerLists));
