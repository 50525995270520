import React, { Component } from 'react';
import { connect } from 'react-redux';

import { path } from 'ramda';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CONFIG from '../../config';

import EmptyRows from '../../componets/EmptyRow.jsx';
import SearchPanel from '../../componets/SearchPanel.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

import { Fonts, Colors,Icons } from '../../themes';
import * as AgentAction from '../../actions/Actions_AgentManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
const { BorderColorIcon } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'name', isOrder: true, },
    { label: 'EMAIL', name: 'email', isOrder: true, },
    { label: 'TEL.', name: '', isOrder: false, },
    { label: 'GROUP', name: '', isOrder: false, },
    { label: 'LEVEL', name: 'level', isOrder: true, },
    { label: 'VERIFIED', name: '', isOrder: false, },
    { label: 'ACTIVED', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', minWidth: 160 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };
const _wording = { header: 'Agent Manage' }

let AgentList = [];
let waitingTime = true;

class AgentListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {

        const { AgentManager } = nextProps;
        // console.log(`SellerManager [componentWillReceiveProps] : `, SellerManager, );

        if (!!AgentManager) {
            const { actions, data } = AgentManager.AGENT_REDUCER;
            switch (actions) {
                case AgentAction.GET_AGENT_SUCCESS:
                    {
                        AgentList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AgentAction.GET_AGENT_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, CreatePanel, DisplayAgentInfo, EditInfo, onClickHandle, Level } = this.props;// onNextPage 
        const { datas, } = this.props.AgentList;

        AgentList = datas || [];
        const isAgent = (AgentList.length > 0);

        const { isLoading, msgError, dialogType, orderBy, searchActive } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} onClick={() => this.setState({ searchActive: !searchActive })} />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            {(searchActive) &&
                                (<TableRow>
                                    <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                        <SearchPanel searchActive={searchActive} SearchType='agent' />
                                    </TableCell>
                                </TableRow>)}
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isAgent) ? AgentList.map(_info => {
                                const { agentId, name, telOffice, email, group, level, logo, username, reservationNumber, emergencyContact, officialWebsite, priceGroup, tag, affiliateId, status, creditTermId, credit, actived, visible, approved, id, userId } = _info;
                                let levelList = Array.isArray(path(['datas'], Level)) ? path(['datas'], Level).filter((val) => val.defaultCommissionId === level) : [];
                                // console.log('levelList', levelList);
                                return (
                                    <TableRow hover style={{ cursor: 'pointer' }} key={agentId} >
                                        <TableCell style={{ ..._callStyle, ...{ minWidth: 80, paddingRight: 5 } }} onClick={() => { DisplayAgentInfo(_info) }} >
                                            <div style={this.getBackgroundImage(logo.thumbnail)} />
                                        </TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplayAgentInfo(_info) }} >{username || name}</TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplayAgentInfo(_info) }} >{email || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplayAgentInfo(_info) }} >{telOffice || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplayAgentInfo(_info) }} >{group || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplayAgentInfo(_info) }} >{path([0, 'levelName'], levelList) || '-'}</TableCell>
                                        <TableCell style={_callStyle} onClick={() => { DisplayAgentInfo(_info) }} >{approved ? 'Approved' : 'Pending'}</TableCell>
                                        <TableCell style={{ ..._callStyle, ...{ color: (actived) ? Colors.success : Colors.error } }} onClick={() => { }} >{actived ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell style={_callStyle}>
                                            <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(_info) }} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(isAgent) && GetPagination(this.props.AgentList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        );
    }


    //===============================================================================================================================================================

    getBackgroundImage = (logo) => ({ width: '50px', height: '50px', backgroundColor: 'gray', borderRadius: '50%', backgroundImage: `url(${CheckImageURL(logo)})`, backgroundRepeat: 'round', border: '1px solid #828181' })

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        // console.log('handleRequestSort : ', _fildeName, AgentList)

        AgentList = (orderBy === 'desc')
            ? AgentList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : AgentList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        this.props.getAPIAgentList({ page: 0, num_limit: _limitRows });
    }

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });
        this.props.getAPIAgentList({ page: _pageNumber, num_limit: _limit });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

const CheckImageURL = (_url) => (Boolean(_url) ? `${CONFIG.host}${_url}` : '');

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, pageSize } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const styles = theme => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            marginBottom: 10,
        },
        table: { minWidth: 700, },
    });

AgentListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIAgentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AgentAction.getAgentList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AgentListPanel));
