import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '../../themes';
import '../../themes/Themes.css';
import { isMobile } from '../../common/DeviceCheck';

const styles = theme => (
    {
        button: {
            color: Colors.white,
            padding: 10,
            fontSize: isMobile() ? 12 : 14,
            width: '100%',
        }
    }
);

const Themes = (props) => {
    const { classes, isTable, FullWidth } = props;
    return (
        <div style={{ width: isMobile() ? (FullWidth ? '100%' : 100) : ((isTable || FullWidth) ? '100%' : 150), cursor: 'pointer', ...props.style }}>
            <Button
                id='hoverButton'
                className={classes.button}
                style={{ backgroundColor: props.disabled ? Colors.disabled : Colors.theme, ...props.buttonStyle }}
                disabled={props.disabled}
                onClick={() => props.onClick()}>
                {props.TitleText}
            </Button>
        </div >
    );

};

Themes.propTypes = { classes: PropTypes.object.isRequired, };
Themes.propTypes = { onClick: PropTypes.func };

Themes.defaultProps = { onClick: () => console.warn('default onClickCancel') }

export default withStyles(styles)(Themes);

