
import * as UploadActions from '../actions/Actions_UploadManager.js';

const INITIAL_STATE = { UPLOAD_REDUCER: { actions: UploadActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: UploadActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case UploadActions.INITIAL_STATE:
            {
                return state;
            }
        case UploadActions.IDLE_STATE:
            {
                //console.log("[ Products ] : IDLE_STATE : ", payload);
                return { ...state, UPLOAD_REDUCER: { actions: UploadActions.IDLE_STATE, data: payload, error: _error } };
            }
        case UploadActions.UPLOAD_IMAGE_SUCCESS:
            {
                // console.log("[ Products ] : UPLOAD_IMAGE_SUCCESS : ", payload);
                return { ...state, UPLOAD_REDUCER: { actions: UploadActions.UPLOAD_IMAGE_SUCCESS, data: payload, error: _error } };
            }
        case UploadActions.UPLOAD_IMAGE_FAILURE:
            {
                // console.log("[ Products ] : UPLOAD_IMAGE_FAILURE : ", payload);
                return { ...state, UPLOAD_REDUCER: { actions: UploadActions.UPLOAD_IMAGE_FAILURE, data: payload, error: _error } };
            }
        case UploadActions.UPLOAD_IMAGE_SQUARE_SUCCESS:
            {
                // console.log("[ Products ] : UPLOAD_IMAGE_SQUARE_SUCCESS : ", payload);
                return { ...state, UPLOAD_REDUCER: { actions: UploadActions.UPLOAD_IMAGE_SQUARE_SUCCESS, data: payload, error: _error } };
            }
        case UploadActions.UPLOAD_IMAGE_SQUARE_FAILURE:
            {
                // console.log("[ Products ] : UPLOAD_IMAGE_SQUARE_FAILURE : ", payload);
                return { ...state, UPLOAD_REDUCER: { actions: UploadActions.UPLOAD_IMAGE_SQUARE_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Products ]  default :", state);
                return state;
            }
    }
}
