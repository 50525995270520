import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';

import { Family, Colors } from '../themes';

let EnhancedTableToolbar = props => {

    const { numSelected, classes } = props;
    const isSelected = (numSelected > 0);

    return (
        <Toolbar className={classNames(classes.root, { [classes.highlight]: isSelected, })} >
            <div className={classes.title}>
                {(isSelected) ?
                    (<Typography color="inherit" variant="subheading" style={{ color: Colors.white }}>{numSelected} selected</Typography>) :
                    (<Typography variant="title" id="tableTitle" style={{ color: Colors.white, fontSize: '14px', fontFamily: Family.roboto }}>CUSTOMER LISTS</Typography>)
                }
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {isSelected ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                        <Tooltip title="Filter list">
                            <IconButton aria-label="Filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                    )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = { classes: PropTypes.object.isRequired, numSelected: PropTypes.number.isRequired, };

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        backgroundColor: Colors.theme,
        height: '56px',
        color: Colors.white
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
        color: Colors.white
    },
});

export default EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
//export default withStyles(toolbarStyles)(EnhancedTableToolbar);