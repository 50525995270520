import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withStyles } from '@material-ui/core/styles';

import BookingTable from '../containers/Booking/BookingTable.jsx';
import BookingListsPanel from '../componets/BoookingListsPanel.jsx';
import ContentPanels from '../componets/ContentManage/ContentPanel.jsx';
import BookingStatusPanel from '../containers/Booking/BookingStatusPanel.jsx';
import BookingSellerLists from '../containers/Booking/BookingSellerLists.jsx';
import BestProductStatPanel from '../containers/Supplier/BestProductStatPanel.jsx';
import TransactionHistoryListPanel from '../containers/TransactionHistory/TransactionHistoryListPanel';

import { PathName, Roles } from '../constant';
import * as BookingAction from '../actions/Actions_BookingManager.js';
import * as StatiticAction from '../actions/Actions_StatiticManager.js';

import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';
import { isMobile, isFirefox, isSafari } from '../common/DeviceCheck.js';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Colors, Family } from '../themes/index.js';

const pageWording = {
  topics: 'Booking Manager',
  subheading: ['You get the best rates', ' only at TravisGo'],
};

let _role;
let _userInfo;
let _bookingList = [];
let TopProductList = [];
let waitingTime = true;

class BookingManage extends Component {

  state = { isLoading: true, orderBy: 'desc', width: window.innerWidth }

  constructor(props) {

    super(props);

    const _info = JSON.parse(localStorage.getItem('UserInfo'));
    _role = (!!_info) ? (_info.userType || '') : '';

    switch (_role) {
      case Roles.Seller: {

        const { location, getAPIBookingLists, getAPIBestBookingWithSellerId, } = this.props;
        const { sellerInfo } = location.state;

        _userInfo = sellerInfo;

        getAPIBookingLists('', { affiliateId: _userInfo.sellerId });
        getAPIBestBookingWithSellerId({ sellerId: _userInfo.sellerId, startDate: '2018-01-01' });

      } break;
      case Roles.Admin:
      default: this.props.getAPIBookingLists();
    }

    // console.log(`BookingManage [constructor] : `, this.props.location.state);

  }

  componentWillMount() {
    this.updateDimensions();
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    _role = undefined;
    _bookingList = [];
    TopProductList = [];
  }

  componentWillReceiveProps(nextProps) {

    const { BookingManager, StatiticManager } = nextProps;

    if (!!BookingManager)
      this.BookingManager(BookingManager.BOOKING_REDUCER);

    if (!!StatiticManager) {

      const { data, actions, error } = StatiticManager.STATITIC_REDUCER;

      switch (actions) {

        case StatiticAction.GET_TOP_PRODUCT_WITH_SELLER_SUCCESS:
          {
            StatiticManager.STATITIC_REDUCER.actions = StatiticAction.WATING_STATE;
            TopProductList = data.results.data;
          } break;

        case StatiticAction.GET_TOP_PRODUCT_WITH_SELLER_FAILURE:
          {
            StatiticManager.STATITIC_REDUCER.actions = StatiticAction.WATING_STATE;
          } break;
      }
    }
  }

  BookingManager(_bookingInfo) {

    const { actions, data } = _bookingInfo;

    switch (actions) {
      case BookingAction.GET_BOOKING_LISTS_SUCCESS:
        {
          _bookingList = data;
          SetStateWithTime(this, waitingTime);
        } break;
      case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
        {

        } break;
      case BookingAction.GET_BOOKING_LISTS_FAILURE:
        {
          SetStateWithTime(this, waitingTime);
        } break;
    }
  }

  render() {

    const { isLoading, orderBy } = this.state;
    const _isMobile = isMobile();

    if (Boolean(_role)) {

      const _info = JSON.parse(localStorage.getItem('UserInfo'));
      _role = (!!_info) ? (_info.userType || '') : '';

    }

    return (//pageWording={pageWording}
      <ContentPanels isLoading={isLoading} OnClose={this.onClosePanel} isMobile={_isMobile} >
        {this.StaticPanel(_isMobile)}
        {this.BookingPanel(_isMobile)}
      </ContentPanels>);
  }

  handleChange = (event, value) => {

    switch (value) {
      case 1:
        {
          const _filter = { startDate: '2019-01-01', endDate: '2020-01-01', isTravelDate: false };
          this.props.getAPIAllSummaryBookingPaymentInfo(_filter);
        } break;
      case 2:
        {
          const _filter = { startDate: '2019-01-01', endDate: '2020-01-01', isTravelDate: true }
          this.props.getAPIAllSummaryBookingPaymentInfo(_filter);
        } break;
    }

    this.setState({ value });
  };

  StaticPanel(_isMobile) {

    const { isLoading, } = this.state;
    const { history } = this.props;

    switch (_role) {
      case Roles.Seller: break;
      case Roles.Admin:
        {
          return (<BookingStatusPanel
            BookinList={_bookingList}
            history={history}
            userInfo={_userInfo}
            isLoading={isLoading}
            isMobile={_isMobile} />)
        }
      default: { } break;
    }
  }

  BookingPanel(_isMobile) {

    const { isLoading, orderBy, value = 0 } = this.state;
    const { history, classes } = this.props;

    switch (_role) {
      case Roles.Seller:
        {
          return (
            <React.Fragment>
              <BestProductStatPanel SupplierList={TopProductList || []} history={history} isLoading={isLoading} isMobile={_isMobile} _marginRight={'unset'} />
              <div style={{ marginTop: 15 }} />
              {(!_isMobile) && <BookingSellerLists BookingList={_bookingList} BookingDetail={() => { }} orderBy={orderBy} isMobile={_isMobile} userInfo={_userInfo} />}
              {/* <BookingListsPanel BookingList={_bookingList} BookingDetail={() => { }} orderBy={orderBy} isMobile={_isMobile} userInfo={_userInfo} /> */}
              {/* <TransactionHistoryListPanel BookingList={_bookingList} BookingDetail={() => { }} orderBy={orderBy} isMobile={_isMobile} userInfo={_userInfo} /> */}
            </React.Fragment>
          )

        }
      case Roles.Admin:
      default:
        {

          const { tabRoot, tabsRoot, tabsIndicator } = classes;

          return (
            <TabPanelBooking>
              <AppBar position="static" >
                <Tabs value={value} onChange={this.handleChange} classes={{ root: tabsRoot, indicator: tabsIndicator }}>
                  <Tab label="BOOKING LIST" classes={{ root: tabRoot }} />
                  <Tab label="BOOKING DATE TABLE" classes={{ root: tabRoot }} />
                  <Tab label="BOOKING TRAVAL TABLE" classes={{ root: tabRoot }} />
                </Tabs>
              </AppBar>
              {this.BookingInfoWithTabDisplay(value, _isMobile)}
            </TabPanelBooking>)
        }
    }
  }

  BookingInfoWithTabDisplay(tabName, _isMobile) {

    const { orderBy } = this.state;

    switch (tabName) {
      case 0: {
        return (
          <TabContainer>
            <BookingListsPanel
              BookingList={_bookingList}
              BookingDetail={(id) => this.CallBookingDetail(id)}
              isMobile={_isMobile} orderBy={orderBy} />
            {/* <TransactionHistoryListPanel BookingList={_bookingList} BookingDetail={() => { }} orderBy={orderBy} isMobile={_isMobile} userInfo={_userInfo} /> */}
          </TabContainer>)
      }
      case 1:
        {
          return (<TabContainer><BookingTable /></TabContainer>)
        }
      case 2:
        {
          return (<TabContainer><BookingTable isTravel /></TabContainer>)
        }
    }
  }

  // ====================================================================================================================================================

  onClosePanel = () => (this.setState({ isLoading: false }));

  CallBookingDetail(_id) {
    console.log('CallBookingDetail : ', _id);

    this.props.getAPIBookingDetails(_id);
    this.props.history.push({ pathname: PathName.BookingDetail, state: { bookingId: _id }, search: '', hash: '' });
  }

  updateDimensions = () => {

    const { width } = this.state;
    const _with = window.innerWidth;

    if ((width !== _with)) {
      this.setState({ width: _with });
    }
  }

  // ====================================================================================================================================================
}
const styles = theme => ({
  tabsRoot: {
    backgroundColor: Colors.themeDark,
    borderBottom: `${'1px solid' + Colors.border}`,
  },
  tabsIndicator: {
    backgroundColor: Colors.theme,
    height: 3,
  },
  tabRoot: {
    textTransform: 'initial',
    fontWeight: theme.typography.fontWeightRegular,
    color: Colors.white,
    fontFamily: Family.roboto,
    '&:hover': {
      color: Colors.white,
      opacity: 1,
    },
    '&:tabSelected': {
      color: Colors.white,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: Colors.white,
    },
  },
});

// =====================================================================================================================================================

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIBookingLists: (_keyword, _filter) => {
      InitWaitingTime();
      dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
    },
    getAPIBookingDetails: (_id) => {
      dispatch(BookingAction.getAPIBookingDetail(_id));
    },
    getAPIAllSummaryBookingPaymentInfo: (_filter) => {
      dispatch(StatiticAction.getAllSummaryBookingPaymentInfo(_filter));
    },
    getAPIBestBookingWithSellerId: (_fliter) => {
      dispatch(StatiticAction.getBestBookingWithSellerId(_fliter));
    },
  }
}

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: '5px 0px' }}>
      {props.children}
    </Typography>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BookingManage));

// ====================================================================================================================================================

const TabPanelBooking = styled.div`    
flex-grow: 1;
margin: 10px 0px;
max-width: 1024px;`;

// ====================================================================================================================================================
