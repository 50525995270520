const HeaderPage = {
    CREATE_EN: "Create Staff",
    CREATE_TH: "----",
    CREATE_CN: "----",
    UPDATE_EN: "Update Staff",
    UPDATE_TH: "----",
    UPDATE_CN: "----",
}

const BodyPage = [
    [{
        title_TH: "---",
        title_EN: "Frist Name",
        title_CN: "---",
        type: "text",
        margin: 'right',
        require: true,
        stateName: "firstName",
        placeholder_TH: "-----",
        placeholder_EN: "Insert Name",
        placeholder_CN: "-----"
    }, {
        title_EN: "Last Name",
        title_TH: "",
        title_CN: "",
        type: "text",
        require: true,
        stateName: "lastName",
    }],
    [{
        title_EN: "Member Email ",
        title_TH: "",
        title_CN: "",
        type: 'email',
        stateName: 'email',
        require: true,
    }],
    [{
        title_TH: "---",
        title_EN: "Status",
        title_CN: "---",
        type: 'switch',
        stateName: 'visible'
    }],
];
export default {
    HeaderPage,
    BodyPage
}