import React, { Component } from 'react'
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import config from '../../config';

const hostName = config.host;

class FormCondition extends Component {

    render() {
        const { classes, StateInfo, disabled, Items, handleToggle, Language } = this.props;
        return (
            <div className={classes.root} >
                {Array.isArray(Items) && Items.map((each, index) => {
                    return (
                        <ListItem key={index} dense button disabled={disabled} className={classes.item} onClick={handleToggle('condition', each)}>
                            <Checkbox color="primary" className={classes.checkbox} disabled={disabled}
                                checked={path(['icon', 0, each.stateName], StateInfo)} tabIndex={-1} disableRipple />
                            <img src={`${hostName}${each.path}`} style={{ marginLeft: '10px' }} alt='' />
                            <ListItemText className={classes.text} primary={`${each[Language].name}`} />
                        </ListItem>
                    )
                })}
            </div>
        )

    }

}
const styles = theme => (
    {
        root: { width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' },
        checkbox: { width: '20px' },
        item: { width: '33%' },
        text: { paddingLeft: '10px', whiteSpace: 'nowrap' }
    }
)
FormCondition.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormCondition);