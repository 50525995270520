import React, { Component } from 'react'
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction'

import Button from '../../constant/Button';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import { GetSymbol } from '../../common/GetSymbol.js';
import { NumberFormats } from '../../common/SetNumberFormat';
import { GetStatusColor } from '../../common/GetStatusColor';
import { SetDate } from '../../common/SetDate';

import * as AccountingActions from '../../actions/Actions_Accounting';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import WithDrawRequestPanel from './WithDrawRequestPanel';
import TableComponent from '../../componets/TableComponent.jsx';

const { SendIcon } = Icons;
let WithDrawRequest = [];
let waitingTime = true;

const fildeLists = [
    { label: 'DATE', name: '', isOrder: true },
    { label: 'NAME', name: '', isOrder: false },
    { label: 'TYPE', name: '', isOrder: false },
    { label: 'WALLET', name: '', isOrder: false },
    { label: 'WITHDRAW', name: '', isOrder: false },
    { label: 'BALANCE', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: '', name: '', isOrder: false }];

/* Desktop */
const _callStyle = { textAlign: 'center', padding: '5px', border: `${'1px solid' + Colors.white}` };
const _callName = { ..._callStyle, textAlign: 'left', paddingRight: '15px', maxWidth: 280 };

/* Mobile */
const _callStyleMobile = { ..._callStyle, padding: 2, fontSize: 11, width: 50, };
const _callNameMobile = { ..._callStyleMobile, textAlign: 'left', maxWidth: 80, };


class WithDrawRequestListPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRequestPanel: false,
            isLoading: false, isView: false, msgError: undefined, dialogType: undefined,
            orderBy: 'desc', sortFilde: undefined, searchActive: false
        }
    }

    componentWillReceiveProps(nextProps) {
        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER;

            switch (actions) {
                case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

    }

    render() {
        const { classes, withDrawRequestList, isMobile, Header } = this.props;
        const { isLoading, isDialog, msgError, dialogType, orderBy, isWithDrawPanel, isView, withdrawInfo } = this.state;

        WithDrawRequest = withDrawRequestList.data || [];
        const _isWithDraw = (WithDrawRequest.length > 0);

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isWithDrawPanel) && <DialogAction Active={isWithDrawPanel} OnClose={this.onClosePanel} AcrionCom={this.WithDrawPanel(isView, withdrawInfo)} />}

                <TableComponent
                    orderBy={orderBy}
                    isActive={_isWithDraw}
                    FildeLists={fildeLists}
                    HandleRequestSort={this.handleRequestSort}
                    ListCount={withDrawRequestList}
                    onNextPage={this.onNextPage}
                    onChangeLimit={this.onChangeLimit}
                    GetBookingRows={GetBookingRows(classes, WithDrawRequest, this.DisplayPanel, isMobile)} />
            </React.Fragment>
        )
    }
    WithDrawPanel = (isView, withdrawInfo) => (
        < WithDrawRequestPanel isView={isView} withdrawInfo={withdrawInfo} userInfo={this.props.userInfo} onClosePanel={this.onClosePanel} />
    );

    DisplayPanel = (_action, _info) => {
        switch (_action) {
            case 'view': {
                this.setState({ isWithDrawPanel: true, isView: true, withdrawInfo: _info })
            } break;
            case 'edit': {
                this.setState({ isWithDrawPanel: true, isView: false, withdrawInfo: _info })
            } break;
        }
    };

    // onSavePanel = (_info, _value) => {
    //     const { transferId } = _info;
    //     const { remark, status } = _value;
    //     const { userId } = this.props.userInfo;
    //     let newData;
    //     if (status) {
    //         newData = {
    //             adminId: userId,
    //             transferId: transferId,
    //             remark: remark,
    //             transferStatus: status
    //         }
    //         console.log('newData', newData);
    //         this.setState({ isDialog: true }, () => this.props.APIUpdateAccountStatus(newData))
    //     } else {
    //         this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error' })
    //     }
    // }

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        WithDrawRequest = (orderBy === 'desc')
            ? WithDrawRequest.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : WithDrawRequest.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));
        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onNextPage = (_pageNumber, limit) => {
        this.setState({ isLoading: true });
        this.props.APIWithDrawRequestList({ page: _pageNumber, num_limit: (limit || 25) });
    }

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        this.props.APIWithDrawRequestList({ page: 0, num_limit: _limitRows });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => (this.setState({ isWithDrawPanel: false }));

}

function GetBookingRows(classes, WithDrawRequest, DisplayPanel, isMobile) {

    return WithDrawRequest.map(_info => {

        const { id, createDate, userAssigneeName, currency, userAssigneeType, transferStatus, oldBalance, amount, balance } = _info;

        const isCallStyle = (isMobile) ? _callStyleMobile : _callStyle;
        const isCallNameStyle = (isMobile) ? _callNameMobile : _callName;

        return (
            <TableRow hover tabIndex={-1} className={classes.row} key={id} style={{ cursor: 'pointer' }} >
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('view', _info) }}>{SetDate(createDate)}</TableCell>
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('view', _info) }}>{userAssigneeName || '-'}</TableCell>
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('view', _info) }}>{userAssigneeType || '-'}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, transferStatus)} onClick={() => { DisplayPanel('view', _info) }} >{`${GetSymbol(currency)}${NumberFormats(oldBalance || 0)}`}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, transferStatus)} onClick={() => { DisplayPanel('view', _info) }}>{`${GetSymbol(currency)}${NumberFormats(amount || 0)} `}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, transferStatus)} onClick={() => { DisplayPanel('view', _info) }} >{`${GetSymbol(currency)}${NumberFormats(balance || 0)} `}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, transferStatus)} onClick={() => { DisplayPanel('view', _info) }}>{transferStatus || '-'}</TableCell>
                <TableCell style={isCallStyle}>
                    <Button.Icons icon={<SendIcon />} tooltip={'Send'} onClick={() => { DisplayPanel('edit', _info) }} />
                </TableCell>
            </TableRow>)
    });
}

const styles = theme => (
    {
        row: {
            '&:nth-of-type(odd)': { backgroundColor:Colors.themeDark_Light },
            '&:nth-of-type(odd):hover': { backgroundColor: Colors.themeDark_Item },
        },
    });

WithDrawRequestListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIWithDrawRequestList: (_id) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIWithDrawRequestList(_id));
        },
        APIUpdateAccountStatus: (_id) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIUpdateAccountStatus(_id));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WithDrawRequestListPanel));
