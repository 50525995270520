import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { path, equals, keys, omit } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import validate from "validate.js";

import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Zone';
import Zone from '../../constant/JSON/Zone';
import Language from '../../constant/Language';
import Avatar from '../../constant/Avatar';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Fonts } from '../../themes'
import FormTypes from '../../common/FormTypes';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import * as ZoneActions from '../../actions/Actions_ZoneManager';
import * as UploadActions from '../../actions/Actions_UploadManager';

import CONFIG from '../../config';

let waitingTime = true;

const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex' };

class ZoneCard extends Component {
    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }
    constructor(props) {
        super(props);
        this.state = {
            ZoneInfo: this.props.ZoneInfo,
            oldZoneInfo: this.props.oldZoneInfo,
            isUpdate: false,
        }
    }
    componentWillReceiveProps = (nextProps) => {
        const { ZoneManager, UpLoadManager } = nextProps;

        if (!!ZoneManager) {
            const { actions, data } = ZoneManager.ZONE_REDUCER;

            switch (actions) {
                case ZoneActions.CREATE_ZONE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Zone Success.', dialogType: 'success', });
                        this.setState({ zoneId: data.zoneId, isUpdate: true })
                        this.props.getAPIZoneList({ num_limit: 10 });
                    } break;

                case ZoneActions.UPDATE_ZONE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Zone Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPIZoneList({ num_limit: 10 });
                    } break;

                case ZoneActions.CREATE_ZONE_FAILURE:
                case ZoneActions.UPDATE_ZONE_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, zoneId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let ZoneInfo = { ...this.state.ZoneInfo };
                        ZoneInfo.picture = data.result.upload.images;
                        if (isEdit) {
                            this.props.APIUpdateZone(ZoneInfo)
                        } else if (isUpdate) {
                            ZoneInfo = { ...ZoneInfo, zoneId: this.state.zoneId }
                            this.props.APIUpdateZone(ZoneInfo)
                        } else {
                            this.props.APICreateZone(ZoneInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }
    render() {
        const { classes, onClosePanel, isView, ZoneList, isEdit } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive, ZoneInfo } = this.state;
        const LanguageZone = path(['language'], ZoneList);

        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? Zone.HeaderPage.UPDATE_EN : Zone.HeaderPage.EN}</Typography>
                    <Language LanguagePage={LanguageZone} onChangeState={this.props.onChangeLanguage} isValue={true} />
                </div>}
                {Zone.BodyPage.map((row, index) => {
                    return (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each, LanguageZone)}</div>))}
                        </div>
                    )
                })}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        )
    }
    SetItemsWithType(each, LanguageZone) {

        const { classes, isView, ZoneList } = this.props;
        const { oldZoneInfo, ZoneInfo } = this.state;
        const valueLanguage = path([LanguageZone, each.stateName], ZoneInfo);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={undefined}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], ZoneInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
            case 'image': {
                return (
                    <div className={classes.textDefalut} >
                        {isView ?
                         <div className={classes.avatar} 
                         style={{
                             backgroundSize: 'cover',
                             backgroundColor: Colors.lightGrey, 
                             backgroundImage:`url("${CONFIG.host}${ZoneInfo.picture.thumbnail}")`}} 
                         />
                        //  <img className={classes.avatar} src={`${CONFIG.host}${ZoneInfo.picture.thumbnail}`} />
                            :
                            <Avatar ref='avatar' editable={true}
                            onRemove={() => this.onRemoveImage()}
                                onChange={(files) => { this.onChangeState(each.stateName, files) }}
                                avatar={Boolean(ZoneInfo.picture.thumbnail) ? `${CONFIG.host}${ZoneInfo.picture.thumbnail}` : ''} />
                        }
                    </div>
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], ZoneInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(each.options) && each.options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN} </option>) })} />
                )
            }
        }
    }

    onRemoveImage = () => {
        const { ZoneInfo } = this.state;
        this.setState({ ZoneInfo: { ...ZoneInfo, picture: { original: "", thumbnail: "" } }, isBtnActive: true })
        this.refs.avatar.remove('file');
    }

    onChangeState = (fieldName, state) => {
        let ZoneInfo = { ...this.state.ZoneInfo };
        const { oldZoneInfo } = this.state;
        const LanguageZone = path(['language',], this.props.ZoneList);

        switch (fieldName) {
            case "name":
            case "remark": {
                ZoneInfo = { ...ZoneInfo, [LanguageZone]: { ...ZoneInfo[LanguageZone], [fieldName]: state, } }
            } break;
            default: { ZoneInfo = { ...ZoneInfo, [fieldName]: state, } }
        }
        console.log('ZoneInfo success', ZoneInfo)
        this.setState({ ZoneInfo, isBtnActive: !equals(oldZoneInfo, ZoneInfo) }, () => { this.validate({ [fieldName]: state }); });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        const LanguageZone = path(['language'], this.props.ZoneList);
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.ZoneInfo[LanguageZone][each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        let ZoneInfo = { ...this.state.ZoneInfo }
        const { isEdit } = this.props;
        const { isUpdate, zoneId } = this.state;
        const newAvatar = this.refs.avatar.getAvatar('file');

        if (this.validateAll()) {
            if (!!newAvatar) {
                this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadImage(newAvatar));
            }
            else {
                this.setState({ isLoading: true, isBtnActive: false })
                if (isEdit) {
                    this.props.APIUpdateZone(ZoneInfo);
                }
                else if (isUpdate) {
                    ZoneInfo = { ...ZoneInfo, zoneId: this.state.zoneId };
                    this.props.APIUpdateZone(ZoneInfo);
                }
                else { this.props.APICreateZone(ZoneInfo); }
            }
        }
        else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error);
        }
    }

    APIUpLoadImage = (newAvatar) => {
        let formdata = new FormData();
        formdata.append('imgThumbnailWidth', 200);
        formdata.append('imgThumbnailHeight', 200);
        formdata.append("images", newAvatar);
        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
});

function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {
        getAPIZoneList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(ZoneActions.getZoneList(_keyword, _filter));
        },
        APICreateZone: (_confirmInfo) => {
            dispatch(ZoneActions.APICreateZone(_confirmInfo));
        },
        APIUpdateZone: (_confirmInfo) => {
            dispatch(ZoneActions.APIUpdateZone(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}
ZoneCard.propTypes = { classes: PropTypes.object.isRequired };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ZoneCard))