import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';


import { content_forgot, placeholder } from '../constant/JSON/Forgot';
import { path, equals } from 'ramda';
import * as validator from '../constant/Validator/Forgot';
import { isMobile } from '../common/DeviceCheck';

import DialogPanel from './DialogPanel.jsx';
import { SetStateWithObject } from '../common/SetTime.js';
import * as ForgotActions from '../actions/Actions_AuthenManager';
import ButtonText from '../constant/Button/ButtonText';
import Button from '../constant/Button';
import { Colors } from '../themes';

let waitingTime = true;

class Forgot extends Component {

    state = { Email: '', error: null, isBtnActive: false, isDialog: false, isSuccess: false };

    constructor(props) {

        super(props);

        this.state = {
            ForgotInfo: this.props.ForgotInfo,
            oldForgotInfo: this.props.oldForgotInfo,
            disabled: true,
        }

        this.onChangeState = this.onChangeState.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(nextProps) {

        const { AuthenManager } = nextProps;

        if (!!AuthenManager) {

            const { actions, data } = AuthenManager.AUTHEN_REDUCER;

            switch (actions) {
                case ForgotActions.FORGOT_PASSWORD_LB_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Forgot Success.', dialogType: 'success', isSuccess: true });
                    } break;
                case ForgotActions.FORGOT_PASSWORD_LB_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;//ErrorCode[data.status].EN;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', isSuccess: false });
                    } break;
            }
        }
    };

    onChangeState(state) {

        let ForgotInfo = { ...this.state.ForgotInfo, ...state };
        const { oldForgotInfo } = this.state;

        const result = validator.validateForgot(ForgotInfo, (result) => { this.setState({ error: result, ForgotInfo, isBtnActive: !result }); })
    }

    onSave() {

        let Forgot = { ...this.state.ForgotInfo }
        let oldForgotInfo = { ...this.state.oldForgotInfo }

        if (this.state.error) {
            //!!!
            this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.state.error)
        }
        else {
            this.setState({ error: null, isDialog: true, isBtnActive: false }, () => this.props.ForgotPasswordLB({ email: Forgot.Email }))
        }
    }

    onCloseDialog = () => {

        if (this.state.isSuccess)
            this.setState({ isDialog: false, msgError: undefined, dialogType: undefined, }, () => this.props.OnCloseForgotPasswordPanel())
        else
            this.setState({ isDialog: false, msgError: undefined, dialogType: undefined, })
    };

    render() {

        const { classes, OnCloseForgotPasswordPanel } = this.props;
        const { msgError, dialogType, isDialog, disabled, error, ForgotInfo, isBtnActive } = this.state;
        const { onChangeState, onSave } = this;

        return (
            <div className={classes.card} style={{ width:  '100%'  }}>

                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Typography className={classes.title}>{content_forgot.text_name}</Typography>
                </div>
                <div style={{ display: 'flex' }}>
                    <TextField
                        id="Email"
                        error={!!path(['Email'], error)}
                        label={content_forgot.text_Email}
                        placeholder={placeholder.text_Email}
                        className={classes.textField}
                        value={path(['Email'], ForgotInfo) || ''}
                        onChange={(e) => { onChangeState({ Email: e.target.value }) }}
                        margin="normal"
                        InputProps={{ disableUnderline: true, classes: { input: classes.textInput, }, }}
                        InputLabelProps={{ shrink: true, className: classes.textFormLabel, }}
                    />
                </div>
                <div style={{ display: 'flex', float: 'right' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => OnCloseForgotPasswordPanel()} />
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_SUBMIT}
                        disabled={!isBtnActive} onClick={() => onSave()} />
                </div>
            </div>
        );
    }
}

const styles = theme => (
    {
        card: {
            backgroundColor: Colors.white,
            textAlign: ' start',
        },
        title: {
            // marginBottom: '15px',
            fontSize: isMobile() ? '20px' : '24px',
            display: 'flex',
            height: '60px',
            alignItems: 'center'
        },
        textField: {
            width: '100%',
            marginTop: 0,
        },
        textFormLabel: {
            fontSize: 18,
            marginTop: 0,
        },
        textInput: {
            marginTop: 5,
            padding: '10px 10px',
            color: Colors.grey,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 14,
        },

    });

Forgot.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        ForgotPasswordLB: (_fileterjson) => {
            dispatch(ForgotActions.ForgotPasswordLB(_fileterjson));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(Forgot));




