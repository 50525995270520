import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PathName } from '../../constant';
import SupplierStatCard from '../../componets/SupplierStatCard.jsx';
import * as StatiticActions from '../../actions/Actions_StatiticManager.js';

import '../../App.css';
import { NumberFormats, NumberFormatNoFix } from '../../common/SetNumberFormat';

const _supplier = [{ name: 'Pending', value: 0 }, { name: 'Verified', value: 0 },];

let BestSupplierList;
let SummaryStatus;

class SupplierStatPanel extends Component {

    state = {}

    componentWillReceiveProps(nextProps) {

        const { SupplierManager, StatiticManager } = nextProps;

        if (!!StatiticManager) {

            //console.log(`StatiticManager [componentWillReceiveProps] : `, StatiticManager, );
            const { actions, data } = StatiticManager.STATITIC_REDUCER;
            const { isEdit, isCreate } = this.state;

            switch (actions) {
                case StatiticActions.GET_BEST_SUPPLIER_STAT_SUCCESS:
                    {
                        BestSupplierList = data.results.data;

                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        // SetStateWithTime(this, waitingTime);
                    } break;
                case StatiticActions.GET_SUMMARY_SUPPLIER_STATUS_SUCCESS:
                    {
                        //console.log(`${actions} : `, data.results);
                        SummaryStatus = data.results;

                        if (!!SummaryStatus) {
                            _supplier[0].value = SummaryStatus.pending;
                            _supplier[1].value = SummaryStatus.vertify;
                        }

                    } break;
                case StatiticActions.GET_BEST_SUPPLIER_STAT_FAILURE:
                    {
                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        // SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }


    render() {

        const { SupplierList, BestSupplierList, isLoading } = this.props;

        // console.log('SupplierStatPanel : ', SupplierList)

        return (
            <div className="App-content">
                <div style={{ flex: 3, marginRight: '10px' }}>
                    <SupplierStatCard Info={BestSupplierList} Topics={`Top Supplier Booking`} Disable={false} Styles={{ width: 750 }} isLoading={isLoading} btnViewHadle={this.btnViewHadle(PathName.SupplierManage)} chartType={2} />
                </div>
                <div style={{ flex: 1, marginLeft: '10px' }}>
                    <SupplierStatCard Info={_supplier} Topics={`Total Supplier : ${(!!SummaryStatus) ? NumberFormatNoFix(SummaryStatus.total) : 0}`} Disable={true} isLoading={isLoading} chartType={1} />
                </div>
            </div>)
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        // getAPLists:(_keyword) =>
        // {
        //     dispatch(SearchAction.getAPILists(_keyword));
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SupplierStatPanel);
