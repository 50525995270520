import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import '../App.css';
import CONFIG from '../config';

function Avatar(props) {
  const { classes, URL } = props;
  const _url = Boolean(URL) ? { backgroundImage: `url(${CONFIG.host}/${URL})` } : {};

  return (
    <div className={classes.container}>
      <div className="avatar-defualt" style={_url} />
    </div>);
}

const styles = { container: { margin: '5px', }, };
Avatar.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(Avatar);