import React, { Component } from 'react';
import { connect } from 'react-redux';
import validate from "validate.js";
import { path, equals, keys, omit, clone } from 'ramda';
// import 'react-select/dist/react-select.css';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { SetStateWithObject } from '../../common/SetTime.js';
import { Colors, Family } from '../../themes';
import FormTypes from '../../common/FormTypes';

import Appointment from '../../constant/JSON/Appointment';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import * as validator from '../../constant/Validator/Appoiontment';
import * as format from '../../common/setFormat';
import Avatar from '../../constant/Avatar';
import Language from '../../constant/Language';

import * as AppointmentActions from '../../actions/Actions_Appointment.js';
import * as UploadActions from '../../actions/Actions_UploadManager';

import DialogPanel from '../../componets/DialogPanel.jsx';
import AutoThaiAddress from '../../componets/AutoThaiAddress.jsx';
import CONFIG from '../../config';

import { acceptFile } from '../../constant';
import FileUploader from '../../FileUploader';
import { isMobile } from '../../common/DeviceCheck.js';

let waitingTime = true;

class AppointmentCard extends Component {
    error = null;
    gallery = null;
    imageOther = [];
    state = { isDialog: false, msgError: undefined, dialogType: undefined, isBtnActive: false, }

    constructor(props) {
        super(props);
        this.state = {
            appointmentInfo: { ...this.props.AppointmentInfo },
            oldAppointmentInfo: { ...this.props.oldAppointmentInfo },
            isUpdate: false,
            new_gallery: [],
        }
    }

    componentWillReceiveProps(nextProps) {
        const { AppointmentManager, UpLoadManager } = nextProps;

        if (!!AppointmentManager) {
            const { actions, data } = AppointmentManager.APPOINTMENT_REDUCER;

            switch (actions) {
                case AppointmentActions.CREATE_APPOINTMENT_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Appoiontment Success.', dialogType: 'success', });
                        this.setState({ appointmentId: data.appointmentId, isUpdate: true })
                        this.props.getAPIAppointmentList();
                    } break;

                case AppointmentActions.UPDATE_APPOINTMENT_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Appoiontment Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPIAppointmentList();
                    } break;
                case AppointmentActions.CREATE_APPOINTMENT_FAILURE:
                case AppointmentActions.UPDATE_APPOINTMENT_FAILURE:
                    {
                        console.log("UPDATE_APPOINTMENT_FAILURE : ", data.response.data.error.message);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, appointmentId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let appointmentInfo = { ...this.state.appointmentInfo };
                        const _image = data.result.upload.images;
                        // appointmentInfo.picture = data.result.upload.images;
                        console.log('_image:', _image)
                        console.log('UPLOAD_IMAGE_UPDATE_SUCCESS :', appointmentInfo)

                        if (isEdit) {
                            appointmentInfo.gallery = appointmentInfo.gallery.concat(_image);
                            appointmentInfo.picture = appointmentInfo.gallery[0];
                            this.props.APIUpdateAppointment(appointmentInfo)
                        } else if (isUpdate) {
                            appointmentInfo.gallery = appointmentInfo.gallery.concat(_image);
                            appointmentInfo.picture = appointmentInfo.gallery[0];
                            appointmentInfo = { ...appointmentInfo, appointmentId: appointmentId }
                            this.props.APIUpdateAppointment(appointmentInfo)
                        } else {
                            appointmentInfo.gallery = (_image.length > 0) ? _image : [_image];
                            appointmentInfo.picture = appointmentInfo.gallery[0];
                            this.props.APICreateAppointment(appointmentInfo);
                        }

                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("CREATE_APPOINTMENT_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    };
    render() {
        const { classes, onClosePanel, isView, isEdit } = this.props;
        const { msgError, dialogType, isDialog, isBtnActive } = this.state;
        const LanguageAppoiontment = path(['language'], this.props.AppoiontmentList);
        return (
            <div style={{ textAlign: 'start', overflow: 'unset', maxWidth: 800 }}>
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? Appointment.HeaderPage.UPDATE : Appointment.HeaderPage.EN}</Typography>
                    <Language LanguagePage={LanguageAppoiontment} onChangeState={this.props.onChangeLanguage} isValue={true} />
                </div>}
                {
                    Appointment.BodyPage.map((row, index) => {
                        return (
                            <div key={index} style={{ display: 'flex', flexDirection: isMobile() ? 'column' : null }}>
                                {row.map((each, index) => {
                                    let no = each.stateName === "no";
                                    let road = each.stateName === "road";
                                    return (
                                        <div key={index} style={{ textAlign: 'start', width: (no || road) ? '50%' : '100%', marginRight: (no) ? 15 : null, paddingTop: 5 }}>
                                            {this.SetItemsWithType(each, LanguageAppoiontment)}
                                        </div>)
                                })}
                            </div>
                        )
                    })
                }
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        );
    }
    SetItemsWithType(each, LanguageAppoiontment) {

        const { classes, isView, isEdit } = this.props;
        const { appointmentInfo, oldAppointmentInfo } = this.state;
        const { onChangeState } = this;
        const valueLanguage = path([LanguageAppoiontment, each.stateName], appointmentInfo);
        let options = (each.options) ? each.options : null;
        const isError = !!path([each.stateName], this.error);

        // console.log('appointmentInfo', appointmentInfo);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage || path([each.stateName], appointmentInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={3}
                        style={{ marginBottom: 10 }}
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage || ''}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case "search_select": {
                return (
                    <AutoThaiAddress each={each}
                        // style={{ paddingLeft: 0 }}
                        disabledProvince={isView ? true : false}
                        disabledDistrict={isView ? true : false}
                        errorPro={!!path(['province'], this.error)}
                        RequirePro={isView ? false : each.requirePro}
                        RequireDis={isView ? false : each.requireDis}
                        provinceId={path([each.stateName_pro], appointmentInfo) || ''}
                        districtId={path([each.stateName_dis], appointmentInfo) || ''}
                        onChange={(val) => {
                            if (!!val.district) {
                                this.onChangeState('district', path(['district', 'code'], val))
                            } else {
                                this.onChangeState('province', path(['province', 'code'], val))
                            }
                        }} />
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isView ? true : false}
                        error={isError}
                        style={{ marginLeft: isMobile() ? 0 : '15px', width: isMobile() ? '100%' : '94.5%' }}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], appointmentInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(options) && options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN} </option>) })} />
                )
            }
            case 'checkbox': {
                return (
                    <div className={classes.card}>
                        <div className={classes.textDefalut}> {each.title_EN} </div>
                        <div className={classes.listItem}>
                            {
                                Array.isArray(each.items) && each.items.map((eachs, index) => {
                                    return (
                                        <ListItem key={index} disabled={isView && true} dense button className={classes.item} onClick={this.handleToggle(each.stateName, eachs)}>
                                            <Checkbox color="primary" checked={path([each.stateName], appointmentInfo).indexOf(eachs.value) !== -1} className={classes.checkbox} tabIndex={-1} disableRipple />
                                            <ListItemText style={{ padding: 0 }} primary={`${eachs.label}`} />
                                        </ListItem>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            // case 'image': {
            //     return (
            //         <div >
            //             {isView ?
            //                 <img className={classes.avatar} src={`${CONFIG.host}${appointmentInfo.picture.thumbnail}`} />
            //                 :
            //                 <Avatar editable={!!isView ? false : true}
            //                     ref='avatar'
            //                     onChange={(files) => { onChangeState(each.stateName, files) }}
            //                     avatar={Boolean(appointmentInfo.picture.thumbnail) ? `${CONFIG.host}${appointmentInfo.picture.thumbnail}` : ''} />
            //             }
            //         </div>
            //     )
            // }
            case 'media': {
                const value = path([each.stateName], oldAppointmentInfo) || [];
                return (
                    <React.Fragment>
                        {(isEdit || isView) ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader
                                    ref={(ref) => this[each.stateName] = ref}
                                    disabled={isView ? true : false}
                                    multiple
                                    onChange={(files) => this.setState({ [`new_${each.stateName}`]: files, isBtnActive: true })}
                                    onDefaultChange={(defaultFiles) => { this.onChangeState(each.stateName, defaultFiles) }}
                                    defaultFiles={value}
                                    accept={acceptFile.image}
                                />
                                {!isView && <div style={{ color: Colors.disabled, fontSize: '10px', paddingTop: '5px' }} >
                                    {each.require.EN}
                                </div>}
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader multiple accept={acceptFile.image}
                                    disabled={isView ? true : false}
                                    onChange={(files) => { this.onChangeState(each.stateName, files) }} />
                                <div style={{ color: Colors.disabled, fontSize: '12px', paddingTop: '10px', fontFamily: Family.roboto }} >
                                    {each.require.EN}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], appointmentInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }
        }
    }
    handleToggle = (fieldName, state) => () => {
        console.log('[handleToggle] :', fieldName, state);
        const { appointmentInfo } = this.state;

        let newData = [...appointmentInfo[fieldName]]
        const currentIndex = newData.indexOf(state.value);

        switch (fieldName) {
            case "routingType": {
                if (currentIndex === -1) {
                    newData.push(state.value);
                } else {
                    newData.splice(currentIndex, 1);
                }
            } break;
            case "packageTypeService": {
                if (currentIndex === -1) {
                    newData.push(state.value);
                }
                else {
                    newData.splice(currentIndex, 1);
                }

                let valueAll = newData.some((each) => (each === 'All'))
                if (valueAll) {
                    let keyAll = ["OneDayTrip", "Transfer", "Hotel", "All"]
                    newData = keyAll
                } else {
                    if (newData.length === 3) {
                        newData = []
                    }
                    else {
                        newData = newData
                    }
                }
            } break;

        }



        console.log('newData', newData)
        this.setState({ isBtnActive: true }, () => { this.onChangeState(fieldName, newData) })
    }
    onChangeState = (fieldName, state) => {
        console.log('[onChangeState]', fieldName, state)
        const { oldAppointmentInfo } = this.state;
        const LanguageAppoiontment = path(['language'], this.props.AppoiontmentList);
        let appointmentInfo = { ...this.state.appointmentInfo };

        switch (fieldName) {
            case "name":
            case "address":
            case "description": {
                appointmentInfo = { ...appointmentInfo, [LanguageAppoiontment]: { ...appointmentInfo[LanguageAppoiontment], [fieldName]: state } }
            } break;
            case "province": {
                appointmentInfo = { ...appointmentInfo, parentId: state, [fieldName]: state }
            } break;
            // case "gallery": {
            //     appointmentInfo = { ...appointmentInfo, [fieldName]: [state] }
            // } break;
            default: { appointmentInfo = { ...appointmentInfo, [fieldName]: state } }
        }
        console.log('appointment success', appointmentInfo);
        this.setState({ appointmentInfo, isBtnActive: true }, () => { this.validate({ [fieldName]: state }) });
    }

    validate = (value) => {
        let result = true;
        const fieldName = keys(value)[0];
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};
            if (!!validateResult) {
                result = false;
                this.error = {
                    ...error,
                    ...validateResult
                };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }
    validateAll() {
        let success = true;
        const LanguageAppoiontment = path(['language'], this.props.AppoiontmentList);

        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.appointmentInfo[LanguageAppoiontment][each.fieldName] || this.state.appointmentInfo[each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }
    onSave = () => {
        const { isEdit } = this.props;
        let Appointment = { ...this.state.appointmentInfo }
        const { gallery } = Appointment;

        const { isUpdate, appointmentId, oldAppointmentInfo, new_gallery } = this.state;

        if (this.validateAll()) {
            if (isEdit) {
                const { defaultFiles } = this.gallery ? this.gallery.state.length === 0 ? [] : this.gallery.state : [];
                this.imageOther = this.imageOther.concat(this.gallery.files, defaultFiles)
                let newImage = this.gallery.files;
                if (newImage && newImage.length > 0) {
                    this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(newImage));
                } else {
                    this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APIUpdateAppointment(Appointment));
                }
            } else {
                if (new_gallery.length > 0 || gallery.length > 0) {
                    if (isUpdate) {
                        this.setState({ isDialog: true, appointmentInfo: { ...Appointment, gallery: [] }, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
                    } else {
                        this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
                    }
                } else {
                    if (isUpdate) {
                        Appointment = { ...Appointment, appointmentId: appointmentId }
                        this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APIUpdateAppointment(Appointment));
                    } else {
                        this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APICreateAppointment(Appointment));
                    }
                }
            }
        } else {
            this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        }


        // if (this.validateAll()) {
        //     if (new_gallery.length > 0 || gallery.length > 0) {
        //         if (isEdit) {
        //             const { defaultFiles } = this.gallery ? this.gallery.state.length === 0 ? [] : this.gallery.state : [];
        //             const { removeList } = this.gallery
        //             this.imageOther = this.imageOther.concat(this.gallery.files, defaultFiles)
        //             let newImage = this.gallery.files;
        //             if (newImage && newImage.length > 0) {
        //                 this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(newImage));
        //             } else {
        //                 if (removeList.length > 0) {

        //                     this.setState({ isDialog: true, isBtnActive: false }, () => this.props.APIUpdateAppointment(Appointment));
        //                 } else
        //                     this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(new_gallery));
        //             }
        //         } else {
        //             this.setState({ isDialog: true, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
        //         }
        //     } else {
        //         this.setState({ isDialog: true, isBtnActive: false, })
        //         if (isEdit) { this.props.APIUpdateAppointment(Appointment) }
        //         else if (isUpdate) {
        //             Appointment = { ...Appointment, appointmentId: appointmentId }
        //             this.props.APIUpdateAppointment(Appointment)
        //         } else { this.props.APICreateAppointment(Appointment) }
        //     }
        // } else {
        //     this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        // }
    }

    APIUpLoadGallery = (cover) => {

        let formdata = new FormData();
        formdata.append('imgOriginalWidth', 600); //650
        formdata.append('imgOriginalHeight', 600); //450
        formdata.append('imgThumbnailWidth', 400); //340
        formdata.append('imgThumbnailHeight', 400); //260
        formdata.append('imgThumbnailWidthMobile', 200); //308
        formdata.append('imgThumbnailHeightMobile', 200); //181

        for (const _image of cover)
            formdata.append("images", _image.file);

        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isDialog: false, msgError: undefined, dialogType: undefined }));

}

const styles = theme => (
    {
        title: { fontSize: '24px', display: 'flex' },
        listItem: { display: 'flex', flexDirection: isMobile() ? 'column' : 'row' },
        item: { display: 'flex', width: '100%', alignItems: 'center', padding: '10px 0px 0px', },
        card: { border: `${'1px solid' + Colors.border}`, padding: '10px', position: 'relative' },
        textDefalut: { fontSize: '14px', color: Colors.themeDark, fontFamily: Family.roboto, fontWeight: 'bold' },
        avatar: {
            height: '120px',
            width: '120px',
            margin: '14px 0px',
            boxShadow: '0 2px 5px 0 hsla(0,0%,47%,.5)',
        }
    });


AppointmentCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APICreateAppointment: (_confirmInfo) => {
            dispatch(AppointmentActions.APICreateAppointment(_confirmInfo));
        },
        APIUpdateAppointment: (_confirmInfo) => {
            dispatch(AppointmentActions.APIUpdateAppointment(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(AppointmentCard));
