import React, { Component, } from 'react';
import { withRouter } from 'react-router';
// import Button from '@material-ui/core/Button';
// import { pathName } from "../../constant";
// import { Images, Metrics } from '../../themes';
// import { GetSymbol, AgeCondition } from '../../components/common/GetSymbol';

import { Metrics, Family, Colors } from '../../themes';
import { GetSymbol } from '../../common/GetSymbol';
import "./card.css";

// let PromotionI
export const PercentType = { Percent_Adult: 'Percent Adult', Percent_Kid: 'Percent Kid', Percent_Infant: 'Percent Infant' }

class PercentPriceDetail extends Component {
    render() {

        const { PromotionInfo } = this.props;
        // console.log('Promotioninfo', this.props.PromotionInfo)
        const { percent_infant, percent_kid, percent_adult, currency, } = PromotionInfo;
        // const _crrency = GetSymbol(currency.currency);
        // console.log(percent_kid);
        return (
            <div style={{ position: 'relative', }} >
                <div style={_style.card_container}>
                    <div style={_style.sortMenu_container}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', textAlign: 'center', }}>
                            {this.setPriceBox(PercentType.Percent_Adult, percent_adult)}
                            {this.setPriceBox(PercentType.Percent_Kid, percent_kid)}
                            {this.setPriceBox(PercentType.Percent_Infant, percent_infant)}
                        </div>
                    </div>
                </div >
            </div>
        )
    }

    setPriceBox(type, percent, currency, percent_kid) {

        return (
            <div style={_style.header_col}>
                <label style={_style.label_topic_col}>{type}</label>
                <div style={_style.label_detail_col}>
                    <label>{`${percent} ${'%'}`}</label>
                </div>
            </div>
        )
    }
}

export default withRouter(PercentPriceDetail);

const _style = {
    card_container: {
        height: '100%',
        width: '100%',
        display: 'inline-flex',
        borderBottom: `${'1px solid' + Colors.borderLight}`,
        borderTop: `${'1px solid' + Colors.borderLight}`,
        marginTop: '2px',
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '10px',
        backgroundColor: Colors.white
    },
    sortMenu_container: {
        flex: '7',
        maxWidth: '954px',
        maxHeigth: '422px',
    },
    header_col: {
        flex: 1,
        borderLeft: `${'1px solid' + Colors.borderLight}`,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    label_topic_col: {
        color: Colors.themeDark,
        fontSize: '16px',
        fontFamily: Family.roboto,
    },
    label_detail_col: {
        color: Colors.grey,
        fontSize: '22px',
        fontFamily: Family.roboto,
    },
};
