import * as LocationServicesActions from '../actions/Actions_LocationServicesManager';

const INITIAL_STATE = { LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: LocationServicesActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case LocationServicesActions.INITIAL_STATE:
            {
                return state;
            }
        case LocationServicesActions.IDLE_STATE:
            {
                //console.log("[ LocationServices ] : IDLE_STATE : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.IDLE_STATE, data: payload, error: _error } };
            }
        case LocationServicesActions.GET_LOCATIONSERVICES_SUCCESS:
            {
                //console.log("[ LocationServices ] : GET_LOCATIONSERVICES_SUCCESS : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.GET_LOCATIONSERVICES_SUCCESS, data: payload, error: _error } };
            }
        case LocationServicesActions.GET_LOCATIONSERVICES_FAILURE:
            {
                console.log("[ LocationServices ] : GET_LOCATIONSERVICES_FAILURE : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.GET_LOCATIONSERVICES_FAILURE, data: payload, error: _error } };
            }
        case LocationServicesActions.CREATE_LOCATIONSERVICES_SUCCESS:
            {
                // console.log("[ LocationServices ] : CREATE_LOCATIONSERVICES_SUCCESS : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.CREATE_LOCATIONSERVICES_SUCCESS, data: payload, error: _error } };
            }
        case LocationServicesActions.CREATE_LOCATIONSERVICES_FAILURE:
            {
                console.log("[ LocationServices ] : CREATE_LOCATIONSERVICES_FAILURE : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.CREATE_LOCATIONSERVICES_FAILURE, data: payload, error: _error } };
            }
        case LocationServicesActions.UPDATE_LOCATIONSERVICES_SUCCESS:
            {
                // console.log("[ LocationServices ] : UPDATE_LOCATIONSERVICES_SUCCESS : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.UPDATE_LOCATIONSERVICES_SUCCESS, data: payload, error: _error } };
            }
        case LocationServicesActions.UPDATE_LOCATIONSERVICES_FAILURE:
            {
                console.log("[ LocationServices ] : UPDATE_LOCATIONSERVICES_FAILURE : ", payload);
                return { ...state, LOCATIONSERVICES_REDUCER: { actions: LocationServicesActions.UPDATE_LOCATIONSERVICES_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ LocationServices ]  default :", state);
                return state;
            }
    }
}
