import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { UpperCaseFormats } from '../../common/SetUpperCaseFormat.js';
import { NumberFormats } from '../../common/SetNumberFormat.js';
import { SetDate } from '../../common/SetDate';
import { Roles } from '../../constant/JSON/Roles.js';
import { isMobile, isTablet } from '../../common/DeviceCheck.js';

import DialogPanel from '../../componets/DialogPanel.jsx';
import StepperComponent from '../../componets/StepperComponent.jsx';
import RequestComponent from '../../componets/RequestComponent.jsx';

import * as AccountingActions from '../../actions/Actions_Accounting.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let isBtnActive = false;
let waitingTime = true;

let _userInfo;

let _info = JSON.parse(localStorage.getItem('UserInfo'));
let _role = (!!_info) ? (_info.userType || '') : '';


class WithDrawHistoryPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, msgError: undefined, dialogType: undefined,
            newData: {
                remark: '',
                amount: 0,
                isAmountAll: false,
            }
        }
    }

    componentWillReceiveProps(nextProps) {

        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {
            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.REQUEST_TRANSFER_BY_SELLER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'REQUEST WITHDRAW SUCCESS.', dialogType: 'success', });
                        this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 10, where: { "userAssigneeId": data.result.userAssigneeId } });

                    } break;
                case AccountingActions.REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'REQUEST WITHDRAW SUCCESS.', dialogType: 'success', });
                        this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 10, where: { "userAssigneeId": data.result.userAssigneeId } });

                    } break;
                case AccountingActions.REQUEST_TRANSFER_BY_SUPPLIER_FAILURE:
                case AccountingActions.REQUEST_TRANSFER_BY_SELLER_FAILURE:
                    {
                        console.log("SEND REQUEST WITHDRAW_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;


                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }
    render() {
        const { onSavePanel } = this;
        const { withdrawInfo, onClosePanel, isView, Role } = this.props;
        const { isLoading, msgError, dialogType, newData } = this.state;
        const { userAssigneeType, receivedWallet, wallet, amount, transferId, userTransfererName, currency, balance, createDate, userAssigneeName, oldBalance, transferStatus } = withdrawInfo;
        let InfoLists = [];
        let _currency = (currency === 'THB') ? 'Bath' : 'Bath';
        isBtnActive = (!newData.amount) && true;
        // console.log('props', this.props, Role);
        switch (Role) {
            case Roles.Supplier:
            case Roles.Seller: {
                if (isView) {
                    InfoLists = [
                        { title: 'Id : ', value: transferId },
                        { title: 'Date : ', value: SetDate(createDate) },
                        { title: 'Withdraw : ', value: `${NumberFormats(amount || 0)} ${_currency}` },
                        { title: 'Status : ', value: UpperCaseFormats(0, transferStatus) },
                    ];
                } else {
                    InfoLists = [
                        { title: 'Wallet : ', value: `${NumberFormats(wallet || 0)} ${_currency}` },
                        { title: 'Received Wallet : ', value: `${NumberFormats(receivedWallet || 0)} ${_currency}` },
                    ];
                }
            } break;
            case Roles.Admin: {
                InfoLists = [
                    { title: 'Staff Name : ', value: userTransfererName },
                    { title: 'Reciver Name : ', value: userAssigneeName },
                    { title: 'Wallet : ', value: `${NumberFormats(oldBalance || 0)} ${_currency}` },
                    { title: 'Withdraw : ', value: `${NumberFormats(amount || 0)} ${_currency}` },
                    { title: 'Balance : ', value: `${NumberFormats(balance || 0)} ${_currency}` },
                    { title: 'Type : ', value: UpperCaseFormats(0, userAssigneeType) },
                    { title: 'Date : ', value: SetDate(createDate) },
                    { title: 'Status : ', value: UpperCaseFormats(0, transferStatus) },
                ];
            } break;
        }
        const Structure = [
            [{
                title_EN: 'Amount WithDraw', title_TH: '', title_CN: '',
                type: 'number',
                require: true,
                value: path(['amount'], newData),
                onChange: this.onChangeState('amount'),
            }, {
                title_EN: 'Amount WithDraw All', title_TH: '', title_CN: '',
                type: 'checkbox',
                width: '60%',
                require: false,
                value: newData.isAmountAll,
                onChange: this.onChangeState('isAmountAll'),
            }], [{
                title_EN: 'Remark', title_TH: '', title_CN: '',
                type: 'text-multiline',
                require: false,
                value: path(['remark'], newData),
                onChange: this.onChangeState('remark'),
            }],
        ]

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isView) ?
                    <StepperComponent
                        checkRender={2}
                        rederRefuse={'refuse'}
                        activeStep={mapWithDrawStatus(transferStatus)}
                        steps={mapStepArray(transferStatus)}
                        component={renderRequestComponent(true, { InfoLists, onClosePanel })} />
                    :
                    renderRequestComponent(false, { Structure, InfoLists, onClosePanel, onSavePanel })
                }
            </React.Fragment>
        )
    }

    onChangeState = (fieldName) => (event) => {
        let newData = { ...this.state.newData };
        const { receivedWallet } = this.props.withdrawInfo;

        switch (fieldName) {
            case 'isAmountAll': {
                switch (event.target.checked) {
                    case true: {
                        newData = { ...newData, [fieldName]: event.target.checked, amount: receivedWallet }
                    } break;
                    case false: {
                        newData = { ...newData, [fieldName]: event.target.checked, amount: 0 }
                    } break;
                }
            } break;
            case 'amount': {
                newData = { ...newData, [fieldName]: parseFloat(event.target.value) }

            } break;
            default: {
                newData = { ...newData, [fieldName]: event.target.value }
            } break;
        }
        // console.log('onChangeState', newData);
        this.setState({ newData })
    };

    onSavePanel = () => {
        const { Role, withdrawInfo } = this.props;
        const { sellerId, supplierId } = withdrawInfo;
        const { amount, remark } = this.state.newData;
        let newData;

        if (amount) {
            switch (Role) {
                case Roles.Supplier:
                    {
                        newData = {
                            supplierId: supplierId,
                            remark: remark,
                            amount: amount,
                        }
                        // console.log('newData supplier', newData);
                        this.setState({ isLoading: true }, () => this.props.APIRequestTransferBySupplier(newData))
                    } break;
                case Roles.Seller: {
                    newData = {
                        sellerId: sellerId,
                        remark: remark,
                        amount: amount,
                    }
                    // console.log('newData seller', newData);
                    this.setState({ isLoading: true }, () => this.props.APIRequestTransferBySeller(newData))
                } break;
            }

        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error' })
        }
    }


    onCloseDialog = () => { this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }) }
};

function renderRequestComponent(action, props) {
    const { Structure, InfoLists, onClosePanel, onSavePanel } = props;

    return (
        <RequestComponent Action={action}
            Header={'WithDraw Request'}
            Structure={Structure}
            InfoLists={InfoLists}
            onClickCancel={onClosePanel}
            onClickSave={() => onSavePanel()}
            BtnSaveActive={isBtnActive} />
    )
};


function mapWithDrawStatus(status) {
    switch (status) {
        case 'success': return 2;
        case 'refuse': return 2;
        case 'in-process': return 1;
        default: return 0;
    }
};

function mapStepArray(status) {
    switch (status) {
        case 'refuse': {
            return ["request", "in-process", "refuse"]
        };
        default: return ["request", "in-process", "success"];
    }
};

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAccoutingHistoryList: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
        APIRequestTransferBySeller: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIRequestTransferBySeller(_filter));
        },
        APIRequestTransferBySupplier: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIRequestTransferBySupplier(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithDrawHistoryPanel);
