const HeaderPage = {
    CREATE_EN: "Create Default Commission", CREATE_TH: "----", CREATE_CN: "----",
    UPDATE_EN: "Update Default Commission", UPDATE_TH: "----", UPDATE_CN: "----",
}

const BodyPage = [
    [{
        title_TH: "---", title_EN: "Level Name", title_CN: "---",
        type: "text",
        require: true,
        stateName: "levelName",
        placeholder_TH: "-----", placeholder_EN: "Insert Name", placeholder_CN: "-----"
    }],
    [{
        title_EN: "Commission", title_TH: "", title_CN: "",
        type: "select",
        margin: 'right',
        require: true,
        stateName: "priceType",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "----",
        options: [
            { value: "percent", label_EN: 'Percent', label_TH: '', label_CN: '' },
            { value: "price", label_EN: 'Price', label_TH: '', label_CN: '' },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "number",
        margin: 'right',
        require: true,
        stateName: "adult",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "number",
        margin: 'right',
        require: true,
        stateName: "kid",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "number",
        require: true,
        stateName: "infant",
    }],
    [{
        title_TH: "---", title_EN: "Actived", title_CN: "---",
        type: 'switch',
        stateName: 'actived'
    }],
];
export default {
    HeaderPage,
    BodyPage
} 