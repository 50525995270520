import React, { Component } from 'react';
import { connect } from 'react-redux';
import validate from "validate.js";

import { path, equals, keys, omit, remove } from 'ramda';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import * as validator from '../../constant/Validator/Promotion';
import * as PromotionActions from '../../actions/Actions_PromotionManager';
import * as CurrencyActions from '../../actions/Actions_CurrencyManager';

import SelectPackageCard from './SelectPackageCard';
import InfoPanel from './InfoPanel';
// import 'react-datepicker/dist/react-datepicker.css';
import './card.css';

let waitingTime = true;
let CurrencyList = [];
let PromotionsAll = [];

class CreatePromotion extends Component {
    error = null;
    state = { isLoading: false, isSuccess: false, isDialog: false, isPackage: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    constructor(props) {
        super(props);
        this.state = {
            promotionId: undefined,
            promotionInfo: this.props.promotionInfo,
            oldPromotionInfo: this.props.oldPromotionInfo,
            disabled: true,
            showPackage: false,
            showEditPackage: false,
            isUpdateAPI: false,
            update: false
        }
        this.props.getAPICurrencyList({ num_limit: 10 });
        this.props.getAPIPromotions();
    }
    componentWillReceiveProps(nextProps) {
        const { PromotionManager, CurrencyManager } = nextProps;

        if (!!PromotionManager) {
            const { actions, data } = PromotionManager.PROMOTIONS_REDUCER;

            switch (actions) {
                case PromotionActions.CREATE_PROMOTIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Promotion Success.', dialogType: 'success', });
                        this.setState({ promotionId: path(['promotionId'], data), update: true })
                        this.props.getAPIPromotionsList({ num_limit: 10 });
                    } break;
                case PromotionActions.UPDATE_PROMOTIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Promotion Success.', dialogType: 'success', isSuccess: true, });
                        this.setState({ update: true })
                        this.props.getAPIPromotionsList({ num_limit: 10 });
                    } break;
                case PromotionActions.CREATE_PROMOTIONS_FAILURE:
                case PromotionActions.UPDATE_PROMOTIONS_FAILURE:
                    {
                        const _msg = data.response.data.error || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
                case PromotionActions.GET_PROMOTIONALL_SUCCESS:
                    {
                        PromotionsAll = (data || []);
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PromotionActions.GET_PROMOTIONALL_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!CurrencyManager) {
            const { actions, data } = CurrencyManager.CURRENCY_REDUCER;

            switch (actions) {
                case CurrencyActions.GET_CURRENCY_SUCCESS:
                    {
                        CurrencyList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CurrencyActions.GET_CURRENCY_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    handleChange = ({ startDate, endDate }, state) => {
        let promotionInfo = { ...this.state.promotionInfo, ...state };

        startDate = startDate || this.state.promotionInfo.startDate;
        endDate = endDate || this.state.promotionInfo.endDate;

        this.setState({ promotionInfo: { ...promotionInfo, startDate, endDate } })
    }

    handleChangeStart = (startDate) => {
        const strDate = new Date(startDate);
        startDate = strDate.toISOString();
        console.log("CheckDataPicker : ", startDate);
        this.handleChange({ startDate })
    }
    handleChangeEnd = (endDate) => {
        const strDate = new Date(endDate);
        endDate = strDate.toISOString();
        console.log("CheckDataPicker : ", endDate);
        this.handleChange({ endDate })
    }

    onChangeState = (fieldName, value, fieldGroup) => {

        console.log('onChangeState', fieldName, value, fieldGroup);

        let promotionInfo = { ...this.state.promotionInfo };
        const { oldPromotionInfo } = this.state;

        switch (fieldName) {
            case "type": {
                promotionInfo = {
                    ...promotionInfo,
                    percent_adult: 0, percent_kid: 0, percent_infant: 0,
                    adult: 0, kid: 0, infant: 0,
                    [fieldName]: value
                }
            } break;
            case "startDate":
            case "endDate": {
                promotionInfo = {
                    ...promotionInfo,
                    [fieldGroup]: {
                        ...promotionInfo[fieldGroup],
                        [fieldName]: value
                    }
                }
            } break;
            case "percent_adult":
            case "percent_kid":
            case "percent_infant": {

                // const newValue = (value < 10) ? (`0${value}` * 1.00).toFixed(2) : (value / 100).toFixed(2);
                promotionInfo = {
                    ...promotionInfo,
                    [fieldName]: (value / 100).toFixed(2)
                    // [fieldName]: newValue
                }

                // console.log('promotion parseFloat', promotionInfo[fieldName])
            } break;
            default: {
                promotionInfo = { ...promotionInfo, [fieldName]: value }
            } break
        }

        console.log('promotion success', promotionInfo)
        this.setState({ promotionInfo, isBtnActive: !equals(oldPromotionInfo, promotionInfo), },
            () => { this.validate({ [fieldName]: value }); });
    }

    validate = (value) => {

        const fieldName = keys(value)[0];
        let result = true;

        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};
            if (!!validateResult) {
                result = false;
                this.error = {
                    ...error,
                    ...validateResult
                };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }

        return result;
    }

    validateAll() {

        let success = true;
        const { promotionInfo } = this.state;

        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: promotionInfo[each.fieldName] || promotionInfo.bookingDate[each.fieldName] || promotionInfo.travelDate[each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }

    GetPackage = () => (
        <SelectPackageCard
            PackageAll={this.props.PackageAll}
            isEdit={this.props.isEdit}
            PromotionsAll={this.state.update ? PromotionsAll : this.props.PromotionsAll}
            onReset={this.onClosePanelPackage}
            promotionInfo={this.state.promotionInfo}
            oldPromotionInfo={this.state.oldPromotionInfo}
            handleToggle={this.handleToggle}
            onSavePanelPackage={this.onSavePanelPackage}
        />)

    onClosePanelPackage = () => {
        const { promotionInfo, } = this.state;
        const { packageId } = this.state.promotionInfo;
        this.setState({ isPackage: false, promotionInfo: { ...promotionInfo, packageId: [] } })
    };

    onSavePanelPackage = () => {
        const { packages, promotionInfo, } = this.state;
        const { packageId } = this.state.promotionInfo;
        this.setState({ isPackage: false, promotionInfo: { ...promotionInfo, packageId: packageId }, showPackage: true, showEditPackage: false });
    }

    handleToggle = value => () => {
        const { promotionInfo } = this.state;
        const { packageId } = this.state.promotionInfo;
        const currentIndex = packageId.indexOf(value.packageId);
        if (currentIndex === -1) {
            packageId.push(value.packageId);
        } else {
            packageId.splice(currentIndex, 1);
        }
        this.setState({ promotionInfo: { ...promotionInfo, packageId: packageId }, isBtnActive: true, showPackage: false, showEditPackage: true })
    }

    handleDelete = (fieldName, index, state) => {
        let promotionInfo = { ...this.state.promotionInfo };
        switch (fieldName) {
            case "packageId": {
                promotionInfo = { ...promotionInfo, [fieldName]: remove(index, 1, state) }
            }
        }
        this.setState({ promotionInfo })
    };

    onReset = () => {
        const { promotionInfo, isSuccess } = this.state;
        const { startDate, endDate } = promotionInfo;
        const { isEdit } = this.props;
        this.props.onClosePanel((isEdit && isSuccess) && promotionInfo)
    }

    onCheckPrice = () => {

        let promotionInfo = { ...this.state.promotionInfo }
        const { PackageAll, isEdit } = this.props;
        const { isUpdateAPI } = this.state;
        const { adult, kid, infant, packageId, bookingDate, travelDate } = promotionInfo
        let discountAdult = null;
        let discountKid = null;
        let discountInfant = null;
        let packageInfo = PackageAll.package && PackageAll.package.filter(_package => packageId.some(val => val === _package.packageId));

        packageInfo.map(each => {
            discountAdult = path(['price', 'adult'], each.packageInfo)
            discountKid = path(['price', 'kid'], each.packageInfo)
            discountInfant = path(['price', 'infant'], each.packageInfo)
        })

        promotionInfo.adult = parseFloat(promotionInfo.adult || 0)
        promotionInfo.kid = parseFloat(promotionInfo.kid || 0)
        promotionInfo.infant = parseFloat(promotionInfo.infant || 0)
        promotionInfo.percent_adult = parseFloat(promotionInfo.percent_adult || 0)
        promotionInfo.percent_kid = parseFloat(promotionInfo.percent_kid || 0)
        promotionInfo.percent_infant = parseFloat(promotionInfo.percent_infant || 0)

        let startBook = bookingDate.startDate;
        let endBook = bookingDate.endDate;
        let startTravel = travelDate.startDate;
        let endTravel = travelDate.endDate;

        console.log('promotionInfo onsave', promotionInfo);

        if (this.validateAll()) {
            if (startBook && endBook && startTravel && endTravel) {
                if (packageId.length > 0) {
                    if (adult > discountAdult || kid > discountKid || infant > discountInfant) {
                        this.setState({ isDialog: true, msgError: 'Promotion price must be less than the package price.', dialogType: 'error', isBtnActive: false }, () => this.error)
                    } else {
                        (isEdit) ? this.setState({ isDialog: true, isBtnActive: false, }, () => this.props.APIUpdatePromotions(promotionInfo)) :
                            (isUpdateAPI) ? this.onUpdateAPI(promotionInfo)
                                : this.setState({ isDialog: true, isBtnActive: false, isUpdateAPI: true }, () => this.props.APICreatePromotions(promotionInfo));

                    }
                } else this.setState({ isDialog: true, msgError: 'Please Select Package.', dialogType: 'error', isBtnActive: false }, () => this.error)
            } else this.setState({ isDialog: true, msgError: 'Please Select Booking Time and Travel Time.', dialogType: 'error', isBtnActive: false }, () => this.error)
        } else {
            this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        }
    }

    onCreatePanel = () => (this.setState({ isPackage: true }));
    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, }));

    onUpdateAPI = (promotionInfo) => {
        const { promotionId } = this.state;
        promotionInfo = { ...promotionInfo, promotionId: promotionId }
        this.setState({ isDialog: true, isBtnActive: false, promotionInfo }, () => this.props.APIUpdatePromotion(promotionInfo))
    }

    render() {

        const { promotionInfo, oldPromotionInfo } = this.state;
        // console.log('promotionInfo render', promotionInfo);

        return (
            <div>
                <InfoPanel
                    CurrencyList={CurrencyList}
                    showPackage={this.state.showPackage} showEditPackage={this.state.showEditPackage} isEdit={this.props.isEdit}
                    onSave={this.onSave} onReset={this.onReset} isLoading={this.state.isLoading} onCheckPrice={this.onCheckPrice}
                    msgError={this.state.msgError} isDialog={this.state.isDialog} dialogType={this.state.dialogType}
                    onCreatePanel={this.onCreatePanel} error={this.error} handleDelete={this.handleDelete} PackageAll={this.props.PackageAll}
                    handleChange={this.handleChange} handleChangeStart={this.handleChangeStart} handleChangeEnd={this.handleChangeEnd}
                    GetPackage={this.GetPackage} onCloseDialog={this.onCloseDialog} disabled={this.state.disabled}
                    onClosePanelPackage={this.onClosePanelPackage} isPackage={this.state.isPackage} isBtnActive={this.state.isBtnActive}
                    onChangeState={this.onChangeState}
                    promotionInfo={promotionInfo}
                    oldPromotionInfo={oldPromotionInfo} />
            </div>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {

        APICreatePromotions: (_confirmInfo) => {
            dispatch(PromotionActions.APICreatePromotions(_confirmInfo));
        },
        APIUpdatePromotions: (_confirmInfo) => {
            dispatch(PromotionActions.APIUpdatePromotions(_confirmInfo));
        },
        getAPIPromotionsList: (_keyword, _filter) => {
            dispatch(PromotionActions.getPromotionsList(_keyword, _filter));
        },
        getAPICurrencyList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CurrencyActions.getCurrencyList(_keyword, _filter));
        },
        getAPIPromotions: (_filter) => {
            InitWaitingTime();
            dispatch(PromotionActions.getPromotions(_filter));
        },
        getAPIPromotionsList: (_keyword) => {
            InitWaitingTime();
            dispatch(PromotionActions.getPromotionsList(_keyword));
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePromotion);
