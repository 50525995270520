import React from 'react'
import Language from './JSON/Language';
import Typography from '@material-ui/core/Typography';

const LanguageForm = (props) => {

    const { LanguagePage, onChangeState, } = props;
    const LanguageType = { TH: 'TH', EN: 'EN', CN: 'CN' };

    return (
        <div style={{
            display: 'flex',
            height: '60px',
            alignItems: 'center',
            fontSize: '20px',
            marginBottom: '15px 0px'
        }}>
            <Typography style={{ margin: '0px 10px', width: '70%', ...props.style }}>
                {Language.HeaderPage.EN}
            </Typography>
            <select style={{ height: '25px', marginTop: 0, fontSize: '10px' }}
                value={!!LanguagePage ? LanguagePage : ''}
                onChange={(e) => {
                    props.isKey ?
                        onChangeState(Language.StateName, e.target.value)
                        :
                        props.isValue ?
                            onChangeState(e.target.value)
                            :
                            onChangeState({ [Language.StateName]: e.target.value })
                }}>
                {
                    Language.SelectLanguage.map((each, index) => {
                        return (
                            <option key={index} value={each.value}>
                                {each.label}
                            </option>
                        )
                    })
                }
            </select>
        </div>
    )
}

export default LanguageForm;