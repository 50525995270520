import React, { Component } from 'react'
import { update, equals, path } from 'ramda';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import FormTypes from '../../common/FormTypes';
import EmptyRows from '../../componets/EmptyRow.jsx';
import { Family, Colors } from '../../themes';

import * as format from '../../common/setFormat';

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px' };
const _callName = { textAlign: 'left', padding: '15px 15px 10px 20px', fontSize: 14, fontWeight: 300 };

const fildeList = [
    { label: 'AREA', name: 'area', isOrder: false, },
    { label: 'PICKUP TIME', name: 'detail', isOrder: false, },
    { label: 'EXTRA CHARGE', name: 'extracharge', isOrder: false, },
];

class FormPickUpZone extends Component {
    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }
    constructor(props) {
        super(props);
        this.state = {
            appointmentIdList: { ...this.props.InfoList },
            pickUpZoneList: this.props.value,
        }
    }
    componentWillReceiveProps = (nextProps) => {
        if (!equals(this.props.appointmentIdList, nextProps.InfoList) || !equals(this.props.pickUpZoneList, nextProps.value)) {
            this.setState({ appointmentIdList: nextProps.InfoList, pickUpZoneList: nextProps.value })
        }
    }
    render() {
        const { pickUpZoneList } = this.state
        const { classes, disabled, InfoList = [], AppointmentList } = this.props;
        const _isInfo = (InfoList.length > 0);

        return (
            <Paper className={classes.root} >
                <Table>
                    <TableHead>
                        <TableRow>
                            {fildeList.map((_filde, index) => {
                                return (
                                    <TableCell key={index} style={_callStyle}>
                                        {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                    </TableCell>
                                )
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (_isInfo) ? InfoList.map((info, index) => {
                                const valuePickUp = Array.isArray(AppointmentList) && AppointmentList.find((val) => val.appointmentId === info)
                                const Language = 'EN';
                                const NAME = valuePickUp && valuePickUp[Language].name || '-';

                                return (
                                    <TableRow hover style={{ cursor: 'pointer', }} key={index} >
                                        <TableCell style={{ ..._callName, ...{ minWidth: 120, } }}  >{NAME || '-'}</TableCell>
                                        <TableCell style={{ ..._callName, ...{} }}  >
                                            <div className={classes.textInput}>
                                                <FormTypes.TextFormat
                                                    InputFormat={format.TimeFormat}
                                                    TitleText={'startDate'}
                                                    disabled={disabled}
                                                    value={path([index, 'pickUpTime', 'startDate'], pickUpZoneList)}
                                                    onChange={(e) => this.onChangeText('startDate', e.target.value, index)}
                                                    style={{ margin: '0px' }} />
                                                <div className={classes.line}> {'To'} </div>
                                                <FormTypes.TextFormat
                                                    InputFormat={format.TimeFormat}
                                                    TitleText={'endDate'}
                                                    disabled={disabled}
                                                    value={path([index, 'pickUpTime', 'endDate'], pickUpZoneList)}
                                                    onChange={(e) => this.onChangeText('endDate', e.target.value, index)}
                                                    style={{ margin: '0px' }} />
                                            </div>
                                        </TableCell>
                                        <TableCell style={{ ..._callName, ...{} }} >
                                            <FormTypes.TextFormat
                                                InputFormat={format.NumberFormatCustom}
                                                TitleText={'ExtraCharge'}
                                                disabled={disabled}
                                                value={path([index, 'extraCharge'], pickUpZoneList) || '0'}
                                                onChange={(e) => this.onChangeText('extraCharge', e.target.value, index)}
                                                style={{ margin: '0px', }} />

                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
    onChangeText = (fieldName, state, index) => {

        // console.log('onChangeText', fieldName, state, index)
        let pickUpZoneList = [...this.state.pickUpZoneList]
        // console.log('pickUpZoneList', pickUpZoneList)

        switch (fieldName) {
            case 'startDate':
            case 'endDate': {
                pickUpZoneList = update(index, {
                    ...pickUpZoneList[index],
                    pickUpTime: { ...pickUpZoneList[index].pickUpTime, [fieldName]: state }
                }, pickUpZoneList)
            } break;
            case 'extraCharge': {
                pickUpZoneList = update(index, {
                    ...pickUpZoneList[index],
                    [fieldName]: parseInt(state) || 0
                }, pickUpZoneList)
            } break;
        }
        this.setState({ pickUpZoneList }, () => this.props.onChange('pickUpZoneList', this.state.pickUpZoneList))
    }
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

const styles = theme => (
    {
        root: { display: 'inline-table', width: '100% ', marginTop: theme.spacing.unit * 3, overflowX: 'hidden', marginBottom: 10, },
        textInput: { display: 'flex', flexDirection: 'row', },
        line: {
            display: 'flex',
            alignItems: 'center',
            padding: ' 0px 15px',
            fontSize: '14px',
            fontWeight: 300,
            color: Colors.themeDark
        },
    });

FormPickUpZone.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormPickUpZone);

