export const constraints = {
    name: { presence: { allowEmpty: false } },
    description: { presence: { allowEmpty: false } },
    address: { presence: { allowEmpty: false } },
    province: { presence: { allowEmpty: false } },
};
export const input = [
    { fieldName: 'name', },
    { fieldName: 'description', },
    { fieldName: 'address', },
    { fieldName: 'province', },
];
