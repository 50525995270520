import React, { Component } from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import events from '../../common/Events';
import Calendar from '../../componets/Calendar';
// import BigCalendar from 'react-big-calendar';
import { momentLocalizer } from 'react-big-calendar';

import * as StatiticActions from '../../actions/Actions_StatiticManager.js';

import '../../App.css';

let BookinPaymentList = [];
const localizer = momentLocalizer(moment);

class BookingTable extends Component {

    constructor(props) {

        super(props);
        // this.props.getAPIAllSummaryBookingPaymentInfo({ startDate: '2019-01-01', endDate: '2019-02-01', isTravelDate: false });
        //{ startDate: '2019-01-01', endDate: '2019-01-01' }
    }

    componentWillReceiveProps(nextProps) {

        const { StatiticManager, isLoading } = nextProps;


        if (!!StatiticManager) {

            const { actions, data } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {

                case StatiticActions.GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS:
                    {
                        StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;
                        BookinPaymentList = data.results || [];

                        // console.log(`!!! ${actions} `, BookinPaymentList);

                        // SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    componentDidUpdate() {
        BookinPaymentList = [];
    }

    render() {

        //console.log('BookingTable : ', this.props)

        const { BookinList, isLoading, isTravel } = this.props;
        const _event = [];

        // Alienation: 0
        // AlienationPrice: 0
        // Date: "14-01-2019"
        // Omise: 18
        // OmisePrice: 27000
        // total: 18
        // totalPrice: 27000

        const itemsLabel = (isTravel) ? 'Travel' : 'Booking';

        for (const booking of BookinPaymentList) {
            if (booking.total > 0) {

                const bookingDate = (booking.Date.indexOf('-') === 2) ? booking.Date.split('-').reverse().join('-') : booking.Date;

                _event.push(
                    {
                        id: booking.Date,
                        title: `${itemsLabel} ( ${booking.total} )`,
                        allDay: true,
                        start: bookingDate,
                        end: bookingDate,
                        action: "select" | "click"
                    }
                )
            }
        }

        console.log('BookingTable : ', _event)


        return (
            <div style={{ marginTop: 20, height: 550 }}>
                <Calendar
                    date={new Date()}
                    events={_event}
                    localizer={localizer}
                    onNavigate={() => { console.log('onNavigate :') }}
                    onSelectEvent={(event) => {
                        console.log('onSelectSlot :', event[0], ' : ', event[1])
                        // this.getReportListWithDate(supplierInfo, (event[0] || createDate), (event[1] || createDate));
                    }}
                    onSelectSlot={(slot) => { console.log('onSelectSlot :', slot) }} />
            </div>)
    }


    btnViewHadle = (value) => () => { this.props.history.push(value); }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAllSummaryBookingPaymentInfo: (_filter) => {
            dispatch(StatiticActions.getAllSummaryBookingPaymentInfo(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingTable);
