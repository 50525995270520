import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';

import { Family, Colors, RequireForm } from '../../themes';
import { isMobile } from '../DeviceCheck'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

class SelectChip extends Component {

    render() {
        const { classes, disabled, error, TitleText, value, style, onChange, renderValue, placeholder, options, Require, renderLabel } = this.props;
        return (
            <div style={{ paddingTop: 5, ...style }}>
                <div className={classes.textFormLabel}> {Require ? <div> {RequireForm()}{TitleText} </div> : TitleText}</div>
                <Select multiple className={classes.textInput}
                    MenuProps={MenuProps}
                    value={value}
                    onChange={onChange}
                    renderValue={renderValue}
                    disabled={disabled}>
                    {options}
                </Select>
            </div>
        )
    }
}
const styles = theme => (
    {
        render: {
            position: 'absolute',
            top: '46px',
            left: '15px',
            fontFamily: Family.roboto,
            fontSize: 16,
            color: Colors.themeDark,
        },
        textInput: {
            width: '100%',
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: 14,
            marginTop: '8px',
            padding: '3px 0px',
            border: `${'1px solid' + Colors.border}`,
            borderRadius: '0px',
            backgroundColor: Colors.white,
            color: Colors.themeDark,
        },
        textFormLabel: { fontSize: 12, color: Colors.themeDark, fontWeight: 300, fontFamily: Family.roboto, },
    }
)
SelectChip.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(SelectChip);