import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { Metrics, Colors, Family } from "../../themes";

import { SetDate } from '../../common/SetDate';
import { NumberFormats } from '../../common/SetNumberFormat';
import { isMobile, isTablet } from '../../common/DeviceCheck';
import { BookingStatus } from '../../common/StatusBooking';

const PaymetStatus = 'in-process';

class PaymetnInfoPanel extends Component {

    render() {

        const { paymentInfo, bookingStatus } = this.props;
        const { amount, bankId, images, note, paymentDate, paymentMethod, updateDate } = paymentInfo;
        const { card, source, subPaymentMethod } = paymentInfo;
        const { bank, brand, object, name, financing } = card;

        console.log('PaymetnInfoPanel : ', paymentInfo, ' : ', bookingStatus);
        console.log('card : ', card, ' source : ', source);

        const stylePanel = isMobile() ? { padding: '20px 0px 20px 20px', width: '95%' } : { padding: '20px 10px 20px 50px', width: '55%', textAlign: 'left', };

        return (bookingStatus.match(/(in-process|approved|on-delivery|complete|refund)/i)) ?
            (<div key={`PaymentInfoPanel`} style={{ backgroundColor:Colors.themeDark_Light, width: '100%', height: '100%', display: 'flex', fontFamily: Family.roboto }}>
                <div style={stylePanel}>
                    <label style={style.label_topic}>{`Payment Infomation.`}</label>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Payment Method : `}</label>
                        <label style={style.priceInfo_lable}>{`${paymentMethod}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Payment Type : `}</label>
                        <label style={style.priceInfo_lable}>{`${subPaymentMethod}`.capitalize() || '-'}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Bank Name : `}</label>
                        {(paymentMethod === 'Omise') ?
                            <label style={style.priceInfo_lable}>{`${bank || '-'}`}</label> :
                            <label style={style.priceInfo_lable}>{`${Boolean(bankId) ? 'KASIKORN BANK' : '-'}`}</label>}
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Card Type : `}</label>
                        <label style={style.priceInfo_lable}>{`${brand || '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Financing : `}</label>
                        <label style={style.priceInfo_lable}>{`${financing || '-'}`.capitalize()}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Payment amount : `}</label>
                        <label style={style.priceInfo_lable}>{`${Boolean(amount) ? NumberFormats(amount) : '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Payment Date : `}</label>
                        <label style={style.priceInfo_lable}>{`${Boolean(paymentDate) ? SetDate(paymentDate) : '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Payment Update : `}</label>
                        <label style={style.priceInfo_lable}>{`${Boolean(updateDate) ? SetDate(updateDate) : '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Note : `}</label>
                        <label style={style.priceInfo_lable}>{`${note || '-'}`}</label>
                    </div>
                </div>
            </div >) : <div />;
    }
}

export default withRouter(PaymetnInfoPanel);

String.prototype.capitalize = function () {
    return this.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
};

const style =
{
    priceInfo_lable: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'right',
        //   marginTop: '5px',
        //   marginRight: '5px',
    },
    packege_container: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
        fontFamily: Family.roboto
    },
    label_topic: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '31px',
        display: 'inline-grid',
        marginTop: '5px',
    },
    label_detail: {
        height: '31px',
        width: '100%',
        maxWidth: '140px',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        //   marginTop: '5px',
        //   marginBottom: '5px',
    },
};
