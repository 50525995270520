export const constraints = {
    "name": {
        presence: { allowEmpty: false, },
    },
    "email": {
        email: true,
        presence: { allowEmpty: false, },
    },
    "telOffice": {
        format: {
            pattern: '^0(\\d{1,2})[0-9]{7}$',
            message: "can only contain 0-9"
        },
    },
    "post-code": {
        format: {
            pattern: '\\d{5}$',
            message: "is invalid"
        },
    },
}
export const input = [
    { fieldName: "name" },
    { fieldName: "email" },
    { fieldName: "telOffice" },
    { fieldName: "post-code" },
];
