import * as RefundActions from '../actions/Actions_RefundManager';

const INITIAL_STATE = { REFUND_REDUCER: { actions: RefundActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: RefundActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case RefundActions.INITIAL_STATE:
            {
                return state;
            }
        case RefundActions.IDLE_STATE:
            {
                //console.log("[ Refund ] : IDLE_STATE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.IDLE_STATE, data: payload, error: _error } };
            }
        case RefundActions.REFUND_SUCCESS:
            {
                //console.log("[ Refund ] : REFUND_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.REFUND_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.REFUND_FAILURE:
            {
                //console.log("[ Refund ] : REFUND_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.REFUND_FAILURE, data: payload, error: _error } };
            }
        // case RefundActions.GET_REFUND_SUCCESS:
        //     {
        //         //console.log("[ Refund ] : GET_REFUND_SUCCESS : ", payload);
        //         return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_SUCCESS, data: payload, error: _error } };
        //     }
        // case RefundActions.GET_REFUND_FAILURE:
        //     {
        //         console.log("[ Refund ] : GET_REFUND_FAILURE : ", payload);
        //         return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_FAILURE, data: payload, error: _error } };
        //     }
        case RefundActions.GET_REFUND_HISTORY_LISTS_SUCCESS:
            {
                // console.log("[ Refund ] : GET_REFUND_HISTORY_LISTS_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_HISTORY_LISTS_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.GET_REFUND_HISTORY_LISTS_FAILURE:
            {
                console.log("[ Refund ] : GET_REFUND_HISTORY_LISTS_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_HISTORY_LISTS_FAILURE, data: payload, error: _error } };
            }
        case RefundActions.UPDATE_REFUND_SUCCESS:
            {
                // console.log("[ Refund ] : UPDATE_REFUND_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.UPDATE_REFUND_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.UPDATE_REFUND_FAILURE:
            {
                console.log("[ Refund ] : UPDATE_REFUND_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.UPDATE_REFUND_FAILURE, data: payload, error: _error } };
            }
        case RefundActions.GET_REFUND_REASONS_SUCCESS:
            {
                //console.log("[ Refund ] : GET_REFUND_REASONS_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_REASONS_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.GET_REFUND_REASONS_FAILURE:
            {
                console.log("[ Refund ] : GET_REFUND_REASONS_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_REASONS_FAILURE, data: payload, error: _error } };
            }
        case RefundActions.CREATE_REFUND_REASONS_SUCCESS:
            {
                // console.log("[ Refund ] : CREATE_REFUND_REASONS_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.CREATE_REFUND_REASONS_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.CREATE_REFUND_REASONS_FAILURE:
            {
                console.log("[ Refund ] : CREATE_REFUND_REASONS_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.CREATE_REFUND_REASONS_FAILURE, data: payload, error: _error } };
            }
        case RefundActions.UPDATE_REFUND_REASONS_SUCCESS:
            {
                // console.log("[ Refund ] : UPDATE_REFUND_REASONS_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.UPDATE_REFUND_REASONS_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.UPDATE_REFUND_REASONS_FAILURE:
            {
                console.log("[ Refund ] : UPDATE_REFUND_REASONS_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.UPDATE_REFUND_REASONS_FAILURE, data: payload, error: _error } };
            }
        case RefundActions.GET_REFUND_REQUEST_LISTS_SUCCESS:
            {
                // console.log("[ Accounting ] : GET_REFUND_REQUEST_LISTS_SUCCESS : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_REQUEST_LISTS_SUCCESS, data: payload, error: _error } };
            }
        case RefundActions.GET_REFUND_REQUEST_LISTS_FAILURE:
            {
                // console.log("[ Accounting ] : GET_REFUND_REQUEST_LISTS_FAILURE : ", payload);
                return { ...state, REFUND_REDUCER: { actions: RefundActions.GET_REFUND_REQUEST_LISTS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Refund ]  default :", state);
                return state;
            }
    }
}
