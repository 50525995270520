import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DatePicker from 'react-datepicker';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";

import { Colors, Size, Family } from '../../themes';

/** How to use  
 * 
 * import * as CompPicker from '../componets/CompPicker';
 * 
 * return(
 *      <CompPicker.TimePicker 
 *          timeString={this.state.time} 
 *          onChange={(fieldName, time) => this.setState({ time })} />
 * )
 *  
*/

class TimePicker extends Component {

    static propTypes = {
        onChange: PropTypes.func,
        timeString: PropTypes.string,
        timeInputLabel: PropTypes.string
    };

    constructor(props) {
        super(props);

        this.state = {
            time: this.props.timeString
        };

    }

    onTimeChange = (time) => {
        this.setState({ time });
        const strTime = new Date(time);
        const newTime = `${getFormatted(strTime.getHours())}:${getFormatted(strTime.getMinutes())}`;
        this.props.onChange('time', newTime);
    };

    render() {
        const { time } = this.state;
        const { timeInputLabel } = this.props;
        return (
            <Container>
                <InputLabel> {timeInputLabel || 'Time'} </InputLabel>
                <DatePicker
                    selected={time}
                    onChange={this.onTimeChange}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="h:mm aa"
                    timeCaption="Time"
                    customInput={<InputPicker />}
                    injectTimes={[
                        setHours(setMinutes(new Date(), 1), 0),
                        setHours(setMinutes(new Date(), 5), 12),
                        setHours(setMinutes(new Date(), 59), 23)
                    ]}
                />
            </Container>
        )
    }
}
export default TimePicker;


/** function other */

function getFormatted(_info) {
    return _info < 10 ? `0${_info}` : _info;
}

/** style component */

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;    
    align-items: flex-start;
`;
const InputLabel = styled.div`
    color: ${Colors.input.label};
    font-size: ${Size.input.label};
    font-family:${Family.avenir};
    font-weight: 300;
    padding-bottom: 5px;
`;
const InputPicker = styled.input`
    width: 100%;
    padding: 9.5px 10px;
    font-weight: 300;
    font-size: ${Size.input.label};
    font-family:${Family.avenir};
    color: ${Colors.input.label};
    border: 1px solid ${Colors.border};
    min-width: ${props => props.width ? props.width : '100px'};
`;
