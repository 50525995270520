import React from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';
// import Button from '@material-ui/core/Button';
import CONFIG from '../config';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import * as BankActions from '../actions/Actions_Settings';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';
import { isMobile } from '../common/DeviceCheck'
import ButtonText from '../constant/Button/ButtonText';
import Button from '../constant/Button';

import { Colors, Family } from '../themes';

let BookBankList = [];
let waitingTime = true;

class BankCard extends React.Component {

    state = { disabled: true, }

    constructor(props) {
        super(props);
        this.props.getAPIgetBankList({ num_limit: 100 });

        const { SettingManager } = this.props;
        if (!!SettingManager)
            this.SettingManager(SettingManager);

    }

    componentWillReceiveProps(nextProps) {

        const { SettingManager } = nextProps;

        if (!!SettingManager)
            this.SettingManager(SettingManager);
    }

    SettingManager(SettingManager) {

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case BankActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BankActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BankActions.WATING_STATE:
                    {
                        BookBankList = (data.result) || [];
                    } break;
            }
        }
    }

    getAPIgetBankListListWithLimit = () => {
        const { page, limit, } = BookBankList;
        return this.props.getAPIgetBankListList({ page: (page || 0), num_limit: (limit || 10) });
    }

    render() {

        const { onAcceptonClosePanel, onAcceptPanel, handleToggle, _bankId, BookBankList } = this.props;
        let LanguageBank = path(['language'], BookBankList) || 'EN'
        let display_BookBank = Array.isArray(BookBankList.datas) && BookBankList.datas.filter((val) => val.actived)

        return (
            <div style={{ display: 'inline-block', width: '100%', maxWidth: isMobile() ? window.innerWidth : '1030px', maxHeight: '700px', fontFamily: Family.roboto }}>
                <div style={{ color: Colors.themeDark, fontSize: '24px', lineHeight: '30px' }}> {'Bank List'} </div>

                <List style={{ width: '100%', position: 'relative', overflow: 'auto', minWidth: isMobile() ? '200px' : '600px', height: '400px' }}>
                    {display_BookBank.map((value, index) => {
                        const _backgroundImage = {
                            width: isMobile() ? '35px' : '40px', height: isMobile() ? '35px' : '40px', backgroundColor: Colors.defaultImage, borderRadius: '50%', position: 'relative',
                            boxShadow: '-1 0 1px 1.9px ',
                            backgroundRepeat: 'round', backgroundImage: `url(${CONFIG.host}${value.logo.thumbnail})`, backgroundRepeat: 'round'
                        };
                        return (
                            <ListItem key={index} style={{ padding: '5px 0px', border: `${'1px solid' + Colors.border}`, margin: '5px 0px' }} dense button
                                onClick={handleToggle(value)}>
                                <Checkbox color="primary" checked={_bankId.indexOf(value.bankSettingId) !== -1} tabIndex={-1} disableRipple />
                                <div style={_backgroundImage} />
                                <ListItemText style={{ fontSize: isMobile() ? '10px' : '14px', width: isMobile() ? '100px' : 'unset', marginLeft: isMobile() ? '20px' : 'unset' }} primary={`${value[LanguageBank].name}`} />
                            </ListItem>
                        )
                    })
                    }
                </List>
                <div style={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'end', fontSize: '16px', paddingTop: '25px' }}>

                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => onAcceptonClosePanel()} />
                    <Button.Themes TitleText={ButtonText.BUTTON_OK} disabled={!_bankId && true}
                        onClick={() => onAcceptPanel()} />
                </div>
            </div >
        );
    }

}

const styles = theme => ({});

BankCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIgetBankList: (_keyword) => {
            dispatch(BankActions.getBankList(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(BankCard));

