
import { Province } from './Province';
import { isMobile } from '../../common/DeviceCheck'

const HeaderPage = {
    CREATE_EN: 'Create Seller', CREATE_TH: '------', CREATE_CN: '------',
    UPDATE_EN: 'Update Seller', UPDATE_TH: '------', UPDATE_CN: '------',
    REGISTER_EN: 'Register Seller', REGISTER_ETH: '------', REGISTER_ECN: '------',
}


const BodyPage = [
    [{

        title_TH: "คำนำหน้า", title_EN: "Title", title_CN: "名称",
        type: "select",
        require: true,
        width: isMobile() ? '100%' : '25%',
        // width: '100%',
        margin: 'right',
        stateName: "title",
        placeholder_TH: "คำนำหน้า", placeholder_EN: "Insert Title", placeholder_CN: "插入名称",
        options: [
            { id: 0, value: "Mr.", label_EN: "Mr.", label_TH: "นาย", label_CN: "---" },
            { id: 1, value: "Mrs.", label_EN: "Mrs.", label_TH: "นาง", label_CN: "---" },
            { id: 2, value: "Miss", label_EN: "Miss", label_TH: "นางสาว", label_CN: "---" },
        ]

    }, {
        title_TH: "ชื่อ", title_EN: "FirstName", title_CN: "名称",
        type: "text",
        require: true,
        margin: 'right',
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        isRequired: true,
        stateName: "firstName",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert FirstName", placeholder_CN: "插入名称"
    }, {
        title_TH: "นามสกุล", title_EN: "LastName", title_CN: "描述",
        type: "text",
        require: true,
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        stateName: "lastName",
        placeholder_TH: "นามสกุล", placeholder_EN: "Insert LastName", placeholder_CN: "插入说明"
    }], [{
        title_TH: "อีเมล์", title_EN: "Email", title_CN: "描述",
        type: "email",
        require: true,
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        margin: 'right',
        stateName: "email",
        placeholder_TH: "อีเมล์", placeholder_EN: "Insert Email", placeholder_CN: "插入说明"
    }, {
        title_TH: "เบอร์โทร", title_EN: "Tel Office", title_CN: "描述",
        type: 'number',
        stateName: "telOffice",
        // width: isMobile() ? '60%' : '60%',
        width: '100%',
        margin: 'right',
        placeholder_TH: "เบอร์โทร", placeholder_EN: "Insert Tel Office", placeholder_CN: "插入说明"
    }, {
        title_TH: "เบอร์โทรศัพท์มือถือ", title_EN: "Phone Number", title_CN: "描述",
        type: 'telephone',
        // width: isMobile() ? '60%' : '60%',
        width: '100%',
        stateName: "tel",
        placeholder_TH: "เบอร์โทร", placeholder_EN: "Insert Phone Number", placeholder_CN: "插入说明"
    }], [{
        title_TH: "เลขที่", title_EN: "No", title_CN: "号",
        type: "text",
        margin: 'right',
        width: isMobile() ? '100%' : '15%',
        // width: '100%',
        stateName: "no",
        placeholder_TH: "เลขที่", placeholder_EN: "Insert No", placeholder_CN: "插入号码",
    }, {
        title_TH: "ถนน", title_EN: "Road", title_CN: "路",
        type: "text",
        width: isMobile() ? '100%' : '30%',
        // width: '100%',
        margin: 'right',
        stateName: "road",
        placeholder_TH: "ถนน", placeholder_EN: "Insert Road", placeholder_CN: "插入道路",
    }, {
        title_TH: "ประเทศ", title_EN: "Country", title_CN: "国家",
        type: "select",
        width: isMobile() ? '100%' : '35%',
        // width: '100%',
        stateName: "country",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: [
            { id: 0, value: "Thailand", label_EN: "Thailand", label_TH: "ประเทศไทย", label_CN: "---" },
        ]
    }, {
        pro_TH: "จังหวัด", pro_EN: "Province", pro_CN: "省",
        dis_TH: "ตำบล", dis_EN: "District", dis_CN: "区",
        type: "search_select",
        width: isMobile() ? '100%' : '70%',
        // width: '100%',
        stateName_pro: "province", stateName_dis: "district",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: Province,

    }], [{
        title_TH: "---", title_EN: "BookBank", title_CN: "---",
        type: 'select_checkbok',
        stateName: 'bank',
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        stateName_account: 'accountNumber'
    }], [{
        title_TH: "ประเภท", title_EN: "Type", title_CN: "国家",
        type: "select",
        disibleState: true,
        require: true,
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        margin: 'right',
        stateName: "type",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        // options: [
        //     { id: 0, value: "blogger", label_EN: "Blogger", label_TH: "----", label_CN: "---" },
        //     { id: 1, value: "hotel", label_EN: "Hotel", label_TH: "----", label_CN: "---" },
        // ]
    }, {
        title_TH: "ระดับ", title_EN: "Level", title_CN: "描述",
        type: 'select',
        disibleState: true,
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        require: true,
        stateName: "level",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "插入说明",
        // options: [
        //     { id: 0, value: 'welcomLevel', label_EN: "Welcome level", label_TH: "---", label_CN: "---" },
        //     { id: 1, value: 'greenLevel', label_EN: "Green level", label_TH: "---", label_CN: "---" },
        //     { id: 2, value: 'goldLevel', label_EN: "Gold level", label_TH: "---", label_CN: "---" },
        //     { id: 3, value: 'diamondLevel', label_EN: "Diamond level", label_TH: "---", label_CN: "---" },
        // ]
    }], [{
        title_TH: "---", title_EN: "Marketing Chanel", title_CN: "---",
        type: 'marketing',
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        itemHotel: [
            { id: 0, value: 'name', label_EN: "Name Hotel", label_TH: "---", label_CN: "---", stateName: 'name' },
            // { id: 1, value: '', label_EN: "", label_TH: "---", label_CN: "---", stateName: '' }
        ],
        itemSocial: [
            { id: 0, value: 'facebook', label_EN: "Facebook", label_TH: "---", label_CN: "---", stateName: 'facebook' },
            { id: 1, value: 'instagram', label_EN: "Instagram", label_TH: "---", label_CN: "---", stateName: 'instagram' },
            { id: 2, value: 'twitter', label_EN: "Twitter", label_TH: "---", label_CN: "---", stateName: 'twitter' },
            { id: 3, value: 'website', label_EN: "Web Site", label_TH: "---", label_CN: "---", stateName: 'website' },
        ]
    }], [{
        title_TH: "---", title_EN: "Actived", title_CN: "---",
        type: 'switch',
        disibleState: true,
        width: isMobile() ? '100%' : '15%',
        // width: '100%',
        stateName: 'actived'
    }, {
        title_TH: "---", title_EN: "Visible", title_CN: "---",
        type: 'switch',
        width: isMobile() ? '100%' : '15%',
        // width: '100%',
        disibleState: true,
        stateName: 'visible'
    }], [{
        title_TH: "---", title_EN: "Avatar", title_CN: "---",
        type: 'image',
        // width: isMobile() ? '60%' : '100%',
        width: '100%',
        stateName: 'logo'
    }]
    , [{
        title_EN: "Accept Terms and Condition", title_TH: "", title_CN: "",
        width: '100%',
        type: "checkbox",
        require: false,
        stateName: 'isCondition',
        // items: [
        //     { value: false, TH: { name: "" }, EN: { name: "Accept Terms and Condition" }, CN: { name: "" }, stateName: 'isCondition' },
        // ],
    }]
];

const RegisterPage = [
    [{

        title_TH: "คำนำหน้า", title_EN: "Title", title_CN: "名称",
        type: "select",
        require: true,
        width: isMobile() ? '100%' : '25%',
        margin: 'right',
        stateName: "title",
        placeholder_TH: "คำนำหน้า", placeholder_EN: "Insert Title", placeholder_CN: "插入名称",
        options: [
            { id: 0, value: "Mr.", label_EN: "Mr.", label_TH: "นาย", label_CN: "---" },
            { id: 1, value: "Mrs.", label_EN: "Mrs.", label_TH: "นาง", label_CN: "---" },
            { id: 2, value: "Miss", label_EN: "Miss", label_TH: "นางสาว", label_CN: "---" },
        ]

    }, {
        title_TH: "ชื่อ", title_EN: "FirstName", title_CN: "名称",
        type: "text",
        require: true,
        margin: 'right',
        isRequired: true,
        stateName: "firstName",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert FirstName", placeholder_CN: "插入名称"
    }, {
        title_TH: "นามสกุล", title_EN: "LastName", title_CN: "描述",
        type: "text",
        require: true,
        stateName: "lastName",
        placeholder_TH: "นามสกุล", placeholder_EN: "Insert LastName", placeholder_CN: "插入说明"
    }], [{
        title_TH: "อีเมล์", title_EN: "Email", title_CN: "描述",
        type: "email",
        require: true,
        margin: 'right',
        stateName: "email",
        placeholder_TH: "อีเมล์", placeholder_EN: "Insert Email", placeholder_CN: "插入说明"
    }, {
        title_TH: "เบอร์โทร", title_EN: "Tel Office", title_CN: "描述",
        type: 'number',
        stateName: "telOffice",
        margin: 'right',
        placeholder_TH: "เบอร์โทร", placeholder_EN: "Insert Tel Office", placeholder_CN: "插入说明"
    }, {
        title_TH: "เบอร์โทรศัพท์มือถือ", title_EN: "Phone Number", title_CN: "描述",
        type: 'telephone',
        stateName: "tel",
        placeholder_TH: "เบอร์โทร", placeholder_EN: "Insert Phone Number", placeholder_CN: "插入说明"
    }], [{
        title_TH: "เลขที่", title_EN: "No", title_CN: "号",
        type: "text",
        margin: 'right',
        width: isMobile() ? '100%' : '15%',
        stateName: "no",
        placeholder_TH: "เลขที่", placeholder_EN: "Insert No", placeholder_CN: "插入号码",
    }, {
        title_TH: "ถนน", title_EN: "Road", title_CN: "路",
        type: "text",
        width: isMobile() ? '100%' : '30%',
        margin: 'right',
        stateName: "road",
        placeholder_TH: "ถนน", placeholder_EN: "Insert Road", placeholder_CN: "插入道路",
    }, {
        title_TH: "ประเทศ", title_EN: "Country", title_CN: "国家",
        type: "select",
        width: isMobile() ? '100%' : '35%',
        stateName: "country",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: [
            { id: 0, value: "Thailand", label_EN: "Thailand", label_TH: "ประเทศไทย", label_CN: "---" },
        ]
    }, {
        pro_TH: "จังหวัด", pro_EN: "Province", pro_CN: "省",
        dis_TH: "ตำบล", dis_EN: "District", dis_CN: "区",
        type: "search_select",
        width: isMobile() ? '100%' : '70%',
        stateName_pro: "province", stateName_dis: "district",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: Province,

    }], [{
        title_TH: "---", title_EN: "BookBank", title_CN: "---",
        type: 'select_checkbok',
        stateName: 'bank',
        stateName_account: 'accountNumber'
    }], [{
        title_TH: "ประเภท", title_EN: "Type", title_CN: "国家",
        type: "select",
        disibleState: true,
        require: true,
        stateName: "type",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }], [{
        title_TH: "---", title_EN: "Actived", title_CN: "---",
        type: 'switch',
        disibleState: true,
        width: isMobile() ? '100%' : '15%',
        stateName: 'actived'
    }, {
        title_TH: "---", title_EN: "Visible", title_CN: "---",
        type: 'switch',
        width: isMobile() ? '100%' : '15%',
        disibleState: true,
        stateName: 'visible'
    }], [{
        title_TH: "---", title_EN: "Avatar", title_CN: "---",
        type: 'image',
        stateName: 'logo'
    }]
    , [{
        title_EN: "Accept Terms and Condition", title_TH: "", title_CN: "",
        type: "checkbox",
        require: false,
        stateName: 'isCondition',
    }]
];

export default {
    HeaderPage,
    BodyPage,
    RegisterPage,
}