
export const init_refund = {
    TH: {
        reasons: '',
    },
    EN: {
        reasons: '',
    },
    CN: {
        reasons: '',
    },
    isCustomer: false,
    isSupplier: false,
    isAdmin: false,
    visible: false
}


