import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PromotionCodeListPanel from '../containers/Promotions/PromotionCodeListPanel.jsx';

import PackPromotionListPanel from '../containers/Promotions/PackPromotionListPanel.jsx'
import CreatePromotion from '../containers/Promotions/CreatePromotion.jsx'

import { PathName } from '../constant';
import { InitWaitingTime, SetStateWithTime, } from '../common/SetTime.js';
import * as PackageAction from '../actions/Actions_PackageManager.js';
import * as PromotionActions from '../actions/Actions_PromotionManager.js';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { isMobile, isFirefox, isSafari } from '../common/DeviceCheck.js'

import '../App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';


const _height = (window.innerHeight - 130);
let PromotionPackage = [];
let PromotionsList = [];
let PromotionsCodeList = [];
let waitingTime = true;
let PromotionsAll = [];
let PackageAll = [];
let _today;
let _sellerInfo;

class PromotionCodeManage extends Component {

    constructor(props) {
        super(props);
        _today = new Date();

        this.state = {
            promotionInfo: undefined,
            isLoading: true,
            editInfo: false,
            isPackage: false,
            error: null,
            isCreate: false,
            isEdit: false,
            msgError: undefined,
            dialogType: undefined,
            orderBy: 'desc',
        }

        const { location } = this.props;

        if (location.state !== undefined) {
            const { sellerInfo } = location.state;
            _sellerInfo = sellerInfo;
        }

        this.props.getAPIPromotionsCodeList({ page: 0, num_limit: 25 })

    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager, PromotionManager } = nextProps;

        if (!!PackageManager) {

            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        PromotionPackage = (data || []);
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.GET_PACKAGE_SUCCESS:
                    {
                        PackageAll = (data || []);
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                case PackageAction.GET_PACKAGE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!PromotionManager) {

            const { actions, data } = PromotionManager.PROMOTIONS_REDUCER;

            // console.log(`${actions} : `, data); //#checknew

            switch (actions) {
                case PromotionActions.GET_PROMOTIONS_CODE_SUCCESS:
                    {
                        PromotionsCodeList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PromotionActions.GET_PROMOTIONS_SUCCESS:
                    {
                        PromotionsList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PromotionActions.GET_PROMOTIONALL_SUCCESS:
                    {
                        PromotionsAll = data;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PromotionActions.GET_PROMOTIONS_FAILURE:
                case PromotionActions.GET_PROMOTIONALL_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate, } = this.state;
        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {

        // console.log('contentPanel : ', PromotionsCodeList);
        const _isMobile = isMobile();

        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <PromotionCodeListPanel
                    PromotionsCodeList={PromotionsCodeList}
                    userInfo={_sellerInfo}
                    isMobile={_isMobile}
                    onCreateEvent={this.onCreateEvent()}
                    DisplayPromotionInfo={this.DisplayPromotionInfo()} />
            </div>)
    }

    onClickHandle = (page) => (_packageId) => {
        const _packageInfo = PromotionPackage.packageList.find((_package) => { return _package.packageId === _packageId });
        this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo }, search: '', hash: '' });
    }

    DisplayPromotionInfo = () => (PromotionInfo) => {

        this.props.history.push({ pathname: PathName.PromotionInfo, state: { PromotionInfo }, search: '', hash: '' });
    }

    onCreateEvent = () => (_event) => {
        console.log('onCreateEvent')
        this.setState({ isCreate: true })
    };

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    onClosePanel = () => (this.props.getAPIPromotionsCodeList({ limit: 10 }),
        this.setState({ isCreate: false, editInfo: false, isEdit: false, }));

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPromotionsList: (_keyword) => {
            InitWaitingTime();
            dispatch(PromotionActions.getPromotionsList(_keyword));
        },
        getAPIPromotionsCodeList: (_keyword) => {
            InitWaitingTime();
            dispatch(PromotionActions.getPromotionsCodeList(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(PromotionCodeManage);
