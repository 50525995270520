import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import CategoryListPanel from '../containers/Category/CategoryListPanel';
import CategoryCard from '../containers/Category/CategoryCard';

import * as PackageAction from '../actions/Actions_PackageManager.js';


let waitingTime = true;
const _height = (window.innerHeight - 130);
let packageCategoryList = [];

class CategoryManage extends Component {
    state = {
        isView: false, isLoading: true, msgError: undefined, dialogType: undefined
    };

    constructor(props) {
        super(props);
        this.props.getAPIPackageCategory({ limit: 10 });
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager } = nextProps;
        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;
            switch (actions) {
                case PackageAction.GET_PACKAGE_CATEGORY_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                        packageCategoryList = data || [];
                    } break;
                case PackageAction.GET_PACKAGE_CATEGORY_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break
            }
        }
    }


    render() {
        const { isLoading, isEdit } = this.state;
        // console.log('packageCategoryList', packageCategoryList);
        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}> {this.contentPanel()} </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <CategoryListPanel Header={`Package Category List`} packageCategoryList={packageCategoryList} />
            </div>
        )
    }

    onClosePanel = () => (this.setState({ isUpdatePackage: false }));

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageCategory: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageCategory(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoryManage);
