export const constraints = {
    nameCategory: {
        presence: {
            allowEmpty: false,
            message: 'error require Name',
        }
    },
    order: {
        presence: {
            allowEmpty: false,
            message: 'error require order',
        }
    },
};
export const input = [
    { fieldName: 'nameCategory', },
    { fieldName: 'order', },
];