import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Button from '../../constant/Button';
import { Roles } from '../../constant/JSON/Roles.js';
import { SetDate } from '../../common/SetDate';

import { NumberFormats } from '../../common/SetNumberFormat';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import CONFIG from '../../config';
import DialogAction from '../../componets/DialogAction'
import DialogPanel from '../../componets/DialogPanel.jsx';

import SearchPanel from '../../componets/SearchPanel.jsx';

import * as AccountingActions from '../../actions/Actions_Accounting.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import WithDrawHistoryPanel from './WithDrawHistoryPanel';
import TableComponent from '../../componets/TableComponent.jsx';
const { CancelIcon, VisibilityIcon  } = Icons;

let WithDrawHistory = [];
let waitingTime = true;

const fildeLists = [
    { label: 'STAFF NAME', name: 'userTransfererName', isOrder: true, },
    { label: 'RECIVER NAME', name: 'userAssigneeName', isOrder: true, },
    { label: 'WALLET', name: 'oldBalance', isOrder: true, },
    { label: 'WITHDRAW', name: 'amount', isOrder: true, },
    { label: 'BALANCE', name: 'balance', isOrder: true, },
    { label: 'TYPE', name: 'userAssigneeType', isOrder: true, },
    { label: 'DATE', name: 'createDate', isOrder: true, },
    { label: '', name: '', isOrder: false, }
];

/* Desktop */
const _callStyle = { textAlign: 'center', padding: '5px', border: `${'1px solid' + Colors.white}` };
const _callName = { ..._callStyle, textAlign: 'left', paddingRight: '15px', maxWidth: 280 };

/* Mobile */
const _callStyleMobile = { ..._callStyle, padding: 2, fontSize: 11, width: 50, };
const _callNameMobile = { ..._callStyleMobile, textAlign: 'left', maxWidth: 80, };


class WithDrawHistoryListPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isWithDrawPanel: false, isView: false,
            isLoading: false, msgError: undefined, dialogType: undefined,
            orderBy: 'desc', sortFilde: undefined, searchActive: false
        };

        const { userInfo, Role } = this.props;

        switch (Role) {
            case Roles.Admin:
            default:
                {
                    this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 25, where: { transferStatus: ['success', 'refuse'] } });
                };
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps);
        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    componentWillUnmount() {
        WithDrawHistory = [];
    }

    render() {

        const { classes, withDrawHistoryList, isMobile } = this.props;

        WithDrawHistory = withDrawHistoryList.datas || [];
        const _isWithDraw = (WithDrawHistory.length > 0);
        const { isLoading, msgError, dialogType, searchActive, withdrawInfo, isWithDrawPanel, orderBy, isView } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isWithDrawPanel) && <DialogAction Active={isWithDrawPanel} OnClose={this.onClosePanel} AcrionCom={this.WithDrawPanel(isView, withdrawInfo)} />}

                <TableComponent
                    orderBy={orderBy}
                    isActive={_isWithDraw}
                    FildeLists={fildeLists}
                    HandleRequestSort={this.handleRequestSort}
                    ListCount={withDrawHistoryList}
                    onNextPage={this.onNextPage}
                    onChangeLimit={this.onChangeLimit}
                    GetBookingRows={GetBookingRows(classes, WithDrawHistory, this.DisplayPanel, isMobile)} />
            </React.Fragment >
        );
    }

    WithDrawPanel = (isView, withdrawInfo) => (
        <WithDrawHistoryPanel isView={isView} withdrawInfo={withdrawInfo} onClosePanel={this.onClosePanel} userInfo={this.props.userInfo} Role={this.props.Role} />
    );

    DisplayPanel = (_action, _info) => {
        switch (_action) {
            case 'view': {
                this.setState({ isWithDrawPanel: true, isView: true, withdrawInfo: _info })
            } break;
            default: {
                this.setState({ isWithDrawPanel: true, isView: false, withdrawInfo: _info })
            } break;
        }
    };
    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        if (WithDrawHistory) {
            WithDrawHistory = (orderBy === 'desc')
                ? WithDrawHistory.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
                : WithDrawHistory.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

            this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
        }
    };

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });

        const { userInfo, Role } = this.props;
        switch (Role) {
            case Roles.Admin:
            default:
                {
                    this.props.getAPIAccoutingHistoryList({ page: _pageNumber, num_limit: (_limit || 10), key: 'updateDate', orderBy: 'desc', where: { transferStatus: ['success', 'refuse'] } });
                };
        }
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        const { userInfo, Role } = this.props;
        switch (Role) {
            case Roles.Admin:
            default:
                {
                    this.props.getAPIAccoutingHistoryList({ page: 0, num_limit: _limitRows, key: 'updateDate', orderBy: 'desc', where: { transferStatus: ['success', 'refuse'] } });
                };
        }
    };


    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => (this.setState({ isWithDrawPanel: false }));

}

function GetBookingRows(classes, WithDrawHistory, DisplayPanel, isMobile) {

    return WithDrawHistory.map(_info => {

        const { transferId, transferStatus, amount, balance, createDate, oldBalance, userAssigneeName, userAssigneeType, userTransfererName } = _info;

        const isCallStyle = (isMobile) ? _callStyleMobile : _callStyle;
        const isCallNameStyle = (isMobile) ? _callNameMobile : _callName;

        let success = (transferStatus === 'success')

        return (
            <TableRow hover className={classes.row} style={{ cursor: 'pointer' }} key={transferId} onClick={() => { DisplayPanel('view', _info) }}>
                <TableCell style={isCallNameStyle}  >{userTransfererName}</TableCell>
                <TableCell style={isCallNameStyle} >{userAssigneeName || '-'}</TableCell>
                <TableCell style={isCallStyle} >{NumberFormats(oldBalance || 0)}</TableCell>
                <TableCell style={isCallStyle}  >{NumberFormats(amount || 0)}</TableCell>
                <TableCell style={isCallStyle}  >{NumberFormats(balance || 0)}</TableCell>
                <TableCell style={isCallStyle}  >{userAssigneeType || '-'}</TableCell>
                <TableCell style={isCallStyle}  >{SetDate(createDate)}</TableCell>

                <TableCell style={{ ...isCallStyle, }}>
                    <Button.Icons icon={(success) ? <VisibilityIcon /> : <CancelIcon />}
                        buttonStyle={{ color: (success) ? Colors.success : Colors.error, backgroundColor: 'transparent' }} />
                </TableCell>

            </TableRow>
        )
    });
}

const styles = theme => (
    {
        row: {
            '&:nth-of-type(odd)': { backgroundColor: Colors.themeDark_Light },
            '&:nth-of-type(odd):hover': { backgroundColor: Colors.themeDark_Item },
        },
    });

WithDrawHistoryListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAccoutingHistoryList: (_filter) => {
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WithDrawHistoryListPanel));


