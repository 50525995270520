import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import styled from 'styled-components';
import { Colors, Family } from '../themes';
import { Calendar } from 'primereact/calendar';
import { SetDateFormat, getFormatted } from '../common/SetDate';

import '../containers/Packages/Card.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/nova-light/theme.css';

let checkDateList = [];

class CloseServicePanel extends Component {

    constructor(props) {

        super(props);

        const { StateInfo } = this.props;
        this.state = { date: null, sysDate: new Date() }
        this.setDateTemplate = this.setDateTemplate.bind(this);

        // this.dateTemplate = this.dateTemplate.bind(this);
    }

    // componentWillReceiveProps = (nextProps) => {
    //     if (!equals(this.props.appointmentIdList, nextProps.InfoList) || !equals(this.props.pickUpZoneList, nextProps.value)) {
    //         this.setState({ appointmentIdList: nextProps.InfoList, pickUpZoneList: nextProps.value })
    //     }
    // }

    render() {

        const { classes, TitleCheckBox, disabled, StateInfo, Language, packageId, handleToggle, Items = [] } = this.props
        const { isCloseListService, closeListService } = StateInfo;
        const { date, sysDate } = this.state;

        (closeListService) && closeListService.sort();
        checkDateList = [];

        return (
            <React.Fragment>
                <ListItemsContianer>
                    {
                        Items.map((each, index) => (
                            <ListItem key={index} dense button
                                className={classes.item}
                                disabled={disabled}
                                onClick={handleToggle('isCloseListService', each)}>
                                <Checkbox color="primary" className={classes.checkbox} disabled={disabled} checked={isCloseListService} tabIndex={-1} disableRipple />
                                <ListItemText primary={`${each[Language].name || each}`} />
                            </ListItem>
                        ))
                    }
                </ListItemsContianer>

                <CalendarContianer>
                    <Calendar
                        inline
                        value={date}
                        disabled={!isCloseListService ? true : disabled}
                        style={CalendarStyle}
                        viewDate={sysDate}
                        onChange={(e) => this.onChangeState(e.value)}
                        selectionMode="multiple"
                        readonlyInput={true}
                        dateTemplate={this.setDateTemplate}
                        numberOfMonths={3}
                        onViewDateChange={(e) => this.setState({ sysDate: e.value })}>
                    </Calendar>
                </CalendarContianer>
                {/* </React.Fragment>
                } */}
            </React.Fragment >
        )
    }


    setDateTemplate(date) {

        const { closeListService = [] } = this.props.StateInfo;
        const amountDate = checkDateList.length;

        if (date.selectable) {
            if (amountDate < closeListService.length) {
                if (closeListService.indexOf(this.DateFormat(date)) > -1) {
                    checkDateList.push(this.DateFormat(date));
                    return (<DateHighlight>{date.day}</DateHighlight>)
                }
            }
        }

        return date.day;
    }

    DateFormat(date) {

        return `${date.year}-${getFormatted(date.month + 1)}-${getFormatted(date.day)}`;
    }

    onChangeState = (event) => {

        const { closeListService = [] } = this.props.StateInfo;
        const selectDate = SetDateFormat(event)
       if (closeListService) {
            if (closeListService.length > 0) {

                const currentIndex = closeListService.indexOf(selectDate);

                if (currentIndex === -1)
                    closeListService.push(selectDate);
                else
                    closeListService.splice(currentIndex, 1);
            }
            else
                closeListService.push(selectDate);
        }

        this.props.onChangeText('closeListService', closeListService)
    }

}

const styles = theme => (
    {
        root: { border: `1px solid ${Colors.border}`, padding: '10px', margin: '15px 0px', position: 'relative' },
        row: { display: 'flex' },
        item: { display: 'flex', width: '100%', alignItems: 'center', padding: '0px', marginLeft: '-12px' },
    }
)

CloseServicePanel.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(CloseServicePanel);

const CalendarStyle = { width: '100%' };

const DateHighlight = styled.span`
        background-color: ${Colors.theme};
        color: ${Colors.white};
        font-weight: bold;
        line-height: 2.26em;
        padding: 4px 6px;
        border-radius: 3px;` ;

const ListItemsContianer = styled.div`
        display: flex;
        flex-direction: row;`;

const CalendarContianer = styled.div`
        justify-content: center;
        display: grid;
        width: 100%;`;

const FromLabelContianer = styled.div`
        color: ${Colors.themeDark};
        font-size: 13px;
        font-family: ${Family.roboto};
        font-weight: 300;
        padding-top: px;`;



