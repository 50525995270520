import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux'
import { path, update, keys, omit } from 'ramda'
import validate from "validate.js";
import * as validator from '../../constant/Validator/Category';

import { Colors, Family } from '../../themes';
import FormTypes from '../../common/FormTypes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import Language from '../../constant/Language';

import Category from '../../constant/JSON/Category';
import PackagePanel from '../../componets/PackagePanel';
// import { init_category } from '../../constant/initState/initCategory';
import DialogAction from '../../componets/DialogAction.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';
import * as format from '../../common/setFormat';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let Structure = Category.BodyPage
let Header = Category.HeaderPage
let waitingTime = true;
let PackageList = [];
let LanguagePage = 'EN';

class CategoryCard extends Component {
    error = null;
    constructor(props) {
        super(props);
        const { categoryInfo, isEdit } = this.props;
        let listId = [];
        if (categoryInfo) {
            listId = categoryInfo.packageList.map((val) => (val.packageId)) || [];
        }
        this.state = {
            categoryInfo: categoryInfo,
            isBtnActive: false,
            isPackage: false,
            packageId: (isEdit) ? listId : [],
            isLoading: false, msgError: undefined, dialogType: undefined

        }
        this.props.getAPIPackageList()
    }


    componentWillReceiveProps(nextProps) {
        const { PackageManager } = nextProps;
        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;
            switch (actions) {

                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime)
                        PackageList = data.packageList || [];
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.CREATE_PACKAGE_CATEGORY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'CREATE_PACKAGE_CATEGORY_SUCCESS', dialogType: 'success', isUpdate: true });
                        this.props.getAPIPackageCategory({ limit: 10 });
                    } break;

                case PackageAction.UPDATE_PACKAGE_CATEGORY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE_PACKAGE_CATEGORY_SUCCESS', dialogType: 'success', });
                        this.props.getAPIPackageCategory({ limit: 10 });
                    } break;

                case PackageAction.CREATE_PACKAGE_CATEGORY_FAILURE:
                case PackageAction.UPDATE_PACKAGE_CATEGORY_FAILURE:
                    {
                        const _msg = data.response.data.result || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error' });
                    } break;
            }
        }
    }

    componentWillUnmount() {
        LanguagePage = 'EN';
    }

    render() {
        const { isEdit, isView } = this.props;
        const { isPackage, isLoading, msgError, dialogType } = this.state;
        return (
            <Contianer>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}

                {(isPackage) && <DialogAction Active={isPackage} OnClose={this.onClosePanel} AcrionCom={this.renderPackageList()} />}
                {(!isView) &&
                    <Fragment>
                        <TypographyContianer>
                            <Typography> {(isEdit) ? Header.UPDATE : Header.CREATE}  </Typography>
                            <Language LanguagePage={LanguagePage} onChangeState={this.onChangeLanguage} isValue={true} />
                        </TypographyContianer>
                        <Line />
                    </Fragment>
                }
                <ListContainer> {this.renderContent({ Structure, isEdit, isView })}  </ListContainer>
                {this.renderButton()}
            </Contianer>
        )
    }

    renderContent(props) {
        const { Structure } = props;
        return (
            Structure.map((row, index) => (
                <ListItmes key={index}>
                    {
                        row.map((each, index) => (
                            <List key={index} style={each.style}>
                                {this.renderFormTypes(each)}
                            </List>
                        ))
                    }
                </ListItmes>
            ))
        )
    }


    renderFormTypes(each) {
        const { isView } = this.props;
        const { categoryInfo, isUpdate } = this.state;
        let isError = !!path([each.stateName], this.error)
        switch (each.type) {
            case 'text':
                return (
                    <FormTypes.TextFormat error={isError}
                        Require={(isView) ? false : each.require}
                        disabled={(isView || isUpdate) ? true : each.disabled}
                        TitleText={each.title_EN}
                        value={categoryInfo[LanguagePage][each.stateName]}
                        onChange={this.onChangeState(each.stateName)} />
                )
            case 'text-multiline':
                return (
                    <FormTypes.TextFormat multiline rows={4} error={isError}
                        Require={(isView) ? false : each.require}
                        disabled={(isView || isUpdate) ? true : each.disabled}
                        TitleText={each.title_EN}
                        value={categoryInfo[LanguagePage][each.stateName]}
                        onChange={this.onChangeState(each.stateName)} />
                )
            case 'number':
                return (
                    <FormTypes.TextFormat error={isError}
                        InputFormat={format.NumberFormatCustom}
                        Require={(isView) ? true : each.require}
                        disabled={(isView || isUpdate) ? true : each.disabled}
                        TitleText={each.title_EN}
                        value={(categoryInfo[each.stateName] === 0) ? '0' : categoryInfo[each.stateName]}
                        onChange={this.onChangeState(each.stateName)} />
                )
            case 'checkbox':
                return (
                    <FormTypes.Checkbox
                        disabled={(isView || isUpdate) ? true : each.disabled}
                        style={each.style}
                        TitleText={each.title_EN}
                        value={categoryInfo[each.stateName]}
                        onChange={this.onChangeState(each.stateName)} />
                )
            case 'listItem':
                let InfoList = categoryInfo[each.stateName] || []
                return (
                    <Fragment>
                        {
                            InfoList.map((text, index) => {
                                let packageName = PackageList.filter((val) => val.packageId === text.packageId).map((e) => e.packageInfo.name)[0]
                                return (
                                    <ListItmeContianer key={index}>
                                        <FormTypes.TextFormat disabled TitleText={'Package'}
                                            value={(isView) ? text.packageInfo.name : packageName} />
                                        <FormTypes.TextFormat TitleText={'Priority Display'}
                                            InputFormat={format.NumberFormatCustom}
                                            style={{ width: '30%', marginLeft: 15 }}
                                            disabled={(isView || isUpdate) && true}
                                            value={(text.priorityDisplay === 0) ? '0' : text.priorityDisplay}
                                            onChange={this.onChangeState('priorityDisplay', index)} />
                                    </ListItmeContianer>
                                )
                            })
                        }
                        {(!isView) && <Button.ThemesDark FullWidth
                            disabled={(isView || isUpdate) ? true : each.disabled}
                            TitleText={'+ Add Package Category'}
                            onClick={() => this.renderDialog('package')} />}
                    </Fragment>
                )
        }
    }

    renderDialog(_type) {
        switch (_type) {
            case 'package': { this.setState({ isPackage: true }) } break;
        }
    }

    renderPackageList = () => {
        return (
            <PackagePanel
                PackageList={PackageList}
                packageId={this.state.packageId}
                handleToggle={this.handleToggle}
                onClosePanel={this.onClosePanel} />

        )
    }

    handleToggle = (_value) => {
        let categoryInfo = { ...this.state.categoryInfo };
        let packageId = [...this.state.packageId];
        let packageList = [...categoryInfo.packageList];
        const currentIndex = packageId.indexOf(_value.packageId);

        if (currentIndex === -1) {
            packageId.push(_value.packageId);
            packageList.push({
                packageId: _value.packageId,
                priorityDisplay: 0
            })
        } else {
            packageId.splice(currentIndex, 1);
            packageList = packageList.filter((item) => item.packageId !== _value.packageId);
        }
        this.setState({
            isBtnActive: true, packageId, categoryInfo: { ...categoryInfo, packageList }
        });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => { this.setState({ isPackage: false }) }

    renderButton() {
        const { onClosePanel, isView } = this.props;
        const { isBtnActive } = this.state;
        return (
            <ButtonContianer>
                <Button.Themes TitleText={ButtonText.BUTTON_CANCEL}
                    onClick={onClosePanel}
                    buttonStyle={{ margin: 0, padding: 7 }} />
                {(!isView) && <Button.Themes TitleText={ButtonText.BUTTON_SAVE}
                    onClick={() => this.onSave()}
                    style={{ marginLeft: 10 }}
                    disabled={!isBtnActive}
                    buttonStyle={{ margin: 0, padding: 7 }} />}
            </ButtonContianer>
        )
    };

    onAddList = (_fieldName, _textArray) => {
        let categoryInfo = { ...this.state.categoryInfo };
        switch (_fieldName) {
            default: {
                categoryInfo = { ...categoryInfo, [_fieldName]: _textArray.concat({ 'packageId': '', 'priorityDisplay': '' }) }
            }
        }
        this.setState({ categoryInfo, isBtnActive: true })
    }
    onChangeLanguage = (_language) => {
        LanguagePage = _language;
        this.forceUpdate();
    }
    onChangeState = (_fieldName, _index) => (event) => {
        let value = event.target.value;
        let checked = event.target.checked;

        let categoryInfo = { ...this.state.categoryInfo };
        // const { LanguagePage } = this.props;

        console.log('[onChangeState] :', value, _fieldName, _index)

        switch (_fieldName) {
            case 'description':
            case 'nameCategory': {
                categoryInfo = { ...categoryInfo, [LanguagePage]: { ...categoryInfo[LanguagePage], [_fieldName]: value } }
            } break;
            case 'visible': {
                categoryInfo = { ...categoryInfo, [_fieldName]: checked }
            } break;
            case 'priorityDisplay': {
                categoryInfo = {
                    ...categoryInfo,
                    packageList: update(_index, { ...categoryInfo.packageList[_index], [_fieldName]: parseInt(value) || 0 }, categoryInfo.packageList)
                }
            } break;
            default: {
                categoryInfo = { ...categoryInfo, [_fieldName]: value }
            } break;
        }
        console.log('categoryInfo success :', categoryInfo)
        this.setState({ categoryInfo, isBtnActive: true }, () => { this.validate({ [_fieldName]: value }) })
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        let validateResult = null;
        const { categoryInfo } = this.state;
        validator.input.forEach(each => {
            switch (each.fieldName) {
                case 'order':
                    validateResult = this.validate({ [each.fieldName]: categoryInfo[each.fieldName], });
                    break;
                default:
                    validateResult = this.validate({ [each.fieldName]: categoryInfo[LanguagePage][each.fieldName], });
                    break;
            }
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        const { isEdit } = this.props;
        const { isUpdate } = this.state;
        let categoryInfo = { ...this.state.categoryInfo };
        console.log('[onSave] :', isUpdate, this.error)
        if (this.validateAll() && categoryInfo.packageList.length > 0) {
            if (isEdit) {
                let new_packageList = categoryInfo.packageList.map((val) => ({ packageId: val.packageId, priorityDisplay: val.priorityDisplay })) || []
                categoryInfo = {
                    packageCategoryId: categoryInfo.packageCategoryId,
                    TH: categoryInfo.TH,
                    EN: categoryInfo.EN,
                    CN: categoryInfo.CN,
                    order: parseInt(categoryInfo.order),
                    visible: categoryInfo.visible,
                    packageList: new_packageList
                }
                console.log('payload categoryInfo', categoryInfo)
                this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APIUpdatePackageCategory(categoryInfo))
            } else {
                categoryInfo.order = parseInt(categoryInfo.order)
                console.log('payload categoryInfo', categoryInfo)
                this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APICreatePackageCategory(categoryInfo))
            }
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false })
        }
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getAPIPackageCategory: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageCategory(_keyword));
        },
        APICreatePackageCategory: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.APICreatePackageCategory(_keyword));
        },
        APIUpdatePackageCategory: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.APIUpdatePackageCategory(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryCard);


const Contianer = styled.div`
            max-width: 800px;
            font-family: ${Family.roboto};`;
const Line = styled.div`
            height:3px;
            background-color:${Colors.theme}`;
const Typography = styled.div`            
            padding: 15px 0px;
            font-size: 24px;`;
const ListContainer = styled.div`
            display: flex;
            flex-direction: column;
            padding: 10px 0px;
            font-size: 16px; `;
const ListItmeContianer = styled.div`
            display: flex;
            padding: 7px 0px;`;
const ListItmes = styled.div`
            display: flex;
            padding: 5px 0px;
            width: 100%;`;
const List = styled.div`
            width: ${props => props.width ? props.width : '100%'}`;
const TypographyContianer = styled.div`
            display: flex;
            justify-content: space-between;
            align-items: center`;
const ButtonContianer = styled.div`
            display: flex;
            justify-content: flex-end;`;
const Title = styled.div`
            font-weight:bold;
            padding-right:5px;`;
const Label = styled.div`
            color:${Colors.grey};
            font-weight:300; `;
