import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '../../themes';
import '../../themes/Themes.css';

const styles = theme => (
    {
        button: {
            color: Colors.white,
            padding: '10px',
            margin: '15px 0px 0px',
            width: '100%',
        }
    }
);

const ThemesDark = (props) => {
    const { classes, FullWidth } = props;
    return (
        <div style={{ width: (FullWidth) ? '100%' : 150, cursor: 'pointer', ...props.style }}>
            <Button
                id='hoverButtonDrak'
                className={classes.button}
                style={{ backgroundColor: props.disabled ? Colors.disabled : Colors.themeDark, ...props.buttonStyle }}
                disabled={props.disabled}
                onClick={() => props.onClick()}>
                {props.TitleText}
            </Button>
        </div >
    );

};

ThemesDark.propTypes = { classes: PropTypes.object.isRequired, };
ThemesDark.propTypes = { onClick: PropTypes.func };

ThemesDark.defaultProps = { onClick: () => console.warn('default onClickCancel') }

export default withStyles(styles)(ThemesDark);

