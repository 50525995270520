import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


import Button from '../../constant/Button';
import SearchPanel from '../../componets/SearchPanel.jsx';
import EmptyRows from '../../componets/EmptyRow.jsx';
import SortHandle from '../../componets/TableManage/SortHandle.jsx';

import DialogPanel from '../../componets/DialogPanel.jsx';

import { Colors } from '../../themes/index.js';
import { GetSymbol } from '../../common/GetSymbol.js';
import { NumberFormats } from '../../common/SetNumberFormat';
import { mapPaymentType } from '../../common/PaymentMethod';
import { GetStatusColor } from '../../common/GetStatusColor';
import { SetDate, SetDateShort } from '../../common/SetDate';
import { Roles } from '../../constant/JSON/Roles.js';

import * as BookingAction from '../../actions/Actions_BookingManager';
import * as AccountingActions from '../../actions/Actions_Accounting.js';

import { InitwaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

// import { isMobile } from '../common/DeviceCheck.js';

const _wording = { header: 'TRANSACTION HISTORY MANAGE' };

const fildeList = [
    { label: 'TRANSACTION ID', name: '', isOrder: true },
    { label: 'TRANSACTION DATE', name: '', isOrder: false },
    { label: 'TYPE', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: 'AMOUNT', name: '', isOrder: false }];

const fildeListsMobile = [
    { label: 'TRANSACTION DATE', name: '', isOrder: false },
    { label: 'TYPE', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: 'AMOUNT', name: '', isOrder: false }];

let accountList = [];
let AccountDetailList = [];
let waitingTime = false;

/* Desktop */
const _callStyle = { textAlign: 'center', padding: '5px', border: `${'1px solid' + Colors.white}` };
const _callCustomStyle = { ..._callStyle, backgroundColor: Colors.themeDark, color: Colors.white, }
const _callName = { ..._callStyle, textAlign: 'left', paddingRight: '15px', maxWidth: 280 };

/* Mobile */
const _callStyleMobile = { ..._callStyle, padding: 2, fontSize: 11, width: 50, };
const _callNameMobile = { ..._callStyleMobile, textAlign: 'left', maxWidth: 80, };


class TransactionHistory extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: true }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {

        // console.log('nextProps', nextProps);

        // const { BookingManager } = nextProps;

        // if (!!BookingManager) {
        //     const { actions, data } = BookingManager.BOOKING_REDUCER;

        //     switch (actions) {
        //         case BookingAction.GET_BOOKING_LISTS_SUCCESS:
        //             {
        //                 accountList = data;
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //         case BookingAction.GET_BOOKING_DETAIL_SUCCESS:
        //             {
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //     }
        // }

        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.GET_ACCOUNT_DETAIL_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        AccountDetailList = data.result || [];
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_ACCOUNT_DETAIL_FAILURE:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;

            }
        }
    }

    render() {

        const { classes, AccountDetail, userInfo, isMobile, Role } = this.props;
        const { isLoading, msgError, dialogType, orderBy, searchActive } = this.state;

        accountList = AccountDetailList.data || [];

        const _isAccount = (accountList.length > 0);
        const fildeLists = (isMobile) ? fildeListsMobile : fildeList;

        // const _isSeller = Boolean(userInfo);
        // const isCallStyle = (isMobile) ? _callCustomStyleMobile : _callCustomStyle;

        // console.log('props', this.props);

        return (

            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={(isMobile) ? classes.tableMobile : classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeLists.length} style={{ padding: 0 }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, fontSize: (isMobile) ? 12 : 14, padding: (isMobile) ? 10 : 15 }}
                                        onClick={() => this.setState({ searchActive: !searchActive })} />
                                    {/* {(searchActive) && <SearchPanel searchActive={searchActive} SearchType='booking' userInfo={userInfo} />} */}
                                </TableCell>
                            </TableRow>
                            <TableRow >
                                {fildeLists.map((filde, index) => {
                                    return (
                                        <TableCell key={index} style={{ ..._callCustomStyle, paddingLeft: (filde.isOrder) ? 25 : 10, fontSize: (isMobile) ? 10 : 12 }} >
                                            <SortHandle style={{ color: Colors.white }} orderBy={orderBy} field={filde} handleRequestSort={this.handleRequestSort} />
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(_isAccount) ? GetBookingRows(classes, accountList, AccountDetail, isMobile, Role) : <EmptyRows fildeList={fildeLists} />}
                        </TableBody>
                    </Table>
                    {(_isAccount) && GetPagination(AccountDetailList, this.onNextPage, this.onChangeLimit, userInfo, Role)}
                </Paper>
            </React.Fragment>
        )
    }

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        // console.log('handleRequestSort : ', sortFilde, _fildeName, accountList);

        accountList = (orderBy === 'desc')
            ? accountList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : accountList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));
        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onNextPage = (_pageNumber, limit, userInfo, Role) => {

        this.setState({ isLoading: true });
        console.log('onNextPage : ', _pageNumber, limit, userInfo, Role)

        if (!!userInfo)
            this.props.getAPIAccountDetail({ serviceId: userInfo.sellerId, customerType: Role, page: _pageNumber, num_limit: (limit || 25) });
        else
            this.props.getAPIAccountDetail({ serviceId: " ", customerType: 'admin', page: (_pageNumber || 0), num_limit: (limit || 25) });
    }

    onChangeLimit = (_limitRows, userInfo, Role) => {

        this.setState({ isLoading: true });
        console.log('onChangeLimit : ', _limitRows, userInfo, Role)

        // console.log('onChangeLimit : ', userInfo)
        // serviceId: " ", customerType: _role, num_limit: 25 
        if (!!userInfo)
            this.props.getAPIAccountDetail({ serviceId: userInfo.sellerId, page: 0, num_limit: _limitRows, customerType: Role });
        else
            this.props.getAPIAccountDetail({ serviceId: " ", customerType: 'admin', page: 0, num_limit: _limitRows });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

}

function GetBookingRows(classes, accountList, AccountDetail, isMobile, Role) {
    return accountList.map(_info => {

        const { bookingId, bookingNo, bookingStatus, type, paymentDate, amount } = _info;
        const isCallStyle = isMobile ? _callStyleMobile : _callStyle;
        const isCallNameStyle = isMobile ? _callNameMobile : _callName;

        let currency = 'TH'
        let amountPrice;
        let colorType;

        switch (Role) {
            case Roles.Admin:
                {
                    amountPrice = amount;
                    colorType = 'Booking';
                } break;
            case Roles.Seller:
            default:
                {
                    amountPrice = amount;
                    colorType = 'Transaction';
                }
        }

        // console.log('_transaction : ', priceTotal);

        return (
            <TableRow hover tabIndex={-1} className={classes.row} key={bookingId} style={{ cursor: 'pointer', }} onClick={() => { AccountDetail(bookingId) }}>
                {(!isMobile) && <TableCell style={isCallStyle}>{bookingNo || bookingId}</TableCell>}
                <TableCell style={isCallStyle}>{SetDate(paymentDate)}</TableCell>
                <TableCell style={GetStatusColor(colorType, isCallStyle, bookingStatus)}>{type}</TableCell>
                <TableCell style={GetStatusColor(colorType, isCallStyle, bookingStatus)}>{bookingStatus}</TableCell>
                <TableCell style={GetStatusColor(colorType, isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(amountPrice || 0)}`}</TableCell>
                {/* <TableCell  style={isCallStyle}>{traveller || '-'}</TableCell> */}
                {/* <TableCell  style={GetStatusColor(isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(subTotal || net)}`}</TableCell>
                <TableCell  style={GetStatusColor(isCallStyle, bookingStatus)}>{`${GetSymbol(currency)}${NumberFormats(affiliatePrice || 0)}`}</TableCell> */}
            </TableRow>)
    });
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, userInfo, Role) {

    const { limit, page, pageSize, total } = _ListCount;
    // console.log('_ListCount', _ListCount);

    return (<TablePagination
        component="div"
        count={total || 0}
        rowsPerPage={(limit || 25)}
        page={page || 0}
        backIconButtonProps={{ 'aria-label': 'Previous Page', }}
        nextIconButtonProps={{ 'aria-label': 'Next Page', }}
        onChangePage={(event, page) => { onNextPage(page, limit, userInfo, Role) }}
        onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, userInfo, Role) }} />)
}

const styles = theme => (
    {
        root: { width: '100%', marginTop: (theme.spacing.unit * 3), marginTop: '0px', maxWidth: 1024 },
        table: { minWidth: 1024, },
        tableMobile: { width: '100%' },
        textField: { width: 400, },
        customTable: {
            textAlign: 'center',
            paddingLeft: '30px',
            paddingRight: '5px',
            backgroundColor: Colors.themeDark,
            color: Colors.white,
            border: `${'1px solid' + Colors.white}`
        },
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: Colors.themeDark_Light,
            },
            '&:nth-of-type(odd):hover': {
                backgroundColor: Colors.themeDark_Item,
            },
        },
    });

TransactionHistory.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        // getAPIBookingDetails: (_id) => {
        //     dispatch(BookingAction.getAPIBookingDetail(_id));
        // },
        // getAPIBookingLists: (_keyword, _filter) => {
        //     dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        // },
        getAPIAccountDetail: (_filter) => {
            dispatch(AccountingActions.APIAccountDetail(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransactionHistory));
