import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import SellerCard from '../containers/Sellers/SellerCard';
import { PathName, ErrorCode } from '../constant';

import SellerListPanel from '../containers/Sellers/SellerListPanel.jsx'
import SellerStatPanel from '../containers/Sellers/SellerStatPanel.jsx'

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import ContentPanels from '../componets/ContentManage/ContentPanel.jsx';
import * as SellerActions from '../actions/Actions_SellerManager.js';

import { init_seller, SetSellerToCreate } from '../constant/initState/initSeller';
import * as UploadActions from '../actions/Actions_UploadManager';
import * as BankActions from '../actions/Actions_Settings';
import * as SettingActions from '../actions/Actions_Settings';

import '../App.css';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let SellerList = [];
let BookBankList = [];
let SellerTypeList = [];
let DefaultCommisstions = [];

class SellerManage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isCreate: false,
            isEdit: false,
            isInfo: false,
            isLoading: true,
            isDialog: false,
            SellerInfo: undefined,
            oldSellerInfo: null,
            msgError: undefined,
            dialogType: undefined
        }

        this.props.getAPISellerList({ page: 0, num_limit: 10, orderBy: 'desc' });//'desc'
        // this.props.getAPISellerList({ page: 0, num_limit: 10 });//'desc'
        this.props.getAPIBankList({ num_limit: 100 });
        this.props.getAPISellerTypeList();
        this.props.getDefaultCommissions();
    }


    componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps);
        const { SellerManager, SettingManager, UpLoadManager } = nextProps;

        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;
            const { isEdit, isCreate } = this.state;

            switch (actions) {
                case SellerActions.GET_SELLER_TYPE_SUCCESS:
                    {
                        SellerTypeList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_TYPE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_SUCCESS:
                    {
                        SellerList = data.result;
                        if (isEdit || isCreate)
                            SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case SellerActions.GET_SELLER_FAILURE:
                    {
                        if (isEdit || isCreate)
                            SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.CREATE_SELLER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Success for register, please verify you account on your email.', dialogType: 'success' });
                        this.props.getAPISellerList({ num_limit: 10 });
                    } break;

                case SellerActions.UPDATE_SELLER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Seller Success.', dialogType: 'success' });
                        this.props.getAPISellerList({ num_limit: 10 });
                    } break;

                case SellerActions.CREATE_SELLER_FAILURE:
                case SellerActions.UPDATE_SELLER_FAILURE:
                    {
                        console.log("CREATE_SELLER_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!UpLoadManager) {

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            const { SellerInfo, isEdit } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let _newSeller = this.state.SellerInfo;
                        _newSeller.logo = data.result.upload.images;

                        // const { isEdit } = this.state;

                        if (isEdit) {
                            console.log('UPLOAD_IMAGE_SUCCESS :', data.result.upload.images)
                            this.props.APIUpdateSeller(_newSeller);
                        } else {
                            console.log('UPLOAD_IMAGE_SUCCESS :')
                            SellerInfo.agentId = this.props.agentId;
                            this.props.APICreateSeller(SetSellerToCreate(_newSeller))
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case BankActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BankActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        DefaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { SellerInfo, isLoading, isDialog, isCreate, msgError, dialogType, isInfo } = this.state;

        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.CreateSellerPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={this.onCloseLoading()} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={this.onCloseDialog()} />}
                <div style={{ display: 'inline-block', width: '100%', }}>
                    <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                        <div style={{ display: 'inline-block', width: '100%', maxWidth: '1024px' }}>
                            {this.contentPanel()}
                        </div>
                    </List>
                </div>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <SellerStatPanel SellerList={SellerList} history={this.props.history} isLoading={this.state.isLoading} />
                <SellerListPanel Level={DefaultCommisstions} CreatePanel={this.onCreatePanel(this.state.isCreate)} SellerTypeList={SellerTypeList}
                    DisplaySellerInfo={this.DisplaySellerInfo(PathName.SellerInfo)} EditInfo={this.EditSellerInfo()} SellerList={SellerList} />
            </div>)
    }

    getAPISellerListWithLimit = () => () => {
        const { page, limit, } = SellerList;
        return this.props.getAPISellerList({ page: (page || 0), num_limit: (limit || 10) });
    }

    CreateSellerPanel = () => {

        const { isEdit, SellerInfo, oldSellerInfo } = this.state;
        // console.log('[SellerInfo]', SellerInfo)
        return (
            <SellerCard
                Level={DefaultCommisstions}
                SellerTypeList={SellerTypeList}
                BookBankList={BookBankList}
                SellerInfo={SellerInfo}
                oldSellerInfo={oldSellerInfo}
                onClosePanel={this.onClosePanel}
                isEdit={isEdit}
                onCloseDialog={this.onCloseDialog}
                APICreateSeller={this.APICreateSeller}
                APIUpdateSeller={this.APIUpdateSeller}
            />
        )
    }

    APICreateSeller = (avatar, sellerInfo) => {
        // console.log('APICreateSeller', avatar, sellerInfo);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APICreateSeller(SetSellerToCreate(sellerInfo)));
        }
    }
    APIUpdateSeller = (avatar, sellerInfo) => {
        // console.log('APIUpdateSeller', avatar, sellerInfo);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, SellerInfo: sellerInfo, isEdit: true }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpdateSeller(sellerInfo));
        }
    }

    onCreatePanel = (_isActive) => () => { return this.setState({ isCreate: !_isActive, oldSellerInfo: { ...init_seller }, SellerInfo: { ...init_seller } }) };
    onClosePanel = () => (this.setState({ isCreate: false, isInfo: false, isEdit: false, error: null, isLoading: false, msgError: undefined, dialogType: undefined }));
    onCloseLoading = () => () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onCloseDialog = () => () => (this.setState({ isDialog: false, msgError: undefined, dialogType: undefined }));
    EditSellerInfo = () => (SellerInfo) => { this.setState({ isCreate: true, isEdit: true, SellerInfo, oldSellerInfo: SellerInfo }) }
    DisplaySellerInfo = (page) => (_sellerId) => {
        const sellerInfo = Array.isArray(SellerList.datas) && SellerList.datas.find((val) => { return val.sellerId === _sellerId });
        this.props.history.push({ pathname: page, state: { sellerInfo }, search: '', hash: '' });
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        getAPIBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(BankActions.getBankList(_keyword));
        },
        APICreateSeller: (_confirmInfo) => {
            dispatch(SellerActions.APICreateSeller(_confirmInfo));
        },
        APIUpdateSeller: (_confirmInfo) => {
            dispatch(SellerActions.APIUpdateSeller(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SellerManage);



















