import React, { Component, } from 'react';
import { connect } from 'react-redux';
import CONFIG from '../../config';
import { withStyles } from '@material-ui/core/styles';
import { mathMod, sum, divide, subtract, path } from 'ramda';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import { InitWaitingTime, SetStateWithTime, } from '../../common/SetTime.js';
import { Metrics, Colors, Family } from '../../themes';
import { GetSymbol } from '../../common/GetSymbol';
import { NumberFormats } from '../../common/SetNumberFormat';

import "./card.css";
import '../../App.css';

let PromotionPackage = [];
let isDialogDisplay = false;
let waitingTime = true;
const _wording = { header: 'Package List' }

export const PackageType = { Detail: 'Package Detail', Price: 'Package Price', Total: 'Promotion Price' }

class PackagePriceInfo extends Component {

    constructor(props) {

        super(props)
        isDialogDisplay = (!!this.props.PromotionInfo);

        const { PromotionInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        // if (!!PromotionInfo) {
        // this.props.getAPIPackageList();
        this.props.getAPIPackage()
    }

    componentWillReceiveProps(nextProps) {

        const { PromotionManager, PackageManager } = nextProps;
        const { PromotionInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_SUCCESS:
                    {
                        PromotionPackage = (data.package || []);
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.GET_PACKAGE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const { PromotionInfo, } = this.props;
        const { infant, kid, adult, currency, packageId, type, percent_adult, percent_kid, percent_infant } = PromotionInfo;
        const _crrency = GetSymbol(currency);
        return (
            <div style={{ position: 'relative', width: 1024 }} >
                <div style={_style.title_bar}><label style={{ fontSize: '20px' }}>{_wording.header}</label></div>
                <div style={_style.card_container}>
                    <div style={_style.sortMenu_container}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', textAlign: 'center', }}>
                            {this.setPackagaDetailBox(PackageType.Detail, PromotionPackage, PromotionInfo, packageId)}
                            {this.setPriceBox(PackageType.Price, PromotionPackage, packageId, _crrency)}
                            {this.setTotalBox(PackageType.Total, PromotionPackage, adult, kid, infant, packageId, type, percent_adult, percent_kid, percent_infant, _crrency)}
                        </div>
                    </div>
                </div >
            </div>
        )
    }

    setPackagaDetailBox(packageDetail, PromotionPackage, PromotionInfo, packageId) {

        return (
            <div style={_style.header_col}>
                <label style={_style.label_topic_col}>{packageDetail}</label>
                <div style={{ padding: '20px', paddingLeft: '30px', textAlign: 'left', }}>
                    {
                        PromotionPackage.filter(_packages => packageId.some(each => each === _packages.packageId)).map((_info, index) => {
                            const { packageInfo, packageId, visible, type, } = _info;
                            const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10 };
                            const _backgroundImage = { height: '80px', width: '100px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${packageInfo.image.thumbnail})`, backgroundRepeat: 'round' };
                            return (
                                <div key={index} style={{ width: '100%', marginBottom: '5px', textAlign: 'left', border: '1px solid #9B9B9B', }}>
                                    <div style={{ display: 'flex', paddingLeft: 15 }}>
                                        <div style={{ flex: 1, marginTop: 15 }}>
                                            <div style={_backgroundImage} />
                                        </div>
                                        <div style={{ width: 495, textAlign: 'left' }}>
                                            <div style={_styleInfoItem}>
                                                <label style={_style.class}>Package Name : </label>
                                                <label style={{
                                                    width: '340px', overflow: 'hidden',
                                                    whiteSpace: 'nowrap', textOverflow: 'ellipsis',
                                                    ..._style.info
                                                }}>{packageInfo.name || ''}</label>
                                            </div>
                                            <div style={_styleInfoItem}>
                                                <label style={_style.class}>Package Id : </label>
                                                <label style={_style.info}>{packageId || ''}</label>
                                            </div>
                                            <div style={_styleInfoItem}>
                                                <label style={_style.class}>Package Type : </label>
                                                <label style={_style.info}>{type || ''}</label>
                                            </div>
                                            <div style={_styleInfoItem}>
                                                <label style={_style.class}>Visible : </label>
                                                <label style={_style.info}>{`${visible}` || ''}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div >
            </div >
        )
    }
    setPriceBox(type, PromotionPackage, packageId, _crrency) {

        return (
            <div style={_style.header_col}>
                <label style={_style.label_topic_col}>{type}</label>
                <div style={{ padding: '10px', paddingTop: '20px', textAlign: 'left', }}>
                    {
                        PromotionPackage.filter(_packages => packageId.some(each => each === _packages.packageId)).map((_info, index) => {
                            const { packageInfo, } = _info;
                            const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10 };
                            return (
                                <div key={index} style={{ width: '100%', paddingBottom: '24px', marginBottom: '5px', textAlign: 'left', border: '1px solid #9B9B9B', }}>
                                    <div style={_styleInfoItem}>
                                        <label style={{ ..._style.class, width: '75px' }}>Adult Price : </label>
                                        <label style={_style.info}>{`${NumberFormats(`${packageInfo.price.adult}`)} ${_crrency}` || ''}</label>
                                    </div>
                                    <div style={_styleInfoItem}>
                                        <label style={{ ..._style.class, width: '75px' }}>Kid Price : </label>
                                        <label style={_style.info}>{`${NumberFormats(`${packageInfo.price.kid}`)} ${_crrency}` || ''}</label>
                                    </div>
                                    <div style={_styleInfoItem}>
                                        <label style={{ ..._style.class, width: '75px' }}>Infant Price : </label>
                                        <label style={_style.info}>{`${NumberFormats(`${packageInfo.price.infant}`)} ${_crrency}` || ''}</label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        )
    }
    setTotalBox(packageType, PromotionPackage, adult, kid, infant, packageId, type, percent_adult, percent_kid, percent_infant, _crrency) {
        return (
            <div style={_style.header_col}>
                <label style={{ ..._style.label_topic_col, ...{ color: Colors.error } }}>{packageType}</label>
                <div style={{ padding: '10px', paddingTop: '20px', textAlign: 'left' }}>
                    {
                        PromotionPackage.filter(_packages => packageId.some(each => each === _packages.packageId)).map((_info, index) => {
                            const { packageInfo, } = _info;
                            const { price } = packageInfo;
                            let adultdiscount = divide(packageInfo.price.adult * percent_adult, 100);
                            let kiddiscount = divide(packageInfo.price.kid * percent_kid, 100);
                            let infantdiscount = divide(packageInfo.price.infant * percent_infant, 100);
                            const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10 };

                            return (
                                <div key={index} style={{ width: '100%', paddingBottom: '24px', marginBottom: '5px', textAlign: 'left', border: '1px solid #9B9B9B', }}>
                                    <div style={_styleInfoItem}>
                                        <label style={{ ..._style.class, width: '75px' }}>Adult Price : </label>
                                        <label style={{ ..._style.info, ...{ color: Colors.error, opacity: '1' } }}>
                                            {type === 'price' ? `${adult < price.adult ?
                                                NumberFormats(`${subtract(price.adult, adult)}`) : price.adult}`
                                                : type === 'percent' ? NumberFormats(`${subtract(price.adult, adultdiscount)}`)
                                                    : `${adult}`} {_crrency}</label>
                                    </div>
                                    <div style={_styleInfoItem}>
                                        <label style={{ ..._style.class, width: '75px' }}>Kid Price : </label>
                                        <label style={{ ..._style.info, ...{ color: Colors.error, opacity: '1' } }}>
                                            {type === 'price' ? `${kid < price.kid ?
                                                NumberFormats(`${subtract(price.kid, kid)}`) : price.kid}`
                                                : type === 'percent' ? NumberFormats(`${subtract(price.kid, kiddiscount)}`)
                                                    : `${kid}`} {_crrency}</label>
                                    </div>
                                    <div style={_styleInfoItem}>
                                        <label style={{ ..._style.class, width: '75px' }}>Infant Price : </label>
                                        <label style={{ ..._style.info, ...{ color: Colors.error, opacity: '1' } }}>
                                            {type === 'price' ? `${infant < price.infant ?
                                                NumberFormats(`${subtract(price.infant, infant)}`) : price.infant}`
                                                : type === 'percent' ? NumberFormats(`${subtract(price.infant, infantdiscount)}`)
                                                    : `${infant}`} {_crrency}</label>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackage: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackage(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(_style)(PackagePriceInfo));

const _style = {
    card_container: {
        height: '100%',
        width: '100%',
        borderBottom: `${'1px solid' + Colors.borderLight}`,
        borderTop: `${'1px solid' + Colors.borderLight}`,
        marginTop: '2px',
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '10px',
        backgroundColor: Colors.white
    },
    title_bar: {
        backgroundColor: Colors.theme, fontFamily: Family.roboto,
        fontSize: '30px', color: Colors.white, width: '100%', height: '40px'
    },
    header_col: {
        flex: 1,
        borderLeft: `${'1px solid' + Colors.borderLight}`,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    label_topic_col: {
        color: Colors.disabled,
        fontSize: '16px',
        fontFamily: Family.roboto
    },
    info: {
        height: '14px',
        opacity: '0.64',
        color: Colors.black,
        fontFamily: Family.roboto,
        fontSize: '13px',
        lineHeight: '14px',
        paddingLeft: '5px',
    },
    class: {
        height: '14px',
        width: '104px',
        color: Colors.black,
        fontFamily: Family.roboto,
        fontSize: '13px',
        // fontWeight: 'bold',
        lineHeight: '14px',
        textAlign: "left"
    },
};
