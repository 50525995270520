import React from 'react'
import { connect } from 'react-redux';
import { path, equals } from 'ramda';

import Typography from '@material-ui/core/Typography';
import FormTypes from '../../common/FormTypes';

import Commission from '../../constant/JSON/Commission';

import DialogPanel from '../../componets/DialogPanel.jsx';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Colors, Fonts } from '../../themes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import { SetCustomUpdate } from '../../constant/initState/initPackage';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as format from '../../common/setFormat';

let waitingTime = true;

let isActive;
const _rowContainer = { display: 'flex', padding: '2px 0px', };

const _commission = {
    diamondLevel: { adult: 0, infant: 0, kid: 0, priceType: "" },
    goldLevel: { adult: 0, infant: 0, kid: 0, priceType: "" },
    greenLevel: { adult: 0, infant: 0, kid: 0, priceType: "" },
    welcomeLevel: { adult: 0, infant: 0, kid: 0, priceType: "" },
};

class CommissionCard extends React.Component {
    error = null;
    state = { isBtnActive: false, isLoading: false, msgError: undefined, dialogType: undefined, }

    constructor(props) {
        super(props);

        const { PackageInfo } = props;

        this.state = {
            PackageInfo: PackageInfo
        }

    }
    componentWillReceiveProps(nextProps) {

        const { PackageManager, } = nextProps;

        if (!!PackageManager) {
            const { actions, data } = PackageManager;
            const { page, language, limit } = this.props.packageInfoList;
            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
                case PackageAction.UPDATE_PACKAGE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update PackageTopList Success.', dialogType: 'success', });
                        this.props.getAPIPackageList({ page: page, limit: limit, language: language });
                    } break;
                case PackageAction.UPDATE_PACKAGE_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }

    render() {

        const { isBtnActive, isLoading, msgError, dialogType } = this.state;
        const { classes, isView } = this.props;

        return (
            <div className={classes.root}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {!isView && <Typography className={classes.header}> {Commission.HeaderPage.UPDATE_EN} </Typography>}
                </div>
                {Commission.BodyPage.map((row, index) => (
                    <div key={index} style={_rowContainer}>
                        {row.map((each, index) => {
                            let _marginRight = each.margin === 'right';
                            return (
                                <div key={index} style={{ textAlign: 'start', width: '100%', marginRight: (_marginRight) ? 15 : 0 }}>
                                    {this.SetItemsWithType(each)}
                                </div>
                            )
                        })}
                    </div>
                ))}
                {!isView && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{  paddingRight: '15px', }}
                        onClick={() => this.props.onClosePanel()} />
                    <Button.Themes TitleText={ButtonText.BUTTON_SAVE} 
                        disabled={!isBtnActive}  onClick={() => { this.onSave() }} />
                </div>}
            </div>
        )
    }

    SetItemsWithType(each) {
        const { PackageInfo } = this.state
        const { classes, isView } = this.props;
        let options = (each.options) ? each.options : null;
        let isError = !!path([each.stateName], this.error)

        let { diamondLevel, goldLevel, greenLevel, welcomeLevel } = PackageInfo.commission;

        isActive = equals(diamondLevel, _commission.diamondLevel)
            && equals(goldLevel, _commission.goldLevel)
            && equals(greenLevel, _commission.greenLevel)
            && equals(welcomeLevel, _commission.welcomeLevel)
            && each.stateName !== 'isDefault';

        switch (each.type) {
            case 'title': {
                return (
                    <Typography className={classes.title}> {each.title_EN} </Typography>
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isView && true}
                        error={isError}
                        style={{ marginTop: 5, }}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path(['commission', each.stateLevel, each.stateName], PackageInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value, each.stateLevel) }}
                        options={Array.isArray(options) && options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN || ''} </option>) })} />

                )
            }
            case 'number': {
                let percentLevel = (path(['commission', each.stateLevel, 'priceType'], PackageInfo) === 'percent')
                let _percent = (each.percent && each.stateLevel && percentLevel) ? true : false;
                let isType = path(['commission', each.stateLevel, 'priceType'], PackageInfo) === "";

                return (
                    <FormTypes.TextFormat
                        InputFormat={_percent ? format.PercentFormat : format.PriceFormat}
                        disabled={(isView || isType) && true}
                        error={isError}
                        style={{ marginBottom: 25, marginTop: 4, }}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path(['commission', each.stateLevel, each.stateName], PackageInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value, each.stateLevel) }} />
                )
            }
            case "switch": {
                return (
                    <FormTypes.Switches
                        Left
                        disabled={(isView || isActive) && true}
                        ValueText={each.title_EN}
                        checked={path(['commission', each.stateName], PackageInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }
        }
    }
    onChangeState = (fieldName, state, fieldLevel, ) => {
        console.log('onChangeState', fieldName, state);
        let PackageInfo = { ...this.state.PackageInfo };
        const { defaultCommissions } = this.props;

        let defaultDiamond = defaultCommissions.filter(each => each.valueType === 'diamondLevel')
        let defaultGold = defaultCommissions.filter(each => each.valueType === 'goldLevel')
        let defaultGreen = defaultCommissions.filter(each => each.valueType === 'greenLevel')
        let defaultWelcome = defaultCommissions.filter(each => each.valueType === 'welcomeLevel')


        const new_commission = {
            diamondLevel: { adult: defaultDiamond[0].adult, infant: defaultDiamond[0].infant, kid: defaultDiamond[0].kid, priceType: defaultDiamond[0].priceType },
            goldLevel: { adult: defaultGold[0].adult, infant: defaultGold[0].infant, kid: defaultGold[0].kid, priceType: defaultGold[0].priceType },
            greenLevel: { adult: defaultGreen[0].adult, infant: defaultGreen[0].infant, kid: defaultGreen[0].kid, priceType: defaultGreen[0].priceType },
            welcomeLevel: { adult: defaultWelcome[0].adult, infant: defaultWelcome[0].infant, kid: defaultWelcome[0].kid, priceType: defaultWelcome[0].priceType },
        };
        switch (fieldName) {
            case "priceType": {
                PackageInfo = {
                    ...PackageInfo,
                    commission: {
                        ...PackageInfo.commission,
                        [fieldLevel]: { ...PackageInfo.commission[fieldLevel], adult: 0, kid: 0, infant: 0, [fieldName]: state }
                    }
                }
            } break;
            case "adult":
            case "kid":
            case "infant": {
                if (PackageInfo.commission[fieldLevel].priceType === 'percent') {
                    PackageInfo = {
                        ...PackageInfo,
                        commission: {
                            ...PackageInfo.commission,
                            [fieldLevel]: { ...PackageInfo.commission[fieldLevel], [fieldName]: (state / 100).toFixed(2) }
                        }
                    }

                } else {
                    PackageInfo = {
                        ...PackageInfo,
                        commission: {
                            ...PackageInfo.commission,
                            [fieldLevel]: { ...PackageInfo.commission[fieldLevel], [fieldName]: parseFloat(state) || 0 }
                        }
                    }

                }
            } break;
            case "isDefault": {
                if (state === false) {
                    PackageInfo = {
                        ...PackageInfo, commission: {
                            ...PackageInfo.commission,
                            ..._commission,
                            [fieldName]: state,
                            actived: false,
                        }
                    }
                } else {
                    PackageInfo = {
                        ...PackageInfo, commission: { ...PackageInfo.commission, ...new_commission, [fieldName]: state, }
                    }
                }
            } break;
            default: {
                PackageInfo = {
                    ...PackageInfo, commission: { ...PackageInfo.commission, [fieldName]: state, }
                }
            } break;
        }
        console.log('PackageInfo Success', PackageInfo);
        this.setState({ PackageInfo, isBtnActive: true });
    }

    onSave = () => {
        let Package = { ...this.state.PackageInfo }
        let Commission = path(['commission'], Package)
        Commission.welcomeLevel.adult = parseFloat(Commission.welcomeLevel.adult)
        Commission.welcomeLevel.kid = parseFloat(Commission.welcomeLevel.kid)
        Commission.welcomeLevel.infant = parseFloat(Commission.welcomeLevel.infant)

        Commission.greenLevel.adult = parseFloat(Commission.greenLevel.adult)
        Commission.greenLevel.kid = parseFloat(Commission.greenLevel.kid)
        Commission.greenLevel.infant = parseFloat(Commission.greenLevel.infant)

        Commission.goldLevel.adult = parseFloat(Commission.goldLevel.adult)
        Commission.goldLevel.kid = parseFloat(Commission.goldLevel.kid)
        Commission.goldLevel.infant = parseFloat(Commission.goldLevel.infant)

        Commission.diamondLevel.adult = parseFloat(Commission.diamondLevel.adult)
        Commission.diamondLevel.kid = parseFloat(Commission.diamondLevel.kid)
        Commission.diamondLevel.infant = parseFloat(Commission.diamondLevel.infant)

        // console.log('onSave', Package)
        this.setState({ isLoading: true, isBtnActive: false }, () => this.props.getAPIupdatePackage(SetCustomUpdate('Commission', Package, Package.packageInfo)))

    }
    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({
    root: { minWidth: '500px' },
    title: { fontSize: 18, },
    header: { fontSize: '24px', display: 'flex', padding: '10px 0px 25px', fontWeight: 400, },
});

CommissionCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { PackageManager: state.PackageManager.PACKAGE_REDUCER, }; }
function mapDispatchToProps(dispatch) {
    return {
        getAPIupdatePackage: (packageInfo) => {
            dispatch(PackageAction.APIUpdatePackage(packageInfo));
        },
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CommissionCard))