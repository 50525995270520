import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { path, equals, keys, omit } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import validate from "validate.js";
import Switch from '@material-ui/core/Switch';

import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Level';
import Level from '../../constant/JSON/Level';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import FormTypes from '../../common/FormTypes';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { Family, Colors } from '../../themes';
import { init_level } from '../../constant/initState/initLevel';
import * as SettingActions from '../../actions/Actions_Settings';
import * as format from '../../common/setFormat';

const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex' };
let waitingTime = true;
let defaultCommisstions;

class LevelCard extends Component {
    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }

    constructor(props) {
        super(props);
        this.state = {
            LevelInfo: this.props.LevelInfo,
            oldLevelInfo: this.props.oldLevelInfo,
            isUpdate: false,
        }
    }
    componentWillReceiveProps(nextProps) {
        // console.log('[ componentWillReceiveProps(nextProps) : ]', nextProps)
        const { SettingManager } = nextProps;
        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        defaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.CREATE_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'CREATE DEFAULT COMMISSIONS SUCCESS.', dialogType: 'success', });
                        this.props.getDefaultCommissions({})
                    } break;
                case SettingActions.UPDATE_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE DEFAULT COMMISSIONS SUCCESS.', dialogType: 'success', });
                        this.props.getDefaultCommissions({})
                    } break;
                case SettingActions.CREATE_DEFAULT_COMMISSIONS_FAILURE:
                case SettingActions.UPDATE_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        const _msg = data.response.data.error || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }
    render() {
        const { isLoading, msgError, dialogType, isBtnActive, } = this.state;
        const { classes, onClosePanel, isView, isEdit } = this.props;
        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? Level.HeaderPage.UPDATE_EN : Level.HeaderPage.CREATE_EN}</Typography>
                    {/* <Language LanguagePage={LanguageLocation} onChangeState={this.props.onChangeLanguage} isValue={true} /> */}
                </div>}
                {Level.BodyPage.map((row, index) => (
                    <div key={index} style={_rowContainer}>
                        {row.map((each, index) => {
                            let _marginRight = each.margin === 'right';
                            return (
                                <div key={index} style={{ textAlign: 'start', width: '100%', marginRight: (_marginRight) ? 15 : 0, paddingTop: 5 }}>
                                    {this.SetItemsWithType(each)}
                                </div>)
                        })}
                    </div>
                )
                )}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            buttonStyle={{ margin: 0, }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} buttonStyle={{ margin: 0, }}
                            disabled={!isBtnActive}
                            onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        )
    }
    SetItemsWithType(each) {

        const { isView, classes } = this.props;
        const { LevelInfo } = this.state;
        let options = (each.options) ? each.options : null;
        let isError = !!path([each.stateName], this.error)
        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], LevelInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isView && true}
                        error={isError}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], LevelInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(options) && options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN || ''} </option>) })} />
                )
            }
            case 'number': {
                return (
                    <FormTypes.TextFormat
                        InputFormat={LevelInfo.priceType === 'percent' ? format.PercentFormat : format.PriceFormat}
                        disabled={isView && true}
                        error={isError}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], LevelInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path(['actived'], LevelInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />

                )
            }
        }
    }

    onChangeState = (fieldName, state) => {
        let LevelInfo = { ...this.state.LevelInfo };
        const { oldLevelInfo } = this.state;
        switch (fieldName) {
            case "adult":
            case "kid":
            case "infant": {
                if (LevelInfo.priceType === 'percent') {
                    LevelInfo = { ...LevelInfo, [fieldName]: (state / 100).toFixed(2) }
                } else {
                    LevelInfo = { ...LevelInfo, [fieldName]: parseFloat(state) || 0 }
                }
            } break;
            case "levelName": {
                LevelInfo = { ...LevelInfo, [fieldName]: state, valueType: state }
            }
            default: { LevelInfo = { ...LevelInfo, [fieldName]: state, } }
        }
        this.setState({ LevelInfo, isBtnActive: !equals(oldLevelInfo, LevelInfo) }, () => { this.validate({ [fieldName]: state }); });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.LevelInfo[each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        let LevelInfo = { ...this.state.LevelInfo }
        const { isEdit } = this.props;
        if (this.validateAll()) {
            this.setState({ isLoading: true, isBtnActive: false })
            if (isEdit) {
                this.props.updateDefaultCommissions(LevelInfo)
            } else { this.props.createDefaultCommissions(LevelInfo) }
        } else this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
    }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));
}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
});
function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {
        createDefaultCommissions: (_filter) => {
            // InitwaitingTime();
            dispatch(SettingActions.createDefaultCommissions(_filter));
        },
        updateDefaultCommissions: (_info) => {
            // InitwaitingTime();
            dispatch(SettingActions.updateDefaultCommissions(_info));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}

LevelCard.propTypes = { classes: PropTypes.object.isRequired };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LevelCard));