import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import { Images, Family, Colors, Svg } from '../themes';
import Button from '../constant/Button';
import { isMobile } from '../common/DeviceCheck';

import config from "../config";
import { PLATFORM_NAME, } from "../config/platform";

const _userInfo = { username: '', password: '' }

const isTravisgo = config.isPlatForm === PLATFORM_NAME.TRAVISGO;
const isPhiPhi = config.isPlatForm === PLATFORM_NAME.PHIPHITOURS;


function LoginCard(props, ) {

  const { classes, Onclik, ValidateFrom, BtnActive, BtnForgot, BtnRegister } = props;
  const _cardStyle = isMobile() ? { minWidth: 'unset', padding: 20 } : {}
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>

      <div style={{ padding: isMobile() ? '30px 0px 10px' : (isTravisgo ? '60px 30px 20px' : '30px 35px') }}>
        <img src={Images.logo.login} style={{ width: isMobile() ? '60%' : '75%' }} />

      </div>
      <Card className={classes.card} style={_cardStyle}>
        <CardContent style={{ padding: 0 }}>
          <div style={{ textAlign: 'start', padding: isMobile() ? '15px 0px 10px' : '15px 0px 20px', fontSize: isMobile() ? '20px' : '24px', }}>
            <label style={_style.label_topic}>{`Bussiness Partners`}</label>
          </div>

          <div style={{ fontSize: isMobile() ? '14px' : '18px', ..._style.login }} >{'Login'}</div>

          <div style={{ borderBottom: `${'1px solid' + Colors.border}` }} />
          <TextField
            id="Username"
            label="Username"
            style={{ margin: isMobile() ? '5px 0px' : '10px 0px' }}
            className={classes.textField}
            value={undefined}
            onChange={(event) => { _userInfo.username = event.target.value; ValidateFrom(_userInfo); }}
            margin="normal" />
          <TextField
            id="Password"
            label="Password"
            type={'password'}
            style={{ margin: isMobile() ? '5px 0px' : '10px 0px' }}
            className={classes.textField}
            value={undefined}
            onChange={(event) => { _userInfo.password = event.target.value; ValidateFrom(_userInfo); }}
            onKeyPress={(event) => { if (event.key === 'Enter' && BtnActive) Onclik(); }}
            margin="normal" />
        </CardContent>
        <div style={{ textAlign: 'end', padding: isMobile() ? '5px' : '10px 5px 20px', fontSize: isMobile() ? '12px' : '16px', }}>
          <label style={_style.label_forgot} onClick={() => BtnForgot()}>
            {`Forgot Password ?`}
          </label>
        </div>
        <CardActions style={{ marginBottom: '0px', marginTop: '0px', paddingTop: '0px', paddingBottom: '0px' }}>
          <Button.Themes FullWidth TitleText={'Login'} disabled={!BtnActive} onClick={Onclik} />
        </CardActions>
        <div style={{ textAlign: 'center', padding: isMobile() ? '5px' : '10px 30px 0px 30px', }}>
          <label style={_style.label_forgot} onClick={() => BtnRegister()}>
            {`Register`}
          </label>
        </div>
      </Card>
    </div>
  );
}

LoginCard.propTypes = { classes: PropTypes.object.isRequired, };

const styles = theme => (
  {
    card: {
      minWidth: 500,
      maxWidth: 500,
      padding: 20,
      borderRadius: 0,
      backgroundColor: 'rgba(255, 255, 255, 0.74)',
    },
    textField: {
      width: '100%',
    },
  });

export default withStyles(styles)(LoginCard);

const _style = {
  login: {
    color: Colors.themeDark,
    fontFamily: Family.roboto,
    fontWeight: 600,
    lineHeight: '30px',
    textAlign: 'start',
    width: 50,
    borderBottom: `${'2px solid' + Colors.theme}`
  },
  label_topic:
  {
    color: Colors.theme,
    fontWeight: 900,
    fontFamily: Family.roboto,
    lineHeight: '40px'
  },
  label_forgot:
  {
    height: '31px',
    color: Colors.theme,
    lineHeight: '31px',
    display: 'inline-grid',
    marginTop: '5px',
    cursor: 'pointer',
    fontFamily: Family.roboto,
  },
}