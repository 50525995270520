import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
// import List from '@material-ui/core/List';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

import { PathName } from '../constant';
import { Colors, Family } from '../themes';
import EmptyRows from '../componets/EmptyRow.jsx';
import DialogAction from '../componets/DialogAction.jsx';

import DialogPanel from '../componets/DialogPanel.jsx';
import AccountingStatistic from '../containers/Accounting/AccountingStatistic.jsx';

import AccountingHistoryListPanel from '../containers/Accounting/AccountingHistoryListPanel.jsx';
import * as AccountingActions from '../actions/Actions_Accounting.js';

import { Roles } from '../constant/JSON/Roles.js';
import { isMobile, isTablet } from '../common/DeviceCheck.js';
import RefundRequestListPanel from '../containers/Accounting/RefundRequestListPanel';
import * as RefundActions from '../actions/Actions_RefundManager';
import { InitwaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';
// import RefundRequestCard from './RefundRequest/RefundRequestCard';
import RefundHistoryListPanel from '../containers/Accounting/RefundHistoryListPanel';
import RequestPanel from '../componets/RequestPanel';

import StepperComponent from '../componets/StepperComponent';
import '../App.css';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let refundRequestList = [];
let refundHistoryList = [];

let _info = JSON.parse(localStorage.getItem('UserInfo'));
let _role = (!!_info) ? (_info.userType || '') : '';

class RefundManagement extends Component {

    state = { isLoading: true, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {

        super(props);

        _info = JSON.parse(localStorage.getItem('UserInfo'));
        _role = (!!_info) ? (_info.userType || '') : '';

        switch (_role) {
            case Roles.Admin:
            default:
                {
                    this.props.APIRefundRequestList({ num_limit: 25 });
                    this.props.APIRefundHistoryList({ num_limit: 25, where: { "refundStatus": ["refuse", "success"] } });
                };
        }
    }

    componentWillReceiveProps(nextProps) {

        const { RefundManager } = nextProps;

        if (!!RefundManager) {

            const { actions, data } = RefundManager.REFUND_REDUCER

            switch (actions) {
                case RefundActions.GET_REFUND_REQUEST_LISTS_SUCCESS:
                    {
                        // RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        refundRequestList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_REQUEST_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_HISTORY_LISTS_SUCCESS:
                    {
                        // RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        refundHistoryList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_HISTORY_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    componentWillUnmount() {

    }

    render() {
        const { isLoading, msgError, dialogType, value = 0, isActive = false } = this.state;
        const _isMobile = isMobile();

        return (
            <Contianer>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {/* {(isEdit) && <DialogAction Active={isEdit} OnClose={this.onClosePanel} AcrionCom={this.updateRefundPanel()} />} */}
                {(isActive) && <DialogAction Active={isActive} OnClose={this.onClosePanel} AcrionCom={this.refundHistoryPanel()} />}
                <ListContianer >
                    <List>  {this.contentPanel(value, _isMobile)}   </List>
                </ListContianer>
            </Contianer>

        );
    }

    contentPanel(value, _isMobile) {
        const { classes } = this.props;
        switch (_role) {
            case Roles.Admin:
            default:
                {
                    return (
                        <AppBar position="static">
                            <Tabs
                                value={value}
                                variant="fullWidth"
                                onChange={this.handleChange}
                                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}    >
                                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                    label="Refund Request" />
                                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                    label="Refund History" />
                            </Tabs>
                            <div style={{ width: '100%', maxWidth: '1024px', }}>
                                {this.DisplayPanelWithStyle(value, _isMobile)}
                            </div>
                        </AppBar>
                    )
                };
        }

    }

    DisplayPanelWithStyle(value, _isMobile) {
        // console.log('refundHistoryList', refundHistoryList);
        switch (value) {
            case 0: {
                return (<RefundRequestListPanel Header={`Refund Request List`} RefundRequestList={refundRequestList} isMobile={_isMobile}
                //  DisplayInfo={this.displayInfo(this.state.isActive)} 
                />)
            }
            case 1: {
                return (
                    <RefundHistoryListPanel Header={`Refund History List`} RefundHistoryList={refundHistoryList} isMobile={_isMobile}
                    // DisplayInfo={}
                    />
                )
            }
        }
    }

    handleChange = (event, value) => {

        switch (value) {
            case 0: { this.setState({ isLoading: true }, () => this.props.APIRefundRequestList({ num_limit: 25 })) } break;
            case 1: {
                this.setState({ isLoading: true },
                    () => this.props.APIRefundHistoryList({ num_limit: 25, where: { "refundStatus": ["refuse", "success"] } }))
            } break;
        }
        this.setState({ value });
    };


    // refundHistoryPanel = () => {
    //     const { refundHistoryInfo } = this.state;
    //     console.log('refundHistoryInfo', refundHistoryInfo);
    //     return <StepperComponent
    //         steps={getSteps()}
    //         component={<div>bobo</div>}
    //     />
    // }

    // updateRefundPanel = () => {
    //     return (
    //         <RequestPanel refundInfo={this.state.RefundInfo} onClosePanel={this.onClosePanel} />
    //         // <RefundRequestCard refundInfo={this.state.RefundInfo} onClosePanel={this.onClosePanel} / >
    //     )s
    // }
    // editInfo = (_info) => { this.setState({ RefundInfo: _info, isEdit: true }) }
    // onClosePanel = () => { this.setState({ isActive: false }) }
    // displayInfo = (isActive) => (_info) => { this.setState({ isActive: !isActive, refundHistoryInfo: _info }) }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tabsRoot: {
        backgroundColor: Colors.themeDark,
        borderBottom: `${'1px solid' + Colors.border}`,
    },
    tabsIndicator: {
        backgroundColor: Colors.theme,
        height: 3,
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        color: Colors.white,
        fontFamily: Family.roboto,
        '&:hover': {
            color: Colors.white,
            opacity: 1,
        },
        '&$tabSelected': {
            color: Colors.white,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: Colors.white,
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

RefundManagement.propTypes = { classes: PropTypes.object.isRequired };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIRefundRequestList: (_filter) => {
            dispatch(RefundActions.APIRefundRequestList(_filter));
        },
        APIRefundHistoryList: (_filter) => {
            dispatch(RefundActions.APIRefundHistoryList(_filter));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RefundManagement));
const Contianer = styled.div`
    padding-top:15px;
    text-align: center;
    font-family: ${Family.roboto};`;

const ListContianer = styled.div`
    width: 100%; 
    position: relative;
    overflow: auto;
    height: ${_height}px;`;
const List = styled.div`
    display: inline-block;`;
const ListPanelContianer = styled.div`
    height: 600px;
    width: 100% ;
    maxWidth: 1024px;`;