import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../App.css';
import List from '@material-ui/core/List';

import { PathName } from '../../constant';
import FerriesListPanel from './FerriesListPanel'

import DialogPanel from '../../componets/DialogPanel.jsx';
import { SetStateWithTime } from '../../common/SetTime.js';

import * as PackageAction from '../../actions/Actions_PackageManager.js';

import { init_package } from '../../constant/initState/initPackage';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let PackageList = [];


class FerriesManage extends Component {

    constructor(props) {
        super(props);
        this.state = { isLoading: true, FerriesInfo: undefined, oldFerriesInfo: null, }
        this.props.getAPIPackageList({ type: 'Transfer', limit: 25 });
    }

    componentWillReceiveProps(nextProps) {
        const { PackageManager } = nextProps;
        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;
            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        PackageList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break
            }
        }
    }

    render() {
        const { isLoading } = this.state;
        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}> {this.contentPanel()}</div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <FerriesListPanel
                    PackageList={PackageList}
                    CreatePanel={this.btnCreateHadle(PathName.CreateFerries)}
                    onClickHandle={this.onClickHandle(PathName.FerriesDetail)}
                />
            </div>)
    }

    btnCreateHadle = (value) => () => { this.props.history.push({ pathname: value, state: { packageInfo: { ...init_package, type: 'Transfer' } }, search: '', hash: '' }); }
    // btnViewHadle = (value) => () => { this.props.history.push(value); }

    onClickHandle = (page) => (_packageId) => {
        const _packageInfo = PackageList.packageList.find((_package) => { return _package.packageId === _packageId });
        this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo }, search: '', hash: '', isTransfer: true });
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FerriesManage);
