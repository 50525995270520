import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../../App.css';

import List from '@material-ui/core/List';

import DialogAction from '../../componets/DialogAction.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

import LevelCard from './LevelCard';
import LevelListPanel from './LevelListPanel';
import * as SettingActions from '../../actions/Actions_Settings';

import { init_level } from '../../constant/initState/initLevel';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let waitingTime = true;
let defaultCommisstions;
const _height = (window.innerHeight - 130);

class LevelManage extends Component {
    state = { isLoading: false, msgError: undefined, dialogType: undefined }
    constructor(props) {
        let error = null;
        super(props);
        this.state = {
            LevelInfo: { ...init_level },
            oldLevelInfo: { ...init_level },
            isCreate: false,
            isEdit: false,
            isView: false,
        }
        this.props.getDefaultCommissions({ num_limit: 25 });
    }
    componentWillReceiveProps(nextProps) {
        // console.log('[ componentWillReceiveProps(nextProps) : ]', nextProps)
        const { SettingManager } = nextProps;
        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        defaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }
    render() {
        const { isLoading, isCreate, msgError, dialogType, } = this.state;

        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.LevelPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto' }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        );
    }
    contentPanel() {
        return (
            // <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
            <div style={{ width: '100%', maxWidth: '1024px', }}>
                <LevelListPanel
                    LevelList={defaultCommisstions}
                    LevelInfo={this.state.LevelInfo}
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.editPanel()}
                    ViewInfo={this.viewInfo()} />
            </div>)
    }
    LevelPanel = () => {
        const { isEdit, isView, LevelInfo, oldLevelInfo } = this.state;
        return (
            <LevelCard
                LevelList={defaultCommisstions}
                onChangeLanguage={this.onChangeLanguage}
                isEdit={isEdit}
                isView={isView}
                LevelInfo={LevelInfo}
                oldLevelInfo={oldLevelInfo}
                onClosePanel={this.onClosePanel} />
        )
    }
    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, oldLevelInfo: { ...init_level }, LevelInfo: { ...init_level } }));
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, isView: false, LevelInfo: undefined, }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onChangeLanguage = (_language) => {
        console.log('onChangeLanguage', _language)
        // const { page } = LocationServicesList;
        // this.props.getAPILocationServiceList({ page: page, num_limit: 10, language: _language });
    }

    editPanel = () => (LevelInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            isView: false,
            LevelInfo,
            oldLevelInfo: LevelInfo
        })
    }

    viewInfo = () => (LevelInfo) => {
        this.setState({
            isCreate: true,
            isView: true,
            LevelInfo,
            oldLevelInfo: LevelInfo
        })
    }
}
function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LevelManage);