import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '../constant/Button';
import { Colors } from '../themes';

function InfoCard(props) {
  const { classes, Info, Disable, btnViewHadle, btnCreateHadle, View } = props;
  // const bull = <span className={classes.bullet}>•</span>;

  return (
    <Card className={classes.card}>
      <CardContent>
        <Typography className={classes.title}>{Info.name}</Typography>
        <Typography className={classes.pos}>
          <span style={{ fontSize: '50px', padding: '20px' }}>{Info.amount}</span>
          <span style={{ fontSize: '24px' }}>{`Trip`}</span>
        </Typography>
      </CardContent>
      <CardActions className={classes.card_action}>
        <Button.Themes disabled={Disable} style={{ width: '100%' }}
          TitleText={`CREATE ${Info.name}`} onClick={() => btnCreateHadle()} />
      </CardActions>
      <CardActions className={classes.card_action}>
        <Button.ThemesDark disabled={(View) ? true : Disable} style={{ width: '100%' }}
          TitleText={`VIEW ALL ${Info.name}`} onClick={() => btnViewHadle()} />
      </CardActions>
    </Card>
  );
}

const styles = theme => (
  {
    card: {
      minWidth: 220,
      maxWidth: 300,
      padding: '10px 0px',
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      marginBottom: 16,
      fontSize: 20,
      color: Colors.black,
      textAlign: 'left',
    },
    pos: {
      marginBottom: 12,
      color: theme.palette.text.secondary,
      marginTop: '40px',
      marginBottom: '20px',
    },
    card_action: { marginBottom: '0px', marginTop: '0px', paddingTop: '0px', paddingBottom: '0px' },
  });


InfoCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(InfoCard);