const HeaderPage = {
    EN: {
        CREATE: "Create Announcement",
        UPDATE: "Update Announcement",
    },
    TH: {
        CREATE: "",
        UPDATE: "",
    },
    CN: {
        CREATE: "",
        UPDATE: "",
    },
}

const BodyPage = [
    [{
        title_EN: "Announcement Type", title_TH: "", title_CN: "",
        type: "select",
        require: true,
        margin: true,
        stateName: "type",
        options: [
            { value: 'maintenance', EN: { label: "Maintenance" }, TH: { label: "" }, CN: { label: "" } },
            { value: 'news', EN: { label: "News" }, TH: { label: "" }, CN: { label: "" } },
            { value: 'promote', EN: { label: "Promote" }, TH: { label: "" }, CN: { label: "" } },
        ]
    }, {
        title_EN: "Type Content", title_TH: "", title_CN: "",
        type: "select",
        require: true,
        stateName: "typeContent",
        options: [
            { value: 'wording', EN: { label: "Wording" }, TH: { label: "" }, CN: { label: "" } },
            { value: 'slide', EN: { label: "Slide" }, TH: { label: "" }, CN: { label: "" } },
            { value: 'image', EN: { label: "Image" }, TH: { label: "" }, CN: { label: "" } },
        ]
    }], [{
        type: "additional",
        wording: [{
            title_EN: "Wording", title_TH: "", title_CN: "",
            type: "text-multiline",
            require: false,
            stateName: "wording",
        }, {
            title_EN: "Image", title_TH: "", title_CN: "",
            type: "image",
            stateName: "background",
            require: {
                EN: "",
                TH: "",
                CN: "",
            }
        }, {
            title_EN: "Link", title_TH: "", title_CN: "",
            type: "text",
            require: false,
            stateName: "link",
        }],
        slide: [{
            title_EN: "Image", title_TH: "", title_CN: "",
            type: "image",
            stateName: "image",
            require: {
                EN: "",
                TH: "",
                CN: "",
            }
        }, {
            title_EN: "Link", title_TH: "", title_CN: "",
            type: "text",
            require: false,
            stateName: "link",
        }],
        image: [{
            title_EN: "Image", title_TH: "", title_CN: "",
            type: "image",
            stateName: "background",
            require: {
                EN: "",
                TH: "",
                CN: "",
            }
        }, {
            title_EN: "Link", title_TH: "", title_CN: "",
            type: "text",
            require: false,
            stateName: "link",
        }]
    }],
    [{
        title_start: "Start Date", title_end: "End Date",
        type: "date",
        width: '25%',
        require: false,
        stateName_start: 'startDate',
        stateName_end: "endDate",
    }],
    [{
        title_EN: "Time", title_TH: "", title_CN: "",
        type: "switch",
        width: '24%',
        stateName: "isTime",
    }, {
        title_start: "Start Time", title_end: "End Time",
        type: "time",
        stateName_start: 'startTime',
        stateName_end: "endTime",
    }],
    [{
        title_EN: "Destroy", title_TH: "", title_CN: "",
        type: "switch",
        width: '20%',
        stateName: "isDestroy",
    }, {
        title_EN: "Visible", title_TH: "", title_CN: "",
        type: "switch",
        width: '40%',
        stateName: "visible",
    }],
    // [{
    //     title_EN: "Destroy", title_TH: "", title_CN: "",
    //     type: "switch",
    //     stateName: "isDestroy",
    // }, {
    //     title_EN: "Visible", title_TH: "", title_CN: "",
    //     type: "switch",
    //     stateName: "visible",
    // }],
];

export default {
    HeaderPage,
    BodyPage,
}