import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { path, equals, keys, omit } from 'ramda';

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import FormTypes from '../../common/FormTypes';
import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Currency';
import * as CurrencyActions from '../../actions/Actions_CurrencyManager';

import Currency from '../../constant/JSON/Currency';
import Language from '../../constant/Language';

import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';

import validate from "validate.js";
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let waitingTime = true;

const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex' };


class CurrencyCard extends Component {
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }

    constructor(props) {
        let error = null;
        super(props);
        this.state = {
            CurrencyInfo: this.props.CurrencyInfo,
            oldCurrencyInfo: this.props.oldCurrencyInfo,
            isUpdate: false,
        }

    }
    componentWillReceiveProps = (nextProps) => {
        const { CurrencyManager } = nextProps;

        if (!!CurrencyManager) {
            const { actions, data } = CurrencyManager.CURRENCY_REDUCER;

            switch (actions) {
                case CurrencyActions.CREATE_CURRENCY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Currency Success.', dialogType: 'success', });
                        this.setState({ currencyId: data.currencyId, isUpdate: true })
                        this.props.getAPICurrencyList({ num_limit: 10 });
                    } break;

                case CurrencyActions.UPDATE_CURRENCY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Currency Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPICurrencyList({ num_limit: 10 });
                    } break;

                case CurrencyActions.CREATE_CURRENCY_FAILURE:
                case CurrencyActions.UPDATE_CURRENCY_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }
    render() {
        const { classes, onClosePanel, isView, CurrencyList, isEdit } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive } = this.state;
        const { onSave } = this;
        const LanguageCurrency = path(['language'], CurrencyList);
        // console.log('LanguageCurrency', LanguageCurrency)
        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isView ? null : isEdit ? Currency.HeaderPage.UPDATE_EN : Currency.HeaderPage.EN}</Typography>
                    {isView ? null : <Language LanguagePage={LanguageCurrency} onChangeState={this.props.onChangeLanguage} isValue={true} />}
                </div>

                {
                    Currency.BodyPage.map((row, index) => {
                        return (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each, LanguageCurrency)}</div>))}
                            </div>
                        )
                    })
                }
                {!isView ?
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE}
                            disabled={!isBtnActive}
                            onClick={() => onSave()} />
                    </div> : null}
            </div >
        )
    }

    SetItemsWithType(each, LanguageCurrency) {

        const { classes, isView } = this.props;
        const { oldCurrencyInfo, CurrencyInfo } = this.state;
        const { onChangeState } = this;
        const valueLanguage = path([LanguageCurrency, each.stateName], CurrencyInfo);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.isRequired}
                        TitleText={each.title_EN}
                        value={path([each.stateName], CurrencyInfo) || valueLanguage}
                        onChange={(e) => { onChangeState({ [each.stateName]: e.target.value }) }} />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={3}
                        style={{ marginBottom: 10 }}
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.isRequired}
                        TitleText={each.title_EN}
                        value={valueLanguage || ''}
                        onChange={(e) => { onChangeState({ [each.stateName]: e.target.value }) }} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], CurrencyInfo) || false}
                        onChange={(e) => { onChangeState({ [each.stateName]: e.target.checked }) }} />
                )
            }
        }
    }
    onChangeState = (state) => {
        // console.log('state', state);
        let CurrencyInfo = { ...this.state.CurrencyInfo };
        const { oldCurrencyInfo } = this.state;
        const key = Object.keys(state)[0];
        const LanguageCurrency = path(['language',], this.props.CurrencyList);

        switch (key) {
            case "name":
            case "description": {
                CurrencyInfo = { ...CurrencyInfo, [LanguageCurrency]: { ...CurrencyInfo[LanguageCurrency], ...state } }

            } break;
            default: { CurrencyInfo = { ...CurrencyInfo, ...state } }
        }
        console.log('new CurrencyInfo', CurrencyInfo)

        this.setState({ CurrencyInfo, isBtnActive: !equals(oldCurrencyInfo, CurrencyInfo) }, () => { this.validate(state); });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        const LanguageCurrency = path(['language',], this.props.CurrencyList);
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: each.fieldName === 'currencyType' ?
                    this.state.CurrencyInfo[each.fieldName]
                    :
                    this.state.CurrencyInfo[LanguageCurrency][each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        const { isEdit } = this.props;
        let CurrencyInfo = { ...this.state.CurrencyInfo }
        const { isUpdate, currencyId } = this.state;

        if (this.validateAll()) {

            this.setState({ isLoading: true, isBtnActive: false });

            if (isEdit) {
                this.props.getAPIUpdateCurrency(CurrencyInfo);
            }
            else if (isUpdate) {
                CurrencyInfo = { ...CurrencyInfo, currencyId: currencyId };
                this.props.getAPIUpdateCurrency(CurrencyInfo);
            } else {
                this.props.getAPICreateCurrency(CurrencyInfo);
            }
        }
        else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        }
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
}
    );
function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {
        getAPICurrencyList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CurrencyActions.getCurrencyList(_keyword, _filter));
        },
        getAPICreateCurrency: (_confirmInfo) => {
            dispatch(CurrencyActions.APICreateCurrency(_confirmInfo));
        },
        getAPIUpdateCurrency: (_confirmInfo) => {
            dispatch(CurrencyActions.APIUpdateCurrency(_confirmInfo));
        },
    }
}
CurrencyCard.propTypes = { classes: PropTypes.object.isRequired };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CurrencyCard));