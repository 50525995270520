import moment from 'moment';

export const init_promotion_code = {
    'namePromotion': '',
    'promotionCode': '',
    'description': '',
    'discountType': 'price',
    'discountValue': 0,
    'limit': 0,
    'countLimit': 0,
    'condition': "",
    'startDate': moment().format('YYYY-MM-DD'),
    'endDate': moment().add(1, 'day').format('YYYY-MM-DD'),
    'actived': false,
    'currency': 'THB',
    'codeType': "all",
    'tag': [],
    'checkDate': false,
    'checkMin': false,
    'checkMax': false,
    'checkProduct': false,
    'startTimeCondition': moment('24:00', 'hh:mm').format('HH:mm'),
    'endTimeCondition': moment('24:00', 'hh:mm').format('HH:mm'),
    'minimum': 0,
    'maximum': 0,
    'product': [],
    'isDiscountEachProduct': false,
    'discountEachProduct': [],
    // 'endDate': moment().format('YYYY-MM-DD'),
    // 'startDate': "",
}

