import React from 'react';
import InfoDCard from '../../componets/InfoDCard.jsx';

// import '../../App.css';
import styled, { css } from 'styled-components';

const summaryFormat = {
    Wallet: { name: 'Commission', amount: 0, price: 0 },
    Total: { name: 'Total', amount: 0, price: 0 },
    OneDayTrip: { name: 'OneDayTrip', amount: 0, price: 0 },
    Package: { name: 'Package', amount: 0, price: 0 },
    Transfer: { name: 'Transfer', amount: 0, price: 0 },
    Ferries: { name: 'Ferries', amount: 0, price: 0 },
    Hotel: { name: 'Hotel', amount: 0, price: 0 },
}

let _Info = { ...summaryFormat }
const _flexStyle = { flex: 1, margin: 10 };

function SellerSumaryBookingPanel(props) {

    const { SummaryBooking, Wallet, isMobile } = props;

    //console.log('SummaryBooking : ', SummaryBooking, ':', Wallet);

    _Info.Wallet.price = Wallet;

    if (!!SummaryBooking.totalPrice) {
        const { totalPrice, totalSale, data } = SummaryBooking;

        data.map((item) => {
            const _key = Object.keys(item)[0];
            const _newItem = { [_key]: item };

            if (_key === 'Transfer') {
                _Info['Ferries'].amount = ((_newItem[_key])['ferries']) || 0;
                _Info['Ferries'].price = ((_newItem[_key])['netPriceFerries']) || 0;
            }

            _Info[_key].amount = ((_newItem[_key])[_key]) || 0;
            _Info[_key].price = ((_newItem[_key])['netPrice']) || 0;

        });

        _Info.Total.amount = totalSale;
        _Info.Total.price = totalPrice;
    }
    else if (SummaryBooking.length === 0) {

        Object.keys(summaryFormat).map((key) => { _Info[key] = { ...(summaryFormat[key]) } })
    }

    return (isMobile) ? <MobilePanel _Info={_Info} /> : <DeskTopPanel _Info={_Info} />

}

function DeskTopPanel(props) {

    const { _Info } = props;

    return (
        <div className="App-content" style={{ maxWidth: 1024 }}>
            <div style={{ flex: 2, margin: 10, marginLeft: 0 }}>
                <div style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    <InfoDCard Info={_Info.Total} Disable={false} Type='full' />
                </div>
                <div style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    <InfoDCard Info={_Info.Wallet} Disable={true} Type='full' />
                </div>
            </div>
            <div style={_flexStyle}>
                <div style={_flexStyle}>
                    <InfoDCard Info={_Info.OneDayTrip} Disable={false} Type='full' />
                </div>
                <div style={_flexStyle}>
                    <InfoDCard Info={_Info.Ferries} Disable={false} Type='full' />
                </div>
            </div>
            <div style={{ flex: 1, margin: 10, marginRight: 5 }}>
                <div style={{ flex: 1, margin: 10, marginRight: 5 }}>
                    <InfoDCard Info={_Info.Transfer} Disable={false} Type='full' />
                </div>
                <div style={{ flex: 1, margin: 10, marginRight: 5 }}>
                    <InfoDCard Info={_Info.Package} Disable={false} Type='full' />
                </div>
            </div>
        </div>)
}

function MobilePanel(props) {

    const { _Info } = props;
    const _width = { width: window.innerWidth - 15 };
    const _widthHalf = { width: ((window.innerWidth - 30) / 2) };

    return (
        <React.Fragment>
            <div style={{ width: '100%' }}>
                <CardCenter>
                    <InfoDCard Info={_Info.Total} Disable={false} Type='full' Height={_width} />
                </CardCenter>
                <CardCenter>
                    <InfoDCard Info={_Info.Wallet} Disable={true} Type='full' Height={_width} />
                </CardCenter>
                <SectionContianer>
                    <CardLeft>
                        <InfoDCard Info={_Info.OneDayTrip} Disable={false} Type='full' Height={_widthHalf} isMobile />
                    </CardLeft>
                    <CardRight>
                        <InfoDCard Info={_Info.Ferries} Disable={false} Type='full' Height={_widthHalf} isMobile />
                    </CardRight>
                </SectionContianer>
                <SectionContianer>
                    <CardLeft>
                        <InfoDCard Info={_Info.Transfer} Disable={false} Type='full' Height={_widthHalf} isMobile />
                    </CardLeft>
                    <CardRight>
                        <InfoDCard Info={_Info.Package} Disable={false} Type='full' Height={_widthHalf} isMobile />
                    </CardRight>
                </SectionContianer>
            </div>
        </React.Fragment>
    )
}

export default SellerSumaryBookingPanel;

const SectionContianer = styled.div`
display: flex;`;

const CardLeft = styled.div`
margin: 0px 10px 0px 5px;
text-align: center;
${(props) => props.isMobile && css``}`;

const CardRight = styled.div`
margin: 0px 0px 0px 5px;
text-align: center;
${(props) => props.isMobile && css``}`;

const CardCenter = styled.div`
margin: 0px 5px;
text-align: center;
${(props) => props.isMobile && css``}`;