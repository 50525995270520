import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PathName, Roles } from '../../constant';

import InfoDCard from '../../componets/InfoDCard.jsx';
import BookingStatCard from '../../componets/BookingStatCard.jsx';
import SupplierStatCard from '../../componets/SupplierStatCard.jsx';
// import TwoLevelPieChart from '../../componets/TwoLevelPieChart';

import * as StatiticActions from '../../actions/Actions_StatiticManager.js';
import * as AccountingActions from '../../actions/Actions_Accounting.js';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import '../../App.css';
import BookingStatChart from '../../componets/BookingStatChart';


const _info = JSON.parse(localStorage.getItem('UserInfo'));
const _role = (!!_info) ? (_info.userType || '') : '';


let waitingTime = true;
let _BookingStatus = [];
let _BookingCharts = [];
let _SumaryBookingWithType = [];

const _Info = {
    Wallet: { name: 'Profits', amount: 0, price: 0 },
    Total: { name: 'Total Sales', amount: 0, price: 0 },
    OneDayTrip: { name: 'Onedaytrip', amount: 0, price: 0 },
    Package: { name: 'Package', amount: 0, price: 0 },
    Transfer: { name: 'Transfer', amount: 0, price: 0 },
    Ferries: { name: 'Ferries', amount: 0, price: 0 },
    Hotel: { name: 'Hotel', amount: 0, price: 0 },
}

class BookingStatusPanel extends Component {

    constructor(props) {
        super(props);

        switch (_role) {
            case Roles.Seller: {

                const { userInfo } = this.props;
                console.log('_userInfo : ', userInfo)
                const _filter = { startDate: '2018-01-01' };
                this.props.getAPIBookingStatus(_filter);
                this.props.getAPIIncomeAllSummary(_filter);
                this.props.getAPIAllSummaryBookingChart();
            } break;
            case Roles.Admin:
            default: {
                const _filter = { startDate: '2018-01-01' };
                this.props.getAPIBookingStatus(_filter);
                this.props.getAPIIncomeAllSummary(_filter);
                this.props.getAPIAllSummaryBookingChart();
            };
        }
    }

    componentWillReceiveProps(nextProps) {

        const { StatiticManager, AccountingManager, isLoading } = nextProps;

        // if (!isLoading && _BookingStatus.length === 0)
        //     this.props.getAPIBookingStatus();

        if (!!StatiticManager) {

            const { actions, data } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {
                case StatiticActions.GET_BOOKING_STATUS_SUCCESS:
                    {
                        _BookingStatus = data.results;

                        // console.log(`[BookingStatusPanel] ${actions} `, _BookingStatus);
                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;

                case StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS:
                    {
                        _BookingCharts = data.results.data;
                        // _BookingCharts=BookingStatChart.map((item)=>{})
                        //console.log(`[BookingStatusPanel] ${actions} `, _BookingCharts);
                    } break;

                case StatiticActions.GET_ALL_SUMMARY_INCOME_SUCCESS:
                    {
                        //StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;
                        _SumaryBookingWithType = data.results;

                        // console.warn(`!!! ${actions} `, _SumaryBookingWithType);
                    } break;

                case StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_FAILURE:
                case StatiticActions.GET_BOOKING_STATUS_FAILURE:
                    {
                        // if (isEdit || isCreate)
                        //   StatiticManager.STATITIC_REDUCER.actions = StatiticActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!AccountingManager)
            this.AccountingManager(AccountingManager.ACCOUNTING_REDUCER);
    }

    AccountingManager(_accountingInfo) {

        const { actions, data } = _accountingInfo

        switch (actions) {
            case AccountingActions.GET_SYSTEM_WALLET_SUCCESS:
                {
                    _accountingInfo.actions = AccountingActions.WATING_STATE;
                    const { wallet } = data[0];
                    _Info.Wallet.price = wallet || 0;
                } break;
        }
    }

    render() {

        const { isLoading, DisplayPage, isMobile } = this.props;
        const _isDashBoard = (DisplayPage === PathName.DashBoardPage)

        return (
            <React.Fragment>
                <div className="App-content">
                    <div style={{ flex: 4 }}>
                        {(_isDashBoard) ?
                            SummaryBookingWithWalletPanel(_SumaryBookingWithType) :
                            SummaryBookingWithTypePanel(_SumaryBookingWithType, _isDashBoard)
                        }
                        <SupplierStatCard Info={_BookingCharts}
                            Topics={`Top Product Booking`}
                            Disable={false} Styles={{ width: 785 }}
                            isLoading={isLoading}
                            btnViewHadle={this.btnViewHadle(PathName.SupplierManage)}
                            chartType={4} />
                    </div>
                    <div style={{ flex: 1, marginLeft: '10px' }}>
                        <BookingStatCard Info={_BookingStatus} Disable={true} isLoading={isLoading} chartType={1} />
                    </div>
                </div>
                {(_isDashBoard) && SummaryBookingWithTypePanel(_SumaryBookingWithType, _isDashBoard)}
            </React.Fragment>)
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}

function SummaryBookingWithTypePanel(SummaryBooking, _isDashBoard) {

    const _flexStyle = { flex: 1, margin: 10, marginTop: 0 };
    const { totalPrice, totalSale, data } = SummaryBooking;

    // console.log('BookingStatusPanel : ', SummaryBooking)

    if (Boolean(data)) {

        SummaryBooking.data.map((item) => {

            const _key = Object.keys(item)[0];
            const _newItem = { [_key]: item };

            if (_key === 'Transfer') {
                _Info['Ferries'].amount = ((_newItem[_key])['ferries']) || 0;
                _Info['Ferries'].price = ((_newItem[_key])['netPriceFerries']) || 0;
            }

            _Info[_key].amount = ((_newItem[_key])[_key]) || 0;
            _Info[_key].price = ((_newItem[_key])['netPrice']) || 0;
        });

        _Info.Total.amount = totalSale || 0;
        _Info.Total.price = totalPrice || 0;

        // const _summary = data.map((item) => { const _key = Object.keys(item)[0]; const _item = { [_key]: { name: _key, amount: item[_key], price: item.netPrice } }; _newSummary[_key] = _item[_key]; return _item });
    }

    return (
        <div className="App-content" style={{ maxWidth: 1024, marginBottom: 10 }}>
            <div style={{ flex: 1, margin: 10, marginLeft: 0, marginTop: 0 }}>
                <InfoDCard Info={_Info.OneDayTrip} Disable={false} Styles={1} Type={_isDashBoard && 'full'} />
            </div>
            <div style={_flexStyle}>
                <InfoDCard Info={_Info.Transfer} Disable={false} Type={_isDashBoard && 'full'} />
            </div>
            <div style={{ flex: 1, margin: 10, marginRight: 5, marginTop: 0 }}>
                <InfoDCard Info={_Info.Ferries} Disable={false} Type={_isDashBoard && 'full'} />
            </div>
            <div style={_flexStyle}>
                <InfoDCard Info={_Info.Package} Disable={false} Type={_isDashBoard && 'full'} />
            </div>
        </div >)
}

function SummaryBookingWithWalletPanel(SummaryBooking) {

    const { totalPrice, totalSale, } = SummaryBooking;
    _Info.Total.amount = totalSale;
    _Info.Total.price = totalPrice;

    return (
        <div className="App-content" style={{ maxWidth: 1024, margin: 0, }}>
            <div style={{ flex: 1, margin: 10, marginLeft: 0, marginTop: 0, }}>
                <InfoDCard Info={_Info.Total} Disable={false} Styles={1} Type={'full'} />
            </div>
            <div style={{ flex: 1, margin: 10, marginRight: 5, marginTop: 0 }}>
                <InfoDCard Info={_Info.Wallet} Disable={true} Type={'full'} />
            </div>
        </div >)
}


function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingStatus: (_keyword) => {
            InitWaitingTime();
            dispatch(StatiticActions.getBookingStatus(_keyword));
        },
        getAPIIncomeAllSummary: (_filter) => {
            dispatch(StatiticActions.getIncomeAllSummary(_filter))
        },
        getAPIAllSummaryBookingChart: (_filter) => {
            dispatch(StatiticActions.getAllSummaryBookingChart(_filter))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BookingStatusPanel);
