
const HeaderPage = {
    UPDATE_EN: "Update Commission", UPDATE_TH: "Update Commission", UPDATE_CN: "Update Commission",
}

const BodyPage = [
    [{
        title_EN: "Welcome Level", title_TH: "---", title_CN: "---",
        type: "title",
    }], [{
        title_EN: "Commission", title_TH: "", title_CN: "",
        type: "select",
        margin: 'right',
        stateLevel: "welcomeLevel",
        stateName: "priceType",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "----",
        options: [
            { value: "percent", label_EN: 'Percent', label_TH: '', label_CN: '' },
            { value: "price", label_EN: 'Price', label_TH: '', label_CN: '' },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'welcomeLevel', stateName: "adult",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'welcomeLevel', stateName: "kid",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "number", percent: true,
        stateLevel: 'welcomeLevel', stateName: "infant",
    }],

    //------------------------------------------------------------------

    [{
        title_EN: "Green Level", title_TH: "---", title_CN: "---",
        type: "title",
    }], [{
        title_EN: "Commission", title_TH: "", title_CN: "",
        type: "select",
        margin: 'right',
        stateLevel: "greenLevel",
        stateName: "priceType",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "----",
        options: [
            { value: "percent", label_EN: 'Percent', label_TH: '', label_CN: '' },
            { value: "price", label_EN: 'Price', label_TH: '', label_CN: '' },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'greenLevel', stateName: "adult",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'greenLevel', stateName: "kid",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "number", percent: true,
        stateLevel: 'greenLevel', stateName: "infant",
    }],

    //------------------------------------------------------------------

    [{
        title_EN: "Gold Level", title_TH: "---", title_CN: "---",
        type: "title",
    }], [{
        title_EN: "Commission", title_TH: "", title_CN: "",
        type: "select",
        margin: 'right',
        stateLevel: "goldLevel",
        stateName: "priceType",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "----",
        options: [
            { value: "percent", label_EN: 'Percent', label_TH: '', label_CN: '' },
            { value: "price", label_EN: 'Price', label_TH: '', label_CN: '' },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'goldLevel', stateName: "adult",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'goldLevel', stateName: "kid",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "number", percent: true,
        stateLevel: 'goldLevel', stateName: "infant",
    }],

    //------------------------------------------------------------------

    [{
        title_EN: "Diamond Level", title_TH: "---", title_CN: "---",
        type: "title",
    }], [{
        title_EN: "Commission", title_TH: "", title_CN: "",
        type: "select",
        margin: 'right',
        stateLevel: "diamondLevel",
        stateName: "priceType",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "----",
        options: [
            { value: "percent", label_EN: 'Percent', label_TH: '', label_CN: '' },
            { value: "price", label_EN: 'Price', label_TH: '', label_CN: '' },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'diamondLevel', stateName: "adult",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'diamondLevel', stateName: "kid",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "number", percent: true,
        stateLevel: 'diamondLevel', stateName: "infant",
    }],

    [{
        title_EN: "Actived", title_TH: "", title_CN: "",
        type: "switch",
        stateName: "actived",
    }, {
        title_EN: "Default", title_TH: "", title_CN: "",
        type: "switch",
        stateName: "isDefault",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}