import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import TableComponent from '../../componets/TableComponent';
import Button from '../../constant/Button';
import CategoryCard from './CategoryCard';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import { init_category } from '../../constant/initState/initCategory';

import DialogAction from '../../componets/DialogAction.jsx'
import DialogPanel from '../../componets/DialogPanel.jsx';
import { isMobile } from '../../common/DeviceCheck';
import * as PackageAction from '../../actions/Actions_PackageManager.js';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

const { BorderColorIcon,AddIcon } = Icons;
let PackageCategoryList = [];
let waitingTime = true;
let _isMobile = isMobile();
let LanguagePage = 'EN'

const fildeLists = [
    { label: 'NAME', name: '', isOrder: false, },
    { label: 'DESCRIPTION', name: '', isOrder: false, },
    { label: 'ORDER', name: 'order', isOrder: true, },
    { label: 'PACKAGELIST', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, },
];

/* Desktop */
const _callStyle = { textAlign: 'center', padding: '5px', border: `${'1px solid' + Colors.white}` };
const _callCustomStyle = { ..._callStyle, backgroundColor: Colors.themeDark, color: Colors.white, }
const _callName = { ..._callStyle, textAlign: 'left', paddingRight: '15px', maxWidth: 280 };

/* Mobile */
const _callStyleMobile = { ..._callStyle, padding: 2, fontSize: 11, width: 50, };
const _callNameMobile = { ..._callStyleMobile, textAlign: 'left', maxWidth: 80, };



class CategoryListPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPanel: false, isView: false, isEdit: false, categoryInfo: init_category,
            isLoading: false, msgError: undefined, dialogType: undefined,
            orderBy: 'desc', sortFilde: undefined, searchActive: false
        }
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager } = nextProps;
        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;
            switch (actions) {
                case PackageAction.GET_PACKAGE_CATEGORY_SUCCESS:
                case PackageAction.GET_PACKAGE_CATEGORY_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break
            }
        }
    }

    render() {
        const { classes, packageCategoryList, Header } = this.props;
        const { isPanel, isView, isEdit, isLoading, msgError, dialogType, orderBy, sortFilde, searchActive, categoryInfo } = this.state;
        // console.log('packageCategoryList', packageCategoryList);
        PackageCategoryList = packageCategoryList.packageCategoryList || [];
        const _isWithDraw = (PackageCategoryList.length > 0);
        LanguagePage = path(['language'], packageCategoryList)
        // console.log('LanguagePage', LanguagePage);
        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isPanel) && <DialogAction Active={isPanel} OnClose={this.onClosePanel} AcrionCom={this.contentPanel(isView, isEdit, categoryInfo)} />}

                <TableComponent isCreate icon={<AddIcon />} tooltip={'Create'}
                    Header={Header}
                    orderBy={orderBy}
                    isActive={_isWithDraw}
                    FildeLists={fildeLists}
                    HandleRequestSort={this.handleRequestSort}
                    onClickCreate={() => this.DisplayPanel('create')}
                    ListCount={packageCategoryList}
                    onNextPage={this.onNextPage}
                    onChangeLimit={this.onChangeLimit}
                    onChangeLanguage={this.onChangeLanguage}
                    GetBookingRows={GetBookingRows(classes, PackageCategoryList, this.DisplayPanel, _isMobile)} />

            </React.Fragment>
        )
    }


    contentPanel = (isView, isEdit, categoryInfo) => {
        return <CategoryCard
            isView={isView}
            isEdit={isEdit}
            categoryInfo={categoryInfo}
            LanguagePage={LanguagePage}
            onChangeLanguage={this.onChangeLanguage}
            onClosePanel={this.onClosePanel} />
    };

    DisplayPanel = (_action, _info) => {
        switch (_action) {
            case 'view': {
                this.setState({ isPanel: true, isView: true, isEdit: false, categoryInfo: _info })
            } break;
            case 'edit': {
                this.setState({ isPanel: true, isView: false, isEdit: true, categoryInfo: _info })
            } break;
            case 'create': {
                this.setState({ isPanel: true, isView: false, isEdit: false, categoryInfo: init_category })
            } break;
        }
    };

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => (this.setState({ isPanel: false, isView: false }));

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        if (PackageCategoryList) {
            PackageCategoryList = (orderBy === 'desc')
                ? PackageCategoryList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
                : PackageCategoryList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

            this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
        }
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIPackageCategory({ page: _pageNumber, limit: (_limit || 10), language: _language });
    }
    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIPackageCategory({ page: 0, limit: _limitRows, language: _language });
    }

    onChangeLanguage = (_language) => {
        this.setState({ isLoading: true });
        const { page } = this.props.packageCategoryList;
        this.props.getAPIPackageCategory({ page: page, limit: 10, language: _language });
    }
}

function GetBookingRows(classes, PackageCategoryList, DisplayPanel, isMobile) {
    return PackageCategoryList.map((_info, index) => {

        const { packageList, order, visible } = _info;
        const isCallStyle = (isMobile) ? _callStyleMobile : _callStyle;
        const isCallNameStyle = (isMobile) ? _callNameMobile : _callName;

        let NAME = path([LanguagePage, 'nameCategory'], _info);
        let DESCRIPTION = path([LanguagePage, 'description'], _info);

        return (
            <TableRow key={index} hover className={classes.row} style={{ cursor: 'pointer' }}>
                <TableCell style={isCallNameStyle} onClick={() => { DisplayPanel('view', _info) }} >{NAME || '-'}</TableCell>
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('view', _info) }} >{DESCRIPTION || '-'}</TableCell>
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('view', _info) }} >{order || 0}</TableCell>
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('view', _info) }} >{packageList.length || 0}</TableCell>
                <TableCell style={{ ...isCallStyle, color: (visible) ? Colors.success : Colors.error }} onClick={() => { DisplayPanel('view', _info) }} >
                    {(visible) ? 'Active' : 'Inactive'}
                </TableCell>
                <TableCell style={isCallStyle} onClick={() => { DisplayPanel('edit', _info) }}>
                    <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} />
                </TableCell>
            </TableRow>
        )
    });
}

const styles = theme => (
    {
        root: { width: '100%', },
        table: { minWidth: 1024, },
        tableMobile: { width: '100%' },
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: Colors.themeDark_Light,
            },
            '&:nth-of-type(odd):hover': {
                backgroundColor: Colors.themeDark_Item,
            },
        },
    });

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageCategory: (_filter) => {
            dispatch(PackageAction.getAPIPackageCategory(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CategoryListPanel)); 