// ===================================================================================================================================
// Main Themes selector.
// ===================================================================================================================================

import config from "../config";
import { PLATFORM_NAME, } from "../config/platform";

const isPlatFrom = config.isPlatForm;

console.log(` === Themes : ${isPlatFrom} ===`);

let platforms;

switch (isPlatFrom) {
    case PLATFORM_NAME.PHIPHITOURS: platforms = require("./PhiPhiTours"); break;
    case PLATFORM_NAME.TRAVISGO:
    default: platforms = require("./TravisGo"); break;
}


const { Colors, Family, Fonts, Images, Svg, Size, RequireForm, Styles, Icons } = platforms;

export { platforms, Colors, Family, Fonts, Images, Svg, Size, RequireForm, Styles, Icons };

// ===================================================================================================================================