import red from "@material-ui/core/colors/red";

export const Colors = {
    error: red.A400,
    success: '#8BC34A',

    border: '#979797',
    borderLight: '#DFDFDF',

    defaultButton: '#2B2B2B',

    themeOpacity: '#bce7f7',
    theme: '#0F5F9E', // Blue
    themeDark: '#2B2B2B', // greyDark 
    themeDark_Light: '#F3F3F3',
    themeDark_Item: '#e2e2e2',
    themeDark_Hilight: '#686868',
    defaultImage: '#BFBFBF',

    barChart_1: '#0F5F9E', // <== Theme
    barChart_2: '#F7941E',
    barChart_3: '#FFC400',

    pieChart_1: '#BDBDBD', // Wating Grey                   //Color Full Status
    pieChart_2: '#1E88E5', // inProcess Yellow Light
    pieChart_3: '#8BC34A', // Appoved Green Light
    pieChart_4: '#FF1744', // Cancel Red Orange
    pieChart_5: '#f78201', // Reject Red Dark

    bigChart_1: '#355808',//'#ff8465'  //Green
    bigChart_2: '#436f0b',//'#ffa365'
    bigChart_3: '#51870d',//'#ffba65'
    bigChart_4: '#5f9e0f',//'#ffca65'
    bigChart_5: '#6db511',//'#ffd965'

    lineChart_1: '#083558',//'#7d0000' // Blue
    lineChart_2: '#0b436f',//'#cd3700'
    lineChart_3: '#0d5187',//'#cd7f32'
    lineChart_4: '#116db5',//'#ff8c00'
    lineChart_5: '#1689e4',//'#ffa500'


    header: {
        background: "#292929",
        color: "#b6b6b6",
    },
    gradient: "linear-gradient(180deg, #4188C6 0%, #2D609C 100%)",
    darkBlue: "#295899",
    lightBlue: "#346DAA",
    darkGrey: '#474747',
    grey: '#9B9B9B',
    lightGrey: '#E9E9E9',
    input: {
        label: 'rgba(0,0,0,0.54)',
        border: '#c8c8c8',
    },
    orange: '#EA4F48',

    disabled: '#AAA9A9',
    white: '#FFFFFF',
    black: '#000000',
    footer: '#222222'

};
