

export const PathName = {
    Index: '/',
    LoginPage: '/LoginPage',
    DashBoardPage: '/DashBoardPage',
    ActivityManage: '/ActivitiesManage',
    ActivityInfo: '/ActivityInfo',
    MemberManage: '/MemberManage',
    MemberInfo: '/MemberInfo',
    CustomerInfo: '/CustomerInfo',
    CustomerManage: '/CustomerManage',
    CommissionManage: '/CommissionManage',
    SpecialManage: '/SpecialManage',
    BookingManage: '/BookingManage',
    BookingDetail: '/BookingDetail',
    PackageManage: '/PackageManage',
    PackageDetail: '/PackageDetail',
    AccountingManage: '/AccountingManage',
    PromotionManage: '/PromotionManage',
    PromotionCode: '/Codes',
    PromotionInfo: '/PromotionInfo',
    CreatePackage: '/CreatePackage',
    TransferManage: '/TransferManage',
    TransferDetail: '/TransferDetail',
    LocationManage: '/LocationManage',
    ZoneManage: '/ZoneManage',
    CountryManage: '/CountryManage',
    CreateTransfer: '/CreateTransfer',
    SupplierManage: '/SupplierManage',
    SupplierInfo: '/SupplierInfo',
    CreateSupplier: '/CreateSupplier',
    ProviderManage: '/ProviderManage',
    DestinationManage: '/DestinationManage',
    DestinationInfo: '/DestinationInfo',
    CreateDestination: '/CreateDestination',
    AgentManage: '/AgentManage',
    AgentInfo: '/AgentInfo',

    SellerManage: '/SellerManage',
    SellerInfo: '/SellerInfo',
    SettingSeller: '/SettingSeller',
    SellerDashboard: '/SellerDashboard',
    SellerManagment: '/SellerManagment',
    SellerCommission: '/SellerCommission',
    SpecialCommission: '/SpecialCommission',

    ConditionManage: '/ConditionManage',
    AppointmentManage: '/AppointmentManage',
    Settings: '/Settings',
    WebsiteManage: '/WebsiteManage',
    FerriesManage: '/FerriesManage',
    CreateFerries: '/CreateFerries',
    FerriesDetail: '/FerriesDetail',
    PageNotFound: '/PageNotFound',

    Products: '/Products',

    ServiceManage: '/ServiceManage',
    PaymentManage: '/PaymentManage',
    UserManage: '/UserManage',
    OtherManage: '/OtherManage',
    ReservationManage: '/ReservationManage',
    ReservationTable: '/ReservationTable',
    AnnouncementManage: '/AnnouncementManage',
    WithdrawManagment: '/WithdrawManagment',
    DefaultCommissionManage: '/DefaultCommissionManage',

    RefundRequestManage: '/RefundRequest',
    AccountingManagement: '/AccountingManagement',
    TransactionManagement: '/TransactionManagement',
    CategoryManage: '/CategoryManage',

    Hotel: '/Hotel',
    CreateHotel: '/CreateHotel'
}
