import React, { Component } from 'react'
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import DialogAction from '../../componets/DialogAction'
import DialogPanel from '../../componets/DialogPanel.jsx';

import { Colors } from '../../themes';
import { GetSymbol } from '../../common/GetSymbol.js';
import { NumberFormats } from '../../common/SetNumberFormat';
import { GetStatusColor } from '../../common/GetStatusColor';
import { SetDate } from '../../common/SetDate';
import { isMobile } from '../../common/DeviceCheck.js';

import * as AdminActions from '../../actions/Actions_AdminManager';
import * as RefundActions from '../../actions/Actions_RefundManager';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as CustomerActions from '../../actions/Actions_CustomerManager.js';
import { SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import RefundHistoryPanel from './RefundHistoryPanel';
import TableComponent from '../../componets/TableComponent.jsx';

let UserList = [];
let CustomerList = [];
let RefundHistory = [];
let PackageList = [];
let waitingTime = true;
const _isMobile = isMobile();

const fildeList = [
    { label: 'REFUND ID', name: '', isOrder: true },
    { label: 'DATE', name: '', isOrder: false },
    { label: 'PACKAGE NAME', name: '', isOrder: false },
    { label: 'REQUEST BY', name: '', isOrder: false },
    { label: 'REFUND BY', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: 'AMOUNT', name: '', isOrder: false },
];
const fildeListsMobile = [
    { label: 'DATE', name: '', isOrder: false },
    { label: 'PACKAGE NAME', name: '', isOrder: false },
    { label: 'REFUND BY', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: 'AMOUNT', name: '', isOrder: false },
];

/* Desktop */
const _callStyle = { textAlign: 'center', padding: '5px', border: `${'1px solid' + Colors.white}` };
const _callName = { ..._callStyle, textAlign: 'left', paddingRight: '15px', maxWidth: 280 };

/* Mobile */
const _callStyleMobile = { ..._callStyle, padding: 2, fontSize: 11, width: 50, };
const _callNameMobile = { ..._callStyleMobile, textAlign: 'left', maxWidth: 80, };


class RefundHistoryListPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRequestPanel: false,
            isLoading: false, msgError: undefined, dialogType: undefined,
            orderBy: 'desc', sortFilde: undefined, searchActive: true
        };

        this.props.getAPIPackageList()
        this.props.getCustomers()
        this.props.getUserAlls()

    }
    componentWillReceiveProps(nextProps) {
        // console.log('nextProps', nextProps)
        const { RefundManager, PackageManager, CustomerManager, AdminManager } = nextProps;

        if (!!RefundManager) {
            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.GET_REFUND_HISTORY_LISTS_SUCCESS:
                    {
                        RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        RefundHistory = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_HISTORY_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!CustomerManager) {
            const { actions, data } = CustomerManager.CUSTOMER_REDUCER;

            switch (actions) {
                case CustomerActions.GET_CUSTOMERS_SUCCESS:
                    {
                        CustomerManager.CUSTOMER_REDUCER.actions = CustomerActions.WATING_STATE;
                        CustomerList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CustomerActions.GET_CUSTOMERS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!AdminManager) {
            const { actions, data } = AdminManager.ADMIN_REDUCER;

            switch (actions) {
                case AdminActions.GET_USER_ALLS_SUCCESS:
                    {
                        AdminManager.ADMIN_REDUCER.actions = AdminActions.WATING_STATE;
                        UserList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AdminActions.GET_USER_ALLS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        PackageList = (data.packageList || []);
                        SetStateWithObject(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        const _msg = data.response.data.error || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }
    render() {
        const { classes, RefundHistoryList } = this.props;
        const { isLoading, msgError, dialogType, orderBy, isRequestPanel, requestInfo, searchActive } = this.state;

        const _isRequest = (RefundHistory.length > 0);
        let fildeLists = (_isMobile) ? fildeListsMobile : fildeList;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isRequestPanel) && <DialogAction Active={isRequestPanel} OnClose={this.onClosePanel} AcrionCom={this.RequestPanel(requestInfo)} />}

                <TableComponent
                    orderBy={orderBy}
                    isActive={_isRequest}
                    FildeLists={fildeLists}
                    HandleRequestSort={this.handleRequestSort}
                    ListCount={RefundHistoryList}
                    onNextPage={this.onNextPage}
                    onChangeLimit={this.onChangeLimit}
                    GetBookingRows={GetBookingRows(classes, RefundHistory, PackageList, this.DisplayPanel)} />
            </React.Fragment>
        )
    }

    RequestPanel = (requestInfo) => (
        <RefundHistoryPanel PackageList={PackageList} requestInfo={requestInfo} onClosePanel={this.onClosePanel} />
    )

    DisplayPanel = (_info) => { this.setState({ isRequestPanel: true, requestInfo: _info }) };

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        RefundHistory = (orderBy === 'desc')
            ? RefundHistory.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : RefundHistory.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));
        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onNextPage = (_pageNumber, limit) => {
        this.setState({ isLoading: true }, () => this.props.APIRefundHistoryList({ page: _pageNumber, num_limit: (limit || 25), where: { refundStatus: ["refuse", "success"] } }));
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true }, () => this.props.APIRefundHistoryList({ page: 0, num_limit: _limitRows, where: { refundStatus: ["refuse", "success"] } }));
    };

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => (this.setState({ isRequestPanel: false }));
}

function GetBookingRows(classes, RefundHistory, PackageList, DisplayPanel) {
    return RefundHistory.map(_info => {
        const { refundId, refundById, createDate, refundBy, refundStatus, amount, currency, packageId } = _info;

        const isCallStyle = (_isMobile) ? _callStyleMobile : _callStyle;
        const isCallNameStyle = (_isMobile) ? _callNameMobile : _callName;

        let PackageName = null
        PackageList.filter((each) => each.packageId === packageId).map((value) => PackageName = value.packageInfo.name) 
        return (
            <TableRow hover tabIndex={-1} className={classes.row} key={refundId} style={{ cursor: 'pointer' }} onClick={() => DisplayPanel(_info)} >
                {(!_isMobile) && <TableCell style={isCallStyle}  >{refundId}</TableCell>}
                <TableCell style={isCallStyle}>{SetDate(createDate)}</TableCell>
                <TableCell style={isCallNameStyle}>{PackageName || '-'}</TableCell>
                <TableCell style={isCallStyle} >{GetRefundByName(refundBy, refundById) || '-'}</TableCell>
                <TableCell style={isCallStyle} >{refundBy || '-'}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, refundStatus)} >{refundStatus || '-'}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, refundStatus)}  >{`${GetSymbol(currency)}${NumberFormats(amount || 0)}`}</TableCell>
            </TableRow>)
    });
}

function GetRefundByName(_refundBy, _refundById) {
    let result = null;
    switch (_refundBy) {
        case 'customer':
            CustomerList.filter((each) => each.id === _refundById).map((val) => result = val.fullName)
            break;
        case 'admin':
            UserList.filter((each) => each.id === _refundById).map((val) => result = val.username)
            break;
    }
    return result
}

const styles = theme => (
    {
        row: {
            '&:nth-of-type(odd)': { backgroundColor: Colors.themeDark_Light },
            '&:nth-of-type(odd):hover': { backgroundColor: Colors.themeDark_Item },
        },
    });

RefundHistoryListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIRefundHistoryList: (_filter) => {
            dispatch(RefundActions.APIRefundHistoryList(_filter));
        },
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getCustomers: (_filter) => {
            dispatch(CustomerActions.getCustomers(_filter));
        },
        getUserAlls: (_filter) => {
            dispatch(AdminActions.getUserAlls(_filter));
        },

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RefundHistoryListPanel));
