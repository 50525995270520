export default {
    BUTTON_ADD_MORE_TITLE: '+ ADD MORE TITLE',
    BUTTON_ADD_MORE_SUBJECT: '+ ADD MORE SUBJECT',
    BUTTON_SUBMIT: 'SUBMIT',
    BUTTON_SAVE: 'SAVE',
    BUTTON_CANCEL: 'CANCLE',
    BUTTON_OK: 'OK',
    BUTTON_CLOSE: 'CLOSE',
    BUTTON_ADD_TRAVEL: '+ ADD TRAVEL PERIOD',
    BUTTON_SELECT: 'SELECT',
    BUTTON_RESET: 'RESET',
    BUTTON_APPLY_FOR_REFUND: 'APPLY FOR REFUND',

}