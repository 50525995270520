import React from 'react'
import { connect } from 'react-redux';
import { path } from 'ramda';

import Typography from '@material-ui/core/Typography';
import FormTypes from '../../common/FormTypes';

import Special from '../../constant/JSON/Special';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction';

import { SetStateWithTime } from '../../common/SetTime.js';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Colors, Fonts } from '../../themes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import PackageCard from './PackageCard.jsx';
import PackageList from './PackageList.jsx';

import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';
import * as CommissionsActions from '../../actions/Actions_CommissionsManager';
import * as PackageAction from '../../actions/Actions_PackageManager';
import { init_Special } from '../../constant/initState/initCommission';
import CONFIG from '../../config';
import { Family } from '../../themes';
import { isMobile } from '../../common/DeviceCheck';

let waitingTime = true;
const _rowContainer = { display: 'flex', padding: '2px 0px' };


class SpecialCard extends React.Component {
    error = null;
    state = { isBtnActive: false, isEditSpecial: false, isPanelActive: false, isSpecial: false, isLoading: false, msgError: undefined, dialogType: undefined, isPackage: false }
    constructor(props) {
        super(props);
        this.state = {
            SellerInfo: this.props.SellerInfo,
            SpecialInfo: Array.isArray(props.specialList.datas) ? props.specialList.datas.find((val) => val.affiliateId === path(['sellerId'], props.SellerInfo)) : [],
        }
    }
    componentWillReceiveProps(nextProps) {
        const { CommissionsManager } = nextProps;
        if (!!CommissionsManager) {
            const { actions, data } = CommissionsManager.COMMISSIONS_REDUCER;
            const { page, language } = this.props.specialList;

            switch (actions) {
                case CommissionsActions.CREATE_SPECIAL_COMMISSIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create SpecialList Success.', dialogType: 'success', });
                        this.props.getAPISpecialList({ page: page, num_limit: 25, language: language });
                    } break;
                case CommissionsActions.UPDATE_SPECIAL_COMMISSIONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update SpecialList Success.', dialogType: 'success', });
                        this.props.getAPISpecialList({ page: page, num_limit: 25, language: language });
                    } break;
                case CommissionsActions.CREATE_SPECIAL_COMMISSIONS_FAILURE:
                case CommissionsActions.UPDATE_SPECIAL_COMMISSIONS_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }

    render() {
        const { classes } = this.props;
        const { isLoading, msgError, dialogType, isSpecial } = this.state;
        return (
            <div className={classes.root}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {/* {(isSpecial) && <DialogAction Active={isSpecial} OnClose={this.onClosePanel} AcrionCom={this.GetPanelSpecial(Special.CommissionForm)} />} */}

                {Special.BodyPage.map((row, index) => (
                    <div key={index} style={_rowContainer}>
                        {row.map((each, index) => {
                            let _marginRight = each.margin === 'right';
                            return (
                                <div key={index} style={{ textAlign: 'start', width: each.width ? each.width : '100%', marginRight: (_marginRight) ? 15 : 0 }}>
                                    {this.SetItemsWithType(each)}
                                </div>
                            )
                        })}
                    </div>
                ))}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_CLOSE}
                        onClick={() => this.props.onClosePanel()} />
                </div>
            </div>
        )
    }

    SetItemsWithType(each) {
        const { SpecialInfo, SellerInfo, isSpecial, isEditSpecial } = this.state;
        const { classes, isView, packageInfoList, sellerList, isEdit, specialList, SellerTypeList } = this.props;
        let options = (each.options) ? each.options : null;
        let isError = !!path([each.stateName], this.error)
        const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');
        const sellerType = Array.isArray(SellerTypeList) && SellerTypeList.filter((val) => (val.sellerTypeId === path(['type'], SellerInfo))).map((each) => each.name)
        switch (each.type) {
            case 'title': {
                const _backgroundImage = { width: '80px', height: '80px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${checkURL(path(['logo'], SellerInfo))})`, backgroundRepeat: 'round' };
                return (
                    <div className={classes.title}>
                        <div style={_backgroundImage} />
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography className={classes.text} > {path(['name'], SellerInfo)} </Typography>
                            <Typography className={classes.content} > {'Type : ' + sellerType} </Typography>
                        </div>
                    </div>
                )
            }
            case 'button-list': {
                return (
                    <Button.ThemesDark FullWidth
                        disabled={isSpecial && true}
                        TitleText={each.title_EN}
                        onClick={() => this.onCreateSpecial()} />
                )
            }
            case 'table-list': {
                return (
                    <React.Fragment>
                        {(isSpecial) ? this.GetPanelSpecial(Special.CommissionForm) : null}
                        <PackageList
                            each={each}
                            isCreate={isSpecial}
                            CommissionForm={Special.CommissionForm}
                            onSavePanel={this.onSavePanel}
                            onUpdate={this.onUpdate}
                            SellerId={SellerInfo.sellerId}
                            SpecialList={specialList}
                            SpecialInfo={SpecialInfo}
                            onChange={(fieldName, state) => this.onChangeState(fieldName, state)}
                            PackageList={Array.isArray(packageInfoList.package) ? packageInfoList.package : []} />
                    </React.Fragment>
                )
            }
        }
    }
    GetPanelSpecial(CommissionForm) {
        const { SpecialInfo, SellerInfo, isSpecial } = this.state;
        return (
            <FormTypes.FormSpecial
                isEdit={!isSpecial}
                onChange={(fieldName, state) => this.onChangeState(fieldName, state)}
                StateList={CommissionForm}
                SellerId={SellerInfo.sellerId}
                PackageAll={this.props.packageInfoList}
                SpecialInfo={SpecialInfo}
                SpecialAll={this.props.specialList}
                onSaveSpecial={this.onSaveSpecial}
                onSavePanel={this.onSavePanel}
                onClosePanel={this.onClosePanel} />
        )

    }
    onChangeState = (fieldName, state) => {
        console.log('onChangeState', fieldName, state);
        let SpecialInfo = { ...this.state.SpecialInfo };
        switch (fieldName) {
            case "priceType": {
                SpecialInfo = {
                    ...SpecialInfo,
                    adult: 0, kid: 0, infant: 0, [fieldName]: state
                }
            } break;
            // case "adult":
            // case "kid":
            // case "infant": {
            //     if (SpecialInfo.priceType === 'percent') {
            //         SpecialInfo = { ...SpecialInfo, [fieldName]: (state / 100).toFixed(2) }
            //     } else {
            //         SpecialInfo = { ...SpecialInfo, [fieldName]: parseFloat(state) || 0 }
            //     }
            // } break;
            default: {
                SpecialInfo = { ...SpecialInfo, [fieldName]: state }
            } break;
        }
        console.log('SpecialInfo Success', SpecialInfo);
        this.setState({ SpecialInfo, isBtnActive: true });
    }
    onSaveSpecial = () => {
        const { SpecialInfo } = this.state;
        if (SpecialInfo) {
            SpecialInfo.adult = parseFloat(SpecialInfo.adult) || 0
            SpecialInfo.kid = parseFloat(SpecialInfo.kid) || 0
            SpecialInfo.infant = parseFloat(SpecialInfo.infant) || 0
            console.log('onSaveSpecial', SpecialInfo)
            this.setState({ isLoading: true, SpecialInfo }, () => this.props.APICreateSpecial(SpecialInfo));
        }
    }

    onSavePanel = (packageId, packageName, packageImage) => {
        let SpecialInfo = { ...this.state.SpecialInfo }
        SpecialInfo = { ...SpecialInfo, packageId, packageName, packageImage }
        console.log('onSavePanel Package', SpecialInfo)
        this.setState({ SpecialInfo })
    }

    onUpdate = (SpecialItem) => {
        // console.log('SpecialItem', SpecialItem)
        // const { SpecialInfo } = this.state;
        // if (SpecialInfo) {
        //     SpecialInfo.adult = parseFloat(SpecialInfo.adult)
        //     SpecialInfo.kid = parseFloat(SpecialInfo.kid)
        //     SpecialInfo.infant = parseFloat(SpecialInfo.infant)
        //     console.log('onSave', SpecialInfo)
        //     this.setState({ isLoading: true, SpecialInfo }, () => this.props.APIUpdateSpecial(SpecialInfo));
        // }
        if (SpecialItem) {
            SpecialItem.adult = parseFloat(SpecialItem.adult) || 0
            SpecialItem.kid = parseFloat(SpecialItem.kid) || 0
            SpecialItem.infant = parseFloat(SpecialItem.infant) || 0
            console.log('onUpdate onUpdate', SpecialItem)
            this.setState({ isLoading: true }, () => this.props.APIUpdateSpecial(SpecialItem));
        }
    };
    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, }));
    onCreateSpecial = () => (this.setState({ isSpecial: true, SpecialInfo: { ...init_Special, affiliateId: this.state.SellerInfo.sellerId } }))
    onClosePanel = () => (this.setState({ isSpecial: false }))
}

const styles = theme => ({
    root: { maxWidth: 800, fontFamily: Family.roboto, overflow: 'hidden' },
    text: { fontSize: isMobile() ? '12px' : '20px', display: 'flex', padding: isMobile() ? '5px 10px' : '5px 35px', fontWeight: 400, },
    title: { display: 'flex', textAlign: 'center', padding: '15px 0px' },
    content: { fontSize: isMobile() ? '10px' : '18px', display: 'flex', padding: isMobile() ? '5px 10px' : '5px 35px', },
});

SpecialCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISpecialList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CommissionsActions.getAPISpecialList(_keyword, _filter));
        },
        APICreateSpecial: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CommissionsActions.APICreateSpecial(_keyword, _filter));
        },
        APIUpdateSpecial: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CommissionsActions.APIUpdateSpecial(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecialCard))