import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import AnnouncementListPanel from '../containers/Website/AnnouncementListPanel.jsx';
import AnnouncementCard from '../containers/Website/AnnouncementCard.jsx';

import { Family, Colors } from '../themes/';
import { initAnnouncement } from '../constant/initState/initAnnouncement.js';
import * as AnnouncementActions from '../actions/Actions_AnnouncementManager.js';


let waitingTime = true;
let announcementList = [];
const _height = (window.innerHeight - 130);

class AnnouncementManage extends Component {
    state = { isLoading: true, msgError: undefined, dialogType: undefined, isView: false, isOpen: false };

    constructor(props) {
        super(props);

        this.props.APIAnnouncementList({ num_limit: 25 });
    }


    componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps);

        const { AnnouncementManager } = nextProps;
        if (!!AnnouncementManager) {
            const { actions, data } = AnnouncementManager.ANNOUNCEMENT_REDUCER;
            switch (actions) {
                case AnnouncementActions.GET_ANNOUNCEMENT_LIST_SUCCESS:
                    {
                        announcementList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AnnouncementActions.GET_ANNOUNCEMENT_LIST_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }
    }

    render() {
        const { isLoading, isOpen } = this.state;
        return (
            <Contianer>
                {(isOpen) && <DialogAction Active={isOpen} OnClose={this.onClosePanel} AcrionCom={this.contentPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <ListContianer >
                    <List>  {this.contentListPanel()}   </List>
                </ListContianer>
            </Contianer>
        );
    }

    contentListPanel() {
        return (
            <ListPanelContianer>
                <AnnouncementListPanel
                    announcementList={announcementList}
                    CreatePanel={this.CreateContent(this.state.isOpen)}
                    onClickHandle={this.ViewContent()}
                    EditInfo={this.EditContent()} />
            </ListPanelContianer>
        )
    }

    contentPanel() {
        const { isView, isEdit, oldAnnouncementInfo, announcementInfo } = this.state;
        return (
            <AnnouncementCard
                onChangeLanguage={this.onChangeLanguage}
                announcementList={announcementList}
                isView={isView}
                isEdit={isEdit}
                oldAnnouncementInfo={oldAnnouncementInfo}
                announcementInfo={announcementInfo}
                onClosePanel={this.onClosePanel} />
        )
    }

    onChangeLanguage = (_language) => {
        const { page } = announcementList;
        this.props.APIAnnouncementList({ page: page, num_limit: 10, language: _language });
    }

    CreateContent = (_isActive) => () => {
        this.setState({
            isOpen: !_isActive,
            isEdit: false,
            isView: false,
            announcementInfo: { ...initAnnouncement },
            oldAnnouncementInfo: { ...initAnnouncement }
        });
    }

    EditContent = () => (announcementInfo) => {
        this.setState({
            isOpen: true,
            isEdit: true,
            isView: false,
            announcementInfo,
            oldAnnouncementInfo: announcementInfo
        });
    }

    ViewContent = () => (announcementInfo) => {
        this.setState({
            isOpen: true,
            isEdit: false,
            isView: true,
            announcementInfo,
            oldAnnouncementInfo: announcementInfo
        });
    }

    onClosePanel = () => (this.setState({ isOpen: false }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIAnnouncementList: (_keyword) => {
            InitWaitingTime();
            dispatch(AnnouncementActions.getAnnouncementList(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementManage);

const Contianer = styled.div`
    text-align: center;
    font-family: ${Family.roboto};`;

const ListContianer = styled.div`
    width: 100%; 
    position: relative;
    overflow: auto;
    height: ${_height}px;`;
const List = styled.div`
    display: inline-block;`;
const ListPanelContianer = styled.div`
    height: 600px;
    width: 100% ;
    maxWidth: 1024px;`;