import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { Images, Colors, Family } from '../../themes';
import { PathName } from '../../constant';

// import logo from '../../images/logo.svg';
// import AppbarPanel from '../../componets/AppbarPanel.jsx';
// import FooterPanel from '../../componets/FooterPanel.jsx';
// import InfoCard from '../../componets/InfoCard.jsx';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import CONFIG from '../../config';

import InProcessListCard from '../../containers/Booking/InProcessListCard.jsx'
import * as BookingActions from '../../actions/Actions_BookingManager';
import '../../App.css';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';
import DialogPanel from '../../componets/DialogPanel.jsx';

const { HeaderBackground } = Images;

const _height = (window.innerHeight - 130);
let BookingList = {};
let waitingTime = true;



class CustomerInfo extends Component {

    state = { isLoading: true }

    constructor(props) {
        super(props);

        const { customerInfo } = this.props.location.state;

        if (!!customerInfo) {
            //this.props.getBookingListsWithCustomerID(customerInfo.id);
            this.props.getAPIBookingLists('', { userId: customerInfo.id, page: 0, limit: 25 });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { BookingManager } = nextProps;
        const { customerInfo } = this.props.location.state;

        //console.log(`BookingManager [componentWillReceiveProps] : `, BookingManager, );

        if (!!BookingManager) {
            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingActions.GET_LB_BOOKING_LISTS_SUCCESS:
                    {
                        if (!!data)
                            BookingList.bookingList = data.filter((booking) => (booking.userId === customerInfo.id));

                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingActions.GET_BOOKING_LISTS_SUCCESS:
                    {
                        BookingList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingActions.GET_LB_BOOKING_LISTS_FAILURE:
                case BookingActions.GET_BOOKING_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { customerInfo } = this.props.location.state;
        //console.log(customerInfo);

        return (
            <div className="App">
                <DialogPanel Active={this.state.isLoading} />
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel(customerInfo)}
                    </div>
                </List>
                {/* <FooterPanel /> */}
            </div>
        );
    }

    NavbarAcitve = (value) => { console.log('NavbarAcitve : ', value); this.props.history.push(value); };

    contentPanel(customerInfo) {
        // return <div style={{height:'600px',width:'100%',maxWidth:'1024px',}}>
        return (
            <div>
                {this.InfoPanel(customerInfo)}
                <InProcessListCard BookingList={BookingList} userInfo={customerInfo.id} BookingDetail={(id) => this.CallBookingDetail(id)} />
            </div>
        )
    }

    CallBookingDetail(_id) {
        console.log('CallBookingDetail : ', _id)

        this.props.getAPIBookingDetails(_id);
        this.props.history.push({ pathname: PathName.BookingDetail, state: {}, search: _id, hash: '' });
    }


    InfoPanel(customerInfo) {
        const { email, firstName, lastName, tel, isAcceptInformation, type, countryCode, active, avatarLink, memberLevelId, memberTypeId, registerDate, id, title, visible, memberType, memberLevel, expireDate } = customerInfo;
        const { classes } = this.props;
        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10, fontFamily: Family.roboto };
        console.log('customerInfo', customerInfo);
        return (
            <div style={{
                display: 'flex', height: '328px', width: '1020px',
                backgroundColor: Colors.themeDark,
                // backgroundImage: `url(${Images.HeaderBackground.HeaderBackground})`, 
            }} >
                < div style={{ height: '300px', width: '250px', paddingLeft: '50px', paddingTop: '50px', paddingRight: '50px', borderradius: '10%', boxshadow: '0  0 5px rgba(0, 0, 0, 0.2)', }} >
                    <img className={classes.avataronline} src={(avatarLink) ? (`${CONFIG.host}${avatarLink}`) : Images.User.userdefault} />
                </div>
                <div style={{ width: '500px', height: '200px', paddingTop: '30px', paddingLeft: '0px', textAlign: 'left' }}>
                    <div style={_styleInfoItem}>
                        <label className={classes.betty_pittman}>{`${title || ''} ${firstName}   ${lastName}`}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>User ID : </label>
                        <label className={classes.july32017copy}>{id}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Phone number : </label>
                        <label className={classes.july32017copy}>{`${countryCode + tel}` || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Email : </label>
                        <label className={classes.july32017copy}>{email || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Level : </label>
                        <label className={classes.july32017copy}>{memberLevel || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Type : </label>
                        <label className={classes.july32017copy}>{memberType || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Visible : </label>
                        <label className={classes.july32017copy}>{visible ? 'Active' : 'Inactive'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Regiter Type : </label>
                        <label className={classes.july32017copy}>{type || '-'}</label>
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getBookingListsWithCustomerID: (customerID) => {
            InitWaitingTime();
            dispatch(BookingActions.get_LB_APIBookingLists(customerID));
        },
        getAPIBookingLists: (_keyword, _filter) => {
            dispatch(BookingActions.getAPIBookingLists(_keyword, _filter));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingActions.getAPIBookingDetail(_id));
        },
    }
}

const styles = theme => (
    {
        july32017copy: {
            height: '16px',
            width: '100px',
            opacity: '0.64',
            color: Colors.white,
            fontFamily: Family.roboto,
            fontSize: '12px',
            lineHeight: '16px',
        },

        betty_pittman: {
            height: '39px',
            color: Colors.white,
            fontFamily: Family.roboto,
            fontSize: '30px',
            lineHeight: '39px',
        },
        class: {
            height: '16px',
            width: '110px',
            color: Colors.white,
            fontFamily: Family.roboto,
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '16px',
        },
        avataronline: {
            position: 'relative',
            boxShadow: '0 0 1px 1.9px ',
            backgroundRepeat: 'round',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            marginRight: '20%',
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerInfo));

//{email,firstName,lastName,tel,isAcceptInformation,type,active,avatarLink,activeToken,memberLevelId,memberTypeId,registerDate,id,password,title,visible,memberType,memberLevel,expireDate}

// {
//     "email": "naruchai.chim@hotmail.com",
//     "firstName": "Naruchai",
//     "lastName": "Chim",
//     "tel": "+66871909878",
//     "isAcceptInformation": true,
//     "type": "normal",
//     "active": 1,
//     "avatarLink": "/api/uploads/client/avatar/xgLqjsjq407H83o6.jpeg",
//     "activeToken": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE1MjQyMzYyMTMsImRhdGEiOiJuYXJ1Y2hhaS5jaGltQGhvdG1haWwuY29tIiwiaWF0IjoxNTI0MjE4MjEzfQ.YbCKlmXANvr7l8YPvAR3T8W2KeJTzGPSwpfQEBa8_DA",
//     "memberLevelId": "",
//     "memberTypeId": "",
//     "registerDate": "2018-02-08T07:43:53.351Z",
//     "id": "5a7bffb98d6bd15178bde9cc",
//     "password": "$2a$10$SQqrHo0l2uJKj5pAY6pNDOOkNkwrl7KGKMe5qdj3xZV7CZUfABQY.",
//     "title": "Mr.",
//     "visible": true,
//     "version": {
//       "bf": 1,
//       "mi": 0,
//       "mj": 1
//     },
//     "memberType": "Member",
//     "memberLevel": "None",
//     "expireDate": "2018-04-20T10:26:53.338Z"
//   }