export const init_supplier = {
    "language": "",
    "name": "",
    "email": "",
    "parentId": "",
    "address": {
        "no": "",
        "road": "",
        "district": "",
        "sub-district": "",
        "province": "",
        "post-code": "",
        "country": ""
    },
    'bank': {
        'bankId': '',
        'accountNumber': ''
    },
    "telOffice": "",
    "reservation_number": "",
    "emergencyContact": [{
        "person": "",
        "tel": ""
    }],
    "type": [],
    "officialWebsite": "",
    "logo": {
        "original": "",
        "thumbnail": ""
    },
    "visible": false,
}

