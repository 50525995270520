export const Province = [
    {
        "code": "10",
        "name": "กรุงเทพมหานคร",
        "name_EN": "Bangkok",
        "id": "5af3bacabc6c1e191b4848f5"
    },
    {
        "code": "11",
        "name": "สมุทรปราการ",
        "name_EN": "SamutPrakan",
        "id": "5af3bacabc6c1e191b4848f6"
    },
    {
        "code": "12",
        "name": "นนทบุรี",
        "name_EN": "Nonthaburi",
        "id": "5af3bacabc6c1e191b4848f7"
    },
    {
        "code": "13",
        "name": "ปทุมธานี",
        "name_EN": "PathumThani",
        "id": "5af3bacabc6c1e191b4848f8"
    },
    {
        "code": "14",
        "name": "พระนครศรีอยุธยา",
        "name_EN": "PhraNakhonSiAyutthaya",
        "id": "5af3bacabc6c1e191b4848f9"
    },
    {
        "code": "15",
        "name": "อ่างทอง",
        "name_EN": "AngThong",
        "id": "5af3bacabc6c1e191b4848fa"
    },
    {
        "code": "16",
        "name": "ลพบุรี",
        "name_EN": "Loburi",
        "id": "5af3bacabc6c1e191b4848fb"
    },
    {
        "code": "17",
        "name": "สิงห์บุรี",
        "name_EN": "SingBuri",
        "id": "5af3bacabc6c1e191b4848fc"
    },
    {
        "code": "18",
        "name": "ชัยนาท",
        "name_EN": "ChaiNat",
        "id": "5af3bacabc6c1e191b4848fd"
    },
    {
        "code": "19",
        "name": "สระบุรี",
        "name_EN": "Saraburi",
        "id": "5af3bacabc6c1e191b4848fe"
    },
    {
        "code": "20",
        "name": "ชลบุรี",
        "name_EN": "ChonBuri",
        "id": "5af3bacabc6c1e191b4848ff"
    },
    {
        "code": "21",
        "name": "ระยอง",
        "name_EN": "Rayong",
        "id": "5af3bacabc6c1e191b484900"
    },
    {
        "code": "22",
        "name": "จันทบุรี",
        "name_EN": "Chanthaburi",
        "id": "5af3bacabc6c1e191b484901"
    },
    {
        "code": "23",
        "name": "ตราด",
        "name_EN": "Trat",
        "id": "5af3bacabc6c1e191b484902"
    },
    {
        "code": "24",
        "name": "ฉะเชิงเทรา",
        "name_EN": "Chachoengsao",
        "id": "5af3bacabc6c1e191b484903"
    },
    {
        "code": "25",
        "name": "ปราจีนบุรี",
        "name_EN": "PrachinBuri",
        "id": "5af3bacabc6c1e191b484904"
    },
    {
        "code": "26",
        "name": "นครนายก",
        "name_EN": "NakhonNayok",
        "id": "5af3bacabc6c1e191b484905"
    },
    {
        "code": "27",
        "name": "สระแก้ว",
        "name_EN": "SaKaeo",
        "id": "5af3bacabc6c1e191b484906"
    },
    {
        "code": "30",
        "name": "นครราชสีมา",
        "name_EN": "NakhonRatchasima",
        "id": "5af3bacabc6c1e191b484907"
    },
    {
        "code": "31",
        "name": "บุรีรัมย์",
        "name_EN": "BuriRam",
        "id": "5af3bacabc6c1e191b484908"
    },
    {
        "code": "32",
        "name": "สุรินทร์",
        "name_EN": "Surin",
        "id": "5af3bacabc6c1e191b484909"
    },
    {
        "code": "33",
        "name": "ศรีสะเกษ",
        "name_EN": "SiSaKet",
        "id": "5af3bacabc6c1e191b48490a"
    },
    {
        "code": "34",
        "name": "อุบลราชธานี",
        "name_EN": "UbonRatchathani",
        "id": "5af3bacabc6c1e191b48490b"
    },
    {
        "code": "35",
        "name": "ยโสธร",
        "name_EN": "Yasothon",
        "id": "5af3bacabc6c1e191b48490c"
    },
    {
        "code": "36",
        "name": "ชัยภูมิ",
        "name_EN": "Chaiyaphum",
        "id": "5af3bacabc6c1e191b48490d"
    },
    {
        "code": "37",
        "name": "อำนาจเจริญ",
        "name_EN": "AmnatCharoen",
        "id": "5af3bacabc6c1e191b48490e"
    },
    {
        "code": "39",
        "name": "หนองบัวลำภู",
        "name_EN": "NongBuaLamPhu",
        "id": "5af3bacabc6c1e191b48490f"
    },
    {
        "code": "40",
        "name": "ขอนแก่น",
        "name_EN": "KhonKaen",
        "id": "5af3bacabc6c1e191b484910"
    },
    {
        "code": "41",
        "name": "อุดรธานี",
        "name_EN": "UdonThani",
        "id": "5af3bacabc6c1e191b484911"
    },
    {
        "code": "42",
        "name": "เลย",
        "name_EN": "Loei",
        "id": "5af3bacabc6c1e191b484912"
    },
    {
        "code": "43",
        "name": "หนองคาย",
        "name_EN": "NongKhai",
        "id": "5af3bacabc6c1e191b484913"
    },
    {
        "code": "44",
        "name": "มหาสารคาม",
        "name_EN": "MahaSarakham",
        "id": "5af3bacabc6c1e191b484914"
    },
    {
        "code": "45",
        "name": "ร้อยเอ็ด",
        "name_EN": "RoiEt",
        "id": "5af3bacabc6c1e191b484915"
    },
    {
        "code": "46",
        "name": "กาฬสินธุ์",
        "name_EN": "Kalasin",
        "id": "5af3bacabc6c1e191b484916"
    },
    {
        "code": "47",
        "name": "สกลนคร",
        "name_EN": "SakonNakhon",
        "id": "5af3bacabc6c1e191b484917"
    },
    {
        "code": "48",
        "name": "นครพนม",
        "name_EN": "NakhonPhanom",
        "id": "5af3bacabc6c1e191b484918"
    },
    {
        "code": "49",
        "name": "มุกดาหาร",
        "name_EN": "Mukdahan",
        "id": "5af3bacabc6c1e191b484919"
    },
    {
        "code": "50",
        "name": "เชียงใหม่",
        "name_EN": "ChiangMai",
        "id": "5af3bacabc6c1e191b48491a"
    },
    {
        "code": "51",
        "name": "ลำพูน",
        "name_EN": "Lamphun",
        "id": "5af3bacabc6c1e191b48491b"
    },
    {
        "code": "52",
        "name": "ลำปาง",
        "name_EN": "Lampang",
        "id": "5af3bacabc6c1e191b48491c"
    },
    {
        "code": "53",
        "name": "อุตรดิตถ์",
        "name_EN": "Uttaradit",
        "id": "5af3bacabc6c1e191b48491d"
    },
    {
        "code": "54",
        "name": "แพร่",
        "name_EN": "Phrae",
        "id": "5af3bacabc6c1e191b48491e"
    },
    {
        "code": "55",
        "name": "น่าน",
        "name_EN": "Nan",
        "id": "5af3bacabc6c1e191b48491f"
    },
    {
        "code": "56",
        "name": "พะเยา",
        "name_EN": "Phayao",
        "id": "5af3bacabc6c1e191b484920"
    },
    {
        "code": "57",
        "name": "เชียงราย",
        "name_EN": "ChiangRai",
        "id": "5af3bacabc6c1e191b484921"
    },
    {
        "code": "58",
        "name": "แม่ฮ่องสอน",
        "name_EN": "MaeHongSon",
        "id": "5af3bacabc6c1e191b484922"
    },
    {
        "code": "60",
        "name": "นครสวรรค์",
        "name_EN": "NakhonSawan",
        "id": "5af3bacabc6c1e191b484923"
    },
    {
        "code": "61",
        "name": "อุทัยธานี",
        "name_EN": "UthaiThani",
        "id": "5af3bacabc6c1e191b484924"
    },
    {
        "code": "62",
        "name": "กำแพงเพชร",
        "name_EN": "KamphaengPhet",
        "id": "5af3bacabc6c1e191b484925"
    },
    {
        "code": "63",
        "name": "ตาก",
        "name_EN": "Tak",
        "id": "5af3bacabc6c1e191b484926"
    },
    {
        "code": "64",
        "name": "สุโขทัย",
        "name_EN": "Sukhothai",
        "id": "5af3bacabc6c1e191b484927"
    },
    {
        "code": "65",
        "name": "พิษณุโลก",
        "name_EN": "Phitsanulok",
        "id": "5af3bacabc6c1e191b484928"
    },
    {
        "code": "66",
        "name": "พิจิตร",
        "name_EN": "Phichit",
        "id": "5af3bacabc6c1e191b484929"
    },
    {
        "code": "67",
        "name": "เพชรบูรณ์",
        "name_EN": "Phetchabun",
        "id": "5af3bacabc6c1e191b48492a"
    },
    {
        "code": "70",
        "name": "ราชบุรี",
        "name_EN": "Ratchaburi",
        "id": "5af3bacabc6c1e191b48492b"
    },
    {
        "code": "71",
        "name": "กาญจนบุรี",
        "name_EN": "Kanchanaburi",
        "id": "5af3bacabc6c1e191b48492c"
    },
    {
        "code": "72",
        "name": "สุพรรณบุรี",
        "name_EN": "SuphanBuri",
        "id": "5af3bacabc6c1e191b48492d"
    },
    {
        "code": "73",
        "name": "นครปฐม",
        "name_EN": "NakhonPathom",
        "id": "5af3bacabc6c1e191b48492e"
    },
    {
        "code": "74",
        "name": "สมุทรสาคร",
        "name_EN": "SamutSakhon",
        "id": "5af3bacabc6c1e191b48492f"
    },
    {
        "code": "75",
        "name": "สมุทรสงคราม",
        "name_EN": "SamutSongkhram",
        "id": "5af3bacabc6c1e191b484930"
    },
    {
        "code": "76",
        "name": "เพชรบุรี",
        "name_EN": "Phetchaburi",
        "id": "5af3bacabc6c1e191b484931"
    },
    {
        "code": "77",
        "name": "ประจวบคีรีขันธ์",
        "name_EN": "PrachuapKhiriKhan",
        "id": "5af3bacabc6c1e191b484932"
    },
    {
        "code": "80",
        "name": "นครศรีธรรมราช",
        "name_EN": "NakhonSiThammarat",
        "id": "5af3bacabc6c1e191b484933"
    },
    {
        "code": "81",
        "name": "กระบี่",
        "name_EN": "Krabi",
        "id": "5af3bacabc6c1e191b484934"
    },
    {
        "code": "82",
        "name": "พังงา",
        "name_EN": "Phangnga",
        "id": "5af3bacabc6c1e191b484935"
    },
    {
        "code": "83",
        "name": "ภูเก็ต",
        "name_EN": "Phuket",
        "id": "5af3bacabc6c1e191b484936"
    },
    {
        "code": "84",
        "name": "สุราษฎร์ธานี",
        "name_EN": "SuratThani",
        "id": "5af3bacabc6c1e191b484937"
    },
    {
        "code": "85",
        "name": "ระนอง",
        "name_EN": "Ranong",
        "id": "5af3bacabc6c1e191b484938"
    },
    {
        "code": "86",
        "name": "ชุมพร",
        "name_EN": "Chumphon",
        "id": "5af3bacabc6c1e191b484939"
    },
    {
        "code": "90",
        "name": "สงขลา",
        "name_EN": "Songkhla",
        "id": "5af3bacabc6c1e191b48493a"
    },
    {
        "code": "91",
        "name": "สตูล",
        "name_EN": "Satun",
        "id": "5af3bacabc6c1e191b48493b"
    },
    {
        "code": "92",
        "name": "ตรัง",
        "name_EN": "Trang",
        "id": "5af3bacabc6c1e191b48493c"
    },
    {
        "code": "93",
        "name": "พัทลุง",
        "name_EN": "Phatthalung",
        "id": "5af3bacabc6c1e191b48493d"
    },
    {
        "code": "94",
        "name": "ปัตตานี",
        "name_EN": "Pattani",
        "id": "5af3bacabc6c1e191b48493e"
    },
    {
        "code": "95",
        "name": "ยะลา",
        "name_EN": "Yala",
        "id": "5af3bacabc6c1e191b48493f"
    },
    {
        "code": "96",
        "name": "นราธิวาส",
        "name_EN": "Narathiwat",
        "id": "5af3bacabc6c1e191b484940"
    },
    {
        "code": "97",
        "name": "บึงกาฬ",
        "name_EN": "buogkan",
        "id": "5af3bacabc6c1e191b484941"
    }
]