import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { Colors } from '../themes';

function ImageSlide(props) {
    const { classes, ImageList, Host } = props;
    // console.log('ImageList : ', ImageList);

    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} style={{ backgroundColor: 'transparent' }} cols={2.9} spacing={1}>
                {ImageList.map((_image, index) => (
                    <GridListTile key={index} style={{ height: '100%', minWidth: '320px', display: '-webkit-inline-box', width: '30%' }}>
                        <img src={`${Host}${_image.thumbnail}`} alt={''} style={{ width: 320, height: 220 }} />
                        <GridListTileBar classes={{ root: classes.titleBar, title: classes.title, }} />
                    </GridListTile>))}
            </GridList>
        </div>);
}

function GetItemPerWidth() {
    return (window.screen.width / 395);
}

ImageSlide.propTypes = { classes: PropTypes.object.isRequired };

const styles = (theme) => ({
    root: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: Colors.white,
        padding: 5,
        marginTop: 10
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background: 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
});
export default withRouter(withStyles(styles)(ImageSlide));
