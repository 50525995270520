const HeaderPage = {
    TH: "เลือกภาษา",
    EN: "Select Language",
    CN: "选择语言",
}

const ConstLanguage = {
    TH: "TH",
    EN: "EN",
    CN: "CN",
}

const SelectLanguage = [
    { icon: "", value: "EN", label: "EN" },
    { icon: "", value: "TH", label: "TH" },
    { icon: "", value: "CN", label: "CN" },
]

const StateName = "language";

export default {
    HeaderPage,
    ConstLanguage,
    SelectLanguage,
    StateName,
} 