import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_BANKLIST_SETTING_SUCCESS = 'GET_BANKLIST_SETTING_SUCCESS';
export const GET_BANKLIST_SETTING_FAILURE = 'GET_BANKLIST_SETTING_FAILURE';

export const CREATE_BANK_SETTING_SUCCESS = 'CREATE_BANK_SETTING_SUCCESS';
export const CREATE_BANK_SETTING_FAILURE = 'CREATE_BANK_SETTING_FAILURE';

export const UPDATE_BANK_SETTING_SUCCESS = 'UPDATE_BANK_SETTING_SUCCESS';
export const UPDATE_BANK_SETTING_FAILURE = 'UPDATE_BANK_SETTING_FAILURE';

export const GET_PAYMENT_TIME_SUCCESS = 'GET_PAYMENT_TIME_SUCCESS';
export const GET_PAYMENT_TIME_FAILURE = 'GET_PAYMENT_TIME_FAILURE';

export const UPDATE_PAYMENT_TIME_SUCCESS = 'UPDATE_PAYMENT_TIME_SUCCESS';
export const UPDATE_PAYMENT_TIME_FAILURE = 'UPDATE_PAYMENT_TIME_FAILURE';

export const GET_DEFAULT_COMMISSIONS_SUCCESS = 'GET_DEFAULT_COMMISSIONS_SUCCESS';
export const GET_DEFAULT_COMMISSIONS_FAILURE = 'GET_DEFAULT_COMMISSIONS_FAILURE';

export const CREATE_DEFAULT_COMMISSIONS_SUCCESS = 'CREATE_DEFAULT_COMMISSIONS_SUCCESS';
export const CREATE_DEFAULT_COMMISSIONS_FAILURE = 'CREATE_DEFAULT_COMMISSIONS_FAILURE';

export const UPDATE_DEFAULT_COMMISSIONS_SUCCESS = 'UPDATE_DEFAULT_COMMISSIONS_SUCCESS';
export const UPDATE_DEFAULT_COMMISSIONS_FAILURE = 'UPDATE_DEFAULT_COMMISSIONS_FAILURE';


// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getBankList ============================================================================================================================

export function getBankList(_fileterjson = {}) {
    return dispatch => {

        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getBankList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getBankList : ", response);
            //localStorage.setItem('BankList', response.data)
            dispatch(getBankList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getBankList Failure", error);
            dispatch(getBankList_Failure(error));
        })
    }
}

function getBankList_Success(data) {
    return { type: GET_BANKLIST_SETTING_SUCCESS, payload: data };
}

function getBankList_Failure(error) {
    return { type: GET_BANKLIST_SETTING_FAILURE, payload: error };
}

// getBankList =========================================================================================================================

export function APICreateBank(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createBank}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APICreateBank : ", response.data);
            dispatch(APICreateBank_Success(response.data));

        }).catch((error) => {
            console.log("APICreateBank fail", error);
            dispatch(APICreateBank_Failure(error));
        })
    }
}

function APICreateBank_Success(data) {
    return { type: CREATE_BANK_SETTING_SUCCESS, payload: data };
}

function APICreateBank_Failure(error) {
    return { type: CREATE_BANK_SETTING_FAILURE, payload: error };
}

// updateBankList =========================================================================================================================

export function APIUpdateBank(confirmInfo) {
    //console.log('APIUpdateBank : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateBank}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { bankSettingId: confirmInfo.bankSettingId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIUpdateBank : ", response.data);
            dispatch(APIUpdateBank_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateBank fail", error);
            dispatch(APIUpdateBank_Failure(error));
        })
    }
}

function APIUpdateBank_Success(data) {
    return { type: UPDATE_BANK_SETTING_SUCCESS, payload: data };
}

function APIUpdateBank_Failure(error) {
    return { type: UPDATE_BANK_SETTING_FAILURE, payload: error };
}

// getBankList ============================================================================================================================

// getPaymentTime ============================================================================================================================

export function getPaymentTime(_fileterjson = {}) {
    return dispatch => {

        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getPaymentTime}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getPaymentTime : ", response);
            //localStorage.setItem('PaymentTime', response.data)
            dispatch(getPaymentTime_Success(response.data));

        }).catch((error) => {
            console.log("[API] getPaymentTime Failure", error);
            dispatch(getPaymentTime_Failure(error));
        })
    }
}

function getPaymentTime_Success(data) {
    return { type: GET_PAYMENT_TIME_SUCCESS, payload: data };
}

function getPaymentTime_Failure(error) {
    return { type: GET_PAYMENT_TIME_FAILURE, payload: error };
}

// getPaymentTime =========================================================================================================================
// PaymentTime =========================================================================================================================

export function UpdatePaymentTime(confirmInfo) {

    // console.log('UpdatePaymentTime : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updatePaymentTime}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] UpdatePaymentTime : ", response.data);
            dispatch(UpdatePaymentTime_Success(response.data));

        }).catch((error) => {
            console.log("UpdatePaymentTime fail", error);
            dispatch(UpdatePaymentTime_Failure(error));
        })
    }
}

function UpdatePaymentTime_Success(data) {
    return { type: UPDATE_PAYMENT_TIME_SUCCESS, payload: data };
}

function UpdatePaymentTime_Failure(error) {
    return { type: UPDATE_PAYMENT_TIME_FAILURE, payload: error };
}

// PaymentTime ============================================================================================================================
// level commissions ======================================================================================================================
export function getDefaultCommissions(_fileterjson = {}) {
    return dispatch => {

        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getDefaultCommissions}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getBankList : ", response);
            //localStorage.setItem('BankList', response.data)
            dispatch(getDefaultCommissions_Success(response.data));

        }).catch((error) => {
            console.log("[API] getDefaultCommissions Failure", error);
            dispatch(getDefaultCommissions_Failure(error));
        })
    }
}

function getDefaultCommissions_Success(data) {
    return { type: GET_DEFAULT_COMMISSIONS_SUCCESS, payload: data };
}

function getDefaultCommissions_Failure(error) {
    return { type: GET_DEFAULT_COMMISSIONS_FAILURE, payload: error };
}

// getBankList =========================================================================================================================

export function createDefaultCommissions(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createDefaultCommissions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] createDefaultCommissions : ", response.data);
            dispatch(createDefaultCommissions_Success(response.data));

        }).catch((error) => {
            console.log("createDefaultCommissions fail", error);
            dispatch(createDefaultCommissions_Failure(error));
        })
    }
}

function createDefaultCommissions_Success(data) {
    return { type: CREATE_DEFAULT_COMMISSIONS_SUCCESS, payload: data };
}

function createDefaultCommissions_Failure(error) {
    return { type: CREATE_DEFAULT_COMMISSIONS_FAILURE, payload: error };
}

// updateBankList =========================================================================================================================

export function updateDefaultCommissions(confirmInfo) {
    //console.log('updateDefaultCommissions : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateDefaultCommissions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { defaultCommissionId: confirmInfo.defaultCommissionId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] updateDefaultCommissions : ", response.data);
            dispatch(updateDefaultCommissions_Success(response.data));

        }).catch((error) => {
            console.log("updateDefaultCommissions fail", error);
            dispatch(updateDefaultCommissions_Failure(error));
        })
    }
}

function updateDefaultCommissions_Success(data) {
    return { type: UPDATE_DEFAULT_COMMISSIONS_SUCCESS, payload: data };
}

function updateDefaultCommissions_Failure(error) {
    return { type: UPDATE_DEFAULT_COMMISSIONS_FAILURE, payload: error };
}
// level commissions ======================================================================================================================
