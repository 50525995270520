import React from 'react';
import Button from '../constant/Button';
import ButtonText from '../constant/Button/ButtonText';

import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';

import { isMobile } from '../common/DeviceCheck'

import { Colors } from '../themes';

const wording = {
  title: 'Loading Information.',
  btn_ok: 'Close',
  btn_cancel: 'Disagree',
  msg: `Sync information init system.`,
  error: 'System is error....!!',
  success: 'Create Success.'
}

export const DailogType = { waiting: 'waiting', error: 'error', question: 'question', success: 'success', loading: 'loading', alert: 'alert' };

class AlertDialogSlide extends React.Component {
  state = { open: true };

  handleClickOpen = () => { this.setState({ open: true }); };
  handleClose = () => { this.setState({ open: false }); const { OnClose } = this.props; OnClose && OnClose(); };

  componentDidUpdate() {

    if (!this.state.open && !this.props.Active)
      this.setState({ open: true })
  }

  render() {

    // console.log('Dialog : ', this.props)
    const { Active, MSG, Type, OK } = this.props;
    const isActive = (Active && this.state.open);

    return (isActive) && (
      <Dialog
        open={isActive}
        // open={(Active)}
        // open={this.state.open}
        // transition={Transition}
        PaperProps={styles.paperStyle}
        fullWidth={isMobile()}
        keepMounted
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        {GetDialogFrom(this, MSG, Type, OK)}
      </Dialog >
    );
  }
}

function Transition(props) { return <Slide direction="up" {...props} />; }

function GetDialogFrom(_salf, _msg, _type = 'loading', _ok = () => { }) {

  switch (_type) {
    case DailogType.waiting: { } break;
    case DailogType.error: return GetErrorDialog(_salf, _msg, _type);
    case DailogType.question: return GetQuestionDialog(_salf, _msg, _type, _ok);
    case DailogType.success: return GetSuccessDialog(_salf, _msg, _type);
    case DailogType.alert: return GetAlertDialog(_salf, _msg, _type);
    case DailogType.loading:
    default: return GetLoadingDialog(_salf, _msg, _type);
  }
}

function GetLoadingDialog(_salf, _msg, _type) {

  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {wording.title}
      </DialogTitle>
      <DialogContent style={styles.dialog_Content}>
        <div style={styles.progress_Container}>
          <CircularProgress style={styles.progress} thickness={7} />
        </div>
        <DialogContentText id="alert-dialog-slide-description">
          {(!!_msg) ? _msg : wording.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={_salf.handleClose} color="inherit">
          {wording.btn_ok}
        </Button> */}

        <Button.Themes TitleText={wording.btn_ok} onClick={_salf.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
      </DialogActions>
    </React.Fragment>);
}

function GetErrorDialog(_salf, _msg, _type) {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {'Error'}
      </DialogTitle>
      <DialogContent style={styles.dialog_Content}>
        <div style={styles.progress_Container}>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: Colors.theme }}>
            {(!!_msg) ? _msg : wording.error}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={_salf.handleClose} color="secondary">
          {wording.btn_ok}
        </Button> */}
        <Button.Themes TitleText={wording.btn_ok} onClick={_salf.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
      </DialogActions>
    </React.Fragment>);
}

function GetSuccessDialog(_salf, _msg, _type) {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {'Success'}
      </DialogTitle>
      <DialogContent style={styles.dialog_Content}>
        <div style={styles.progress_Container}>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: Colors.success }}>
            {(!!_msg) ? _msg : wording.success}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={_salf.handleClose} color="secondary">
          {'Ok'}
        </Button> */}
        <Button.Themes TitleText={ButtonText.BUTTON_OK} onClick={_salf.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
      </DialogActions>
    </React.Fragment>);
}

function GetDialog(_salf, _msg, _type) {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {wording.title}
      </DialogTitle>
      <DialogContent style={styles.dialog_Content}>
        <div style={styles.progress_Container}>
          <CircularProgress style={styles.progress} thickness={7} />
        </div>
        <DialogContentText id="alert-dialog-slide-description">
          {(!!_msg) ? _msg : wording.msg}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={_salf.handleClose} color="secondary">
          {wording.btn_ok}
        </Button> */}
        <Button.Themes TitleText={wording.btn_ok} onClick={_salf.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
        <Button.Themes TitleText={wording.btn_cancel} onClick={this.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
        {/* <Button onClick={this.handleClose} color="primary">
      {wording.btn_cancel}
    </Button> */}
      </DialogActions>
    </React.Fragment>);
}

function GetQuestionDialog(_salf, _msg, _type, _ok) {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {''}
      </DialogTitle>
      <DialogContent style={styles.dialog_Content}>
        <div style={styles.progress_Container}>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: Colors.themeDark }}>
            {(!!_msg) ? _msg : '?'}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} onClick={_salf.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
        <Button.Themes TitleText={ButtonText.BUTTON_OK} onClick={_ok}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
        {/* <Button onClick={_salf.handleClose} color="secondary">
          {'Cancel'}
        </Button>
        <Button onClick={_ok} color="secondary">
          {'Ok'}
        </Button> */}
      </DialogActions>
    </React.Fragment>);
}

function GetAlertDialog(_salf, _msg, _type, _ok) {
  return (
    <React.Fragment>
      <DialogTitle id="alert-dialog-slide-title">
        {''}
      </DialogTitle>
      <DialogContent style={styles.dialog_Content}>
        <div style={styles.progress_Container}>
          <DialogContentText id="alert-dialog-slide-description" style={{ color: Colors.themeDark }}>
            {(!!_msg) ? _msg : '?'}
          </DialogContentText>
        </div>
      </DialogContent>
      <DialogActions>
        <Button.Themes TitleText={ButtonText.BUTTON_OK} onClick={_salf.handleClose}
          style={{ width: ' 20%', margin: '0px 10px', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} />
        {/* <Button.Themes TitleText={ButtonText.BUTTON_OK} onClick={_ok}
          style={{ width: '100%', textAlign: 'end' }} buttonStyle={{ margin: '0px', padding: '10px' }} /> */}
        {/* <Button onClick={_salf.handleClose} color="secondary">
          {'Cancel'}
        </Button>
        <Button onClick={_ok} color="secondary">
          {'Ok'}
        </Button> */}
      </DialogActions>
    </React.Fragment>);
}

const styles = {
  dialog_Content: { width: isMobile() ? 'unset' : 550, maxWidth: 550, },
  progress_Container: { textAlign: 'center', padding: '20px' },
  progress: { color: Colors.theme, },
  paperStyle: { style: { margin: 10 } },
};

export default AlertDialogSlide;