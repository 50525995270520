import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconClose from '@material-ui/icons/HighlightOff';

import FormTypes from '../../common/FormTypes';
import { Colors } from '../../themes';

class FormText extends Component {
    render() {
        const { classes, disabled, error, TitleText, value, indexArray, onRemove, onChange, placeholder, Require, StyleIcon } = this.props;
        return (
            <div className={classes.root}>
                <FormTypes.TextFormat multiline rowsMax={10} rows={2} disableUnderline
                    disabled={disabled}
                    error={error}
                    Require={Require}
                    TitleText={TitleText}
                    value={value}
                    style={{ marginBottom: 10, width: '97%' }}
                    onChange={onChange}
                    placeholder={placeholder} />
                {
                    (indexArray > 0) && <IconClose style={{ color: Colors.themeDark, position: 'absolute', top: 35, right: -20, zIndex: disabled ? -1 : null, ...StyleIcon }} onClick={onRemove} />
                }
            </div>

        )
    }
}
const styles = theme => ({
    root: { position: 'relative', width: '97%', paddingBottom: "10px" }
})

FormText.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormText);