export const Districts = [
  {
    "code": "1001",
    "name": "เขตพระนคร",
    "id": "5afae3c7ead44fb20f6c85cb",
    "name_EN": "Khet Phra Nakhon",
    "province_code": "10"
  },
  {
    "code": "1002",
    "name": "เขตดุสิต",
    "id": "5afae3c7ead44fb20f6c85cc",
    "name_EN": "Khet Dusit",
    "province_code": "10"
  },
  {
    "code": "1003",
    "name": "เขตหนองจอก",
    "id": "5afae3c7ead44fb20f6c85cd",
    "name_EN": "Khet  Nong Chok",
    "province_code": "10"
  },
  {
    "code": "1004",
    "name": "เขตบางรัก",
    "id": "5afae3c7ead44fb20f6c85ce",
    "name_EN": "Khet Bang Rak",
    "province_code": "10"
  },
  {
    "code": "1005",
    "name": "เขตบางเขน",
    "id": "5afae3c7ead44fb20f6c85cf",
    "name_EN": "Khet Bang Khen",
    "province_code": "10"
  },
  {
    "code": "1006",
    "name": "เขตบางกะปิ",
    "id": "5afae3c7ead44fb20f6c85d0",
    "name_EN": "Khet Bang Kapi",
    "province_code": "10"
  },
  {
    "code": "1007",
    "name": "เขตปทุมวัน",
    "id": "5afae3c7ead44fb20f6c85d1",
    "name_EN": "Khet Pathum Wan",
    "province_code": "10"
  },
  {
    "code": "1008",
    "name": "เขตป้อมปราบศัตรูพ่าย",
    "id": "5afae3c7ead44fb20f6c85d2",
    "name_EN": "Khet Pom Prap Sattru Phai",
    "province_code": "10"
  },
  {
    "code": "1009",
    "name": "เขตพระโขนง",
    "id": "5afae3c7ead44fb20f6c85d3",
    "name_EN": "Khet Phra Khanong",
    "province_code": "10"
  },
  {
    "code": "1010",
    "name": "เขตมีนบุรี",
    "id": "5afae3c7ead44fb20f6c85d4",
    "name_EN": "Khet Min Buri",
    "province_code": "10"
  },
  {
    "code": "1011",
    "name": "เขตลาดกระบัง",
    "id": "5afae3c7ead44fb20f6c85d5",
    "name_EN": "Khet Lat Krabang",
    "province_code": "10"
  },
  {
    "code": "1012",
    "name": "เขตยานนาวา",
    "id": "5afae3c7ead44fb20f6c85d6",
    "name_EN": "Khet Yan Nawa",
    "province_code": "10"
  },
  {
    "code": "1013",
    "name": "เขตสัมพันธวงศ์",
    "id": "5afae3c7ead44fb20f6c85d7",
    "name_EN": "Khet Samphanthawong",
    "province_code": "10"
  },
  {
    "code": "1014",
    "name": "เขตพญาไท",
    "id": "5afae3c7ead44fb20f6c85d8",
    "name_EN": "Khet Phaya Thai",
    "province_code": "10"
  },
  {
    "code": "1015",
    "name": "เขตธนบุรี",
    "id": "5afae3c7ead44fb20f6c85d9",
    "name_EN": "Khet Thon Buri",
    "province_code": "10"
  },
  {
    "code": "1016",
    "name": "เขตบางกอกใหญ่",
    "id": "5afae3c7ead44fb20f6c85da",
    "name_EN": "Khet Bangkok Yai",
    "province_code": "10"
  },
  {
    "code": "1017",
    "name": "เขตห้วยขวาง",
    "id": "5afae3c7ead44fb20f6c85db",
    "name_EN": "Khet Huai Khwang",
    "province_code": "10"
  },
  {
    "code": "1018",
    "name": "เขตคลองสาน",
    "id": "5afae3c7ead44fb20f6c85dc",
    "name_EN": "Khet Khlong San",
    "province_code": "10"
  },
  {
    "code": "1019",
    "name": "เขตตลิ่งชัน",
    "id": "5afae3c7ead44fb20f6c85dd",
    "name_EN": "Khet Taling Chan",
    "province_code": "10"
  },
  {
    "code": "1020",
    "name": "เขตบางกอกน้อย",
    "id": "5afae3c7ead44fb20f6c85de",
    "name_EN": "Khet Bangkok Noi",
    "province_code": "10"
  },
  {
    "code": "1021",
    "name": "เขตบางขุนเทียน",
    "id": "5afae3c7ead44fb20f6c85df",
    "name_EN": "Khet Bang Khun Thian",
    "province_code": "10"
  },
  {
    "code": "1022",
    "name": "เขตภาษีเจริญ",
    "id": "5afae3c7ead44fb20f6c85e0",
    "name_EN": "Khet Phasi Charoen",
    "province_code": "10"
  },
  {
    "code": "1023",
    "name": "เขตหนองแขม",
    "id": "5afae3c7ead44fb20f6c85e1",
    "name_EN": "Khet Nong Khaem",
    "province_code": "10"
  },
  {
    "code": "1024",
    "name": "เขตราษฎร์บูรณะ",
    "id": "5afae3c7ead44fb20f6c85e2",
    "name_EN": "Khet Rat Burana",
    "province_code": "10"
  },
  {
    "code": "1025",
    "name": "เขตบางพลัด",
    "id": "5afae3c7ead44fb20f6c85e3",
    "name_EN": "Khet Bang Phlat",
    "province_code": "10"
  },
  {
    "code": "1026",
    "name": "เขตดินแดง",
    "id": "5afae3c7ead44fb20f6c85e4",
    "name_EN": "Khet Din Daeng",
    "province_code": "10"
  },
  {
    "code": "1027",
    "name": "เขตบึงกุ่ม",
    "id": "5afae3c7ead44fb20f6c85e5",
    "name_EN": "Khet Bueng Kum",
    "province_code": "10"
  },
  {
    "code": "1028",
    "name": "เขตสาทร",
    "id": "5afae3c7ead44fb20f6c85e6",
    "name_EN": "Khet Sathon",
    "province_code": "10"
  },
  {
    "code": "1029",
    "name": "เขตบางซื่อ",
    "id": "5afae3c7ead44fb20f6c85e7",
    "name_EN": "Khet Bang Sue",
    "province_code": "10"
  },
  {
    "code": "1030",
    "name": "เขตจตุจักร",
    "id": "5afae3c7ead44fb20f6c85e8",
    "name_EN": "Khet Chatuchak",
    "province_code": "10"
  },
  {
    "code": "1031",
    "name": "เขตบางคอแหลม",
    "id": "5afae3c7ead44fb20f6c85e9",
    "name_EN": "Khet Bang Kho Laem",
    "province_code": "10"
  },
  {
    "code": "1032",
    "name": "เขตประเวศ",
    "id": "5afae3c7ead44fb20f6c85ea",
    "name_EN": "Khet Prawet",
    "province_code": "10"
  },
  {
    "code": "1033",
    "name": "เขตคลองเตย",
    "id": "5afae3c7ead44fb20f6c85eb",
    "name_EN": "Khet Khlong Toei",
    "province_code": "10"
  },
  {
    "code": "1034",
    "name": "เขตสวนหลวง",
    "id": "5afae3c7ead44fb20f6c85ec",
    "name_EN": "Khet Suan Luang",
    "province_code": "10"
  },
  {
    "code": "1035",
    "name": "เขตจอมทอง",
    "id": "5afae3c7ead44fb20f6c85ed",
    "name_EN": "Khet Chom Thong",
    "province_code": "10"
  },
  {
    "code": "1036",
    "name": "เขตดอนเมือง",
    "id": "5afae3c7ead44fb20f6c85ee",
    "name_EN": "Khet Don Mueang",
    "province_code": "10"
  },
  {
    "code": "1037",
    "name": "เขตราชเทวี",
    "id": "5afae3c7ead44fb20f6c85ef",
    "name_EN": "Khet Ratchathewi",
    "province_code": "10"
  },
  {
    "code": "1038",
    "name": "เขตลาดพร้าว",
    "id": "5afae3c7ead44fb20f6c85f0",
    "name_EN": "Khet Lat Phrao",
    "province_code": "10"
  },
  {
    "code": "1039",
    "name": "เขตวัฒนา",
    "id": "5afae3c7ead44fb20f6c85f1",
    "name_EN": "Khet Watthana",
    "province_code": "10"
  },
  {
    "code": "1040",
    "name": "เขตบางแค",
    "id": "5afae3c7ead44fb20f6c85f2",
    "name_EN": "Khet Bang Khae",
    "province_code": "10"
  },
  {
    "code": "1041",
    "name": "เขตหลักสี่",
    "id": "5afae3c7ead44fb20f6c85f3",
    "name_EN": "Khet Lak Si",
    "province_code": "10"
  },
  {
    "code": "1042",
    "name": "เขตสายไหม",
    "id": "5afae3c7ead44fb20f6c85f4",
    "name_EN": "Khet Sai Mai",
    "province_code": "10"
  },
  {
    "code": "1043",
    "name": "เขตคันนายาว",
    "id": "5afae3c7ead44fb20f6c85f5",
    "name_EN": "Khet Khan Na Yao",
    "province_code": "10"
  },
  {
    "code": "1044",
    "name": "เขตสะพานสูง",
    "id": "5afae3c7ead44fb20f6c85f6",
    "name_EN": "Khet Saphan Sung",
    "province_code": "10"
  },
  {
    "code": "1045",
    "name": "เขตวังทองหลาง",
    "id": "5afae3c7ead44fb20f6c85f7",
    "name_EN": "Khet Wang Thonglang",
    "province_code": "10"
  },
  {
    "code": "1046",
    "name": "เขตคลองสามวา",
    "id": "5afae3c7ead44fb20f6c85f8",
    "name_EN": "Khet Khlong Sam Wa",
    "province_code": "10"
  },
  {
    "code": "1047",
    "name": "เขตบางนา",
    "id": "5afae3c7ead44fb20f6c85f9",
    "name_EN": "Khet Bang Na",
    "province_code": "10"
  },
  {
    "code": "1048",
    "name": "เขตทวีวัฒนา",
    "id": "5afae3c7ead44fb20f6c85fa",
    "name_EN": "Khet Thawi Watthana",
    "province_code": "10"
  },
  {
    "code": "1049",
    "name": "เขตทุ่งครุ",
    "id": "5afae3c7ead44fb20f6c85fb",
    "name_EN": "Khet Thung Khru",
    "province_code": "10"
  },
  {
    "code": "1050",
    "name": "เขตบางบอน",
    "id": "5afae3c7ead44fb20f6c85fc",
    "name_EN": "Khet Bang Bon",
    "province_code": "10"
  },
  {
    "code": "1081",
    "name": "*บ้านทะวาย",
    "id": "5afae3c7ead44fb20f6c85fd",
    "name_EN": "*Bantawai",
    "province_code": "10"
  },
  {
    "code": "1101",
    "name": "เมืองสมุทรปราการ",
    "id": "5afae3c7ead44fb20f6c85fe",
    "name_EN": "Mueang Samut Prakan",
    "province_code": "11"
  },
  {
    "code": "1102",
    "name": "บางบ่อ",
    "id": "5afae3c7ead44fb20f6c85ff",
    "name_EN": "Bang Bo",
    "province_code": "11"
  },
  {
    "code": "1103",
    "name": "บางพลี",
    "id": "5afae3c7ead44fb20f6c8600",
    "name_EN": "Bang Phli",
    "province_code": "11"
  },
  {
    "code": "1104",
    "name": "พระประแดง",
    "id": "5afae3c7ead44fb20f6c8601",
    "name_EN": "Phra Pradaeng",
    "province_code": "11"
  },
  {
    "code": "1105",
    "name": "พระสมุทรเจดีย์",
    "id": "5afae3c7ead44fb20f6c8602",
    "name_EN": "Phra Samut Chedi",
    "province_code": "11"
  },
  {
    "code": "1106",
    "name": "บางเสาธง",
    "id": "5afae3c7ead44fb20f6c8603",
    "name_EN": "Bang Sao Thong",
    "province_code": "11"
  },
  {
    "code": "1201",
    "name": "เมืองนนทบุรี",
    "id": "5afae3c7ead44fb20f6c8604",
    "name_EN": "Mueang Nonthaburi",
    "province_code": "12"
  },
  {
    "code": "1202",
    "name": "บางกรวย",
    "id": "5afae3c7ead44fb20f6c8605",
    "name_EN": "Bang Kruai",
    "province_code": "12"
  },
  {
    "code": "1203",
    "name": "บางใหญ่",
    "id": "5afae3c7ead44fb20f6c8606",
    "name_EN": "Bang Yai",
    "province_code": "12"
  },
  {
    "code": "1204",
    "name": "บางบัวทอง",
    "id": "5afae3c7ead44fb20f6c8607",
    "name_EN": "Bang Bua Thong",
    "province_code": "12"
  },
  {
    "code": "1205",
    "name": "ไทรน้อย",
    "id": "5afae3c7ead44fb20f6c8608",
    "name_EN": "Sai Noi",
    "province_code": "12"
  },
  {
    "code": "1206",
    "name": "ปากเกร็ด",
    "id": "5afae3c7ead44fb20f6c8609",
    "name_EN": "Pak Kret",
    "province_code": "12"
  },
  {
    "code": "1251",
    "name": "เทศบาลนครนนทบุรี (สาขาแขวงท่าทราย)*",
    "id": "5afae3c7ead44fb20f6c860a",
    "name_EN": "Tetsaban Nonthaburi",
    "province_code": "12"
  },
  {
    "code": "1297",
    "name": "เทศบาลเมืองปากเกร็ด*",
    "id": "5afae3c7ead44fb20f6c860b",
    "name_EN": "Tetsaban Pak Kret",
    "province_code": "12"
  },
  {
    "code": "1301",
    "name": "เมืองปทุมธานี",
    "id": "5afae3c7ead44fb20f6c860c",
    "name_EN": "Mueang Pathum Thani",
    "province_code": "13"
  },
  {
    "code": "1302",
    "name": "คลองหลวง",
    "id": "5afae3c7ead44fb20f6c860d",
    "name_EN": "Khlong Luang",
    "province_code": "13"
  },
  {
    "code": "1303",
    "name": "ธัญบุรี",
    "id": "5afae3c7ead44fb20f6c860e",
    "name_EN": "Thanyaburi",
    "province_code": "13"
  },
  {
    "code": "1304",
    "name": "หนองเสือ",
    "id": "5afae3c7ead44fb20f6c860f",
    "name_EN": "Nong Suea",
    "province_code": "13"
  },
  {
    "code": "1305",
    "name": "ลาดหลุมแก้ว",
    "id": "5afae3c7ead44fb20f6c8610",
    "name_EN": "Lat Lum Kaeo",
    "province_code": "13"
  },
  {
    "code": "1306",
    "name": "ลำลูกกา",
    "id": "5afae3c7ead44fb20f6c8611",
    "name_EN": "Lam Luk Ka",
    "province_code": "13"
  },
  {
    "code": "1307",
    "name": "สามโคก",
    "id": "5afae3c7ead44fb20f6c8612",
    "name_EN": "Sam Khok",
    "province_code": "13"
  },
  {
    "code": "1351",
    "name": "ลำลูกกา (สาขาตำบลคูคต)*",
    "id": "5afae3c7ead44fb20f6c8613",
    "name_EN": "Khlong Luang(Kukod)",
    "province_code": "13"
  },
  {
    "code": "1401",
    "name": "พระนครศรีอยุธยา",
    "id": "5afae3c7ead44fb20f6c8614",
    "name_EN": "Phra Nakhon Si Ayutthaya",
    "province_code": "14"
  },
  {
    "code": "1402",
    "name": "ท่าเรือ",
    "id": "5afae3c7ead44fb20f6c8615",
    "name_EN": "Tha Ruea",
    "province_code": "14"
  },
  {
    "code": "1403",
    "name": "นครหลวง",
    "id": "5afae3c7ead44fb20f6c8616",
    "name_EN": "Nakhon Luang",
    "province_code": "14"
  },
  {
    "code": "1404",
    "name": "บางไทร",
    "id": "5afae3c7ead44fb20f6c8617",
    "name_EN": "Bang Sai",
    "province_code": "14"
  },
  {
    "code": "1405",
    "name": "บางบาล",
    "id": "5afae3c7ead44fb20f6c8618",
    "name_EN": "Bang Ban",
    "province_code": "14"
  },
  {
    "code": "1406",
    "name": "บางปะอิน",
    "id": "5afae3c7ead44fb20f6c8619",
    "name_EN": "Bang Pa-in",
    "province_code": "14"
  },
  {
    "code": "1407",
    "name": "บางปะหัน",
    "id": "5afae3c7ead44fb20f6c861a",
    "name_EN": "Bang Pahan",
    "province_code": "14"
  },
  {
    "code": "1408",
    "name": "ผักไห่",
    "id": "5afae3c7ead44fb20f6c861b",
    "name_EN": "Phak Hai",
    "province_code": "14"
  },
  {
    "code": "1409",
    "name": "ภาชี",
    "id": "5afae3c7ead44fb20f6c861c",
    "name_EN": "Phachi",
    "province_code": "14"
  },
  {
    "code": "1410",
    "name": "ลาดบัวหลวง",
    "id": "5afae3c7ead44fb20f6c861d",
    "name_EN": "Lat Bua Luang",
    "province_code": "14"
  },
  {
    "code": "1411",
    "name": "วังน้อย",
    "id": "5afae3c7ead44fb20f6c861e",
    "name_EN": "Wang Noi",
    "province_code": "14"
  },
  {
    "code": "1412",
    "name": "เสนา",
    "id": "5afae3c7ead44fb20f6c861f",
    "name_EN": "Sena",
    "province_code": "14"
  },
  {
    "code": "1413",
    "name": "บางซ้าย",
    "id": "5afae3c7ead44fb20f6c8620",
    "name_EN": "Bang Sai",
    "province_code": "14"
  },
  {
    "code": "1414",
    "name": "อุทัย",
    "id": "5afae3c7ead44fb20f6c8621",
    "name_EN": "Uthai",
    "province_code": "14"
  },
  {
    "code": "1415",
    "name": "มหาราช",
    "id": "5afae3c7ead44fb20f6c8622",
    "name_EN": "Maha Rat",
    "province_code": "14"
  },
  {
    "code": "1416",
    "name": "บ้านแพรก",
    "id": "5afae3c7ead44fb20f6c8623",
    "name_EN": "Ban Phraek",
    "province_code": "14"
  },
  {
    "code": "1501",
    "name": "เมืองอ่างทอง",
    "id": "5afae3c7ead44fb20f6c8624",
    "name_EN": "Mueang Ang Thong",
    "province_code": "15"
  },
  {
    "code": "1502",
    "name": "ไชโย",
    "id": "5afae3c7ead44fb20f6c8625",
    "name_EN": "Chaiyo",
    "province_code": "15"
  },
  {
    "code": "1503",
    "name": "ป่าโมก",
    "id": "5afae3c7ead44fb20f6c8626",
    "name_EN": "Pa Mok",
    "province_code": "15"
  },
  {
    "code": "1504",
    "name": "โพธิ์ทอง",
    "id": "5afae3c7ead44fb20f6c8627",
    "name_EN": "Pho Thong",
    "province_code": "15"
  },
  {
    "code": "1505",
    "name": "แสวงหา",
    "id": "5afae3c7ead44fb20f6c8628",
    "name_EN": "Sawaeng Ha",
    "province_code": "15"
  },
  {
    "code": "1506",
    "name": "วิเศษชัยชาญ",
    "id": "5afae3c7ead44fb20f6c8629",
    "name_EN": "Wiset Chai Chan",
    "province_code": "15"
  },
  {
    "code": "1507",
    "name": "สามโก้",
    "id": "5afae3c7ead44fb20f6c862a",
    "name_EN": "Samko",
    "province_code": "15"
  },
  {
    "code": "1601",
    "name": "เมืองลพบุรี",
    "id": "5afae3c7ead44fb20f6c862b",
    "name_EN": "Mueang Lop Buri",
    "province_code": "16"
  },
  {
    "code": "1602",
    "name": "พัฒนานิคม",
    "id": "5afae3c7ead44fb20f6c862c",
    "name_EN": "Phatthana Nikhom",
    "province_code": "16"
  },
  {
    "code": "1603",
    "name": "โคกสำโรง",
    "id": "5afae3c7ead44fb20f6c862d",
    "name_EN": "Khok Samrong",
    "province_code": "16"
  },
  {
    "code": "1604",
    "name": "ชัยบาดาล",
    "id": "5afae3c7ead44fb20f6c862e",
    "name_EN": "Chai Badan",
    "province_code": "16"
  },
  {
    "code": "1605",
    "name": "ท่าวุ้ง",
    "id": "5afae3c7ead44fb20f6c862f",
    "name_EN": "Tha Wung",
    "province_code": "16"
  },
  {
    "code": "1606",
    "name": "บ้านหมี่",
    "id": "5afae3c7ead44fb20f6c8630",
    "name_EN": "Ban Mi",
    "province_code": "16"
  },
  {
    "code": "1607",
    "name": "ท่าหลวง",
    "id": "5afae3c7ead44fb20f6c8631",
    "name_EN": "Tha Luang",
    "province_code": "16"
  },
  {
    "code": "1608",
    "name": "สระโบสถ์",
    "id": "5afae3c7ead44fb20f6c8632",
    "name_EN": "Sa Bot",
    "province_code": "16"
  },
  {
    "code": "1609",
    "name": "โคกเจริญ",
    "id": "5afae3c7ead44fb20f6c8633",
    "name_EN": "Khok Charoen",
    "province_code": "16"
  },
  {
    "code": "1610",
    "name": "ลำสนธิ",
    "id": "5afae3c7ead44fb20f6c8634",
    "name_EN": "Lam Sonthi",
    "province_code": "16"
  },
  {
    "code": "1611",
    "name": "หนองม่วง",
    "id": "5afae3c7ead44fb20f6c8635",
    "name_EN": "Nong Muang",
    "province_code": "16"
  },
  {
    "code": "1681",
    "name": "*อ.บ้านเช่า  จ.ลพบุรี",
    "id": "5afae3c7ead44fb20f6c8636",
    "name_EN": "*Amphoe Ban Chao",
    "province_code": "16"
  },
  {
    "code": "1701",
    "name": "เมืองสิงห์บุรี",
    "id": "5afae3c7ead44fb20f6c8637",
    "name_EN": "Mueang Sing Buri",
    "province_code": "17"
  },
  {
    "code": "1702",
    "name": "บางระจัน",
    "id": "5afae3c7ead44fb20f6c8638",
    "name_EN": "Bang Rachan",
    "province_code": "17"
  },
  {
    "code": "1703",
    "name": "ค่ายบางระจัน",
    "id": "5afae3c7ead44fb20f6c8639",
    "name_EN": "Khai Bang Rachan",
    "province_code": "17"
  },
  {
    "code": "1704",
    "name": "พรหมบุรี",
    "id": "5afae3c7ead44fb20f6c863a",
    "name_EN": "Phrom Buri",
    "province_code": "17"
  },
  {
    "code": "1705",
    "name": "ท่าช้าง",
    "id": "5afae3c7ead44fb20f6c863b",
    "name_EN": "Tha Chang",
    "province_code": "17"
  },
  {
    "code": "1706",
    "name": "อินทร์บุรี",
    "id": "5afae3c7ead44fb20f6c863c",
    "name_EN": "In Buri",
    "province_code": "17"
  },
  {
    "code": "1801",
    "name": "เมืองชัยนาท",
    "id": "5afae3c7ead44fb20f6c863d",
    "name_EN": "Mueang Chai Nat",
    "province_code": "18"
  },
  {
    "code": "1802",
    "name": "มโนรมย์",
    "id": "5afae3c7ead44fb20f6c863e",
    "name_EN": "Manorom",
    "province_code": "18"
  },
  {
    "code": "1803",
    "name": "วัดสิงห์",
    "id": "5afae3c7ead44fb20f6c863f",
    "name_EN": "Wat Sing",
    "province_code": "18"
  },
  {
    "code": "1804",
    "name": "สรรพยา",
    "id": "5afae3c7ead44fb20f6c8640",
    "name_EN": "Sapphaya",
    "province_code": "18"
  },
  {
    "code": "1805",
    "name": "สรรคบุรี",
    "id": "5afae3c7ead44fb20f6c8641",
    "name_EN": "Sankhaburi",
    "province_code": "18"
  },
  {
    "code": "1806",
    "name": "หันคา",
    "id": "5afae3c7ead44fb20f6c8642",
    "name_EN": "Hankha",
    "province_code": "18"
  },
  {
    "code": "1807",
    "name": "หนองมะโมง",
    "id": "5afae3c7ead44fb20f6c8643",
    "name_EN": "Nong Mamong",
    "province_code": "18"
  },
  {
    "code": "1808",
    "name": "เนินขาม",
    "id": "5afae3c7ead44fb20f6c8644",
    "name_EN": "Noen Kham",
    "province_code": "18"
  },
  {
    "code": "1901",
    "name": "เมืองสระบุรี",
    "id": "5afae3c7ead44fb20f6c8645",
    "name_EN": "Mueang Saraburi",
    "province_code": "19"
  },
  {
    "code": "1902",
    "name": "แก่งคอย",
    "id": "5afae3c7ead44fb20f6c8646",
    "name_EN": "Kaeng Khoi",
    "province_code": "19"
  },
  {
    "code": "1903",
    "name": "หนองแค",
    "id": "5afae3c7ead44fb20f6c8647",
    "name_EN": "Nong Khae",
    "province_code": "19"
  },
  {
    "code": "1904",
    "name": "วิหารแดง",
    "id": "5afae3c7ead44fb20f6c8648",
    "name_EN": "Wihan Daeng",
    "province_code": "19"
  },
  {
    "code": "1905",
    "name": "หนองแซง",
    "id": "5afae3c7ead44fb20f6c8649",
    "name_EN": "Nong Saeng",
    "province_code": "19"
  },
  {
    "code": "1906",
    "name": "บ้านหมอ",
    "id": "5afae3c7ead44fb20f6c864a",
    "name_EN": "Ban Mo",
    "province_code": "19"
  },
  {
    "code": "1907",
    "name": "ดอนพุด",
    "id": "5afae3c7ead44fb20f6c864b",
    "name_EN": "Don Phut",
    "province_code": "19"
  },
  {
    "code": "1908",
    "name": "หนองโดน",
    "id": "5afae3c7ead44fb20f6c864c",
    "name_EN": "Nong Don",
    "province_code": "19"
  },
  {
    "code": "1909",
    "name": "พระพุทธบาท",
    "id": "5afae3c7ead44fb20f6c864d",
    "name_EN": "Phra Phutthabat",
    "province_code": "19"
  },
  {
    "code": "1910",
    "name": "เสาไห้",
    "id": "5afae3c7ead44fb20f6c864e",
    "name_EN": "Sao Hai",
    "province_code": "19"
  },
  {
    "code": "1911",
    "name": "มวกเหล็ก",
    "id": "5afae3c7ead44fb20f6c864f",
    "name_EN": "Muak Lek",
    "province_code": "19"
  },
  {
    "code": "1912",
    "name": "วังม่วง",
    "id": "5afae3c7ead44fb20f6c8650",
    "name_EN": "Wang Muang",
    "province_code": "19"
  },
  {
    "code": "1913",
    "name": "เฉลิมพระเกียรติ",
    "id": "5afae3c7ead44fb20f6c8651",
    "name_EN": "Chaloem Phra Kiat",
    "province_code": "19"
  },
  {
    "code": "2001",
    "name": "เมืองชลบุรี",
    "id": "5afae3c7ead44fb20f6c8652",
    "name_EN": "Mueang Chon Buri",
    "province_code": "20"
  },
  {
    "code": "2002",
    "name": "บ้านบึง",
    "id": "5afae3c7ead44fb20f6c8653",
    "name_EN": "Ban Bueng",
    "province_code": "20"
  },
  {
    "code": "2003",
    "name": "หนองใหญ่",
    "id": "5afae3c7ead44fb20f6c8654",
    "name_EN": "Nong Yai",
    "province_code": "20"
  },
  {
    "code": "2004",
    "name": "บางละมุง",
    "id": "5afae3c7ead44fb20f6c8655",
    "name_EN": "Bang Lamung",
    "province_code": "20"
  },
  {
    "code": "2005",
    "name": "พานทอง",
    "id": "5afae3c7ead44fb20f6c8656",
    "name_EN": "Phan Thong",
    "province_code": "20"
  },
  {
    "code": "2006",
    "name": "พนัสนิคม",
    "id": "5afae3c7ead44fb20f6c8657",
    "name_EN": "Phanat Nikhom",
    "province_code": "20"
  },
  {
    "code": "2007",
    "name": "ศรีราชา",
    "id": "5afae3c7ead44fb20f6c8658",
    "name_EN": "Si Racha",
    "province_code": "20"
  },
  {
    "code": "2008",
    "name": "เกาะสีชัง",
    "id": "5afae3c7ead44fb20f6c8659",
    "name_EN": "Ko Sichang",
    "province_code": "20"
  },
  {
    "code": "2009",
    "name": "สัตหีบ",
    "id": "5afae3c7ead44fb20f6c865a",
    "name_EN": "Sattahip",
    "province_code": "20"
  },
  {
    "code": "2010",
    "name": "บ่อทอง",
    "id": "5afae3c7ead44fb20f6c865b",
    "name_EN": "Bo Thong",
    "province_code": "20"
  },
  {
    "code": "2011",
    "name": "เกาะจันทร์",
    "id": "5afae3c7ead44fb20f6c865c",
    "name_EN": "Ko Chan",
    "province_code": "20"
  },
  {
    "code": "2051",
    "name": "สัตหีบ (สาขาตำบลบางเสร่)*",
    "id": "5afae3c7ead44fb20f6c865d",
    "name_EN": "Sattahip(Bang Sre)*",
    "province_code": "20"
  },
  {
    "code": "2072",
    "name": "ท้องถิ่นเทศบาลเมืองหนองปรือ*",
    "id": "5afae3c7ead44fb20f6c865e",
    "name_EN": "Tong Tin Tetsaban Mueang Nong Prue*",
    "province_code": "20"
  },
  {
    "code": "2093",
    "name": "เทศบาลตำบลแหลมฉบัง*",
    "id": "5afae3c7ead44fb20f6c865f",
    "name_EN": "Tetsaban Tambon Lamsabang*",
    "province_code": "20"
  },
  {
    "code": "2099",
    "name": "เทศบาลเมืองชลบุรี*",
    "id": "5afae3c7ead44fb20f6c8660",
    "name_EN": "Mueang Chon Buri",
    "province_code": "20"
  },
  {
    "code": "2101",
    "name": "เมืองระยอง",
    "id": "5afae3c7ead44fb20f6c8661",
    "name_EN": "Mueang Rayong",
    "province_code": "21"
  },
  {
    "code": "2102",
    "name": "บ้านฉาง",
    "id": "5afae3c7ead44fb20f6c8662",
    "name_EN": "Ban Chang",
    "province_code": "21"
  },
  {
    "code": "2103",
    "name": "แกลง",
    "id": "5afae3c7ead44fb20f6c8663",
    "name_EN": "Klaeng",
    "province_code": "21"
  },
  {
    "code": "2104",
    "name": "วังจันทร์",
    "id": "5afae3c7ead44fb20f6c8664",
    "name_EN": "Wang Chan",
    "province_code": "21"
  },
  {
    "code": "2105",
    "name": "บ้านค่าย",
    "id": "5afae3c7ead44fb20f6c8665",
    "name_EN": "Ban Khai",
    "province_code": "21"
  },
  {
    "code": "2106",
    "name": "ปลวกแดง",
    "id": "5afae3c7ead44fb20f6c8666",
    "name_EN": "Pluak Daeng",
    "province_code": "21"
  },
  {
    "code": "2107",
    "name": "เขาชะเมา",
    "id": "5afae3c7ead44fb20f6c8667",
    "name_EN": "Khao Chamao",
    "province_code": "21"
  },
  {
    "code": "2108",
    "name": "นิคมพัฒนา",
    "id": "5afae3c7ead44fb20f6c8668",
    "name_EN": "Nikhom Phatthana",
    "province_code": "21"
  },
  {
    "code": "2151",
    "name": "สาขาตำบลมาบข่า*",
    "id": "5afae3c7ead44fb20f6c8669",
    "name_EN": "Saka Tambon Mabka",
    "province_code": "21"
  },
  {
    "code": "2201",
    "name": "เมืองจันทบุรี",
    "id": "5afae3c7ead44fb20f6c866a",
    "name_EN": "Mueang Chanthaburi",
    "province_code": "22"
  },
  {
    "code": "2202",
    "name": "ขลุง",
    "id": "5afae3c7ead44fb20f6c866b",
    "name_EN": "Khlung",
    "province_code": "22"
  },
  {
    "code": "2203",
    "name": "ท่าใหม่",
    "id": "5afae3c7ead44fb20f6c866c",
    "name_EN": "Tha Mai",
    "province_code": "22"
  },
  {
    "code": "2204",
    "name": "โป่งน้ำร้อน",
    "id": "5afae3c7ead44fb20f6c866d",
    "name_EN": "Pong Nam Ron",
    "province_code": "22"
  },
  {
    "code": "2205",
    "name": "มะขาม",
    "id": "5afae3c7ead44fb20f6c866e",
    "name_EN": "Makham",
    "province_code": "22"
  },
  {
    "code": "2206",
    "name": "แหลมสิงห์",
    "id": "5afae3c7ead44fb20f6c866f",
    "name_EN": "Laem Sing",
    "province_code": "22"
  },
  {
    "code": "2207",
    "name": "สอยดาว",
    "id": "5afae3c7ead44fb20f6c8670",
    "name_EN": "Soi Dao",
    "province_code": "22"
  },
  {
    "code": "2208",
    "name": "แก่งหางแมว",
    "id": "5afae3c7ead44fb20f6c8671",
    "name_EN": "Kaeng Hang Maeo",
    "province_code": "22"
  },
  {
    "code": "2209",
    "name": "นายายอาม",
    "id": "5afae3c7ead44fb20f6c8672",
    "name_EN": "Na Yai Am",
    "province_code": "22"
  },
  {
    "code": "2210",
    "name": "เขาคิชฌกูฏ",
    "id": "5afae3c7ead44fb20f6c8673",
    "name_EN": "Khoa Khitchakut",
    "province_code": "22"
  },
  {
    "code": "2281",
    "name": "*กิ่ง อ.กำพุธ  จ.จันทบุรี",
    "id": "5afae3c7ead44fb20f6c8674",
    "name_EN": "*King Amphoe Kampud",
    "province_code": "22"
  },
  {
    "code": "2301",
    "name": "เมืองตราด",
    "id": "5afae3c7ead44fb20f6c8675",
    "name_EN": "Mueang Trat",
    "province_code": "23"
  },
  {
    "code": "2302",
    "name": "คลองใหญ่",
    "id": "5afae3c7ead44fb20f6c8676",
    "name_EN": "Khlong Yai",
    "province_code": "23"
  },
  {
    "code": "2303",
    "name": "เขาสมิง",
    "id": "5afae3c7ead44fb20f6c8677",
    "name_EN": "Khao Saming",
    "province_code": "23"
  },
  {
    "code": "2304",
    "name": "บ่อไร่",
    "id": "5afae3c7ead44fb20f6c8678",
    "name_EN": "Bo Rai",
    "province_code": "23"
  },
  {
    "code": "2305",
    "name": "แหลมงอบ",
    "id": "5afae3c7ead44fb20f6c8679",
    "name_EN": "Laem Ngop",
    "province_code": "23"
  },
  {
    "code": "2306",
    "name": "เกาะกูด",
    "id": "5afae3c7ead44fb20f6c867a",
    "name_EN": "Ko Kut",
    "province_code": "23"
  },
  {
    "code": "2307",
    "name": "เกาะช้าง",
    "id": "5afae3c7ead44fb20f6c867b",
    "name_EN": "Ko Chang",
    "province_code": "23"
  },
  {
    "code": "2401",
    "name": "เมืองฉะเชิงเทรา",
    "id": "5afae3c7ead44fb20f6c867c",
    "name_EN": "Mueang Chachoengsao",
    "province_code": "24"
  },
  {
    "code": "2402",
    "name": "บางคล้า",
    "id": "5afae3c7ead44fb20f6c867d",
    "name_EN": "Bang Khla",
    "province_code": "24"
  },
  {
    "code": "2403",
    "name": "บางน้ำเปรี้ยว",
    "id": "5afae3c7ead44fb20f6c867e",
    "name_EN": "Bang Nam Priao",
    "province_code": "24"
  },
  {
    "code": "2404",
    "name": "บางปะกง",
    "id": "5afae3c7ead44fb20f6c867f",
    "name_EN": "Bang Pakong",
    "province_code": "24"
  },
  {
    "code": "2405",
    "name": "บ้านโพธิ์",
    "id": "5afae3c7ead44fb20f6c8680",
    "name_EN": "Ban Pho",
    "province_code": "24"
  },
  {
    "code": "2406",
    "name": "พนมสารคาม",
    "id": "5afae3c7ead44fb20f6c8681",
    "name_EN": "Phanom Sarakham",
    "province_code": "24"
  },
  {
    "code": "2407",
    "name": "ราชสาส์น",
    "id": "5afae3c7ead44fb20f6c8682",
    "name_EN": "Ratchasan",
    "province_code": "24"
  },
  {
    "code": "2408",
    "name": "สนามชัยเขต",
    "id": "5afae3c7ead44fb20f6c8683",
    "name_EN": "Sanam Chai Khet",
    "province_code": "24"
  },
  {
    "code": "2409",
    "name": "แปลงยาว",
    "id": "5afae3c7ead44fb20f6c8684",
    "name_EN": "Plaeng Yao",
    "province_code": "24"
  },
  {
    "code": "2410",
    "name": "ท่าตะเกียบ",
    "id": "5afae3c7ead44fb20f6c8685",
    "name_EN": "Tha Takiap",
    "province_code": "24"
  },
  {
    "code": "2411",
    "name": "คลองเขื่อน",
    "id": "5afae3c7ead44fb20f6c8686",
    "name_EN": "Khlong Khuean",
    "province_code": "24"
  },
  {
    "code": "2501",
    "name": "เมืองปราจีนบุรี",
    "id": "5afae3c7ead44fb20f6c8687",
    "name_EN": "Mueang Prachin Buri",
    "province_code": "25"
  },
  {
    "code": "2502",
    "name": "กบินทร์บุรี",
    "id": "5afae3c7ead44fb20f6c8688",
    "name_EN": "Kabin Buri",
    "province_code": "25"
  },
  {
    "code": "2503",
    "name": "นาดี",
    "id": "5afae3c7ead44fb20f6c8689",
    "name_EN": "Na Di",
    "province_code": "25"
  },
  {
    "code": "2504",
    "name": "*สระแก้ว",
    "id": "5afae3c7ead44fb20f6c868a",
    "name_EN": "Sa Kaeo",
    "province_code": "25"
  },
  {
    "code": "2505",
    "name": "*วังน้ำเย็น",
    "id": "5afae3c7ead44fb20f6c868b",
    "name_EN": "Wang Nam Yen",
    "province_code": "25"
  },
  {
    "code": "2506",
    "name": "บ้านสร้าง",
    "id": "5afae3c7ead44fb20f6c868c",
    "name_EN": "Ban Sang",
    "province_code": "25"
  },
  {
    "code": "2507",
    "name": "ประจันตคาม",
    "id": "5afae3c7ead44fb20f6c868d",
    "name_EN": "Prachantakham",
    "province_code": "25"
  },
  {
    "code": "2508",
    "name": "ศรีมหาโพธิ",
    "id": "5afae3c7ead44fb20f6c868e",
    "name_EN": "Si Maha Phot",
    "province_code": "25"
  },
  {
    "code": "2509",
    "name": "ศรีมโหสถ",
    "id": "5afae3c7ead44fb20f6c868f",
    "name_EN": "Si Mahosot",
    "province_code": "25"
  },
  {
    "code": "2510",
    "name": "*อรัญประเทศ",
    "id": "5afae3c7ead44fb20f6c8690",
    "name_EN": "Aranyaprathet",
    "province_code": "25"
  },
  {
    "code": "2511",
    "name": "*ตาพระยา",
    "id": "5afae3c7ead44fb20f6c8691",
    "name_EN": "Ta Phraya",
    "province_code": "25"
  },
  {
    "code": "2512",
    "name": "*วัฒนานคร",
    "id": "5afae3c7ead44fb20f6c8692",
    "name_EN": "Watthana Nakhon",
    "province_code": "25"
  },
  {
    "code": "2513",
    "name": "*คลองหาด",
    "id": "5afae3c7ead44fb20f6c8693",
    "name_EN": "Khlong Hat",
    "province_code": "25"
  },
  {
    "code": "2601",
    "name": "เมืองนครนายก",
    "id": "5afae3c7ead44fb20f6c8694",
    "name_EN": "Mueang Nakhon Nayok",
    "province_code": "26"
  },
  {
    "code": "2602",
    "name": "ปากพลี",
    "id": "5afae3c7ead44fb20f6c8695",
    "name_EN": "Pak Phli",
    "province_code": "26"
  },
  {
    "code": "2603",
    "name": "บ้านนา",
    "id": "5afae3c7ead44fb20f6c8696",
    "name_EN": "Ban Na",
    "province_code": "26"
  },
  {
    "code": "2604",
    "name": "องครักษ์",
    "id": "5afae3c7ead44fb20f6c8697",
    "name_EN": "Ongkharak",
    "province_code": "26"
  },
  {
    "code": "2701",
    "name": "เมืองสระแก้ว",
    "id": "5afae3c7ead44fb20f6c8698",
    "name_EN": "Mueang Sa Kaeo",
    "province_code": "27"
  },
  {
    "code": "2702",
    "name": "คลองหาด",
    "id": "5afae3c7ead44fb20f6c8699",
    "name_EN": "Khlong Hat",
    "province_code": "27"
  },
  {
    "code": "2703",
    "name": "ตาพระยา",
    "id": "5afae3c7ead44fb20f6c869a",
    "name_EN": "Ta Phraya",
    "province_code": "27"
  },
  {
    "code": "2704",
    "name": "วังน้ำเย็น",
    "id": "5afae3c7ead44fb20f6c869b",
    "name_EN": "Wang Nam Yen",
    "province_code": "27"
  },
  {
    "code": "2705",
    "name": "วัฒนานคร",
    "id": "5afae3c7ead44fb20f6c869c",
    "name_EN": "Watthana Nakhon",
    "province_code": "27"
  },
  {
    "code": "2706",
    "name": "อรัญประเทศ",
    "id": "5afae3c7ead44fb20f6c869d",
    "name_EN": "Aranyaprathet",
    "province_code": "27"
  },
  {
    "code": "2707",
    "name": "เขาฉกรรจ์",
    "id": "5afae3c7ead44fb20f6c869e",
    "name_EN": "Khao Chakan",
    "province_code": "27"
  },
  {
    "code": "2708",
    "name": "โคกสูง",
    "id": "5afae3c7ead44fb20f6c869f",
    "name_EN": "Khok Sung",
    "province_code": "27"
  },
  {
    "code": "2709",
    "name": "วังสมบูรณ์",
    "id": "5afae3c7ead44fb20f6c86a0",
    "name_EN": "Wang Sombun",
    "province_code": "27"
  },
  {
    "code": "3001",
    "name": "เมืองนครราชสีมา",
    "id": "5afae3c7ead44fb20f6c86a1",
    "name_EN": "Mueang Nakhon Ratchasima",
    "province_code": "30"
  },
  {
    "code": "3002",
    "name": "ครบุรี",
    "id": "5afae3c7ead44fb20f6c86a2",
    "name_EN": "Khon Buri",
    "province_code": "30"
  },
  {
    "code": "3003",
    "name": "เสิงสาง",
    "id": "5afae3c7ead44fb20f6c86a3",
    "name_EN": "Soeng Sang",
    "province_code": "30"
  },
  {
    "code": "3004",
    "name": "คง",
    "id": "5afae3c7ead44fb20f6c86a4",
    "name_EN": "Khong",
    "province_code": "30"
  },
  {
    "code": "3005",
    "name": "บ้านเหลื่อม",
    "id": "5afae3c7ead44fb20f6c86a5",
    "name_EN": "Ban Lueam",
    "province_code": "30"
  },
  {
    "code": "3006",
    "name": "จักราช",
    "id": "5afae3c7ead44fb20f6c86a6",
    "name_EN": "Chakkarat",
    "province_code": "30"
  },
  {
    "code": "3007",
    "name": "โชคชัย",
    "id": "5afae3c7ead44fb20f6c86a7",
    "name_EN": "Chok Chai",
    "province_code": "30"
  },
  {
    "code": "3008",
    "name": "ด่านขุนทด",
    "id": "5afae3c7ead44fb20f6c86a8",
    "name_EN": "Dan Khun Thot",
    "province_code": "30"
  },
  {
    "code": "3009",
    "name": "โนนไทย",
    "id": "5afae3c7ead44fb20f6c86a9",
    "name_EN": "Non Thai",
    "province_code": "30"
  },
  {
    "code": "3010",
    "name": "โนนสูง",
    "id": "5afae3c7ead44fb20f6c86aa",
    "name_EN": "Non Sung",
    "province_code": "30"
  },
  {
    "code": "3011",
    "name": "ขามสะแกแสง",
    "id": "5afae3c7ead44fb20f6c86ab",
    "name_EN": "Kham Sakaesaeng",
    "province_code": "30"
  },
  {
    "code": "3012",
    "name": "บัวใหญ่",
    "id": "5afae3c7ead44fb20f6c86ac",
    "name_EN": "Bua Yai",
    "province_code": "30"
  },
  {
    "code": "3013",
    "name": "ประทาย",
    "id": "5afae3c7ead44fb20f6c86ad",
    "name_EN": "Prathai",
    "province_code": "30"
  },
  {
    "code": "3014",
    "name": "ปักธงชัย",
    "id": "5afae3c7ead44fb20f6c86ae",
    "name_EN": "Pak Thong Chai",
    "province_code": "30"
  },
  {
    "code": "3015",
    "name": "พิมาย",
    "id": "5afae3c7ead44fb20f6c86af",
    "name_EN": "Phimai",
    "province_code": "30"
  },
  {
    "code": "3016",
    "name": "ห้วยแถลง",
    "id": "5afae3c7ead44fb20f6c86b0",
    "name_EN": "Huai Thalaeng",
    "province_code": "30"
  },
  {
    "code": "3017",
    "name": "ชุมพวง",
    "id": "5afae3c7ead44fb20f6c86b1",
    "name_EN": "Chum Phuang",
    "province_code": "30"
  },
  {
    "code": "3018",
    "name": "สูงเนิน",
    "id": "5afae3c7ead44fb20f6c86b2",
    "name_EN": "Sung Noen",
    "province_code": "30"
  },
  {
    "code": "3019",
    "name": "ขามทะเลสอ",
    "id": "5afae3c7ead44fb20f6c86b3",
    "name_EN": "Kham Thale So",
    "province_code": "30"
  },
  {
    "code": "3020",
    "name": "สีคิ้ว",
    "id": "5afae3c7ead44fb20f6c86b4",
    "name_EN": "Sikhio",
    "province_code": "30"
  },
  {
    "code": "3021",
    "name": "ปากช่อง",
    "id": "5afae3c7ead44fb20f6c86b5",
    "name_EN": "Pak Chong",
    "province_code": "30"
  },
  {
    "code": "3022",
    "name": "หนองบุญมาก",
    "id": "5afae3c7ead44fb20f6c86b6",
    "name_EN": "Nong Bunnak",
    "province_code": "30"
  },
  {
    "code": "3023",
    "name": "แก้งสนามนาง",
    "id": "5afae3c7ead44fb20f6c86b7",
    "name_EN": "Kaeng Sanam Nang",
    "province_code": "30"
  },
  {
    "code": "3024",
    "name": "โนนแดง",
    "id": "5afae3c7ead44fb20f6c86b8",
    "name_EN": "Non Daeng",
    "province_code": "30"
  },
  {
    "code": "3025",
    "name": "วังน้ำเขียว",
    "id": "5afae3c7ead44fb20f6c86b9",
    "name_EN": "Wang Nam Khiao",
    "province_code": "30"
  },
  {
    "code": "3026",
    "name": "เทพารักษ์",
    "id": "5afae3c7ead44fb20f6c86ba",
    "name_EN": "Thepharak",
    "province_code": "30"
  },
  {
    "code": "3027",
    "name": "เมืองยาง",
    "id": "5afae3c7ead44fb20f6c86bb",
    "name_EN": "Mueang Yang",
    "province_code": "30"
  },
  {
    "code": "3028",
    "name": "พระทองคำ",
    "id": "5afae3c7ead44fb20f6c86bc",
    "name_EN": "Phra Thong Kham",
    "province_code": "30"
  },
  {
    "code": "3029",
    "name": "ลำทะเมนชัย",
    "id": "5afae3c7ead44fb20f6c86bd",
    "name_EN": "Lam Thamenchai",
    "province_code": "30"
  },
  {
    "code": "3030",
    "name": "บัวลาย",
    "id": "5afae3c7ead44fb20f6c86be",
    "name_EN": "Bua Lai",
    "province_code": "30"
  },
  {
    "code": "3031",
    "name": "สีดา",
    "id": "5afae3c7ead44fb20f6c86bf",
    "name_EN": "Sida",
    "province_code": "30"
  },
  {
    "code": "3032",
    "name": "เฉลิมพระเกียรติ",
    "id": "5afae3c7ead44fb20f6c86c0",
    "name_EN": "Chaloem Phra Kiat",
    "province_code": "30"
  },
  {
    "code": "3049",
    "name": "ท้องถิ่นเทศบาลตำบลโพธิ์กลาง*",
    "id": "5afae3c7ead44fb20f6c86c1",
    "name_EN": "Pho Krang",
    "province_code": "30"
  },
  {
    "code": "3051",
    "name": "สาขาตำบลมะค่า-พลสงคราม*",
    "id": "5afae3c7ead44fb20f6c86c2",
    "name_EN": "Ma Ka-Pon Songkram*",
    "province_code": "30"
  },
  {
    "code": "3081",
    "name": "*โนนลาว",
    "id": "5afae3c7ead44fb20f6c86c3",
    "name_EN": "*Non Lao",
    "province_code": "30"
  },
  {
    "code": "3101",
    "name": "เมืองบุรีรัมย์",
    "id": "5afae3c7ead44fb20f6c86c4",
    "name_EN": "Mueang Buri Ram",
    "province_code": "31"
  },
  {
    "code": "3102",
    "name": "คูเมือง",
    "id": "5afae3c7ead44fb20f6c86c5",
    "name_EN": "Khu Mueang",
    "province_code": "31"
  },
  {
    "code": "3103",
    "name": "กระสัง",
    "id": "5afae3c7ead44fb20f6c86c6",
    "name_EN": "Krasang",
    "province_code": "31"
  },
  {
    "code": "3104",
    "name": "นางรอง",
    "id": "5afae3c7ead44fb20f6c86c7",
    "name_EN": "Nang Rong",
    "province_code": "31"
  },
  {
    "code": "3105",
    "name": "หนองกี่",
    "id": "5afae3c7ead44fb20f6c86c8",
    "name_EN": "Nong Ki",
    "province_code": "31"
  },
  {
    "code": "3106",
    "name": "ละหานทราย",
    "id": "5afae3c7ead44fb20f6c86c9",
    "name_EN": "Lahan Sai",
    "province_code": "31"
  },
  {
    "code": "3107",
    "name": "ประโคนชัย",
    "id": "5afae3c7ead44fb20f6c86ca",
    "name_EN": "Prakhon Chai",
    "province_code": "31"
  },
  {
    "code": "3108",
    "name": "บ้านกรวด",
    "id": "5afae3c7ead44fb20f6c86cb",
    "name_EN": "Ban Kruat",
    "province_code": "31"
  },
  {
    "code": "3109",
    "name": "พุทไธสง",
    "id": "5afae3c7ead44fb20f6c86cc",
    "name_EN": "Phutthaisong",
    "province_code": "31"
  },
  {
    "code": "3110",
    "name": "ลำปลายมาศ",
    "id": "5afae3c7ead44fb20f6c86cd",
    "name_EN": "Lam Plai Mat",
    "province_code": "31"
  },
  {
    "code": "3111",
    "name": "สตึก",
    "id": "5afae3c7ead44fb20f6c86ce",
    "name_EN": "Satuek",
    "province_code": "31"
  },
  {
    "code": "3112",
    "name": "ปะคำ",
    "id": "5afae3c7ead44fb20f6c86cf",
    "name_EN": "Pakham",
    "province_code": "31"
  },
  {
    "code": "3113",
    "name": "นาโพธิ์",
    "id": "5afae3c7ead44fb20f6c86d0",
    "name_EN": "Na Pho",
    "province_code": "31"
  },
  {
    "code": "3114",
    "name": "หนองหงส์",
    "id": "5afae3c7ead44fb20f6c86d1",
    "name_EN": "Nong Hong",
    "province_code": "31"
  },
  {
    "code": "3115",
    "name": "พลับพลาชัย",
    "id": "5afae3c7ead44fb20f6c86d2",
    "name_EN": "Phlapphla Chai",
    "province_code": "31"
  },
  {
    "code": "3116",
    "name": "ห้วยราช",
    "id": "5afae3c7ead44fb20f6c86d3",
    "name_EN": "Huai Rat",
    "province_code": "31"
  },
  {
    "code": "3117",
    "name": "โนนสุวรรณ",
    "id": "5afae3c7ead44fb20f6c86d4",
    "name_EN": "Non Suwan",
    "province_code": "31"
  },
  {
    "code": "3118",
    "name": "ชำนิ",
    "id": "5afae3c7ead44fb20f6c86d5",
    "name_EN": "Chamni",
    "province_code": "31"
  },
  {
    "code": "3119",
    "name": "บ้านใหม่ไชยพจน์",
    "id": "5afae3c7ead44fb20f6c86d6",
    "name_EN": "Ban Mai Chaiyaphot",
    "province_code": "31"
  },
  {
    "code": "3120",
    "name": "โนนดินแดง",
    "id": "5afae3c7ead44fb20f6c86d7",
    "name_EN": "Din Daeng",
    "province_code": "31"
  },
  {
    "code": "3121",
    "name": "บ้านด่าน",
    "id": "5afae3c7ead44fb20f6c86d8",
    "name_EN": "Ban Dan",
    "province_code": "31"
  },
  {
    "code": "3122",
    "name": "แคนดง",
    "id": "5afae3c7ead44fb20f6c86d9",
    "name_EN": "Khaen Dong",
    "province_code": "31"
  },
  {
    "code": "3123",
    "name": "เฉลิมพระเกียรติ",
    "id": "5afae3c7ead44fb20f6c86da",
    "name_EN": "Chaloem Phra Kiat",
    "province_code": "31"
  },
  {
    "code": "3201",
    "name": "เมืองสุรินทร์",
    "id": "5afae3c7ead44fb20f6c86db",
    "name_EN": "Mueang Surin",
    "province_code": "32"
  },
  {
    "code": "3202",
    "name": "ชุมพลบุรี",
    "id": "5afae3c7ead44fb20f6c86dc",
    "name_EN": "Chumphon Buri",
    "province_code": "32"
  },
  {
    "code": "3203",
    "name": "ท่าตูม",
    "id": "5afae3c7ead44fb20f6c86dd",
    "name_EN": "Tha Tum",
    "province_code": "32"
  },
  {
    "code": "3204",
    "name": "จอมพระ",
    "id": "5afae3c7ead44fb20f6c86de",
    "name_EN": "Chom Phra",
    "province_code": "32"
  },
  {
    "code": "3205",
    "name": "ปราสาท",
    "id": "5afae3c7ead44fb20f6c86df",
    "name_EN": "Prasat",
    "province_code": "32"
  },
  {
    "code": "3206",
    "name": "กาบเชิง",
    "id": "5afae3c7ead44fb20f6c86e0",
    "name_EN": "Kap Choeng",
    "province_code": "32"
  },
  {
    "code": "3207",
    "name": "รัตนบุรี",
    "id": "5afae3c7ead44fb20f6c86e1",
    "name_EN": "Rattanaburi",
    "province_code": "32"
  },
  {
    "code": "3208",
    "name": "สนม",
    "id": "5afae3c7ead44fb20f6c86e2",
    "name_EN": "Sanom",
    "province_code": "32"
  },
  {
    "code": "3209",
    "name": "ศีขรภูมิ",
    "id": "5afae3c7ead44fb20f6c86e3",
    "name_EN": "Sikhoraphum",
    "province_code": "32"
  },
  {
    "code": "3210",
    "name": "สังขะ",
    "id": "5afae3c7ead44fb20f6c86e4",
    "name_EN": "Sangkha",
    "province_code": "32"
  },
  {
    "code": "3211",
    "name": "ลำดวน",
    "id": "5afae3c7ead44fb20f6c86e5",
    "name_EN": "Lamduan",
    "province_code": "32"
  },
  {
    "code": "3212",
    "name": "สำโรงทาบ",
    "id": "5afae3c7ead44fb20f6c86e6",
    "name_EN": "Samrong Thap",
    "province_code": "32"
  },
  {
    "code": "3213",
    "name": "บัวเชด",
    "id": "5afae3c7ead44fb20f6c86e7",
    "name_EN": "Buachet",
    "province_code": "32"
  },
  {
    "code": "3214",
    "name": "พนมดงรัก",
    "id": "5afae3c7ead44fb20f6c86e8",
    "name_EN": "Phanom Dong Rak",
    "province_code": "32"
  },
  {
    "code": "3215",
    "name": "ศรีณรงค์",
    "id": "5afae3c7ead44fb20f6c86e9",
    "name_EN": "Si Narong",
    "province_code": "32"
  },
  {
    "code": "3216",
    "name": "เขวาสินรินทร์",
    "id": "5afae3c7ead44fb20f6c86ea",
    "name_EN": "Khwao Sinarin",
    "province_code": "32"
  },
  {
    "code": "3217",
    "name": "โนนนารายณ์",
    "id": "5afae3c7ead44fb20f6c86eb",
    "name_EN": "Non Narai",
    "province_code": "32"
  },
  {
    "code": "3301",
    "name": "เมืองศรีสะเกษ",
    "id": "5afae3c7ead44fb20f6c86ec",
    "name_EN": "Mueang Si Sa Ket",
    "province_code": "33"
  },
  {
    "code": "3302",
    "name": "ยางชุมน้อย",
    "id": "5afae3c7ead44fb20f6c86ed",
    "name_EN": "Yang Chum Noi",
    "province_code": "33"
  },
  {
    "code": "3303",
    "name": "กันทรารมย์",
    "id": "5afae3c7ead44fb20f6c86ee",
    "name_EN": "Kanthararom",
    "province_code": "33"
  },
  {
    "code": "3304",
    "name": "กันทรลักษ์",
    "id": "5afae3c7ead44fb20f6c86ef",
    "name_EN": "Kantharalak",
    "province_code": "33"
  },
  {
    "code": "3305",
    "name": "ขุขันธ์",
    "id": "5afae3c7ead44fb20f6c86f0",
    "name_EN": "Khukhan",
    "province_code": "33"
  },
  {
    "code": "3306",
    "name": "ไพรบึง",
    "id": "5afae3c7ead44fb20f6c86f1",
    "name_EN": "Phrai Bueng",
    "province_code": "33"
  },
  {
    "code": "3307",
    "name": "ปรางค์กู่",
    "id": "5afae3c7ead44fb20f6c86f2",
    "name_EN": "Prang Ku",
    "province_code": "33"
  },
  {
    "code": "3308",
    "name": "ขุนหาญ",
    "id": "5afae3c7ead44fb20f6c86f3",
    "name_EN": "Khun Han",
    "province_code": "33"
  },
  {
    "code": "3309",
    "name": "ราษีไศล",
    "id": "5afae3c7ead44fb20f6c86f4",
    "name_EN": "Rasi Salai",
    "province_code": "33"
  },
  {
    "code": "3310",
    "name": "อุทุมพรพิสัย",
    "id": "5afae3c7ead44fb20f6c86f5",
    "name_EN": "Uthumphon Phisai",
    "province_code": "33"
  },
  {
    "code": "3311",
    "name": "บึงบูรพ์",
    "id": "5afae3c7ead44fb20f6c86f6",
    "name_EN": "Bueng Bun",
    "province_code": "33"
  },
  {
    "code": "3312",
    "name": "ห้วยทับทัน",
    "id": "5afae3c7ead44fb20f6c86f7",
    "name_EN": "Huai Thap Than",
    "province_code": "33"
  },
  {
    "code": "3313",
    "name": "โนนคูณ",
    "id": "5afae3c7ead44fb20f6c86f8",
    "name_EN": "Non Khun",
    "province_code": "33"
  },
  {
    "code": "3314",
    "name": "ศรีรัตนะ",
    "id": "5afae3c7ead44fb20f6c86f9",
    "name_EN": "Si Rattana",
    "province_code": "33"
  },
  {
    "code": "3315",
    "name": "น้ำเกลี้ยง",
    "id": "5afae3c7ead44fb20f6c86fa",
    "name_EN": "Si Rattana",
    "province_code": "33"
  },
  {
    "code": "3316",
    "name": "วังหิน",
    "id": "5afae3c7ead44fb20f6c86fb",
    "name_EN": "Wang Hin",
    "province_code": "33"
  },
  {
    "code": "3317",
    "name": "ภูสิงห์",
    "id": "5afae3c7ead44fb20f6c86fc",
    "name_EN": "Phu Sing",
    "province_code": "33"
  },
  {
    "code": "3318",
    "name": "เมืองจันทร์",
    "id": "5afae3c7ead44fb20f6c86fd",
    "name_EN": "Mueang Chan",
    "province_code": "33"
  },
  {
    "code": "3319",
    "name": "เบญจลักษ์",
    "id": "5afae3c7ead44fb20f6c86fe",
    "name_EN": "Benchalak",
    "province_code": "33"
  },
  {
    "code": "3320",
    "name": "พยุห์",
    "id": "5afae3c7ead44fb20f6c86ff",
    "name_EN": "Phayu",
    "province_code": "33"
  },
  {
    "code": "3321",
    "name": "โพธิ์ศรีสุวรรณ",
    "id": "5afae3c7ead44fb20f6c8700",
    "name_EN": "Pho Si Suwan",
    "province_code": "33"
  },
  {
    "code": "3322",
    "name": "ศิลาลาด",
    "id": "5afae3c7ead44fb20f6c8701",
    "name_EN": "Sila Lat",
    "province_code": "33"
  },
  {
    "code": "3401",
    "name": "เมืองอุบลราชธานี",
    "id": "5afae3c7ead44fb20f6c8702",
    "name_EN": "Mueang Ubon Ratchathani",
    "province_code": "34"
  },
  {
    "code": "3402",
    "name": "ศรีเมืองใหม่",
    "id": "5afae3c7ead44fb20f6c8703",
    "name_EN": "Si Mueang Mai",
    "province_code": "34"
  },
  {
    "code": "3403",
    "name": "โขงเจียม",
    "id": "5afae3c7ead44fb20f6c8704",
    "name_EN": "Khong Chiam",
    "province_code": "34"
  },
  {
    "code": "3404",
    "name": "เขื่องใน",
    "id": "5afae3c7ead44fb20f6c8705",
    "name_EN": "Khueang Nai",
    "province_code": "34"
  },
  {
    "code": "3405",
    "name": "เขมราฐ",
    "id": "5afae3c7ead44fb20f6c8706",
    "name_EN": "Khemarat",
    "province_code": "34"
  },
  {
    "code": "3406",
    "name": "*ชานุมาน",
    "id": "5afae3c7ead44fb20f6c8707",
    "name_EN": "*Shanuman",
    "province_code": "34"
  },
  {
    "code": "3407",
    "name": "เดชอุดม",
    "id": "5afae3c7ead44fb20f6c8708",
    "name_EN": "Det Udom",
    "province_code": "34"
  },
  {
    "code": "3408",
    "name": "นาจะหลวย",
    "id": "5afae3c7ead44fb20f6c8709",
    "name_EN": "Na Chaluai",
    "province_code": "34"
  },
  {
    "code": "3409",
    "name": "น้ำยืน",
    "id": "5afae3c7ead44fb20f6c870a",
    "name_EN": "Nam Yuen",
    "province_code": "34"
  },
  {
    "code": "3410",
    "name": "บุณฑริก",
    "id": "5afae3c7ead44fb20f6c870b",
    "name_EN": "Buntharik",
    "province_code": "34"
  },
  {
    "code": "3411",
    "name": "ตระการพืชผล",
    "id": "5afae3c7ead44fb20f6c870c",
    "name_EN": "Trakan Phuet Phon",
    "province_code": "34"
  },
  {
    "code": "3412",
    "name": "กุดข้าวปุ้น",
    "id": "5afae3c7ead44fb20f6c870d",
    "name_EN": "Kut Khaopun",
    "province_code": "34"
  },
  {
    "code": "3413",
    "name": "*พนา",
    "id": "5afae3c7ead44fb20f6c870e",
    "name_EN": "*Phana",
    "province_code": "34"
  },
  {
    "code": "3414",
    "name": "ม่วงสามสิบ",
    "id": "5afae3c7ead44fb20f6c870f",
    "name_EN": "Muang Sam Sip",
    "province_code": "34"
  },
  {
    "code": "3415",
    "name": "วารินชำราบ",
    "id": "5afae3c7ead44fb20f6c8710",
    "name_EN": "Warin Chamrap",
    "province_code": "34"
  },
  {
    "code": "3416",
    "name": "*อำนาจเจริญ",
    "id": "5afae3c7ead44fb20f6c8711",
    "name_EN": "*Amnat Charoen",
    "province_code": "34"
  },
  {
    "code": "3417",
    "name": "*เสนางคนิคม",
    "id": "5afae3c7ead44fb20f6c8712",
    "name_EN": "*Senangkhanikhom",
    "province_code": "34"
  },
  {
    "code": "3418",
    "name": "*หัวตะพาน",
    "id": "5afae3c7ead44fb20f6c8713",
    "name_EN": "*Hua Taphan",
    "province_code": "34"
  },
  {
    "code": "3419",
    "name": "พิบูลมังสาหาร",
    "id": "5afae3c7ead44fb20f6c8714",
    "name_EN": "Phibun Mangsahan",
    "province_code": "34"
  },
  {
    "code": "3420",
    "name": "ตาลสุม",
    "id": "5afae3c7ead44fb20f6c8715",
    "name_EN": "Tan Sum",
    "province_code": "34"
  },
  {
    "code": "3421",
    "name": "โพธิ์ไทร",
    "id": "5afae3c7ead44fb20f6c8716",
    "name_EN": "Pho Sai",
    "province_code": "34"
  },
  {
    "code": "3422",
    "name": "สำโรง",
    "id": "5afae3c7ead44fb20f6c8717",
    "name_EN": "Samrong",
    "province_code": "34"
  },
  {
    "code": "3423",
    "name": "*กิ่งอำเภอลืออำนาจ",
    "id": "5afae3c7ead44fb20f6c8718",
    "name_EN": "*King Amphoe Lue Amnat",
    "province_code": "34"
  },
  {
    "code": "3424",
    "name": "ดอนมดแดง",
    "id": "5afae3c7ead44fb20f6c8719",
    "name_EN": "Don Mot Daeng",
    "province_code": "34"
  },
  {
    "code": "3425",
    "name": "สิรินธร",
    "id": "5afae3c7ead44fb20f6c871a",
    "name_EN": "Sirindhorn",
    "province_code": "34"
  },
  {
    "code": "3426",
    "name": "ทุ่งศรีอุดม",
    "id": "5afae3c7ead44fb20f6c871b",
    "name_EN": "Thung Si Udom",
    "province_code": "34"
  },
  {
    "code": "3427",
    "name": "*ปทุมราชวงศา",
    "id": "5afae3c7ead44fb20f6c871c",
    "name_EN": "*Pathum Ratchawongsa",
    "province_code": "34"
  },
  {
    "code": "3428",
    "name": "*กิ่งอำเภอศรีหลักชัย",
    "id": "5afae3c7ead44fb20f6c871d",
    "name_EN": "*King Amphoe Sri Lunk Chai",
    "province_code": "34"
  },
  {
    "code": "3429",
    "name": "นาเยีย",
    "id": "5afae3c7ead44fb20f6c871e",
    "name_EN": "Na Yia",
    "province_code": "34"
  },
  {
    "code": "3430",
    "name": "นาตาล",
    "id": "5afae3c7ead44fb20f6c871f",
    "name_EN": "Na Tan",
    "province_code": "34"
  },
  {
    "code": "3431",
    "name": "เหล่าเสือโก้ก",
    "id": "5afae3c7ead44fb20f6c8720",
    "name_EN": "Lao Suea Kok",
    "province_code": "34"
  },
  {
    "code": "3432",
    "name": "สว่างวีระวงศ์",
    "id": "5afae3c7ead44fb20f6c8721",
    "name_EN": "Sawang Wirawong",
    "province_code": "34"
  },
  {
    "code": "3433",
    "name": "น้ำขุ่น",
    "id": "5afae3c7ead44fb20f6c8722",
    "name_EN": "Nam Khun",
    "province_code": "34"
  },
  {
    "code": "3481",
    "name": "*อ.สุวรรณวารี  จ.อุบลราชธานี",
    "id": "5afae3c7ead44fb20f6c8723",
    "name_EN": "*Suwan Wari",
    "province_code": "34"
  },
  {
    "code": "3501",
    "name": "เมืองยโสธร",
    "id": "5afae3c7ead44fb20f6c8724",
    "name_EN": "Mueang Yasothon",
    "province_code": "35"
  },
  {
    "code": "3502",
    "name": "ทรายมูล",
    "id": "5afae3c7ead44fb20f6c8725",
    "name_EN": "Sai Mun",
    "province_code": "35"
  },
  {
    "code": "3503",
    "name": "กุดชุม",
    "id": "5afae3c7ead44fb20f6c8726",
    "name_EN": "Kut Chum",
    "province_code": "35"
  },
  {
    "code": "3504",
    "name": "คำเขื่อนแก้ว",
    "id": "5afae3c7ead44fb20f6c8727",
    "name_EN": "Kham Khuean Kaeo",
    "province_code": "35"
  },
  {
    "code": "3505",
    "name": "ป่าติ้ว",
    "id": "5afae3c7ead44fb20f6c8728",
    "name_EN": "Pa Tio",
    "province_code": "35"
  },
  {
    "code": "3506",
    "name": "มหาชนะชัย",
    "id": "5afae3c7ead44fb20f6c8729",
    "name_EN": "Maha Chana Chai",
    "province_code": "35"
  },
  {
    "code": "3507",
    "name": "ค้อวัง",
    "id": "5afae3c7ead44fb20f6c872a",
    "name_EN": "Kho Wang",
    "province_code": "35"
  },
  {
    "code": "3508",
    "name": "เลิงนกทา",
    "id": "5afae3c7ead44fb20f6c872b",
    "name_EN": "Loeng Nok Tha",
    "province_code": "35"
  },
  {
    "code": "3509",
    "name": "ไทยเจริญ",
    "id": "5afae3c7ead44fb20f6c872c",
    "name_EN": "Thai Charoen",
    "province_code": "35"
  },
  {
    "code": "3601",
    "name": "เมืองชัยภูมิ",
    "id": "5afae3c7ead44fb20f6c872d",
    "name_EN": "Mueang Chaiyaphum",
    "province_code": "36"
  },
  {
    "code": "3602",
    "name": "บ้านเขว้า",
    "id": "5afae3c7ead44fb20f6c872e",
    "name_EN": "Ban Khwao",
    "province_code": "36"
  },
  {
    "code": "3603",
    "name": "คอนสวรรค์",
    "id": "5afae3c7ead44fb20f6c872f",
    "name_EN": "Khon Sawan",
    "province_code": "36"
  },
  {
    "code": "3604",
    "name": "เกษตรสมบูรณ์",
    "id": "5afae3c7ead44fb20f6c8730",
    "name_EN": "Kaset Sombun",
    "province_code": "36"
  },
  {
    "code": "3605",
    "name": "หนองบัวแดง",
    "id": "5afae3c7ead44fb20f6c8731",
    "name_EN": "Nong Bua Daeng",
    "province_code": "36"
  },
  {
    "code": "3606",
    "name": "จัตุรัส",
    "id": "5afae3c7ead44fb20f6c8732",
    "name_EN": "Chatturat",
    "province_code": "36"
  },
  {
    "code": "3607",
    "name": "บำเหน็จณรงค์",
    "id": "5afae3c7ead44fb20f6c8733",
    "name_EN": "Bamnet Narong",
    "province_code": "36"
  },
  {
    "code": "3608",
    "name": "หนองบัวระเหว",
    "id": "5afae3c7ead44fb20f6c8734",
    "name_EN": "Nong Bua Rawe",
    "province_code": "36"
  },
  {
    "code": "3609",
    "name": "เทพสถิต",
    "id": "5afae3c7ead44fb20f6c8735",
    "name_EN": "Thep Sathit",
    "province_code": "36"
  },
  {
    "code": "3610",
    "name": "ภูเขียว",
    "id": "5afae3c7ead44fb20f6c8736",
    "name_EN": "Phu Khiao",
    "province_code": "36"
  },
  {
    "code": "3611",
    "name": "บ้านแท่น",
    "id": "5afae3c7ead44fb20f6c8737",
    "name_EN": "Ban Thaen",
    "province_code": "36"
  },
  {
    "code": "3612",
    "name": "แก้งคร้อ",
    "id": "5afae3c7ead44fb20f6c8738",
    "name_EN": "Kaeng Khro",
    "province_code": "36"
  },
  {
    "code": "3613",
    "name": "คอนสาร",
    "id": "5afae3c7ead44fb20f6c8739",
    "name_EN": "Khon San",
    "province_code": "36"
  },
  {
    "code": "3614",
    "name": "ภักดีชุมพล",
    "id": "5afae3c7ead44fb20f6c873a",
    "name_EN": "Phakdi Chumphon",
    "province_code": "36"
  },
  {
    "code": "3615",
    "name": "เนินสง่า",
    "id": "5afae3c7ead44fb20f6c873b",
    "name_EN": "Noen Sa-nga",
    "province_code": "36"
  },
  {
    "code": "3616",
    "name": "ซับใหญ่",
    "id": "5afae3c7ead44fb20f6c873c",
    "name_EN": "Sap Yai",
    "province_code": "36"
  },
  {
    "code": "3651",
    "name": "เมืองชัยภูมิ (สาขาตำบลโนนสำราญ)*",
    "id": "5afae3c7ead44fb20f6c873d",
    "name_EN": "Mueang Chaiyaphum(Non Sumran)*",
    "province_code": "36"
  },
  {
    "code": "3652",
    "name": "สาขาตำบลบ้านหว่าเฒ่า*",
    "id": "5afae3c7ead44fb20f6c873e",
    "name_EN": "Ban Wha Tao*",
    "province_code": "36"
  },
  {
    "code": "3653",
    "name": "หนองบัวแดง (สาขาตำบลวังชมภู)*",
    "id": "5afae3c7ead44fb20f6c873f",
    "name_EN": "Nong Bua Daeng",
    "province_code": "36"
  },
  {
    "code": "3654",
    "name": "กิ่งอำเภอซับใหญ่ (สาขาตำบลซับใหญ่)*",
    "id": "5afae3c7ead44fb20f6c8740",
    "name_EN": "King Amphoe Sap Yai*",
    "province_code": "36"
  },
  {
    "code": "3655",
    "name": "สาขาตำบลโคกเพชร*",
    "id": "5afae3c7ead44fb20f6c8741",
    "name_EN": "Coke Phet*",
    "province_code": "36"
  },
  {
    "code": "3656",
    "name": "เทพสถิต (สาขาตำบลนายางกลัก)*",
    "id": "5afae3c7ead44fb20f6c8742",
    "name_EN": "Thep Sathit*",
    "province_code": "36"
  },
  {
    "code": "3657",
    "name": "บ้านแท่น (สาขาตำบลบ้านเต่า)*",
    "id": "5afae3c7ead44fb20f6c8743",
    "name_EN": "Ban Thaen",
    "province_code": "36"
  },
  {
    "code": "3658",
    "name": "แก้งคร้อ (สาขาตำบลท่ามะไฟหวาน)*",
    "id": "5afae3c7ead44fb20f6c8744",
    "name_EN": "Kaeng Khro*",
    "province_code": "36"
  },
  {
    "code": "3659",
    "name": "คอนสาร (สาขาตำบลโนนคูณ)*",
    "id": "5afae3c7ead44fb20f6c8745",
    "name_EN": "Khon San*",
    "province_code": "36"
  },
  {
    "code": "3701",
    "name": "เมืองอำนาจเจริญ",
    "id": "5afae3c7ead44fb20f6c8746",
    "name_EN": "Mueang Amnat Charoen",
    "province_code": "37"
  },
  {
    "code": "3702",
    "name": "ชานุมาน",
    "id": "5afae3c7ead44fb20f6c8747",
    "name_EN": "Chanuman",
    "province_code": "37"
  },
  {
    "code": "3703",
    "name": "ปทุมราชวงศา",
    "id": "5afae3c7ead44fb20f6c8748",
    "name_EN": "Pathum Ratchawongsa",
    "province_code": "37"
  },
  {
    "code": "3704",
    "name": "พนา",
    "id": "5afae3c7ead44fb20f6c8749",
    "name_EN": "Phana",
    "province_code": "37"
  },
  {
    "code": "3705",
    "name": "เสนางคนิคม",
    "id": "5afae3c7ead44fb20f6c874a",
    "name_EN": "Senangkhanikhom",
    "province_code": "37"
  },
  {
    "code": "3706",
    "name": "หัวตะพาน",
    "id": "5afae3c7ead44fb20f6c874b",
    "name_EN": "Hua Taphan",
    "province_code": "37"
  },
  {
    "code": "3707",
    "name": "ลืออำนาจ",
    "id": "5afae3c7ead44fb20f6c874c",
    "name_EN": "Lue Amnat",
    "province_code": "37"
  },
  {
    "code": "3901",
    "name": "เมืองหนองบัวลำภู",
    "id": "5afae3c7ead44fb20f6c874d",
    "name_EN": "Mueang Nong Bua Lam Phu",
    "province_code": "39"
  },
  {
    "code": "3902",
    "name": "นากลาง",
    "id": "5afae3c7ead44fb20f6c874e",
    "name_EN": "Na Klang",
    "province_code": "39"
  },
  {
    "code": "3903",
    "name": "โนนสัง",
    "id": "5afae3c7ead44fb20f6c874f",
    "name_EN": "Non Sang",
    "province_code": "39"
  },
  {
    "code": "3904",
    "name": "ศรีบุญเรือง",
    "id": "5afae3c7ead44fb20f6c8750",
    "name_EN": "Si Bun Rueang",
    "province_code": "39"
  },
  {
    "code": "3905",
    "name": "สุวรรณคูหา",
    "id": "5afae3c7ead44fb20f6c8751",
    "name_EN": "Suwannakhuha",
    "province_code": "39"
  },
  {
    "code": "3906",
    "name": "นาวัง",
    "id": "5afae3c7ead44fb20f6c8752",
    "name_EN": "Na Wang",
    "province_code": "39"
  },
  {
    "code": "4001",
    "name": "เมืองขอนแก่น",
    "id": "5afae3c7ead44fb20f6c8753",
    "name_EN": "Mueang Khon Kaen",
    "province_code": "40"
  },
  {
    "code": "4002",
    "name": "บ้านฝาง",
    "id": "5afae3c7ead44fb20f6c8754",
    "name_EN": "Ban Fang",
    "province_code": "40"
  },
  {
    "code": "4003",
    "name": "พระยืน",
    "id": "5afae3c7ead44fb20f6c8755",
    "name_EN": "Phra Yuen",
    "province_code": "40"
  },
  {
    "code": "4004",
    "name": "หนองเรือ",
    "id": "5afae3c7ead44fb20f6c8756",
    "name_EN": "Nong Ruea",
    "province_code": "40"
  },
  {
    "code": "4005",
    "name": "ชุมแพ",
    "id": "5afae3c7ead44fb20f6c8757",
    "name_EN": "Chum Phae",
    "province_code": "40"
  },
  {
    "code": "4006",
    "name": "สีชมพู",
    "id": "5afae3c7ead44fb20f6c8758",
    "name_EN": "Si Chomphu",
    "province_code": "40"
  },
  {
    "code": "4007",
    "name": "น้ำพอง",
    "id": "5afae3c7ead44fb20f6c8759",
    "name_EN": "Nam Phong",
    "province_code": "40"
  },
  {
    "code": "4008",
    "name": "อุบลรัตน์",
    "id": "5afae3c7ead44fb20f6c875a",
    "name_EN": "Ubolratana",
    "province_code": "40"
  },
  {
    "code": "4009",
    "name": "กระนวน",
    "id": "5afae3c7ead44fb20f6c875b",
    "name_EN": "Kranuan",
    "province_code": "40"
  },
  {
    "code": "4010",
    "name": "บ้านไผ่",
    "id": "5afae3c7ead44fb20f6c875c",
    "name_EN": "Ban Phai",
    "province_code": "40"
  },
  {
    "code": "4011",
    "name": "เปือยน้อย",
    "id": "5afae3c7ead44fb20f6c875d",
    "name_EN": "Pueai Noi",
    "province_code": "40"
  },
  {
    "code": "4012",
    "name": "พล",
    "id": "5afae3c7ead44fb20f6c875e",
    "name_EN": "Phon",
    "province_code": "40"
  },
  {
    "code": "4013",
    "name": "แวงใหญ่",
    "id": "5afae3c7ead44fb20f6c875f",
    "name_EN": "Waeng Yai",
    "province_code": "40"
  },
  {
    "code": "4014",
    "name": "แวงน้อย",
    "id": "5afae3c7ead44fb20f6c8760",
    "name_EN": "Waeng Noi",
    "province_code": "40"
  },
  {
    "code": "4015",
    "name": "หนองสองห้อง",
    "id": "5afae3c7ead44fb20f6c8761",
    "name_EN": "Nong Song Hong",
    "province_code": "40"
  },
  {
    "code": "4016",
    "name": "ภูเวียง",
    "id": "5afae3c7ead44fb20f6c8762",
    "name_EN": "Phu Wiang",
    "province_code": "40"
  },
  {
    "code": "4017",
    "name": "มัญจาคีรี",
    "id": "5afae3c7ead44fb20f6c8763",
    "name_EN": "Mancha Khiri",
    "province_code": "40"
  },
  {
    "code": "4018",
    "name": "ชนบท",
    "id": "5afae3c7ead44fb20f6c8764",
    "name_EN": "Chonnabot",
    "province_code": "40"
  },
  {
    "code": "4019",
    "name": "เขาสวนกวาง",
    "id": "5afae3c7ead44fb20f6c8765",
    "name_EN": "Khao Suan Kwang",
    "province_code": "40"
  },
  {
    "code": "4020",
    "name": "ภูผาม่าน",
    "id": "5afae3c7ead44fb20f6c8766",
    "name_EN": "Phu Pha Man",
    "province_code": "40"
  },
  {
    "code": "4021",
    "name": "ซำสูง",
    "id": "5afae3c7ead44fb20f6c8767",
    "name_EN": "Sam Sung",
    "province_code": "40"
  },
  {
    "code": "4022",
    "name": "โคกโพธิ์ไชย",
    "id": "5afae3c7ead44fb20f6c8768",
    "name_EN": "Khok Pho Chai",
    "province_code": "40"
  },
  {
    "code": "4023",
    "name": "หนองนาคำ",
    "id": "5afae3c7ead44fb20f6c8769",
    "name_EN": "Nong Na Kham",
    "province_code": "40"
  },
  {
    "code": "4024",
    "name": "บ้านแฮด",
    "id": "5afae3c7ead44fb20f6c876a",
    "name_EN": "Ban Haet",
    "province_code": "40"
  },
  {
    "code": "4025",
    "name": "โนนศิลา",
    "id": "5afae3c7ead44fb20f6c876b",
    "name_EN": "Non Sila",
    "province_code": "40"
  },
  {
    "code": "4029",
    "name": "เวียงเก่า",
    "id": "5afae3c7ead44fb20f6c876c",
    "name_EN": "Wiang Kao",
    "province_code": "40"
  },
  {
    "code": "4068",
    "name": "ท้องถิ่นเทศบาลตำบลบ้านเป็ด*",
    "id": "5afae3c7ead44fb20f6c876d",
    "name_EN": "Ban Pet*",
    "province_code": "40"
  },
  {
    "code": "4098",
    "name": "เทศบาลตำบลเมืองพล*",
    "id": "5afae3c7ead44fb20f6c876e",
    "name_EN": "Tet Saban Tambon Muang Phon*",
    "province_code": "40"
  },
  {
    "code": "4101",
    "name": "เมืองอุดรธานี",
    "id": "5afae3c7ead44fb20f6c876f",
    "name_EN": "Mueang Udon Thani",
    "province_code": "41"
  },
  {
    "code": "4102",
    "name": "กุดจับ",
    "id": "5afae3c7ead44fb20f6c8770",
    "name_EN": "Kut Chap",
    "province_code": "41"
  },
  {
    "code": "4103",
    "name": "หนองวัวซอ",
    "id": "5afae3c7ead44fb20f6c8771",
    "name_EN": "Nong Wua So",
    "province_code": "41"
  },
  {
    "code": "4104",
    "name": "กุมภวาปี",
    "id": "5afae3c7ead44fb20f6c8772",
    "name_EN": "Kumphawapi",
    "province_code": "41"
  },
  {
    "code": "4105",
    "name": "โนนสะอาด",
    "id": "5afae3c7ead44fb20f6c8773",
    "name_EN": "Non Sa-at",
    "province_code": "41"
  },
  {
    "code": "4106",
    "name": "หนองหาน",
    "id": "5afae3c7ead44fb20f6c8774",
    "name_EN": "Nong Han",
    "province_code": "41"
  },
  {
    "code": "4107",
    "name": "ทุ่งฝน",
    "id": "5afae3c7ead44fb20f6c8775",
    "name_EN": "Thung Fon",
    "province_code": "41"
  },
  {
    "code": "4108",
    "name": "ไชยวาน",
    "id": "5afae3c7ead44fb20f6c8776",
    "name_EN": "Chai Wan",
    "province_code": "41"
  },
  {
    "code": "4109",
    "name": "ศรีธาตุ",
    "id": "5afae3c7ead44fb20f6c8777",
    "name_EN": "Si That",
    "province_code": "41"
  },
  {
    "code": "4110",
    "name": "วังสามหมอ",
    "id": "5afae3c7ead44fb20f6c8778",
    "name_EN": "Wang Sam Mo",
    "province_code": "41"
  },
  {
    "code": "4111",
    "name": "บ้านดุง",
    "id": "5afae3c7ead44fb20f6c8779",
    "name_EN": "Ban Dung",
    "province_code": "41"
  },
  {
    "code": "4112",
    "name": "*หนองบัวลำภู",
    "id": "5afae3c7ead44fb20f6c877a",
    "name_EN": "*Nong Bua Lam Phu",
    "province_code": "41"
  },
  {
    "code": "4113",
    "name": "*ศรีบุญเรือง",
    "id": "5afae3c7ead44fb20f6c877b",
    "name_EN": "*Si Bun Rueang",
    "province_code": "41"
  },
  {
    "code": "4114",
    "name": "*นากลาง",
    "id": "5afae3c7ead44fb20f6c877c",
    "name_EN": "*Na Klang",
    "province_code": "41"
  },
  {
    "code": "4115",
    "name": "*สุวรรณคูหา",
    "id": "5afae3c7ead44fb20f6c877d",
    "name_EN": "*Suwannakhuha",
    "province_code": "41"
  },
  {
    "code": "4116",
    "name": "*โนนสัง",
    "id": "5afae3c7ead44fb20f6c877e",
    "name_EN": "*Non Sang",
    "province_code": "41"
  },
  {
    "code": "4117",
    "name": "บ้านผือ",
    "id": "5afae3c7ead44fb20f6c877f",
    "name_EN": "Ban Phue",
    "province_code": "41"
  },
  {
    "code": "4118",
    "name": "น้ำโสม",
    "id": "5afae3c7ead44fb20f6c8780",
    "name_EN": "Nam Som",
    "province_code": "41"
  },
  {
    "code": "4119",
    "name": "เพ็ญ",
    "id": "5afae3c7ead44fb20f6c8781",
    "name_EN": "Phen",
    "province_code": "41"
  },
  {
    "code": "4120",
    "name": "สร้างคอม",
    "id": "5afae3c7ead44fb20f6c8782",
    "name_EN": "Sang Khom",
    "province_code": "41"
  },
  {
    "code": "4121",
    "name": "หนองแสง",
    "id": "5afae3c7ead44fb20f6c8783",
    "name_EN": "Nong Saeng",
    "province_code": "41"
  },
  {
    "code": "4122",
    "name": "นายูง",
    "id": "5afae3c7ead44fb20f6c8784",
    "name_EN": "Na Yung",
    "province_code": "41"
  },
  {
    "code": "4123",
    "name": "พิบูลย์รักษ์",
    "id": "5afae3c7ead44fb20f6c8785",
    "name_EN": "Phibun Rak",
    "province_code": "41"
  },
  {
    "code": "4124",
    "name": "กู่แก้ว",
    "id": "5afae3c7ead44fb20f6c8786",
    "name_EN": "Ku Kaeo",
    "province_code": "41"
  },
  {
    "code": "4125",
    "name": "ประจักษ์ศิลปาคม",
    "id": "5afae3c7ead44fb20f6c8787",
    "name_EN": "rachak-sinlapakhom",
    "province_code": "41"
  },
  {
    "code": "4201",
    "name": "เมืองเลย",
    "id": "5afae3c7ead44fb20f6c8788",
    "name_EN": "Mueang Loei",
    "province_code": "42"
  },
  {
    "code": "4202",
    "name": "นาด้วง",
    "id": "5afae3c7ead44fb20f6c8789",
    "name_EN": "Na Duang",
    "province_code": "42"
  },
  {
    "code": "4203",
    "name": "เชียงคาน",
    "id": "5afae3c7ead44fb20f6c878a",
    "name_EN": "Chiang Khan",
    "province_code": "42"
  },
  {
    "code": "4204",
    "name": "ปากชม",
    "id": "5afae3c7ead44fb20f6c878b",
    "name_EN": "Pak Chom",
    "province_code": "42"
  },
  {
    "code": "4205",
    "name": "ด่านซ้าย",
    "id": "5afae3c7ead44fb20f6c878c",
    "name_EN": "Dan Sai",
    "province_code": "42"
  },
  {
    "code": "4206",
    "name": "นาแห้ว",
    "id": "5afae3c7ead44fb20f6c878d",
    "name_EN": "Na Haeo",
    "province_code": "42"
  },
  {
    "code": "4207",
    "name": "ภูเรือ",
    "id": "5afae3c7ead44fb20f6c878e",
    "name_EN": "Phu Ruea",
    "province_code": "42"
  },
  {
    "code": "4208",
    "name": "ท่าลี่",
    "id": "5afae3c7ead44fb20f6c878f",
    "name_EN": "Tha Li",
    "province_code": "42"
  },
  {
    "code": "4209",
    "name": "วังสะพุง",
    "id": "5afae3c7ead44fb20f6c8790",
    "name_EN": "Wang Saphung",
    "province_code": "42"
  },
  {
    "code": "4210",
    "name": "ภูกระดึง",
    "id": "5afae3c7ead44fb20f6c8791",
    "name_EN": "Phu Kradueng",
    "province_code": "42"
  },
  {
    "code": "4211",
    "name": "ภูหลวง",
    "id": "5afae3c7ead44fb20f6c8792",
    "name_EN": "Phu Luang",
    "province_code": "42"
  },
  {
    "code": "4212",
    "name": "ผาขาว",
    "id": "5afae3c7ead44fb20f6c8793",
    "name_EN": "Pha Khao",
    "province_code": "42"
  },
  {
    "code": "4213",
    "name": "เอราวัณ",
    "id": "5afae3c7ead44fb20f6c8794",
    "name_EN": "Erawan",
    "province_code": "42"
  },
  {
    "code": "4214",
    "name": "หนองหิน",
    "id": "5afae3c7ead44fb20f6c8795",
    "name_EN": "Nong Hin",
    "province_code": "42"
  },
  {
    "code": "4301",
    "name": "เมืองหนองคาย",
    "id": "5afae3c7ead44fb20f6c8796",
    "name_EN": "Mueang Nong Khai",
    "province_code": "43"
  },
  {
    "code": "4302",
    "name": "ท่าบ่อ",
    "id": "5afae3c7ead44fb20f6c8797",
    "name_EN": "Tha Bo",
    "province_code": "43"
  },
  {
    "code": "4303",
    "name": "เมืองบึงกาฬ",
    "id": "5afae3c7ead44fb20f6c8798",
    "name_EN": "Mueang Bueng Kan",
    "province_code": "43"
  },
  {
    "code": "4304",
    "name": "พรเจริญ",
    "id": "5afae3c7ead44fb20f6c8799",
    "name_EN": "Phon Charoen",
    "province_code": "43"
  },
  {
    "code": "4305",
    "name": "โพนพิสัย",
    "id": "5afae3c7ead44fb20f6c879a",
    "name_EN": "Phon Phisai",
    "province_code": "43"
  },
  {
    "code": "4306",
    "name": "โซ่พิสัย",
    "id": "5afae3c7ead44fb20f6c879b",
    "name_EN": "So Phisai",
    "province_code": "43"
  },
  {
    "code": "4307",
    "name": "ศรีเชียงใหม่",
    "id": "5afae3c7ead44fb20f6c879c",
    "name_EN": "Si Chiang Mai",
    "province_code": "43"
  },
  {
    "code": "4308",
    "name": "สังคม",
    "id": "5afae3c7ead44fb20f6c879d",
    "name_EN": "Sangkhom",
    "province_code": "43"
  },
  {
    "code": "4309",
    "name": "เซกา",
    "id": "5afae3c7ead44fb20f6c879e",
    "name_EN": "Seka",
    "province_code": "43"
  },
  {
    "code": "4310",
    "name": "ปากคาด",
    "id": "5afae3c7ead44fb20f6c879f",
    "name_EN": "Pak Khat",
    "province_code": "43"
  },
  {
    "code": "4311",
    "name": "บึงโขงหลง",
    "id": "5afae3c7ead44fb20f6c87a0",
    "name_EN": "Bueng Khong Long",
    "province_code": "43"
  },
  {
    "code": "4312",
    "name": "ศรีวิไล",
    "id": "5afae3c7ead44fb20f6c87a1",
    "name_EN": "Si Wilai",
    "province_code": "43"
  },
  {
    "code": "4313",
    "name": "บุ่งคล้า",
    "id": "5afae3c7ead44fb20f6c87a2",
    "name_EN": "Bung Khla",
    "province_code": "43"
  },
  {
    "code": "4314",
    "name": "สระใคร",
    "id": "5afae3c7ead44fb20f6c87a3",
    "name_EN": "Sakhrai",
    "province_code": "43"
  },
  {
    "code": "4315",
    "name": "เฝ้าไร่",
    "id": "5afae3c7ead44fb20f6c87a4",
    "name_EN": "Fao Rai",
    "province_code": "43"
  },
  {
    "code": "4316",
    "name": "รัตนวาปี",
    "id": "5afae3c7ead44fb20f6c87a5",
    "name_EN": "Rattanawapi",
    "province_code": "43"
  },
  {
    "code": "4317",
    "name": "โพธิ์ตาก",
    "id": "5afae3c7ead44fb20f6c87a6",
    "name_EN": "Pho Tak",
    "province_code": "43"
  },
  {
    "code": "4401",
    "name": "เมืองมหาสารคาม",
    "id": "5afae3c7ead44fb20f6c87a7",
    "name_EN": "Mueang Maha Sarakham",
    "province_code": "44"
  },
  {
    "code": "4402",
    "name": "แกดำ",
    "id": "5afae3c7ead44fb20f6c87a8",
    "name_EN": "Kae Dam",
    "province_code": "44"
  },
  {
    "code": "4403",
    "name": "โกสุมพิสัย",
    "id": "5afae3c7ead44fb20f6c87a9",
    "name_EN": "Kosum Phisai",
    "province_code": "44"
  },
  {
    "code": "4404",
    "name": "กันทรวิชัย",
    "id": "5afae3c7ead44fb20f6c87aa",
    "name_EN": "Kantharawichai",
    "province_code": "44"
  },
  {
    "code": "4405",
    "name": "เชียงยืน",
    "id": "5afae3c7ead44fb20f6c87ab",
    "name_EN": "Kantharawichai",
    "province_code": "44"
  },
  {
    "code": "4406",
    "name": "บรบือ",
    "id": "5afae3c7ead44fb20f6c87ac",
    "name_EN": "Borabue",
    "province_code": "44"
  },
  {
    "code": "4407",
    "name": "นาเชือก",
    "id": "5afae3c7ead44fb20f6c87ad",
    "name_EN": "Na Chueak",
    "province_code": "44"
  },
  {
    "code": "4408",
    "name": "พยัคฆภูมิพิสัย",
    "id": "5afae3c7ead44fb20f6c87ae",
    "name_EN": "Phayakkhaphum Phisai",
    "province_code": "44"
  },
  {
    "code": "4409",
    "name": "วาปีปทุม",
    "id": "5afae3c7ead44fb20f6c87af",
    "name_EN": "Wapi Pathum",
    "province_code": "44"
  },
  {
    "code": "4410",
    "name": "นาดูน",
    "id": "5afae3c7ead44fb20f6c87b0",
    "name_EN": "Na Dun",
    "province_code": "44"
  },
  {
    "code": "4411",
    "name": "ยางสีสุราช",
    "id": "5afae3c7ead44fb20f6c87b1",
    "name_EN": "Yang Sisurat",
    "province_code": "44"
  },
  {
    "code": "4412",
    "name": "กุดรัง",
    "id": "5afae3c7ead44fb20f6c87b2",
    "name_EN": "Kut Rang",
    "province_code": "44"
  },
  {
    "code": "4413",
    "name": "ชื่นชม",
    "id": "5afae3c7ead44fb20f6c87b3",
    "name_EN": "Chuen Chom",
    "province_code": "44"
  },
  {
    "code": "4481",
    "name": "*หลุบ",
    "id": "5afae3c7ead44fb20f6c87b4",
    "name_EN": "*Lub",
    "province_code": "44"
  },
  {
    "code": "4501",
    "name": "เมืองร้อยเอ็ด",
    "id": "5afae3c7ead44fb20f6c87b5",
    "name_EN": "Mueang Roi Et",
    "province_code": "45"
  },
  {
    "code": "4502",
    "name": "เกษตรวิสัย",
    "id": "5afae3c7ead44fb20f6c87b6",
    "name_EN": "Kaset Wisai",
    "province_code": "45"
  },
  {
    "code": "4503",
    "name": "ปทุมรัตต์",
    "id": "5afae3c7ead44fb20f6c87b7",
    "name_EN": "Pathum Rat",
    "province_code": "45"
  },
  {
    "code": "4504",
    "name": "จตุรพักตรพิมาน",
    "id": "5afae3c7ead44fb20f6c87b8",
    "name_EN": "Chaturaphak Phiman",
    "province_code": "45"
  },
  {
    "code": "4505",
    "name": "ธวัชบุรี",
    "id": "5afae3c7ead44fb20f6c87b9",
    "name_EN": "Thawat Buri",
    "province_code": "45"
  },
  {
    "code": "4506",
    "name": "พนมไพร",
    "id": "5afae3c7ead44fb20f6c87ba",
    "name_EN": "Phanom Phrai",
    "province_code": "45"
  },
  {
    "code": "4507",
    "name": "โพนทอง",
    "id": "5afae3c7ead44fb20f6c87bb",
    "name_EN": "Phon Thong",
    "province_code": "45"
  },
  {
    "code": "4508",
    "name": "โพธิ์ชัย",
    "id": "5afae3c7ead44fb20f6c87bc",
    "name_EN": "Pho Chai",
    "province_code": "45"
  },
  {
    "code": "4509",
    "name": "หนองพอก",
    "id": "5afae3c7ead44fb20f6c87bd",
    "name_EN": "Nong Phok",
    "province_code": "45"
  },
  {
    "code": "4510",
    "name": "เสลภูมิ",
    "id": "5afae3c7ead44fb20f6c87be",
    "name_EN": "Selaphum",
    "province_code": "45"
  },
  {
    "code": "4511",
    "name": "สุวรรณภูมิ",
    "id": "5afae3c7ead44fb20f6c87bf",
    "name_EN": "Suwannaphum",
    "province_code": "45"
  },
  {
    "code": "4512",
    "name": "เมืองสรวง",
    "id": "5afae3c7ead44fb20f6c87c0",
    "name_EN": "Mueang Suang",
    "province_code": "45"
  },
  {
    "code": "4513",
    "name": "โพนทราย",
    "id": "5afae3c7ead44fb20f6c87c1",
    "name_EN": "Phon Sai",
    "province_code": "45"
  },
  {
    "code": "4514",
    "name": "อาจสามารถ",
    "id": "5afae3c7ead44fb20f6c87c2",
    "name_EN": "At Samat",
    "province_code": "45"
  },
  {
    "code": "4515",
    "name": "เมยวดี",
    "id": "5afae3c7ead44fb20f6c87c3",
    "name_EN": "Moei Wadi",
    "province_code": "45"
  },
  {
    "code": "4516",
    "name": "ศรีสมเด็จ",
    "id": "5afae3c7ead44fb20f6c87c4",
    "name_EN": "Si Somdet",
    "province_code": "45"
  },
  {
    "code": "4517",
    "name": "จังหาร",
    "id": "5afae3c7ead44fb20f6c87c5",
    "name_EN": "Changhan",
    "province_code": "45"
  },
  {
    "code": "4518",
    "name": "เชียงขวัญ",
    "id": "5afae3c7ead44fb20f6c87c6",
    "name_EN": "Chiang Khwan",
    "province_code": "45"
  },
  {
    "code": "4519",
    "name": "หนองฮี",
    "id": "5afae3c7ead44fb20f6c87c7",
    "name_EN": "Nong Hi",
    "province_code": "45"
  },
  {
    "code": "4520",
    "name": "ทุ่งเขาหลวง",
    "id": "5afae3c7ead44fb20f6c87c8",
    "name_EN": "Thung Khao Luangกิ่",
    "province_code": "45"
  },
  {
    "code": "4601",
    "name": "เมืองกาฬสินธุ์",
    "id": "5afae3c7ead44fb20f6c87c9",
    "name_EN": "Mueang Kalasin",
    "province_code": "46"
  },
  {
    "code": "4602",
    "name": "นามน",
    "id": "5afae3c7ead44fb20f6c87ca",
    "name_EN": "Na Mon",
    "province_code": "46"
  },
  {
    "code": "4603",
    "name": "กมลาไสย",
    "id": "5afae3c7ead44fb20f6c87cb",
    "name_EN": "Kamalasai",
    "province_code": "46"
  },
  {
    "code": "4604",
    "name": "ร่องคำ",
    "id": "5afae3c7ead44fb20f6c87cc",
    "name_EN": "Rong Kham",
    "province_code": "46"
  },
  {
    "code": "4605",
    "name": "กุฉินารายณ์",
    "id": "5afae3c7ead44fb20f6c87cd",
    "name_EN": "Kuchinarai",
    "province_code": "46"
  },
  {
    "code": "4606",
    "name": "เขาวง",
    "id": "5afae3c7ead44fb20f6c87ce",
    "name_EN": "Khao Wong",
    "province_code": "46"
  },
  {
    "code": "4607",
    "name": "ยางตลาด",
    "id": "5afae3c7ead44fb20f6c87cf",
    "name_EN": "Yang Talat",
    "province_code": "46"
  },
  {
    "code": "4608",
    "name": "ห้วยเม็ก",
    "id": "5afae3c7ead44fb20f6c87d0",
    "name_EN": "Huai Mek",
    "province_code": "46"
  },
  {
    "code": "4609",
    "name": "สหัสขันธ์",
    "id": "5afae3c7ead44fb20f6c87d1",
    "name_EN": "Sahatsakhan",
    "province_code": "46"
  },
  {
    "code": "4610",
    "name": "คำม่วง",
    "id": "5afae3c7ead44fb20f6c87d2",
    "name_EN": "Kham Muang",
    "province_code": "46"
  },
  {
    "code": "4611",
    "name": "ท่าคันโท",
    "id": "5afae3c7ead44fb20f6c87d3",
    "name_EN": "Tha Khantho",
    "province_code": "46"
  },
  {
    "code": "4612",
    "name": "หนองกุงศรี",
    "id": "5afae3c7ead44fb20f6c87d4",
    "name_EN": "Nong Kung Si",
    "province_code": "46"
  },
  {
    "code": "4613",
    "name": "สมเด็จ",
    "id": "5afae3c7ead44fb20f6c87d5",
    "name_EN": "Somdet",
    "province_code": "46"
  },
  {
    "code": "4614",
    "name": "ห้วยผึ้ง",
    "id": "5afae3c7ead44fb20f6c87d6",
    "name_EN": "Huai Phueng",
    "province_code": "46"
  },
  {
    "code": "4615",
    "name": "สามชัย",
    "id": "5afae3c7ead44fb20f6c87d7",
    "name_EN": "Sam Chai",
    "province_code": "46"
  },
  {
    "code": "4616",
    "name": "นาคู",
    "id": "5afae3c7ead44fb20f6c87d8",
    "name_EN": "Na Khu",
    "province_code": "46"
  },
  {
    "code": "4617",
    "name": "ดอนจาน",
    "id": "5afae3c7ead44fb20f6c87d9",
    "name_EN": "Don Chan",
    "province_code": "46"
  },
  {
    "code": "4618",
    "name": "ฆ้องชัย",
    "id": "5afae3c7ead44fb20f6c87da",
    "name_EN": "Khong Chai",
    "province_code": "46"
  },
  {
    "code": "4701",
    "name": "เมืองสกลนคร",
    "id": "5afae3c7ead44fb20f6c87db",
    "name_EN": "Mueang Sakon Nakhon",
    "province_code": "47"
  },
  {
    "code": "4702",
    "name": "กุสุมาลย์",
    "id": "5afae3c7ead44fb20f6c87dc",
    "name_EN": "Kusuman",
    "province_code": "47"
  },
  {
    "code": "4703",
    "name": "กุดบาก",
    "id": "5afae3c7ead44fb20f6c87dd",
    "name_EN": "Kut Bak",
    "province_code": "47"
  },
  {
    "code": "4704",
    "name": "พรรณานิคม",
    "id": "5afae3c7ead44fb20f6c87de",
    "name_EN": "Phanna Nikhom",
    "province_code": "47"
  },
  {
    "code": "4705",
    "name": "พังโคน",
    "id": "5afae3c7ead44fb20f6c87df",
    "name_EN": "Phang Khon",
    "province_code": "47"
  },
  {
    "code": "4706",
    "name": "วาริชภูมิ",
    "id": "5afae3c7ead44fb20f6c87e0",
    "name_EN": "Waritchaphum",
    "province_code": "47"
  },
  {
    "code": "4707",
    "name": "นิคมน้ำอูน",
    "id": "5afae3c7ead44fb20f6c87e1",
    "name_EN": "Nikhom Nam Un",
    "province_code": "47"
  },
  {
    "code": "4708",
    "name": "วานรนิวาส",
    "id": "5afae3c7ead44fb20f6c87e2",
    "name_EN": "Wanon Niwat",
    "province_code": "47"
  },
  {
    "code": "4709",
    "name": "คำตากล้า",
    "id": "5afae3c7ead44fb20f6c87e3",
    "name_EN": "Kham Ta Kla",
    "province_code": "47"
  },
  {
    "code": "4710",
    "name": "บ้านม่วง",
    "id": "5afae3c7ead44fb20f6c87e4",
    "name_EN": "Ban Muang",
    "province_code": "47"
  },
  {
    "code": "4711",
    "name": "อากาศอำนวย",
    "id": "5afae3c7ead44fb20f6c87e5",
    "name_EN": "Akat Amnuai",
    "province_code": "47"
  },
  {
    "code": "4712",
    "name": "สว่างแดนดิน",
    "id": "5afae3c7ead44fb20f6c87e6",
    "name_EN": "Sawang Daen Din",
    "province_code": "47"
  },
  {
    "code": "4713",
    "name": "ส่องดาว",
    "id": "5afae3c7ead44fb20f6c87e7",
    "name_EN": "Song Dao",
    "province_code": "47"
  },
  {
    "code": "4714",
    "name": "เต่างอย",
    "id": "5afae3c7ead44fb20f6c87e8",
    "name_EN": "Tao Ngoi",
    "province_code": "47"
  },
  {
    "code": "4715",
    "name": "โคกศรีสุพรรณ",
    "id": "5afae3c7ead44fb20f6c87e9",
    "name_EN": "Khok Si Suphan",
    "province_code": "47"
  },
  {
    "code": "4716",
    "name": "เจริญศิลป์",
    "id": "5afae3c7ead44fb20f6c87ea",
    "name_EN": "Charoen Sin",
    "province_code": "47"
  },
  {
    "code": "4717",
    "name": "โพนนาแก้ว",
    "id": "5afae3c7ead44fb20f6c87eb",
    "name_EN": "Phon Na Kaeo",
    "province_code": "47"
  },
  {
    "code": "4718",
    "name": "ภูพาน",
    "id": "5afae3c7ead44fb20f6c87ec",
    "name_EN": "Phu Phan",
    "province_code": "47"
  },
  {
    "code": "4751",
    "name": "วานรนิวาส (สาขาตำบลกุดเรือคำ)*",
    "id": "5afae3c7ead44fb20f6c87ed",
    "name_EN": "Wanon Niwat",
    "province_code": "47"
  },
  {
    "code": "4781",
    "name": "*อ.บ้านหัน  จ.สกลนคร",
    "id": "5afae3c7ead44fb20f6c87ee",
    "name_EN": "*Banhan",
    "province_code": "47"
  },
  {
    "code": "4801",
    "name": "เมืองนครพนม",
    "id": "5afae3c7ead44fb20f6c87ef",
    "name_EN": "Mueang Nakhon Phanom",
    "province_code": "48"
  },
  {
    "code": "4802",
    "name": "ปลาปาก",
    "id": "5afae3c7ead44fb20f6c87f0",
    "name_EN": "Pla Pak",
    "province_code": "48"
  },
  {
    "code": "4803",
    "name": "ท่าอุเทน",
    "id": "5afae3c7ead44fb20f6c87f1",
    "name_EN": "Tha Uthen",
    "province_code": "48"
  },
  {
    "code": "4804",
    "name": "บ้านแพง",
    "id": "5afae3c7ead44fb20f6c87f2",
    "name_EN": "Ban Phaeng",
    "province_code": "48"
  },
  {
    "code": "4805",
    "name": "ธาตุพนม",
    "id": "5afae3c7ead44fb20f6c87f3",
    "name_EN": "That Phanom",
    "province_code": "48"
  },
  {
    "code": "4806",
    "name": "เรณูนคร",
    "id": "5afae3c7ead44fb20f6c87f4",
    "name_EN": "Renu Nakhon",
    "province_code": "48"
  },
  {
    "code": "4807",
    "name": "นาแก",
    "id": "5afae3c7ead44fb20f6c87f5",
    "name_EN": "Na Kae",
    "province_code": "48"
  },
  {
    "code": "4808",
    "name": "ศรีสงคราม",
    "id": "5afae3c7ead44fb20f6c87f6",
    "name_EN": "Si Songkhram",
    "province_code": "48"
  },
  {
    "code": "4809",
    "name": "นาหว้า",
    "id": "5afae3c7ead44fb20f6c87f7",
    "name_EN": "Na Wa",
    "province_code": "48"
  },
  {
    "code": "4810",
    "name": "โพนสวรรค์",
    "id": "5afae3c7ead44fb20f6c87f8",
    "name_EN": "Phon Sawan",
    "province_code": "48"
  },
  {
    "code": "4811",
    "name": "นาทม",
    "id": "5afae3c7ead44fb20f6c87f9",
    "name_EN": "Na Thom",
    "province_code": "48"
  },
  {
    "code": "4812",
    "name": "วังยาง",
    "id": "5afae3c7ead44fb20f6c87fa",
    "name_EN": "Wang Yang",
    "province_code": "48"
  },
  {
    "code": "4901",
    "name": "เมืองมุกดาหาร",
    "id": "5afae3c7ead44fb20f6c87fb",
    "name_EN": "Mueang Mukdahan",
    "province_code": "49"
  },
  {
    "code": "4902",
    "name": "นิคมคำสร้อย",
    "id": "5afae3c7ead44fb20f6c87fc",
    "name_EN": "Nikhom Kham Soi",
    "province_code": "49"
  },
  {
    "code": "4903",
    "name": "ดอนตาล",
    "id": "5afae3c7ead44fb20f6c87fd",
    "name_EN": "Don Tan",
    "province_code": "49"
  },
  {
    "code": "4904",
    "name": "ดงหลวง",
    "id": "5afae3c7ead44fb20f6c87fe",
    "name_EN": "Dong Luang",
    "province_code": "49"
  },
  {
    "code": "4905",
    "name": "คำชะอี",
    "id": "5afae3c7ead44fb20f6c87ff",
    "name_EN": "Khamcha-i",
    "province_code": "49"
  },
  {
    "code": "4906",
    "name": "หว้านใหญ่",
    "id": "5afae3c7ead44fb20f6c8800",
    "name_EN": "Wan Yai",
    "province_code": "49"
  },
  {
    "code": "4907",
    "name": "หนองสูง",
    "id": "5afae3c7ead44fb20f6c8801",
    "name_EN": "Nong Sung",
    "province_code": "49"
  },
  {
    "code": "5001",
    "name": "เมืองเชียงใหม่",
    "id": "5afae3c7ead44fb20f6c8802",
    "name_EN": "Mueang Chiang Mai",
    "province_code": "50"
  },
  {
    "code": "5002",
    "name": "จอมทอง",
    "id": "5afae3c7ead44fb20f6c8803",
    "name_EN": "Chom Thong",
    "province_code": "50"
  },
  {
    "code": "5003",
    "name": "แม่แจ่ม",
    "id": "5afae3c7ead44fb20f6c8804",
    "name_EN": "Mae Chaem",
    "province_code": "50"
  },
  {
    "code": "5004",
    "name": "เชียงดาว",
    "id": "5afae3c7ead44fb20f6c8805",
    "name_EN": "Chiang Dao",
    "province_code": "50"
  },
  {
    "code": "5005",
    "name": "ดอยสะเก็ด",
    "id": "5afae3c7ead44fb20f6c8806",
    "name_EN": "Doi Saket",
    "province_code": "50"
  },
  {
    "code": "5006",
    "name": "แม่แตง",
    "id": "5afae3c7ead44fb20f6c8807",
    "name_EN": "Mae Taeng",
    "province_code": "50"
  },
  {
    "code": "5007",
    "name": "แม่ริม",
    "id": "5afae3c7ead44fb20f6c8808",
    "name_EN": "Mae Rim",
    "province_code": "50"
  },
  {
    "code": "5008",
    "name": "สะเมิง",
    "id": "5afae3c7ead44fb20f6c8809",
    "name_EN": "Samoeng",
    "province_code": "50"
  },
  {
    "code": "5009",
    "name": "ฝาง",
    "id": "5afae3c7ead44fb20f6c880a",
    "name_EN": "Fang",
    "province_code": "50"
  },
  {
    "code": "5010",
    "name": "แม่อาย",
    "id": "5afae3c7ead44fb20f6c880b",
    "name_EN": "Mae Ai",
    "province_code": "50"
  },
  {
    "code": "5011",
    "name": "พร้าว",
    "id": "5afae3c7ead44fb20f6c880c",
    "name_EN": "Phrao",
    "province_code": "50"
  },
  {
    "code": "5012",
    "name": "สันป่าตอง",
    "id": "5afae3c7ead44fb20f6c880d",
    "name_EN": "San Pa Tong",
    "province_code": "50"
  },
  {
    "code": "5013",
    "name": "สันกำแพง",
    "id": "5afae3c7ead44fb20f6c880e",
    "name_EN": "San Kamphaeng",
    "province_code": "50"
  },
  {
    "code": "5014",
    "name": "สันทราย",
    "id": "5afae3c7ead44fb20f6c880f",
    "name_EN": "San Sai",
    "province_code": "50"
  },
  {
    "code": "5015",
    "name": "หางดง",
    "id": "5afae3c7ead44fb20f6c8810",
    "name_EN": "Hang Dong",
    "province_code": "50"
  },
  {
    "code": "5016",
    "name": "ฮอด",
    "id": "5afae3c7ead44fb20f6c8811",
    "name_EN": "Hot",
    "province_code": "50"
  },
  {
    "code": "5017",
    "name": "ดอยเต่า",
    "id": "5afae3c7ead44fb20f6c8812",
    "name_EN": "Doi Tao",
    "province_code": "50"
  },
  {
    "code": "5018",
    "name": "อมก๋อย",
    "id": "5afae3c7ead44fb20f6c8813",
    "name_EN": "Omkoi",
    "province_code": "50"
  },
  {
    "code": "5019",
    "name": "สารภี",
    "id": "5afae3c7ead44fb20f6c8814",
    "name_EN": "Saraphi",
    "province_code": "50"
  },
  {
    "code": "5020",
    "name": "เวียงแหง",
    "id": "5afae3c7ead44fb20f6c8815",
    "name_EN": "Wiang Haeng",
    "province_code": "50"
  },
  {
    "code": "5021",
    "name": "ไชยปราการ",
    "id": "5afae3c7ead44fb20f6c8816",
    "name_EN": "Chai Prakan",
    "province_code": "50"
  },
  {
    "code": "5022",
    "name": "แม่วาง",
    "id": "5afae3c7ead44fb20f6c8817",
    "name_EN": "Mae Wang",
    "province_code": "50"
  },
  {
    "code": "5023",
    "name": "แม่ออน",
    "id": "5afae3c7ead44fb20f6c8818",
    "name_EN": "Mae On",
    "province_code": "50"
  },
  {
    "code": "5024",
    "name": "ดอยหล่อ",
    "id": "5afae3c7ead44fb20f6c8819",
    "name_EN": "Doi Lo",
    "province_code": "50"
  },
  {
    "code": "5051",
    "name": "เทศบาลนครเชียงใหม่ (สาขาแขวงกาลวิละ)*",
    "id": "5afae3c7ead44fb20f6c881a",
    "name_EN": "Tet Saban Nakorn Chiangmai(Kan lawi la)*",
    "province_code": "50"
  },
  {
    "code": "5052",
    "name": "เทศบาลนครเชียงใหม่ (สาขาแขวงศรีวิชั)*",
    "id": "5afae3c7ead44fb20f6c881b",
    "name_EN": "Tet Saban Nakorn Chiangmai(Sri Wi)*",
    "province_code": "50"
  },
  {
    "code": "5053",
    "name": "เทศบาลนครเชียงใหม่ (สาขาเม็งราย)*",
    "id": "5afae3c7ead44fb20f6c881c",
    "name_EN": "Tet Saban Nakorn Chiangmai(Meng Rai)*",
    "province_code": "50"
  },
  {
    "code": "5101",
    "name": "เมืองลำพูน",
    "id": "5afae3c7ead44fb20f6c881d",
    "name_EN": "Mueang Lamphun",
    "province_code": "51"
  },
  {
    "code": "5102",
    "name": "แม่ทา",
    "id": "5afae3c7ead44fb20f6c881e",
    "name_EN": "Mae Tha",
    "province_code": "51"
  },
  {
    "code": "5103",
    "name": "บ้านโฮ่ง",
    "id": "5afae3c7ead44fb20f6c881f",
    "name_EN": "Ban Hong",
    "province_code": "51"
  },
  {
    "code": "5104",
    "name": "ลี้",
    "id": "5afae3c7ead44fb20f6c8820",
    "name_EN": "Li",
    "province_code": "51"
  },
  {
    "code": "5105",
    "name": "ทุ่งหัวช้าง",
    "id": "5afae3c7ead44fb20f6c8821",
    "name_EN": "Thung Hua Chang",
    "province_code": "51"
  },
  {
    "code": "5106",
    "name": "ป่าซาง",
    "id": "5afae3c7ead44fb20f6c8822",
    "name_EN": "Pa Sang",
    "province_code": "51"
  },
  {
    "code": "5107",
    "name": "บ้านธิ",
    "id": "5afae3c7ead44fb20f6c8823",
    "name_EN": "Ban Thi",
    "province_code": "51"
  },
  {
    "code": "5108",
    "name": "เวียงหนองล่อง",
    "id": "5afae3c7ead44fb20f6c8824",
    "name_EN": "Wiang Nong Long",
    "province_code": "51"
  },
  {
    "code": "5201",
    "name": "เมืองลำปาง",
    "id": "5afae3c7ead44fb20f6c8825",
    "name_EN": "Mueang Lampang",
    "province_code": "52"
  },
  {
    "code": "5202",
    "name": "แม่เมาะ",
    "id": "5afae3c7ead44fb20f6c8826",
    "name_EN": "Mae Mo",
    "province_code": "52"
  },
  {
    "code": "5203",
    "name": "เกาะคา",
    "id": "5afae3c7ead44fb20f6c8827",
    "name_EN": "Ko Kha",
    "province_code": "52"
  },
  {
    "code": "5204",
    "name": "เสริมงาม",
    "id": "5afae3c7ead44fb20f6c8828",
    "name_EN": "Soem Ngam",
    "province_code": "52"
  },
  {
    "code": "5205",
    "name": "งาว",
    "id": "5afae3c7ead44fb20f6c8829",
    "name_EN": "Ngao",
    "province_code": "52"
  },
  {
    "code": "5206",
    "name": "แจ้ห่ม",
    "id": "5afae3c7ead44fb20f6c882a",
    "name_EN": "Chae Hom",
    "province_code": "52"
  },
  {
    "code": "5207",
    "name": "วังเหนือ",
    "id": "5afae3c7ead44fb20f6c882b",
    "name_EN": "Wang Nuea",
    "province_code": "52"
  },
  {
    "code": "5208",
    "name": "เถิน",
    "id": "5afae3c7ead44fb20f6c882c",
    "name_EN": "Thoen",
    "province_code": "52"
  },
  {
    "code": "5209",
    "name": "แม่พริก",
    "id": "5afae3c7ead44fb20f6c882d",
    "name_EN": "Mae Phrik",
    "province_code": "52"
  },
  {
    "code": "5210",
    "name": "แม่ทะ",
    "id": "5afae3c7ead44fb20f6c882e",
    "name_EN": "Mae Tha",
    "province_code": "52"
  },
  {
    "code": "5211",
    "name": "สบปราบ",
    "id": "5afae3c7ead44fb20f6c882f",
    "name_EN": "Sop Prap",
    "province_code": "52"
  },
  {
    "code": "5212",
    "name": "ห้างฉัตร",
    "id": "5afae3c7ead44fb20f6c8830",
    "name_EN": "Hang Chat",
    "province_code": "52"
  },
  {
    "code": "5213",
    "name": "เมืองปาน",
    "id": "5afae3c7ead44fb20f6c8831",
    "name_EN": "Mueang Pan",
    "province_code": "52"
  },
  {
    "code": "5301",
    "name": "เมืองอุตรดิตถ์",
    "id": "5afae3c7ead44fb20f6c8832",
    "name_EN": "Mueang Uttaradit",
    "province_code": "53"
  },
  {
    "code": "5302",
    "name": "ตรอน",
    "id": "5afae3c7ead44fb20f6c8833",
    "name_EN": "Tron",
    "province_code": "53"
  },
  {
    "code": "5303",
    "name": "ท่าปลา",
    "id": "5afae3c7ead44fb20f6c8834",
    "name_EN": "Tha Pla",
    "province_code": "53"
  },
  {
    "code": "5304",
    "name": "น้ำปาด",
    "id": "5afae3c7ead44fb20f6c8835",
    "name_EN": "Nam Pat",
    "province_code": "53"
  },
  {
    "code": "5305",
    "name": "ฟากท่า",
    "id": "5afae3c7ead44fb20f6c8836",
    "name_EN": "Fak Tha",
    "province_code": "53"
  },
  {
    "code": "5306",
    "name": "บ้านโคก",
    "id": "5afae3c7ead44fb20f6c8837",
    "name_EN": "Ban Khok",
    "province_code": "53"
  },
  {
    "code": "5307",
    "name": "พิชัย",
    "id": "5afae3c7ead44fb20f6c8838",
    "name_EN": "Phichai",
    "province_code": "53"
  },
  {
    "code": "5308",
    "name": "ลับแล",
    "id": "5afae3c7ead44fb20f6c8839",
    "name_EN": "Laplae",
    "province_code": "53"
  },
  {
    "code": "5309",
    "name": "ทองแสนขัน",
    "id": "5afae3c7ead44fb20f6c883a",
    "name_EN": "Thong Saen Khan",
    "province_code": "53"
  },
  {
    "code": "5401",
    "name": "เมืองแพร่",
    "id": "5afae3c7ead44fb20f6c883b",
    "name_EN": "Mueang Phrae",
    "province_code": "54"
  },
  {
    "code": "5402",
    "name": "ร้องกวาง",
    "id": "5afae3c7ead44fb20f6c883c",
    "name_EN": "Rong Kwang",
    "province_code": "54"
  },
  {
    "code": "5403",
    "name": "ลอง",
    "id": "5afae3c7ead44fb20f6c883d",
    "name_EN": "Long",
    "province_code": "54"
  },
  {
    "code": "5404",
    "name": "สูงเม่น",
    "id": "5afae3c7ead44fb20f6c883e",
    "name_EN": "Sung Men",
    "province_code": "54"
  },
  {
    "code": "5405",
    "name": "เด่นชัย",
    "id": "5afae3c7ead44fb20f6c883f",
    "name_EN": "Den Chai",
    "province_code": "54"
  },
  {
    "code": "5406",
    "name": "สอง",
    "id": "5afae3c7ead44fb20f6c8840",
    "name_EN": "Song",
    "province_code": "54"
  },
  {
    "code": "5407",
    "name": "วังชิ้น",
    "id": "5afae3c7ead44fb20f6c8841",
    "name_EN": "Wang Chin",
    "province_code": "54"
  },
  {
    "code": "5408",
    "name": "หนองม่วงไข่",
    "id": "5afae3c7ead44fb20f6c8842",
    "name_EN": "Nong Muang Khai",
    "province_code": "54"
  },
  {
    "code": "5501",
    "name": "เมืองน่าน",
    "id": "5afae3c7ead44fb20f6c8843",
    "name_EN": "Mueang Nan",
    "province_code": "55"
  },
  {
    "code": "5502",
    "name": "แม่จริม",
    "id": "5afae3c7ead44fb20f6c8844",
    "name_EN": "Mae Charim",
    "province_code": "55"
  },
  {
    "code": "5503",
    "name": "บ้านหลวง",
    "id": "5afae3c7ead44fb20f6c8845",
    "name_EN": "Ban Luang",
    "province_code": "55"
  },
  {
    "code": "5504",
    "name": "นาน้อย",
    "id": "5afae3c7ead44fb20f6c8846",
    "name_EN": "Na Noi",
    "province_code": "55"
  },
  {
    "code": "5505",
    "name": "ปัว",
    "id": "5afae3c7ead44fb20f6c8847",
    "name_EN": "Pua",
    "province_code": "55"
  },
  {
    "code": "5506",
    "name": "ท่าวังผา",
    "id": "5afae3c7ead44fb20f6c8848",
    "name_EN": "Tha Wang Pha",
    "province_code": "55"
  },
  {
    "code": "5507",
    "name": "เวียงสา",
    "id": "5afae3c7ead44fb20f6c8849",
    "name_EN": "Wiang Sa",
    "province_code": "55"
  },
  {
    "code": "5508",
    "name": "ทุ่งช้าง",
    "id": "5afae3c7ead44fb20f6c884a",
    "name_EN": "Thung Chang",
    "province_code": "55"
  },
  {
    "code": "5509",
    "name": "เชียงกลาง",
    "id": "5afae3c7ead44fb20f6c884b",
    "name_EN": "Chiang Klang",
    "province_code": "55"
  },
  {
    "code": "5510",
    "name": "นาหมื่น",
    "id": "5afae3c7ead44fb20f6c884c",
    "name_EN": "Na Muen",
    "province_code": "55"
  },
  {
    "code": "5511",
    "name": "สันติสุข",
    "id": "5afae3c7ead44fb20f6c884d",
    "name_EN": "Santi Suk",
    "province_code": "55"
  },
  {
    "code": "5512",
    "name": "บ่อเกลือ",
    "id": "5afae3c7ead44fb20f6c884e",
    "name_EN": "Bo Kluea",
    "province_code": "55"
  },
  {
    "code": "5513",
    "name": "สองแคว",
    "id": "5afae3c7ead44fb20f6c884f",
    "name_EN": "Song Khwae",
    "province_code": "55"
  },
  {
    "code": "5514",
    "name": "ภูเพียง",
    "id": "5afae3c7ead44fb20f6c8850",
    "name_EN": "Phu Phiang",
    "province_code": "55"
  },
  {
    "code": "5515",
    "name": "เฉลิมพระเกียรติ",
    "id": "5afae3c7ead44fb20f6c8851",
    "name_EN": "Chaloem Phra Kiat",
    "province_code": "55"
  },
  {
    "code": "5601",
    "name": "เมืองพะเยา",
    "id": "5afae3c7ead44fb20f6c8852",
    "name_EN": "Mueang Phayao",
    "province_code": "56"
  },
  {
    "code": "5602",
    "name": "จุน",
    "id": "5afae3c7ead44fb20f6c8853",
    "name_EN": "Chun",
    "province_code": "56"
  },
  {
    "code": "5603",
    "name": "เชียงคำ",
    "id": "5afae3c7ead44fb20f6c8854",
    "name_EN": "Chiang Kham",
    "province_code": "56"
  },
  {
    "code": "5604",
    "name": "เชียงม่วน",
    "id": "5afae3c7ead44fb20f6c8855",
    "name_EN": "Chiang Muan",
    "province_code": "56"
  },
  {
    "code": "5605",
    "name": "ดอกคำใต้",
    "id": "5afae3c7ead44fb20f6c8856",
    "name_EN": "Dok Khamtai",
    "province_code": "56"
  },
  {
    "code": "5606",
    "name": "ปง",
    "id": "5afae3c7ead44fb20f6c8857",
    "name_EN": "Pong",
    "province_code": "56"
  },
  {
    "code": "5607",
    "name": "แม่ใจ",
    "id": "5afae3c7ead44fb20f6c8858",
    "name_EN": "Mae Chai",
    "province_code": "56"
  },
  {
    "code": "5608",
    "name": "ภูซาง",
    "id": "5afae3c7ead44fb20f6c8859",
    "name_EN": "Phu Sang",
    "province_code": "56"
  },
  {
    "code": "5609",
    "name": "ภูกามยาว",
    "id": "5afae3c7ead44fb20f6c885a",
    "name_EN": "Phu Kamyao",
    "province_code": "56"
  },
  {
    "code": "5701",
    "name": "เมืองเชียงราย",
    "id": "5afae3c7ead44fb20f6c885b",
    "name_EN": "Mueang Chiang Rai",
    "province_code": "57"
  },
  {
    "code": "5702",
    "name": "เวียงชัย",
    "id": "5afae3c7ead44fb20f6c885c",
    "name_EN": "Wiang Chai",
    "province_code": "57"
  },
  {
    "code": "5703",
    "name": "เชียงของ",
    "id": "5afae3c7ead44fb20f6c885d",
    "name_EN": "Chiang Khong",
    "province_code": "57"
  },
  {
    "code": "5704",
    "name": "เทิง",
    "id": "5afae3c7ead44fb20f6c885e",
    "name_EN": "Thoeng",
    "province_code": "57"
  },
  {
    "code": "5705",
    "name": "พาน",
    "id": "5afae3c7ead44fb20f6c885f",
    "name_EN": "Phan",
    "province_code": "57"
  },
  {
    "code": "5706",
    "name": "ป่าแดด",
    "id": "5afae3c7ead44fb20f6c8860",
    "name_EN": "Pa Daet",
    "province_code": "57"
  },
  {
    "code": "5707",
    "name": "แม่จัน",
    "id": "5afae3c7ead44fb20f6c8861",
    "name_EN": "Mae Chan",
    "province_code": "57"
  },
  {
    "code": "5708",
    "name": "เชียงแสน",
    "id": "5afae3c7ead44fb20f6c8862",
    "name_EN": "Chiang Saen",
    "province_code": "57"
  },
  {
    "code": "5709",
    "name": "แม่สาย",
    "id": "5afae3c7ead44fb20f6c8863",
    "name_EN": "Mae Sai",
    "province_code": "57"
  },
  {
    "code": "5710",
    "name": "แม่สรวย",
    "id": "5afae3c7ead44fb20f6c8864",
    "name_EN": "Mae Suai",
    "province_code": "57"
  },
  {
    "code": "5711",
    "name": "เวียงป่าเป้า",
    "id": "5afae3c7ead44fb20f6c8865",
    "name_EN": "Wiang Pa Pao",
    "province_code": "57"
  },
  {
    "code": "5712",
    "name": "พญาเม็งราย",
    "id": "5afae3c7ead44fb20f6c8866",
    "name_EN": "Phaya Mengrai",
    "province_code": "57"
  },
  {
    "code": "5713",
    "name": "เวียงแก่น",
    "id": "5afae3c7ead44fb20f6c8867",
    "name_EN": "Wiang Kaen",
    "province_code": "57"
  },
  {
    "code": "5714",
    "name": "ขุนตาล",
    "id": "5afae3c7ead44fb20f6c8868",
    "name_EN": "Khun Tan",
    "province_code": "57"
  },
  {
    "code": "5715",
    "name": "แม่ฟ้าหลวง",
    "id": "5afae3c7ead44fb20f6c8869",
    "name_EN": "Mae Fa Luang",
    "province_code": "57"
  },
  {
    "code": "5716",
    "name": "แม่ลาว",
    "id": "5afae3c7ead44fb20f6c886a",
    "name_EN": "Mae Lao",
    "province_code": "57"
  },
  {
    "code": "5717",
    "name": "เวียงเชียงรุ้ง",
    "id": "5afae3c7ead44fb20f6c886b",
    "name_EN": "Wiang Chiang Rung",
    "province_code": "57"
  },
  {
    "code": "5718",
    "name": "ดอยหลวง",
    "id": "5afae3c7ead44fb20f6c886c",
    "name_EN": "Doi Luang",
    "province_code": "57"
  },
  {
    "code": "5801",
    "name": "เมืองแม่ฮ่องสอน",
    "id": "5afae3c7ead44fb20f6c886d",
    "name_EN": "Mueang Mae Hong Son",
    "province_code": "58"
  },
  {
    "code": "5802",
    "name": "ขุนยวม",
    "id": "5afae3c7ead44fb20f6c886e",
    "name_EN": "Khun Yuam",
    "province_code": "58"
  },
  {
    "code": "5803",
    "name": "ปาย",
    "id": "5afae3c7ead44fb20f6c886f",
    "name_EN": "Pai",
    "province_code": "58"
  },
  {
    "code": "5804",
    "name": "แม่สะเรียง",
    "id": "5afae3c7ead44fb20f6c8870",
    "name_EN": "Mae Sariang",
    "province_code": "58"
  },
  {
    "code": "5805",
    "name": "แม่ลาน้อย",
    "id": "5afae3c7ead44fb20f6c8871",
    "name_EN": "Mae La Noi",
    "province_code": "58"
  },
  {
    "code": "5806",
    "name": "สบเมย",
    "id": "5afae3c7ead44fb20f6c8872",
    "name_EN": "Sop Moei",
    "province_code": "58"
  },
  {
    "code": "5807",
    "name": "ปางมะผ้า",
    "id": "5afae3c7ead44fb20f6c8873",
    "name_EN": "Pang Mapha",
    "province_code": "58"
  },
  {
    "code": "5881",
    "name": "*อ.ม่วยต่อ  จ.แม่ฮ่องสอน",
    "id": "5afae3c7ead44fb20f6c8874",
    "name_EN": "Muen Tor",
    "province_code": "58"
  },
  {
    "code": "6001",
    "name": "เมืองนครสวรรค์",
    "id": "5afae3c7ead44fb20f6c8875",
    "name_EN": "Mueang Nakhon Sawan",
    "province_code": "60"
  },
  {
    "code": "6002",
    "name": "โกรกพระ",
    "id": "5afae3c7ead44fb20f6c8876",
    "name_EN": "Krok Phra",
    "province_code": "60"
  },
  {
    "code": "6003",
    "name": "ชุมแสง",
    "id": "5afae3c7ead44fb20f6c8877",
    "name_EN": "Chum Saeng",
    "province_code": "60"
  },
  {
    "code": "6004",
    "name": "หนองบัว",
    "id": "5afae3c7ead44fb20f6c8878",
    "name_EN": "Nong Bua",
    "province_code": "60"
  },
  {
    "code": "6005",
    "name": "บรรพตพิสัย",
    "id": "5afae3c7ead44fb20f6c8879",
    "name_EN": "Banphot Phisai",
    "province_code": "60"
  },
  {
    "code": "6006",
    "name": "เก้าเลี้ยว",
    "id": "5afae3c7ead44fb20f6c887a",
    "name_EN": "Kao Liao",
    "province_code": "60"
  },
  {
    "code": "6007",
    "name": "ตาคลี",
    "id": "5afae3c7ead44fb20f6c887b",
    "name_EN": "Takhli",
    "province_code": "60"
  },
  {
    "code": "6008",
    "name": "ท่าตะโก",
    "id": "5afae3c7ead44fb20f6c887c",
    "name_EN": "Takhli",
    "province_code": "60"
  },
  {
    "code": "6009",
    "name": "ไพศาลี",
    "id": "5afae3c7ead44fb20f6c887d",
    "name_EN": "Phaisali",
    "province_code": "60"
  },
  {
    "code": "6010",
    "name": "พยุหะคีรี",
    "id": "5afae3c7ead44fb20f6c887e",
    "name_EN": "Phayuha Khiri",
    "province_code": "60"
  },
  {
    "code": "6011",
    "name": "ลาดยาว",
    "id": "5afae3c7ead44fb20f6c887f",
    "name_EN": "Phayuha Khiri",
    "province_code": "60"
  },
  {
    "code": "6012",
    "name": "ตากฟ้า",
    "id": "5afae3c7ead44fb20f6c8880",
    "name_EN": "Tak Fa",
    "province_code": "60"
  },
  {
    "code": "6013",
    "name": "แม่วงก์",
    "id": "5afae3c7ead44fb20f6c8881",
    "name_EN": "Mae Wong",
    "province_code": "60"
  },
  {
    "code": "6014",
    "name": "แม่เปิน",
    "id": "5afae3c7ead44fb20f6c8882",
    "name_EN": "Mae Poen",
    "province_code": "60"
  },
  {
    "code": "6015",
    "name": "ชุมตาบง",
    "id": "5afae3c7ead44fb20f6c8883",
    "name_EN": "Chum Ta Bong",
    "province_code": "60"
  },
  {
    "code": "6051",
    "name": "สาขาตำบลห้วยน้ำหอม*",
    "id": "5afae3c7ead44fb20f6c8884",
    "name_EN": "Huen Nam Hom",
    "province_code": "60"
  },
  {
    "code": "6052",
    "name": "กิ่งอำเภอชุมตาบง (สาขาตำบลชุมตาบง)*",
    "id": "5afae3c7ead44fb20f6c8885",
    "name_EN": "Chum Ta Bong",
    "province_code": "60"
  },
  {
    "code": "6053",
    "name": "แม่วงก์ (สาขาตำบลแม่เล่ย์)*",
    "id": "5afae3c7ead44fb20f6c8886",
    "name_EN": "Mea Ley",
    "province_code": "60"
  },
  {
    "code": "6101",
    "name": "เมืองอุทัยธานี",
    "id": "5afae3c7ead44fb20f6c8887",
    "name_EN": "Mueang Uthai Thani",
    "province_code": "61"
  },
  {
    "code": "6102",
    "name": "ทัพทัน",
    "id": "5afae3c7ead44fb20f6c8888",
    "name_EN": "Thap Than",
    "province_code": "61"
  },
  {
    "code": "6103",
    "name": "สว่างอารมณ์",
    "id": "5afae3c7ead44fb20f6c8889",
    "name_EN": "Sawang Arom",
    "province_code": "61"
  },
  {
    "code": "6104",
    "name": "หนองฉาง",
    "id": "5afae3c7ead44fb20f6c888a",
    "name_EN": "Nong Chang",
    "province_code": "61"
  },
  {
    "code": "6105",
    "name": "หนองขาหย่าง",
    "id": "5afae3c7ead44fb20f6c888b",
    "name_EN": "Nong Khayang",
    "province_code": "61"
  },
  {
    "code": "6106",
    "name": "บ้านไร่",
    "id": "5afae3c7ead44fb20f6c888c",
    "name_EN": "Ban Rai",
    "province_code": "61"
  },
  {
    "code": "6107",
    "name": "ลานสัก",
    "id": "5afae3c7ead44fb20f6c888d",
    "name_EN": "Lan Sak",
    "province_code": "61"
  },
  {
    "code": "6108",
    "name": "ห้วยคต",
    "id": "5afae3c7ead44fb20f6c888e",
    "name_EN": "Huai Khot",
    "province_code": "61"
  },
  {
    "code": "6201",
    "name": "เมืองกำแพงเพชร",
    "id": "5afae3c7ead44fb20f6c888f",
    "name_EN": "Mueang Kamphaeng Phet",
    "province_code": "62"
  },
  {
    "code": "6202",
    "name": "ไทรงาม",
    "id": "5afae3c7ead44fb20f6c8890",
    "name_EN": "Sai Ngam",
    "province_code": "62"
  },
  {
    "code": "6203",
    "name": "คลองลาน",
    "id": "5afae3c7ead44fb20f6c8891",
    "name_EN": "Khlong Lan",
    "province_code": "62"
  },
  {
    "code": "6204",
    "name": "ขาณุวรลักษบุรี",
    "id": "5afae3c7ead44fb20f6c8892",
    "name_EN": "Khanu Woralaksaburi",
    "province_code": "62"
  },
  {
    "code": "6205",
    "name": "คลองขลุง",
    "id": "5afae3c7ead44fb20f6c8893",
    "name_EN": "Khlong Khlung",
    "province_code": "62"
  },
  {
    "code": "6206",
    "name": "พรานกระต่าย",
    "id": "5afae3c7ead44fb20f6c8894",
    "name_EN": "Phran Kratai",
    "province_code": "62"
  },
  {
    "code": "6207",
    "name": "ลานกระบือ",
    "id": "5afae3c7ead44fb20f6c8895",
    "name_EN": "Lan Krabue",
    "province_code": "62"
  },
  {
    "code": "6208",
    "name": "ทรายทองวัฒนา",
    "id": "5afae3c7ead44fb20f6c8896",
    "name_EN": "Sai Thong Watthana",
    "province_code": "62"
  },
  {
    "code": "6209",
    "name": "ปางศิลาทอง",
    "id": "5afae3c7ead44fb20f6c8897",
    "name_EN": "Pang Sila Thong",
    "province_code": "62"
  },
  {
    "code": "6210",
    "name": "บึงสามัคคี",
    "id": "5afae3c7ead44fb20f6c8898",
    "name_EN": "Bueng Samakkhi",
    "province_code": "62"
  },
  {
    "code": "6211",
    "name": "โกสัมพีนคร",
    "id": "5afae3c7ead44fb20f6c8899",
    "name_EN": "Kosamphi Nakhon",
    "province_code": "62"
  },
  {
    "code": "6301",
    "name": "เมืองตาก",
    "id": "5afae3c7ead44fb20f6c889a",
    "name_EN": "Mueang Tak",
    "province_code": "63"
  },
  {
    "code": "6302",
    "name": "บ้านตาก",
    "id": "5afae3c7ead44fb20f6c889b",
    "name_EN": "Ban Tak",
    "province_code": "63"
  },
  {
    "code": "6303",
    "name": "สามเงา",
    "id": "5afae3c7ead44fb20f6c889c",
    "name_EN": "Sam Ngao",
    "province_code": "63"
  },
  {
    "code": "6304",
    "name": "แม่ระมาด",
    "id": "5afae3c7ead44fb20f6c889d",
    "name_EN": "Mae Ramat",
    "province_code": "63"
  },
  {
    "code": "6305",
    "name": "ท่าสองยาง",
    "id": "5afae3c7ead44fb20f6c889e",
    "name_EN": "Tha Song Yang",
    "province_code": "63"
  },
  {
    "code": "6306",
    "name": "แม่สอด",
    "id": "5afae3c7ead44fb20f6c889f",
    "name_EN": "Mae Sot",
    "province_code": "63"
  },
  {
    "code": "6307",
    "name": "พบพระ",
    "id": "5afae3c7ead44fb20f6c88a0",
    "name_EN": "Phop Phra",
    "province_code": "63"
  },
  {
    "code": "6308",
    "name": "อุ้มผาง",
    "id": "5afae3c7ead44fb20f6c88a1",
    "name_EN": "Umphang",
    "province_code": "63"
  },
  {
    "code": "6309",
    "name": "วังเจ้า",
    "id": "5afae3c7ead44fb20f6c88a2",
    "name_EN": "Wang Chao",
    "province_code": "63"
  },
  {
    "code": "6381",
    "name": "*กิ่ง อ.ท่าปุย  จ.ตาก",
    "id": "5afae3c7ead44fb20f6c88a3",
    "name_EN": "*King Ta Pui",
    "province_code": "63"
  },
  {
    "code": "6401",
    "name": "เมืองสุโขทัย",
    "id": "5afae3c7ead44fb20f6c88a4",
    "name_EN": "Mueang Sukhothai",
    "province_code": "64"
  },
  {
    "code": "6402",
    "name": "บ้านด่านลานหอย",
    "id": "5afae3c7ead44fb20f6c88a5",
    "name_EN": "Ban Dan Lan Hoi",
    "province_code": "64"
  },
  {
    "code": "6403",
    "name": "คีรีมาศ",
    "id": "5afae3c7ead44fb20f6c88a6",
    "name_EN": "Khiri Mat",
    "province_code": "64"
  },
  {
    "code": "6404",
    "name": "กงไกรลาศ",
    "id": "5afae3c7ead44fb20f6c88a7",
    "name_EN": "Kong Krailat",
    "province_code": "64"
  },
  {
    "code": "6405",
    "name": "ศรีสัชนาลัย",
    "id": "5afae3c7ead44fb20f6c88a8",
    "name_EN": "Si Satchanalai",
    "province_code": "64"
  },
  {
    "code": "6406",
    "name": "ศรีสำโรง",
    "id": "5afae3c7ead44fb20f6c88a9",
    "name_EN": "Si Samrong",
    "province_code": "64"
  },
  {
    "code": "6407",
    "name": "สวรรคโลก",
    "id": "5afae3c7ead44fb20f6c88aa",
    "name_EN": "Sawankhalok",
    "province_code": "43"
  },
  {
    "code": "6408",
    "name": "ศรีนคร",
    "id": "5afae3c7ead44fb20f6c88ab",
    "name_EN": "Si Nakhon",
    "province_code": "64"
  },
  {
    "code": "6409",
    "name": "ทุ่งเสลี่ยม",
    "id": "5afae3c7ead44fb20f6c88ac",
    "name_EN": "Thung Saliam",
    "province_code": "64"
  },
  {
    "code": "6501",
    "name": "เมืองพิษณุโลก",
    "id": "5afae3c7ead44fb20f6c88ad",
    "name_EN": "Mueang Phitsanulok",
    "province_code": "65"
  },
  {
    "code": "6502",
    "name": "นครไทย",
    "id": "5afae3c7ead44fb20f6c88ae",
    "name_EN": "Nakhon Thai",
    "province_code": "65"
  },
  {
    "code": "6503",
    "name": "ชาติตระการ",
    "id": "5afae3c7ead44fb20f6c88af",
    "name_EN": "Chat Trakan",
    "province_code": "65"
  },
  {
    "code": "6504",
    "name": "บางระกำ",
    "id": "5afae3c7ead44fb20f6c88b0",
    "name_EN": "Bang Rakam",
    "province_code": "65"
  },
  {
    "code": "6505",
    "name": "บางกระทุ่ม",
    "id": "5afae3c7ead44fb20f6c88b1",
    "name_EN": "Bang Krathum",
    "province_code": "65"
  },
  {
    "code": "6506",
    "name": "พรหมพิราม",
    "id": "5afae3c7ead44fb20f6c88b2",
    "name_EN": "Phrom Phiram",
    "province_code": "65"
  },
  {
    "code": "6507",
    "name": "วัดโบสถ์",
    "id": "5afae3c7ead44fb20f6c88b3",
    "name_EN": "Wat Bot",
    "province_code": "65"
  },
  {
    "code": "6508",
    "name": "วังทอง",
    "id": "5afae3c7ead44fb20f6c88b4",
    "name_EN": "Wang Thong",
    "province_code": "65"
  },
  {
    "code": "6509",
    "name": "เนินมะปราง",
    "id": "5afae3c7ead44fb20f6c88b5",
    "name_EN": "Noen Maprang",
    "province_code": "65"
  },
  {
    "code": "6601",
    "name": "เมืองพิจิตร",
    "id": "5afae3c7ead44fb20f6c88b6",
    "name_EN": "Mueang Phichit",
    "province_code": "66"
  },
  {
    "code": "6602",
    "name": "วังทรายพูน",
    "id": "5afae3c7ead44fb20f6c88b7",
    "name_EN": "Wang Sai Phun",
    "province_code": "66"
  },
  {
    "code": "6603",
    "name": "โพธิ์ประทับช้าง",
    "id": "5afae3c7ead44fb20f6c88b8",
    "name_EN": "Pho Prathap Chang",
    "province_code": "66"
  },
  {
    "code": "6604",
    "name": "ตะพานหิน",
    "id": "5afae3c7ead44fb20f6c88b9",
    "name_EN": "Taphan Hin",
    "province_code": "66"
  },
  {
    "code": "6605",
    "name": "บางมูลนาก",
    "id": "5afae3c7ead44fb20f6c88ba",
    "name_EN": "Bang Mun Nak",
    "province_code": "66"
  },
  {
    "code": "6606",
    "name": "โพทะเล",
    "id": "5afae3c7ead44fb20f6c88bb",
    "name_EN": "Pho Thale",
    "province_code": "66"
  },
  {
    "code": "6607",
    "name": "สามง่าม",
    "id": "5afae3c7ead44fb20f6c88bc",
    "name_EN": "Sam Ngam",
    "province_code": "66"
  },
  {
    "code": "6608",
    "name": "ทับคล้อ",
    "id": "5afae3c7ead44fb20f6c88bd",
    "name_EN": "Tap Khlo",
    "province_code": "66"
  },
  {
    "code": "6609",
    "name": "สากเหล็ก",
    "id": "5afae3c7ead44fb20f6c88be",
    "name_EN": "Sak Lek",
    "province_code": "66"
  },
  {
    "code": "6610",
    "name": "บึงนาราง",
    "id": "5afae3c7ead44fb20f6c88bf",
    "name_EN": "Bueng Na Rang",
    "province_code": "66"
  },
  {
    "code": "6611",
    "name": "ดงเจริญ",
    "id": "5afae3c7ead44fb20f6c88c0",
    "name_EN": "Dong Charoen",
    "province_code": "66"
  },
  {
    "code": "6612",
    "name": "วชิรบารมี",
    "id": "5afae3c7ead44fb20f6c88c1",
    "name_EN": "Wachirabarami",
    "province_code": "66"
  },
  {
    "code": "6701",
    "name": "เมืองเพชรบูรณ์",
    "id": "5afae3c7ead44fb20f6c88c2",
    "name_EN": "Mueang Phetchabun",
    "province_code": "67"
  },
  {
    "code": "6702",
    "name": "ชนแดน",
    "id": "5afae3c7ead44fb20f6c88c3",
    "name_EN": "Chon Daen",
    "province_code": "67"
  },
  {
    "code": "6703",
    "name": "หล่มสัก",
    "id": "5afae3c7ead44fb20f6c88c4",
    "name_EN": "Lom Sak",
    "province_code": "67"
  },
  {
    "code": "6704",
    "name": "หล่มเก่า",
    "id": "5afae3c7ead44fb20f6c88c5",
    "name_EN": "Lom Kao",
    "province_code": "67"
  },
  {
    "code": "6705",
    "name": "วิเชียรบุรี",
    "id": "5afae3c7ead44fb20f6c88c6",
    "name_EN": "Wichian Buri",
    "province_code": "67"
  },
  {
    "code": "6706",
    "name": "ศรีเทพ",
    "id": "5afae3c7ead44fb20f6c88c7",
    "name_EN": "Si Thep",
    "province_code": "67"
  },
  {
    "code": "6707",
    "name": "หนองไผ่",
    "id": "5afae3c7ead44fb20f6c88c8",
    "name_EN": "Nong Phai",
    "province_code": "67"
  },
  {
    "code": "6708",
    "name": "บึงสามพัน",
    "id": "5afae3c7ead44fb20f6c88c9",
    "name_EN": "Bueng Sam Phan",
    "province_code": "67"
  },
  {
    "code": "6709",
    "name": "น้ำหนาว",
    "id": "5afae3c7ead44fb20f6c88ca",
    "name_EN": "Nam Nao",
    "province_code": "67"
  },
  {
    "code": "6710",
    "name": "วังโป่ง",
    "id": "5afae3c7ead44fb20f6c88cb",
    "name_EN": "Wang Pong",
    "province_code": "67"
  },
  {
    "code": "6711",
    "name": "เขาค้อ",
    "id": "5afae3c7ead44fb20f6c88cc",
    "name_EN": "Khao Kho",
    "province_code": "67"
  },
  {
    "code": "7001",
    "name": "เมืองราชบุรี",
    "id": "5afae3c7ead44fb20f6c88cd",
    "name_EN": "Mueang Ratchaburi",
    "province_code": "70"
  },
  {
    "code": "7002",
    "name": "จอมบึง",
    "id": "5afae3c7ead44fb20f6c88ce",
    "name_EN": "Chom Bueng",
    "province_code": "70"
  },
  {
    "code": "7003",
    "name": "สวนผึ้ง",
    "id": "5afae3c7ead44fb20f6c88cf",
    "name_EN": "Suan Phueng",
    "province_code": "70"
  },
  {
    "code": "7004",
    "name": "ดำเนินสะดวก",
    "id": "5afae3c7ead44fb20f6c88d0",
    "name_EN": "Damnoen Saduak",
    "province_code": "70"
  },
  {
    "code": "7005",
    "name": "บ้านโป่ง",
    "id": "5afae3c7ead44fb20f6c88d1",
    "name_EN": "Ban Pong",
    "province_code": "70"
  },
  {
    "code": "7006",
    "name": "บางแพ",
    "id": "5afae3c7ead44fb20f6c88d2",
    "name_EN": "Bang Phae",
    "province_code": "70"
  },
  {
    "code": "7007",
    "name": "โพธาราม",
    "id": "5afae3c7ead44fb20f6c88d3",
    "name_EN": "Photharam",
    "province_code": "70"
  },
  {
    "code": "7008",
    "name": "ปากท่อ",
    "id": "5afae3c7ead44fb20f6c88d4",
    "name_EN": "Pak Tho",
    "province_code": "70"
  },
  {
    "code": "7009",
    "name": "วัดเพลง",
    "id": "5afae3c7ead44fb20f6c88d5",
    "name_EN": "Wat Phleng",
    "province_code": "70"
  },
  {
    "code": "7010",
    "name": "บ้านคา",
    "id": "5afae3c7ead44fb20f6c88d6",
    "name_EN": "Ban Kha",
    "province_code": "70"
  },
  {
    "code": "7074",
    "name": "ท้องถิ่นเทศบาลตำบลบ้านฆ้อง",
    "id": "5afae3c7ead44fb20f6c88d7",
    "name_EN": "Tet Saban Ban Kong",
    "province_code": "70"
  },
  {
    "code": "7101",
    "name": "เมืองกาญจนบุรี",
    "id": "5afae3c7ead44fb20f6c88d8",
    "name_EN": "Mueang Kanchanaburi",
    "province_code": "71"
  },
  {
    "code": "7102",
    "name": "ไทรโยค",
    "id": "5afae3c7ead44fb20f6c88d9",
    "name_EN": "Sai Yok",
    "province_code": "71"
  },
  {
    "code": "7103",
    "name": "บ่อพลอย",
    "id": "5afae3c7ead44fb20f6c88da",
    "name_EN": "Bo Phloi",
    "province_code": "71"
  },
  {
    "code": "7104",
    "name": "ศรีสวัสดิ์",
    "id": "5afae3c7ead44fb20f6c88db",
    "name_EN": "Si Sawat",
    "province_code": "71"
  },
  {
    "code": "7105",
    "name": "ท่ามะกา",
    "id": "5afae3c7ead44fb20f6c88dc",
    "name_EN": "Tha Maka",
    "province_code": "71"
  },
  {
    "code": "7106",
    "name": "ท่าม่วง",
    "id": "5afae3c7ead44fb20f6c88dd",
    "name_EN": "Tha Muang",
    "province_code": "71"
  },
  {
    "code": "7107",
    "name": "ทองผาภูมิ",
    "id": "5afae3c7ead44fb20f6c88de",
    "name_EN": "Pha Phum",
    "province_code": "71"
  },
  {
    "code": "7108",
    "name": "สังขละบุรี",
    "id": "5afae3c7ead44fb20f6c88df",
    "name_EN": "Sangkhla Buri",
    "province_code": "71"
  },
  {
    "code": "7109",
    "name": "พนมทวน",
    "id": "5afae3c7ead44fb20f6c88e0",
    "name_EN": "Phanom Thuan",
    "province_code": "71"
  },
  {
    "code": "7110",
    "name": "เลาขวัญ",
    "id": "5afae3c7ead44fb20f6c88e1",
    "name_EN": "Lao Khwan",
    "province_code": "71"
  },
  {
    "code": "7111",
    "name": "ด่านมะขามเตี้ย",
    "id": "5afae3c7ead44fb20f6c88e2",
    "name_EN": "Dan Makham Tia",
    "province_code": "71"
  },
  {
    "code": "7112",
    "name": "หนองปรือ",
    "id": "5afae3c7ead44fb20f6c88e3",
    "name_EN": "Nong Prue",
    "province_code": "71"
  },
  {
    "code": "7113",
    "name": "ห้วยกระเจา",
    "id": "5afae3c7ead44fb20f6c88e4",
    "name_EN": "Huai Krachao",
    "province_code": "71"
  },
  {
    "code": "7151",
    "name": "สาขาตำบลท่ากระดาน*",
    "id": "5afae3c7ead44fb20f6c88e5",
    "name_EN": "Tha Kra Dan",
    "province_code": "71"
  },
  {
    "code": "7181",
    "name": "*บ้านทวน  จ.กาญจนบุรี",
    "id": "5afae3c7ead44fb20f6c88e6",
    "name_EN": "*Ban Tuan",
    "province_code": "71"
  },
  {
    "code": "7201",
    "name": "เมืองสุพรรณบุรี",
    "id": "5afae3c7ead44fb20f6c88e7",
    "name_EN": "Mueang Suphan Buri",
    "province_code": "72"
  },
  {
    "code": "7202",
    "name": "เดิมบางนางบวช",
    "id": "5afae3c7ead44fb20f6c88e8",
    "name_EN": "Doem Bang Nang Buat",
    "province_code": "72"
  },
  {
    "code": "7203",
    "name": "ด่านช้าง",
    "id": "5afae3c7ead44fb20f6c88e9",
    "name_EN": "Dan Chang",
    "province_code": "72"
  },
  {
    "code": "7204",
    "name": "บางปลาม้า",
    "id": "5afae3c7ead44fb20f6c88ea",
    "name_EN": "Bang Pla Ma",
    "province_code": "72"
  },
  {
    "code": "7205",
    "name": "ศรีประจันต์",
    "id": "5afae3c7ead44fb20f6c88eb",
    "name_EN": "Si Prachan",
    "province_code": "72"
  },
  {
    "code": "7206",
    "name": "ดอนเจดีย์",
    "id": "5afae3c7ead44fb20f6c88ec",
    "name_EN": "Don Chedi",
    "province_code": "72"
  },
  {
    "code": "7207",
    "name": "สองพี่น้อง",
    "id": "5afae3c7ead44fb20f6c88ed",
    "name_EN": "Song Phi Nong",
    "province_code": "72"
  },
  {
    "code": "7208",
    "name": "สามชุก",
    "id": "5afae3c7ead44fb20f6c88ee",
    "name_EN": "Sam Chuk",
    "province_code": "72"
  },
  {
    "code": "7209",
    "name": "อู่ทอง",
    "id": "5afae3c7ead44fb20f6c88ef",
    "name_EN": "U Thong",
    "province_code": "72"
  },
  {
    "code": "7210",
    "name": "หนองหญ้าไซ",
    "id": "5afae3c7ead44fb20f6c88f0",
    "name_EN": "Nong Ya Sai",
    "province_code": "72"
  },
  {
    "code": "7301",
    "name": "เมืองนครปฐม",
    "id": "5afae3c7ead44fb20f6c88f1",
    "name_EN": "Mueang Nakhon Pathom",
    "province_code": "73"
  },
  {
    "code": "7302",
    "name": "กำแพงแสน",
    "id": "5afae3c7ead44fb20f6c88f2",
    "name_EN": "Kamphaeng Saen",
    "province_code": "73"
  },
  {
    "code": "7303",
    "name": "นครชัยศรี",
    "id": "5afae3c7ead44fb20f6c88f3",
    "name_EN": "Nakhon Chai Si",
    "province_code": "73"
  },
  {
    "code": "7304",
    "name": "ดอนตูม",
    "id": "5afae3c7ead44fb20f6c88f4",
    "name_EN": "Don Tum",
    "province_code": "73"
  },
  {
    "code": "7305",
    "name": "บางเลน",
    "id": "5afae3c7ead44fb20f6c88f5",
    "name_EN": "Bang Len",
    "province_code": "73"
  },
  {
    "code": "7306",
    "name": "สามพราน",
    "id": "5afae3c7ead44fb20f6c88f6",
    "name_EN": "Sam Phran",
    "province_code": "73"
  },
  {
    "code": "7307",
    "name": "พุทธมณฑล",
    "id": "5afae3c7ead44fb20f6c88f7",
    "name_EN": "Phutthamonthon",
    "province_code": "73"
  },
  {
    "code": "7401",
    "name": "เมืองสมุทรสาคร",
    "id": "5afae3c7ead44fb20f6c88f8",
    "name_EN": "Mueang Samut Sakhon",
    "province_code": "74"
  },
  {
    "code": "7402",
    "name": "กระทุ่มแบน",
    "id": "5afae3c7ead44fb20f6c88f9",
    "name_EN": "Krathum Baen",
    "province_code": "74"
  },
  {
    "code": "7403",
    "name": "บ้านแพ้ว",
    "id": "5afae3c7ead44fb20f6c88fa",
    "name_EN": "Ban Phaeo",
    "province_code": "74"
  },
  {
    "code": "7501",
    "name": "เมืองสมุทรสงคราม",
    "id": "5afae3c7ead44fb20f6c88fb",
    "name_EN": "Mueang Samut Songkhram",
    "province_code": "75"
  },
  {
    "code": "7502",
    "name": "บางคนที",
    "id": "5afae3c7ead44fb20f6c88fc",
    "name_EN": "Bang Khonthi",
    "province_code": "75"
  },
  {
    "code": "7503",
    "name": "อัมพวา",
    "id": "5afae3c7ead44fb20f6c88fd",
    "name_EN": "Amphawa",
    "province_code": "75"
  },
  {
    "code": "7601",
    "name": "เมืองเพชรบุรี",
    "id": "5afae3c7ead44fb20f6c88fe",
    "name_EN": "Mueang Phetchaburi",
    "province_code": "76"
  },
  {
    "code": "7602",
    "name": "เขาย้อย",
    "id": "5afae3c7ead44fb20f6c88ff",
    "name_EN": "Khao Yoi",
    "province_code": "76"
  },
  {
    "code": "7603",
    "name": "หนองหญ้าปล้อง",
    "id": "5afae3c7ead44fb20f6c8900",
    "name_EN": "Nong Ya Plong",
    "province_code": "76"
  },
  {
    "code": "7604",
    "name": "ชะอำ",
    "id": "5afae3c7ead44fb20f6c8901",
    "name_EN": "Cha-am",
    "province_code": "76"
  },
  {
    "code": "7605",
    "name": "ท่ายาง",
    "id": "5afae3c7ead44fb20f6c8902",
    "name_EN": "Tha Yang",
    "province_code": "76"
  },
  {
    "code": "7606",
    "name": "บ้านลาด",
    "id": "5afae3c7ead44fb20f6c8903",
    "name_EN": "Ban Lat",
    "province_code": "76"
  },
  {
    "code": "7607",
    "name": "บ้านแหลม",
    "id": "5afae3c7ead44fb20f6c8904",
    "name_EN": "Ban Laem",
    "province_code": "76"
  },
  {
    "code": "7608",
    "name": "แก่งกระจาน",
    "id": "5afae3c7ead44fb20f6c8905",
    "name_EN": "Kaeng Krachan",
    "province_code": "76"
  },
  {
    "code": "7701",
    "name": "เมืองประจวบคีรีขันธ์",
    "id": "5afae3c7ead44fb20f6c8906",
    "name_EN": "Mueang Prachuap Khiri Khan",
    "province_code": "77"
  },
  {
    "code": "7702",
    "name": "กุยบุรี",
    "id": "5afae3c7ead44fb20f6c8907",
    "name_EN": "Kui Buri",
    "province_code": "77"
  },
  {
    "code": "7703",
    "name": "ทับสะแก",
    "id": "5afae3c7ead44fb20f6c8908",
    "name_EN": "Thap Sakae",
    "province_code": "77"
  },
  {
    "code": "7704",
    "name": "บางสะพาน",
    "id": "5afae3c7ead44fb20f6c8909",
    "name_EN": "Bang Saphan",
    "province_code": "77"
  },
  {
    "code": "7705",
    "name": "บางสะพานน้อย",
    "id": "5afae3c7ead44fb20f6c890a",
    "name_EN": "Bang Saphan Noi",
    "province_code": "77"
  },
  {
    "code": "7706",
    "name": "ปราณบุรี",
    "id": "5afae3c7ead44fb20f6c890b",
    "name_EN": "Pran Buri",
    "province_code": "77"
  },
  {
    "code": "7707",
    "name": "หัวหิน",
    "id": "5afae3c7ead44fb20f6c890c",
    "name_EN": "Hua Hin",
    "province_code": "77"
  },
  {
    "code": "7708",
    "name": "สามร้อยยอด",
    "id": "5afae3c7ead44fb20f6c890d",
    "name_EN": "Sam Roi Yot",
    "province_code": "77"
  },
  {
    "code": "8001",
    "name": "เมืองนครศรีธรรมราช",
    "id": "5afae3c7ead44fb20f6c890e",
    "name_EN": "Mueang Nakhon Si Thammarat",
    "province_code": "80"
  },
  {
    "code": "8002",
    "name": "พรหมคีรี",
    "id": "5afae3c7ead44fb20f6c890f",
    "name_EN": "Phrom Khiri",
    "province_code": "80"
  },
  {
    "code": "8003",
    "name": "ลานสกา",
    "id": "5afae3c7ead44fb20f6c8910",
    "name_EN": "Lan Saka",
    "province_code": "80"
  },
  {
    "code": "8004",
    "name": "ฉวาง",
    "id": "5afae3c7ead44fb20f6c8911",
    "name_EN": "Chawang",
    "province_code": "80"
  },
  {
    "code": "8005",
    "name": "พิปูน",
    "id": "5afae3c7ead44fb20f6c8912",
    "name_EN": "Phipun",
    "province_code": "80"
  },
  {
    "code": "8006",
    "name": "เชียรใหญ่",
    "id": "5afae3c7ead44fb20f6c8913",
    "name_EN": "Chian Yai",
    "province_code": "80"
  },
  {
    "code": "8007",
    "name": "ชะอวด",
    "id": "5afae3c7ead44fb20f6c8914",
    "name_EN": "Cha-uat",
    "province_code": "80"
  },
  {
    "code": "8008",
    "name": "ท่าศาลา",
    "id": "5afae3c7ead44fb20f6c8915",
    "name_EN": "Tha Sala",
    "province_code": "80"
  },
  {
    "code": "8009",
    "name": "ทุ่งสง",
    "id": "5afae3c7ead44fb20f6c8916",
    "name_EN": "Thung Song",
    "province_code": "80"
  },
  {
    "code": "8010",
    "name": "นาบอน",
    "id": "5afae3c7ead44fb20f6c8917",
    "name_EN": "Na Bon",
    "province_code": "80"
  },
  {
    "code": "8011",
    "name": "ทุ่งใหญ่",
    "id": "5afae3c7ead44fb20f6c8918",
    "name_EN": "Thung Yai",
    "province_code": "80"
  },
  {
    "code": "8012",
    "name": "ปากพนัง",
    "id": "5afae3c7ead44fb20f6c8919",
    "name_EN": "Pak Phanang",
    "province_code": "80"
  },
  {
    "code": "8013",
    "name": "ร่อนพิบูลย์",
    "id": "5afae3c7ead44fb20f6c891a",
    "name_EN": "Ron Phibun",
    "province_code": "80"
  },
  {
    "code": "8014",
    "name": "สิชล",
    "id": "5afae3c7ead44fb20f6c891b",
    "name_EN": "Sichon",
    "province_code": "80"
  },
  {
    "code": "8015",
    "name": "ขนอม",
    "id": "5afae3c7ead44fb20f6c891c",
    "name_EN": "Khanom",
    "province_code": "80"
  },
  {
    "code": "8016",
    "name": "หัวไทร",
    "id": "5afae3c7ead44fb20f6c891d",
    "name_EN": "Hua Sai",
    "province_code": "80"
  },
  {
    "code": "8017",
    "name": "บางขัน",
    "id": "5afae3c7ead44fb20f6c891e",
    "name_EN": "Bang Khan",
    "province_code": "80"
  },
  {
    "code": "8018",
    "name": "ถ้ำพรรณรา",
    "id": "5afae3c7ead44fb20f6c891f",
    "name_EN": "Tham Phannara",
    "province_code": "80"
  },
  {
    "code": "8019",
    "name": "จุฬาภรณ์",
    "id": "5afae3c7ead44fb20f6c8920",
    "name_EN": "Chulabhorn",
    "province_code": "80"
  },
  {
    "code": "8020",
    "name": "พระพรหม",
    "id": "5afae3c7ead44fb20f6c8921",
    "name_EN": "Phra Phrom",
    "province_code": "80"
  },
  {
    "code": "8021",
    "name": "นบพิตำ",
    "id": "5afae3c7ead44fb20f6c8922",
    "name_EN": "Nopphitam",
    "province_code": "80"
  },
  {
    "code": "8022",
    "name": "ช้างกลาง",
    "id": "5afae3c7ead44fb20f6c8923",
    "name_EN": "Chang Klang",
    "province_code": "80"
  },
  {
    "code": "8023",
    "name": "เฉลิมพระเกียรติ",
    "id": "5afae3c7ead44fb20f6c8924",
    "name_EN": "Chaloem Phra Kiat",
    "province_code": "80"
  },
  {
    "code": "8051",
    "name": "เชียรใหญ่ (สาขาตำบลเสือหึง)*",
    "id": "5afae3c7ead44fb20f6c8925",
    "name_EN": "Chian Yai*",
    "province_code": "80"
  },
  {
    "code": "8052",
    "name": "สาขาตำบลสวนหลวง**",
    "id": "5afae3c7ead44fb20f6c8926",
    "name_EN": "Suan Luang",
    "province_code": "80"
  },
  {
    "code": "8053",
    "name": "ร่อนพิบูลย์ (สาขาตำบลหินตก)*",
    "id": "5afae3c7ead44fb20f6c8927",
    "name_EN": "Ron Phibun",
    "province_code": "80"
  },
  {
    "code": "8054",
    "name": "หัวไทร (สาขาตำบลควนชะลิก)*",
    "id": "5afae3c7ead44fb20f6c8928",
    "name_EN": "Hua Sai",
    "province_code": "80"
  },
  {
    "code": "8055",
    "name": "ทุ่งสง (สาขาตำบลกะปาง)*",
    "id": "5afae3c7ead44fb20f6c8929",
    "name_EN": "Thung Song",
    "province_code": "80"
  },
  {
    "code": "8101",
    "name": "เมืองกระบี่",
    "id": "5afae3c7ead44fb20f6c892a",
    "name_EN": "Mueang Krabi",
    "province_code": "81"
  },
  {
    "code": "8102",
    "name": "เขาพนม",
    "id": "5afae3c7ead44fb20f6c892b",
    "name_EN": "Khao Phanom",
    "province_code": "81"
  },
  {
    "code": "8103",
    "name": "เกาะลันตา",
    "id": "5afae3c7ead44fb20f6c892c",
    "name_EN": "Ko Lanta",
    "province_code": "81"
  },
  {
    "code": "8104",
    "name": "คลองท่อม",
    "id": "5afae3c7ead44fb20f6c892d",
    "name_EN": "Khlong Thom",
    "province_code": "81"
  },
  {
    "code": "8105",
    "name": "อ่าวลึก",
    "id": "5afae3c7ead44fb20f6c892e",
    "name_EN": "Ao Luek",
    "province_code": "81"
  },
  {
    "code": "8106",
    "name": "ปลายพระยา",
    "id": "5afae3c7ead44fb20f6c892f",
    "name_EN": "Plai Phraya",
    "province_code": "81"
  },
  {
    "code": "8107",
    "name": "ลำทับ",
    "id": "5afae3c7ead44fb20f6c8930",
    "name_EN": "Lam Thap",
    "province_code": "81"
  },
  {
    "code": "8108",
    "name": "เหนือคลอง",
    "id": "5afae3c7ead44fb20f6c8931",
    "name_EN": "Nuea Khlong",
    "province_code": "81"
  },
  {
    "code": "8201",
    "name": "เมืองพังงา",
    "id": "5afae3c7ead44fb20f6c8932",
    "name_EN": "Mueang Phang-nga",
    "province_code": "82"
  },
  {
    "code": "8202",
    "name": "เกาะยาว",
    "id": "5afae3c7ead44fb20f6c8933",
    "name_EN": "Ko Yao",
    "province_code": "82"
  },
  {
    "code": "8203",
    "name": "กะปง",
    "id": "5afae3c7ead44fb20f6c8934",
    "name_EN": "Kapong",
    "province_code": "82"
  },
  {
    "code": "8204",
    "name": "ตะกั่วทุ่ง",
    "id": "5afae3c7ead44fb20f6c8935",
    "name_EN": "Takua Thung",
    "province_code": "82"
  },
  {
    "code": "8205",
    "name": "ตะกั่วป่า",
    "id": "5afae3c7ead44fb20f6c8936",
    "name_EN": "Takua Pa",
    "province_code": "82"
  },
  {
    "code": "8206",
    "name": "คุระบุรี",
    "id": "5afae3c7ead44fb20f6c8937",
    "name_EN": "Khura Buri",
    "province_code": "82"
  },
  {
    "code": "8207",
    "name": "ทับปุด",
    "id": "5afae3c7ead44fb20f6c8938",
    "name_EN": "Thap Put",
    "province_code": "82"
  },
  {
    "code": "8208",
    "name": "ท้ายเหมือง",
    "id": "5afae3c7ead44fb20f6c8939",
    "name_EN": "Thai Mueang",
    "province_code": "82"
  },
  {
    "code": "8301",
    "name": "เมืองภูเก็ต",
    "id": "5afae3c7ead44fb20f6c893a",
    "name_EN": "Mueang Phuket",
    "province_code": "83"
  },
  {
    "code": "8302",
    "name": "กะทู้",
    "id": "5afae3c7ead44fb20f6c893b",
    "name_EN": "Kathu",
    "province_code": "83"
  },
  {
    "code": "8303",
    "name": "ถลาง",
    "id": "5afae3c7ead44fb20f6c893c",
    "name_EN": "Thalang",
    "province_code": "83"
  },
  {
    "code": "8381",
    "name": "*ทุ่งคา",
    "id": "5afae3c7ead44fb20f6c893d",
    "name_EN": "*Tung Ka",
    "province_code": "83"
  },
  {
    "code": "8401",
    "name": "เมืองสุราษฎร์ธานี",
    "id": "5afae3c7ead44fb20f6c893e",
    "name_EN": "Mueang Surat Thani",
    "province_code": "84"
  },
  {
    "code": "8402",
    "name": "กาญจนดิษฐ์",
    "id": "5afae3c7ead44fb20f6c893f",
    "name_EN": "Kanchanadit",
    "province_code": "84"
  },
  {
    "code": "8403",
    "name": "ดอนสัก",
    "id": "5afae3c7ead44fb20f6c8940",
    "name_EN": "Don Sak",
    "province_code": "84"
  },
  {
    "code": "8404",
    "name": "เกาะสมุย",
    "id": "5afae3c7ead44fb20f6c8941",
    "name_EN": "Ko Samui",
    "province_code": "84"
  },
  {
    "code": "8405",
    "name": "เกาะพะงัน",
    "id": "5afae3c7ead44fb20f6c8942",
    "name_EN": "Ko Pha-ngan",
    "province_code": "84"
  },
  {
    "code": "8406",
    "name": "ไชยา",
    "id": "5afae3c7ead44fb20f6c8943",
    "name_EN": "Chaiya",
    "province_code": "84"
  },
  {
    "code": "8407",
    "name": "ท่าชนะ",
    "id": "5afae3c7ead44fb20f6c8944",
    "name_EN": "Tha Chana",
    "province_code": "84"
  },
  {
    "code": "8408",
    "name": "คีรีรัฐนิคม",
    "id": "5afae3c7ead44fb20f6c8945",
    "name_EN": "Khiri Rat Nikhom",
    "province_code": "84"
  },
  {
    "code": "8409",
    "name": "บ้านตาขุน",
    "id": "5afae3c7ead44fb20f6c8946",
    "name_EN": "Ban Ta Khun",
    "province_code": "84"
  },
  {
    "code": "8410",
    "name": "พนม",
    "id": "5afae3c7ead44fb20f6c8947",
    "name_EN": "Phanom",
    "province_code": "84"
  },
  {
    "code": "8411",
    "name": "ท่าฉาง",
    "id": "5afae3c7ead44fb20f6c8948",
    "name_EN": "Tha Chang",
    "province_code": "84"
  },
  {
    "code": "8412",
    "name": "บ้านนาสาร",
    "id": "5afae3c7ead44fb20f6c8949",
    "name_EN": "Ban Na San",
    "province_code": "84"
  },
  {
    "code": "8413",
    "name": "บ้านนาเดิม",
    "id": "5afae3c7ead44fb20f6c894a",
    "name_EN": "Ban Na Doem",
    "province_code": "84"
  },
  {
    "code": "8414",
    "name": "เคียนซา",
    "id": "5afae3c7ead44fb20f6c894b",
    "name_EN": "Khian Sa",
    "province_code": "84"
  },
  {
    "code": "8415",
    "name": "เวียงสระ",
    "id": "5afae3c7ead44fb20f6c894c",
    "name_EN": "Wiang Sa",
    "province_code": "84"
  },
  {
    "code": "8416",
    "name": "พระแสง",
    "id": "5afae3c7ead44fb20f6c894d",
    "name_EN": "Phrasaeng",
    "province_code": "84"
  },
  {
    "code": "8417",
    "name": "พุนพิน",
    "id": "5afae3c7ead44fb20f6c894e",
    "name_EN": "Phunphin",
    "province_code": "84"
  },
  {
    "code": "8418",
    "name": "ชัยบุรี",
    "id": "5afae3c7ead44fb20f6c894f",
    "name_EN": "Chai Buri",
    "province_code": "84"
  },
  {
    "code": "8419",
    "name": "วิภาวดี",
    "id": "5afae3c7ead44fb20f6c8950",
    "name_EN": "Vibhavadi",
    "province_code": "84"
  },
  {
    "code": "8451",
    "name": "เกาะพงัน (สาขาตำบลเกาะเต่า)*",
    "id": "5afae3c7ead44fb20f6c8951",
    "name_EN": "Ko Pha-ngan",
    "province_code": "84"
  },
  {
    "code": "8481",
    "name": "*อ.บ้านดอน  จ.สุราษฎร์ธานี",
    "id": "5afae3c7ead44fb20f6c8952",
    "name_EN": "*Ban Don",
    "province_code": "84"
  },
  {
    "code": "8501",
    "name": "เมืองระนอง",
    "id": "5afae3c7ead44fb20f6c8953",
    "name_EN": "Mueang Ranong",
    "province_code": "85"
  },
  {
    "code": "8502",
    "name": "ละอุ่น",
    "id": "5afae3c7ead44fb20f6c8954",
    "name_EN": "La-un",
    "province_code": "85"
  },
  {
    "code": "8503",
    "name": "กะเปอร์",
    "id": "5afae3c7ead44fb20f6c8955",
    "name_EN": "Kapoe",
    "province_code": "85"
  },
  {
    "code": "8504",
    "name": "กระบุรี",
    "id": "5afae3c7ead44fb20f6c8956",
    "name_EN": "Kra Buri",
    "province_code": "85"
  },
  {
    "code": "8505",
    "name": "สุขสำราญ",
    "id": "5afae3c7ead44fb20f6c8957",
    "name_EN": "Suk Samran",
    "province_code": "85"
  },
  {
    "code": "8601",
    "name": "เมืองชุมพร",
    "id": "5afae3c7ead44fb20f6c8958",
    "name_EN": "Mueang Chumphon",
    "province_code": "86"
  },
  {
    "code": "8602",
    "name": "ท่าแซะ",
    "id": "5afae3c7ead44fb20f6c8959",
    "name_EN": "Tha Sae",
    "province_code": "86"
  },
  {
    "code": "8603",
    "name": "ปะทิว",
    "id": "5afae3c7ead44fb20f6c895a",
    "name_EN": "Pathio",
    "province_code": "86"
  },
  {
    "code": "8604",
    "name": "หลังสวน",
    "id": "5afae3c7ead44fb20f6c895b",
    "name_EN": "Lang Suan",
    "province_code": "86"
  },
  {
    "code": "8605",
    "name": "ละแม",
    "id": "5afae3c7ead44fb20f6c895c",
    "name_EN": "Lamae",
    "province_code": "86"
  },
  {
    "code": "8606",
    "name": "พะโต๊ะ",
    "id": "5afae3c7ead44fb20f6c895d",
    "name_EN": "Phato",
    "province_code": "86"
  },
  {
    "code": "8607",
    "name": "สวี",
    "id": "5afae3c7ead44fb20f6c895e",
    "name_EN": "Sawi",
    "province_code": "86"
  },
  {
    "code": "8608",
    "name": "ทุ่งตะโก",
    "id": "5afae3c7ead44fb20f6c895f",
    "name_EN": "Thung Tako",
    "province_code": "86"
  },
  {
    "code": "9001",
    "name": "เมืองสงขลา",
    "id": "5afae3c7ead44fb20f6c8960",
    "name_EN": "Mueang Songkhla",
    "province_code": "90"
  },
  {
    "code": "9002",
    "name": "สทิงพระ",
    "id": "5afae3c7ead44fb20f6c8961",
    "name_EN": "Sathing Phra",
    "province_code": "90"
  },
  {
    "code": "9003",
    "name": "จะนะ",
    "id": "5afae3c7ead44fb20f6c8962",
    "name_EN": "Chana",
    "province_code": "90"
  },
  {
    "code": "9004",
    "name": "นาทวี",
    "id": "5afae3c7ead44fb20f6c8963",
    "name_EN": "Na Thawi",
    "province_code": "90"
  },
  {
    "code": "9005",
    "name": "เทพา",
    "id": "5afae3c7ead44fb20f6c8964",
    "name_EN": "Thepha",
    "province_code": "90"
  },
  {
    "code": "9006",
    "name": "สะบ้าย้อย",
    "id": "5afae3c7ead44fb20f6c8965",
    "name_EN": "Saba Yoi",
    "province_code": "90"
  },
  {
    "code": "9007",
    "name": "ระโนด",
    "id": "5afae3c7ead44fb20f6c8966",
    "name_EN": "Ranot",
    "province_code": "90"
  },
  {
    "code": "9008",
    "name": "กระแสสินธุ์",
    "id": "5afae3c7ead44fb20f6c8967",
    "name_EN": "Krasae Sin",
    "province_code": "90"
  },
  {
    "code": "9009",
    "name": "รัตภูมิ",
    "id": "5afae3c7ead44fb20f6c8968",
    "name_EN": "Rattaphum",
    "province_code": "90"
  },
  {
    "code": "9010",
    "name": "สะเดา",
    "id": "5afae3c7ead44fb20f6c8969",
    "name_EN": "Sadao",
    "province_code": "90"
  },
  {
    "code": "9011",
    "name": "หาดใหญ่",
    "id": "5afae3c7ead44fb20f6c896a",
    "name_EN": "Hat Yai",
    "province_code": "90"
  },
  {
    "code": "9012",
    "name": "นาหม่อม",
    "id": "5afae3c7ead44fb20f6c896b",
    "name_EN": "Na Mom",
    "province_code": "90"
  },
  {
    "code": "9013",
    "name": "ควนเนียง",
    "id": "5afae3c7ead44fb20f6c896c",
    "name_EN": "Khuan Niang",
    "province_code": "90"
  },
  {
    "code": "9014",
    "name": "บางกล่ำ",
    "id": "5afae3c7ead44fb20f6c896d",
    "name_EN": "Bang Klam",
    "province_code": "90"
  },
  {
    "code": "9015",
    "name": "สิงหนคร",
    "id": "5afae3c7ead44fb20f6c896e",
    "name_EN": "Singhanakhon",
    "province_code": "90"
  },
  {
    "code": "9016",
    "name": "คลองหอยโข่ง",
    "id": "5afae3c7ead44fb20f6c896f",
    "name_EN": "Khlong Hoi Khong",
    "province_code": "90"
  },
  {
    "code": "9077",
    "name": "ท้องถิ่นเทศบาลตำบลสำนักขาม",
    "id": "5afae3c7ead44fb20f6c8970",
    "name_EN": "Sum Nung Kam",
    "province_code": "90"
  },
  {
    "code": "9096",
    "name": "เทศบาลตำบลบ้านพรุ*",
    "id": "5afae3c7ead44fb20f6c8971",
    "name_EN": "Ban Pru*",
    "province_code": "90"
  },
  {
    "code": "9101",
    "name": "เมืองสตูล",
    "id": "5afae3c7ead44fb20f6c8972",
    "name_EN": "Mueang Satun",
    "province_code": "91"
  },
  {
    "code": "9102",
    "name": "ควนโดน",
    "id": "5afae3c7ead44fb20f6c8973",
    "name_EN": "Khuan Don",
    "province_code": "91"
  },
  {
    "code": "9103",
    "name": "ควนกาหลง",
    "id": "5afae3c7ead44fb20f6c8974",
    "name_EN": "Khuan Kalong",
    "province_code": "91"
  },
  {
    "code": "9104",
    "name": "ท่าแพ",
    "id": "5afae3c7ead44fb20f6c8975",
    "name_EN": "Tha Phae",
    "province_code": "91"
  },
  {
    "code": "9105",
    "name": "ละงู",
    "id": "5afae3c7ead44fb20f6c8976",
    "name_EN": "La-ngu",
    "province_code": "91"
  },
  {
    "code": "9106",
    "name": "ทุ่งหว้า",
    "id": "5afae3c7ead44fb20f6c8977",
    "name_EN": "Thung Wa",
    "province_code": "91"
  },
  {
    "code": "9107",
    "name": "มะนัง",
    "id": "5afae3c7ead44fb20f6c8978",
    "name_EN": "Manang",
    "province_code": "91"
  },
  {
    "code": "9201",
    "name": "เมืองตรัง",
    "id": "5afae3c7ead44fb20f6c8979",
    "name_EN": "Mueang Trang",
    "province_code": "92"
  },
  {
    "code": "9202",
    "name": "กันตัง",
    "id": "5afae3c7ead44fb20f6c897a",
    "name_EN": "Kantang",
    "province_code": "92"
  },
  {
    "code": "9203",
    "name": "ย่านตาขาว",
    "id": "5afae3c7ead44fb20f6c897b",
    "name_EN": "Yan Ta Khao",
    "province_code": "92"
  },
  {
    "code": "9204",
    "name": "ปะเหลียน",
    "id": "5afae3c7ead44fb20f6c897c",
    "name_EN": "Palian",
    "province_code": "92"
  },
  {
    "code": "9205",
    "name": "สิเกา",
    "id": "5afae3c7ead44fb20f6c897d",
    "name_EN": "Sikao",
    "province_code": "92"
  },
  {
    "code": "9206",
    "name": "ห้วยยอด",
    "id": "5afae3c7ead44fb20f6c897e",
    "name_EN": "Huai Yot",
    "province_code": "92"
  },
  {
    "code": "9207",
    "name": "วังวิเศษ",
    "id": "5afae3c7ead44fb20f6c897f",
    "name_EN": "Wang Wiset",
    "province_code": "92"
  },
  {
    "code": "9208",
    "name": "นาโยง",
    "id": "5afae3c7ead44fb20f6c8980",
    "name_EN": "Na Yong",
    "province_code": "92"
  },
  {
    "code": "9209",
    "name": "รัษฎา",
    "id": "5afae3c7ead44fb20f6c8981",
    "name_EN": "Ratsada",
    "province_code": "92"
  },
  {
    "code": "9210",
    "name": "หาดสำราญ",
    "id": "5afae3c7ead44fb20f6c8982",
    "name_EN": "Hat Samran",
    "province_code": "92"
  },
  {
    "code": "9251",
    "name": "อำเภอเมืองตรัง(สาขาคลองเต็ง)**",
    "id": "5afae3c7ead44fb20f6c8983",
    "name_EN": "Mueang Trang(Krong Teng)**",
    "province_code": "92"
  },
  {
    "code": "9301",
    "name": "เมืองพัทลุง",
    "id": "5afae3c7ead44fb20f6c8984",
    "name_EN": "Mueang Phatthalung",
    "province_code": "93"
  },
  {
    "code": "9302",
    "name": "กงหรา",
    "id": "5afae3c7ead44fb20f6c8985",
    "name_EN": "Kong Ra",
    "province_code": "93"
  },
  {
    "code": "9303",
    "name": "เขาชัยสน",
    "id": "5afae3c7ead44fb20f6c8986",
    "name_EN": "Khao Chaison",
    "province_code": "93"
  },
  {
    "code": "9304",
    "name": "ตะโหมด",
    "id": "5afae3c7ead44fb20f6c8987",
    "name_EN": "Tamot",
    "province_code": "93"
  },
  {
    "code": "9305",
    "name": "ควนขนุน",
    "id": "5afae3c7ead44fb20f6c8988",
    "name_EN": "Khuan Khanun",
    "province_code": "93"
  },
  {
    "code": "9306",
    "name": "ปากพะยูน",
    "id": "5afae3c7ead44fb20f6c8989",
    "name_EN": "Pak Phayun",
    "province_code": "93"
  },
  {
    "code": "9307",
    "name": "ศรีบรรพต",
    "id": "5afae3c7ead44fb20f6c898a",
    "name_EN": "Si Banphot",
    "province_code": "93"
  },
  {
    "code": "9308",
    "name": "ป่าบอน",
    "id": "5afae3c7ead44fb20f6c898b",
    "name_EN": "Pa Bon",
    "province_code": "93"
  },
  {
    "code": "9309",
    "name": "บางแก้ว",
    "id": "5afae3c7ead44fb20f6c898c",
    "name_EN": "Bang Kaeo",
    "province_code": "93"
  },
  {
    "code": "9310",
    "name": "ป่าพะยอม",
    "id": "5afae3c7ead44fb20f6c898d",
    "name_EN": "Pa Phayom",
    "province_code": "93"
  },
  {
    "code": "9311",
    "name": "ศรีนครินทร์",
    "id": "5afae3c7ead44fb20f6c898e",
    "name_EN": "Srinagarindra",
    "province_code": "93"
  },
  {
    "code": "9401",
    "name": "เมืองปัตตานี",
    "id": "5afae3c7ead44fb20f6c898f",
    "name_EN": "Mueang Pattani",
    "province_code": "94"
  },
  {
    "code": "9402",
    "name": "โคกโพธิ์",
    "id": "5afae3c7ead44fb20f6c8990",
    "name_EN": "Khok Pho",
    "province_code": "94"
  },
  {
    "code": "9403",
    "name": "หนองจิก",
    "id": "5afae3c7ead44fb20f6c8991",
    "name_EN": "Nong Chik",
    "province_code": "94"
  },
  {
    "code": "9404",
    "name": "ปะนาเระ",
    "id": "5afae3c7ead44fb20f6c8992",
    "name_EN": "Panare",
    "province_code": "94"
  },
  {
    "code": "9405",
    "name": "มายอ",
    "id": "5afae3c7ead44fb20f6c8993",
    "name_EN": "Mayo",
    "province_code": "94"
  },
  {
    "code": "9406",
    "name": "ทุ่งยางแดง",
    "id": "5afae3c7ead44fb20f6c8994",
    "name_EN": "Thung Yang Daeng",
    "province_code": "94"
  },
  {
    "code": "9407",
    "name": "สายบุรี",
    "id": "5afae3c7ead44fb20f6c8995",
    "name_EN": "Sai Buri",
    "province_code": "94"
  },
  {
    "code": "9408",
    "name": "ไม้แก่น",
    "id": "5afae3c7ead44fb20f6c8996",
    "name_EN": "Mai Kaen",
    "province_code": "94"
  },
  {
    "code": "9409",
    "name": "ยะหริ่ง",
    "id": "5afae3c7ead44fb20f6c8997",
    "name_EN": "Yaring",
    "province_code": "94"
  },
  {
    "code": "9410",
    "name": "ยะรัง",
    "id": "5afae3c7ead44fb20f6c8998",
    "name_EN": "Yarang",
    "province_code": "94"
  },
  {
    "code": "9411",
    "name": "กะพ้อ",
    "id": "5afae3c7ead44fb20f6c8999",
    "name_EN": "Kapho",
    "province_code": "94"
  },
  {
    "code": "9412",
    "name": "แม่ลาน",
    "id": "5afae3c7ead44fb20f6c899a",
    "name_EN": "Mae Lan",
    "province_code": "94"
  },
  {
    "code": "9501",
    "name": "เมืองยะลา",
    "id": "5afae3c7ead44fb20f6c899b",
    "name_EN": "Mueang Yala",
    "province_code": "95"
  },
  {
    "code": "9502",
    "name": "เบตง",
    "id": "5afae3c7ead44fb20f6c899c",
    "name_EN": "Betong",
    "province_code": "95"
  },
  {
    "code": "9503",
    "name": "บันนังสตา",
    "id": "5afae3c7ead44fb20f6c899d",
    "name_EN": "Bannang Sata",
    "province_code": "95"
  },
  {
    "code": "9504",
    "name": "ธารโต",
    "id": "5afae3c7ead44fb20f6c899e",
    "name_EN": "Than To",
    "province_code": "95"
  },
  {
    "code": "9505",
    "name": "ยะหา",
    "id": "5afae3c7ead44fb20f6c899f",
    "name_EN": "Yaha",
    "province_code": "95"
  },
  {
    "code": "9506",
    "name": "รามัน",
    "id": "5afae3c7ead44fb20f6c89a0",
    "name_EN": "Raman",
    "province_code": "95"
  },
  {
    "code": "9507",
    "name": "กาบัง",
    "id": "5afae3c7ead44fb20f6c89a1",
    "name_EN": "Kabang",
    "province_code": "95"
  },
  {
    "code": "9508",
    "name": "กรงปินัง",
    "id": "5afae3c7ead44fb20f6c89a2",
    "name_EN": "Krong Pinang",
    "province_code": "95"
  },
  {
    "code": "9601",
    "name": "เมืองนราธิวาส",
    "id": "5afae3c7ead44fb20f6c89a3",
    "name_EN": "Mueang Narathiwat",
    "province_code": "96"
  },
  {
    "code": "9602",
    "name": "ตากใบ",
    "id": "5afae3c7ead44fb20f6c89a4",
    "name_EN": "Tak Bai",
    "province_code": "96"
  },
  {
    "code": "9603",
    "name": "บาเจาะ",
    "id": "5afae3c7ead44fb20f6c89a5",
    "name_EN": "Bacho",
    "province_code": "96"
  },
  {
    "code": "9604",
    "name": "ยี่งอ",
    "id": "5afae3c7ead44fb20f6c89a6",
    "name_EN": "Yi-ngo",
    "province_code": "96"
  },
  {
    "code": "9605",
    "name": "ระแงะ",
    "id": "5afae3c7ead44fb20f6c89a7",
    "name_EN": "Ra-ngae",
    "province_code": "96"
  },
  {
    "code": "9606",
    "name": "รือเสาะ",
    "id": "5afae3c7ead44fb20f6c89a8",
    "name_EN": "Rueso",
    "province_code": "96"
  },
  {
    "code": "9607",
    "name": "ศรีสาคร",
    "id": "5afae3c7ead44fb20f6c89a9",
    "name_EN": "Si Sakhon",
    "province_code": "96"
  },
  {
    "code": "9608",
    "name": "แว้ง",
    "id": "5afae3c7ead44fb20f6c89aa",
    "name_EN": "Waeng",
    "province_code": "96"
  },
  {
    "code": "9609",
    "name": "สุคิริน",
    "id": "5afae3c7ead44fb20f6c89ab",
    "name_EN": "Sukhirin",
    "province_code": "96"
  },
  {
    "code": "9610",
    "name": "สุไหงโก-ลก",
    "id": "5afae3c7ead44fb20f6c89ac",
    "name_EN": "Su-ngai Kolok",
    "province_code": "96"
  },
  {
    "code": "9611",
    "name": "สุไหงปาดี",
    "id": "5afae3c7ead44fb20f6c89ad",
    "name_EN": "Su-ngai Padi",
    "province_code": "96"
  },
  {
    "code": "9612",
    "name": "จะแนะ",
    "id": "5afae3c7ead44fb20f6c89ae",
    "name_EN": "Chanae",
    "province_code": "96"
  },
  {
    "code": "9613",
    "name": "เจาะไอร้อง",
    "id": "5afae3c7ead44fb20f6c89af",
    "name_EN": "Cho-airong",
    "province_code": "96"
  },
  {
    "code": "9681",
    "name": "*อ.บางนรา  จ.นราธิวาส",
    "id": "5afae3c7ead44fb20f6c89b0",
    "name_EN": "*Bang Nra",
    "province_code": "96"
  },
  {
    "code": "3807",
    "name": "ปากคาด",
    "id": "5afae3c7ead44fb20f6c89b1",
    "name_EN": "Pak Khat",
    "province_code": "38"
  },
  {
    "code": "3806",
    "name": "บึงโขงหลง",
    "id": "5afae3c7ead44fb20f6c89b2",
    "name_EN": "Bueng Khong Long",
    "province_code": "38"
  },
  {
    "code": "3805",
    "name": "ศรีวิไล",
    "id": "5afae3c7ead44fb20f6c89b3",
    "name_EN": "Si Wilai",
    "province_code": "38"
  },
  {
    "code": "3804",
    "name": "พรเจริญ",
    "id": "5afae3c7ead44fb20f6c89b4",
    "name_EN": "Phon Charoen",
    "province_code": "38"
  },
  {
    "code": "3803",
    "name": "โซ่พิสัย",
    "id": "5afae3c7ead44fb20f6c89b5",
    "name_EN": "So Phisai",
    "province_code": "38"
  },
  {
    "code": "3802",
    "name": "เซกา",
    "id": "5afae3c7ead44fb20f6c89b6",
    "name_EN": "Seka",
    "province_code": "38"
  },
  {
    "code": "3801",
    "name": "เมืองบึงกาฬ",
    "id": "5afae3c7ead44fb20f6c89b7",
    "name_EN": "Mueang Bueng Kan",
    "province_code": "38"
  },
  {
    "code": "3808",
    "name": "บุ่งคล้า",
    "id": "5afae3c7ead44fb20f6c89b8",
    "name_EN": "Bung Khla",
    "province_code": "38"
  }
]