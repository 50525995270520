import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { getFormatted } from '../../common/SetDate.js';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import { blue } from '@material-ui/core/colors';

import PaymentTimeCard from './PaymentTimeCard';
import { Icons } from '../../themes';

import DialogAction from '../../componets/DialogAction.jsx';

import * as PaymenttimeSettingActions from '../../actions/Actions_Settings';

const { BorderColorIcon } = Icons;
const fildeList = ['DATE', 'TIME', 'PACKAGE NAME', 'MEMBER', 'PRICE', 'PAYMET BY', 'STATUS']

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 0 };
const _callName = { textAlign: 'left', paddingLeft: '30px', paddingRight: '5px', minWidth: 100 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };

const _wording = { header: 'Payment Time Setting' }

let PaymentTime;
let waitingTime = true;
const _time = { hour: 0, min: 0 };

class PaymentTimePanalList extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, isCreatePaymenttime: false }

    constructor(props) {
        super(props);

        this.props.getAPIgetPaymentTime();
    }

    componentWillReceiveProps(nextProps) {

        const { SettingManager } = nextProps;

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case PaymenttimeSettingActions.GET_PAYMENT_TIME_SUCCESS:
                    {
                        //SettingManager.SETTING_REDUCER.actions = PaymenttimeSettingActions.WATING_STATE;
                        PaymentTime = data[0];
                        // console.log('PAYMENTTIMESETTING_REDUCER : ', PaymentTime);
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PaymenttimeSettingActions.GET_PAYMENT_TIME_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, CreatePanel, EditInfo, } = this.props;
        const { isCreatePaymenttime, isLoading, msgError, dialogType } = this.state;

        if (!!PaymentTime) {

            //console.log('paymenttime list', PaymentTime);
            const { id, paymentTimeId, time } = PaymentTime;
            _time.hour = time.hour;
            _time.min = time.min;
        }

        return (
            <Paper className={classes.root}>
                {(isCreatePaymenttime) && <DialogAction Active={isCreatePaymenttime} OnClose={this.onClosePanel} AcrionCom={this.CreatePaymentTimePanel()} />}
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow style={{ height: '100%' }}>
                            <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow hover style={{ cursor: 'pointer' }} >
                            <TableCell style={_callName} > {`Payment time  : `}</TableCell>
                            <TableCell style={_callStyle} >{`${getFormatted(_time.hour) || ''}   :  ${getFormatted(_time.min) || '00'} Hour.`}</TableCell>
                            <TableCell style={_callName} />
                            <TableCell style={_callStyle}>
                                <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { this.EditPaymentTime(PaymentTime) }} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }

    CreatePaymentTimePanel = () => {

        const { isEdit, isView, oldPaymentTimeInfo, PaymentTimeInfo } = this.state;

        return (
            <PaymentTimeCard
                isEdit={isEdit}
                isView={isView}
                oldPaymentTimeInfo={oldPaymentTimeInfo}
                PaymentTimeInfo={PaymentTimeInfo}
                onClosePanel={this.onClosePanel} />)
    }

    EditPaymentTime = (PaymentTimeInfo) => {

        this.setState({
            isCreatePaymenttime: true,
            isEdit: true,
            isView: false,
            PaymentTimeInfo,
            oldPaymentTimeInfo: PaymentTimeInfo,
        });
    }

    onClosePanel = () => (this.setState({ isCreatePaymenttime: false }));
}

const styles = theme => (
    {
        root: {
            width: '100%',
            marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            marginBottom: 10,
        },
        table: { minWidth: 1000, },

    });

PaymentTimePanalList.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIgetPaymentTime: (confirmInfo) => {
            dispatch(PaymenttimeSettingActions.getPaymentTime(confirmInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PaymentTimePanalList));
