import * as CurrencyActions from '../actions/Actions_CurrencyManager';

const INITIAL_STATE = { CURRENCY_REDUCER: { actions: CurrencyActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: CurrencyActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case CurrencyActions.INITIAL_STATE:
            {
                return state;
            }
        case CurrencyActions.IDLE_STATE:
            {
                //console.log("[ Currency ] : IDLE_STATE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.IDLE_STATE, data: payload, error: _error } };
            }
        case CurrencyActions.GET_CURRENCY_SUCCESS:
            {
                // console.log("[ Currency ] : GET_CURRENCYS_SUCCESS : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.GET_CURRENCY_SUCCESS, data: payload, error: _error } };
            }
        case CurrencyActions.GET_CURRENCY_FAILURE:
            {
                console.log("[ Currency ] : GET_CURRENCY_FAILURE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.GET_CURRENCY_FAILURE, data: payload, error: _error } };
            }
        case CurrencyActions.CREATE_CURRENCY_SUCCESS:
            {
                // console.log("[ Currency ] : CREATE_CURRENCY_SUCCESS : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.CREATE_CURRENCY_SUCCESS, data: payload, error: _error } };
            }
        case CurrencyActions.CREATE_CURRENCY_FAILURE:
            {
                console.log("[ Currency ] : CREATE_CURRENCY_FAILURE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.CREATE_CURRENCY_FAILURE, data: payload, error: _error } };
            }
        case CurrencyActions.UPDATE_CURRENCY_SUCCESS:
            {
                console.log("[ Currency ] : UPDATE_CURRENCY_SUCCESS : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.UPDATE_CURRENCY_SUCCESS, data: payload, error: _error } };
            }
        case CurrencyActions.UPDATE_CURRENCY_FAILURE:
            {
                console.log("[ Currency ] : UPDATE_CURRENCY_FAILURE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.UPDATE_CURRENCY_FAILURE, data: payload, error: _error } };
            }
        case CurrencyActions.GET_CURRENCY_RATE_SUCCESS:
            {
                // console.log("[ Currency ] : GET_CURRENCY_RATE_SUCCESS : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.GET_CURRENCY_RATE_SUCCESS, data: payload, error: _error } };
            }
        case CurrencyActions.GET_CURRENCY_RATE_FAILURE:
            {
                console.log("[ Currency ] : GET_CURRENCY_RATE_FAILURE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.GET_CURRENCY_RATE_FAILURE, data: payload, error: _error } };
            }
        case CurrencyActions.CREATE_CURRENCY_RATE_SUCCESS:
            {
                // console.log("[ Currency ] : CREATE_CURRENCY_RATE_SUCCESS : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.CREATE_CURRENCY_RATE_SUCCESS, data: payload, error: _error } };
            }
        case CurrencyActions.CREATE_CURRENCY_RATE_FAILURE:
            {
                console.log("[ Currency ] : CREATE_CURRENCY_RATE_FAILURE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.CREATE_CURRENCY_RATE_FAILURE, data: payload, error: _error } };
            }
        case CurrencyActions.UPDATE_CURRENCY_RATE_SUCCESS:
            {
                console.log("[ Currency ] : UPDATE_CURRENCY_RATE_SUCCESS : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.UPDATE_CURRENCY_RATE_SUCCESS, data: payload, error: _error } };
            }
        case CurrencyActions.UPDATE_CURRENCY_RATE_FAILURE:
            {
                console.log("[ Currency ] : UPDATE_CURRENCY_RATE_FAILURE : ", payload);
                return { ...state, CURRENCY_REDUCER: { actions: CurrencyActions.UPDATE_CURRENCY_RATE_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Currency ]  default :", state);
                return state;
            }
    }
}
