export const constraints = {
    type: {
        presence: { allowEmpty: false, },
    },

};
export const input = [
    { fieldName: 'type', },

];
