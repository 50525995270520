import React, { Component } from 'react';
import { connect } from 'react-redux';

import CONFIG from '../config';
import { PathName, Roles } from '../constant';

// import logo from '../images/logo.svg';
import LoginCard from '../componets/LoginCard.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import ForgotPassword from '../componets/Forgot.jsx';
import SellerCard from '../containers/Sellers/SellerCard';

import { isMobile } from '../common/DeviceCheck.js';
import { init_seller, SetSellerToCreate } from '../constant/initState/initSeller';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import * as AuthenActions from '../actions/Actions_AuthenManager.js';
import * as AdminActions from '../actions/Actions_AdminManager.js';
import * as SupplierActions from '../actions/Actions_SupplierManager.js';
import * as AgentActions from '../actions/Actions_AgentManager.js';
import * as SellerActions from '../actions/Actions_SellerManager.js';
import * as BankActions from '../actions/Actions_Settings';
import * as SettingActions from '../actions/Actions_Settings';
import * as UploadActions from '../actions/Actions_UploadManager';

import styles, { css } from 'styled-components';
import { Images } from '../themes';

import '../App.css';

let waitingTime = true;
let userInfo = { username: '', password: '' }
let BookBankList = [];
let SellerTypeList = [];
let DefaultCommisstions = [];

class LoginPage extends Component {

    state = { isLoading: false, isDialog: false, isForgot: false, isRegister: false, btnActive: false, msgError: undefined, dialogType: undefined, }

    constructor(props) {
        super(props);

        //const _info = JSON.parse(localStorage.getItem('UserInfo'));
        //console.log('UserInfo : ', _info);
        this.props.getAPIBankList({ num_limit: 100 });
        this.props.getAPISellerTypeList();
        this.props.getDefaultCommissions();
    }

    componentWillMount() {
        //console.log(`LoginPage [componentWillMount] : `, this.props);
    }

    componentWillReceiveProps(nextProps) {

        //console.log(`LoginPage [componentWillReceiveProps] : `, );
        const { AuthenManager, SupplierManager, AgentManager, SellerManager, SettingManager, UpLoadManager } = nextProps;

        if (!!AuthenManager) {

            const { actions, data, error } = AuthenManager.AUTHEN_REDUCER;

            switch (actions) {

                case AuthenActions.AUTHEN_LB_SUCCESS:
                    {
                        //console.log(`LoginPage [componentWillReceiveProps] : `, data);

                        localStorage.setItem('UserInfo', JSON.stringify(data));
                        const { token, userType, userTypeId, level, user } = data;
                        CONFIG.LB_token = token;

                        switch (userType) {
                            case Roles.Admin:
                                {
                                    AuthenManager.AUTHEN_REDUCER.actions = AuthenActions.WATING_STATE;
                                    this.props.history.push({ pathname: PathName.DashBoardPage, state: {} })
                                } break;
                            case Roles.Supplier:
                                {
                                    AuthenManager.AUTHEN_REDUCER.actions = AuthenActions.WATING_STATE;
                                    this.props.getAPISupplierInfo(userTypeId);

                                } break;
                            case Roles.Agent:
                                {
                                    AuthenManager.AUTHEN_REDUCER.actions = AuthenActions.WATING_STATE;
                                    this.props.getAPIAgentInfo(userTypeId);

                                } break;
                            case Roles.Seller:
                                {
                                    AuthenManager.AUTHEN_REDUCER.actions = AuthenActions.WATING_STATE;
                                    this.props.getAPISellerInfo(userTypeId);

                                } break;
                            default:
                                {
                                    AuthenManager.AUTHEN_REDUCER.actions = AuthenActions.WATING_STATE;
                                } break;
                        }

                        //SetStateWithTime(this, waitingTime);
                    } break;

                case AuthenActions.AUTHEN_LB_FAILURE:
                    {
                        //const _msg = data.response.data.error.message || actions;
                        // AuthenManager.AUTHEN_REDUCER.actions = AuthenActions.WATING_STATE;

                        const _msg = data.error || `User Auten failure.`;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, isDialog: true, dialogType: 'error' });
                    } break;

                default: { }
            }
        }

        if (!!SupplierManager) {

            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_INFO_SUCCESS:
                    {
                        const supplierInfo = data || {};
                        console.log(`${actions} : `, supplierInfo);
                        this.props.history.push({ pathname: PathName.DashBoardPage, state: { supplierInfo }, search: '', hash: '' });
                    } break;
                case SupplierActions.GET_SUPPLIER_INFO_FAILURE:
                    {

                    } break;
            }
        }

        if (!!AgentManager) {

            const { actions, data } = AgentManager.AGENT_REDUCER;

            switch (actions) {
                case AgentActions.GET_AGENT_INFO_SUCCESS:
                    {
                        const agentInfo = data || {};
                        console.log(`${actions} : `, agentInfo);
                        this.props.history.push({ pathname: PathName.DashBoardPage, state: { agentInfo }, search: '', hash: '' });

                    } break;
                case AgentActions.GET_AGENT_INFO_FAILURE:
                    {

                    } break;
            }

        }

        if (!!SellerManager) {

            const { actions, data } = SellerManager.SELLER_REDUCER;

            switch (actions) {
                case SellerActions.GET_SELLER_INFO_SUCCESS:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        const sellerInfo = data || {};
                        console.log(`${actions} : `, sellerInfo);
                        this.props.history.push({ pathname: PathName.DashBoardPage, state: { sellerInfo }, search: '', hash: '' });
                    } break;

                case SellerActions.GET_SELLER_TYPE_SUCCESS:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SellerTypeList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_INFO_FAILURE:
                case SellerActions.GET_SELLER_TYPE_FAILURE:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.CREATE_SELLER_SUCCESS:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: 'Success for register, please verify you account on your email.', dialogType: 'success' });
                    } break;
                case SellerActions.CREATE_SELLER_FAILURE:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        console.log("CREATE_SELLER_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!SettingManager) {
            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case BankActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BankActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        DefaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!UpLoadManager) {

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            const { SellerInfo, isEdit } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let _newSeller = SellerInfo;
                        _newSeller.logo = data.result.upload.images;
                        console.log('UPLOAD_IMAGE_SUCCESS :', _newSeller)
                        this.props.APICreateSeller(SetSellerToCreate(_newSeller))
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;      
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }

    render() {

        const { isLoading, isDialog, isForgot, isRegister, msgError, dialogType } = this.state;
        const _isMobile = isMobile();

        return (
            <AppLoginContianer>
                {(isRegister) && <DialogAction Active={isRegister} OnClose={this.onClosePanel} AcrionCom={this.RegisterPanel()} />}
                {(isForgot) && <DialogAction Active={isForgot} OnClose={this.onClosePanel} AcrionCom={this.ForgotPasswordPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={this.onClosePanel} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={this.onClosePanel} />}
                <div style={{ display: 'flex', height: window.innerHeight }}>
                    <div style={{ flex: _isMobile ? 0.1 : 2 }} />
                    {this.contentPanel(_isMobile)}
                    <div style={{ flex: _isMobile ? 0.1 : 2 }} />
                </div>
            </AppLoginContianer>
        );
    }

    contentPanel(_isMobile) {

        const { btnActive } = this.state;

        return (
            <div style={_style.card}>
                <div style={{ display: 'inline-flex', paddingTop: _isMobile ? '5%' : '10%' }}>
                    <LoginCard Onclik={this.Loging}
                        ValidateFrom={this.ValidateFrom()}
                        BtnActive={btnActive}
                        BtnForgot={this.OnForgotPassword()}
                        BtnRegister={this.OnRegister()}
                        isMobile={_isMobile} />
                </div>
            </div>);
    }

    Loging = () => { this.setState({ isLoading: true }, () => this.props.getAPIAuthenticationLBLists(userInfo)); }

    onClosePanel = () => (this.setState({ isLoading: false, isDialog: false, isForgot: false, isRegister: false, msgError: undefined, dialogType: undefined }));

    OnForgotPassword = () => () => { this.setState({ isForgot: true }) }

    OnRegister = () => () => { this.setState({ isRegister: true }) }

    RegisterPanel = () => (
        <SellerCard
            SellerInfo={init_seller}
            Level={DefaultCommisstions}
            SellerTypeList={SellerTypeList}
            APICreateSeller={this.APICreateSeller}
            BookBankList={BookBankList}
            onClosePanel={this.onClosePanel}
            isRegister={true} />
    );

    APICreateSeller = (avatar, sellerInfo) => {

        console.log('APICreateSeller', avatar, sellerInfo);

        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpLoadImage(formdata));
        }
        else {
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APICreateSeller(SetSellerToCreate(sellerInfo)));
        }
    }

    ForgotPasswordPanel = () => (<ForgotPassword OnCloseForgotPasswordPanel={this.onClosePanel} />);

    ValidateFrom = () => (_userInfo) => {

        const { username, password } = _userInfo;
        const { btnActive } = this.state;

        if (username.length > 3 && password.length > 3) {

            userInfo = _userInfo;
            //console.log('username : ', _userInfo);

            if (!btnActive)
                this.setState({ btnActive: true })
        }
        else if (btnActive)
            this.setState({ btnActive: false })
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAuthenticationLBLists: (_keyword) => {
            dispatch(AuthenActions.AuthenticationLB(_keyword));
        },
        getAPIAdminInfo: (_filter) => {
            dispatch(AdminActions.getAdminInfo(_filter));
        },
        getAPISupplierInfo: (_filter) => {
            dispatch(SupplierActions.getSupplierInfo(_filter));
        },
        getAPISellerInfo: (_fliter) => {
            dispatch(SellerActions.getSellerInfo(_fliter));
        },
        getAPIAgentInfo: (_filter) => {
            dispatch(AgentActions.getAgentInfo(_filter));
        },
        getAPIBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(BankActions.getBankList(_keyword));
        },
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
        APICreateSeller: (_confirmInfo) => {
            dispatch(SellerActions.APICreateSeller(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

const _style = {
    card:
    {
        height: window.innerHeight,
        width: '100%',
        maxWidth: '1024px',
        flex: 6
    },
}

const AppLoginContianer = styles.div`
        text-align: center;
        font-family: 'Roboto' !important ;
        background-image: url(${Images.ImageBackground});
        background-repeat: round;
        overflow: hidden;`;
