export const init_destination = {
    "destinationId": "",
    "EN": {
        "name": "",
        "description": "",
        "address": ""
    },
    "TH": {
        "name": "",
        "description": "",
        "address": ""
    },
    "CN": {
        "name": "",
        "description": "",
        "address": ""
    },
    "location": {
        "latitude": "",
        "longitude": ""
    },
    "picture": "",
    "gallery": [],
    "parentId": "",
    "actived": false,
    "province": "",
    "district": "",
}

