import { UserFormat } from '../JSON/UserFormat';

export const init_agent = {
    "name": '',
    "email": '',
    "affiliateId": '',
    "creditTermId": '',
    "telOffice": '',
    "reservationNumber": '',
    "emergencyContact": [{
        "tel": '',
        'person': '',
    }],
    "officialWebsite": '',
    "priceGroup": 0,
    "level": '',
    "tag": '',
    "credit": '',
    "bank": {
        "bankId": '',
        "bankName": '',
        "accountNumber": '',
    },
    "logo": {
        "original": '',
        "thumbnail": '',
    },
    "actived": false,
}

export const SetAgentToCreate = (agent) => {

    const _userInfo = { ...UserFormat };
    const _agentInfo = { ...agent };
    // console.log('agent : ', _agentInfo);

    _userInfo.username = agent.email;
    _userInfo.password = agent.email;
    _userInfo.userType = 'agent';
    _userInfo.email = agent.email;
    _userInfo.visible = agent.visible || false;

    _agentInfo.name = agent.name;
    _userInfo.user = _agentInfo;
    // console.log('_userInfo : ', _userInfo);

    return _userInfo;
}