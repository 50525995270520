// import facebook from '../assets/svg/facebook_logo';
// import google from '../assets/svg/google_logo';
// import travisGoLogo from '../assets/svg/travisGo_logo';
// import xWhite from '../assets/svg/x_white';
// import xCircleWhite from '../assets/svg/x-circle-white';
// import mail from '../assets/svg/mail';
// import phone from '../assets/svg/phone';
// import fax from '../assets/svg/fax';
// import dropdown from '../assets/svg/dropdown';
// import edit from "../assets/svg/editProfile";
// import Step from "../assets/svg/Step";
// import Header from "../assets/svg/Header";
// import Tools from '../assets/svg/tools';
// import Warning from '../assets/svg/warning';

import Landing from "./assets/svg/Landing";

export const Svg = {
    Landing,
    // facebook,
    // google,
    // travisGoLogo,
    // xWhite,
    // xCircleWhite,
    // mail,
    // phone,
    // fax,
    // dropdown,
    // profile: { edit },
    // Step,
    // Header,
    // Tools,
    // Warning,
};