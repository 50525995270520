
import * as PromotionActions from '../actions/Actions_PromotionManager.js';

const INITIAL_STATE = { PROMOTIONS_REDUCER: { actions: PromotionActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: PromotionActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case PromotionActions.INITIAL_STATE:
            {
                return state;
            }
        case PromotionActions.IDLE_STATE:
            {
                //console.log("[ Promotion ]  : IDLE_STATE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.IDLE_STATE, data: payload, error: _error } };
            }
        case PromotionActions.GET_PROMOTIONS_SUCCESS:
            {
                // console.log("[ Promotion ]  : GET_PROMOTIONS_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.GET_PROMOTIONS_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.GET_PROMOTIONS_FAILURE:
            {
                console.log("[ Promotion ] : GET_PROMOTIONS_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.GET_PROMOTIONS_FAILURE, data: payload, error: _error } };
            }
        case PromotionActions.GET_PROMOTIONALL_SUCCESS:
            {
                // console.log("[ Promotion ]  : GET_PROMOTIONALL_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.GET_PROMOTIONALL_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.GET_PROMOTIONALL_FAILURE:
            {
                console.log("[ Promotion ] : GET_PROMOTIONALL_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.GET_PROMOTIONALL_FAILURE, data: payload, error: _error } };
            }
        case PromotionActions.CREATE_PROMOTIONS_SUCCESS:
            {
                // console.log("[ Promotion ]  : CREATE_PROMOTIONS_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.CREATE_PROMOTIONS_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.CREATE_PROMOTIONS_FAILURE:
            {
                console.log("[ Promotion ] : CREATE_PROMOTIONS_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.CREATE_PROMOTIONS_FAILURE, data: payload, error: _error } };
            }
        case PromotionActions.UPDATE_PROMOTIONS_SUCCESS:
            {
                // console.log("[ Promotion ] : UPDATE_PROMOTIONS_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.UPDATE_PROMOTIONS_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.UPDATE_PROMOTIONS_FAILURE:
            {
                console.log("[ Promotion ] : UPDATE_PROMOTIONS_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.UPDATE_PROMOTIONS_FAILURE, data: payload, error: _error } };
            }
        case PromotionActions.GET_PROMOTIONS_CODE_SUCCESS:
            {
                // console.log("[ Promotion ]  : GET_PROMOTIONS_CODE_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.GET_PROMOTIONS_CODE_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.GET_PROMOTIONS_CODE_FAILURE:
            {
                console.log("[ Promotion ] : GET_PROMOTIONS_CODE_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.GET_PROMOTIONS_CODE_FAILURE, data: payload, error: _error } };
            }
        case PromotionActions.CREATE_PROMOTIONS_CODE_SUCCESS:
            {
                // console.log("[ Promotion ]  : CREATE_PROMOTIONS_CODE_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.CREATE_PROMOTIONS_CODE_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.CREATE_PROMOTIONS_CODE_FAILURE:
            {
                console.log("[ Promotion ] : CREATE_PROMOTIONS_CODE_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.CREATE_PROMOTIONS_CODE_FAILURE, data: payload, error: _error } };
            }
        case PromotionActions.UPDATE_PROMOTIONS_CODE_SUCCESS:
            {
                // console.log("[ Promotion ] : UPDATE_PROMOTIONS_CODE_SUCCESS : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.UPDATE_PROMOTIONS_CODE_SUCCESS, data: payload, error: _error } };
            }
        case PromotionActions.UPDATE_PROMOTIONS_CODE_FAILURE:
            {
                console.log("[ Promotion ] : UPDATE_PROMOTIONS_CODE_FAILURE : ", payload);
                return { ...state, PROMOTIONS_REDUCER: { actions: PromotionActions.UPDATE_PROMOTIONS_CODE_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Promotion ]   default :", state);
                return state;
            }
    }
}
