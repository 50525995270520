import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_COUNTRY_SUCCESS = 'GET_COUNTRY_SUCCESS';
export const GET_COUNTRY_FAILURE = 'GET_COUNTRY_FAILURE';

export const CREATE_COUNTRY_SUCCESS = 'CREATE_COUNTRY_SUCCESS';
export const CREATE_COUNTRY_FAILURE = 'CREATE_COUNTRY_FAILURE';

export const UPDATE_COUNTRY_SUCCESS = 'UPDATE_COUNTRY_SUCCESS';
export const UPDATE_COUNTRY_FAILURE = 'UPDATE_COUNTRY_FAILURE';



// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getCountryList =====================================================================================================================

export function getCountryList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getCountryList :", `${CONFIG.LBhost}${CONFIG.api.getCountryList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getCountryList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getCountryList : ", response);
            localStorage.setItem('CountryList', response.data)
            dispatch(getCountryList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getCountryList Failure", error);
            dispatch(getCountryList_Failure(error));
        })
    }
}

function getCountryList_Success(data) {
    return { type: GET_COUNTRY_SUCCESS, payload: data };
}

function getCountryList_Failure(error) {
    return { type: GET_COUNTRY_FAILURE, payload: error };
}

// createCountry ========================================================================================================================


export function APICreateCountry(confirmInfo) {

    console.log('APICreateCountry : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createCountry}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateCountry : ", response.data);
            dispatch(APICreateCountry_Success(response.data));

        }).catch((error) => {
            console.log("APICreateCountry Failure", error);
            dispatch(APICreateCountry_Failure(error));
        })
    }
}

function APICreateCountry_Success(data) {
    return { type: CREATE_COUNTRY_SUCCESS, payload: data };
}

function APICreateCountry_Failure(error) {
    return { type: CREATE_COUNTRY_FAILURE, payload: error };
}

// updateCountry =========================================================================================================================

export function APIUpdateCountry(confirmInfo) {
    console.log('APIUpdateCountry : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateCountry}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { countryId: confirmInfo.countryId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateCountry : ", response.data);
            dispatch(APIUpdateCountry_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateCountry Failure", error);
            dispatch(APIUpdateCountry_Failure(error));
        })
    }
}

function APIUpdateCountry_Success(data) {
    return { type: UPDATE_COUNTRY_SUCCESS, payload: data };
}

function APIUpdateCountry_Failure(error) {
    return { type: UPDATE_COUNTRY_FAILURE, payload: error };
}
