import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Family, Colors, RequireForm } from '../../themes';
import EmailAutoComplete from '../../componets/EmailAutoComplete';
import { isMobile } from '../DeviceCheck'

class FormEmail extends Component {
    render() {
        const { classes, disabled, error, TitleText, value, onChange, placeholder, Require } = this.props;
        return (
            <EmailAutoComplete
                disabled={disabled}
                label={Require ? <div>{RequireForm()}{TitleText} </div> : TitleText}
                style={{ width: '100%', margin: '5px 0px 0px', }}
                placeholder={placeholder}
                error={error}
                value={value}
                onChange={(value) => onChange(value)}
                InputProps={{ classes: { input: classes.textInput } }}
                InputLabelProps={{ shrink: true, className: classes.textFormLabel }} />
        )
    }
}
const styles = theme => (
    {
        textInput: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            borderRadius: 0,
            fontSize: 14,
            marginTop: '5px',
            padding: '8px 15px',
            backgroundColor: Colors.white,
            border: `${'1px solid' + Colors.border}`,
            color: Colors.themeDark,
        },
        textFormLabel: { fontSize: 16, color: Colors.themeDark, fontWeight: 300, },
    }
)
FormEmail.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormEmail);