import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import MaskedInput from 'react-text-mask'

export function TimeFormat(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
            showMask
        />
    );
}


export function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            format={number}
        />
    );
}

export function PercentFormat(props) {
    const { inputRef, onChange, ...other } = props;
    let value = 0;
    let isUpdate = false;

    return (
        <NumberFormat
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            onValueChange={values => { value = values.value; isUpdate = true; }}
            onBlur={() => {
                (isUpdate) && onChange({
                    target: {
                        value: value,
                    },
                });
                isUpdate = false;
            }}
            format={percent}
        // decimalSeparator={'.'}
        />

    );
}


export function PriceFormat(props) {
    const { inputRef, onChange, ...other } = props;
    let value = 0;
    let isUpdate = false;

    return (
        <NumberFormat
            {...other}
            ref={ref => { inputRef(ref ? ref.inputElement : null); }}
            onValueChange={values => { value = values.value; isUpdate = true; }}
            onBlur={() => {
                (isUpdate) && onChange({
                    target: {
                        value: value,
                    },
                });
                isUpdate = false;
            }}
            thousandSeparator={true}
        // decimalSeparator={'.'}
        // format={price}
        />

    );
}

function limit(val) {
    if (val.length === 1 && Number(val) === 0) {
        val = val
    } else if (val.length === 2 && Number(val[1]) === 0) {
        val = '0'
    } else if (val.length === 2 && Number(val[1]) > 0) {
        val = val.substring(1, 10) + val
    }

    return val;
}

function number(val) {
    let number = val.substring(0, 10);
    return (number.length ? number + '' : '');
}
function price(val) {
    // let number = limit(val.substring(0, 10));
    let number = val.substring(0, 10);

    return (number.length ? number + '' : '');
}
function percent(val) {
    if (val.indexOf('.') === -1) {
        let percent = val.substring(0, 2);
        let dot = val.substring(2, 4);
        return (percent.length >= 2 ? percent + '.' + (dot || '00') + '%' : percent);
    } else {
        let percent = val.substring(0, 5);
        return percent + '%'
    }
}