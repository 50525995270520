import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path, omit, keys, equals } from 'ramda';
import styled from 'styled-components';
import validate from "validate.js";
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { Colors, Family } from '../../themes'

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';

import FormTypes from '../../common/FormTypes';
import Announcement from '../../constant/JSON/Announcement';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { acceptFile } from '../../constant';
import FileUploader from '../../FileUploader';

import * as format from '../../common/setFormat';
import * as validator from '../../constant/Validator/Announcement';
import * as AnnouncementActions from '../../actions/Actions_AnnouncementManager';
import * as UploadActions from '../../actions/Actions_UploadManager';

let Structure = Announcement.BodyPage;
let Header = Announcement.HeaderPage;
let waitingTime = true;

class AnnouncementCard extends Component {

    error = null;
    imageOther = [];
    image = null;
    background = null;

    state = { isBtnActive: false, isLoading: false, msgError: undefined, dialogType: undefined }
    constructor(props) {
        super(props);

        const { announcementInfo, } = this.props;
        this.state = {
            announcementInfo: { ...announcementInfo },
            isUpdate: false
        }

    }
    componentWillReceiveProps(nextProps) {

        const { AnnouncementManager, UpLoadManager } = nextProps;

        if (!!AnnouncementManager) {
            const { actions, data } = AnnouncementManager.ANNOUNCEMENT_REDUCER;

            switch (actions) {

                case AnnouncementActions.CREATE_ANNOUNCEMENT_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'CREATE_ANNOUNCEMENT_SUCCESS', dialogType: 'success', });
                        this.setState({ announcementId: data.announcementId, isUpdate: true })
                        this.props.APIAnnouncementList();
                    } break;

                case AnnouncementActions.UPDATE_ANNOUNCEMENT_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE_ANNOUNCEMENT_SUCCESS', dialogType: 'success', });
                        this.setState({ isUpdate: false }, () => this.props.APIAnnouncementList())
                    } break;
                case AnnouncementActions.CREATE_ANNOUNCEMENT_FAILURE:
                case AnnouncementActions.UPDATE_ANNOUNCEMENT_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, announcementId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let announcementInfo = { ...this.state.announcementInfo };
                        const _image = data.result.upload.images;
                        delete announcementInfo.id;
                        if (isEdit) {
                            if (announcementInfo.typeContent === 'wording' || announcementInfo.typeContent === 'image') {
                                announcementInfo.background = announcementInfo.background.concat(_image);
                            } else if (announcementInfo.typeContent === 'slide') {
                                announcementInfo.image = announcementInfo.image.concat(_image);
                            }
                            console.log('Image Edit', _image, announcementInfo);
                            this.props.APIUpdateAnnouncement(announcementInfo);
                        } else if (isUpdate) {
                            announcementInfo = { ...announcementInfo, announcementId: announcementId };
                            if (announcementInfo.typeContent === 'wording' || announcementInfo.typeContent === 'image') {
                                announcementInfo.background = (_image.length > 0) ? _image : [_image];
                            } else if (announcementInfo.typeContent === 'slide') {
                                announcementInfo.image = (_image.length > 0) ? _image : [_image];
                            }
                            console.log('Image Update', _image, announcementInfo);
                            this.props.APIUpdateAnnouncement(announcementInfo);
                        } else {
                            if (announcementInfo.typeContent === 'wording' || announcementInfo.typeContent === 'image') {
                                announcementInfo.background = (_image.length > 0) ? _image : [_image];
                            } else if (announcementInfo.typeContent === 'slide') {
                                announcementInfo.image = (_image.length > 0) ? _image : [_image];
                            }
                            console.log('Image Create', _image, announcementInfo);
                            this.props.APICreateAnnouncement(announcementInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }

    render() {
        const { isBtnActive, isLoading, msgError, dialogType } = this.state;
        const { isView, isEdit, onClosePanel } = this.props;
        return (
            <Contianer>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <FromLabelContianer>
                    {!isView && <Typography> {(isEdit) ? Header.EN.UPDATE : Header.EN.CREATE} </Typography>}
                </FromLabelContianer>
                {
                    Structure.map((R, index) => (
                        <ListContainer key={index}>
                            {
                                R.map((structure, index) => (
                                    <ListItemsContianer key={index} style={{ display: (structure.type === 'additional') ? 'unset' : 'flex', alignItems: 'center', width: structure.width ? structure.width : '100%', marginRight: structure.margin ? 15 : 0 }}>
                                        {this.contentPanel(structure)}
                                    </ListItemsContianer>
                                ))
                            }
                        </ListContainer>
                    ))
                }
                {
                    (!isView) &&
                    <ButtonContianer>
                        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes TitleText={ButtonText.BUTTON_SAVE}
                            disabled={!isBtnActive} onClick={() => this.onSave()} />
                    </ButtonContianer>
                }
            </Contianer>
        )
    }

    contentPanel(each) {
        const { announcementInfo, } = this.state;
        const { isView, isEdit } = this.props;

        let Info = announcementInfo;
        let InfoType = announcementInfo.typeContent;

        switch (each.type) {
            case "additional": {
                switch (InfoType) {
                    case 'wording': {
                        return (each.wording.map((structure, index) => <div key={index}>{this.setTypePanel(structure)} </div>))
                    }
                    case 'slide': {
                        return (each.slide.map((structure, index) => <div key={index}>{this.setTypePanel(structure)} </div>))
                    }
                    case 'image': {
                        return (each.image.map((structure, index) => <div key={index}>{this.setTypePanel(structure)} </div>))
                    }
                }
            } break;
            case "select": {
                return (
                    <React.Fragment>
                        <FormTypes.Select
                            disabled={(isView || isEdit) && true}
                            error={!!path([each.stateName], this.error)}
                            Require={(isView || isEdit) ? false : each.require}
                            TitleText={each.title_EN}
                            value={path([each.stateName], Info)}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                            options={Array.isArray(each.options) && each.options.map((option, i) => { return (<option key={i} value={option.value}> {option.EN.label} </option>) })} />
                    </React.Fragment>
                )
            }
            case "date": {
                return (
                    <FormTypes.TextDatePicker
                        disabled={(isView) && true}
                        // minStartDate={moment()}
                        //  minEndDate={minEndDate} maxEndDate={maxEndDate}
                        Require={each.required}
                        width={'375px'}
                        valueStart={path([each.stateName_start], Info)}
                        valueEnd={path([each.stateName_end], Info)}
                        stateStart={each.stateName_start} stateEnd={each.stateName_end}
                        TitleStart={each.title_start} TitleEnd={each.title_end}
                        onChange={(name, value) => { this.onChangeState(name, value) }} />
                )
            }
            case "time": {
                return (
                    <div style={{ flexDirection: 'row', display: 'flex', width: '100%' }} >
                        <FormTypes.TextFormat
                            InputFormat={format.TimeFormat}
                            TitleText={each.title_start}
                            disabled={Info.isTime ? false : true}
                            value={path([each.stateName_start], Info) || ''}
                            onChange={(e) => this.onChangeState(each.stateName_start, e.target.value)}
                            style={{ margin: '0px', width: '35%' }} />
                        <LineItem> {'To'} </LineItem>
                        <FormTypes.TextFormat
                            InputFormat={format.TimeFormat}
                            TitleText={each.title_end}
                            disabled={Info.isTime ? false : true}
                            value={path([each.stateName_end], Info)}
                            onChange={(e) => this.onChangeState(each.stateName_end, e.target.value)}
                            style={{ margin: '0px', width: '35%' }} />
                    </div>

                )
            }
            case "switch": {
                return (
                    <FormTypes.Switches Left
                        disabled={(isView) && true}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], announcementInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
        }
    }

    setTypePanel(each) {
        const { isView, isEdit } = this.props;
        const { announcementInfo, } = this.state;

        let Info = announcementInfo;
        switch (each.type) {
            case "text": {
                return (
                    <FormTypes.TextFormat
                        disabled={(isView) && true}
                        error={!!path([each.stateName], this.error)}
                        Require={(isView) ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], Info)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            };
            case "text-multiline": {
                return (
                    <FormTypes.TextFormat multiline rows={3}
                        style={{ marginBottom: 10 }}
                        disabled={(isView) && true}
                        error={!!path([each.stateName], this.error)}
                        Require={(isView) ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], Info)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            };
            case "image": {
                return (
                    <React.Fragment>
                        {(isEdit || isView) ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader
                                    ref={(ref) => this[each.stateName] = ref}
                                    disabled={(isView) ? true : false}
                                    multiple
                                    onChange={(files) => this.setState({ [`new_${each.stateName}`]: files })}
                                    onDefaultChange={(defaultFiles) => { this.onChangeState(each.stateName, defaultFiles) }}
                                    defaultFiles={path([each.stateName], Info)}
                                    accept={acceptFile.image}
                                />
                                <div style={{ color: Colors.disabled, fontSize: '10px', paddingTop: '5px' }} >
                                    {each.require.EN}
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader multiple accept={acceptFile.image}
                                    disabled={(isView) ? true : false}
                                    onChange={(files) => { this.onChangeState(each.stateName, files) }} />
                                <div style={{ color: Colors.disabled, fontSize: '12px', paddingTop: '10px', fontFamily: Family.roboto }} >
                                    {each.require.EN}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }

        }
    }

    onChangeState = (fieldName, value) => {
        console.log('onChangeState :', fieldName, value);
        let announcementInfo = { ...this.state.announcementInfo };

        switch (fieldName) {
            case 'typeContent': {
                announcementInfo = { ...announcementInfo, [fieldName]: value, image: [], background: [], wording: '', link: '' }
            }
            default: { announcementInfo = { ...announcementInfo, [fieldName]: value, } }
        }
        console.log('announcementInfo success', announcementInfo)
        this.setState({ announcementInfo, isBtnActive: true },
            () => { this.validate({ [fieldName]: value }); }
        );
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.announcementInfo[each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        const { isEdit } = this.props;
        const { isUpdate, announcementId } = this.state;
        let announcementInfo = { ...this.state.announcementInfo }
        const { image, background, typeContent } = announcementInfo;

        console.log('onSave :', this.validateAll(), this.error, announcementInfo);
        let _cover = (typeContent === 'slide') ? this.image : this.background;
        delete announcementInfo.id;

        if (this.validateAll()) {
            if (isEdit) {
                const { defaultFiles } = _cover ? (_cover.state.length === 0 ? [] : _cover.state) : [];
                this.imageOther = this.imageOther.concat(_cover.files, defaultFiles) || []
                const { removeList } = _cover;
                let newImage = _cover.files || [];

                if (newImage.length > 0) {
                    this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadImage(newImage));
                } else {
                    this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APIUpdateAnnouncement(announcementInfo))
                }
            }
            else {
                if (image.length > 0 || background.length > 0) {
                    this.setState({ isLoading: true, isBtnActive: false }, () => (typeContent === 'slide') ? this.APIUpLoadImage(image) : this.APIUpLoadImage(background));
                } else {
                    if (isUpdate) {
                        announcementInfo = { ...announcementInfo, announcementId: announcementId };
                        this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APIUpdateAnnouncement(announcementInfo))
                    } else {
                        this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APICreateAnnouncement(announcementInfo))
                    }
                }
            }
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        }

    }


    APIUpLoadImage = (cover) => {

        let formdata = new FormData();
        formdata.append('imgOriginalWidth', 600); //650
        formdata.append('imgOriginalHeight', 600); //450
        formdata.append('imgThumbnailWidth', 400); //340
        formdata.append('imgThumbnailHeight', 400); //260

        for (const _image of cover)
            formdata.append("images", _image.file);

        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIAnnouncementList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AnnouncementActions.getAnnouncementList(_keyword, _filter));
        },
        APICreateAnnouncement: (_confirmInfo) => {
            InitWaitingTime();
            dispatch(AnnouncementActions.APICreateAnnouncement(_confirmInfo));
        },
        APIUpdateAnnouncement: (_confirmInfo) => {
            InitWaitingTime();
            dispatch(AnnouncementActions.APIUpdateAnnouncement(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementCard);

const Contianer = styled.div`
        max-width: 800px;
        font-family: ${Family.roboto};`;
const FromLabelContianer = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;` ;
const Typography = styled.div`
        font-family: ${Family.roboto};
        display: flex;
        padding: 10px 0px;
        font-size: 24px;
        font-weight: 400; `;
const ListContainer = styled.div`
        display: flex;
        padding: 2px 0px;`;
const ListItemsContianer = styled.div`
        text-align: start;
        width: 100%; `;
const ButtonContianer = styled.div`
        display: flex;
        justify-content: flex-end; `;
const LineItem = styled.div`
        display: flex;
        align-items: center;
        padding: 0px 15px;
        line-height: 6;
        font-size: 13px;
        font-family: ${Family.roboto};
        color: ${Colors.border};`;