export const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    description: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    limit: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    currency: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    type: {
        presence: {
            allowEmpty: false,
            message: "Please Select type"
        }
    },
};
export const input = [
    {
        fieldName: 'name',
    },
    {
        fieldName: 'description',
    },
    {
        fieldName: 'limit',
    },
    {
        fieldName: 'currency',
    },
    {
        fieldName: 'type',
    },
];