import React from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';


import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import { blue } from '@material-ui/core/colors';

import EmptyRows from '../../componets/EmptyRow.jsx';
import { NumberFormats } from '../../common/SetNumberFormat';
import * as PackageAction from '../../actions/Actions_PackageManager.js';

import Language from '../../constant/Language';
import {Colors} from '../../themes';

import CONFIG from '../../config';

const fildeList = ['', 'PACKAGE NAME', 'PROMOTION', 'TYPE', 'PRICE', 'RATING', 'STATUS'];
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', };
const _wording = { header: 'Ferries Manage' }
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
let FerriesList = [];


class FerriesListPanel extends React.Component {
    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }
    render() {
        const { classes, onClickHandle, CreatePanel } = this.props;
        let PackageList = (!!this.props.PackageList) ? (this.props.PackageList.packageList || []) : [];
        FerriesList = PackageList.filter(val => val.type === 'Transfer' && path(['packageInfo', 'transferType', 'conveyanceType'], val) === 'ferries');
        // console.log('FerriesList', FerriesList)
        const isFerries = (FerriesList.length > 0);
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow style={{ height: '100%' }}>
                            <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0 }}
                                // onClick={() => this.setState({ searchActive: !searchActive })}
                                />
                            </TableCell>
                            <TableCell colSpan={1} style={{ padding: '0px' }} >
                                <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                    <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {fildeList.map((_filde, index) => { return <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>{_filde}</TableCell> })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isFerries) ? GetBookingRows(FerriesList, onClickHandle, classes) : <EmptyRows fildeList={fildeList} />}
                    </TableBody>
                </Table>
                {(isFerries) && GetPagination(this.props.PackageList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
            </Paper>
        );
    }

    //===============================================================================================================================================================

    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ type: 'Transfer', conveyanceType: "ferries", page: 0, limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {

        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ type: 'Transfer', conveyanceType: "ferries", page: _pageNumber, limit: _limit || 25, language: _language });
    }

    onChangeLanguage = (_language) => {
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ type: 'Transfer', conveyanceType: "ferries", page: 0, limit: 25, language: _language });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}
function GetBookingRows(PackageList, onClickHandle, classes) {

    return PackageList.map(Info => {
        const { packageId, packageInfo, type, isPromotion, rating, visible } = Info;
        const { name, price = { adult: 0 }, image } = packageInfo;
        const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${checkURL(image)})`, backgroundRepeat: 'round' };


        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={packageId} onClick={() => onClickHandle(packageId)}  >
                <TableCell    style={{ textAlign: 'left' }}><div style={_backgroundImage} /></TableCell>
                <TableCell style={{ textAlign: 'left', paddingLeft: '5px', paddingRight: '5px' }}>{name}</TableCell>
                <TableCell    className={classes.text_info} >{`${isPromotion}`}</TableCell>
                <TableCell    className={classes.text_info} >{type}</TableCell>
                <TableCell    className={classes.text_info} >{NumberFormats(price.adult)}</TableCell>
                <TableCell    className={classes.text_info} >{rating.point}</TableCell>
                <TableCell    style={{ ..._callStyle, ...{ color: visible ? Colors.success : Colors.error } }}>{visible ? 'Active' : 'Inactive'}</TableCell>
            </TableRow>
        );
    });

}


function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {
    const { total, page, limit, pageSize, language } = _ListCount;
    // console.log('_ListCount', _ListCount)
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        table: { minWidth: 1024, },
        text_info: { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', },
        name: { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px' },
        });

FerriesListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIpackageInfoList: (_fliter) => {
            dispatch(PackageAction.getAPIPackageLists(_fliter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FerriesListPanel));