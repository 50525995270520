export const content_paymenttime = {
    title_name: 'Paymenttime ',
    text_Name: 'Name',
    text_Hour: 'Hour',
    text_Min: 'Min',
}
export const placeholder = {

    text_Name: 'Name',
    text_Hour: 'Hour',
    text_Min: 'Min',
}

