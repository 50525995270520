import React from 'react';
// import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { Images, Fonts, Colors } from '../themes';
import { PathName } from '../constant';
import styled from 'styled-components';

// import ButtonListPanel from './ButtonListPanel';

let userInfo = JSON.parse(localStorage.getItem('UserInfo'));

const _topics = ['LOGOUT'];//'DASHBOARD',

function AppbarPanel(props) {

  const { classes } = props;
  userInfo = JSON.parse(localStorage.getItem('UserInfo'));

  return (
    <AppBar position="static" color='inherit'>
      <div className={classes.root}>
        {/* <div style={{ flex: 0.5 }} /> */}
        <div style={{ flex: 5, paddingLeft: 20, paddingRight: 20 }}>
          {HeaderPanel(props)}
        </div>
        <div style={{ flex: 0.2 }} />
      </div>
    </AppBar>
  );
}

function HeaderPanel(props) {

  const { isOpen, Acitve } = props;

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <div style={{ flex: 1 }}>

        <Toolbar>
          {/* <Typography type="title" color="inherit" > */}
          {(!isOpen) &&
            <ImgLogo src={Images.logo.color} onClick={() => { (userInfo.userType === 'admin') && Acitve(PathName.DashBoardPage) }} alt='' />
          }
          {/* </Typography> */}
        </Toolbar>
      </div>
      <div style={{
        flex: 7, margin: '15px 12px 10px 0px', textAlign: 'right', display: 'flex',
        justifyContent: 'flex-end', alignItems: 'center'
      }}>
        {/* <ButtonListPanel/> */}
        {(!!userInfo) && (<UserInfoLabel>{` ${userInfo.username} ( ${userInfo.userType} ) `}</UserInfoLabel>)}
        {_topics.map((name, index) => (SetButton(index, name, Acitve)))}
      </div>
    </div>
  )
}

const SetButton = (index, name, Acitve) => (<Button key={index} onClick={() => { Acitve(GetPage(name)) }} style={{ color: Colors.themeDark }}>{name}</Button>);

function GetPage(_name) {
  switch (_name) {
    case 'DASHBOARD': return PathName.DashBoardPage;
    case 'PROMOTION MANAGE': return PathName.PromotionManage;
    case 'MEMBER MANAGE': return PathName.MemberManage;
    case 'PACKAGE MANAGE': return PathName.PackageManage;
    case 'BOOKING MANAGE': return PathName.BookingManage;
    case 'LOGOUT': return PathName.LoginPage;
    default: return PathName.DashBoardPage;
  }
}

const styles = { root: { width: '100%', textAlign: 'center', fontFamily: Fonts.family.roboto, display: 'flex', }, };

AppbarPanel.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(AppbarPanel);


const ImgLogo = styled.img`
cursor: pointer;
height: 30px;
`;
const UserInfoLabel = styled.label`
color: ${Colors.themeDark};
font-family:${Fonts.family.roboto}`;

