import React, { Component } from 'react';
import { connect } from 'react-redux';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import { blue } from '@material-ui/core/colors';
import AddIcon from '@material-ui/icons/Add';

import Avatar from '../../componets/Avatar.jsx';
import EmptyRows from '../../componets/EmptyRow.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

import SearchPanel from '../../componets/SearchPanel.jsx';
import SortHandle from '../../componets/TableManage/SortHandle.jsx';
import PaginationRow from '../../componets/TableManage/PaginationRow.jsx';


import { Family, Fonts, Colors, Icons } from '../../themes';

import { SetDate } from '../../common/SetDate';

import * as MemberActions from '../../actions/Actions_MemberManager.js';


const { BorderColorIcon, DoneIcon, CloseIcon } = Icons;
const fildeList = [
  { label: '', name: '', isOrder: false, },
  { label: 'NAME', name: 'firstName', isOrder: true, },
  { label: 'EMAIL', name: 'email', isOrder: true, },
  // { label: 'PHONE', name: 'tel', isOrder: true, },
  { label: 'REGISTER DATE', name: 'registerDate', isOrder: true, },
  { label: 'LEVEL', name: 'level', isOrder: true, },
  { label: 'STATUS', name: 'visible', isOrder: true, },
  { label: 'VERIFIED', name: 'actived', isOrder: true, },
  // { label: 'VERIFIED', name: 'approved', isOrder: true, },
  { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', maxWidth: 280, };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };
const _wording = { header: 'Staff Manage' }

let MemberList = [];
let waitingTime = true;

class MemberListPanel extends Component {

  state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

  constructor(props) {
    super(props);

  }

  componentWillReceiveProps(nextProps) {

    const { MemberManager } = nextProps;
    //console.log(`MemberManager [componentWillReceiveProps] : `, MemberManager, );

    if (!!MemberManager) {
      const { actions, data } = MemberManager.MEMBER_REDUCER;

      switch (actions) {
        case MemberActions.GET_MEMBER_SUCCESS:
          {
            MemberList = data.result;
            SetStateWithTime(this, waitingTime);
          } break;
        case MemberActions.GET_MEMBER_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break;
      }
    }
  }

  render() {

    const { classes, CreatePanel, DisplayMemberInfo, EditInfo, } = this.props;//onClickHandle
    const { datas, } = this.props.MemberList;

    MemberList = datas || [];
    const is_member = (MemberList.length > 0);
    const { isLoading, msgError, dialogType, orderBy, searchActive } = this.state;
    //console.log('MemberListPanel : ', this.props.MemberList)

    return (
      <Paper className={classes.root}>
        {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
        <Table className={classes.table}>
          <TableHead>
            <TableRow style={{ height: '100%' }}>
              <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} onClick={() => this.setState({ searchActive: !searchActive })} />
              </TableCell>
              <TableCell colSpan={1} style={{ padding: '0px' }} >
                <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                  <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                </div>
              </TableCell>
            </TableRow>
            {(searchActive) &&
              (<TableRow>
                <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                  <SearchPanel searchActive={searchActive} SearchType='staff' />
                </TableCell>
              </TableRow>)}
          </TableHead>
          <TableHead>
            <TableRow>
              {fildeList.map((_filde, index) => {
                return (
                  <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                    {/* {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)} */}
                    <SortHandle orderBy={orderBy} field={_filde} handleRequestSort={this.handleRequestSort} />
                  </TableCell>)
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {(is_member) ? MemberList.map(info => {
              const { actived, adminId, approved, avatarLink, email, id, title, firstName, lastName, level, registerDate, tel, userId, visible, wallet, } = info;
              return (
                <TableRow key={id} hover style={{ cursor: 'pointer' }}>
                  <TableCell style={_callName} onClick={() => DisplayMemberInfo(info)}><Avatar URL={avatarLink.thumbnail} /></TableCell>
                  <TableCell style={_callName} onClick={() => DisplayMemberInfo(info)}> {`${title || ''} ${firstName}  ${lastName}`}</TableCell>
                  <TableCell style={{ ..._callStyle, minWidth: 200, textAlign: 'left', paddingLeft: 20 }} onClick={() => DisplayMemberInfo(info)}>{email || '-'}</TableCell>
                  {/* <TableCell    style={_callStyle} onClick={() => DisplayMemberInfo(info)}>{tel || '-'}</TableCell> */}
                  <TableCell style={{ ..._callStyle, minWidth: 150 }} onClick={() => DisplayMemberInfo(info)}>{Boolean(registerDate) ? SetDate(registerDate) : '-'}</TableCell>
                  <TableCell style={_callStyle} onClick={() => DisplayMemberInfo(info)}>{(level || '-')}</TableCell>
                  <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }} onClick={() => DisplayMemberInfo(info)}>{visible ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell style={{ ..._callStyle, color: actived ? Colors.success : Colors.error }} onClick={() => DisplayMemberInfo(info)}>{GetIconWithStatus(actived)}</TableCell>
                  {/* <TableCell style={{ ..._callStyle, color: approved ? Colors.success : Colors.error }} onClick={() => DisplayMemberInfo(info)}>{GetIconWithStatus(approved)}</TableCell> */}

                  <TableCell style={_callStyle}><Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(info) }} /></TableCell>
                </TableRow>
              );
            }) : <EmptyRows fildeList={fildeList} />}
          </TableBody>
        </Table>
        {/* {(is_member) && <PaginationRow _ListCount={this.props.MemberList} onNextPage={() => this.onNextPage} onChangeLimit={() => this.onChangeLimit} />} */}
        {(is_member) && GetPagination(this.props.MemberList, this.onNextPage, this.onChangeLimit)}
      </Paper >
    );
  }

  //===============================================================================================================================================================

  handleRequestSort = (sortFilde) => {

    const { orderBy, field } = sortFilde;
    const _fildeName = field.name;
    console.log('handleRequestSort : ', _fildeName, MemberList)

    MemberList = (orderBy === 'desc')
      ? MemberList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
      : MemberList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

    this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
  };

  onChangeLimit = (_limitRows) => {
    this.setState({ isLoading: true });
    this.props.getAPIMemberList({ page: 0, num_limit: _limitRows });
  }

  onNextPage = (_pageNumber, _limit) => {
    this.setState({ isLoading: true });
    this.props.getAPIMemberList({ page: _pageNumber, num_limit: _limit });
  }

  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

  //===============================================================================================================================================================
}

// function GetSortIcon(orderBy, field, handleRequestSort) {
//   return (
//     <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
//       {field.label}
//     </TableSortLabel>)
// }

function GetIconWithStatus(_status) {

  return ((_status) ? <DoneIcon /> : <CloseIcon />)
}


function GetPagination(_ListCount, onNextPage, onChangeLimit) {

  const { total, page, limit, pageSize } = _ListCount;

  return (
    <TablePagination
      component="div"
      count={total || _ListCount.length}
      rowsPerPage={limit || 25}
      page={page || 0}
      backIconButtonProps={{ 'aria-label': 'Previous Page', }}
      nextIconButtonProps={{ 'aria-label': 'Next Page', }}
      onChangePage={(event, page) => { onNextPage(page, limit) }}
      onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const styles = theme => (
  {
    root: {
      width: '100%',
      marginTop: theme.spacing.unit * 3,
      overflowX: 'auto',
      marginBottom: 10,
    },
    table: { minWidth: 1000, },
  });

MemberListPanel.propTypes = { classes: PropTypes.object.isRequired, };


function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {
    getAPIMemberList: (_filter) => {
      InitWaitingTime();
      dispatch(MemberActions.getMemberList(_filter));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MemberListPanel));
  //export default withStyles(styles)(MemberListPanel);


