export const init_zone = {
    "zoneId": "",
    "TH": {
        "name": "",
        "remark": ""
    },
    "EN": {
        "name": "",
        "remark": ""
    },
    "CN": {
        "name": "",
        "remark": ""
    },
    "code": "",
    "picture": {
        "original": '',
        "thumbnail": '',
    },
    "visible": false,
}