import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { UpperCaseFormats } from '../../common/SetUpperCaseFormat.js';
import { NumberFormats } from '../../common/SetNumberFormat.js';
import { SetDate } from '../../common/SetDate';
import { Colors } from '../../themes';

import DialogPanel from '../../componets/DialogPanel.jsx';
import StepperComponent from '../../componets/StepperComponent.jsx';
import RequestComponent from '../../componets/RequestComponent.jsx';

import * as RefundActions from '../../actions/Actions_RefundManager';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let isBtnActive = false;
let waitingTime = true;

class RefundHistoryPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, msgError: undefined, dialogType: undefined,
        }
    }

    componentWillReceiveProps(nextProps) {
        const { RefundManager } = nextProps;

        if (!!RefundManager) {
            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.GET_REFUND_REQUEST_LISTS_SUCCESS:
                    {
                        RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_REQUEST_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

    }
    render() {
        const { requestInfo, PackageList, onClosePanel } = this.props;
        const { isLoading, msgError, dialogType } = this.state;
        const { refundStatus, refundBy, amount, createDate, packageId, refundId } = requestInfo;

        let PackageName = null
        PackageList.filter((each) => each.packageId === packageId).map((value) => PackageName = value.packageInfo.name)

        const InfoLists = [
            { title: 'Refund ID : ', value: refundId },
            { title: 'Date : ', value: SetDate(createDate) },
            { title: 'Package Name : ', value: PackageName },
            { title: 'Refund By : ', value: UpperCaseFormats(0, refundBy) },
            { title: 'Amount : ', value: `${NumberFormats(amount || 0)} Bath` },
            { title: 'Status : ', value: UpperCaseFormats(0, refundStatus) },
        ];

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}

                <StepperComponent
                    checkRender={2}
                    rederRefuse={'refuse'}
                    activeStep={mapRefundStatus(refundStatus)}
                    steps={mapStepArray(refundStatus)}
                    component={renderRequestComponent(true, { InfoLists, onClosePanel })} />

            </React.Fragment>
        )
    }

    onCloseDialog = () => { this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }) }
};

function renderRequestComponent(action, props) {
    const { InfoLists, onClosePanel } = props;

    return (
        <RequestComponent Action={action} InfoLists={InfoLists} onClickCancel={onClosePanel} />
    )
};


function mapRefundStatus(status) {
    switch (status) {
        case 'success': return 2;
        case 'refuse': return 2;
        case 'in-process': return 1;
        default: return 0;
    }
};

function mapStepArray(status) {
    switch (status) {
        case 'refuse': {
            return ["request", "in-process", "refuse"]
        };
        default: return ["request", "in-process", "success"];
    }
};

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIRefundRequestList: (_filter) => {
            InitWaitingTime();
            dispatch(RefundActions.APIRefundRequestList(_filter));
        },
        APIUpdateRefund: (_confirmInfo) => {
            dispatch(RefundActions.APIUpdateRefund(_confirmInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundHistoryPanel);
