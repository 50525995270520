
export const paymentMethod = {
    Omise: 'Omise',
    Alienation: 'Alienation',
    iBanking: 'iBanking',
    Alipay: 'alipay',
};

export const paymentType = {
    Alipay: 'alipay',
    BarcodeAlipay: "barcode_alipay",
    iBanking: 'iBanking',
    Card: 'card',
}

export const internet_banking = {
    Krungsri: 'internet_banking_bay',
    Bualuang: 'internet_banking_bbl',
    KTB: 'internet_banking_ktb',
    SCN: 'internet_banking_scb',
}

export const bill = {
    Tesco: 'bill_payment_tesco_lotus',
}

export const mapInternetBankName = (code) => {
    switch (code) {
        case internet_banking.Krungsri: return 'Krungsri';
        case internet_banking.Bualuang: return 'Bualuang';
        case internet_banking.KTB: return 'KTB';
        case internet_banking.SCN: return 'SCN';
        default: return code;
    }
}

export const mapPaymentType = (code) => {
    switch (code) {
        case internet_banking.Krungsri:
        case internet_banking.Bualuang:
        case internet_banking.KTB:
        case internet_banking.SCN:
        case paymentType.iBanking: return 'iBanking';
        case paymentType.Alipay: return 'Alipay';
        case paymentType.BarcodeAlipay: return 'Barcode Alipay';
        case paymentType.Card: return 'Card';
        case bill.Tesco: return 'Bill ( Tesco Lotus )';
        case paymentMethod.Omise: return 'Card';
        default: return code;
    }
}


