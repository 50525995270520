import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_CURRENCY_SUCCESS = 'GET_CURRENCY_SUCCESS';
export const GET_CURRENCY_FAILURE = 'GET_CURRENCY_FAILURE';

export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';
export const CREATE_CURRENCY_FAILURE = 'CREATE_CURRENCY_FAILURE';

export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_FAILURE = 'UPDATE_CURRENCY_FAILURE';

export const GET_CURRENCY_RATE_SUCCESS = 'GET_CURRENCY_RATE_SUCCESS';
export const GET_CURRENCY_RATE_FAILURE = 'GET_CURRENCY_RATE_FAILURE';

export const CREATE_CURRENCY_RATE_SUCCESS = 'CREATE_CURRENCY_RATE_SUCCESS';
export const CREATE_CURRENCY_RATE_FAILURE = 'CREATE_CURRENCY_RATE_FAILURE';

export const UPDATE_CURRENCY_RATE_SUCCESS = 'UPDATE_CURRENCY_RATE_SUCCESS';
export const UPDATE_CURRENCY_RATE_FAILURE = 'UPDATE_CURRENCY_RATE_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getCurrencyList =====================================================================================================================

export function getCurrencyList(_keyword = '', _fileterjson = {}) {

    // console.log("[API] getCurrencyList :", `${CONFIG.LBhost}${CONFIG.api.getCurrencyList}`)

    return dispatch => {
        _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getCurrencyList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getCurrencyList : ", response);
            localStorage.setItem('CurrencyList', response.data)
            dispatch(getCurrencyList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getCurrencyList Failure", error);
            dispatch(getCurrencyList_Failure(error));
        })
    }
}

function getCurrencyList_Success(data) {
    return { type: GET_CURRENCY_SUCCESS, payload: data };
}

function getCurrencyList_Failure(error) {
    return { type: GET_CURRENCY_FAILURE, payload: error };
}

// createCurrencyList ========================================================================================================================


export function APICreateCurrency(confirmInfo) {

    console.log('APICreateCurrency : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createCurrency}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateCurrency : ", response.data);
            dispatch(APICreateCurrency_Success(response.data));

        }).catch((error) => {
            console.log("APICreateCurrency Failure", error);
            dispatch(APICreateCurrency_Failure(error));
        })
    }
}

function APICreateCurrency_Success(data) {
    return { type: CREATE_CURRENCY_SUCCESS, payload: data };
}

function APICreateCurrency_Failure(error) {
    return { type: CREATE_CURRENCY_FAILURE, payload: error };
}

// updateCurrencyList =========================================================================================================================

export function APIUpdateCurrency(confirmInfo) {
    console.log('APIUpdateCurrency : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateCurrency}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { currencyId: confirmInfo.currencyId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateCurrency : ", response.data);
            dispatch(APIUpdateCurrency_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateCurrency Failure", error);
            dispatch(APIUpdateCurrency_Failure(error));
        })
    }
}

function APIUpdateCurrency_Success(data) {
    return { type: UPDATE_CURRENCY_SUCCESS, payload: data };
}

function APIUpdateCurrency_Failure(error) {
    return { type: UPDATE_CURRENCY_FAILURE, payload: error };
}

//============= Currency Rate ====================================================================================================================

export function getCurrencyRateList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getCurrencyRateList :", `${CONFIG.LBhost}${CONFIG.api.getCurrencyRateList}`)
    return dispatch => {
        _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getCurrencyRateList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getCurrencyRateList : ", response);
            localStorage.setItem('CurrencyRateList', response.data)
            dispatch(getCurrencyRateList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getCurrencyRateList Failure", error);
            dispatch(getCurrencyRateList_Failure(error));
        })
    }
}

function getCurrencyRateList_Success(data) {
    return { type: GET_CURRENCY_RATE_SUCCESS, payload: data };
}

function getCurrencyRateList_Failure(error) {
    return { type: GET_CURRENCY_RATE_FAILURE, payload: error };
}

// createCurrencyRateList ========================================================================================================================


export function APICreateCurrencyRate(confirmInfo) {

    console.log('APICreateCurrencyRate : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createCurrencyRate}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateCurrencyRate : ", response.data);
            dispatch(APICreateCurrencyRate_Success(response.data));

        }).catch((error) => {
            console.log("APICreateCurrencyRate Failure", error);
            dispatch(APICreateCurrencyRate_Failure(error));
        })
    }
}

function APICreateCurrencyRate_Success(data) {
    return { type: CREATE_CURRENCY_RATE_SUCCESS, payload: data };
}

function APICreateCurrencyRate_Failure(error) {
    return { type: CREATE_CURRENCY_RATE_FAILURE, payload: error };
}

// updateCurrencyRateList =========================================================================================================================

export function APIUpdateCurrencyRate(confirmInfo) {
    console.log('APIUpdateCurrencyRate : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateCurrencyRate}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { currencyRateId: confirmInfo.currencyRateId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateCurrencyRate : ", response.data);
            dispatch(APIUpdateCurrencyRate_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateCurrencyRate Failure", error);
            dispatch(APIUpdateCurrencyRate_Failure(error));
        })
    }
}

function APIUpdateCurrencyRate_Success(data) {
    return { type: UPDATE_CURRENCY_RATE_SUCCESS, payload: data };
}

function APIUpdateCurrencyRate_Failure(error) {
    return { type: UPDATE_CURRENCY_RATE_FAILURE, payload: error };
}