import React from 'react';
import PropTypes from 'prop-types';

// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '../constant/Button';

function InfoSCard(props) {

  const { classes, Info, Disable } = props;

  return (
    <div>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.pos}>{Info.name}</Typography>
        </CardContent>
        <CardActions style={{ marginBottom: '0px', marginTop: '0px', paddingTop: '0px', paddingBottom: '0px' }}>
          <Button.Themes FullWidth disabled={Disable} TitleText={`${Info.name} MANAGE`} style={{ margin: 10 }} />
        </CardActions>
      </Card>
    </div>
  );
}

const styles = theme => (
  {
    card: {
      width: '100%',
      maxWidth: 320,
      marginTop: '10px'
    },
    pos: {
      marginBottom: 16,
      color: theme.palette.text.secondary,
    },
  });


InfoSCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(InfoSCard);