import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAILURE = 'GET_CUSTOMER_FAILURE';

export const GET_CUSTOMER_REQUESTS_SUCCESS = 'GET_CUSTOMER_REQUESTS_SUCCESS';
export const GET_CUSTOMER_REQUESTS_FAILURE = 'GET_CUSTOMER_REQUESTS_FAILURE';

export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';

export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE';

export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_FAILURE = 'GET_CUSTOMERS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getCustomerList ============================================================================================================================

export function getCustomerList(_keyword = '', _fileterjson = {}) {

    // console.log("[API] getCustomerList :", _keyword)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getCustomerList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getCustomerList : ", response.data);
            localStorage.setItem('CustomerList', response.data)
            dispatch(getCustomerList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getCustomerList Failure", error);
            dispatch(getCustomerList_Failure(error));
        })
    }
}

function getCustomerList_Success(data) {
    return { type: GET_CUSTOMER_SUCCESS, payload: data };
}

function getCustomerList_Failure(error) {
    return { type: GET_CUSTOMER_FAILURE, payload: error };
}
// getCustomerRequests ============================================================================================================================

export function getCustomerRequests(_keyword = '', _fileterjson = {}) {

    // console.log("[API] getCustomerRequests :", _keyword)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getCustomerRequests}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getCustomerRequests : ", response.data);
            localStorage.setItem('CustomerRequests', response.data)
            dispatch(getCustomerRequests_Success(response.data));

        }).catch((error) => {
            console.log("[API] CustomerRequests Failure", error);
            dispatch(getCustomerRequests_Failure(error));
        })
    }
}

function getCustomerRequests_Success(data) {
    return { type: GET_CUSTOMER_REQUESTS_SUCCESS, payload: data };
}

function getCustomerRequests_Failure(error) {
    return { type: GET_CUSTOMER_REQUESTS_FAILURE, payload: error };
}

// getCustomerList =========================================================================================================================


export function APICreateCustomer(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createCustomer}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateCustomer : ", response.data);
            dispatch(APICreateCustomer_Success(response.data));

        }).catch((error) => {
            console.log("APICreateCustomer fail", error);
            dispatch(APICreateCustomer_Failure(error));
        })
    }
}

function APICreateCustomer_Success(data) {
    return { type: CREATE_CUSTOMER_SUCCESS, payload: data };
}

function APICreateCustomer_Failure(error) {
    return { type: CREATE_CUSTOMER_FAILURE, payload: error };
}


//========update=============

export function APIUpdateCustomer(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateCustomer}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateCustomer : ", response.data);
            dispatch(APIUpdateCustomer_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateCustomer fail", error);
            dispatch(APIUpdateCustomer_Failure(error));
        })
    }
}

function APIUpdateCustomer_Success(data) {
    return { type: UPDATE_CUSTOMER_SUCCESS, payload: data };
}

function APIUpdateCustomer_Failure(error) {
    return { type: UPDATE_CUSTOMER_FAILURE, payload: error };
}

//------------


export function getCustomers(_keyword = '', _fileterjson = {}) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createCustomer}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] GET Customers : ", response.data);
            dispatch(getCustomers_Success(response.data));

        }).catch((error) => {
            console.log("GET Customers fail", error);
            dispatch(getCustomers_Failure(error));
        })
    }
}

function getCustomers_Success(data) {
    return { type: GET_CUSTOMERS_SUCCESS, payload: data };
}

function getCustomers_Failure(error) {
    return { type: GET_CUSTOMERS_FAILURE, payload: error };
}
