import React, { Component } from 'react';
import { connect } from 'react-redux';


import CONFIG from '../../config';
import { PathName } from '../../constant';
import List from '@material-ui/core/List';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

import InfoCard from '../../componets/InfoCard.jsx';
import SupplierListPanel from './SupplierListPanel.jsx';
import BestProductStatPanel from './BestProductStatPanel.jsx';
import PackageListPanel from '../../componets/PackageListPanel.jsx';
import ChangePasswordCard from '../../componets/ChangePasswordCard';
import ReportsListPanel from '../../containers/Report/ReportListPanel.jsx';

import * as SettingActions from '../../actions/Actions_Settings.js';
import * as SupplierActions from '../../actions/Actions_SupplierManager.js';
import * as BookingActions from '../../actions/Actions_BookingManager.js';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as StatiticAction from '../../actions/Actions_StatiticManager.js';
import * as ReportsAction from '../../actions/Actions_Reports.js';


import moment from 'moment';
import events from '../../common/Events';
import Calendar from '../../componets/Calendar';

// import BigCalendar from 'react-big-calendar';
import { momentLocalizer } from 'react-big-calendar';
import SumaryBookingPanel from '../../containers/Booking/SummaryBookingPanel.jsx';

//import CreateSupplier from './CreateSupplier';
import SupplierCard from './SupplierCard';
import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';

import Button from '../../constant/Button';

import { init_supplier } from '../../constant/initState/initSupplier';
import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';
import { getFormatted } from '../../common/SetDate.js'

import { Family, Fonts, Colors, Icons, Images } from '../../themes';

import NumberFormat from 'react-number-format';

import '../../App.css';
// import '../../containers/Promotions/card.css';
import DisplaySupplier from '../Supplier/DisplaySupplier';
import { SupplierType } from '../../constant/JSON/SupplierType';
const { PasswordIcon, BorderColorIcon, DoneIcon, CloseIcon } = Icons;

const localizer = momentLocalizer(moment);
const _CardInfo = {
    OneDayTrip: { name: 'OneDayTrip', amount: 0 },
    Ferries: { name: 'Ferries', amount: 0 },
    Transfer: { name: 'Transfer', amount: 0 },
    Hotel: { name: 'Hotel', amount: 0 }
}

const _height = (window.innerHeight - 130);

let BookingList = {};
let waitingTime = true;
let isDialogDisplay = false;

let BestStatList;
let SummaryBooking;

let SupplierList = [];
let BookBankList = [];
let ReportList = [];
let EventList = [];

let packageCount = [];
let _packageInfoList = [];

let _today;

class SupplierInfo extends Component {

    state = {
        editInfo: false, isPassword: false, isLoading: true, isCreate: false,
        isEdit: false, msgError: undefined, dialogType: undefined, sortFilde: undefined,
        isBtnActive: false, create: { ...init_supplier }, isAffiliate: false, displaySuppplier: '', isReports: false
    }

    constructor(props) {

        super(props)
        isDialogDisplay = (!!this.props.supplierInfo);

        _today = new Date();

        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        if (!!supplierInfo) {

            // logic get frist day of mounth ========================================================================================================================================

            const dateSelect = `${_today.getFullYear()}-${getFormatted(_today.getMonth() + 1)}`;
            const fritstDate = `${dateSelect}-01`;
            const lastDate = `${_today.getFullYear() + 1}-${getFormatted(_today.getMonth() + 1)}-${new Date(_today.getFullYear(), (_today.getMonth() + 1), 0).getDate()}`;

            // console.log('SupplierInfo : ', fritstDate, ' : ', lastDate)
            console.log('SupplierInfo : ', supplierInfo)

            // logic get frist day of mounth ========================================================================================================================================

            const { supplierId } = supplierInfo;
            this.props.getBookingListsWithCustomerID(supplierId);//not use
            //this.props.getAPISupplierList_WithParentId(supplierId);
            this.getSupplierListWithLimit();
            this.props.getAPIPackageCountSupplier({ supplierId: supplierId });
            // this.props.getAPIPackageCount();
            this.props.getAPIPackageList({ supplierId: supplierId, limit: 10 })
            this.props.getAPIgetBankList({ num_limit: 100 });
            this.props.getAPISupplierBookingPaymentInfoList({ supplierId: supplierId, startDate: fritstDate, endDate: lastDate })
            //BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment)) // or globalizeLocalizer
        }
    }

    componentWillUnmount() {
        this.ClearAllInfo();
    }

    ClearAllInfo = () => {

        BookingList = {};

        waitingTime = true;
        isDialogDisplay = false;
        BestStatList = undefined;
        SummaryBooking = undefined;

        _CardInfo.OneDayTrip.amount = 0;
        _packageInfoList = [];
        SupplierList = [];
        EventList = [];
    }

    componentWillReceiveProps(nextProps) {

        const { SupplierManager, BookingManager, PackageManager, StatiticManager, SettingManager, RepotsManager } = nextProps;
        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        // console.log(`[componentWillReceiveProps] : `, nextProps);

        if (!!SupplierManager) {

            //console.log(`SupplierManager [componentWillReceiveProps] : `, SupplierManager, );

            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;
            const { isEdit, isCreate } = this.state;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_WITHPARENT_ID_SUCCESS:
                    {
                        console.log(`!!! SupplierManager ${actions} : `, data);
                        //if (isEdit || isCreate)
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SupplierList = data.result;
                        //SetStateWithTime(this, waitingTime);
                    } break;

                case SupplierActions.GET_SUPPLIER_BOOKING_LIST_SUCCESS:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        EventList = data.results;
                    } break;

                case SupplierActions.GET_SUPPLIER_WITHPARENT_ID_FAILURE:
                    {
                        if (isEdit || isCreate)
                            SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        // SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!BookingManager) {

            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingActions.GET_LB_BOOKING_LISTS_SUCCESS:
                    {
                        if (!!data)
                            BookingList.bookingList = data.filter((booking) => (booking.userId === supplierInfo.id));

                    } break;
                case BookingActions.GET_LB_BOOKING_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!PackageManager) {

            const { data, actions, error } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        PackageManager.PACKAGE_REDUCER.actions = PackageAction.WATING_STATE;
                        //console.log(`SupplierManager [ GET_PACKAGE_LISTS_SUCCESS ] : `, this.props);

                        const { total, packageList } = data;
                        _packageInfoList = (data || []);//(data.package || []);     

                        // const amountOneDayTrip = packageList.length > 0 ? (packageList.filter(val => val.type === 'OneDayTrip').length) : 0

                        // _CardInfo.OneDayTrip.amount = amountOneDayTrip;
                        // _CardInfo.Transfer.amount = (total && amountOneDayTrip) ? total - amountOneDayTrip : 0;
                        // _CardInfo.Ferries.amount = packageList.length > 0 ? packageList.filter(val => (path(['packageInfo', 'transferType', 'conveyanceType'], val)) === 'ferries').length : 0

                        _CardInfo.OneDayTrip.amount = packageCount && packageCount.oneDayTrip
                        _CardInfo.Transfer.amount = packageCount && packageCount.transfer
                        _CardInfo.Ferries.amount = packageCount && packageCount.ferries

                        if (total > 0) {
                            const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
                            if (!!supplierInfo) {
                                this.props.getAPIbestProctStatWithIdList({ supplierId: supplierInfo.supplierId });
                                this.props.getAPIIncomeSupplierSummary({ supplierId: supplierInfo.supplierId, startDate: _today.setDate(_today.getDate() - 7) });
                            }
                            else
                                SetStateWithTime(this, waitingTime);
                        }
                        else
                            SetStateWithTime(this, waitingTime);

                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_COUNT_SUPPLIER_SUCCESS:
                    {
                        packageCount = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_COUNT_SUPPLIER_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }

        if (!!StatiticManager) {

            const { data, actions, error } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {
                case StatiticAction.GET_TOP_PRODUCT_STAT_SUCCESS:
                    {
                        StatiticManager.STATITIC_REDUCER.actions = StatiticAction.WATING_STATE;

                        // console.log(`StatiticManager ${actions} : `, data);
                        BestStatList = (!!data.results) ? (data.results.data || []) : [];
                        SetStateWithTime(this, waitingTime);

                    } break;
                case StatiticAction.GET_SUPPLIER_SUMMARY_INCOME_SUCCESS:
                    {
                        //console.log(`StatiticManager ${actions}  : `, data.results);
                        SummaryBooking = data.results || [];
                    } break;
                case StatiticAction.GET_TOP_PRODUCT_STAT_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        SettingManager.SETTING_REDUCER.actions = StatiticAction.WATING_STATE;
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!RepotsManager) {
            const { actions, data } = RepotsManager.REPORTS_REDUCER;

            switch (actions) {
                case ReportsAction.GET_SUPPLIER_REPORTS_SUCCESS:
                    {
                        RepotsManager.REPORTS_REDUCER.actions = ReportsAction.WATING_STATE;
                        const { total } = data.result;

                        if (total === 0) {
                            this.setState({ isLoading: true, msgError: data.result.data, dialogType: 'error', isBtnActive: false });
                        }
                        else {
                            this.setState({ isLoading: false, isReports: true })
                            ReportList = data.result;
                        }
                        console.log(`${actions}  : `, data.result);
                    } break;

                case ReportsAction.GET_SUPPLIER_REPORTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { isLoading, isCreate, isReports, msgError, dialogType, isAffiliate } = this.state;

        return (
            <div className="App">
                {(isAffiliate) && <DialogAction Active={isAffiliate} OnClose={this.onClosePanel} AcrionCom={this.SupplierAffiliatePanel()} />}
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.CreateSupplierPanel()} />}
                {(isReports) && <DialogAction Active={isReports} OnClose={this.onClosePanel} AcrionCom={this.ReportsListPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel(supplierInfo)}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel(supplierInfo) {

        const isDisplayInfo = Boolean(BestStatList);
        // console.log('supplierInfo', supplierInfo)
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                {this.InfoPanel(supplierInfo)}
                {this.packageSetPanel()}
                {(isDisplayInfo) &&
                    <React.Fragment>
                        <div style={{ marginTop: 10, height: '100%' }}>{this.calendarPanel(supplierInfo)}</div>
                        <SumaryBookingPanel SummaryBooking={SummaryBooking} Wallet={supplierInfo.wallet || 0} />
                        <BestProductStatPanel SupplierList={BestStatList} history={this.props.history} isLoading={this.state.isLoading} />
                    </React.Fragment>
                }
                <PackageListPanel packageInfoList={_packageInfoList} supplierId={supplierInfo.supplierId} onClickHandle={this.onClickHandle(PathName.PackageDetail)} />
                <SupplierListPanel
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    DisplaySupplierInfo={this.displaySuppplier()}
                    EditInfo={this.EditSupplierInfo()}
                    isEmployee={true}
                    SupplierList={SupplierList} />
            </div>
        )
    }

    calendarPanel = (supplierInfo) => {

        // console.log('calendarPanel', EventList, supplierInfo);

        const _event = [];

        for (let booking of EventList) {
            if (booking.total > 0)
                _event.push(
                    {
                        id: booking.Date,
                        title: `Booking ( ${booking.total} )`,
                        allDay: true,
                        start: booking.Date,
                        end: booking.Date,
                        action: "select" | "click"
                    }
                )
        }

        const _isDate = new Date();
        const _mount = _isDate.getMonth();
        const _year = _isDate.getFullYear();
        const _date = _isDate.getDate();
        const createDate = `${_year}-${getFormatted(_mount + 1)}-${getFormatted(_date)}`;

        return <Calendar
            date={_today}
            events={_event}
            localizer={localizer}
            onNavigate={() => { console.log('onNavigate :') }}
            onSelectEvent={(event) => {
                // console.log('onSelectSlot :', event[0], ' : ', event[1])
                this.getReportListWithDate(supplierInfo, (event[0] || createDate), (event[1] || createDate));
            }}
            onSelectSlot={(slot) => { console.log('onSelectSlot :', slot) }} /> // console.log('onSelectSlot :', slot)

    };

    getReportListWithDate(supplierInfo, _startDate, _endDate = '') {

        const { supplierId, name } = supplierInfo;
        const _selectDate = new Date(_startDate);
        const _endSelectDate = new Date(_selectDate.setDate(_selectDate.getDate() + 1));

        const startDate = _startDate;
        // const startDate = `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate())}`;
        // const endDate = `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate() + 1)}`;

        // console.warn('onSelectSlot :', startDate, ' : ', _endSelectDate);
        this.setState({ isLoading: true }, () => this.props.getAPISupplierRepost({ supplierId: supplierId, supplierName: name, startDate: startDate, endDate: _endSelectDate }))
    }

    ReportsListPanel = () => {
        return <ReportsListPanel ReportList={ReportList || []} OnClose={() => this.onClosePanel()} />
    }

    packageSetPanel() {

        // console.log('_packageInfoList', packageCount)
        // const amountOneDayTrip = !!_packageInfoList ? (_packageInfoList.packageList.filter(val => val.type === 'OneDayTrip').length) : null

        // _CardInfo.OneDayTrip.amount = (_packageInfoList && amountOneDayTrip) ? amountOneDayTrip : 0;
        // _CardInfo.Transfer.amount = (_packageInfoList && _packageInfoList.total && amountOneDayTrip) ? _packageInfoList.total - amountOneDayTrip : 0;
        // _CardInfo.Ferries.amount = (_packageInfoList && _packageInfoList.packageList.length > 0) ? _packageInfoList.packageList.filter(val =>
        // (path(['packageInfo', 'transferType', 'conveyanceType'], val)) === 'ferries').length : 0

        return (
            <div className="App-content">
                <div style={{ flex: 1, marginRight: '30px' }}>
                    <InfoCard
                        _supplierId={this.props.location.state.supplierInfo.supplierId}
                        Info={_CardInfo.OneDayTrip} Disable={false}
                        btnCreateHadle={this.btnCreateHadle(PathName.CreatePackage)}
                        btnViewHadle={this.btnViewHadle(PathName.PackageManage)} />
                </div>
                <div style={{ flex: 1, margin: '30px' }}>
                    <InfoCard Info={_CardInfo.Transfer} Disable={false}
                        btnCreateHadle={this.btnCreateHadle(PathName.CreateTransfer)}
                        btnViewHadle={this.btnViewHadle(PathName.TransferManage)} />
                </div>
                <div style={{ flex: 1, marginLeft: '30px' }}>
                    <InfoCard Info={_CardInfo.Ferries} Disable={false} View={true}
                        btnCreateHadle={this.btnCreateHadle(PathName.CreateFerries)}
                    // btnViewHadle={this.btnViewHadle(PathName.TransferManage)} 
                    />
                </div>
                {/* <div style={{ flex: 1, marginLeft: '30px' }}>
                    <InfoCard Info={_CardInfo.Hotel} Disable={true} />
                </div> */}
            </div>)
    }

    //========================================================================================================================================================================================================

    btnCreateHadle = (page) => () => {
        // console.log('btnCreateHadle : ', page);

        const { supplierInfo } = this.props.location.state;
        // const { supplierId } = supplierInfo;
        // console.log('_supplierId : ', supplierId);

        // let _supplierId = this.props.location.state.supplierInfo.supplierId
        this.props.history.push({ pathname: page, state: { supplierInfo: supplierInfo } });
    }

    btnViewHadle = (page) => () => {
        // console.log('this.props', this.props);
        const { supplierInfo } = this.props.location.state;
        // const { supplierInfo } = this.props.location.state;
        this.props.history.push({ pathname: page, state: { supplierInfo: supplierInfo } });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, isReports: false }));

    onCreatePanel = (_isActive) => () => { return this.setState({ isCreate: !_isActive, oldSupplierInfo: { ...init_supplier }, SupplierInfo: { ...init_supplier } }) };

    onClosePanel = (_supplierInfo, _parentId) => {

        console.log('_supplierInfo : ', _supplierInfo, _parentId);

        if (_supplierInfo && !_parentId) {
            this.props.location.state.supplierInfo = _supplierInfo;
            this.setState({ editInfo: false, isPassword: false, isCreate: false, isEdit: false, isReports: false, isAffiliate: false, create: { ...init_supplier }, error: null, SupplierInfo: undefined, isLoading: false, msgError: undefined, dialogType: undefined }, () =>
                this.props.history.replace({ pathname: PathName.SupplierInfo, state: { supplierInfo: _supplierInfo }, search: '', hash: '' }));
        } else {
            this.setState({ editInfo: false, isPassword: false, isCreate: false, isEdit: false, isReports: false, isAffiliate: false, create: { ...init_supplier }, error: null, SupplierInfo: undefined, isLoading: false, msgError: undefined, dialogType: undefined })

        }

        // if(_supplierInfo){
        //     this.props.location.state.supplierInfo = _supplierInfo;
        //     this.setState({ editInfo: false, isPassword: false, isCreate: false, isEdit: false, isReports: false, isAffiliate: false, create: { ...init_supplier }, error: null, SupplierInfo: undefined, isLoading: false, msgError: undefined, dialogType: undefined }, () =>
        //         this.props.history.replace({ pathname: PathName.SupplierInfo, state: { supplierInfo: _supplierInfo }, search: '', hash: '' }));

        // }else{
        //     this.setState({ editInfo: false, isPassword: false, isCreate: false, isEdit: false, isReports: false, isAffiliate: false, create: { ...init_supplier }, error: null, SupplierInfo: undefined, isLoading: false, msgError: undefined, dialogType: undefined })
        // }


    };

    onClickHandle = (page) => (_packageId) => {
        const { supplierInfo } = this.props.location.state;
        const { supplierId } = supplierInfo;
        console.log('_supplierId : ', supplierId);
        console.log('onClickHandle : ', page, ' : ', _packageId);
        const _packageInfo = _packageInfoList.packageList.find((_package) => { return _package.packageId === _packageId });
        console.log('packageInfo : ', _packageInfo);

        this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo, supplierInfo: supplierInfo }, search: '', hash: '' });
    }

    CallBookingDetail(_id) {
        //console.log('CallBookingDetail : ', _id)

        this.props.getAPIBookingDetails(_id);
        this.props.history.push({ pathname: PathName.BookingDetail, state: {}, search: _id, hash: '' });
    }

    CreateSupplierPanel = () => {

        const { isEdit, SupplierInfo, oldSupplierInfo, editInfo } = this.state;
        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        return (
            <SupplierCard
                EditInfo={editInfo}
                isEdit={isEdit}
                parentId={supplierInfo.supplierId}
                BookBankList={BookBankList}
                SupplierInfo={SupplierInfo}
                oldSupplierInfo={oldSupplierInfo}
                onClosePanel={this.onClosePanel}
                getAPISupplierList={this.getSupplierListWithLimit} />
        )
    }

    EditInfo = () => {

        const { editInfo } = this.state;
        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        return (
            <SupplierCard
                EditInfo={editInfo}
                isEdit={editInfo}
                BookBankList={BookBankList}
                SupplierInfo={supplierInfo}
                onClosePanel={this.onClosePanel}
                getAPISupplierList={this.getSupplierListWithLimit}
            />
        )
    }

    getSupplierListWithLimit = () => {

        const { page, limit, } = SupplierList;
        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const _fliter = { where: { parentId: supplierInfo.supplierId }, page: (page || 0), num_limit: (limit || 10) };
        console.log('_fliter', _fliter);
        this.props.getAPISupplierList_WithParentId(_fliter);
    }

    EditSupplierInfo = () => (SupplierInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            editInfo: false,
            SupplierInfo,
            oldSupplierInfo: SupplierInfo,
        });
    }

    displaySuppplier = () => (supplierId) => { this.setState({ isAffiliate: true, supplierId: supplierId }) }

    SupplierAffiliatePanel = () => {

        const { supplierInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { supplierId } = this.state.supplierId;
        const _supplierInfo = SupplierList.datas.find((_item) => (_item.supplierId === supplierId));

        return (
            <DisplaySupplier
                BookBankList={BookBankList}
                SupplierInfo={_supplierInfo}
                supplierID={supplierId}
                OnClose={() => this.onClosePanel()}
            />
        )
    }
    //========================================================================================================================================================================================================

    InfoPanel(supplierInfo) {

        console.log('supplierList : ', supplierInfo);

        const { classes } = this.props;
        const { isPassword, editInfo } = this.state;
        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10, fontFamily: Family.roboto, };
        const { supplierId, email, name, userType, type, address, reservation_number, telOffice, logo, visible, approved, officialWebsite } = supplierInfo;
        const _type = SupplierType.filter(_info => type.some(each => _info.id === each)).map(each => each.type);

        // console.log('SupplierType', SupplierType)
        // console.log('_type', _type)
        // console.log('type', type)
        // const _type = [];
        // SupplierType.forEach(_info => {
        //     if (type.some(each => _info.id === each)) {
        //         _type.push(_info.type)
        //     }
        // })

        return (
            <div style={{
                display: 'flex', height: '328px', width: '1020px',
                backgroundColor: Colors.themeDark,
                // backgroundImage: `url(${Images.HeaderBackground.HeaderBackground})`,
            }} >
                {(isPassword) && <DialogAction Active={isPassword} OnClose={this.onClosePanel} AcrionCom={<ChangePasswordCard id={supplierId} onClosePanel={this.onClosePanel} />} />}
                {(editInfo) && <DialogAction Active={editInfo} OnClose={this.onClosePanel} AcrionCom={this.EditInfo()} />}

                < div style={{ height: '300px', width: '250px', paddingLeft: '50px', paddingTop: '50px', paddingRight: '50px', borderradius: '10%', boxshadow: '0  0 5px rgba(0, 0, 0, 0.2)', }} >
                    <img className={classes.avataronline} src={(logo.original) ? (`${CONFIG.host}${logo.original}`) : Images.User.userdefault} />
                </div>
                <div style={{ width: '100%', height: '200px', padding: '15px', textAlign: 'left' }}>
                    <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Icons themeDark icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { this.setState({ editInfo: true }) }} />
                        <Button.Icons themeDark icon={<PasswordIcon />} tooltip={'Change Password'} onClick={() => { this.setState({ isPassword: true }) }} />
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Supplier Name : </label>
                        <label className={classes.july32017copy}>{`${name || ''}`}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Supplier ID : </label>
                        <label className={classes.july32017copy}>{supplierId}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Phone number : </label>
                        <label className={classes.july32017copy}>{telOffice || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Email : </label>
                        <label className={classes.july32017copy}>{email || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Official Website : </label>
                        <label className={classes.july32017copy}>{officialWebsite || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Type : </label>
                        <label className={classes.july32017copy}>{userType || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Visible : </label>
                        <label className={classes.july32017copy}>{visible ? 'Active' : 'Inactive'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Regiter Type : </label>
                        <label className={classes.july32017copy}>{_type.join() || '-'}</label>
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getBookingListsWithCustomerID: (memberID) => {
            InitWaitingTime();
            dispatch(BookingActions.get_LB_APIBookingLists(memberID));
        },
        getAPIPackageList: (_fliter) => {
            // InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_fliter));
        },
        getAPISupplierList: (_keyword, _fliter) => {
            dispatch(SupplierActions.getSupplierList(_keyword, _fliter));
        },
        getAPISupplierList_WithParentId: (_keyword, _fliter) => {
            dispatch(SupplierActions.getSupplierList_WithParentId(_keyword, _fliter));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingActions.getAPIBookingDetail(_id));
        },
        getAPIbestProctStatWithIdList: (_fliter) => {
            dispatch(StatiticAction.getbestProctStatWithId(_fliter));
        },
        getAPIIncomeSupplierSummary: (_fliter) => {
            dispatch(StatiticAction.getIncomeSupplierSummary(_fliter));
        },
        getAPIgetBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(SettingActions.getBankList(_keyword));
        },
        getAPISupplierRepost: (_fliter) => {
            dispatch(ReportsAction.getSupplierRepost(_fliter));
        },
        getAPISupplierBookingPaymentInfoList: (_fliter) => {
            dispatch(SupplierActions.getSupplierBookingPaymentInfoList(_fliter));
        },
        // getAPIPackageCount: (_keyword) => {
        //     InitWaitingTime();
        //     dispatch(PackageAction.getAPIPackageCount(_keyword));
        // },
        getAPIPackageCountSupplier: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageCountSupplier(_keyword));
        },
        // getBookingWithPackageId: (_keyword) => {
        //     dispatch(BookingActions.getBookingWithPackageId(_keyword));
        // },
    }
}

const styles = theme => (
    {
        cssRoot: {
            color: theme.palette.getContrastText(blue[500]),
            backgroundColor: blue[500], '&:hover': { backgroundColor: blue[700], },
        },
        july32017copy: {
            height: '16px',
            opacity: '0.64',
            color: Colors.white,
            fontSize: '12px',
            lineHeight: '16px',
        },
        class: {
            height: '16px',
            width: '110px',
            color: Colors.white,
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '16px',
        },
        avataronline: {
            position: 'relative',
            boxShadow: '0 0 1px 1.9px ',
            backgroundRepeat: 'round',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            marginRight: '20%',
            background: Colors.white,
        }
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SupplierInfo));