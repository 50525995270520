import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CONFIG from '../../config';
import * as MemberActions from '../../actions/Actions_MemberManager.js';
import * as UploadActions from '../../actions/Actions_UploadManager.js';

import Avatar from '../../constant/Avatar';
import DialogPanel from '../../componets/DialogPanel.jsx';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import { PathName, ErrorCode, SetMemberToCrate } from '../../constant';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import Member from '../../constant/JSON/Member';
import { init_member } from '../../constant/initState/initMember';

import { CheckEmailFormat } from '../../common/EmailFormat.js';
import '../Supplier/Supplier.css';
import FormTypes from '../../common/FormTypes';

let waitingTime = true;
let _memberInfo;
let _images;
let requiredList = [];

const _rowContainer = { display: 'flex', paddingTop: 5 };

class CreateMember extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, isBtnActive: false }

    constructor(props) {
        super(props);
        _memberInfo = this.props.MemberInfo || { ...init_member };
    }

    componentWillReceiveProps(nextProps) {

        const { UpLoadManager, MemberManager } = nextProps;

        if (!!UpLoadManager) {

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE
                        _memberInfo.avatarLink = data.result.upload.images;
                        const { isEdit, CreateMember, UpdateMember } = this.props;
                        console.log('UPLOAD_IMAGE_SUCCESS :')

                        if (isEdit) {
                            UpdateMember(_memberInfo);
                        } else {
                            CreateMember(SetMemberToCrate(_memberInfo))
                        }

                    } break;

                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });

                    } break;
            }
        }

        if (!!MemberManager) {

            const { data, actions, error } = MemberManager.MEMBER_REDUCER;

            switch (actions) {
                case MemberActions.CREATE_MEMBER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Staff Success.', dialogType: 'success', });
                        this.props.getAPIMemberList({ page: 0, num_limit: 10 });
                    } break;
                case MemberActions.UPDATE_MEMBER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Staff Success.', dialogType: 'success', });
                        this.props.getAPIMemberList({ page: 0, num_limit: 10 });
                    } break;
                case MemberActions.CREATE_MEMBER_FAILURE:
                case MemberActions.UPDATE_MEMBER_FAILURE:
                    {
                        console.log("CREATE_STAFF_FAILURE : ", data.response.data.error.message);
                        const _msg = data.response.data.error.message || actions;//ErrorCode[data.status].EN;
                        // UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    componentWillUnmount() {
        // console.log('componentWillUnmount : ', _memberInfo)
        _images = undefined;
        _memberInfo = undefined;
    }

    render() {

        const { OnClose, classes, isEdit } = this.props;
        const { isBtnActive, isLoading, msgError, dialogType } = this.state;
        requiredList = [];

        return (
            <div style={{ maxWidth: 800 }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? Member.HeaderPage.UPDATE_EN : Member.HeaderPage.CREATE_EN}</Typography>
                </div>
                {
                    Member.BodyPage.map((row, index) => {
                        return (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => (<div key={index} style={{ marginRight: (each.margin === 'right') ? 15 : null, width: each.width ? each.width : '100%' }}>
                                    {this.SetItemsWithType(each)}
                                </div>))}
                            </div>
                        )
                    })
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => OnClose()} />
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_SAVE}
                        disabled={!isBtnActive} onClick={() => this.onSave()} />

                </div>
            </div>
        )
    }

    SetItemsWithType(each) {

        const { classes, MemberInfo, } = this.props;

        if (each.required)
            requiredList.push(each.stateName);

        //CreatePanel, onSave, handleChange, oldMemberInfo, name 
        // console.log('MemberInfo', MemberInfo);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        // disabled={isView ? true : false}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={_memberInfo[each.stateName] || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }

            case 'email': {
                return (
                    <FormTypes.FormEmail
                        // disabled={isView ? true : false}
                        // error={isError}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={_memberInfo[each.stateName] || ''}
                        onChange={value => { this.onChangeState(each.stateName, value) }} />
                )
            }
            case 'image': {
                return (
                    <div className={classes.textDefalut} >
                        <Avatar editable ref='avatar'
                            onChange={(files) => { this.onChangeState(each.stateName || each.type, files) }}
                            avatar={Boolean(_memberInfo.avatarLink.thumbnail) ? `${CONFIG.host}${_memberInfo.avatarLink.thumbnail}` : ''} />
                    </div>)
            }

            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        ValueText={each.title_EN}
                        checked={path(['visible'], MemberInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }
        }
    }

    // =====================================================================================================================================================

    getRequiredSymbol(item) {
        return (item.required) ? (<React.Fragment><span style={{ color: 'red' }}>{`* `}</span>{item.title_EN} </React.Fragment>) : (item.title_EN);
    }

    getRequiredDateSymbol(item, classes) {

        return (item.required) ?
            (<React.Fragment>
                <span style={{ color: 'red' }}>{`* `}</span>
                <label className={classes.textDatePicker} style={{ fontSize: 13 }} >{item.title}</label>
            </React.Fragment>) :
            (<label className={classes.textDatePicker} style={{ fontSize: 13 }} >{item.title}</label>);
    }

    // =====================================================================================================================================================

    onChangeState = (_key, _value) => {

        switch (_key) {
            case "firstName":
            case "lastName":
                {
                    _memberInfo[_key] = _value;
                    this.ValidateRequiredList();
                } break;

            case "email":
                {
                    _memberInfo[_key] = _value;
                    CheckEmailFormat(_memberInfo[_key]) ? this.ValidateRequiredList() : this.forceUpdate();
                } break;

            case "avatarLink":
                {
                    _images = _value;
                    this.ValidateRequiredList();
                } break;

            default:
                {
                    _memberInfo[_key] = _value;
                    this.ValidateRequiredList();
                } break;
        }
    }

    // =====================================================================================================================================================

    ValidateRequiredList() {

        const _isEvery = requiredList.every((item) => Boolean(_memberInfo[item]));
        const { isBtnActive } = this.state;

        if (!isBtnActive)
            (_isEvery) ? this.setState({ isBtnActive: true }) : this.forceUpdate();
        else if (isBtnActive && !_isEvery)
            this.setState({ isBtnActive: false })
        else
            this.forceUpdate();
    }

    // =====================================================================================================================================================

    onSave = () => {

        const { isEdit, UpdateMember, CreateMember } = this.props;

        if (!!_images)
            this.setState({ isLoading: true, isBtnActive: false }, () => this.upLoadImage(_images));
        else
            this.setState({ isLoading: true, isBtnActive: false }, () => ((isEdit) ? UpdateMember(_memberInfo) : CreateMember(SetMemberToCrate(_memberInfo))));

    }

    upLoadImage = (_image) => {

        let formdata = new FormData();
        formdata.append('imgThumbnailWidth', 200);
        formdata.append('imgThumbnailHeight', 200);
        formdata.append("images", _image);

        this.props.uploadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => (
    {
        textField: { width: '100%', marginTop: 0, },
        textFormLabel: { fontSize: 18, marginTop: -3, },
        textDefalut: { margin: '5px 0px', fontSize: '16px', color: Colors.themeDark, fontFamily: Family.roboto },
        title: { display: 'flex', fontSize: '24px', alignItems: 'center' },
        textInput: {
            width: '100%', marginTop: 5, padding: 10,
            color: Colors.themeDark, borderRadius: 0, border: `${'1px solid' + Colors.border}`, fontSize: 16,
        },

    }
);

CreateMember.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIMemberList: (_filter) => {
            InitWaitingTime();
            dispatch(MemberActions.getMemberList(_filter));
        },
        CreateMember: (memberInfo) => {
            dispatch(MemberActions.APICreateMember(memberInfo));
        },
        UpdateMember: (memberInfo) => {
            dispatch(MemberActions.APIUpdateMember(memberInfo));
        },
        uploadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true })(CreateMember)));











