import React, { Component, Fragment } from 'react';
import Rating from 'react-rating';
import { withRouter } from 'react-router';

import config from '../../config';
import { Images, Svg, Fonts } from '../../themes';
import { ConvertDateAll } from '../../common/SetDate';
import { GetSymbol, Cuurency } from '../../common/GetSymbol';
import { PathName as pathName, packageType, promotionType, ConveyanceType } from "../../constant";

// import WishListIconPanel from './WishListIconPanel';

import { isMobile, isSafari } from '../../common/DeviceCheck.js';
// import { SetFakeReviewWithPackageId } from './PackageFakeBooked';
// import { ConveyanceType } from '../../containers/Search/FilterTypeInfo';
import { NumberFormats, SortLargeNumber } from '../../common/SetNumberFormat';

// import TraceFacebookPixel, { TrackType } from '../../components/TraceInfo/FacebookPixel';
// import { Images, Metrics, Colors } from '../../themes';

import './Card.css';
import styled, { css } from 'styled-components';
import { wordingKeys } from "../../constant/enum/packageLabelKeys";
// import localization, { isLanguage } from "../../services/localization";

import Language from '../../constant/JSON/Language';
import PackageLabel from '../../constant/JSON/PackageLabel';

let packageLabel;
const { ConstLanguage } = Language;
const isLanguage = ConstLanguage.EN;

const CardHeight = 211;
const ActiveBags = false;
const hostName = config.host;
const { duration, passengers, bags, sale } = Images.package;
// const { ratingActive, ratingDefault, duration, bestSeller, passengers, bags, sale } = Images.package;

class RowPackagesCard extends Component {

    state = { loading: true, }

    constructor(props) {

        super(props);
        this.onChoosePackage = this.onChoosePackage.bind(this);
    }

    render() {

        // console.log('RowPackagesCard : ', this.props.packageInfo)
        //packageLabel = localization(wordingKeys.packageLabel);

        packageLabel = PackageLabel;

        return this.packageItem(this.props.packageInfo, true); //isMobile()
    }

    packageItem(_package, _isMobile) {

        const { packageId, type, packageInfo } = _package;
        const { name } = packageInfo;
        const newSetInfo = { packageId, type, name };

        return (
            <RowCardContiner isMobile={_isMobile}>
                <FlexImageContianer>
                    {this.imagePanel(newSetInfo, _package, _isMobile)}
                </FlexImageContianer>
                <FlexInfoContianer>
                    {this.packageNamePanel(name, _isMobile)}
                    {this.infoPanel(_package, _isMobile)}
                    {this.ratingPanel(_package, _isMobile)}
                </FlexInfoContianer>
            </RowCardContiner >
        );
    }

    onChoosePackage() {

        const { packageInfo, filterDate, history } = this.props;
        const { packageId, type } = packageInfo;
        const { name } = packageInfo.packageInfo;
        const _newSetInfo = { packageId, type, name }
        const locale = isLanguage; //isLanguage();

        // TraceFacebookPixel({ trackType: TrackType.AddToCart, data: _newSetInfo })
        // TraceFacebookPixel({ trackType: TrackType.ViewContent, data: _newSetInfo });
        const localState = { packageInfo: [packageInfo], FilterDate: (filterDate) && ConvertDateAll(filterDate), locale };
        // history.push({ pathname: pathName.CHOOSE_PACKAGE, state: localState, search: `${packageId}&language=${isLanguage()}` });
    }

    imagePanel(_newSetInfo, _package, _isMobile) {

        const { image, promotionInfo, price, name } = _package.packageInfo;
        const { isPromotion } = _package;
        const { loading } = this.state;

        return (
            <ImageContianer>
                <ImageInnerBox className="item" isMobile={_isMobile} >
                    <ProductImage isMobile={_isMobile} src={`${hostName}${image.thumbnail}`} alt={name} onLoad={() => { (loading) && this.setState({ loading: !loading }) }} onClick={this.onChoosePackage} />
                    {(loading) && <LoadingContianer className="imageLoding" isMobile={_isMobile} style={_isMobile ? loadingStyleMobile : loadingStyle} />}
                    {(isPromotion && Boolean(promotionInfo)) && this.promotionContainer(promotionInfo, price, _isMobile)}
                    {/* <WishListIconPanel isMobile={_isMobile} packageInfo={_newSetInfo} /> */}
                </ImageInnerBox>
            </ImageContianer>
        )
    }

    packageNamePanel(_name, _isMobile) {

        const isReFont = _isMobile ? (_name.length > 43) : (_name.length > 55);

        return (
            <PackageNameContianer isMobile={_isMobile} onClick={this.onChoosePackage} >
                <ProductName isMobile={_isMobile} isReFont={isReFont}>{_name}</ProductName>
            </PackageNameContianer>
        )
    }

    infoPanel(_package, _isMobile) {

        // const { image, price, promotionInfo, name, scheduleTime, desShort, } = _package.packageInfo;
        // const { price, promotionInfo, scheduleTime, transferType } = packag

        const { isPromotion, type, packageInfo } = _package;
        const { price, promotionInfo, } = packageInfo;

        const isPrice = (isPromotion) ? promotionInfo : price;
        const isTranfers = (type === packageType.Transfer);

        return (
            <InfoContianer isMobile={_isMobile} onClick={this.onChoosePackage} >
                {(isTranfers) ?
                    this.TransferInfoPanel(_package, _isMobile) :
                    this.OneDayTripsInfoPanel(_package, _isMobile)
                }
                {(isPromotion) ?
                    <PriceContianerWithPromotion isMobile={_isMobile}>
                        <RealPriceLabel isMobile={_isMobile}>
                            {`${GetSymbol(isPrice.currency)} ${NumberFormats(isPrice.adult)}`}
                        </RealPriceLabel>
                        {this.promotionInfo(price, promotionInfo, _isMobile)}
                    </PriceContianerWithPromotion> :
                    <PriceContianer isMobile={_isMobile} >
                        <RealPriceLabel isMobile={_isMobile}>
                            {`${GetSymbol(isPrice.currency)} ${NumberFormats(isPrice.adult)}`}
                        </RealPriceLabel>
                    </PriceContianer>}
            </InfoContianer>
        )
    }

    OneDayTripsInfoPanel(_package, _isMobile) {

        const { scheduleTime, } = _package.packageInfo;

        return (
            <InfoIconContianer isMobile={_isMobile}>
                <DurationContianer isMobile={_isMobile}>
                    <ImgDuration isMobile={_isMobile} src={duration} className="icon" alt='duration' />
                    <DetialLabel isMobile={_isMobile}>{this.getDurationTime(scheduleTime, _isMobile)}</DetialLabel>
                </DurationContianer>
            </InfoIconContianer>
        )
    }

    TransferInfoPanel(_package, _isMobile) {

        const { scheduleTime, transferType, language } = _package.packageInfo;
        const { bags: infoBags, conveyanceType: infoConveyance, passengers: infoPassengers, } = transferType;
        const _isTHLanguage = (language === ConstLanguage.TH);
        const _passengersWording = (_isTHLanguage) ?
            ` ${packageLabel[wordingKeys.Passengers]} ${infoPassengers || 0} ${packageLabel[wordingKeys.PassengersUnit]}` :
            ` ${infoPassengers || 0} ${packageLabel[_isMobile ? wordingKeys.ShortPassengers : wordingKeys.Passengers]}`;

        return (
            <InfoIconContianer isMobile={_isMobile}>
                <DurationContianer isMobile={_isMobile}>
                    <ImgDuration isMobile={_isMobile} src={duration} className="icon" alt='duration' />
                    <DetialLabel isMobile={_isMobile}>{this.getDurationTime(scheduleTime, _isMobile)}</DetialLabel>
                </DurationContianer>
                {(infoConveyance !== ConveyanceType.Ferries) &&
                    <Fragment>
                        <PassengersContianer isMobile={_isMobile}>
                            <ImgPassengers src={passengers} className="icon" alt='passengers' />
                            <DetialLabel isMobile={_isMobile}>{_passengersWording}</DetialLabel>
                        </PassengersContianer>
                        {(ActiveBags) &&
                            <BagsContianer isMobile={_isMobile}>
                                <ImgBags src={bags} isMobile={_isMobile} className="icon" alt='bags' />
                                <DetialLabel isMobile={_isMobile}>{` ${infoBags || 0} ${packageLabel[wordingKeys.StandardBags]}`}</DetialLabel>
                            </BagsContianer>}
                    </Fragment>}
            </InfoIconContianer>
        )
    }

    ratingPanel(_package, _isMobile) {

        const { rating, packageInfo, packageId, isBestSeller } = _package;
        const { booked } = (packageInfo.booked === undefined) ? 0 : packageInfo.booked;
        //const fakeReviews = SetFakeReviewWithPackageId(packageId, rating.reviews);
        const fakeReviews = rating.reviews;
        const iconStyle = (_isMobile) ? ratingIconMobile : ratingIcon;
        const iconSellerStyle = (_isMobile) ? bestSellerIconMobile : bestSellerIcon;

        return (
            <RatingContianer isMobile={_isMobile} onClick={this.onChoosePackage} >
                <div >
                    <Svg.Landing.GetLink style={iconSellerStyle} />
                </div>
                <ReviewsInfoContianer>
                    <ReviewsInfoLeft isMobile={_isMobile}>
                        <Rating
                            emptySymbol={<Svg.Landing.Star style={iconStyle} color={'#9d9d9d'} />}
                            fullSymbol={<Svg.Landing.Star style={iconStyle} />}
                            initialRating={rating.averagePoint}
                            readonly
                            fractions={2} />
                    </ReviewsInfoLeft>
                    <ReviewsInfoRight isMobile={_isMobile}>
                        <RatingLable isMobile={_isMobile}>
                            {(_isMobile) ?
                                `( ${fakeReviews || 0} ) | ${SortLargeNumber(booked, 2, packageId)} ${packageLabel[wordingKeys.Booked]}` :
                                `${fakeReviews || 0} ${packageLabel[wordingKeys.Reviews]} | ${SortLargeNumber(booked, 2, packageId)} ${packageLabel[wordingKeys.Booked]}`}
                        </RatingLable>
                    </ReviewsInfoRight>
                </ReviewsInfoContianer>
            </RatingContianer>
        )
    }

    getDurationTime(scheduleTime, _isMobile) {

        const { start, unit } = scheduleTime;

        if (_isMobile)
            return `${packageLabel[wordingKeys.Duration]} ${start || '-'}${packageLabel[`${unit}Shot`] || ''}`;
        else
            return `${packageLabel[wordingKeys.Duration]} ${start || '-'} ${packageLabel[unit] || ''}`;
    }

    promotionInfo(_price, _promotionInfo, _isMobile) {

        const { type, adult, currency } = _promotionInfo;
        const _discount = (type === promotionType.Percent) ? ` -${_promotionInfo['percent-adult']} %` : ` ${GetSymbol(currency)} -${NumberFormats(_price.adult - adult)}`;

        return (
            <PromotionInfoContianer isMobile={_isMobile}>
                <PriceCossLabel isMobile={_isMobile}>
                    <CossPrice>{`${GetSymbol(currency)} ${NumberFormats(_price.adult)} `}</CossPrice>
                </PriceCossLabel>
                <PriceLabel isMobile={_isMobile}>
                    {_discount}
                </PriceLabel>
            </PromotionInfoContianer>
        );
    }

    promotionContainer(_promotion, _price, _isMobile) {

        const { type, adult, currency } = _promotion;
        const _isPercent = (type === promotionType.Percent);
        const _discount = (_isPercent) ? ` ${Number.parseInt(_promotion['percent-adult'])}%` : ((currency === Cuurency.THB) ? `${(_price.adult - adult).toFixed(0)} ${GetSymbol(currency)}` : `${NumberFormats(_price.adult - adult)} ${GetSymbol(currency)}`);
        const _is2Digit = (_isPercent && (_promotion['percent-adult'] > 9));

        return (
            <BubbleContianer>
                <BubbleFlex>
                    <BubbleLeft></BubbleLeft>
                    <BubbleRight>
                        <ImgBubble src={sale} alt='sale' isMobile={_isMobile} />
                        <PromotionContianer className="promotinPrice" is2Digit={_is2Digit} isMobile={_isMobile}>
                            <DiscountLabel isPercent={_isPercent} isMobile={_isMobile}>{_discount}</DiscountLabel>
                        </PromotionContianer>
                    </BubbleRight>
                </BubbleFlex>
            </BubbleContianer>);
    }

}

export default withRouter(RowPackagesCard);


const { avenir, avenirHeavy, avenirBlack } = Fonts.family;
const _isSafari = isSafari();

const loadingStyle = { left: '35%', bottom: '35%', position: 'absolute' }
const loadingStyleMobile = { left: '30%', bottom: '24%', position: 'absolute', width: 60, height: 60 };
const ratingIcon = { width: 19, height: 18, };
const ratingIconMobile = { width: 12, height: 11, };
const bestSellerIcon = { width: '138px', height: '34px', marginLeft: '0.9375rem', cursor: 'pointer', marginTop: '0px', };
const bestSellerIconMobile = { cursor: 'pointer', width: 80, height: 21, marginLeft: 10, marginTop: 6, };

const RowCardContiner = styled.div`    
height: 211px;
width: 100%;
display: flex;
background-color: rgb(255, 255, 255);
box-shadow: rgba(0, 0, 0, 0.18) 3px 2px 10px 0px;
margin: 20px 0px;
cursor: pointer;
${(props) => props.isMobile && css`height: 119.38px;margin: 5px 0px;`}`;

const FlexImageContianer = styled.div`flex: 1 1 0%;`;
const FlexInfoContianer = styled.div`flex: 3 1 0%;`;

const InfoContianer = styled.div`    
display: flex;
justify-content: space-between;
padding: 5px 15px;
height: 3.75em;
margin: 5px 0px;
${(props) => props.isMobile && css`height:unset;margin:0px;padding: 0px 10px;`}`;

const ImageContianer = styled.div`
height:${CardHeight}px;
&:hover .promotinPrice {-moz-transform: scale(1.12);-webkit-transform: scale(1.12);transform: scale(1.12);`;

const ImageInnerBox = styled.div`
width: 265px;
height: 211px;
position: relative;
${(props) => props.isMobile && css`width: 148.72px;height: 119.38px;`}`;

const ProductImage = styled.img`
background-color: rgba(155, 155, 155, 0.35);
width: 265px;
height: 211px;
${(props) => props.isMobile && css`width: 148.72px;height: 119.38px;`}`;

const LoadingContianer = styled.div`
left: 35%;
bottom: 35%;
position: absolute;
${(props) => props.isMobile && css`left: 30%;bottom: 24%;width: 60px;height: 60px;`}`;

const PriceContianerWithPromotion = styled.div`
text-align: right;
margin-top: 5px;
height: 45px;
${(props) => props.isMobile && css`height: unset;`}`;

const PriceContianer = styled.div`
text-align: right;
margin-top: 30px;
height: 45px;
${(props) => props.isMobile && css`margin-top: 26px;height: 20px;`}`;

const RealPriceLabel = styled.label`
width: 100%;
color: #B70302;
font-family: ${avenirHeavy};
font-size: 26px;
letter-spacing: 0.4px;
text-align: right;}
${(props) => props.isMobile && css`font-size:16px;letter-spacing:-0.2px;`}`;

const PromotionInfoContianer = styled.div`    
margin-top: -3px;
${(props) => props.isMobile && css`margin-top: -7px;`}`;

const PromotionContianer = styled.div`
margin-top: -65px;
margin-left: 1px;
width: 100%;
height: 50px;
text-align: center;
position: sticky;
${(props) => props.is2Digit && css``}
${(props) => props.isMobile && css`margin-top: -43px;`}`;

const ProductName = styled.p`	
margin: 0px;
width: 100%;	
color: #353535;	
font-family: ${avenirHeavy};	
font-size: 27px;	
line-height: 33px;
cursor: pointer;
text-align: left;
${ (props) => props.isReFont && css`font-size:23.5px;`}
${ (props) => props.isMobile && css`font-size:15px;line-height: 17px;`}
${ (props) => (props.isMobile && props.isReFont) && css`font-size:12.6px;line-height: 17px;`} `;

const RatingContianer = styled.div`
display: flex;
justify-content: space-between;
margin: 5px 0px;
padding: 5px 0px; 
${(props) => props.isMobile && css`margin:2px 0px 0px 0px;padding: 0px;`}`;

const PriceLabel = styled.label`    
width: 100%;
color: rgb(53, 53, 53);
font-family:${avenir};	
font-size: 15px;
${(props) => props.isMobile && css`font-size: 9px;`}`;

const PriceCossLabel = styled.label`;	
width: 100%;	
color: #9B9B9B;	
font-family: ${avenir};	
font-size: 18px;
${(props) => props.isMobile && css`font-size: 11px;letter-spacing: -0.48px;`}`;

const CossPrice = styled.del`
color: rgb(155, 155, 155);
padding: 0px 3px;`;

const BubbleContianer = styled.div`
width: 100%;
text-align: right;
position: absolute;
top: 1rem;
padding-right: 13px;
padding-left: 13px;`;

const BubbleFlex = styled.div`
display: flex;
width: 100%;`;

const BubbleLeft = styled.div`
flex: 1 1 0%;`;

const BubbleRight = styled.div`
flex: 0.4 1 0%;`;

const ImgBubble = styled.img`
width: 66.79px;
height: 74.75px;
${(props) => props.isMobile && css`width: 33.35px;height: 37.38px;`}`;

const ImgBestSeller = styled.img`
width: 138px;
height: 34px;
margin-left: 0.9375rem;
cursor: pointer;
margin-top: 0px;
${(props) => props.isMobile && css`width: 80px;height: 21px;margin-left:10px;margin-top:6px;`}`;

const ImgRating = styled.img`
width: 19px;
height: 18px;
${(props) => props.isMobile && css`width: 12px;height: 11px;`}`;

const DiscountLabel = styled.label`
font-size: 1.063rem;
font-weight: 900;
height: 28px;
width: 126px;
color: rgb(255, 255, 255);
font-family: ${avenir};
line-height: 28px;
${(props) => props.isPercent && css`font-family: ${avenirBlack};font-size: 1.5rem;`}
${(props) => props.isMobile && css`font-size:8.5px;`}`;

const DetialLabel = styled.label`    
height: 16px;
width: 100%;
color: #9B9B9B;
font-family: ${avenirHeavy};
font-size: 12px;
font-weight: bold;
letter-spacing: ${_isSafari ? -1 : -0.09}px;
${ (props) => props.isMobile && css`font-size:9px;letter-spacing: -0.01px;height: 13px;`}`;

const RatingLable = styled.label`
height: 14px;	
width: 100%;	
color: #9B9B9B;	
font-family: ${avenirHeavy};	
font-size: 12px;	
font-weight: 900;	
letter-spacing: -0.08px;	
line-height: 14px;	
text-align: center
${ (props) => props.isMobile && css`font-size:9px;letter-spacing: -0.6px;height:unset;line-height:unset,margin-right:2px;`}`;

const PackageNameContianer = styled.div`    
display: flex;
padding: 0px 10px;
height: 66px;
margin: 7px 0px 0px 0px;
${ (props) => props.isMobile && css`height: 31px;`}`;

const ReviewsInfoContianer = styled.div`    
text-align: right;
padding: 0px 10px 0px 0px`;

const ReviewsInfoLeft = styled.div`
line-height: unset;
${ (props) => props.isMobile && css`line-height:0px`}`;

const ReviewsInfoRight = styled.div`
line-height: unset;
${ (props) => props.isMobile && css`line-height:8px`}`;

const InfoIconContianer = styled.div`    
max-width: 500px;
${ (props) => props.isMobile && css`max-width: 100px;`}`;

const DurationContianer = styled.div`
height: unset;
${ (props) => props.isMobile && css`height:13px`}`;

const PassengersContianer = styled.div`
height: unset;
${ (props) => props.isMobile && css`height:13px`}`;

const BagsContianer = styled.div`
height: unset;
${ (props) => props.isMobile && css`height:16px`}`;

const ImgDuration = styled.img`
margin-right: 8px;
width: 9px;
height: 9px;
${ (props) => props.isMobile && css`height:7px;width:7.02px;`}`;

const ImgPassengers = styled.img`
margin-right: 2px;
width: 11px;
height: 8px;
${ (props) => props.isMobile && css`width: 10px;height: 7.02px;`}`;

const ImgBags = styled.img`
margin-right: 6px;
width: 10px;
height: 9px;
${ (props) => props.isMobile && css`width: 7px;height: 7.02px;`}`;

// ==================================================================================================================================================


// ==================================================================================================================================================