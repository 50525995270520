import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import styled from 'styled-components';
import '../../App.css';

import List from '@material-ui/core/List';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import * as PackageAction from '../../actions/Actions_PackageManager.js';

import CloseServiceListPanel from './CloseServiceListPanel';
import CloseServiceCard from './CloseServiceCard';


let waitingTime = true;
let packageInfoList = [];
const _height = (window.innerHeight - 130);
const _styleList = { display: 'inline-block', width: '100%', position: 'relative', overflow: 'auto', height: _height }

class CloseServiceManage extends Component {

    state = { isLoading: true, msgError: undefined, dialogType: undefined, isView: false, isEdit: false };

    constructor(props) {
        super(props);
        this.props.getAPIPackageList({ limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        const { PackageManager } = nextProps;
        if (!!PackageManager) {

            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        packageInfoList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }
    }

    render() {
        const { isLoading, isEdit } = this.state
        return (
            <React.Fragment>
                {(isEdit) && <DialogAction Active={isEdit} OnClose={this.onClosePanel} AcrionCom={this.contentPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={_styleList}>
                    {this.contentListPanel()}
                </List>
            </React.Fragment>
        );
    }

    contentPanel = () => (
        <CloseServiceCard
            isView={this.state.isView}
            Package={this.state.PackageInfo}
            PeriodInfo={path(['packageInfo', 'periodTime'], this.state.PackageInfo)}
            onClosePanel={this.onClosePanel} />
    )
    contentListPanel = () => (
        <ListContianer>
            <CloseServiceListPanel packageInfoList={packageInfoList} onClickHandle={this.ViewPackage()} EditInfo={this.EditPackage()} />
        </ListContianer>
    )

    onChangeLanguage = (_language) => {
        const { page } = packageInfoList;
        this.props.getAPIPackageList({ page: page, num_limit: 10, language: _language });
    }

    EditPackage = () => (PackageInfo) => {
        this.setState({
            isEdit: true,
            isView: false,
            PackageInfo,
            oldPackageInfo: PackageInfo,
        });
    }

    ViewPackage = () => (PackageInfo) => {
        this.setState({
            isEdit: true,
            isView: true,
            PackageInfo,
            oldPackageInfo: PackageInfo,
        });
    }

    onClosePanel = () => (this.setState({ isEdit: false }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CloseServiceManage);

const ListContianer = styled.div`
        height: 600px, 
        width: 100%, 
        maxWidth: 1024px`;

