const HeaderPage = {
    EN: "Create Country", TH: "สร้างสกุลเงิน", CN: "创造条件",
    UPDATE_EN: "Update Country", UPDATE_TH: "---", UPDATE_CN: "---"
}

const BodyPage = [
    [{
        title_TH: "----", title_EN: "Country Name", title_CN: "----",
        type: "text",
        require: false,
        stateName: "name",
        placeholder_TH: "----", placeholder_EN: "Insert Name", placeholder_CN: "----"
    }], [{
        title_TH: "----", title_EN: "Zone", title_CN: "国家",
        type: "select",
        stateName: "zone",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }], [{
        title_TH: "----", title_EN: "Remark", title_CN: "----",
        type: "text",
        require: false,
        stateName: "remark",
        placeholder_TH: "----", placeholder_EN: "Insert Remark", placeholder_CN: "----"
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "visible",
    }],
    //  [{
    //     title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
    //     type: "image",
    //     stateName: "picture",
    // }],
    [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "media",
        stateName: "gallery",
        require: {
            EN: '650 x 450'
        }
    }]
];

export default {
    HeaderPage,
    BodyPage,
}