import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { equals } from 'ramda';
import { Colors } from '../themes';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textInput: {
        marginTop: '10px',
        padding: '10px 15px',
        border: '1px solid #979797',
        color: Colors.darkGrey,
        fontSize: 16,
        backgroundColor: Colors.white,
    },
    textField: { width: '100%', margin: '10px 10px 0px', },
    textFormLabel: { fontSize: 16, color: Colors.grey },
});
class TimePickers extends Component {

    constructor(props) {

        super(props);
        this.state = { value: props.value || '', };
    }

    componentWillReceiveProps(nextProps) {

        if (!equals(this.state.value, nextProps.value)) {
            this.setState({ value: nextProps.value })
        }
    };

    render() {

        const { classes, label, onChange, disabled } = this.props;
        const { value } = this.state;

        return (
            <form className={classes.container} noValidate>
                <TextField
                    id="time"
                    // label={label}
                    type="time"
                    disabled={disabled}
                    value={value}
                    // value={value}
                    onChange={onChange}
                    className={classes.textField}
                    InputLabelProps={{ shrink: true, className: classes.textFormLabel, }}
                    InputProps={{ step: 300, classes: { input: classes.textInput, }, }}
                />
            </form>
        );
    }
}

TimePickers.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(TimePickers);