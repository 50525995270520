import React, { Component } from 'react';

import { connect } from 'react-redux';
import { PathName } from '../../constant';

import SupplierStatCard from '../../componets/SupplierStatCard.jsx';
import * as StatiticActions from '../../actions/Actions_StatiticManager.js';

import '../../App.css';

let _SellerList;

class SellerStatPanel extends Component {

    state = {}

    constructor(props) {
        super(props);

        const _filter = { startDate: '2018-01-01' };
        this.props.getAPIBestSellerList();
    }

    componentWillReceiveProps(nextProps) {

        const { StatiticManager } = nextProps;

        if (!!StatiticManager) {

            const { actions, data } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {
                case StatiticActions.GET_BEST_SELLER_STAT_SUCCESS:
                    {
                        _SellerList = data.results.data;
                        //console.log(`${actions} : `, _SellerList)
                    } break;

                case StatiticActions.GET_BEST_SELLER_STAT_FAILURE:
                    {

                    } break;
            }
        }
    }

    render() {

        const { SellerList, isLoading } = this.props;
        // console.log('SellerStatPanel : ', AgentList)

        return (
            <div className="App-content">
                <div style={{ flex: 4, marginRight: '10px' }}>
                    <SupplierStatCard
                        Info={_SellerList || SellerList}
                        Topics={`Top Seller Booking`}
                        Disable={false}
                        Styles={{ width: 1024 }}
                        isLoading={isLoading}
                        btnViewHadle={this.btnViewHadle(PathName.SupplierManage)}
                        chartType={2} />
                </div>
            </div>)
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIBestSellerList: (_filter) => {
            dispatch(StatiticActions.getBestSellerList(_filter))
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SellerStatPanel);
