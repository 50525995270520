import React, { Component } from 'react'
import { connect } from 'react-redux';
import { path } from 'ramda';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import { SetDateTo } from '../../common/SetDate.js';

import CONFIG from '../../config';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import EmptyRows from '../../componets/EmptyRow.jsx';

import Language from '../../constant/Language';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';

import * as AnnouncementActions from '../../actions/Actions_AnnouncementManager';

const { BorderColorIcon, DoneIcon, CloseIcon } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'ANNOUNCEMENT TYPE', name: 'type', isOrder: true, },
    { label: 'WORDING', name: 'wording', isOrder: true, },
    { label: 'DATE TIME', name: 'time', isOrder: true, },
    { label: 'DESTROY', name: 'destroy', isOrder: true, },
    { label: 'VISIBLE', name: 'visible', isOrder: true, },
    { label: '', name: '', isOrder: false, },
];

const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _wording = { header: 'Announcement Manage' }

let waitingTime = true;
let contentList = [];

class AnnouncementListPanel extends Component {
    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    componentWillReceiveProps(nextProps) {

        const { AnnouncementManager } = nextProps;
        if (!!AnnouncementManager) {
            const { actions, data } = AnnouncementManager.ANNOUNCEMENT_REDUCER;
            switch (actions) {
                case AnnouncementActions.GET_ANNOUNCEMENT_LIST_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AnnouncementActions.GET_ANNOUNCEMENT_LIST_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }
    }

    render() {

        const { classes, onClickHandle, EditInfo, CreatePanel, announcementList } = this.props;
        const contentList = path(['datas'], announcementList) || [];

        const isPackage = (contentList.length > 0);
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow style={{ height: '100%' }}>
                            <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                            </TableCell>
                            <TableCell colSpan={1} style={{ padding: '0px' }} >
                                <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                    <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {fildeList.map((_filde, index) => {
                                return <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                    {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isPackage) ? GetBookingRows(contentList, onClickHandle, EditInfo, classes) : <EmptyRows fildeList={fildeList} />}
                    </TableBody>
                </Table>
                {(isPackage) && GetPagination(announcementList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
            </Paper>
        );
    }

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        contentList = (orderBy === 'desc')
            ? contentList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : contentList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.APIAnnouncementList({ page: 0, num_limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        this.setState({ isLoading: true });
        this.props.APIAnnouncementList({ page: _pageNumber, num_limit: _limit || 25, language: _language });
    }

    onChangeLanguage = (_language) => {
        console.log('onChangeLanguage', _language);
        this.setState({ isLoading: true });
        this.props.APIAnnouncementList({ page: 0, num_limit: 25, language: _language });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

}

function GetBookingRows(contentList, onClickHandle, EditInfo, classes) {


    return contentList.map(_announcement => {
        const { announcementId, type, wording, visible, image, background, startDate, endDate, isDestroy, typeContent } = _announcement;
        // console.log('_announcement', _announcement);
        let picture = (typeContent === 'slide') ? image : background;
        const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${checkURL(picture[0])})`, backgroundRepeat: 'round' };
        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={announcementId} >
                <TableCell style={_callStyle} onClick={() => onClickHandle(_announcement)}><div style={_backgroundImage} /></TableCell>
                <TableCell className={classes.name} onClick={() => onClickHandle(_announcement)}>{type || '-'}</TableCell>
                <TableCell className={classes.text_info} onClick={() => onClickHandle(_announcement)} >{wording || '-'}</TableCell>
                <TableCell className={classes.text_info} onClick={() => onClickHandle(_announcement)} >{SetDateTo(startDate, endDate) || '-'}</TableCell>
                <TableCell style={{ ..._callStyle, color: isDestroy ? Colors.success : Colors.error }} onClick={() => onClickHandle(_announcement)}>{GetIconWithStatus(isDestroy)}</TableCell>
                <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }} onClick={() => onClickHandle(_announcement)}>{GetIconWithStatus(visible)}</TableCell>
                <TableCell style={_callStyle}>
                    <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(_announcement) }} />
                </TableCell>
            </TableRow>
        );
    });
}
function GetIconWithStatus(_status) { return ((_status) ? <DoneIcon /> : <CloseIcon />) }
function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {
    const { total, page, limit, pageSize, language } = _ListCount;
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing(3), overflowX: 'auto', maxWidth: '1024px' },
        table: { minWidth: 1024, },
        text_info: { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', },
        name: { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px' },
    });

AnnouncementListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIAnnouncementList: (_keyword) => {
            InitWaitingTime();
            dispatch(AnnouncementActions.getAnnouncementList(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AnnouncementListPanel));