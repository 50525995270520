import React from 'react';

import TableSortLabel from '@material-ui/core/TableSortLabel';

function GetSortIcon(props) {

    const { orderBy, field, handleRequestSort, style } = props;

    return (
        <TableSortLabel hideSortIcon style={style} active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

export default GetSortIcon;

