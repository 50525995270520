import React, { Component } from 'react';
import { connect } from 'react-redux';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import CONFIG from '../../config';
import SellerCard from './SellerCard';
import Avatar from '../../componets/Avatar.jsx';
import { PathName, Roles } from '../../constant';
import SellerSumaryBookingPanel from './SellerSumaryBookingPanel.jsx';
import InProcessListCard from '../../containers/Booking/InProcessListCard.jsx'

import * as BookingActions from '../../actions/Actions_BookingManager';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import PackageListPanel from '../../componets/PackageListPanel.jsx';

import ChangePasswordCard from '../../componets/ChangePasswordCard';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import BookingSellerLists from '../../containers/Booking/BookingSellerLists.jsx';
import AccountingInfoPanel from '../../containers/Accounting/AccountingInfoPanel.jsx';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import BestProductStatPanel from '../../containers/Supplier/BestProductStatPanel.jsx';
import * as SettingActions from '../../actions/Actions_Settings';
import { init_seller, SetSellerToUpdate } from '../../constant/initState/initSeller';

import * as UploadActions from '../../actions/Actions_UploadManager';
import * as SellerActions from '../../actions/Actions_SellerManager.js';
import * as StatiticAction from '../../actions/Actions_StatiticManager.js';
import Button from '../../constant/Button';
import PackageAffiliatePanel from '../../containers/Packages/PackageAffiliatePanel.jsx';
import { isMobile } from '../../common/DeviceCheck';

import styled, { css } from 'styled-components';
import { Family, Fonts, Colors, Icons, Images } from '../../themes';
import '../../App.css';
const { BorderColorIcon, PasswordIcon } = Icons;
const { HeaderBackground, User } = Images;


const _height = (window.innerHeight - 130);


let SellerList = [];
let PackgaeList = [];
let SellerTypeList = [];
let BookingList = {};
let BookBankList = [];
let SummaryBooking = [];
let TopProductList = [];
let DefaultCommisstions = [];
let waitingTime = true;
let isDialogDisplay = false;
let _today;
let _role;

const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10, fontFamily: Fonts.family.roboto, };

class SellerInfo extends Component {

    state = { isLoading: true, isSuccess: false, isAffiliate: false, isPassword: false, msgError: undefined, dialogType: undefined, packageId: undefined }

    constructor(props) {

        super(props)

        const { location, sellerInfo: propsSellerInfo } = this.props;
        this.state = { SellerInfo: location.state.sellerInfo, isLoading: true };

        const _info = JSON.parse(localStorage.getItem('UserInfo'));
        _role = (!!_info) ? (_info.userType || '') : '';


        isDialogDisplay = (!!propsSellerInfo);

        _today = new Date();

        const { sellerInfo } = (isDialogDisplay) ? this.props : location.state;
        console.log(`SellerInfo [constructor] : `, sellerInfo);

        if (!!sellerInfo) {
            // this.props.getBookingListsWithCustomerID(sellerInfo.id);// not use check
            this.props.getAPIBookingLists('', { affiliateId: sellerInfo.sellerId });
            this.props.getAPIIncomeSellerSummary({ sellerId: sellerInfo.sellerId, startDate: '2018-01-01' });//_today.setDate(_today.getDate() - 7)
            this.props.getAPIBestBookingWithSellerId({ sellerId: sellerInfo.sellerId, startDate: '2018-01-01' });//
            this.props.getAPIPackageList({ limit: 10, visible: true })
            this.props.getAPIgetBankList({ num_limit: 100 })
            this.props.getAPISellerTypeList();
            this.props.getDefaultCommissions();
        }
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        // console.log(`BookingManage [componentWillMount] : `, this.props);
    }

    componentWillUnmount() {
        PackgaeList = [];
        BookingList = {};
        SummaryBooking = [];
        TopProductList = [];
        BookBankList = [];
        waitingTime = true;
        isDialogDisplay = false;
    }

    componentWillReceiveProps(nextProps) {

        // console.log('nextProps', nextProps);

        const { BookingManager, SettingManager, PackageManager, StatiticManager, SellerManager, UpLoadManager } = nextProps;
        const { sellerInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        if (!!BookingManager) {

            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingActions.GET_BOOKING_LISTS_SUCCESS:
                    {
                        BookingManager.BOOKING_REDUCER.actions = BookingActions.WATING_STATE;
                        console.log('GET_LB_BOOKING_LISTS_SUCCESS : ', data);

                        if (!!data)
                            BookingList = data;

                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingActions.GET_LB_BOOKING_LISTS_SUCCESS:
                    {
                        BookingManager.BOOKING_REDUCER.actions = BookingActions.WATING_STATE;
                        console.log('GET_LB_BOOKING_LISTS_SUCCESS : ', data);

                        if (!!data)
                            BookingList.bookingList = data.filter((booking) => (booking.userId === sellerInfo.id));

                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingActions.GET_BOOKING_LISTS_FAILURE:
                case BookingActions.GET_LB_BOOKING_LISTS_FAILURE:
                    {
                        BookingManager.BOOKING_REDUCER.actions = BookingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!PackageManager) {

            const { data, actions, error } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        PackgaeList = data || [];
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {

                    } break;
            }
        }

        if (!!StatiticManager) {

            const { data, actions, error } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {

                case StatiticAction.GET_TOP_PRODUCT_WITH_SELLER_SUCCESS:
                    {
                        // console.log(`### ${actions}  : `, data.results);
                        TopProductList = data.results.data;
                    } break;

                case StatiticAction.GET_SELLER_SUMMARY_INCOME_SUCCESS:
                    {
                        //console.log(`### ${actions}  : `, data.results);
                        SummaryBooking = data.results || [];
                    } break;

                case StatiticAction.GET_SELLER_SUMMARY_INCOME_FAILURE:
                case StatiticAction.GET_TOP_PRODUCT_WITH_SELLER_FAILURE:
                    {

                    } break;
            }
        }

        if (!!SettingManager) {
            const { actions, data } = SettingManager.SETTING_REDUCER;
            switch (actions) {
                case SettingActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        SettingManager.SETTING_REDUCER.actions = StatiticAction.WATING_STATE;
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        DefaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;
            const { isEdit, isCreate } = this.state;

            switch (actions) {
                case SellerActions.GET_SELLER_TYPE_SUCCESS:
                    {
                        SellerTypeList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_TYPE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_SUCCESS:
                    {
                        // console.log('bobo 1')
                        SellerList = data.result;
                        if (isEdit || isCreate)
                            SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case SellerActions.GET_SELLER_FAILURE:
                    {
                        // console.log('bobo 2')
                        if (isEdit || isCreate)
                            SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case SellerActions.UPDATE_SELLER_SUCCESS:
                    {
                        this.props.location.state.sellerInfo = this.state.SellerInfo;
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Seller Success.', dialogType: 'success', isSuccess: true });
                        this.props.getAPISellerList();
                    } break;

                case SellerActions.CREATE_SELLER_FAILURE:
                case SellerActions.UPDATE_SELLER_FAILURE:
                    {
                        console.log("CREATE_SELLER_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!UpLoadManager) {

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let _newSeller = this.state.SellerInfo;
                        _newSeller.logo = data.result.upload.images;
                        console.log('UPLOAD_IMAGE_SUCCESS :', data.result.upload.images)
                        this.props.APIUpdateSeller(SetSellerToUpdate(_newSeller));
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    render() {

        const { sellerInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { isLoading, isDialog, isCreate, msgError, dialogType } = this.state;
        const { isSetting } = this.props;
        const _isMobile = isMobile();
        // console.log('state', this.state)

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}

                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: (isSetting && _isMobile) ? 'block' : 'inline-block' }}>
                        {this.contentPanel(sellerInfo, _isMobile)}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel(sellerInfo, isMobile) {

        const { isSetting, location, history, classes } = this.props;
        const { isEdit, SellerInfo, oldSellerInfo, isLoading, value = 0 } = this.state;
        const _sellerInfo = location.state.sellerInfo;

        const { visible, wallet } = SellerInfo;

        switch (_role) {
            case Roles.Seller:
                {
                    return (
                        (isSetting) ?
                            <SellerCard
                                Level={DefaultCommisstions}
                                SellerTypeList={SellerTypeList}
                                BookBankList={BookBankList}
                                oldSellerInfo={oldSellerInfo}
                                SellerInfo={SellerInfo}
                                onClosePanel={this.onClosePanel}
                                isEdit={true}
                                isPage={true}
                                APIUpdateSeller={this.APIUpdateSeller} />
                            :
                            <React.Fragment>
                                {this.InfoPanel(sellerInfo, isMobile)}
                                {/* <AccountingInfoPanel /> */}
                                <SellerSumaryBookingPanel SummaryBooking={SummaryBooking} Wallet={wallet || 0} isMobile={isMobile} />
                                {/* <PackageListPanel packageInfoList={visible ? PackgaeList : []} onClickHandle={this.onClickHandle()} isMobile={isMobile} /> */}
                            </React.Fragment>
                    )
                }

            case Roles.Admin:
            default: {

                const { tabRoot, tabsRoot, tabsIndicator } = classes;

                return (
                    (isSetting) ?
                        <SellerCard
                            Level={DefaultCommisstions}
                            SellerTypeList={SellerTypeList}
                            BookBankList={BookBankList}
                            oldSellerInfo={oldSellerInfo}
                            SellerInfo={SellerInfo}
                            onClosePanel={this.onClosePanel}
                            isEdit={true}
                            isPage={true}
                            APIUpdateSeller={this.APIUpdateSeller} />
                        :
                        <React.Fragment>
                            {this.InfoPanel(sellerInfo, isMobile)}
                            <SellerSumaryBookingPanel SummaryBooking={SummaryBooking} Wallet={wallet || 0} isMobile={isMobile} />
                            <BestProductStatPanel SupplierList={TopProductList || []} history={history} isLoading={isLoading} isMobile={isMobile} />
                            <TabPanelBooking>
                                <AppBar position="static" >
                                    <Tabs value={value} onChange={this.handleChange} classes={{ root: tabsRoot, indicator: tabsIndicator }}>
                                        <Tab label="BOOKING LIST" classes={{ root: tabRoot }} />
                                        <Tab label="PACKAGE LIST" classes={{ root: tabRoot }} />
                                    </Tabs>
                                </AppBar>
                                {this.BookingInfoWithTabDisplay(value, _isMobile)}
                                {(value === 1) && <PackageListPanel packageInfoList={visible ? PackgaeList : []} onClickHandle={this.onClickHandle()} isMobile={isMobile} />}
                            </TabPanelBooking>
                        </React.Fragment>
                )
            }
        }
    }

    BookingInfoWithTabDisplay(tabName, _isMobile) {

        const { location, } = this.props;
        const { SellerInfo, orderBy = 'desc' } = this.state;
        const { visible } = SellerInfo;

        switch (tabName) {
            case 0:
                {
                    return (
                        <TabContainer>
                            <BookingSellerLists BookingList={BookingList} BookingDetail={() => { }} orderBy={orderBy} isMobile={_isMobile} userInfo={SellerInfo} />
                        </TabContainer>)
                }
            case 1:
                {
                    return (
                        <TabContainer>
                            {/* <PackageListPanel packageInfoList={visible ? PackgaeList : []} onClickHandle={this.onClickHandle()} isMobile={isMobile} /> */}
                        </TabContainer>)
                }
        }
    }

    handleChange = (event, value) => {

        switch (value) {
            case 1:
                {
                    // this.props.getAPIBookingLists('', { affiliateId: sellerInfo.sellerId });     
                } break;
            case 2:
                {
                    this.props.getAPIPackageList({ limit: 10, visible: true });
                } break;
        }

        this.setState({ value });
    };

    EditInfo = () => {

        const { isEdit, SellerInfo, oldSellerInfo, } = this.state;
        const { sellerInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        return (
            <SellerCard
                Level={DefaultCommisstions}
                SellerTypeList={SellerTypeList}
                BookBankList={BookBankList}
                oldSellerInfo={oldSellerInfo}
                SellerInfo={SellerInfo || sellerInfo}
                onClosePanel={this.onClosePanel}
                isEdit={isEdit}
                APIUpdateSeller={this.APIUpdateSeller} />
        )

    }

    APIUpdateSeller = (avatar, sellerInfo) => {

        console.log('APIUpdateSeller', avatar, sellerInfo);

        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, SellerInfo: sellerInfo, isEdit: true }, () => this.props.uploadImage(formdata));
        }
        else {
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpdateSeller(SetSellerToUpdate(sellerInfo)));
        }
    }

    PackageAffiliatePanel = (isMobile) => {

        const { sellerInfo } = (isDialogDisplay) ? this.props : this.props.location.state;//!!!! check
        const { packageId } = this.state;
        const _packageInfo = PackgaeList.packageList.find((_item) => (_item.packageId === packageId));

        // console.log('onClickHandle : ', `${CONFIG.webURL}/choosePackage?${packageId}#${sellerInfo.sellerId}`)
        // console.log('PackageAffiliatePanel [ PackgaeList ] : ', _packageInfo)
        return <PackageAffiliatePanel packageInfo={_packageInfo} affiliateID={sellerInfo.sellerId} isMobile={isMobile} OnClose={() => { this.onCloseDialog() }} />
    }

    onClickHandle = () => (packageId) => { this.setState({ isAffiliate: true, packageId: packageId }) }
    EditAgnetInfo = () => (SellerInfo) => { this.setState({ isCreate: true, isEdit: true, SellerInfo, oldSellerInfo: SellerInfo }) }
    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, isAffiliate: false, msgError: undefined, dialogType: undefined }));
    onCreatePanel = (_isActive) => () => { this.setState({ isCreate: !_isActive, oldSellerInfo: { ...init_seller }, SellerInfo: { ...init_seller } }) };

    onClosePanel = (_sellerInfo) => {

        const { pathname } = this.props.location;
        let Seller = (pathname === PathName.SellerInfo);
        let Setting = (pathname === PathName.SettingSeller);

        if (!Seller && !Setting) {
            this.props.location.state.sellerInfo = this.state.SellerInfo;
            this.setState({ isEdit: false, SellerInfo: this.state.SellerInfo, isSuccess: false, isAffiliate: false, isPassword: false, isCreate: false, isEdit: false, error: null, isLoading: false, msgError: undefined, dialogType: undefined }, () =>
                this.props.history.replace({ pathname: PathName.DashBoardPage, state: { sellerInfo: this.state.SellerInfo }, search: '', hash: '' }));
        }
        else this.setState({ isEdit: false, SellerInfo: this.state.SellerInfo, isSuccess: false, isAffiliate: false, isPassword: false, isCreate: false, isEdit: false, error: null, isLoading: false, msgError: undefined, dialogType: undefined });
    };

    CallBookingDetail(_id) {

        console.log('CallBookingDetail : ', _id)
        this.props.getAPIBookingDetails(_id);
        this.props.history.push({ pathname: PathName.BookingDetail, state: {}, search: _id, hash: '' });
    }

    InfoPanel(sellerInfo, isMobile) {

        const { sellerId, email, level, logo, name, type, telOffice, priceGroup, tag, firstName, lastName, title, affiliateId, actived, } = this.state.SellerInfo ? this.state.SellerInfo : sellerInfo;

        const { classes } = this.props;
        const { isPassword, isAffiliate, isEdit } = this.state;

        const fullName = `${title} ${firstName} ${lastName}`;

        // console.log('DefaultCommisstions', DefaultCommisstions);
        const sellerType = Array.isArray(SellerTypeList) && SellerTypeList.filter((val) => val.sellerTypeId === type).map((each) => each.name);
        let sellerLevel = null;
        Array.isArray(DefaultCommisstions.datas) && DefaultCommisstions.datas.filter((val) => val.defaultCommissionId === level ? sellerLevel = val.levelName : null)


        // console.log('sellerLevel', sellerLevel);
        return (
            <div style={{
                display: isMobile ? 'block' : 'flex', height: isMobile ? '100%' : '328px', width: isMobile ? window.innerWidth : '1020px',
                backgroundColor: Colors.themeDark,
            }}>

                {(isPassword) && <DialogAction Active={isPassword} OnClose={this.onClosePanel} AcrionCom={<ChangePasswordCard onClosePanel={this.onClosePanel} />} />}
                {(isEdit) && <DialogAction Active={isEdit} OnClose={this.onClosePanel} AcrionCom={this.EditInfo()} />}
                {(isAffiliate) && <DialogAction Active={isAffiliate} OnClose={this.onClosePanel} AcrionCom={this.PackageAffiliatePanel(isMobile)} />}

                < div style={{
                    height: isMobile ? 'unset' : '300px', width: isMobile ? 'unset' : '250px',
                    paddingLeft: isMobile ? 'unset' : '50px', paddingTop: isMobile ? '15px' : '50px',
                    paddingRight: isMobile ? 'unset' : '50px', borderradius: '10%', boxshadow: '0  0 5px rgba(0, 0, 0, 0.2)',
                    display: isMobile ? 'flex' : 'unset', justifyContent: isMobile ? 'center' : 'unset',
                }} >
                    <img className={classes.avataronline}
                        src={(logo.original) ? (`${CONFIG.host}${logo.original}`) : Images.User.userdefault} />
                </div>
                <div style={{ width: '100%', height: isMobile ? '100%' : '200px', padding: isMobile ? 'unset' : '15px', textAlign: 'left', }}>
                    <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end', marginRight: isMobile ? '10px' : 'unset' }}>
                        <Button.Icons themeDark tooltip={'Edit'} icon={<BorderColorIcon />} onClick={() => { this.setState({ isEdit: true }) }} />
                        <Button.Icons themeDark tooltip={'Change Password'} icon={<PasswordIcon />} onClick={() => { this.setState({ isPassword: true }) }} />
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>User Name : </label>
                        <label className={classes.july32017copy}>{fullName || '-'}</label>
                    </div>
                    {isMobile ? null : <div style={_styleInfoItem}>
                        <label className={classes.class}>User ID : </label>
                        <label className={classes.july32017copy}>{sellerId || '-'}</label>
                    </div>}
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Phone number : </label>
                        <label className={classes.july32017copy}>{telOffice || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Email : </label>
                        <label className={classes.july32017copy}>{email || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Level : </label>
                        <label className={classes.july32017copy}>{sellerLevel || '-'}</label>
                    </div>
                    {isMobile ? null : <div style={_styleInfoItem}>
                        <label className={classes.class}>Type : </label>
                        <label className={classes.july32017copy}>{sellerType || '-'}</label>
                    </div>}
                    {isMobile ? null : <div style={_styleInfoItem}>
                        <label className={classes.class}>Visible : </label>
                        <label className={classes.july32017copy}>{actived ? 'Active' : 'Inactive'}</label>
                    </div>}
                    {isMobile ? null : <div style={_styleInfoItem}>
                        <label className={classes.class}>Register Type : </label>
                        <label className={classes.july32017copy}>{affiliateId || '-'}</label>
                    </div>}

                </div>
            </div >
        )
    }

    updateDimensions = () => {

        const { width } = this.state;
        const _with = window.innerWidth;

        if ((width !== _with)) {
            this.setState({ width: _with });
        }
    }
}

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: '5px 0px', }}>
            {props.children}
        </Typography>
    );
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },
        getBookingListsWithCustomerID: (memberID) => {
            InitWaitingTime();
            dispatch(BookingActions.get_LB_APIBookingLists(memberID));
        },
        getAPIBookingLists: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(BookingActions.getAPIBookingLists(_keyword, _filter));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingActions.getAPIBookingDetail(_id));
        },
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        APIUpdateSeller: (_confirmInfo) => {
            dispatch(SellerActions.APIUpdateSeller(_confirmInfo));
        },
        uploadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getAPIBestBookingWithSellerId: (_fliter) => {
            dispatch(StatiticAction.getBestBookingWithSellerId(_fliter));
        },
        getAPIIncomeSellerSummary: (_fliter) => {
            dispatch(StatiticAction.getIncomeSellerSummary(_fliter));
        },
        getAPIgetBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(SettingActions.getBankList(_keyword));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}

const _isMobile = isMobile();
const styles = theme => (
    {
        july32017copy: {
            height: '16px',
            opacity: '0.64',
            color: Colors.white,
            fontSize: '12px',
            lineHeight: '16px',
        },
        class: {
            height: '16px',
            width: '110px',
            color: Colors.white,
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '16px',
        },
        avataronline: {
            position: 'relative',
            boxShadow: '0 0 1px 1.9px ',
            backgroundRepeat: 'round',
            borderRadius: '50%',
            marginRight: _isMobile ? 'unset' : '20%',
            width: _isMobile ? (window.innerWidth / 2) - 45 : '200px',
            height: _isMobile ? (window.innerWidth / 2) - 45 : '200px',
        },
        tabsRoot: {
            backgroundColor: Colors.themeDark,
            borderBottom: `${'1px solid' + Colors.border}`,
        },
        tabsIndicator: {
            backgroundColor: Colors.theme,
            height: 3,
        },
        tabRoot: {
            textTransform: 'initial',
            fontWeight: theme.typography.fontWeightRegular,
            color: Colors.white,
            fontFamily: Family.roboto,
            '&:hover': {
                color: Colors.white,
                opacity: 1,
            },
            '&:tabSelected': {
                color: Colors.white,
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: Colors.white,
            },
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SellerInfo));

// ====================================================================================================================================================

const TabPanelBooking = styled.div`    
flex-grow: 1;
margin: 10px 0px;
max-width: 1024px;`;

// ====================================================================================================================================================