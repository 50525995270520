import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_TOP_PRODUCT_STAT_SUCCESS = 'GET_TOP_PRODUCT_STAT_SUCCESS';
export const GET_TOP_PRODUCT_STAT_FAILURE = 'GET_TOP_PRODUCT_STAT_FAILURE';

export const GET_BEST_SUPPLIER_STAT_SUCCESS = 'GET_BEST_SUPPLIER_STAT_SUCCESS';
export const GET_BEST_SUPPLIER_STAT_FAILURE = 'GET_BEST_SUPPLIER_STAT_FAILURE';

export const GET_BEST_AGENT_STAT_SUCCESS = 'GET_BEST_AGENT_STAT_SUCCESS';
export const GET_BEST_AGENT_STAT_FAILURE = 'GET_BEST_AGENT_STAT_FAILURE';

export const GET_TOP_PRODUCT_WITH_AGENT_SUCCESS = 'GET_TOP_PRODUCT_WITH_AGENT_SUCCESS';
export const GET_TOP_PRODUCT_WITH_AGENT_FAILURE = 'GET_TOP_PRODUCT_WITH_AGENT_FAILURE';

export const GET_BEST_SELLER_STAT_SUCCESS = 'GET_BEST_SELLER_STAT_SUCCESS';
export const GET_BEST_SELLER_STAT_FAILURE = 'GET_BEST_SELLER_STAT_FAILURE';

export const GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS = 'GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS';
export const GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE = 'GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE';


export const GET_TOP_PRODUCT_WITH_SELLER_SUCCESS = 'GET_TOP_PRODUCT_WITH_SELLER_SUCCESS';
export const GET_TOP_PRODUCT_WITH_SELLER_FAILURE = 'GET_TOP_PRODUCT_WITH_SELLER_FAILURE';

export const GET_BOOKING_STATUS_SUCCESS = 'GET_BOOKING_STATUS_SUCCESS';
export const GET_BOOKING_STATUS_FAILURE = 'GET_BOOKING_STATUS_FAILURE';

export const GET_SUPPLIER_SUMMARY_INCOME_SUCCESS = 'GET_SUPPLIER_SUMMARY_INCOME_SUCCESS';
export const GET_SUPPLIER_SUMMARY_INCOME_FAILURE = 'GET_SUPPLIER_SUMMARY_INCOME_FAILURE';

export const GET_SUMMARY_SUPPLIER_STATUS_SUCCESS = 'GET_SUMMARY_SUPPLIER_STATUS_SUCCESS';
export const GET_SUMMARY_SUPPLIER_STATUS_FAILURE = 'GET_SUMMARY_SUPPLIER_STATUS_FAILURE';

export const GET_AGENT_SUMMARY_INCOME_SUCCESS = 'GET_AGENT_SUMMARY_INCOME_SUCCESS';
export const GET_AGENT_SUMMARY_INCOME_FAILURE = 'GET_AGENT_SUMMARY_INCOME_FAILURE';

export const GET_SELLER_SUMMARY_INCOME_SUCCESS = 'GET_SELLER_SUMMARY_INCOME_SUCCESS';
export const GET_SELLER_SUMMARY_INCOME_FAILURE = 'GET_SELLER_SUMMARY_INCOME_FAILURE';

export const GET_SUMMARY_AGENT_STATUS_SUCCESS = 'GET_SUMMARY_AGENT_STATUS_SUCCESS';
export const GET_SUMMARY_AGENT_STATUS_FAILURE = 'GET_SUMMARY_AGENT_STATUS_FAILURE';

export const GET_SUMMARY_CUSTOMER_STATUS_SUCCESS = 'GET_SUMMARY_CUSTOMER_STATUS_SUCCESS';
export const GET_SUMMARY_CUSTOMER_STATUS_FAILURE = 'GET_SUMMARY_CUSTOMER_STATUS_FAILURE';

export const GET_CUSTOMER_SUMMARY_INCOME_SUCCESS = 'GET_CUSTOMER_SUMMARY_INCOME_SUCCESS';
export const GET_CUSTOMER_SUMMARY_INCOME_FAILURE = 'GET_CUSTOMER_SUMMARY_INCOME_FAILURE';

export const GET_ALL_SUMMARY_INCOME_SUCCESS = 'GET_ALL_SUMMARY_INCOME_SUCCESS';
export const GET_ALL_SUMMARY_INCOME_FAILURE = 'GET_ALL_SUMMARY_INCOME_FAILURE';

export const GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS = 'GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS';
export const GET_ALL_SUMMARY_BOOKINGPAYMENT_FAILURE = 'GET_ALL_SUMMARY_BOOKINGPAYMENT_FAILURE';

export const GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS = 'GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS';
export const GET_ALL_SUMMARY_BOOKINGCHART_FAILURE = 'GET_ALL_SUMMARY_BOOKINGCHART_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// bestProctStatWithId =====================================================================================================================

export function getbestProctStatWithId(_fileterjson = {}) {
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getBestBookingWithSupplierId}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] bestProctStatWithId : ", response);
            dispatch(bestProctStatWithId_Success(response.data));

        }).catch((error) => {
            console.log("[API] bestProctStatWithId Failure", error);
            dispatch(bestProctStatWithId_Failure(error));
        })
    }
}

function bestProctStatWithId_Success(data) {
    return { type: GET_TOP_PRODUCT_STAT_SUCCESS, payload: data };
}

function bestProctStatWithId_Failure(error) {
    return { type: GET_TOP_PRODUCT_STAT_FAILURE, payload: error };
}

// bestProctStatWithId ======================================================================================================================

// getBestBookingWithAgentId ================================================================================================================

export function getBestBookingWithAgentId(_fileterjson = {}) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getBestBookingWithAgentId}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getBestBookingWithAgentId : ", response);
            dispatch(getBestBookingWithAgentId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getBestBookingWithAgentId Failure", error);
            dispatch(getBestBookingWithAgentId_Failure(error));
        })
    }
}

function getBestBookingWithAgentId_Success(data) {
    return { type: GET_TOP_PRODUCT_WITH_AGENT_SUCCESS, payload: data };
}

function getBestBookingWithAgentId_Failure(error) {
    return { type: GET_TOP_PRODUCT_WITH_AGENT_FAILURE, payload: error };
}

// getBestBookingWithAgentId ============================================================================================================

// getBestBookingWithSellerId ================================================================================================================

export function getBestBookingWithSellerId(_fileterjson = {}) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getBestBookingWithSellerId}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getBestBookingWithSellerId : ", response);
            dispatch(getBestBookingWithSellerId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getBestBookingWithSellerId Failure", error);
            dispatch(getBestBookingWithSellerId_Failure(error));
        })
    }
}

function getBestBookingWithSellerId_Success(data) {
    return { type: GET_TOP_PRODUCT_WITH_SELLER_SUCCESS, payload: data };
}

function getBestBookingWithSellerId_Failure(error) {
    return { type: GET_TOP_PRODUCT_WITH_SELLER_FAILURE, payload: error };
}

// getBestBookingWithSellerId ============================================================================================================

// bestSupplierList =====================================================================================================================

export function getBestSupplierList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.bestSupplierList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] bestSupplierList : ", response);
            dispatch(bestSupplierList_Success(response.data));

        }).catch((error) => {
            console.log("[API] bestSupplierList Failure", error);
            dispatch(bestSupplierList_Failure(error));
        })
    }
}

function bestSupplierList_Success(data) {
    return { type: GET_BEST_SUPPLIER_STAT_SUCCESS, payload: data };
}

function bestSupplierList_Failure(error) {
    return { type: GET_BEST_SUPPLIER_STAT_FAILURE, payload: error };
}

// bestSupplierList ======================================================================================================================
// getBookingStatus =====================================================================================================================

export function getBookingStatus(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getBookingStatus}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getBookingStatus : ", response);
            dispatch(getBookingStatus_Success(response.data));

        }).catch((error) => {
            console.log("[API] getBookingStatus Failure", error);
            dispatch(getBookingStatus_Failure(error));
        })
    }
}

function getBookingStatus_Success(data) {
    return { type: GET_BOOKING_STATUS_SUCCESS, payload: data };
}

function getBookingStatus_Failure(error) {
    return { type: GET_BOOKING_STATUS_FAILURE, payload: error };
}

// getBookingStatus ======================================================================================================================


// getIncomeSupplierSummary =====================================================================================================================

export function getIncomeSupplierSummary(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getIncomeSupplierSummary}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getIncomeSupplierSummary : ", response);
            dispatch(getIncomeSupplierSummary_Success(response.data));

        }).catch((error) => {
            console.log("[API] getIncomeSupplierSummary Failure", error);
            dispatch(getIncomeSupplierSummary_Failure(error));
        })
    }
}

function getIncomeSupplierSummary_Success(data) {
    return { type: GET_SUPPLIER_SUMMARY_INCOME_SUCCESS, payload: data };
}

function getIncomeSupplierSummary_Failure(error) {
    return { type: GET_SUPPLIER_SUMMARY_INCOME_FAILURE, payload: error };
}

// getIncomeSupplierSummary ======================================================================================================================

// getSummarySupplierStatus ============================================================================================================================

export function getSummarySupplierStatus(_keyword = {}, _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSummarySupplierStatus}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSummarySupplierStatus : ", response);
            dispatch(getSummarySupplierStatus_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSummarySupplierStatus Failure", error);
            dispatch(getSummarySupplierStatus_Failure(error));
        })
    }
}

function getSummarySupplierStatus_Success(data) {
    return { type: GET_SUMMARY_SUPPLIER_STATUS_SUCCESS, payload: data };
}

function getSummarySupplierStatus_Failure(error) {
    return { type: GET_SUMMARY_SUPPLIER_STATUS_FAILURE, payload: error };
}

// getSummarySupplierStatus =========================================================================================================================

//getSummaryAgentStatus ============================================================================================================================

export function getSummaryAgentStatus(_keyword = {}, _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSummaryAgentStatus}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSummaryAgentStatus : ", response);
            dispatch(getSummaryAgentStatus_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSummaryAgentStatus Failure", error);
            dispatch(getSummaryAgentStatus_Failure(error));
        })
    }
}

function getSummaryAgentStatus_Success(data) {
    return { type: GET_SUMMARY_AGENT_STATUS_SUCCESS, payload: data };
}

function getSummaryAgentStatus_Failure(error) {
    return { type: GET_SUMMARY_AGENT_STATUS_FAILURE, payload: error };
}

// getSummaryAgentStatus =========================================================================================================================

//getIncomeAgentSummary ============================================================================================================================

export function getIncomeAgentSummary(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getIncomeAgentSummary}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getIncomeAgentSummary : ", response);
            dispatch(getIncomeAgentSummary_Success(response.data));

        }).catch((error) => {
            console.log("[API] getIncomeAgentSummary Failure", error);
            dispatch(getIncomeAgentSummary_Failure(error));
        })
    }
}

function getIncomeAgentSummary_Success(data) {
    return { type: GET_AGENT_SUMMARY_INCOME_SUCCESS, payload: data };
}

function getIncomeAgentSummary_Failure(error) {
    return { type: GET_AGENT_SUMMARY_INCOME_FAILURE, payload: error };
}

// getIncomeAgentSummary =========================================================================================================================

//getIncomeSellerSummary ============================================================================================================================

export function getIncomeSellerSummary(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getIncomeSellerSummary}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getIncomeSellerSummary : ", response);
            dispatch(getIncomeSellerSummary_Success(response.data));

        }).catch((error) => {
            console.log("[API] getIncomeSellerSummary Failure", error);
            dispatch(getIncomeSellerSummary_Failure(error));
        })
    }
}

function getIncomeSellerSummary_Success(data) {
    return { type: GET_SELLER_SUMMARY_INCOME_SUCCESS, payload: data };
}

function getIncomeSellerSummary_Failure(error) {
    return { type: GET_SELLER_SUMMARY_INCOME_FAILURE, payload: error };
}

// getIncomeSellerSummary =========================================================================================================================

//getSummaryCustomerStatus ============================================================================================================================

export function getSummaryCustomerStatus(_keyword = {}, _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSummaryCustomerStatus}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSummaryCustomerStatus : ", response);
            dispatch(getSummaryCustomerStatus_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSummaryCustomerStatus Failure", error);
            dispatch(getSummaryCustomerStatus_Failure(error));
        })
    }
}

function getSummaryCustomerStatus_Success(data) {
    return { type: GET_SUMMARY_CUSTOMER_STATUS_SUCCESS, payload: data };
}

function getSummaryCustomerStatus_Failure(error) {
    return { type: GET_SUMMARY_CUSTOMER_STATUS_FAILURE, payload: error };
}

// getSummaryCustomerStatus =========================================================================================================================

//getIncomeAllSummary ============================================================================================================================

export function getIncomeAllSummary(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getIncomeAllSummary}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getIncomeAllSummary : ", response);
            dispatch(getIncomeAllSummary_Success(response.data));

        }).catch((error) => {
            console.log("[API] getIncomeAllSummary Failure", error);
            dispatch(getIncomeAllSummary_Failure(error));
        })
    }
}

function getIncomeAllSummary_Success(data) {
    return { type: GET_ALL_SUMMARY_INCOME_SUCCESS, payload: data };
}

function getIncomeAllSummary_Failure(error) {
    return { type: GET_ALL_SUMMARY_INCOME_FAILURE, payload: error };
}

// getIncomeAllSummary =========================================================================================================================

//getAllSummaryBookingPaymentInfo ============================================================================================================================

export function getAllSummaryBookingPaymentInfo(_keyword = {}, _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAllSummaryBookingPaymentInfo}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAllSummaryBookingPaymentInfo : ", response);
            dispatch(getAllSummaryBookingPaymentInfo_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAllSummaryBookingPaymentInfo Failure", error);
            dispatch(getAllSummaryBookingPaymentInfo_Failure(error));
        })
    }
}

function getAllSummaryBookingPaymentInfo_Success(data) {
    return { type: GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS, payload: data };
}

function getAllSummaryBookingPaymentInfo_Failure(error) {
    return { type: GET_ALL_SUMMARY_BOOKINGPAYMENT_FAILURE, payload: error };
}

// getAllSummaryBookingPaymentInfo =========================================================================================================================

//getAllSummaryBookingChart ============================================================================================================================

export function getAllSummaryBookingChart(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAllSummaryBookingChart}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAllSummaryBookingChart : ", response);
            dispatch(getAllSummaryBookingChart_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAllSummaryBookingChart Failure", error);
            dispatch(getAllSummaryBookingChart_Failure(error));
        })
    }
}

function getAllSummaryBookingChart_Success(data) {
    return { type: GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS, payload: data };
}

function getAllSummaryBookingChart_Failure(error) {
    return { type: GET_ALL_SUMMARY_BOOKINGCHART_FAILURE, payload: error };
}

// getAllSummaryBookingChart =========================================================================================================================

// bestAgentList =====================================================================================================================

export function getBestAgentList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.bestAgentList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] bestAgentList : ", response);
            dispatch(bestAgentList_Success(response.data));

        }).catch((error) => {
            console.log("[API] bestAgentList Failure", error);
            dispatch(bestAgentList_Failure(error));
        })
    }
}

function bestAgentList_Success(data) {
    return { type: GET_BEST_AGENT_STAT_SUCCESS, payload: data };
}

function bestAgentList_Failure(error) {
    return { type: GET_BEST_AGENT_STAT_FAILURE, payload: error };
}

// bestAgentList ======================================================================================================================

// bestSellerList =====================================================================================================================

export function getBestSellerList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.bestSellerList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] bestSellerList : ", response);
            dispatch(bestSellerList_Success(response.data));

        }).catch((error) => {
            console.log("[API] bestSellerList Failure", error);
            dispatch(bestSellerList_Failure(error));
        })
    }
}

function bestSellerList_Success(data) {
    return { type: GET_BEST_SELLER_STAT_SUCCESS, payload: data };
}

function bestSellerList_Failure(error) {
    return { type: GET_BEST_SELLER_STAT_FAILURE, payload: error };
}

// bestSellerList ======================================================================================================================

// BookingPackageTopOfWeek =====================================================================================================================

export function getBookingPackageTopOfWeek(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getStatisticBookingPackageTopOfWeek}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] BookingPackageTopOfWeek : ", response);
            dispatch(BookingPackageTopOfWeek_Success(response.data));

        }).catch((error) => {
            console.log("[API] BookingPackageTopOfWeek Failure", error);
            dispatch(BookingPackageTopOfWeek_Failure(error));
        })
    }
}

function BookingPackageTopOfWeek_Success(data) {
    return { type: GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS, payload: data };
}

function BookingPackageTopOfWeek_Failure(error) {
    return { type: GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE, payload: error };
}

// BookingPackageTopOfWeek ======================================================================================================================