const validate = require("validate.js");

const constraints = {
    Email: {
        email: true,
        presence: {
            allowEmpty: false,
            message: ':error require Email',
        }
    },

};

export function validateForgot(data, callback) {
    let thisConstraints = { ...constraints }

    callback(validate({
        Email: data.Email,

    }, thisConstraints));
}

