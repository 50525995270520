import moment from 'moment';

export const initAnnouncement = {
    "link": "",
    "type": "",
    "wording": "",
    "background": [],
    "image": [],
    "isDestroy": false,
    "isTime": false,
    "visible": false,
    "startDate": moment().format('YYYY-MM-DD'),
    "endDate": moment().add(1, 'day').format('YYYY-MM-DD'),
}

