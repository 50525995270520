import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIER_FAILURE = 'GET_SUPPLIER_FAILURE';

export const GET_SUPPLIER_INFO_SUCCESS = 'GET_SUPPLIER_INFO_SUCCESS';
export const GET_SUPPLIER_INFO_FAILURE = 'GET_SUPPLIER_INFO_FAILURE';

export const CREATE_SUPPLIER_SUCCESS = 'CREATE_SUPPLIER_SUCCESS';
export const CREATE_SUPPLIER_FAILURE = 'CREATE_SUPPLIER_FAILURE';

export const UPDATE_SUPPLIER_SUCCESS = 'UPDATE_SUPPLIER_SUCCESS';
export const UPDATE_SUPPLIER_FAILURE = 'UPDATE_SUPPLIER_FAILURE';

export const GET_SUPPLIER_WITHPARENT_ID_SUCCESS = 'GET_SUPPLIER_WITHPARENT_ID_SUCCESS';
export const GET_SUPPLIER_WITHPARENT_ID_FAILURE = 'GET_SUPPLIER_WITHPARENT_ID_FAILURE';//getSummarySupplierStatus

export const GET_SUPPLIER_BOOKING_LIST_SUCCESS = 'GET_SUPPLIER_BOOKING_LIST_SUCCESS';
export const GET_SUPPLIER_BOOKING_LIST_FAILURE = 'GET_SUPPLIER_BOOKING_LIST_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getSupplierList ============================================================================================================================

export function getSupplierList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getSupplierList :", _keyword);

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSupplierList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSupplierList : ", response);
            dispatch(getSupplierList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSupplierList Failure", error);
            dispatch(getSupplierList_Failure(error));
        })
    }
}

function getSupplierList_Success(data) {
    return { type: GET_SUPPLIER_SUCCESS, payload: data };
}

function getSupplierList_Failure(error) {
    return { type: GET_SUPPLIER_FAILURE, payload: error };
}

// getSupplierList =========================================================================================================================

// getSupplierInfo ============================================================================================================================

export function getSupplierInfo(_id, _fileterjson) {

    console.log("[API] getSupplierInfo :", _fileterjson);

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSupplierInfo}${_id}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getSupplierInfo : ", response);
            dispatch(getSupplierInfo_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSupplierInfo Failure", error);
            dispatch(getSupplierInfo_Failure(error));
        })
    }
}

function getSupplierInfo_Success(data) {
    return { type: GET_SUPPLIER_INFO_SUCCESS, payload: data };
}

function getSupplierInfo_Failure(error) {
    return { type: GET_SUPPLIER_INFO_FAILURE, payload: error };
}

// getSupplierInfo =========================================================================================================================

export function APICreateSupplier(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createSupplier}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APICreateSupplier : ", response.data);
            dispatch(APICreateSupplier_Success(response.data));

        }).catch((error) => {
            console.log("APICreateSupplier fail", error);
            dispatch(APICreateSupplier_Failure(error));
        })
    }
}

function APICreateSupplier_Success(data) {
    return { type: CREATE_SUPPLIER_SUCCESS, payload: data };
}

function APICreateSupplier_Failure(error) {
    return { type: CREATE_SUPPLIER_FAILURE, payload: error };
}

// updateSupplierList =========================================================================================================================

export function APIUpdateSupplier(confirmInfo) {
    //console.log('APIUpdateSupplier : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateSupplier}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIUpdateSupplier : ", response.data);
            dispatch(APIUpdateSupplier_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateSupplier fail", error);
            dispatch(APIUpdateSupplier_Failure(error));
        })
    }
}

function APIUpdateSupplier_Success(data) {
    return { type: UPDATE_SUPPLIER_SUCCESS, payload: data };
}

function APIUpdateSupplier_Failure(error) {
    return { type: UPDATE_SUPPLIER_FAILURE, payload: error };
}

// getSupplierList ============================================================================================================================

export function getSupplierList_WithParentId(_keyword = {}, _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSupplierList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSupplierList_WithParentId : ", response);
            dispatch(getSupplierList_WithParentId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSupplierList_WithParentId Failure", error);
            dispatch(getSupplierList_WithParentId_Failure(error));
        })
    }
}

function getSupplierList_WithParentId_Success(data) {
    return { type: GET_SUPPLIER_WITHPARENT_ID_SUCCESS, payload: data };
}

function getSupplierList_WithParentId_Failure(error) {
    return { type: GET_SUPPLIER_WITHPARENT_ID_FAILURE, payload: error };
}

// getSupplierList_WithParentId =========================================================================================================================

// getSupplierBookingPaymentInfoList ============================================================================================================================

export function getSupplierBookingPaymentInfoList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSupplierBookingPaymentInfoList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getSupplierBookingPaymentInfoList : ", response);
            dispatch(getSupplierBookingPaymentInfoList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSupplierBookingPaymentInfoList Failure", error);
            dispatch(getSupplierBookingPaymentInfoList_Failure(error));
        })
    }
}

function getSupplierBookingPaymentInfoList_Success(data) {
    return { type: GET_SUPPLIER_BOOKING_LIST_SUCCESS, payload: data };
}

function getSupplierBookingPaymentInfoList_Failure(error) {
    return { type: GET_SUPPLIER_BOOKING_LIST_FAILURE, payload: error };
}

// getSupplierBookingPaymentInfoList =========================================================================================================================

