import React, { Component } from 'react';
import { path, equals } from "ramda";

import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
// import MenuItem from '@material-ui/core/MenuItem';

// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Province } from "../constant/JSON/Province";
import { Districts } from "../constant/JSON/District";
import { Family, Colors, RequireForm } from '../themes';
import { isMobile } from '../common/DeviceCheck'

const styles = () => ({
    // textField: {
    //     color: 'red'
    // },
    paper: {
        // padding: 10,
        fontFamily: Family.roboto,
        position: 'absolute',
        zIndex: 1,
        overflowY: 'auto',
        minWidth: '225px',
        maxHeight: '250px',
    },
    // textInput: {
    //     width: '100%', marginTop: 5, padding: '7px 15px',
    //     borderRadius: 0, border: `${'1px solid' + Colors.border}`,
    //     color: Colors.themeDark, fontSize: 14,
    // },
    textInput: {
        fontFamily: Family.roboto,
        fontWeight: 300,
        fontSize: 14,
        borderRadius: 0,
        marginTop: '5px',
        padding: '8px 15px',
        backgroundColor: Colors.white,
        border: `${'1px solid' + Colors.border}`,
        color: Colors.themeDark,
    },
    // selectInput: {
    //     marginTop: 5, padding: 10, paddingRight: '20px',
    //     borderRadius: 0, border: `${'1px solid' + Colors.border}`,
    //     color: Colors.themeDark, fontSize: 14,  fontWeight: 300,
    // },
    selectInput: {
        fontFamily: Family.roboto,
        fontWeight: 300,
        fontSize: 14,
        borderRadius: 0,
        marginTop: '5px',
        padding: '8px 15px',
        backgroundColor: Colors.white,
        border: `${'1px solid' + Colors.border}`,
        color: Colors.themeDark,
    },
    multiple: {
        width: '100%', margin: '4px 0px', padding: '4px 0px',
        color: Colors.themeDark, border: `${'1px solid' + Colors.border}`, fontSize: '14px',
    },
    avataronline: {
        width: '20px', height: '20px', position: 'relative',
        boxShadow: '-1 0 1px 1.9px ', backgroundRepeat: 'round', borderRadius: '50%',
    },
    chips: { display: 'flex', flexWrap: 'wrap', },
    textField: { width: '100%', marginTop: 0, },
    textFormLabel: { fontSize: 16, marginTop: -3, color: Colors.themeDark, fontWeight: 300, },
    textDefalut: { margin: '5px 0px', fontSize: '16px', color: Colors.themeDark, fontFamily: Family.roboto },

});

const addressTypes = {
    province: 'province',
    district: 'district',
};

const getProvice = (code) => {
    return Province.find(each => each.code === code);
}

const getDistrict = (code) => {
    return Districts.find(each => each.code === code);
}

const renderInput = (inputProps) => {
    const { InputProps, classes, ref, error, ...other } = inputProps;
    return (
        <TextField
            className={classes.textField}
            error={error}
            // error={this.province === "" ? true : false }

            InputProps={{
                inputRef: ref,
                classes: { input: classes.textInput, },
                // classes: {
                //     root: classes.inputRoot,
                // },
                ...InputProps,
            }}
            InputLabelProps={{ shrink: true, className: classes.textFormLabel, }}
            {...other}
        />
    );
}

const getSuggestions = (inputValue, type, provinceCode) => {
    // let count = 0;

    const suggestions = type === addressTypes.province ?
        Province :
        Districts.filter(each => each.province_code === provinceCode);

    return suggestions.filter(suggestion => {
        // const keep =
        //     (!inputValue || suggestion.name_EN.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1) &&
        //     count < 5;

        // if (keep) {
        //     count += 1;
        // }

        // return keep;
        return !inputValue || suggestion.name_EN.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
    });
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem, onChange }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.name_EN) > -1;
    if (isSelected) return null;

    return (
        <div
            {...itemProps}
            onClick={(e) => {
                itemProps.onClick(e);
                onChange(suggestion);
            }}

            key={suggestion.name_EN}
            selected={isHighlighted}
            component="div"
            style={{
                fontWeight: isSelected ? 500 : 400, padding: "5px", cursor: 'pointer'
            }}
        >
            {suggestion.name_EN}
        </div>
    );
}

const DownshiftWrapper = ({
    type, onChange, selectedItem, disabled = false,
    provinceCode, label, placeholder = '',
    getRef, classes, isOpen, handleFocus, handleBlur, style, error
}) => (
        <Downshift
            selectedItem={selectedItem}
            ref={getRef}
        >
            {({ getInputProps, getItemProps, inputValue, selectedItem, highlightedIndex }) => (
                <div style={{ backgroundColor: Colors.white, ...style }}
                // className={classes.container}
                >
                    {renderInput({
                        fullWidth: true,
                        disabled,
                        classes,
                        label: label,
                        error: error,
                        InputProps: getInputProps({
                            placeholder,
                            onKeyDown: event => {

                                if (event.key === 'Enter') {
                                    // Prevent Downshift's default 'Enter' behavior.
                                    event.nativeEvent.preventDownshiftDefault = true
                                }
                            },
                            onFocus: handleFocus,
                            onBlur: handleBlur,
                        }),
                    })}
                    {isOpen ? (
                        <Paper
                            className={classes.paper}
                            square>
                            {getSuggestions(inputValue, type, provinceCode).map((suggestion, index) => {
                                // console.warn(getItemProps({ item: suggestion.name }));

                                return renderSuggestion({
                                    onChange,
                                    suggestion,
                                    index,
                                    itemProps: getItemProps({ item: suggestion.name_EN }),
                                    highlightedIndex,
                                    selectedItem,
                                })

                            }
                            )}
                        </Paper>
                    ) : null}
                </div>
            )}
        </Downshift>
    )
const DownshiftWithStyle = withStyles(styles)(DownshiftWrapper);

class AutoThaiAddress extends Component {
    province = null;
    district = null;
    constructor(props) {
        super(props);
        this.state = {
            address: {
                province: getProvice(props.provinceId),
                district: getDistrict(props.districtId),
            },
            isOpen: {
                province: false,
                district: false,
            },
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        const { provinceId, districtId } = this.props;
        if (!equals(nextProps.provinceId, provinceId) || !equals(nextProps.districtId, districtId)) {
            this.setState({
                address: {
                    province: getProvice(nextProps.provinceId),
                    district: getDistrict(nextProps.districtId),
                },
            });
        }
    }

    handleChange(state) {
        const { onChange } = this.props;
        this.setState({
            address: {
                ...this.state.address,
                ...state,
            },
        }, () => {
            if (typeof onChange === 'function') {
                onChange(this.state.address);
            }
        });
    }

    render() {
        const { address: { province, district }, isOpen } = this.state;
        const { each, disabledProvince, disabledDistrict, RequirePro, RequireDis, errorPro, errorDis } = this.props;
        return (
            <div style={{ display: isMobile() ? 'unset' : 'flex', paddingTop: 5, paddingLeft: 15, ...this.props.style }}>
                <DownshiftWithStyle
                    error={errorPro}
                    getRef={(ref) => this.province = ref}
                    // style={{ width: isMobile() ? '70%' : '50%' }}
                    label={RequirePro ? <div>{RequireForm()} {each.pro_EN} </div> : each.pro_EN}
                    disabled={disabledProvince}
                    type={addressTypes.province}
                    onChange={(val) => {
                        this.handleChange({ province: val, district: null });
                        this.district.clearSelection();
                    }}
                    selectedItem={path(['name_EN'], province)}
                    // placeholder={each.placeholder_EN}
                    handleFocus={() => this.setState({ isOpen: { province: true, district: false } })}
                    handleBlur={() => this.setState({ isOpen: { province: false, district: false } })}
                    isOpen={isOpen.province}
                />
                <DownshiftWithStyle
                    error={errorDis}
                    getRef={(ref) => this.district = ref}
                    style={{
                        //  width: isMobile() ? '70%' : '50%', 
                        paddingLeft: isMobile() ? 0 : 15, paddingTop: isMobile() ? 15 : 0
                    }}
                    label={RequireDis ? <div>{RequireForm()} {each.dis_EN} </div> : each.dis_EN}
                    type={addressTypes.district}
                    onChange={(val) => this.handleChange({ district: val })}
                    disabled={!province || disabledDistrict}
                    // isView={disabledDistrict}
                    provinceCode={path(['code'], province)}
                    selectedItem={path(['name_EN'], district)}
                    // placeholder={each.placeholder_EN}
                    // label="District"
                    handleFocus={() => this.setState({ isOpen: { province: false, district: true } })}
                    handleBlur={() => this.setState({ isOpen: { province: false, district: false } })}
                    isOpen={isOpen.district}
                />
            </div>
        );
    }
}

export default AutoThaiAddress;