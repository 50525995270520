import React, { Component } from 'react';
import { connect } from 'react-redux';

import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import { Images, Colors, Family } from '../../themes';
import { PathName } from '../../constant';

import CONFIG from '../../config';

import * as SettingActions from '../../actions/Actions_Settings.js';
import * as BookingActions from '../../actions/Actions_BookingManager.js';
import * as SellerActions from '../../actions/Actions_SellerManager.js';
import * as StatiticAction from '../../actions/Actions_StatiticManager.js';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as AgentActions from '../../actions/Actions_AgentManager.js';
import * as UploadActions from '../../actions/Actions_UploadManager';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';

import AgentSumaryBookingPanel from './AgentSumaryBookingPanel.jsx';
import BestProductStatPanel from '../../containers/Supplier/BestProductStatPanel.jsx';
import PackageAffiliatePanel from '../../containers/Packages/PackageAffiliatePanel.jsx';
import PackageListPanel from '../../componets/PackageListPanel.jsx';
import SellerListPanel from '../../containers/Sellers/SellerListPanel.jsx';
import SellerCard from '../Sellers/SellerCard';
import AgentCard from './AgentCard';

import ChangePasswordCard from '../../componets/ChangePasswordCard';
import Button from '../../constant/Button';
import { Icons } from '../../themes';
import { init_seller, SetSellerToCreate } from '../../constant/initState/initSeller';

import '../../App.css';

const { PasswordIcon, BorderColorIcon } = Icons;

const _height = (window.innerHeight - 130);
let BookingList = {};
let SellerList = [];
let PackgaeList = [];
let SummaryBooking = [];
let TopProductList = [];
let BookBankList = [];
let SellerTypeList = [];
let DefaultCommisstions = [];

let waitingTime = true;
let isDialogDisplay = false;

class AgentInfo extends Component {

    state = { isPassword: false, isLoading: true, isDialog: false, isAffiliate: false, msgError: undefined, dialogType: undefined, }

    constructor(props) {
        super(props);
        this.state = {
            SellerInfo: undefined,
            AgentInfo: this.props.location.state.agentInfo,
            isCreateSeller: false,
            isEditSeller: false,
            isEditAgent: false,
            isViewSeller: false,
        }
        isDialogDisplay = (!!this.props.agentInfo);

        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        if (!!agentInfo) {

            // console.log('AgentInfo : ', agentInfo, this.state.AgentInfo)
            const { id, agentId, actived } = agentInfo;

            this.props.getBookingListsWithCustomerID(id);// not use check

            if (actived) {
                this.props.getAPIPackageList({ limit: 10, visible: true })
                this.props.getAPIIncomeAgentSummary({ agentId: agentId, startDate: '2018-01-01' });//check
                this.props.getAPIBestBookingWithAgentId({ agentId: agentId })
                this.props.getAPIBankList({ num_limit: 100 });
            }
            this.getAPISellerListWithLimit({ num_limit: 10 });
            this.props.getAPIBankList({ num_limit: 100 });
            this.props.getAPISellerTypeList();
            this.props.getDefaultCommissions();
        }
    }

    componentWillUnmount() {
        BookingList = {};
        SellerList = [];
        PackgaeList = [];
        SummaryBooking = [];
        TopProductList = [];
    }

    componentWillReceiveProps(nextProps) {

        const { SellerManager, UpLoadManager, AgentManager, BookingManager, StatiticManager, PackageManager, SettingManager } = nextProps;
        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        if (!!AgentManager) {
            const { actions, data, error } = AgentManager.AGENT_REDUCER;
            switch (actions) {
                case AgentActions.UPDATE_AGENT_SUCCESS:
                    {
                        AgentManager.AGENT_REDUCER.actions = AgentActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Agent Success.', dialogType: 'success' });
                        this.props.getAPIAgentList();
                    } break;
                case AgentActions.UPDATE_AGENT_FAILURE:
                    {
                        console.log("UPDATE_AGENT_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;
            const { isEditSeller, isCreateSeller } = this.state;

            switch (actions) {
                case SellerActions.GET_SELLER_WITH_AGENT_ID_SUCCESS:
                    {
                        if (isEditSeller || isCreateSeller)
                            SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SellerList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_WITH_AGENT_ID_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break;
                case SellerActions.CREATE_SELLER_SUCCESS:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Seller Success.', dialogType: 'success', });
                        this.getAPISellerListWithLimit({ num_limit: 10 });
                    } break;

                case SellerActions.UPDATE_SELLER_SUCCESS:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Seller Success.', dialogType: 'success' });
                        this.getAPISellerListWithLimit({ num_limit: 10 });
                    } break;

                case SellerActions.CREATE_SELLER_FAILURE:
                case SellerActions.UPDATE_SELLER_FAILURE:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        console.log("CREATE_SELLER_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
                case SellerActions.GET_SELLER_TYPE_SUCCESS:
                    {
                        // SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SellerTypeList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_TYPE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!BookingManager) {

            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingActions.GET_LB_BOOKING_LISTS_SUCCESS:
                    {
                        if (!!data)
                            BookingList.bookingList = data.filter((booking) => (booking.userId === agentInfo.id));
                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingActions.GET_LB_BOOKING_LISTS_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break;
            }
        }

        if (!!StatiticManager && agentInfo.actived) {

            const { data, actions, error } = StatiticManager.STATITIC_REDUCER;

            switch (actions) {
                case StatiticAction.GET_TOP_PRODUCT_WITH_AGENT_SUCCESS:
                    { TopProductList = data.results.data; } break;

                case StatiticAction.GET_AGENT_SUMMARY_INCOME_SUCCESS:
                    { SummaryBooking = data.results || []; } break;

                case StatiticAction.GET_AGENT_SUMMARY_INCOME_FAILURE:
                case StatiticAction.GET_TOP_PRODUCT_WITH_AGENT_FAILURE:
                    { } break;
            }
        }

        if (!!PackageManager && agentInfo.actived) {

            const { data, actions, error } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {

                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    { PackgaeList = data || []; } break;

                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    { } break;
            }
        }

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_BANKLIST_SETTING_SUCCESS:
                    {
                        BookBankList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_BANKLIST_SETTING_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        DefaultCommisstions = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!UpLoadManager) {

            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            const { SellerInfo, isEditSeller, isEditAgent } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let _newAgent = this.state.AgentInfo;
                        let _newSeller = this.state.SellerInfo;
                        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

                        if (isEditSeller && !isEditAgent) {
                            _newSeller.logo = data.result.upload.images;
                            console.log('UPLOAD_IMAGE_SUCCESS_SELLER :', _newSeller)
                            this.props.APIUpdateSeller(_newSeller);
                        } else if (isEditAgent && !isEditSeller) {
                            _newAgent.logo = data.result.upload.images;
                            console.log('UPLOAD_IMAGE_SUCCESS_AGENT :', _newAgent)
                            this.props.APIUpdateAgent(_newAgent);
                        } else if (!isEditSeller && !isEditAgent) {
                            _newSeller.logo = data.result.upload.images;
                            console.log('UPLOAD_IMAGE_SUCCESS_CREATE_SELLER :')
                            SellerInfo.agentId = agentInfo.agentId;
                            this.props.APICreateSeller(SetSellerToCreate(_newSeller))
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    render() {
        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { isLoading, isAffiliate, isCreateSeller, msgError, dialogType, isDialog, isPassword, isEditAgent } = this.state;

        return (
            <div className="App">
                {(isCreateSeller) && <DialogAction Active={isCreateSeller} OnClose={this.onClosePanel} AcrionCom={this.SellerPanel()} />}
                {(isEditAgent) && <DialogAction Active={isEditAgent} OnClose={this.onClosePanel} AcrionCom={this.AgentPanel()} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isAffiliate) && <DialogAction Active={isAffiliate} OnClose={this.onClosePanel} AcrionCom={this.PackageAffiliatePanel()} />}

                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>                        {this.contentPanel(agentInfo)}                    </div>
                </List>
            </div>
        );
    }

    contentPanel(agentInfo) {
        const { isLoading, isCreateSeller, } = this.state;
        return (
            <React.Fragment>
                {this.InfoPanel(agentInfo)}
                <AgentSumaryBookingPanel Wallet={agentInfo.wallet} SummaryBooking={SummaryBooking} />
                <BestProductStatPanel SupplierList={TopProductList} history={this.props.history} isLoading={isLoading} />
                <SellerListPanel
                    CreatePanel={this.onCreatePanel(isCreateSeller)}
                    DisplaySellerInfo={this.DisplaySellerInfo()}
                    EditInfo={this.EditSellerInfo()}
                    SellerList={SellerList}
                    isEmployee={true}
                    agentId={agentInfo.agentId} />
                <PackageListPanel packageInfoList={PackgaeList} onClickHandle={this.onClickHandle()} />
            </React.Fragment>
        )
    }

    DisplaySellerInfo = () => (sellerId) => { this.setState({ isEditSeller: true, sellerId: sellerId, isViewSeller: true }) }

    onClickHandle = () => (packageId) => { this.setState({ isAffiliate: true, packageId: packageId }) }
    PackageAffiliatePanel = () => {
        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;//!!!! check
        const { packageId } = this.state;
        const _packageInfo = PackgaeList.packageList.find((_item) => (_item.packageId === packageId));
        return <PackageAffiliatePanel packageInfo={_packageInfo} affiliateID={agentInfo.agentId} OnClose={() => { this.onCloseDialog() }} />
    }

    //========================================================================================================================================================================================================
    //Function Call back use with SellerListPanel
    onCreatePanel = (_isActive) => () => { this.setState({ isCreateSeller: !_isActive }) };
    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, isAffiliate: false, msgError: undefined, dialogType: undefined }));
    EditSellerInfo = () => (SellerInfo) => { this.setState({ isCreateSeller: true, isEditSeller: true, SellerInfo, oldSellerInfo: SellerInfo }) }

    SellerPanel = () => {
        const { isEditSeller, SellerInfo, isViewSeller } = this.state;
        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        return (
            <SellerCard
                Level={DefaultCommisstions}
                SellerTypeList={SellerTypeList}
                isView={isViewSeller}
                isEdit={isEditSeller}
                BookBankList={BookBankList}
                agentId={agentInfo.agentId}
                onClosePanel={this.onClosePanel}
                SellerInfo={(isEditSeller) ? SellerInfo : init_seller}
                APICreateSeller={this.APICreateSeller}
                APIUpdateSeller={this.APIUpdateSeller} />
        )
    }

    AgentPanel = () => {
        const { AgentInfo, isEditAgent } = this.state;
        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        return (
            <AgentCard
                Level={DefaultCommisstions}
                BookBankList={BookBankList}
                isEdit={isEditAgent}
                AgentInfo={AgentInfo || agentInfo}
                onClosePanel={this.onClosePanel}
                APIUpdateAgent={this.APIUpdateAgent} />
        )
    }

    APICreateSeller = (avatar, sellerInfo) => {
        // console.log('APICreateSeller', this.state);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APICreateSeller(SetSellerToCreate(sellerInfo)));
        }
    }

    APIUpdateSeller = (avatar, sellerInfo) => {
        // console.log('APIUpdateSeller', this.state);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, SellerInfo: sellerInfo }, () => this.props.APIUpdateSeller(sellerInfo));
        }
    }

    APIUpdateAgent = (avatar, agentInfo) => {
        // console.log('APIUpdateSeller', this.state);
        if (avatar) {
            let formdata = new FormData();
            formdata.append("images", avatar);
            this.setState({ isDialog: true, AgentInfo: agentInfo }, () => this.props.APIUpLoadImage(formdata));
        } else {
            this.setState({ isDialog: true, AgentInfo: agentInfo }, () => this.props.APIUpdateAgent(agentInfo));
        }
    }

    onClosePanel = (_agentInfo) => {
        if (!!_agentInfo) {
            this.props.location.state.agentInfo = _agentInfo;
            this.setState({ isPassword: false, isCreateSeller: false, isEditAgent: false, isEditSeller: false, error: null, isLoading: false, msgError: undefined, dialogType: undefined }, () =>
                this.props.history.replace({ pathname: PathName.AgentInfo, state: { agentInfo: _agentInfo }, search: '', hash: '' }));
        } else this.setState({ isPassword: false, isCreateSeller: false, isEditAgent: false, isEditSeller: false, AgentInfo: this.state.AgentInfo, error: null, isLoading: false, msgError: undefined, dialogType: undefined });
    }

    getAPISellerListWithLimit = () => {
        const { page, limit, } = SellerList;
        const { agentInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const _fliter = { where: { agentId: agentInfo.agentId }, page: (page || 0), num_limit: (limit || 10) };
        return this.props.getAPISellerList_WithAgentId(_fliter);
    }

    //========================================================================================================================================================================================================

    InfoPanel(agentInfo) {
        const { isPassword, AgentInfo } = this.state
        const { agentId, email, level, logo, name, telOffice, priceGroup, affiliateId, actived, } = AgentInfo || agentInfo;
        const { classes } = this.props;
        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10, fontFamily: Family.roboto };
        return (
            <div style={{
                display: 'flex', height: '328px', width: '1020px',
                backgroundColor: Colors.themeDark,
                //  backgroundImage: `url(${Images.HeaderBackground.HeaderBackground})`,
            }} >
                {(isPassword) && <DialogAction Active={isPassword} OnClose={this.onClosePanel} AcrionCom={<ChangePasswordCard id={agentId} onClosePanel={this.onClosePanel} />} />}

                < div style={{ height: '300px', width: '250px', paddingLeft: '50px', paddingTop: '50px', paddingRight: '50px', borderradius: '10%', boxshadow: '0  0 5px rgba(0, 0, 0, 0.2)', }} >
                    <img className={classes.avataronline} src={(logo) ? (`${CONFIG.host}${logo.original}`) : Images.User.userdefault} />
                </div>
                <div style={{ width: '100%', height: '200px', padding: '15px', textAlign: 'left', color: Colors.white }}>
                    <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Icons themeDark tooltip={'Edit'} buttonStyle={{ backgroundColor: Colors.themeDark }} icon={<BorderColorIcon />} onClick={() => { this.setState({ isEditAgent: true }) }} />
                        <Button.Icons themeDark tooltip={'Change Password'} buttonStyle={{ backgroundColor: Colors.themeDark }} icon={<PasswordIcon />} onClick={() => { this.setState({ isPassword: true }) }} />
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>User Name : </label>
                        <label className={classes.content}>{name}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>User ID : </label>
                        <label className={classes.content}>{agentId}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>Phone number : </label>
                        <label className={classes.content}>{telOffice || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>Email : </label>
                        <label className={classes.content}>{email || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>Level : </label>
                        <label className={classes.content}>{level || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>Type : </label>
                        <label className={classes.content}>{priceGroup || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>Visible : </label>
                        <label className={classes.content}>{actived ? 'Active' : 'Inactive'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.title}>Regiter Type : </label>
                        <label className={classes.content}>{affiliateId || '-'}</label>
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIAgentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AgentActions.getAgentList(_keyword, _filter));
        },
        getBookingListsWithCustomerID: (memberID) => {
            InitWaitingTime();
            dispatch(BookingActions.get_LB_APIBookingLists(memberID));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingActions.getAPIBookingDetail(_id));
        },
        getAPISellerList_WithAgentId: (_fliter) => {
            dispatch(SellerActions.getSellerList_WithAgentId(_fliter));
        },
        getAPIIncomeAgentSummary: (_fliter) => {
            dispatch(StatiticAction.getIncomeAgentSummary(_fliter));
        },
        getAPIBestBookingWithAgentId: (_fliter) => {
            dispatch(StatiticAction.getBestBookingWithAgentId(_fliter));
        },
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getAPIBankList: (_keyword) => {
            InitWaitingTime();
            dispatch(SettingActions.getBankList(_keyword));
        },
        APICreateSeller: (_confirmInfo) => {
            dispatch(SellerActions.APICreateSeller(_confirmInfo));
        },
        APIUpdateSeller: (_confirmInfo) => {
            dispatch(SellerActions.APIUpdateSeller(_confirmInfo));
        },
        APICreateAgent: (_confirmInfo) => {
            dispatch(AgentActions.APICreateAgent(_confirmInfo));
        },
        APIUpdateAgent: (_confirmInfo) => {
            dispatch(AgentActions.APIUpdateAgent(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },

    }
}

const styles = theme => (
    {
        content: { opacity: '0.64', fontSize: '12px', },
        title: { width: '110px', fontSize: '12px', fontWeight: 'bold' },
        avataronline: { backgroundColor: Colors.defaultImage, position: 'relative', boxShadow: '0 0 1px 1.9px ', borderRadius: '50%', backgroundRepeat: 'round', width: '200px', height: '200px' },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AgentInfo));