import React, { Component } from 'react';
import * as R from "ramda";

import TextField from '@material-ui/core/TextField';

import AddImage from "./AddImage";
import PreviewImage from "./PreviewImage";
import PreviewVideo from "./PreviewVideo";
import FileExtension from "../constant/FileExtension";
import FileName from "../constant/FileName";
import { acceptFile } from "../constant";

import CONFIG from '.././config';

class FileUploader extends Component {
    inputRef;
    removeList = [];
    files;
    constructor(props) {
        super(props);
        this.state = {
            defaultFiles: this.props.defaultFiles || [],
        };
        this.files = this.props.files || [];

        this.onClickAdd = this.onClickAdd.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (Array.isArray(nextProps.files) && !R.equals(nextProps.files, this.files)) {
            this.files = nextProps.files;
            this.forceUpdate();
        }
        if (!R.equals(nextProps.defaultFiles, this.props.defaultFiles)) {
            this.setState({ defaultFiles: nextProps.defaultFiles });
        }
    }

    onFileAdded(file) {
        this.onFilesChange(this.files.concat(file));
    }

    onFileRemoved(index) {
        this.onFilesChange(R.remove(index, 1, this.files));
    }

    onFilesChange(files) {
        this.files = files;
        this.forceUpdate();
        if (typeof this.props.onChange === 'function') {
            this.props.onChange(files);
        }
    }

    onDefaultFileRemoved(index) {
        this.onDefaultFilesChange(R.remove(index, 1, this.state.defaultFiles));
    }

    onDefaultFilesChange(defaultFiles) {
        this.setState({ defaultFiles }, () => {
            if (typeof this.props.onDefaultChange === 'function') {
                this.props.onDefaultChange(defaultFiles);
            }
        });
    }

    onClickAdd() {
        this.inputRef.click();
    }

    getRemoveList() {
        return this.removeList;
    }

    render() {
        const { accept, multiple = false } = this.props;
        return (
            <div>
                <div
                    style={{
                        flexWrap: 'wrap',
                        display: 'flex',
                    }}
                >
                    {this.renderDefault()}
                    {this.renderPreview()}
                    {this.renderAdd()}
                </div>
                <input
                    ref={(ref) => this.inputRef = ref}
                    accept={accept}
                    style={{
                        display: 'none',
                    }}
                    type="file"
                    onChange={this.onFileChange}
                    multiple={multiple}
                />
            </div>
        );
    }

    renderDefault() {
        const { size, editFileName = false, disabled } = this.props;
        return (this.state.defaultFiles || []).map((each, index) => {
            const key = `preview${index}`;
            // const fileExtension = FileExtension.get(each.file.name || each.file);
            // const fileExtension = FileExtension.get(`url(${CONFIG.host}${each.thumbnail})`);
            const fileExtension = FileExtension.get(each.thumbnail);
            if (this.props.accept.indexOf(fileExtension) > -1) {
                if (acceptFile.image.indexOf(fileExtension) > -1) {
                    // console.log('config', `${CONFIG.host}${each.thumbnail}`)
                    return (

                        <PreviewImage
                            key={key}
                            alt={key}
                            removable={disabled ? false : each.removable}
                            src={`${CONFIG.host}${each.thumbnail}`}
                            size={size}
                            onClick={() => {
                                this.onDefaultFileRemoved(index);
                                this.removeList = R.append(each, this.removeList);
                            }} />
                    )
                } else {
                    return (
                        <PreviewVideo
                            key={key}
                            alt={key}
                            onClick={() => this.onFileRemoved(index)}
                            src={each.base64Image}
                            size={size} />
                    )
                }

            } else {
                return null;
            }
        })
    }

    renderPreview() {
        const { size, editFileName = false, disabled } = this.props;
        return this.files.map((each, index) => {
            const key = `preview${index}`;
            const fileExtension = FileExtension.get(each.file.name || each.file);
            if (this.props.accept.indexOf(fileExtension) > -1) {
                if (acceptFile.image.indexOf(fileExtension) > -1) {
                    return (
                        <PreviewImage
                            key={key}
                            alt={key}
                            disabled={disabled}
                            onClick={() => this.onFileRemoved(index)}
                            src={each.base64Image}
                            size={size} />
                    )
                } else {
                    return (
                        <PreviewVideo
                            key={key}
                            disabled={disabled}
                            alt={key}
                            onClick={() => this.onFileRemoved(index)}
                            src={each.base64Image}
                            size={size} />
                    )
                }

            } else {
                return null;
            }
        })
    }

    renderAdd() {
        const { size, disabled } = this.props;
        if (!this.props.multiple && (this.files.length > 0 || this.state.defaultFiles.length > 0)) return null;
        return disabled ? null : <AddImage onClick={this.onClickAdd} size={size} />;
    }

    onFileChange(event) {
        const files = event.target.files;
        if (files.length > 0) {
            const reader = new FileReader();
            const readFile = (index = 0) => {
                const fileType = files[index].type;
                const fileExtension = FileExtension.get(files[index].name).toLowerCase();
                // re-check file type
                if (this.props.accept.indexOf(fileExtension) === -1) {
                    console.log('wrong file type');
                    if (index < files.length - 1) {
                        readFile(index + 1);
                    } else {
                        this.inputRef.value = null;
                    }
                } else {
                    // check file name not duplicate
                    const fileNameList = [
                        ...this.files.map(each => each.name),
                        ...this.state.defaultFiles.map(each => FileName.getNameByFirebaseStorage(each.file)),
                    ];
                    if (fileNameList.indexOf(files[index].name) > -1) {
                        alert(`ชื่อไฟล์ซ้ำ\nโปรดเปลี่ยนชื่อไฟล์ "${files[index].name}" แล้วทำการเลือกไฟล์อีกครั้ง`);
                        if (index < files.length - 1) {
                            readFile(index + 1);
                        }
                    } else {
                        if (!!fileType && fileType.indexOf("image") > -1) {
                            reader.onloadend = (e) => {
                                this.onFileAdded({
                                    file: files[index],
                                    base64Image: R.path(['target', 'result'], e),
                                    name: files[index].name,
                                    fileExtension,
                                });
                                if (index < files.length - 1) {
                                    readFile(index + 1);
                                } else {
                                    this.inputRef.value = null;
                                }
                            };
                            reader.readAsDataURL(files[index]);
                        } else if (!!fileType && fileType.indexOf("video") > -1) {
                            reader.onloadend = (e) => {
                                this.onFileAdded({
                                    file: files[index],
                                    base64Image: R.path(['target', 'result'], e),
                                    name: files[index].name,
                                    fileExtension,
                                });
                                if (index < files.length - 1) {
                                    readFile(index + 1);
                                } else {
                                    this.inputRef.value = null;
                                }
                            };
                            reader.readAsDataURL(files[index]);

                            // this.onFileAdded({
                            //     file: files[index],
                            //     name: files[index].name,
                            //     fileExtension,
                            // });
                            // if (index < files.length - 1) {
                            //     readFile(index + 1);
                            // } else {
                            //     this.inputRef.value = null;
                            // }
                        }
                    }
                }
            };
            readFile();
        }
    }
}

FileUploader.defaultProps = {
    size: 100,
}

export default FileUploader;