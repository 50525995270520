import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import AuthenManager from './redux/Reducers_AuthenManager';
import ProductManager from './redux/Reducers_ProductsManager';
import PackageManager from './redux/Reducers_PackageManager';
import BookingManager from './redux/Reducers_BookingManager';
import SellerManager from './redux/Reducers_SellerManager';
import SupplierManager from './redux/Reducers_SuppliersManager';
import CustomerManager from './redux/Reducers_CustomerManager';
import MemberManager from './redux/Reducers_MemberManager';
import DestinationsManager from './redux/Reducers_DestinationsManager';
import UpLoadManager from './redux/Reducers_UploadManager';
import ActivityManager from './redux/Reducers_ActivityManager';
import AppointmentManager from './redux/Reducers_AppointmentManager';
import ConditionManager from './redux/Reducers_ConditionManager';
import CurrencyManager from './redux/Reducers_CurrencyManager';
import CommissionsManager from './redux/Reducers_CommissionsManager';
import StatiticManager from './redux/Reducers_StatiticManager';
import AgentManager from './redux/Reducers_AgentManager';
import PromotionManager from './redux/Reducers_PromotionManager';
import SettingManager from './redux/Reducers_SettingManager';
import AccountingManager from './redux/Reducers_AccountingManager';
import RepotsManager from './redux/Reducers_RepotsManager';
import AdminManager from './redux/Reducers_AdminManager';
import LocationServicesManager from './redux/Reducers_LocationServicesManager';
import ZoneManager from './redux/Reducers_ZoneManager';
import CountryManager from './redux/Reducers_CountryManager';
import RefundManager from './redux/Reducers_RefundManager';
import AnnouncementManager from './redux/Reducers_AnnouncementManager';


//{DataReducer:reducer_DataManager,APIReducer:reducer_API,DisplayReducer:reducer_DisplayManager}

const Store = createStore(combineReducers({
    AdminManager,
    PackageManager,
    ProductManager,
    BookingManager,
    SellerManager,
    SupplierManager,
    CustomerManager,
    CommissionsManager,
    MemberManager,
    DestinationsManager,
    AuthenManager,
    UpLoadManager,
    ActivityManager,
    AppointmentManager,
    ConditionManager,
    StatiticManager,
    AgentManager,
    PromotionManager,
    SettingManager,
    AccountingManager,
    RepotsManager,
    LocationServicesManager,
    ZoneManager,
    CountryManager,
    CurrencyManager,
    RefundManager,
    AnnouncementManager,

}), applyMiddleware(thunk));

export default Store;