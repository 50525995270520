const HeaderPage = {
    TH: "สร้างลักษณะ",
    EN: "Create Promotion",
    CN: "创建包",
}

const BodyPage = [
    [{
        title_TH: "ชื่อ", title_EN: "Promotion Name", title_CN: "名称",
        type: "text",
        require: true,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ลักษณะ", title_EN: "Description", title_CN: "描述",
        type: "text-multiline",
        require: true,
        stateName: "description",
        placeholder_TH: "ลักษณะ", placeholder_EN: "Insert Description", placeholder_CN: "插入说明"
    }], [{
        title: "Booking Time",
        titleStart: "Start Date",
        type: 'date-picker',
        require: true,
        titleEnd: "End Date", end_TH: "", end_CN: "",
        stateStart: 'startDate', stateEnd: 'endDate',
        stateGroup: 'bookingDate',
    }, {
        title: "Travel Time",
        titleStart: "Start Date",
        type: 'date-picker',
        require: true,
        titleEnd: "End Date", end_TH: "", end_CN: "",
        stateStart: 'startDate', stateEnd: 'endDate',
        stateGroup: 'travelDate',
    }],
    [{
        title_TH: "แพ็คเกจ", title_EN: "SELECT PACKAGE", title_CN: "约定",
        type: "button-list",
        stateName: "packageId",
        require: false,
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }], [{
        title_EN: "Price Type", title_TH: "", title_CN: "",
        type: "select",
        require: true,
        stateName: "type",
        placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        options: [
            { value: "price", label_TH: 'ราคา', label_EN: "Price", label_CN: "一日游" },
            { value: "percent", label_TH: "เปอร์เซ็น", label_EN: "Percent", label_CN: "转让" },
            { value: "group", label_TH: "กลุ่ม", label_EN: "Group", label_CN: "服务" },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "text-number",
        require: false,
        stateName: "adult",
        statePercent: "percent_adult",
        placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "text-number",
        require: false,
        stateName: "kid",
        statePercent: "percent_kid",
        placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "text-number",
        require: false,
        stateName: "infant",
        statePercent: "percent_infant",
        placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",
    }], [{
        title_EN: "Limit", title_TH: "", title_CN: "",
        type: "text-number",
        require: true,
        stateName: "limit",
        placeholder_EN: "", placeholder_TH: "", placeholder_CN: "",

    }, {
        title_EN: "Currency", title_TH: "", title_CN: "",
        type: "select",
        require: true,
        stateName: "currency",
        placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
    }], [{
        title_EN: "", title_TH: "", title_CN: "",
        type: "switch",
        stateName: "visible",
        require: false,
        label_EN: " Status", label_TH: "", label_CN: ""
    }]
];

export default {
    HeaderPage,
    BodyPage,
}