const HeaderPage = {
    CREATE_EN: "Create Refund Reasons", CREATE_TH: "----", CREATE_CN: "----",
    UPDATE_EN: "Update Refund Reasons", UPDATE_TH: "----", UPDATE_CN: "----",
}

const BodyPage = [
    [{
        title_TH: "---", title_EN: "Reasons", title_CN: "---",
        type: "text",
        require: true,
        stateName: "reasons",
        placeholder_TH: "-----", placeholder_EN: "Insert Reasons", placeholder_CN: "-----"
    }],
    [{
        type: "form-checkbox",
        items: [
            { value: 'isAdmin', TH: { name: "---" }, EN: { name: "ADMIN" }, CN: { name: "---" }, stateName: "isAdmin", },
            { value: 'isCustomer', TH: { name: "---" }, EN: { name: "CUSTOMER" }, CN: { name: "---" }, stateName: "isCustomer", },
            { value: 'isSupplier', TH: { name: "---" }, EN: { name: "SUPPLIER" }, CN: { name: "---" }, stateName: "isSupplier", },
            { value: 'all', TH: { name: "---" }, EN: { name: "ALL" }, CN: { name: "---" }, stateName: "all", },
        ],
    }],
    [{
        title_TH: "---", title_EN: "Visible", title_CN: "---",
        type: 'switch',
        stateName: 'visible'
    }],
];
export default {
    HeaderPage,
    BodyPage
} 