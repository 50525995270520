import { Province } from './Province';

const HeaderPage = {
    TH: "สร้างลักษณะ",
    EN: "Create Destination",
    CN: "创建包",
    UPDATE: "Update Destination",
}

const BodyPage = [
    [{
        title_TH: "ชื่อ", title_EN: "Destination Name", title_CN: "名称",
        type: "text",
        require: true,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ลักษณะ", title_EN: "Description", title_CN: "描述",
        type: "text-multiline",
        require: true,
        stateName: "description",
        placeholder_TH: "ลักษณะ", placeholder_EN: "Insert Description", placeholder_CN: "插入说明"
    }], [{
        title_TH: "ที่อยู่", title_EN: "Address", title_CN: "地址",
        type: "text",
        require: true,
        stateName: "address",
        placeholder_TH: "ที่อยู่", placeholder_EN: "Insert Address", placeholder_CN: "插入地址"
    }, {
        pro_TH: "จังหวัด", pro_EN: "Province", pro_CN: "省",
        dis_TH: "ตำบล", dis_EN: "District", dis_CN: "区",
        width: '95%',
        requirePro: true,
        requireDis: false,
        type: "search_select",
        stateName_pro: "province", stateName_dis: "district",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: Province
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "actived",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "image",
        stateName: "picture",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Image", title_CN: "照片",
        type: "media",
        stateName: "gallery",
        require: {
            EN: '650 x 450'
        }
    }]
];

export default {
    HeaderPage,
    BodyPage,
}