const get = (fullFileName = '') => {
    if (!!fullFileName && fullFileName !== '' && (Object.keys(fullFileName).length > 0)) {
        return fullFileName.trim().slice(fullFileName.lastIndexOf("."));
    }
    return '';
}

const add = (filename, extension) => {
    if (typeof filename === 'string' && typeof extension === 'string') {
        if (new RegExp(`${extension}$`).test(filename)) {
            return filename;
        } else {
            return filename + extension;
        }
    }
    return '';
}

export default {
    get,
    add,
}