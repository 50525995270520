import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { Family, Colors, RequireForm } from '../../themes';

class TextFormat extends Component {
    render() {
        const { classes, disabled, disableUnderline, multiline, onKeyUp, rowsMax, rows, helperText, type, error, TitleText, value, style, onChange, placeholder, Require, InputFormat } = this.props;
        return (
            <TextField className={classes.textField}
                multiline={multiline}
                onKeyUp={onKeyUp}
                rowsMax={rowsMax}
                rows={rows}
                disabled={disabled}
                error={error}
                helperText={helperText}
                type={type}
                label={Require ? <div> {RequireForm()}{TitleText} </div> : TitleText}
                value={value || ''}
                style={style}
                onChange={onChange}
                placeholder={placeholder}
                InputProps={{ disableUnderline: disableUnderline, inputComponent: InputFormat, classes: { input: (multiline) ? classes.textMultiline : classes.textInput, }, }}
                InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} />
        )
    }
}
const styles = theme => (
    {
        textInput: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: 14,
            borderRadius: 0,
            marginTop: '5px',
            padding: '8px 15px',
            backgroundColor: Colors.white,
            border: `${'1px solid' + Colors.border}`,
            color: Colors.themeDark,
        },
        textMultiline: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: 14,
            marginTop: '0px',
            borderRadius: 0,
            // padding: '8px 15px',
            padding: '8px 40px 15px 15px',
            marginBottom: '-8px',
            backgroundColor: Colors.white,
            border: `${'1px solid' + Colors.border}`,
            color: Colors.themeDark,
        },
        textField: { width: '100%', margin: '5px 0px 0px', },
        textFormLabel: { fontSize: 16, color: Colors.themeDark, fontWeight: 300, },
    }
)
TextFormat.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(TextFormat);