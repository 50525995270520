import React from 'react';

import TablePagination from '@material-ui/core/TablePagination';

const _defualtProp = { dTaltal: 0, dLimit: 25, dPage: 0 }

function GetPagination(props, ) {

    const { _ListCount, onNextPage, onChangeLimit } = props;
    const { total, page, limit } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || _ListCount.length}
            rowsPerPage={limit || _defualtProp.dLimit}
            page={page || _defualtProp.dLimit}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

export default GetPagination;

