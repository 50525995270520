import * as DestinationsActions from '../actions/Actions_DestinationsManager.js';

const INITIAL_STATE = { DESTINATION_REDUCER: { actions: DestinationsActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: DestinationsActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case DestinationsActions.INITIAL_STATE:
            {
                return state;
            }
        case DestinationsActions.IDLE_STATE:
            {
                //console.log("[ Destinations ] : IDLE_STATE : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.IDLE_STATE, data: payload, error: _error } };
            }
        case DestinationsActions.GET_DESTINATIONS_SUCCESS:
            {
                //console.log("[ Destinations ] : GET_DESTINATIONS_SUCCESS : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.GET_DESTINATIONS_SUCCESS, data: payload, error: _error } };
            }
        case DestinationsActions.GET_DESTINATIONS_FAILURE:
            {
                console.log("[ Destinations ] : GET_DESTINATIONS_FAILURE : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.GET_DESTINATIONS_FAILURE, data: payload, error: _error } };
            }
        case DestinationsActions.CREATE_DESTINATIONS_SUCCESS:
            {
                // console.log("[ Destinations ] : CREATE_DESTINATIONS_SUCCESS : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.CREATE_DESTINATIONS_SUCCESS, data: payload, error: _error } };
            }
        case DestinationsActions.CREATE_DESTINATIONS_FAILURE:
            {
                console.log("[ Destinations ] : CREATE_DESTINATIONS_FAILURE : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.CREATE_DESTINATIONS_FAILURE, data: payload, error: _error } };
            }
        case DestinationsActions.UPDATE_DESTINATIONS_SUCCESS:
            {
                // console.log("[ Destinations ] : UPDATE_DESTINATIONS_SUCCESS : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.UPDATE_DESTINATIONS_SUCCESS, data: payload, error: _error } };
            }
        case DestinationsActions.UPDATE_DESTINATIONS_FAILURE:
            {
                console.log("[ Destinations ] : UPDATE_DESTINATIONS_FAILURE : ", payload);
                return { ...state, DESTINATION_REDUCER: { actions: DestinationsActions.UPDATE_DESTINATIONS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Destinations ]  default :", state);
                return state;
            }
    }
}
