import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormTypes from '../../common/FormTypes';
import { Family, Colors } from '../../themes';
import * as format from '../../common/setFormat';

class TextDuration extends Component {

    render() {
        const { classes, disabled, error, errorUnit, TitleText, style, value, styleText, styleUnit, onChange, placeholder, Require, options, valueUnit, onChangeUnit } = this.props;
        return (
            <div className={classes.root} style={style}>
                <FormTypes.TextFormat
                    InputFormat={format.NumberFormatCustom}
                    disabled={disabled}
                    error={error}
                    style={{ width: '80%', ...styleText }}
                    Require={Require}
                    TitleText={TitleText}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange} />
                <FormTypes.Select
                    disabled={disabled}
                    error={errorUnit}
                    TitleText={' '}
                    style={{ width: '20%', ...styleUnit }}
                    value={valueUnit || ''}
                    onChange={onChangeUnit}
                    placeholder={placeholder}
                    options={options} />
            </div >
        )
    }

}
const styles = theme => (
    {
        root: { display: 'flex', width: '100%', },
    }
)
TextDuration.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(TextDuration);