import React, { Component } from 'react'
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import DialogAction from '../componets/DialogAction.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';

import * as CountryActions from '../actions/Actions_CountryManager';


import CountryCard from '../containers/Country/CountryCard';
import CountryListPanel from '../containers/Country/CountryListPanel';
import { init_country } from '../constant/initState/initCountry';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

let CountryList = [];
let ZoneList = [];
let waitingTime = true;
const _height = (window.innerHeight - 130);

class CountryManage extends Component {
    state = { isLoading: true, msgError: undefined, dialogType: undefined, }
    constructor(props) {
        let error = null;
        super(props);
        this.state = {
            CountryInfo: undefined,
            oldCountryInfo: null,
            isCreate: false,
            isEdit: false,
            isView: false,
        }

        this.props.getCountryList({ num_limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps)
        const { CountryManager } = nextProps;
        if (!!CountryManager) {

            const { actions, data } = CountryManager.COUNTRY_REDUCER;

            switch (actions) {
                case CountryActions.GET_COUNTRY_SUCCESS:
                    {
                        CountryList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CountryActions.GET_COUNTRY_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

    }

    render() {
        const { isLoading, isCreate } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.createCountry()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        )
    }
    contentPanel() {
        // console.log('CountryList', CountryList)
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <CountryListPanel
                    CountryList={CountryList}
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.editPanel()}
                    ViewInfo={this.viewInfo()} />
            </div>)
    }

    createCountry = () => {
        const { isEdit, CountryInfo, oldCountryInfo, isView } = this.state;
        return (
            <CountryCard
                CountryList={CountryList}
                onChangeLanguage={this.onChangeLanguage}
                isEdit={isEdit}
                isView={isView}
                CountryInfo={CountryInfo}
                oldCountryInfo={oldCountryInfo}
                onClosePanel={this.onClosePanel}
            />
        )
    }

    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, oldCountryInfo: { ...init_country }, CountryInfo: { ...init_country } }));
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, isView: false, CountryInfo: undefined, }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    onChangeLanguage = (_language) => {
        const { page } = CountryList;
        // console.log('_language', _language);
        this.props.getCountryList({ page: page, num_limit: 10, language: _language });
    }

    editPanel = () => (CountryInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            isView: false,
            CountryInfo,
            oldCountryInfo: CountryInfo
        })
    }

    viewInfo = () => (CountryInfo) => {
        this.setState({
            isCreate: true,
            isView: true,
            CountryInfo,
            oldCountryInfo: CountryInfo
        })
    }

}
function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountryList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CountryActions.getCountryList(_keyword, _filter));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CountryManage);