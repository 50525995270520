
// ===================================================================================================================================

export const api = {
    // admin set ===================================================================================================================================

    // host,
    register: `/api/register/backend/`,
    authentication: `/api/login/backend/`,
    authenticationLB: `/api/useralls/loginBackend/`,
    forgotPasswordLB: `/api/useralls/reset/`,
    changePasswordLB: `/api/useralls/change-password/`,
    updateUser: `/api/user/update/backend/`,
    getUser: `/api/user/getUser/backend/`,
    getUserAlls: `/api/useralls/`,
    getUserHistory: `/api/user/getUserHistory`,
    forgotPassword: `/api/user/forgotPassword/backend/`,

    // =============================================================================================================================================== 
    // package set ===================================================================================================================================

    getPackage: `/home/getPackage/`,
    getPackageCountSupplier: `/api/packages/countPackagesSupplier`,
    getPackageCount: `/api/packages/countPackages`,
    getPackageList: `/home/getPackage/paging/`,
    getDestination: `/api/getDestination`,

    getActivities: `/api/activities/paging/`,
    createActivity: `/api/activities/`,
    updateActivity: `/api/activities/update`,

    createPackage: `/api/system/createPackageAll/`,
    updatePackage: `/api/system/updatePackage/`,

    getAppointmentList: `/api/appointments/paging/`,
    createAppointment: `/api/appointments/`,
    updateAppointment: `/api/appointments/update`,

    // =============================================================================================================================================== 
    // booking set ===================================================================================================================================

    confirmPayment: `/api/`,
    getBookingList: `/api/system/BookingList/`,
    getBookingDetail: `/api/system/BookingDetail/`,
    changeBookingStatus: '/api/system/changeBookingStatus/',

    get_LB_BookingList: `/api/bookings`,
    get_LB_BookingPackageList: `/api/bookingpackages`,

    // =============================================================================================================================================== 
    // seller set ====================================================================================================================================

    getMemberList: `/api/admins/paging/`,
    createMember: `/api/useralls/`,
    updateMember: `/api/admins/update/`,
    getAdminInfo: `/api/admins/`,

    getSupplierList: `/api/suppliers/paging/`,
    getSupplierInfo: `/api/suppliers/`,
    createSupplier: `/api/useralls/`,
    updateSupplier: `/api/suppliers/update/`,

    getAgentList: `/api/agents/paging/`,
    getAgentInfo: `/api/agents/`,
    createAgent: `/api/useralls/`,
    updateAgent: `/api/agents/update/`,

    getSellerList: `/api/sellers/paging/`,
    getSellerInfo: `/api/sellers/`,
    createSeller: `/api/useralls/`,
    updateSeller: `/api/sellers/update/`,

    getProductsList: `/api/products/`,
    getproductCatergories: '/api/productCatergories',
    createProducts: `/api/products/`,

    getCustomerList: `/api/customers/paging/`,
    createCustomer: `/api/customers/`,
    updateCustomer: `/api/customers/update`,
    getCustomerRequests: `/api/customer_requests/paging`,

    getDestinationsList: `/api/destinations/paging/`,
    createDestinations: `/api/destinations`,
    updateDestinations: `/api/destinations/update/`,

    getConditionsList: `/api/conditions/paging/`,
    createConditions: `/api/conditions`,
    updateConditions: `/api/conditions/update`,

    getPromotionsList: `/api/promotions/paging`,
    getPromotions: `/api/promotions/`,
    createPromotions: `/api/promotions`,
    updatePromotions: `/api/promotions/update`,

    getPromotionsCodeList: `/api/promotion_codes/paging/`,
    createPromotionsCode: `/api/promotion_codes/`,
    updatePromotionsCode: `/api/promotion_codes/update`,

    getSellerTypeList: `/api/seller_types/paging`,
    createSellerType: `/api/seller_types`,
    updateSellerType: `/api/seller_types/update`,

    // Statistic =====================================================================================================================================


    bestSupplierList: `/api/bookingpackages/getStatisticBySupplier`,
    getStatisticActivedSupplier: `api/suppliers/getStatisticActivedSupplier`,
    getSummarySupplierStatus: `/api/suppliers/getStatisticApprovedSupplier/`,
    getStatisticParentSupplier: `/api/suppliers/getStatisticParentSupplier/`,
    getBestBookingWithSupplierId: `/api/bookingpackages/getStatisticBookingPackageBySupplierId`,
    getIncomeSupplierSummary: `/api/bookingpackages/getStatisticbookingPackageSaleOrderAndIncomeSupplier/`,

    bestAgentList: `/api/bookingpackages/getStatisticByAgent/`,
    getBestBookingWithAgentId: `/api/bookingpackages/getStatisticBookingPackageByAgentId`,
    getIncomeAgentSummary: `/api/bookingpackages/getStatisticbookingPackageSaleOrderAndIncomeAgent/`,

    bestSellerList: `/api/bookingpackages/getStatisticBySeller`,
    getBestBookingWithSellerId: `/api/bookingpackages/getStatisticBookingPackageBySellerId`,
    getIncomeSellerSummary: `/api/bookingpackages/getStatisticbookingPackageSaleOrderAndIncomeSeller`,

    getBookingStatus: `/api/bookings/getStatisticBookingStatus`,
    getPaymentSummary: `/api/bookings/getStatisticBookingPaymentInfo`,

    getIncomeAllSummary: `/api/bookingpackages/getStatisticBookingPackageSaleOrderAndIncome`,
    getAllSummaryBookingPaymentInfo: `/api/bookings/getStatisticBookingPaymentInfoList`,
    getAllSummaryBookingChart: `/api/bookingpackages/getStatisticBookingPackage`,
    getSummaryCustomerStatus: `/api/customers/getStatisticActivedCustomer/`,

    getStatisticBookingPackageTopOfWeek: `/api/bookingpackages/getStatisticBookingPackageTopOfWeek/`,
    getWalletInfoWithId: `/api/bookings/getStatisticBookingPriceEachStatus`,

    // =============================================================================================================================================== 
    // other set =====================================================================================================================================

    uploads: `/api/system/uploads/`,
    uploadBackEnd: `/api/uploads/backend/`,
    getErrorCode: `/api/getErrorCode/`,

    getBankList: `/api/banksettings/paging/`,
    createBank: `/api/banksettings/`,
    updateBank: `/api/banksettings/update/`,

    getCurrencyList: `/api/currencies/paging/`,
    createCurrency: `/api/currencies/`,
    updateCurrency: `/api/currencies/update/`,

    getCurrencyRateList: `/api/currency_rates/paging`,
    createCurrencyRate: `/api/currency_rates/`,
    updateCurrencyRate: `/api/currency_rates/update/`,

    getPaymentTime: `/api/payment_times/`,
    updatePaymentTime: `/api/payment_times/update/`,

    getSystemWallet: `/api/systems`,

    getAdminRepost: `/api/system/BookingReportAdmin/`,
    getSupplierRepost: `/api/system/BookingReportSupplier/`,
    getAgentRepost: `/api/system/BookingReportAgent/`,
    getSellerRepost: `/api/system/BookingReportSeller/`,
    getCustomerRepost: `/api/system/BookingReportCustomer/`,

    getSupplierBookingPaymentInfoList: `/api/bookings/getStatisticBookingPaymentInfoListBySupplier/`,
    getAccoutingHistoryList: `/api/account_histories/paging`,
    withdrawMoney: `/api/account_histories/postTransferByAdmin/`,

    getLocationServicesList: `/api/location_services/paging/`,
    createLocationServices: `/api/location_services/`,
    updateLocationServices: `/api/location_services/update/`,

    getZoneList: `/api/zones/paging`,
    createZone: `/api/zones`,
    updateZone: `/api/zones/update`,

    getCountryList: `/api/conutries/paging`,
    createCountry: `/api/conutries`,
    updateCountry: `/api/conutries/update`,

    getSpecialCommissions: `/api/special_commissions/paging/`,
    createSpecialCommissions: `/api/special_commissions/`,
    updateSpecialCommissions: `/api/special_commissions/update/`,

    getDefaultCommissions: `/api/default_commissions/paging/`,
    createDefaultCommissions: `/api/default_commissions/`,
    updateDefaultCommissions: `/api/default_commissions/update/`,

    getRefundReasonsList: `/api/refund_policies/paging`,
    createRefundReasons: `/api/refund_policies`,
    updateRefundReasons: `/api/refund_policies/update`,

    getAnnouncementList: `/api/announcements/paging`,
    createAnnouncement: `/api/announcements`,
    updateAnnouncement: `/api/announcements/update`,

    APIRefund: `/api/system/bookingRefundBackend`,
    getBookingWithPackageId: `/api/bookings/getBookingListByPackageId`,

    getWithDrawRequestList: `/api/account_histories/getWithdrawRequestData`,
    getRefundRequestList: `/api/refunds/getRefundRequestData`,
    getRefundHistoryList: `/api/refunds/paging`,
    getAccountDetail: `/api/account_histories/getAccountDetail`,

    updateRefund: `/api/system/changeRefundStatusBooking`,
    requestTransferBySeller: `/api/account_histories/requestTransferBySeller`,
    requestTransferBySupplier: `/api/account_histories/requestTransferBySupplier`,
    updateAccountStatus: `/api/account_histories/updateAccountStatus`,

    getPackageCategory: `/api/system/getPackageCategory/paging`,
    createPackageCategory: `/api/system/createPackageCategory`,
    updatePackageCategory: `/api/system/updatePackageCategory`

    // =============================================================================================================================================== 
};

// ===================================================================================================================================
