
import { BookingStatus, TransactionStatus, RequestStatus } from '../common/StatusBooking.js';
const colorStatus = { 'in-process': '#4A90E2', 'approved': '#417505', 'cancel': '#EA4F48', 'reject': '#EA4F48' }

export function GetStatusColor(_name, _style, _status) {
    switch (_name) {
        case 'Booking': {
            switch (_status) {
                case BookingStatus.INPROCESS: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#4A90E2', border: _style.border };
                case BookingStatus.ABANDON:
                case BookingStatus.COMPLETE:
                case BookingStatus.APPROVED: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#08960e', border: _style.border };
                case BookingStatus.ONDELIVERY: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#08960e', border: _style.border };
                case BookingStatus.FAILED:
                case BookingStatus.CANCEL: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#B81807', border: _style.border };
                case BookingStatus.REJECT: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#B81807', border: _style.border };
                case BookingStatus.REFUND: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#EA4F48', border: _style.border };
                default: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', border: _style.border };
            }
        }
        case 'Transaction': {
            switch (_status) {
                case BookingStatus.INPROCESS: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#4A90E2', border: _style.border };
                case TransactionStatus.ABANDON: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#417505', border: _style.border };
                case TransactionStatus.COMPLETE: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#08960e', border: _style.border };
                case TransactionStatus.APPROVED: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#ff8528', border: _style.border };
                case BookingStatus.ONDELIVERY: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#FF9800', border: _style.border };
                case BookingStatus.CANCEL: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#B81807', border: _style.border };
                case BookingStatus.REJECT: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#B81807', border: _style.border };
                case BookingStatus.REFUND: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#EA4F48', border: _style.border };
                default: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', border: _style.border };
            }
        }
        case 'Request': {
            switch (_status) {
                case RequestStatus.REQUEST: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#e89b17', border: _style.border };
                case RequestStatus.INPROCESS: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#4A90E2', border: _style.border };
                case RequestStatus.SUCCESS: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#08960e', border: _style.border };
                case RequestStatus.REFUSE: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#EA4F48', border: _style.border };
                case RequestStatus.FAILED: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', color: '#B81807', border: _style.border };
                default: return { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', border: _style.border };
            }
        }
    }
}
