import axios from 'axios';
import CONFIG from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_ANNOUNCEMENT_LIST_SUCCESS = 'GET_ANNOUNCEMENT_LIST_SUCCESS';
export const GET_ANNOUNCEMENT_LIST_FAILURE = 'GET_ANNOUNCEMENT_LIST_FAILURE';

export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS';
export const CREATE_ANNOUNCEMENT_FAILURE = 'CREATE_ANNOUNCEMENT_FAILURE';

export const UPDATE_ANNOUNCEMENT_SUCCESS = 'UPDATE_ANNOUNCEMENT_SUCCESS';
export const UPDATE_ANNOUNCEMENT_FAILURE = 'UPDATE_ANNOUNCEMENT_FAILURE';



// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// getAnnouncementList =====================================================================================================================

export function getAnnouncementList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getAnnouncementList :", `${CONFIG.LBhost}${CONFIG.api.getAnnouncementList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAnnouncementList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getAnnouncementList : ", response);
            localStorage.setItem('AnnouncementList', response.data)
            dispatch(getAnnouncementList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAnnouncementList Failure", error);
            dispatch(getAnnouncementList_Failure(error));
        })
    }
}

function getAnnouncementList_Success(data) {
    return { type: GET_ANNOUNCEMENT_LIST_SUCCESS, payload: data };
}

function getAnnouncementList_Failure(error) {
    return { type: GET_ANNOUNCEMENT_LIST_FAILURE, payload: error };
}

// createAnnouncement ========================================================================================================================


export function APICreateAnnouncement(confirmInfo) {

    console.log('APICreateAnnouncement : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createAnnouncement}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateAnnouncement : ", response.data);
            dispatch(APICreateAnnouncement_Success(response.data));

        }).catch((error) => {
            console.log("APICreateAnnouncement Failure", error);
            dispatch(APICreateAnnouncement_Failure(error));
        })
    }
}

function APICreateAnnouncement_Success(data) {
    return { type: CREATE_ANNOUNCEMENT_SUCCESS, payload: data };
}

function APICreateAnnouncement_Failure(error) {
    return { type: CREATE_ANNOUNCEMENT_FAILURE, payload: error };
}

// updateAnnouncement =========================================================================================================================

export function APIUpdateAnnouncement(confirmInfo) {
    console.log('APIUpdateAnnouncement : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateAnnouncement}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { announcementId: confirmInfo.announcementId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateAnnouncement : ", response.data);
            dispatch(APIUpdateAnnouncement_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateAnnouncement Failure", error);
            dispatch(APIUpdateAnnouncement_Failure(error));
        })
    }
}

function APIUpdateAnnouncement_Success(data) {
    return { type: UPDATE_ANNOUNCEMENT_SUCCESS, payload: data };
}

function APIUpdateAnnouncement_Failure(error) {
    return { type: UPDATE_ANNOUNCEMENT_FAILURE, payload: error };
}
