import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { Family, Colors, RequireForm } from '../../themes';
import { isMobile } from '../DeviceCheck'

class Select extends Component {

    render() {
        const { classes, disabled, error, TitleText, value, style, onChange, placeholder, options, Require, renderLabel } = this.props;
        return (
            <React.Fragment>
                <TextField select className={classes.textField}
                    disabled={disabled}
                    error={error}
                    label={Require ? <div> {RequireForm()}{TitleText} </div> : TitleText}
                    value={value || ''}
                    style={style}
                    onChange={onChange}
                    SelectProps={{ native: true }}
                    InputProps={{ classes: { input: classes.textInput }, }}
                    InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} >
                    <option value=""> {placeholder || ''} </option>
                    {options}
                </TextField>
                <div className={classes.render}>{renderLabel ? `${'(' + renderLabel + ')'}` : null}</div>
            </React.Fragment>
        )
    }
}
const styles = theme => (
    {
        render: {
            position: 'absolute',
            top: '46px',
            left: '15px',
            fontFamily: Family.roboto,
            fontSize: 16,
            color: Colors.themeDark,
        },
        textInput: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: 14,
            marginTop: '5px',
            padding: '8px 15px',
            border: `${'1px solid' + Colors.border}`,
            borderRadius: '0px',
            backgroundColor: Colors.white,
            color: Colors.themeDark,
        },
        textField: { width: '100%', margin: '5px 0px 0px' },
        textFormLabel: { fontSize: 16, color: Colors.themeDark, fontWeight: 300, },
    }
)
Select.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(Select);