import React, { Component, } from 'react';
import { withRouter } from 'react-router';

import { SetDate } from '../../common/SetDate';
import { Metrics, Colors, Family } from "../../themes";
import { isMobile, isTablet } from '../../common/DeviceCheck';

import TotalPanel from './TotalPanel';
import BuyerInfoPanel from './BuyerInfoPanel';
import PriceDetailPanel from '../../componets/PriceDetailPanel.jsx';

class BookingInfoPanel extends Component {

    render() {

        const { _bookingInfo } = this.props;
        const { priceTotal, packageInfo, buyerInfo, userInfo, } = _bookingInfo;
        const { promotion, price, foreignPrice, isPromotion, bookingInfo, foreignInfo, } = packageInfo[0];
        const _price = (isPromotion) ? promotion : price;
        const _isMobile = isMobile();

        const stylePanel = _isMobile ? style.mobile_panel : style.desktop_panel;
        const styleDisplay = { width: '100%', height: '100%', display: _isMobile ? 'grid' : 'flex', borderTop: `${'5px solid' + Colors.theme}`, }

        // console.log('BookingInfoPanel : ', _bookingInfo);
        // console.log('BookingInfoPanel : ', promotion,' : ',bookingInfo,' : ',_price,' : ',isPromotion);

        return (
            <React.Fragment>
                <div style={styleDisplay}>
                    <div style={{ flex: 0.99, borderLeft: `${'1px solid' + Colors.border}`, borderRight: `${'1px solid' + Colors.border}` }}>
                        <BuyerInfoPanel buyerInfo={buyerInfo} userInfo={userInfo} />
                    </div>
                    <div style={stylePanel}>
                        <PriceDetailPanel _price={_price} _foreignPrice={foreignPrice} bookingInfo={bookingInfo} foreignInfo={foreignInfo} />
                        <TotalPanel priceTotal={priceTotal} />
                    </div>
                </div>
                {this.SpecialRequestPanel(buyerInfo, _isMobile)}
            </React.Fragment>
        );
    }

    SpecialRequestPanel = (buyerInfo, isMobile) => {

        const stylePanel = isMobile ? { padding: '20px 0px 20px 20px', width: '95%' } : { padding: '20px 20px 20px 50px', width: '100%', textAlign: 'left' };
        const { specialRequest, request, pickupDetail, pickupNote, roomNo } = buyerInfo;
        const { customerRequests = [], AppoiontmentList = [] } = this.props;

        let requestInfo = null;
        let pickUpInfo = null;
        customerRequests.filter((val) => val.requestId === request).map(each => requestInfo = each.langugeENG.requestName)
        AppoiontmentList.filter((val) => val.appointmentId === pickupDetail).map(each => pickUpInfo = each.EN.name)

        return (
            <div style={{ backgroundColor: Colors.themeDark_Light, width: '100%', height: '100%', display: 'flex', fontFamily: Family.roboto, borderBottom: `${'5px solid ' + Colors.theme}`, borderTop: `${'5px solid ' + Colors.theme}` }}>
                <div style={stylePanel}>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Room No : `}</label>
                        <label style={style.priceInfo_lable}>{`${roomNo || '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Pickup Detail : `}</label>
                        <label style={style.priceInfo_lable}>{`${pickUpInfo || '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Pickup Note : `}</label>
                        <label style={style.priceInfo_lable}>{`${pickupNote || '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Request : `}</label>
                        <label style={style.priceInfo_lable}>{`${requestInfo || '-'}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Special Request : `}</label>
                        <label style={style.priceInfo_lable}>{`${specialRequest || '-'}`}</label>
                    </div>
                </div>
            </div >

        )
    }
}

export default withRouter(BookingInfoPanel);

const style =
{
    summary_container:
    {
        width: '100%',
        backgroundColor: Colors.white,
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '20px',
    },
    content_container:
    {
        height: '100%',
        width: '100%',
        maxWidth: '1024px',
    },
    mobile_panel: { flex: 7, borderRight: `${'0px solid' + Colors.border}`, padding: '20px 20px 20px 20px' },
    desktop_panel: { flex: 1, borderRight: `${'1px solid' + Colors.border}`, padding: '20px 20px 20px 50px' },
    packege_container: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
    },
    label_detail: {
        height: '31px',
        // width: '20%',
        color: Colors.themeDark,
        paddingRight: 5,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
    },
    priceInfo_lable: {
        minHeight: '31px',
        width: '85%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'left',
    },
};