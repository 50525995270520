export const constraints = {
    currencyRate: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    currencyTypeFrom: {
        presence: {
            allowEmpty: false,
            message: "can't be blank"
        }
    },
    currencyTypeTo: {
        presence: {
            allowEmpty: false,
            message: "can't be blank"
        }
    },

};
export const input = [
    { fieldName: 'currencyRate', },
    { fieldName: 'currencyTypeFrom', },
    { fieldName: 'currencyTypeTo', },

];
