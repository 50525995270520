import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { path, equals, keys, omit } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import validate from "validate.js";

import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/LocationService';
import LocationService from '../../constant/JSON/LocationService';
import Language from '../../constant/Language';
import Avatar from '../../constant/Avatar';
import { Districts } from '../../constant/JSON/District';
import { Province } from '../../constant/JSON/Province';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Fonts } from '../../themes';
import FormTypes from '../../common/FormTypes';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import * as LocationActions from '../../actions/Actions_LocationServicesManager';
import * as UploadActions from '../../actions/Actions_UploadManager';

import CONFIG from '../../config';

let waitingTime = true;

// const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex', width: '100%' };

class LocationCard extends Component {
    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }

    constructor(props) {
        super(props);
        this.state = {
            LocationInfo: this.props.LocationServicesInfo,
            oldLocationInfo: this.props.oldLocationServicesInfo,
            isUpdate: false,
        }

    }
    componentWillReceiveProps = (nextProps) => {
        const { LocationServicesManager, UpLoadManager } = nextProps;

        if (!!LocationServicesManager) {
            const { actions, data } = LocationServicesManager.LOCATIONSERVICES_REDUCER;

            switch (actions) {
                case LocationActions.CREATE_LOCATIONSERVICES_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Location Success.', dialogType: 'success', });
                        this.setState({ locationServiceId: data.locationServiceId, isUpdate: true })
                        this.props.getAPILocationServicesList({ num_limit: 10 });
                    } break;

                case LocationActions.UPDATE_LOCATIONSERVICES_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Location Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPILocationServicesList({ num_limit: 10 });
                    } break;

                case LocationActions.CREATE_LOCATIONSERVICES_FAILURE:
                case LocationActions.UPDATE_LOCATIONSERVICES_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, locationServiceId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let LocationInfo = { ...this.state.LocationInfo };
                        LocationInfo.picture = data.result.upload.images;
                        if (isEdit) {
                            this.props.APIUpdateLocationServices(LocationInfo)
                        } else if (isUpdate) {
                            LocationInfo = { ...LocationInfo, locationServiceId: locationServiceId }
                            this.props.APIUpdateLocationServices(LocationInfo)
                        } else {
                            this.props.APICreateLocationServices(LocationInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }
    render() {
        const { classes, onClosePanel, isView, LocationServicesList, isEdit } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive, LocationInfo } = this.state;
        const LanguageLocation = path(['language'], LocationServicesList) || 'EN';
        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? LocationService.HeaderPage.UPDATE : LocationService.HeaderPage.EN}</Typography>
                    <Language LanguagePage={LanguageLocation} onChangeState={this.props.onChangeLanguage} isValue={true} />
                </div>}
                {LocationService.BodyPage.map((row, index) => {
                    return (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={{ textAlign: 'start', width: '100%', paddingTop: 5, width: each.width ? each.width : '100%', margin: each.margin ? each.margin : 0 }}>{this.SetItemsWithType(each, LanguageLocation)}</div>))}
                        </div>
                    )
                })}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive} onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        )
    }

    SetItemsWithType(each, LanguageLocation) {

        const { classes, isView, CountryList = [] } = this.props;
        const { oldLocationInfo, LocationInfo } = this.state;
        const valueLanguage = path([LanguageLocation, each.stateName], LocationInfo);
        // console.log('CountryList', CountryList);
        switch (each.type) {
            case 'text': {
                let tag = each.stateName === 'tag'
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={undefined}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={tag ? path([each.stateName], LocationInfo) : valueLanguage}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={6}
                        disabled={isView ? true : false}
                        error={undefined}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], LocationInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
            case 'image': {
                return (
                    <div className={classes.textDefalut} >
                        {isView ?
                         <div className={classes.avatar} 
                         style={{
                             backgroundSize: 'cover',
                             backgroundColor:Colors.lightGrey , 
                             backgroundImage:`url("${CONFIG.host}${LocationInfo.picture.thumbnail}")`}} 
                         />
                        //  <img className={classes.avatar} src={`${CONFIG.host}${LocationInfo.picture.thumbnail}`} />
                            :
                            <Avatar ref='avatar' editable={true}
                            onRemove={() => this.onRemoveImage()}
                                onChange={(files) => { this.onChangeState(each.stateName, files) }}
                                avatar={Boolean(LocationInfo.picture.thumbnail) ? `${CONFIG.host}${LocationInfo.picture.thumbnail}` : ''} />
                        }
                    </div>
                )
            }
            case 'code': {
                let TypeProvince = LocationInfo.locationType === 'province';
                let TypeDistrict = LocationInfo.locationType === 'district';
                let Typesubdistrict = LocationInfo.locationType === 'subdistrict';
                return (
                    <FormTypes.Select
                        disabled={(!TypeProvince && !TypeDistrict && !Typesubdistrict) || isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        style={{ marginTop: (!TypeProvince && !TypeDistrict && !Typesubdistrict) && '20px' }}
                        Require={(!TypeProvince && !TypeDistrict && !Typesubdistrict) || isView ? false : each.require}
                        TitleText={TypeProvince ? each.province_EN : TypeDistrict ?
                            each.district_EN : Typesubdistrict ? each.subdistrict_EN : null}
                        value={path([each.stateName], LocationInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)}
                        options={
                            TypeProvince ?
                                Array.isArray(Province) && Province.map((option, i) => { return (<option key={i} value={option.code}> {option.name_EN} </option>) })
                                : TypeDistrict ?
                                    Array.isArray(Districts) && Districts.map((option, i) => { return (<option key={i} value={option.code}> {option.name_EN} </option>) })
                                    : null
                        }
                    />
                )
            }
            case 'select': {
                let country = each.stateName === 'country';
                return (
                    <FormTypes.Select
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], LocationInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={
                            (country) ?
                                CountryList.map((option, i) => { return (<option key={i} value={option.countryId}> {option[LanguageLocation].name} </option>) })
                                :
                                Array.isArray(each.options) && each.options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN} </option>) })
                        } />
                )
            }
        }
    }

    onChangeState = (fieldName, state) => {
        let LocationInfo = { ...this.state.LocationInfo };
        const { oldLocationInfo } = this.state;
        const LanguageLocation = path(['language',], this.props.LocationServicesList);

        switch (fieldName) {
            case "locationType": {
                LocationInfo = { ...LocationInfo, code: '', [fieldName]: state }
            }
            case "name":
            case "address":
            case "description": {
                LocationInfo = { ...LocationInfo, [LanguageLocation]: { ...LocationInfo[LanguageLocation], [fieldName]: state, } }

            } break;
            default: { LocationInfo = { ...LocationInfo, [fieldName]: state, } }
        }
        console.log('LocationInfo success', LocationInfo)
        this.setState({ LocationInfo, isBtnActive: !equals(oldLocationInfo, LocationInfo) }, () => { this.validate({ [fieldName]: state }); });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.LocationInfo[each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onRemoveImage = () => {
        const { LocationInfo } = this.state;
        this.setState({ LocationInfo: { ...LocationInfo, picture: { original: "", thumbnail: "" } }, isBtnActive: true })
        this.refs.avatar.remove('file');
    }

    onSave = () => {

        let LocationInfo = { ...this.state.LocationInfo }
        const { isEdit } = this.props;
        const { isUpdate, locationServiceId } = this.state;
        const newAvatar = this.refs.avatar.getAvatar('file');

        if (this.validateAll()) {
            if (!!newAvatar) {
                this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadImage(newAvatar));
            }
            else {
                this.setState({ isLoading: true, isBtnActive: false });

                if (isEdit) {
                    this.props.APIUpdateLocationServices(LocationInfo);
                }
                else if (isUpdate) {
                    LocationInfo = { ...LocationInfo, locationServiceId: locationServiceId };
                    this.props.APIUpdateLocationServices(LocationInfo);
                }
                else {
                    this.props.APICreateLocationServices(LocationInfo);
                }
            }
        }
        else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error);
        }
    }

    APIUpLoadImage = (newAvatar) => {
        let formdata = new FormData();
        formdata.append('imgThumbnailWidth', 200);
        formdata.append('imgThumbnailHeight', 200);
        formdata.append("images", newAvatar);
        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
});

function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {
        getAPILocationServicesList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(LocationActions.getLocationServicesList(_keyword, _filter));
        },
        APICreateLocationServices: (_confirmInfo) => {
            dispatch(LocationActions.APICreateLocationServices(_confirmInfo));
        },
        APIUpdateLocationServices: (_confirmInfo) => {
            dispatch(LocationActions.APIUpdateLocationServices(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}
LocationCard.propTypes = { classes: PropTypes.object.isRequired };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationCard))