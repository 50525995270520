import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';

import { PathName } from '../constant';

import DialogAction from '../componets/DialogAction';
import DialogPanel from '../componets/DialogPanel.jsx';
import AccountingStatistic from '../containers/Accounting/AccountingStatistic.jsx';
import AccountingHistoryListPanel from '../containers/Accounting/AccountingHistoryListPanel.jsx';
import TransactionHistoryListPanel from '../containers/TransactionHistory/TransactionHistoryListPanel';
import * as AccountingActions from '../actions/Actions_Accounting.js';

import { Roles } from '../constant/JSON/Roles.js';
import { isMobile, isTablet } from '../common/DeviceCheck.js';
import { BookingStatus } from '../common/StatusBooking.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';
import '../App.css';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let withDrawHistoryList = [];

// let AccountDetailList = [];
// let AccountHistoryList = [];
// let _bookingList = [];

let _userInfo;

let _info = JSON.parse(localStorage.getItem('UserInfo'));
let _role = (!!_info) ? (_info.userType || '') : '';

class AccountingManage extends Component {

    state = { isLoading: true, isDialog: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {

        super(props);

        _info = JSON.parse(localStorage.getItem('UserInfo'));
        _role = (!!_info) ? (_info.userType || '') : '';

        // console.log('_info', _info);

        switch (_role) {
            case Roles.Supplier:
                {
                    const { supplierInfo } = this.props.location.state;
                    _userInfo = supplierInfo;
                    this.props.getAPIAccountDetail({ serviceId: _userInfo.supplierId, customerType: _role, num_limit: 25 });
                    this.props.getAPIWalletInfoWithId({ findById: _userInfo.supplierId, startDate: '2018-01-01' });
                } break;
            case Roles.Seller:
                {
                    const { sellerInfo } = this.props.location.state;
                    _userInfo = sellerInfo;
                    this.props.getAPIAccountDetail({ serviceId: _userInfo.sellerId, customerType: _role, num_limit: 25 });
                    this.props.getAPIWalletInfoWithId({ findById: _userInfo.sellerId, startDate: '2018-01-01' });
                } break;
            case Roles.Admin:
            default:
                {
                    const _filterStatus = { bookingStatus: [BookingStatus.APPROVED, BookingStatus.REFUND, BookingStatus.ONDELIVERY, BookingStatus.COMPLETE,] };
                    const _filter = { key: 'wallet', orderBy: 'desc', num_limit: 10 };

                    this.props.getAPIAccountDetail({ serviceId: " ", customerType: _role, num_limit: 25 });
                    // this.props.getAPIBookingLists('');
                    // this.props.getAPIgetBankList();
                    this.props.getAPISystemWallet();
                    // this.props.getAPISupplierList(_filter)
                    // this.props.getAPIAgentList(_filter);
                    // this.props.getAPISellerList(_filter);
                    // this.props.getAPICustomerList(_filter);
                    // this.props.getAPIAccoutingHistoryList({ key: 'createDate', orderBy: 'desc', num_limit: 10 });
                };
        }
    }

    componentWillReceiveProps(nextProps) {

        const { AccountingManager, SettingManager, BookingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.GET_SYSTEM_WALLET_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        // const { wallet } = data[0];
                        // console.log(`${actions} : ${wallet}`)
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_WALLET_INFO_WITH_ID_SUCCESS:
                    {
                        // AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        // console.log(`GET_WALLET_INFO_WITH_ID_SUCCESS : `, data.results);
                        SetStateWithTime(this, waitingTime);
                    } break;
                // case AccountingActions.GET_ACCOUNT_DETAIL_SUCCESS:
                //     {
                //         AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                //         AccountDetailList = data.result;
                //         SetStateWithTime(this, waitingTime);
                //     } break;
                // case AccountingActions.GET_ACCOUNT_DETAIL_FAILURE:
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_SUCCESS:
                    {
                        // AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        withDrawHistoryList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE:
                case AccountingActions.GET_SYSTEM_WALLET_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.REQUEST_TRANSFER_BY_SELLER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'REQUEST WITHDRAW SUCCESS.', dialogType: 'success', });
                        this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 10, where: { "userAssigneeId": _userInfo.sellerId, } });

                    } break;
                case AccountingActions.REQUEST_TRANSFER_BY_SELLER_FAILURE:
                    {
                        console.log("SEND REQUEST WITHDRAW_FAILURE : ", data);
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;


            }
        }

        // if (!!SettingManager) {
        //     const { actions, data } = SettingManager.SETTING_REDUCER;
        //     // console.log('BookBankList', SettingManager)

        //     switch (actions) {
        //         case BankActions.GET_BANKLIST_SETTING_SUCCESS:
        //             {
        //                 BookBankList = data.result;
        //                 SettingManager.actions = BankActions.WATING_STATE;
        //                 // console.log('BookBankList', BookBankList)
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //         case BankActions.GET_BANKLIST_SETTING_FAILURE:
        //             {
        //                 SettingManager.actions = BankActions.WATING_STATE;
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //     }
        // }
        // if (!!BookingManager) {
        //     const { actions, data } = BookingManager.BOOKING_REDUCER;
        //     // console.log('BookBankList', SettingManager)

        //     switch (actions) {
        //         case BookingAction.GET_BOOKING_LISTS_SUCCESS:
        //             {
        //                 _bookingList = data;
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //         case BookingAction.GET_BOOKING_LISTS_FAILURE:
        //             {
        //                 SetStateWithTime(this, waitingTime);
        //             } break;
        //     }
        // }
    }

    componentWillUnmount() {

    }

    render() {

        const { isLoading, isDialog, isWithdraw, msgError, dialogType, searchActive, } = this.state;
        const _isMobile = isMobile();

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {/* {(isWithdraw) && <DialogAction Active={isWithdraw} OnClose={this.onClosePanel} AcrionCom={this.WithDrawPanel()} />} */}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel(_isMobile)}
                    </div>
                </List>
            </div>);
    }

    contentPanel(_isMobile) {

        const { isLoading, orderBy, isWithdraw } = this.state;
        // console.log('withDrawHistoryList', withDrawHistoryList);
        switch (_role) {

            case Roles.Supplier:
                {
                    return (
                        <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                            <AccountingStatistic isLoading={isLoading} Role={_role} isMobile={_isMobile} DisplayPage={PathName.DashBoardPage} />
                            <div style={{ marginTop: 15 }} />
                            {(!_isMobile) &&
                                <AccountingHistoryListPanel
                                    Header={`Withdraw History List`}
                                    withDrawHistoryList={withDrawHistoryList}
                                    userInfo={_userInfo} Role={_role}
                                />}
                            <div style={{ marginTop: 15 }} />
                        </div>
                    )
                }
            case Roles.Seller:
                {
                    return (
                        <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                            <AccountingStatistic isLoading={isLoading} Role={_role} isMobile={_isMobile} DisplayPage={PathName.DashBoardPage} />
                            <div style={{ marginTop: 15 }} />
                            {(!_isMobile) &&
                                <AccountingHistoryListPanel
                                    Header={`Withdraw History List`}
                                    withDrawHistoryList={withDrawHistoryList}
                                    userInfo={_userInfo} Role={_role}
                                />}
                            <div style={{ marginTop: 15 }} />
                            <TransactionHistoryListPanel
                                // AccountDetailList={AccountDetailList} 
                                AccountDetail={() => { }} orderBy={orderBy}
                                isMobile={_isMobile} userInfo={_userInfo} Role={_role} />
                        </div>
                    )
                }
            case Roles.Admin:
            default:
                {
                    return (
                        <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                            <AccountingStatistic isLoading={isLoading} Role={_role} DisplayPage={PathName.DashBoardPage} />
                            <TransactionHistoryListPanel
                                // AccountDetailList={AccountDetailList} 
                                AccountDetail={() => { }}
                                orderBy={orderBy}
                                isMobile={_isMobile}
                                serInfo={_userInfo} Role={_role} />
                            {/* <AccountingHistoryListPanel Header={`Withdraw History List`} SearchType='history' /> */}
                            {/* <AccoutingSupplierListPanel Header={`Supplier Accounting List`} SearchType={Roles.Supplier} BookBankList={BookBankList} />
                            <AccountingAgentListPanel Header={`Agent Accounting List`} SearchType={Roles.Agent} BookBankList={BookBankList} />
                            <AccountingSellerListPanel Header={`Seller Accounting List`} SearchType={Roles.Seller} BookBankList={BookBankList} />
                            <AccountingCustomerListPanel Header={`Customer Accounting List`} SearchType={Roles.Customer} BookBankList={BookBankList} /> */}
                        </div>
                    )
                };
        }

    }
    // WithDrawPanel = () => {
    //     const { receivedWallet, wallet } = _userInfo;
    //     // console.log('withDrawInfo', withDrawInfo);
    //     const infoLists = [
    //         { title: 'Wellet : ', value: `${NumberFormats(wallet || 0)} Bath` },
    //         { title: 'Received Wallet : ', value: `${NumberFormats(receivedWallet || 0)} Bath` },
    //     ]
    //     const structure = [
    //         [{
    //             title_EN: 'Amount WithDraw', title_TH: '', title_CN: '',
    //             type: 'number',
    //             stateName: 'amount',
    //             require: true,
    //         }, {
    //             title_EN: 'Amount WithDraw All', title_TH: '', title_CN: '',
    //             type: 'checkbox',
    //             stateName: 'isAmountAll',
    //             width: '60%',
    //             require: false,
    //         }], [{
    //             title_EN: 'Remark', title_TH: '', title_CN: '',
    //             type: 'text-multiline',
    //             stateName: 'remark',
    //             require: false,
    //         }],
    //     ]

    //     return (
    //         <RequestPanel Header={'WithDraw Request'} Info={_userInfo} InfoLists={infoLists} onClosePanel={this.onClosePanel} Structure={structure} onSavePanel={this.onSavePanel} />
    //     )
    // }
    // CreatePanel = (_isActive) => () => {
    //     this.setState({
    //         isWithdraw: !_isActive,
    //     })
    // }
    // onSavePanel = (_info, _value) => {
    //     // console.log('onSavePanel', _info, _value);
    //     const { refundId } = _info;
    //     const { remark, status } = _value;

    //     let newData;

    //     if (remark && status) {
    //         newData = {
    //             refundId: refundId,
    //             remark: remark,
    //             refundStatus: status
    //         }
    //         console.log('newData', newData);
    //         this.setState({ isDialog: true }, () => this.props.APIUpdateRefund(newData))
    //     } else {
    //         this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error' })
    //     }
    // }
    // onSavePanel = (_info, _value) => {
    //     console.log('onSavePanel', _info, _value);
    //     console.log('sellerId', _info.sellerId);
    //     const { sellerId } = _info;
    //     const { amount, remark } = _value;
    //     let newData;
    //     if (amount) {
    //         newData = {
    //             sellerId: sellerId,
    //             remark: remark,
    //             amount: amount,
    //         }
    //         console.log('newData', newData);
    //         this.setState({ isDialog: true }, () => this.props.APIRequestTransferBySeller(newData))
    //     } else {
    //         this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error' })
    //     }
    // }
    // onClosePanel = () => { this.setState({ isWithdraw: false }) }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISystemWallet: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.getSystemWallet(_filter));
        },
        getAPIAccoutingHistoryList: (_filter) => {
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
        getAPIWalletInfoWithId: (_filter) => {
            dispatch(AccountingActions.getWalletInfoWithId(_filter));
        },
        // getAPISupplierList: (_keyword, _filter) => {
        //     dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        // },
        // getAPIAgentList: (_keyword, _filter) => {
        //     dispatch(AgentActions.getAgentList(_keyword, _filter));
        // },
        // getAPISellerList: (_keyword, _filter) => {
        //     dispatch(SellerActions.getSellerList(_keyword, _filter));
        // },
        // getAPICustomerList: (_keyword, _filter) => {
        //     dispatch(CustomerActions.getCustomerList(_keyword, _filter));
        // },
        // getAPIgetBankList: (_keyword) => {
        //     InitWaitingTime();
        //     dispatch(BankActions.getBankList(_keyword));
        // },
        // getAPIBookingLists: (_keyword, _filter) => {
        //     InitWaitingTime();
        //     dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        // },
        getAPIAccountDetail: (_filter) => {
            dispatch(AccountingActions.APIAccountDetail(_filter));
        },
        APIRequestTransferBySeller: (_filter) => {
            dispatch(AccountingActions.APIRequestTransferBySeller(_filter));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AccountingManage);
