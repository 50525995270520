import React, { Component } from 'react';

import { path, equals, keys, omit, update } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Avatar from '../../constant/Avatar';
import Seller from '../../constant/JSON/Seller';
import DialogPanel from '../../componets/DialogPanel.jsx';

import { Colors } from '../../themes';
import Button from '../../constant/Button';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FormTypes from '../../common/FormTypes';
import { isMobile } from '../../common/DeviceCheck';
import ButtonText from '../../constant/Button/ButtonText';
import * as validator from '../../constant/Validator/Seller';
import AutoThaiAddress from '../../componets/AutoThaiAddress.jsx';

import CONFIG from '../../config';
import { Roles } from '../../constant/JSON/Roles.js';

const validate = require("validate.js");
const _rowContainer = { display: isMobile() ? 'unset' : 'flex', paddingTop: 5 };

let userInfo = JSON.parse(localStorage.getItem('UserInfo'));

class SellerCard extends Component {

    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }

    constructor(props) {

        userInfo = JSON.parse(localStorage.getItem('UserInfo'));

        super(props);
        const { SellerInfo, isRegister } = this.props;
        this.state = { sellerInfo: (isRegister) ? { ...SellerInfo, level: "DSCI-20181116-000001" } : { ...SellerInfo }, oldSellerInfo: { ...SellerInfo }, isCondition: false };

    }

    render() {

        const { classes, isEdit, isView, isPage, isRegister } = this.props;
        const { isBtnActive, isLoading, msgError, dialogType, isCondition } = this.state;
        const { UPDATE_EN, REGISTER_EN, CREATE_EN } = Seller.HeaderPage;

        // console.log('props', this.props);

        return (
            <div className={classes.root} >
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {(!isView) &&
                        <Typography className={classes.header}>
                            {(isPage) ? null : (isEdit) ? UPDATE_EN : (isRegister) ? REGISTER_EN : CREATE_EN}
                        </Typography>}
                </div>
                {
                    ((isRegister) ? Seller.RegisterPage : Seller.BodyPage).map((row, index) => {
                        return (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => {
                                    let _marginRight = each.margin === 'right';
                                    return (
                                        <div key={index} style={{
                                            position: 'relative',
                                            textAlign: 'start',
                                            width: (each.width) ? (each.width) : '100%',
                                            marginRight: (_marginRight) ? 15 : null
                                        }}>
                                            {this.SetItemsWithType(each)}
                                        </div>)
                                })}
                            </div>)
                    })
                }
                {!isView &&

                    <div style={{ display: 'flex', justifyContent: isMobile() ? 'space-between' : 'flex-end', paddingTop: '15px' }}>
                        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => this.onCancel()} />
                        <Button.Themes TitleText={(isRegister) ? ButtonText.BUTTON_SUBMIT : ButtonText.BUTTON_SAVE}
                            disabled={(isRegister) ? !isCondition : !isBtnActive}
                            onClick={() => { this.onSave() }} />
                    </div>}
            </div>
        );
    }

    SetItemsWithType(each) {

        const { isEdit, isView, isRegister, BookBankList, classes, SellerTypeList = [], Level = [] } = this.props;
        const { sellerInfo, oldSellerInfo, isCondition } = this.state;

        const isError = !!path([each.stateName], this.error);
        const RoleSeller = !!userInfo && userInfo.userType === Roles.Seller;
        const disibleState = each.disibleState && RoleSeller;


        let options = (each.options) ? each.options : [];
        let levelList = Array.isArray(path(['datas'], Level)) ? path(['datas'], Level).filter((val) => val.actived) : [];

        switch (each.type) {
            case "text": {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], sellerInfo) || path(['address', each.stateName], sellerInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "email": {
                return (
                    <FormTypes.FormEmail
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], sellerInfo) || ''}
                        onChange={(value) => { this.onChangeState(each.stateName, value) }} />
                )
            }
            case "number": {

                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], sellerInfo) || path(['address', each.stateName], sellerInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "telephone": {
                return (
                    // <FormTypes.TextTelephone
                    //     disabled={isView ? true : false}
                    //     error={this.error}
                    //     Require={isView ? false : each.require}
                    //     TitleText={each.title_EN}
                    //     value={path([each.stateName], sellerInfo) || ''}
                    //     onChange={(name, value) => { this.onChangeState(name, value) }} />
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], sellerInfo) || path(['address', each.stateName], sellerInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "select": {
                let seller_type = each.stateName === 'type'
                let level_type = each.stateName === 'level'

                return (
                    <FormTypes.Select
                        disabled={(isView || disibleState || (isRegister && level_type)) ? true : false}
                        // disabled={(isView) ? true : false}
                        error={isError}
                        Require={(isView || disibleState || (isRegister && level_type)) ? false : each.require}
                        // Require={(isView) ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], sellerInfo) || path(['address', each.stateName], sellerInfo) || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={
                            seller_type ?
                                SellerTypeList.filter((val) => val.visible).map((option, i) => {
                                    return (<option key={i} value={option.sellerTypeId}> {option.name} </option>)
                                })
                                :
                                level_type ?
                                    levelList.map((option, i) => { return (<option key={i} value={option.defaultCommissionId || '-'}> {option.levelName} </option>) })
                                    :
                                    Array.isArray(options) && options.map((option, i) => {
                                        return (<option key={i} value={option.value}> {option.label_EN} </option>)
                                    })
                        } />
                )
            }
            case "search_select": {
                return (
                    <AutoThaiAddress each={each}
                        disabledProvince={isView ? true : false}
                        disabledDistrict={isView ? true : false}
                        provinceId={path(['address', each.stateName_pro], sellerInfo) || ''}
                        districtId={isEdit ? path(['address', each.stateName_dis], sellerInfo) : !equals(path(['address', each.stateName_pro], sellerInfo), path(['address', each.stateName_pro], oldSellerInfo)) ? '' : path(['address', each.stateName_dis], sellerInfo)}
                        onChange={(val) => {
                            if (!!val.district) {
                                this.onChangeState(each.stateName_dis, path(['district', 'code'], val))
                            } else {
                                this.onChangeState(each.stateName_pro, path(['province', 'code'], val))
                            }
                        }} />
                )
            }
            case "select_checkbok": {
                return (
                    <FormTypes.FormBookBank
                        disabled={isView ? true : false}
                        BookBankList={BookBankList}
                        Info={path([each.stateName], sellerInfo)}
                        valueAccount={path(['bank', each.stateName_account], sellerInfo)}
                        onChange={this.onChangeState} />
                )
            }
            case "marketing": {
                let hotel = sellerInfo.type === 'STPD-20181211-000001';
                let social = sellerInfo.type === 'STPD-20181211-000002';
                let item = (hotel) ? each.itemHotel : (social) ? each.itemSocial : [];
                return (
                    <FormTypes.FormMarketing
                        onChange={(fieldName, state) => this.onChangeState(fieldName, state)}
                        Info={sellerInfo}
                        isHotel={(hotel) ? true : false}
                        items={item} />
                )
            }
            case "switch": {
                return (
                    (!isRegister) &&
                    <FormTypes.Switches Left
                        disabled={(isView || disibleState) ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], sellerInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }
            case "image": {
                return (
                    <div style={{ marginTop: 15 }}>
                        {
                            (isView) ?
                                <div className={classes.avatar}
                                    style={{
                                        backgroundSize: 'cover',
                                        backgroundColor: Colors.lightGrey,
                                        backgroundImage: `url("${CONFIG.host}${sellerInfo.logo.thumbnail}")`
                                    }}
                                />
                                // <img className={classes.avatar} src={`${CONFIG.host}${path([each.stateName, 'thumbnail'], sellerInfo)}`} />
                                :
                                <Avatar editable={isView ? false : true} ref='avatar'
                                    onRemove={() => this.onRemoveImage()}
                                    disabled={isView ? true : false}
                                    onChange={(files) => { this.onChangeState(each.stateName, files) }}
                                    avatar={`${CONFIG.host}${path([each.stateName, 'thumbnail'], sellerInfo)}`} />}
                    </div>

                )
            }
            case "checkbox": {
                return (
                    (isRegister) &&
                    <ListItem  >
                        <Checkbox color="primary" style={{ padding: 5, marginLeft: -20 }} checked={isCondition} tabIndex={-1} disableRipple onChange={this.handleToggle(each.stateName)} />
                        <ListItemText style={{ padding: 0 }} >{`${each.title_EN}`}</ListItemText>
                    </ListItem>

                )
            }
        }
    }

    // SetItemsMarketing(each, sellerInfo) {

    //     switch (sellerInfo.type) {
    //         case 'STPD-20181211-000001': {
    //             return (
    //                 <FormTypes.FormMarketing.Hotel
    //                     onChange={(fieldName, state) => this.onChangeState(fieldName, state)}
    //                     Info={sellerInfo}
    //                     items={each.itemHotel} />
    //             )
    //         } break;
    //         case 'STPD-20181211-000002': {
    //             return (
    //                 <FormTypes.FormMarketing.Blogger
    //                     onChange={(fieldName, state) => this.onChangeState(fieldName, state)}
    //                     Info={sellerInfo}
    //                     items={each.itemSocial} />
    //             )
    //         } break;
    //     }
    // }
    handleToggle = name => event => {
        this.setState({ [name]: event.target.checked })
    }

    onRemoveImage = () => {
        const { sellerInfo } = this.state;
        this.setState({ sellerInfo: { ...sellerInfo, logo: { original: "", thumbnail: "" } }, isBtnActive: true })
        this.refs.avatar.remove('file');
    }

    onChangeState = (fieldName, state) => {

        console.log('[onChangeState]', fieldName, state)
        const { oldSellerInfo } = this.state;
        let sellerInfo = { ...this.state.sellerInfo }

        switch (fieldName) {
            case 'accountNumber':
            case 'bankId': {
                sellerInfo = { ...sellerInfo, bank: { ...sellerInfo.bank, [fieldName]: state } }
            } break;
            case 'number':
            case 'code': {
                sellerInfo = { ...sellerInfo, tel: { ...sellerInfo.tel, [fieldName]: state } }
            } break;
            case "no":
            case "road":
            case "province":
            case "district":
            case "country": {
                sellerInfo = { ...sellerInfo, address: { ...sellerInfo.address, [fieldName]: state } }
            } break;
            case "facebook":
            case "instagram":
            case "twitter":
            case "website": {
                sellerInfo = { ...sellerInfo, marketingChannel: { ...sellerInfo.marketingChannel, [fieldName]: state } }
            } break;
            case "name": {
                sellerInfo = { ...sellerInfo, marketingChannel: { ...sellerInfo.marketingChannel, hotel: update(0, { ...sellerInfo.marketingChannel.hotel[0], [fieldName]: state }, sellerInfo.marketingChannel) } }
            } break;
            case "type": {
                sellerInfo = { ...sellerInfo, marketingChannel: { facebook: '', instagram: '', twitter: '', website: '', hotel: [{ name: '' }] } }
            }

            default: {
                sellerInfo = { ...sellerInfo, [fieldName]: state }
            }
        }

        console.log('[sellerInfo success]', sellerInfo)
        this.setState({ sellerInfo, isBtnActive: !equals(oldSellerInfo, sellerInfo) }, () => { this.validate({ [fieldName]: state }) })
    }

    validate = (value) => {

        let result = true;
        const fieldName = keys(value)[0];

        if (!!validator.constraints[fieldName]) {

            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            }
            else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {

        let success = true;
        const { sellerInfo } = this.state;

        validator.input.forEach(each => {
            const validateResult = this.validate({ [each.fieldName]: sellerInfo[each.fieldName] || sellerInfo.tel[each.fieldName], });
            success = success && validateResult;
        });

        return success;
    }

    onSave = () => {

        const { sellerInfo } = this.state;
        const { agentId, isEdit } = this.props;
        const newAvatar = this.refs.avatar.getAvatar('file');

        console.log('[validateAll]', this.validateAll(), this.error)

        if (this.validateAll()) {

            const { APIUpdateSeller, APICreateSeller } = this.props;

            if (agentId) {

                sellerInfo.agentId = agentId;
                this.setState({ isBtnActive: false },
                    (isEdit) ? APIUpdateSeller(newAvatar, sellerInfo) : APICreateSeller(newAvatar, sellerInfo))

            }
            else {
                this.setState({ isBtnActive: false },
                    (isEdit) ? APIUpdateSeller(newAvatar, sellerInfo) : APICreateSeller(newAvatar, sellerInfo))
            }
        }
        else { this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error' }) }
    }

    onCancel = () => { this.props.onClosePanel() }
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

}

const styles = theme => (
    {
        root: { textAlign: 'start', overflow: 'unset', maxWidth: isMobile() ? 'unset' : '800px', },
        header: { fontSize: '24px', display: 'flex', padding: '10px 0px' },
        avatar: { height: '120px', width: '120px', boxShadow: '0 2px 5px 0 hsla(0,0%,47%,.5)', }
    }
);

SellerCard.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

export default withStyles(styles, { withTheme: true })(SellerCard);