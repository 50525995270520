import React from 'react';
import { path } from 'ramda';

import FormTypes from '../../common/FormTypes';

const FormMarketing = (props) => {
    const { items, disabled, error, Require, Info, onChange, value, isHotel } = props;
    return (
        <div style={styles.root}>
            {
                items.map((each, index) => {
                    return (
                        <FormTypes.TextFormat key={index}
                            disabled={disabled}
                            error={error}
                            Require={Require}
                            value={(isHotel) ? path(['marketingChannel', 'hotel', 0, each.stateName], Info) : path(['marketingChannel', each.stateName], Info)}
                            TitleText={each.label_EN}
                            onChange={(e) => onChange(each.stateName, e.target.value)} />
                    )
                })
            }
        </div>
    );
};
export default FormMarketing;

const styles = { root: { margin: '5px 0px' } };