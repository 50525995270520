export const init_country = {
    "countryId": "",
    "TH": {
        "name": "",
        "remark": ""
    },
    "EN": {
        "name": "",
        "remark": ""
    },
    "CN": {
        "name": "",
        "remark": ""
    },
    "code": "",
    "zone": "",
    "picture": {
        "original": '',
        "thumbnail": '',
    },
    "gallery": [],
    "rating": "",
    "visible": false,
}