import React, { Component } from 'react'

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconClose from '@material-ui/icons/HighlightOff';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';

import FormTypes from '../../common/FormTypes';
import { Colors, Family } from '../../themes';
import { Title } from '@material-ui/icons';

class FormText extends Component {
    render() {
        const { TitleText, value, onChange, stateName, disabled, style } = this.props;
       return (
            <Contianer>
                <ListItem style={style} >
                    <Checkbox color="primary" disabled={disabled} checked={value} tabIndex={-1} disableRipple
                        onChange={onChange} />
                    <ListItemText >{`${TitleText}`}</ListItemText>
                </ListItem>
            </Contianer>

        )
    }
}

export default (FormText);
const Contianer = styled.div`
        position: relative;
        width: 97%
        paddingBottom: 10px`;
const ListItem = styled.div`
        display: flex;
        margin-left:-12px;
        padding: 0px;`;
const ListItemText = styled.div`
        display: flex;
        font-family: ${Family.roboto}
        font-size: 13px;
        align-items: center;`;