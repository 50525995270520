
import * as SupplierActions from '../actions/Actions_SupplierManager.js';

const INITIAL_STATE = { SUPPLIER_REDUCER: { actions: SupplierActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: SupplierActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case SupplierActions.INITIAL_STATE:
            {
                return state;
            }
        case SupplierActions.IDLE_STATE:
            {
                //console.log("[ Supplier Package ] : IDLE_STATE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.IDLE_STATE, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_SUCCESS:
            {
                //console.warn("[ Supplier Package ] : GET_SUPPLIER_SUCCESS : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_SUCCESS, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_FAILURE:
            {
                console.log("[ Supplier Package ] : GET_SUPPLIER_FAILURE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_FAILURE, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_INFO_SUCCESS:
            {
                // console.log("[ Supplier Package ] : GET_SUPPLIER_INFO_SUCCESS : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_INFO_SUCCESS, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_INFO_FAILURE:
            {
                console.log("[ Supplier Package ] : GET_SUPPLIER_INFO_FAILURE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_INFO_FAILURE, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_WITHPARENT_ID_SUCCESS:
            {
                //console.log("[ Supplier Package ] : GET_SUPPLIER_WITHPARENT_ID_SUCCESS : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_WITHPARENT_ID_SUCCESS, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_WITHPARENT_ID_FAILURE:
            {
                console.log("[ Supplier Package ] : GET_SUPPLIER_WITHPARENT_ID_FAILURE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_WITHPARENT_ID_FAILURE, data: payload, error: _error } };
            }
        case SupplierActions.CREATE_SUPPLIER_SUCCESS:
            {
                // console.log("[ Supplier Package ] : CREATE_SUPPLIER_SUCCESS : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.CREATE_SUPPLIER_SUCCESS, data: payload, error: _error } };
            }
        case SupplierActions.CREATE_SUPPLIER_FAILURE:
            {
                console.log("[ Supplier Package ] : CREATE_SUPPLIER_FAILURE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.CREATE_SUPPLIER_FAILURE, data: payload, error: _error } };
            }
        case SupplierActions.UPDATE_SUPPLIER_SUCCESS:
            {
                //console.log("[ Supplier Package ] : UPDATE_SUPPLIER_SUCCESS : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.UPDATE_SUPPLIER_SUCCESS, data: payload, error: _error } };
            }
        case SupplierActions.UPDATE_SUPPLIER_FAILURE:
            {
                console.log("[ Supplier Package ] : UPDATE_SUPPLIER_FAILURE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.UPDATE_SUPPLIER_FAILURE, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_BOOKING_LIST_SUCCESS:
            {
                // console.log("[ Supplier Package ] : GET_SUPPLIER_BOOKING_LIST_SUCCESS : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_BOOKING_LIST_SUCCESS, data: payload, error: _error } };
            }
        case SupplierActions.GET_SUPPLIER_BOOKING_LIST_FAILURE:
            {
                console.log("[ Supplier Package ] : GET_SUPPLIER_BOOKING_LIST_FAILURE : ", payload);
                return { ...state, SUPPLIER_REDUCER: { actions: SupplierActions.GET_SUPPLIER_BOOKING_LIST_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[ Supplier Package ]  default :", state);
                return state;
            }
    }
}
