export const constraints = {
    name: {
        presence: { allowEmpty: false, },
    },
  
};
export const input = [
    { fieldName: 'name', },
 
];
