import React from 'react';
import { path } from 'ramda';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import CONFIG from '../../config';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Package from '../../constant/JSON/Package';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';

const Supplier = Package.SupplierData;
let display_supplier = null;

class SupplierCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = { searchText: '' }
    }
    render() {
        const { onAcceptonClosePanel, handleToggle, SupplierList, _supplierId, classes, } = this.props;
        const { searchText } = this.state;
        let newSupplier = SupplierList.filter(val => val.visible)

        if (Array.isArray(newSupplier) && newSupplier.length > 0) {
            display_supplier = newSupplier.filter(each => new RegExp(searchText, "i").test(each.name))
        }

        console.log('display_supplier', display_supplier);
        return (
            <div style={{ display: 'inline-block', width: '100%', maxWidth: '1030px', maxHeight: '700px', fontFamily: Family.roboto }}>
                <h2 style={{ color: Colors.themeDark }}>{Supplier.title_EN} </h2>
                <div style={{ paddingBottom: '10px' }}>

                    <TextField id='search' className={classes.textField}
                        value={searchText}
                        onChange={e => this.setState({ searchText: e.target.value })}
                        placeholder={Supplier.placeholder_EN}
                        InputProps={{ disableUnderline: false, classes: { input: classes.textInput } }}
                        InputLabelProps={{ shrink: true, className: classes.textFormLabel }} />
                </div>
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', maxWidth: '800px', height: '400px', paddingBottom: '17px', marginBottom: '15px' }}>
                    {
                        display_supplier.map((value, index) => {
                            const _backgroundImage = { width: '70px', height: '50px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${CONFIG.host}${path(['logo', 'thumbnail'], value)})`, backgroundRepeat: 'round' };
                            return (
                                <ListItem key={index} style={{ paddingLeft: '0px', border: `${'1px solid' + Colors.border}`, margin: '10px 0px' }} dense button
                                    onClick={handleToggle('supplier', value)}>
                                    <Checkbox  color="primary" checked={_supplierId === value.supplierId} tabIndex={-1} disableRipple />
                                    <div style={_backgroundImage} />
                                    <ListItemText style={{ fontSize: '14px' }} primary={`${value.name}`} />
                                </ListItem>
                            )
                        })
                    }
                </List>
                <div style={{ textAlign: 'end', fontSize: '16px', padding: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_OK}
                        disabled={!!_supplierId ? false : true}
                        buttonStyle={{ padding: '12px', marginLeft: '15px' }}
                        onClick={() => onAcceptonClosePanel()} />

                </div>

            </div >
        )
    }
}
const styles = theme => (
    {
        textInput: {
            width: '100%',
            padding: '10px 20px',
            color: Colors.themeDark,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 14,
        },
        textField: { width: '100%', marginTop: 0 },
        textFormLabel: { fontSize: 18, marginTop: -3 },
    }
);

SupplierCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(SupplierCard);
