import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_SPECIAL_COMMISSIONS_SUCCESS = 'GET_SPECIAL_COMMISSIONS_SUCCESS';
export const GET_SPECIAL_COMMISSIONS_FAILURE = 'GET_SPECIAL_COMMISSIONS_FAILURE';

export const CREATE_SPECIAL_COMMISSIONS_SUCCESS = 'CREATE_SPECIAL_COMMISSIONS_SUCCESS';
export const CREATE_SPECIAL_COMMISSIONS_FAILURE = 'CREATE_SPECIAL_COMMISSIONS_FAILURE';

export const UPDATE_SPECIAL_COMMISSIONS_SUCCESS = 'UPDATE_SPECIAL_COMMISSIONS_SUCCESS';
export const UPDATE_SPECIAL_COMMISSIONS_FAILURE = 'UPDATE_SPECIAL_COMMISSIONS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// getAPISpecialList =====================================================================================================================

export function getAPISpecialList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getAPISpecialList :", `${CONFIG.LBhost}${CONFIG.api.getSpecialCommissions}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSpecialCommissions}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getAPISpecialList : ", response);
            localStorage.setItem('SpecialList ', response.data)
            dispatch(getAPISpecialList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAPISpecialList Failure", error);
            dispatch(getAPISpecialList_Failure(error));
        })
    }
}

function getAPISpecialList_Success(data) {
    return { type: GET_SPECIAL_COMMISSIONS_SUCCESS, payload: data };
}

function getAPISpecialList_Failure(error) {
    return { type: GET_SPECIAL_COMMISSIONS_FAILURE, payload: error };
}

// createConditionsList =========================================================================================================================

export function APICreateSpecial(confirmInfo) {

    console.log('APICreateSpecial : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createSpecialCommissions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateSpecial : ", response.data);
            dispatch(APICreateSpecial_Success(response.data));

        }).catch((error) => {
            console.log("APICreateSpecial fail", error);
            dispatch(APICreateSpecial_Failure(error));
        })
    }
}

function APICreateSpecial_Success(data) {
    return { type: CREATE_SPECIAL_COMMISSIONS_SUCCESS, payload: data };
}

function APICreateSpecial_Failure(error) {
    return { type: CREATE_SPECIAL_COMMISSIONS_FAILURE, payload: error };
}

// updateSpecialList =========================================================================================================================

export function APIUpdateSpecial(confirmInfo) {
    console.log('APIUpdateSpecial : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateSpecialCommissions}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { specialCommissionId: confirmInfo.specialCommissionId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateSpecial : ", response.data);
            dispatch(APIUpdateSpecial_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateSpecial fail", error);
            dispatch(APIUpdateSpecial_Failure(error));
        })
    }
}

function APIUpdateSpecial_Success(data) {
    return { type: UPDATE_SPECIAL_COMMISSIONS_SUCCESS, payload: data };
}

function APIUpdateSpecial_Failure(error) {
    return { type: UPDATE_SPECIAL_COMMISSIONS_FAILURE, payload: error };
}