import React, { Component } from 'react'
import { connect } from 'react-redux';
import styled from 'styled-components';

import { path, keys, omit } from 'ramda';
import validate from "validate.js";

import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Refund';
import Refund from '../../constant/JSON/RefundReasons';
import Language from '../../constant/Language';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import FormTypes from '../../common/FormTypes';
import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';

import * as RefundActions from '../../actions/Actions_RefundManager';
import * as UploadActions from '../../actions/Actions_UploadManager';


let waitingTime = true;

const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex' };

class RefundReasonsCard extends Component {
    error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }

    constructor(props) {
        super(props);
        this.state = { RefundInfo: this.props.RefundInfo, isUpdate: false, }
    }

    componentWillReceiveProps = (nextProps) => {
        const { RefundManager, UpLoadManager } = nextProps;

        if (!!RefundManager) {
            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.CREATE_REFUND_REASONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'CREATE_REFUND_REASONS_SUCCESS', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getRefundReasonsList();
                    } break;

                case RefundActions.UPDATE_REFUND_REASONS_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE_REFUND_REASONS_SUCCESS', dialogType: 'success', });
                        this.setState({ refundId: data.refundId, isUpdate: true })
                        this.props.getRefundReasonsList();
                    } break;

                case RefundActions.GET_REFUND_REASONS_FAILURE:
                case RefundActions.UPDATE_REFUND_REASONS_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, refundId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let RefundInfo = { ...this.state.RefundInfo };
                        RefundInfo.picture = data.result.upload.images;
                        if (isEdit) {
                            this.props.APIUpdateRefundReasons(RefundInfo)
                        } else if (isUpdate) {
                            RefundInfo = { ...RefundInfo, refundId: refundId }
                            this.props.APIUpdateRefundReasons(RefundInfo)
                        } else {
                            this.props.APICreateRefundReasons(RefundInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }
    render() {
        const { onClosePanel, isView, RefundReasonsList, isEdit } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive } = this.state;
        const LanguageRefund = path(['language'], RefundReasonsList);

        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView &&
                    <FromLabelContianer>
                        <Typography >{isEdit ? Refund.HeaderPage.UPDATE_EN : Refund.HeaderPage.CREATE_EN}</Typography>
                        <Language LanguagePage={LanguageRefund} onChangeState={this.props.onChangeLanguage} isValue={true} />
                    </FromLabelContianer>
                }
                {
                    Refund.BodyPage.map((row, index) => {
                        return (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each, LanguageRefund)}</div>))}
                            </div>
                        )
                    })
                }
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            buttonStyle={{ margin: 0 }} onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} buttonStyle={{ margin: 0 }}
                            disabled={!isBtnActive} onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        )
    }
    SetItemsWithType(each, LanguageRefund) {

        const { isView } = this.props;
        const { RefundInfo } = this.state;
        const valueLanguage = path([LanguageRefund, each.stateName], RefundInfo);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView && true}
                        error={undefined}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView && true}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], RefundInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
            case "form-checkbox": {
                return (
                    <FormTypes.Checkboxes
                        style={{ paddingTop: 10 }}
                        disabled={isView && true}
                        Items={each.items}
                        StateInfo={RefundInfo}
                        Language={'EN'}
                        handleToggle={(fieldName, each) => this.handleToggle(fieldName, each)}
                    />
                )
            }
        }
    }

    handleToggle = (fieldName, state) => {
        const { RefundInfo } = this.state;

        switch (fieldName) {
            case "isCustomer":
            case "isSupplier":
            case "isAdmin": {
                if (RefundInfo.all) {
                    RefundInfo[state.stateName] = RefundInfo[state.stateName];
                } else {
                    RefundInfo[state.stateName] = !RefundInfo[state.stateName];
                }
            } break;
            case "all": {
                RefundInfo[state.stateName] = !RefundInfo[state.stateName];
            } break;
        }
        this.setState({ isBtnActive: true }, () => this.onChangeState(fieldName, RefundInfo[state.stateName]));

    }
    onChangeState = (fieldName, state) => {
        let RefundInfo = { ...this.state.RefundInfo };
        const LanguageRefund = path(['language',], this.props.RefundReasonsList);

        switch (fieldName) {
            case "reasons": {
                RefundInfo = { ...RefundInfo, [LanguageRefund]: { ...RefundInfo[LanguageRefund], [fieldName]: state, } }
            } break;
            case "all": {
                RefundInfo = { ...RefundInfo, isCustomer: state, isSupplier: state, isAdmin: state }
            } break;
            default: { RefundInfo = { ...RefundInfo, [fieldName]: state, } }
        }
        console.log('RefundInfo success', RefundInfo)
        this.setState({ RefundInfo, isBtnActive: true }, () => { this.validate({ [fieldName]: state }); });
    }


    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        const LanguageRefund = path(['language'], this.props.RefundReasonsList);
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.RefundInfo[LanguageRefund][each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        let RefundInfo = { ...this.state.RefundInfo }
        const { isEdit } = this.props;
        const { isUpdate, refundId } = this.state;
        // const newAvatar = this.refs.avatar.getAvatar('file');
        delete RefundInfo.all;

        if (this.validateAll()) {

            this.setState({ isLoading: true, isBtnActive: false });

            if (isEdit) {
                this.props.APIUpdateRefundReasons(RefundInfo);
            }
            else if (isUpdate) {
                RefundInfo = { ...RefundInfo, refundId: refundId };
                this.props.APIUpdateRefundReasons(RefundInfo);
            }
            else {
                this.props.APICreateRefundReasons(RefundInfo);
            }
        }
        else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error);
        }
    }

    APIUpLoadImage = (newAvatar) => {
        let formdata = new FormData();
        formdata.append('imgThumbnailWidth', 200);
        formdata.append('imgThumbnailHeight', 200);
        formdata.append("images", newAvatar);
        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

function mapStateToProps(state, ownProps) { return { ...state } }

function mapDispatchToProps(dispatch) {
    return {
        getRefundReasonsList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(RefundActions.getRefundReasonsList(_keyword, _filter));
        },
        APICreateRefundReasons: (_confirmInfo) => {
            dispatch(RefundActions.APICreateRefundReasons(_confirmInfo));
        },
        APIUpdateRefundReasons: (_confirmInfo) => {
            dispatch(RefundActions.APIUpdateRefundReasons(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RefundReasonsCard);

const FromLabelContianer = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;` ;
const Typography = styled.div`
        font-family: ${Family.roboto};
        display: flex; 
        padding: 10px 0px;   
        font-size: 24px;
        font-weight: 400;`;