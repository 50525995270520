import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_ACCOUNT_DETAIL_SUCCESS = 'GET_ACCOUNT_DETAIL_SUCCESS';
export const GET_ACCOUNT_DETAIL_FAILURE = 'GET_ACCOUNT_DETAIL_FAILURE';

export const GET_WITHDRAW_REQUEST_LISTS_SUCCESS = 'GET_WITHDRAW_REQUEST_LISTS_SUCCESS';
export const GET_WITHDRAW_REQUEST_LISTS_FAILURE = 'GET_WITHDRAW_REQUEST_LISTS_FAILURE';

export const GET_SYSTEM_WALLET_SUCCESS = 'GET_SYSTEM_WALLET_SUCCESS';
export const GET_SYSTEM_WALLET_FAILURE = 'GET_SYSTEM_WALLET_FAILURE';

export const GET_WALLET_INFO_WITH_ID_SUCCESS = 'GET_WALLET_INFO_WITH_ID_SUCCESS';
export const GET_WALLET_INFO_WITH_ID_FAILURE = 'GET_WALLET_INFO_WITH_ID_FAILURE';

export const GET_ACCOUNTING_HISTORY_LIST_SUCCESS = 'GET_ACCOUNTING_HISTORY_LIST_SUCCESS';
export const GET_ACCOUNTING_HISTORY_LIST_FAILURE = 'GET_ACCOUNTING_HISTORY_LIST_FAILURE';

export const WITHDRAW_MONEY_SUCCESS = 'WITHDRAW_MONEY_SUCCESS';
export const WITHDRAW_MONEY_FAILURE = 'WITHDRAW_MONEY_FAILURE';

export const UPDATE_ACCOUNTING_SUCCESS = 'UPDATE_ACCOUNTING_SUCCESS';
export const UPDATE_ACCOUNTING_FAILURE = 'UPDATE_ACCOUNTING_FAILURE';

export const REQUEST_TRANSFER_BY_SELLER_SUCCESS = 'REQUEST_TRANSFER_BY_SELLER_SUCCESS';
export const REQUEST_TRANSFER_BY_SELLER_FAILURE = 'REQUEST_TRANSFER_BY_SELLER_FAILURE';

export const REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS = 'REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS';
export const REQUEST_TRANSFER_BY_SUPPLIER_FAILURE = 'REQUEST_TRANSFER_BY_SUPPLIER_FAILURE';

export const UPDATE_ACCOUNT_STATUS_SUCCESS = 'UPDATE_ACCOUNT_STATUS_SUCCESS';
export const UPDATE_ACCOUNT_STATUS_FAILURE = 'UPDATE_ACCOUNT_STATUS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getSystemWallet ============================================================================================================================

export function getSystemWallet(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getSystemWallet}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] SystemWallet : ", response.data);
            dispatch(getSystemWallet_Success(response.data));

        }).catch((error) => {
            console.log("[API] getProductsList Failure", error);
            dispatch(getSystemWallet_Failure(error));
        })
    }
}

function getSystemWallet_Success(data) {
    return { type: GET_SYSTEM_WALLET_SUCCESS, payload: data };
}

function getSystemWallet_Failure(error) {
    return { type: GET_SYSTEM_WALLET_FAILURE, payload: error };
}

// getWalletInfoWithId ============================================================================================================================

export function getWalletInfoWithId(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getWalletInfoWithId}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getWalletInfoWithId : ", response.data);
            dispatch(getWalletInfoWithId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getWalletInfoWithId Failure", error);
            dispatch(getWalletInfoWithId_Failure(error));
        })
    }
}

function getWalletInfoWithId_Success(data) {
    return { type: GET_WALLET_INFO_WITH_ID_SUCCESS, payload: data };
}

function getWalletInfoWithId_Failure(error) {
    return { type: GET_WALLET_INFO_WITH_ID_FAILURE, payload: error };
}


// CreateAccounting =========================================================================================================================

// getAccoutingHistoryList ============================================================================================================================

export function getAccoutingHistoryList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAccoutingHistoryList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: { access_token: CONFIG.LB_token, ..._fileterjson },
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] AccountingList : ", response.data);
            dispatch(getAccoutingHistoryList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getProductsList Failure", error);
            dispatch(getAccoutingHistoryList_Failure(error));
        })
    }
}

function getAccoutingHistoryList_Success(data) {
    return { type: GET_ACCOUNTING_HISTORY_LIST_SUCCESS, payload: data };
}

function getAccoutingHistoryList_Failure(error) {
    return { type: GET_ACCOUNTING_HISTORY_LIST_FAILURE, payload: error };
}

// CreateAccounting =========================================================================================================================


export function APIWithdrawMoney(confirmInfo) {

    console.log('APIWithdrawMoney : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.withdrawMoney}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            params: { access_token: CONFIG.LB_token, },
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIWithdrawMoney : ", response.data);
            dispatch(APIWithdrawMoney_Success(response.data));

        }).catch((error) => {
            console.log("APIWithdrawMoney fail", error);
            dispatch(APIWithdrawMoney_Failure(error));
        })
    }
}

function APIWithdrawMoney_Success(data) {
    return { type: WITHDRAW_MONEY_SUCCESS, payload: data };
}

function APIWithdrawMoney_Failure(error) {
    return { type: WITHDRAW_MONEY_FAILURE, payload: error };
}

// APIWithDrawRequestList ============================================================================================================================

export function APIWithDrawRequestList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getWithDrawRequestList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIWithDrawRequestList Success : ", response.data);
            dispatch(APIWithDrawRequestList_Success(response.data));

        }).catch((error) => {
            console.log("[API] WithDrawRequestList Failure", error);
            dispatch(APIWithDrawRequestList_Failure(error));
        })
    }
}

function APIWithDrawRequestList_Success(data) {
    return { type: GET_WITHDRAW_REQUEST_LISTS_SUCCESS, payload: data };
}

function APIWithDrawRequestList_Failure(error) {
    return { type: GET_WITHDRAW_REQUEST_LISTS_FAILURE, payload: error };
}



// APIAccountDetail ============================================================================================================================

export function APIAccountDetail(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAccountDetail}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APIAccountDetail Success : ", response.data);
            dispatch(APIAccountDetail_Success(response.data));

        }).catch((error) => {
            console.log("[API] APIAccountDetail Failure", error);
            dispatch(APIAccountDetail_Failure(error));
        })
    }
}

function APIAccountDetail_Success(data) {
    return { type: GET_ACCOUNT_DETAIL_SUCCESS, payload: data };
}

function APIAccountDetail_Failure(error) {
    return { type: GET_ACCOUNT_DETAIL_FAILURE, payload: error };
}

// RequestTransferBySeller =========================================================================================================================


export function APIRequestTransferBySeller(confirmInfo) {

    console.log('APIRequestTransferBySeller : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.requestTransferBySeller}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIRequestTransferBySeller : ", response.data);
            dispatch(APIRequestTransferBySeller_Success(response.data));

        }).catch((error) => {
            console.log("APIRequestTransferBySeller fail", error);
            dispatch(APIRequestTransferBySeller_Failure(error));
        })
    }
}

function APIRequestTransferBySeller_Success(data) {
    return { type: REQUEST_TRANSFER_BY_SELLER_SUCCESS, payload: data };
}

function APIRequestTransferBySeller_Failure(error) {
    return { type: REQUEST_TRANSFER_BY_SELLER_FAILURE, payload: error };
}



// updateAccountStatus =========================================================================================================================
export function APIUpdateAccountStatus(confirmInfo) {
    console.log('APIUpdateAccountStatus : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateAccountStatus}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            params: { access_token: CONFIG.LB_token, },
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateAccountStatus : ", response.data);
            dispatch(APIUpdateAccountStatus_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateAccountStatus fail", error);
            dispatch(APIUpdateAccountStatus_Failure(error));
        })
    }
}

function APIUpdateAccountStatus_Success(data) {
    return { type: UPDATE_ACCOUNT_STATUS_SUCCESS, payload: data };
}

function APIUpdateAccountStatus_Failure(error) {
    return { type: UPDATE_ACCOUNT_STATUS_FAILURE, payload: error };
}


// RequestTransferBySupplier =========================================================================================================================


export function APIRequestTransferBySupplier(confirmInfo) {

    console.log('APIRequestTransferBySupplier : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.requestTransferBySupplier}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIRequestTransferBySupplier : ", response.data);
            dispatch(APIRequestTransferBySupplier_Success(response.data));

        }).catch((error) => {
            console.log("APIRequestTransferBySupplier fail", error);
            dispatch(APIRequestTransferBySupplier_Failure(error));
        })
    }
}

function APIRequestTransferBySupplier_Success(data) {
    return { type: REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS, payload: data };
}

function APIRequestTransferBySupplier_Failure(error) {
    return { type: REQUEST_TRANSFER_BY_SUPPLIER_FAILURE, payload: error };
}
