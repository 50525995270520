import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Button from '@material-ui/core/Button';

import CONFIG from '../../config';
import { Colors, Svg, Images, Fonts, Family } from '../../themes';

import RowPackagesCard from './RowPackagesCard.jsx';
import ContentPanels from '../../componets/ContentManage/ContentPanel.jsx';


import * as PackageAction from '../../actions/Actions_PackageManager.js';
import './Card.css';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';
let CountryList = [];
let LocationServicesList = [];
let waitingTime = true;
let packgaeList = [];

const Levles = { country: 'country', location: 'location', package: 'pacage' };

class ProductListPanel extends Component {

    state = { isLoading: true, level: Levles.country };

    constructor(props) {

        super(props);
        //this.props.getAPIPackageList({ limit: 10, visible: true });
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager } = nextProps;

        if (!!PackageManager) {

            const { data, actions, error } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        packgaeList = data.packageList || [];
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {

                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { isLoading } = this.state;
        const { onClickHandle, isMobile, LocationServiceId } = this.props;

        console.log('ProductListPanel : ', packgaeList)
        packgaeList = packgaeList.filter((item) => item.locationServiceId === LocationServiceId);

        return (
            <ContentPanels isLoading={isLoading} OnClose={this.onClosePanel} isMobile={isMobile} >
                <GridList cols={2} style={style.gridList} spacing={1}>
                    {(packgaeList.length > 0) && packgaeList.map(info => (
                        <GridListTile style={style.gridTileStyle} key={info.packageId}>
                            <Card onClick={() => onClickHandle(info.packageId)}>
                                <RowPackagesCard key={info.packageId} packageInfo={info} />
                            </Card>
                        </GridListTile>))}
                </GridList>
            </ContentPanels>
        )

    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {

        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPanel);

// export default CountryPanel;

const PanelContiner = styled.div`
width: 100%;
margin-top: 10px;
max-width: 1024px;`;

const CardContianer = styled.div`
height: 189px;	
width: 189px;	
border: 1px solid #818181;	
background-repeat: round;
box-shadow: 0 3px 5px 0 rgba(0,0,0,0.7);
${(props) => props.isDisable && css`-webkit-filter: grayscale(100%);filter: grayscale(100%); cursor:unset;`}`;

const EmptyContianer = styled.div`	
width: '100%';
height: 96px;
cursor: pointer;`;

const InfoContianer = styled.div`	
height: 93px;	
width: '100%';	
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, #000000 100%);`;

const LableContianer = styled.div`
padding: 45px 0px 0px 10px;
text-align: left;
${(props) => props.isDisable && css`padding: 30px 0px 0px 10px;`}`;

const NameContianer = styled.div`	
color: #FFFFFF;	
font-family: ${Family.roboto};	
font-size: 16px;	
font-weight: bold;	
line-height: 19px;`

const AmountContianer = styled.div`	
color: #FFFFFF;	
font-family: ${Family.roboto};	
font-size: 14px;	
font-weight: lighter;	
line-height: 19px;`

const ImgCountry = styled.img`
height: 189px;	
width: 189px;`;

const StepLable = styled.label`
font-size: 18px;
color: rgb(184, 24, 7);
font-weight: 600;
font-family: ${Family.roboto};
${(props) => props.isActive && css`cursor: pointer;color:#000000;`}`;

const StepCotianer = styled.div`    
text-align: left;
padding: 10px 0px 20px 0px;`;

const Card = styled.div`
width: 500px;
display: flex;
margin: 5px 0px ;
cursor: pointer;`;

// const Card = styled.div`
// height: 211px;
// width: 500px;
// display: flex;
// margin: 10px 0px;
// background-color: rgb(255, 255, 255);
// box-shadow: rgba(0, 0, 0, 0.18) 3px 2px 10px 0px;
// cursor: pointer;`;

const ImageContianer = styled.div`
height:${189}px;
width:${189}px;
box-shadow: 0 3px 5px 0 rgba(0,0,0,0.7);
&:hover .promotinPrice {-moz-transform: scale(1.12);-webkit-transform: scale(1.12);transform: scale(1.12);`;

const ImageInnerBox = styled.div`
width: 265px;
height: 211px;
position: relative;
${(props) => props.isMobile && css`width: 148.72px;height: 119.38px;`}`;

const ProductImage = styled.img`
background-color: rgba(155, 155, 155, 0.35);
width: 265px;
height: 211px;
${(props) => props.isMobile && css`width: 148.72px;height: 119.38px;`}`;

const LoadingContianer = styled.div`
left: 35%;
bottom: 35%;
position: absolute;
${(props) => props.isMobile && css`left: 30%;bottom: 24%;width: 60px;height: 60px;`}`;

const _isFirefox = false;
const maxContentWidth = 1024;

const style = {
    gridList:
    {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        // display: _isFirefox ? '-moz-box' : '-webkit-box',
    },
    gridListMax:
    {
        height: '100%',
        display: _isFirefox ? '-moz-box' : '-webkit-box',
        maxWidth: maxContentWidth,
        minWidth: maxContentWidth,
    },
    gridTabletList:
    {
        width: '100%',
        height: '100%',
        minWidth: 600,
        maxWidth: maxContentWidth,
    },
    gridMobileList:
    {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: '-webkit-box',
    },
    gridTileStyle: {
        width: 'unset',
        height: '100%',
        // marginRight: 18,
        marginBottom: 15,
    },
};
