import React from 'react';
import PropTypes from 'prop-types';

import { path, remove } from 'ramda';
import CONFIG from '../../config';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import IconClose from '@material-ui/icons/HighlightOff';
import ListItemText from '@material-ui/core/ListItemText';

import FormTypes from '../../common/FormTypes';
import * as format from '../../common/setFormat';
import { Colors, Family, RequireForm } from '../../themes';

import Button from '../../constant/Button';
import PromotionCode from '../../constant/JSON/PromotionCode';

import ButtonText from '../../constant/Button/ButtonText';
import FormButtonList from '../../common/FormTypes/FormButtonList';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction';

const _rowContainer = { display: 'flex', };

class ConditionPanel extends React.Component {

    state = { showPackage: false }

    render() {

        const { classes, disabled, onClosePanelPackage, GetPackage } = this.props;
        const { showPackage } = this.state;
        const { ConditionComponent } = PromotionCode;

        return (
            <div>
                {(showPackage) && <DialogAction Active={showPackage} OnClose={onClosePanelPackage} AcrionCom={GetPackage()} />}
                {/* <div style={{ display: 'flex', paddingTop: 25 }}>
                    <div className={classes.title}>{'Condition '}     </div>
                    <span style={{ color: Colors.error, fontSize: 14 }}> {' * ยังไม่สามารถใช้งานได้'}</span>
                </div> */}

                {ConditionComponent.map((row, index) => {
                    return (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (
                                <div key={index} style={{ width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, paddingBottom: 10 }}>
                                    {this.SetItemsWithType(each)}
                                </div>))}
                        </div>
                    )
                })}

            </div>
        )
    }

    SetItemsWithType(each) {

        const { classes, disabled, StateInfo, PackageList, openPackage } = this.props;
        // console.log('StateInfo', StateInfo);

        switch (each.type) {
            case 'checkbox': {
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox color="primary"
                            checked={path([each.stateName], StateInfo) || false}
                            tabIndex={-1} disableRipple
                            onChange={(e) => { this.onChangeText(each.stateName, e.target.checked) }} />
                        <div className={classes.label_checkbox}> {each.title_EN}</div>
                    </div>
                )
            }
            case 'number': {

                let checkValue = null;

                if (each.stateName === 'maximum') {
                    checkValue = 'checkMax'
                }
                else if (each.stateName === 'minimum') {
                    checkValue = 'checkMin'
                }

                return (
                    <FormTypes.TextFormat
                        Require={each.require}
                        InputFormat={format.NumberFormatCustom}
                        TitleText={each.title_EN}
                        disabled={StateInfo[checkValue] === false ? true : false}
                        // placeholder={each.placeholder}
                        // style={{ marginLeft: each.stateName === 'maximum' || each.stateName === 'minimum' ? '-60px' : 'unset' }}
                        value={path([each.stateName], StateInfo)}
                        onChange={(e) => { this.onChangeText(each.stateName, e.target.value) }} />
                )

            }
            case 'button-list': {

                let codeInfo = StateInfo.product ? StateInfo.product : [];
                const StateList = PackageList.filter(_package => codeInfo.some(val => val === _package.packageId));

                return (
                    <React.Fragment>
                        <Button.Themes style={{ width: '100%' }}
                            disabled={StateInfo['checkProduct'] === false ? true : false}
                            TitleText={each.title_EN}
                            onClick={() => this.props.onCreatePanel()} />
                        <div style={{ position: 'relative', border: StateList && StateList.length > 0 ? '1px solid #bfbfbf' : null }}>
                            {(openPackage) &&
                                StateList && Array.isArray(StateList) && StateList.length > 0 && StateList.map((each, index) => {
                                    const _backgroundImage = { width: '70px', height: '40px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${each.packageInfo.image.thumbnail})` || '', backgroundRepeat: 'round' };
                                    // console.log('each', each);
                                    return (
                                        <div key={index} className={classes.item} >
                                            <div style={{ display: 'flex', padding: 5, alignItems: 'center' }} >
                                                <div style={_backgroundImage} />
                                                <div className={classes.price}>
                                                    <ListItemText style={{ paddingLeft: '15px', fontSize: '16px' }} primary={each.packageInfo.name || ''} />
                                                </div>
                                                <IconClose style={{ color: Colors.darkGrey, position: 'absolute', right: 10, cursor: 'pointer' }}
                                                    onClick={this.props.handleToggle(each)} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                )
            }
            case 'time': {
                return (
                    <div className={classes.textInput}>
                        <FormTypes.TextFormat
                            InputFormat={format.TimeFormat}
                            TitleText={each.title_start}
                            disabled={StateInfo['checkDate'] === false ? true : false}
                            value={path([each.stateName_start], StateInfo) || ''}
                            onChange={(e) => this.onChangeText(each.stateName_start, e.target.value)}
                            style={{ margin: '0px' }} />
                        <div className={classes.line}> {'To'} </div>
                        <FormTypes.TextFormat
                            InputFormat={format.TimeFormat}
                            TitleText={each.title_end}
                            disabled={StateInfo['checkDate'] === false ? true : false}
                            value={path([each.stateName_end], StateInfo)}
                            onChange={(e) => this.onChangeText(each.stateName_end, e.target.value)}
                            style={{ margin: '0px' }} />
                    </div>
                )
            }
        }
    }

    onChangeText = (fieldName, value) => {

        console.log('onChangeText', fieldName, value);
        return (this.props.onChange(fieldName, value))
    }
}

const styles = theme => (
    {
        textInput: { flexDirection: 'row', paddingTop: '15px', display: 'flex' },
        line: {
            display: 'flex',
            alignItems: 'center',
            padding: ' 0px 15px',
            fontSize: '14px',
            fontWeight: 300,
            fontFamily: Family.roboto,
            color: Colors.themeDark
        },
        title: {
            display: 'flex',
            fontSize: 20, color: Colors.themeDark, marginBottom: 15,
            // marginTop: 15,
            fontFamily: Family.roboto,
        },
        label_checkbox: {
            fontFamily: Family.roboto,
            color: Colors.themeDark,
            fontWeight: 300,
            fontSize: '16px',
        }
    });

ConditionPanel.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(ConditionPanel);

