const Fonts = {
    family: {
        helvetica: 'Helvetica',
        roboto: `Roboto, Helvetica, Arial, sans-serif`, // 'Roboto', sans-serif
        serif: 'PT Serif Caption', // 'PT Serif Caption', serif
        avenir: `Roboto, Helvetica, Arial, sans-serif`,
        avenirHeavy: `Roboto, Helvetica, Arial, sans-serif`,
        avenirBlack: `Roboto, Helvetica, Arial, sans-serif`,
    },
    size: {
        input: {
            label: '12px',
            error: '0.75rem',
        },
        chart: {
            title: '18px',
            label: '14px'
        }
    },
};

const Family = Fonts.family;
const Size = Fonts.size;

export { Fonts, Family, Size }
