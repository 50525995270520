import React, { Component } from 'react';
import { connect } from 'react-redux';

import List from '@material-ui/core/List';

import MemberInfo from '../containers/Members/MemberInfo.jsx';
import CreateMember from '../containers/Members/CreateMember.jsx'
import MemberListPanel from '../containers/Members/MemberListPanel.jsx';
import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';

import { PathName } from '../constant';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import * as MemberActions from '../actions/Actions_MemberManager.js';
//import * as UploadActions from '../actions/Actions_UploadManager.js';

import '../App.css';

const _height = (window.innerHeight - 130);

let MemberList = [];
let waitingTime = true;

class MemberManage extends Component {

  constructor(props) {
    super(props);

    this.state = { isCreate: false, isEdit: false, isLoading: true, isInfo: false, memberInfo: undefined }
    this.props.getAPIMemberList({ page: 0, num_limit: 10 });
  }

  componentWillReceiveProps(nextProps) {

    const { MemberManager } = nextProps;
    console.log(`MemberManager [componentWillReceiveProps] : `, MemberManager);

    if (!!MemberManager) {
      const { actions, data } = MemberManager.MEMBER_REDUCER;

      switch (actions) {
        case MemberActions.GET_MEMBER_SUCCESS:
          {
            MemberList = data.result;
            SetStateWithTime(this, waitingTime);
          } break;
        case MemberActions.GET_MEMBER_FAILURE:
          {
            SetStateWithTime(this, waitingTime);
          } break;
      }
    }
  }

  render() {

    const { memberInfo, isLoading, isCreate, msgError, dialogType, isInfo } = this.state;

    return (
      <div className="App">
        {(isLoading) && <DialogPanel Active={isLoading} />}
        {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.CreateMember()} />}
        {(isInfo) && <DialogAction Active={isInfo} OnClose={this.onClosePanel} AcrionCom={(memberInfo) && <MemberInfo memberInfo={memberInfo} />} />}
        <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
          <div style={{ display: 'inline-block' }}>
            {this.contentPanel()}
          </div>
        </List>
      </div>
    );
  }

  contentPanel() {
    return (
      <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
        <MemberListPanel CreatePanel={this.onCreatePanel()} DisplayMemberInfo={this.DisplayMemberInfo()} EditInfo={this.EditMemberInfo()} MemberList={MemberList} />
      </div>
    )
  }

  CreateMember = () => <CreateMember
    OnClose={this.onClosePanel}
    MemberInfo={this.state.memberInfo} isEdit={this.state.isEdit} />

  onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false }));

  onClosePanel = () => (
    this.setState({ isCreate: false, isInfo: false, isEdit: false, memberInfo: undefined })
  );

  EditMemberInfo = () => (memberInfo) => { this.setState({ isCreate: true, isEdit: true, memberInfo: memberInfo }) }

  DisplayMemberInfo = () => (memberInfo) => {
    // console.log('DisplayMemberInfo : ', memberInfo);
    //this.setState({ isInfo: true, memberInfo: memberInfo })
    this.props.history.push({ pathname: PathName.MemberInfo, state: { memberInfo }, search: '', hash: '' });
  }

}

function mapStateToProps(state, ownProps) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return {
    getAPIMemberList: (_filter) => {
      InitWaitingTime();
      dispatch(MemberActions.getMemberList(_filter));
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MemberManage);
