import React from 'react'
import { connect } from 'react-redux';
import { path, update } from 'ramda';
import styled from 'styled-components';
import moment from 'moment';

import { Colors, Family } from '../../themes';
import FormTypes from '../../common/FormTypes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import { SetCustomUpdate } from '../../constant/initState/initPackage';
import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';
import DialogPanel from '../../componets/DialogPanel.jsx';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import CloseService from '../../constant/JSON/CloseService';

let waitingTime = true;
let Structure = CloseService.BodyPage;
let Header = CloseService.HeaderPage;

class CloseServiceCard extends React.Component {

    error = null;
    state = { isBtnActive: false, isLoading: false, msgError: undefined, dialogType: undefined }

    constructor(props) {
        super(props);

        const { Package } = this.props;
        this.state = { packageInfo: { ...Package.packageInfo } }

    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager } = nextProps;

        if (!!PackageManager) {
            const { actions, data } = PackageManager;

            switch (actions) {

                case PackageAction.UPDATE_PACKAGE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update CloseServiceCard Success.', dialogType: 'success', });
                        this.props.getAPIPackageList();
                    } break;
                case PackageAction.UPDATE_PACKAGE_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    render() {
        const { isBtnActive, isLoading, msgError, dialogType } = this.state;
        const { isView, onClosePanel, PeriodInfo } = this.props;

        // let Period = PeriodInfo.filter((val) => moment().format('YYYY-MM-DD') > moment(val.startDate).format('YYYY-MM-DD')).map((each) => each) // Filter Period

        return (
            <Contianer>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <FromLabelContianer>
                    {!isView && <Typography> {Header.UPDATE_EN} </Typography>}
                </FromLabelContianer>
                {
                    Structure.map((R, index) => (
                        <ListContainer key={index}>
                            {
                                R.map((structure, index) => (
                                    <ListItemsContianer key={index} >
                                        {
                                            PeriodInfo.map((period, index) => (
                                                <div key={index}>
                                                    {this.contentPanel(period, index, structure)}
                                                </div>
                                            )) //Filter ช่วงเวลาที่ เปิดใช้งานเท่านั้น !! 
                                        }
                                    </ListItemsContianer>
                                ))
                            }
                        </ListContainer>
                    ))
                }
                {
                    (!isView) &&
                    <ButtonContianer>
                        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes TitleText={ButtonText.BUTTON_SAVE} 
                            disabled={!isBtnActive}  onClick={() => this.onSave()} />
                    </ButtonContianer>
                }
            </Contianer>
        )
    }

    contentPanel = (period, index, structure) => {
        const { packageInfo } = this.state;
        const { packageInfoList, Package, isView } = this.props;
        const { packageId, type } = Package;
        const PeriodInfo = path(['periodTime'], packageInfo)
        const LanguagePackage = path(['language'], packageInfoList)
        return (
            <FormTypes.FormTimePeriod
                StateInfo={PeriodInfo[index]}
                packageId={packageId}
                TransferType={type}
                isUpdate={true}
                disabled={(isView) && true}
                FormLayout={structure}
                Language={LanguagePackage || 'EN'}
                widthDate={400}
                minStartDate={index > 0 ? moment(PeriodInfo[index - 1].endDate).add(1, 'day') : null}
                maxEndDate={undefined}
                indexArray={index}
                onChange={(event) => { this.onChangeState(structure.stateName, PeriodInfo, event, index) }} />
        )
    }

    onChangeState = (fieldName, state, value, index) => {

        let packageInfo = { ...this.state.packageInfo }

        switch (fieldName) {
            default: {
                packageInfo = {
                    ...packageInfo,
                    [fieldName]: update(index, {
                        ...state[index],
                        startDate: value.startDate,
                        endDate: value.endDate,
                        price: value.price,
                        netPrice: value.netPrice,
                        serviceDate: value.serviceDate,
                        marginAdult: value.marginAdult,
                        marginKid: value.marginKid,
                        isCloseListService: value.isCloseListService,
                        closeListService: value.closeListService
                    }, state)
                }
            }
        }
        this.setState({ packageInfo, isBtnActive: true })
    }


    onSave = () => {
        const { packageInfo } = this.state;
        const { Package } = this.props;
        this.setState({ isLoading: true }, () => this.props.getAPIupdatePackage(SetCustomUpdate('CloseListService', Package, packageInfo)))
    }

    onCloseDialog = () => (this.setState({ isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, }));

}

function mapStateToProps(state, ownProps) { return { PackageManager: state.PackageManager.PACKAGE_REDUCER, }; }
function mapDispatchToProps(dispatch) {
    return {
        getAPIupdatePackage: (packageInfo) => {
            dispatch(PackageAction.APIUpdatePackage(packageInfo));
        },
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CloseServiceCard);

const Contianer = styled.div`
        font-family: ${Family.roboto};`;
const FromLabelContianer = styled.div`
        display: flex;
        justify-content: space-between;
        align-items: center;` ;
const Typography = styled.div`
        font-family: ${Family.roboto};
        display: flex; 
        padding: 10px 0px;   
        font-size: 24px;
        font-weight: 400;`;
const ListContainer = styled.div`
        display: flex;
        padding: 2px 0px;`;
const ListItemsContianer = styled.div`
        text-align: start;
        width: 100%;`;
const ButtonContianer = styled.div`
        display: flex;
        justify-content: flex-end;`;