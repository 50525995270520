import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { Colors } from '../../themes';
import '../../themes/Themes.css';

const styles = theme => (
    {
        button: {
            color: Colors.white,
            padding: ' 9px',
            // textAlign: 'center',
            backgroundColor: Colors.theme,
            // border: ' 1px solid',
            // lineHeight: '5px',
            borderRadius: '40px',
            minWidth: '40px'
        }
    }
);

const Icons = (props) => {
    const { classes, tooltip, theme, themeDark } = props;
    return (
        (tooltip) ?
            <Tooltip title={tooltip} >
                <IconButton id="hoverIconButton" aria-label={tooltip}
                    onClick={() => props.onClick()}
                    style={{
                        backgroundColor: (theme) ? Colors.theme : (themeDark) ? Colors.themeDark : 'transparent',
                        color: (themeDark || theme) ? Colors.white : Colors.themeDark,
                        cursor: 'pointer', ...props.buttonStyle

                    }} >
                    {props.icon}
                </IconButton>
            </Tooltip>
            :
            <div style={{ marginLeft: '5px', cursor: 'pointer', ...props.style }}  >
                <Button
                    id='hoverButton'
                    className={classes.button}
                    style={props.buttonStyle}
                    disabled={props.disabled}
                    onClick={() => props.onClick()}>
                    {props.icon}
                </Button>
            </div >

    );

};

Icons.propTypes = { classes: PropTypes.object.isRequired, };

Icons.propTypes = { onClick: PropTypes.func };

Icons.defaultProps = { onClick: () => console.warn('default onClickCancel') }

export default withStyles(styles)(Icons);