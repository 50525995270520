import * as CountryActions from '../actions/Actions_CountryManager';

const INITIAL_STATE = { COUNTRY_REDUCER: { actions: CountryActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: CountryActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case CountryActions.INITIAL_STATE:
            {
                return state;
            }
        case CountryActions.IDLE_STATE:
            {
                //console.log("[ Country ] : IDLE_STATE : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.IDLE_STATE, data: payload, error: _error } };
            }
        case CountryActions.GET_COUNTRY_SUCCESS:
            {
                //console.log("[ Country ] : GET_COUNTRY_SUCCESS : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.GET_COUNTRY_SUCCESS, data: payload, error: _error } };
            }
        case CountryActions.GET_COUNTRY_FAILURE:
            {
                console.log("[ Country ] : GET_COUNTRY_FAILURE : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.GET_COUNTRY_FAILURE, data: payload, error: _error } };
            }
        case CountryActions.CREATE_COUNTRY_SUCCESS:
            {
                // console.log("[ Country ] : CREATE_COUNTRY_SUCCESS : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.CREATE_COUNTRY_SUCCESS, data: payload, error: _error } };
            }
        case CountryActions.CREATE_COUNTRY_FAILURE:
            {
                console.log("[ Country ] : CREATE_COUNTRY_FAILURE : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.CREATE_COUNTRY_FAILURE, data: payload, error: _error } };
            }
        case CountryActions.UPDATE_COUNTRY_SUCCESS:
            {
                // console.log("[ Country ] : UPDATE_COUNTRY_SUCCESS : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.UPDATE_COUNTRY_SUCCESS, data: payload, error: _error } };
            }
        case CountryActions.UPDATE_COUNTRY_FAILURE:
            {
                console.log("[ Country ] : UPDATE_COUNTRY_FAILURE : ", payload);
                return { ...state, COUNTRY_REDUCER: { actions: CountryActions.UPDATE_COUNTRY_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Country ]  default :", state);
                return state;
            }
    }
}
