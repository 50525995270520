import React from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Submenu from './Submenu';
import classNames from 'classnames';

import CONFIG from './config';
import { Images, Colors } from './themes';
import { PathName } from './constant';
import { isMobile } from './common/DeviceCheck.js';
import DialogPanel from './componets/DialogPanel.jsx';
import AppbarPanel from './componets/AppbarPanel.jsx';
import FooterPanel from './componets/FooterPanel.jsx';

// import * as AuthenActions from './actions/Actions_AuthenManager.js';
// import ManageListsPanel from './componets/ManageListsPanel.jsx';
// import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from './common/SetTime.js';
// import { mailFolderListItems, otherMailFolderListItems } from './tileData';

const otherMailFolderListItems = []// ['TEL OFFICE NUMBER', 'VERIFIED', 'STATUS']

const _space = 30;
const drawerWidth = 240;
let waitingTime = true;
let _isMobile = isMobile();;

class MiniDrawer extends React.Component {

    state = { isOpen: !_isMobile, isLoading: false, msgError: undefined, dialogType: undefined, width: window.innerWidth, height: window.innerHeight }

    constructor(props) {
        super(props);

        // console.warn(`MiniDrawer [constructor] : `, _isMobile);
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps) {

        window.addEventListener("resize", this.updateDimensions);
    }

    render() {

        _isMobile = isMobile();
        const { classes, children } = this.props;
        const { root, content } = classes;
        const { isLoading, msgError, dialogType, } = this.state;

        return (
            <div className={root}>
                {(true) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={this.onClosePanel} OK={this.onOk} />}
                {(_isMobile) ? this.MobileAppbarPanel() : this.DesktopAppbarPanel()}
                <main className={content}>
                    <div className={content} />
                    {/* <Typography noWrap>{'You think water moves fast? You should see ice.'}</Typography> */}
                    <div style={{ marginTop: 64, height: (window.innerHeight - (_isMobile ? 115 : 110)) }}>{children}</div>
                    <FooterPanel isMobile={_isMobile} />
                </main>
            </div >
        );
    }

    DesktopAppbarPanel = () => {

        const { classes, theme, } = this.props;
        const { appBar, appBarShift, menuButton, hide, drawerPaper, drawerPaperClose, toolbar, RIcons } = classes;
        const { isOpen } = this.state;

        // console.log('DesktopAppbarPanel : ')

        return (
            <React.Fragment>
                <AppBar position="absolute" className={classNames(appBar, (isOpen && appBarShift))}>

                    <Toolbar disableGutters={!isOpen} style={{ paddingRight: 0, paddingLeft: 0, }}>
                        <IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawerOpen} className={classNames(menuButton, (isOpen && hide))}>
                            <MenuIcon />
                        </IconButton>
                        {/* <Typography variant="title" color="inherit" noWrap>
                            Mini variant drawer
                    <AppbarPanel />
                        </Typography> */}
                        <AppbarPanel Acitve={this.NavbarAcitve} isOpen={isOpen} />
                    </Toolbar>
                </AppBar>

                <Drawer variant="permanent"
                    classes={{ paper: classNames(drawerPaper, (!isOpen && drawerPaperClose)), }} open={isOpen}>
                    <div className={toolbar}>
                        <div>
                            {isOpen && <img src={Images.logo.white} style={{ width: 110, marginTop: 10, marginRight: 20 }} alt='TravisGo' />}
                        </div>
                        <IconButton onClick={this.handleDrawerClose} className={RIcons}>
                            {(theme.direction === 'rtl') ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Divider />
                    {/* <List>{mailFolderListItems}</List> */}
                    <Submenu />
                    <Divider />
                    {/* <ManageListsPanel onClickHandle={() => (info) => alert(info)} /> */}
                    {/* <List>{otherMailFolderListItems}</List> */}
                </Drawer>
            </React.Fragment>)
    }

    MobileAppbarPanel = () => {

        const { classes, theme, } = this.props;
        const { isOpen } = this.state;

        console.log('MobileAppbarPanel : ')

        return (
            <React.Fragment>
                <AppBar position="absolute" className={classNames(classes.appBar, (isOpen && classes.appBarShift))}>
                    <Toolbar disableGutters={!isOpen} style={{ paddingRight: 0, paddingLeft: 0, }}>
                        <IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawerOpen} className={classNames(classes.menuButton, (isOpen && classes.hide))}>
                            <MenuIcon />
                        </IconButton>
                        {/* <AppbarPanel Acitve={this.NavbarAcitve} /> */}
                        <div style={{ marginLeft: '14%' }}>
                            <img src={Images.logo.white} style={{ width: 100 }} alt='TravisGo' />
                        </div>
                    </Toolbar>
                </AppBar>
                {(isOpen) &&
                    < Drawer variant="permanent"
                        classes={{ paper: classNames(classes.drawerPaper, (!isOpen && classes.drawerPaperClose)), }} open={isOpen}>
                        <div className={classes.toolbar}>
                            <IconButton onClick={this.handleDrawerClose} className={classes.RIcons}>
                                {(theme.direction === 'rtl') ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                            </IconButton>
                        </div>
                        <React.Fragment>
                            <Divider />
                            <Submenu />
                            <Divider />
                        </React.Fragment>
                    </Drawer>}
            </React.Fragment>)
    }

    NavbarAcitve = (value) => {

        // console.log('NavbarAcitve : ', value, ' : ', this.props.history.location.pathname)

        switch (value) {
            case PathName.DashBoardPage:
                {
                    const { pathname } = this.props.history.location;

                    if (value !== pathname)
                        this.props.history.push({ pathname: value, search: '', });
                } break;
            case PathName.PackageManage:
                {
                    value = { pathname: value, state: { packageInfo: {} }, search: '', }
                } break;
            case PathName.LoginPage:
                {
                    this.setState({ isLoading: true, msgError: 'Are you sure you want to log out ? ', dialogType: 'question' });
                } break;
            default: { }
        }
    }

    // ====================================================================================================================================================

    handleDrawerOpen = () => { this.setState({ isOpen: true }); };
    handleDrawerClose = () => { this.setState({ isOpen: false }); };

    onOk = () => { CONFIG._info = undefined; localStorage.clear(); this.props.history.push(PathName.LoginPage); }
    onClosePanel = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    updateDimensions = () => {

        const { width, height } = this.state;
        const _width = window.innerWidth;
        const _height = window.innerHeight;

        switch (true) {
            case (_width > (width + _space)):
                {
                    this.setState({ width: _width })
                } break;
            case (_width < (width - _space)):
                {
                    this.setState({ width: _width })
                } break;
            case (_height > (height + _space)):
                {
                    console.log('componentDidMount H : + ', _height, ' : ', height)
                    this.setState({ height: _height })
                } break;
            case (_height < (height - _space)):
                {
                    console.log('componentDidMount H : - ', _height, ' : ', height)
                    this.setState({ height: _height })
                } break;
        }

    }
    // ====================================================================================================================================================
}

// ====================================================================================================================================================

MiniDrawer.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        zIndex: 1,
        overflow: 'hidden',
        position: 'relative',
        display: 'flex',
        backgroundColor: Colors.themeDark,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: Colors.themeDark,
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    menuButton: {
        marginLeft: 12,
        marginRight: 13,
    },
    RIcons: { color: Colors.white, margin: 0, fontSize: 27 },
    hide: {
        display: 'none',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: Colors.themeDark
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing.unit * 9,
        },
        backgroundColor: Colors.themeDark
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,

    },
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        //padding: theme.spacing.unit * 3,
    },
});

// ====================================================================================================================================================

// ====================================================================================================================================================

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        // getAPIBookingLists: (_keyword, _filter) => {
        //     dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        // },
    }
}

export default withRouter((withStyles(styles, { withTheme: true })(MiniDrawer)));
//export default connect(mapStateToProps, mapDispatchToProps)(withRouter((withStyles(styles, { withTheme: true })(MiniDrawer))));

// ====================================================================================================================================================
