import React, { Component } from 'react'
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import DialogAction from '../../componets/DialogAction.jsx';
import Button from '../../constant/Button';
import ButtonText from '../../constant/Button/ButtonText';
import FormTypes from '../../common/FormTypes';
import BankCard from '../../componets/BankCard';
import { isMobile } from '../../common/DeviceCheck';
import { Colors, Family } from '../../themes';
import CONFIG from '../../config';

class FormBookBank extends Component {
    state = { isOpen: false, isShow: false, bankInfo: null, bankId: '' }
    render() {
        const { isOpen } = this.state
        const { classes, BookBankList, valueAccount, Info, disabled } = this.props;
        const BookBank = Array.isArray(BookBankList.datas) ? BookBankList.datas.find((val) => (val.bankSettingId === path(['bankId'], Info))) : [];
        let Language = path(['language'], BookBankList);

        let logoBank = path(['logo', 'thumbnail'], BookBank);
        let nameBank = path([Language, 'name'], BookBank);
        return (
            <div className={classes.root}>
                {(isOpen) && <DialogAction Active={isOpen} OnClose={this.onCloseDialog} AcrionCom={this.GetBankId()} />}

                <ListItem className={classes.input} dense button onClick={() => disabled ? null : this.onToggle()} >
                    {(Info && Info.bankId) ? <img className={classes.avatar} src={`${CONFIG.host}${logoBank}`} /> : null}
                    <ListItemText style={{ paddingLeft: 10 }} primary={nameBank || 'Please Select Bank'} />
                </ListItem>

                <FormTypes.TextFormat
                    disabled={disabled}
                    // style={{ width: isMobile() ? '70%' : '75%', }}
                    TitleText={'Account Number'}
                    value={valueAccount}
                    onChange={(e) => this.props.onChange('accountNumber', e.target.value)} />

                <Button.ThemesDark disabled={disabled} TitleText={ButtonText.BUTTON_SELECT}
                    style={{ marginLeft: isMobile() ? 0 : 20, width: isMobile() ? '100%' : '60%' }} buttonStyle={{ margin: isMobile() ? '15px 0px 0px' : '25px 0px 0px', padding: '9px' }}
                    onClick={() => this.onToggle()} />
            </div>

        )
    }
    GetBankId = () => (
        <BankCard
            _bankId={this.state.bankId || path(['bank', 'bankId'], this.props.Info) || ''}
            BookBankList={this.props.BookBankList}
            onAcceptPanel={this.onSave}
            onAcceptonClosePanel={this.onCloseDialog}
            handleToggle={this.handleToggle} />
    )
    handleToggle = value => () => { this.setState({ bankInfo: value, bankId: value.bankSettingId, isBtnActive: true, isShow: false }) }
    onSave = () => {
        const { bankInfo } = this.state;
        this.setState({
            isOpen: false,
            isShow: true,
        }, () => this.props.onChange('bankId', bankInfo.bankSettingId))
    }
    onToggle = () => { this.setState({ isOpen: true, isShow: false }) }
    onCloseDialog = () => { this.setState({ isOpen: false, bankId: path(['Info', 'bankId'], this.props) || '' }) }

}
const styles = theme => (
    {
        root: { display: isMobile() ? 'unset' : 'flex', alignItems: 'center' },
        input: { paddingLeft: '15px', border: `${'1px solid' + Colors.border}`, marginTop: isMobile() ? 'unset' : '25px', marginRight: '15px', width: '100%' },
        avatar: {
            position: 'relative',
            boxShadow: '-1 0 1px 1.9px ',
            backgroundRepeat: 'round',
            width: '18px',
            height: '18px',
            borderRadius: '50%',
        }
    }
)
FormBookBank.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormBookBank);