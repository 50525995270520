
const HeaderPage = {
    CREATE_EN: "Create Special", CREATE_TH: "Create Special", CREATE_CN: "Create Special",
    UPDATE_EN: "Update Special", UPDATE_TH: "Update Special", UPDATE_CN: "Update Special",
}

const BodyPage = [
    [{
        title_EN: "Seller", title_TH: "---", title_CN: "---",
        type: "title",
        width: '75%',
        stateName: "affiliateId",
    }, {
        title_EN: "+ Add Special", title_TH: "---", title_CN: "---",
        icon: '',
        width: '25%',
        type: "button-list",
        stateName: "packageId",
    }], [{
        title_EN: "SPECIAL LIST", title_TH: "---", title_CN: "---",
        type: "table-list",
        stateName: "affiliateId",
    }],
];

const CommissionForm = [
    [{
        title_EN: "select Packages", title_TH: "---", title_CN: "---",
        // width: '20%',
        type: "button-list",
        stateName: "packageId",
    }],
    [{
        title_EN: "Commission", title_TH: "", title_CN: "",
        type: "select",
        margin: 'right',
        stateLevel: "welcomeLevel",
        stateName: "priceType",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "----",
        options: [
            { value: "percent", label_EN: 'Percent', label_TH: '', label_CN: '' },
            { value: "price", label_EN: 'Price', label_TH: '', label_CN: '' },
        ]
    }, {
        title_EN: "Adult", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'welcomeLevel', stateName: "adult",
    }, {
        title_EN: "Kid", title_TH: "", title_CN: "",
        type: "number", percent: true,
        margin: 'right',
        stateLevel: 'welcomeLevel', stateName: "kid",
    }, {
        title_EN: "Infant", title_TH: "", title_CN: "",
        type: "number", percent: true,
        stateLevel: 'welcomeLevel', stateName: "infant",
    }], [{
        title_EN: "Actived", title_TH: "", title_CN: "",
        type: "switch",
        stateName: "actived",
    }]
]

export default {
    HeaderPage,
    BodyPage,
    CommissionForm
}