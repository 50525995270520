import React, { Component } from 'react'
import { path } from 'ramda';

import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { Family, Colors } from '../../themes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import FormTypes from '../../common/FormTypes';
import DialogAction from '../../componets/DialogAction';
import PackageCard from '../../containers/Commission/PackageCard.jsx';
import CONFIG from '../../config';
import * as format from '../../common/setFormat';
import { isMobile } from '../DeviceCheck';

const _rowContainer = { display: 'flex', padding: '5px', };

class FormSpecial extends Component {
    state = { isBtnActive: false, isPackage: false, SpecialInfo: this.props.SpecialInfo, SpecialItem: this.props.SpecialInfo }
    render() {
        const { isPackage } = this.state;
        const { classes, StateList, isEdit } = this.props;
        return (
            <div className={isEdit ? classes.rootEdit : classes.root}>
                {(isPackage) && <DialogAction Active={isPackage} OnClose={this.onClosePanel} AcrionCom={this.GetPanelPackage()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{(isEdit) ? 'Update Commission' : null}</Typography>
                </div>

                {StateList.map((row, index) => (
                    <div key={index} style={_rowContainer}>
                        {row.map((each, index) => {
                            let _marginRight = each.margin === 'right';
                            return (
                                <div key={index} style={{ textAlign: 'start', width: each.width ? each.width : '100%', marginRight: (_marginRight) ? 15 : 0 }}>
                                    {this.SetItemsWithType(each)}
                                </div>
                            )
                        })}
                    </div>
                ))}
                {/* {!isEdit && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', textAlign: 'end' }}
                        buttonStyle={{ margin: 0, width: '80%', padding: 10 }} onClick={() => this.props.onClosePanel()} />
                    <Button.Themes TitleText={ButtonText.BUTTON_SAVE} style={{ textAlign: 'end' }}
                        disabled={!isBtnActive} buttonStyle={{ margin: 0, width: '80%', padding: 10 }}
                        onClick={() => { this.setState({ isBtnActive: false }, () => this.props.onSaveSpecial(this.state.SpecialInfo)) }} />
                </div>} */}
            </div>
        )
    }

    SetItemsWithType(each) {
        const { isBtnActive, SpecialItem, isUpdate } = this.state;
        const { classes, SpecialInfo, PackageAll, isEdit, onChange } = this.props;
        let options = (each.options) ? each.options : null;
        let PackageInfo = Array.isArray(PackageAll.package) && PackageAll.package.find((val) => val.packageId === SpecialInfo.packageId ? Package = val : null)
        let Package = PackageInfo && PackageInfo.packageInfo;
        const _backgroundImage = { width: '70px', height: '50px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${CONFIG.host}${Package && Package.image.thumbnail})` || '', backgroundRepeat: 'round' };
        const Info = (isEdit) ? SpecialItem : SpecialInfo;

        switch (each.type) {
            case 'button-list': {
                return (
                    <React.Fragment>
                        {!isEdit &&
                            <Button.ThemesDark style={{ width: '100%' }}
                                disabled={isUpdate && true}
                                buttonStyle={{ margin: '0px', }}
                                TitleText={each.title_EN}
                                onClick={() => this.setState({ isPackage: true })} />}
                        {
                            (!isEdit && SpecialInfo.packageId) ?
                                <div className={classes.item} >
                                    <div style={_backgroundImage} />
                                    <div className={classes.price}>
                                        <ListItemText style={{ fontSize: '20px', fontWeight: 'bold', fontFamily: Family.roboto, }} primary={Package && Package.name || ''} />
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-start', paddingTop: 5 }}>
                                            <div style={{ paddingRight: '10px' }}> {'Adult :'} {Package && Package.price.adult} </div>
                                            <div style={{ paddingRight: '10px' }}> {'Kid :'}  {Package && Package.price.kid}</div>
                                            <div style={{ paddingRight: '10px' }}> {'Infant :'} {Package && Package.price.infant}</div>
                                        </div>
                                    </div>
                                </div>
                                : null
                        }
                    </React.Fragment>
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isUpdate && true}
                        // error={isError}
                        style={{ marginTop: 5, }}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], Info) || ''}
                        onChange={(e) => { this.setChangeState(each.stateName, e.target.value, each.stateLevel) }}
                        options={Array.isArray(options) && options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN || ''} </option>) })} />
                )
            }
            case 'number': {
                let priceType = (path(['priceType'], Info) === 'percent') ? true : false
                let _percent = (each.percent && priceType) ? true : false;
                return (
                    <FormTypes.TextFormat
                        disabled={isUpdate && true}
                        InputFormat={(_percent) ? format.PercentFormat : format.PriceFormat}
                        style={{ marginTop: 4, }}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], Info)}
                        onChange={(e) => { this.setChangeState(each.stateName, e.target.value) }}
                    />
                )
            }
            case "switch": {
                return (
                    <div style={{ margin: 0, display: 'flex' }}>
                        <FormTypes.Switches Left
                            disabled={isUpdate && true}
                            ValueText={each.title_EN}
                            checked={path([each.stateName], SpecialInfo)}
                            onChange={(e) => { this.setChangeState(each.stateName, e.target.checked) }} />
                        <div style={{ display: 'flex', cursor: 'pointer', flexDirection: isMobile() ? 'column' : 'row' }}>
                            <Button.Themes TitleText={ButtonText.BUTTON_CLOSE} style={{ textAlign: 'end', paddingBottom: isMobile() ? 10 : 0 }}
                                buttonStyle={{ width: '90%', padding: 10 }} onClick={() => this.props.onClosePanel()} />
                            <Button.Themes TitleText={ButtonText.BUTTON_SAVE} buttonStyle={{ width: '90%', padding: 10 }} style={{ textAlign: 'end' }}
                                disabled={!isBtnActive} onClick={() => { this.onSpecial() }} />
                        </div>
                    </div>
                )
            }
        }
    }
    GetPanelPackage = () => {
        const { PackageAll, SpecialAll, SellerId } = this.props;
        return (
            <PackageCard
                SpecialAll={SpecialAll}
                SellerId={SellerId}
                PackageAll={PackageAll}
                SpecialInfo={this.state.SpecialInfo}
                handleToggle={this.handleToggle}
                onSavePanel={this.onSavePanel}
                onCancelPanel={this.onClosePanel} />
        )
    }
    handleToggle = (fieldName, state) => () => {
        switch (fieldName) {
            case 'special': {
                let SpecialInfo = { ...this.state.SpecialInfo }
                const packageId = SpecialInfo.packageId === state.packageId ? null : state.packageId;
                SpecialInfo = { ...SpecialInfo, packageId, packageName: state.packageInfo.name, packageImage: state.packageInfo.image }
                this.setState({ isBtnActive: true, SpecialInfo });
            }
        }
    }
    onChangeEdit = (fieldName, state) => {
        let SpecialItem = { ...this.state.SpecialItem };
        switch (fieldName) {
            case "priceType": {
                SpecialItem = {
                    ...SpecialItem,
                    adult: 0, kid: 0, infant: 0, [fieldName]: state
                }
            } break;
            // case "adult":
            // case "kid":
            // case "infant": {
            //     if (SpecialItem.priceType === 'percent') {
            //         SpecialItem = { ...SpecialItem, [fieldName]: (state / 100).toFixed(2) }
            //     } else {
            //         SpecialItem = { ...SpecialItem, [fieldName]: parseFloat(state) || 0 }
            //     }
            // } break;
            default: {
                SpecialItem = { ...SpecialItem, [fieldName]: state }
            } break;
        }
        this.setState({ SpecialItem, isBtnActive: true });
    }

    setChangeState = (fieldName, state) => {
        const { isEdit } = this.props;
        console.log('setChangeState', isEdit)
        this.setState({ isBtnActive: true })
        if (!isEdit){
            this.props.onChange(fieldName, state)
        } else {
            this.onChangeEdit(fieldName, state)
        }
    }

    onClosePanel = () => (this.setState({ isPackage: false, }))
    onSavePanel = () => {
        const { packageId, packageName, packageImage } = this.state.SpecialInfo
        this.setState({ isPackage: false, },
            () => this.props.onSavePanel(packageId, packageName, packageImage))
    }

    onSpecial = () => {
        const { SpecialItem, SpecialInfo } = this.state;
        const { isEdit } = this.props;
        if (isEdit) {
            this.setState({ isBtnActive: false }, () => this.props.onUpdate(SpecialItem))
        } else {
            this.setState({ isBtnActive: false, isUpdate: true }, () => this.props.onSaveSpecial(SpecialInfo))
        }
    }
}
const styles = theme => ({
    root: { border: `${'1px solid' + Colors.border}`, padding: 10, position: 'relative' },
    rootEdit: { position: 'relative', },
    item: { display: 'flex', justifyContent: 'flex-start', alignItems: 'start', flexDirection: 'row', border: `${'1px solid' + Colors.border}`, padding: '10px', position: 'relative' },
    price: { display: 'flex', width: ' 100%', padding: '2px 0px 0px 20px', fontFamily: Family.roboto, fontSize: 12, alignItems: 'flex-start', flexDirection: 'column' },
    title: { fontSize: '24px', display: 'flex' },

});

FormSpecial.propTypes = { classes: PropTypes.object.isRequired, }

export default withStyles(styles)(FormSpecial);