import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

import { PathName } from '../constant';
import { Colors, Family } from '../themes';

import DialogPanel from '../componets/DialogPanel.jsx';
import AccountingStatistic from '../containers/Accounting/AccountingStatistic.jsx';

import WithDrawHistoryListPanel from '../containers/Accounting/WithDrawHistoryListPanel.jsx';
import * as AccountingActions from '../actions/Actions_Accounting.js';

import { Roles } from '../constant/JSON/Roles.js';
import { isMobile, isTablet } from '../common/DeviceCheck.js';
import WithDrawRequestListPanel from '../containers/Accounting/WithDrawRequestListPanel';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../common/SetTime.js';

import '../App.css';

const _height = (window.innerHeight - 130);
let waitingTime = false;
let withDrawRequestList = [];
let withDrawHistoryList = [];



let _info = JSON.parse(localStorage.getItem('UserInfo'));
let _role = (!!_info) ? (_info.userType || '') : '';

class WithdrawManage extends Component {

    state = { isLoading: true, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {

        super(props);

        _info = JSON.parse(localStorage.getItem('UserInfo'));
        _role = (!!_info) ? (_info.userType || '') : '';
        switch (_role) {
            case Roles.Admin:
            default:
                {
                    this.props.getAPISystemWallet();
                    this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 25, where: { transferStatus: ['success', 'refuse'] } });
                    this.props.APIWithDrawRequestList({ num_limit: 25 });
                };
        }
    }

    componentWillReceiveProps(nextProps) {

        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.GET_SYSTEM_WALLET_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_SUCCESS:
                    {
                        // AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        withDrawRequestList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_SUCCESS:
                    {
                        // AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        withDrawHistoryList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE:
                case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_FAILURE:
                case AccountingActions.GET_SYSTEM_WALLET_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { isLoading, msgError, dialogType, value = 0, } = this.state;
        const _isMobile = isMobile();

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}

                <List style={{ width: '100%', flexGrow: 1, position: 'relative', overflow: 'auto', height: _height, }}>
                    <div style={{ display: 'inline-block' }}>
                        <AccountingStatistic isLoading={isLoading} Role={_role} isMobile={_isMobile} DisplayPage={PathName.DashBoardPage} />
                        <div style={{ marginTop: 15 }} />
                        {this.contentPanel(value, _isMobile)}
                    </div>
                </List>
            </div>);
    }

    contentPanel(value, _isMobile) {

        const { classes } = this.props;

        switch (_role) {
            case Roles.Admin:
            default:
                {
                    return (
                        <AppBar position="static" >
                            <Tabs
                                value={value}
                                variant="fullWidth"
                                onChange={this.handleChange}
                                classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}>
                                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                    label="Withdraw Request" />
                                <Tab classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
                                    label="Withdraw History" />
                            </Tabs>
                            <div style={{ width: '100%', maxWidth: '1024px', }}>
                                {this.DisplayPanelWithStyle(value, _isMobile)}
                            </div>
                        </AppBar>
                    )
                };
        }

    }

    DisplayPanelWithStyle(value, _isMobile) {
        console.log('withDrawHistoryList', withDrawHistoryList);
        switch (value) {
            case 0:
                {
                    return (<WithDrawRequestListPanel
                        Header={`Withdraw Request List`} withDrawRequestList={withDrawRequestList} userInfo={_info} Role={_role} />)
                }
            case 1:
                {
                    return (<WithDrawHistoryListPanel
                        Header={`Withdraw History List`} withDrawHistoryList={withDrawHistoryList} userInfo={_info} Role={_role} />)
                }
        }
    }

    handleChange = (event, value) => {

        switch (value) {
            case 0: { this.props.APIWithDrawRequestList({ num_limit: 25 }) } break;
            case 1: { this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 25, where: { transferStatus: ['success', 'refuse'] } }) } break;
        }

        this.setState({ value });
    };

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tabsRoot: {
        backgroundColor: Colors.themeDark,
        borderBottom: `${'1px solid' + Colors.border}`,
    },
    tabsIndicator: {
        backgroundColor: Colors.theme,
        height: 3,
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        // marginRight: theme.spacing.unit * 4,
        color: Colors.white,
        fontFamily: Family.roboto,
        '&:hover': {
            color: Colors.white,
            opacity: 1,
        },
        '&$tabSelected': {
            color: Colors.white,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: Colors.white,
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit * 3,
    },
});

WithdrawManage.propTypes = { classes: PropTypes.object.isRequired };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISystemWallet: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.getSystemWallet(_filter));
        },
        getAPIAccoutingHistoryList: (_filter) => {
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
        APIWithDrawRequestList: (_filter) => {
            dispatch(AccountingActions.APIWithDrawRequestList(_filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(WithdrawManage));
