import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import { Colors, Images, Size, Svg, Family } from '../../themes';
// const { DateRange } = Images;

const Calendar = (props) => {
    const { classes } = props;
    return (
        <div>
            <Button
                className={classes.button}
                style={{ position: 'relative', width: props.width, zIndex: 1, ...props.buttonStyle }}
                disabled={props.disabled}
                onClick={props.onClick}>
                {props.value}
            </Button>
            <img alt='header' src={Images.Calendar} className={classes.image}
                style={{ position: 'absolute', top: '20px', right: '10px', ...props.style }} />
        </div>
    );
};


const styles = theme => (
    {
        image: { width: '15px', height: '15px' },
        button: {
            fontSize: 14,
            fontWeight: 300,
            border: `${'1px solid' + Colors.border}`,
            borderRadius: '0px',
            padding: '7px 15px',
            margin: '7px 0px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
        }
    }
);

Calendar.propTypes = { classes: PropTypes.object.isRequired, };
Calendar.propTypes = { onClick: PropTypes.func };

Calendar.defaultProps = { onClick: () => console.warn('default onClickCancel') }

export default withStyles(styles)(Calendar);

