import React, { Component } from 'react';
import { connect } from 'react-redux';
import validate from "validate.js";
import PropTypes from 'prop-types';
import { path, equals, keys, omit } from 'ramda';

import { InitWaitingTime, SetStateWithObject } from '../../common/SetTime.js';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import Button from '../../constant/Button';
import Language from '../../constant/Language';
import Activity from '../../constant/JSON/Activity';
import ButtonText from '../../constant/Button/ButtonText';
import * as validator from '../../constant/Validator/Activity';

import { Colors, Fonts } from '../../themes';
import FormTypes from '../../common/FormTypes';

import * as ActivityActions from '../../actions/Actions_Activity';

import DialogPanel from '../../componets/DialogPanel.jsx';

let waitingTime = true;

class ActtivityCard extends Component {
    error = null;
    state = { isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, disabled: true, }

    constructor(props) {
        super(props);
        this.state = {
            ActivityInfo: this.props.ActivityInfo,
            oldActivityInfo: this.props.oldActivityInfo,
            disabled: true,
        }
    }

    componentWillReceiveProps(nextProps) {
        const { ActivityManager } = nextProps;

        if (!!ActivityManager) {
            const { actions, data } = ActivityManager.ACTIVITY_REDUCER;

            switch (actions) {
                case ActivityActions.CREATE_ACTIVITY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Activity Success.', dialogType: 'success', });
                        this.setState({ activityId: data.activityId, isUpdate: true })
                        this.props.getAPIActivityList();
                    } break;

                case ActivityActions.UPDATE_ACTIVITY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Activity Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPIActivityList();
                    } break;
                case ActivityActions.CREATE_ACTIVITY_FAILURE:
                case ActivityActions.UPDATE_ACTIVITY_FAILURE:
                    {
                        console.log("UPDATE_ACTIVITY_FAILURE : ", data.response.data.error.message);
                        const _msg = data.response.data.error.message || actions;//ErrorCode[data.status].EN;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    };

    onChangeState = (fieldName, state) => {
        // console.log('[onChangeState] :', fieldName, state)
        let ActivityInfo = { ...this.state.ActivityInfo };
        const { oldActivityInfo } = this.state;
        // const key = Object.keys(state)[0];
        const LanguageActivity = path(['language'], this.props.ActivityList);
        switch (fieldName) {
            case "name":
            case "description": {
                ActivityInfo = { ...ActivityInfo, [LanguageActivity]: { ...ActivityInfo[LanguageActivity], [fieldName]: state } }

            } break;
            default: { ActivityInfo = { ...ActivityInfo, [fieldName]: state } }
        }
        // console.log('ActivityInfo success', ActivityInfo)
        this.setState({ ActivityInfo, isBtnActive: !equals(oldActivityInfo, ActivityInfo) }, () => { this.validate({ [fieldName]: state }) });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};
            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        let success = true;
        const LanguageActivity = path(['language'], this.props.ActivityList);
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.ActivityInfo[LanguageActivity][each.fieldName],
            });;
            success = success && validateResult;
        });
        // console.log(success);
        return success;
    }

    onReset = () => { this.setState({ ActivityInfo: undefined }, () => this.props.onClosePanel()) }

    onSave = () => {
        const { isEdit } = this.props;
        let Activity = { ...this.state.ActivityInfo };
        const { isUpdate, activityId } = this.state;

        if (this.validateAll()) {

            this.setState({ isDialog: true, isBtnActive: false });

            if (isEdit) {
                this.props.APIUpdateActivity(Activity);
            }
            else if (isUpdate) {
                Activity = { ...Activity, activityId: activityId };
                this.props.APIUpdateActivity(Activity);
            }
            else { this.props.APICreateActivity(Activity) }
        } else { this.setState({ isDialog: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }); }
    }
    onCloseDialog = () => (this.setState({ isDialog: false, msgError: undefined, dialogType: undefined, }));

    render() {

        const { classes, isView, isEdit } = this.props;
        const { msgError, dialogType, isLoading, isDialog, isBtnActive } = this.state;
        const LanguageActivity = path(['language'], this.props.ActivityList);
        return (

            <div style={{ maxWidth: 800, backgroundColor: Colors.white, textAlign: ' start', }} >
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography className={classes.title}>{isEdit ? Activity.HeaderPage.UPDATE : Activity.HeaderPage.EN}</Typography>
                        <Language LanguagePage={LanguageActivity} onChangeState={this.props.onChangeLanguage} isValue={true} />
                    </div>
                }
                {Activity.BodyPage.map((row, index) => {
                    return (
                        <div key={index} style={{ display: 'flex' }}>
                            {row.map((each, index) => {
                                return (
                                    <div key={index} style={{ textAlign: 'start', width: '100%', paddingTop: 5 }}>
                                        {this.SetItemsWithType(each, LanguageActivity)}
                                    </div>)
                            })}
                        </div>
                    )
                })}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => this.onReset()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => { this.onSave() }} />       </div>
                }
            </div >
        );
    }
    SetItemsWithType(each, LanguageActivity) {
        const { isView } = this.props;
        const { ActivityInfo } = this.state;
        const valueLanguage = path([LanguageActivity, each.stateName], ActivityInfo);
        const isError = !!path([each.stateName], this.error)
        switch (each.type) {
            case "text": {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        // placeholder={each.placeholder_EN}
                        value={path([each.stateName], ActivityInfo) || valueLanguage || ''}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case "text-multiline": {
                return (
                    <FormTypes.TextFormat multiline rows={6}
                        disabled={isView ? true : false}
                        error={isError}
                        Require={isView ? false : each.require}
                        style={{ marginBottom: 10 }}
                        TitleText={each.title_EN}
                        // placeholder={each.placeholder_EN}
                        value={valueLanguage || ''}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        // TitleText={each.title_EN}
                        checked={path([each.stateName], ActivityInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
        }
    }
}



const styles = theme => ({ title: { fontSize: '24px', display: 'flex' }, });

ActtivityCard.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIActivityList: (_keyword) => {
            InitWaitingTime();
            dispatch(ActivityActions.getActivityList());
        },
        APICreateActivity: (confirmInfo) => {
            dispatch(ActivityActions.APICreateActivity(confirmInfo));
        },
        APIUpdateActivity: (confirmInfo) => {
            dispatch(ActivityActions.APIUpdateActivity(confirmInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(ActtivityCard));
