import moment from 'moment';
export const init_package = {
    supplierId: "",
    language: "",
    name: "",
    type: "",
    desShort: "",
    desFull: [],
    image: [],
    imageCover: [],
    imageGallery: [],
    locationServiceId: '',
    //TransferTypeData
    passengers: '',
    bags: '',
    conveyanceType: "",
    transferType: '',
    transferIn: '',
    serviceType: '',
    //------------------
    limit: '',
    scheduleTime: {
        start: "",
        end: "",
        unit: "",
    },
    isPickUp: false,
    pickUpList: [],
    isRefund: false,
    beforeRefund: "",
    isDropOff: false,
    dropOffList: [],
    cutOffTime: "",
    shouldKnow: [""],
    cancelPolicy: [""],
    service: [{
        topic: "",
        detail: []
    }],
    serviceExclusion: [{
        topic: "",
        detail: []
    }],
    pickUpPolicy: [""],
    highlight: [""],
    schedule: [{
        time: "",
        topic: "",
        detail: [],
        appointmentId: "",
        destinationId: "",
    }],
    icon: [{
        isNoRefund: false,
        isTicket: false,
        isLang: false,
        isProtect: false,
        isGroup: false,
        isTraveler: false,
        isCar: false,
        isInsant: false,
        isVan: false,
        isMaximum: false,
        isCalendra: false,
        isReverse: false,
    }],
    periodInfo: [{
        // startDate: new Date().toISOString().substr(0, 10),
        // endDate: new Date().toISOString().substr(0, 10),
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        // startDate: "",
        // endDate: "",
        serviceDate: [],
        price: {
            adult: 0,
            kid: 0,
            infant: 0,
            currency: "THB"
        },
        netPrice: {
            adult: 0,
            kid: 0,
            infant: 0,
            currency: "THB"
        },
        margin: 0,
        marginAdult: 30,
        marginKid: 30,
        marginInfant: 0,
        isCloseListService: false,
    }],
    isAllTraveller: false,
    visible: false,
    isAttractions: true,
    pickUpZoneList: [
        // {
        //     appointmentId: '',
        //     pickUpTime: {
        //         startDate: '',
        //         endDate: ''
        //         // startDate: moment('07:00', 'hh:mm').format('HH:mm'),
        //         // endDate: moment('07:00', 'hh:mm').format('HH:mm')
        //     },
        //     extraCharge: 0,
        // }
    ],
    //Did't Use 
    advice: [],
    adult_normal: 0,
    kid_normal: 0,
    infant_normal: 0,
    adult_foreign: 0,
    kid_foreign: 0,
    infant_foreign: 0,
    currency: "THB",
    appointmentId: '',
    //---------
}

const packageStructure = {
    "packageId": "",
    "supplierId": "",
    "language": "",
    "nameData": {
        "name": ""
    },
    "type": "",
    "desShortData": {
        "desShort": ""
    },
    "desFullData": {
        "desFull": []
    },
    "imageData": {
        "image": {},
        "imageCover": [],
        "imageGallery": [{
            "original": "",
            "thumbnail": ""
        }]
    },
    "locationServiceId": "",
    "transferTypeData": {
        "transferType": "",
        "transferIn": "",
        "passengers": 0,
        "bags": 0,
        "conveyanceType": "",
        "appointmentId": "",
    },
    "serviceType": "",
    "limit": 0,
    "scheduleTimeData": {
        "scheduleTime": {
            "start": "",
            "end": "",
            "unit": "",
        }
    },
    "isPickUp": false,
    "pickUpList": [],
    "isRefund": false,
    "beforeRefund": "",
    "isDropOff": false,
    "dropOffList": [],
    "cutOffTime": "",
    "shouldknowData": {
        "shouldKnow": []
    },
    "cancelPolicyData": {
        "cancelPolicy": []
    },
    "serviceData": {
        "service": [{
            "topic": "",
            "detail": []
        }]
    },
    "serviceExclusionData": {
        "serviceExclusion": [{
            "topic": "",
            "detail": []
        }]
    },
    "pickUpPolicyData": {
        "pickUpPolicy": []
    },
    "highlightData": {
        "highlight": []
    },
    "scheduleData": {
        "schedule": [{
            "time": "",
            "topic": "",
            "detail": [],
            "appointmentId ": "",
            "destinationId": ""
        }, ]
    },
    "iconData": {
        "icon": [{
            "isNoRefund": "",
            "isTicket": "",
            "isLang": "",
            "isProtect": "",
            "isGroup": "",
            "isTraveler": "",
            "isCar": "",
            "isInsant": "",
            "isVan": "",
            "isMaximum": "",
            "isCalendra": "",
            "isReverse": "",
        }]
    },
    "periodTimeData": {
        "periodInfo": [{
            "startDate": moment().format('YYYY-MM-DD'),
            "endDate": moment().format('YYYY-MM-DD'),
            // "startDate": "",
            "endDate": "",
            "serviceDate": [],
            "price": {
                "adult": 0,
                "kid": 0,
                "infant": 0,
                "currency": "THB"
            },
            "netPrice": {
                "adult": 0,
                "kid": 0,
                "infant": 0,
                "currency": "THB"
            },
            "margin": 0,
            "marginAdult": 30,
            "marginKid": 30,
            "marginInfant": 0,
            "isCloseListService": false,
        }],
    },
    "commission": {
        "actived": false,
        "updateDate": "",
        "diamondLevel": {
            "adult": 0,
            "infant": 0,
            "kid": 0,
            "priceType": "",
        },
        "goldLevel": {
            "adult": 0,
            "infant": 0,
            "kid": 0,
            "priceType": "",
        },
        "greenLevel": {
            "adult": 0,
            "infant": 0,
            "kid": 0,
            "priceType": "",
        },
        "welcomeLevel": {
            "adult": 0,
            "infant": 0,
            "kid": 0,
            "priceType": "",
        },
    },

    "isAllTraveller": false,
    "visible": false,
    "pickUpZoneListData": {
        // "pickUpZoneList": [
        //     {
        //         "appointmentId": '',
        //         "pickUpTime": {
        //             "startDate": '',
        //             "endDate": ''
        //             // "startDate": moment('07:00', 'hh:mm').format('HH:mm'),
        //             // "endDate": moment('07:00', 'hh:mm').format('HH:mm')
        //         },
        //         "extraCharge": 0,
        //     }
        // ],
    },
    // "isAttractions": true,

    //Did't Use
    "adviceData": {
        "advice": []
    },
    "priceData": {
        "adult": 0,
        "kid": 0,
        "infant": 0,
        "currency": "THB"
    },
    "foreignPriceData": {
        "adult": 0,
        "kid": 0,
        "infant": 0,
        "currency": "THB"
    },
    "vnscData": {
        "sc": 0,
        "vat": 0,
        "total": 0.15,
        "strTotal": "15"
    }
    //------------
}


export const SetPackegeInfo = (_package) => {

    const _structure = {
        ...init_package
    };
    const _packageInfo = _package.packageInfo;
    const _transferIn = (_packageInfo.transferType.transferIn === true ? 'true' : _packageInfo.transferType.transferIn === false ? 'false' : 'false')

    // console.log('_structure : ', _structure);
    // console.log('_package : ', _package);
    if (_packageInfo.transferType.conveyanceType === 'ferries') {
        _structure.type = 'Ferries'
    } else {
        _structure.type = _package.type;
    }


    _structure.packageId = _package.packageId;
    _structure.supplierId = _package.supplierId;
    _structure.language = _packageInfo.language === "" ? "EN" : _packageInfo.language;
    _structure.name = _packageInfo.name;
    // _structure.type = _package.type;
    _structure.desShort = _packageInfo.desShort;
    _structure.desFull = _packageInfo.desFull;
    // _structure.image = _packageInfo.image
    _structure.image = _packageInfo.image
    _structure.imageCover = _packageInfo.imageCover
    _structure.imageGallery = _packageInfo.imageGallery
    _structure.locationServiceId = _package.locationServiceId;
    _structure.passengers = _packageInfo.transferType.passengers;
    _structure.bags = _packageInfo.transferType.bags;
    _structure.conveyanceType = _packageInfo.transferType.conveyanceType;
    _structure.transferType = _packageInfo.transferType.transferType;
    // _structure.transferIn = _packageInfo.transferType.transferIn;
    _structure.transferIn = _transferIn;
    _structure.serviceType = _package.serviceType;
    _structure.limit = _package.limit;
    _structure.scheduleTime = _packageInfo.scheduleTime;
    _structure.isPickUp = _package.isPickUp;
    _structure.pickUpList = _package.pickUpList;
    _structure.isDropOff = _package.isDropOff;
    _structure.dropOffList = _package.dropOffList;
    _structure.cutOffTime = _package.cutOffTime;
    _structure.shouldKnow = _packageInfo.shouldKnow;
    _structure.service = _packageInfo.service;
    _structure.highlight = _packageInfo.highlight;
    _structure.schedule = _packageInfo.schedule;
    _structure.icon = _packageInfo.inclusion_icon;
    _structure.periodInfo = _packageInfo.periodTime;
    _structure.visible = _package.visible;

    _structure.isAllTraveller = _package.isAllTraveller;
    _structure.serviceExclusion = _packageInfo.serviceExclusion;
    _structure.pickUpPolicy = _packageInfo.pickUpPolicy;
    _structure.pickUpZoneList = _packageInfo.pickUpZoneList;
    _structure.cancelPolicy = _packageInfo.cancelPolicy;

    _structure.isRefund = _package.isRefund;
    _structure.beforeRefund = _package.beforeRefund;

    //------------------------------------------------------
    // _structure.advice = _packageInfo.advice;
    // _structure.infant_normal = _packageInfo.price.infant;
    // _structure.adult_normal = _packageInfo.price.adult;
    // _structure.kid_normal = _packageInfo.price.kid;
    // _structure.appointmentId = _packageInfo.transferType.appointmentId;
    // console.log('New PackageInfo : ', _structure)

    return {
        "packageData": _structure
    }
}

export const SetPackegeInfoToCreate = (_package) => {

    const _structure = {
        ...packageStructure
    };
    delete _structure.packageId;
    // console.log('_structure : ', _structure);
    // console.log('_package : ', _package);
    const _isImage = (_package.newImage.length > 0);
    const _transferIn = (_package.transferIn === 'true' ? true : _package.transferIn === 'false' ? false : false)

    if (_package.type === 'Ferries') {
        _structure.type = 'Transfer'
    } else {
        _structure.type = _package.type;
    }

    _structure.isAttractions = _package.isAttractions;
    _structure.supplierId = _package.supplierId;
    _structure.language = _package.language === "" ? "EN" : _package.language;
    _structure.nameData.name = _package.name;
    _structure.desShortData.desShort = _package.desShort;
    _structure.desFullData.desFull = (_package.desFull.indexOf('\n') === -1) ? [_package.desFull] : _package.desFull.split('\n');
    _structure.imageData.image = (_isImage) ? _package.newImage[0] : {};
    _structure.imageData.imageCover = (_isImage) ? _package.newImage : [];
    _structure.imageData.imageGallery = (_isImage) ? _package.newImage : [];
    _structure.locationServiceId = _package.locationServiceId;
    _structure.transferTypeData.transferType = _package.transferType;
    _structure.transferTypeData.passengers = parseInt(_package.passengers || 0);
    _structure.transferTypeData.bags = parseInt(_package.bags || 0);
    _structure.transferTypeData.conveyanceType = _package.conveyanceType;
    _structure.transferTypeData.transferIn = _package.transferIn ? _transferIn : false;
    _structure.serviceType = _package.serviceType;
    _structure.limit = parseInt(_package.limit || 0);
    _structure.scheduleTimeData.scheduleTime = _package.scheduleTime;
    _structure.isPickUp = _package.isPickUp;
    _structure.pickUpList = _package.pickUpList;
    _structure.isDropOff = _package.isDropOff;
    _structure.dropOffList = _package.dropOffList;
    _structure.cutOffTime = parseInt(_package.cutOffTime || 0);
    _structure.shouldknowData.shouldKnow = _package.shouldKnow;
    _structure.serviceData.service = _package.service;
    _structure.highlightData.highlight = _package.highlight;
    _structure.scheduleData.schedule = _package.schedule;
    _structure.iconData.icon = _package.icon;
    _structure.periodTimeData.periodInfo = _package.periodInfo;
    _structure.visible = _package.visible;

    _structure.isAllTraveller = _package.isAllTraveller;
    _structure.serviceExclusionData.serviceExclusion = _package.serviceExclusion;
    _structure.pickUpPolicyData.pickUpPolicy = _package.pickUpPolicy;
    _structure.cancelPolicyData.cancelPolicy = _package.cancelPolicy;
    _structure.pickUpZoneListData.pickUpZoneList = _package.pickUpZoneList;

    _structure.isRefund = _package.isRefund;
    _structure.beforeRefund = parseInt(_package.beforeRefund || 0);
    //-----------------------------------------
    // _structure.adviceData.advice = _package.advice;
    // _structure.priceData.adult = parseInt(_package.adult_normal || 0);
    // _structure.priceData.kid = parseInt(_package.kid_normal || 0);
    // _structure.transferTypeData.appointmentId = _package.appointmentId ? _package.appointmentId : '';

    // console.log('New PackageInfo : ', _structure)

    return {
        "packageData": _structure
    }
}


export const SetCustomUpdate = (_page, _package, _packageInfo) => {

    let packageData;
    let _transferIn;
    let _desFull;
    let _imageGallery;

    if (_page === 'UpdatePackage') {
        const {
            transferIn,
            conveyanceType,
            desFull,
            newImageGallery,
            imageGallery
        } = _package;
        _transferIn = (transferIn === 'true') ? true : (transferIn === 'false') ? false : false;
        // let _type = (conveyanceType === 'ferries') ? 'Transfer' : _package.type;
        _desFull = (desFull.indexOf('\n') === -1) ? [desFull] : desFull.split('\n');
        _imageGallery = (newImageGallery) ? imageGallery.concat(newImageGallery) : imageGallery;
    }
    switch (_page) {
        case 'UpdatePackage': {
            packageData = {
                packageId: _package.packageId,
                // type: _type,
                adviceData: {
                    advice: _package.advice
                },
                cancelPolicyData: {
                    cancelPolicy: _package.cancelPolicy
                },
                cutOffTime: parseInt(_package.cutOffTime) || 0,
                desFullData: {
                    desFull: _desFull,
                },
                desShortData: {
                    desShort: _package.desShort
                },
                dropOffList: _package.dropOffList,
                highlightData: {
                    highlight: _package.highlight
                },
                iconData: {
                    icon: _package.icon
                },
                imageData: {
                    image: _package.imageGallery[0] || _package.newImageGallery[0],
                    imageCover: _imageGallery,
                    imageGallery: _imageGallery,
                },
                isPickUp: _package.isPickUp,
                isDropOff: _package.isDropOff,
                language: _package.language || 'EN',
                limit: parseInt(_package.limit) || 0,
                // locationServiceId: _package.locationServiceId,
                nameData: {
                    name: _package.name
                },
                periodTimeData: {
                    periodInfo: _package.periodInfo
                },
                pickUpList: _package.pickUpList,
                pickUpPolicyData: {
                    pickUpPolicy: _package.pickUpPolicy
                },
                pickUpZoneListData: {
                    pickUpZoneList: _package.pickUpZoneList
                },
                dropOffList: _package.dropOffList,
                scheduleData: {
                    schedule: _package.schedule
                },
                scheduleTimeData: {
                    scheduleTime: _package.scheduleTime
                },
                serviceData: {
                    service: _package.service
                },
                serviceExclusionData: {
                    serviceExclusion: _package.serviceExclusion
                },
                serviceType: _package.serviceType,
                shouldknowData: {
                    shouldKnow: _package.shouldKnow
                },
                // supplierId: _package.supplierId,
                transferTypeData: {
                    transferType: _package.transferType,
                    transferIn: _transferIn,
                    passengers: parseInt(_package.passengers) || 0,
                    bags: parseInt(_package.bags) || 0,
                    conveyanceType: _package.conveyanceType,
                    appointmentId: (_package.isPickUp) ? _package.appointmentId : '',
                },
                visible: _package.visible,
            }
        }
        break;
    case 'PackageTopList': {
        packageData = {
            packageId: _package.packageId,
            priorityDisplay: parseInt(_package.priorityDisplay),
            isAttractions: _package.isAttractions,
            isRecommends: _package.isRecommends,
            isBestSeller: _package.isBestSeller,
            visible: _package.visible,
        }
    }
    break;
    case 'CloseListService': {
        packageData = {
            packageId: _package.packageId,
            periodTimeData: {
                periodInfo: _packageInfo.periodTime
            }
        }
    }
    break;
    case 'Commission': {
        packageData = {
            packageId: _package.packageId,
            commission: _package.commission,
        }
    }
    break;
    }
    return {
        packageData
    }
}




// export const SetPackegeInfoToUpdate = (_package) => {

//     const _structure = { ...packageStructure };
//     const _transferIn = (_package.transferIn === 'true' ? true : _package.transferIn === 'false' ? false : false)
//     if (_package.conveyanceType === 'ferries') { _structure.type = 'Transfer' } else { _structure.type = _package.type; }

//     console.log('_structure', _structure);
//     console.log('_package', _package);

//      _structure.packageId = _package.packageId;
//     _structure.supplierId = _package.supplierId;
//     _structure.language = _package.language === "" ? "EN" : _package.language;
//     _structure.nameData.name = _package.name || _package.packageInfo.name;
//     _structure.desShortData.desShort = _package.desShort
//     _structure.desFullData.desFull = (_package.desFull.indexOf('\n') === -1) ? [_package.desFull] : _package.desFull.split('\n');
//     _structure.imageData.image = _package.imageGallery[0] || _package.newImageGallery[0];
//     _structure.imageData.imageCover = (_package.newImageGallery) ? _package.imageGallery.concat(_package.newImageGallery) : _package.imageGallery;
//     _structure.imageData.imageGallery = (_package.newImageGallery) ? _package.imageGallery.concat(_package.newImageGallery) : _package.imageGallery;
//     _structure.locationServiceId = _package.locationServiceId;
//     _structure.transferTypeData.transferType = _package.transferType;
//     _structure.transferTypeData.transferIn = _package.transferIn ? _transferIn : false;
//     _structure.transferTypeData.passengers = parseInt(_package.passengers || 0);
//     _structure.transferTypeData.bags = parseInt(_package.bags || 0);
//     _structure.transferTypeData.conveyanceType = _package.conveyanceType;
//     _structure.serviceType = _package.serviceType;
//     _structure.limit = parseInt(_package.limit || 0);
//     _structure.scheduleTimeData.scheduleTime = _package.scheduleTime;
//     _structure.isPickUp = _package.isPickUp;
//     _structure.pickUpList = _package.pickUpList;
//     _structure.isDropOff = _package.isDropOff;
//     _structure.dropOffList = _package.dropOffList;
//     _structure.cutOffTime = parseInt(_package.cutOffTime || 0);
//     _structure.shouldknowData.shouldKnow = _package.shouldKnow;
//     _structure.serviceData.service = _package.service;
//     _structure.highlightData.highlight = _package.highlight;
//     _structure.scheduleData.schedule = _package.schedule
//     _structure.iconData.icon = _package.icon;
//     _structure.periodTimeData.periodInfo = _package.periodInfo;
//     _structure.visible = _package.visible;

//     _structure.isAllTraveller = _package.isAllTraveller;
//     _structure.serviceExclusionData.serviceExclusion = _package.serviceExclusion;
//     _structure.pickUpPolicyData.pickUpPolicy = _package.pickUpPolicy;
//     _structure.cancelPolicyData.cancelPolicy = _package.cancelPolicy;
//     _structure.pickUpZoneListData.pickUpZoneList = _package.pickUpZoneList;

//     _structure.isRefund = _package.isRefund;
//     _structure.beforeRefund = parseInt(_package.beforeRefund || 0);

//     const _isPickup = _package.isPickUp;

//     _structure.adviceData.advice = _package.advice
//     _structure.transferTypeData.appointmentId = (!_isPickup) ? '' : _package.appointmentId ?
//         _package.appointmentId : '';

//     console.log('Update PackageInfo : ', _structure)

//     return { "packageData": _structure }
// }