import React from 'react';
import { connect } from 'react-redux';
import { path, keys, omit, equals } from 'ramda';
import validate from "validate.js";
import './Hotel.css';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';

import CONFIG from '../../config';
import * as validator from '../../constant/Validator/Supplier';
import * as SupplierActions from '../../actions/Actions_SupplierManager.js';
import * as UploadActions from '../../actions/Actions_UploadManager';

import { PathName, SetSupplierToCrate, ErrorCode, SetSupplierToUpdate } from '../../constant';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import FormTypes from '../../common/FormTypes';
import Avatar from '../../constant/Avatar';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import { SupplierType } from '../../constant/JSON/SupplierType';
import BankCard from '../../componets/BankCard';
import AutoThaiAddress from '../../componets/AutoThaiAddress.jsx';
import EmailAutoComplete from '../../componets/EmailAutoComplete';
import LanguageForm from '../../constant/Language';
import Hotel from '../../constant/JSON/Hotel';
import * as format from '../../common/setFormat';

let waitingTime = true;


class HotelCard extends React.Component {
    // error = null;
    state = { isLoading: false, msgError: undefined, dialogType: undefined }
    constructor(props) {
        super(props);
        this.state = {
            SupplierInfo: this.props.SupplierInfo,
            oldSupplierInfo: this.props.oldSupplierInfo,
            isBtnActive: false,
            error: null,
            value: '',
            isSuccess: false
        }
    }

    componentWillReceiveProps = (nextProps) => {
        const { SupplierManager, UpLoadManager } = nextProps;

        if (!!SupplierManager) {
            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;
            switch (actions) {
                case SupplierActions.CREATE_SUPPLIER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Success for register, please verify you account on your email.', dialogType: 'success', });
                        this.props.getAPISupplierList();
                        // console.log('CREATE_SUPPLIER_SUCCESS', SupplierManager.SUPPLIER_REDUCER);
                    } break;
                case SupplierActions.UPDATE_SUPPLIER_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Supplier Success.', dialogType: 'success', isSuccess: true });
                        // if (!Boolean(this.props.parentId))
                        this.props.getAPISupplierList();

                    } break;
                case SupplierActions.UPDATE_SUPPLIER_FAILURE:
                case SupplierActions.CREATE_SUPPLIER_FAILURE:
                    {
                        console.log("CREATE_SUPPLIER_FAILURE");
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error' });
                    } break;
            }
        }

        if (!!UpLoadManager) {
            const { data, actions, error } = UpLoadManager.UPLOAD_REDUCER;
            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let _newSupplier = this.state.SupplierInfo;
                        _newSupplier.logo = data.result.upload.images;
                        const { isEdit, setCreateSupplier, updateSupplier } = this.props;
                        if (isEdit) {
                            console.log('UPLOAD_IMAGE_SUCCESS_EDIT :', _newSupplier)
                            updateSupplier(_newSupplier);
                        } else {
                            console.log('UPLOAD_IMAGE_SUCCESS :', _newSupplier)
                            setCreateSupplier(SetSupplierToCrate(_newSupplier));
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("CREATE_SUPPLIER_FAILURE : ", data);
                        const _msg = data.status; //ErrorCode[data.status].EN;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error' });
                    } break;
            }
        }
    };

    render() {
        const { classes, isEdit } = this.props;
        const { msgError, dialogType, isLoading, isBtnActive, SupplierInfo } = this.state;
        console.log('Hotel Props', this.props);

        return (
            <div style={{ maxWidth: 800, overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}

                {!isEdit &&
                    <Typography className={classes.header} style={{ fontSize: '20px', paddingTop: 15 }}>{Hotel.HeaderPage.EN}</Typography>

                }

                {Hotel.BodyPage.map((row, index) => (<div key={index} style={{ display: 'flex' }}>
                    {row.map((each, index) => (<div key={index} style={{ textAlign: 'start', width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, marginTop: '5px' }}>
                        {this.setSupplier(each)}
                    </div>))}
                </div>))}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => this.onReset()} />
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_SAVE}
                        disabled={!isBtnActive} onClick={() => this.onSave()} />
                </div>
            </div>
        )
    }

    setSupplier(each) {
        console.log("each : ",each);
        const { classes, parentId, theme, isEdit, BookBankList } = this.props;
        const { SupplierInfo, oldSupplierInfo } = this.state;
        switch (each.type) {
            case "text": {
                return (
                    (!!parentId && !each.action) ? null :
                        <FormTypes.TextFormat
                            error={!!path([each.stateName], this.error)}
                            Require={each.require}
                            TitleText={each.title_EN}
                            value={path([each.stateName], SupplierInfo) || path(['emergencyContact', 0, each.stateName], SupplierInfo) ||
                                path(['address', each.stateName], SupplierInfo) || ''}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "email": {
                return (
                    <FormTypes.FormEmail
                        error={!!path([each.stateName], this.error)}
                        Require={each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], SupplierInfo)}
                        onChange={(value) => { this.onChangeState(each.stateName, value) }} />

                )
            }
            case "search_select": {
                return (
                    (!!parentId && !each.action) ? null :
                        <AutoThaiAddress each={each}
                            provinceId={path(['address', each.stateName_pro], SupplierInfo) || ''}
                            districtId={isEdit ? path(['address', each.stateName_dis], SupplierInfo) : !equals(path(['address', each.stateName_pro], SupplierInfo), path(['address', each.stateName_pro], oldSupplierInfo)) ? '' : path(['address', each.stateName_dis], SupplierInfo)}
                            onChange={(val) => {
                                if (!!val.district) {
                                    this.onChangeState(each.stateName_dis, path(['district', 'code'], val))
                                } else {
                                    this.onChangeState(each.stateName_pro, path(['province', 'code'], val))
                                }
                            }} />
                )
            }
            case "number": {
                return (
                    (!!parentId && !each.action) ? null :
                        <FormTypes.TextFormat
                            // disabled={isView ? true : false}
                            // error={isError}
                            Require={each.require}
                            InputFormat={format.NumberFormatCustom}
                            TitleText={each.title_EN}
                            value={path([each.stateName], SupplierInfo) || path(['emergencyContact', 0, each.stateName], SupplierInfo) ||
                                path(['address', each.stateName], SupplierInfo) || ''}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />

                )
            }
            case "image": {
                return (
                    <div className={classes.textDefalut} style={{ margin: '0px ', paddingBottom: '6px' }} >
                        <Avatar editable ref='avatar'
                            onRemove={() => this.onRemoveImage()}
                            onChange={(files) => { this.onChangeState(each.stateName, files) }}
                            avatar={`${CONFIG.host}${path([each.stateName, 'thumbnail'], SupplierInfo)}`} />
                    </div>
                )
            }
            case "switch": {
                return (
                    <FormTypes.Switches Left
                        ValueText={each.title_EN}
                        checked={path([each.stateName], SupplierInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }

            case "select": {
                return (
                    (!!parentId) ? null :
                        <FormTypes.Select
                            Require={each.require}
                            TitleText={each.title_EN}
                            error={!!path([each.stateName], this.error)}
                            value={path(['address', each.stateName], SupplierInfo) || ''}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                            options={Array.isArray(Hotel.SelectCountry) && Hotel.SelectCountry.map((option, i) => {
                                return (<option key={i} value={option.name_EN}> {option.name_EN || '-'} </option>)
                            })} />

                )
            }
            case "select-multiple": {
                return (
                    (!!parentId) ? null :
                        <FormTypes.SelectChip
                            Require={each.require}
                            TitleText={each.title_EN}
                            // error={!!path([each.stateName], this.error)}
                            value={path([each.stateName], SupplierInfo)}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                            renderValue={selected => (
                                <div className={classes.chips} style={{ padding: '0px 10px', marginTop: '-26px', marginBottom: '-14px' }}>
                                    {selected.map(obj => (<Chip key={obj} label={SupplierType.find(_info => _info.id === obj).type || obj} style={{ margin: '7px', height: '23px' }} />))}
                                </div>
                            )}
                            options={Hotel.SelectSupplierType.map(info => (
                                <MenuItem key={info.key} value={info.id} style={{ fontWeight: path([each.stateName], SupplierInfo).indexOf(info.id) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium, }}>
                                    <Checkbox color="primary" checked={path([each.stateName], SupplierInfo).indexOf(info.id) > -1} />
                                    <ListItemText primary={info.title_EN} />
                                </MenuItem>
                            ))
                            } />
                )
            }
            case "select_checkbok": {
                return (
                    <FormTypes.FormBookBank
                        BookBankList={BookBankList}
                        Info={path([each.stateName], SupplierInfo)}
                        valueAccount={path(['bank', each.stateName_account], SupplierInfo)}
                        onChange={this.onChangeState} />

                )
            }

        }
    }


    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

    onRemoveImage = () => {
        const { SupplierInfo } = this.state;
        this.setState({ SupplierInfo: { ...SupplierInfo, logo: { original: "", thumbnail: "" } }, isBtnActive: true })
        this.refs.avatar.remove('file');
    }

    onChangeState = (fieldName, state) => {
        console.log("onChangeState :", fieldName, state)
        const { oldSellerInfo } = this.state;
        let SupplierInfo = { ...this.state.SupplierInfo }

        switch (fieldName) {
            case "person":
            case "tel": {
                SupplierInfo = {
                    ...SupplierInfo,
                    emergencyContact: [{ ...SupplierInfo.emergencyContact[0], [fieldName]: state }]
                }
            } break;
            case "no":
            case "road":
            case "district":
            case "sub-district":
            case "province":
            case "post-code":
            case "country": {
                SupplierInfo = {
                    ...SupplierInfo,
                    address: { ...SupplierInfo.address, [fieldName]: state }
                }
            } break;
            case "bankId":
            case "accountNumber": {
                SupplierInfo = {
                    ...SupplierInfo,
                    bank: { ...SupplierInfo.bank, [fieldName]: state }
                }
            } break;
            default: { SupplierInfo = { ...SupplierInfo, [fieldName]: state } } break;

        }
        console.log('SupplierInfo success', SupplierInfo)
        this.setState({ SupplierInfo, isBtnActive: true }, () => this.validate(state));
    }
    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};
            if (!!validateResult) {
                result = false;
                this.error = {
                    ...error,
                    ...validateResult
                };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }
    validateAll() {
        let success = true;
        const { packageInfo } = this.state;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: packageInfo[each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }

    onReset = () => {
        const { SupplierInfo, isSuccess } = this.state;
        const { isEdit, EditInfo } = this.props;
        // console.log(' onReset SupplierInfo', SupplierInfo)
        if (isEdit && isSuccess) {
            this.props.onClosePanel(SupplierInfo, !!SupplierInfo.parentId)
        } else {
            this.props.onClosePanel()
        }

    }

    onSave = () => {
        const { SupplierInfo, error } = this.state;
        const { parentId, isEdit, EditInfo } = this.props;
        const { name, email, officialWebsite } = this.state.SupplierInfo;
        const newAvatar = this.refs.avatar.getAvatar('file');



        if (name === "" || email === "")
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => error)
        else if (newAvatar) {
            if (parentId) {
                SupplierInfo.parentId = this.props.parentId;
                let formdata = new FormData();
                formdata.append("images", newAvatar);
                this.setState({ isLoading: true, isBtnActive: false }, () => this.props.uploadImage(formdata));
            } else {
                let formdata = new FormData();
                formdata.append("images", newAvatar);
                this.setState({ isLoading: true, isBtnActive: false }, () => this.props.uploadImage(formdata));
            }
        }
        else if (parentId) {
            this.setState({ isLoading: true, isBtnActive: false })
            console.log('Create and Edit ParentId');
            SupplierInfo.parentId = this.props.parentId;
            (isEdit) ? this.props.updateSupplier(SupplierInfo) : this.props.setCreateSupplier(SetSupplierToCrate(SupplierInfo))
        }
        else {
            this.setState({ isLoading: true, isBtnActive: false, })
            if (EditInfo) {
                console.log('Edit Info');
                this.props.updateSupplier(SupplierInfo)
            } else {
                console.log('Create and Edit Not ParentId');
                (isEdit) ? this.props.updateSupplier(SupplierInfo) : this.props.setCreateSupplier(SetSupplierToCrate(SupplierInfo))
            }

        }
    }



}

const styles = theme => (
    {
        header: { fontSize: '13px', display: 'flex', alignItems: 'center' },
        chips: { display: 'flex', flexWrap: 'wrap', },
        textDefalut: {
            margin: '5px 0px',
            fontSize: '12px',
            color: Colors.grey,
            fontFamily: Family.roboto,
        },
    }
);

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        setCreateSupplier: (_confirmInfo) => {
            dispatch(SupplierActions.APICreateSupplier(_confirmInfo));
        },
        updateSupplier: (_confirmInfo) => {
            dispatch(SupplierActions.APIUpdateSupplier(_confirmInfo));
        },
        uploadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
    }
}

HotelCard.propTypes = { classes: PropTypes.object.isRequired, theme: PropTypes.object.isRequired, };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(HotelCard));
