import React from 'react';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import DatePicker from 'react-datepicker';
// import moment from 'moment';
import { getFormatted, SetDate } from '../../common/SetDate';
import 'react-datepicker/dist/react-datepicker.css';
import { Colors, RequireForm, Family } from '../../themes';
import * as CompPicker from '../../componets/CompPicker';

class DurationCard extends React.Component {
    constructor(props) {
        super(props); {
            this.state = {
                start: props.stateTime,
                // || setHours(setMinutes(new Date(), 0), 8),
                end: new Date()
            }
        }
    }
    render() {
        return (this.setTime(this.props.each))
    }

    // onChangeStart = (start) => {
    //     const { indexadd } = this.props;
    //     const strTime = new Date(start);
    //     const newStart = `${getFormatted(strTime.getHours())}:${getFormatted(strTime.getMinutes())}`;

    //     this.setState({ start })
    //     this.props.onChangeState('time', newStart, indexadd)

    // }
    // onChangeEnd = (end) => {
    //     const { indexadd } = this.props;
    //     const strTime = new Date(end);
    //     const newEnd = `${getFormatted(strTime._d.getHours())}:${getFormatted(strTime._d.getMinutes())}`;
    //     this.setState({ end })
    //     this.props.onChangeState(newEnd)
    //     // console.log('onChangeEnd', end, strTime)

    // }
    setTime(each) {
        const { classes, packageInfo, Require, Ferries, style, indexadd } = this.props;
        const FerriesEdit = path(['packageInfo', 'transferType', 'conveyanceType'], packageInfo) === "ferries";
        switch (each.type) {
            case 'time': {
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        {
                            each.detail.map((each, index) => {
                                // const start = (each.stateName === "start");
                                return (
                                    <div key={index} style={{ paddingTop: '7px', color: Colors.themeDark, ...style }} >
                                        <CompPicker.TimePicker
                                            timeString={new Date(this.state[each.stateName])}
                                            timeInputLabel={Require ? <div> {RequireForm()} {each.title_EN} </div> : each.title_EN}
                                            onChange={(fieldName, time) => this.props.onChangeState(fieldName, time, indexadd)} />
                                    </div>
                                    // <div key={index} style={{ paddingTop: '4px', color: Colors.themeDark, ...style }} >
                                    //     <label className={classes.textDefalut}>
                                    //         {Require ? <div> {RequireForm()} {each.title_EN} </div> : each.title_EN}
                                    //     </label>
                                    //     <DatePicker
                                    //         className={Ferries || FerriesEdit ? classes.textDate : classes.textInput}
                                    //         timeIntervals={15}
                                    //         timeFormat="HH:mm"
                                    //         timeCaption="Time"
                                    //         dateFormat="h:mm aa"
                                    //         showTimeSelect
                                    //         showTimeSelectOnly
                                    //         injectTimes={[
                                    //             setHours(setMinutes(new Date(), 1), 0),
                                    //             setHours(setMinutes(new Date(), 5), 12),
                                    //             setHours(setMinutes(new Date(), 59), 23)
                                    //         ]}
                                    //         onChange={start ? this.onChangeStart : this.onChangeEnd}
                                    //         selected={this.state[each.stateName]}
                                    //     />
                                    //     <DatePicker
                                    //         className={Ferries || FerriesEdit ? classes.textDate : classes.textInput}
                                    //         selected={new Date(this.state[each.stateName], 'hh:mm')}
                                    //         onChange={start ? this.onChangeStart : this.onChangeEnd}
                                    //         showTimeSelect
                                    //         showTimeSelectOnly
                                    //         timeIntervals={15}
                                    //         timeCaption="Time"
                                    //         timeFormat="HH:mm"
                                    //         // injectTimes={[
                                    //         //     new Date().hours(0).minutes(1),
                                    //         //     new Date().hours(12).minutes(5),
                                    //         //     new Date().hours(23).minutes(59)
                                    //         // ]}
                                    //         dateFormat="LT" />
                                    // </div>
                                )
                            })
                        }
                    </div>
                )
            }
        }
    }
}
const styles = theme => (
    {
        textInput: {
            marginTop: '5px',
            padding: '10px 15px',
            color: Colors.themeDark,
            border: `${'1px solid' + Colors.border}`,
            fontSize: '14px',
        },
        textDate: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            cursor: 'pointer',
            marginTop: '5px',
            padding: '9px 15px',
            color: Colors.themeDark,
            border: `${'1px solid' + Colors.border}`,
            fontSize: '14px',
        },
        title: { fontSize: '24px', display: 'flex' },
        textDefalut: { margin: '5px 0px', fontSize: '14px', color: Colors.themeDark, fontFamily: Family.roboto, fontWeight: 'bold' },
    }
)

DurationCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(DurationCard);
