import React, { Component } from 'react';
import { connect } from 'react-redux';

import { path, equals, keys, omit } from 'ramda';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Promotion from '../../constant/JSON/Promotion';

import CONFIG from '../../config';
import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as format from '../../common/setFormat';
import FormTypes from '../../common/FormTypes';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import 'react-datepicker/dist/react-datepicker.css';
import IconClose from '@material-ui/icons/HighlightOff';
import { Colors, Family } from '../../themes';

const _style = { textAlign: 'start', width: '100%', marginRight: '15px', marginTop: 5 };
const _rowContainer = { display: 'flex', };

class InfoPanel extends Component {
    error = null;
    constructor(props) {
        super(props);

    }
    render() {
        const { classes, onSave, onReset, GetPackage, onClosePanelPackage, isEdit, onCloseDialog } = this.props;
        const { msgError, dialogType, isLoading, isDialog, isPackage } = this.props;
        return (
            <div style={{ maxWidth: '800px', backgroundColor: Colors.white, textAlign: ' start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => onCloseDialog()} />}
                {(isPackage) && <DialogAction Active={isPackage} OnClose={onClosePanelPackage} AcrionCom={GetPackage()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={classes.title}>{isEdit ? 'Update Promotion' : Promotion.HeaderPage.EN}</div>
                </div>
                {this.InfoPanel()}
            </div>
        );
    }

    InfoPanel() {
        const { onReset, onCheckPrice, isBtnActive } = this.props;

        return (
            <div>
                {Promotion.BodyPage.map((row, index) => {
                    return (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each)}</div>))}
                        </div>
                    )
                })}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL}
                        onClick={() => onReset()} />
                    <Button.Themes TitleText={ButtonText.BUTTON_SAVE} style={{ margin: '0px 10px', }}
                        disabled={!isBtnActive} onClick={() => onCheckPrice()} />
                </div>
            </div>
        )
    }
    SetItemsWithType(each) {

        const { classes, onChangeState, showPackage, showEditPackage, isEdit, PackageAll, error, onCheckPrice, oldPromotionInfo, promotionInfo, onCreatePanel, disabled, handleChange, handleChangeStart, handleChangeEnd, handleDelete, CurrencyList } = this.props;
        let currencyList = path(['datas'], CurrencyList)
        // console.log('promotionInfo Info', promotionInfo);
        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        error={!!path([each.stateName], error)}
                        Require={each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={path([each.stateName], promotionInfo) || ''}
                        onChange={(e) => { onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case "text-number": {

                let percent = promotionInfo.type === 'percent';
                let empty = promotionInfo.type === '';

                // console.log('text-number : ', each.statePercent, ' : ', typeof (promotionInfo[each.statePercent]), ' : ', checkType(promotionInfo[each.statePercent]))

                return (
                    <FormTypes.TextFormat
                        disabled={(empty) ? true : false}
                        error={!!path([each.stateName], error)}
                        InputFormat={(percent && each.stateName !== 'limit') ? format.PercentFormat : format.PriceFormat}
                        Require={each.require}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={
                            (percent && each.stateName !== 'limit') ? checkType(promotionInfo[each.statePercent])
                                :
                                path([each.stateName], promotionInfo)
                        }
                        onChange={(e) => {
                            (percent && each.stateName !== 'limit') ?
                                onChangeState(each.statePercent, e.target.value)
                                :
                                onChangeState(each.stateName, e.target.value)
                        }} />

                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={4}
                        error={!!path([each.stateName], error)}
                        Require={each.require}
                        style={{ marginBottom: 10 }}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder_EN}
                        value={path([each.stateName], promotionInfo) || ''}
                        onChange={(e) => { onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case 'button-list': {
                const StateList = Array.isArray(PackageAll.package) && PackageAll.package.filter(_package => promotionInfo.packageId.some(val => val === _package.packageId))
                return (
                    <div style={{ width: '100%' }}>
                        <Button.ThemesDark
                            style={{ width: '100%' }}
                            buttonStyle={{ marginTop: 10 }}
                            TitleText={each.title_EN}
                            onClick={() => onCreatePanel()} />
                        <div style={{ padding: '5px', border: StateList && StateList.length > 0 ? `${'1px solid' + Colors.borderLight}` : null }}>
                            {(showPackage || showEditPackage || isEdit) &&
                                StateList && Array.isArray(StateList) && StateList.length > 0 && StateList.map((each, index) => {
                                    const _backgroundImage = { width: '70px', height: '40px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${CONFIG.host}${each.packageInfo.image.thumbnail})` || '', backgroundRepeat: 'round' };
                                    return (
                                        <div key={index} className={classes.item} >
                                            <div style={{ display: 'flex', paddingLeft: '15px' }} >
                                                <div style={_backgroundImage} />

                                                <div className={classes.price}>
                                                    <ListItemText style={{ fontSize: '16px' }} primary={each.packageInfo.name || ''} />
                                                    <div style={{ display: 'flex', width: '100%', paddingLeft: '10px', justifyContent: 'flex-start' }}>
                                                        <div style={{ paddingRight: '10px' }}> <span style={{ fontWeight: 'bold' }}>{'Adult :'} </span> {each.packageInfo.price.adult} </div>
                                                        <div style={{ paddingRight: '10px' }}>  <span style={{ fontWeight: 'bold' }}>{'Kid :'} </span>  {each.packageInfo.price.kid}</div>
                                                        <div style={{ paddingRight: '10px' }}> <span style={{ fontWeight: 'bold' }}>{'Infant :'} </span> {each.packageInfo.price.infant}</div>
                                                    </div>
                                                </div>
                                                <IconClose style={{ color: Colors.darkGrey, position: 'absolute', right: 10 }}
                                                    onClick={() => handleDelete('packageId', index, promotionInfo.packageId)} />
                                            </div>


                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                )
            }
            case "date-picker": {
                ///////// wait min-Start ////////

                // let date = (indexPeriod > 0) ? new Date(periodList[indexPeriod - 1][each.stateEnd]) : new Date()
                // let minStart = date.setDate(date.getDate() + 1);
                // let minEnd = (minStart) ? moment(minStart).add(1, 'day') : moment().add(1, 'day');
                // console.log('minEnd',minEnd.format('YYYY-MM-DD'))
                let active = false
                return (
                    <div style={{ border: `${'1px solid' + Colors.border}`, padding: '10px' }}>
                        <label className={classes.textDefalut} >{each.title}</label>
                        <FormTypes.TextDatePicker disabled={(active) ? true : false}
                            error={!!path([each.stateName], error)}
                            // minStartDate={minStart}
                            // minEndDate={(indexPeriod > 0) ? moment(minStart).add(1, 'day') : moment()}
                            Require={(active) ? false : each.require}
                            width={'150px'}
                            valueStart={path([each.stateGroup, each.stateStart], promotionInfo)}
                            valueEnd={path([each.stateGroup, each.stateEnd], promotionInfo)}
                            stateStart={each.stateStart} stateEnd={each.stateEnd}
                            TitleStart={each.titleStart} TitleEnd={each.titleEnd}
                            onChange={(name, value) => { onChangeState(name, value, each.stateGroup) }} />
                    </div>
                )

                //     return (
                //         <div style={{ border: `${'1px solid' + Colors.border}`, padding: '10px' }}>
                //             <label className={classes.textDefalut} >{each.title_EN}</label>
                //             <FormTypes.TextDatePicker
                //                 error={!!path([each.stateName], error)}
                //                 Require={each.require}
                //                 width={'150px'}
                //                 styleIcon={{ top: '15px' }}
                //                 buttonStyle={{ fontSize: 14, }}
                //                 valueStart={path([each.stateGroup, each.stateStart], promotionInfo)}
                //                 valueEnd={path([each.stateGroup, each.stateEnd], promotionInfo)}
                //                 stateEnd={each.stateEnd}
                //                 stateStart={each.stateStart}
                //                 TitleStart={each.start_EN}
                //                 TitleEnd={each.end_EN}
                //                 onChange={(name, value) => { onChangeState(name, value, each.stateGroup) }}
                //             />
                //         </div>
                //     )
            }
            case "select": {
                return (
                    <FormTypes.Select
                        disabled={((promotionInfo.visible && promotionInfo.expDate) || (each.stateName === 'currency' && promotionInfo.type === 'percent')) ? disabled : !disabled}
                        error={!!path([each.stateName], error)}
                        Require={promotionInfo.type === 'percent' ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], promotionInfo) || ''}
                        onChange={(e) => { onChangeState(each.stateName, e.target.value) }}
                        options={
                            each.stateName === 'currency' ?
                                Array.isArray(currencyList) ? currencyList.map((option) =>
                                    (<option key={option.currencyType} value={option.currencyType}> {option.currencyType} </option>)) : null
                                :
                                Array.isArray(each.options) && each.options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN} </option>) })
                        } />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        ValueText={each.label_EN}
                        checked={path([each.stateName], promotionInfo)}
                        onChange={(e) => { onChangeState(each.stateName, e.target.checked) }} />
                )
            }
        }
    }
}

InfoPanel.propTypes = { classes: PropTypes.object.isRequired, label: PropTypes.string };

const styles = theme => (
    {
        price: { display: 'flex', textAlign: 'start', width: ' 100%', paddingLeft: '20px', fontFamily: Family.roboto, fontSize: 12, alignItems: 'center', flexDirection: 'column' },
        item: { display: 'flex', justifyContent: 'flex-start', alignItems: 'start', flexDirection: 'column', border: `${'1px solid ' + Colors.border}`, marginTop: '10px', padding: '10px', position: 'relative' },
        title: {
            fontSize: 28, color: Colors.black, marginBottom: 15, marginTop: 15,
            fontFamily: Family.roboto,
        },
        textDefalut: { margin: '5px 0px', fontSize: '14px', color: Colors.themeDark, fontFamily: Family.roboto, fontWeight: 'bold' },
    });

function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {}
}

function checkType(value) {
    return (typeof (value) === "string") ? parseFloat(value) : value;
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(InfoPanel));
