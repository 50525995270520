import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import ActivityListPanel from '../containers/Activity/ActivityListPanel.jsx';
import ActtivityCard from "../containers/Activity/ActtivityCard";

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';

import * as ActivityActions from '../actions/Actions_Activity';

import { init_activity } from '../constant/initState/initActivity';

import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

const _height = (window.innerHeight - 130);
let waitingTime = true;
let ActivityList = [];

class ActivityManage extends Component {

    constructor(props) {
        super(props);

        this.state = { isLoading: true, isEdit: false, isView: false, ActivityInfo: undefined, oldActivityInfo: null, };

        this.props.getAPIActivityList({ num_limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        const { ActivityManager } = nextProps;
        if (!!ActivityManager) {
            const { actions, data } = ActivityManager.ACTIVITY_REDUCER;
            switch (actions) {
                case ActivityActions.GET_ACTIVITY_SUCCESS:
                    {
                        ActivityList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case ActivityActions.GET_ACTIVITY_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate, } = this.state;
        return (
            <div className="App">
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.CreateActivityPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}

                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>                        {this.contentPanel()}                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <ActivityListPanel
                    CreatePanel={this.onCreatePanel(this.state.isCreate)}
                    EditInfo={this.EditActivity()}
                    onClickHandle={this.onClickHandle()}
                    ActivityList={ActivityList} />
            </div>
        )
    }

    getAPIActivityListWithLimit = () => {
        const { page, limit, } = ActivityList;
        return this.props.getAPIActivityList({ page: (page || 0), num_limit: (limit || 10) });
    }
    onChangeLanguage = (_language) => {
        const { page } = ActivityList;
        this.props.getAPIActivityList({ page: page, num_limit: 10, language: _language });
    }

    CreateActivityPanel = () => {
        return (
            <ActtivityCard
                onChangeLanguage={this.onChangeLanguage}
                ActivityList={ActivityList}
                isEdit={this.state.isEdit}
                isView={this.state.isView}
                oldActivityInfo={this.state.oldActivityInfo}
                ActivityInfo={this.state.ActivityInfo}
                onClosePanel={this.onClosePanel}
                getAPIActivityListWithLimit={this.getAPIActivityListWithLimit} />
        )
    }

    EditActivity = () => (ActivityInfo) => {
        this.setState({
            isCreate: true,
            isEdit: true,
            isView: false,
            ActivityInfo,
            oldActivityInfo: ActivityInfo,
        });
    }

    onClickHandle = () => (ActivityInfo) => {
        this.setState({
            isCreate: true,
            isEdit: false,
            isView: true,
            ActivityInfo,
            oldActivityInfo: ActivityInfo,
        });
    }

    NavbarAcitve = (value) => { console.log('NavbarAcitve : ', value); this.props.history.push(value); };
    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive, isEdit: false, isView: false, ActivityInfo: { ...init_activity }, oldActivityInfo: { ...init_activity } }));//oldActivityInfo: { ...init_activity },
    onClosePanel = () => (this.setState({ isCreate: false, isEdit: false, ActivityInfo: undefined, }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIActivityList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(ActivityActions.getActivityList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ActivityManage);
