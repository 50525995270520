import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import CONFIG from '../../config';
import { blue } from '@material-ui/core/colors';
import Button from '../../constant/Button';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import EmptyRows from '../../componets/EmptyRow.jsx';
import ButtonText from '../../constant/Button/ButtonText';


import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import RefundCard from '../Refund/RefundCard.jsx';

import * as RefundActions from '../../actions/Actions_RefundManager.js';
import * as BookingActions from '../../actions/Actions_BookingManager.js';

const { CloseIcon, PDFIcon} = Icons;
const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME(EN)', name: 'name_EN', isOrder: false, }, //NAME (EN) 
    { label: 'NAME', name: 'name', isOrder: false, },
    { label: 'LANGUAGE', name: 'language', isOrder: false, },//NAME (TH)
    { label: 'STATUS', name: 'active', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '10px', paddingRight: '5px', minWidth: 300 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };

const _wording = { header: 'Report List' }
let waitingTime = true;
let BookingWithPackageList = [];
class ReportsListPanel extends Component {

    state = { isRefund: false, isDialog: false, isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    constructor(props) {
        super(props);

        const { packageId } = this.state;
        console.log('packageId', packageId);
        if (Boolean(packageId))
            this.props.getBookingWithPackageId({ packageId: packageId })
    }
    componentWillReceiveProps(nextProps) {


        const { RefundManager, BookingManager } = nextProps;
        if (!!RefundManager) {

            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.REFUND_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'REFUND_SUCCESS', dialogType: 'success', })
                    } break;
                case RefundActions.REFUND_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!BookingManager) {

            const { actions, data } = BookingManager.BOOKING_REDUCER;
            switch (actions) {
                case BookingActions.GET_BOOKING_WITHPACKAGE_SUCCESS: {
                    BookingWithPackageList = data.result.resultList;
                    SetStateWithTime(this, waitingTime);
                } break;
                case BookingActions.GET_BOOKING_WITHPACKAGE_FAILURE: {
                    SetStateWithTime(this, waitingTime);
                } break;
            }
        }
    }
    render() {

        const { classes, CreatePanel, EditInfo, onClickHandle, ReportList, EventList } = this.props;
        const { data, userName } = ReportList;
        const _isReport = (data.length > 0);
        const { isLoading, isRefund, msgError, dialogType, isDialog } = this.state;
        console.log('ReportList : ', ReportList)
        console.log('EventList : ', EventList)

        return (
            <React.Fragment>
                {(isRefund) && <DialogAction Active={isRefund} OnClose={this.onClosePanel} AcrionCom={this.onRefundPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes disabled={true} style={{ width: '100%' }}
                                        buttonStyle={{ margin: 0, padding: '19px 0px' }}
                                        TitleText={`${userName}  ${_wording.header}`}
                                        onClick={() => this.props.OnClose()} />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(_isReport) ? data.map(_info => {

                                const { amount, packageID, packageName, summary, type, url } = _info;

                                return (
                                    <TableRow hover style={{ cursor: 'pointer' }} key={packageID} >
                                        <TableCell style={_callName} >{packageName || ''}</TableCell>
                                        <TableCell style={_callStyle} >{type || ''}</TableCell>
                                        <TableCell style={_callName} >{`${amount} Booking ` || ''}</TableCell>
                                        {/* <TableCell    style={_callStyle}>{summary || ''}</TableCell> */}
                                        <TableCell style={_callStyle}>
                                            <PDFIcon style={{ color: Colors.theme, width: 30, height: 30 }} onClick={this.onClickHandle(url)} />
                                        </TableCell>
                                        <TableCell style={_callStyle}>
                                            <CloseIcon style={{ color: Colors.theme, width: 30, height: 30 }} onClick={() => this.handleRefund(packageID)} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>

                </Paper>
                <Button.Themes
                    TitleText={ButtonText.BUTTON_CANCEL} style={{ float: 'right' }}
                    onClick={() => this.props.OnClose()} />

            </React.Fragment>
        );
    }

    //===============================================================================================================================================================
    onRefundPanel = () => {
        const { startDate, endDate, userType, totalPrice } = this.props.ReportList;
        const { packageId } = this.state;
        return (
            <RefundCard
                paymentInfo={{}}
                amount={totalPrice}
                userType={userType}
                packageId={packageId}
                startDate={startDate}
                endDate={endDate}
                confirmRefund={this.confirmRefund}
                onClosePanel={this.onClosePanel}
            />
        )
    }
    handleRefund = (id) => { this.setState({ isRefund: true, packageId: id }) };
    onClickHandle = (url) => () => { (Boolean(url)) && window.open(`${CONFIG.host}${url}`) };
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => (this.setState({ isRefund: false, isDialog: false, msgError: undefined, dialogType: undefined }));
    confirmRefund = (value) => {
        console.log('confirmRefund', value);
        this.setState({ isDialog: true }, () => this.props.APIRefund(value))
    }
    //===============================================================================================================================================================
}

const styles = theme => (
    {
        root: {
            width: '100%',
            overflowX: 'auto',
            marginBottom: 10,
            // marginTop: theme.spacing.unit * 3,
        },
        table: { maxWidth: '800px', },
    });

ReportsListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIRefund: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(RefundActions.APIRefund(_keyword, _filter));
        },
        getBookingWithPackageId: (_keyword) => {
            dispatch(BookingActions.getBookingWithPackageId(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReportsListPanel));