import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import styled from 'styled-components';

import { PathName } from '../../constant';
import DialogPanel from '../../componets/DialogPanel.jsx';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';
import * as PackageAction from '../../actions/Actions_PackageManager.js';

import ReservationTableListPanel from './ReservationTableListPanel';

let waitingTime = true;
let packageInfoList = [];
const _height = (window.innerHeight - 130);

class ReservationTableManage extends Component {

    state = { isLoading: true, msgError: undefined, dialogType: undefined, isView: false, isEdit: false };

    constructor(props) {
        super(props);
        this.props.getAPIPackageList({ limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        const { PackageManager } = nextProps;

        if (!!PackageManager) {

            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        packageInfoList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }
    }

    render() {
        const { isLoading } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <ListContianer>
                    <ReservationTableListPanel
                        packageInfoList={packageInfoList}
                        onClickHandle={this.onClickHandle(PathName.ReservationTable)} />
                </ListContianer>
            </React.Fragment>
        );
    }

    onChangeLanguage = (_language) => {
        const { page } = packageInfoList;
        this.props.getAPIPackageList({ page: page, num_limit: 10, language: _language });
    }

    onClickHandle = (page) => (_packageId) => {
        const _packageInfo = packageInfoList.packageList.find((_package) => { return _package.packageId === _packageId });
        this.props.history.push({ pathname: page, state: { packageInfo: _packageInfo, packageId: _packageId }, search: '', hash: '' });
    }

    onClosePanel = () => (this.setState({ isEdit: false }));

}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ReservationTableManage));

const ListContianer = styled.div`
        display: inline-block;
        width: 100%; 
        position: relative;
        overflow: auto;
        height: ${_height};`;



