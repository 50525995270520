import React from 'react';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { withStyles } from "@material-ui/core/styles";

import { Colors, Icons } from '../themes';
const {CheckIcon, CancelIcon } = Icons;

const styles = (theme) => ({
    root: {},
    step: {
        "fontSize": 30,
        '&$completed': { color: '#0eab0eab' },
        '&$active': { color: '#14161a4d' },
        '&$error': { color: 'rgb(184, 24, 7)' },
    },
    labelContainer: {
        "& $alternativeLabel": { marginTop: 10, fontSize: 14 },
    },
    alternativeLabel: { marginTop: -2, paddingBottom: 0 },
    active: { color: '#14161a4d' },
    error: { color: 'rgb(184, 24, 7)' },
    completed: { color: '#0eab0eab' },
    icon: {
        fontSize: 30,
        marginTop: -2
    }
});

const StepperComponent = (props) => {

    const { classes, steps, activeStep, error, color, component, rederRefuse, checkRender } = props;

    return (
        <div className={classes.root} >
            <Stepper alternativeLabel activeStep={activeStep}>
                {
                    steps.map((label, i) => (
                        < Step key={label} >
                            <StepLabel
                                icon={
                                    ((label === rederRefuse) && (i === checkRender)) ?
                                        < CancelIcon className={classes.icon}
                                            style={{ color: (i > activeStep) ? `${Colors.disabled}` : ((color) ? color : `${Colors.error}`) }} />
                                        :
                                        < CheckIcon className={classes.icon}
                                            style={{ color: (i > activeStep) ? `${Colors.disabled}` : ((color) ? color : `${Colors.success}`) }} />
                                }
                                classes={{
                                    alternativeLabel: classes.alternativeLabel,
                                    labelContainer: classes.labelContainer,
                                }}
                                StepIconProps={{
                                    classes: {
                                        root: classes.step,
                                        active: (error) ? classes.error : classes.active,
                                        completed: classes.completed,
                                    },
                                }}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))
                }
            </Stepper>
            <React.Fragment> {component} </React.Fragment>
        </div>
    )
};

export default withStyles(styles)(StepperComponent);
