
export const content_forgot = {
    text_name: 'Forgot your Password',
    text_Email: 'Email Address',
}

export const placeholder = {
    text_Email: 'Insert Email',
}

