export const constraints = {
    code: {
        presence: { allowEmpty: false, },
    },
    locationType: {
        presence: { allowEmpty: false, }
    },
};
export const input = [
    { fieldName: 'code', },
    { fieldName: 'locationType', },

];
