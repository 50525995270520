import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { BrowserRouter, Router, Switch, Route, Link, } from 'react-router-dom';

import Stores from './Store'
import AppRouter from './AppRouter.jsx';

ReactDOM.render(
  <Provider store={Stores}>
    <BrowserRouter>
      <AppRouter />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'));

// ReactDOM.render(<App />, document.getElementById('root'));
// registerServiceWorker();

