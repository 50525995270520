import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { path, equals, keys, omit } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import validate from "validate.js";

import DialogPanel from '../../componets/DialogPanel.jsx';
import * as validator from '../../constant/Validator/Country';
import Country from '../../constant/JSON/Country';
import Language from '../../constant/Language';
import Avatar from '../../constant/Avatar';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Family } from '../../themes';
import { acceptFile } from '../../constant';
import FileUploader from '../../FileUploader';
import FormTypes from '../../common/FormTypes';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import * as CountryActions from '../../actions/Actions_CountryManager';
import * as UploadActions from '../../actions/Actions_UploadManager';
import * as ZoneActions from '../../actions/Actions_ZoneManager';

import CONFIG from '../../config';


let ZoneList = [];
let waitingTime = true;

const _style = { textAlign: 'start', width: '100%', paddingTop: 5 };
const _rowContainer = { display: 'flex' };

class CountryCard extends Component {
    error = null;
    gallery = null;
    imageOther = [];
    state = { isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }
    constructor(props) {
        super(props);
        this.state = {
            CountryInfo: this.props.CountryInfo,
            oldCountryInfo: this.props.oldCountryInfo,
            isUpdate: false,
            new_gallery: [],
        }
        this.props.getZoneList();
    }
    componentWillReceiveProps = (nextProps) => {
        const { CountryManager, UpLoadManager, ZoneManager } = nextProps;

        if (!!CountryManager) {
            const { actions, data } = CountryManager.COUNTRY_REDUCER;

            switch (actions) {
                case CountryActions.CREATE_COUNTRY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Country Success.', dialogType: 'success', });
                        this.setState({ countryId: data.countryId, isUpdate: true })
                        this.props.getAPICountryList({ num_limit: 10 });
                    } break;

                case CountryActions.UPDATE_COUNTRY_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Country Success.', dialogType: 'success', });
                        this.setState({ isUpdate: false })
                        this.props.getAPICountryList({ num_limit: 10 });
                    } break;

                case CountryActions.CREATE_COUNTRY_FAILURE:
                case CountryActions.UPDATE_COUNTRY_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!UpLoadManager) {
            const { data, actions, } = UpLoadManager.UPLOAD_REDUCER;
            const { isEdit } = this.props;
            const { isUpdate, countryId } = this.state;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        let CountryInfo = { ...this.state.CountryInfo };
                        const _image = data.result.upload.images;
                        // CountryInfo.picture = data.result.upload.images;

                        if (isEdit) {
                            CountryInfo.gallery = CountryInfo.gallery.concat(_image);
                            CountryInfo.picture = CountryInfo.gallery[0];

                            this.props.APIUpdateCountry(CountryInfo)
                        } else if (isUpdate) {
                            CountryInfo.gallery = CountryInfo.gallery.concat(_image);
                            CountryInfo.picture = CountryInfo.gallery[0];

                            CountryInfo = { ...CountryInfo, countryId: countryId }
                            this.props.APIUpdateCountry(CountryInfo)
                        } else {
                            CountryInfo.gallery = (_image.length > 0) ? _image : [_image];
                            CountryInfo.picture = CountryInfo.gallery[0];
                            this.props.APICreateCountry(CountryInfo);
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        const _msg = data.status;
                        UpLoadManager.UPLOAD_REDUCER.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
        if (!!ZoneManager) {

            const { actions, data } = ZoneManager.ZONE_REDUCER;

            switch (actions) {
                case ZoneActions.GET_ZONE_SUCCESS:
                    {
                        ZoneManager.ZONE_REDUCER.actions = ZoneActions.WATING_STATE;
                        ZoneList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case ZoneActions.GET_ZONE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

    }
    render() {
        const { classes, onClosePanel, isView, CountryList, isEdit } = this.props;
        const { isLoading, msgError, dialogType, isBtnActive, CountryInfo } = this.state;
        const LanguageCountry = path(['language'], CountryList);

        return (
            <div style={{ maxWidth: 800, textAlign: 'start', overflow: 'unset' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                {!isView && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? Country.HeaderPage.UPDATE_EN : Country.HeaderPage.EN}</Typography>
                    <Language LanguagePage={LanguageCountry} onChangeState={this.props.onChangeLanguage} isValue={true} />
                </div>}
                {Country.BodyPage.map((row, index) => {
                    return (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each, LanguageCountry)}</div>))}
                        </div>
                    )
                })}
                {!isView &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                            onClick={() => onClosePanel()} />
                        <Button.Themes
                            TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                            onClick={() => { this.onSave() }} />
                    </div>}
            </div >
        )
    }
    SetItemsWithType(each, LanguageCountry) {

        const { classes, isView, isEdit } = this.props;
        const { oldCountryInfo, CountryInfo } = this.state;
        const valueLanguage = path([LanguageCountry, each.stateName], CountryInfo);
        // console.log('ZoneList', ZoneList)
        const zoneOption = path(['datas'], ZoneList)
        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        disabled={isView ? true : false}
                        error={undefined}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={valueLanguage}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.value)} />
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        disabled={isView ? true : false}
                        ValueText={each.title_EN}
                        checked={path([each.stateName], CountryInfo)}
                        onChange={(e) => this.onChangeState(each.stateName, e.target.checked)} />
                )
            }
            // case 'image': {
            //     return (
            //         <div className={classes.textDefalut} >
            //             {isView ? <img className={classes.avatar} src={`${CONFIG.host}${CountryInfo.picture.thumbnail}`} />
            //                 :
            //                 <Avatar ref='avatar' editable={true}
            //                     onChange={(files) => { this.onChangeState(each.stateName, files) }}
            //                     avatar={Boolean(CountryInfo.picture.thumbnail) ? `${CONFIG.host}${CountryInfo.picture.thumbnail}` : ''} />
            //             }
            //         </div>
            //     )
            // }
            case 'media': {
                const value = path([each.stateName], oldCountryInfo) || [];
                return (
                    <React.Fragment>
                        {(isEdit || isView) ?
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader
                                    ref={(ref) => this[each.stateName] = ref}
                                    disabled={isView ? true : false}
                                    multiple
                                    onChange={(files) => this.setState({ [`new_${each.stateName}`]: files, isBtnActive: true })}
                                    onDefaultChange={(defaultFiles) => { this.onChangeState(each.stateName, defaultFiles) }}
                                    defaultFiles={value}
                                    accept={acceptFile.image}
                                />
                                {!isView && <div style={{ color: Colors.disabled, fontSize: '10px', paddingTop: '5px' }} >
                                    {each.require.EN}
                                </div>}
                            </div>
                            :
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <FileUploader multiple accept={acceptFile.image}
                                    disabled={isView ? true : false}
                                    onChange={(files) => { this.onChangeState(each.stateName, files) }} />
                                <div style={{ color: Colors.disabled, fontSize: '12px', paddingTop: '10px', fontFamily: Family.roboto }} >
                                    {each.require.EN}
                                </div>
                            </div>
                        }
                    </React.Fragment>
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        disabled={isView ? true : false}
                        error={!!path([each.stateName], this.error)}
                        Require={isView ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], CountryInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        options={Array.isArray(zoneOption) && zoneOption.map((option, i) => { return (<option key={i} value={option.zoneId}> {option[LanguageCountry].name} </option>) })} />
                )
            }
        }
    }
    onChangeState = (fieldName, state) => {
        let CountryInfo = { ...this.state.CountryInfo };
        const { oldCountryInfo } = this.state;
        const LanguageCountry = path(['language',], this.props.CountryList);

        switch (fieldName) {
            case "name":
            case "remark": {
                CountryInfo = { ...CountryInfo, [LanguageCountry]: { ...CountryInfo[LanguageCountry], [fieldName]: state, } }
            } break;
            default: { CountryInfo = { ...CountryInfo, [fieldName]: state, } }
        }
        console.log('CountryInfo success', CountryInfo)
        this.setState({ CountryInfo, isBtnActive: !equals(oldCountryInfo, CountryInfo) }, () => { this.validate({ [fieldName]: state }); });
    }

    validate = (value) => {
        const fieldName = keys(value)[0];
        let result = true;
        if (!!validator.constraints[fieldName]) {
            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {
                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            } else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {
        const LanguageCountry = path(['language',], this.props.CountryList);
        let success = true;
        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: this.state.CountryInfo[LanguageCountry][each.fieldName]
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {
        let CountryInfo = { ...this.state.CountryInfo }
        const { isEdit } = this.props;
        const { isUpdate, countryId, oldCountryInfo, new_gallery } = this.state;
        const { gallery } = CountryInfo;

        if (this.validateAll()) {
            if (isEdit) {
                const { defaultFiles } = this.gallery ? this.gallery.state.length === 0 ? [] : this.gallery.state : [];
                this.imageOther = this.imageOther.concat(this.gallery.files, defaultFiles)
                let newImage = this.gallery.files || [];

                if (newImage && newImage.length > 0) {
                    console.log('1');
                    this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadGallery(newImage));
                } else {
                    console.log('2');
                    this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APIUpdateCountry(CountryInfo))
                }
            } else {
                if (new_gallery.length > 0 || gallery.length > 0) {
                    if (isUpdate) {
                        console.log('3', new_gallery, gallery, this.gallery);
                        this.setState({ isLoading: true, CountryInfo: { ...CountryInfo, gallery: [] }, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
                    } else {
                        console.log('4', new_gallery, gallery);
                        this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
                    }
                } else {
                    if (isUpdate) {
                        console.log('5');
                        CountryInfo = { ...CountryInfo, countryId: countryId }
                        this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APIUpdateCountry(CountryInfo));
                    } else {
                        console.log('6');
                        this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APICreateCountry(CountryInfo));
                    }
                }
            }
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
        }
    }
    // 2
    //     if (this.validateAll()) {
    //         if (new_gallery.length > 0 || gallery.length > 0) {
    //             if (isEdit) {
    //                 const { defaultFiles } = this.gallery ? this.gallery.state.length === 0 ? [] : this.gallery.state : [];
    //                 const { removeList } = this.gallery
    //                 this.imageOther = this.imageOther.concat(this.gallery.files, defaultFiles)
    //                 let newImage = this.gallery.files;
    //                 if (newImage && newImage.length > 0) {
    //                     this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadGallery(newImage));
    //                 } else {
    //                     if (removeList.length > 0) {

    //                         this.setState({ isLoading: true, isBtnActive: false }, () => this.props.APIUpdateCountry(CountryInfo));
    //                     } else
    //                         this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadGallery(new_gallery));
    //                 }
    //             } else {
    //                 this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadGallery(gallery));
    //             }
    //         } else {
    //             this.setState({ isLoading: true, isBtnActive: false, })
    //             if (isEdit) { this.props.APIUpdateCountry(CountryInfo) }
    //             else if (isUpdate) {
    //                 CountryInfo = { ...CountryInfo, countryId: countryId }
    //                 this.props.APIUpdateCountry(CountryInfo)
    //             } else { this.props.APICreateCountry(CountryInfo) }
    //         }
    //     } else {
    //         this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
    //     }
    // }

    // 1

    // onSave = () => {
    //     let CountryInfo = { ...this.state.CountryInfo }
    //     const { isEdit } = this.props;
    //     const { isUpdate, countryId } = this.state;
    //     const newAvatar = this.refs.avatar.getAvatar('file');

    //     if (this.validateAll()) {
    //         if (!!newAvatar) { this.setState({ isLoading: true, isBtnActive: false }, () => this.APIUpLoadImage(newAvatar)); }
    //         else {
    //             this.setState({ isLoading: true, isBtnActive: false })
    //             if (isEdit) {
    //                 this.props.APIUpdateCountry(CountryInfo)
    //             } else if (isUpdate) {
    //                 CountryInfo = { ...CountryInfo, countryId: countryId },
    //                     this.props.APIUpdateCountry(CountryInfo)
    //             } else { this.props.APICreateCountry(CountryInfo) }
    //         }
    //     } else this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error', isBtnActive: false }, () => this.error)
    // }

    // APIUpLoadImage = (newAvatar) => {
    //     let formdata = new FormData();
    //     formdata.append('imgThumbnailWidth', 200);
    //     formdata.append('imgThumbnailHeight', 200);
    //     formdata.append("images", newAvatar);
    //     this.props.APIUpLoadImage(formdata)
    // }
    APIUpLoadGallery = (cover) => {

        let formdata = new FormData();
        formdata.append('imgOriginalWidth', 600); //650
        formdata.append('imgOriginalHeight', 600); //450
        formdata.append('imgThumbnailWidth', 400); //340
        formdata.append('imgThumbnailHeight', 400); //260
        formdata.append('imgThumbnailWidthMobile', 200); //308
        formdata.append('imgThumbnailHeightMobile', 200); //181

        for (const _image of cover)
            formdata.append("images", _image.file);

        this.props.APIUpLoadImage(formdata)
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, }));

}

const styles = theme => ({
    title: { fontSize: '24px', display: 'flex' },
});

function mapStateToProps(state, ownProps) {
    return { ...state };
}
function mapDispatchToProps(dispatch) {
    return {
        getAPICountryList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CountryActions.getCountryList(_keyword, _filter));
        },
        APICreateCountry: (_confirmInfo) => {
            dispatch(CountryActions.APICreateCountry(_confirmInfo));
        },
        APIUpdateCountry: (_confirmInfo) => {
            dispatch(CountryActions.APIUpdateCountry(_confirmInfo));
        },
        APIUpLoadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getZoneList: (_keyword, _filter) => {
            // InitWaitingTime();
            dispatch(ZoneActions.getZoneList(_keyword, _filter));
        }
    }
}
CountryCard.propTypes = { classes: PropTypes.object.isRequired };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CountryCard))