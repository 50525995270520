import TextFormat from './TextFormat';
import TextDuration from './TextDuration';
import TextTelephone from './TextTelephone';
import Select from './Select';
import Switches from './Switches';
import FormText from './FormText';
import FormEmail from './FormEmail';
import FormBookBank from './FormBookBank';
import FormSchedule from './FormSchedule';
import TextDatePicker from './TextDatePicker';
import Checkboxes from './Checkboxes';
import FormTimePeriod from './FormTimePeriod';
import FormCondition from './FormCondition';
import FormAdditional from './FormAdditional';
import FormMarketing from './FormMarketing';
import FormButtonList from './FormButtonList';
import FormSpecial from './FormSpecial';
import FormPickUpZone from './FormPickUpZone';
import FormTextTime from './FormTextTime';
import SelectText from './SelectText';
import SelectChip from './SelectChip';
import Checkbox from './Checkbox';

export default {
    TextFormat,
    TextDuration,
    TextTelephone,
    Select,
    SelectChip,
    Switches,
    FormText,
    FormEmail,
    FormBookBank,
    FormSchedule,
    TextDatePicker,
    Checkboxes,
    FormTimePeriod,
    FormCondition,
    FormAdditional,
    FormMarketing,
    FormButtonList,
    FormSpecial,
    FormPickUpZone,
    FormTextTime,
    SelectText,
    Checkbox,
}