import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { path, equals, keys, omit } from 'ramda';
import PropTypes from 'prop-types';

import { PathName } from '../../constant';
import { ConvertDate, SetDateTo } from '../../common/SetDate.js';

import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime, } from '../../common/SetTime.js';
import * as PromotionActions from '../../actions/Actions_PromotionManager.js';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import { init_promotion } from '../../constant/initState/initPromotion';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import '../../App.css';
import CreatePromotion from './CreatePromotion';
import PromotionPriceDetail from './PromotionPriceDetail';
import PercentPriceDetail from './PercentPriceDetail';
import PackagePriceInfo from './PackagePriceInfo';
import moment from 'moment';
import Button from '../../constant/Button';

import { Family, Fonts, Colors, Icons } from '../../themes';
const { BorderColorIcon, PasswordIcon, } = Icons;

const _height = (window.innerHeight - 260);
const pageWording = {
    topics: 'Promotion Detail',
    subheading: ['You get the best rates', ' only at TravisGo'],
    icon: ['safeBook', 'bestPrice', 'fee'],
};

let waitingTime = true;
let isDialogDisplay = false;

let PackageAll = [];
let PromotionsAll = [];
const _wording = { header: 'Discount' }

class PromotionInfo extends Component {

    state = { editInfo: false, isLoading: true, isCreate: false, isPackage: false, isEdit: false, msgError: undefined, dialogType: undefined, sortFilde: undefined, isBtnActive: false, }

    constructor(props) {
        super(props)

        isDialogDisplay = (!!this.props.PromotionInfo);

        this.props.getAPIPromotions()
        this.props.getAPIPackage()
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager, PromotionManager, } = nextProps;

        if (!!PackageManager) {

            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_SUCCESS:
                    {
                        PackageAll = (data || []);
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.GET_PACKAGE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!PromotionManager) {

            const { actions, data } = PromotionManager.PROMOTIONS_REDUCER;

            switch (actions) {
                case PromotionActions.GET_PROMOTIONALL_SUCCESS:
                    {
                        PromotionsAll = data;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PromotionActions.GET_PROMOTIONALL_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { PromotionInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { classes } = this.props;
        const { isLoading, msgError, dialogType, } = this.state;
        // console.log('PromotionInfo', PromotionInfo);

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div className={classes.page_container}>
                    <SubTopicsPanel _topics={pageWording.topics} _wording={pageWording.subheading} />
                </div>
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel(PromotionInfo)}
                    </div>
                </List>
            </div>);
    }

    contentPanel(PromotionInfo) {

        // console.log('PromotionInfo', PromotionInfo)
        const { classes } = this.props;

        if (PromotionInfo) {
            return (
                <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                    {this.InfoPanel(PromotionInfo)}
                    <div className={classes.title_bar}><label style={{ fontSize: '20px' }}>{`${_wording.header} (${PromotionInfo.type})`}</label></div>
                    {
                        (PromotionInfo.type !== 'percent') ?
                            <PromotionPriceDetail PromotionInfo={PromotionInfo} /> :
                            <PercentPriceDetail PromotionInfo={PromotionInfo} />
                    }
                    <PackagePriceInfo PromotionInfo={PromotionInfo} />
                </div>
            )
        }
    }

    EditInfo = () => {
        // console.log('PromotionInfo', PackageAll);

        const { editInfo } = this.state;
        const { PromotionInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const oldPromotionInfo = { ...init_promotion, packageId: [] }

        return (
            <CreatePromotion
                isEdit={editInfo}
                promotionInfo={PromotionInfo}
                PromotionsAll={PromotionsAll}
                PackageAll={PackageAll}
                CreatePanel={this.onCreatePanel}
                oldPromotionInfo={oldPromotionInfo}
                onClosePanel={this.onClosePanelInfo}
                APISavePromotion={this.APISavePromotion}
                APIUpdatePromotion={this.APIUpdatePromotion}
            />
        )
    }

    onCreatePanel = () => (this.setState({ isPackage: true, }));
    APISavePromotion = (_promotion) => {
        console.log('APISavePromotion', _promotion);
    }
    APIUpdatePromotion = (_promotion) => {
        console.log('APIUpdatePromotion', _promotion);
    }
    onClosePanelInfo = (_PromotionInfo) => {
        console.log('onClosePanelInfo', _PromotionInfo);
        const { PromotionInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { packageId, startDate, endDate } = PromotionInfo;

        if (!!_PromotionInfo)
            this.setState({ editInfo: false, isCreate: false, promotionInfo: { ...PromotionInfo, }, oldPromotionInfo: undefined, error: null, isLoading: false, msgError: undefined, dialogType: undefined, }, () =>
                this.props.history.replace({ pathname: PathName.PromotionInfo, state: { PromotionInfo: _PromotionInfo }, search: '', hash: '' }));
        else
            this.setState({ editInfo: false, isCreate: false, promotionInfo: { ...PromotionInfo, }, oldPromotionInfo: undefined, error: null, isLoading: false, msgError: undefined, dialogType: undefined, });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    InfoPanel(PromotionInfo) {
        // console.log('Props', this.props);
        // console.log('Info', PromotionInfo);
        const { promotionId, name, description, packageId, travelDate, startDate, endDate, expDate, limit, visible, type } = PromotionInfo;
        const { classes } = this.props;
        const { editInfo } = this.state;
        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '10px', margin: 10 };

        return (
            <div>
                <div style={{
                    display: 'flex', height: '100%',
                    backgroundColor: Colors.themeDark,
                    // backgroundImage: `url(${Images.HeaderBackground.HeaderBackground})`, 
                }} >
                    {(editInfo) && <DialogAction Active={editInfo} OnClose={this.onClosePanelInfo} AcrionCom={this.EditInfo()} />}
                    <div style={{ width: '100%', height: '320px', padding: '15px', textAlign: 'left' }}>
                        <div style={{ textAlign: 'right', }}>
                            <Button.Icons themeDark icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { this.setState({ editInfo: true, }) }} />
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Name : </label>
                            <label className={classes.july32017copy}>{name || '-'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Promotion ID : </label>
                            <label className={classes.july32017copy}>{promotionId || '-'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Description :</label>
                            <label className={classes.july32017copy}>{description || ''}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Type :</label>
                            <label className={classes.july32017copy}>{type || ''}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Booking Date :</label>
                            <label className={classes.july32017copy}>{SetDateTo(startDate, endDate) || '-'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Travel Date :</label>
                            <label className={classes.july32017copy}>{SetDateTo(travelDate.startDate, travelDate.endDate) || '-'} </label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Limit : </label>
                            <label className={classes.july32017copy}>{limit || ''}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Exp Date :</label>
                            <label className={classes.july32017copy}>{expDate ? 'true' : 'false'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label className={classes.class}>Visible :</label>
                            <label className={classes.july32017copy}>{visible ? 'Active' : 'Inactive'}</label>
                        </div>
                    </div>
                </div >
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        // getAPIPromotionsList: (_keyword) => {
        //     InitWaitingTime();
        //     dispatch(PromotionActions.getPromotionsList(_keyword));
        // },
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getAPIPromotions: (_keyword) => {
            InitWaitingTime();
            dispatch(PromotionActions.getPromotions(_keyword));
        },
        getAPIPackage: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackage(_keyword));
        },
    }
}
PromotionInfo.propTypes = { classes: PropTypes.object.isRequired, label: PropTypes.string };

const styles = theme => (
    {
        july32017copy: {
            height: '16px',
            // width: '100px',
            opacity: '0.64',
            color: Colors.white,
            fontSize: '16px',
            lineHeight: '12px',
            fontFamily: Family.roboto,
        },
        title_bar: { backgroundColor: Colors.theme, fontFamily: Family.roboto, marginTop: '10px', fontSize: '30px', color: Colors.white, width: '100%', height: '40px' },
        betty_pittman: {
            height: '39px',
            color: Colors.white,
            fontFamily: Family.roboto,
            fontSize: '30px',
            lineHeight: '39px',
        },
        class: {
            height: '16px',
            width: '110px',
            color: Colors.white,
            fontFamily: Family.roboto,
            fontSize: '14px',
            fontWeight: 'bold',
            lineHeight: '12px',
        },
        avataronline: {
            position: 'relative',
            boxShadow: '0 0 1px 1.9px ',
            backgroundRepeat: 'round',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            marginRight: '20%',
        },
        page_container:
        {
            display: 'inline-block',
            width: '100%',
            maxWidth: '1030px',
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PromotionInfo));