export const constraints = {
    levelName: {
        presence: { allowEmpty: false }
    },
    priceType: {
        presence: { allowEmpty: false }
    },
    adult: {
        presence: { allowEmpty: false }
    },
    kid: {
        presence: { allowEmpty: false }
    },
    infant: {
        presence: { allowEmpty: false }
    },
};
export const input = [
    { fieldName: 'levelName', },
    { fieldName: 'priceType', },
    { fieldName: 'adult', },
    { fieldName: 'kid', },
    { fieldName: 'infant', },
];
