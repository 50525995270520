
const Symbol = { THB: '฿', USD: '$', JPY: '￥', CNY: '￥' };
const Local = { THB: 'THB', USD: 'USD', JPY: 'JPY', CNY: 'CNY' };

export const Cuurency = { THB: 'THB', USD: 'USD', CNY: 'CNY' };

export const GetSymbol = (_currency) => {

    // console.log('GetSymbol : ', _currency)
    switch (_currency) {
        case Local.THB: return Symbol.THB;
        case Local.USD: return Symbol.USD;
        case Local.JPY: return Symbol.JPY;
        case Local.CNY: return Symbol.CNY;
        case '': return _currency;
        default: return Symbol.THB;
    }

}

export const AgeCondition = { Adult: 'Age 13+ Yrs', Kid: 'Age 4 - 12 Yrs', Infant: 'Age 0 - 3 Yrs' };