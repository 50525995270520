
const HeaderPage = {
    CREATE: "Create CloseService",
    UPDATE: "Update CloseService",
}

const BodyPage = [
    [{
        type: 'text', require: true,
        title_EN: 'Name Category', title_TH: '', title_CN: '',
        stateName: 'nameCategory',
        style: { marginRight: 15 }
    }, {
        type: 'number', require: true,
        title_EN: 'Order Category', title_TH: '', title_CN: '',
        stateName: 'order',
        style: {}
    }], [{
        type: 'text-multiline', require: false,
        title_EN: 'Description', title_TH: '', title_CN: '',
        stateName: 'description',
        style: {}
    }], [{
        type: 'listItem', require: false,
        title_EN: 'Package Category', title_TH: '', title_CN: '',
        stateName: 'packageList',
        style: {}
    }], [{
        type: 'checkbox', require: false,
        title_EN: 'visible', title_TH: '', title_CN: '',
        stateName: 'visible',
        // style: { padding: 0 }
    }]
]

export default {
    HeaderPage,
    BodyPage,
}