import Themes from './Themes';
import ThemesDark from './ThemesDark';
import Icons from './Icons';
import Pickers from './Pickers';
import Calendar from './Calendar';

export default {
    Themes,
    ThemesDark,
    Icons,
    Pickers,
    Calendar,

}