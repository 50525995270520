import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_APPOINTMENT_SUCCESS = 'GET_APPOINTMENT_SUCCESS';
export const GET_APPOINTMENT_FAILURE = 'GET_APPOINTMENT_FAILURE';

export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE';

export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';
export const UPDATE_APPOINTMENT_FAILURE = 'UPDATE_APPOINTMENT_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

// getAppointmentList ============================================================================================================================

export function getAppointmentList(_keyword = '', _fileterjson = {}) {

    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAppointmentList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getAppointmentList : ", response);
            localStorage.setItem('AppointmentList', response.data)
            dispatch(getAppointmentList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAppointmentList Failure", error);
            dispatch(getAppointmentList_Failure(error));
        })
    }
}

function getAppointmentList_Success(data) {
    return { type: GET_APPOINTMENT_SUCCESS, payload: data };
}

function getAppointmentList_Failure(error) {
    return { type: GET_APPOINTMENT_FAILURE, payload: error };
}

// getAppointmentList =========================================================================================================================


export function APICreateAppointment(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createAppointment}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateAppointment : ", response.data);
            dispatch(APICreateAppointment_Success(response.data));

        }).catch((error) => {
            console.log("APICreateAppointment fail", error);
            dispatch(APICreateAppointment_Failure(error));
        })
    }
}

function APICreateAppointment_Success(data) {
    return { type: CREATE_APPOINTMENT_SUCCESS, payload: data };
}

function APICreateAppointment_Failure(error) {
    return { type: CREATE_APPOINTMENT_FAILURE, payload: error };
}

// updateAppointmentList =========================================================================================================================
export function APIUpdateAppointment(confirmInfo) {
    console.log('APIUpdateAppointment : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateAppointment}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { appointmentId: confirmInfo.appointmentId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateAppointment : ", response.data);
            dispatch(APIUpdateAppointment_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateAppointment fail", error);
            dispatch(APIUpdateAppointment_Failure(error));
        })
    }
}

function APIUpdateAppointment_Success(data) {
    return { type: UPDATE_APPOINTMENT_SUCCESS, payload: data };
}

function APIUpdateAppointment_Failure(error) {
    return { type: UPDATE_APPOINTMENT_FAILURE, payload: error };
}