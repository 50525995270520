import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

import { Colors, Svg, Images, Fonts, Family } from '../../themes';

import CardInfo from './CardInfo.jsx';
import RowPackagesCard from './RowPackagesCard.jsx';
import ProductListPanel from './ProductListPanel.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

import * as CountryActions from '../../actions/Actions_CountryManager';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as LocationActions from '../../actions/Actions_LocationServicesManager';

import './Card.css';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';
let CountryList = [];
let LocationServicesList = [];
let waitingTime = true;
let _packgaeList = [];

const Levles = { country: 'country', location: 'location', package: 'pacage' };

class CountryPanel extends Component {

    state = { isLoading: true, level: Levles.country };

    constructor(props) {

        super(props);
        this.props.getCountryList('', { orderBy: 'desc', sortFilde: 'visible' });
        this.props.getAPILocationServicesList('', { where: { isService: true } });
    }

    componentWillReceiveProps(nextProps) {

        const { ZoneManager, CountryManager, LocationServicesManager, PackageManager } = nextProps;

        if (!!ZoneManager) {

            // const { actions, data } = ZoneManager.ZONE_REDUCER;

            // switch (actions) {

            //     case ZoneActions.GET_ZONE_SUCCESS:
            //         {
            //             ZoneList = data.result.datas;
            //             SetStateWithTime(this, waitingTime);
            //         } break;
            //     case ZoneActions.GET_ZONE_FAILURE:
            //         { SetStateWithTime(this, waitingTime); } break;
            // }
        }

        if (!!CountryManager) {

            const { actions, data } = CountryManager.COUNTRY_REDUCER;

            switch (actions) {
                case CountryActions.GET_COUNTRY_SUCCESS:
                    {
                        CountryManager.state = CountryActions.WATING_STATE;
                        CountryList = data.result.datas || [];
                        // CountryList.sort((a, b) => (b.visible ? 1 : -1));
                        // SetStateWithTime(this, waitingTime);
                    } break;
                case CountryActions.GET_COUNTRY_FAILURE:
                    {
                        // SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!LocationServicesManager) {

            const { actions, data } = LocationServicesManager.LOCATIONSERVICES_REDUCER;

            switch (actions) {

                case LocationActions.GET_LOCATIONSERVICES_SUCCESS:
                    {
                        LocationServicesList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case LocationActions.GET_LOCATIONSERVICES_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!PackageManager) {

            const { data, actions, error } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {

                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        _packgaeList = data || [];
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { level, loading, name, locationServiceId, isLoading } = this.state;
        const { List, onClickHandle, onClickCardHandle, isVisible, isMobile, } = this.props;
        const { packageList = [] } = _packgaeList;

        switch (level) {
            case Levles.country:
                {
                    console.log('CountryList : ', CountryList);

                    return (
                        <PanelContiner>
                            <StepCotianer>
                                <StepLable>{`Country`}</StepLable>
                            </StepCotianer>
                            {(isLoading) && <DialogPanel Active={isLoading} OnClose={() => this.onClosePanel} />}
                            <GridList cols={5} style={style.gridList} spacing={1}>
                                {(CountryList.length > 0) && CountryList.map(info => (
                                    <GridListTile style={style.gridTileStyle} key={info.id}>
                                        <CardInfo info={info} level={level} isVisible={info.visible} itemHandle={this.handleLevelsDisplay} onClickCardHandle={onClickCardHandle} />
                                    </GridListTile>))}
                            </GridList>
                        </PanelContiner >
                    )
                }
            case Levles.location:
                {
                    console.log('LocationServicesList : ', LocationServicesList);

                    return (
                        <PanelContiner>
                            <StepCotianer>
                                <StepLable onClick={() => this.handleBack(Levles.country)} isActive>{`Country > `}</StepLable>
                                <StepLable>{`Location Service `}</StepLable>
                            </StepCotianer>
                            {(isLoading) && <DialogPanel Active={isLoading} OnClose={() => this.onClosePanel} />}
                            <GridList cols={5} style={style.gridList} spacing={1}>
                                {(LocationServicesList.length > 0) && LocationServicesList.map(info => (
                                    <GridListTile style={style.gridTileStyle} key={info.id}>
                                        <CardInfo info={info} level={level} isVisible={info.isService} itemHandle={this.handleLevelsDisplay} onClickCardHandle={onClickCardHandle} />
                                    </GridListTile>))}
                            </GridList>
                        </PanelContiner >
                    )
                }
            case Levles.package:
                {
                    // console.log('List : ', packageList);

                    return (
                        <PanelContiner>
                            <StepCotianer>
                                <StepLable onClick={() => this.handleBack(Levles.country)} isActive>{`Country > `}</StepLable>
                                <StepLable onClick={() => this.handleBack(Levles.location)} isActive>{`Location Service > `}</StepLable>
                                <StepLable>{`${name || 'Package'} `}</StepLable>
                            </StepCotianer>
                            <ProductListPanel LocationServiceId={locationServiceId} onClickHandle={onClickHandle} isVisible={isVisible} />
                        </PanelContiner >
                    )
                }
        }
    }

    StepHistoryPanel() {

    }

    handleLevelsDisplay = (value) => () => {

        console.log("handleLevelsDisplay : ", value);

        const { level, info } = value;
        const { name_EN, locationServiceId } = info;

        switch (level) {
            case Levles.country:
                {
                    this.setState({ level: Levles.location });
                } break;
            case Levles.location:
                {
                    this.props.getAPIPackageList({ limit: 100, visible: true });
                    this.setState({ level: Levles.package, name: name_EN, locationServiceId: locationServiceId });
                } break;
            case Levles.package:
                {

                } break;
            default:
                {

                }
        }
    }

    handleBack = (level) => {

        console.log("handleBack : ", level);

        switch (level) {
            case Levles.country:
                {
                    this.setState({ level: Levles.country });
                } break;
            case Levles.location:
                {
                    this.setState({ level: Levles.location });
                } break;
            case Levles.package:
                {

                } break;
            default:
                {

                }
        }
    }

    onClosePanel = () => (this.setState({ isLoading: false, }));

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getCountryList: (_keyword, _filter) => {
            // InitWaitingTime();
            dispatch(CountryActions.getCountryList(_keyword, _filter));
        },
        getAPILocationServicesList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(LocationActions.getLocationServicesList(_keyword, _filter));
        },
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CountryPanel);

// export default CountryPanel;

const PanelContiner = styled.div`
width: 100%;
margin-top: 10px;
max-width: 1024px;`;

const StepLable = styled.label`
font-size: 18px;
color: ${Colors.theme};
font-weight: 600;
font-family: ${Family.roboto};
${(props) => props.isActive && css`cursor: pointer;color:#000000;`}`;

const StepCotianer = styled.div`    
text-align: left;
padding: 10px 0px 20px 0px;`;

const Card = styled.div`
width: 500px;
display: flex;
margin: 5px 0px ;
cursor: pointer;`;

// const Card = styled.div`
// height: 211px;
// width: 500px;
// display: flex;
// margin: 10px 0px;
// background-color: rgb(255, 255, 255);
// box-shadow: rgba(0, 0, 0, 0.18) 3px 2px 10px 0px;
// cursor: pointer;`;

const _isFirefox = false;
const maxContentWidth = 1024;

const style = {
    gridList:
    {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        // display: _isFirefox ? '-moz-box' : '-webkit-box',
    },
    gridListMax:
    {
        height: '100%',
        display: _isFirefox ? '-moz-box' : '-webkit-box',
        maxWidth: maxContentWidth,
        minWidth: maxContentWidth,
    },
    gridTabletList:
    {
        width: '100%',
        height: '100%',
        minWidth: 600,
        maxWidth: maxContentWidth,
    },
    gridMobileList:
    {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        display: '-webkit-box',
    },
    gridTileStyle: {
        width: 'unset',
        height: '100%',
        // marginRight: 18,
        marginBottom: 15,
    },
};
