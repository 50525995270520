import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { SetStateWithTime, } from '../../common/SetTime.js';
import { SetDateTo, } from '../../common/SetDate.js';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import EmptyRows from '../../componets/EmptyRow.jsx';
import { blue } from '@material-ui/core/colors';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import DialogPanel from '../../componets/DialogPanel.jsx';

import * as PromotionActions from '../../actions/Actions_PromotionManager';
const { DoneIcon, CloseIcon,  } = Icons;

const fildeList = [
    { label: 'PROMOTION NAME', name: 'name', isOrder: true, },
    { label: 'DESCRIPTION', name: '', isOrder: false, },
    { label: 'PRICE', name: '', isOrder: false, },
    // { label: 'TYPE', name: '', isOrder: false, },
    { label: 'BOOKING DATE', name: '', isOrder: true, },
    { label: 'TRAVEL DATE', name: '', isOrder: true, },
    { label: 'EXP DATE', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: false, },];

const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', };
const _wording = { header: 'Promotion List.' }

let PromotionsList = [];
let waitingTime = false;

class PromotionListPanel extends Component {

    state = { isLoading: false, isCreate: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps(nextProps) {

        const { PromotionManager } = nextProps;

        if (!!PromotionManager) {
            const { actions, } = PromotionManager.PROMOTIONS_REDUCER;//PackageManager

            switch (actions) {
                case PromotionActions.GET_PROMOTIONS_SUCCESS:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PromotionActions.GET_PROMOTIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, DisplayPromotionInfo, onCreateEvent } = this.props;
        const isPromotion = (PromotionsList.length > 0);
        PromotionsList = (!!this.props.PromotionsList) ? (this.props.PromotionsList.datas || []) : [];

        const { isLoading, msgError, dialogType } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ margin: 5, textAlign: 'center' }} onClick={() => onCreateEvent()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                   <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button className={classes.title_bar} disabled={true} >{_wording.header}</Button>
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ margin: 5, textAlign: 'center' }} onClick={() => onCreateEvent()}>
                                        <Button variant="fab" aria-label="add" className={classes.cssRoot}>
                                            <AddIcon />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isPromotion) ? GetBookingRows(PromotionsList, DisplayPromotionInfo, classes) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(isPromotion) && GetPagination(this.props.PromotionsList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        );
    }

    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        //console.log('handleRequestSort : ', _fildeName, packageInfoList)

        PromotionsList = (orderBy === 'desc')
            ? PromotionsList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : PromotionsList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        this.props.getAPIPromotionsList({ page: 0, num_limit: _limitRows });
    }

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });
        this.props.getAPIPromotionsList({ page: _pageNumber, num_limit: _limit });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

function GetBookingRows(PromotionsList, DisplayPromotionInfo, classes) {

    return PromotionsList.map(_info => {
        const { id, description, name, infant, adult, kid, travelDate, startDate, endDate, visible, expDate, type, percent_adult, percent_kid } = _info;
        // const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${picture})`, backgroundRepeat: 'round' };
        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={id} >
                <TableCell style={{ ..._callName, width: 200 }} onClick={() => DisplayPromotionInfo(_info)}>{name || ''}</TableCell>
                <TableCell style={{ ..._callName, width: 200 }} onClick={() => DisplayPromotionInfo(_info)} >{description || ''}</TableCell>
                <TableCell style={_callStyle} onClick={() => DisplayPromotionInfo(_info)}>{type === 'percent' ? `${percent_adult}%/${percent_kid}%` : `${adult}/${kid}`}</TableCell>
                {/* <TableCell    style={_callStyle} onClick={() => DisplayPromotionInfo(_info)} >{type || ''}</TableCell> */}
                <TableCell style={{ ..._callName, width: 150 }} onClick={() => DisplayPromotionInfo(_info)} >{SetDateTo(startDate, endDate)}</TableCell>
                <TableCell style={{ ..._callName, width: 150 }} onClick={() => DisplayPromotionInfo(_info)} >{SetDateTo(travelDate.startDate, travelDate.endDate)}</TableCell>
                <TableCell style={{ ..._callStyle, ...{ color: expDate ? Colors.success : Colors.error } }}>{GetIconWithStatus(expDate)}</TableCell>
                <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }}>{GetIconWithStatus(visible)}</TableCell>
            </TableRow>
        );
    });
}
function GetIconWithStatus(_status) {

    return ((_status) ? <DoneIcon /> : <CloseIcon />)
}
function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 25}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}


const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        table: { minWidth: 1024, },
    });

PromotionListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPromotionsList: (_keyword, _filter) => {
            // InitwaitingTime();
            dispatch(PromotionActions.getPromotionsList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PromotionListPanel));