import React, { Component } from 'react';
import { path } from 'ramda';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import CONFIG from '../../config';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Colors, Family } from '../../themes';

class SelectPackageCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
        }
    }
    render() {
        const { PackageAll, classes, onSavePanelPackage, PackageLists, promotionCodeInfo, promotionInfo, handleToggle, PromotionsAll, onReset } = this.props;
        let Promotion = Array.isArray(PackageLists) ? [] : PromotionsAll.filter(_promotion => (new Date(path(['bookingDate', 'startDate'], promotionInfo)) < (Date.parse(_promotion.endDate)) && _promotion.visible));
        let PackageList = Array.isArray(PackageLists) ? [] : PackageAll && PackageAll.package.filter(_package => !(Promotion.some(_promotion => (_promotion.packageId.some(_id => (_id === _package.packageId) && (_package.visible))))));
        let Display_Package = [];
        let Display = [];

        if (PackageAll && PackageAll.package.length > 0) {
            Display_Package = PackageList.filter(each => new RegExp(this.state.searchText, "i").test(each.packageInfo.name))
        }
        if (PackageLists && PackageLists.length > 0) {
            Display = PackageLists.filter(each => new RegExp(this.state.searchText, "i").test(each.packageInfo.name));
        }
        const showDisplay = Array.isArray(PackageLists) ? Display : Display_Package;
        return (
            <div style={{ display: 'inline-block', width: '100%', maxWidth: 800 }}>
                <div >
                    <TextField id='search' margin="normal"
                        value={this.state.searchText}
                        onChange={(e) => this.setState({ searchText: e.target.value })}
                        className={classes.textField}
                        InputProps={{ disableUnderline: false, classes: { input: classes.selectInput, }, }}
                        InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} />
                </div>
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: '400px', paddingBottom: '17px', marginBottom: '15px' }}>
                    {
                        showDisplay.map((value, index) => {
                            const _backgroundImage = { width: '60px', height: '40px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${value.packageInfo.image.thumbnail})`, backgroundRepeat: 'round' };
                            return (
                                <ListItem key={index} style={{ paddingLeft: '15px', border: `${'1px solid' + Colors.border}`, marginTop: '10px', }} dense button
                                    onClick={handleToggle(value)}>
                                    <Checkbox color="primary"
                                        checked={Array.isArray(PackageLists) ? promotionCodeInfo.product.indexOf(value.packageId) !== -1 : promotionInfo.packageId.indexOf(value.packageId) !== -1}
                                        tabIndex={-1} disableRipple />
                                    <div style={_backgroundImage} />
                                    <ListItemText style={{ fontSize: '14px', }} primary={`${value.packageInfo.name}`} />
                                </ListItem>
                            )
                        }
                        )
                    }
                </List>
                <div style={{ textAlign: 'center', fontSize: '16px', display: 'flex', }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_OK}
                        disabled={(Array.isArray(PackageLists) ? !promotionCodeInfo.product.length > 0 : !promotionInfo.packageId.length > 0) && true}
                        onClick={() => onSavePanelPackage()}
                    />
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingLeft: '15px', }}
                        onClick={() => onReset()}
                    />
                </div>
            </div>
        );
    }
}
SelectPackageCard.propTypes = { classes: PropTypes.object.isRequired, };

const styles = theme => (
    {
        textField: {
            width: '100%',
            marginTop: 5,
            marginBottom: 5
        },
        textFormLabel: {
            fontSize: 18,
            marginTop: 0,
            fontFamily: Family.roboto,
        },
        selectInput: {
            marginTop: 5,
            padding: 10,
            paddingRight: '20px',
            color: Colors.themeDark,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 16,
            fontFamily: Family.roboto,
        },
    });

export default (withStyles(styles)(SelectPackageCard));
