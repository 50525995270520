
import * as AuthenActions from '../actions/Actions_AuthenManager.js';

const INITIAL_STATE = { AUTHEN_REDUCER: { actions: AuthenActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: AuthenActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case AuthenActions.INITIAL_STATE:
            {
                return state;
            }
        case AuthenActions.IDLE_STATE:
            {
                //console.log("[ Authen Actions ] : IDLE_STATE : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.IDLE_STATE, data: payload, error: _error } };
            }
        case AuthenActions.AUTHEN_SUCCESS:
            {
                // console.log("[ Authen Actions ] : AUTHEN_SUCCESS : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.AUTHEN_SUCCESS, data: payload, error: _error } };
            }
        case AuthenActions.AUTHEN_FAILURE:
            {
                console.log("[ Authen Actions ] : AUTHEN_FAILURE : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.AUTHEN_FAILURE, data: payload, error: _error } };
            }
        case AuthenActions.AUTHEN_LB_SUCCESS:
            {
                // console.log("[ Authen Actions ] : AUTHEN_LB_SUCCESS : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.AUTHEN_LB_SUCCESS, data: payload, error: _error } };
            }
        case AuthenActions.AUTHEN_LB_FAILURE:
            {
                console.log("[ Authen Actions ] : AUTHEN_LB_FAILURE : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.AUTHEN_LB_FAILURE, data: payload, error: _error } };
            }
        case AuthenActions.FORGOT_PASSWORD_LB_SUCCESS:
            {
                //console.log("[ Authen Actions ] : FORGOT_PASSWORD_LB_SUCCESS : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.FORGOT_PASSWORD_LB_SUCCESS, data: payload, error: _error } };
            }
        case AuthenActions.FORGOT_PASSWORD_LB_FAILURE:
            {
                console.log("[ Authen Actions ] : FORGOT_PASSWORD_LB_FAILURE : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.FORGOT_PASSWORD_LB_FAILURE, data: payload, error: _error } };
            }
        case AuthenActions.CHANGE_PASSWORD_LB_SUCCESS:
            {
                // console.log("[ Authen Actions ] : CHANGE_PASSWORD_LB_SUCCESS : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.CHANGE_PASSWORD_LB_SUCCESS, data: payload, error: _error } };
            }
        case AuthenActions.CHANGE_PASSWORD_LB_FAILURE:
            {
                console.log("[ Authen Actions ] : CHANGE_PASSWORD_LB_FAILURE : ", payload);
                return { ...state, AUTHEN_REDUCER: { actions: AuthenActions.CHANGE_PASSWORD_LB_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Authen Actions ]  default :", state);
                return state;
            }
    }
}
