export const constraints = {
    name: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    description: {
        presence: {
            allowEmpty: false,
            message: "can't be blank"
        }
    },

};
export const input = [
    {        fieldName: 'name',    },
    {        fieldName: 'description',    },
];
