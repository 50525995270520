import React, { Component } from 'react'
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import DialogAction from '../../componets/DialogAction'
import DialogPanel from '../../componets/DialogPanel.jsx';

import Button from '../../constant/Button';
import { Colors, Icons } from '../../themes';
import { GetSymbol } from '../../common/GetSymbol.js';
import { NumberFormats } from '../../common/SetNumberFormat';
import { GetStatusColor } from '../../common/GetStatusColor';
import { SetDate } from '../../common/SetDate';

import * as RefundActions from '../../actions/Actions_RefundManager';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import RefundRequestPanel from './RefundRequestPanel';
import TableComponent from '../../componets/TableComponent.jsx';

const { BorderColorIcon } = Icons;
let RefundRequest = [];
let waitingTime = true;

const fildeList = [
    { label: 'REFUND ID', name: '', isOrder: true },
    { label: 'DATE', name: '', isOrder: false },
    { label: 'REFUND BY', name: '', isOrder: false },
    { label: 'NAME', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: 'AMOUNT', name: '', isOrder: false },
    { label: '', name: '', isOrder: false },
];

const fildeListsMobile = [
    { label: 'DATE', name: '', isOrder: false },
    { label: 'REFUND BY', name: '', isOrder: false },
    { label: 'NAME', name: '', isOrder: false },
    { label: 'STATUS', name: '', isOrder: false },
    { label: 'AMOUNT', name: '', isOrder: false },
    { label: '', name: '', isOrder: false },
];

/* Desktop */
const _callStyle = { textAlign: 'center', padding: '5px', border: `${'1px solid' + Colors.white}` };
const _callName = { ..._callStyle, textAlign: 'left', paddingRight: '15px', maxWidth: 280 };

/* Mobile */
const _callStyleMobile = { ..._callStyle, padding: 2, fontSize: 11, width: 50, };
const _callNameMobile = { ..._callStyleMobile, textAlign: 'left', maxWidth: 80, };


class RefundRequestListPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, isView: false, isRequestPanel: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false
        }
    }
    componentWillReceiveProps(nextProps) {
        const { RefundManager } = nextProps;

        if (!!RefundManager) {
            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.GET_REFUND_REQUEST_LISTS_SUCCESS:
                    {
                        RefundManager.REFUND_REDUCER.actions = RefundActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case RefundActions.GET_REFUND_REQUEST_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

    }
    render() {
        const { classes, RefundRequestList, isMobile, } = this.props;
        const { isLoading, msgError, dialogType, orderBy, isRequestPanel, isView, requestInfo, searchActive, } = this.state;

        RefundRequest = RefundRequestList.data || [];
        const _isRequest = (RefundRequest.length > 0);
        let fildeLists = (isMobile) ? fildeListsMobile : fildeList;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isRequestPanel) && <DialogAction Active={isRequestPanel} OnClose={this.onClosePanel} AcrionCom={this.RequestPanel(isView, requestInfo)} />}

                <TableComponent
                    orderBy={orderBy}
                    isActive={_isRequest}
                    FildeLists={fildeLists}
                    HandleRequestSort={this.handleRequestSort}
                    ListCount={RefundRequestList}
                    onNextPage={this.onNextPage}
                    onChangeLimit={this.onChangeLimit}
                    GetBookingRows={GetBookingRows(classes, RefundRequest, this.DisplayPanel, isMobile)} />
            </React.Fragment>
        )
    }

    RequestPanel = (isView, requestInfo) => (
        <RefundRequestPanel isView={isView} requestInfo={requestInfo} onClosePanel={this.onClosePanel} />
    )

    DisplayPanel = (_action, _info) => {
        switch (_action) {
            case 'view': {
                this.setState({ isRequestPanel: true, isView: true, requestInfo: _info })
            } break;
            case 'edit': {
                this.setState({ isRequestPanel: true, isView: false, requestInfo: _info })
            } break;
        }
    };

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        RefundRequest = (orderBy === 'desc')
            ? RefundRequest.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : RefundRequest.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));
        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onNextPage = (_pageNumber, limit) => {
        this.setState({ isLoading: true }, () => this.props.APIRefundRequestList({ page: _pageNumber, num_limit: (limit || 25) }));
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true }, () => this.props.APIRefundRequestList({ page: 0, num_limit: _limitRows }));
    };

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
    onClosePanel = () => (this.setState({ isRequestPanel: false, isView: false }));

}

function GetBookingRows(classes, RefundRequest, DisplayPanel, isMobile) {

    return RefundRequest.map(_info => {
        const { refundId, createDate, refundBy, refundStatus, amount, currency, customerRequestData } = _info;

        const isCallStyle = (isMobile) ? _callStyleMobile : _callStyle;
        const isCallNameStyle = (isMobile) ? _callNameMobile : _callName;
        let name = null;
        switch (refundBy) {
            case 'supplier': {
                name = customerRequestData.name
            } break;
            case 'customer': {
                name = customerRequestData.fullName
            } break;
        }
        return (
            <TableRow hover tabIndex={-1} className={classes.row} key={refundId} style={{ cursor: 'pointer' }} >
                {(!isMobile) && <TableCell style={isCallStyle} onClick={() => DisplayPanel('view', _info)}>{refundId}</TableCell>}
                <TableCell style={isCallStyle} onClick={() => DisplayPanel('view', _info)}>{SetDate(createDate)}</TableCell>
                <TableCell style={isCallStyle} onClick={() => DisplayPanel('view', _info)}>{refundBy || '-'}</TableCell>
                <TableCell style={isCallNameStyle} onClick={() => DisplayPanel('view', _info)}>{name || '-'}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, refundStatus)} onClick={() => DisplayPanel('view', _info)}>{refundStatus || '-'}</TableCell>
                <TableCell style={GetStatusColor('Request', isCallStyle, refundStatus)} onClick={() => DisplayPanel('view', _info)}>{`${GetSymbol(currency)}${NumberFormats(amount || 0)}`}</TableCell>
                <TableCell style={isCallStyle}>
                    <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { DisplayPanel('edit', _info) }} />
                </TableCell>
            </TableRow>)
    });
}

const styles = theme => (
    {
        row: {
            '&:nth-of-type(odd)': { backgroundColor: Colors.themeDark_Light },
            '&:nth-of-type(odd):hover': { backgroundColor: Colors.themeDark_Item },
        },
    });

RefundRequestListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIRefundRequestList: (_filter) => {
            InitWaitingTime();
            dispatch(RefundActions.APIRefundRequestList(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RefundRequestListPanel));
