import { Province } from './Province';

const HeaderPage = {
    TH: "สร้างซัพพลายเออร์",
    EN: "Create Suppliers",
    CN: "创建供应商",
}

const BodyPage = [
    [{
        title_TH: "ชื่อซัพพลายเออร์", title_EN: "Supplier Name", title_CN: "供应商名称",
        type: "text",
        require: true,
        action: true,
        margin: 'right',
        display: "input",
        stateName: "name",
        placeholder_TH: "ชื่อซัพพลายเออร์", placeholder_EN: "Insert Supplier Name", placeholder_CN: "插入供应商名称",
    }, {
        title_TH: "อีเมล์ซัพพลายเออร์", title_EN: "Supplier Email", title_CN: "供应商电邮",
        type: "email",
        require: true,
        action: true,
        display: "input",
        stateName: "email",
        placeholder_TH: "อีเมล์ซัพพลายเออร์", placeholder_EN: "Insert Supplier Email", placeholder_CN: "插入供应商电邮",
    }], [{
        title_TH: "ประเภทซัพพลายเออร์", title_EN: "Supplier Type", title_CN: "供应商类型",
        type: "select-multiple",
        action: false,
        margin: 'right',
        stateName: "type",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: SelectSupplierType,
    }, {
        title_TH: "หมายเลขการจอง", title_EN: "Reservation Number", title_CN: "预约号码",
        type: "text",
        action: false,
        stateName: "reservation_number",
        placeholder_TH: "หมายเลขการจอง", placeholder_EN: "Insert Reservation Number", placeholder_CN: "插入预订编号",
    }], [{
        title_TH: "เว็บไซต์", title_EN: "Official Website", title_CN: "官方网站",
        type: "text",
        action: true,
        display: "input",
        stateName: "officialWebsite",
        placeholder_TH: "เว็บไซต์", placeholder_EN: "Insert Official Website", placeholder_CN: "插入官方网站",
    }], [{
        title_TH: "เบอร์โทรศัพท์", title_EN: "Telephone Number", title_CN: "电话号码",
        type: "number",
        action: true,
        display: "input",
        margin: 'right',
        stateName: "telOffice",
        placeholder_TH: "เบอร์โทรศัพท์", placeholder_EN: "Insert Telephone Number", placeholder_CN: "插入电话号码",
    }, {
        title_TH: "เบอร์ฉุกเฉิน", title_EN: "Emergency", title_CN: "急",
        type: "number",
        action: true,
        display: "input",
        margin: 'right',
        stateName: "tel",
        placeholder_TH: "เบอร์ฉุกเฉิน", placeholder_EN: "Insert Emergency", placeholder_CN: "插入紧急",
    }, {
        title_TH: "บุคคล", title_EN: "Person", title_CN: "人",
        type: "text",
        action: true,
        display: "input",
        stateName: "person",
        placeholder_TH: "บุคคล", placeholder_EN: "Insert Name", placeholder_CN: "插入名称",
    }], [{
        title_TH: "เลขที่", title_EN: "No", title_CN: "号",
        type: "text",
        action: false,
        margin: 'right',
        width: "30%",
        stateName: "no",
        placeholder_TH: "เลขที่", placeholder_EN: "Insert No", placeholder_CN: "插入号码",
    }, {
        title_TH: "ถนน", title_EN: "Road", title_CN: "路",
        type: "text",
        action: false,
        width: "65%",
        stateName: "road",
        placeholder_TH: "ถนน", placeholder_EN: "Insert Road", placeholder_CN: "插入道路",
    }, {
        pro_TH: "จังหวัด", pro_EN: "Province", pro_CN: "省",
        dis_TH: "ตำบล", dis_EN: "District", dis_CN: "区",
        type: "search_select",
        action: false,
        stateName_pro: "province", stateName_dis: "district",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
        options: Province,
    }], [{
        title_TH: "รหัสไปรษณีย์", title_EN: "Postal Code", title_CN: "邮政编码",
        type: "number",
        action: false,
        margin: 'right',
        stateName: "post-code",
        placeholder_TH: "รหัสไปรษณีย์", placeholder_EN: "Insert Postal Code", placeholder_CN: "插入邮政编码",
    }, {
        title_TH: "ประเทศ", title_EN: "Country", title_CN: "国家",
        type: "select",
        action: false,
        stateName: "country",
        placeholder_TH: "กรุณาเลือก", placeholder_EN: "Please Select", placeholder_CN: "请选择",
    }], [{
        // label_TH: "เลขที่บัญชี", label_EN: "Account Number", label_CN: "帐号",
        // type: "select_checkbok",
        // action: true,
        // display: "Bookbank",
        // stateName_bank: "bankName", stateName_account: "accountNumber", stateName_id: "bankId",
        // button_TH: "ธนาคาร", button_EN: "Bank", button_CN: "银行",
        // select_TH: "กรุณาเลือกธนาคาร", select_EN: "Please select a bank", select_CN: "请选择一家银行",
        // placeholder_TH: "กรุณาเลือกธนาคาร", placeholder_EN: "Please select a bank", placeholder_CN: "请选择一家银行",

        title_TH: "---", title_EN: "BookBank", title_CN: "---",
        type: 'select_checkbok',
        action: true,
        display: "Bookbank",
        stateName: 'bank',
        stateName_account: 'accountNumber'
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        action: true,
        display: "switch",
        stateName: "visible",
        placeholder_TH: "สถานะ", placeholder_EN: "Insert Status", placeholder_CN: "插入状态",
    }], [{
        title_TH: "รูปสัญลักษณ์", title_EN: "Logo", title_CN: "商标",
        type: "image",
        action: true,
        display: "image",
        stateName: "logo",
        placeholder_TH: "รูปสัญลักษณ์", placeholder_EN: "Insert Logo", placeholder_CN: "插入徽标",
    }]
]

const SelectCountry = [{
    name_TH: "ประเทศไทย",
    name_EN: "Thailand",
    name_CN: "泰国",
    code: "66"
}]

const SelectSupplierType = [
    {
        title_TH: "วันเดียว", title_EN: "Day Tour", title_CN: "汽车",
        active: true,
        key: 'OneDayTrip',
        id: "5af3ce5fcb05af349c4202de"
    }, {
        title_TH: "เรือ", title_EN: "Ferries", title_CN: "船",
        active: true,
        key: 'Ferries',
        id: "5af3ce90cb05af349c4202df"
    }, {
        title_TH: "บริการ", title_EN: "Service", title_CN: "旅馆",
        active: true,
        key: 'Service',
        id: "5af3cea1cb05af349c4202e0"
    }, {
        title_TH: "หลายวัน", title_EN: "Transfer", title_CN: "旅馆",
        active: true,
        key: 'Transfer',
        id: "5af3cea1cb05af349c4202e1"
    }
]

export default {
    HeaderPage,
    BodyPage,
    SelectCountry,
    SelectSupplierType
}