// const validate = require("validate.js");

// export function validatePassword(data, callback) {
//     let constraints = {
//         oldPassword: {
//             presence: {
//                 allowEmpty: false,
//             },
//         },
//         newPassword: {
//             equality: "confirmPassword",
//             length: {
//                 minimum: 8,
//                 message: "must be at least 8 characters."
//             }
//         },
//         confirmPassword: {
//             equality: "newPassword"
//         }
//     }
//     callback
//         (validate(data, constraints))
// }


export const constraints = {
    oldPassword: {
        presence: { allowEmpty: false }
    },
    newPassword: {
        // equality: "confirmPassword",
        length: {
            minimum: 8,
            message: "must be at least 8 characters."
        }
    },
    confirmPassword: {
        // equality: "newPassword"
        length: {
            minimum: 8,
            message: "must be at least 8 characters."
        }
    },
};
export const input = [
    { fieldName: 'oldPassword', },
    { fieldName: 'newPassword', },
    { fieldName: 'confirmPassword', },
];
