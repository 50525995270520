import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

// import { BorderColorIcon } from '../themes/Icons';
// import { GetStatusColor } from '../common/GetStatusColor';
import { Colors } from '../themes';
import { isMobile } from '../common/DeviceCheck.js';
import AddIcon from '@material-ui/icons/Add';

import Button from '../constant/Button';
import EmptyRows from './EmptyRow.jsx';
import SortHandle from './TableManage/SortHandle.jsx';
// import SearchPanel from './SearchPanel.jsx';
import Language from '../constant/Language';

let _isMobile = isMobile();

const styles = theme => (
    {
        root: { width: '100%', maxWidth: 1024 },
        header: {
            fontSize: (_isMobile) ? 10 : 12,
            backgroundColor: Colors.themeDark,
            color: Colors.white,
            textAlign: 'center',
            // padding: 5,
            border: `${'1px solid' + Colors.white}`
        },
        tableRow: { height: 50 },
        table: { minWidth: 1024, },
        tableMobile: { width: '100%' },
        textField: { width: 400, },
        customTable: {
            textAlign: 'center',
            paddingLeft: '30px',
            paddingRight: '5px',
            backgroundColor: Colors.themeDark,
            color: Colors.white,
            border: `${'1px solid' + Colors.white}`
        },
        row: {
            '&:nth-of-type(odd)': {
                backgroundColor: Colors.themeDark_Light,
            },
            '&:nth-of-type(odd):hover': {
                backgroundColor: Colors.themeDark_Item,
            },
        },
    });

const TableComponent = (props) => {
    const { classes, isActive, isCreate, orderBy, GetBookingRows,
        CreatePanel, HandleRequestSort, onNextPage, onChangeLanguage, onChangeLimit,
        onClickSearch, searchActive, searchType, userInfo, Header, FildeLists, ListCount, onClickCreate } = props;
    let _icon = <AddIcon />
    return (
        <Paper className={classes.root}>
            <Table className={(_isMobile) ? classes.tableMobile : classes.table}>
                <TableHead >
                    {(Header) && <TableRow style={{ height: '100%' }}>
                        <TableCell colSpan={(!isCreate) ? FildeLists.length : FildeLists.length - 1} style={{ padding: 0 }}  >
                            <Button.Themes isTable TitleText={Header} buttonStyle={{ padding: 15 }} />
                            {/* {(searchActive) && <SearchPanel searchActive={searchActive} SearchType={searchType} userInfo={userInfo} />} */}
                        </TableCell>
                        {
                            (isCreate) &&
                            <TableCell colSpan={1} style={{ width: 75, textAlign: 'center' }} >
                                <Button.Icons theme icon={{ _icon, ...props.icon }} tooltip={props.tooltip ? props.tooltip : 'Create'} onClick={onClickCreate} />
                            </TableCell>
                        }
                    </TableRow>}
                    <TableRow className={classes.tableRow} >
                        {FildeLists.map((filde, index) => {
                            return (
                                <TableCell key={index} className={classes.header} style={{ padding: 5 }} >
                                    <SortHandle
                                        style={{ color: Colors.white }}
                                        orderBy={orderBy}
                                        field={filde}
                                        handleRequestSort={HandleRequestSort} />
                                </TableCell>
                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {(isActive) ? GetBookingRows : <EmptyRows fildeList={FildeLists} />}
                </TableBody>
            </Table>
            {(isActive) && GetPagination(ListCount, onNextPage, onChangeLimit, onChangeLanguage)}
        </Paper>
    )
}

function GetPagination(ListCount, onNextPage, onChangeLimit, onChangeLanguage) {

    const { limit, page, pageSize, total, language } = ListCount;
    return (
        <div style={{ display: (language) && 'flex', justifyContent: 'space-between' }}>
            {(language) && <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />}
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
};

// function GetBookingRows(classes, CellLists = [], _isMobile) {

//     const isCallStyle = (_isMobile) ? _callStyleMobile : _callStyle;
//     const isCallNameStyle = (_isMobile) ? _callNameMobile : _callName;
//         const CellLists = [
//             {
//                 id: '1',
//                 onClick: () => console.log('View'),
//                 content: [{
//                     type: 'default',
//                     onClick: () => console.log('View'),
//                     value: 'cell_1'
//                 }, {
//                     type: 'default',
//                     onClick: () => console.log('View'),
//                     value: 'cell_2'
//                 }, {
//                     type: 'name',
//                     onClick: () => console.log('View'),
//                     value: 'cell_3'
//                 }, {
//                     type: 'default',
//                     onClick: () => console.log('View'),
//                     value: 'cell_4'
//                 }, {
//                     type: 'status',
//                     onClick: () => console.log('View'),
//                     header: 'Request',
//                     value: 'cell_5'
//                 }, {
//                     type: 'icon',
//                     onClick: () => console.log('View'),
//                     icon: <BorderColorIcon />,
//                     tooltip: 'Edit',
//                 },]
//             }
//         ];
//     return (
//         CellLists.map(_info => {
//             return (
//                 <TableRow hover tabIndex={-1} className={classes.row} key={_info.id} style={{ cursor: 'pointer' }} >
//                     {(!_isMobile) && <TableCell style={isCallStyle} onClick={_info.onClick}>{_info.id}</TableCell>}
//                     {
//                         _info.content.map(each => {
//                             switch (each.type) {
//                                 case 'default':
//                                     return (<TableCell style={isCallStyle} onClick={each.onClick}>{each.value || '-'}</TableCell>)
//                                 case 'name':
//                                     return (<TableCell style={isCallNameStyle} onClick={each.onClick}>{each.value || '-'}</TableCell>)
//                                 case 'status':
//                                     return (
//                                         <TableCell style={GetStatusColor(each.header, isCallStyle, each.value)} onClick={each.onClick}>
//                                             {each.value || '-'}
//                                         </TableCell>
//                                     )
//                                 case 'icon':
//                                     return (
//                                         <TableCell style={isCallStyle}>
//                                             <Button.Icons icon={each.icon} tooltip={each.tooltip} onClick={each.onClick} />
//                                         </TableCell>
//                                     )
//                             }
//                         })
//                     }
//                 </TableRow>
//             )
//         })
//     )
// };

export default withStyles(styles)(TableComponent)