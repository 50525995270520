export const init_bank = {
    "bankSettingId": "",
    "actived": "",
    "logo": [],
    "TH": {
        "name": "",
        "description": "",
    },
    "EN": {
        "name": "",
        "description": "",
    },
    "CN": {
        "name": "",
        "description": "",
    },
}