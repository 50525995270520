import { Province } from './Province';

const HeaderPage = {
    TH: "สร้างลักษณะ",
    EN: "Update Package",
    CN: "创建包",
}

const BodyPage = [
    [{
        title_TH: "ชื่อ", title_EN: "Package Name", title_CN: "名称",
        type: "text",
        // width: '70%',
        margin: 'right',
        require: false,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }, {
        title_EN: "Package Type", title_TH: "", title_CN: "",
        type: "text",
        require: false,
        stateName: "type",
        placeholder_EN: "ประเภท", placeholder_TH: "Insert Type", placeholder_CN: "",
    }], [{
        title_EN: "Priority Display", title_TH: "", title_CN: "",
        type: "select",
        require: false,
        stateName: "priorityDisplay",
        placeholder_EN: "Please Select", placeholder_TH: "", placeholder_CN: "",
        options: [
            { value: "0", },
            { value: "1", },
            { value: "2", },
            { value: "3", },
            { value: "4", },
            { value: "5", },
        ]
    }], [{
        type: "form-checkbox",
        items: [
            { value: 'attractions', TH: { name: "สถานที่ท่องเที่ยว" }, EN: { name: "ATTRACTIONS" }, CN: { name: "周二" }, stateName: "isAttractions", },
            { value: 'recommends', TH: { name: "แนะนำ" }, EN: { name: "RECOMMENDS" }, CN: { name: "周三" }, stateName: "isRecommends", },
            { value: 'bestseller', TH: { name: "แนะนำ" }, EN: { name: "BEST SELLER" }, CN: { name: "周三" }, stateName: "isBestSeller", },
            { value: 'visible', TH: { name: "สถานะ" }, EN: { name: "VISIBLE" }, CN: { name: "周四" }, stateName: "visible", },
        ],
    }]
];

export default {
    HeaderPage,
    BodyPage,
}