import * as CommissionsActions from '../actions/Actions_CommissionsManager';

const INITIAL_STATE = { COMMISSIONS_REDUCER: { actions: CommissionsActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: CommissionsActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case CommissionsActions.INITIAL_STATE:
            {
                return state;
            }
        case CommissionsActions.IDLE_STATE:
            {
                //console.log("[ Commissions ] : IDLE_STATE : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.IDLE_STATE, data: payload, error: _error } };
            }
        case CommissionsActions.GET_SPECIAL_COMMISSIONS_SUCCESS:
            {
                //console.log("[ Commissions ] : GET_SPECIAL_COMMISSIONS_SUCCESS : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.GET_SPECIAL_COMMISSIONS_SUCCESS, data: payload, error: _error } };
            }
        case CommissionsActions.GET_SPECIAL_COMMISSIONS_FAILURE:
            {
                console.log("[ Commissions ] : GET_SPECIAL_COMMISSIONS_FAILURE : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.GET_SPECIAL_COMMISSIONS_FAILURE, data: payload, error: _error } };
            }
        case CommissionsActions.CREATE_SPECIAL_COMMISSIONS_SUCCESS:
            {
                // console.log("[ Commissions ] : CREATE_SPECIAL_COMMISSIONS_SUCCESS : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.CREATE_SPECIAL_COMMISSIONS_SUCCESS, data: payload, error: _error } };
            }
        case CommissionsActions.CREATE_SPECIAL_COMMISSIONS_FAILURE:
            {
                console.log("[ Commissions ] : CREATE_SPECIAL_COMMISSIONS_FAILURE : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.CREATE_SPECIAL_COMMISSIONS_FAILURE, data: payload, error: _error } };
            }
        case CommissionsActions.UPDATE_SPECIAL_COMMISSIONS_SUCCESS:
            {
                // console.log("[ Commissions ] : UPDATE_SPECIAL_COMMISSIONS_SUCCESS : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.UPDATE_SPECIAL_COMMISSIONS_SUCCESS, data: payload, error: _error } };
            }
        case CommissionsActions.UPDATE_SPECIAL_COMMISSIONS_FAILURE:
            {
                console.log("[ Commissions ] : UPDATE_SPECIAL_COMMISSIONS_FAILURE : ", payload);
                return { ...state, COMMISSIONS_REDUCER: { actions: CommissionsActions.UPDATE_SPECIAL_COMMISSIONS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                return state;
            }
    }
}
