import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import { UpperCaseFormats } from '../../common/SetUpperCaseFormat.js';
import { NumberFormats } from '../../common/SetNumberFormat.js';
import { SetDate } from '../../common/SetDate';

import DialogPanel from '../../componets/DialogPanel.jsx';
import StepperComponent from '../../componets/StepperComponent.jsx';
import RequestComponent from '../../componets/RequestComponent.jsx';

import * as AccountingActions from '../../actions/Actions_Accounting';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

let isBtnActive = false;
let waitingTime = true;

class WithDrawRequestPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false, msgError: undefined, dialogType: undefined,
            newData: {
                status: '',
                remark: ''
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data } = AccountingManager.ACCOUNTING_REDUCER;

            switch (actions) {
                case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AccountingActions.UPDATE_ACCOUNT_STATUS_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: 'UPDATE_ACCOUNT_STATUS_SUCCESS.', dialogType: 'success', });
                        this.props.APIWithDrawRequestList({ num_limit: 25 });
                    } break;
                case AccountingActions.UPDATE_ACCOUNT_STATUS_FAILURE:
                    {
                        console.log("UPDATE_ACCOUNT_STATUS_FAILURE : ", data);
                        const _msg = data.response.data.error || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

    }

    render() {
        const { onSavePanel } = this;
        const { withdrawInfo, isView, onClosePanel } = this.props;
        const { newData, isLoading, msgError, dialogType } = this.state;
        const { createDate, userAssigneeName, userAssigneeType, currency, transferStatus, oldBalance, amount, balance, bankName, accountNo } = withdrawInfo;

        isBtnActive = (!newData.status) && true;
        let _currency = (currency === 'THB') ? 'Bath' : null;

        const InfoLists = [
            { title: 'Name : ', value: userAssigneeName },
            { title: 'Type : ', value: UpperCaseFormats(0, userAssigneeType) },
            { title: 'Date : ', value: SetDate(createDate) },
            { title: 'Wallet :', value: `${NumberFormats(oldBalance || 0)} ${_currency}` },
            { title: 'WithDraw : ', value: `${NumberFormats(amount || 0)} ${_currency}` },
            { title: 'Amount : ', value: `${NumberFormats(balance || 0)} ${_currency}` },
            { title: 'Bank : ', value: bankName },
            { title: 'Account Number : ', value: accountNo },
        ];

        const Structure = [
            [{
                title_EN: "Status WithDraw", title_TH: "", title_CN: "",
                type: "select",
                require: true,
                value: path(['status'], newData),
                onChange: this.onChangeState('status'),
                options: renderOptions(transferStatus).map((option, i) => (<option key={i} value={option.value} >  {option.label}</option>)),
            }],
            [{
                title_EN: "Remark", title_TH: "", title_CN: "",
                type: "text-multiline",
                require: false,
                value: path(['remark'], newData),
                onChange: this.onChangeState('remark')
            }],
        ];


        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {
                    (isView) ?
                        <StepperComponent
                            activeStep={mapWithDrawStatus(transferStatus)}
                            steps={mapStepArray(transferStatus)}
                            component={renderRequestComponent(true, { Structure, InfoLists, onClosePanel, onSavePanel })} />
                        :
                        renderRequestComponent(false, { Structure, InfoLists, onClosePanel, onSavePanel })
                }
            </React.Fragment>
        )
    }


    onChangeState = (fieldName) => (event) => {
        let newData = { ...this.state.newData };

        switch (fieldName) {
            default: {
                newData = { ...newData, [fieldName]: event.target.value }
            } break;
        }
        this.setState({ newData })
    };

    onSavePanel = () => {
        const { transferId } = this.props.withdrawInfo;
        const { remark, status } = this.state.newData;
        const { userId } = this.props.userInfo;
        let newData;

        if (status) {
            newData = {
                adminId: userId,
                transferId: transferId,
                remark: remark,
                transferStatus: status
            }
            // console.log('newData', newData);
            this.setState({ isLoading: true }, () => this.props.APIUpdateAccountStatus(newData))
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form.', dialogType: 'error' })
        }
    }

    onCloseDialog = () => { this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }) }
};

function renderRequestComponent(action, props) {
    const { Structure, InfoLists, onClosePanel, onSavePanel } = props;

    return (
        <RequestComponent Action={action}
            Header={'WithDraw Request'}
            Structure={Structure}
            InfoLists={InfoLists}
            onClickCancel={onClosePanel}
            onClickSave={() => onSavePanel()}
            BtnSaveActive={isBtnActive} />
    )
};

function renderOptions(transferStatus) {
    let optionsWithDraw = [];

    switch (transferStatus) {
        case 'request': {
            optionsWithDraw = [
                { value: 'in-process', label: "in-process" },
                { value: 'success', label: "success" },
                { value: 'refuse', label: "refuse" },
            ];
        } break;
        case 'in-process': {
            optionsWithDraw = [
                { value: 'success', label: "success" },
                { value: 'refuse', label: "refuse" },
            ];
        } break;
    };

    return optionsWithDraw;
};

function mapWithDrawStatus(status) {
    switch (status) {
        case 'success': return 2;
        case 'in-process': return 1;
        default: return 0;
    }
};

function mapStepArray(status) {
    switch (status) {
        default: return ["request", "in-process", "success"];
    }
};

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        APIWithDrawRequestList: (_id) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIWithDrawRequestList(_id));
        },
        APIUpdateAccountStatus: (_id) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIUpdateAccountStatus(_id));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithDrawRequestPanel);
