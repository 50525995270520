import React, { Component } from 'react'
import { path, clone } from 'ramda';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import IconClose from '@material-ui/icons/HighlightOff';
import Checkbox from '@material-ui/core/Checkbox';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

// import * as format from '../../common/setFormat';
import { PriceFormat, PercentFormat } from '../../common/setFormat';
import FormTypes from '../../common/FormTypes';
import { Colors, Family } from '../../themes';
import CloseServicePanel from '../../componets/CloseServicePanel';
import { SetDateShort } from '../../common/SetDate';

class FormTimePeriod extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // StateInfo: this.props.StateInfo,
            isCloseListService: false  // waiting to close
        }
        this.onChangeText = this.onChangeText.bind(this);
    }

    render() {

        const { classes, FormLayout, indexArray, onRemove, disabled, isUpdate } = this.props;
        return (
            <div className={classes.root}>
                {(indexArray > 0 && !isUpdate) &&
                    <IconClose style={{ color: Colors.themeDark, position: 'absolute', top: 5, right: 10, zIndex: disabled ? -1 : 2 }} onClick={onRemove} />}
                {Array.isArray(FormLayout.structure) && FormLayout.structure.map((row, index) => (
                    <div key={index} style={{ display: 'flex' }}>
                        {row.map((each, i) => (<div key={i} style={{ width: '100%' }} >{this.SetItemWithStyle(each)}</div>))}
                    </div>
                ))}
            </div>

        )
    }

    SetItemWithStyle(each) {

        // const { StateInfo } = this.state;
        const { classes, TransferType, widthDate, maxEndDate, Active, StateInfo, isUpdate, Language, minStartDate, minEndDate, disabled, packageId } = this.props;

        switch (each.type) {
            case "date-picker":
                {
                    return (
                        (isUpdate) ?
                            <React.Fragment>
                                <FromContianer>
                                    <FromLabelContianer>{'Start Date : ' + SetDateShort(StateInfo[each.stateStart])} </FromLabelContianer>
                                    <FromLabelContianer>{'End Date : ' + SetDateShort(StateInfo[each.stateEnd])} </FromLabelContianer>
                                </FromContianer>
                                <FromContianer style={{ marginTop: 15 }}>
                                    <FromLabelContianer>{'Price (Adult) : ' + StateInfo.price.adult} </FromLabelContianer>
                                    <FromLabelContianer>{'Price (Children) : ' + StateInfo.price.kid} </FromLabelContianer>
                                </FromContianer>
                            </React.Fragment>
                            :
                            <FormTypes.TextDatePicker disabled={(Active) ? true : false}
                                // error={error}
                                minStartDate={minStartDate}
                                minEndDate={minEndDate}
                                Require={(Active) ? false : each.require}
                                width={'455px'}
                                valueStart={path([each.stateStart], StateInfo)}
                                valueEnd={path([each.stateEnd], StateInfo)}
                                stateStart={each.stateStart} stateEnd={each.stateEnd}
                                TitleStart={each.titleStart} TitleEnd={each.titleEnd}
                                onChange={(name, value) => { this.onChangeText(name, value) }} />

                        //             <FormTypes.TextDatePicker disabled={disabled}
                        //                 minStartDate={minStartDate}
                        //                 minEndDate={minEndDate} maxEndDate={maxEndDate}
                        //                 Require={(Active) ? false : each.require}
                        //                 width={widthDate || '455px'}
                        //                 valueStart={path([each.stateStart], StateInfo)} valueEnd={path([each.stateEnd], StateInfo)}
                        //                 stateStart={each.stateStart} stateEnd={each.stateEnd}
                        //                 TitleStart={each.start_EN} TitleEnd={each.end_EN}
                        //                 onChange={(name, value) => { this.onChangeText(name, value) }}
                        //             />
                    )
                }

            case "number":
                {

                    const { stateName, stateGroup, require, title_EN } = each;

                    let Transfer = (TransferType === 'Transfer') && (((stateName === 'kid') && (stateGroup === 'netPrice')) || (stateName === 'marginKid')) || (stateName === 'marginKid')
                    let Margin = (stateName === 'marginAdult') || (stateName === 'marginKid');
                    let SaleAdult = (stateName === 'adult') && (stateGroup === 'price');
                    let SaleKid = (stateName === 'kid') && (stateGroup === 'price');

                    return (
                        (!isUpdate) &&
                        <div className={classes.row}>
                            <FormTypes.TextFormat
                                InputFormat={(Margin) ? PercentFormat : PriceFormat}
                                disabled={Margin || disabled && true}//SaleAdult || SaleKid || disabled && true
                                style={{ marginLeft: (Margin) && '10px', marginRight: (Margin) && '10px', width: '100%' }}
                                Require={(Active || Transfer) ? false : require}
                                TitleText={title_EN}
                                value={(Margin) ? path([stateName], StateInfo) : path([stateGroup, stateName], StateInfo)}
                                onChange={(e) => {

                                    const value = e.target.value;

                                    if (Margin || SaleAdult || SaleKid) {

                                        // console.log(title_EN, ' : ', value);
                                        this.onChangeText(stateName, value, stateGroup);
                                    }
                                    else {

                                        // console.log(stateName, ' : ', value);

                                        let margin;
                                        let periodInfo = { ...StateInfo };
                                        const price = parseInt(value || 0);

                                        switch (stateName) {
                                            case 'adult': { margin = periodInfo.marginAdult; } break;
                                            case 'kid': { margin = periodInfo.marginKid; } break;
                                            default: { margin = 0; }
                                        }

                                        // console.log('margin : ', (price * margin));

                                        const newPrice = (price + ((price * margin) / 100));

                                        periodInfo = {
                                            ...periodInfo,
                                            [stateGroup]: { ...periodInfo[stateGroup], [stateName]: price },
                                            price: { ...periodInfo.price, [stateName]: parseInt(newPrice) },
                                        }

                                        console.log('periodInfo', periodInfo);
                                        this.props.onChange(periodInfo);
                                    }
                                }} />
                        </div>
                    )
                }

            case "checkbox":
                {
                    return (
                        <React.Fragment>
                            <div className={classes.textFormLabel}> {each.title_EN} </div>
                            <div className={classes.listItem}>
                                {
                                    Array.isArray(each.items) && each.items.map((each, index) => {
                                        return (
                                            <ListItem key={index} dense button className={classes.item} disabled={(isUpdate) ? true : disabled} onClick={this.handleToggle('serviceDate', each)}>
                                                <Checkbox color="primary" className={classes.checkbox} disabled={disabled}
                                                    checked={path(['serviceDate'], StateInfo).indexOf(each.value) !== -1} tabIndex={-1} disableRipple />
                                                <ListItemText style={{ fontWeight: 300, fontFamily: Family.roboto }} primary={`${each[Language].name || each}`} />
                                            </ListItem>
                                        )
                                    })
                                }
                            </div>
                        </React.Fragment>
                    )
                }

            case "close_service":
                {
                    return (
                        (packageId) ?
                            <CloseServicePanel
                                packageId={packageId}
                                TitleCheckBox={each.title_EN}
                                Items={each.items}
                                disabled={(isUpdate || Active) ? disabled : false}
                                StateInfo={StateInfo}
                                Language={Language}
                                handleToggle={this.handleToggle}
                                onChangeText={this.onChangeText}
                            />
                            : null
                    )
                }
        }
    }

    handleToggle = (fieldName, state) => () => {

        let periodInfo = { ...this.props.StateInfo };

        switch (fieldName) {

            case "isCloseListService":
                {
                    const newIcon = clone(periodInfo);
                    newIcon.isCloseListService = !newIcon.isCloseListService;
                    periodInfo = { ...periodInfo, isCloseListService: newIcon.isCloseListService }
                } break;

            case "serviceDate":
                {

                    const key = [...periodInfo.serviceDate]
                    const currentIndex = key.indexOf(state.value);

                    if (currentIndex === -1) {
                        key.push(state.value);
                    }
                    else { key.splice(currentIndex, 1); }

                    let valueAll = key.some(each => each === 'All');

                    if (valueAll) {
                        let keyAll = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "All"];
                        periodInfo = { ...periodInfo, serviceDate: keyAll };
                    }
                    else {
                        if (key.length === 7) {
                            let keyAll = []
                            periodInfo = { ...periodInfo, serviceDate: keyAll };
                        }
                        else {
                            periodInfo = { ...periodInfo, serviceDate: key };
                        }
                    }
                }

        }

        this.onChangeText(fieldName, periodInfo[fieldName]);

    }

    onChangeText = (fieldName, value, fieldGroup) => {

        let periodInfo = { ...this.props.StateInfo };

        switch (fieldName) {

            case "adult":
                {
                    const { adult } = periodInfo.netPrice;

                    const netPrice = adult;
                    const newMargin = (((value - netPrice) * 100) / netPrice);

                    periodInfo = {
                        ...periodInfo, ["marginAdult"]: newMargin,
                        price: { ...periodInfo.price, [fieldName]: parseInt(value || 0) },
                    }
                } break;

            case "kid":
                {

                    const { kid } = periodInfo.netPrice;

                    const netPrice = kid;
                    const newMargin = (((value - netPrice) * 100) / netPrice);

                    periodInfo = {
                        ...periodInfo, ["marginKid"]: newMargin,
                        price: { ...periodInfo.price, [fieldName]: parseInt(value || 0) },
                    }

                    // console.log(`periodInfo ${fieldName} : `, periodInfo)

                } break;

            case "marginAdult":
                {
                    let price = periodInfo.netPrice.adult;
                    const marginAdult = (value * 0.01).toFixed(2);
                    const newPrice = (price + ((price * marginAdult) / 100)).toFixed(2);

                    // console.log(`price ${price} / margin :${marginAdult} : ${newPrice}`)

                    periodInfo = {
                        ...periodInfo,
                        price: { ...periodInfo.price, adult: (newPrice || 0) },
                        [fieldName]: marginAdult
                    }
                } break;

            case "marginKid":
                {
                    let price = periodInfo.netPrice.kid;
                    const marginKid = (value * 0.01).toFixed(2);
                    const newPrice = (price + ((price * marginKid) / 100)).toFixed(2);

                    periodInfo = {
                        ...periodInfo,
                        price: { ...periodInfo.price, kid: (newPrice || 0) },
                        [fieldName]: newPrice
                    }

                } break;

            default:
                {
                    periodInfo = { ...periodInfo, [fieldName]: value }
                } break;

        }

        this.props.onChange(periodInfo);

    }
}

const styles = theme => (
    {
        root: { border: `${'1px solid' + Colors.border}`, padding: '10px', margin: '15px 0px', position: 'relative' },
        row: { display: 'flex' },
        item: { display: 'flex', width: '100%', alignItems: 'center', padding: '0px', marginLeft: '-12px' },
        listItem: { display: 'flex', flexDirection: 'row' },
        textFormLabel: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: '13px',
            color: Colors.themeDark,
            paddingTop: '15px',
        },
    }
)

FormTimePeriod.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormTimePeriod);

const FromLabelContianer = styled.div`
        width:50%;
        font-weight: 300;
        font-size: 13px;
        font-family: ${Family.roboto};
        padding-right: 40px;`;
const FromContianer = styled.div`
        width:100%;
        display:flex;`;