import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { AreaChart, Area, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { SetDate, ConvertDate } from '../common/SetDate';
import { Family, Size, Colors } from '../themes';

const data = [
    { name: 'Sunday', Omise: 0, Alienation: 0, amt: 0 },
    { name: 'Monday', Omise: 0, Alienation: 0, amt: 0 },
    { name: 'Tuesday', Omise: 0, Alienation: 0, amt: 0 },
    { name: 'Wednesday', Omise: 0, Alienation: 0, amt: 0 },
    { name: 'Thursday', Omise: 0, Alienation: 0, amt: 0 },
    { name: 'Friday', Omise: 0, Alienation: 0, amt: 0 },
    { name: 'Saturday', Omise: 0, Alienation: 0, amt: 0 },];

function BookingStatChart(props) {

    const { classes, Info, Disable, btnViewHadle, btnCreateHadle, isLoading } = props;
    const _isInfo = (!!Info);
    let _newInfo;

    if (_isInfo) {
        //console.warn('BookingStatChart [BigChart] : ', Info);
        _newInfo = Info.map((_item) => { _item.Date = ConvertDate(_item.Date); return _item; })
    }

    return (
        <Card className={classes.card} >
            <CardContent style={{ paddingLeft: 0 }}>
                <div style={{ margin: 10, fontSize: Size.chart.title }}>
                    <label style={{ fontFamily: Family.roboto }}>{`Summary Booking With Payment Type`}</label>
                </div>
                {(isLoading) ? <div className={classes.chart} /> : Chart(((_isInfo) ? _newInfo : data), _isInfo)}
            </CardContent>
        </Card>
    );
}

const Chart = (data, _isInfo) => {
    return (
        <AreaChart width={1000} height={335} data={data} margin={cMargin} style={{ fontFamily: Family.roboto, fontSize: Size.chart.label }}>
            <defs>
                <linearGradient id="colorOmise" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={Colors.bigChart_1} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={Colors.bigChart_1} stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorAlienation" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={Colors.bigChart_2} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={Colors.bigChart_2} stopOpacity={0} />
                </linearGradient>
            </defs>
            <XAxis dataKey={(_isInfo) ? 'Date' : 'name'} />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="Omise" stroke={Colors.bigChart_1} fillOpacity={1} fill="url(#colorOmise)" />
            <Area type="monotone" dataKey="Alienation" stroke={Colors.bigChart_2} fillOpacity={1} fill="url(#colorAlienation)" />
        </AreaChart>)
}

const styles = theme => (
    {
        // card: { minWidth: 763, maxWidth: 300, },
        card: { minWidth: 1024, maxWidth: 300, },
        chart: { height: 250, width: 730, },
    });

const cMargin = { top: 10, right: 30, left: 0, bottom: 0 };

BookingStatChart.propTypes = { classes: PropTypes.object.isRequired, };


export default withStyles(styles)(BookingStatChart);

// const data = [
//     { name: 'Sunday', Omise: 4000, Alienation: 2400, amt: 2400 },
//     { name: 'Monday', Omise: 3000, Alienation: 1398, amt: 2210 },
//     { name: 'Tuesday', Omise: 2000, Alienation: 9800, amt: 2290 },
//     { name: 'Wednesday', Omise: 2780, Alienation: 3908, amt: 2000 },
//     { name: 'Thursday', Omise: 18900, Alienation: 4800, amt: 2181 },
//     { name: 'Friday', Omise: 2390, Alienation: 3800, amt: 2500 },
//     { name: 'Saturday', Omise: 0, Alienation: 0, amt: 0 },];