import * as AnnouncementActions from '../actions/Actions_AnnouncementManager';

const INITIAL_STATE = { ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: AnnouncementActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case AnnouncementActions.INITIAL_STATE:
            {
                return state;
            }
        case AnnouncementActions.IDLE_STATE:
            {
                //console.log("[ Refund ] : IDLE_STATE : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.IDLE_STATE, data: payload, error: _error } };
            }
        case AnnouncementActions.GET_ANNOUNCEMENT_LIST_SUCCESS:
            {
                //console.log("[ Refund ] : GET_ANNOUNCEMENT_LIST_SUCCESS : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.GET_ANNOUNCEMENT_LIST_SUCCESS, data: payload, error: _error } };
            }
        case AnnouncementActions.GET_ANNOUNCEMENT_LIST_FAILURE:
            {
                //console.log("[ Refund ] : GET_ANNOUNCEMENT_LIST_FAILURE : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.GET_ANNOUNCEMENT_LIST_FAILURE, data: payload, error: _error } };
            }
        case AnnouncementActions.CREATE_ANNOUNCEMENT_SUCCESS:
            {
                // console.log("[ Refund ] : CREATE_ANNOUNCEMENT_SUCCESS : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.CREATE_ANNOUNCEMENT_SUCCESS, data: payload, error: _error } };
            }
        case AnnouncementActions.CREATE_ANNOUNCEMENT_FAILURE:
            {
                // console.log("[ Refund ] : CREATE_ANNOUNCEMENT_FAILURE : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.CREATE_ANNOUNCEMENT_FAILURE, data: payload, error: _error } };
            }
        case AnnouncementActions.UPDATE_ANNOUNCEMENT_SUCCESS:
            {
                // console.log("[ Refund ] : UPDATE_ANNOUNCEMENT_SUCCESS : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.UPDATE_ANNOUNCEMENT_SUCCESS, data: payload, error: _error } };
            }
        case AnnouncementActions.UPDATE_ANNOUNCEMENT_FAILURE:
            {
                // console.log("[ Refund ] : UPDATE_ANNOUNCEMENT_FAILURE : ", payload);
                return { ...state, ANNOUNCEMENT_REDUCER: { actions: AnnouncementActions.UPDATE_ANNOUNCEMENT_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Refund ]  default :", state);
                return state;
            }
    }
}
