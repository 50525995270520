import React, { Component, } from 'react';
import { withRouter } from 'react-router';

import { isMobile, isTablet } from '../../common/DeviceCheck';
import { SetDate } from '../../common/SetDate';
import { Metrics, Colors, Fonts, Family, Icons } from "../../themes";
const { PDFIcon } = Icons;

class PackageInfoPanel extends Component {

    render() {

        const { _bookingInfo } = this.props;
        const { packageInfo, bookingId, bookingNo, bookingStatus, expired, date, paymentInfo, ticketLink, remark, refundLog } = _bookingInfo;
        const { bookingInfo } = packageInfo[0];
        const { date: travelDate } = bookingInfo;

        console.log('_bookingInfoPanel : ', _bookingInfo);

        // const duration = (_bookingInfo.packageInfo.schedule.duration.split('-'));
        // const duration = _bookingInfo.packageInfo.schedule[0].time.split('–');

        const stylePanel = isMobile() ? { padding: '20px 0px 20px 20px', width: '95%' } : { padding: '20px 20px 20px 50px', width: '100%', textAlign: 'left' };

        return (
            <div key={bookingId} style={{ backgroundColor: Colors.themeDark_Light, width: '100%', height: '50%', display: 'flex' }}>
                <div style={stylePanel}>
                    <label style={style.label_topic}>{packageInfo[0].name}</label>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Bookining ID : `}</label>
                        <label style={style.priceInfo_lable}>{`${bookingId}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Bookining No. : `}</label>
                        <label style={style.priceInfo_lable}>{`${bookingNo}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Booking Date : `}</label>
                        <label style={style.priceInfo_lable}>{`${SetDate(date)}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Traval Date : `}</label>
                        <label style={style.priceInfo_lable}>{`${SetDate(travelDate)}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Payment Method  : `}</label>
                        <label style={style.priceInfo_lable}>{`${paymentInfo.paymentMethod}`}</label>
                    </div>
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Booking Status : `}</label>
                        <label style={style.priceInfo_lable}>{`${bookingStatus}`}</label>
                    </div>
                    {Boolean(refundLog) && this.RefundInfoPanel(refundLog)}
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Expired : `}</label>
                        <label style={style.priceInfo_lable}>{`${expired}`}</label>
                    </div>
                    {/* {(bookingStatus === 'cancel') && */}
                    <div style={style.packege_container}>
                        <label style={style.label_detail}>{`Remark : `}</label>
                        <label style={style.priceInfo_lable}>{`${remark}`}</label>
                    </div>
                    {/* } */}
                    {Boolean(ticketLink) &&
                        <div style={style.packege_container}>
                            <label style={{ width: '33%', ...style.label_detail }}>{`Ticket : `}</label>
                            <label style={style.priceInfo_lable}>
                                <PDFIcon onClick={() => window.open(ticketLink)} style={{ color: Colors.theme, width: 30, height: 30, cursor: 'pointer' }} />
                            </label>
                        </div>
                    }
                </div>
            </div >);
    }

    RefundInfoPanel(refundLog) {

        const { description, refundBy } = refundLog;

        return (
            <React.Fragment>
                <div style={style.packege_container}>
                    <label style={style.label_detail}>{`Refund By : `}</label>
                    <label style={style.priceInfo_lable}>{refundBy}</label>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(PackageInfoPanel);

const style =
{
    priceInfo_lable: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'left',
        fontFamily: Family.roboto,
        //   marginTop: '5px',
        //   marginRight: '5px',
    },
    packege_container: {
        width: '100%',
        display: 'flex',
        textAlign: 'left',
        fontFamily: Family.roboto,
    },
    label_topic: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '31px',
        display: 'inline-grid',
        marginTop: '5px',
        fontFamily: Family.roboto,
    },
    label_detail: {
        height: '31px',
        width: '50%',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        fontFamily: Family.roboto,
        //   marginTop: '5px',
        //   marginBottom: '5px',
    },
};
