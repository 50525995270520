export function NumberFormats(price) {
    // console.log('NumberFormats', price)

    if ((price > 999) || (price < -999)) {
        let _price = parseFloat(`${price}`).toFixed(2);

        return (`${_price}`).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseFloat(price).toFixed(2);
}

export function NumberFormatNoFix(price) {

    if ((price > 999) || (price < -999)) {
        let _price = parseFloat(`${price}`);

        return (`${_price}`).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return parseFloat(price);
}

// const SI_SYMBOL = ['', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];

export function SortLargeNumber(num, digits) {

    num += 1000;

    // if (num < 1100) {
    //     return num;
    // }
    // else {

    let units = ['K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
        decimal;

    for (let i = units.length - 1; i >= 0; i--) {
        decimal = Math.pow(1000, i + 1);

        if (num <= -decimal || num >= decimal) {
            return +(num / decimal).toFixed(digits) + units[i];
        }
    }

    return num;
    // }
}