import axios from 'axios';
import CONFIG from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const WATING_STATE_CHANGE_STATUS = 'WATING_STATE_CHANGE_STATUS';

export const GET_BOOKING_LISTS_SUCCESS = 'GET_BOOKING_LISTS_SUCCESS';
export const GET_BOOKING_LISTS_FAILURE = 'GET_BOOKING_LISTS_FAILURE';

export const GET_LB_BOOKING_LISTS_SUCCESS = 'GET_LB_BOOKING_LISTS_SUCCESS';
export const GET_LB_BOOKING_LISTS_FAILURE = 'GET_LB_BOOKING_LISTS_FAILURE';

export const GET_BOOKING_DETAIL_SUCCESS = 'GET_BOOKING_DETAIL_SUCCESS';
export const GET_BOOKING_DETAIL_FAILURE = 'GET_BOOKING_DETAIL_FAILURE';

export const SET_BOOKING_STATUS_SUCCESS = 'SET_BOOKING_STATUS_SUCCESS';
export const SET_BOOKING_STATUS_FAILURE = 'SET_BOOKING_STATUS_FAILURE';

export const GET_BOOKING_WITHPACKAGE_SUCCESS = 'GET_BOOKING_WITHPACKAGE_SUCCESS';
export const GET_BOOKING_WITHPACKAGE_FAILURE = 'GET_BOOKING_WITHPACKAGE_FAILURE';


// setAPIState =============================================================================================================================

const _header = { 'Content-Type': 'application/json', Authorization: `Bearer ${CONFIG.EP_token}`, 'Cache-Control': ['no-cache', 'no-store', 'must-revalidate',] }

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================
// getAPIBookingLists ======================================================================================================================

export function getAPIBookingLists(_keyword = '', _fileterjson = {}) {

    // console.log("[API] getAPIBookingLists :", _fileterjson)

    return dispatch => {
        // const request = axios.get(`${api}business/getAllEmployee?businessId=${business_id}`,{headers:{'x-master-key':'smelink1234'}});
        // const _header={'Content-Type':'application/json',Authorization:`Bearer ${_token}`,'Cache-Control':['no-cache','no-store','must-revalidate',]}

        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getBookingList}`,
            method: 'GET',
            headers: _header,
            data: {},
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getBookingList : ", response);
            dispatch(getAPIBookingList_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getBookingList Failure", error);
            dispatch(getAPIBookingList_Failure(error));
        })
    }
}

function getAPIBookingList_Success(data) {
    return { type: GET_BOOKING_LISTS_SUCCESS, payload: data };
}

function getAPIBookingList_Failure(error) {
    return { type: GET_BOOKING_LISTS_FAILURE, payload: error };
}

// getAPIBookingLists ==========================================================================================================================

// get_LB_APIBookingLists ======================================================================================================================

export function get_LB_APIBookingLists(_keyword = '', _fileterjson = {}) {

    //const encodeFileter = encodeURI(_fileterjson);
    //console.log("[API] get_LB_APIBookingLists :",_fileterjson)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.get_LB_BookingList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: {},
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] get_LB_APIBookingLists : ", response);
            dispatch(get_LB_APIBookingLists_Success(response.data));

        }).catch((error) => {
            console.log("[API] get_LB_APIBookingLists Failure", error);
            dispatch(get_LB_APIBookingLists_Failure(error));
        })
    }
}

function get_LB_APIBookingLists_Success(data) {
    return { type: GET_LB_BOOKING_LISTS_SUCCESS, payload: data };
}

function get_LB_APIBookingLists_Failure(error) {
    return { type: GET_LB_BOOKING_LISTS_FAILURE, payload: error };
}

// get_LB_APIBookingLists ====================================================================================================================

// getAPIBookingDetail =======================================================================================================================

export function getAPIBookingDetail(_keyword = '', _fileterjson = {}) {
    // console.log("[API] getAPIBookingDetail :", _keyword, _fileterjson)
    //?bookingId

    return dispatch => {
        _fileterjson.keyword = _keyword;

        // const request = axios.get(`${api}business/getAllEmployee?businessId=${business_id}`,{headers:{'x-master-key':'smelink1234'}});
        // const _header={'Content-Type':'application/json',Authorization:`Bearer ${_token}`}

        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getBookingDetail}?bookingId=${_keyword}`,
            method: 'GET',
            headers: _header,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getBookingDetail : ", response);
            dispatch(getAPIBookingDetail_Success(response.data.result));

        }).catch((error) => {
            console.log("[API] getBookingDetail Failure", error);
            dispatch(getAPIBookingDetail_Failure(error));
        })
    }
}

function getAPIBookingDetail_Success(data) {
    return { type: GET_BOOKING_DETAIL_SUCCESS, payload: data };
}

function getAPIBookingDetail_Failure(error) {
    return { type: GET_BOOKING_DETAIL_FAILURE, payload: error };
}

// getAPIBookingDetails ===================================================================================================================


export function setAPIBookingStatus(confirmInfo) {

    setWatingChangeStatus();

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.changeBookingStatus}`,
            method: 'POST',
            headers: _header,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] setAPIBookingStatus : ", response.data);
            dispatch(setAPIBookingStatus_Success(response.data));

        }).catch((error) => {
            const { status, statusText, data } = error.response;
            const _error = { status, statusText, data };

            console.log("setAPIBookingStatus fail", _error);
            dispatch(setAPIBookingStatus_Failure(_error));
        })
    }
}
export function setWatingChangeStatus(confirmInfo) {
    return dispatch => setWatingStatus();
}

function setWatingStatus() {
    console.log('[ WATING_STATE_CHANGE_STATUS ]');
    return { type: WATING_STATE_CHANGE_STATUS, payload: null }
}

function setAPIBookingStatus_Success(data) {
    return { type: SET_BOOKING_STATUS_SUCCESS, payload: data };
}

function setAPIBookingStatus_Failure(error) {
    return { type: SET_BOOKING_STATUS_FAILURE, payload: error };
}


export function getBookingWithPackageId(_fileterjson = {}) {
    // console.log("[API] getBookingWithPackageId :", _fileterjson)

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getBookingWithPackageId}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getBookingWithPackageId : ", response);
            dispatch(getBooking_WithPackageId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getBookingWithPackageId Failure", error);
            dispatch(getBooking_WithPackageId_Failure(error));
        })
    }
}

function getBooking_WithPackageId_Success(data) {
    return { type: GET_BOOKING_WITHPACKAGE_SUCCESS, payload: data };
}

function getBooking_WithPackageId_Failure(error) {
    return { type: GET_BOOKING_WITHPACKAGE_FAILURE, payload: error };
}