export const wordingKeys = {

    templateLabel: 'templateLabel',
    packageLabel: 'packageLabel',

    sentence1: 'sentence1',
    sentence2: 'sentence2',
    sentence3: 'sentence3',

    Duration: 'Duration',
    Passengers: 'Passengers',
    ShortPassengers: 'ShortPassengers',
    LBQuantity: 'LBQuantity',
    LBPassengers: 'LBPassengers',
    PassengersUnit: 'PassengersUnit',
    StandardBags: 'Standard bags',
    Reviews: 'Reviews',
    Booked: 'Booked',
    BestSeller: 'BestSeller',

    ShareTrip: 'ShareTrip',
    ShouldKnowTopics: 'ShouldKnowTopics',
    CancelationPolicy: 'CancellationPolicy',
    HotelPickUpInformation: 'HotelPickUpInformation',
    InclusionTopics: 'InclusionTopics',
    TourProgramTopics: 'TourProgramTopics',
    ReviewsTopics: 'ReviewsTopics',
    ReviewPackageTopics: 'ReviewPackageTopics',
    BookedTopics: 'BookedTopics',
    BookedTransfer: 'BookedTransfer',
    ProCodeTopics: 'ProCodeTopics',

    Area: 'Area',
    PickUpTime: 'PickUpTime',
    ExtraCharge: 'ExtraCharge',

    EmptySeat: 'EmptySeat',

    Date: 'Date',
    Adult: 'Adult',
    Kid: 'Kid',

    Hour: 'hour',
    Minute: 'minute',

    AgeDate: 'AgeDate',
    AgeAdult: 'AgeAdult',
    AgeKid: 'AgeKid',

    Infant: 'Infant',
    Quantity: 'Quantity',
    Seat: 'Seat',
    Guide: 'Guide',
    Discount: 'Discount',
    ServiceCharge: 'ServiceCharge',
    Total: 'Total',
    Activity: 'Activity',

    BookButton: 'BookButton',
    ChatWithUsButton: 'ChatWithUs',
    SBookButton: 'SBookButton',
    CouponButton: 'CouponButton',
    Highlight: 'Highlight',
    ReviewHolder: 'ReviewHolder',
    LoadMore: 'LoadMore',
    HideReview: 'HideReview',
    Submit: 'Submit',

    seeMoreLable: 'seeMoreLable',
    ResultOf: 'ResultOf',
    AtLocation: 'AtLocation',
    FilterTopics: 'FilterTopics',
    PickUp: 'PickUp',
    DropOff: 'DropOff',
    Passenger: 'Passenger',
    Members: 'Members',
    Destination: 'Destination',
    Find: 'Find',

    SpecialInfoTopics: 'SpecialInfoTopics',
    BookingOn: 'BookingOn',
    TravelDate: 'TravelDate',

    changeDateDetail: 'changeDateDetail',

    WarningMSG: 'WarningMSG',
    NoServiceMSG: 'NoServiceMSG',
    FullServiceMSG: 'FullServiceMSG',
    Save: 'Save',

    AppTitle: 'AppTitle',
    AppDescription: 'AppDescription',
}