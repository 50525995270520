import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';

export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const CREATE_PRODUCTS_SUCCESS = 'CREATE_PRODUCTS_SUCCESS';
export const CREATE_PRODUCTS_FAILURE = 'CREATE_PRODUCTS_FAILURE';

export const UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FAILURE = 'UPDATE_PRODUCTS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

// getProductsList ============================================================================================================================

export function getProductsList(_keyword = '', _fileterjson = {}) {

    //console.log("[API] getProductsList :", `${CONFIG.LBhost}${CONFIG.api.getProductsList}`)
    return dispatch => {
        _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getProductsList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getProductsList : ", response);
            localStorage.setItem('ProductsList', response.data)
            dispatch(getProductsList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getProductsList Failure", error);
            dispatch(getProductsList_Failure(error));
        })
    }
}

function getProductsList_Success(data) {
    return { type: GET_PRODUCTS_SUCCESS, payload: data };
}

function getProductsList_Failure(error) {
    return { type: GET_PRODUCTS_FAILURE, payload: error };
}

// getProductsList =========================================================================================================================


export function APICreateProducts(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.createProducts}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateProducts : ", response.data);
            dispatch(APICreateProducts_Success(response.data));

        }).catch((error) => {
            console.log("APICreateProducts fail", error);
            dispatch(APICreateProducts_Failure(error));
        })
    }
}

function APICreateProducts_Success(data) {
    return { type: CREATE_PRODUCTS_SUCCESS, payload: data };
}

function APICreateProducts_Failure(error) {
    return { type: CREATE_PRODUCTS_FAILURE, payload: error };
}