import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import PackageTopListPanel from '../containers/Website/PackageTopListPanel';
import PackageTopListCard from '../containers/Website/PackageTopListCard';

import * as PackageAction from '../actions/Actions_PackageManager.js';

let waitingTime = true;
const _height = (window.innerHeight - 130);
let packageInfoList = [];

class WebsiteManage extends Component {
    state = { isLoading: true, msgError: undefined, dialogType: undefined, isView: false, isUpdatePackage: false };

    constructor(props) {
        super(props);
        this.props.getAPIPackageList({ limit: 25, visible: true });
    }

    componentWillReceiveProps(nextProps) {
        const { PackageManager } = nextProps;
        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;
            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        packageInfoList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break
            }
        }
    }

    render() {
        const { isLoading, isUpdatePackage } = this.state;
        return (
            <div className="App">
                {(isUpdatePackage) && <DialogAction Active={isUpdatePackage} OnClose={this.onClosePanel} AcrionCom={this.PackageTopListPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}> {this.contentPanel()} </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <PackageTopListPanel packageInfoList={packageInfoList} onClickHandle={this.ViewPackage()} EditInfo={this.EditPackage()} />
            </div>
        )
    }

    onChangeLanguage = (_language) => {
        const { page } = packageInfoList;
        this.props.getAPIPackageList({ page: page, num_limit: 10, language: _language });
    }
    PackageTopListPanel = () => {
        return (
            <PackageTopListCard
                onChangeLanguage={this.onChangeLanguage}
                packageInfoList={packageInfoList}
                isView={this.state.isView}
                oldPackageInfo={this.state.oldPackageInfo}
                PackageInfo={this.state.PackageInfo}
                onClosePanel={this.onClosePanel} />
        )
    }

    EditPackage = () => (PackageInfo) => {
        this.setState({
            isUpdatePackage: true,
            isView: false,
            PackageInfo,
            oldPackageInfo: PackageInfo,
        });
    }
    ViewPackage = () => (PackageInfo) => {
        this.setState({
            isUpdatePackage: true,
            isView: true,
            PackageInfo,
            oldPackageInfo: PackageInfo,
        });
    }

    onClosePanel = () => (this.setState({ isUpdatePackage: false }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(WebsiteManage);
