import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_LOCATIONSERVICES_SUCCESS = 'GET_LOCATIONSERVICES_SUCCESS';
export const GET_LOCATIONSERVICES_FAILURE = 'GET_LOCATIONSERVICES_FAILURE';

export const CREATE_LOCATIONSERVICES_SUCCESS = 'CREATE_LOCATIONSERVICES_SUCCESS';
export const CREATE_LOCATIONSERVICES_FAILURE = 'CREATE_LOCATIONSERVICES_FAILURE';

export const UPDATE_LOCATIONSERVICES_SUCCESS = 'UPDATE_LOCATIONSERVICES_SUCCESS';
export const UPDATE_LOCATIONSERVICES_FAILURE = 'UPDATE_LOCATIONSERVICES_FAILURE';



// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getLocationServicesList =====================================================================================================================

export function getLocationServicesList(_keyword = '', _fileterjson = {}) {

    // console.log("[API] getLocationServicesList :", `${CONFIG.LBhost}${CONFIG.api.getLocationServicesList}`)
    return dispatch => {
        console.log('getLocationServicesList', _keyword);
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getLocationServicesList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getLocationServicesList : ", response);
            localStorage.setItem('LocationServicesList', response.data)
            dispatch(getLocationServicesList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getLocationServicesList Failure", error);
            dispatch(getLocationServicesList_Failure(error));
        })
    }
}

function getLocationServicesList_Success(data) {
    return { type: GET_LOCATIONSERVICES_SUCCESS, payload: data };
}

function getLocationServicesList_Failure(error) {
    return { type: GET_LOCATIONSERVICES_FAILURE, payload: error };
}

// createLocationServices ========================================================================================================================


export function APICreateLocationServices(confirmInfo) {

    console.log('APICreateLocationServices : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createLocationServices}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateLocationServices : ", response.data);
            dispatch(APICreateLocationServices_Success(response.data));

        }).catch((error) => {
            console.log("APICreateLocationServices Failure", error);
            dispatch(APICreateLocationServices_Failure(error));
        })
    }
}

function APICreateLocationServices_Success(data) {
    return { type: CREATE_LOCATIONSERVICES_SUCCESS, payload: data };
}

function APICreateLocationServices_Failure(error) {
    return { type: CREATE_LOCATIONSERVICES_FAILURE, payload: error };
}

// updateLocationServices =========================================================================================================================

export function APIUpdateLocationServices(confirmInfo) {
    console.log('APIUpdateLocationServices : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateLocationServices}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { locationServiceId: confirmInfo.locationServiceId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateLocationServices : ", response.data);
            dispatch(APIUpdateLocationServices_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateLocationServices Failure", error);
            dispatch(APIUpdateLocationServices_Failure(error));
        })
    }
}

function APIUpdateLocationServices_Success(data) {
    return { type: UPDATE_LOCATIONSERVICES_SUCCESS, payload: data };
}

function APIUpdateLocationServices_Failure(error) {
    return { type: UPDATE_LOCATIONSERVICES_FAILURE, payload: error };
}
