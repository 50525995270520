import React, { Component } from 'react'
import { connect } from 'react-redux';
import { path } from 'ramda';
import styled from 'styled-components';
import moment from 'moment';

// import BigCalendar from 'react-big-calendar';
import {  momentLocalizer } from 'react-big-calendar';

import * as BookingAction from '../../actions/Actions_BookingManager.js';
import * as RefundActions from '../../actions/Actions_RefundManager.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import Calendar from '../../componets/Calendar';
import { getFormatted } from '../../common/SetDate.js'
import { Colors, Family } from '../../themes';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';

import RefundCard from '../Refund/RefundCard';
import CONFIG from '../../config';

let EventList = [];
let waitingTime = true;
let _today;
const localizer = momentLocalizer(moment);
class ReservationTable extends Component {

    constructor(props) {
        super(props);

        _today = new Date();

        this.state = {
            _package: path(['packageInfo'], props.location.state),
            isLoading: false, msgError: undefined, dialogType: undefined,
            isRefund: false
        }

        const { packageId } = this.state._package;
        if (Boolean(packageId))
            this.props.getBookingWithPackageId({ packageId: packageId })
    }

    componentWillReceiveProps(nextProps) {

        const { BookingManager, RefundManager } = nextProps;
        // console.log('nextProps', nextProps);

        if (!!BookingManager) {
            const { actions, data, error } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingAction.GET_BOOKING_WITHPACKAGE_SUCCESS: {
                    BookingManager.BOOKING_REDUCER.actions = BookingManager.WATING_STATE;
                    EventList = data.result.resultList;
                    SetStateWithTime(this, waitingTime);
                } break;
                case BookingAction.GET_BOOKING_WITHPACKAGE_FAILURE: {
                    SetStateWithTime(this, waitingTime);
                } break;
            }
        }

        if (!!RefundManager) {

            const { actions, data } = RefundManager.REFUND_REDUCER;

            switch (actions) {
                case RefundActions.REFUND_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'REFUND_SUCCESS', dialogType: 'success', })
                    } break;
                case RefundActions.REFUND_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    render() {

        const { _package, isLoading, isRefund, msgError, dialogType, startDate = '', endDate = '' } = this.state;

        return (
            <Contianer>
                {(isRefund) && <DialogAction Active={isRefund} OnClose={this.onClosePanel} AcrionCom={this.RefundPanel(_package, startDate, endDate)} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <ListContianer>
                    {this.contentPanel(_package)}
                    {this.calendarPanel(_package)}
                </ListContianer>
            </Contianer>
        )
    }

    contentPanel = (_package) => {

        const { type, packageId, isPromotion, visible } = _package;
        const { image, name } = _package.packageInfo;
        const fildeList = [
            { label: 'Package Name :', value: name },
            { label: 'Package Id :', value: packageId },
            { label: 'Package Type :', value: type },
            { label: 'Promotion :', value: (isPromotion) ? 'true' : 'false' },
            { label: 'Status :', value: (visible) ? 'Active' : 'Inactive' }
        ];

        return (
            <InfoContianer>
                <FormLabelContianer style={{ width: '45%' }}>
                    <div style={this.getBackgroundImage(path(['thumbnail'], image))} />
                </FormLabelContianer>
                <FormLabelContianer>
                    {
                        fildeList.map((each, index) => (
                            <ItemContianer key={index}>
                                <TitleLabel>{each.label}</TitleLabel>
                                <TextLabel >{each.value || '-'}</TextLabel>
                            </ItemContianer>
                        ))
                    }
                </FormLabelContianer>
            </InfoContianer>
        )
    }

    calendarPanel = (_package) => {

        const _event = [];

        for (let booking of EventList) {
            if (booking.total > 0)
                _event.push(
                    {
                        id: booking.travelDate,
                        title: (booking.isRefund) ? `Refund All ( ${booking.total} )` : `Booking ( ${booking.total} )`,
                        allDay: true,
                        start: booking.travelDate,
                        end: booking.travelDate,
                        action: "select" | "click",
                        isRefund: (booking.isRefund) ? true : false,
                    }
                )
        }

        const _isDate = new Date();
        const _mount = _isDate.getMonth();
        const _year = _isDate.getFullYear();
        const _date = _isDate.getDate();
        const createDate = `${_year}-${getFormatted(_mount + 1)}-${getFormatted(_date)}`;

        return (
            <CalendarContianer>
                <Calendar
                    date={_today}
                    events={_event}
                    localizer={localizer}
                    onNavigate={() => { console.log('onNavigate :') }}
                    onSelectEvent={(event) => {
                        const _refund = _event.filter((val) => (val.isRefund === true) && (val.id === event[0]))
                        // console.log('onSelectSlot :', _event, _refund, event[0], ' : ', event[1])
                        if (_refund.length === 0) {
                            this.setState({ isRefund: true, startDate: (event[0] || createDate), endDate: (event[1] || createDate) }, () => EventList = [])
                        } else {
                            this.setState({ isLoading: true, msgError: 'Make money for every booking in this package.', dialogType: 'alert' })
                        }
                    }}
                    onSelectSlot={(slot) => { console.log('onSelectSlot :', slot) }} />
            </CalendarContianer>
        )
    };

    RefundPanel = (_package, startDate, endDate) => {
        return (
            <RefundCard
                startDate={startDate}
                endDate={endDate}
                confirmRefund={this.confirmRefund}
                packageId={_package.packageId}
                onClosePanel={this.onClosePanel} />
        )
    }

    confirmRefund = (value) => {
        this.setState({ isLoading: true }, () => this.props.APIRefund(value));
    }

    onCloseDialog = () => {
        this.setState({ isLoading: false, msgError: undefined, dialogType: undefined });
    }

    onClosePanel = () => {
        const packageId = path(['packageId'], this.props.location.state)
        this.setState({ isRefund: false },
            () => this.props.getBookingWithPackageId({ packageId: packageId }));
    }

    getBackgroundImage(image) {
        return (({ width: '100%', height: 280, backgroundColor: 'gray', backgroundImage: `url(${CheckImageURL(image)})`, backgroundRepeat: 'round', border: '1px solid #828181' }))
    }

}
const CheckImageURL = (_url) => (Boolean(_url) ? `${CONFIG.host}${_url}` : '');

function mapStateToProps(state, ownProps) { return { ...state } }

function mapDispatchToProps(dispatch) {
    return {
        getBookingWithPackageId: (_keyword) => {
            dispatch(BookingAction.getBookingWithPackageId(_keyword));
        },
        APIRefund: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(RefundActions.APIRefund(_keyword, _filter));
        },
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(ReservationTable);

const Contianer = styled.div`
        display: flex;
        justify-content: center;
        padding-top: 10px;
        width: 100%; 
        position: relative;
        overflow: auto;`;

const ListContianer = styled.div`
        width: 100%;
        max-width: 1024px;
        overflow-y: scroll;
        display: list-item;
        height: 780px;` ;

const CalendarContianer = styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 600px;
        max-width: 1024px;` ;

const InfoContianer = styled.div`
        display:flex;
        background-color: ${Colors.themeDark};
        margin-bottom:3em;
        height: 350px;
        width: 100%;`;

const FormLabelContianer = styled.div`
        font-family:${Family.roboto};
        font-size: 14px;
        padding:35px;
        color:${Colors.white};
        width: 50%;`;

const ItemContianer = styled.div`
        align-items: center;
        height: 35px;
        display:flex;`;

const TitleLabel = styled.div`
        width: 25%;
        line-height: 16px;`;

const TextLabel = styled.div`
        padding-left:10px;
        opacity: 0.64;
        line-height: 16px;`;
