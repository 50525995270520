
import * as MemberActions from '../actions/Actions_MemberManager';

const INITIAL_STATE = { MEMBER_REDUCER: { actions: MemberActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: MemberActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case MemberActions.INITIAL_STATE:
            {
                return state;
            }
        case MemberActions.IDLE_STATE:
            {
                //console.log("[ Member ] : IDLE_STATE : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.IDLE_STATE, data: payload, error: _error } };
            }
        case MemberActions.GET_MEMBER_SUCCESS:
            {
                //console.log("[ Member ] : GET_MEMBER_SUCCESS : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.GET_MEMBER_SUCCESS, data: payload, error: _error } };
            }
        case MemberActions.GET_MEMBER_FAILURE:
            {
                console.log("[ Member ] : GET_MEMBER_FAILURE : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.GET_MEMBER_FAILURE, data: payload, error: _error } };
            }
        case MemberActions.CREATE_MEMBER_SUCCESS:
            {
                // console.log("[ Member ] : CREATE_MEMBER_SUCCESS : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.CREATE_MEMBER_SUCCESS, data: payload, error: _error } };
            }
        case MemberActions.CREATE_MEMBER_FAILURE:
            {
                console.log("[ Member ] : CREATE_MEMBER_FAILURE : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.CREATE_MEMBER_FAILURE, data: payload, error: _error } };
            }
        case MemberActions.UPDATE_MEMBER_SUCCESS:
            {
                console.log("[ Member ] : UPDATE_MEMBER_SUCCESS : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.UPDATE_MEMBER_SUCCESS, data: payload, error: _error } };
            }
        case MemberActions.UPDATE_MEMBER_FAILURE:
            {
                console.log("[ Member ] : UPDATE_MEMBER_FAILURE : ", payload);
                return { ...state, MEMBER_REDUCER: { actions: MemberActions.UPDATE_MEMBER_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Member ]  default :", state);
                return state;
            }
    }
}
