
import * as AccountingActions from '../actions/Actions_Accounting.js';

const INITIAL_STATE = { ACCOUNTING_REDUCER: { actions: AccountingActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: AccountingActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case AccountingActions.INITIAL_STATE:
            {
                return state;
            }
        case AccountingActions.IDLE_STATE:
            {
                //console.log("[ Accounting ] : IDLE_STATE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.IDLE_STATE, data: payload, error: _error } };
            }
        case AccountingActions.GET_SYSTEM_WALLET_SUCCESS:
            {
                // console.log("[ Accounting ] : GET_SYSTEM_WALLET_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_SYSTEM_WALLET_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.GET_SYSTEM_WALLET_FAILURE:
            {
                console.log("[ Accounting ] : GET_SYSTEM_WALLET_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_SYSTEM_WALLET_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.GET_WALLET_INFO_WITH_ID_SUCCESS:
            {
                // console.log("[ Accounting ] : GET_WALLET_INFO_WITH_ID_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_WALLET_INFO_WITH_ID_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.GET_WALLET_INFO_WITH_ID_FAILURE:
            {
                console.log("[ Accounting ] : GET_WALLET_INFO_WITH_ID_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_WALLET_INFO_WITH_ID_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_SUCCESS:
            {
                // console.log("[ Accounting ] : GET_ACCOUNTING_HISTORY_LIST_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_ACCOUNTING_HISTORY_LIST_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE:
            {
                console.log("[ Accounting ] : GET_ACCOUNTING_HISTORY_LIST_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_ACCOUNTING_HISTORY_LIST_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.WITHDRAW_MONEY_SUCCESS:
            {
                // console.log("[ Accounting ] : WITHDRAW_MONEY_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.WITHDRAW_MONEY_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.WITHDRAW_MONEY_FAILURE:
            {
                console.log("[ Accounting ] : WITHDRAW_MONEY_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.WITHDRAW_MONEY_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.UPDATE_ACCOUNTING_SUCCESS:
            {
                // console.log("[ Accounting ]: UPDATE_ACCOUNTING_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.UPDATE_ACCOUNTING_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.UPDATE_ACCOUNTING_FAILURE:
            {
                console.log("[ Accounting ] : UPDATE_ACCOUNTING_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.UPDATE_ACCOUNTING_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_SUCCESS:
            {
                // console.log("[ Accounting ] : GET_WITHDRAW_REQUEST_LISTS_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_WITHDRAW_REQUEST_LISTS_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.GET_WITHDRAW_REQUEST_LISTS_FAILURE:
            {
                // console.log("[ Accounting ] : GET_WITHDRAW_REQUEST_LISTS_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_WITHDRAW_REQUEST_LISTS_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.GET_ACCOUNT_DETAIL_SUCCESS:
            {
                // console.log("[ Accounting ] : GET_ACCOUNT_DETAIL_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_ACCOUNT_DETAIL_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.GET_ACCOUNT_DETAIL_FAILURE:
            {
                // console.log("[ Accounting ] : GET_ACCOUNT_DETAIL_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.GET_ACCOUNT_DETAIL_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.REQUEST_TRANSFER_BY_SELLER_SUCCESS:
            {
                // console.log("[ Accounting ] : REQUEST_TRANSFER_BY_SELLER_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.REQUEST_TRANSFER_BY_SELLER_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.REQUEST_TRANSFER_BY_SELLER_FAILURE:
            {
                // console.log("[ Accounting ] : REQUEST_TRANSFER_BY_SELLER_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.REQUEST_TRANSFER_BY_SELLER_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS:
            {
                // console.log("[ Accounting ] : REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.REQUEST_TRANSFER_BY_SUPPLIER_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.REQUEST_TRANSFER_BY_SUPPLIER_FAILURE:
            {
                // console.log("[ Accounting ] : REQUEST_TRANSFER_BY_SUPPLIER_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.REQUEST_TRANSFER_BY_SUPPLIER_FAILURE, data: payload, error: _error } };
            }
        case AccountingActions.UPDATE_ACCOUNT_STATUS_SUCCESS:
            {
                // console.log("[ Accounting ] : UPDATE_ACCOUNT_STATUS_SUCCESS : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.UPDATE_ACCOUNT_STATUS_SUCCESS, data: payload, error: _error } };
            }
        case AccountingActions.UPDATE_ACCOUNT_STATUS_FAILURE:
            {
                // console.log("[ Accounting ] : UPDATE_ACCOUNT_STATUS_FAILURE : ", payload);
                return { ...state, ACCOUNTING_REDUCER: { actions: AccountingActions.UPDATE_ACCOUNT_STATUS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Accounting ]  default :", state);
                return state;
            }
    }
}
