import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import * as format from '../../common/setFormat';
import { path, equals } from 'ramda';
import { Family, Colors, RequireForm } from '../../themes';
import { countryCode } from '../countryEn';
import FormTypes from '.';

class TextTelephone extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tel: {
                code: props.value.code,
                number: props.value.number
            }
        }
    }
    render() {
        const { classes, disabled, error, TitleText, value, style, onChange, placeholder, Require, percent } = this.props;

        let valueCode = path(['tel', 'code'], this.state)
        let valueNumber = path(['tel', 'number'], this.state)
        let filterCode = valueCode && Array.isArray(countryCode) && countryCode.find((val) => val.id === valueCode)

        return (
            <div className={classes.root}>
                <FormTypes.Select
                    error={!!path(['code'], error)}
                    TitleText={'Dialling Code'}
                    style={{ width: '40%' }}
                    renderLabel={path(['id'], filterCode) || ''}
                    // value={valueCode}
                    onChange={(e) => { this.onChangeText('code', e.target.value) }}
                    options={Array.isArray(countryCode) && countryCode.map((option, i) => {
                        return (
                            <option key={i} value={option.id}> {option.label} </option>
                        )
                    })} />
                <TextField className={classes.textField}
                    disabled={disabled}
                    error={!!path(['number'], error)}
                    label={Require ? <div> {RequireForm()}{TitleText} </div> : TitleText}
                    style={style}
                    value={valueNumber}
                    onChange={(e) => this.onChangeText('number', e.target.value)}
                    placeholder={placeholder}
                    InputProps={{ inputComponent: format.NumberFormatCustom, classes: { input: classes.textInput, }, }}
                    InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} />
            </div>
        )
    }
    onChangeText = (fieldName, state) => {
        let tel = { ...this.state.tel }

        tel = { ...tel, [fieldName]: state }

        this.setState({ tel }, this.props.onChange(fieldName, state))
    }
}
const styles = theme => (
    {
        root: { display: 'flex' },
        textInput: {
            marginTop: '10px',
            padding: '10px 15px',
            border: `${'1px solid' + Colors.border}`,
            color: Colors.themeDark,
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: 14,
            backgroundColor: Colors.white,
        },
        textField: { width: '100%', margin: '5px 0px 0px', },
        textFormLabel: { fontSize: 20, color: Colors.themeDark, fontWeight: 300 },
    }
)
TextTelephone.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(TextTelephone);