import React, { Component, } from 'react';
import { withRouter } from 'react-router';
// import Button from '@material-ui/core/Button';
// import { pathName } from "../../constant";
// import { Images, Metrics } from '../../themes';
// import { GetSymbol, AgeCondition } from '../../components/common/GetSymbol';

import { Metrics, Colors, Family } from '../../themes';
import { GetSymbol } from '../../common/GetSymbol';
import "./card.css";

// let PromotionI
export const PriceType = { Adult: 'Adult', Kid: 'Kid', Infant: 'Infant' }

class PromotionPriceDetail extends Component {
    render() {

        const { PromotionInfo } = this.props;
        // console.log('Promotioninfo', this.props.PromotionInfo)
        const { infant, kid, adult, currency, } = PromotionInfo;
        const _crrency = GetSymbol(currency);
        return (
            <div style={{ position: 'relative', }} >
                <div style={_style.card_container}>
                    <div style={_style.sortMenu_container}>
                        <div style={{ width: '100%', height: '100%', display: 'flex', textAlign: 'center', }}>
                            {this.setPriceBox(PriceType.Adult, adult, _crrency)}
                            {this.setPriceBox(PriceType.Kid, kid, _crrency)}
                            {this.setPriceBox(PriceType.Infant, infant, _crrency)}
                        </div>
                    </div>
                </div >
            </div>
        )
    }

    setPriceBox(type, price, _crrency) {

        return (
            <div style={_style.header_col}>
                <label style={_style.label_topic_col}>{type}</label>
                <div style={_style.label_detail_col}>
                    <label>{`${price} ${_crrency}`}</label>
                </div>
            </div>
        )
    }
}

export default withRouter(PromotionPriceDetail);

const _style = {
    card_container: {
        height: '100%',
        width: '100%',
        display: 'inline-flex',
        borderBottom: `${'1px solid' + Colors.borderLight}`,
        borderTop: `${'1px solid' + Colors.borderLight}`,
        marginTop: '2px',
        boxShadow: '0 2px 10px 0 rgba(126,126,126,0.5)',
        marginBottom: '10px',
        backgroundColor: Colors.white
    },
    sortMenu_container: {
        flex: '7',
        maxWidth: '954px',
        maxHeigth: '422px',
    },
    header_col: {
        flex: 1,
        borderLeft: `${'1px solid' + Colors.borderLight}`,
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    label_topic_col: {
        color: Colors.themeDark,
        fontSize: '16px',
        fontFamily: Family.roboto,
    },
    label_detail_col: {
        color: Colors.grey,
        fontSize: '22px',
        fontFamily: Family.roboto,
    },
};
