import React from 'react'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import '../../themes/Themes.css';
import { Colors } from '../../themes';

const styles = theme => (
    {
        button: {
            fontSize: 14,
            fontWeight: 300,
            border: `${'1px solid' + Colors.border}`,
            borderRadius: '0px',
            padding: '10px',
            margin: '7px 0px 0px',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start'
        }
    }
);

const Pickers = (props) => {
    const { classes } = props;
    return (
        <div style={props.style}>
            <Button
                className={classes.button}
                style={props.buttonStyle}
                disabled={props.disabled}
                onClick={() => props.onClick()}>
                {props.TitleText}
            </Button>
        </div >
    );

};

Pickers.propTypes = { classes: PropTypes.object.isRequired, };
Pickers.propTypes = { onClick: PropTypes.func };

Pickers.defaultProps = { onClick: () => console.warn('default onClickCancel') }

export default withStyles(styles)(Pickers);

