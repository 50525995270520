export const init_location = {
    "locationServiceId": "",
    "locationType": "",
    "EN": {
        "name": "",
        "description": "",
        "address": "",
    },
    "TH": {
        "name": "",
        "description": "",
        "address": "",
    },
    "CN": {
        "name": "",
        "description": "",
        "address": "",
    },
    "code": "",
    "picture": "",
    "tag":"",
    "country": "CTYI-20181126-000003",
    "isService": false,
}