import React from 'react';
import PropTypes from 'prop-types';
import BookingStatus from '../common/StatusBooking';

import { AreaChart, Area, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { Family, Size, Colors } from '../themes';
const data = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },];

const CMargin = { top: 10, right: 30, left: 0, bottom: 0 };

function BookingStatChart(props) {
    return (
        <AreaChart width={230} height={200} data={data} margin={CMargin} style={{ fontFamily: Family.roboto, fontSize: Size.chart.label }}>
            <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={Colors.theme} stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={Colors.theme} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={Colors.theme} stopOpacity={0} />
                </linearGradient>
            </defs>
            <XAxis dataKey="name" />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Area type="monotone" dataKey="uv" stroke={Colors.theme} fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="pv" stroke={Colors.theme} fillOpacity={1} fill="url(#colorPv)" />
        </AreaChart>);
}

// BookingStatChart.propTypes = { classes: PropTypes.object.isRequired, };

export default (BookingStatChart);