
import * as StatiticActions from '../actions/Actions_StatiticManager';

const INITIAL_STATE = { STATITIC_REDUCER: { actions: StatiticActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: StatiticActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {

    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case StatiticActions.INITIAL_STATE:
            {
                return state;
            }
        case StatiticActions.IDLE_STATE:
            {
                //console.log("[Statitic] : IDLE_STATE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.IDLE_STATE, data: payload, error: _error } };
            }
        case StatiticActions.GET_TOP_PRODUCT_STAT_SUCCESS:
            {
                //console.log("[Statitic] : GET_TOP_PRODUCT_STAT_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_TOP_PRODUCT_STAT_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_TOP_PRODUCT_STAT_FAILURE:
            {
                console.log("[Statitic] : GET_TOP_PRODUCT_STAT_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_TOP_PRODUCT_STAT_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_BOOKING_STATUS_SUCCESS:
            {
                //console.log("[Statitic] : GET_BOOKING_STATUS_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BOOKING_STATUS_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_BOOKING_STATUS_FAILURE:
            {
                console.log("[Statitic] : GET_BOOKING_STATUS_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BOOKING_STATUS_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_BEST_SUPPLIER_STAT_SUCCESS:
            {
                //console.log("[Statitic] : GET_BEST_SUPPLIER_STAT_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BEST_SUPPLIER_STAT_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_BEST_SUPPLIER_STAT_FAILURE:
            {
                console.log("[Statitic] : GET_BEST_SUPPLIER_STAT_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BEST_SUPPLIER_STAT_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUPPLIER_SUMMARY_INCOME_SUCCESS:
            {
                //console.log("[Statitic] : GET_SUPPLIER_SUMMARY_INCOME_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUPPLIER_SUMMARY_INCOME_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUPPLIER_SUMMARY_INCOME_FAILURE:
            {
                console.log("[Statitic] : GET_SUPPLIER_SUMMARY_INCOME_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUPPLIER_SUMMARY_INCOME_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUMMARY_SUPPLIER_STATUS_SUCCESS:
            {
                //console.log("[Statitic] : GET_SUMMARY_SUPPLIER_STATUS_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUMMARY_SUPPLIER_STATUS_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUMMARY_SUPPLIER_STATUS_FAILURE:
            {
                console.log("[Statitic] : GET_SUMMARY_SUPPLIER_STATUS_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUMMARY_SUPPLIER_STATUS_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUMMARY_AGENT_STATUS_SUCCESS:
            {
                //console.log("[Statitic] : GET_SUMMARY_AGENT_STATUS_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUMMARY_AGENT_STATUS_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUMMARY_AGENT_STATUS_FAILURE:
            {
                console.log("[Statitic] : GET_SUMMARY_AGENT_STATUS_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUMMARY_AGENT_STATUS_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_SUCCESS:
            {
                //console.log("[Statitic] : GET_SUMMARY_CUSTOMER_STATUS_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_FAILURE:
            {
                console.log("[Statitic] : GET_SUMMARY_CUSTOMER_STATUS_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_ALL_SUMMARY_INCOME_SUCCESS:
            {
                //console.log("[Statitic] : GET_ALL_SUMMARY_INCOME_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_ALL_SUMMARY_INCOME_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_ALL_SUMMARY_INCOME_FAILURE:
            {
                console.log("[Statitic] : GET_ALL_SUMMARY_INCOME_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_ALL_SUMMARY_INCOME_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS:
            {
                //console.log("[Statitic] : GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_ALL_SUMMARY_BOOKINGPAYMENT_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_ALL_SUMMARY_BOOKINGPAYMENT_FAILURE:
            {
                console.log("[Statitic] : GET_ALL_SUMMARY_BOOKINGPAYMENT_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_ALL_SUMMARY_BOOKINGPAYMENT_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS:
            {
                //console.log("[Statitic] : GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_FAILURE:
            {
                console.log("[Statitic] : GET_ALL_SUMMARY_BOOKINGCHART_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_ALL_SUMMARY_BOOKINGCHART_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_BEST_AGENT_STAT_SUCCESS:
            {
                //console.log("[Statitic] : GET_BEST_AGENT_STAT_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BEST_AGENT_STAT_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_BEST_AGENT_STAT_FAILURE:
            {
                console.log("[Statitic] : GET_BEST_AGENT_STAT_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BEST_AGENT_STAT_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_BEST_SELLER_STAT_SUCCESS:
            {
                //console.log("[Statitic] : GET_BEST_SELLER_STAT_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BEST_SELLER_STAT_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_BEST_SELLER_STAT_FAILURE:
            {
                console.log("[Statitic] : GET_BEST_SELLER_STAT_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BEST_SELLER_STAT_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_AGENT_SUMMARY_INCOME_SUCCESS:
            {
                //console.log("[Statitic] : GET_AGENT_SUMMARY_INCOME_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_AGENT_SUMMARY_INCOME_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_AGENT_SUMMARY_INCOME_FAILURE:
            {
                console.log("[Statitic] : GET_AGENT_SUMMARY_INCOME_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_AGENT_SUMMARY_INCOME_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_SELLER_SUMMARY_INCOME_SUCCESS:
            {
                //console.log("[Statitic] : GET_SELLER_SUMMARY_INCOME_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SELLER_SUMMARY_INCOME_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_SELLER_SUMMARY_INCOME_FAILURE:
            {
                console.log("[Statitic] : GET_SELLER_SUMMARY_INCOME_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_SELLER_SUMMARY_INCOME_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_TOP_PRODUCT_WITH_AGENT_SUCCESS:
            {
                //console.log("[Statitic] : GET_TOP_PRODUCT_WITH_AGENT_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_TOP_PRODUCT_WITH_AGENT_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_TOP_PRODUCT_WITH_AGENT_FAILURE:
            {
                console.log("[Statitic] : GET_TOP_PRODUCT_WITH_AGENT_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_TOP_PRODUCT_WITH_AGENT_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_TOP_PRODUCT_WITH_SELLER_SUCCESS:
            {
                //console.log("[Statitic] : GET_TOP_PRODUCT_WITH_SELLER_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_TOP_PRODUCT_WITH_SELLER_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_TOP_PRODUCT_WITH_SELLER_FAILURE:
            {
                console.log("[Statitic] : GET_TOP_PRODUCT_WITH_SELLER_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_TOP_PRODUCT_WITH_SELLER_FAILURE, data: payload, error: _error } };
            }
        case StatiticActions.GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS:
            {
                //console.log("[Statitic] : GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BOOKING_PACKAGE_TOP_OF_WEEK_SUCCESS, data: payload, error: _error } };
            }
        case StatiticActions.GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE:
            {
                console.log("[Statitic] : GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE : ", payload);
                return { ...state, STATITIC_REDUCER: { actions: StatiticActions.GET_BOOKING_PACKAGE_TOP_OF_WEEK_FAILURE, data: payload, error: _error } };
            }
        default:
            {

                //console.log("[Statitic]  default :", state);
                return state;
            }
    }
}
