import React from 'react';
import '../App.css';

const _style = { width: '100%', height: (window.innerHeight - 150), padding: 20 }

function PageNotFound(props) {

    const { classes } = props;
    return (<div className="App-PageNotFound" style={_style} />);
}

export default PageNotFound;