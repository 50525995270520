const HeaderPage = {
    TH: "สร้างเงื่อนไข",
    EN: "Create Condition",
    CN: "创造条件",
    UPDATE: "Update Condition",
}

const BodyPage = [
    [{
        title_TH: "ชื่อ", title_EN: "Condition Name", title_CN: "名称",
        type: "text",
        require: true,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ลักษณะ", title_EN: "Description", title_CN: "描述",
        type: "text-multiline",
        require: true,
        stateName: "description",
        placeholder_TH: "ลักษณะ", placeholder_EN: "Insert Description", placeholder_CN: "插入说明"
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "actived",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}