import React from 'react';
import InfoDCard from '../../componets/InfoDCard.jsx';
import '../../App.css';

const _Info = {
    Wallet: { name: 'Wallet', amount: 0, price: 0 },
    Total: { name: 'Total', amount: 0, price: 0 },
    OneDayTrip: { name: 'Onedaytrip', amount: 0, price: 0 },
    Package: { name: 'Package', amount: 0, price: 0 },
    Transfer: { name: 'Transfer', amount: 0, price: 0 },
    Ferries: { name: 'Ferries', amount: 0, price: 0 },
    Hotel: { name: 'Hotel', amount: 0, price: 0 },
}

const _flexStyle = { flex: 1, margin: 10 };

function SummaryBookingPanel(props) {

    const { SummaryBooking, Wallet } = props;

    // console.log('SummaryBooking : ', SummaryBooking);

    _Info.Wallet.price = Wallet;

    if (!!SummaryBooking) {

        SummaryBooking.data.map((item) => {
            const _key = Object.keys(item)[0];
            const _newItem = { [_key]: item };

            if (_key === 'Transfer') {
                _Info['Ferries'].amount = ((_newItem[_key])['ferries']) || 0;
                _Info['Ferries'].price = ((_newItem[_key])['netPriceFerries']) || 0;
            }

            _Info[_key].amount = ((_newItem[_key])[_key]) || 0;
            _Info[_key].price = ((_newItem[_key])['netPrice']) || 0;
        });

        _Info.Total.amount = SummaryBooking.totalSale;
        _Info.Total.price = SummaryBooking.totalPrice;
    }

    return (
        <div className="App-content" style={{ maxWidth: 1024 }}>
            <div style={{ flex: 2, margin: 10, marginLeft: 0 }}>
                <div style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    <InfoDCard Info={_Info.Total} Disable={false} Type='full' />
                </div>
                <div style={{ flex: 1, margin: 10, marginLeft: 0 }}>
                    <InfoDCard Info={_Info.Wallet} Disable={true} Type='full' />
                </div>
            </div>
            <div style={_flexStyle}>
                <div style={_flexStyle}>
                    <InfoDCard Info={_Info.OneDayTrip} Disable={false} Type='full' />
                </div>
                <div style={_flexStyle}>
                    <InfoDCard Info={_Info.Ferries} Disable={false} Type='full' />
                </div>
            </div>
            <div style={{ flex: 1, margin: 10, marginRight: 5 }}>
                <div style={{ flex: 1, margin: 10, marginRight: 5 }}>
                    <InfoDCard Info={_Info.Transfer} Disable={false} Type='full' />
                </div>
                <div style={{ flex: 1, margin: 10, marginRight: 5 }}>
                    <InfoDCard Info={_Info.Package} Disable={false} Type='full' />
                </div>
            </div>
        </div>)
}

export default SummaryBookingPanel;