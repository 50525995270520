export const init_condition = {
    "conditionId": "",
    "EN": {
        "name": "",
        "description": "",
    },
    "TH": {
        "name": "",
        "description": "",
    },
    "CN": {
        "name": "",
        "description": "",
    },
    "actived": false,
}

