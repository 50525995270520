import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { observer } from 'mobx-react'
import { observable, toJS } from 'mobx'
import TextField from '@material-ui/core/TextField';

// @observer
export default class EmailAutoComplete extends Component {
  email = this.props.value || ''
  domains = [...(this.props.domains || []), 'yahoo.com', 'hotmail.com', 'gmail.com', 'live.com', 'googlemail.com', 'msn.com', 'facebook.com', 'outlook.com',]
  prevValue = ''
  prevEmail = ''

  handleChange = ({ target: { value } }) => {
    const suggestion = this.suggest(value)
    this.email = value + suggestion
    if (suggestion) this.highlight(suggestion)
    this.props.onChange(this.email)
    this.prevEmail = value + suggestion
    this.prevValue = value
  }

  suggest(email) {
    const [emailName, partialDomain] = email.split('@')
    if (!partialDomain || email.length <= this.prevValue.length) return ''
    const domain = this.domains.find(d => d.indexOf(partialDomain) === 0) || ''
    return domain.replace(partialDomain, '')
  }

  highlight(suggestion) {
    setTimeout(() => {
      const startPos = this.email.lastIndexOf(suggestion)
      const endPos = startPos + suggestion.length
      this._input.setSelectionRange(startPos, endPos)
    }, 0)
  }

  render() {
    const { className, ...props } = this.props
    return (
      <TextField
        {...props}
        value={this.email}
        onChange={this.handleChange}
        inputRef={r => this._input = r}
      />
    )
  }
}

