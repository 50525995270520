export const constraints = {
  name: { 
    presence: { allowEmpty: false }, 
  },
  description: { 
    presence: { allowEmpty: false } 
  },
};

export const input = [
  { fieldName: 'name' },
  { fieldName: 'description' },
];
