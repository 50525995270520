const HeaderPage = {
    TH: "สร้างธนาคาร",
    EN: "Create Bank",
    CN: "创建银行",
    UPDATE_EN: 'Update Bank'
}


const BodyPage = [
    [{
        title_TH: "ชื่อ", title_EN: "Bank Name", title_CN: "名称",
        type: "text",
        isRequired: true,
        stateName: "name",
        placeholder_TH: "ชื่อ", placeholder_EN: "Insert Name", placeholder_CN: "插入名称"
    }], [{
        title_TH: "ลักษณะ", title_EN: "Description", title_CN: "描述",
        type: "text-multiline",
        stateName: "description",
        placeholder_TH: "ลักษณะ", placeholder_EN: "Insert Description", placeholder_CN: "插入说明"
    }], [{
        title_TH: "สถานะ", title_EN: "Status", title_CN: "状态",
        type: "switch",
        stateName: "actived",
    }], [{
        title_TH: "รูปภาพ", title_EN: "Logo", title_CN: "照片",
        type: "image",
        stateName: "logo",
    }]
];

export default {
    HeaderPage,
    BodyPage,
}