import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import CONFIG from '../../config';

import EmptyRows from '../../componets/EmptyRow.jsx';

import Language from '../../constant/Language';
import Button from '../../constant/Button';

import * as CommissionsActions from '../../actions/Actions_CommissionsManager';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as SellerActions from '../../actions/Actions_SellerManager.js';
import { Colors,Icons } from '../../themes/index.js';
const { BorderColorIcon, DoneIcon, CloseIcon } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'SELLER NAME', name: 'name', isOrder: false, },
    { label: 'TYPE', name: 'type', isOrder: false, },
    { label: 'ACTIVED', name: 'actived', isOrder: true, },
    { label: 'VISIBLE', name: 'visible', isOrder: true, },
];

const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _wording = { header: 'Special Manage' }
let waitingTime = true;
let sellerList = [];
let SellerTypeList = [];

class SpecialListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    render() {

        const { classes, onClickHandle, EditInfo, CreatePanel, SellerTypeList } = this.props;
        sellerList = this.props.sellerList ? (this.props.sellerList.datas || []) : [];
        // console.log('Props', this.props)
        const isSeller = (sellerList.length > 0);
        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow style={{ height: '100%' }}>
                            <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {fildeList.map((_filde, index) => {
                                return <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                    {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isSeller) ? GetBookingRows(sellerList, onClickHandle, EditInfo, classes, this.props.packageInfoList, SellerTypeList) : <EmptyRows fildeList={fildeList} />}
                    </TableBody>
                </Table>
                {(isSeller) && GetPagination(this.props.sellerList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
            </Paper>
        );
    }


    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        sellerList = (orderBy === 'desc')
            ? sellerList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : sellerList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPISellerList({ page: 0, num_limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPISellerList({ page: _pageNumber, num_limit: _limit || 25, language: _language });
    }

    onChangeLanguage = (_language) => {
        this.setState({ isLoading: true });
        this.props.getAPISellerList({ page: 0, limit: 25, language: _language });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================

}

function GetBookingRows(sellerList, onClickHandle, EditInfo, classes, packageInfoLis, SellerTypeList) {

    return sellerList.map(_info => {
        const { sellerId, name, logo, type, visible, actived } = _info;
        const sellerType = Array.isArray(SellerTypeList) && SellerTypeList.filter((val) => (val.sellerTypeId === type)).map((each) => each.name)
        const _backgroundImage = { width: '70px', height: '50px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${checkURL(logo)})`, backgroundRepeat: 'round' };
        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={sellerId} onClick={() => onClickHandle(_info)}>
                <TableCell style={{..._callStyle,width:50}} ><div style={_backgroundImage} /></TableCell>
                <TableCell className={classes.name} >{name || '-'}</TableCell>
                <TableCell className={classes.text_info} >{sellerType || '-'}</TableCell>
                <TableCell style={{ ..._callStyle, color: actived ? Colors.success : Colors.error }} >{GetIconWithStatus(actived)}</TableCell>
                <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }} >{GetIconWithStatus(visible)}</TableCell>
                {/* <TableCell    style={_callStyle}><BorderColorIcon onClick={() => { EditInfo(_info) }} /></TableCell> */}
            </TableRow>
        );
    });
}
function GetIconWithStatus(_status) { return ((_status) ? <DoneIcon /> : <CloseIcon />) }
function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {
    const { total, page, limit, pageSize, language } = _ListCount;
    // console.log('_ListCount', _ListCount)
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {/* <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} /> */}
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        table: { minWidth: 1024, },
        text_info: { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', },
        name: { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px' },
    });

SpecialListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpecialListPanel));