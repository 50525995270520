import React from 'react'
import styled from 'styled-components';

import { Colors, Family } from '../themes';
import FormTypes from '../common/FormTypes';
import ButtonText from '../constant/Button/ButtonText';
import Button from '../constant/Button';

import * as format from '../common/setFormat';

const RequestComponent = (props) => {
    const { Header, Action } = props;
    return (
        <Contianer>
            {(!Action) && <Typography> {Header}  </Typography>}
            <Line />
            <ListContainer>
                {renderContent(props)}
            </ListContainer>
            {
                (!Action) &&
                <React.Fragment>
                    <Line />
                    <ListContainer>
                        {renderAction(props)}
                    </ListContainer>
                </React.Fragment>
            }
            {renderButton(props)}
        </Contianer>
    )
};

function renderContent(props) {
    const { InfoLists } = props;
    return (
        InfoLists.map((each, index) => {
            return (
                <ListItmeContianer key={index}>
                    <Title>{each.title || '-'}</Title>
                    <Label>{each.value || '-'} </Label>
                </ListItmeContianer>

            )
        })
    )
};

function renderAction(props) {
    const { Structure } = props;
    return (
        Structure.map((row, index) => (
            <ListItmes key={index}>
                {
                    row.map((each, index) => (
                        <List key={index} width={each.width}>
                            {renderFormTypes({ each })}
                        </List>
                    ))
                }
            </ListItmes>
        ))
    )
};

function renderFormTypes(props) {
    const { each } = props;
    switch (each.type) {
        case 'text-multiline':
            return (
                <FormTypes.TextFormat multiline rows={3}
                    style={{ marginBottom: 10 }}
                    Require={each.require}
                    disabled={each.disabled}
                    TitleText={each.title_EN}
                    value={each.value}
                    onChange={each.onChange} />
            )
        case 'number':
            return (
                <FormTypes.TextFormat
                    InputFormat={format.PriceFormat}
                    Require={each.require}
                    disabled={each.disabled}
                    TitleText={each.title_EN}
                    value={each.value}
                    onChange={each.onChange} />
            )
        case 'checkbox':
            return (
                <FormTypes.Checkbox
                    disabled={each.disabled}
                    TitleText={each.title_EN}
                    value={each.value}
                    onChange={each.onChange} />
            )
        case 'select':
            return (
                <FormTypes.Select
                    disabled={each.disabled}
                    TitleText={each.title_EN}
                    Require={each.require}
                    value={each.value}
                    onChange={each.onChange}
                    options={each.options} />
            )
    };
};

function renderButton(props) {
    const { Action, onClickSave, onClickCancel, BtnSaveActive } = props;
    return (
        <React.Fragment>
            <Line />
            <ButtonContianer>
                <Button.Themes TitleText={ButtonText.BUTTON_CANCEL}
                    onClick={onClickCancel}
                    style={{ width: '100px', marginRight: (!Action) ? 15 : 0 }}
                    buttonStyle={{ margin: 0, padding: 7 }} />
                {
                    (!Action) &&
                    <Button.Themes TitleText={ButtonText.BUTTON_SAVE}
                        onClick={onClickSave}
                        disabled={BtnSaveActive}
                        style={{ width: '100px' }}
                        buttonStyle={{ margin: 0, padding: 7 }} />
                }
            </ButtonContianer>
        </React.Fragment>
    )
};

export default RequestComponent;

const Contianer = styled.div`
        max-width: 800px;
        font-family: ${Family.roboto};`;
const Line = styled.div`
        height:3px;
        background-color:${Colors.theme}`;
const Typography = styled.div`            
        padding: 15px 0px;
        font-size: 24px;`;
const ListContainer = styled.div`
        display: flex;
        flex-direction: column;
        padding: 10px 0px;
        font-size: 16px; `;
const ListItmeContianer = styled.div`
        display: flex;
        padding: 7px 0px;`;
const ListItmes = styled.div`
        display: flex;
        padding: 5px 0px;
        width: 100%;`;
const List = styled.div`
        width: ${props => props.width ? props.width : '100%'}`;
const ButtonContianer = styled.div`
        display: flex;
        padding: 15px 0px;
        justify-content: flex-end;`;
const Title = styled.div`
        font-weight:bold;
        padding-right:5px;`;
const Label = styled.div`
        color:${Colors.grey};
        font-weight:300; `;
