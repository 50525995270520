
import * as SettingActions from '../actions/Actions_Settings';

const INITIAL_STATE = { SETTING_REDUCER: { actions: SettingActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: SettingActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case SettingActions.INITIAL_STATE:
            {
                return state;
            }
        case SettingActions.IDLE_STATE:
            {
                //console.log("[ Setting ] : IDLE_STATE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.IDLE_STATE, data: payload, error: _error } };
            }
        case SettingActions.GET_BANKLIST_SETTING_SUCCESS:
            {
                //console.log("[ Setting ] : GET_BANKLIST_SETTING_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.GET_BANKLIST_SETTING_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.GET_BANKLIST_SETTING_FAILURE:
            {
                console.log("[ Setting ] : GET_BANKLIST_SETTING_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.GET_BANKLIST_SETTING_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.CREATE_BANK_SETTING_SUCCESS:
            {
                //console.log("[ Setting ] : CREATE_BANK_SETTING_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.CREATE_BANK_SETTING_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.CREATE_BANK_SETTING_FAILURE:
            {
                console.log("[ Setting ] : CREATE_BANK_SETTING_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.CREATE_BANK_SETTING_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.UPDATE_BANK_SETTING_SUCCESS:
            {
                // console.log("[ Setting ] : UPDATE_BANK_SETTING_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.UPDATE_BANK_SETTING_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.UPDATE_BANK_SETTING_FAILURE:
            {
                console.log("[ Setting ] : UPDATE_BANK_SETTING_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.UPDATE_BANK_SETTING_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.GET_PAYMENT_TIME_SUCCESS:
            {
                //console.log("[ Setting ] : GET_PAYMENT_TIME_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.GET_PAYMENT_TIME_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.GET_PAYMENT_TIME_FAILURE:
            {
                console.log("[ Setting ] : GET_PAYMENT_TIME_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.GET_PAYMENT_TIME_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.UPDATE_PAYMENT_TIME_SUCCESS:
            {
                //console.log("[ Setting ] : UPDATE_PAYMENT_TIME_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.UPDATE_PAYMENT_TIME_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.UPDATE_PAYMENT_TIME_FAILURE:
            {
                console.log("[ Setting ] : UPDATE_PAYMENT_TIME_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.UPDATE_PAYMENT_TIME_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
            {
                //console.log("[ Setting ] : GET_BANKLIST_SETTING_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
            {
                console.log("[ Setting ] : GET_DEFAULT_COMMISSIONS_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.CREATE_DEFAULT_COMMISSIONS_SUCCESS:
            {
                //console.log("[ Setting ] : CREATE_DEFAULT_COMMISSIONS_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.CREATE_DEFAULT_COMMISSIONS_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.CREATE_DEFAULT_COMMISSIONS_FAILURE:
            {
                console.log("[ Setting ] : CREATE_DEFAULT_COMMISSIONS_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.CREATE_DEFAULT_COMMISSIONS_FAILURE, data: payload, error: _error } };
            }
        case SettingActions.UPDATE_DEFAULT_COMMISSIONS_SUCCESS:
            {
                // console.log("[ Setting ] : UPDATE_DEFAULT_COMMISSIONS_SUCCESS : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.UPDATE_DEFAULT_COMMISSIONS_SUCCESS, data: payload, error: _error } };
            }
        case SettingActions.UPDATE_DEFAULT_COMMISSIONS_FAILURE:
            {
                console.log("[ Setting ] : UPDATE_BANK_SETTING_FAILURE : ", payload);
                return { ...state, SETTING_REDUCER: { actions: SettingActions.UPDATE_BANK_SETTING_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[ Setting ]  default :", state);
                return state;
            }
    }
}
