export const PLATFORM_NAME = { TRAVISGO: 'TravisGo', PHIPHITOURS: 'PhiPhiTours' };

const path = './platform/';

export const getPlatForm = (isPlatFrom) => {

    switch (isPlatFrom) {
        case PLATFORM_NAME.PHIPHITOURS: return `${path}${PLATFORM_NAME.PHIPHITOURS}`;
        case PLATFORM_NAME.TRAVISGO:
        default: return `${path}${PLATFORM_NAME.TRAVISGO}`;
    }
}

export const getTheme = (isPlatFrom) => {

    switch (isPlatFrom) {
        case PLATFORM_NAME.PHIPHITOURS: return true;
        case PLATFORM_NAME.TRAVISGO:
        default: return true;
    }
}