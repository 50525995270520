export const constraints = {
    name: {
        presence: { allowEmpty: false, },
    },
    email: {
        email: true,
        presence: { allowEmpty: false, },
    },
    level: {
        presence: { allowEmpty: false, },
    },

};
export const input = [
    { fieldName: 'name', },
    { fieldName: 'email', },
    { fieldName: 'level', }
];

