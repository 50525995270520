import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { InitWaitingTime, SetStateWithTime, } from '../../common/SetTime.js';

import Language from '../../constant/Language';

import EmptyRows from '../../componets/EmptyRow.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';

import CONFIG from '../../config';

import { Family, Colors, Icons } from '../../themes';

import * as DestinationsActions from '../../actions/Actions_DestinationsManager';
const { BorderColorIcon } = Icons

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', fontFamily: Family.roboto };

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'name', isOrder: true, },
    { label: 'DETAIL', name: 'detail', isOrder: false, },
    { label: 'LOCATION', name: 'location', isOrder: false, },
    { label: 'STATUS', name: 'status', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _wording = { header: 'Destination Manage' }
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };

let DestinationsList = [];
let waitingTime = true;

class DestinationListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    componentWillReceiveProps(nextProps) {
        const { DestinationsManager, } = nextProps;

        if (!!DestinationsManager) {
            const { actions, data } = DestinationsManager.DESTINATION_REDUCER;
            switch (actions) {
                case DestinationsActions.GET_DESTINATIONS_SUCCESS:
                    {
                        DestinationsList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case DestinationsActions.GET_DESTINATIONS_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break;
            }
        }
    }

    render() {
        const { classes, CreatePanel, EditInfo, ViewInfo, } = this.props;
        const { datas, } = this.props.DestinationsList;
        DestinationsList = datas || [];

        const _isDestination = (DestinationsList.length > 0);

        const { isLoading, msgError, dialogType } = this.state;

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }}
                                    // onClick={() => this.setState({ searchActive: !searchActive })} 
                                    />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {(_isDestination) ? DestinationsList.map(info => {
                                const { id, actived, picture, gallery } = info;

                                const LanguageDestination = path(['language',], this.props.DestinationsList);
                                const NAME = info[LanguageDestination].name;
                                const DESCRIPTION = info[LanguageDestination].description;
                                const ADDRESS = info[LanguageDestination].address;

                                const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${path([0, 'thumbnail'], gallery)})`, backgroundRepeat: 'round' };

                                return (
                                    <TableRow hover style={{ cursor: 'pointer', }} key={id} >
                                        <TableCell style={{ ..._callStyle, ...{ minWidth: 80, paddingRight: 5 } }} onClick={() => { ViewInfo(info) }} ><div style={_backgroundImage} /></TableCell>
                                        <TableCell style={{ ..._callName, ...{ minWidth: 120 } }} onClick={() => { ViewInfo(info) }} >{NAME || '-'}</TableCell>
                                        <TableCell style={_callName} onClick={() => { ViewInfo(info) }} >{DESCRIPTION || '-'}</TableCell>
                                        <TableCell style={_callName} onClick={() => { ViewInfo(info) }} >{ADDRESS || '-'}</TableCell>
                                        <TableCell style={{ ..._callStyle, ...{ color: (actived) ? Colors.success : Colors.error } }} onClick={() => { }} >{actived ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell style={_callStyle}><Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(info) }} /></TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(_isDestination) && GetPagination(this.props.DestinationsList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
                </Paper>
            </React.Fragment>
        );
    }

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        DestinationsList = (orderBy === 'desc')
            ? DestinationsList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : DestinationsList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIDestinationsLists({ page: 0, num_limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        this.setState({ isLoading: true });
        this.props.getAPIDestinationsLists({ page: _pageNumber, num_limit: _limit, language: _language });
    }

    onChangeLanguage = (_language) => {
        this.setState({ isLoading: true });
        this.props.getAPIDestinationsLists({ page: 0, num_limit: 10, language: _language });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {

    const { total, page, limit, language } = _ListCount;
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
}


const styles = theme => (
    {
        root: { width: 1024, marginTop: theme.spacing.unit * 3, overflowX: 'auto', marginBottom: 10, },
        table: { minWidth: 1000, },
    });

DestinationListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIDestinationsLists: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(DestinationsActions.getDestinationsList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DestinationListPanel));