import React from 'react';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FormTypes from '../../common/FormTypes';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel, { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanelActions, } from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../constant/Button';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import ButtonText from '../../constant/Button/ButtonText';
import CONFIG from '../../config';
// import { BorderColorIcon } from '../../themes/Icons';
// import { DoneIcon, CloseIcon, OfflinePin, Save, CheckCircle } from '../../themes/Icons.js';
import { Colors, Icons } from '../../themes';
const { BorderColorIcon } = Icons;

class PackageList extends React.Component {
    state = { isSpecial: false, expanded: null, }
    render() {
        const { isSpecial, SpecialInfoItem, expanded } = this.state;
        const { classes, each, onClick, Left, Right, SpecialList, SpecialInfo, PackageList, SellerId, isCreate } = this.props;
        let InfoList = Array.isArray(SpecialList.datas) && SpecialList.datas.filter((val) => val.affiliateId === SellerId)
        // console.log('InfoList', InfoList);

        return (
            <div style={{ width: '100%', padding: '0px' }}>
                {(isSpecial) && <DialogAction Active={isSpecial} OnClose={this.onClosePanel} AcrionCom={this.GetPanelSpecial()} />}


                <Button.ThemesDark
                    style={{ marginLeft: Left, marginRight: Right, width: '100%' }}
                    TitleText={each.title_EN} onClick={onClick} />
                <div style={{ overflow: 'overlay', maxHeight: isCreate ? '150px' : '400px', border: `${'1px solid' + Colors.border}`, marginBottom: 70 }}>
                    {
                        (Array.isArray(InfoList) && InfoList.length > 0) ?
                            InfoList.map((value, index) => {
                                const _backgroundImage = { width: '70px', height: '50px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${CONFIG.host}${path(['packageImage', 'thumbnail'], value)})` || '', backgroundRepeat: 'round' };

                                return (
                                    <div key={index} className={classes.item}  >
                                        <div style={{ padding: '10px', borderTop: index === 0 ? null : `${'1px solid' + Colors.border}` }}>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative' }} >
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <div style={_backgroundImage} />
                                                    <div className={classes.text}>{path(['packageName'], value) || '-'}   </div>
                                                </div>
                                                <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => this.setState({ isSpecial: !isSpecial, SpecialInfoItem: value })} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '14px' }}>
                                <Typography >{'Not Found'} </Typography>
                            </div>

                    }
                </div>
            </div>
        )
    }
    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
            isSpecial: true
        });
    };

    GetPanelSpecial() {
        const { SpecialInfoItem } = this.state;
        const { CommissionForm, PackageList, onChange, onUpdate } = this.props;
        return (
            <FormTypes.FormSpecial
                onChange={onChange}
                onUpdate={onUpdate}
                onClosePanel={this.onClosePanel}
                isEdit={true}
                StateList={CommissionForm}
                PackageAll={PackageList}
                SpecialInfo={SpecialInfoItem}
            />
        )
    }
    onSavePanel = () => (this.setState({ isSpecial: false }, () => this.props.onUpdate()))
    onClosePanel = () => (this.setState({ isSpecial: false }));
}
PackageList.propTypes = { classes: PropTypes.object.isRequired, label: PropTypes.string };

const styles = theme => (
    {
        item: { padding: '0px' },
        title: {
            fontSize: 18, color: Colors.darkGrey, marginBottom: 15, marginTop: 15,
        },
        textDefalut: { margin: '5px 0px', fontSize: '14px', color: Colors.grey },
        text: { fontSize: '14px', padding: '5px 20px 0px', textOverflow: 'ellipsis', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', lineHeight: '40px' },
        root: {
            width: '100%',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: '33.33%',
            flexShrink: 0,
            padding: '5px 20px 0px',
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', lineHeight: '40px'
        },
        secondaryHeading: {
            fontSize: theme.typography.pxToRem(15),
            color: theme.palette.text.secondary,
        },
    });

export default withStyles(styles)(PackageList)