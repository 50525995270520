import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE';

export const GET_ADMIN_INFO_SUCCESS = 'GET_ADMIN_INFO_SUCCESS';
export const GET_ADMIN_INFO_FAILURE = 'GET_ADMIN_INFO_FAILURE';

export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_FAILURE = 'CREATE_ADMIN_FAILURE';

export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';

export const GET_USER_ALLS_SUCCESS = 'GET_USER_ALLS_SUCCESS';
export const GET_USER_ALLS_FAILURE = 'GET_USER_ALLS_FAILURE';


// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getAdminList ============================================================================================================================

export function getAdminList(_keyword = '', _fliterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAdminList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fliterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAdminList : ", response);
            dispatch(getAdminList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAdminList Failure", error);
            dispatch(getAdminList_Failure(error));
        })
    }
}

function getAdminList_Success(data) { return { type: GET_ADMIN_SUCCESS, payload: data }; }

function getAdminList_Failure(error) { return { type: GET_ADMIN_FAILURE, payload: error }; }

// getAdminList ======================================================================================================================================

// getAdminInfo ============================================================================================================================

export function getAdminInfo(id, _fliterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAdminInfo}${id}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fliterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAdminInfo : ", response);
            dispatch(getAdminInfo_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAdminInfo Failure", error);
            dispatch(getAdminInfo_Failure(error));
        })
    }
}

function getAdminInfo_Success(data) { return { type: GET_ADMIN_INFO_SUCCESS, payload: data }; }

function getAdminInfo_Failure(error) { return { type: GET_ADMIN_INFO_FAILURE, payload: error }; }

// getAdminInfo ======================================================================================================================================

// crateAdminList ======================================================================================================================================

export function APICreateAdmin(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createAdmin}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APICreateAdmin : ", response.data);
            dispatch(APICreateAdmin_Success(response.data));

        }).catch((error) => {
            console.log("APICreateAdmin fail", error);
            dispatch(APICreateAdmin_Failure(error));
        })
    }
}

function APICreateAdmin_Success(data) { return { type: CREATE_ADMIN_SUCCESS, payload: data }; }

function APICreateAdmin_Failure(error) { return { type: CREATE_ADMIN_FAILURE, payload: error }; }

// updateAdminList ======================================================================================================================================

export function APIUpdateAdmin(confirmInfo) {
    // console.log('APIUpdateAdmin : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateAdmin}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpdateAdmin : ", response.data);
            dispatch(APIUpdateAdmin_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateAdmin fail", error);
            dispatch(APIUpdateAdmin_Failure(error));
        })
    }
}

function APIUpdateAdmin_Success(data) { return { type: UPDATE_ADMIN_SUCCESS, payload: data }; }

function APIUpdateAdmin_Failure(error) { return { type: UPDATE_ADMIN_FAILURE, payload: error }; }




export function getUserAlls(_keyword = '', _fileterjson = {}) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getUserAlls}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            data: _fileterjson,
            params: _keyword,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] GET UserAlls : ", response.data);
            dispatch(getUserAlls_Success(response.data));

        }).catch((error) => {
            console.log("GET UserAlls fail", error);
            dispatch(getUserAlls_Failure(error));
        })
    }
}

function getUserAlls_Success(data) {
    return { type: GET_USER_ALLS_SUCCESS, payload: data };
}

function getUserAlls_Failure(error) {
    return { type: GET_USER_ALLS_FAILURE, payload: error };
}

