
import * as AdminActions from '../actions/Actions_AdminManager.js';

const INITIAL_STATE = { ADMIN_REDUCER: { actions: AdminActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: AdminActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case AdminActions.INITIAL_STATE:
            {
                return state;
            }
        case AdminActions.IDLE_STATE:
            {
                //console.log("[Admin ] : IDLE_STATE : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.IDLE_STATE, data: payload, error: _error } };
            }
        case AdminActions.GET_ADMIN_SUCCESS:
            {
                //console.log("[Admin ] : GET_ADMIN_SUCCESS : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.GET_ADMIN_SUCCESS, data: payload, error: _error } };
            }
        case AdminActions.GET_ADMIN_FAILURE:
            {
                console.log("[Admin ] : GET_ADMIN_FAILURE : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.GET_ADMIN_FAILURE, data: payload, error: _error } };
            }
        case AdminActions.GET_ADMIN_INFO_SUCCESS:
            {
                //console.log("[Admin ] : GET_ADMIN_INFO_SUCCESS : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.GET_ADMIN_INFO_SUCCESS, data: payload, error: _error } };
            }
        case AdminActions.GET_ADMIN_INFO_FAILURE:
            {
                console.log("[Admin ] : GET_ADMIN_INFO_FAILURE : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.GET_ADMIN_INFO_FAILURE, data: payload, error: _error } };
            }
        case AdminActions.CREATE_ADMIN_SUCCESS:
            {
                //console.log("[Admin ] : CREATE_ADMIN_SUCCESS : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.CREATE_ADMIN_SUCCESS, data: payload, error: _error } };
            }
        case AdminActions.CREATE_ADMIN_FAILURE:
            {
                console.log("[Admin ] : CREATE_ADMIN_FAILURE : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.CREATE_ADMIN_FAILURE, data: payload, error: _error } };
            }
        case AdminActions.UPDATE_ADMIN_SUCCESS:
            {
                // console.log("[Admin ] : UPDATE_ADMIN_SUCCESS : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.UPDATE_ADMIN_SUCCESS, data: payload, error: _error } };
            }
        case AdminActions.UPDATE_ADMIN_FAILURE:
            {
                console.log("[Admin ] : UPDATE_ADMIN_FAILURE : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.UPDATE_ADMIN_FAILURE, data: payload, error: _error } };
            }
        case AdminActions.GET_USER_ALLS_SUCCESS:
            {
                // console.log("[Admin ] : GET_USER_ALLS_SUCCESS : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.GET_USER_ALLS_SUCCESS, data: payload, error: _error } };
            }
        case AdminActions.GET_USER_ALLS_FAILURE:
            {
                console.log("[Admin ] : GET_USER_ALLS_FAILURE : ", payload);
                return { ...state, ADMIN_REDUCER: { actions: AdminActions.GET_USER_ALLS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[Admin ]  default :", state);
                return state;
            }
    }
}
