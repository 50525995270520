
export const InitWaitingTime = (waitingTime) => {
    waitingTime = true;
    setTimeout(() => { waitingTime = false }, 2000);
}

export const SetStateWithTime = (_salf, waitingTime, timeOut = 480) => {

    // const { isLoading } = Boolean(_salf.state) ? _salf.state : false;

    // if (isLoading) {

    if (waitingTime) {
        setTimeout(() => { _salf.setState({ isLoading: false }) }, timeOut);
    }
    else
        _salf.setState({ isLoading: false });
    // }
}

export const SetStateWithObject = (_salf, waitingTime, StateObject, timeOut = 480) => {

    if (waitingTime) {
        setTimeout(() => { _salf.setState(StateObject) }, timeOut);
    }
} 