import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path, remove, keys, omit } from 'ramda';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconClose from '@material-ui/icons/HighlightOff';
import ListItemText from '@material-ui/core/ListItemText';

// import { TextField } from 'material-ui';
// import Switch from '@material-ui/core/Switch';
// import Checkbox from '@@material-ui/core/core/Checkbox';

// =========================================================================================================================================
// import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepButton from '@material-ui/core/StepButton';
// import  Button  from '@material-ui/core/Button';
// import Paper from '@material-ui/core/Paper';
// import Typography from '@material-ui/core/Typography';
// =========================================================================================================================================

import CONFIG from '../../config';
import Avatar from '../../constant/Avatar';
import Button from '../../constant/Button';
import ButtonText from '../../constant/Button/ButtonText';

// import { create_promotionCode, content_promotionCode, PromotionCodeStruct, SelectCodeType } from '../../constant/JSON/CreatePromotinCode';

import { Colors, Family } from '../../themes';

import TimePickers from '../../componets/TimePickers';
import DialogAction from '../../componets/DialogAction';
import DialogPanel from '../../componets/DialogPanel.jsx';

import * as format from '../../common/setFormat';
import * as PromotionActions from '../../actions/Actions_PromotionManager';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as SupplierActions from '../../actions/Actions_SupplierManager.js';
import * as SellerActions from '../../actions/Actions_SellerManager.js';
import * as AgentAction from '../../actions/Actions_AgentManager.js';

import * as validator from '../../constant/Validator/PromotionCode';
import PromotionCode from '../../constant/JSON/PromotionCode';
import { init_promotion_code } from '../../constant/initState/initPromotionCode';

import FormTypes from '../../common/FormTypes';
import { CheckEmailFormat } from '../../common/EmailFormat.js'
import { SetDate, getFormatted } from '../../common/SetDate.js'
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import validate from "validate.js";
import SelectTag from './SelectTag';
import ConditionPanel from './ConditionPanel.jsx';
import SelectPackageCard from './SelectPackageCard';
import DiscountWithProdutListPanel from './DiscountWithProdutListPanel.jsx';

import '../Supplier/Supplier.css';
import 'react-datepicker/dist/react-datepicker.css';

let requiredList = [];
let waitingTime = true;
let PackageList = [];
let SupplierList = [];
let SellerList = [];
let AgentList = [];
let isCheck;

const _rowContainer = { display: 'flex', };
class PromotionCodeCard extends Component {

    state = { isLoading: false, isBtnActive: false, msgError: undefined, dialogType: undefined, };

    constructor(props) {

        super(props);
        const { PromotionCodeInfo, isEdit } = this.props;

        this.state = {
            isPackageLoading: false,
            isPackage: false,
            isSuccess: false,
            showPackage: false,
            showEditPackage: false,
            isTag: false,
            activeStep: 0,
            _PromotionCodeInfo: PromotionCodeInfo || { ...init_promotion_code, tag: [], product: [] }
        }

        if (isEdit) {
            const { getAPIPackage, getAPISupplierList, getAPISellerList, getAPIAgentList } = this.props;
            const { tag, product } = PromotionCodeInfo;

            if (tag.length > 0)
                getAPISellerList();
            if (product.length > 0)
                getAPIPackage();
        }

        // getAPIPackage();
        // getAPISupplierList();
        // getAPISellerList();
        // getAPIAgentList();
    }

    componentWillReceiveProps(nextProps) {

        const { PromotionManager, PackageManager, SupplierManager, SellerManager, AgentManager } = nextProps;

        if (!!PromotionManager) {

            const { actions, data } = PromotionManager.PROMOTIONS_REDUCER;

            switch (actions) {
                case PromotionActions.CREATE_PROMOTIONS_CODE_SUCCESS:
                    {
                        this.props.getAPIPromotionsCodeList({ num_limit: 25 });
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Promotion Code Success.', dialogType: 'success', isSuccess: true });
                    } break;
                case PromotionActions.UPDATE_PROMOTIONS_CODE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Promotion Code Success.', dialogType: 'success', isSuccess: true });
                        this.props.getAPIPromotionsCodeList();
                    } break;
                case PromotionActions.UPDATE_PROMOTIONS_CODE_FAILURE:
                case PromotionActions.CREATE_PROMOTIONS_CODE_FAILURE:
                    {
                        let _msg;
                        if (!!data.response.data.error) {
                            console.log(`${actions} : `, data.response.data.error.message);
                            _msg = data.response.data.error.message || actions;
                        }
                        else
                            _msg = actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!PackageManager) {

            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_SUCCESS:
                    {
                        PackageManager.PACKAGE_REDUCER.actions = PackageAction.WATING_STATE;
                        console.log(`${actions} : `);
                        PackageList = (data.package || []);
                        this.state.isPackageLoading = true;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case PackageAction.GET_PACKAGE_FAILURE:
                    {
                        PackageManager.PACKAGE_REDUCER.actions = PackageAction.WATING_STATE;
                        this.state.isPackageLoading = true;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SupplierManager) {

            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_SUCCESS:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        console.log(`${actions} : `);
                        SupplierList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_FAILURE:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!SellerManager) {

            const { actions, data } = SellerManager.SELLER_REDUCER;

            switch (actions) {
                case SellerActions.GET_SELLER_SUCCESS:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        console.log(`${actions} : `);
                        SellerList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_FAILURE:
                    {
                        SellerManager.SELLER_REDUCER.actions = SellerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!AgentManager) {

            const { actions, data } = AgentManager.AGENT_REDUCER;

            switch (actions) {
                case AgentAction.GET_AGENT_SUCCESS:
                    {
                        AgentManager.AGENT_REDUCER.actions = AgentAction.WATING_STATE;
                        console.log(`${actions} : `);
                        AgentList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AgentAction.GET_AGENT_FAILURE:
                    {
                        AgentManager.AGENT_REDUCER.actions = AgentAction.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    componentWillUnmount() {

        this.state._PromotionCodeInfo = undefined;

    }

    render() {

        const { OnClose, classes, isEdit } = this.props;
        const { isBtnActive, isLoading, msgError, dialogType, isPackage, isTag } = this.state;
        const { HeaderPage, BodyPage } = PromotionCode;

        requiredList = [];

        return (
            <div style={{ maxWidth: 800, margin: '5px' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                {(isPackage) && <DialogAction Active={isPackage} OnClose={this.onClosePanelPackage} AcrionCom={this.GetPackage()} />}
                {(isTag) && <DialogAction Active={isTag} OnClose={this.onClosePanelTag} AcrionCom={this.GetSupplierList()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{isEdit ? HeaderPage.UPDATE_EN : HeaderPage.CREATE_EN}</Typography>
                </div>
                {/* {
                    BodyPage.map((row, index) => (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (
                                <div key={index} style={{ marginTop: 5, width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, flexDirection: each.flex ? 'row' : null }}>
                                    {this.SetItemsWithType(each)}
                                </div>))}
                        </div>
                    ))
                } */}
                {this.StepDispalyStyle()}
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => OnClose()} />
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_SAVE} disabled={!isBtnActive}
                        onClick={() => this.onSave()} />
                </div>
            </div >
        )
    }

    SetItemsWithType(each) {

        // console.log('_PromotionCodeInfo', this.state._PromotionCodeInfo);
        const { classes, CurrencyList } = this.props;
        const { _PromotionCodeInfo } = this.state;

        if (each.required)
            requiredList.push(each.stateName);

        switch (each.type) {
            case 'text': {
                return (
                    <FormTypes.TextFormat
                        Require={each.required}
                        helperText={each.helperText}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder}
                        value={_PromotionCodeInfo[each.stateName] || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case 'text-multiline': {
                return (
                    <FormTypes.TextFormat multiline rows={4}
                        // error={!!path([each.stateName], error)}
                        Require={each.required}
                        helperText={each.helperText}
                        TitleText={each.title_EN}
                        placeholder={each.placeholder}
                        value={_PromotionCodeInfo[each.stateName] || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />

                )
            }
            case 'number': {

                let percent = _PromotionCodeInfo.discountType === 'percent';
                let limit = each.stateName === 'limit';

                return (
                    <FormTypes.TextFormat
                        Require={each.required}
                        InputFormat={(percent && !limit) ? format.PercentFormat : format.PriceFormat}
                        TitleText={each.title_EN}
                        disabled={each.stateName === 'minimum' ? _PromotionCodeInfo['checkMin'] === false ? true : false : false ||
                            each.stateName === 'maximum' ? _PromotionCodeInfo['checkMax'] === false ? true : false : false}
                        placeholder={each.placeholder}
                        // style={{ marginLeft: each.stateName === 'maximum' || each.stateName === 'minimum' ? '-60px' : 'unset' }}
                        value={path([each.stateName], _PromotionCodeInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }} />
                )
            }
            case 'select': {
                return (
                    <FormTypes.Select
                        TitleText={each.title_EN}
                        value={_PromotionCodeInfo[each.stateName] || ''}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                        disabled={each.stateName === 'product' ? _PromotionCodeInfo['checkProduct'] === false ? true : false : false}
                        options={
                            each.stateName === 'currency' ?
                                Array.isArray(CurrencyList) && CurrencyList.length > 0 && CurrencyList.map((option, index) => {
                                    return (<option key={index} value={option.currencyType}>{option.currencyType}</option>)
                                })
                                :
                                each.stateName === 'codeType' ?
                                    Array.isArray(PromotionCode.SelectCodeType) && PromotionCode.SelectCodeType.length > 0 && PromotionCode.SelectCodeType.map((option, index) => {
                                        return (<option key={index} value={option.value}>{option.label}</option>)
                                    })
                                    :
                                    each.option.map((option, index) => (<option key={index} value={option.name}>{option.name}</option>))
                        } />
                )
            }
            case 'button-list': {

                const { codeType, tag } = _PromotionCodeInfo;
                let codeInfo = _PromotionCodeInfo.tag ? _PromotionCodeInfo.tag : [];


                // const StateList = (codeType === 'supplier') ? SupplierList.filter(supplier => codeInfo.some(val => val === supplier.supplierId)) :
                //     (codeType === 'seller' || codeType === 'blogger') ? SellerList.filter(seller => codeInfo[0] === seller.sellerId) :
                //         (codeType === 'agent') ? AgentList.filter(agent => codeInfo[0] === agent.agentId) : [];

                const { showPackage, showEditPackage } = this.state;
                const StateList = this.getStateList(codeType, codeInfo);

                return (
                    <React.Fragment>
                        <Button.Themes style={{ width: '100%' }}
                            disabled={['all', 'system', ""].includes(codeType)} // (codeType === 'all' || codeType === 'system' || codeType === "") ? true : false
                            TitleText={each.title_EN}
                            onClick={() => this.onCreatePanelTag(codeType)} />
                        <div style={{ position: 'relative', padding: '5px', border: StateList && StateList.length > 0 ? '1px solid #bfbfbf' : null, position: 'relative' }}>
                            {(showPackage || showEditPackage || this.props.isEdit) &&
                                StateList && Array.isArray(StateList) && StateList.length > 0 && StateList.map((each, index) => {
                                    const _backgroundImage = { width: '70px', height: '40px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${each.logo.thumbnail})` || '', backgroundRepeat: 'round' };
                                    return (
                                        <div key={index} className={classes.item} >
                                            <div style={{ display: 'flex', paddingLeft: '15px' }} >
                                                <div style={_backgroundImage} />
                                                <div className={classes.price}>
                                                    <ListItemText style={{ fontSize: '16px', paddingLeft: '10px' }} primary={each.name || ''} />
                                                </div>
                                                <IconClose style={{ color: Colors.darkGrey, position: 'absolute', right: 10, cursor: 'pointer' }}
                                                    onClick={(codeType === 'supplier') ? this.handleToggleTag(each) :
                                                        this.handleDelete(each)} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </React.Fragment>
                )
            }
            case 'condition': {
                return (
                    <ConditionPanel
                        onClosePanelPackage={this.onClosePanelPackage}
                        openPackage={this.state.showPackage || this.state.showEditPackage || this.props.isEdit}
                        handleToggle={this.handleToggle}
                        onCreatePanel={this.onCreatePanel}
                        GetPackage={this.GetPackage}
                        onCreatePanel={this.onCreatePanel}
                        StateInfo={_PromotionCodeInfo}
                        onChange={this.onChangeState}
                        PackageList={PackageList}
                    />
                )
            }
            case 'product-list': {

                const { isPackageLoading } = this.state;
                const { isDiscountEachProduct, checkProduct, product } = _PromotionCodeInfo;

                switch (true) {
                    case (checkProduct): { isCheck = 'discountEachProduct'; _PromotionCodeInfo.isDiscountEachProduct = true; } break;
                    case (isCheck === "allProduct"): isCheck = 'allProduct'; break;
                    default: isCheck = 'autoSet'; break;
                }

                // console.log('_PromotionCodeInfo : ', _PromotionCodeInfo);

                return (
                    <React.Fragment>
                        <RadioGroup style={{ paddingBottom: 20 }} value={isCheck} onChange={(e) => this.handleDiscoutProductPanel({ type: e.target.value, _PromotionCodeInfo })} row>
                            <FormControlLabel
                                value="autoSet"
                                control={<Radio />}
                                label="Set discount all product."
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="allProduct"
                                control={<Radio />}
                                label="Set discount by product."
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="discountEachProduct"
                                disabled={(product.length === 0)}
                                control={<Radio />}
                                label="Set discount dentify product."
                                labelPlacement="end"
                            />
                        </RadioGroup>
                        <DiscountWithProdutListPanel packageInfoList={{ packageList: PackageList }} StateInfo={_PromotionCodeInfo} isPackageLoading={isPackageLoading} displayType={isCheck} />
                    </React.Fragment>
                )
            }
            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        ValueText={each.title_EN}
                        checked={path(['actived'], _PromotionCodeInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                )
            }

            case 'date': {

                const { required, stateStart, stateEnd } = each;

                return (
                    // <div style={{ marginTop: 5, }}>
                    <FormTypes.TextDatePicker
                        // disabled={disabled}
                        // minStartDate={moment()}
                        // minEndDate={minEndDate} maxEndDate={maxEndDate}
                        Require={required}
                        width={'350px'}
                        // valueStart={Boolean(_PromotionCodeInfo[stateStart]) ? moment(_PromotionCodeInfo[stateStart]) : undefined}
                        // valueEnd={Boolean(_PromotionCodeInfo[stateEnd]) ? moment(_PromotionCodeInfo[stateEnd]) : undefined}
                        valueStart={_PromotionCodeInfo[stateStart]}
                        valueEnd={_PromotionCodeInfo[stateEnd]}
                        stateStart={stateStart} stateEnd={stateEnd}
                        TitleStart={stateStart} TitleEnd={stateEnd}
                        onChange={(name, value) => { this.onChangeState(name, value) }} />
                    // </div>

                )
            }
        }
    }

    handleDiscoutProductPanel = (props) => {

        const { type, _PromotionCodeInfo } = props;
        isCheck = type;

        switch (type) {
            case 'autoSet': { _PromotionCodeInfo.checkProduct = false; _PromotionCodeInfo.isDiscountEachProduct = false; }; break;
            case 'allProduct': { _PromotionCodeInfo.checkProduct = false; _PromotionCodeInfo.isDiscountEachProduct = true; }; break;
            case 'discountEachProduct': { _PromotionCodeInfo.checkProduct = true; _PromotionCodeInfo.isDiscountEachProduct = true; }; break;
        }
        this.forceUpdate();
    }

    getStateList = (codeType, codeInfo) => {

        switch (codeType) {
            case 'supplier': return SupplierList.filter(supplier => codeInfo.some(val => val === supplier.supplierId));
            case 'agent': return AgentList.filter(agent => codeInfo[0] === agent.agentId);
            case 'seller':
            case 'blogger': return SellerList.filter(seller => codeInfo[0] === seller.sellerId);
            default: return [];
        }
    }

    onCreatePanel = () => (this.setState({ isPackage: true }));

    onCreatePanelTag = (tag) => {

        const { getAPISupplierList, getAPISellerList, getAPIAgentList } = this.props;

        switch (tag) {
            case 'supplier':
                {
                    getAPISupplierList();
                } break;
            case 'agent':
                {
                    getAPIAgentList();
                } break;
            case 'seller':
            case 'blogger':
                {
                    getAPISellerList();
                } break;
        }

        this.setState({ isTag: true });
    }

    onSavePanelPackage = () => {

        const { _PromotionCodeInfo } = this.state;
        this.setState({ isPackage: false, _PromotionCodeInfo: { ..._PromotionCodeInfo, product: _PromotionCodeInfo.product }, showPackage: true, showEditPackage: false });
    }

    onSavePanelTag = () => {

        const { _PromotionCodeInfo } = this.state;
        this.setState({ isTag: false, _PromotionCodeInfo: { ..._PromotionCodeInfo, tag: _PromotionCodeInfo.tag }, showPackage: true, showEditPackage: false });
    }

    handleToggle = value => () => {

        const { isEdit } = this.props;
        const { _PromotionCodeInfo } = this.state;
        const currentIndex = _PromotionCodeInfo.product.indexOf(value.packageId);

        if (currentIndex === -1) {
            _PromotionCodeInfo.product.push(value.packageId);
        }
        else {
            _PromotionCodeInfo.product.splice(currentIndex, 1);
        }

        this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, product: _PromotionCodeInfo.product }, isBtnActive: true, showPackage: false, showEditPackage: true });
    }

    handleChange = value => () => {

        const { _PromotionCodeInfo } = this.state;
        switch (_PromotionCodeInfo.codeType) {
            case 'seller':
            case 'blogger':
                {
                    this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, tag: [value.sellerId] } });
                } break;
            case 'agent':
                {
                    this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, tag: [value.agentId] } });
                } break;
            default:
                {
                    _PromotionCodeInfo.tag = []
                } break;
        }
    };

    handleDelete = value => () => {
        const { _PromotionCodeInfo } = this.state;
        this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, tag: [] } });
    };

    handleToggleTag = value => () => {

        const { isEdit } = this.props;
        const { _PromotionCodeInfo } = this.state;
        let currentIndex;

        switch (_PromotionCodeInfo.codeType) {
            case 'seller':
            case 'blogger':
                {
                    currentIndex = _PromotionCodeInfo.tag.indexOf(value.sellerId);
                    // console.log(currentIndex);
                    if (currentIndex === -1) {
                        _PromotionCodeInfo.tag.push(value.sellerId);
                    }
                    else {
                        _PromotionCodeInfo.tag.splice(currentIndex, 1);
                    }
                } break;
            case 'supplier':
                {
                    currentIndex = _PromotionCodeInfo.tag.indexOf(value.supplierId);

                    if (currentIndex === -1) {
                        _PromotionCodeInfo.tag.push(value.supplierId);
                    }
                    else {
                        _PromotionCodeInfo.tag.splice(currentIndex, 1);
                    }
                } break;
            case 'agent':
                {
                    currentIndex = _PromotionCodeInfo.tag.indexOf(value.agentId);
                    if (currentIndex === -1) {
                        _PromotionCodeInfo.tag.push(value.agentId);
                    }
                    else {
                        _PromotionCodeInfo.tag.splice(currentIndex, 1);
                    }
                } break;
            default:
                {
                    _PromotionCodeInfo.tag = [];
                } break;
        }

        this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, tag: _PromotionCodeInfo.tag }, isBtnActive: true, showPackage: false, showEditPackage: true })
    }

    GetPackage = () => (
        <SelectPackageCard
            PackageAll={this.props.PackageAll}
            isEdit={this.props.isEdit}
            PackageLists={PackageList}
            onReset={this.onClosePanelPackage}
            promotionCodeInfo={this.state._PromotionCodeInfo}
            handleToggle={this.handleToggle}
            onSavePanelPackage={this.onSavePanelPackage}
        />);

    GetSupplierList = () => (
        <SelectTag
            SupplierList={SupplierList}
            SellerList={SellerList}
            AgentList={AgentList}
            isEdit={this.props.isEdit}
            onReset={this.onClosePanelTag}
            promotionCodeInfo={this.state._PromotionCodeInfo}
            handleToggle={this.handleToggleTag}
            handleChange={this.handleChange}
            onSavePanelPackage={this.onSavePanelTag}
        />);

    onClosePanelPackage = () => {

        const { _PromotionCodeInfo } = this.state;
        this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, product: [] }, isPackage: false, });
    };

    onClosePanelTag = () => {

        const { _PromotionCodeInfo } = this.state;
        this.setState({ _PromotionCodeInfo: { ..._PromotionCodeInfo, tag: [] }, isTag: false, });
    };

    getRequiredSymbol(item) {

        const { required, title } = item;
        return (required) ? (<React.Fragment><span style={{ color: 'red' }}>{`* `}</span>{title} </React.Fragment>) : (title);
    }

    getRequiredDateSymbol(item, classes) {

        const { required, title_EN } = item;
        const { textDatePicker } = classes;

        return (required) ?
            (<React.Fragment>
                <span style={{ color: 'red' }}>{`* `}</span>
                <label className={textDatePicker} style={{ fontSize: 13 }} >{title_EN}</label>
            </React.Fragment>) :
            (<label className={textDatePicker} style={{ fontSize: 13 }} >{title_EN}</label>);
    }

    onChangeState = (fieldName, value) => {

        console.log('onChangeState', fieldName, value);

        let _PromotionCodeInfo = { ...this.state._PromotionCodeInfo };

        switch (fieldName) {

            case "discountType": {

                _PromotionCodeInfo = { ..._PromotionCodeInfo, discountValue: 0, [fieldName]: value };

            } break;
            case "discountValue": {

                switch (_PromotionCodeInfo.discountType) {
                    case 'fixPrice':
                    case 'price':
                        {
                            _PromotionCodeInfo = { ..._PromotionCodeInfo, [fieldName]: value };

                        } break;
                    case 'percent':
                        {
                            _PromotionCodeInfo = { ..._PromotionCodeInfo, [fieldName]: (value / 100).toFixed(2) };

                        } break;
                }
            } break;
            case "codeType":
                {
                    if (value === 'blogger' || value === 'seller') {
                        _PromotionCodeInfo = { ..._PromotionCodeInfo, tag: [], [fieldName]: value };
                    }
                    else {
                        _PromotionCodeInfo = { ..._PromotionCodeInfo, tag: [], [fieldName]: value };
                    }
                } break;
            case "checkProduct":
                {
                    if (value)
                        this.props.getAPIPackage();

                    _PromotionCodeInfo = { ..._PromotionCodeInfo, [fieldName]: value };

                } break;
            default:
                {
                    _PromotionCodeInfo = { ..._PromotionCodeInfo, [fieldName]: value };
                } break;

        }

        console.log('_PromotionCodeInfo', _PromotionCodeInfo);
        this.setState({ _PromotionCodeInfo, isBtnActive: true }, () => { this.validate({ [fieldName]: value }) })

    }

    validate = (value) => {

        const fieldName = keys(value)[0];
        let result = true;

        if (!!validator.constraints[fieldName]) {

            const validateResult = validate(value, { [fieldName]: validator.constraints[fieldName] });
            const error = this.error || {};

            if (!!validateResult) {

                result = false;
                this.error = { ...error, ...validateResult };
                this.forceUpdate();
            }
            else {
                this.error = omit([fieldName], error);
                this.forceUpdate();
            }
        }
        return result;
    }

    validateAll() {

        let success = true;
        const { _PromotionCodeInfo } = this.state;

        validator.input.forEach(each => {
            const validateResult = this.validate({
                [each.fieldName]: _PromotionCodeInfo[each.fieldName],
            });;
            success = success && validateResult;
        });
        return success;
    }

    onSave = () => {

        const { isEdit } = this.props;
        const { _PromotionCodeInfo } = this.state;
        const { limit, discountValue, minimum, maximum } = _PromotionCodeInfo;

        // console.log('onSave : ', this.validateAll(), _PromotionCodeInfo, this.error);
        _PromotionCodeInfo.discountValue = parseFloat(discountValue);
        _PromotionCodeInfo.limit = parseFloat(limit);
        _PromotionCodeInfo.minimum = parseFloat(minimum);
        _PromotionCodeInfo.maximum = parseFloat(maximum);
        console.log('_PromotionCodeInfo', _PromotionCodeInfo);

        if (this.validateAll() && discountValue !== 0 && limit !== 0) {

            const { getAPIUpdatePromotionsCode, getAPICreatePromotionsCode } = this.props;

            this.setState({ isLoading: true, isBtnActive: false }, () => {
                (isEdit) ? getAPIUpdatePromotionsCode(_PromotionCodeInfo) : getAPICreatePromotionsCode(_PromotionCodeInfo)
            })
        } else {
            this.setState({ isLoading: true, msgError: 'Please complete the form and select time.', dialogType: 'error', isBtnActive: false }, () => this.error)
        }
    }

    onCloseDialog = () => (
        this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }, () => {
            this.state.isSuccess && this.props.OnClose();
        }));

    // =========================================================================================================================================
    // Test step 
    // =========================================================================================================================================

    handleNext = () => {
        // setActiveStep(prevActiveStep => prevActiveStep + 1);
        console.log('handleNext');
    };

    handleBack = () => {
        // setActiveStep(prevActiveStep => prevActiveStep - 1);
        console.log('handleBack');
    };

    handleReset = () => {
        // setActiveStep(0);
        console.log('handleReset');
    };

    StepDispalyStyle() {

        // const { classes } = this.props;
        // console.log('classes : ', classes);

        // const [activeStep, setActiveStep] = React.useState(0);
        // const activeStep = 1;

        const steps = getSteps();

        const { activeStep = 0 } = this.state;
        const { getAPIPackage } = this.props;

        return (
            <div style={{ width: '100%' }}>
                <Stepper style={{ padding: 10 }} activeStep={activeStep} nonLinear orientation="vertical">
                    {steps.map((label, stepIndex) => (
                        <Step key={label} >
                            <StepButton style={{ padding: 0, margin: 0 }} onClick={() => { (stepIndex === 2 && activeStep !== stepIndex) && getAPIPackage(); (activeStep !== stepIndex) && this.setState({ activeStep: stepIndex }); }}>
                                <StepLabel>{label}</StepLabel>
                            </StepButton>
                            <StepContent style={{ minWidth: 768 }}>
                                {this.MapRowWithStepIndex(stepIndex)}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>

            </div >
        )
    }

    MapRowWithStepIndex(stepIndex = 0) {

        const { BodyPage } = PromotionCode;

        switch (stepIndex) {
            case 0: {
                return (
                    BodyPage.map((row, index) => (
                        (index < 4) && (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => (
                                    <div key={index} style={{ marginTop: 5, width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, flexDirection: each.flex ? 'row' : null }}>
                                        {this.SetItemsWithType(each)}
                                    </div>))}
                            </div>)
                    )));
            }
            case 1: {
                return (
                    BodyPage.map((row, index) => (
                        (index >= 4 && index <= 6) && (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => (
                                    <div key={index} style={{ marginTop: 5, width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, flexDirection: each.flex ? 'row' : null }}>
                                        {this.SetItemsWithType(each)}
                                    </div>))}
                            </div>)
                    )));
            }
            case 2: {
                return (
                    BodyPage.map((row, index) => (
                        (index > 6) && (
                            <div key={index} style={_rowContainer}>
                                {row.map((each, index) => (
                                    <div key={index} style={{ marginTop: 5, width: each.width ? each.width : '100%', marginRight: each.margin === 'right' ? 15 : null, flexDirection: each.flex ? 'row' : null }}>
                                        {this.SetItemsWithType(each)}
                                    </div>))}
                            </div>)
                    )));
            }
        }
    }
    // =========================================================================================================================================
}

const styles = theme => (
    {
        title: {
            fontSize: 28, color: Colors.black,
            fontFamily: Family.roboto,
        },
        textInput: { display: 'flex', flexDirection: 'row', paddingTop: '15px' },
        line: {
            display: 'flex',
            alignItems: 'center',
            padding: ' 0px 15px',
            fontSize: '14px',
            fontWeight: 300,
            fontFamily: Family.roboto,
            color: Colors.themeDark
        },
        root: {
            width: '90%',
        },
        button: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        actionsContainer: {
            marginBottom: theme.spacing(2),
        },
        resetContainer: {
            padding: theme.spacing(3),
        },
    }
);

// =========================================================================================================================================

function getSteps() {
    return ['Set promotion code info.', 'Set promotion code receiver.', 'Set promotion condition.'];
}

// =========================================================================================================================================


PromotionCodeCard.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPICreatePromotionsCode: (_filter) => {
            // InitwaitingTime();
            dispatch(PromotionActions.APICreatePromotionsCode(_filter));
        },
        getAPIUpdatePromotionsCode: (_info) => {
            dispatch(PromotionActions.APIUpdatePromotionsCode(_info));
        },
        getAPIPromotionsCodeList: (_keyword) => {
            dispatch(PromotionActions.getPromotionsCodeList(_keyword));
        },
        getAPIPackage: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackage(_keyword));
        },
        getAPISupplierList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },
        getAPIAgentList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(AgentAction.getAgentList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true })(PromotionCodeCard)));










