import React, { Component } from 'react';

import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';

import DialogPanel from '../../componets/DialogPanel.jsx';
import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';

import { Styles } from '../../themes';
import '../../App.css';

const _space = 30;

class ContentPanel extends Component {

    state = { isLoading: true, width: window.innerWidth, height: window.innerHeight }

    constructor(props) {

        super(props);
    }

    componentWillMount() {
        this.updateDimensions();
    }

    componentDidMount() {

        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {

    }

    componentWillReceiveProps(nextProps) {

        window.addEventListener("resize", this.updateDimensions);
    }

    // function ContentPanel(props) {

    render() {

        const { classes, isLoading, pageWording, onClosePanel, children, isMobile } = this.props;
        const { container, contentListHeader, contentList, contentBox } = classes;
        const _isPageWording = (Boolean(pageWording) && (!isMobile));

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} OnClose={onClosePanel} />}
                <div className={container}>
                    {(pageWording) && <SubTopicsPanel _topics={pageWording.topics} _wording={pageWording.subheading} _isMobile={isMobile} />}
                    <List className={(_isPageWording) ? contentListHeader : contentList}>
                        <div className={contentBox}>
                            {children}
                        </div>
                    </List>
                </div>
            </div>
        );
    }

    // ====================================================================================================================================================

    updateDimensions = () => {

        const { width, height } = this.state;
        const _width = window.innerWidth;
        const _height = window.innerHeight;

        switch (true) {
            case (_width > (width + _space)):
                {
                    this.setState({ width: _width })
                } break;
            case (_width < (width - _space)):
                {
                    this.setState({ width: _width })
                } break;
            case (_height > (height + _space)):
                {
                    this.setState({ height: _height })
                } break;
            case (_height < (height - _space)):
                {
                    this.setState({ height: _height })
                } break;
        }

    }
    // ====================================================================================================================================================
}

// ====================================================================================================================================================

const styles = theme => (
    {
        contentBox: { display: 'inline-block', width: '100%', maxWidth: '1024px' },
        container: { display: 'inline-block', width: '100%', }, // maxWidth: '1030px',
        contentList: { width: '100%', position: 'relative', overflow: 'auto', height: (window.innerHeight - 115) },//160
        contentListHeader: { width: '100%', position: 'relative', overflow: 'auto', height: Styles.heightWithTopic },
    }
);

ContentPanel.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(ContentPanel);

// ====================================================================================================================================================
