import React from 'react';
import styled, { css } from 'styled-components';
import { Fonts, Colors } from '../themes';

function FooterPanel(props) {

  const { isMobile } = props;

  return (
    <Footer isMobile={isMobile}>© 2017 TravisGO</Footer>
  );
}

const Footer = styled.footer`
width: 100%;
color: white;
height: '100%';
padding: 20px 0px;
font-size: 15px;
text-align: center;
background-color:${Colors.footer};
font-family: ${Fonts.family.roboto};
${(props) => props.isMobile && css`width:${window.innerWidth}px`}`;

export default FooterPanel; 