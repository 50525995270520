import React from 'react';
// import BigCalendar from 'react-big-calendar';
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar';
import events from '../common/Events';
import moment from 'moment'
import { Family, Colors } from '../themes';

const localizer = momentLocalizer(moment);
const allViews = Object.keys(Views).map(k => Views[k]);
const _style = { cursor: 'pointer', fontFamily: Family.roboto, fontSize: 14, width: '100%' };

const Calendar = (props) => {

  return (
    <BigCalendar
      localizer={localizer}
      style={_style}
      selectable
      events={(props.events || events)}
      views={allViews}
      step={60}
      showMultiDayTimes
      defaultDate={props.date || (new Date())}
      onSelectEvent={event => props.onSelectEvent([event.start, event.end, event.id ])}
      onSelectSlot={slotInfo => !!(Date.parse(slotInfo.start) >= Date.parse(props.date)) ? props.onSelectSlot([slotInfo.start, slotInfo.end]) : null}
    />
  )

  // alert(
  //   `selected slot: \n\nstart ${slotInfo.start.toLocaleString()} ` +
  //   `\nend: ${slotInfo.end.toLocaleString()}` +
  //   `\naction: ${slotInfo.action}`
  // )} 
}

export default Calendar