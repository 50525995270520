import React, { Component } from 'react';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import '../App.css';

const _height = (window.innerHeight - 130);

class ProviderManage extends Component {

  render() {

    return (
      <div className="App">
        <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
          <div style={{ display: 'inline-block' }}>
            {this.contentPanel()}
          </div>
        </List>
      </div>
    );
  }


  contentPanel() {
    return (
      <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
        Provider
      </div>
    )
  }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
  return {

  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProviderManage);
