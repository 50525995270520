import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Package from '../../constant/JSON/Package';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import { Colors } from '../../themes/index.js';

const Appointment = Package.AppointmentData;
let display_appointment = [];

class AppointmentCard extends Component {
    constructor(props) {
        super(props);
        this.state = { searchText: '' }
    }
    render() {
        const { classes, onAcceptonClosePanel, handleToggle, LocationServicesList, AppointmentList, packageInfo, LanguagePackage, DropOff } = this.props;
        const { searchText } = this.state;

        let locationId = !!LocationServicesList && LocationServicesList.find(val => val.locationServiceId === packageInfo.locationServiceId)
        let new_pickUp = locationId && AppointmentList.filter(val => (val.parentId === (locationId.code)) && (val.actived))

        if (Array.isArray(new_pickUp) && new_pickUp.length > 0) {
            display_appointment = new_pickUp.filter(each => new RegExp(searchText, "i").test(each[LanguagePackage].name))
        }

        // console.log('display_appointment',display_appointment);
        return (
            <div style={{ display: 'inline-block', width: '100%', maxWidth: '1030px', maxHeight: '700px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                <h2 style={{ color: Colors.themeDark }}> {DropOff ? 'DropOff List' : Appointment.title_EN}</h2>
                <div style={{ paddingBottom: '10px' }}>
                    <TextField className={classes.textField}
                        value={searchText}
                        onChange={e => this.setState({ searchText: e.target.value })}
                        placeholder={Appointment.placeholder_EN}
                        InputProps={{ disableUnderline: false, classes: { input: classes.textInput } }}
                        InputLabelProps={{ shrink: true, className: classes.textFormLabel }} />
                </div>
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', maxWidth: '800px', height: '400px', paddingBottom: '17px', marginBottom: '15px' }}>
                    {
                        Array.isArray(display_appointment) && display_appointment.length === 0 ?
                            <div style={{ maxWidth: '800px', height: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {'Not Found'} </div>
                            :
                            display_appointment.map((value, index) => {
                                return (
                                    <React.Fragment key={index} >
                                        {value.actived && (
                                            <ListItem dense button style={{ paddingLeft: '0px', border: '1px solid #979797', margin: '10px 0px' }}
                                                onClick={DropOff ? handleToggle('dropOffList', value) : handleToggle('pickUpList', value, index)}>
                                                <Checkbox color="primary" checked={(DropOff ? packageInfo.dropOffList : packageInfo.pickUpList).indexOf(value.appointmentId) !== -1} tabIndex={-1} disableRipple />
                                                <ListItemText style={{ fontSize: '18px' }} primary={`${value[LanguagePackage].name}`} />
                                            </ListItem>
                                        )}
                                    </React.Fragment>
                                )
                            })
                    }
                </List>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_OK}
                        onClick={() => onAcceptonClosePanel()} />
                </div>
            </div>
        )
    }
}
const styles = theme => (
    {
        textInput: {
            width: '100%',
            padding: '10px 20px',
            color: Colors.grey,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 14,
        },
        textField: { width: '100%', marginTop: 0 },
        textFormLabel: { fontSize: 18, marginTop: -3 },
    }
);

AppointmentCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(AppointmentCard);