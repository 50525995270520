import React, { Component } from 'react';
import { connect } from 'react-redux';
import { equals } from 'ramda';
import '../App.css';

import List from '@material-ui/core/List';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import CommissionListPanel from '../containers/Commission/CommissionListPanel';
import CommissionCard from '../containers/Commission/CommissionCard';

import * as PackageAction from '../actions/Actions_PackageManager.js';
import * as SettingActions from '../actions/Actions_Settings';

let waitingTime = true;
const _height = (window.innerHeight - 130);
let packageInfoList = [];
let defaultCommissions = [];

class CommissionManage extends Component {
    state = { isLoading: true, msgError: undefined, dialogType: undefined, isView: false, isUpdatePackage: false };

    constructor(props) {
        super(props);
        this.props.getDefaultCommissions();
        this.props.getAPIPackageList({ limit: 25, visible: true });
    }

    componentWillReceiveProps(nextProps) {

        const { PackageManager, SettingManager } = nextProps;

        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;

            switch (actions) {
                case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
                    {
                        packageInfoList = data;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_LISTS_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break
            }
        }

        if (!!SettingManager) {

            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case SettingActions.GET_DEFAULT_COMMISSIONS_SUCCESS:
                    {
                        defaultCommissions = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SettingActions.GET_DEFAULT_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { isLoading, isCreateBank, isUpdatePackage } = this.state;

        return (
            <div className="App">
                {(isUpdatePackage) && <DialogAction Active={isUpdatePackage} OnClose={this.onClosePanel} AcrionCom={this.CommissionListPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}> {this.contentPanel()} </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <CommissionListPanel packageInfoList={packageInfoList} onClickHandle={this.ViewPackage()} EditInfo={this.EditPackage()} />
            </div>
        )
    }

    onChangeLanguage = (_language) => {
        const { page } = packageInfoList;
        this.props.getAPIPackageList({ page: page, num_limit: 10, language: _language });
    }

    CommissionListPanel = () => {

        return (
            <CommissionCard
                defaultCommissions={defaultCommissions}
                onChangeLanguage={this.onChangeLanguage}
                packageInfoList={packageInfoList}
                isView={this.state.isView}
                PackageInfo={this.state.PackageInfo}
                onClosePanel={this.onClosePanel} />
        )
    }

    EditPackage = () => (PackageInfo) => {
        this.setState({
            isUpdatePackage: true,
            isView: false,
            PackageInfo,
        });
    }
    ViewPackage = () => (PackageInfo) => {
        this.setState({
            isUpdatePackage: true,
            isView: true,
            PackageInfo,
        });
    }

    onClosePanel = () => (this.setState({ isUpdatePackage: false }));
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackageLists(_keyword));
        },
        getDefaultCommissions: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SettingActions.getDefaultCommissions(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CommissionManage);
