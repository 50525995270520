import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import StarIcon from '@material-ui/icons/Book';

import { Family, Colors } from '../themes';

const styles = theme => ({
  root: {
    width: '100%',
    // maxWidth: 600,   
    // background: theme.palette.background.paper,
  },
  itembox: { color: Colors.white, fontFamily: Family.roboto }
});

//const _menuList = ['SUPPLIER  MANAGE', 'CUSTOMER  MANAGE', 'MEMBER  MANAGE', 'SELLER  MANAGE', 'ACTIVITIES  MANAGE', 'APPOINTMENT  MANAGE', 'DESTINATION  MANAGE', 'CONDITION  MANAGE', 'TRANSFER  MANAGE'];
const _menuList = ['SUPPLIER  MANAGE', 'CUSTOMER  MANAGE', 'MEMBER  MANAGE', 'SELLER  MANAGE', 'ACTIVITIES  MANAGE'];

function ManageList(props) {
  const { classes, onClickHandle } = props;

  return (
    <List className={classes.root}>
      {_menuList.map((item, index) => {
        return (<ListItem button key={index} style={{ backgroundColor: Colors.theme, marginBottom: '2px', textAlign: 'center', }}>
          <div style={{ display: 'flex', width: '100%' }} onClick={() => onClickHandle(item)} >
            <div style={{ flex: 0.5, textAlign: 'right' }}>
              <StarIcon style={{ color: Colors.white }} />
            </div>
            <div style={{ flex: 2, textAlign: 'left', }}>
              <ListItemText inset primary={<label className={classes.itembox}  >{item}</label>} />
            </div>
          </div>
        </ListItem>)
      })}
    </List>
  );
}

ManageList.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(ManageList);