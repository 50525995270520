import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import FileCopy from '@material-ui/icons/FileCopy';
import CONFIG from '../../config';
// import DialogPanel from '../../componets/DialogPanel.jsx';
// import { NumberFormats } from '../../common/SetNumberFormat';

// import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
// import * as DestinationsActions from '../../actions/Actions_DestinationsManager';
// import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import '../../App.css';
import '../../themes/Themes.css';
import { Styles, Colors, Family } from '../../themes';
import { isMobile } from '../../common/DeviceCheck';

var QRCode = require('qrcode.react');

const _height = Styles.heightWithTopic;
const Levles = { country: 'country', location: 'location', package: 'pacage' };

const pageWording = {
    topics: 'Package Affiliate Detail.',
    subheading: [],
    icon: ['safeBook', 'bestPrice', 'fee'],
};

let DestinationsList = [];
let waitingTime = true;

class PackageAffiliatePanel extends Component {

    state = { isLoading: true, }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {
        console.log(`PackageAffiliatePanel [componentWillReceiveProps] : `, nextProps);

    }

    render() {

        // console.log('PackageAffiliatePanel', this.props)

        // const { isMobile() } = this.props;

        return (
            <div className="App">
                <div style={style.page_container}>
                    <label style={isMobile() ? style.topicsMobile : style.topics}>{pageWording.topics}</label>
                </div>
                <List style={style.list_container}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel() {

        const { classes, affiliateID, Info, isVisible } = this.props;
        const { id, name, picture, tag } = Info;

        // console.log('contentPanel : ', Info);

        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: isMobile() ? '10px 0px' : 10, textAlign: 'left' };
        const _backgroundImage = { width: '250px', height: '200px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${this.checkURL(picture)})`, backgroundRepeat: 'round' };
        const _affiliateLink = this.MapLinkWithLevel({ Info, affiliateID: affiliateID, isVisible: isVisible }) //`${CONFIG.webURL}/search/${name.trim()}#${affiliateID}`
        const _topicsLable = isMobile() ? style.classMobile : style.class;
        const _infoLable = isMobile() ? style.infoMobile : style.info;

        return (
            <div style={{ height: '400px', width: '100%', maxWidth: '1024px', }}>
                <div style={{ display: isMobile() ? 'block' : 'flex', borderBottom: `5px solid ${Colors.theme}`, borderTop: `5px solid ${Colors.theme}`, padding: '10px 0px' }}>
                    <div style={{ flex: 1, marginRight: 10 }}>
                        <div style={_backgroundImage} />
                    </div>
                    <div style={{ width: '100%', flex: 3 }}>
                        <div style={_styleInfoItem}>
                            <div >
                                <label style={_topicsLable}>{`Card Name : `}</label>
                                <label style={_infoLable}>{name}</label>
                            </div>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>{`Card Id : `}</label>
                            <label style={_infoLable}>{` ${id || '-'}`}</label>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'left', padding: '10px 0px', borderBottom: `${'5px solid' + Colors.theme}` }}>
                    <QRCode
                        value={_affiliateLink}
                        size={128}
                        bgColor={Colors.white}
                        fgColor={Colors.themeDark}
                        level={"L"} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }} >
                    <TextField
                        id="AffiliateLink"
                        label="Affiliate Link"
                        style={{ width: '100%', marginTop: 0 }}
                        value={_affiliateLink}
                        InputProps={{ classes: { input: classes.textFormLabel }, }} />
                    <Button.Icons themeDark icon={<FileCopy />} tooltip={'Copy'} onClick={() => this.HandleCopy()} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 20 }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ width: '150px' }} buttonStyle={{ padding: 10 }}
                        onClick={() => this.props.OnClose()} />
                </div>
            </div >
        );
    }

    MapLinkWithLevel(value) {

        const { Info, affiliateID, isVisible } = value;
        const { tag, level } = Info;

        if (isVisible) {
            switch (level) {
                case Levles.location: return `${CONFIG.webURL}/search/${tag}#${affiliateID}`;
                case Levles.country:
                default: return `${CONFIG.webURL}/#${affiliateID}`;
            }
        }
        else {
            switch (level) {
                case Levles.location: return `${CONFIG.webURL}/search/${tag}`;
                case Levles.country:
                default: return `${CONFIG.webURL}/`;
            }
        }

    }

    HandleCopy = () => {

        const copyText = document.getElementById("AffiliateLink");
        copyText.select();
        document.execCommand("copy");
        // alert("Copied the text: " + copyText.value);
    }

    checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');
}

const style =
{
    topics:
    {
        fontSize: 26,
        color: Colors.theme,
        fontFamily: Family.roboto,
        float: 'left',
    },
    topicsMobile:
    {
        fontSize: 18,
        color: Colors.theme,
        fontFamily: Family.roboto,
        marginTop: 10,
    },
    page_container:
    {
        display: 'inline-block',
        width: '100%',
        maxWidth: '1030px',
        paddingTop: '10px',

    },
    content_container:
    {
        height: '100%',
        width: '100%',
        maxWidth: '1024px',
    },
    list_container:
    {
        width: '100%',
        position: 'relative',
        // overflow: 'auto',
        height: 550,//_height
    },
    info: {
        height: '14px',
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        lineHeight: '16px',
    },
    class: {
        height: '14px',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: "left"
    },
    infoMobile: {
        height: '14px',
        width: '100%',
        maxWidth: 180,
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '11.5px',
        lineHeight: '16px',
        textAlign: 'left'
    },
    classMobile: {
        height: '14px',
        marginRight: 5,
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '11px',
        lineHeight: '16px',
        textAlign: 'left'
    },

};
const styles = theme => ({
    textFormLabel: {
        fontSize: '14.5px',
    }
})
PackageAffiliatePanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        PackageManager: state.PackageManager.PACKAGE_REDUCER,
        DestinationsManager: state.DestinationsManager.DESTINATION_REDUCER,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PackageAffiliatePanel));


