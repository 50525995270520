import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS';
export const GET_AGENT_FAILURE = 'GET_AGENT_FAILURE';

export const GET_AGENT_INFO_SUCCESS = 'GET_AGENT_INFO_SUCCESS';
export const GET_AGENT_INFO_FAILURE = 'GET_AGENT_FAILURE';

export const CREATE_AGENT_SUCCESS = 'CREATE_AGENT_SUCCESS';
export const CREATE_AGENT_FAILURE = 'CREATE_AGENT_FAILURE';

export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_FAILURE = 'UPDATE_AGENT_FAILURE';

export const GET_AGENT_WITHPARENT_ID_SUCCESS = 'GET_AGENT_WITHPARENT_ID_SUCCESS';
export const GET_AGENT_WITHPARENT_ID_FAILURE = 'GET_AGENT_WITHPARENT_ID_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

// getAgentList ============================================================================================================================

export function getAgentList(_keyword = '', _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAgentList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAgentList : ", response);
            dispatch(getAgentList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAgentList Failure", error);
            dispatch(getAgentList_Failure(error));
        })
    }
}

function getAgentList_Success(data) {
    return { type: GET_AGENT_SUCCESS, payload: data };
}

function getAgentList_Failure(error) {
    return { type: GET_AGENT_FAILURE, payload: error };
}

// getAgentList =========================================================================================================================

// getAgentInfo ============================================================================================================================

export function getAgentInfo(id, _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAgentInfo}${id}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            // data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAgentInfo : ", response);
            dispatch(getAgentInfo_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAgentInfo Failure", error);
            dispatch(getAgentInfo_Failure(error));
        })
    }
}

function getAgentInfo_Success(data) {
    return { type: GET_AGENT_INFO_SUCCESS, payload: data };
}

function getAgentInfo_Failure(error) {
    return { type: GET_AGENT_INFO_FAILURE, payload: error };
}

// getAgentInfo =========================================================================================================================

// getAgentListWithParentId( =========================================================================================================================

export function getAgentList_WithParentId(_keyword = '', _fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getAgentList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: { where: _keyword },
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] getAgentList_WithParentId : ", response);
            dispatch(getAgentList_WithParentId_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAgentList_WithParentId Failure", error);
            dispatch(getAgentList_WithParentId_Failure(error));
        })
    }
}

function getAgentList_WithParentId_Success(data) {
    return { type: GET_AGENT_WITHPARENT_ID_SUCCESS, payload: data };
}

function getAgentList_WithParentId_Failure(error) {
    return { type: GET_AGENT_WITHPARENT_ID_FAILURE, payload: error };
}

// APICreateAgent =========================================================================================================================

export function APICreateAgent(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createAgent}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] APICreateAgent : ", response.data);
            dispatch(APICreateAgent_Success(response.data));

        }).catch((error) => {
            console.log("APICreateAgent fail", error);
            dispatch(APICreateAgent_Failure(error));
        })
    }
}

function APICreateAgent_Success(data) {
    return { type: CREATE_AGENT_SUCCESS, payload: data };
}

function APICreateAgent_Failure(error) {
    return { type: CREATE_AGENT_FAILURE, payload: error };
}

// updateAgentList =========================================================================================================================

export function APIUpdateAgent(confirmInfo) {
    // console.log('APIUpdateAgent : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateAgent}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": { id: confirmInfo.id } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] APIUpdateAgent : ", response.data);
            dispatch(APIUpdateAgent_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateAgent fail", error);
            dispatch(APIUpdateAgent_Failure(error));
        })
    }
}

function APIUpdateAgent_Success(data) {
    return { type: UPDATE_AGENT_SUCCESS, payload: data };
}

function APIUpdateAgent_Failure(error) {
    return { type: UPDATE_AGENT_FAILURE, payload: error };
}