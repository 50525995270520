import React, { Component, } from 'react';
import { withRouter } from 'react-router';
import { Metrics, Colors, Fonts, Family } from "../../themes";

import { SetDate } from '../../common/SetDate';
import { NumberFormats } from '../../common/SetNumberFormat';
import { isMobile, isTablet } from '../../common/DeviceCheck';

class TotalPanel extends Component {

    render() {

        const { priceTotal } = this.props;
        const { currency, discount, net, serviceChargeAndVat, subTotal } = priceTotal;
        console.log('TotalPanel : ', priceTotal);

        return (
            <div style={{ marginTop: '20px' }}>
                <div style={style.priceItem_container}>
                    <label style={style.label_detail}>{`Total`}</label>
                    <label style={style.priceInfo_lable}>{`${NumberFormats(subTotal + discount)} ${currency}`}</label>
                </div>
                <div style={style.priceItem_container}>
                    <label style={style.label_detail}>{`Discount`}</label>
                    <label style={style.priceInfo_lable}>{`${NumberFormats(discount)} ${currency}`}</label>
                </div>
                <div style={style.priceItem_container}>
                    <label style={style.label_detail}>{`Included : Service charge & VAT.`}</label>
                    <label style={style.priceInfo_lable}>{`${NumberFormats(serviceChargeAndVat)} ${currency}`}</label>
                </div>
                <div style={style.priceItem_container}>
                    <label style={style.label_detail}>{`Payment Amount`}</label>
                    <label style={style.priceInfo_lable}>{`${NumberFormats(subTotal)} ${currency}`}</label>
                </div>
            </div>);
    }
}

export default withRouter(TotalPanel);

const style = {
    priceInfo_lable: {
        height: '31px',
        width: '100%',
        color: Colors.theme,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        textAlign: 'right',
        //   marginTop: '5px',
        //   marginRight: '5px',
    },
    priceItem_container: {
        width: '100%',
        height: '50px',
        display: 'flex',
        textAlign: 'left',
        lineHeight: '40px',
        borderBottom: `${'1px solid' + Colors.borderLight}`,
        fontFamily: Family.roboto
    },
    label_detail: {
        height: '31px',
        width: '100%',
        color: Colors.themeDark,
        fontSize: '16px',
        lineHeight: '40px',
        display: 'inline-grid',
        //   marginTop: '5px',
        //   marginBottom: '5px',
    },
};