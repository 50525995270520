
import * as PackageAction from '../actions/Actions_PackageManager';

const INITIAL_STATE = { PACKAGE_REDUCER: { actions: PackageAction.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: PackageAction.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case PackageAction.INITIAL_STATE:
            {
                return state;
            }
        case PackageAction.IDLE_STATE:
            {
                //console.log("[Reducer Package ] : IDLE_STATE : ",payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.IDLE_STATE, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_COUNT_SUCCESS:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_COUNT_SUCCESS : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_COUNT_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_COUNT_FAILURE:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_COUNT_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_COUNT_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_COUNT_SUPPLIER_SUCCESS:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_COUNT_SUPPLIER_SUCCESS : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_COUNT_SUPPLIER_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_COUNT_SUPPLIER_FAILURE:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_COUNT_SUPPLIER_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_COUNT_SUPPLIER_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
            {
                //console.log("[Reducer Package ] : GET_PACKAGE_LISTS_SUCCESS : ",payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_LISTS_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_LISTS_FAILURE:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_LISTS_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_LISTS_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_SUCCESS:
            {
                //console.log("[Reducer Package ] : GET_PACKAGE_SUCCESS : ",payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_FAILURE:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.CREATE_PACKAGE_SUCCESS:
            {
                console.log("[Reducer Package ] : CREATE_PACKAGE_SUCCESS : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.CREATE_PACKAGE_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.CREATE_PACKAGE_FAILURE:
            {
                console.log("[Reducer Package ] : CREATE_PACKAGE_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.CREATE_PACKAGE_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.UPDATE_PACKAGE_SUCCESS:
            {
                console.log("[Reducer Package ] : UPDATE_PACKAGE_SUCCESS : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.UPDATE_PACKAGE_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.UPDATE_PACKAGE_FAILURE:
            {
                console.log("[Reducer Package ] : UPDATE_PACKAGE_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.UPDATE_PACKAGE_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_CATEGORY_SUCCESS:
            {
                //console.log("[Reducer Package ] : GET_PACKAGE_CATEGORY_SUCCESS : ",payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_CATEGORY_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.GET_PACKAGE_CATEGORY_FAILURE:
            {
                console.log("[Reducer Package ] : GET_PACKAGE_CATEGORY_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.GET_PACKAGE_CATEGORY_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.CREATE_PACKAGE_CATEGORY_SUCCESS:
            {
                console.log("[Reducer Package ] : CREATE_PACKAGE_CATEGORY_SUCCESS : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.CREATE_PACKAGE_CATEGORY_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.CREATE_PACKAGE_CATEGORY_FAILURE:
            {
                console.log("[Reducer Package ] : CREATE_PACKAGE_CATEGORY_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.CREATE_PACKAGE_CATEGORY_FAILURE, data: payload, error: _error } };
            }
        case PackageAction.UPDATE_PACKAGE_CATEGORY_SUCCESS:
            {
                console.log("[Reducer Package ] : UPDATE_PACKAGE_CATEGORY_SUCCESS : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.UPDATE_PACKAGE_CATEGORY_SUCCESS, data: payload, error: _error } };
            }
        case PackageAction.UPDATE_PACKAGE_CATEGORY_FAILURE:
            {
                console.log("[Reducer Package ] : UPDATE_PACKAGE_CATEGORY_FAILURE : ", payload);
                return { ...state, PACKAGE_REDUCER: { actions: PackageAction.UPDATE_PACKAGE_CATEGORY_FAILURE, data: payload, error: _error } };
            }

        default:
            {
                // console.log("[Reducer Package ]  default :",state);
                return state;
            }
    }
}
