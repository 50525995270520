import React, { Component } from 'react'
import DatePicker from 'react-datepicker';
import { path, equals } from 'ramda';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import PropTypes from 'prop-types';
// import { withStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/styles/withStyles';


import { Colors, Size, Family } from '../../themes';
import Button from '../../constant/Button';


const RequireForm = <span style={{ color: Colors.error, fontSize: Size.input }}>* </span>;

class TextDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            time: {
                startDate: props.valueStart || props.minStartDate || new Date(),
                endDate: props.valueEnd || new Date(),
            },
        };
        if (props.minStartDate) {
            props.onChange(props.stateStart, new Date(props.minStartDate).toISOString().substr(0, 10))
        }
    }
    componentWillReceiveProps(nextProps) {
        if (!equals(nextProps.valueStart, path(['time', 'startDate'], this.state)) || !equals(nextProps.valueEnd, path(['time', 'endDate'], this.state))) {
            this.setState({ time: { ...this.state.time, startDate: nextProps.valueStart || new Date(), endDate: nextProps.valueEnd || new Date() }, });
        }
    }
    handleChange = (date) => {
        const { onChange, stateStart, stateEnd } = this.props;

        this.setState({
            time: {
                ...this.state.time,
                ...date,
            },
        }, () => {
            if (typeof onChange === 'function') {
                if (date.startDate) {
                    const startTDate = new Date(this.state.time.startDate).toISOString().substr(0, 10);
                    onChange(stateStart, startTDate);
                } else if (date.endDate) {
                    const endTDate = new Date(this.state.time.endDate).toISOString().substr(0, 10);
                    onChange(stateEnd, endTDate);
                }
            }
        });
    }

    render() {

        const { classes, disabled, TitleEnd, TitleStart, error, stateStart, stateEnd, errorStart, errorEnd, width, buttonStyle, Require, styleIcon, minStartDate } = this.props;
        let date = new Date();

        return (
            <div className={classes.card}>
                <div className={classes.textInput} >
                    <div className={classes.textFormLabel}> {Require ? <div>{RequireForm} {TitleStart} </div> : TitleStart} </div>
                    <DatePicker
                        autoFocus
                        selectsStart
                        disabled={disabled}
                        customInput={<Button.Calendar width={width} style={styleIcon} buttonStyle={{ borderBottom: (!!path([stateStart], error)) ? `${'1px solid'}${Colors.error}` : null }} />}
                        minDate={minStartDate || new Date()}
                        maxDate={date.setFullYear(date.getFullYear() + 2)}
                        dateFormat="dd/MM/yyyy"
                        selected={new Date(path(['time', 'startDate'], this.state))}
                        onChange={(val) => this.handleChange({ startDate: val })} />
                </div>
                <div className={classes.line}> {'To'} </div>
                <div className={classes.textInput} >
                    <div className={classes.textFormLabel}> {Require ? <div>{RequireForm} {TitleEnd} </div> : TitleEnd} </div>
                    <DatePicker
                        selectsEnd
                        disabled={disabled}
                        customInput={<Button.Calendar width={width} style={styleIcon} buttonStyle={{ borderBottom: (!!path([stateEnd], error)) ? `${'1px solid'}${Colors.error}` : null }} />}
                        minDate={new Date(path(['time', 'startDate'], this.state))}
                        maxDate={date.setFullYear(date.getFullYear() + 2)}
                        dateFormat="dd/MM/yyyy"
                        selected={new Date(path(['time', 'endDate'], this.state))}
                        onChange={(val) => this.handleChange({ endDate: val })} />
                </div>
            </div>
        )
    }
}
const styles = theme => (
    {
        card: {
            display: 'flex',
            height: '65px',
            position: 'relative',
            flexDirection: 'row',
            zIndex: 1,
            // paddingTop: '10px'
        },
        textInput: {
            display: 'flex',
            marginTop: 5,
            flexDirection: 'column',
            width: '100%',
        },
        line: {
            display: 'flex',
            justifyContent: 'center',
            fontFamily: Family.roboto,
            fontSize: 13,
            color: Colors.border,
            lineHeight: 6,
            padding: ' 0px 15px',
        },
        textFormLabel: {
            fontFamily: Family.roboto,
            fontWeight: 300,
            fontSize: 12,
            color: Colors.themeDark,
        },
        icon: { width: '20px', height: '20px' }
    }
)
TextDatePicker.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(TextDatePicker);