import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import BarChart from './BarChart';
import TwoLevelPieChart from './TwoLevelPieChart';
import BookingStatChart from './BookingStatChart';
import BookingSummaryChart from '../componets/BookingSummaryChart';

import { Family, Size, Colors } from '../themes';

function SupplierStatCard(props) {

    const { classes, Info, Topics, Disable, btnViewHadle, btnCreateHadle, isLoading, chartType, Styles } = props;

    return (
        <div style={{ paddingLeft: 2 }}>
            <Card className={classes.card} style={Styles || { maxWidth: 300 }}>
                <CardContent>
                    <span className={classes.topics}>{Topics || `Supplier`}</span>
                </CardContent>
                {(isLoading) ? LoadingContainer() : ChartType(chartType, Info, ((!!Styles) ? Styles.width : undefined))}
            </Card>
        </div>
    );
}

const LoadingContainer = () => {
    return (
        <div style={{ textAlign: 'center', padding: '20px', height: 160, }}>
            <CircularProgress style={{ color: Colors.theme, width: 70, height: 70 }} thickness={5} />
        </div>
    )
}

const ChartType = (_type, _Info, _width) => {

    // const _stat = { approved: 0, new: 0 };
    // const _newStat = _Info.datas.map(_data => { (_data.approved) ? (_stat.approved += 1) : (_stat.new += 1); return _stat });
    //console.log('Chart : ', _Info);

    switch (_type) {
        case 1:
            {
                return <TwoLevelPieChart Info={(Array.isArray(_Info) && _Info)} />
            };
        case 2:
            {
                return <BarChart BCWidth={_width} Info={_Info} />;
            }
        case 4:
            {
                return <BookingSummaryChart BCWidth={_width} Info={_Info} />;
            }
        case 3:
        default:
            {
                return <BookingStatChart />;
            }
    }
}

const styles = theme => (
    {
        card: {
            minWidth: 200,// maxWidth: 300,
            minHeight: 200,
        },
        topics: {
            fontSize: Size.chart.title,
            fontFamily: Family.roboto,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            marginBottom: 10,
            fontSize: 20,
            color: Colors.black,
            textAlign: 'left',
            fontFamily: Family.roboto,
        },
        pos: {
            color: theme.palette.text.secondary,
            // marginTop: '40px',
            // marginBottom: '30px',
        },
        button: { backgroundColor: Colors.theme, color: Colors.white, width: '100%', height: '44px' },
        button_Disable: { backgroundColor: Colors.disabled, color: Colors.white, width: '100%', height: '44px' },
        card_action: { marginBottom: '0px', marginTop: '0px', paddingTop: '0px', paddingBottom: '0px' },
    });

SupplierStatCard.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(SupplierStatCard);