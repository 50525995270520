import React from 'react';
import { path } from 'ramda';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '../../constant/Button';
import CONFIG from '../../config';

import { Colors, Fonts, Icons } from '../../themes';
const { BorderColorIcon } = Icons;

class FormButtonList extends React.Component {
    render() {
        const { classes, ButtonText, Left, Right, StateList, disabled, PackageList } = this.props;
        let List = Array.isArray(PackageList) ? PackageList.find((val) => val.packageId === path(['packageId'], StateList)) : [];

        const _backgroundImage = { width: '50px', height: '50px', backgroundColor: Colors.grey, backgroundImage: `url(${CONFIG.host}${path(['packageInfo', 'image', 'thumbnail'], List)})` || '', backgroundRepeat: 'round' };
        return (
            <div className={classes.root}>
                <Button.ThemesDark disabled={disabled} TitleText={ButtonText}
                    style={{ marginLeft: Left, marginRight: Right }}
                    buttonStyle={{ marginTop: 0, width: '100%' }} onClick={() => this.props.onCreatePanel()} />

                <div style={{ border: StateList ? `${'1px solid' + Colors.borderLight}` : null }}>
                    {
                        (StateList) ?
                            <div className={classes.item} >
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
                                    <div style={{ display: 'flex' }}>
                                        <div style={_backgroundImage} />
                                        <div className={classes.text}>{path(['packageInfo', 'name'], List) || '-'}   </div>
                                    </div>
                                    <BorderColorIcon />
                                </div>
                            </div>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', border: `${'1px solid' + Colors.border}`, padding: '14px' }}>
                                <Typography >{'Not Found'}   </Typography>
                            </div>
                    }


                </div>

            </div>
        )
    }

}
FormButtonList.propTypes = { classes: PropTypes.object.isRequired, label: PropTypes.string };

const styles = theme => (
    {
        root: { width: '100%', padding: '30px 0px' },
        item: { padding: '15px' },
        title: {
            fontSize: 28, color: Colors.black, marginBottom: 15, marginTop: 15,
            fontFamily: Fonts.family.roboto,
        },
        textDefalut: { margin: '5px 0px', fontSize: '14px', color: Colors.grey, fontFamily: Fonts.family.roboto, },
        text: { fontSize: '16px', padding: '5px 20px 0px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '240px', overflow: 'hidden' }
    });

export default withStyles(styles)(FormButtonList)