
export const BookBank = [{
    value: '0',
    language: "TH",
    bankId: 'BB001',
    bankName_EN: 'BANK OF AYUDHYA',
    bankName: 'ธนาคารกรุงศรีอยุธยา จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/krungsi.jpg'),
    active: true,
},
{
    value: '1',
    language: "TH",
    bankId: 'BB002',
    bankName_EN: 'BANGKOK BANK',
    bankName: 'ธนาคารกรุงเทพ จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/krungtep.png'),
    active: true,
},
{
    value: '2',
    language: "TH",
    bankId: 'BB003',
    bankName_EN: 'KRUNG THAI BANK',
    bankName: 'ธนาคารกรุงไทย จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/krungthai.png'),
    active: true,
},
{
    value: '3',
    language: "TH",
    bankId: 'BB004',
    bankName_EN: 'KASIKRON BANK',
    bankName: 'ธนาคารกสิกรไทย จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/kasikon.png'),
    active: true,
},
{
    value: '4',
    language: "TH",
    bankId: 'BB005',
    bankName_EN: 'KIATNAKIN BANK',
    bankName: 'ธนาคารเกียรตินาคิน จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/nakin.jpg'),
    active: true,
},
{
    value: '5',
    language: "TH",
    bankId: 'BB006',
    bankName_EN: 'CIMB BANK',
    bankName: 'ธนาคารซีไอเอ็มบี จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/cimb.jpg'),
    active: true,
},
{
    value: '6',
    language: "TH",
    bankId: 'BB007',
    bankName_EN: 'THAI MILITARY BANK',
    bankName: 'ธนาคารทหารไทย จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/tmb.jpg'),
    active: true,
},
{
    value: '7',
    language: "TH",
    bankId: 'BB008',
    bankName_EN: 'TISCO BANK',
    bankName: 'ธนาคารทิสโก้ จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/tisgo.jpg'),
    active: true,
},
{
    value: '8',
    language: "TH",
    bankId: 'BB009',
    bankName_EN: 'SIAM COMMERCIAL BANK',
    bankName: 'ธนาคารไทยพาณิชย์ จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/thaipanich.png'),
    active: true,
},
{
    value: '9',
    language: "TH",
    bankId: 'BB0010',
    bankName_EN: 'THANACHART BANK',
    bankName: 'ธนาคารธนชาต จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/tanachat.png'),
    active: true,
},
{
    value: '10',
    language: "TH",
    bankId: 'BB0011',
    bankName_EN: 'BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES ',
    bankName: 'ธนาคารเพื่อการเกษตรและสหกรณ์',
    bankLogo: require('../../images/bank/kases.png'),
    active: true,
},
{
    value: '11',
    language: "TH",
    bankId: 'BB0012',
    bankName_EN: 'UNITED OVERSEAS BANK',
    bankName: 'ธนาคารยูโอบี จำกัด(มหาชน)',
    bankLogo: require('../../images/bank/uob.jpg'),
    active: true,
},
{
    value: '12',
    language: "TH",
    bankId: 'BB0013',
    bankName_EN: ' LAND AND HOUSE BANK ',
    bankName: 'ธนาคารแลนด์แอนด์เฮ้าส์',
    bankLogo: require('../../images/bank/lan.jpg'),
    active: true,
},
{
    value: '13',
    language: "TH",
    bankId: 'BB014',
    bankName_EN: 'GOVERNMENT SAVINGS BANK',
    bankName: 'ธนาคารออมสิน',
    bankLogo: require('../../images/bank/omsin.png'),
    active: true,
},
{
    value: '14',
    language: "TH",
    bankId: 'BB015',
    bankName_EN: 'ISLAMIC BANK OF THAILAND',
    bankName: 'ธนาคารอิสลามแห่งประเทศไทย',
    bankLogo: require('../../images/bank/isnamic.jpg'),
    active: true,
},

]