import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { SetDateShort, SetDate, ConvertDate } from '../../common/SetDate.js';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';

import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TablePagination from '@material-ui/core/TablePagination';

import { blue } from '@material-ui/core/colors';

import Button from '../../constant/Button';
import EmptyRows from '../../componets/EmptyRow.jsx';
import PromotionCodeCard from './PromotionCodeCard.jsx';
import AffiliateCodePanel from './AffiliateCodePanel.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import * as CurrencyActions from '../../actions/Actions_CurrencyManager';
import * as PromotionActions from '../../actions/Actions_PromotionManager';
const { DoneIcon, CloseIcon, BorderColorIcon } = Icons;

const fildeListAdmin = [
    { label: 'CODE NAME', name: 'name', isOrder: true, },
    { label: 'DESCRIPTION', name: '', isOrder: false, },
    { label: 'DISCOUNT', name: 'discountValue', isOrder: true, },
    { label: 'LIMIT', name: 'limit', isOrder: true, },
    { label: 'USE', name: 'countLimit', isOrder: true, },
    { label: 'TYPE', name: 'discountType', isOrder: true, },
    { label: 'START', name: 'startDate', isOrder: true, },
    { label: 'END', name: 'endDate', isOrder: true, },
    { label: 'EXP DATE', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, },];

const fildeList = [
    { label: 'CODE NAME', name: 'name', isOrder: true, },
    { label: 'DESCRIPTION', name: '', isOrder: false, },
    { label: 'DISCOUNT', name: 'discountValue', isOrder: true, },
    { label: 'LIMIT', name: 'limit', isOrder: true, },
    { label: 'USE', name: 'countLimit', isOrder: true, },
    { label: 'TYPE', name: 'discountType', isOrder: true, },
    { label: 'START', name: 'startDate', isOrder: true, },
    { label: 'END', name: 'endDate', isOrder: true, },
    { label: 'EXP DATE', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: false, },];

const fildeListMobile = [
    { label: 'CODE NAME', name: 'name', isOrder: true, },
    { label: 'DISCOUNT', name: 'discountValue', isOrder: true, },
    { label: 'LIMIT', name: 'limit', isOrder: true, },
    { label: 'USE', name: 'countLimit', isOrder: true, },];

const _wording = { header: 'Promotion Code List.' };
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', fontFamily: Family.roboto };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100, fontFamily: Family.roboto, };

let waitingTime = false;
let CurrencyList = [];
let PromotionsCodeList = [];
class PromotionCodeListPanel extends Component {

    state = { isLoading: false, isCreate: false, isEdit: false, isView: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, codeInfo: undefined }

    constructor(props) {
        super(props);
        this.props.getAPICurrencyList({ num_limit: 10 });
    }

    componentWillReceiveProps(nextProps) {
        // console.log('componentWillReceiveProps', nextProps)
        const { PromotionManager, CurrencyManager } = nextProps;

        if (!!PromotionManager) {
            const { actions, data } = PromotionManager.PROMOTIONS_REDUCER;//PackageManager

            switch (actions) {
                case PromotionActions.GET_PROMOTIONS_CODE_SUCCESS:
                    {
                        // console.log('data', data);
                        PromotionManager.PROMOTIONS_REDUCER = PromotionActions.WATING_STATE;
                        PromotionsCodeList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PromotionActions.GET_PROMOTIONS_CODE_FAILURE:
                    {
                        PromotionManager.PROMOTIONS_REDUCER = PromotionActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!CurrencyManager) {

            const { actions, data } = CurrencyManager.CURRENCY_REDUCER;

            switch (actions) {
                case CurrencyActions.GET_CURRENCY_SUCCESS:
                    {
                        CurrencyManager.CURRENCY_REDUCER = CurrencyActions.WATING_STATE;
                        CurrencyList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CurrencyActions.GET_CURRENCY_FAILURE:
                    {
                        CurrencyManager.CURRENCY_REDUCER = CurrencyActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, onCreateEvent, userInfo, isMobile } = this.props;
        // PromotionsCodeList = (!!this.props.PromotionsCodeList) ? (this.props.PromotionsCodeList.datas || []) : [];
        // const isPromotion = (PromotionsCodeList.length > 0);

        const { datas } = this.props.PromotionsCodeList;
        PromotionsCodeList = datas || [];

        const { isLoading, isCreate, isView, msgError, dialogType } = this.state;
        let isAdmin = (userInfo === undefined);

        // console.log('PromotionsCodeList', PromotionsCodeList)
        const _fildeList = (isAdmin) ? fildeListAdmin : (isMobile ? fildeListMobile : fildeList);

        if (!isAdmin && PromotionsCodeList.length > 0) {
            PromotionsCodeList = PromotionsCodeList.filter((item) => {
                return (Array.isArray(item.tag)) ? item.tag.includes(userInfo.sellerId) : item.tag === userInfo.sellerId
            });
        }

        const isPromotion = (PromotionsCodeList.length > 0);

        // console.log('Props PromotionsCodeList', this.props.PromotionsCodeList)
        return (
            <React.Fragment>
                {(isView) && <DialogAction Active={isView} OnClose={this.onClosePanel()} AcrionCom={this.PromotionCodePanel()} />}
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel()} AcrionCom={this.CreatePromotionCodePanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={isMobile ? classes.rootMobile : classes.root}>
                    <Table className={isMobile ? classes.tableMobile : classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={(isAdmin) ? (_fildeList.length - 1) : _fildeList.length} style={{ padding: '0px' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }}
                                    // onClick={() => this.setState({ searchActive: !searchActive })} 
                                    />
                                </TableCell>
                                {
                                    (isAdmin) &&
                                    <TableCell colSpan={1} style={{ padding: '0px' }} >
                                        <div style={{ textAlign: 'center' }} onClick={() => this.onCreateEvent()}>
                                            <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                        </div>
                                    </TableCell>
                                }
                            </TableRow>
                            {/* <TableRow>
                                <TableCell colSpan={(isAdmin) ? (_fildeList.length - 1) : _fildeList.length} style={{ padding: '0px' }} >
                                    <Button className={classes.title_bar} disabled={true} >{_wording.header}</Button>
                                </TableCell>
                                {(isAdmin) &&
                                    <TableCell colSpan={1} style={{ padding: '0px' }} >
                                        <div style={{ margin: 5, textAlign: 'center' }} onClick={() => this.onCreateEvent()}>
                                            <Button variant="fab" aria-label="add" className={classes.cssRoot}>
                                                <AddIcon />
                                            </Button>
                                        </div>
                                    </TableCell>}
                            </TableRow> */}
                            <TableRow>
                                {_fildeList.map((_filde, index) => (
                                    <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                        {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                    </TableCell>)
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(isPromotion) ? GetBookingRows(PromotionsCodeList, this.DisplayPromotionInfo, this.onCreateEdit, classes, isAdmin, isMobile) : <EmptyRows fildeList={_fildeList} />}
                        </TableBody>
                    </Table>
                    {(isPromotion) && GetPagination(this.props.PromotionsCodeList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        );
    }

    //===============================================================================================================================================================

    PromotionCodePanel = () => {

        const { codeInfo } = this.state;
        const { isMobile } = this.props;

        return (<AffiliateCodePanel CodeInfo={codeInfo} isMobile={isMobile} OnClose={this.onClosePanel()} />)
    }

    CreatePromotionCodePanel = () => {

        const { codeInfo, isEdit } = this.state;

        return (
            <React.Fragment>
                <PromotionCodeCard OnClose={this.onClosePanel()} CurrencyList={CurrencyList} PromotionCodeInfo={codeInfo} isEdit={isEdit} />
            </React.Fragment>

        )
    }

    onCreateEvent = (_event) => {
        // console.log('onCreateEvent')
        this.setState({ isCreate: true, isEdit: false, codeInfo: undefined })
    };

    onCreateEdit = (_info) => {
        // console.log('onCreateEdit')
        this.setState({ isCreate: true, isEdit: true, codeInfo: _info })
    };

    onClosePanel = () => () => (this.setState({ isCreate: false, isView: false, eidtInfo: false, isEdit: false, codeInfo: undefined }));

    DisplayPromotionInfo = (_info) => {

        //console.log('DisplayPromotionInfo : ', _info);
        this.setState({ isView: true, codeInfo: _info })
    }

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        const _isDESC = (orderBy === 'desc');

        //console.log('handleRequestSort : ', _fildeName, packageInfoList)

        PromotionsCodeList = (_isDESC)
            ? PromotionsCodeList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : PromotionsCodeList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((_isDESC) ? 'asc' : 'desc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        // console.log('_limitRows', _limitRows)
        this.setState({ isLoading: true });
        this.props.getAPIPromotionsCodeList({ page: 0, num_limit: _limitRows });
    }

    onNextPage = (_pageNumber, _limit) => {
        // console.log('_limitRows', _pageNumber, _limit)
        this.setState({ isLoading: true });
        this.props.getAPIPromotionsCodeList({ page: _pageNumber, num_limit: _limit });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================
}

function GetBookingRows(PromotionsCodeList, DisplayPromotionInfo, onCreateEdit, classes, isAdmin, isMobile) {

    return PromotionsCodeList.map(_info => {

        const { condition, countLimit, visible, actived, description, discountType, discountValue, endDate, id, limit, namePromotion, promotionCode, promotionCodeId, startDate, expDate } = _info;

        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={id} >
                <TableCell style={_callName} onClick={() => DisplayPromotionInfo(_info)}>{namePromotion || ''}</TableCell>
                {(!isMobile) && <TableCell style={_callName} onClick={() => DisplayPromotionInfo(_info)} >{description || ''}</TableCell>}
                <TableCell className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)}>{discountValue || 0}</TableCell>
                <TableCell className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)}>{limit || 0}</TableCell>
                <TableCell className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)}>{countLimit || 0}</TableCell>
                {/* <TableCell    className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)}>{discountValue || 0}</TableCell> */}
                {(!isMobile) &&
                    < React.Fragment >
                        <TableCell className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)}>{discountType || ''}</TableCell>
                        <TableCell className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)} >{SetDateShort(startDate)}</TableCell>
                        <TableCell className={classes.text_info} onClick={() => DisplayPromotionInfo(_info)}>{SetDateShort(endDate)}</TableCell>
                        <TableCell style={{ ..._callStyle, color: expDate ? Colors.success : Colors.error }}>{GetIconWithStatus(expDate)}</TableCell>
                        <TableCell style={{ ..._callStyle, color: actived ? Colors.success : Colors.error }}>{GetIconWithStatus(actived)}</TableCell>
                    </React.Fragment>}
                {(isAdmin) && <TableCell style={_callStyle} ><BorderColorIcon onClick={() => onCreateEdit(_info)} /></TableCell>}
            </TableRow>
        );
    });
}

function GetIconWithStatus(_status) {

    return ((_status) ? <DoneIcon /> : <CloseIcon />)
}

function GetSortIcon(orderBy, field, handleRequestSort) {

    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, } = _ListCount;
    // console.log('_ListCount', _ListCount);
    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 25}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        table: { minWidth: 1024, },
        rootMobile: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        tableMobile: { width: '100%' },
        text_info: { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', fontFamily: Family.roboto, },

    });

PromotionCodeListPanel.propTypes = { classes: PropTypes.object.isRequired, };


function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIPromotionsCodeList: (_keyword) => {
            InitWaitingTime();
            dispatch(PromotionActions.getPromotionsCodeList(_keyword));
        },
        getAPICurrencyList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CurrencyActions.getCurrencyList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PromotionCodeListPanel));

