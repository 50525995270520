import React, { Component } from 'react';
import { connect } from 'react-redux';

import CustomerListPanel from '../containers/Customers/CustomerListPanel.jsx';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { PathName } from '../constant';
import * as CustomerActions from '../actions/Actions_CustomerManager.js';
import * as StatiticActions from '../actions/Actions_StatiticManager.js';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import CustomerStatPanel from '../containers/Customers/CustomerStatPanel.jsx';
import CustomerCard from '../containers/Customers/CustomerCard.jsx';

import '../App.css';

const _height = (window.innerHeight - 130);

let CustomerList = [];
let waitingTime = true;

class CustomerManage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            create: { name: '', type: '', email: '', phone: '', place: '', image: [], },
            blog: { title: '', type: '', sub_title: '', destination: '', activities: '', content: [], cover: [], video: [] },
            isCreate: false,
            isLoading: true,
            isActiveCustomer: false,
            CustomerInfo: undefined
        }
        this.onChangeState = this.onChangeState.bind(this);

        this.props.getAPICustomerList({ page: 0, num_limit: 25 });
    }

    componentWillReceiveProps(nextProps) {

        const { CustomerManager, StatiticManager } = nextProps;
        console.log(`CustomerManager [componentWillReceiveProps] : `, CustomerManager);

        if (!!CustomerManager) {

            const { actions, data } = CustomerManager.CUSTOMER_REDUCER;

            switch (actions) {
                case CustomerActions.GET_CUSTOMER_SUCCESS:
                    {
                        // console.log(`CustomerManager : `, data.result);
                        // CustomerManager.CUSTOMER_REDUCER.actions = CustomerActions.WATING_STATE;
                        CustomerList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CustomerActions.GET_CUSTOMER_FAILURE:
                    {
                        // CustomerManager.CUSTOMER_REDUCER.actions = CustomerActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        // if (!!StatiticManager) {
        //     const { data, actions, error } = StatiticManager.STATITIC_REDUCER;

        //     switch (actions) {
        //         case StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_SUCCESS:
        //             {
        //                 console.log(`StatiticManager ${actions}  : `, data.results);

        //             } break;
        //         case StatiticActions.GET_SUMMARY_CUSTOMER_STATUS_FAILURE:
        //             {
        //                 // SetStateWithTime(this, waitingTime);
        //             } break;
        //     }
        // }
    }

    render() {

        const { isLoading, isCreate, isActiveCustomer } = this.state;

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} />}
                {(isActiveCustomer) && <DialogAction Active={isActiveCustomer} OnClose={this.onClosePanel} AcrionCom={this.customerPanel()} />}
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={<div />} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel()}
                    </div>
                </List>
            </div>
        );
    }

    //Check Blog
    onChangeState(state) {
        let create = { ...this.state.create, ...state };
        let blog = { ...this.state.blog, ...state };
        this.setState({ create, blog });
        console.log('blog', blog)
    }

    NavbarAcitve = (value) => { console.log('NavbarAcitve : ', value); this.props.history.push(value); };
    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive }));
    onClosePanel = () => (this.setState({ isCreate: false, isActiveCustomer: false }));

    onNextPage = () => (_pageNumber) => {
        this.setState({ isLoading: true });
        this.props.getAPICustomerList({ page: _pageNumber, num_limit: 25 });
    }

    DisplayCustomerInfo = () => (customerInfo) => {

        console.log('DisplayCustomerInfo : ', customerInfo);
        this.props.history.push({ pathname: PathName.CustomerInfo, state: { customerInfo }, search: '', hash: '' });
    }
    EditCustomer = () => (CustomerInfo) => {
        this.setState({ CustomerInfo, isActiveCustomer: true });
    }

    customerPanel = () => (
        <CustomerCard CustomerInfo={this.state.CustomerInfo} onClosePanel={this.onClosePanel} />
    )

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <CustomerStatPanel />
                <CustomerListPanel
                    EditInfo={this.EditCustomer()}
                    CreatePanel={this.onCreatePanel()}
                    DisplayCustomerInfo={this.DisplayCustomerInfo()}
                    onNextPage={this.onNextPage()}
                    CustomerList={CustomerList} />
            </div>)
    }

}


function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPICustomerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CustomerActions.getCustomerList(_keyword, _filter));
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomerManage);
