import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { getFormatted } from '../../common/SetDate.js';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { content_paymenttime, placeholder, } from '../../constant/JSON/PaymentTime';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors } from '../../themes';

import Select from '@material-ui/core/Select';

import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';

import * as PaymenttimeSettingActions from '../../actions/Actions_Settings';
import DialogPanel from '../../componets/DialogPanel.jsx';

const hourList = Array.from({ length: 25 }, (x, index) => index);
const minList = Array.from({ length: 61 }, (x, index) => index);

let timeSetting;
let waitingTime = true;

class PaymentTimeCard extends Component {

    state = { isLoading: false, isDialog: false, msgError: undefined, dialogType: undefined, isBtnActive: false, error: null, isSuccess: false }

    constructor(props) {
        super(props);

        this.onChangeState = this.onChangeState.bind(this);
        this.onSave = this.onSave.bind(this);

        const { PaymentTimeInfo } = this.props;
        const { time } = PaymentTimeInfo;
        timeSetting = (!!time) ? { ...time } : { hour: 0, min: 0 };

    }

    componentWillReceiveProps(nextProps) {

        const { SettingManager } = nextProps;
        //console.log('PAYMENTTIMESETTING_REDUCER : ', SettingManager);

        if (!!SettingManager) {
            const { actions, data } = SettingManager.SETTING_REDUCER;

            switch (actions) {
                case PaymenttimeSettingActions.UPDATE_PAYMENT_TIME_SUCCESS:
                    {
                        SettingManager.SETTING_REDUCER.actions = PaymenttimeSettingActions.WATING_STATE;

                        SetStateWithObject(this, waitingTime, { msgError: 'Update Time Payment Success.', dialogType: 'success', isSuccess: true });
                        this.props.getAPIgetPaymentTime();
                    } break;
                case PaymenttimeSettingActions.UPDATE_PAYMENT_TIME_FAILURE:
                    {
                        const _msg = data.response.data.error.message || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', isSuccess: false });
                    } break;
            }
        }
    };

    render() {

        const { classes, } = this.props;
        const { msgError, dialogType, isDialog, isBtnActive } = this.state;
        const { onChangeState, onReset, onSave } = this;

        return (
            <div style={{ maxWidth: 800, backgroundColor: Colors.white, padding: '10px 20px', textAlign: ' start', }} >
                {(isDialog) && <DialogPanel Active={isDialog} MSG={msgError} Type={dialogType} OnClose={() => this.onCloseDialog()} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Typography className={classes.title}>{content_paymenttime.title_name}</Typography>
                </div>
                <div style={{ display: 'flex', padding: '5px 0px' }}>
                    <FormControl className={classes.fieldStyle}>
                        <InputLabel htmlFor="age-native-simple">Hour</InputLabel>
                        <Select native
                            value={timeSetting.hour}
                            onChange={(e) => { timeSetting.hour = parseInt(e.target.value); onChangeState(timeSetting) }}
                            inputProps={{ id: 'age-native-simple', }}>
                            {hourList.map((status) => { return <option key={status} value={status}>{getFormatted(status)}</option> })}
                        </Select>
                    </FormControl>
                    <FormControl className={classes.fieldStyle}>
                        <InputLabel htmlFor="age-native-simple">Min</InputLabel>
                        <Select native
                            value={timeSetting.min}
                            onChange={(e) => { timeSetting.min = parseInt(e.target.value); onChangeState(timeSetting) }}
                            inputProps={{ id: 'age-native-simple', }}>
                            {minList.map((status) => { return <option key={status} value={status}>{getFormatted(status)}</option> })}
                        </Select>
                    </FormControl>
                </div>
                <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => onReset()} />
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_SAVE}
                        disabled={!isBtnActive}
                        onClick={() => onSave()} />
                </div>
            </div >
        );
    }

    onChangeState = (value) => {
        const { time } = this.props.PaymentTimeInfo;
        const isTrue = (JSON.stringify(time) === JSON.stringify(value));
        this.setState({ ...value, isBtnActive: (!isTrue) });
    }

    onUpdate(PaymentTime) {

        const NewTime = { ...this.props.PaymentTimeInfo };
        NewTime.time = PaymentTime;
        this.props.UpdatePaymentTime(NewTime);
    }

    onReset = () => { this.props.onClosePanel() }

    onSave = () => { this.setState({ error: null, isDialog: true, isBtnActive: false }, () => this.onUpdate(timeSetting)) }

    onCloseDialog = () => {

        const { isSuccess } = this.state;
        this.setState({ isDialog: false, msgError: undefined, dialogType: undefined, isBtnActive: false });

        if (isSuccess)
            this.props.onClosePanel();
    };
}


const styles = theme => (
    {
        title: {
            marginBottom: '15px',
            fontSize: '24px',
            display: 'flex',
            height: '60px',
            alignItems: 'center'
        },

        fieldStyle: { textAlign: 'start', width: '40%', marginRight: '10px' }
    });

PaymentTimeCard.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIgetPaymentTime: (confirmInfo) => {
            dispatch(PaymenttimeSettingActions.getPaymentTime(confirmInfo));
        },
        UpdatePaymentTime: (confirmInfo) => {
            InitWaitingTime();
            dispatch(PaymenttimeSettingActions.UpdatePaymentTime(confirmInfo));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(PaymentTimeCard));
