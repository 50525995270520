
// ===================================================================================================================================
// Main Config App.
// ===================================================================================================================================

import Language from '../constant/JSON/Language';
import { PLATFORM_NAME, getPlatForm } from "./platform";

// ===================================================================================================================================

const isPlatForm = PLATFORM_NAME.TRAVISGO;

const isPath = getPlatForm(isPlatForm);
const platformConfig = require(`${isPath}`);

// ===================================================================================================================================

const {
    host,
    webURL,
    api,
    LBhost,
    EP_token,
    LB_token,
    LBheader,
    isDevelop,
    buildVer, } = platformConfig;

// ===================================================================================================================================

// console.log(`Main : `, platformConfig);

const isProduction = !isDevelop;
const defaultSetting = { language: Language.ConstLanguage.TH };

// ===================================================================================================================================

switch (true) {
    case isProduction:
        { console.warn(` === Build ${isPlatForm} Ver.(${buildVer}) === `); } break;
    case isDevelop:
        { console.warn(` === Build ${isPlatForm} Branch [ ${process.env.NODE_ENV} ] Ver.(${buildVer}) === `); } break;
}

// ===================================================================================================================================

export default {
    isPlatForm,
    host,
    webURL,
    api,
    LBhost,
    EP_token,
    LB_token,
    LBheader,
    default: defaultSetting,
};

// ===================================================================================================================================