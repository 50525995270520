import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PathName } from '../../constant';
import SupplierStatCard from '../../componets/SupplierStatCard.jsx';

import '../../App.css';

const _supplier = [{ name: 'Pending', value: 6 }, { name: 'Verified', value: 0 },];
let _newSupplierList = [];

class BestProductStatPanel extends Component {

    state = {}

    componentWillUnmount() {
        _newSupplierList = [];
    }

    render() {

        const { SupplierList, isLoading, isMobile, _marginRight } = this.props;
        // console.log('BestProductStatPanel : ', SupplierList)

        if (!!SupplierList) {
            _supplier[0].value = SupplierList.total;

            if (SupplierList.length > 0)
                _newSupplierList = SupplierList.map(_info => { _info[_info.type] = _info.value; return _info })
        }

        return (
            <div className="App-content">
                <div style={{ flex: 1, marginRight: (_marginRight || 10) }}>
                    <SupplierStatCard
                        Info={_newSupplierList || SupplierList}
                        Topics={`Top Product Booking `}
                        Disable={false}
                        Styles={{ width: isMobile ? (window.innerWidth) : 1024 }}
                        isLoading={isLoading}
                        btnViewHadle={this.btnViewHadle(PathName.SupplierManage)}
                        chartType={2} />
                </div>
            </div>)
    }

    btnViewHadle = (value) => () => { this.props.history.push(value); }
}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        // getAPLists:(_keyword) =>
        // {
        //     dispatch(SearchAction.getAPILists(_keyword));
        // },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BestProductStatPanel);
