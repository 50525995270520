
export function SetDate(_date) {

  const strDate = new Date(_date);
  // const isoDate = strDate.toISOString();

  const date = `${getFormatted(strDate.getDate())}-${getFormatted((strDate.getMonth() + 1))}-${strDate.getFullYear()} : ${getFormatted(strDate.getHours())}:${getFormatted(strDate.getMinutes())}`;
  // console.log('date : ', isoDate, ' : ', (new Date(isoDate)));

  return date;
}

export function SetDateShort(_date) {

  const strDate = new Date(_date);
  const date = `${getFormatted(strDate.getDate())}-${getFormatted((strDate.getMonth() + 1))}-${strDate.getFullYear()}`;

  return date;
}

export function ConvertDateAll(_date) {

  const regex = /\//gi;
  const shotDate = _date.replace(regex, '-');
  return shotDate.substr(0, 10).split("-").reverse().join("-");
}

export function SetDateTo(_start, _end) {

  const strDate = new Date(_start);
  const endDate = new Date(_end);
  const date = `${getFormatted(strDate.getDate())}/${getFormatted((strDate.getMonth() + 1))}/${strDate.getFullYear()} ${' - '} ${getFormatted(endDate.getDate())}/${getFormatted((endDate.getMonth() + 1))}/${endDate.getFullYear()}`;

  return date;
}

export function SetDateFormat(_date) {

  const _selectDate = new Date(_date);
  return `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate())}`;
}

export function ConvertDate(_date) {

  return (_date[2] !== '-') ? _date.substr(0, 10).split("-").reverse().join("-") : _date;
}

export function getFormatted(_info) {

  return _info < 10 ? `0${_info}` : _info;
}