import React, { Component } from 'react';
import { connect } from 'react-redux';
import { path } from 'ramda';
import '../../App.css';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

import PackageCard from '../Packages/PackageCard';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction';

import { SetPackegeInfoToCreate, SetPackegeInfoToUpdate, SetCustomUpdate } from '../../constant/initState/initPackage';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as UploadActions from '../../actions/Actions_UploadManager';
import * as DestinationsActions from '../../actions/Actions_DestinationsManager';
import * as LocationServicesActions from '../../actions/Actions_LocationServicesManager';
import * as ConditionActions from '../../actions/Actions_Conditions';
import * as AppointmentActions from '../../actions/Actions_Appointment';

import * as SupplierActions from '../../actions/Actions_SupplierManager.js';


const _height = (window.innerHeight - 130);

let waitingTime = true;
let DestinationsList = [];
let SupplierList = [];
let ConditionList = [];
let LocationServicesList = [];
let AppointmentList = [];


class TransferCard extends Component {

    state = { isLoading: true, isSupplier: false, msgError: undefined, dialogType: undefined, isBtnActive: false }

    constructor(props) {
        super(props);

        this.state = {
            packageId: undefined,
            packageInfo: undefined,
            image: [],
            error: null,
            isCreate: false,
            isUpdate: false,
        }
        this.props.getAPILocationServicesLists();
        this.props.getAPIDestinationsLists();
        this.props.getAPISupplierList();
        this.props.getAPIConditionsList({});
        this.props.getAPIAppointmentList({});
    }

    componentWillReceiveProps(nextProps) {
        const { DestinationsManager, AppointmentManager, PackageManager, UpLoadManager, SupplierManager, ConditionManager, LocationServicesManager } = nextProps;

        if (!!PackageManager) {

            const { data, actions, error } = PackageManager;

            switch (actions) {
                case PackageAction.CREATE_PACKAGE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Create Package Success. ', dialogType: 'success' });
                        this.setState({ packageId: path(['result', 'package', 'packageId'], data) })
                        this.props.getAPIPackageList({ limit: 25, page: 0, language: path(['result', 'package', 'packageInfo', 'language'], data) || 'EN' })
                    } break;
                case PackageAction.UPDATE_PACKAGE_SUCCESS:
                    {
                        SetStateWithObject(this, waitingTime, { msgError: 'Update Package Success.', dialogType: 'success' });
                        this.props.getAPIPackageList({ limit: 25, page: 0, language: path(['result', 'package', 'packageInfo', 'language'], data) || 'EN' })
                    } break;
                case PackageAction.CREATE_PACKAGE_FAILURE:
                case PackageAction.UPDATE_PACKAGE_FAILURE:
                    {
                        console.log("PACKAGE_FAILURE : ", data);
                        const _msg = data.response.data.error || actions;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }

        if (!!UpLoadManager) {
            const { data, actions, error } = UpLoadManager;

            switch (actions) {
                case UploadActions.UPLOAD_IMAGE_SUCCESS:
                    {
                        UpLoadManager.actions = UploadActions.WATING_STATE;
                        let _newPackage = { ...this.state.packageInfo };
                        const _image = data.result.upload.images;

                        if (this.state.isUpdate) {
                            _newPackage.newImageGallery = _newPackage.newImageGallery.concat(_image);
                            _newPackage.newImageCover = _newPackage.newImageCover.concat(_image);
                            console.log('UPLOAD_IMAGE_UPDATE_SUCCESS :', _newPackage)
                            // this.props.getAPIupdatePackage(SetPackegeInfoToUpdate(_newPackage));
                            this.props.getAPIupdatePackage(SetCustomUpdate('UpdatePackage', _newPackage));
                        } else {
                            _newPackage.newImage = _newPackage.newImage.concat(_image);
                            console.log('UPLOAD_IMAGE_SUCCESS :', _newPackage)
                            this.props.getAPIcreatePackage(SetPackegeInfoToCreate(_newPackage));
                        }
                    } break;
                case UploadActions.UPLOAD_IMAGE_FAILURE:
                    {
                        console.log("UPLOAD_IMAGE_FAILURE : ", data);
                        const _msg = data.status;//ErrorCode[data.status].EN;
                        UpLoadManager.actions = UploadActions.WATING_STATE;
                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;

            }
        }

        if (!!DestinationsManager) {
            const { actions, data } = DestinationsManager;

            switch (actions) {
                case DestinationsActions.GET_DESTINATIONS_SUCCESS:
                    {
                        DestinationsList = data.result.datas;
                        DestinationsManager.actions = DestinationsActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case DestinationsActions.GET_DESTINATIONS_FAILURE:
                    {
                        DestinationsManager.actions = DestinationsActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!LocationServicesManager) {
            const { actions, data } = LocationServicesManager;

            switch (actions) {
                case LocationServicesActions.GET_LOCATIONSERVICES_SUCCESS:
                    {
                        LocationServicesList = path(['result'], data);
                        LocationServicesManager.actions = LocationServicesActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case LocationServicesActions.GET_LOCATIONSERVICES_FAILURE:
                    {
                        LocationServicesManager.actions = LocationServicesActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }


        if (!!SupplierManager) {

            const { actions, data } = SupplierManager;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_SUCCESS:
                    {
                        SupplierList = data.result.datas;
                        SupplierManager.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_FAILURE:
                    {
                        SupplierManager.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }

        if (!!ConditionManager) {
            const { actions, data } = ConditionManager;

            switch (actions) {
                case ConditionActions.GET_CONDITIONS_SUCCESS:
                    {
                        ConditionList = data.result.datas;
                        ConditionManager.actions = ConditionActions.WATING_STATE;

                        SetStateWithTime(this, waitingTime);
                    } break;

                case ConditionActions.GET_CONDITIONS_FAILURE:
                    {
                        ConditionManager.actions = ConditionActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
        if (!!AppointmentManager) {
            const { actions, data } = AppointmentManager;

            switch (actions) {
                case AppointmentActions.GET_APPOINTMENT_SUCCESS:
                    {
                        AppointmentList = data.result.datas;
                        AppointmentManager.actions = AppointmentActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case AppointmentActions.GET_APPOINTMENT_FAILURE:
                    {
                        AppointmentManager.actions = AppointmentActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const { isLoading, isCreate, isSupplier, msgError, dialogType } = this.state;
        const { supplierInfo } = (!!this.props.location.state) ? this.props.location.state : '';

        return (
            <div className="App">
                {(isSupplier) && <DialogAction Active={isSupplier} OnClose={this.onClosePanel} AcrionCom={this.GetSupplierId()} />}
                {/* {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel} AcrionCom={this.GetTourIncludeCard()} />} */}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div style={{ display: 'inline-block', width: '100%', maxWidth: '1030px' }}>
                    <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                        <div style={{ display: 'inline-block' }}>
                            <PackageCard
                                isTransfer={true}
                                supplierInfo={supplierInfo}
                                SupplierList={SupplierList}
                                ConditionList={ConditionList}
                                DestinationsList={DestinationsList}
                                AppointmentList={AppointmentList}
                                LocationServicesList={LocationServicesList}
                                saveAPIPackage={this.saveAPIPackage}
                                updateAPIPackage={this.updateAPIPackage} />
                        </div>
                    </List>
                </div>
            </div>
        );
    }

    saveAPIPackage = (packageInfo, supplierId) => {
        const { imageGallery } = packageInfo
        this.setState({
            isLoading: true,
            packageInfo: {
                ...packageInfo,
                supplierId: supplierId,
                newImage: [],
            }
        }, () => this.upLoadImage(imageGallery))
    }

    updateAPIPackage = (packageInfo, supplierId) => {
        const { packageId } = this.state;
        const { imageGallery } = packageInfo;
        if (imageGallery.length > 3) {
            this.setState({
                isUpdate: true,
                isLoading: true,
                packageInfo: {
                    ...packageInfo,
                    packageId: packageId,
                    supplierId: supplierId,
                    imageGallery: [], newImageCover: [], newImageGallery: [],
                }
            }, () => this.upLoadImage(imageGallery)
            )
        } else this.setState({ isLoading: true, msgError: 'Please Select Image Covero at least 4 photo.', dialogType: 'error' })


    }
    upLoadImage = (cover) => {

        let formdata = new FormData();
        formdata.append('imgOriginalWidth', 1360); //650
        formdata.append('imgOriginalHeight', 960); //450
        formdata.append('imgThumbnailWidth', 900); //340
        formdata.append('imgThumbnailHeight', 636); //260
        formdata.append('imgThumbnailWidthMobile', 616); //308
        formdata.append('imgThumbnailHeightMobile', 362); //181

        for (const _image of cover)
            formdata.append("images", _image.file);
        this.props.uploadImage(formdata)
    }


    onCreatePanelId = (_isActive) => () => (this.setState({ isSupplier: !_isActive }));
    onCreatePanel = (_isActive) => () => (this.setState({ isCreate: !_isActive }));
    onClosePanel = () => (this.setState({ isCreate: false, isSupplier: false }));
    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));
}
const styles = theme => ({ title: { fontSize: '24px', display: 'flex' }, });

function mapStateToProps(state, ownProps) {
    return {
        PackageManager: state.PackageManager.PACKAGE_REDUCER,
        DestinationsManager: state.DestinationsManager.DESTINATION_REDUCER,
        LocationServicesManager: state.LocationServicesManager.LOCATIONSERVICES_REDUCER,
        UpLoadManager: state.UpLoadManager.UPLOAD_REDUCER,
        SupplierManager: state.SupplierManager.SUPPLIER_REDUCER,
        ConditionManager: state.ConditionManager.CONDITION_REDUCER,
        AppointmentManager: state.AppointmentManager.APPOINTMENT_REDUCER,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists());
        },
        getAPIcreatePackage: (packageInfo) => {
            dispatch(PackageAction.APICreatePackage(packageInfo));
        },
        getAPIupdatePackage: (packageInfo) => {
            dispatch(PackageAction.APIUpdatePackage(packageInfo));
        },
        uploadImage: (_imageInfo) => {
            dispatch(UploadActions.APIUpLoadImage(_imageInfo));
        },
        getAPIDestinationsLists: (_keyword) => {
            InitWaitingTime();
            dispatch(DestinationsActions.getDestinationsList());
        },
        getAPISupplierList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
        getAPIConditionsList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(ConditionActions.getConditionsList());
        },

        getAPILocationServicesLists: (_keyword) => {
            InitWaitingTime();
            dispatch(LocationServicesActions.getLocationServicesList());
        },
        getAPIAppointmentList: (_keyword, _filter) => {
            dispatch(AppointmentActions.getAppointmentList());
        },
    }
}
TransferCard.propTypes = { classes: PropTypes.object.isRequired, };
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TransferCard));
