
export const BookingStatus = {
    WAITING: 'waiting',
    FAILED: 'failed',
    INPROCESS: 'in-process',
    APPROVED: 'approved',
    CANCEL: 'cancel',
    REJECT: 'reject',
    REFUND: 'refund',
    ONDELIVERY: 'on-delivery',
    COMPLETE: 'complete',
    ABANDON: 'abandon',
    WAITAPPROVED: 'wait-approved',
}

export const OmiseStatus = {
    FAILED: 'failed',
    PENDING: 'pending',
    EXPIRED: 'expired',
    REVERSED: 'reversed',
    SUCCESSFUL: 'successful',
    OMISE: 'omise',
}

export const RefundStatus = {
    REQUEST: 'request',
    INPROCESS: 'inprocess',
    SUCCESS: 'success',
    REFUND: 'refund',
    FAILED: 'failed',
}

export const TransactionStatus = {
    APPROVED: 'approved',
    COMPLETE: 'complete',
    ABANDON: 'abandon',
    REFUND: 'refund',
}

export const RequestStatus = {
    REQUEST: 'request',
    INPROCESS: 'in-process',
    SUCCESS: 'success',
    REFUSE: 'refuse',
    FAILED: 'failed',
}

