import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { SetDate } from '../../common/SetDate';
import { GetStatusColor } from '../../common/GetStatusColor';
import * as BookingAction from '../../actions/Actions_BookingManager.js';
import { InitwaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { NumberFormats } from '../../common/SetNumberFormat';
import Button from '../../constant/Button';
import {Colors} from '../../themes';

import '../../App.css';

// const _height=(window.innerHeight-130);

const fildeList = [
    { label: 'BOOKING ID', name: 'bookingId', isOrder: true, },
    { label: 'PACKAGE NAME', name: 'name', isOrder: true, },
    { label: 'BUYERS', name: 'firstName', isOrder: true, },
    { label: 'TIME', name: '', isOrder: false, },
    { label: 'PRICE', name: 'net', isOrder: false, },
    { label: 'PAYMENT BY', name: '', isOrder: false, },
    { label: 'STATUS', name: 'bookingStatus', isOrder: true, },];

// const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
// const _callName = { textAlign: 'left', paddingLeft: '5px', paddingRight: '5px', minWidth: 160 };

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', };
const _callName = { textAlign: 'left', paddingLeft: '10px', paddingRight: '10px', width: 270 };
const _headCall = { paddingLeft: '5px', paddingRight: '5px', };

const _wording = { btn: 'BOOKING MANAGE', tap: 'DAY TRIPS' };

let bookingList;

class InProcessListCard extends Component {

    state = { value: true, }

    constructor(props) {
        super(props);
        // console.log(`InProcessListCard [constructor] : `, this.props);
    }

    componentWillMount() {
        //  console.log(`InProcessListCard [componentWillMount] : `,this.props);   
    }

    componentWillReceiveProps(nextProps) {
        const { BookingList, BookingDetail, BookingManage, BookingManager } = nextProps;
        //bookingList=BookingManager.BOOKING_REDUCER.data;

        //console.log(`InProcessListCard [componentWillReceiveProps] : `, bookingList);

        //  if(!!BookingManager)
        //      this.BookingManager(BookingManager.BOOKING_REDUCER);
    }

    handleChange = (event, value) => { this.setState({ value }); };

    handleChangeIndex = index => { this.setState({ value: index }); };

    render() {
        return this.contentPanel();
    }

    // NavbarAcitve = (value) => { console.log('NavbarAcitve : ', value); this.props.history.push(value); };

    contentPanel() {

        const { BookingList, BookingDetail, BookingManage, classes, } = this.props;
        bookingList = ((!!BookingList) && (!!BookingList.bookingList)) ? BookingList.bookingList : [];

        //console.warn(`InProcessListCard [componentWillReceiveProps] : `, this.props);

        return (
            <Paper style={{ width: '100%', marginTop: 10, overflowX: 'auto', }}>
                <Table style={{ minWidth: 1024, }}>
                    <TableHead>
                        <TableRow>
                            {fildeList.map((_filde, index) => {
                                return (
                                    <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                        {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                    </TableCell>)
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(bookingList.length === 0) ? GetEmptyRows() : GetBookingRows(bookingList, BookingDetail)}
                    </TableBody>
                </Table>
                {BookingManage &&
                    <Button.Themes TitleText={_wording.btn} style={{ width: '100%' }} buttonStyle={{ marginTop: 0 }}
                        onClick={() => BookingManage()} />
                }
                {(!Boolean(BookingManage) && bookingList.length > 0) && GetPagination(BookingList, this.onNextPage, this.onChangeLimit)}
            </Paper>
        );
    }

    //===============================================================================================================================================================

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        // console.log('handleRequestSort : ', _fildeName, bookingList)

        bookingList = (orderBy === 'desc')
            ? bookingList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : bookingList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onNextPage = (_pageNumber, _limit) => {

        const { userInfo } = this.props;
        this.setState({ isLoading: true });

        this.props.getAPIBookingLists('', (Boolean(userInfo) ? { userId: userInfo, page: _pageNumber, limit: _limit } : { page: _pageNumber, limit: _limit }));
    }

    onChangeLimit = (_limitRows) => {

        const { userInfo } = this.props;
        this.setState({ isLoading: true });

        this.props.getAPIBookingLists('', (Boolean(userInfo) ? { userId: userInfo, page: 0, limit: _limitRows } : { page: 0, limit: _limitRows }));
    }

    //===============================================================================================================================================================
}

function GetEmptyRows() {
    return (
        <TableRow key={'emtpyRow'}>
            <TableCell colSpan={fildeList.length}>
                <div style={{ textAlign: 'center' }}>{`Empty.`}</div>
            </TableCell>
        </TableRow>
    );
}

function GetBookingRows(bookingList, BookingDetail) {

    return bookingList.map(_booking => {

        return (
            <TableRow key={_booking.bookingId} hover style={{ cursor: 'pointer' }} onClick={() => { BookingDetail(_booking.bookingId) }}>
                <TableCell    style={_callStyle}>{_booking.bookingId}</TableCell>
                <TableCell style={_callName}>{Array.isArray(_booking.packageInfo) ? _booking.packageInfo[0].name : '-'}</TableCell>
                <TableCell    style={{ ..._callStyle, textAlign: 'left' }}>{`${_booking.buyerInfo.title}${_booking.buyerInfo.firstName} ${_booking.buyerInfo.lastName}`}</TableCell>
                <TableCell    style={_callStyle}>{SetDate(_booking.date)}</TableCell>
                <TableCell    style={_callStyle}>{NumberFormats(_booking.priceTotal.subTotal)}</TableCell>
                <TableCell    style={_callStyle}>{_booking.paymentInfo.paymentMethod}</TableCell>
                <TableCell    style={GetStatusColor('Booking', _callStyle, _booking.bookingStatus)}>{_booking.bookingStatus}</TableCell>
            </TableRow>
        )
    });
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    // console.log('GetPagination :', _ListCount)
    const { total, page, limit, pageSize } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 25}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    );
}

function GetTapsPanel() {
    // return (
    //         <div style={{width:'100%'}}>
    //         <AppBar position="static" color="primary">
    //             <Tabs value={this.state.value}
    //                   onChange={this.handleChange}
    //                   indicatorColor="primary"
    //                   textColor="primary"
    //                   fullWidth>
    //                 <Tab label="DAY TRIPS" />
    //                 <Tab label="Item Two" />
    //                 <Tab label="Item Three" />
    //             </Tabs>
    //         </AppBar>
    //         <SwipeableViews
    //             axis={'x'}
    //             // axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    //             index={this.state.value}
    //             onChangeIndex={this.handleChangeIndex}>
    //             <TabContainer >{this.contentPanel()}</TabContainer>
    //             <TabContainer >Item Two</TabContainer>
    //             <TabContainer >Item Three</TabContainer>
    //         </SwipeableViews>
    //         </div>
    //   );
}

function mapStateToProps(state, ownProps, ) {
    return {
        ...state,
        classes: PropTypes.object.isRequired,
        theme: PropTypes.object.isRequired,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIBookingDetails: (_id) => {
            dispatch(BookingAction.getAPIBookingDetail(_id));
        },
        getAPIBookingLists: (_keyword, _filter) => {
            dispatch(BookingAction.getAPIBookingLists(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InProcessListCard);
