import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import CONFIG from '../../config';

import EmptyRows from '../../componets/EmptyRow.jsx';
import Language from '../../constant/Language';
import Button from '../../constant/Button';
import { Family, Fonts, Colors, Icons } from '../../themes';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
const { BorderColorIcon, DoneIcon, CloseIcon } = Icons;

const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'PACKAGE NAME', name: 'name', isOrder: true, },
    { label: 'TYPE', name: 'type', isOrder: true, },
    { label: 'STATUS', name: 'status', isOrder: false, }
];

const _headTable = { padding: '0px', border: '0px', height: '100%' };
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callStyle = { textAlign: 'Left', paddingLeft: '5px', paddingRight: '5px', };
const _wording = { header: 'Reservation Table Manage' }

let packageInfoList = [];

class ReservationTableListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    render() {
        const { classes, onClickHandle, EditInfo } = this.props;
        const isPackage = (packageInfoList.length > 0);

        packageInfoList = (this.props.packageInfoList) && (this.props.packageInfoList.packageList || []);

        return (
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={fildeList.length} style={_headTable} >
                                <Button.Themes TitleText={_wording.header}
                                    style={{ width: '100%' }}
                                    buttonStyle={{ marginTop: 0, padding: 15 }} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            {fildeList.map((_filde, index) => {
                                return <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                    {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(isPackage) ? GetBookingRows(packageInfoList, onClickHandle, EditInfo) : <EmptyRows fildeList={fildeList} />}
                    </TableBody>
                </Table>
                {(isPackage) && GetPagination(this.props.packageInfoList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
            </Paper>
        )
    }
    //===============================================================================================================================================================

    handleRequestSort = (sortFilde) => {
        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;

        packageInfoList = (orderBy === 'desc')
            ? packageInfoList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : packageInfoList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows, _language) => {
        const { supplierId } = this.props;
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ supplierId: supplierId, page: 0, limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        const { supplierId } = this.props;
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ supplierId: supplierId, page: _pageNumber, limit: _limit || 10, language: _language });
    }

    onChangeLanguage = (_language) => {
        const { supplierId } = this.props;
        this.setState({ isLoading: true });
        this.props.getAPIpackageInfoList({ supplierId: supplierId, page: 0, limit: 10, language: _language });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    //===============================================================================================================================================================

}
function GetBookingRows(packageInfoList, onClickHandle, EditInfo) {

    return packageInfoList.map(_package => {
        const { packageId, packageInfo, type, visible } = _package;
        const { image, name } = packageInfo;
        const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${checkURL(image)})`, backgroundRepeat: 'round' };
        return (
            <TableRow hover style={{ cursor: 'pointer' }} key={packageId} onClick={() => onClickHandle(packageId)}>
                <TableCell style={_callStyle} ><div style={_backgroundImage} /></TableCell>
                <TableCell style={_callStyle} >{name || '-'}</TableCell>
                <TableCell style={_callStyle}  >{type || '-'}</TableCell>
                <TableCell style={{ ..._callStyle, ...{ color: visible ? Colors.success : Colors.error } }} >{visible ? 'Active' : 'Inactive'}</TableCell>
                {/* <TableCell    style={_callStyle}><Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(_package) }} /></TableCell> */}
            </TableRow>
        );
    });
}

// function GetIconWithStatus(_status) { return ((_status) ? <DoneIcon /> : <CloseIcon />) }

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {
    const { total, page, limit, language } = _ListCount;
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 10}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
    {
        root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
        table: { minWidth: 1024, },
    });

ReservationTableListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPIpackageInfoList: (_fliter) => {
            dispatch(PackageAction.getAPIPackageLists(_fliter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReservationTableListPanel));
