import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const AUTHEN_SUCCESS = 'AUTHEN_SUCCESS';
export const AUTHEN_FAILURE = 'AUTHEN_FAILURE';

export const AUTHEN_LB_SUCCESS = 'AUTHEN_LB_SUCCESS';
export const AUTHEN_LB_FAILURE = 'AUTHEN_LB_FAILURE';

export const FORGOT_PASSWORD_LB_SUCCESS = 'FORGOT_PASSWORD_LB_SUCCESS';
export const FORGOT_PASSWORD_LB_FAILURE = 'FORGOT_PASSWORD_LB_FAILURE';

export const CHANGE_PASSWORD_LB_SUCCESS = 'CHANGE_PASSWORD_LB_SUCCESS';
export const CHANGE_PASSWORD_LB_FAILURE = 'CHANGE_PASSWORD_LB_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() {
    return { type: IDLE_STATE, payload: null };
}

// setAPIState =============================================================================================================================

// Authentication ==========================================================================================================================

export function Authentication(_keyword = '', _fileterjson = {}) {
    // console.log("[API] Authentication :",_fileterjson)
    return dispatch => {
        _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.authentication}`,
            method: 'POST',
            headers: CONFIG.header,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            //console.log("[API] Authentication : ", response);
            dispatch(Authentication_Success(response.data.result));

        }).catch((error) => {
            // console.log("[API] Authentication Failure", error);
            dispatch(Authentication_Failure(error));
        })
    }
}

function Authentication_Success(data) {
    return { type: AUTHEN_SUCCESS, payload: data };
}

function Authentication_Failure(error) {
    return { type: AUTHEN_FAILURE, payload: error };
}

// Authentication ===========================================================================================================

// AuthenticationLB =========================================================================================================

export function AuthenticationLB(_fileterjson = {}) {
    // console.log("[API] AuthenticationLB :",_fileterjson)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.authenticationLB}`,
            method: 'POST',
            headers: CONFIG.header,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] AuthenticationLB : ", response);
            //localStorage.setItem('BookingList', response.data.result)
            dispatch(AuthenticationLB_Success(response.data.result));

        }).catch((error) => {
            // console.log("[API] AuthenticationLB Failure", error);
            dispatch(AuthenticationLB_Failure(error));
        })
    }
}

function AuthenticationLB_Success(data) {
    return { type: AUTHEN_LB_SUCCESS, payload: data };
}

function AuthenticationLB_Failure(error) {
    return { type: AUTHEN_LB_FAILURE, payload: error };
}

// AuthenticationLB ===========================================================================================================

// ForgotPasswordLB ===========================================================================================================

export function ForgotPasswordLB(_fileterjson = {}) {
    // console.log("[API] ForgotPasswordLB :",_fileterjson)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.forgotPasswordLB}`,
            method: 'POST',
            headers: CONFIG.header,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] ForgotPasswordLB : ", response);
            //localStorage.setItem('BookingList', response.data.result)
            dispatch(ForgotPasswordLB_Success(response.data.result));

        }).catch((error) => {
            // console.log("[API] ForgotPasswordLB Failure", error);
            dispatch(ForgotPasswordLB_Failure(error));
        })
    }
}

function ForgotPasswordLB_Success(data) {
    return { type: FORGOT_PASSWORD_LB_SUCCESS, payload: data };
}

function ForgotPasswordLB_Failure(error) {
    return { type: FORGOT_PASSWORD_LB_FAILURE, payload: error };
}

// ForgotPasswordLB ===========================================================================================================

// ChangePasswordLB ===========================================================================================================

export function ChangePasswordLB(_fileterjson = {}) {
    // console.log("[API] ChangePasswordLB :",_fileterjson)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.changePasswordLB}`,
            method: 'POST',
            headers: CONFIG.header,
            params: { access_token: `${CONFIG.LB_token}` },
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] ChangePasswordLB : ", response);
            //localStorage.setItem('BookingList', response.data.result)
            dispatch(ChangePasswordLB_Success(response.data.result));

        }).catch((error) => {
            // console.log("[API] ChangePasswordLB Failure", error);
            dispatch(ChangePasswordLB_Failure(error));
        })
    }
}

function ChangePasswordLB_Success(data) {
    return { type: CHANGE_PASSWORD_LB_SUCCESS, payload: data };
}

function ChangePasswordLB_Failure(error) {
    return { type: CHANGE_PASSWORD_LB_FAILURE, payload: error };
}

// ChangePasswordLB ===========================================================================================================