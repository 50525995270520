import React, { Component } from 'react'
import { connect } from 'react-redux';
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';
import AddIcon from '@material-ui/icons/Add';
import { blue } from '@material-ui/core/colors';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import Language from '../../constant/Language';

import DialogPanel from '../../componets/DialogPanel.jsx';
import EmptyRows from '../../componets/EmptyRow.jsx';

import * as ZoneActions from '../../actions/Actions_ZoneManager';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import { Province } from '../../constant/JSON/Province';
import { Districts } from '../../constant/JSON/District';
import CONFIG from '../../config';

const { BorderColorIcon, } = Icons;
const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'name', isOrder: true, },
    { label: 'STATUS', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100 };
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _wording = { header: 'Zone Manage' }

let waitingTime = true;
let ZoneList = [];

class ZoneListPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps', nextProps)
        const { ZoneManager } = nextProps;
        if (!!ZoneManager) {
            const { actions, data } = ZoneManager.ZONE_REDUCER;
            switch (actions) {
                case ZoneActions.GET_ZONE_SUCCESS:
                    { SetStateWithTime(this, waitingTime); } break;
                case ZoneActions.GET_ZONE_FAILURE:
                    { SetStateWithTime(this, waitingTime); } break;
            }
        }

    }

    render() {
        const { classes, CreatePanel, EditInfo, ViewInfo } = this.props;
        const { isLoading, msgError, dialogType } = this.state;

        const { datas } = this.props.ZoneList;
        ZoneList = datas || [];
        const is_zone = (ZoneList.length > 0);

        return (
            <React.Fragment>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px', width: '100%' }} >
                                    <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ textAlign: 'center' }} onClick={() => CreatePanel()}>
                                        <Button.Icons theme icon={<AddIcon />} tooltip={'Create'} />
                                    </div>
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell colSpan={fildeList.length - 1} style={{ padding: '0px', width: '100%' }} >
                                    <Button className={classes.title_bar} disabled={true}>{_wording.header}</Button>
                                </TableCell>
                                <TableCell colSpan={1} style={{ padding: '0px' }} >
                                    <div style={{ margin: 5, textAlign: 'center' }} onClick={() => CreatePanel()} >
                                        <Button variant="fab" aria-label="add" className={classes.cssRoot}>
                                            <AddIcon />
                                        </Button>
                                    </div>
                                </TableCell>
                            </TableRow> */}
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(is_zone) ? ZoneList.map(info => {
                                const { id, name, picture, code, visible } = info;
                                const LanguageZone = path(['language',], this.props.ZoneList);
                                const NAME = path([LanguageZone, 'name'], info);

                                const _backgroundImage = { position: 'relative', boxShadow: 'rgb(170, 169, 169) 0px 0px 1px 1.9px', backgroundRepeat: 'round', width: '40px', height: '40px', borderRadius: '50%', backgroundImage: `url(${CONFIG.host}${path(['thumbnail'], picture)})`, backgroundRepeat: 'round', margin: 5 };

                                return (
                                    <TableRow hover style={{ cursor: 'pointer', width: '100%' }} key={id} >
                                        <TableCell style={{ ..._callStyle, ...{ minWidth: 80, paddingRight: 5 } }} ><div style={_backgroundImage} /></TableCell>
                                        <TableCell style={_callName} onClick={() => { ViewInfo(info) }} >{NAME || '-'}</TableCell>
                                        {/* <TableCell style={_callName} onClick={() => { ViewInfo(info) }} >{locationType || '-'}</TableCell>
                                        <TableCell    style={_callStyle} onClick={() => { ViewInfo(info) }} >{ADDRESS || '-'}</TableCell> */}
                                        <TableCell style={{ ..._callStyle, ...{ color: (visible) ? Colors.success : Colors.error } }} onClick={() => { ViewInfo(info) }} >{visible ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell style={_callStyle}>
                                            <Button.Icons icon={<BorderColorIcon />} tooltip={'Edit'} onClick={() => { EditInfo(info) }} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(is_zone) && GetPagination(this.props.ZoneList, this.onNextPage, this.onChangeLimit, this.onChangeLanguage)}
                </Paper>
            </React.Fragment>
        )
    }

    onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        console.log('handleRequestSort : ', _fildeName, ZoneList)
        ZoneList = (orderBy === 'desc')
            ? ZoneList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : ZoneList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows, _language) => {
        this.setState({ isLoading: true });
        this.props.getZoneList({ page: 0, num_limit: _limitRows, language: _language });
    }

    onNextPage = (_pageNumber, _limit, _language) => {
        this.setState({ isLoading: true });
        this.props.getZoneList({ page: _pageNumber, num_limit: _limit, language: _language });
    }

    onChangeLanguage = (_language) => {
        this.setState({ isLoading: true });
        this.props.getZoneList({ page: 0, num_limit: 10, language: _language });
    }
}

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit, onChangeLanguage) {

    const { total, page, limit, pageSize, language } = _ListCount;
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Language LanguagePage={language} onChangeState={onChangeLanguage} isValue={true} />
            <TablePagination
                component="div"
                count={total || 0}
                rowsPerPage={limit || 25}
                page={page || 0}
                backIconButtonProps={{ 'aria-label': 'Previous Page', }}
                nextIconButtonProps={{ 'aria-label': 'Next Page', }}
                onChangePage={(event, page) => { onNextPage(page, limit, language) }}
                onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value, language) }} />
        </div>
    )

}

const styles = theme => (
    {
        root: { width: 1024, marginTop: theme.spacing.unit * 3, overflowX: 'auto', marginBottom: 10, },
        table: { minWidth: 1000, },
        cssRoot: { color: theme.palette.getContrastText(blue[500]), backgroundColor: blue[500], '&:hover': { backgroundColor: blue[700], }, },
    });

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getZoneList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(ZoneActions.getZoneList(_keyword, _filter));
        }
    }
}

ZoneListPanel.propTypes = { classes: PropTypes.object.isRequired, };

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ZoneListPanel));