import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../App.css';

import List from '@material-ui/core/List';

import DialogPanel from '../componets/DialogPanel.jsx';
import DialogAction from '../componets/DialogAction.jsx';
import { InitWaitingTime, SetStateWithTime } from '../common/SetTime.js';
import { Colors } from '../themes';

import SpecialListPanel from '../containers/Commission/SpecialListPanel';
import SpecialCard from '../containers/Commission/SpecialCard';
import * as CommissionsActions from '../actions/Actions_CommissionsManager';
import * as PackageAction from '../actions/Actions_PackageManager';
import * as SellerActions from '../actions/Actions_SellerManager.js';

let waitingTime = true;
const _height = (window.innerHeight - 130);
let SpecialList = [];
let PackageInfoList = [];
let SellerList = [];
let SellerTypeList = [];

class SpecialManage extends Component {
    state = {
        isLoading: true, msgError: undefined, dialogType: undefined,
        // isView: false, isEdit: false, isCreate: false,
        isPanel: false
    };

    constructor(props) {
        super(props);
        this.state = {
            SellerInfo: undefined
        }
        this.props.getAPIPackage();
        this.props.getAPISpecialList({ num_limit: 25 });
        this.props.getAPISellerList({ num_limit: 25 });
        this.props.getAPISellerTypeList();
    }

    componentWillReceiveProps(nextProps) {
        const { CommissionsManager, PackageManager, SellerManager } = nextProps;
        if (!!CommissionsManager) {
            const { actions, data } = CommissionsManager.COMMISSIONS_REDUCER;
            switch (actions) {
                case CommissionsActions.GET_SPECIAL_COMMISSIONS_SUCCESS:
                    {
                        SpecialList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case CommissionsActions.GET_SPECIAL_COMMISSIONS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }
        if (!!PackageManager) {
            const { actions, data } = PackageManager.PACKAGE_REDUCER;
            switch (actions) {
                case PackageAction.GET_PACKAGE_SUCCESS:
                    {
                        PackageInfoList = data
                        SetStateWithTime(this, waitingTime);
                    } break;
                case PackageAction.GET_PACKAGE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break
            }
        }
        if (!!SellerManager) {
            const { actions, data } = SellerManager.SELLER_REDUCER;
            switch (actions) {
                case SellerActions.GET_SELLER_TYPE_SUCCESS:
                    {
                        SellerTypeList = data.result.datas;
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_TYPE_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
                case SellerActions.GET_SELLER_SUCCESS:
                    {
                        SellerList = data.result;
                        SetStateWithTime(this, waitingTime);
                    } break;

                case SellerActions.GET_SELLER_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {
        const { isLoading, isPanel } = this.state;
        return (
            <div className="App">
                {(isPanel) && <DialogAction Active={isPanel} OnClose={this.onClosePanel} AcrionCom={this.SpecialListPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}> {this.contentPanel()} </div>
                </List>
            </div>
        );
    }

    contentPanel() {
        return (
            <div style={{ height: '600px', width: '100%', maxWidth: '1024px', }}>
                <SpecialListPanel
                    SellerTypeList={SellerTypeList}
                    packageInfoList={PackageInfoList}
                    specialList={SpecialList}
                    sellerList={SellerList}
                    onClickHandle={this.onClickHandle()}
                />
            </div>
        )
    }

    onChangeLanguage = (_language) => {
        const { page } = SpecialList;
        this.props.getAPISpecialList({ page: page, num_limit: 10, language: _language });
    }
    SpecialListPanel = () => {
        return (
            <SpecialCard
                SellerTypeList={SellerTypeList}
                SellerInfo={this.state.SellerInfo}
                onChangeLanguage={this.onChangeLanguage}
                specialList={SpecialList}
                packageInfoList={PackageInfoList}
                sellerList={SellerList}
                onClosePanel={this.onClosePanel} />
        )
    }
    onClickHandle = () => (SellerInfo) => { this.setState({ isPanel: true, SellerInfo }) }
    onClosePanel = () => (this.setState({ isPanel: false }));

}

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISellerTypeList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerTypeList(_keyword, _filter));
        },
        getAPISpecialList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(CommissionsActions.getAPISpecialList(_keyword, _filter));
        },
        getAPIPackage: (_keyword) => {
            InitWaitingTime();
            dispatch(PackageAction.getAPIPackage(_keyword));
        },
        getAPISellerList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SellerActions.getSellerList(_keyword, _filter));
        },

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SpecialManage);
