export const constraints = {
    namePromotion: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    promotionCode: {
        length: {
            minimum: 5,
            message: "must be at least 5 characters"
        },
    },
    description: {
        presence: {
            allowEmpty: false,
            message: "can't be blank",
        },
    },
    // limit: {
    //     presence: {
    //         allowEmpty: false,
    //         message: "can't be blank",
    //     },
    // },
    // discountValue: {
    //     presence: {
    //         allowEmpty: false,
    //         message: "Please Select type"
    //     }
    // },
    startDate: {
        presence: {
            allowEmpty: false,
            message: "Please Select type"
        }
    },
    endDate: {
        presence: {
            allowEmpty: false,
            message: "Please Select type"
        }
    },
};
export const input = [
    {
        fieldName: 'namePromotion',
    },
    {
        fieldName: 'promotionCode',
    },
    {
        fieldName: 'description',
    },
    // {
    //     fieldName: 'limit',
    // },
    // {
    //     fieldName: 'discountValue',
    // },
    {
        fieldName: 'startDate',
    },
    {
        fieldName: 'endDate',
    },
];