import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';

export const GET_ADMIN_REPORTS_SUCCESS = 'GET_ADMIN_REPORTS_SUCCESS';
export const GET_ADMIN_REPORTS_FAILURE = 'GET_ADMIN_REPORTS_FAILURE';

export const GET_SUPPLIER_REPORTS_SUCCESS = 'GET_SUPPLIER_REPORTS_SUCCESS';
export const GET_SUPPLIER_REPORTS_FAILURE = 'GET_SUPPLIER_REPORTS_FAILURE';

export const GET_AGENT_REPORTS_SUCCESS = 'GET_AGENT_REPORTS_SUCCESS';
export const GET_AGENT_REPORTS_FAILURE = 'GET_AGENT_REPORTS_FAILURE';

export const GET_SELLER_REPORTS_SUCCESS = 'GET_SELLER_REPORTS_SUCCESS';
export const GET_SELLER_REPORTS_FAILURE = 'GET_SELLER_REPORTS_FAILURE';

export const GET_CUSTOMER_REPORTS_SUCCESS = 'GET_CUSTOMER_REPORTS_SUCCESS';
export const GET_CUSTOMER_REPORTS_FAILURE = 'GET_CUSTOMER_REPORTS_FAILURE';

export const CREATE_REPORTS_SUCCESS = 'CREATE_REPORTS_SUCCESS';
export const CREATE_REPORTS_FAILURE = 'CREATE_REPORTS_FAILURE';

export const UPDATE_REPORTS_SUCCESS = 'UPDATE_REPORTS_SUCCESS';
export const UPDATE_REPORTS_FAILURE = 'UPDATE_REPORTS_FAILURE';

// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }
const _header = { 'Content-Type': 'application/json', Authorization: `Bearer ${CONFIG.EP_token}`, 'Cache-Control': ['no-cache', 'no-store', 'must-revalidate',] }

// setAPIState =============================================================================================================================

// getAdminRepost ============================================================================================================================

export function getAdminRepost(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getAdminRepost}`,
            method: 'GET',
            headers: _header,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getAdminRepost : ", response.data);
            dispatch(getAdminRepost_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAdminRepost Failure", error);
            dispatch(getAdminRepost_Failure(error));
        })
    }
}

function getAdminRepost_Success(data) {
    return { type: GET_ADMIN_REPORTS_SUCCESS, payload: data };
}

function getAdminRepost_Failure(error) {
    return { type: GET_ADMIN_REPORTS_FAILURE, payload: error };
}

// getSupplierRepost ============================================================================================================================

export function getSupplierRepost(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getSupplierRepost}`,
            method: 'GET',
            headers: _header,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getSupplierRepost : ", response.data);
            dispatch(getSupplierRepost_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSupplierRepost Failure", error);
            dispatch(getSupplierRepost_Failure(error));
        })
    }
}

function getSupplierRepost_Success(data) {
    return { type: GET_SUPPLIER_REPORTS_SUCCESS, payload: data };
}

function getSupplierRepost_Failure(error) {
    return { type: GET_SUPPLIER_REPORTS_FAILURE, payload: error };
}

// getAgentRepost ============================================================================================================================

export function getAgentRepost(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getAgentRepost}`,
            method: 'GET',
            headers: _header,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getAgentRepost : ", response.data);
            dispatch(getAgentRepost_Success(response.data));

        }).catch((error) => {
            console.log("[API] getAgentRepost Failure", error);
            dispatch(getAgentRepost_Failure(error));
        })
    }
}

function getAgentRepost_Success(data) {
    return { type: GET_AGENT_REPORTS_SUCCESS, payload: data };
}

function getAgentRepost_Failure(error) {
    return { type: GET_AGENT_REPORTS_FAILURE, payload: error };
}

// getSellerRepost ============================================================================================================================

export function getSellerRepost(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getSellerRepost}`,
            method: 'GET',
            headers: _header,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] getSellerRepost : ", response.data);
            dispatch(getSellerRepost_Success(response.data));

        }).catch((error) => {
            console.log("[API] getSellerRepost Failure", error);
            dispatch(getSellerRepost_Failure(error));
        })
    }
}

function getSellerRepost_Success(data) {
    return { type: GET_SELLER_REPORTS_SUCCESS, payload: data };
}

function getSellerRepost_Failure(error) {
    return { type: GET_SELLER_REPORTS_FAILURE, payload: error };
}

//  getCustomerRepost ============================================================================================================================

export function getCustomerRepost(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.getCustomerRepost}`,
            method: 'GET',
            headers: _header,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API]  getCustomerRepost : ", response.data);
            dispatch(getCustomerRepost_Success(response.data));

        }).catch((error) => {
            console.log("[API]  getCustomerRepost Failure", error);
            dispatch(getCustomerRepost_Failure(error));
        })
    }
}

function getCustomerRepost_Success(data) {
    return { type: GET_CUSTOMER_REPORTS_SUCCESS, payload: data };
}

function getCustomerRepost_Failure(error) {
    return { type: GET_CUSTOMER_REPORTS_FAILURE, payload: error };
}

// getReportsList ============================================================================================================================

export function getReportsList(_fileterjson = {}) {

    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getReportsList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] ReportsList : ", response.data);
            dispatch(getReportsList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getProductsList Failure", error);
            dispatch(getReportsList_Failure(error));
        })
    }
}

function getReportsList_Success(data) {
    return { type: GET_REPORTS_SUCCESS, payload: data };
}

function getReportsList_Failure(error) {
    return { type: GET_REPORTS_FAILURE, payload: error };
}

// CreateReports =========================================================================================================================


export function APICreateReports(confirmInfo) {
    return dispatch => {
        const request = axios({
            url: `${CONFIG.host}${CONFIG.api.createReports}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateReports : ", response.data);
            dispatch(APICreateReports_Success(response.data));

        }).catch((error) => {
            console.log("APICreateReports fail", error);
            dispatch(APICreateReports_Failure(error));
        })
    }
}

function APICreateReports_Success(data) {
    return { type: CREATE_REPORTS_SUCCESS, payload: data };
}

function APICreateReports_Failure(error) {
    return { type: CREATE_REPORTS_FAILURE, payload: error };
}
