
const Structure = [
    [{
        title_TH: "---", title_EN: "Refund Reasons", title_CN: "---",
        type: "select",
        width: '80%',
        require: true,
        stateName: "refundPolicyId",
        options: [],
    }, {
        title_TH: "---", title_EN: "Refund Type", title_CN: "---",
        type: "select",
        margin: '0px 0px 0px 15px',
        width: '20%',
        require: false,
        stateName: "refundType",
        options: [{
            value: 'offline', label: 'Offline',
        }, {
            value: 'online', label: 'Online'
        }],
    }],
    [{
        title_TH: "---", title_EN: "Other", title_CN: "---",
        type: "text-multiline",
        stateName: "description",
        require: true,
    }],
    [{
        type: 'additional',
    }],
    [{
        title_EN: "Accept Refund Policy", title_TH: "", title_CN: "",
        type: "checkbox",
        require: false,
        stateName: 'isAccept',
        items: [
            { value: false, TH: { name: "" }, EN: { name: "Accept Refund Policy" }, CN: { name: "" } },
        ],

    }],
];

const UpdateStatus = [
    [{
        title_EN: "Status Refund", title_TH: "", title_CN: "",
        type: "select",
        require: true,
        stateName: 'status',
        optionsRequest: [
            { value: 'in-process', label: "in-process" },
            { value: 'success', label: "success" },
            { value: 'refuse', label: "refuse" },
        ],
        optionsInProcess: [
            { value: 'success', label: "success" },
            { value: 'refuse', label: "refuse" },
        ],
    }],
    [{
        title_TH: "---", title_EN: "Remark", title_CN: "---",
        type: "text-multiline",
        stateName: "remark",
        require: false,
    }],
];


export default {
    Structure,
    UpdateStatus
} 