import axios from 'axios';
import CONFIG, { LBhost, api, } from '../config';

export const INITIAL_STATE = 'INITIAL_STATE';
export const IDLE_STATE = 'IDLE_STATE';
export const WATING_STATE = 'WATING_STATE';

export const GET_ZONE_SUCCESS = 'GET_ZONE_SUCCESS';
export const GET_ZONE_FAILURE = 'GET_ZONE_FAILURE';

export const CREATE_ZONE_SUCCESS = 'CREATE_ZONE_SUCCESS';
export const CREATE_ZONE_FAILURE = 'CREATE_ZONE_FAILURE';

export const UPDATE_ZONE_SUCCESS = 'UPDATE_ZONE_SUCCESS';
export const UPDATE_ZONE_FAILURE = 'UPDATE_ZONE_FAILURE';



// setAPIState =============================================================================================================================

export function setAPIState() { return { type: IDLE_STATE, payload: null }; }

// setAPIState =============================================================================================================================

// getZoneList =====================================================================================================================

export function getZoneList(_keyword = '', _fileterjson = {}) {

    console.log("[API] getZoneList :", `${CONFIG.LBhost}${CONFIG.api.getZoneList}`)
    return dispatch => {
        // _fileterjson.keyword = _keyword;
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.getZoneList}`,
            method: 'GET',
            headers: CONFIG.LBheader,
            params: _keyword,
            data: _fileterjson,
            dataType: 'json',
        });

        request.then((response) => {
            // console.log("[API] getZoneList : ", response);
            localStorage.setItem('ZoneList', response.data)
            dispatch(getZoneList_Success(response.data));

        }).catch((error) => {
            console.log("[API] getZoneList Failure", error);
            dispatch(getZoneList_Failure(error));
        })
    }
}

function getZoneList_Success(data) {
    return { type: GET_ZONE_SUCCESS, payload: data };
}

function getZoneList_Failure(error) {
    return { type: GET_ZONE_FAILURE, payload: error };
}

// createZone ========================================================================================================================


export function APICreateZone(confirmInfo) {

    console.log('APICreateZone : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.createZone}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APICreateZone : ", response.data);
            dispatch(APICreateZone_Success(response.data));

        }).catch((error) => {
            console.log("APICreateZone Failure", error);
            dispatch(APICreateZone_Failure(error));
        })
    }
}

function APICreateZone_Success(data) {
    return { type: CREATE_ZONE_SUCCESS, payload: data };
}

function APICreateZone_Failure(error) {
    return { type: CREATE_ZONE_FAILURE, payload: error };
}

// updateZone =========================================================================================================================

export function APIUpdateZone(confirmInfo) {
    console.log('APIUpdateZone : ', confirmInfo)
    return dispatch => {
        const request = axios({
            url: `${CONFIG.LBhost}${CONFIG.api.updateZone}`,
            method: 'POST',
            headers: CONFIG.LBheader,
            params: { "where": confirmInfo.id ? { id: confirmInfo.id } : { zoneId: confirmInfo.zoneId } },
            data: confirmInfo,
            dataType: 'json',
        });

        request.then((response) => {
            console.log("[API] APIUpdateZone : ", response.data);
            dispatch(APIUpdateZone_Success(response.data));

        }).catch((error) => {
            console.log("APIUpdateZone Failure", error);
            dispatch(APIUpdateZone_Failure(error));
        })
    }
}

function APIUpdateZone_Success(data) {
    return { type: UPDATE_ZONE_SUCCESS, payload: data };
}

function APIUpdateZone_Failure(error) {
    return { type: UPDATE_ZONE_FAILURE, payload: error };
}
