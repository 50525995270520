import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Paper from '@material-ui/core/Paper';
import { blue } from '@material-ui/core/colors';

// import TextField from '@material-ui/core/TextField';

import EmptyRows from '../../componets/EmptyRow.jsx';

import Avatar from '../../componets/Avatar.jsx';
import EnhancedTableToolbar from '../../componets/EnhancedTableToolbar.jsx'
import { path } from 'ramda';
import CONFIG from '../../config';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import SearchPanel from '../../componets/SearchPanel.jsx';

import { Family, Fonts, Colors, Icons, } from '../../themes';

import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { Roles } from '../../constant/JSON/Roles.js'
import Button from '../../constant/Button';

import WithDrawPanel from './WithDrawPanel.jsx'

import * as SupplierActions from '../../actions/Actions_SupplierManager.js';
import * as AgentActions from '../../actions/Actions_AgentManager.js';
import { NumberFormats } from '../../common/SetNumberFormat';

const { DoneIcon, CloseIcon, SendIcon } = Icons;
const fildeList = [
    { label: '', name: '', isOrder: false, },
    { label: 'NAME', name: 'name', isOrder: true, },
    { label: 'WALLET', name: 'wallet', isOrder: true, },
    { label: 'BANK ', name: '', isOrder: false, },
    { label: 'ACCOUNT NO.', name: '', isOrder: true, },
    { label: 'VERIFIED', name: '', isOrder: false, },
    { label: 'STATUS', name: '', isOrder: false, },
    { label: '', name: '', isOrder: false, }];

const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '10px', minWidth: 70 };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100 };
const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _wording = { header: 'Supplier Manage' }

let SupplierList = [];
let waitingTime = true;
let WithdrawInfo;

class AccoutingSupplierListPanel extends Component {

    state = { isLoading: false, isCreate: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, searchActive: false }

    constructor(props) {
        super(props);

        // this.DisplaySupplierInfo = this.DisplaySupplierInfo.bind(this);
        // this.props.getAPISupplierList({ key: 'wallet', orderBy: 'desc', num_limit: 10 })
    }

    componentWillReceiveProps(nextProps) {

        const { SupplierManager, AgentManager } = nextProps;

        if (!!SupplierManager) {

            //console.log(`AccoutingSupplierListPanel [componentWillReceiveProps] : `, SupplierManager, );
            const { actions, data } = SupplierManager.SUPPLIER_REDUCER;

            switch (actions) {
                case SupplierActions.GET_SUPPLIER_SUCCESS:
                    {
                        SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SupplierList = data.result;

                        SetStateWithTime(this, waitingTime);
                    } break;
                case SupplierActions.GET_SUPPLIER_FAILURE:
                    {
                        // SupplierManager.SUPPLIER_REDUCER.actions = SupplierActions.WATING_STATE;
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { classes, CreatePanel, EditInfo, onClickHandle, isEmployee, Header, SearchType } = this.props;
        const { DisplaySupplierInfo } = this;

        const is_supplier = ((SupplierList.length === 0) || (SearchType !== 'supplier')) ? false : (SupplierList.datas.length > 0);
        const { isLoading, isCreate, msgError, dialogType, searchActive, } = this.state;
        const _isEmployee = (Boolean(isEmployee));

        return (
            <React.Fragment>
                {(isCreate) && <DialogAction Active={isCreate} OnClose={this.onClosePanel()} AcrionCom={this.TransferMoneyPanel()} />}
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <Paper className={classes.root}>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow style={{ height: '100%' }}>
                                {/* <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                    <EnhancedTableToolbar numSelected={0} />
                                    <Button.Themes TitleText={Header || _wording.header} disabled={_isEmployee}
                                        style={{ width: '100%' }} buttonStyle={{ marginTop: 0 }}
                                        onClick={() => this.setState({ searchActive: !searchActive })} />
                                </TableCell> */}
                            </TableRow>
                            {(!_isEmployee) && ((searchActive) &&
                                (<TableRow>
                                    <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                                        <SearchPanel searchActive={searchActive} SearchType={SearchType} isAccounting={true} />
                                    </TableCell>
                                </TableRow>))}
                        </TableHead>
                        <TableHead>
                            <TableRow>
                                {fildeList.map((_filde, index) => {
                                    return (
                                        <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                                            {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                                        </TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(is_supplier) ? SupplierList.datas.map(_info => {
                                const { supplierId, wallet, bank, name, userType, reservation_number, telOffice, logo, visible, approved, officialWebsite } = _info;
                                let bankName = null;
                                let bankLogo = null;
                                const { BookBankList } = this.props;
                                let Language = path(['language'], BookBankList)
                                Array.isArray(BookBankList.datas) && BookBankList.datas.find((val) => (val.bankSettingId === bank.bankId) ? bankName = val[Language].name : null)
                                Array.isArray(BookBankList.datas) && BookBankList.datas.find((val) => (val.bankSettingId === bank.bankId) ? bankLogo = val.logo.thumbnail : null)

                                return (
                                    <TableRow hover style={{ cursor: 'pointer' }} key={supplierId} >
                                        <TableCell style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >
                                            <div style={this.getBackgroundImage(_isEmployee, logo.thumbnail)} />
                                        </TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplaySupplierInfo(_info) }} >{name}</TableCell>
                                        <TableCell className={classes.text_info} style={{ ..._callStyle, textAlign: 'left' }} onClick={() => { DisplaySupplierInfo(_info) }} >{NumberFormats(wallet || 0)}</TableCell>
                                        <TableCell style={_callName} onClick={() => { DisplaySupplierInfo(_info) }} >{bankName || '-'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{bank.accountNumber || '-'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle} onClick={() => { DisplaySupplierInfo(_info) }} >{approved ? 'Approved' : 'Pending'}</TableCell>
                                        <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }} onClick={() => { DisplaySupplierInfo(_info) }} >{visible ? 'Active' : 'Inactive'}</TableCell>
                                        <TableCell className={classes.text_info} style={_callStyle}>
                                            <Button.Icons icon={<SendIcon />} tooltip={'Send'} onClick={() => { this.TransferMoneyInfo(_info) }} />
                                        </TableCell>
                                    </TableRow>
                                );
                            }) : <EmptyRows fildeList={fildeList} />}
                        </TableBody>
                    </Table>
                    {(is_supplier) && GetPagination(SupplierList, this.onNextPage, this.onChangeLimit)}
                </Paper>
            </React.Fragment>
        );
    }

    //===============================================================================================================================================================

    TransferMoneyPanel() {
        return (<WithDrawPanel BookBankList={this.props.BookBankList} WithdrawInfo={{ ...WithdrawInfo }} AssigneeType={Roles.Supplier} OnClose={this.onClosePanel()} />)
    }

    TransferMoneyInfo(_info) {

        // console.log('TransferMoneyInfo : ', _info);
        WithdrawInfo = _info;
        this.setState({ isCreate: true })
    }

    DisplaySupplierInfo(_info) {

        console.log('DisplaySupplierInfo : ');
    }

    getBackgroundImage(_isEmployee, logo) {
        return (_isEmployee) ?
            ({ position: 'relative', boxShadow: 'rgb(170, 169, 169) 0px 0px 1px 1.9px', backgroundRepeat: 'round', width: 50, height: 50, borderRadius: '50%', backgroundImage: `url(${CheckImageURL(logo)})`, backgroundRepeat: 'round', margin: 5 }) :
            ({ width: 70, height: 50, backgroundColor: 'gray', backgroundImage: `url(${CheckImageURL(logo)})`, backgroundRepeat: 'round', border: '1px solid #828181' });
    }

    handleRequestSort = (sortFilde) => {

        const { orderBy, field } = sortFilde;
        const _fildeName = field.name;
        // console.log('handleRequestSort : ', _fildeName, SupplierList)

        SupplierList.datas = (orderBy === 'desc')
            ? SupplierList.datas.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
            : SupplierList.datas.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

        this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
    };

    onChangeLimit = (_limitRows) => {
        this.setState({ isLoading: true });
        // console.log('_limitRows', _limitRows);
        this.props.getAPISupplierList({ key: 'wallet', orderBy: 'desc', page: 0, num_limit: _limitRows });
    }

    onNextPage = (_pageNumber, _limit) => {
        this.setState({ isLoading: true });
        this.props.getAPISupplierList({ key: 'wallet', orderBy: 'desc', page: _pageNumber, num_limit: _limit });
    }

    onCloseDialog = () => (this.setState({ isLoading: false, isCreate: false, msgError: undefined, dialogType: undefined }));

    onClosePanel = () => () => (this.setState({ isCreate: false, }));

    //===============================================================================================================================================================
}

const CheckImageURL = (_url) => (Boolean(_url) ? `${CONFIG.host}${_url}` : '');

function GetSortIcon(orderBy, field, handleRequestSort) {
    return (
        <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
            {field.label}
        </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

    const { total, page, limit, pageSize } = _ListCount;

    return (
        <TablePagination
            component="div"
            count={total || 0}
            rowsPerPage={limit || 10}
            page={page || 0}
            backIconButtonProps={{ 'aria-label': 'Previous Page', }}
            nextIconButtonProps={{ 'aria-label': 'Next Page', }}
            onChangePage={(event, page) => { onNextPage(page, limit) }}
            onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const styles = theme => (
    {
        root: {
            width: '100%',
            // marginTop: theme.spacing.unit * 3,
            overflowX: 'auto',
            // marginBottom: 10,
        },
        table: { minWidth: 1000, },
    });

AccoutingSupplierListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        getAPISupplierList: (_keyword, _filter) => {
            InitWaitingTime();
            dispatch(SupplierActions.getSupplierList(_keyword, _filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccoutingSupplierListPanel));
