import React from 'react';
import PropTypes from 'prop-types';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { Family, Size, Colors } from '../themes';

let data = [
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },
    { name: '', OneDayTrip: 0, Transfer: 0, Package: 0 },];


const CMarging = { top: 5, right: 30, left: 20, bottom: 10 }
const maxBarSize = 100;

function SBarChart(props) {

    const { BCWidth, Info } = props;
    const _isInfo = (Array.isArray(Info) && Info.length > 0);
    const _data = setInfo(_isInfo, Info);

    return (
        <BarChart width={BCWidth || 250} height={200} data={_data} margin={CMarging} style={{ fontFamily: Family.roboto, fontSize: Size.chart.label }}  >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {(_isInfo) ? customBar(_data) : defaultBar()}
        </BarChart>);
}

function setInfo(_isInfo, Info) {



    if (_isInfo) {

        if (Info.length > 4) {
            return (Info.map(_info => { _info.name = _info.name.substring(0, 7); return _info }));
        }
        else {
            for (let amount = Info.length; amount <= 4; amount++)
                Info.push({ name: '', OneDayTrip: 0, Transfer: 0, Package: 0 });

            return Info;
        }
    }
    else
        return data;
}

function customBar(_info) {
 // let _list = [];

    // for (const item of _info) {
    //     if (_list.length < 2) {
    //         if (item.OneDayTrip > 0)
    //             _list.push(<Bar key={'OneDayTrip'} dataKey="OneDayTrip" fill="#03A9F4" />)
    //         if (item.Transfer > 0)
    //             _list.push(<Bar key={'Transfer'} dataKey="Transfer" fill="#03A9F4" />)
    //     }
    // }'expand' | 'none' | 'wiggle' | 'silhouette' | 'sign'

    return [<Bar key={'bar_1'} dataKey="OneDayTrip" maxBarSize={maxBarSize} fill={Colors.barChart_1} />,];
}

function defaultBar() {
    return [<Bar key={'bar_1'} dataKey="OneDayTrip" maxBarSize={maxBarSize} fill={Colors.barChart_1} style={{ fontFamily: Family.roboto, fontSize: Size.chart.label }} />,
    <Bar key={'bar_2'} dataKey="Transfer" maxBarSize={maxBarSize} fill={Colors.barChart_2} />,
    <Bar key={'bar_3'} dataKey="Package" maxBarSize={maxBarSize} fill={Colors.barChart_3} />];
}

export default (SBarChart);


// let data = [
//     { name: 'N', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'S', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Nn', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'Ss', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Nm', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'Sm', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Nm', OneDayTrip: 4000, Transfer: 2400, Package: 2400 },
//     { name: 'Sm', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'Sm', OneDayTrip: 3000, Transfer: 1398, Package: 2210 },
//     { name: 'W', OneDayTrip: 2000, Transfer: 9800, Package: 2290 },];
