import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Images, Colors, Family, Icons } from '../../themes';
import { PathName } from '../../constant';

import List from '@material-ui/core/List';

import Button from '../../constant/Button';
import CONFIG from '../../config';

import DialogPanel from '../../componets/DialogPanel.jsx';
import DialogAction from '../../componets/DialogAction.jsx';
import ChangePasswordCard from '../../componets/ChangePasswordCard';
import { InitWaitingTime, SetStateWithTime } from '../../common/SetTime.js';

import * as BookingActions from '../../actions/Actions_BookingManager';
import InProcessListCard from '../../containers/Booking/InProcessListCard.jsx'
import { isMobile } from '../../common/DeviceCheck';

import '../../App.css';
const { HeaderBackground } = Images;
const { PasswordIcon, BorderColorIcon, DoneIcon, CloseIcon } = Icons;
const _isMobile = isMobile()
const _height = (window.innerHeight - 130);
let BookingList = {};
let waitingTime = true;
let isDialogDisplay = false;

class MemberInfo extends Component {

    state = { isLoading: true, isPassword: false }

    constructor(props) {

        super(props)
        isDialogDisplay = (!!this.props.memberInfo);

        const { memberInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        if (!!memberInfo) {
            this.props.getBookingListsWithCustomerID(memberInfo.id);
        }
    }

    componentWillReceiveProps(nextProps) {

        const { BookingManager } = nextProps;
        const { memberInfo } = (isDialogDisplay) ? this.props : this.props.location.state;

        //console.log(`BookingManager [componentWillReceiveProps] : `, BookingManager, );

        if (!!BookingManager) {
            const { actions, data } = BookingManager.BOOKING_REDUCER;

            switch (actions) {
                case BookingActions.GET_LB_BOOKING_LISTS_SUCCESS:
                    {
                        if (!!data)
                            BookingList.bookingList = data.filter((booking) => (booking.userId === memberInfo.id));

                        SetStateWithTime(this, waitingTime);
                    } break;
                case BookingActions.GET_LB_BOOKING_LISTS_FAILURE:
                    {
                        SetStateWithTime(this, waitingTime);
                    } break;
            }
        }
    }

    render() {

        const { memberInfo } = (isDialogDisplay) ? this.props : this.props.location.state;
        const { isLoading } = this.state;

        //console.log(memberInfo);

        return (
            <div className="App">
                {(isLoading) && <DialogPanel Active={isLoading} />}
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: _height }}>
                    <div style={{ display: 'inline-block' }}>
                        {this.contentPanel(memberInfo)}
                    </div>
                </List>
            </div>
        );
    }

    NavbarAcitve = (value) => { console.log('NavbarAcitve : ', value); this.props.history.push(value); };

    contentPanel(memberInfo) {
        // return <div style={{height:'600px',width:'100%',maxWidth:'1024px',}}>
        return (
            <div>
                {this.InfoPanel(memberInfo)}
                {/* <InProcessListCard BookingList={BookingList} BookingDetail={(id) => this.CallBookingDetail(id)} /> */}

            </div>
        )
    }

    CallBookingDetail(_id) {
        console.log('CallBookingDetail : ', _id)

        this.props.getAPIBookingDetails(_id);
        this.props.history.push({ pathname: PathName.BookingDetail, state: {}, search: _id, hash: '' });
    }


    InfoPanel(memberInfo) {

        console.log('memberInfo : ', memberInfo);
        const { isPassword } = this.state
        const { email, firstName, lastName, tel, isAcceptInformation, adminId, type, active, avatarLink, memberLevelId, memberTypeId, registerDate, id, title, visible, memberType, memberLevel, expireDate } = memberInfo;
        const { classes } = this.props;
        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10, fontFamily: Family.roboto, };

        return (
            <div style={{
                display: _isMobile ? 'block' : 'flex', height: _isMobile ? '100%' : '328px', width: _isMobile ? window.innerWidth : '1020px',
                backgroundColor: Colors.themeDark,
            }} >
                {(isPassword) && <DialogAction Active={isPassword} OnClose={() => this.setState({ isPassword: false })} AcrionCom={<ChangePasswordCard id={adminId} onClosePanel={() => this.setState({ isPassword: false })} />} />}
                < div style={{
                    height: _isMobile ? 'unset' : '300px', width: _isMobile ? 'unset' : '250px',
                    paddingLeft: _isMobile ? 'unset' : '50px', paddingTop: _isMobile ? '15px' : '70px',
                    paddingRight: _isMobile ? 'unset' : '50px', borderradius: '10%', boxshadow: '0  0 5px rgba(0, 0, 0, 0.2)',
                    display: _isMobile ? 'flex' : 'unset', justifyContent: _isMobile ? 'center' : 'unset',
                }} >
                    <img className={classes.avataronline} src={(avatarLink.original) ? (`${CONFIG.host}${avatarLink.original}`) : Images.User.userdefault} />
                </div>
                <div style={{ width: '100%', height: '200px', padding: '15px', textAlign: 'left' }}>
                    <div style={{ textAlign: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                        <Button.Icons themeDark icon={<PasswordIcon />} tooltip={'Change Password'} onClick={() => { this.setState({ isPassword: true }) }} />
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>User Name : </label>
                        <label className={classes.july32017copy}>{`${title || ''} ${firstName}   ${lastName}`}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>User ID : </label>
                        <label className={classes.july32017copy}>{adminId}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Phone number : </label>
                        <label className={classes.july32017copy}>{tel || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Email : </label>
                        <label className={classes.july32017copy}>{email || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Level : </label>
                        <label className={classes.july32017copy}>{memberLevel || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Type : </label>
                        <label className={classes.july32017copy}>{memberType || '-'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Visible : </label>
                        <label className={classes.july32017copy}>{visible ? 'Active' : 'Inactive'}</label>
                    </div>
                    <div style={_styleInfoItem}>
                        <label className={classes.class}>Regiter Type : </label>
                        <label className={classes.july32017copy}>{type || '-'}</label>
                    </div>
                </div>
            </div >
        )
    }
}

function mapStateToProps(state, ownProps) {
    return { ...state };
}

function mapDispatchToProps(dispatch) {
    return {
        getBookingListsWithCustomerID: (memberID) => {
            InitWaitingTime();
            dispatch(BookingActions.get_LB_APIBookingLists(memberID));
        },
        getAPIBookingDetails: (_id) => {
            dispatch(BookingActions.getAPIBookingDetail(_id));
        },
    }
}

const styles = theme => (
    {
        july32017copy: {
            height: '16px',
            opacity: '0.64',
            color: Colors.white,
            fontSize: '12px',
            lineHeight: '16px',
        },
        class: {
            height: '16px',
            width: '110px',
            color: Colors.white,
            fontSize: '12px',
            fontWeight: 'bold',
            lineHeight: '16px',
        },
        betty_pittman: {
            height: '39px',
            color: Colors.white,
            fontFamily: Family.roboto,
            fontSize: '30px',
            lineHeight: '39px',
        },

        avataronline: {
            position: 'relative',
            boxShadow: '0 0 1px 1.9px ',
            backgroundRepeat: 'round',
            borderRadius: '50%',
            marginRight: _isMobile ? 'unset' : '20%',
            width: _isMobile ? (window.innerWidth / 2) - 45 : '200px',
            height: _isMobile ? (window.innerWidth / 2) - 45 : '200px',
        },
    });

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MemberInfo));