import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { SetStateWithTime, } from '../../common/SetTime.js';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Button from '../../constant/Button';

import EmptyRows from '../../componets/EmptyRow.jsx';
import DialogPanel from '../../componets/DialogPanel.jsx';
import CONFIG from '../../config';

import * as PackageAction from '../../actions/Actions_PackageManager.js';
import { Family, Fonts, Colors, Icons, } from '../../themes';
const { DoneIcon, CloseIcon, } = Icons;

const fildeList = [
  { label: '', name: '', isOrder: false, },
  { label: 'PACKAGE NAME', name: 'name_EN', isOrder: true, }, //NAME (EN) 
  { label: 'PROMOTION', name: 'description', isOrder: true, },
  { label: 'TYPE', name: 'name', isOrder: false, },//NAME (TH)
  { label: 'PRICE', name: '', isOrder: false, },
  { label: 'RATING', name: '', isOrder: false, },
  { label: 'STATUS', name: 'active', isOrder: true, },];

const _headCall = { paddingLeft: '25px', paddingRight: '5px', };
const _callName = { textAlign: 'left', paddingLeft: '20px', paddingRight: '5px', minWidth: 100, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', };
const _callStyle = { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', };
const _wording = { header: 'Pack Promotion List.' }

let packageInfoList = [];
let waitingTime = false;

class PackPromotionListPanel extends Component {

  state = { isLoading: false, isCreate: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, }

  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    // console.log('nextProps', nextProps);
    const { PackageManager, } = nextProps;

    if (!!PackageManager) {

      const { actions, data } = PackageManager.PACKAGE_REDUCER;

      switch (actions) {
        case PackageAction.GET_PACKAGE_LISTS_SUCCESS:
          {
            packageInfoList = data.result;
            SetStateWithTime(this, waitingTime);
          } break;
        case PackageAction.GET_PACKAGE_LISTS_FAILURE:
          { SetStateWithTime(this, waitingTime); } break;
      }
    }
  }

  render() {

    const { classes, onClickHandle, } = this.props;
    packageInfoList = (!!this.props.packageInfoList) ? (this.props.packageInfoList.packageList || []) : [];
    const isPromotion = (packageInfoList.length > 0);

    const { isLoading, msgError, dialogType } = this.state;
    // console.log('PackageListPanel : ', packageInfoList);

    return (
      <React.Fragment>
        {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow style={{ height: '100%' }}>
                <TableCell colSpan={fildeList.length} style={{ padding: '0px' }} >
                  <Button.Themes TitleText={_wording.header} style={{ width: '100%' }} buttonStyle={{ marginTop: 0, padding: 15 }} />
                </TableCell>
              </TableRow>
              <TableRow>
                {fildeList.map((_filde, index) => {
                  return (
                    <TableCell key={index} style={(index === 1) ? _headCall : _callStyle}>
                      {GetSortIcon(this.state.orderBy, _filde, this.handleRequestSort)}
                    </TableCell>)
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isPromotion) ? GetBookingRows(packageInfoList, onClickHandle, classes) : <EmptyRows fildeList={fildeList} />}
            </TableBody>
          </Table>
          {(isPromotion) && GetPagination(this.props.packageInfoList, this.onNextPage, this.onChangeLimit)}
        </Paper>
      </React.Fragment>
    );
  }

  //===============================================================================================================================================================

  handleRequestSort = (sortFilde) => {

    const { orderBy, field } = sortFilde;
    const _fildeName = field.name;
    // const { packageInfoList } = this.props;
    //console.log('handleRequestSort : ', _fildeName, packageInfoList)

    packageInfoList = (orderBy === 'desc')
      ? packageInfoList.sort((a, b) => (b[_fildeName] < a[_fildeName] ? -1 : 1))
      : packageInfoList.sort((a, b) => (a[_fildeName] < b[_fildeName] ? -1 : 1));

    this.setState({ orderBy: ((orderBy === 'asc') ? 'desc' : 'asc'), sortFilde: _fildeName });
  };

  onChangeLimit = (_limitRows) => {
    this.setState({ isLoading: true });
    this.props.getAPIpackageInfoList({ page: 0, limit: _limitRows, isPromotion: true, });
  }

  onNextPage = (_pageNumber, _limit) => {
    this.setState({ isLoading: true });
    this.props.getAPIpackageInfoList({ page: _pageNumber, limit: _limit, isPromotion: true, });
  }

  onCloseDialog = () => (this.setState({ isLoading: false, msgError: undefined, dialogType: undefined }));

  //===============================================================================================================================================================
}

function GetBookingRows(packageInfoList, onClickHandle, classes) {

  return packageInfoList.map(_package => {
    const { packageId, packageInfo, type, isPromotion, rating, visible } = _package;
    const { name, price = { adult: 0 }, image } = packageInfo;
    const _backgroundImage = { width: '70px', height: '50px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${checkURL(image)})`, backgroundRepeat: 'round' };

    return (
      <TableRow hover style={{ cursor: 'pointer' }} key={packageId} onClick={() => onClickHandle(packageId)} >
        <TableCell style={{ ..._callStyle, ...{ minWidth: 80, } }} ><div style={_backgroundImage} /></TableCell>
        <TableCell style={_callName}>{name || ''}</TableCell>
        <TableCell style={{ ..._callStyle, color: isPromotion ? Colors.success : Colors.error }} >{isPromotion ? <DoneIcon /> : <CloseIcon />}</TableCell>
        <TableCell className={classes.text_info} >{type}</TableCell>
        <TableCell className={classes.text_info} >{price.adult}</TableCell>
        <TableCell className={classes.text_info} >{rating.point}</TableCell>
        <TableCell style={{ ..._callStyle, color: visible ? Colors.success : Colors.error }}>{visible ? 'Active' : 'Inactive'}</TableCell>
      </TableRow>
    );
  });
}

function GetSortIcon(orderBy, field, handleRequestSort) {
  return (
    <TableSortLabel active={field.isOrder} direction={orderBy} onClick={() => { (field.isOrder) && handleRequestSort({ orderBy, field }) }}>
      {field.label}
    </TableSortLabel>)
}

function GetPagination(_ListCount, onNextPage, onChangeLimit) {

  const { total, page, limit, } = _ListCount;

  return (
    <TablePagination
      component="div"
      count={total || 0}
      rowsPerPage={limit || 25}
      page={page || 0}
      backIconButtonProps={{ 'aria-label': 'Previous Page', }}
      nextIconButtonProps={{ 'aria-label': 'Next Page', }}
      onChangePage={(event, page) => { onNextPage(page, limit) }}
      onChangeRowsPerPage={(event) => { onChangeLimit(event.target.value) }} />)
}

const checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');

const styles = theme => (
  {
    root: { width: '100%', marginTop: theme.spacing.unit * 3, overflowX: 'auto', },
    table: { minWidth: 1024, },
    text_info: { textAlign: 'center', paddingLeft: '5px', paddingRight: '5px', fontFamily: Family.roboto, },
  });

PackPromotionListPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return {
    getAPIpackageInfoList: (_fliter) => {
      // InitWaitingTime();
      dispatch(PackageAction.getAPIPackageLists(_fliter));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PackPromotionListPanel));