import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import CONFIG from '../../config';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Colors, Family } from '../../themes';

class SelectTag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
        }
    }
    render() {
        const { classes, onSavePanelPackage, promotionCodeInfo, handleChange,
            handleToggle, onReset, SupplierList, SellerList, AgentList } = this.props;
        let Display = null;
        switch (promotionCodeInfo.codeType) {
            case 'seller':
            case 'blogger':
                {
                    Display = SellerList.filter(each => new RegExp(this.state.searchText, "i").test(each.name));
                } break;
            case 'supplier':
                {
                    Display = SupplierList.filter(each => new RegExp(this.state.searchText, "i").test(each.name));

                } break;
            case 'agent':
                {
                    Display = AgentList.filter(each => new RegExp(this.state.searchText, "i").test(each.name));

                } break;
            default:
                {
                    Display = []
                } break;
        }
        return (
            <div style={{ display: 'inline-block', width: '100%', maxWidth: 800 }}>
                <div style={{ paddingBottom: '10px', }}>
                    <TextField id='search' margin="normal"
                        value={this.state.searchText}
                        onChange={(e) => this.setState({ searchText: e.target.value })}
                        placeholder={'Please Insert package'}
                        className={classes.textField}
                        InputProps={{ disableUnderline: false, classes: { input: classes.selectInput, }, }}
                        InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} />
                </div>
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: '400px', paddingBottom: '17px', marginBottom: '15px' }}>
                    {
                        Display.map((value, index) => {
                            const _backgroundImage = { width: '60px', height: '40px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${value.logo.thumbnail})`, backgroundRepeat: 'round' };
                            return (
                                <ListItem key={index} style={{ paddingLeft: '15px', border: '1px solid #9B9B9B', marginTop: '10px', }} dense button
                                    onClick={promotionCodeInfo.codeType === 'supplier' ? handleToggle(value) : handleChange(value)}>
                                    <Checkbox color="primary"
                                        checked={promotionCodeInfo.codeType === 'supplier' ? promotionCodeInfo.tag.indexOf(value.supplierId) !== -1 :
                                            promotionCodeInfo.codeType === 'seller' || promotionCodeInfo.codeType === 'blogger' ? promotionCodeInfo.tag[0] === value.sellerId :
                                                promotionCodeInfo.codeType === 'agent' ? promotionCodeInfo.tag[0] === value.agentId : null}
                                        tabIndex={-1} disableRipple />
                                    <div style={_backgroundImage} />
                                    <ListItemText style={{ fontSize: '16px', }} primary={`${value.name}`} />
                                </ListItem>
                            )
                        }
                        )
                    }
                </List>
                <div style={{ textAlign: 'center', fontSize: '16px', display: 'flex', }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_OK}
                        disabled={(!promotionCodeInfo.tag.length > 0) && true}
                        onClick={() => onSavePanelPackage()}
                    />
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingLeft: '15px', }}
                        onClick={() => onReset()}
                    />
                </div>
            </div>
        );
    }
}
SelectTag.propTypes = { classes: PropTypes.object.isRequired, };

const styles = theme => (
    {
        textField: {
            width: '100%',
            marginTop: 5,
            marginBottom: 5
        },
        textFormLabel: {
            fontSize: 18,
            marginTop: 0,
            fontFamily: Family.roboto,
        },
        selectInput: {
            marginTop: 5,
            padding: 10,
            paddingRight: '20px',
            color: Colors.grey,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 16,
            fontFamily: Family.roboto,
        },
    });

export default (withStyles(styles)(SelectTag));
