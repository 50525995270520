
import * as ConditionActions from '../actions/Actions_Conditions';

const INITIAL_STATE = { CONDITION_REDUCER: { actions: ConditionActions.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: ConditionActions.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case ConditionActions.INITIAL_STATE:
            {
                return state;
            }
        case ConditionActions.IDLE_STATE:
            {
                //console.log("[ Condition ] : IDLE_STATE : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.IDLE_STATE, data: payload, error: _error } };
            }
        case ConditionActions.GET_CONDITIONS_SUCCESS:
            {
                // console.log("[ Condition ] : GET_CONDITIONS_SUCCESS : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.GET_CONDITIONS_SUCCESS, data: payload, error: _error } };
            }
        case ConditionActions.GET_CONDITIONS_FAILURE:
            {
                console.log("[ Condition ] : GET_CONDITIONS_FAILURE : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.GET_CONDITIONS_FAILURE, data: payload, error: _error } };
            }
        case ConditionActions.CREATE_CONDITIONS_SUCCESS:
            {
                // console.log("[ Condition ] : CREATE_CONDITIONS_SUCCESS : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.CREATE_CONDITIONS_SUCCESS, data: payload, error: _error } };
            }
        case ConditionActions.CREATE_CONDITIONS_FAILURE:
            {
                console.log("[ Condition ] : CREATE_CONDITIONS_FAILURE : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.CREATE_CONDITIONS_FAILURE, data: payload, error: _error } };
            }
        case ConditionActions.UPDATE_CONDITIONS_SUCCESS:
            {
                console.log("[ Condition ] : UPDATE_CONDITIONS_SUCCESS : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.UPDATE_CONDITIONS_SUCCESS, data: payload, error: _error } };
            }
        case ConditionActions.UPDATE_CONDITIONS_FAILURE:
            {
                console.log("[ Condition ] : UPDATE_CONDITIONS_FAILURE : ", payload);
                return { ...state, CONDITION_REDUCER: { actions: ConditionActions.UPDATE_CONDITIONS_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                // console.log("[ Condition ]  default :", state);
                return state;
            }
    }
}
