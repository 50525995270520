import React, { Component } from 'react'
import { path } from 'ramda';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FormTypes from '../../common/FormTypes';
import * as format from '../../common/setFormat';

class FormAdditional extends Component {
    render() {
        const { FormLayout, classes } = this.props;
        return (
            <React.Fragment>
                {
                    FormLayout.map((row, i) => (
                        <div key={i} className={classes.row}>{row.map((each, index) => {
                            return (
                                <div className={classes.each} key={index} >
                                    {this.SetItemWithStyle(each)}
                                </div>
                            )
                        })}</div>
                    ))
                }
            </React.Fragment>
        )
    }
    SetItemWithStyle(each) {
        const { StateInfo, error, Active, disabled } = this.props;
        // console.log('StateInfo', StateInfo)
        const ferries = StateInfo.conveyanceType === 'ferries';
        const join = ferries && StateInfo.transferType === 'join';
        const bags = each.stateName === 'bags';
        switch (each.type) {
            case "number": {
                return (
                    <FormTypes.TextFormat
                        InputFormat={format.NumberFormatCustom}
                        error={!!path([each.stateName], error)}
                        disabled={(join && each.stateName === 'passengers') ? true : (ferries && bags ? true : false) || disabled}
                        style={{ marginRight: '10px' }}
                        Require={(join && each.stateName === 'passengers') ? false : (Active ? false : each.require)}
                        TitleText={each.title_EN}
                        value={ferries && bags ? '0' : path([each.stateName], StateInfo)}
                        onChange={(e) => this.onChangeText(each.stateName, e.target.value)} />
                )
            }
            case "select": {
                return (
                    <FormTypes.Select
                        error={!!path([each.stateName], error)}
                        disabled={(ferries && bags ? true : false) || disabled}
                        style={{ marginLeft: each.stateName === 'transferIn' && '10px' }}
                        Require={Active ? false : each.require}
                        TitleText={each.title_EN}
                        value={path([each.stateName], StateInfo)}
                        onChange={(e) => this.onChangeText(each.stateName, e.target.value)}
                        options={Array.isArray(each.options) && each.options.map((option, i) => { return (<option key={i} value={option.value}> {option.label_EN} </option>) })} />
                )
            }
        }
    }

    onChangeText = (fieldName, value) => { return (this.props.onChange(fieldName, value)) }
}
const styles = theme => (
    {
        row: { display: 'flex' },
        each: { display: 'flex', width: '100%', },
    }
)
FormAdditional.propTypes = { classes: PropTypes.object.isRequired, };

export default withStyles(styles)(FormAdditional);