import React, { Component } from 'react';
import styled from 'styled-components';
import { path } from 'ramda'

import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonText from '../constant/Button/ButtonText';
import Button from '../constant/Button';
import CONFIG from '../config';
import FormTypes from '../common/FormTypes';


import { Colors, Fonts, Family } from '../themes';


class PackagePanel extends Component {
    constructor(props) {
        super(props);
        this.state = { searchText: '' }

    }

    render() {
        const { searchText } = this.state;
        const { handleToggle, onClosePanel, packageId, PackageList } = this.props;

        let DisplayList = [];
        if (Array.isArray(PackageList) && PackageList.length > 0) {
            DisplayList = PackageList.filter(each => new RegExp(this.state.searchText, "i").test(each.packageInfo.name))
        }
        return (
            <Contianer>
                <SearchContianer>
                    <FormTypes.TextFormat
                        TitleText={'Search'}
                        value={searchText}
                        onChange={(e) => this.setState({ searchText: e.target.value })} />
                </SearchContianer>
                <ContentContianer>
                    {
                        (DisplayList.length > 0) ?
                            DisplayList.map((value, index) => {
                                return (
                                    <ListItem key={index} style={{ paddingLeft: '15px', border: '1px solid #9B9B9B', marginTop: '10px', }} dense button
                                        onClick={() => handleToggle(value)} >
                                        <Checkbox color="primary"
                                            checked={packageId.indexOf(value.packageId) !== -1}
                                            tabIndex={-1} disableRipple />
                                        {getBackgroundImage(value.packageInfo.image.thumbnail)}
                                        <ListItemText style={{paddingLeft:10}} primary={`${value.packageInfo.name}`} />
                                    </ListItem>
                                )
                            })
                            :
                            <EmptyContianer>
                                <ListItemText primary={'Not Found'} />
                            </EmptyContianer>
                    }
                </ContentContianer>
                <ButtonContianer>
                    <Button.Themes TitleText={ButtonText.BUTTON_OK} onClick={() => onClosePanel()} />
                </ButtonContianer>
            </Contianer>
        )
    }
}

function getBackgroundImage(_image) {
    const _backgroundImage = {
        width: '70px', height: '50px', backgroundColor: Colors.defaultImage,
        backgroundImage: `url(${CheckImageURL(_image)})`, backgroundRepeat: 'round'
    };
    return (_image) ?
        <div style={_backgroundImage} /> : <div />
}

const CheckImageURL = (_url) => (Boolean(_url) ? `${CONFIG.host}${_url}` : '');

export default PackagePanel;

const Contianer = styled.div`
        padding: 20px;
        display: inline-block;
        width: 100%;`;
const SearchContianer = styled.div`
        padding-bottom:10px`;
const ContentContianer = styled.div`
        width: 100% 
        position: relative
        overflow: auto
        height:400px
        padding-bottom: 17px
        margin-bottom: 15px;`;
const ButtonContianer = styled.div`
        display: flex;
        justify-content: flex-end;`;
const EmptyContianer = styled.div`
        text-align: center;
        padding: 20px;`;
