import React, { Component } from 'react';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import CONFIG from '../../config';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Colors, Fonts, Family } from '../../themes';


class PackageCard extends Component {
    constructor(props) {
        super(props);
        this.state = { searchText: '' }
    }
    render() {
        const { PackageAll, classes, SpecialInfo, onSavePanelPackage, handleToggle, onSavePanel, onCancelPanel, SpecialAll, SellerId } = this.props;
        let PackageList = PackageAll && Array.isArray(PackageAll.package) ? PackageAll.package : []
        let SpecialList = SpecialAll && Array.isArray(SpecialAll.datas) ? SpecialAll.datas : []

        let InfoList = Array.isArray(PackageList) && PackageList.filter((_package) => !(SpecialList.some((_special) => (_special.packageId === _package.packageId) && (_special.affiliateId === SellerId))))
        let Display_Package = null;

        // console.log('InfoList', InfoList)
        // console.log('SpecialInfo', SpecialInfo)
        // console.log('PackageList', PackageList)
        // console.log('SpecialList', SpecialList)
        if (Array.isArray(InfoList) && InfoList.length > 0) {
            Display_Package = InfoList.filter(each => new RegExp(this.state.searchText, "i").test(each.packageInfo.name))
        }
        return (
            <div style={{ display: 'inline-block', width: '100%', maxWidth: 800 }}>
                <div style={{ paddingBottom: '10px', }}>
                    <TextField id='search' margin="normal"
                        value={this.state.searchText}
                        onChange={(e) => this.setState({ searchText: e.target.value })}
                        placeholder={'Please Insert package'}
                        className={classes.textField}
                        InputProps={{ disableUnderline: false, classes: { input: classes.selectInput, }, }}
                        InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} />
                </div>
                <List style={{ width: '100%', position: 'relative', overflow: 'auto', height: '400px', paddingBottom: '17px', marginBottom: '15px' }}>
                    {
                        Display_Package.map((value, index) => {
                            const _backgroundImage = { width: '70px', height: '50px', backgroundColor: 'gray', backgroundImage: `url(${CONFIG.host}${value.packageInfo.image.thumbnail})`, backgroundRepeat: 'round' };
                            return (
                                <ListItem key={index} style={{ paddingLeft: '15px', border: '1px solid #9B9B9B', marginTop: '10px', }} dense button
                                    onClick={handleToggle('special', value)}                                >
                                    <Checkbox  color="primary" checked={SpecialInfo.packageId === value.packageId} tabIndex={-1} disableRipple />
                                    <div style={_backgroundImage} />
                                    <ListItemText style={{ fontSize: '14px', paddingLeft: 10 }} primary={`${value.packageInfo.name}`} />
                                </ListItem>
                            )
                        })}
                </List>
                <div style={{ textAlign: 'center', fontSize: '16px', display: 'flex', }}>
                    <Button.Themes TitleText={ButtonText.BUTTON_OK}
                        disabled={!SpecialInfo.packageId && true}
                        onClick={() => onSavePanel(SpecialInfo.packageId)} />
                    <Button.Themes TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingLeft: '15px', }}
                        onClick={() => onCancelPanel()}
                    />
                </div>
            </div>
        );
    }
}
PackageCard.propTypes = { classes: PropTypes.object.isRequired, };

const styles = theme => (
    {
        textField: { width: '100%', marginTop: 5, marginBottom: 5 },
        textFormLabel: { fontSize: 18, marginTop: 0, fontFamily: Family.roboto, },
        selectInput: {
            marginTop: 5,
            padding: 10,
            paddingRight: '20px',
            color: Colors.grey,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 16,
            fontFamily: Family.roboto,
        },
    });

export default (withStyles(styles)(PackageCard));
