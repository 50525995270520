
import * as SellerAction from '../actions/Actions_SellerManager.js';

const INITIAL_STATE = { SELLER_REDUCER: { actions: SellerAction.IDLE_STATE, data: null, error: null }, };
const ACTION = { type: SellerAction.INITIAL_STATE };

export default function (state = INITIAL_STATE, action) {
    let _error = null;
    const payload = action.payload;

    switch (action.type) {
        case SellerAction.INITIAL_STATE:
            {
                return state;
            }
        case SellerAction.IDLE_STATE:
            {
                //console.log("[Seller ] : IDLE_STATE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.IDLE_STATE, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_SUCCESS:
            {
                //console.log("[Seller ] : GET_SELLER_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_FAILURE:
            {
                console.log("[Seller ] : GET_SELLER_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_INFO_SUCCESS:
            {
                //console.log("[Seller ] : GET_SELLER_INFO_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_INFO_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_INFO_FAILURE:
            {
                console.log("[Seller ] : GET_SELLER_INFO_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_INFO_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_WITH_AGENT_ID_SUCCESS:
            {
                // console.log("[Seller ] : GET_SELLER_WITH_AGENT_ID_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_WITH_AGENT_ID_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_WITH_AGENT_ID_FAILURE:
            {
                console.log("[Seller ] : GET_SELLER_WITH_AGENT_ID_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_WITH_AGENT_ID_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.CREATE_SELLER_SUCCESS:
            {
                //console.log("[Seller ] : CREATE_SELLER_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.CREATE_SELLER_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.CREATE_SELLER_FAILURE:
            {
                console.log("[Seller ] : CREATE_SELLER_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.CREATE_SELLER_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.UPDATE_SELLER_SUCCESS:
            {
                // console.log("[Seller ] : UPDATE_SELLER_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.UPDATE_SELLER_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.UPDATE_SELLER_FAILURE:
            {
                console.log("[Seller ] : UPDATE_SELLER_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.UPDATE_SELLER_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_TYPE_SUCCESS:
            {
                //console.log("[ Seller Type ] : GET_SELLER_TYPE_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_TYPE_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.GET_SELLER_TYPE_FAILURE:
            {
                console.log("[ Seller Type ] : GET_SELLER_TYPE_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.GET_SELLER_TYPE_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.CREATE_SELLER_TYPE_SUCCESS:
            {
                // console.log("[ Seller Type ] : CREATE_SELLER_TYPE_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.CREATE_SELLER_TYPE_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.CREATE_SELLER_TYPE_FAILURE:
            {
                console.log("[ Seller Type ] : CREATE_SELLER_TYPE_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.CREATE_SELLER_TYPE_FAILURE, data: payload, error: _error } };
            }
        case SellerAction.UPDATE_SELLER_TYPE_SUCCESS:
            {
                // console.log("[ Seller Type ] : UPDATE_SELLER_TYPE_SUCCESS : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.UPDATE_SELLER_TYPE_SUCCESS, data: payload, error: _error } };
            }
        case SellerAction.UPDATE_SELLER_TYPE_FAILURE:
            {
                console.log("[ Seller Type ] : UPDATE_SELLER_TYPE_FAILURE : ", payload);
                return { ...state, SELLER_REDUCER: { actions: SellerAction.UPDATE_SELLER_TYPE_FAILURE, data: payload, error: _error } };
            }
        default:
            {
                //console.log("[Seller ]  default :", state);
                return state;
            }
    }
}
