import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import TextField from '@material-ui/core/TextField';
import { FileCopy } from '@material-ui/icons';

import CONFIG from '../../config';
import DialogPanel from '../../componets/DialogPanel.jsx';
import { NumberFormats } from '../../common/SetNumberFormat';

import SubTopicsPanel from '../../componets/SubTopicsPanel.jsx';
import * as PackageAction from '../../actions/Actions_PackageManager.js';
import * as DestinationsActions from '../../actions/Actions_DestinationsManager';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';

// import ImageGalleryPanel from './ImageGalleryPanel';
// import ImageSlide from '../../componets/ImageSlide.jsx'
// import PriceDetailBar from './PriceDetailBar';
// import ServiceIconPanel from './ServiceIconPanel';
// import ShouldKnow from './ShouldKnow';
// import ServiceInclusion from './ServiceInclusion';
// import TourProgram from './TourProgram';

import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';

import '../../App.css';
import '../../themes/Themes.css';
import { Styles, Colors, Family } from '../../themes';
import { isMobile } from '../../common/DeviceCheck';
var QRCode = require('qrcode.react');

const _height = Styles.heightWithTopic;

const pageWording = {
    topics: 'Package Affiliate Detail.',
    subheading: [],
    icon: ['safeBook', 'bestPrice', 'fee'],
};

let DestinationsList = [];
let waitingTime = true;

class PackageAffiliatePanel extends Component {

    state = { isLoading: true, }

    constructor(props) {
        super(props);

    }

    componentWillReceiveProps(nextProps) {
        console.log(`PackageAffiliatePanel [componentWillReceiveProps] : `, nextProps);

    }

    render() {
        console.log('PackageAffiliatePanel', this.props)

        const { packageInfo } = this.props;

        return (
            <div className="App">
                <div style={style.page_container}>
                    <label style={isMobile() ? style.topicsMobile : style.topics}>{pageWording.topics}</label>
                </div>
                <List style={style.list_container}>
                    <div style={{ display: isMobile() ? 'inline-block' : 'flex' }}>
                        {this.contentPanel(packageInfo)}
                    </div>
                </List>
            </div>
        );
    }

    contentPanel(_packageInfo) {
        const { classes, isVisible, affiliateID } = this.props;
        const { packageId, isPromotion, type, packageInfo } = _packageInfo;
        const { image, name, price, desShort } = packageInfo;

        console.log('image : ', image.original);

        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: isMobile() ? '10px 0px' : 10, textAlign: 'left' };
        const _backgroundImage = { width: '250px', height: '200px', backgroundColor: Colors.defaultImage, backgroundImage: `url(${this.checkURL(image)})`, backgroundRepeat: 'round' };

        const _price = price;
        const _affiliateLink = `${CONFIG.webURL}/choosePackage?${packageId}${isVisible ? `#${affiliateID}` : ''}`
        const _topicsLable = isMobile() ? style.classMobile : style.class;
        const _infoLable = isMobile() ? style.infoMobile : style.info;

        return (
            <div style={{ height: '400px', width: '100%', maxWidth: '1024px', }}>
                <div style={{ display: isMobile() ? 'block' : 'flex', borderBottom: `5px solid ${Colors.theme}`, borderTop: `5px solid ${Colors.theme}`, padding: '20px 0px' }}>
                    <div style={{ flex: 1, marginRight: 10 }}>
                        <div style={_backgroundImage} />
                    </div>
                    <div style={{ width: '100%', flex: 3 }}>
                        <div style={_styleInfoItem}>
                            <div >
                                <label style={_topicsLable}>Package Name : </label>
                                <label style={_infoLable}>{name}</label>
                            </div>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>Package Id : </label>
                            <label style={_infoLable}>{packageId}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>Package Type : </label>
                            <label style={_infoLable}>{type}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>In Promotion : </label>
                            <label style={_infoLable}>{`${isPromotion}`}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>Adult Price : </label>
                            <label style={_infoLable}>{`${NumberFormats(_price.adult || 0)} ${_price.currency}`}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>Kid Price : </label>
                            <label style={_infoLable}>{`${NumberFormats(_price.kid || 0)} ${_price.currency}`}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={_topicsLable}>Infant Price : </label>
                            <label style={_infoLable}>{`${NumberFormats(_price.infant || 0)} ${_price.currency}`}</label>
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: 'left', padding: '10px 0px', borderBottom: `${'5px solid' + Colors.theme}` }}>
                    <QRCode
                        value={_affiliateLink}
                        size={128}
                        bgColor={Colors.white}
                        fgColor={Colors.themeDark}
                        level={"L"} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 15 }} >
                    <TextField
                        id="AffiliateLink"
                        label="Affiliate Link"
                        style={{ width: '100%', marginTop: 0 }}
                        value={_affiliateLink}
                        InputProps={{ classes: { input: classes.textFormLabel }, }} />
                    <Button.Icons themeDark icon={<FileCopy />} tooltip={'Copy'} onClick={() => this.HandleCopy()} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px 0px' }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL}
                        onClick={() => this.props.OnClose()} />

                </div>
            </div >
        );
    }

    HandleCopy = () => {

        const copyText = document.getElementById("AffiliateLink");
        copyText.select();
        document.execCommand("copy");
        // alert("Copied the text: " + copyText.value);
    }

    checkURL = (image) => ((!!image && image.thumbnail !== undefined) ? `${CONFIG.host}${image.thumbnail}` : '');
}

const style =
{
    topics:
    {
        fontSize: 26,
        color: Colors.theme,
        fontFamily: Family.roboto,
        float: 'left',
    },
    topicsMobile:
    {
        fontSize: 18,
        color: Colors.theme,
        fontFamily: Family.roboto,
        marginTop: 10,
    },
    page_container:
    {
        display: 'inline-block',
        width: '100%',
        maxWidth: '1030px',
        paddingTop: '10px',
    },
    content_container:
    {
        height: '100%',
        width: '100%',
        maxWidth: '1024px',
    },
    list_container:
    {
        width: '100%',
        position: 'relative',
        // overflow: 'auto',
        height: 550,//_height
    },
    info: {
        height: '14px',
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        lineHeight: '16px',
    },
    class: {
        height: '14px',
        width: '150px',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        lineHeight: '16px',
        textAlign: "left"
    },
    infoMobile: {
        height: '14px',
        width: '100%',
        maxWidth: 180,
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '11.5px',
        lineHeight: '16px',
        textAlign: 'left'
    },
    classMobile: {
        height: '14px',
        marginRight: 5,
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '11px',
        lineHeight: '16px',
        textAlign: 'left'
    },

};
const styles = theme => ({
    textFormLabel: {
        fontSize: '14.5px',
    }
})
PackageAffiliatePanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        PackageManager: state.PackageManager.PACKAGE_REDUCER,
        DestinationsManager: state.DestinationsManager.DESTINATION_REDUCER,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAPIPackageList: (_keyword) => {
            dispatch(PackageAction.getAPIPackageLists());
        },
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PackageAffiliatePanel));


