import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { path } from 'ramda';
import NumberFormat from 'react-number-format';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import CONFIG from '../../config';
import Avatar from '../../constant/Avatar';

import DialogPanel from '../../componets/DialogPanel.jsx';

// import * as PromotionActions from '../../actions/Actions_PromotionManager';
import * as AccountingActions from '../../actions/Actions_Accounting.js';

import { CheckEmailFormat } from '../../common/EmailFormat.js'
// import Language from '../../constant/Language';
import FormTypes from '../../common/FormTypes';

import ButtonText from '../../constant/Button/ButtonText';
import Button from '../../constant/Button';
import { Colors, Fonts, Family } from '../../themes';
import { SetDate, getFormatted, } from '../../common/SetDate.js'
import { PathName, ErrorCode, SetMemberToCrate, Roles } from '../../constant';
import { InitWaitingTime, SetStateWithTime, SetStateWithObject } from '../../common/SetTime.js';
import { create_withdraw, content_withdraw, withdrawStruct } from '../../constant/JSON/CreateWithdarw.js';

import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../Supplier/Supplier.css';
import { NumberFormats } from '../../common/SetNumberFormat';

import * as format from '../../common/SetNumberFormat';
import { PriceFormat } from '../../common/setFormat';

let requiredList = [];
let waitingTime = true;
let _WithdrawInfo;

const _style = { textAlign: 'start', width: '100%', marginRight: '5px' };
const _rowContainer = { display: 'flex', padding: '5px 5px' };

class WithDrawPanel extends Component {

    state = { isLoading: false, msgError: undefined, dialogType: undefined, orderBy: 'desc', sortFilde: undefined, isBtnActive: false, isSuccess: false };

    constructor(props) {
        super(props);

        _WithdrawInfo = this.props.WithdrawInfo || { ...withdrawStruct };

        console.log('WithDrawPanel : ', _WithdrawInfo);
    }

    componentWillReceiveProps(nextProps) {

        const { AccountingManager } = nextProps;

        if (!!AccountingManager) {

            const { actions, data, error } = AccountingManager.ACCOUNTING_REDUCER

            switch (actions) {
                case AccountingActions.WITHDRAW_MONEY_SUCCESS:
                    {
                        AccountingManager.ACCOUNTING_REDUCER.actions = AccountingActions.WATING_STATE;
                        this.props.getAPIAccoutingHistoryList({ key: 'updateDate', orderBy: 'desc', num_limit: 10 });
                        SetStateWithObject(this, waitingTime, { msgError: 'Withdraw money Success.', dialogType: 'success', isSuccess: true });
                    } break;
                case AccountingActions.WITHDRAW_MONEY_FAILURE:
                    {
                        console.log(`${actions} : `, AccountingManager.ACCOUNTING_REDUCER);
                        let _msg = actions;

                        SetStateWithObject(this, waitingTime, { msgError: _msg, dialogType: 'error', });
                    } break;
            }
        }
    }

    componentWillUnmount() {

        // console.log('componentWillUnmount : ', _WithdrawInfo)
        _WithdrawInfo = undefined;
    }

    render() {

        const { OnClose, classes } = this.props;
        const { isBtnActive, isLoading, msgError, dialogType } = this.state;
        requiredList = [];

        return (
            <div style={{ maxWidth: 800, margin: '15px' }}>
                {(isLoading) && <DialogPanel Active={isLoading} MSG={msgError} Type={dialogType} OnClose={() => { this.onCloseDialog() }} />}
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography className={classes.title}>{content_withdraw.header}</Typography>
                </div>
                {(!!_WithdrawInfo) && this.contentPanel(_WithdrawInfo)}
                {
                    create_withdraw.map((row, index) => (
                        <div key={index} style={_rowContainer}>
                            {row.map((each, index) => (<div key={index} style={_style}>{this.SetItemsWithType(each)}</div>))}
                        </div>
                    ))
                }
                <div style={{ display: 'flex', justifyContent: 'flex-end', borderTop: `${'5px solid' + Colors.theme}`, paddingTop: 20 }}>
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_CANCEL} style={{ paddingRight: '15px', }}
                        onClick={() => OnClose()} />
                    <Button.Themes
                        TitleText={ButtonText.BUTTON_SAVE}
                        disabled={!isBtnActive} onClick={() => this.onSave()} />
                </div>
            </div>
        )
    }

    contentPanel(_WithdrawInfo) {

        //console.log('_WithdrawInfo', _WithdrawInfo);

        const { AssigneeType, BookBankList } = this.props;
        const { supplierId, fullName, wallet, bank, name, userType, logo, } = _WithdrawInfo;
        const _styleInfoItem = { display: 'flex', width: '100%', paddingLeft: '0px', margin: 10 };
        let bankName = null;
        let Language = path(['language'], BookBankList)
        Array.isArray(BookBankList.datas) && BookBankList.datas.find((val) => (val.bankSettingId === bank.bankId) ? bankName = val[Language].name : null)

        return (
            <div style={{ width: '100%', maxWidth: '1024px', marginBottom: 20 }}>
                <Line />
                <div style={{ display: 'flex', padding: '20px 0px' }}>
                    <div style={{ width: 450, flex: 3 }}>
                        <div style={_styleInfoItem}>
                            <label style={style.class}>Transfer to : </label>
                            <label style={style.info}>{fullName || (name || '-')}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={style.class}>Balance : </label>
                            <label style={style.info}>{`${NumberFormats(wallet || 0)} Bath`}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={style.class}>Type : </label>
                            <label style={style.info}>{AssigneeType || '-'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={style.class}>Bank : </label>
                            <label style={style.info}>{bankName || '-'}</label>
                        </div>
                        <div style={_styleInfoItem}>
                            <label style={style.class}>Account Id : </label>
                            <label style={style.info}>{bank.accountNumber || '-'}</label>
                        </div>
                    </div>
                </div>
                <Line />
            </div>
        );
    }

    SetItemsWithType(each) {

        const { classes } = this.props;
        // console.log('MemberInfo', MemberInfo);

        if (each.required)
            requiredList.push(each.stateName);

        switch (each.type) {
            case 'text': {
                return (
                    <React.Fragment>
                        <TextField className={classes.textField} margin="normal"
                            label={this.getRequiredSymbol(each)}
                            placeholder={each.placeholder}
                            value={_WithdrawInfo[each.stateName] || ''}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                            InputProps={{ disableUnderline: true, classes: { input: classes.textInput, }, }}
                            InputLabelProps={{ shrink: true, className: classes.textFormLabel, }} />
                    </React.Fragment>
                )
            }

            case 'number': {
                return (
                    < React.Fragment>
                        <TextField className={classes.textField} margin="normal"
                            label={this.getRequiredSymbol(each)}
                            placeholder={each.placeholder}
                            value={_WithdrawInfo[each.stateName] || ''}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                            InputProps={{ inputComponent: PriceFormat, disableUnderline: true, classes: { input: classes.textInput, }, }}
                            InputLabelProps={{ shrink: true, className: classes.textFormLabel }}
                             />
                    </React.Fragment>)
            }

            case 'select': {
                return (
                    <React.Fragment>
                        <TextField select className={classes.textField} margin="normal"
                            label={this.getRequiredSymbol(each)}
                            value={_WithdrawInfo[each.stateName] || ''}
                            onChange={(e) => { this.onChangeState(each.stateName, e.target.value) }}
                            InputProps={{ disableUnderline: true, classes: { input: classes.selectInput, }, }}
                            InputLabelProps={{ shrink: true, className: classes.textFormLabel, }}
                            SelectProps={{ native: true, }}>
                            {
                                each.option.map((option, index) => (<option key={index} value={option.name}>{option.name}</option>))
                            }
                        </TextField>
                    </React.Fragment>)
            }

            case 'date': {
                return (
                    <React.Fragment>
                        {this.getRequiredDateSymbol(each, classes)}
                        <DatePicker
                            selected={Boolean(_WithdrawInfo[each.stateName]) ? moment(_WithdrawInfo[each.stateName]) : undefined}
                            selectsStart
                            disabled={false}
                            className={classes.dateInput}
                            dateFormat="DD/MM/YYYY"
                            onChange={(value) => { this.onChangeState(each.stateName, value) }} />
                    </React.Fragment>)
            }

            case 'image': {
                return (
                    <div className={classes.textDefalut} >
                        <div>{each.title}</div>
                        <Avatar editable ref='avatar'
                            onChange={(files) => { this.onChangeState(each.stateName || each.type, files) }}
                            avatar={Boolean(_WithdrawInfo.avatarLink) ? `${CONFIG.host}${_WithdrawInfo.avatarLink}` : ''} />
                    </div>
                )
            }

            case 'switch': {
                return (
                    <FormTypes.Switches Left
                        ValueText={'Status'}
                        checked={path(['actived'], _WithdrawInfo)}
                        onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                    // <React.Fragment>
                    //     <label className={classes.textDefalut} >{'Status'}</label>
                    //     <Switch color="secondary"
                    //         checked={path(['actived'], _WithdrawInfo)}
                    //         onChange={(e) => { this.onChangeState(each.stateName, e.target.checked) }} />
                    // </React.Fragment>
                )
            }
        }
    }

    // ============================================================================================================================================

    getRequiredSymbol(item) {
        return (item.required) ? (<React.Fragment><span style={{ color: 'red' }}>{`* `}</span>{item.title} </React.Fragment>) : (item.title);
    }

    getRequiredDateSymbol(item, classes) {

        return (item.required) ?
            (<React.Fragment>
                <span style={{ color: 'red' }}>{`* `}</span>
                <label className={classes.textDatePicker} style={{ fontSize: 13 }} >{item.title}</label>
            </React.Fragment>) :
            (<label className={classes.textDatePicker} style={{ fontSize: 13 }} >{item.title}</label>);
    }

    // ============================================================================================================================================

    onChangeState = (_key, _value) => {

        // console.log("onChange : ", _key, ' : ', _value);

        switch (_key) {
            case "firstName":
            case "lastName":
                {
                    _WithdrawInfo[_key] = _value;
                    this.ValidateRequiredList();
                } break;

            case "email":
                {
                    _WithdrawInfo[_key] = _value;
                    const _isvalidate = CheckEmailFormat(_WithdrawInfo[_key]);

                    if (this.state.isBtnActive !== _isvalidate)
                        this.setState({ isBtnActive: _isvalidate });
                    else this.ValidateRequiredList();

                } break;

            case "startDate":
            case "endDate":
                {
                    const _selectDate = new Date(_value);
                    _WithdrawInfo[_key] = `${_selectDate.getFullYear()}-${getFormatted(_selectDate.getMonth() + 1)}-${getFormatted(_selectDate.getDate())}`;
                    console.log("CheckDataPicker : ", _WithdrawInfo);
                    this.ValidateRequiredList();

                } break;

            case "discountType":
                {
                    _WithdrawInfo[_key] = _value;
                    this.ValidateRequiredList();
                } break;

            default:
                {
                    _WithdrawInfo[_key] = _value;
                    this.ValidateRequiredList();
                } break;
        }
    }

    // =====================================================================================================================================================

    ValidateRequiredList() {

        const _isEvery = requiredList.every((item) => Boolean(_WithdrawInfo[item]));
        const { wallet, amount } = _WithdrawInfo;
        const { isBtnActive } = this.state;
        const isBalance = (wallet > amount);

        if (!isBtnActive)
            (_isEvery && isBalance) ? this.setState({ isBtnActive: true }) : this.forceUpdate();
        else if (isBtnActive && (!_isEvery || !isBalance))
            this.setState({ isBtnActive: false })
        else
            this.forceUpdate();

    }

    GetUserAssigneeIdWithType(_type, _info) {

        switch (_type) {
            case Roles.Supplier: return _info.supplierId;
            case Roles.Agent: return _info.agentId;
            case Roles.Seller: return _info.sellerId;
            case Roles.Customer: return _info.customerId;
        }
    }

    // =====================================================================================================================================================

    onSave = () => {

        const { AssigneeType } = this.props;
        const { supplierId, wallet, bank, name, userType, logo, amount } = _WithdrawInfo;
        const UserInfo = JSON.parse(localStorage.getItem('UserInfo'));

        console.log('onSave : ', _WithdrawInfo, ' : ', UserInfo, ' : ', this.GetUserAssigneeIdWithType(AssigneeType, _WithdrawInfo))

        this.setState({ isLoading: true, isBtnActive: false }, () => {
            this.props.setAPIWithdrawMoney({
                AdminId: UserInfo.userTypeId,
                userAssigneeId: this.GetUserAssigneeIdWithType(AssigneeType, _WithdrawInfo),
                userAssigneeType: AssigneeType,
                amount: parseInt(amount)
            })
        });
    }

    onCloseDialog = () => (
        this.setState({ isLoading: false, msgError: undefined, dialogType: undefined, isBtnActive: false }, () => {
            this.state.isSuccess && this.props.OnClose()
        }));
}

const styles = theme => (
    {
        textField: { width: '100%', marginTop: 0 },
        textFormLabel: { fontSize: 18, marginTop: -3, },
        textDefalut: { margin: '5px 0px', fontSize: '16px', color: Colors.themeDark, fontFamily: Family.roboto },
        textDatePicker: { margin: '0px', fontSize: '16px', color: Colors.themeDark, fontFamily: Family.roboto },
        title: { display: 'flex', height: '60px', fontSize: '24px', alignItems: 'center' },
        textInput: {
            width: '100%', marginTop: 5, padding: 10, height: '23px',
            color: Colors.themeDark, borderRadius: 0, border: `${'1px solid' + Colors.border}`, fontSize: 16,
        },
        selectInput: {
            marginTop: 5, padding: 12, paddingRight: '20px',
            color: Colors.themeDark, borderRadius: 0, border: `${'1px solid' + Colors.border}`, fontSize: 16,
        },
        multiple: {
            width: '100%', margin: '7px 0px', padding: '3px 0px',
            color: Colors.themeDark, border: `${'1px solid' + Colors.border}`, fontSize: '18px',
        },
        dateInput: {
            width: '50',
            marginTop: 5,
            padding: 10,
            marginBottom: 10,
            paddingRight: '49px',
            color: Colors.themeDark,
            borderRadius: 0,
            border: `${'1px solid' + Colors.border}`,
            fontSize: 16,
        },
    }
);

const style =
{
    info: {
        height: '14px',
        opacity: '0.64',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '16px',
        lineHeight: '16px',
    },
    class: {
        height: '14px',
        width: '150px',
        color: Colors.themeDark,
        fontFamily: Family.roboto,
        fontSize: '18px',
        // fontWeight: 'bold',
        lineHeight: '16px',
        textAlign: "left"
    },

};

WithDrawPanel.propTypes = { classes: PropTypes.object.isRequired, };

function mapStateToProps(state, ownProps) { return { ...state }; }

function mapDispatchToProps(dispatch) {
    return {
        setAPIWithdrawMoney: (_filter) => {
            InitWaitingTime();
            dispatch(AccountingActions.APIWithdrawMoney(_filter));
        },
        getAPIAccoutingHistoryList: (_filter) => {
            dispatch(AccountingActions.getAccoutingHistoryList(_filter));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true })(WithDrawPanel)));

const Line = styled.div`
        height:3px;
        background-color:${Colors.theme}`;










